/** @format */

import React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';
import cx from 'classnames';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  checkboxContainer: {
    borderRadius: 3,
    width: theme.spacing(5),
    height: theme.spacing(5),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  checkboxContainerNotChecked: {
    border: `1px solid ${theme.palette.ds.grey500}`,
    backgroundColor: theme.palette.ds.grey100,
  },
  checkboxContainerChecked: {
    backgroundColor: theme.palette.ds.lightRed,
    boxShadow: '0px -1px 1px 0px rgba(16, 22, 26, 0.2) inset',
  },
  tick: {
    margin: '2px 0px 0px 2px',
    color: theme.palette.ds.white,
  },
}));
interface Props {
  className?: string;
  isChecked: boolean;
  onChange: () => void;
}
const CustomCheckbox = (props: Props) => {
  const { isChecked, onChange } = props;
  const classes = useStyles();
  return (
    <div
      className={cx(
        classes.checkboxContainer,
        {
          [cx(
            classes.checkboxContainerChecked,
            GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.backgroundColor,
          )]: isChecked,
          [classes.checkboxContainerNotChecked]: !isChecked,
        },
        GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.color,
        props.className,
      )}
      onClick={() => onChange()}>
      {isChecked && <Icon className={classes.tick} icon="small-tick" />}
    </div>
  );
};

export default CustomCheckbox;
