/** @format */

import React, { useCallback, useEffect, useState } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'url-parse';
// @ts-ignore
import JSURL from 'jsurl';

import LoadingState from '../EmbeddedDashboard/LoadingState';
import ChartLayout from 'components/ChartLayout/ChartLayout';

import { ActionFn, ActionFnArgs } from 'actions/actionUtils';
import {
  EmbedFetchDashboardData,
  FetchDataPanelTemplateData,
  FetchDataPanelTemplateRowCountData,
} from 'actions/responseTypes';
import {
  embedFetchDashboard,
  embedFetchDataPanelRowCount,
  embedFetchDataPanelTemplate,
  embedSetDptLoading,
  embedFetchSecondaryData,
} from 'actions/shareActions';
import { ACTION, AdHocOperationInstructions, Team } from 'actions/types';
import { pageView } from 'analytics/exploAnalytics';
import { UserTransformedSchema } from 'constants/types';
import { createLoadingSelector } from 'embeddedContent/reducers/api/selectors';
import { ReduxState } from 'embeddedContent/reducers/rootReducer';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { GlobalStylesProvider } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { createAnalyticsEventTracker, sendAnalyticsEvent } from 'utils/analyticsUtils';
import { getQueryVariables } from 'components/EmbeddedDashboard/EmbeddedDashboard';
import { loadLocale } from 'utils/localizationUtils';

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    width: '100%',
    height: '100%',
    margin: theme.spacing(2),
  },
  errorMessage: {
    margin: theme.spacing(10),
    fontSize: 24,
    padding: theme.spacing(10),
    backgroundColor: '#FAE1E1',
    borderRadius: 8,
  },
}));

type AnalyticsMetadata = {
  team_id: number;
  team_name: string;
  end_user_group_id: number;
  end_user_group_name: string;
  dashboard_template_id: number;
  dashboard_template_name: string;
};

type Props = {
  dashboardEmbedId: string;
  userGroupToken: string;
  dataPanelId: string;
  customStyles?: GlobalStyleConfig;
  embeddedVariables?: DashboardVariableMap;
  environment?: string;
  versionNumber?: number;
  isProduction?: string;
  updateUrlParams?: boolean;
};

export default function EmbeddedChart(props: Props) {
  const {
    customStyles,
    dashboardEmbedId,
    embeddedVariables,
    environment: environmentProp,
    versionNumber,
    isProduction: isProductionProp,
    userGroupToken,
    dataPanelId,
  } = props;
  const classes = useStyles();

  const [visitorId, setVisitorId] = useState<string>('');
  const [userGroupName, setUserGroupName] = useState<string>('');
  const [analyticsMetadata, setAnalyticsMetadata] = useState<AnalyticsMetadata | undefined>();
  const [team, setTeam] = useState<Team>();
  const [urlVariables] = useState<DashboardVariableMap>(getQueryVariables());
  const [userTransformedSchema] = useState<UserTransformedSchema>(getUserTransformedSchema());
  const [adHocOps] = useState<AdHocOperationInstructions>(getAdHocOps());
  const [reportName] = useState<string>(getReportName());

  const dispatch = useDispatch();
  const {
    dashboardConfig,
    dashboardLoading,
    dashboardTemplate,
    dashboardVersionNumber,
    embedError,
    globalStyleConfig,
  } = useSelector((state: ReduxState) => ({
    dashboardTemplate: state.embedDashboard.dashboardTemplate,
    dashboardLoading: createLoadingSelector([ACTION.EMBED_FETCH_DASHBOARD], true)(state),
    dashboardConfig: state.embedDashboard.dashboardVersion?.configuration,
    dashboardVersionNumber: state.embedDashboard.dashboardVersion?.version_number,
    globalStyleConfig: state.embedDashboardStyle.globalStyleConfig,
    embedError: state.dashboard.errorMsg,
  }));

  const onLoad = () => {
    pageView('Shared Chart');
    fetchDashboardData();
  };
  useEffect(onLoad, [dashboardEmbedId, userGroupToken]);

  const fetchDashboardData = () => {
    const queryVariables = getQueryVariables();
    const environment = environmentProp || queryVariables['environment'];
    const isProduction = isProductionProp ?? queryVariables['is_production'];

    dispatch(
      embedFetchDashboard(
        {
          userGroupToken,
          postData: {
            dashboard_embed_id: dashboardEmbedId,
            version_number: versionNumber,
            environment,
          },
        },
        (data: EmbedFetchDashboardData) => {
          const metadata = {
            team_id: data.team.id,
            team_name: data.team.team_name,
            end_user_group_id: data.end_user_group.id,
            end_user_group_name: data.end_user_group.name,
            dashboard_template_id: data.dashboard_template.id,
            dashboard_template_name: data.dashboard_template.name,
          };

          const sendPageViewAnalytics = async () => {
            // Get the (browser) visitor identifier:
            const fp = await FingerprintJS.load();
            const result = await fp.get();

            // Send page view analytics
            sendAnalyticsEvent(
              REPORTED_ANALYTIC_ACTION_TYPES.SHARED_CHART_VIEWS,
              result.visitorId,
              {
                ...metadata,
                is_production: isProduction ? isProduction === 'true' : undefined,
                environment,
              },
              data.team.analytics_reporting_actions,
              data.team.analytics_reporting_url,
              data.team.analytics_reporting_token,
            );

            setVisitorId(result.visitorId);
          };

          sendPageViewAnalytics();

          setUserGroupName(data.end_user_group.name);
          setAnalyticsMetadata(metadata);
          setTeam(data.team as Team);

          loadLocale({
            teamLocaleCode: data.team.default_locale_code,
            useBrowserLocale: data.team.use_browser_locale,
          });
        },
      ),
    );
  };

  const analyticsEventTracker = useCallback(
    (eventType: REPORTED_ANALYTIC_ACTION_TYPES) =>
      createAnalyticsEventTracker(
        visitorId,
        'chart',
        team,
        analyticsMetadata,
      )(eventType, { embed_source: 'share' }),
    [visitorId, analyticsMetadata, team],
  );

  const fetchDataPanelTemplateRowCountWrapper: ActionFn<FetchDataPanelTemplateRowCountData> = useCallback(
    (data?: ActionFnArgs) => {
      data = data || {};

      return dispatch(
        embedFetchDataPanelRowCount({
          ...data,
          userGroupToken,
          postData: {
            ...data.postData,
          },
        }),
      );
    },
    [userGroupToken, dispatch],
  );

  const fetchDataPanelTemplateWrapper: ActionFn<FetchDataPanelTemplateData> = useCallback(
    (data?: ActionFnArgs, onSuccess?: (data: FetchDataPanelTemplateData) => void) => {
      data = data || {};

      return dispatch(
        embedFetchDataPanelTemplate(
          {
            ...data,
            userGroupToken,
            postData: {
              ...data.postData,
            },
          },
          onSuccess,
        ),
      );
    },
    [userGroupToken, dispatch],
  );

  const fetchSecondaryDataWrapper: ActionFn<FetchDataPanelTemplateData> = useCallback(
    (data?: ActionFnArgs) => {
      data = data || {};

      return dispatch(
        embedFetchSecondaryData({
          ...data,
          userGroupToken,
          postData: {
            ...data.postData,
          },
        }),
      );
    },
    [dispatch, userGroupToken],
  );

  if (dashboardLoading) {
    return <LoadingState type={'embedded'} />;
  } else if (embedError) {
    return <div className={classes.errorMessage}>{embedError}</div>;
  } else if (!dashboardTemplate || !dashboardConfig) {
    return (
      <div className={classes.errorMessage}>
        There was an error loading the dashboard. Please contact your support team for help.
      </div>
    );
  }

  return (
    <GlobalStylesProvider globalStyleConfig={{ ...globalStyleConfig, ...customStyles }}>
      {(globalStylesClassName) => (
        <div id="shared-explo-dashboard" className={globalStylesClassName}>
          {dashboardTemplate && dashboardConfig && (
            <ChartLayout
              analyticsEventTracker={analyticsEventTracker}
              dataPanelId={dataPanelId}
              dashboardDatasets={dashboardConfig.datasets}
              dashboardLayout={dashboardConfig.dashboard_layout}
              dashboardTemplate={dashboardTemplate}
              dataPanelTemplate={dashboardConfig.data_panels[dataPanelId]}
              dashboardVersionNumber={dashboardVersionNumber}
              fetchDataPanelRowCount={fetchDataPanelTemplateRowCountWrapper}
              fetchDataPanelTemplate={fetchDataPanelTemplateWrapper}
              fetchSecondaryData={fetchSecondaryDataWrapper}
              setDptLoading={(...args) => {
                dispatch(embedSetDptLoading(...args));
              }}
              userGroupName={userGroupName}
              variables={{ ...urlVariables, ...embeddedVariables }}
              userTransformedSchema={userTransformedSchema}
              adHocOperationInstructions={adHocOps}
              reportName={reportName}
            />
          )}
        </div>
      )}
    </GlobalStylesProvider>
  );
}

export function getReportName() {
  const rawVars = parse(window.location.href, true).query;
  if (!rawVars?.reportName) return '';

  const reportNameWithQuotesRemoved = rawVars.reportName.substring(
    1,
    rawVars.reportName.length - 1,
  );

  return reportNameWithQuotesRemoved;
}

export function getUserTransformedSchema() {
  const rawVars = parse(window.location.href, true).query;
  if (!rawVars?.userTransformedSchema) return [];

  return JSURL.parse(
    rawVars.userTransformedSchema.substring(1, rawVars.userTransformedSchema.length),
  ) as UserTransformedSchema;
}

export function getAdHocOps() {
  const rawVars = parse(window.location.href, true).query;
  if (!rawVars?.adHocOps) return {};

  return JSURL.parse(
    rawVars.adHocOps.substring(1, rawVars.adHocOps.length),
  ) as AdHocOperationInstructions;
}
