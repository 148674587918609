/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from '@material-ui/core/index';

import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import Button from 'shared/Button';

import { SortClause, SortOrder, VisualizeLineOrBarChartInstructions } from 'constants/types';
import { TableColumn } from 'actions/types';
import { useDispatch } from 'react-redux';
import {
  createSortClause,
  deleteSortClause,
  selectSortColumn,
  selectSortOrder,
} from 'actions/dataPanelConfigActions';
import SortEditingPanel from './SortEditingPanel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(6),
  },
}));

type Props = {
  schema: TableColumn[];
  visualizeLineOrBarChartInstructions: VisualizeLineOrBarChartInstructions;
  sortClauses?: SortClause[];
};

export default function SortConfigMenu({ schema, sortClauses }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sortedByColumnNames = sortClauses?.flatMap((clause) => clause.column?.name || []);

  return (
    <div className={classes.root}>
      <ConfigSectionHeader isSubTitle={true} title="SORT" />
      {sortClauses?.map((sortClause, index) => (
        <SortEditingPanel
          schema={schema}
          sortClause={sortClause}
          sortedByColumnNames={sortedByColumnNames}
          onDelete={() => {
            dispatch(deleteSortClause(index));
          }}
          onColumnSelect={(column: TableColumn) => {
            dispatch(selectSortColumn(index, column));
          }}
          onOrderSelect={(order: SortOrder) => {
            dispatch(selectSortOrder(index, order));
          }}
        />
      ))}
      <Button
        fillWidth
        type="secondary"
        onClick={() => {
          dispatch(createSortClause());
        }}
        text="Add Sort"
      />
    </div>
  );
}
