/** @format */

import React, { Component } from 'react';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Button, IconName, Intent } from '@blueprintjs/core';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import InputLabel from 'shared/InputLabel';

const styles = () => ({
  compactInput: {
    '& .bp3-input': {
      padding: `0 6px !important`,
      height: 25,
      textAlign: 'center' as 'center',
    },
  },
  fillWidth: {
    width: '100%',
  },
});

type PassedProps = {
  /**
   * If passed in, prevents user input and interaction
   */
  disabled?: boolean;
  /**
   * If passed in, causes the input to fill the width of its container
   */
  fillWidth?: boolean;
  /**
   * If passed in, the input will be shorter and have less padding on the sides within the
   * input.
   */
  isCompact?: boolean;
  /**
   * If passed in, the input will be positioned as if it had a label (with spacing above it)
   * but no words will be visible
   */
  useFakeLabel?: boolean;
  /**
   * If passed in, the cancel button and enter button on the right inside of the input
   * will not appear
   */
  hideRightIconInteractions?: boolean;
  initialValue?: string;
  onNewValueSubmitted: (newValue: string) => void;
  errorText?: string;
  placeholder?: string;
  leftIcon?: IconName | JSX.Element;
  containerClassName?: string;
  className?: string;
  label?: string;
  type?: string;
  helpText?: string;
};

type Props = WithStyles<typeof styles> & PassedProps;

type State = {
  value?: string;
  inputFocused: boolean;
};

class InputWithBlurSave extends Component<Props, State> {
  inputRef: HTMLInputElement | null = null;

  state: State = {
    value: this.props.initialValue,
    inputFocused: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({ value: this.props.initialValue });
      this.inputRef?.blur();
    }
  }

  render = () => {
    const { value } = this.state;
    const {
      className,
      containerClassName,
      disabled,
      initialValue,
      errorText,
      placeholder,
      onNewValueSubmitted,
      isCompact,
      classes,
      label,
      leftIcon,
      fillWidth,
      type,
      useFakeLabel,
      helpText,
    } = this.props;

    return (
      <div className={cx(containerClassName, { [classes.fillWidth]: fillWidth })}>
        {(label || useFakeLabel) && (
          <InputLabel text={label || ''} fakeLabel={useFakeLabel} helpText={helpText} />
        )}
        <InputGroup
          className={cx({ [classes.compactInput]: isCompact }, className)}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          intent={errorText ? Intent.DANGER : Intent.NONE}
          value={value}
          onFocus={() => {
            this.setState({ inputFocused: true });
          }}
          onBlur={() => {
            this.setState({ value: initialValue, inputFocused: false });
            onNewValueSubmitted(value || '');
          }}
          onKeyPress={this.onKeyPress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ value: e.target.value });
          }}
          leftIcon={leftIcon}
          rightElement={this.renderRightElement()}
          inputRef={(ref) => (this.inputRef = ref)}
        />
        {errorText && <InputLabel error text={errorText} />}
      </div>
    );
  };

  renderRightElement = () => {
    const { inputFocused } = this.state;
    const { onNewValueSubmitted, hideRightIconInteractions } = this.props;

    if (hideRightIconInteractions) return;

    const icon = inputFocused ? 'key-enter' : 'cross';
    const disabled = inputFocused;
    const onClick = inputFocused ? undefined : () => onNewValueSubmitted('');

    return <Button icon={icon} disabled={disabled} minimal onClick={onClick} />;
  };

  onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { onNewValueSubmitted } = this.props;
    const { value } = this.state;
    const code = event.keyCode || event.which;
    if (code === 13 && value) {
      onNewValueSubmitted(value || '');
    }
  };
}

export default withStyles(styles)(InputWithBlurSave);
