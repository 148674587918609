/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

import { Button, IButtonProps, IconName } from '@blueprintjs/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: theme.spacing(23),
    width: theme.spacing(23),
    borderRadius: 2,
    boxShadow: 'none !important',
    backgroundColor: `${theme.palette.ds.grey100} !important`,
    backgroundImage: 'none !important',
    '& svg': {
      height: `${theme.spacing(8)}px !important`,
      width: `${theme.spacing(8)}px !important`,
    },
    '&.bp3-button .bp3-icon, .bp3-button .bp3-icon-standard, .bp3-button .bp3-icon-large': {
      color: theme.palette.ds.grey700,
    },
  },
  selectedBtn: {
    backgroundColor: `${theme.palette.ds.grey700} !important`,
    '&.bp3-button .bp3-icon, .bp3-button .bp3-icon-standard, .bp3-button .bp3-icon-large': {
      color: theme.palette.ds.grey100,
    },
  },
  linkBtn: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

type PassedProps = {
  className?: string;
  btnIcon: IconName | JSX.Element;
  selected?: boolean;
  onClick?: () => void;
};

type Props = IButtonProps & PassedProps;

const IconButton = (props: Props) => {
  const classes = useStyles();
  const { selected, btnIcon, ...otherProps } = props;

  return (
    <Button
      {...otherProps}
      icon={btnIcon}
      className={cx(classes.root, props.className, { [classes.selectedBtn]: selected })}></Button>
  );
};

export default IconButton;
