/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';

import DropdownSelect from 'shared/DropdownSelect';

import { TableColumn } from 'actions/types';
import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(4),
    },
  });

type PassedProps = {
  label: string;
  schema: TableColumn[];
  selectedColumnName?: string;
  onSelect: (column: TableColumn) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class ColumnSelectionDropdown extends React.Component<Props> {
  render() {
    const { classes, label, schema, selectedColumnName, onSelect } = this.props;

    const schemaByName = _.indexBy(schema, 'name');
    const selectedColumn = selectedColumnName && schemaByName[selectedColumnName];

    return (
      <div className={classes.root}>
        <DropdownSelect
          label={label}
          selectedItem={
            selectedColumn
              ? {
                  name: selectedColumn.name,
                  id: selectedColumn.name,
                  icon: SCHEMA_DATA_TYPES_BY_ID[selectedColumn.type].icon,
                }
              : undefined
          }
          onChange={(item) => onSelect(schemaByName[item.name])}
          noSelectionText="Select column"
          options={schema.map((col) => ({
            name: col.name,
            id: col.name,
            icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
          }))}
          minimal
          fillWidth
          showIcon
        />{' '}
      </div>
    );
  }
}

export default withStyles(styles)(ColumnSelectionDropdown);
