/** @format */

import React from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';

import ToggleSwitchInput from 'shared/ToggleSwitchInput';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, VisualizeTableInstructions, SECTION_OPTIONS } from 'constants/types';
import SettingHeader from '../../DataConfigTab/SettingHeader';

const HEADER_SECTION_OPTIONS = [
  { name: '', id: SECTION_OPTIONS.BLANK },
  { name: 'Image', id: SECTION_OPTIONS.IMAGE },
  { name: 'Text', id: SECTION_OPTIONS.TEXT },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
  buttonGrouInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(1),
  },
}));

export type Props = {
  instructions: VisualizeTableInstructions;
  visualizationType: string;
};

export default function TablePDFConfig({ instructions, visualizationType }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const updateInstructions = (update: (draft: VisualizeTableInstructions) => void) => {
    const newInstructions = produce(instructions, update);

    dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
  };

  return (
    <>
      <SettingHeader name="PDF Download" />
      <div className={classes.root}>
        <ToggleSwitchInput
          className={classes.configInput}
          switchOn={instructions.pdfFormat?.headerEnabled}
          onChange={() =>
            updateInstructions((draft) => {
              draft.pdfFormat = {
                ...draft.pdfFormat,
                headerEnabled: !instructions.pdfFormat?.headerEnabled,
              };
            })
          }
          label="Header"
        />
        {instructions.pdfFormat?.headerEnabled && (
          <>
            <ToggleButtonGroup className={classes.buttonGrouInput} fillWidth label="Left">
              {HEADER_SECTION_OPTIONS.map((option) => (
                <ToggleButton
                  key={`left_section_${option.id}`}
                  active={
                    instructions.pdfFormat?.leftOption
                      ? instructions.pdfFormat?.leftOption === option.id
                      : option.id === SECTION_OPTIONS.BLANK
                  }
                  text={option.name}
                  onClick={() =>
                    updateInstructions((draft) => {
                      draft.pdfFormat = {
                        ...draft.pdfFormat,
                        leftOption: option.id,
                      };
                    })
                  }
                />
              ))}
            </ToggleButtonGroup>
            {instructions.pdfFormat?.leftOption &&
              instructions.pdfFormat?.leftOption !== SECTION_OPTIONS.BLANK && (
                <InputWithBlurSave
                  containerClassName={classes.configInput}
                  initialValue={instructions.pdfFormat?.leftContent || ''}
                  onNewValueSubmitted={(newValue: string) => {
                    updateInstructions((draft) => {
                      draft.pdfFormat = {
                        ...draft.pdfFormat,
                        leftContent: newValue,
                      };
                    });
                  }}
                />
              )}

            <ToggleButtonGroup className={classes.buttonGrouInput} fillWidth label="Center">
              {HEADER_SECTION_OPTIONS.map((option) => (
                <ToggleButton
                  key={`center_section_${option.id}`}
                  active={
                    instructions.pdfFormat?.centerOption
                      ? instructions.pdfFormat?.centerOption === option.id
                      : option.id === SECTION_OPTIONS.BLANK
                  }
                  text={option.name}
                  onClick={() =>
                    updateInstructions((draft) => {
                      draft.pdfFormat = {
                        ...draft.pdfFormat,
                        centerOption: option.id,
                      };
                    })
                  }
                />
              ))}
            </ToggleButtonGroup>
            {instructions.pdfFormat?.centerOption &&
              instructions.pdfFormat?.centerOption !== SECTION_OPTIONS.BLANK && (
                <InputWithBlurSave
                  containerClassName={classes.configInput}
                  initialValue={instructions.pdfFormat?.centerContent || ''}
                  onNewValueSubmitted={(newValue: string) => {
                    updateInstructions((draft) => {
                      draft.pdfFormat = {
                        ...draft.pdfFormat,
                        centerContent: newValue,
                      };
                    });
                  }}
                />
              )}

            <ToggleButtonGroup className={classes.buttonGrouInput} fillWidth label="Right">
              {HEADER_SECTION_OPTIONS.map((option) => (
                <ToggleButton
                  key={`right_section_${option.id}`}
                  active={
                    instructions.pdfFormat?.rightOption
                      ? instructions.pdfFormat?.rightOption === option.id
                      : option.id === SECTION_OPTIONS.BLANK
                  }
                  text={option.name}
                  onClick={() =>
                    updateInstructions((draft) => {
                      draft.pdfFormat = {
                        ...draft.pdfFormat,
                        rightOption: option.id,
                      };
                    })
                  }
                />
              ))}
            </ToggleButtonGroup>
            {instructions.pdfFormat?.rightOption &&
              instructions.pdfFormat?.rightOption !== SECTION_OPTIONS.BLANK && (
                <InputWithBlurSave
                  containerClassName={classes.configInput}
                  initialValue={instructions.pdfFormat?.rightContent || ''}
                  onNewValueSubmitted={(newValue: string) => {
                    updateInstructions((draft) => {
                      draft.pdfFormat = {
                        ...draft.pdfFormat,
                        rightContent: newValue,
                      };
                    });
                  }}
                />
              )}
          </>
        )}
      </div>
    </>
  );
}
