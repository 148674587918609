/** @format */

import React from 'react';
import _ from 'underscore';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';

import { DropdownDashboardElemConfig, DashboardVariable } from 'types/dashboardTypes';
import DashboardButtonGroupInput from 'components/dashboardButtonGroupInput';
import { Dataset } from 'actions/types';
import { constructOptions } from './dashboardDropdownElement';

const styles = () =>
  createStyles({
    root: {
      '& .bp3-button-group .bp3-button, .bp3-button-group .bp3-button.bp3-active, .bp3-button-group .bp3-button:hover': {
        zIndex: 0,
      },
    },
  });

type PassedProps = {
  config: DropdownDashboardElemConfig;
  value?: DashboardVariable;
  dashboardDatasets: { [datasetId: string]: Dataset };
  onNewValueSelect: (newvalue: DashboardVariable) => void;
  disabled?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  queryDataset?: Dataset;
  savedConfig?: DropdownDashboardElemConfig;
};

class DashboardSwitchElement extends React.Component<Props> {
  state: State = {};

  static getDerivedStateFromProps(props: Props, state: State): State {
    const { config, dashboardDatasets } = props;
    const { valuesConfig } = config;
    if (
      !state.queryDataset?._rows ||
      valuesConfig.queryTable?.id !== state.savedConfig?.valuesConfig.queryTable?.id ||
      valuesConfig.queryValueColumn?.id !== state.savedConfig?.valuesConfig.queryValueColumn?.id
    ) {
      return {
        queryDataset: valuesConfig.queryTable?.id
          ? dashboardDatasets[valuesConfig.queryTable.id]
          : undefined,
        savedConfig: config,
      };
    }

    return {};
  }

  render() {
    const { classes, config, disabled, dashboardDatasets, value, onNewValueSelect } = this.props;

    const options = constructOptions(config, dashboardDatasets);
    let selectedOption = _.find(options, (option) => option.value === value);

    if (value && !selectedOption) {
      selectedOption = { id: String(value), name: String(value) };
    }

    return (
      <div className={classes.root}>
        <DashboardButtonGroupInput
          disabled={disabled}
          fillWidth
          selectedItem={selectedOption}
          onChange={(item) => {
            onNewValueSelect(item?.value);
          }}
          options={options}
          label={config.label}
          useFakeLabel={config.label === ''}
          loading={this.state.queryDataset?._loading}
        />
      </div>
    );
  }
}

export default withStyles(styles)(DashboardSwitchElement);
