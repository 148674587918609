/** @format */
import React from 'react';
import { ButtonGroup, Icon, IconName } from '@blueprintjs/core';

import Button from 'shared/Button';
import InputLabel from 'shared/InputLabel';

type Props = {
  icons: (IconName | JSX.Element)[];
  active?: number;
  callbacks?: ((() => void) | undefined)[];
  fillWidth?: boolean;
  label?: string;
};

export default function IconButtonGroup({
  active,
  callbacks = [],
  fillWidth,
  icons,
  label,
}: Props) {
  return (
    <>
      {label && <InputLabel text={label} />}
      <ButtonGroup fill={fillWidth}>
        {icons.map((icon, idx) => (
          <Button
            bordered
            key={idx}
            type="secondary"
            active={idx === active}
            icon={<Icon icon={icon} />}
            onClick={callbacks[idx]}
          />
        ))}
      </ButtonGroup>
    </>
  );
}
