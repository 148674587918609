/** @format */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import Button from 'shared/Button';
import EditableVariableList from './EditableVariableList';
import RawVariableList from './RawVariableList';
import ManageCustomVariableModal from '../manageCustomVariableModal';

import {
  DashboardElement,
  DashboardVariableMap,
  DASHBOARD_ELEMENT_TYPES,
} from 'types/dashboardTypes';
import { filterDptsWithDrilldowns } from 'utils/dashboardUtils';
import { DashboardParam, DataPanelTemplate, Dataset, EndUserGroup } from 'actions/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100% + 3px)',
    overflow: 'auto',
  },
  sectionHeader: {
    width: '100%',
    backgroundColor: theme.palette.ds.white,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editModeBtn: {
    width: 100,
  },
  customVarBtn: {
    width: '100%',
    marginLeft: theme.spacing(3),
  },
}));

type Props = {
  variables: DashboardVariableMap;
  dashboardElements: DashboardElement[];
  dataPanels: DataPanelTemplate[];
  selectedUserGroupId?: number;
  updateUserGroup: (userGroupId?: number) => void;
  endUserGroups: EndUserGroup[];
  dashboardDatasets: Record<number, Dataset>;
  dashboardTemplateId: number;
  dashboardParams: Record<string, DashboardParam>;
};

export default function VariablesDebugger({
  variables,
  dashboardElements,
  selectedUserGroupId,
  updateUserGroup,
  endUserGroups,
  dashboardDatasets,
  dataPanels,
  dashboardTemplateId,
  dashboardParams,
}: Props) {
  const classes = useStyles();
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [isCustomVariableModalOpen, setIsCustomVariableModalOpen] = useState<boolean>(false);

  const allowedDashboardElementTypes = [
    DASHBOARD_ELEMENT_TYPES.DROPDOWN,
    DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN,
    DASHBOARD_ELEMENT_TYPES.MULTISELECT,
    DASHBOARD_ELEMENT_TYPES.DATEPICKER,
    DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER,
    DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH,
    DASHBOARD_ELEMENT_TYPES.SWITCH,
  ];
  const filteredDashboardElements =
    dashboardElements &&
    dashboardElements.filter((element) =>
      allowedDashboardElementTypes.includes(element.element_type as DASHBOARD_ELEMENT_TYPES),
    );

  return (
    <div className={classes.root}>
      <div className={classes.sectionHeader}>
        <Button
          className={classes.editModeBtn}
          text={inEditMode ? 'Preview' : 'Edit'}
          onClick={() => setInEditMode(!inEditMode)}
        />
        <Button
          className={classes.customVarBtn}
          text="Manage Custom Variables"
          onClick={() => setIsCustomVariableModalOpen(true)}
        />
      </div>
      {inEditMode ? (
        <EditableVariableList
          variables={variables}
          dashboardInputElements={filteredDashboardElements}
          selectedUserGroupId={selectedUserGroupId}
          updateUserGroup={updateUserGroup}
          endUserGroups={endUserGroups}
          dashboardDatasets={dashboardDatasets}
          dashboardParams={dashboardParams}
        />
      ) : (
        <RawVariableList
          variables={variables}
          dashboardInputElements={filteredDashboardElements}
          dataPanelsWithDrilldown={filterDptsWithDrilldowns(dataPanels)}
          selectedUserGroupId={selectedUserGroupId}
          endUserGroups={endUserGroups}
          dashboardParams={dashboardParams}
        />
      )}
      <ManageCustomVariableModal
        dashboardTemplateId={dashboardTemplateId}
        closeModal={() => setIsCustomVariableModalOpen(false)}
        isOpen={isCustomVariableModalOpen}
        onClose={() => setIsCustomVariableModalOpen(false)}
        dashboardParams={dashboardParams}
      />
    </div>
  );
}
