/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { NonIdealState, Spinner, Icon, IconName } from '@blueprintjs/core';

import { HEADER_HEIGHT } from 'pages/dashboardPage/DashboardDatasetView/DashboardDatasetView';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  calloutContainer: {
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
    padding: theme.spacing(2),

    '&.fullHeight': {
      height: '100%',
    },
  },
  callout: {
    height: '100%',
    overflow: 'auto',
  },
  cogIcon: {
    display: 'block',
  },
}));

type Props = {
  className?: string;
  loading?: boolean;
  fullHeight?: boolean;
  requiredVarsNotSet?: boolean;
};

export default function NeedsConfigurationPanel({
  className,
  fullHeight,
  loading,
  requiredVarsNotSet,
}: Props) {
  const classes = useStyles();

  let icon: IconName | JSX.Element = <Icon className={classes.cogIcon} icon="cog" iconSize={30} />;
  let title = 'Configure the chart to get started';

  if (loading) {
    icon = <Spinner size={30} />;
    title = '';
  } else if (requiredVarsNotSet) {
    icon = 'multi-select';
    title = 'A dropdown or datepicker is required to load this chart. Please select an option.';
  }

  return (
    <div
      className={cx(
        classes.calloutContainer,
        GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor,
        className,
        { fullHeight },
      )}>
      <NonIdealState className={classes.callout} icon={icon} title={title} />
    </div>
  );
}
