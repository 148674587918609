/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';
import { format } from 'utils/localizationUtils';
import { Icon, Spinner } from '@blueprintjs/core';
import _ from 'underscore';

import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';
import InformationIcon from 'pages/dashboardPage/DashboardDatasetView/Header/InformationIcon';
import ProgressBar from 'components/ProgressBar';

import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { V2KPIChartInstructions, OPERATION_TYPES, NoDataConfig } from 'constants/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { TableColumn } from 'actions/types';
import { formatValue } from './utils';
import { getCategoricalColors, GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

declare global {
  interface PointOptionsObject {
    custom: Record<string, boolean | number | string>;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      position: 'relative',

      '&.LEFT_ALIGN': {
        textAlign: 'left',
      },
      '&.RIGHT_ALIGN': {
        textAlign: 'right',
      },
    },
    title: {
      fontWeight: 600,
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(1),

      '&.LEFT_ALIGN': {
        justifyContent: 'flex-start',
      },
      '&.RIGHT_ALIGN': {
        justifyContent: 'flex-end',
      },
    },
    subtitle: {
      fontSize: 14,
      marginBottom: theme.spacing(1),
    },
    valueContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),

      '&.LEFT_ALIGN': {
        justifyContent: 'flex-start',
      },
      '&.RIGHT_ALIGN': {
        justifyContent: 'flex-end',
      },
    },
    value: {
      fontSize: '36px !important',
    },
    valueUnit: {
      marginLeft: theme.spacing(2),
      fontSize: '36px !important',
    },
    bold: {
      fontWeight: 600,
    },
    italic: {
      fontStyle: 'italic',
    },
    trend: {
      color: '#DB3737',
      fontWeight: 600,

      '&.positive': {
        color: '#0F9960',
      },
    },
    progressBar: {
      marginTop: theme.spacing(3),
    },
    intermediateLoadingSpinner: {
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
    loadingState: {
      marginTop: theme.spacing(3),
    },
  });

type PassedProps = {
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2KPIChartInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
  schema: TableColumn[];
  infoTooltipText?: string;
  operationType: OPERATION_TYPES;
  noDataInstructions?: NoDataConfig;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {};

class SingleNumberChart extends React.PureComponent<Props, State> {
  static contextType = GlobalStylesContext;
  context!: React.ContextType<typeof GlobalStylesContext>;

  instructionsReadyToDisplay = (instructions?: V2KPIChartInstructions) => {
    return !!(instructions && instructions.aggColumn?.column);
  };

  render() {
    const { instructions, loading, classes } = this.props;

    if (!this.instructionsReadyToDisplay(instructions)) {
      return <NeedsConfigurationPanel fullHeight loading={loading} />;
    }

    // TODO: Migrate this to use the NumberTrendTextPanel shared component
    // https://linear.app/explo/issue/PD-930/migrate-kpi-to-use-the-kpitrendtextpanel
    return (
      <div className={cx(classes.root, instructions?.generalFormat?.alignment)}>
        {this.renderTitle()}
        {this.renderSubtitle()}
        {this.renderNumberBody()}
      </div>
    );
  }

  renderNumberBody = () => {
    const { classes, loading, operationType } = this.props;

    if (loading) {
      return (
        <div className={classes.loadingState}>
          <Spinner size={30} />
        </div>
      );
    }

    if (this.isNoData()) {
      return this.renderEmptyValue();
    }

    return (
      <>
        {this.renderNumberValue()}
        {operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 && this.renderProgressBar()}
        {this.renderTrend()}
      </>
    );
  };

  renderProgressBar = () => {
    const { classes, previewData, schema, instructions } = this.props;
    if (schema?.length === 0 || !previewData) return;

    const value = this.getRawValue() * (instructions?.valueFormat?.multiplyFactor ?? 1);
    const denominator = instructions?.valueFormat?.progressGoal ?? value;

    return (
      <ProgressBar
        className={classes.progressBar}
        value={value / denominator}
        color={
          instructions?.valueFormat?.progressBarColor ||
          getCategoricalColors(this.context.globalStyleConfig)[0]
        }
      />
    );
  };
  renderTitle = () => {
    const { classes, instructions, infoTooltipText } = this.props;

    if (instructions?.generalFormat?.title) {
      return (
        <div
          className={cx(
            classes.title,
            instructions?.generalFormat?.alignment,
            GLOBAL_STYLE_CLASSNAMES.text.h3.base,
          )}>
          {instructions.generalFormat.title}
          {infoTooltipText && <InformationIcon infoTooltipText={infoTooltipText} />}
        </div>
      );
    }
  };

  renderSubtitle = () => {
    const { classes, instructions } = this.props;

    if (instructions?.generalFormat?.subtitle) {
      return (
        <div className={cx(classes.subtitle, GLOBAL_STYLE_CLASSNAMES.text.body.secondary)}>
          {instructions.generalFormat.subtitle}
        </div>
      );
    }
  };

  renderNumberValue = () => {
    const { classes, instructions } = this.props;
    const value = this.getNumberValue();

    return (
      <div
        className={cx(
          classes.valueContainer,
          instructions?.generalFormat?.alignment,
          GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont,
        )}>
        <span
          className={cx(
            classes.value,
            {
              [classes.bold]: instructions?.valueFormat?.bold,
              [classes.italic]: instructions?.valueFormat?.italic,
            },
            GLOBAL_STYLE_CLASSNAMES.text.body.primary,
          )}>
          {value}
        </span>
        <span className={cx(GLOBAL_STYLE_CLASSNAMES.text.body.primary, classes.valueUnit)}>
          {this.getUnits()}
        </span>
      </div>
    );
  };

  renderEmptyValue = () => {
    const { classes, instructions, noDataInstructions } = this.props;

    return (
      <div
        className={cx(
          classes.valueContainer,
          instructions?.generalFormat?.alignment,
          GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont,
        )}
        style={{ fontSize: noDataInstructions?.noDataFontSize || 36 }}>
        {noDataInstructions?.noDataText || 'No Data'}
      </div>
    );
  };

  getUnits = () => {
    const { operationType, instructions } = this.props;

    // If it is a progress bar but the number format is Percent, don't show the denomonator
    // because 30% / 100% doesn't make sense
    if (
      operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 &&
      instructions?.valueFormat?.numberFormat?.id !== V2_NUMBER_FORMATS.PERCENT.id
    ) {
      const denominator =
        instructions?.valueFormat?.progressGoal &&
        formatValue({
          value: instructions?.valueFormat?.progressGoal,
          decimalPlaces: instructions?.valueFormat?.decimalPlaces ?? 2,
          formatId: instructions?.valueFormat?.numberFormat?.id,
          hasCommas: true,
        });

      return denominator
        ? `/ ${denominator} ${instructions?.valueFormat?.units || ''}`
        : instructions?.valueFormat?.units;
    }

    return instructions?.valueFormat?.units;
  };

  getRawValue = () => {
    const { previewData, instructions, schema } = this.props;

    let numberColName = schema[0].name;
    if (instructions?.trendColumn?.column) {
      numberColName = schema[1].name;
    }
    return previewData[0][numberColName] as number;
  };

  isNoData = () => {
    const { previewData } = this.props;
    if (!previewData || previewData.length === 0) return true;

    const value = this.getRawValue();

    return !_.isNumber(value);
  };

  getNumberValue = () => {
    const { instructions } = this.props;

    const value = this.getRawValue();

    if (!_.isNumber(value)) return 'No data';

    return formatValue({
      value,
      decimalPlaces: instructions?.valueFormat?.decimalPlaces ?? 2,
      formatId: instructions?.valueFormat?.numberFormat?.id,
      multiplier: instructions?.valueFormat?.multiplyFactor,
      hasCommas: true,
      timeFormatId: instructions?.valueFormat?.timeFormat?.id,
      customTimeFormat: instructions?.valueFormat?.timeCustomerFormat,
    });
  };

  renderTrend = () => {
    const { classes, instructions } = this.props;
    const pctChange = this.getTrend();

    if (pctChange === undefined) return;

    const positiveChange = instructions?.trendFormat?.trendColorsReversed
      ? pctChange < 0
      : pctChange >= 0;

    return (
      <div className={cx(classes.trend, { positive: positiveChange })}>
        <Icon
          color={positiveChange ? '#0F9960' : '#DB3737'}
          icon={pctChange >= 0 ? 'caret-up' : 'caret-down'}
        />
        {instructions?.trendFormat?.isNumber
          ? format(',')(Math.abs(pctChange))
          : `${format('0.2f')(Math.abs(pctChange))}%`}{' '}
        {instructions?.trendFormat?.label}
      </div>
    );
  };

  getTrend = () => {
    const { previewData, instructions, schema } = this.props;
    if (instructions?.trendColumn?.column && schema && schema.length >= 2) {
      const numberColName = schema[1].name;
      if (previewData.length <= 1) return;
      const currVal = previewData[0][numberColName] as number;
      const prevVal = previewData[1][numberColName] as number;

      if (instructions.trendFormat?.isNumber) return currVal - prevVal;

      return (currVal / prevVal - 1) * 100;
    }
  };
}

export default withStyles(styles)(SingleNumberChart);
