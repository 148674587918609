/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Layout } from '@explo-tech/react-grid-layout';
import { connect } from 'react-redux';
import parse from 'url-parse';

import EditDashboardConfig from 'pages/dashboardPage/editDashboardConfig';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import AddDataPanelModal from 'pages/dashboardPage/addDataPanelModal';
import ElementConfigPanel from 'pages/dashboardPage/elementConfig/elementConfigPanel';
import DashboardEditBanner from 'pages/dashboardPage/dashboardEditBanner';
import DataPanelConfigMenuV1 from 'pages/dataPanelEditorPage/dataPanelConfigMenuV1';
import DataPanelConfigV2 from 'pages/dashboardPage/DataPanelConfigV2';
import DashboardDebugger from 'pages/dashboardPage/DashboardDebugger';

import ResizeableDatasetEditor from 'pages/dashboardPage/dashboardDatasetEditor/resizeableDatasetEditor';

import { ActionFn } from 'actions/actionUtils';
import {
  FetchDataPanelTemplateData,
  FetchDataPanelTemplateRowCountData,
  FetchDashboardDatasetPreviewData,
  DownloadDataPanelTemplateCsvData,
  ExportUrlResponse,
  PdfUrlResponse,
} from 'actions/responseTypes';
import {
  Dashboard,
  DataPanelTemplate,
  DashboardTemplate,
  Dataset,
  EndUserGroup,
  ShareData,
  DataSource,
  DashboardVersionInfo,
  DashboardParam,
  ParentSchema,
  DashboardVersion,
} from 'actions/types';
import {
  SetDPTLoadingArgs,
  UpdateElementConfigV2Args,
  duplicateDashboardItem,
} from 'actions/dashboardV2Actions';
import { OPERATION_TYPES, V2_VISUALIZATION_OPERATIONS } from 'constants/types';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { updateAdHocOperationInstructions } from 'actions/dataPanelTemplateAction';
import { GlobalStylesProvider } from 'globalStyles';
import { NAVBAR_HEIGHT } from 'components/pages/navbar';
import { ReduxState } from 'reducers/rootReducer';
import {
  DashboardElement,
  DashboardElementConfig,
  DashboardVariableMap,
  DASHBOARD_ELEMENT_TYPES,
  VIEW_MODE,
} from 'types/dashboardTypes';
import {
  fillVisualizeTableConfigToKeepCols,
  getUrlParamStringFromDashVars,
} from 'utils/dashboardUtils';
import { SIDE_PANE_WIDTH, SIDE_PANE_HEADER_HEIGHT } from 'components/SidePane';
import { INITIAL_DATASET_EDITOR_HEIGHT } from 'pages/dashboardPage/dashboardDatasetEditor/dashboardDatasetEditor';
import { datasetEditorNotShown } from 'constants/dataPanelEditorConstants';
import { saveShareLinkTitle } from 'actions/dashboardAction';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
      width: '100%',
    },
    bodyBellowBanner: {
      display: 'flex',
      height: `calc(100vh - ${NAVBAR_HEIGHT}px - ${SIDE_PANE_HEADER_HEIGHT}px)`,
    },
    mainPageBody: {
      width: `100%`,
      height: '100%',
      position: 'relative',

      '&.leftPaneOpen': {
        width: `calc(100% - ${SIDE_PANE_WIDTH}px)`,
      },
      '&.rightPaneOpen': {
        width: `calc(100% - ${SIDE_PANE_WIDTH}px)`,
      },
      '&.leftPaneOpen.rightPaneOpen': {
        width: `calc(100% - ${SIDE_PANE_WIDTH * 2}px)`,
      },
    },
    fullPageBody: {
      width: '100%',
    },
    dashboardPreview: {
      width: `100%`,
      height: `calc(100% - ${INITIAL_DATASET_EDITOR_HEIGHT}px)`,
    },
    fullSizeDashboard: {
      height: '100% !important',
    },
    pdfDashboard: {
      height: `calc(100% - ${SIDE_PANE_HEADER_HEIGHT}px) !important`,
      paddingLeft: '15%',
      paddingRight: '15%',
      overflowY: 'auto',
    },
    leftSide: {
      width: SIDE_PANE_WIDTH,
      height: `100%`,
      backgroundColor: theme.palette.ds.white,
    },
    leftSideHidden: {
      display: 'none',
    },

    rightSide: {
      width: SIDE_PANE_WIDTH,
      height: `100%`,
      backgroundColor: theme.palette.ds.white,
      zIndex: 2,
    },
    rightSideHidden: {
      display: 'none',
    },
  });

type PassedProps = {
  viewMode: VIEW_MODE;
  setViewMode: (viewMode: VIEW_MODE) => void;
  onEditClicked: () => void;
  onPreviewClicked: () => void;
  manageVersionsClicked: () => void;
  switchEditModeLoading?: boolean;
  dashboardVersionInfo: DashboardVersionInfo;
  createDataPanelLoading: boolean;
  onLayoutUpdated: (layout: Layout[]) => void;
  createDataPanel: (
    datasetId: string,
    name: string,
    newLayout: Layout[],
    containerId?: string,
    vizType?: OPERATION_TYPES,
    onSuccess?: () => void,
  ) => void;
  createDashboardElement?: (
    elemType: DASHBOARD_ELEMENT_TYPES,
    newLayout: Layout[],
    containerId?: string,
  ) => void;
  dashboard: DashboardTemplate | Dashboard;
  dataPanels: DataPanelTemplate[];
  headerTitle: string;
  dashboardParams: Record<string, DashboardParam>;
  dashboardLayout: Layout[];
  fetchDataPanelTemplate: ActionFn<FetchDataPanelTemplateData>;
  fetchSecondaryData: ActionFn<FetchDataPanelTemplateData>;
  fetchDataPanelRowCount: ActionFn<FetchDataPanelTemplateRowCountData>;
  fetchDatasetPreview?: ActionFn<FetchDashboardDatasetPreviewData>;
  fetchImageExportUrl: ActionFn<ExportUrlResponse>;
  fetchPdfExportUrl: ActionFn<ExportUrlResponse>;
  downloadDataPanelCsv: ActionFn<DownloadDataPanelTemplateCsvData>;
  downloadDataPanelPdf: ActionFn<PdfUrlResponse>;
  setDptLoading: (args: SetDPTLoadingArgs) => void;
  fetchShareData?: (
    endUserGroupId: number,
    password?: string,
    isStrictViewingMode?: boolean,
  ) => void;
  shareData?: ShareData;
  shareLinkLoading?: boolean;
  pdfExportUrlLoading?: boolean;
  imageExportUrlLoading?: boolean;
  selectDashboardElementToEdit?: (config: DashboardElementConfig) => void;
  selectDashboardDataPanelToEdit?: (dataPanelTemplate: DataPanelTemplate) => void;
  editableDashboard?: boolean;
  dashboardElements?: DashboardElement[];
  dashboardDatasets?: Record<number, Dataset>;
  editorDatasets?: Record<string, Dataset>;
  dashboardTemplateId: number;
  updateElementConfig?: (args: UpdateElementConfigV2Args) => void;
  deleteDashboardElement?: (elementId: string, elementType: string) => void;
  deleteDataPanelTemplate?: (dataPanelId: string) => void;
  endUserGroups: EndUserGroup[];
  teamDataSources: DataSource[];
  parentSchemas: ParentSchema[];
  schemaTablesMap: { [schemaId: string]: { [datasetId: string]: Dataset } };
  onReturnMostCurrentVersionClicked: () => void;
  allDashboardVersions?: DashboardVersion[];
  updateEditModeUrlHash: (viewMode?: VIEW_MODE) => void;
  width: number | null;
};

type Props = RouteComponentProps &
  WithStyles<typeof styles> &
  PassedProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type State = {
  editDashboardLayoutMode: boolean;
  addDataPanelModalOpen: boolean;
  addDataPanelNewLayout?: Layout[];
  addDataPanelContainerId?: string;
  newDashboardElementType?: string;
  selectedDashboardItem?: {
    elementType: string;
    elementId: string;
  };
  selectedUserGroupId?: number;
  confirmSwitchOnSwitch?: () => void;
  addDataPanelVizType?: OPERATION_TYPES;
  datasetEditorIsOpen: boolean;
  leftPaneIsOpen: boolean;
  rightPaneIsOpen: boolean;
  dashboardVars: DashboardVariableMap;
};

class EditDashboardPage extends React.Component<Props, State> {
  dashboardLayout: React.RefObject<typeof DashboardLayout>;
  dashboardPreview: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.dashboardLayout = React.createRef();
    this.dashboardPreview = React.createRef();

    this.state = {
      editDashboardLayoutMode: false,
      addDataPanelModalOpen: false,
      selectedUserGroupId: this.getInitialUserGroupId(props.endUserGroups),
      datasetEditorIsOpen: true,
      leftPaneIsOpen: true,
      rightPaneIsOpen: false,
      dashboardVars: {},
    };
  }

  getInitialUserGroupId = (endUserGroups: EndUserGroup[]) => {
    const { updateEditModeUrlHash } = this.props;
    const queryVars = parse(window.location.href, true).query;
    const parsedCustomerId = parseInt(queryVars.customer || '');

    if (
      (parsedCustomerId === 0 || parsedCustomerId) &&
      endUserGroups.find((eug) => eug.id === parsedCustomerId)
    ) {
      return parsedCustomerId;
    } else if (queryVars.customer && !parsedCustomerId) {
      // if the customer id provided is invalid or not a real customer, clear it from URL
      window.history.replaceState(null, 'Explo', '?');
    }

    updateEditModeUrlHash();
    return endUserGroups[0]?.id;
  };

  render() {
    const { classes, editableDashboard, viewMode, width } = this.props;
    const { leftPaneIsOpen, rightPaneIsOpen } = this.state;

    const isFullPageDashboard = !editableDashboard || viewMode === VIEW_MODE.PDF;

    return (
      <div
        className={classes.root}
        /* These two functions are used to remove the placeholder if the dragged element
           is not dropped onto the canvas */
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={() => {
          // @ts-ignore
          this.dashboardLayout.current?.removeDroppingPlaceholder();
        }}>
        {this.renderDashboardEditBanner()}
        <div className={classes.bodyBellowBanner}>
          {this.renderDashboardConfig(isFullPageDashboard)}
          <div
            className={cx(classes.mainPageBody, {
              leftPaneOpen: leftPaneIsOpen && !isFullPageDashboard,
              rightPaneOpen: rightPaneIsOpen && !isFullPageDashboard,
            })}>
            {this.renderDashboardPreview()}
            {width &&
              !isFullPageDashboard &&
              !datasetEditorNotShown(this.getDashboardWidth()) &&
              this.renderDatasetEditor()}
          </div>
          {this.renderDashboardDebugger(isFullPageDashboard)}
        </div>
        {this.renderAddDataPanelModal()}
      </div>
    );
  }

  renderDashboardEditBanner = () => {
    const {
      viewMode,
      setViewMode,
      endUserGroups,
      dashboardVersionInfo,
      onEditClicked,
      onPreviewClicked,
      switchEditModeLoading,
      manageVersionsClicked,
      headerTitle,
      onReturnMostCurrentVersionClicked,
      allDashboardVersions,
      editableDashboard,
    } = this.props;
    const { selectedUserGroupId, leftPaneIsOpen, rightPaneIsOpen } = this.state;

    return (
      <DashboardEditBanner
        dashboardVersionInfo={dashboardVersionInfo}
        onEditClicked={onEditClicked}
        onPreviewClicked={onPreviewClicked}
        manageVersionsClicked={manageVersionsClicked}
        viewMode={viewMode}
        setViewMode={setViewMode}
        inEditMode={editableDashboard}
        switchEditModeLoading={switchEditModeLoading}
        headerTitle={headerTitle}
        endUserGroups={endUserGroups}
        selectUserGroup={this.selectUserGroupView}
        selectedUserGroupId={selectedUserGroupId}
        onReturnMostCurrentVersionClicked={onReturnMostCurrentVersionClicked}
        allDashboardVersions={allDashboardVersions}
        leftPaneIsOpen={leftPaneIsOpen}
        rightPaneIsOpen={rightPaneIsOpen}
        toggleLeftPane={() => this.setState({ leftPaneIsOpen: !leftPaneIsOpen })}
        toggleRightPane={() => this.setState({ rightPaneIsOpen: !rightPaneIsOpen })}
      />
    );
  };

  selectUserGroupView = (userGroupId?: number) => {
    const { updateEditModeUrlHash } = this.props;
    this.setState({ selectedUserGroupId: userGroupId });

    window.history.replaceState(
      null,
      'Explo',
      getUrlParamStringFromDashVars({
        customer: userGroupId,
      }),
    );

    updateEditModeUrlHash();
  };

  fetchShareData = (password?: string, isStrictViewingMode?: boolean) => {
    const { fetchShareData } = this.props;
    const { selectedUserGroupId } = this.state;
    if (selectedUserGroupId) fetchShareData?.(selectedUserGroupId, password, isStrictViewingMode);
  };

  renderDashboardPreview = () => {
    const {
      classes,
      dataPanels,
      dashboard,
      onLayoutUpdated,
      fetchDataPanelTemplate,
      fetchSecondaryData,
      fetchDataPanelRowCount,
      fetchPdfExportUrl,
      fetchImageExportUrl,
      editableDashboard,
      createDashboardElement,
      downloadDataPanelCsv,
      downloadDataPanelPdf,
      dashboardElements,
      dashboardDatasets,
      shareData,
      shareLinkLoading,
      imageExportUrlLoading,
      pdfExportUrlLoading,
      fetchDatasetPreview,
      dashboardLayout,
      viewMode,
      dashboardVersionInfo,
      updateElementConfig,
      setDptLoading,
      globalStyleConfig,
      updateAdHocOperationInstructions,
      width,
    } = this.props;
    const {
      editDashboardLayoutMode,
      newDashboardElementType,
      selectedDashboardItem,
      selectedUserGroupId,
    } = this.state;

    return (
      <GlobalStylesProvider globalStyleConfig={globalStyleConfig}>
        {(globalStylesClassName) => (
          <div
            ref={this.dashboardPreview}
            className={cx(
              classes.dashboardPreview,
              {
                [classes.fullSizeDashboard]:
                  !editableDashboard ||
                  dashboardDatasets === undefined ||
                  (width && datasetEditorNotShown(this.getDashboardWidth())),
                [classes.pdfDashboard]: viewMode === VIEW_MODE.PDF,
              },
              globalStylesClassName,
            )}>
            <DashboardLayout
              isViewOnly={false}
              dashboardLayout={dashboardLayout}
              ref={this.dashboardLayout}
              dashboardDatasets={dashboardDatasets || {}}
              editBaseOn={
                editDashboardLayoutMode || (viewMode === VIEW_MODE.PDF && editableDashboard)
              }
              dashboardTemplate={dashboard}
              dataPanelTemplates={dataPanels}
              updateDashboardTemplateLayout={onLayoutUpdated}
              fetchDataPanelTemplate={fetchDataPanelTemplate}
              fetchSecondaryData={fetchSecondaryData}
              fetchDataPanelRowCount={fetchDataPanelRowCount}
              fetchDatasetPreview={fetchDatasetPreview}
              setDptLoading={setDptLoading}
              editableDashboard={editableDashboard}
              onCreateDataPanel={(
                newLayout: Layout[],
                visualizationType?: OPERATION_TYPES,
                containerId?: string,
              ) =>
                this.setState({
                  addDataPanelModalOpen: true,
                  addDataPanelNewLayout: newLayout,
                  addDataPanelVizType: visualizationType,
                  addDataPanelContainerId: containerId,
                })
              }
              onCreateNewDashboardElement={createDashboardElement}
              draggingElementType={newDashboardElementType}
              downloadDataPanelCsv={downloadDataPanelCsv}
              downloadDataPanelPdf={downloadDataPanelPdf}
              dashboardElements={dashboardElements}
              onDashboardItemSelect={this.onDashboardItemSelect}
              selectedDashboardItem={editableDashboard ? selectedDashboardItem : undefined}
              userGroupId={selectedUserGroupId}
              userGroupName={this.getSelectedUserGroup()}
              fetchShareData={this.fetchShareData}
              shareData={selectedUserGroupId ? shareData : undefined}
              shareLinkLoading={shareLinkLoading}
              imageExportUrlLoading={imageExportUrlLoading}
              pdfExportUrlLoading={pdfExportUrlLoading}
              fetchImageExportUrl={fetchImageExportUrl}
              fetchPdfExportUrl={fetchPdfExportUrl}
              onCloseConfigClicked={this.onCloseConfigClicked}
              dashboardVersionNumber={dashboardVersionInfo.version_number}
              updateAdHocOperationInstructions={updateAdHocOperationInstructions}
              updateElementConfig={updateElementConfig}
              viewMode={viewMode}
              onVariablesChange={(variables) => this.setState({ dashboardVars: variables })}
            />
          </div>
        )}
      </GlobalStylesProvider>
    );
  };

  getSelectedUserGroup = () => {
    const { endUserGroups } = this.props;
    const { selectedUserGroupId } = this.state;

    if (!selectedUserGroupId) return;

    const groupById = _.indexBy(endUserGroups, 'id');
    return groupById[selectedUserGroupId].name;
  };

  renderDashboardConfig = (isFullPageDashboard: boolean) => {
    const { classes, createDataPanelLoading, dashboard, width } = this.props;
    const { editDashboardLayoutMode, selectedDashboardItem, leftPaneIsOpen } = this.state;

    if (selectedDashboardItem)
      return (
        <div
          className={cx(classes.leftSide, {
            [classes.leftSideHidden]: isFullPageDashboard || !leftPaneIsOpen,
          })}>
          {this.renderElementConfigPanel()}
        </div>
      );

    return (
      <div
        className={cx(classes.leftSide, {
          [classes.leftSideHidden]: isFullPageDashboard || !leftPaneIsOpen,
        })}>
        <EditDashboardConfig
          createDataPanelLoading={createDataPanelLoading}
          editDashboardModeEnabled={editDashboardLayoutMode}
          toggleEditDashboardMode={() =>
            this.setState({ editDashboardLayoutMode: !editDashboardLayoutMode })
          }
          onCreateDataPanel={() => this.setState({ addDataPanelModalOpen: true })}
          onNewDashboardElementDrag={(elementType?: string) =>
            this.setState({ newDashboardElementType: elementType })
          }
          pageWidth={width}
          dashboardTemplate={dashboard as DashboardTemplate}
        />
      </div>
    );
  };

  renderDashboardDebugger = (isFullPageDashboard: boolean) => {
    const {
      classes,
      dashboardElements,
      dataPanels,
      dashboardDatasets,
      endUserGroups,
      dashboardTemplateId,
      dashboardParams,
    } = this.props;
    const {
      rightPaneIsOpen,
      selectedDashboardItem,
      dashboardVars,
      selectedUserGroupId,
    } = this.state;

    return (
      <div
        className={cx(classes.rightSide, {
          [classes.rightSideHidden]: isFullPageDashboard || !rightPaneIsOpen,
        })}>
        <DashboardDebugger
          dashboardTemplateId={dashboardTemplateId}
          isItemSelected={!!selectedDashboardItem}
          selectedDataPanel={this.getDataPanelIfSelected()}
          dataPanels={dataPanels}
          dashboardElements={dashboardElements || []}
          variables={dashboardVars}
          selectedUserGroupId={selectedUserGroupId}
          updateUserGroup={this.selectUserGroupView}
          endUserGroups={endUserGroups}
          dashboardDatasets={dashboardDatasets || {}}
          dashboardParams={dashboardParams}
        />
      </div>
    );
  };

  getDataPanelIfSelected = () => {
    const { dataPanels } = this.props;
    const { selectedDashboardItem } = this.state;

    if (
      selectedDashboardItem?.elementType === DASHBOARD_ELEMENT_TYPES.DATA_PANEL ||
      selectedDashboardItem?.elementType === DASHBOARD_ELEMENT_TYPES.DATA_TABLE
    ) {
      const panelsById = _.indexBy(dataPanels || [], 'id');
      return panelsById[selectedDashboardItem.elementId];
    }
  };

  renderDatasetEditor = () => {
    const {
      editableDashboard,
      editorDatasets,
      dashboardTemplateId,
      parentSchemas,
      schemaTablesMap,
      dataPanels,
    } = this.props;

    const {
      selectedDashboardItem,
      datasetEditorIsOpen,
      dashboardVars,
      selectedUserGroupId,
    } = this.state;

    if (!editorDatasets) return;

    return (
      <ResizeableDatasetEditor
        editorDatasets={editorDatasets}
        dashboardTemplateId={dashboardTemplateId}
        inEditMode={editableDashboard}
        parentSchemas={parentSchemas}
        schemaTablesMap={schemaTablesMap}
        dataPanels={dataPanels}
        selectedDashboardItem={selectedDashboardItem}
        resizePreview={(newPanelHeight: number) => {
          this.dashboardPreview.current &&
            (this.dashboardPreview.current.style.height = `calc(100% - ${newPanelHeight}px)`);
        }}
        pageWidth={this.getDashboardWidth()}
        isOpen={datasetEditorIsOpen}
        setIsOpen={(isOpen: boolean, newHeight: number) => {
          this.setState({ datasetEditorIsOpen: isOpen });
          this.dashboardPreview.current &&
            (this.dashboardPreview.current.style.height = `calc(100% - ${newHeight}px)`);
        }}
        dashboardVars={dashboardVars}
        selectedUserGroupId={selectedUserGroupId}
      />
    );
  };

  getDashboardWidth = () => {
    const { leftPaneIsOpen, rightPaneIsOpen } = this.state;
    const { width } = this.props;

    const leftPanelAddition = leftPaneIsOpen ? 0 : SIDE_PANE_WIDTH;
    const rightPanelAddition = rightPaneIsOpen ? -SIDE_PANE_WIDTH : 0;

    return (width || 0) + leftPanelAddition + rightPanelAddition;
  };

  renderElementConfigPanel = () => {
    const {
      dataPanels,
      dashboardElements,
      updateElementConfig,
      deleteDashboardElement,
      deleteDataPanelTemplate,
      editorDatasets,
      teamDataSources,
      duplicateDashboardItem,
      dashboard,
      dashboardDatasets,
    } = this.props;
    const { selectedDashboardItem, selectedUserGroupId } = this.state;
    if (!selectedDashboardItem) return;

    if (
      selectedDashboardItem.elementType === DASHBOARD_ELEMENT_TYPES.DATA_PANEL ||
      selectedDashboardItem.elementType === DASHBOARD_ELEMENT_TYPES.DATA_TABLE ||
      selectedDashboardItem.elementType === DASHBOARD_ELEMENT_TYPES.REPORT_BUILDER
    ) {
      const panelsById = _.indexBy(dataPanels || [], 'id');
      const dataPanelTemplate = panelsById[selectedDashboardItem.elementId];
      const dataset = editorDatasets && editorDatasets[dataPanelTemplate.table_id];

      if (V2_VISUALIZATION_OPERATIONS.indexOf(dataPanelTemplate.visualize_op.operation_type) > -1) {
        return (
          <DataPanelConfigV2
            unselectElement={() => this.setState({ selectedDashboardItem: undefined })}
            dataPanelTemplate={dataPanelTemplate}
            baseSchema={dataset?.schema || []}
            computedSchema={dataPanelTemplate._schema || []}
            dataPanelConfigState={{
              filterOperation: dataPanelTemplate.filter_op,
              pivotOperation: dataPanelTemplate.group_by_op,
              visualizeOperation: dataPanelTemplate.visualize_op,
              sortOperation: dataPanelTemplate.sort_op,
            }}
            visualizationType={dataPanelTemplate.visualize_op.operation_type}
            sourceTableName={dataset?.table_name}
            dashboardElements={dashboardElements}
            sourceType={
              _.find(
                teamDataSources,
                (dataSource) => dataSource.parent_schema_id === dataset?.parent_schema_id,
              )?.source_type
            }
            duplicateDataPanel={(dataPanelTemplate: DataPanelTemplate) => {
              duplicateDashboardItem({
                dashboardItem: dataPanelTemplate,
                itemType: DASHBOARD_ELEMENT_TYPES.DATA_PANEL,
                dashId: dashboard.id,
              });
            }}
            dashboardDatasets={dashboardDatasets || {}}
            existingDataPanelProvidedIds={_.compact(
              _.map(dataPanels || [], (dpt) =>
                dataPanelTemplate.id === dpt.id ? undefined : dpt.provided_id || dpt.id,
              ),
            )}
          />
        );
      }

      return (
        <DataPanelConfigMenuV1
          unselectElement={() => this.setState({ selectedDashboardItem: undefined })}
          dataPanelTemplate={dataPanelTemplate}
          baseSchema={dataset?.schema || []}
          computedSchema={dataPanelTemplate._schema || []}
          dataPanelConfigState={{
            filterOperation: dataPanelTemplate.filter_op,
            pivotOperation: dataPanelTemplate.group_by_op,
            visualizeOperation: dataPanelTemplate.visualize_op,
            sortOperation: dataPanelTemplate.sort_op,
          }}
          userGroupId={selectedUserGroupId}
          sourceTableName={dataset?.table_name}
          deleteDataPanelTemplate={() => {
            this.setState({ selectedDashboardItem: undefined });
            deleteDataPanelTemplate && deleteDataPanelTemplate(dataPanelTemplate.id);
          }}
        />
      );
    } else {
      const elementsById = _.indexBy(dashboardElements || [], 'id');
      const dashboardElement = elementsById[selectedDashboardItem.elementId];

      return (
        <ElementConfigPanel
          dashboard={dashboard as DashboardTemplate}
          dashboardElement={dashboardElement}
          datasets={editorDatasets || {}}
          unselectElement={() => this.setState({ selectedDashboardItem: undefined })}
          updateConfig={(config: DashboardElementConfig) =>
            updateElementConfig && updateElementConfig({ elementId: dashboardElement.id, config })
          }
          deleteElement={() => {
            this.setState({ selectedDashboardItem: undefined });
            deleteDashboardElement &&
              deleteDashboardElement(dashboardElement.id, dashboardElement.element_type);
          }}
          currElementNames={new Set(_.pluck(dashboardElements || [], 'name'))}
          duplicateElement={(dashboardElement: DashboardElement) => {
            duplicateDashboardItem({
              dashboardItem: dashboardElement,
              itemType: dashboardElement.element_type,
              dashId: dashboard.id,
            });
          }}
        />
      );
    }
  };

  renderAddDataPanelModal = () => {
    const { createDataPanel, createDataPanelLoading, dashboardTemplateId } = this.props;
    const {
      addDataPanelModalOpen,
      addDataPanelNewLayout,
      addDataPanelVizType,
      addDataPanelContainerId,
    } = this.state;

    if (!addDataPanelModalOpen) return;

    return (
      <AddDataPanelModal
        modalOpen={addDataPanelModalOpen}
        closeModal={() => this.setState({ addDataPanelModalOpen: false })}
        onCreate={(datasetId: string, name: string) => {
          if (!addDataPanelNewLayout) return;
          createDataPanel(
            datasetId,
            name,
            addDataPanelNewLayout,
            addDataPanelContainerId,
            addDataPanelVizType,
            () =>
              this.setState({
                addDataPanelModalOpen: false,
                addDataPanelNewLayout: undefined,
                addDataPanelContainerId: undefined,
              }),
          );
        }}
        createLoading={createDataPanelLoading}
        dashboardTemplateId={dashboardTemplateId}
      />
    );
  };

  onDashboardItemSelect = (type: string, id: string) => {
    const {
      selectDashboardDataPanelToEdit,
      selectDashboardElementToEdit,
      dashboardElements,
      dataPanels,
    } = this.props;
    const { selectedDashboardItem } = this.state;
    if (
      selectedDashboardItem &&
      selectedDashboardItem.elementId === id &&
      selectedDashboardItem.elementType === type
    )
      return;

    const elementsById = _.indexBy(dashboardElements || [], 'id');
    const dataPanelsById = _.indexBy(dataPanels || [], 'id');

    if (
      selectDashboardDataPanelToEdit &&
      type === DASHBOARD_ELEMENT_TYPES.DATA_PANEL &&
      dataPanelsById[id]
    ) {
      selectDashboardDataPanelToEdit(dataPanelsById[id]);
      this.setState({ selectedDashboardItem: { elementType: type, elementId: id } });
    } else if (selectDashboardElementToEdit && dashboardElements && elementsById[id]) {
      selectDashboardElementToEdit(elementsById[id].config);
      this.setState({ selectedDashboardItem: { elementType: type, elementId: id } });
    }
  };

  onCloseConfigClicked = () => {
    const { dataPanels, updateVisualizeOperation } = this.props;
    const { selectedDashboardItem } = this.state;
    if (!selectedDashboardItem) return;

    if (selectedDashboardItem.elementType === DASHBOARD_ELEMENT_TYPES.DATA_PANEL) {
      const panelsById = _.indexBy(dataPanels || [], 'id');
      const dataPanel = panelsById[selectedDashboardItem.elementId];
      if (
        dataPanel.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_TABLE &&
        dataPanel._schema
      ) {
        updateVisualizeOperation(
          {
            ...dataPanel.visualize_op.instructions.VISUALIZE_TABLE,
            changeSchemaList: fillVisualizeTableConfigToKeepCols(
              dataPanel.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList,
              dataPanel._schema,
            ),
          },
          OPERATION_TYPES.VISUALIZE_TABLE,
        );
      }
    }

    this.setState({ selectedDashboardItem: undefined });
  };
}

const mapStateToProps = (state: ReduxState) => ({
  oldDataPanelTemplate: state.editingDashboardElement.oldDataPanel,
  oldElemenConfig: state.editingDashboardElement.oldConfig,
  globalStyleConfig: state.styleConfig.current,
  allDashboardVersions: state.dashboardVersions.versions,
});

const mapDispatchToProps = {
  updateVisualizeOperation,
  updateAdHocOperationInstructions,
  duplicateDashboardItem,
  saveShareLinkTitle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(EditDashboardPage)));
