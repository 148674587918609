/** @format */

// French [fr]
import dayjs from 'dayjs';

const locale = {
  name: 'fr',
  weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin: 'di_lu_ma_me_je_ve_sa'.split('_'),
  months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
    '_',
  ),
  monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
  weekStart: 1,
  yearStart: 4,
  formats: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',

    // these are modified from their original definitions
    LLLL: 'DD-MM-YY',
    l: 'D MMM',
    llll: 'HH:00 D/M',
    'L LTS': 'DD/MM/YYYY HH:mm:ss',
  },
  relativeTime: {
    future: 'dans %s',
    past: 'il y a %s',
    s: 'quelques secondes',
    m: 'une minute',
    mm: '%d minutes',
    h: 'une heure',
    hh: '%d heures',
    d: 'un jour',
    dd: '%d jours',
    M: 'un mois',
    MM: '%d mois',
    y: 'un an',
    yy: '%d ans',
  },
  ordinal: (n) => {
    const o = n === 1 ? 'er' : '';
    return `${n}${o}`;
  },
};

dayjs.locale(locale, null, true);

const numbers = {
  decimal: ',',
  thousands: '\u00a0',
  grouping: [3],
  currency: ['', '\u00a0€'],
  percent: '\u202f%',
};

export default { locale, numbers };
