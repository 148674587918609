/** @format */

import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import rootReducer from 'reducers/rootReducer';
import initialState from './initialState';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exploAssets: any;
  }
}

export default () => {
  return configureStore<typeof rootReducer>({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.REACT_APP_ENVIRONMENT === 'development',
    // TODO (Austin): Fix errors such that we can safely use immutability and serializability middleware
    middleware: [thunk],
  });
};
