/** @format */
import { defineAPIAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';

export const { actionFn: saveAnalyticsConfig } = defineAPIAction(
  ACTION.SAVE_ANALYTICS_CONFIG,
  'teams',
  'save_analytics_config',
  'POST',
);
