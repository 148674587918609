/** @format */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import NavTabs from 'components/core/navTabs';
import QueryDebugger from './QueryDebugger';
import DataDebugger from './DataDebugger';
import VariablesDebugger from './VariablesDebugger';

import { DashboardParam, DataPanelTemplate, Dataset, EndUserGroup } from 'actions/types';
import { DashboardElement, DashboardVariableMap } from 'types/dashboardTypes';

const DEBUGGER_TABS = {
  QUERY: {
    id: 'QUERY',
    name: 'Query',
  },
  VARIABLES: {
    id: 'VARIABLES',
    name: 'Variables',
  },
  DATA: {
    id: 'DATA',
    name: 'Data',
  },
};

const TABS_HEIGHT = 40;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    borderLeft: `1px solid ${theme.palette.ds.grey400}`,
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
  },
  tabsMenu: {
    height: TABS_HEIGHT,
    flexBasis: 0,
    borderBottom: `1px solid ${theme.palette.ds.grey400}`,
  },
  tabBtn: {
    width: '100%',
  },
  tabBody: {
    height: `calc(100% - ${TABS_HEIGHT}px)`,
    width: '100%',
    overflow: 'hidden',
  },
}));

type Props = {
  isItemSelected?: boolean;
  selectedDataPanel?: DataPanelTemplate;
  variables: DashboardVariableMap;
  dataPanels: DataPanelTemplate[];
  dashboardElements: DashboardElement[];
  selectedUserGroupId?: number;
  updateUserGroup: (userGroupId?: number) => void;
  endUserGroups: EndUserGroup[];
  dashboardDatasets: Record<number, Dataset>;
  dashboardTemplateId: number;
  dashboardParams: Record<string, DashboardParam>;
};

export default function DashboardDebugger({
  isItemSelected,
  selectedDataPanel,
  variables,
  dashboardElements,
  selectedUserGroupId,
  updateUserGroup,
  endUserGroups,
  dashboardDatasets,
  dataPanels,
  dashboardTemplateId,
  dashboardParams,
}: Props) {
  const classes = useStyles();
  const [selectedTabId, setSelectedTabId] = useState<string>(DEBUGGER_TABS.QUERY.id);

  const noDataPanelSelected = !isItemSelected || !selectedDataPanel;

  return (
    <div className={classes.root}>
      <NavTabs
        className={classes.tabsMenu}
        tabClassName={classes.tabBtn}
        tabs={[DEBUGGER_TABS.QUERY, DEBUGGER_TABS.VARIABLES, DEBUGGER_TABS.DATA]}
        selectedTabId={selectedTabId}
        onTabSelect={(tabId) => setSelectedTabId(tabId)}
      />
      <div className={classes.tabBody}>
        {selectedTabId === DEBUGGER_TABS.QUERY.id && (
          <QueryDebugger dataPanel={selectedDataPanel} noDataPanelSelected={noDataPanelSelected} />
        )}

        {selectedTabId === DEBUGGER_TABS.VARIABLES.id && (
          <VariablesDebugger
            variables={variables}
            dataPanels={dataPanels}
            dashboardElements={dashboardElements}
            selectedUserGroupId={selectedUserGroupId}
            updateUserGroup={updateUserGroup}
            endUserGroups={endUserGroups}
            dashboardDatasets={dashboardDatasets}
            dashboardTemplateId={dashboardTemplateId}
            dashboardParams={dashboardParams}
          />
        )}
        {selectedTabId === DEBUGGER_TABS.DATA.id && (
          <DataDebugger dataPanel={selectedDataPanel} noDataPanelSelected={noDataPanelSelected} />
        )}
      </div>
    </div>
  );
}
