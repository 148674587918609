/** @format */

import React from 'react';
import Highcharts from 'highcharts';
import { dayjs } from 'utils/localizationUtils';
import ReactDOMServer from 'react-dom/server';

import HighCharts from './highCharts';
import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';

import {
  getColorColNames,
  xAxisFormat,
  formatLegend,
  formatLabel,
  getColorPalette,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  areRequiredVariablesSetTwoDimViz,
} from './utils';
import { LineElasticity, V2TwoDimensionChartInstructions } from 'constants/types';
import { DATE_TYPES } from 'constants/dataConstants';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { Dataset, TableColumn } from 'actions/types';
import { getColDisplayText } from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/vizConfigs/utils';
import { GlobalStyleConfig } from 'globalStyles/types';
import {
  createYAxisBaseTooltip,
  getMultiYAxisInstructions,
  getSingleYAxisInstructions,
  getValueFormat,
  getYAxisChartIndex,
} from './utils/multiYAxisUtils';
import { insertZeroesForMissingDateData } from './utils/DateUtils';
import { formatTwoDimensionalData } from 'dataFormatters/twoDimensionalDataFormatter';
declare global {
  interface PointOptionsObject {
    custom: Record<string, boolean | number | string>;
  }
}

type Props = {
  backgroundColor: string;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelTemplateId: string;
  canUseMultiYAxis?: boolean;
  variables: DashboardVariableMap;
  schema: TableColumn[];
  grouped?: boolean;
  horizontal?: boolean;
  area?: boolean;
  normalize?: boolean;
  globalStyleConfig: GlobalStyleConfig;
  dashboardDatasets: Record<string, Dataset>;
};

type State = {};

class LineChart extends React.PureComponent<Props, State> {
  getChartId = () => {
    return `pivotChartContainer${this.props.dataPanelTemplateId}`;
  };

  render() {
    const { instructions, loading, variables } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    if (loading || !isTwoDimVizInstructionsReadyToDisplay(instructions) || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }

    return <HighCharts chartOptions={this._spec()} />;
  }

  _spec = (): Highcharts.Options | undefined => {
    const {
      previewData,
      schema,
      instructions,
      area,
      normalize,
      backgroundColor,
      globalStyleConfig,
      canUseMultiYAxis,
      variables,
      dashboardDatasets,
    } = this.props;
    if (schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();
    const { valueFormatId, decimalPlaces } = getValueFormat(instructions?.yAxisFormats?.[0]);
    const showMarkers = !instructions?.chartSpecificFormat?.lineChart?.hideMarkers;
    const lineWidth = instructions?.chartSpecificFormat?.lineChart?.lineWidth || 2;

    return {
      chart: {
        type: this.getChartType(),
        zoomType: 'x',
        backgroundColor,
      },
      //@ts-ignore
      series: this.transformData(),
      title: {
        text: undefined,
      },
      colors: getColorPalette(
        globalStyleConfig,
        instructions?.colorFormat?.selectedPalette,
        instructions?.colorFormat?.customColors,
      ),
      plotOptions: {
        series: {
          lineWidth: lineWidth,
          animation: false,
        },
        spline: {
          marker: {
            enabled: showMarkers,
          },
        },
        areaspline: {
          stacking: this.getStacking(),
          marker: {
            enabled: showMarkers,
          },
        },

        line: {
          marker: {
            enabled: showMarkers,
          },
        },
        area: {
          stacking: this.getStacking(),
          marker: {
            enabled: showMarkers,
          },
        },
      },
      yAxis: canUseMultiYAxis
        ? getMultiYAxisInstructions(globalStyleConfig, instructions, variables, dashboardDatasets)
        : getSingleYAxisInstructions(globalStyleConfig, instructions, variables, dashboardDatasets),
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        type: this.getXAxisType(),
        crosshair: true,
        categories: this.getAxisCategories(),
        labels: {
          formatter: function () {
            return formatLabel(
              this.value,
              instructions?.categoryColumn?.column.type,
              instructions?.categoryColumn?.bucket?.id,
              instructions?.xAxisFormat?.dateFormat,
              instructions?.xAxisFormat?.stringFormat,
            );
          },
          style: getLabelStyle(globalStyleConfig, 'secondary'),
        },
      },
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
      },

      tooltip: {
        formatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            createYAxisBaseTooltip({
              tooltipFormatter: this,
              globalValueFormatId: valueFormatId,
              globalDecimalPlaces: decimalPlaces,
              globalStyleConfig: globalStyleConfig,
              instructions: instructions,
              includePercent: (area && normalize) || instructions?.tooltipFormat?.showPct,
            }),
          );
        },
        padding: 0,
        borderWidth: 0,
        borderRadius: 0,
        backgroundColor: '#FFFFFF00',
        shadow: false,
        useHTML: true,
        outside: true,
        followPointer: true,
      },
    };
  };

  getChartType = () => {
    const { area, instructions } = this.props;
    if (instructions?.chartSpecificFormat?.lineChart?.elasticity === LineElasticity.STRAIGHT) {
      return area ? 'area' : 'line';
    } else {
      return area ? 'areaspline' : 'spline';
    }
  };

  getStacking = () => {
    const { normalize } = this.props;

    if (normalize) {
      return 'percent';
    } else {
      return 'normal';
    }
  };

  getXAxisType = () => {
    const { instructions } = this.props;

    if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return 'datetime';
  };

  getXAxisColName = () => {
    const { schema } = this.props;

    return schema[0].name;
  };

  getYAxisColName = () => {
    const { schema } = this.props;
    return schema[1].name;
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;

    if (
      !previewData ||
      !DATE_TYPES.has(instructions?.categoryColumn?.column.type || '') ||
      !schema ||
      schema.length === 0
    )
      return;

    const xAxisColName = this.getXAxisColName();

    previewData.forEach((row) => {
      if (!instructions?.categoryColumn?.column.type) return;
      row[xAxisColName] = dayjs.utc(row[xAxisColName]).valueOf();
    });

    if (instructions?.chartSpecificFormat?.timeSeriesDataFormat?.zeroMissingDates) {
      const bucket = instructions?.categoryColumn?.bucket;
      if (!bucket) return;
      insertZeroesForMissingDateData(
        previewData,
        bucket,
        this.getXAxisColName(),
        this.getYAxisColName(),
      );
    }
  };

  getAxisCategories = () => {
    const { instructions, previewData } = this.props;
    if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return;

    const xAxisColName = this.getXAxisColName();
    const categories = new Set(previewData.map((row) => String(row[xAxisColName])));
    return Array.from(categories);
  };

  transformData = () => {
    const { instructions, schema } = this.props;

    if (
      !instructions?.aggColumns ||
      instructions.aggColumns.length === 0 ||
      !schema ||
      schema.length === 0
    )
      return [];

    if (instructions.colorColumn) return this.transformColorData(schema);

    return this.transformAggColsData(schema);
  };

  transformColorData = (schema: TableColumn[]) => {
    const { previewData, instructions } = this.props;
    const { xAxisColName, colorColName, aggColName } = getColorColNames(schema);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const series: Record<string, { name: string; type: string; data: any }> = {};

    previewData.forEach((row) => {
      if (isDate && (row[xAxisColName] === undefined || isNaN(row[xAxisColName] as number))) return;
      const colorCategory = formatLabel(
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        row[colorColName],
        instructions?.colorColumn?.column.type,
        instructions?.colorColumn?.bucket?.id,
      );
      const entry = isDate
        ? [row[xAxisColName], row[aggColName] as number]
        : {
            name: String(row[xAxisColName]),
            y: row[aggColName] as number,
          };
      if (series[colorCategory]) {
        series[colorCategory].data.push(entry);
      } else {
        series[colorCategory] = {
          type: this.getChartType(),
          name: colorCategory,
          data: [entry],
        };
      }
    });

    return Object.values(series);
  };

  transformAggColsData = (schema: TableColumn[]) => {
    const { instructions, previewData, canUseMultiYAxis } = this.props;
    const xAxisColName = schema[0].name;
    const aggCols = instructions?.aggColumns || [];
    const aggColNames = schema.map((col) => col.name).slice(1);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const series: Record<string, { name: string; type: string; data: any; yAxis: number }> = {};

    formatTwoDimensionalData(previewData, instructions).forEach((row) => {
      aggColNames.forEach((colName, index) => {
        if (isDate && (row[xAxisColName] === undefined || isNaN(row[xAxisColName] as number)))
          return;
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const aggCol = aggCols[index];
        const entry = isDate
          ? [row[xAxisColName], row[colName] as number]
          : {
              name: String(row[xAxisColName]),
              y: row[colName] as number,
            };
        if (series[colName]) {
          series[colName].data.push(entry);
        } else {
          series[colName] = {
            type: this.getChartType(),
            name: aggCol.column.friendly_name || getColDisplayText(aggCol) || colName,
            data: [entry],
            yAxis: getYAxisChartIndex(aggCol.yAxisFormatId, canUseMultiYAxis, instructions),
          };
        }
      });
    });

    return Object.values(series);
  };
}

export default LineChart;
