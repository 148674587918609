/** @format */

import React from 'react';
import _ from 'underscore';
import { ReduxState } from 'reducers/rootReducer';

import { Classes, FormGroup, Intent } from '@blueprintjs/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import cloneDeep from 'lodash/cloneDeep';

import ManageSchemasSection from 'pages/DataSourcesPage/ManageSchemasSection';
import { ParentSchema, DataSource, ACTION } from 'actions/types';
import { syncParentSchema } from 'actions/parentSchemaActions';
import Modal from 'components/core/Modal';
import { connect } from 'react-redux';
import Button from 'shared/Button';
import { AppToaster } from 'toaster';
import { createLoadingSelector } from 'reducers/api/selectors';

const useStyles = makeStyles((theme: Theme) => ({
  modalDescription: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  modalBody: {
    padding: theme.spacing(5),
    maxHeight: '45vh',
    overflowY: 'auto',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    height: theme.spacing(8),
    padding: `0px ${theme.spacing(5)}px`,
  },
  addIcon: {
    marginRight: `${theme.spacing(2)}px !important`,
  },
  addTextContainer: {
    margin: `0px ${theme.spacing(2)}px`,
  },
}));

type PassedProps = {
  modalOpen: boolean;
  closeModal: () => void;
  parentSchemas: ParentSchema[];
  dataSources: DataSource[];
};

type Props = PassedProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const ManageSchemasModal = ({
  parentSchemas,
  closeModal,
  dataSources,
  onSyncSchemasLoading,
  modalOpen,
  syncParentSchema,
}: Props) => {
  const [editorDataSources, setEditorDataSources] = React.useState([] as DataSource[]);
  const [editorSchema, setEditorSchema] = React.useState([] as ParentSchema[]);
  React.useEffect(() => {
    setEditorDataSources(cloneDeep(dataSources));
  }, [dataSources]);
  React.useEffect(() => {
    setEditorSchema(cloneDeep(parentSchemas));
  }, [parentSchemas]);

  const classes = useStyles();
  const saveDisabled = _.some(editorSchema, (schema) => !schema.name);

  const renderSchemaSection = (schema: ParentSchema) => {
    return (
      <ManageSchemasSection
        key={_.uniqueId(`droppable-schema-`)}
        schemaName={schema.name}
        onDelete={() => {
          const newSchemas = editorSchema.filter((s) => s.id !== schema.id);
          setEditorSchema(newSchemas);
        }}
        dataSources={_.where(editorDataSources, { parent_schema_id: schema.id })}
        onDefaultDataSourceSelected={(id) => {
          const newEditorDataSources = _.map(editorDataSources, (dataSource) => {
            if (dataSource.default && dataSource.parent_schema_id === schema.id) {
              dataSource.default = false;
            }
            if (dataSource.id === id) {
              dataSource.default = true;
            }
            return dataSource;
          });

          setEditorDataSources(newEditorDataSources);
        }}
        onSchemaNameChange={(val) => {
          schema.name = val;
          setEditorSchema([...editorSchema]);
        }}
      />
    );
  };

  const saveSchema = () => {
    if (editorSchema.length !== new Set(_.pluck(editorSchema, 'name')).size) {
      AppToaster.show({
        message: <div>Two schema cannot have the same name</div>,
        icon: 'error',
        timeout: 5000,
        intent: Intent.WARNING,
      });
      return;
    }

    syncParentSchema(
      {
        postData: { edited_schemas: editorSchema, edited_data_sources: editorDataSources },
      },
      () => {
        const updatedDefaults = _.pluck(
          _.filter(
            editorDataSources,
            (editorDataSource) =>
              editorDataSource.default &&
              _.some(
                dataSources,
                (dataSource) => dataSource.id === editorDataSource.id && !dataSource.default,
              ),
          ),
          'parent_schema_id',
        );
        if (updatedDefaults.length > 0) {
          AppToaster.show({
            message: (
              <div>
                The default data source for a schema has been updated.
                <br />
                <br />
                Consider resyncing source tables for the updated schema with the following links:
                <ul>
                  {updatedDefaults.map((id) => (
                    <li key={_.uniqueId(`sync-tables-list-`)}>
                      <a target="_blank" rel="noopener noreferrer" href={`/sync-tables/${id}`}>
                        {`https://app.explo.co/sync-tables/${id}`}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ),
            icon: 'info-sign',
            timeout: 10000,
            intent: Intent.SUCCESS,
          });
        }

        closeModal();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (e: any) => {
        AppToaster.show({
          message: <div>{e._error}</div>,
          icon: 'error',
          timeout: 5000,
          intent: Intent.DANGER,
        });
      },
    );
  };

  return (
    <Modal
      modalOpen={modalOpen}
      onClose={() => {
        if (onSyncSchemasLoading) {
          return;
        }
        setEditorDataSources(cloneDeep(dataSources));
        setEditorSchema(cloneDeep(parentSchemas));
        closeModal();
      }}
      title="Manage Schemas">
      <div className={classes.modalDescription}>
        Use schemas to group databases that can run the same dataset queries. Databases that you use
        distinctly should be in their own separate schema.
      </div>
      <FormGroup className={classes.modalBody}>
        {editorSchema.map((schema) => renderSchemaSection(schema))}
      </FormGroup>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.buttonContainer}
            type="primary"
            onClick={() => !onSyncSchemasLoading && !saveDisabled && saveSchema()}
            loading={onSyncSchemasLoading}
            disabled={onSyncSchemasLoading || saveDisabled}
            text="Save"
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  onSyncSchemasLoading: createLoadingSelector([ACTION.SYNC_PARENT_SCHEMA], false)(state),
});

const mapDispatchToProps = {
  syncParentSchema,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSchemasModal);
