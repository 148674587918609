/** @format */

import React, { useEffect, useContext, useState } from 'react';
import produce from 'immer';
import { Icon, Popover, Position } from '@blueprintjs/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import Button from 'shared/Button';
import { Schema, UserTransformedSchema } from 'constants/types';
import TransformationSettingsMenu from './TransformationSettingsMenu';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { shiftItemsInList } from 'utils/general';

const useStyles = makeStyles((theme: Theme) => ({
  selectorButton: {
    boxShadow: 'none !important',
    height: 32,

    '& .bp3-spinner .bp3-spinner-track': {
      stroke: theme.palette.ds.grey200,
    },
    '&.bp3-button': {
      '&:hover': {
        backgroundColor: theme.palette.ds.grey200,
      },
      '&:active': {
        backgroundColor: theme.palette.ds.grey200,
      },
    },
  },
  popoverContainer: {
    '& .bp3-popover.bp3-minimal': {
      margin: `${theme.spacing(1)}px 0px 0px !important`,
    },
    '& .bp3-popover': {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.ds.grey400}`,
    },
  },
}));

type Props = {
  loading?: boolean;
  schema: Schema;
  userTransformedSchema?: UserTransformedSchema;
  updateUserTransformedSchema: (newSchema: UserTransformedSchema) => void;
};

export default function TransformationSettingsButton({
  loading,
  schema,
  userTransformedSchema,
  updateUserTransformedSchema,
}: Props) {
  const classes = useStyles();
  const { dashboardLayoutTagId } = useContext(DashboardLayoutContext);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (schema.length && !userTransformedSchema) {
      const initializedUserTransformedSchema = schema.map((column) => ({
        ...column,
        isVisible: true,
      }));
      updateUserTransformedSchema(initializedUserTransformedSchema);
    }
  }, [schema, userTransformedSchema, updateUserTransformedSchema]);

  return (
    <Popover
      minimal
      portalClassName={classes.popoverContainer}
      content={
        userTransformedSchema && (
          <TransformationSettingsMenu
            moveRow={(dragIndex, hoverIndex) => {
              const newSchema = produce(userTransformedSchema, (draft) => {
                shiftItemsInList(draft, dragIndex, hoverIndex);
              });
              updateUserTransformedSchema(newSchema);
            }}
            schema={userTransformedSchema}
            updateSchema={updateUserTransformedSchema}
          />
        )
      }
      isOpen={isPopoverOpen}
      portalContainer={document.getElementById(dashboardLayoutTagId) ?? undefined}
      onClose={() => {
        setIsPopoverOpen(false);
      }}
      position={Position.BOTTOM_RIGHT}>
      <Button
        minimal
        active={isPopoverOpen}
        className={classes.selectorButton}
        disabled={loading}
        icon={<Icon icon="cog" className={GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color} />}
        onClick={() => {
          setIsPopoverOpen(true);
        }}
      />
    </Popover>
  );
}
