/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { cloneDeep } from 'lodash';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import DropdownSelect from 'shared/DropdownSelect';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';

import { DateRangePickerElemConfig, DEFAULT_DATE_RANGES } from 'types/dashboardTypes';

const styles = (theme: Theme) => ({
  root: {
    '& .bp3-button-group .bp3-button': {
      zIndex: 0,
    },
  },
  configInput: {
    color: theme.palette.ds.grey700,
    marginTop: theme.spacing(4),
  },
});

type PassedProps = {
  config: DateRangePickerElemConfig;
  updateConfig: (newConfig: DateRangePickerElemConfig) => void;
};

type Props = RouteComponentProps & WithStyles<typeof styles> & PassedProps;

type State = {};

class DateRangeElementConfigPanel extends React.Component<Props, State> {
  state: State = {};

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderLabelInput()}
        {this.renderDefaultValue()}
        {this.renderIncludePreselectedRangeDropdown()}
        {this.renderEnableClearingSwitch()}
      </div>
    );
  }

  renderLabelInput = () => {
    const { config, classes, updateConfig } = this.props;
    return (
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={config.label || ''}
        onNewValueSubmitted={(newValue: string) => {
          const newConfig = cloneDeep(config);
          newConfig.label = newValue;
          updateConfig(newConfig);
        }}
        placeholder=""
        label="Label"
      />
    );
  };

  renderDefaultValue = () => {
    const { classes, updateConfig, config } = this.props;
    return (
      <DropdownSelect
        containerClassName={classes.configInput}
        filterable={false}
        label="Default Range"
        showCancelBtn
        onCancelClick={() => {
          const newConfig = cloneDeep(config);
          newConfig.defaultDateRange = undefined;
          updateConfig(newConfig);
        }}
        selectedItem={
          config.defaultDateRange
            ? { name: config.defaultDateRange, id: config.defaultDateRange }
            : undefined
        }
        onChange={(newvalue) => {
          const newConfig = cloneDeep(config);
          newConfig.defaultDateRange = newvalue.name as DEFAULT_DATE_RANGES;
          updateConfig(newConfig);
        }}
        noSelectionText="Select a range"
        options={Object.values(DEFAULT_DATE_RANGES).map((range) => ({
          id: range,
          name: range,
        }))}
        minimal
        fillWidth
        showIcon
      />
    );
  };

  renderIncludePreselectedRangeDropdown = () => {
    const { config, classes, updateConfig } = this.props;

    return (
      <SwitchInput
        className={classes.configInput}
        switchOn={config.includeRangeDropdown}
        onChange={() => {
          const newConfig = cloneDeep(config);
          newConfig.includeRangeDropdown = !config.includeRangeDropdown;
          updateConfig(newConfig);
        }}
        label="Include pre-selected ranges dropdown"
      />
    );
  };

  renderEnableClearingSwitch = () => {
    const { config, classes, updateConfig } = this.props;

    return (
      <SwitchInput
        className={classes.configInput}
        switchOn={!config.disableCancel}
        onChange={() => {
          const newConfig = cloneDeep(config);
          newConfig.disableCancel = !config.disableCancel;
          updateConfig(newConfig);
        }}
        label="Enable clearing selection"
      />
    );
  };
}

export default withRouter(withStyles(styles)(DateRangeElementConfigPanel));
