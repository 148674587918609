/** @format */

import React from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/index';

import FullPageContentContainer from 'shared/FullPageContentContainer';
import ConnectStepByStep from '../ConnectStepByStep';
import ConnectFAQs from '../ConnectFAQs';
import { GettingStartedBody } from './GettingStarted';
import { EnterCredentialsBody, areCredentialsComplete } from './EnterCredentials';
import { SecurityConfigurationBody, isSecurityConfigComplete } from './SecurityConfiguration';
import PanelButton from 'shared/PanelButton';

import { ConnectDataSourceStep } from '../constants';
import { DBConnectionConfig } from '../types';
import { Intent } from '@blueprintjs/core';
import { integrationsInfo } from 'constants/dataSourceConstants';
import { ParentSchema, DataSource } from 'actions/types';

const dataSourceByType = _.indexBy(Object.values(integrationsInfo), 'source_type');

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    paddingBottom: 100,
  },
  sectionHeader: {
    fontWeight: 600,
    fontSize: 16,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(8),

    '&::first-child': {
      marginTop: 0,
    },
  },
}));

export type Props = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  onBackClicked: () => void;
  connectLoading?: boolean;
  testingConnWorked?: boolean;
  testingConnError?: string;
  testingConnLoading?: boolean;
  parentSchemas?: ParentSchema[];
  selectedSchema?: ParentSchema;
  selectedSchemaIsNew?: boolean;
  setSelectedSchema: (schema: ParentSchema, isNew?: boolean) => void;
  existingDataSources: DataSource[];
};

const ReviewConfiguration: React.FC<Props> = ({
  config,
  updateConfig,
  onNextClicked,
  onBackClicked,
  testingConnWorked,
  testingConnError,
  testingConnLoading,
  connectLoading,
  parentSchemas,
  selectedSchema,
  selectedSchemaIsNew,
  setSelectedSchema,
  existingDataSources,
}) => {
  const classes = useStyles();

  if (!config.selectedDataSource)
    return <div>Error: A data source type must be selected before proceeding</div>;

  const dataSource = config.selectedDataSource;

  const getBottomBannerConfig = () => {
    if (testingConnLoading || testingConnWorked === undefined) return;
    if (testingConnWorked)
      return {
        intent: Intent.SUCCESS,
        text: <b>We’ve tested your credentials, you’re ready to go!</b>,
      };
    else
      return {
        intent: Intent.DANGER,
        text: (
          <>
            <b>There was an error testing: </b>
            {testingConnError}
          </>
        ),
      };
  };

  return (
    <FullPageContentContainer
      headerTitle="Review"
      primaryActionConfig={{
        text: testingConnWorked ? 'Connect' : 'Test Connection',
        onClick: onNextClicked,
        loading: connectLoading || testingConnLoading,
        disabled:
          !config.name ||
          !config.providedId ||
          !selectedSchema ||
          !!config.providedIdError ||
          !areCredentialsComplete(dataSource, config.dataSourceConfig) ||
          !isSecurityConfigComplete(dataSource, config.dataSourceConfig),
      }}
      backBtnConfig={{
        onClick: onBackClicked,
      }}
      bottomBannerConfig={getBottomBannerConfig()}
      leftSideBarTopContent={<ConnectStepByStep currentStep={ConnectDataSourceStep.REVIEW} />}
      leftSideBarBottomContent={<ConnectFAQs />}
      bodyContent={
        <div className={classes.body}>
          <div className={classes.sectionHeader}>Getting Started</div>
          <GettingStartedBody
            config={config}
            updateConfig={updateConfig}
            parentSchemas={parentSchemas}
            selectedSchema={selectedSchema}
            selectedSchemaIsNew={selectedSchemaIsNew}
            setSelectedSchema={setSelectedSchema}
            existingDataSources={existingDataSources}
          />
          <div className={classes.sectionHeader}>Database Type</div>
          <PanelButton
            imgUrl={dataSourceByType[dataSource.type].datasourceIconImg}
            text={dataSource.name}
            selected={config.selectedDataSource?.name === dataSource.name}
            onClick={() => {
              return;
            }}
          />
          <div className={classes.sectionHeader}>Credentials</div>
          <EnterCredentialsBody config={config} updateConfig={updateConfig} />
          <div className={classes.sectionHeader}>Security</div>
          <SecurityConfigurationBody config={config} updateConfig={updateConfig} />
        </div>
      }
    />
  );
};

export default ReviewConfiguration;
