/** @format */

import React, { useContext } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import {
  Cell,
  Column,
  ColumnHeaderCell,
  SelectionModes,
  Table,
  TableLoadingOption,
} from '@blueprintjs/table';
import { GLOBAL_STYLE_CLASSNAMES, GlobalStylesContext } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { TABLE_ROW_HEIGHT } from 'components/dataTable/baseDataTable';

const LOADING_OPTIONS = [TableLoadingOption.CELLS, TableLoadingOption.COLUMN_HEADERS];

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    height: 277,
  },
  tableTheme: {
    '& .bp3-table-selection-enabled.bp3-table-column-headers .bp3-table-header': {
      cursor: 'pointer',
    },

    '& .bp3-table-quadrant': {
      backgroundColor: 'transparent',
    },

    '& .bp3-table-column-headers .bp3-table-header': {
      boxShadow: 'none',
    },
  },
  columnHeaderCell: (props: Props) => ({
    height: props.rowHeight || TABLE_ROW_HEIGHT,
  }),
}));

const styleContextStyles = makeStyles({
  table: {
    backgroundColor: (styleTheme: GlobalStyleConfig) => styleTheme.container.fill,
    '& .bp3-table-row-name': {
      backgroundColor: (styleTheme: GlobalStyleConfig) => styleTheme.container.fill,
    },

    '& .bp3-table-menu': {
      backgroundColor: (styleTheme: GlobalStyleConfig) => styleTheme.container.fill,
    },
  },
});

type Props = {
  className?: string;
  maxRows: number;
  rowHeight?: number;
  disableRowHeader?: boolean;
};

const LoadingDataTable = (props: Props) => {
  const classes = useStyles(props);
  const context = useContext(GlobalStylesContext);
  const styleContextClasses = styleContextStyles(context.globalStyleConfig);

  const cellRenderer = () => {
    return (
      <Cell
        className={cx(
          GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor,
          GLOBAL_STYLE_CLASSNAMES.text.body.primary,
        )}
      />
    );
  };

  const headers = Array.from(Array(25).keys());
  const columns = headers.map((headerName: number, index: number) => {
    return (
      <Column
        cellRenderer={cellRenderer}
        columnHeaderCellRenderer={() => (
          <ColumnHeaderCell
            name={headerName.toString()}
            className={cx(
              classes.columnHeaderCell,
              GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor,
              GLOBAL_STYLE_CLASSNAMES.text.body.primary,
            )}
          />
        )}
        key={index}
        name={headerName.toString()}
      />
    );
  });

  return (
    <div className={cx(classes.root, props.className)}>
      <Table
        className={cx(classes.tableTheme, styleContextClasses.table)}
        numRows={props.maxRows}
        enableRowHeader={!props.disableRowHeader}
        selectionModes={SelectionModes.ALL}
        loadingOptions={LOADING_OPTIONS}
        defaultRowHeight={props.rowHeight || TABLE_ROW_HEIGHT}>
        {columns}
      </Table>
    </div>
  );
};

export default LoadingDataTable;
