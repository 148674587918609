/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/index';
import { Icon, Popover, Position, Menu, MenuItem, MenuDivider } from '@blueprintjs/core';

import DroppedColumn from './DroppedColumn';

import { ConfigurableColumnInfo } from 'constants/types';
import { getColDisplayText, getGroupOptions } from './utils';
import { dayjs } from 'utils/localizationUtils';
import { DashboardElement } from 'types/dashboardTypes';

const useStyles = makeStyles((theme: Theme) => ({
  droppedColumn: {
    marginBottom: theme.spacing(1),
  },
  groupHeader: {
    '&..bp3-heading': {
      paddingLeft: 0,
      color: theme.palette.ds.grey800,
    },
  },
  menuItemOption: {
    alignItems: 'center',
  },
}));

export type Props = {
  column: ConfigurableColumnInfo;
  onColOptionChanged: (option: { id: string; name: string }) => void;
  onRemoveCol: () => void;
  disableEdits?: boolean;
  dashboardElements?: DashboardElement[];
};

export default function DroppedGroupingColumn({
  column,
  onColOptionChanged,
  onRemoveCol,
  disableEdits,
  dashboardElements,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const groupOptions = getGroupOptions(column, dashboardElements);
  const dateTime = dayjs();

  if (groupOptions.length === 0) {
    return (
      <DroppedColumn
        className={classes.droppedColumn}
        columnName={getColDisplayText(column)}
        icon="cross"
        onIconClick={onRemoveCol}
      />
    );
  }

  return (
    <div className={classes.droppedColumn}>
      <Popover
        fill
        minimal
        captureDismiss
        position={Position.BOTTOM_RIGHT}
        content={
          <Menu>
            {groupOptions.map((group) => (
              <React.Fragment key={_.uniqueId('dropped-column-groups')}>
                {group.title && (
                  <li className="bp3-menu-header">
                    <h6 className={cx('bp3-heading', classes.groupHeader)}>{group.title}</h6>
                  </li>
                )}
                {group.options.map((option) => (
                  <MenuItem
                    className={classes.menuItemOption}
                    icon={
                      (column.bucket?.id === option.id || column.agg?.id === option.id) && (
                        <Icon
                          icon="full-circle"
                          iconSize={6} // 6 is the size of the dot icon
                          color={theme.palette.ds.blue}
                        />
                      )
                    }
                    key={_.uniqueId('col-config-option')}
                    text={option.name}
                    onClick={() => !disableEdits && onColOptionChanged(option)}
                    disabled={disableEdits}
                    label={option.getExample?.(dateTime)}
                  />
                ))}
              </React.Fragment>
            ))}
            {groupOptions.length > 0 && <MenuDivider />}
            <MenuItem
              text="Remove"
              onClick={() => !disableEdits && onRemoveCol()}
              disabled={disableEdits}
            />
          </Menu>
        }>
        <DroppedColumn columnName={getColDisplayText(column)} icon="caret-down" />
      </Popover>
    </div>
  );
}
