/** @format */

import React from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';

import ToggleSwitchInput from 'shared/ToggleSwitchInput';

import TextAreaWithBlurSave from 'pages/dataPanelEditorPage/textAreaWithBlurSave';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, VisualizeTableInstructions } from 'constants/types';
import SettingHeader from '../../DataConfigTab/SettingHeader';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export type Props = {
  instructions: VisualizeTableInstructions;
  visualizationType: string;
};

export default function TableHeaderConfig({ instructions, visualizationType }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <SettingHeader
        name="Header"
        rightContent={
          <ToggleSwitchInput
            switchOn={!instructions.isHeaderHidden}
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isHeaderHidden = !draft.isHeaderHidden;
              });

              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
          />
        }
      />
      <div className={classes.root}>
        <ToggleSwitchInput
          className={classes.configInput}
          switchOn={!instructions.isFilteringDisabled}
          onChange={() => {
            const newInstructions = produce(instructions, (draft) => {
              draft.isFilteringDisabled = !draft.isFilteringDisabled;
            });

            dispatch(
              updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
            );
          }}
          label="Filtering"
        />
        <ToggleSwitchInput
          className={classes.configInput}
          switchOn={instructions.isSchemaCustomizationEnabled}
          onChange={() => {
            const newInstructions = produce(instructions, (draft) => {
              draft.isSchemaCustomizationEnabled = !draft.isSchemaCustomizationEnabled;
            });

            dispatch(
              updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
            );
          }}
          label="Editing columns"
        />
        <ToggleSwitchInput
          className={classes.configInput}
          switchOn={instructions.generalFormat?.showTooltip}
          onChange={() => {
            const newInstructions = produce(instructions, (draft) => {
              draft.generalFormat = {
                ...draft.generalFormat,
                showTooltip: !draft.generalFormat?.showTooltip,
              };
            });

            dispatch(
              updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
            );
          }}
          label="Show Information Tooltip"
        />
        {instructions.generalFormat?.showTooltip && (
          <TextAreaWithBlurSave
            containerClassName={classes.configInput}
            initialValue={instructions.generalFormat?.tooltipText || ''}
            onNewValueSubmitted={(newValue: string) => {
              const newInstructions = produce(instructions, (draft) => {
                draft.generalFormat = {
                  ...draft.generalFormat,
                  tooltipText: newValue,
                };
              });

              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            placeholder=""
            label="Tooltip Text"
          />
        )}
      </div>
    </>
  );
}
