/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/index';

import StepByStep from 'shared/StepByStep';

import { ConnectDataSourceStep, CONNECT_STEPS_ORDER } from './constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  header: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: theme.spacing(6),
    textAlign: 'right',
  },
}));

export type Props = {
  className?: string;
  currentStep: ConnectDataSourceStep;
};

const ConnectStepByStep: React.FC<Props> = ({ className, currentStep }) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.header}>Connecting your database</div>
      <StepByStep
        steps={CONNECT_STEPS_ORDER.map((step) => ({
          name: step,
        }))}
        currentStepIndex={CONNECT_STEPS_ORDER.indexOf(currentStep)}
      />
    </div>
  );
};

export default ConnectStepByStep;
