/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import EmbeddedDashboard from 'components/EmbeddedDashboard/EmbeddedDashboard';

type MatchParams = {
  dashboardId: string;
  userGroupToken: string;
  environment?: string;
};

type Props = RouteComponentProps<MatchParams>;
class IFrameDashboardPage extends React.Component<Props> {
  render() {
    const { match } = this.props;

    return (
      <EmbeddedDashboard
        type="iframe"
        dashboardEmbedId={match.params.dashboardId}
        userGroupToken={match.params.userGroupToken}
        environment={match.params.environment}
        isStrict={match.url.toUpperCase().endsWith('STRICT')}
      />
    );
  }
}

export default withRouter(IFrameDashboardPage);
