/** @format */

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Icon, Popover, Position } from '@blueprintjs/core';

import Button from 'shared/Button';
import FilterDropdownMenu from './FilterDropdownMenu';

import { TableColumn } from 'actions/types';
import { EMPTY_FILTER_CLAUSE } from 'constants/dataPanelEditorConstants';
import {
  FilterClause,
  FilterOperationInstructions,
  FilterOperatorType,
  FilterValueType,
  Schema,
} from 'constants/types';
import { isSingleFilterClauseIncomplete, listsAreDifferent } from 'utils/dataPanelConfigUtils';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';

const useStyles = makeStyles((theme: Theme) => ({
  popoverContainer: {
    '& .bp3-popover.bp3-minimal': {
      margin: `${theme.spacing(1)}px 0px 0px !important`,
    },
    '& .bp3-popover': {
      boxShadow: 'none',
      border: `2px solid ${theme.palette.ds.grey200}`,
    },
  },
  selectorButton: {
    boxShadow: 'none !important',
    height: 32,

    '& .bp3-spinner .bp3-spinner-track': {
      stroke: theme.palette.ds.grey100,
    },
    '&.bp3-button': {
      '&:hover': {
        backgroundColor: theme.palette.ds.grey200,
      },
      '&:active': {
        backgroundColor: theme.palette.ds.grey200,
      },
    },
  },
}));

type Props = {
  schema: Schema;
  onAdHocFilterInfoUpdate: (adHocFilterInfo: FilterOperationInstructions) => void;
  adHocFilterInfo?: FilterOperationInstructions;
  error?: boolean;
  loading?: boolean;
};

export default function FilterButton({
  adHocFilterInfo,
  onAdHocFilterInfoUpdate,
  schema,
  error,
  loading,
}: Props) {
  const { dashboardLayoutTagId } = useContext(DashboardLayoutContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [filterConfigs, setFilterConfigs] = useState<FilterClause[]>(
    adHocFilterInfo?.filterClauses?.length
      ? adHocFilterInfo.filterClauses
      : [
          {
            ...EMPTY_FILTER_CLAUSE,
          },
        ],
  );
  const classes = useStyles();

  const getValidFilters = useCallback(() => {
    return filterConfigs.filter((filter) => !isSingleFilterClauseIncomplete(filter));
  }, [filterConfigs]);

  useEffect(() => {
    const validFilters = getValidFilters();
    if (adHocFilterInfo && listsAreDifferent(adHocFilterInfo.filterClauses, validFilters)) {
      adHocFilterInfo.filterClauses = validFilters;
      onAdHocFilterInfoUpdate(adHocFilterInfo);
    }
  }, [filterConfigs, onAdHocFilterInfoUpdate, adHocFilterInfo, getValidFilters]);

  const onColumnSelect = (column: TableColumn, idx: number) => {
    setFilterConfigs((prevState) => {
      const newState = [...prevState];
      if (column.type !== prevState[idx].filterColumn?.type) {
        newState[idx] = { ...EMPTY_FILTER_CLAUSE, filterColumn: column };
      }
      newState[idx] = { ...newState[idx], filterColumn: column };
      return newState;
    });
  };
  const onOperatorSelect = (operator: FilterOperatorType, idx: number) => {
    setFilterConfigs((prevState) => {
      const newState = [...prevState];
      newState[idx] = { ...newState[idx], filterOperation: operator };
      return newState;
    });
  };
  const onFilterValueUpdate = (value: FilterValueType, idx: number) => {
    setFilterConfigs((prevState) => {
      const newState = [...prevState];
      newState[idx] = { ...newState[idx], filterValue: value };
      return newState;
    });
  };
  const addFilter = () => {
    setFilterConfigs((prevState) => [...prevState, { ...EMPTY_FILTER_CLAUSE }]);
  };
  const deleteFilter = (idx: number) => {
    setFilterConfigs((prevState) => {
      const newState = [...prevState];
      newState.splice(idx, 1);
      return newState;
    });
  };

  return (
    <Popover
      minimal
      isOpen={isDropdownOpen}
      portalContainer={document.getElementById(dashboardLayoutTagId) ?? undefined}
      disabled={!schema}
      portalClassName={classes.popoverContainer}
      position={Position.BOTTOM_RIGHT}
      modifiers={{
        preventOverflow: {
          enabled: false,
        },
      }}
      content={
        <FilterDropdownMenu
          filterConfigs={filterConfigs}
          onColumnSelect={onColumnSelect}
          onOperatorSelect={onOperatorSelect}
          onFilterValueUpdate={onFilterValueUpdate}
          addFilter={addFilter}
          deleteFilter={deleteFilter}
          schema={schema}
        />
      }
      onClose={(event) => {
        const e = event as typeof event & { target: { className: string[] } };
        if (e?.type === 'mousedown' && !e?.target.className.includes('react-datepicker')) {
          setIsDropdownOpen(false);
        }
      }}>
      <Button
        minimal
        disabled={loading || !schema || error}
        active={isDropdownOpen || getValidFilters().length > 0}
        className={classes.selectorButton}
        icon={<Icon icon="filter" className={GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color} />}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        text={getValidFilters().length || ''}
      />
    </Popover>
  );
}
