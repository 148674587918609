/** @format */

import { defineAPIAction } from 'actions/actionUtils';
import { ACTION } from './types';

export const { actionFn: fetchEndUserGroups } = defineAPIAction(
  ACTION.FETCH_END_USER_GROUPS,
  'end_user_groups',
  '',
  'GET',
);

export const { actionFn: fetchUserGroupInfo } = defineAPIAction(
  ACTION.FETCH_END_USER_GROUP_INFO,
  'end_user_groups',
  '',
  'GET',
);

export const { actionFn: addEndUserGroup } = defineAPIAction(
  ACTION.CREATE_END_USER_GROUP,
  'end_user_groups',
  '',
  'POST',
);

export const { actionFn: deleteEndUserGroup } = defineAPIAction(
  ACTION.DELETE_END_USER_GROUP,
  'end_user_groups',
  '',
  'DELETE',
);

export const { actionFn: editEndUserGroup } = defineAPIAction(
  ACTION.EDIT_END_USER_GROUP,
  'end_user_groups',
  'update_user_group',
  'POST',
);

export const { actionFn: getOrCreateEndUserGroup } = defineAPIAction(
  ACTION.GET_OR_CREATE_END_USER_GROUP,
  'embed/create_user_group',
  '',
  'POST',
);
