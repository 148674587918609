/** @format */

import React from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';

import ToggleSwitchInput from 'shared/ToggleSwitchInput';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, VisualizeTableInstructions } from 'constants/types';
import SettingHeader from '../../DataConfigTab/SettingHeader';

export type Props = {
  instructions: VisualizeTableInstructions;
  visualizationType: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  configContainer: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export default function TableFooterConfig({ instructions, visualizationType }: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <>
      <SettingHeader
        name="Footer"
        rightContent={
          <ToggleSwitchInput
            switchOn={!instructions.isFooterHidden}
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isFooterHidden = !draft.isFooterHidden;
              });

              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
          />
        }
      />
      <div className={classes.configContainer}>
        <ToggleSwitchInput
          className={classes.configInput}
          switchOn={!instructions.isDownloadButtonHidden}
          onChange={() => {
            const newInstructions = produce(instructions, (draft) => {
              draft.isDownloadButtonHidden = !draft.isDownloadButtonHidden;
            });

            dispatch(
              updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
            );
          }}
          label="Show download button"
        />
      </div>
    </>
  );
}
