/** @format */

import React from 'react';
import cx from 'classnames';
import produce from 'immer';
import { withStyles, createStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Theme, WithStyles } from '@material-ui/core/index';
import _ from 'underscore';
import { NonIdealState, Intent, Icon, Spinner } from '@blueprintjs/core';

import DataTable from 'pages/dashboardPage/DashboardDatasetView/DataTable';
import LineOrBarChart from 'pages/dashboardPage/lineOrBarChart';
import SingleNumberDisplay from 'pages/dashboardPage/singleNumberDisplay';
import ChoroplethMap from 'pages/dashboardPage/choroplethMap';
import FunnelChart from 'pages/dashboardPage/funnelChart';
import PieChart from 'pages/dashboardPage/pieChart';
import SingleNumberDisplayEmpty from 'pages/dashboardPage/singleNumberDisplayEmpty';
import Header from 'pages/dashboardPage/DashboardDatasetView/Header';
import HeatMap from 'pages/dashboardPage/charts/heatMap';
import HeatMapV2 from 'pages/dashboardPage/charts/heatMapV2';
import FunnelChartV2 from 'pages/dashboardPage/charts/funnelChart';
import PivotChart from 'pages/dashboardPage/charts/pivotChart';
import BarChart from 'pages/dashboardPage/charts/barChart';
import PieChartV2 from 'pages/dashboardPage/charts/pieChart';
import LineChart from 'pages/dashboardPage/charts/lineChart';
import SingleNumberDisplayV2 from 'pages/dashboardPage/charts/singleNumberChart';
import BoxPlotV2 from 'pages/dashboardPage/charts/boxPlot';
import NumberTrend from 'pages/dashboardPage/charts/numberTrend';

import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';
import { isDataPanelReadyToCompute } from 'utils/dataPanelConfigUtils';

import {
  OP_TYPE_TO_BP3_ICON,
  NUMBER_FORMATS,
  DEFAULT_CHART_VIEW,
  VIZ_OP_WITH_CSV_DOWNLOAD,
} from 'constants/dataConstants';
import {
  FilterOperationInstructions,
  OPERATION_TYPES,
  VISUALIZATION_OPERATIONS,
  Schema,
  REPORTED_ANALYTIC_ACTION_TYPES,
  REGION_TYPES,
  UserTransformedSchema,
} from 'constants/types';
import { VisualizeOperation, AdHocOperationInstructions, TableRow, Dataset } from 'actions/types';
import { isMultiAxis, getInformationTooltipInfo } from 'utils/graphUtils';
import Button from 'shared/Button';
import { LEGEND_LOCATIONS, PIE_CHART_TYPES } from 'constants/dataConstants';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DashboardVariableMap, DashboardVariable } from 'types/dashboardTypes';
import { parseErrorMessage } from 'utils/queryUtils';
import { getChangedSchema } from 'utils/dashboardUtils';
import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { CAN_USE_MULTI_Y_AXIS_OPS } from '../charts/utils/multiYAxisUtils';
import { DownloadChartInfo } from 'components/DashboardLayout/DashboardLayout';
import DownloadCsvButton from './DataTable/DownloadCsvButton';
import ReportBuilder from './ReportBuilder';
import DataTableHeader from './Header/DataTableHeader';

export const HEADER_HEIGHT = 63;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      height: '100%',
      '&:hover .downloadCSVChartIcon': {
        display: 'inherit',
      },
      overflow: 'hidden',
    },
    kpiContainer: {
      paddingLeft: `4px !important`,
      paddingRight: `4px !important`,
    },
    operationName: {
      marginRight: theme.spacing(2),
    },
    datasetActions: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(2),
    },
    chartContainer: {
      paddingTop: 0,
      height: `calc(100% - ${HEADER_HEIGHT}px)`,
      width: '100%',
    },
    fullHeightChartContainer: {
      height: `100%`,
      width: '100%',
      overflow: 'hidden',
    },
    downloadCsvButton: {
      display: 'none',
      opacity: 0.5,
      position: 'absolute',
      top: theme.spacing(5),
      right: theme.spacing(4),
    },
    noDataContainer: {
      height: `calc(100% - ${HEADER_HEIGHT}px - 20px)`, //20px to account for header padding
      padding: theme.spacing(2),
    },
    fullSizeNeedsConfig: {
      height: '100%',
    },
    noDataFullContainer: {
      height: '100%',
      padding: theme.spacing(2),
    },
    emptyIcon: {
      display: 'block',
      height: 30,
    },
    noDataText: {
      fontSize: 18,
    },
    errorContainer: {
      height: `calc(100% - ${HEADER_HEIGHT}px)`,
      padding: theme.spacing(2),
    },
    errorCallout: {
      height: '100%',
      overflow: 'auto',

      '& .bp3-non-ideal-state-visual': {
        height: 50,
      },
    },
    noDataCallout: (props: PassedProps) => ({
      height: '100%',
      overflow: 'auto',

      '& .bp3-non-ideal-state-visual': {
        height: 50,
      },

      '& .bp3-heading': {
        fontSize: props.visualizeOperation.generalFormatOptions?.noDataState?.noDataFontSize || 20,
        fontWeight: 'unset',
      },
    }),
    chartNoPadding: {
      padding: 0,
    },
  });

type PassedProps = {
  adHocOperationInstructions: AdHocOperationInstructions;
  analyticsEventTracker?: (
    eventName: REPORTED_ANALYTIC_ACTION_TYPES,
    metaData?: Record<string, string | number>,
  ) => void;
  canDownloadDataPanel: boolean;
  clearDownloadCsvInfo?: () => void;
  dataPanelTemplateId: string;
  dataPanelProvidedId: string;
  downloadDataPanelCsvErrored?: boolean;
  downloadDataPanelCsvLink?: string;
  downloadDatapanelCsvPopupBlocked?: boolean;
  downloadCsvLoading?: boolean;
  editBaseOn?: boolean;
  error?: string;
  isInContainer?: boolean;
  isSelected?: boolean;
  isViewOnly: boolean;
  loading?: boolean;
  secondaryDataLoading?: boolean;
  onAdHocOperationInstructionsUpdated: (
    dptId: string,
    adHocInstructions: AdHocOperationInstructions,
    skipRowCount?: boolean,
  ) => void;
  panelName: string;
  previewData?: Record<string, string | number>[];
  rowError?: boolean;
  schema: Schema;
  secondaryData: TableRow[];
  onDownloadPanelCsv: (userTransformedSchema?: UserTransformedSchema) => void;
  onDownloadPanelPdf: (
    adHocOperationInstructions: AdHocOperationInstructions,
    userTransformedSchema?: UserTransformedSchema,
    reportName?: string,
  ) => void;
  downloadChartInfo?: DownloadChartInfo;
  sourceDataRowCount?: number;
  variables: DashboardVariableMap;
  visualizeOperation: VisualizeOperation;
  defaultUserTransformedSchema?: UserTransformedSchema;
  setVariable?: (value: DashboardVariable) => void;
  dashboardDatasets: Record<string, Dataset>;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  PassedProps &
  WithStyles<typeof styles>;

/**
 * TODO: We should create a DataTable component that is entirely self contained (its own loading and error logic)
 * to maintain this state
 */
type State = {
  userTransformedSchema: UserTransformedSchema;
};

/**
 * TODO: We should investigate bringing loading state logic out from individual charts so that changes to this logic
 * don't need to be copied to every single chart.
 */
class DashboardDatasetView extends React.Component<Props, State> {
  static contextType = GlobalStylesContext;
  context!: React.ContextType<typeof GlobalStylesContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      userTransformedSchema:
        props.defaultUserTransformedSchema ||
        getChangedSchema(props.schema, props.visualizeOperation.instructions.VISUALIZE_TABLE).map(
          (column) => ({
            ...column,
            isVisible: true,
          }),
        ),
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.state.userTransformedSchema?.length) {
      this.setState({
        userTransformedSchema: getChangedSchema(
          prevProps.schema,
          prevProps.visualizeOperation.instructions.VISUALIZE_TABLE,
        ).map((column) => ({ ...column, isVisible: true })),
      });
    }
  }

  render() {
    const { classes, visualizeOperation, isInContainer } = this.props;

    return (
      <div
        className={cx(
          classes.root,
          GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadius,
          {
            [cx(GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor, {
              [GLOBAL_STYLE_CLASSNAMES.container.padding.default.padding]: !isInContainer,
            })]: ![
              OPERATION_TYPES.VISUALIZE_TABLE,
              OPERATION_TYPES.VISUALIZE_REPORT_BUILDER,
            ].includes(visualizeOperation.operation_type),
            [GLOBAL_STYLE_CLASSNAMES.container.outline.border]:
              !isInContainer ||
              visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_TABLE,
            [GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow]: !isInContainer,
            [classes.kpiContainer]: this.isKpiNumberOp(),
          },
        )}>
        {this.renderHeader()}
        {this.renderChartOrTable()}
        {this.renderCSVButton()}
      </div>
    );
  }

  isKpiNumberOp = () => {
    const { visualizeOperation } = this.props;
    return (
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
      (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 &&
        visualizeOperation.instructions.V2_KPI_TREND?.hideTrendLines)
    );
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    // these functions are defined as closures and not bound functions, meaning that they are
    // redefined every render. Thus, we must omit them to prevent unnecessary renders when the
    // underlying data for the chart hasn't changed. Long term, we should make DashboardLayout
    // a functional component to access the benefits of the useCallback() hook
    const relevantDataNext = _.omit(
      nextProps,
      'onDownloadPanelCsv',
      'clearDownloadCsvInfo',
      'analyticsEventTracker',
    );
    const relevantDataThis = _.omit(
      this.props,
      'onDownloadPanelCsv',
      'clearDownloadCsvInfo',
      'analyticsEventTracker',
    );
    return (
      !_.isEqual(relevantDataNext, relevantDataThis) ||
      !_.isEqual(this.state.userTransformedSchema, nextState.userTransformedSchema)
    );
  }

  renderChartOrTable = () => {
    const {
      adHocOperationInstructions,
      analyticsEventTracker,
      error,
      loading,
      secondaryDataLoading,
      previewData,
      rowError,
      schema,
      sourceDataRowCount,
      visualizeOperation,
      updateVisualizeOperation,
      isSelected = false,
      classes,
      dataPanelTemplateId,
      canDownloadDataPanel,
      onDownloadPanelCsv,
      onDownloadPanelPdf,
      variables,
      panelName,
      isViewOnly,
      secondaryData,
      downloadChartInfo,
      setVariable,
      dataPanelProvidedId,
      dashboardDatasets,
    } = this.props;

    const v2ChartClass = !panelName ? classes.fullHeightChartContainer : classes.chartContainer;
    const backgroundColor = this.context.globalStyleConfig.container.fill;

    if (error) {
      return this.renderErrorState();
    }

    const isNumberTrendAgg =
      visualizeOperation.operation_type !== OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 ||
      !visualizeOperation.instructions.V2_KPI_TREND?.hideTrendLines;

    if (
      !loading &&
      previewData &&
      previewData.length === 0 &&
      visualizeOperation.operation_type !== OPERATION_TYPES.VISUALIZE_PROGRESS_V2 &&
      isNumberTrendAgg
    ) {
      return this.renderNoDataBody();
    }

    // V1 Charts
    if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_LINE_CHART) {
      return this.renderV1LineOrBarChart('line');
    } else if (
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_BAR_CHART ||
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_COMBO_CHART
    ) {
      return this.renderV1LineOrBarChart('bar');
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_HORIZ_BAR_CHART) {
      return this.renderV1LineOrBarChart('horizontalBar');
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER) {
      return this.renderNumber();
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP) {
      return this.renderChoroplethMap();
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_FUNNEL) {
      return this.renderFunnelChart();
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_PIE_CHART) {
      return this.renderPieChart();
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_PIVOT_CHART) {
      return (
        <div className={classes.chartContainer}>
          <PivotChart
            loading={loading}
            previewData={previewData || []}
            instructions={visualizeOperation.instructions.VISUALIZE_PIVOT_CHART}
            dataPanelTemplateId={dataPanelTemplateId}
            variables={variables}
          />
        </div>
      );
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_HEAT_MAP) {
      return (
        <div className={classes.chartContainer}>
          <HeatMap
            loading={loading}
            previewData={previewData || []}
            instructions={visualizeOperation.instructions.VISUALIZE_HEAT_MAP}
            dataPanelTemplateId={dataPanelTemplateId}
          />
        </div>
      );
    }
    // V2 Charts
    else if (
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2 ||
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2 ||
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2 ||
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2 ||
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2 ||
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2
    ) {
      return (
        <div className={v2ChartClass}>
          <BarChart
            globalStyleConfig={this.context.globalStyleConfig}
            backgroundColor={backgroundColor}
            loading={loading}
            previewData={previewData || []}
            instructions={visualizeOperation.instructions.V2_TWO_DIMENSION_CHART}
            dataPanelTemplateId={dataPanelTemplateId}
            variables={variables}
            dashboardDatasets={dashboardDatasets}
            schema={schema}
            normalize={
              visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2 ||
              visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2
            }
            grouped={
              visualizeOperation.operation_type ===
                OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2 ||
              visualizeOperation.operation_type ===
                OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2
            }
            horizontal={
              visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2 ||
              visualizeOperation.operation_type ===
                OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2 ||
              visualizeOperation.operation_type ===
                OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2
            }
            canUseMultiYAxis={CAN_USE_MULTI_Y_AXIS_OPS.has(visualizeOperation.operation_type)}
            dataPanelProvidedId={dataPanelProvidedId}
            setVariable={setVariable}
          />
        </div>
      );
    } else if (
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_PIE_CHART_V2 ||
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2
    ) {
      return (
        <div className={v2ChartClass}>
          <PieChartV2
            globalStyleConfig={this.context.globalStyleConfig}
            backgroundColor={backgroundColor}
            loading={loading}
            previewData={previewData || []}
            instructions={visualizeOperation.instructions.V2_TWO_DIMENSION_CHART}
            dataPanelTemplateId={dataPanelTemplateId}
            variables={variables}
            schema={schema}
            donut={visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2}
          />
        </div>
      );
    } else if (
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_LINE_CHART_V2 ||
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_AREA_CHART_V2 ||
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2
    ) {
      return (
        <div className={v2ChartClass}>
          <LineChart
            globalStyleConfig={this.context.globalStyleConfig}
            backgroundColor={backgroundColor}
            loading={loading}
            previewData={previewData || []}
            instructions={visualizeOperation.instructions.V2_TWO_DIMENSION_CHART}
            dataPanelTemplateId={dataPanelTemplateId}
            variables={variables}
            area={
              visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_AREA_CHART_V2 ||
              visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2
            }
            normalize={
              visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2
            }
            schema={schema}
            canUseMultiYAxis={CAN_USE_MULTI_Y_AXIS_OPS.has(visualizeOperation.operation_type)}
            dashboardDatasets={dashboardDatasets}
          />
        </div>
      );
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2) {
      return (
        <div className={v2ChartClass}>
          <BarChart
            globalStyleConfig={this.context.globalStyleConfig}
            backgroundColor={backgroundColor}
            loading={loading}
            previewData={previewData || []}
            instructions={visualizeOperation.instructions.V2_TWO_DIMENSION_CHART}
            dataPanelTemplateId={dataPanelTemplateId}
            variables={variables}
            schema={schema}
            canUseMultiYAxis={CAN_USE_MULTI_Y_AXIS_OPS.has(visualizeOperation.operation_type)}
            grouped
            isComboChart
            dataPanelProvidedId={dataPanelProvidedId}
            dashboardDatasets={dashboardDatasets}
          />
        </div>
      );
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2) {
      return (
        <div className={v2ChartClass}>
          <HeatMapV2
            globalStyleConfig={this.context.globalStyleConfig}
            backgroundColor={backgroundColor}
            loading={loading}
            previewData={previewData || []}
            instructions={visualizeOperation.instructions.V2_TWO_DIMENSION_CHART}
            dataPanelTemplateId={dataPanelTemplateId}
            variables={variables}
            schema={schema}
          />
        </div>
      );
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_FUNNEL_V2) {
      return (
        <div className={v2ChartClass}>
          <FunnelChartV2
            globalStyleConfig={this.context.globalStyleConfig}
            backgroundColor={backgroundColor}
            loading={loading}
            previewData={previewData || []}
            instructions={visualizeOperation.instructions.V2_TWO_DIMENSION_CHART}
            dataPanelTemplateId={dataPanelTemplateId}
            variables={variables}
            schema={schema}
          />
        </div>
      );
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2) {
      return (
        <div className={classes.fullHeightChartContainer}>
          <SingleNumberDisplayV2
            loading={loading}
            previewData={previewData || []}
            instructions={visualizeOperation.instructions.V2_KPI}
            dataPanelTemplateId={dataPanelTemplateId}
            variables={variables}
            schema={schema}
            infoTooltipText={getInformationTooltipInfo(
              visualizeOperation.operation_type,
              visualizeOperation.instructions,
            )}
            operationType={visualizeOperation.operation_type}
            noDataInstructions={visualizeOperation.generalFormatOptions?.noDataState}
          />
        </div>
      );
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_PROGRESS_V2) {
      return (
        <div className={classes.fullHeightChartContainer}>
          <SingleNumberDisplayV2
            loading={loading}
            previewData={previewData || []}
            instructions={visualizeOperation.instructions.V2_KPI}
            dataPanelTemplateId={dataPanelTemplateId}
            variables={variables}
            schema={schema}
            infoTooltipText={getInformationTooltipInfo(
              visualizeOperation.operation_type,
              visualizeOperation.instructions,
            )}
            operationType={visualizeOperation.operation_type}
            noDataInstructions={visualizeOperation.generalFormatOptions?.noDataState}
          />
        </div>
      );
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
      return (
        <div className={v2ChartClass}>
          <BoxPlotV2
            backgroundColor={backgroundColor}
            globalStyleConfig={this.context.globalStyleConfig}
            loading={loading}
            previewData={previewData || []}
            instructions={visualizeOperation.instructions.V2_BOX_PLOT}
            dataPanelTemplateId={dataPanelTemplateId}
            variables={variables}
            schema={schema}
            secondaryData={secondaryData}
          />
        </div>
      );
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
      return (
        <div className={classes.fullHeightChartContainer}>
          <NumberTrend
            loading={loading}
            backgroundColor={backgroundColor}
            previewData={previewData || []}
            aggValuesLoading={secondaryDataLoading}
            aggregatedValues={
              secondaryData && !_.isEmpty(secondaryData)
                ? {
                    comparisonRange: secondaryData[0]
                      ? (secondaryData[0][Object.keys(secondaryData[0])[0] as string] as number) ??
                        0
                      : 0,
                    periodRange: secondaryData[1]
                      ? (secondaryData[1][Object.keys(secondaryData[1])[0] as string] as number) ??
                        0
                      : 0,
                  }
                : undefined
            }
            instructions={visualizeOperation.instructions.V2_KPI_TREND}
            dataPanelTemplateId={dataPanelTemplateId}
            variables={variables}
            schema={schema}
            title={panelName}
            globalStyleConfig={this.context.globalStyleConfig}
            infoTooltipText={getInformationTooltipInfo(
              visualizeOperation.operation_type,
              visualizeOperation.instructions,
            )}
          />
        </div>
      );
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_MAP_V2) {
      if (
        loading ||
        !visualizeOperation.instructions.V2_TWO_DIMENSION_CHART ||
        !visualizeOperation.instructions.V2_TWO_DIMENSION_CHART.aggColumns ||
        !visualizeOperation.instructions.V2_TWO_DIMENSION_CHART.categoryColumn ||
        !visualizeOperation.instructions.V2_TWO_DIMENSION_CHART.aggColumns.length
      )
        return <NeedsConfigurationPanel loading={loading} />;
      return (
        <ChoroplethMap
          region={
            visualizeOperation.instructions.V2_TWO_DIMENSION_CHART.chartSpecificFormat?.mapChart
              ?.region || REGION_TYPES.WORLD
          }
          densityColumn={
            visualizeOperation.instructions.V2_TWO_DIMENSION_CHART.aggColumns[0].column
          }
          regionColumn={
            visualizeOperation.instructions.V2_TWO_DIMENSION_CHART.categoryColumn.column
          }
          data={previewData || []}
          schema={schema}
        />
      );
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER) {
      return (
        <ReportBuilder
          adHocOperationInstructions={adHocOperationInstructions || {}}
          downloadChartInfo={downloadChartInfo}
          error={!!error}
          loading={loading}
          onAdHocFilterInfoUpdate={this.onAdHocFilterInfoUpdate}
          onAdHocOperationInstructionsChange={this.onAdHocOperationInstructionsChange}
          onDownloadPanelCsv={onDownloadPanelCsv}
          onDownloadPanelPdf={onDownloadPanelPdf}
          rows={previewData || []}
          secondaryData={secondaryData ?? []}
          schema={schema}
          sourceDataRowCount={sourceDataRowCount}
          updateVisualizeOperation={(newVisualizeOperation) => {
            updateVisualizeOperation(newVisualizeOperation, OPERATION_TYPES.VISUALIZE_TABLE);
          }}
          visualizeOperation={visualizeOperation}
        />
      );
    } else {
      return (
        <DataTable
          adHocOperationInstructions={adHocOperationInstructions || {}}
          secondaryData={secondaryData || {}}
          onAdHocOperationInstructionsChange={this.onAdHocOperationInstructionsChange}
          analyticsEventTracker={analyticsEventTracker}
          loading={loading}
          previewData={previewData || []}
          rowError={rowError}
          schema={getChangedSchema(schema, visualizeOperation.instructions.VISUALIZE_TABLE)}
          dashboardDatasets={dashboardDatasets}
          sourceDataRowCount={sourceDataRowCount}
          visualizeOperation={visualizeOperation}
          updateVisualizeOperation={(newVisualizeOperation) => {
            updateVisualizeOperation(newVisualizeOperation, OPERATION_TYPES.VISUALIZE_TABLE);
          }}
          userTransformedSchema={this.state.userTransformedSchema}
          enableColumnResizing={isSelected || isViewOnly}
          isViewOnly={isViewOnly}
          canDownloadDataPanel={canDownloadDataPanel}
          downloadChartInfo={downloadChartInfo}
          onDownloadPanelCsv={onDownloadPanelCsv}
          onDownloadPanelPdf={onDownloadPanelPdf}
          error={!!error}
        />
      );
    }
  };

  canDownloadCSV = () => {
    const { visualizeOperation, loading, previewData } = this.props;
    return Boolean(
      VIZ_OP_WITH_CSV_DOWNLOAD.has(visualizeOperation.operation_type) &&
        !loading &&
        previewData?.length &&
        isDataPanelReadyToCompute,
    );
  };

  updateUserTransformedSchema = (userTransformedSchema: UserTransformedSchema) => {
    this.setState({ userTransformedSchema });
  };

  renderHeader = () => {
    const {
      adHocOperationInstructions,
      error,
      loading,
      panelName,
      schema,
      visualizeOperation,
    } = this.props;

    if (
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER ||
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 ||
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 ||
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER ||
      (VISUALIZATION_OPERATIONS.indexOf(visualizeOperation.operation_type) > -1 && !panelName)
    ) {
      return;
    } else if (
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_TABLE &&
      visualizeOperation.instructions.VISUALIZE_TABLE.isHeaderHidden
    ) {
      return;
    }

    const infoTooltipText = getInformationTooltipInfo(
      visualizeOperation.operation_type,
      visualizeOperation.instructions,
    );

    if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_TABLE) {
      return (
        <DataTableHeader
          adHocOperationInstructions={adHocOperationInstructions}
          error={!!error}
          infoTooltipText={infoTooltipText}
          isSchemaCustomizationEnabled={
            visualizeOperation.instructions.VISUALIZE_TABLE.isSchemaCustomizationEnabled
          }
          isFilteringDisabled={visualizeOperation.instructions.VISUALIZE_TABLE.isFilteringDisabled}
          loading={loading}
          onAdHocFilterInfoUpdate={this.onAdHocFilterInfoUpdate}
          panelName={panelName}
          schema={schema}
          userTransformedSchema={this.state.userTransformedSchema}
          updateUserTransformedSchema={this.updateUserTransformedSchema}
        />
      );
    }

    return <Header loading={loading} panelName={panelName} infoTooltipText={infoTooltipText} />;
  };

  renderV1LineOrBarChart = (chartType: string) => {
    const { classes } = this.props;

    return (
      <div className={classes.chartContainer}>{this.renderV1LineOrBarChartBody(chartType)}</div>
    );
  };

  renderV1LineOrBarChartBody = (chartType: string) => {
    const {
      previewData,
      schema,
      visualizeOperation,
      dataPanelTemplateId,
      loading,
      classes,
    } = this.props;
    const operationInstructions = visualizeOperation.instructions.VISUALIZE_LINE_OR_BAR_CHART;

    if (
      !previewData ||
      !operationInstructions.xAxisColumn ||
      !operationInstructions.xAxisColumn.name ||
      operationInstructions.lineColumns.length === 0 ||
      !schema
    ) {
      return <NeedsConfigurationPanel className={classes.fullSizeNeedsConfig} loading={loading} />;
    }

    const columnsByName = _.indexBy(schema, 'name');
    if (!columnsByName[operationInstructions.xAxisColumn.name])
      return <NeedsConfigurationPanel className={classes.fullSizeNeedsConfig} loading={loading} />;

    return (
      <LineOrBarChart
        chartKey={dataPanelTemplateId}
        chartType={chartType}
        textColor={this.context.globalStyleConfig.text.primaryColor}
        operationType={visualizeOperation.operation_type}
        multiYAxis={isMultiAxis(operationInstructions)}
        xAxis={operationInstructions.xAxisColumn && operationInstructions.xAxisColumn.name}
        viewTypeId={
          operationInstructions.viewType
            ? operationInstructions.viewType.id
            : DEFAULT_CHART_VIEW[visualizeOperation.operation_type].id
        }
        // xAxisFormat={operationInstructions.xAxisFormat && operationInstructions.xAxisFormat.id}
        columns={operationInstructions.lineColumns}
        data={previewData}
        schema={schema}
        mirrorLabels={operationInstructions.labelMirrored}
        yAxisRangeConfig={operationInstructions.yAxisRangeConfig}
      />
    );
  };

  renderChoroplethMap = () => {
    const { previewData, visualizeOperation, loading, schema } = this.props;
    const operationInstructions = visualizeOperation.instructions.VISUALIZE_CHOROPLETH_MAP;

    if (
      !previewData ||
      !previewData[0] ||
      !operationInstructions ||
      !operationInstructions.densityColumn ||
      !operationInstructions.densityColumn.name ||
      !operationInstructions.regionColumn ||
      !operationInstructions.regionColumn.name
    ) {
      return <NeedsConfigurationPanel loading={loading} />;
    }

    return (
      <ChoroplethMap
        region={operationInstructions.region}
        densityColumn={operationInstructions.densityColumn}
        regionColumn={operationInstructions.regionColumn}
        data={previewData}
        schema={schema}
      />
    );
  };

  renderFunnelChart = () => {
    const { visualizeOperation, previewData, dataPanelTemplateId, loading } = this.props;
    const instructions = visualizeOperation.instructions.VISUALIZE_FUNNEL;
    if (
      !instructions?.categoryColumn?.name ||
      !instructions?.amountColumn?.name ||
      !previewData ||
      !previewData[0]
    )
      return <NeedsConfigurationPanel loading={loading} />;

    return (
      <FunnelChart
        dataPanelTemplateId={dataPanelTemplateId}
        textColor={this.context.globalStyleConfig.text.primaryColor}
        labels={_.pluck(previewData, instructions.categoryColumn.name)}
        values={_.pluck(previewData, instructions.amountColumn.name) as number[]}
        colors={[instructions.startColor || '#0069ED', instructions.endColor || '#3890FF']}
      />
    );
  };

  renderPieChart = () => {
    const { classes, visualizeOperation, previewData, dataPanelTemplateId, loading } = this.props;
    const instructions = visualizeOperation.instructions.VISUALIZE_PIE_CHART;

    if (
      !instructions?.categoryColumn?.name ||
      !instructions?.valueColumn?.name ||
      !previewData ||
      !previewData[0]
    )
      return <NeedsConfigurationPanel loading={loading} />;

    const sortedData = _.sortBy(
      previewData,
      (row: Record<string, string | number>) => row[instructions.valueColumn?.name as string],
    );

    return (
      <div className={classes.chartContainer}>
        <PieChart
          dataPanelTemplateId={dataPanelTemplateId}
          chartType={instructions.layoutType || PIE_CHART_TYPES.pie.id}
          legendLocation={instructions.legendLocation || LEGEND_LOCATIONS.right.id}
          textColor={this.context.globalStyleConfig.text.primaryColor}
          labels={_.pluck(sortedData, instructions.categoryColumn.name)}
          values={_.pluck(sortedData, instructions.valueColumn.name)}
        />
      </div>
    );
  };

  renderNumber = () => {
    const { classes, previewData, panelName, visualizeOperation, loading } = this.props;
    const operationInstructions = visualizeOperation.instructions.VISUALIZE_NUMBER;

    if (
      !previewData ||
      previewData.length === 0 ||
      !operationInstructions.layoutConfig.NUMBER.column?.name
    ) {
      return <NeedsConfigurationPanel className={classes.fullSizeNeedsConfig} loading={loading} />;
    }

    const title =
      visualizeOperation.instructions.VISUALIZE_NUMBER.displayFormat.title || panelName || '';
    const number = previewData[0][operationInstructions.layoutConfig.NUMBER.column.name] as number;

    return (
      <SingleNumberDisplay
        title={operationInstructions.displayFormat.hideTitle ? undefined : title}
        number={number}
        numberFormat={
          operationInstructions.layoutConfig.NUMBER.numberFormat || NUMBER_FORMATS.NORMAL
        }
        units={
          operationInstructions.displayFormat.hideUnits
            ? undefined
            : operationInstructions.displayFormat.units
        }
        multiplier={operationInstructions.layoutConfig.NUMBER.multiplyFactor || 1}
        alignment={operationInstructions.displayFormat.alignment}
        decimalPlaces={operationInstructions.layoutConfig.NUMBER.decimalPlaces || 0}
        loading={loading}
        isProgress={operationInstructions.displayFormat.isProgess}
        progressGoal={operationInstructions.layoutConfig.NUMBER.progressGoal}
        progressColor={operationInstructions.displayFormat.progressColor || '#0069ED'}
      />
    );
  };

  onAdHocOperationInstructionsChange = (adHocOperationInstructions: AdHocOperationInstructions) => {
    const { onAdHocOperationInstructionsUpdated, dataPanelTemplateId } = this.props;

    onAdHocOperationInstructionsUpdated(dataPanelTemplateId, adHocOperationInstructions, true);
  };

  onAdHocFilterInfoUpdate = (adHocFilterInfo: FilterOperationInstructions) => {
    const {
      adHocOperationInstructions,
      onAdHocOperationInstructionsUpdated,
      dataPanelTemplateId,
      analyticsEventTracker,
    } = this.props;
    const newAdHocOperationInstructions = produce(adHocOperationInstructions, (draft) => {
      draft.filterInfo = adHocFilterInfo;
      draft.currentPage = 1;
    });

    onAdHocOperationInstructionsUpdated(dataPanelTemplateId, newAdHocOperationInstructions);
    analyticsEventTracker && analyticsEventTracker(REPORTED_ANALYTIC_ACTION_TYPES.TABLE_FILTERED);
  };

  renderNoDataBody = () => {
    const {
      classes,
      variables,
      secondaryDataLoading,
      schema,
      dataPanelTemplateId,
      visualizeOperation,
      panelName,
      loading,
      secondaryData,
      previewData,
    } = this.props;

    if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER) {
      return (
        <div className={classes.noDataFullContainer}>
          <SingleNumberDisplayEmpty
            loading={loading}
            title={
              visualizeOperation.instructions.VISUALIZE_NUMBER.displayFormat.title ||
              panelName ||
              ''
            }
            alignment={visualizeOperation.instructions.VISUALIZE_NUMBER.displayFormat.alignment}
            emptyText={visualizeOperation.instructions.VISUALIZE_NUMBER.displayFormat.emptyText}
          />
        </div>
      );
    } else if (
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
    ) {
      return (
        <div className={classes.noDataFullContainer}>
          <SingleNumberDisplayV2
            loading={loading}
            previewData={[]}
            instructions={visualizeOperation.instructions.V2_KPI}
            dataPanelTemplateId={dataPanelTemplateId}
            variables={variables}
            schema={schema}
            infoTooltipText={getInformationTooltipInfo(
              visualizeOperation.operation_type,
              visualizeOperation.instructions,
            )}
            operationType={visualizeOperation.operation_type}
            noDataInstructions={visualizeOperation.generalFormatOptions?.noDataState}
          />
        </div>
      );
    } else if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
      return (
        <NumberTrend
          loading={loading}
          backgroundColor={this.context.globalStyleConfig.container.fill}
          previewData={previewData || []}
          aggValuesLoading={secondaryDataLoading}
          aggregatedValues={
            secondaryData && !_.isEmpty(secondaryData)
              ? {
                  comparisonRange: secondaryData[0]
                    ? (secondaryData[0][Object.keys(secondaryData[0])[0] as string] as number) ?? 0
                    : 0,
                  periodRange: secondaryData[1]
                    ? (secondaryData[1][Object.keys(secondaryData[1])[0] as string] as number) ?? 0
                    : 0,
                }
              : undefined
          }
          instructions={visualizeOperation.instructions.V2_KPI_TREND}
          dataPanelTemplateId={dataPanelTemplateId}
          variables={variables}
          schema={schema}
          title={panelName}
          globalStyleConfig={this.context.globalStyleConfig}
          infoTooltipText={getInformationTooltipInfo(
            visualizeOperation.operation_type,
            visualizeOperation.instructions,
          )}
          noDataInstructions={visualizeOperation.generalFormatOptions?.noDataState}
        />
      );
    }

    return (
      <div className={cx(classes.noDataContainer, GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont)}>
        <NonIdealState
          className={classes.noDataCallout}
          icon={
            loading ? (
              <Spinner size={30} />
            ) : visualizeOperation.generalFormatOptions?.noDataState?.hideChartIcon ? undefined : (
              <Icon
                className={classes.emptyIcon}
                icon={OP_TYPE_TO_BP3_ICON[visualizeOperation.operation_type]}
                iconSize={30}
              />
            )
          }
          title={
            visualizeOperation.generalFormatOptions?.noDataState?.noDataText || 'No Data'
          }></NonIdealState>
      </div>
    );
  };

  renderCSVButton = () => {
    const {
      clearDownloadCsvInfo,
      downloadCsvLoading,
      downloadDataPanelCsvErrored,
      downloadDataPanelCsvLink,
      onDownloadPanelCsv,
      error,
      loading,
      classes,
      visualizeOperation,
    } = this.props;
    const downloadCsvButtonProps = {
      clearDownloadCsvInfo,
      downloadCsvLoading,
      downloadDataPanelCsvErrored,
      downloadDataPanelCsvLink,
      onDownloadPanelCsv,
      error: !!error,
      loading,
    };

    if (visualizeOperation.generalFormatOptions?.hideDownloadButton || !this.canDownloadCSV())
      return;

    return (
      <div className={cx(classes.downloadCsvButton, 'downloadCSVChartIcon')}>
        <DownloadCsvButton {...downloadCsvButtonProps} />
      </div>
    );
  };

  renderErrorState = () => {
    const { classes, error, loading, dataPanelTemplateId } = this.props;

    return (
      <div
        className={cx(
          classes.errorContainer,
          'data-panel-error',
          GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor,
        )}
        id={dataPanelTemplateId}>
        <NonIdealState
          className={classes.errorCallout}
          icon={
            loading ? (
              <Spinner size={30} />
            ) : (
              <Icon icon="error" iconSize={30} intent={Intent.DANGER} />
            )
          }
          title="There was an error fetching the results"
          description={parseErrorMessage(error)}
          action={
            <Button
              minimal
              onClick={() => alert(error)}
              icon="document-open"
              text="View full error"
            />
          }></NonIdealState>
      </div>
    );
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateVisualizeOperation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(DashboardDatasetView));
