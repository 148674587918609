/** @format */
import { Action } from 'reducers/rootReducer';
import { createReducer, resolveSecondaryData } from './utils';

import {
  ACTION,
  DashboardTemplate,
  DashboardVersion,
  DataPanelTemplate,
  ShareData,
  Team,
} from 'actions/types';
import { EMPTY_FILTER_CONFIG } from 'constants/dataConstants';
import _ from 'underscore';

export interface EmbedDashboardReducer {
  dashboardTemplate?: DashboardTemplate;
  shareData: ShareData;
  dashboardVersion?: DashboardVersion;
  team?: Team;
}

const initialState: EmbedDashboardReducer = {
  shareData: {
    id: '',
    share_link_url: null,
  },
};

export const fetchDashboardSuccess = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;
  newState.dashboardTemplate = payload.dashboard_template;
  newState.dashboardVersion = payload.dashboard_version;
  newState.team = payload.team;

  if (!newState.dashboardVersion) return newState;
  const valid_data_panels: Record<string, DataPanelTemplate> = {};
  const received_data_panels = newState.dashboardVersion.configuration.data_panels;
  // PD-995: For some reason we're getting empty data panel templates sometimes, mitigate this by
  // checking for emptyness until we can figure out why
  _.forEach(Object.keys(received_data_panels), (dpt_id) => {
    if (!_.isEmpty(received_data_panels[dpt_id])) {
      valid_data_panels[dpt_id] = received_data_panels[dpt_id];
    }
  });
  newState.dashboardVersion.configuration.data_panels = valid_data_panels;

  return newState;
};

export const fetchDataPanelTemplateRequest = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;
  if (!newState.dashboardVersion) return newState;
  const { configuration } = newState.dashboardVersion;

  configuration.data_panels[payload.postData.id]._loading = true;
  if (payload.postData)
    configuration.data_panels[payload.postData.id]._adHocOperationInstructions = {
      ...configuration.data_panels[payload.postData.id]._adHocOperationInstructions,
      currentPage: payload.postData.page_number || 1,
      sortInfo: payload.postData.sort_info,
      filterInfo: payload.postData.filter_info || { ...EMPTY_FILTER_CONFIG },
    };
  return newState;
};

export const fetchDataPanelTemplateError = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;
  if (!newState.dashboardVersion) return newState;
  const { configuration } = newState.dashboardVersion;

  configuration.data_panels[payload.postData.id]._loading = false;
  return newState;
};

export const fetchDataPanelTemplateSuccess = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;
  if (!newState.dashboardVersion) return newState;
  const { configuration } = newState.dashboardVersion;

  configuration.data_panels[payload.postData.id] = {
    ...configuration.data_panels[payload.postData.id],
    ...payload.data_panel_template,
  };
  configuration.data_panels[payload.postData.id]._loading = false;
  if (payload.postData)
    configuration.data_panels[payload.postData.id]._adHocOperationInstructions = {
      ...configuration.data_panels[payload.postData.id]._adHocOperationInstructions,
      currentPage: payload.postData.page_number || 1,
      sortInfo: payload.postData.sort_info,
      filterInfo: payload.postData.filter_info || { ...EMPTY_FILTER_CONFIG },
    };
  return newState;
};

export const fetchSecondaryDataRequest = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;
  if (!newState.dashboardVersion) return newState;
  const { configuration } = newState.dashboardVersion;

  const currentDataPanel = configuration.data_panels[payload.postData.id];

  if (currentDataPanel._outstandingSecondaryDataRequests === undefined) {
    currentDataPanel._outstandingSecondaryDataRequests = 0;
  }

  currentDataPanel._outstandingSecondaryDataRequests++;

  return newState;
};

export const fetchSecondaryDataSuccess = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;
  if (!newState.dashboardVersion) return newState;
  const { configuration } = newState.dashboardVersion;

  const currentDataPanel = configuration.data_panels[payload.postData.id];

  // outstanding requests should never be undefined, but need to appease TS
  if (currentDataPanel._outstandingSecondaryDataRequests !== undefined) {
    currentDataPanel._outstandingSecondaryDataRequests--;
  }

  currentDataPanel._secondaryData = resolveSecondaryData(
    currentDataPanel,
    payload.data_panel_template as DataPanelTemplate,
  );

  return newState;
};

export const fetchDataPanelRowCountSuccess = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;
  if (!newState.dashboardVersion) return newState;
  const { configuration } = newState.dashboardVersion;

  configuration.data_panels[payload.postData.id]._total_row_count = payload._total_row_count;
  return newState;
};

export const fetchDatasetPreviewSuccess = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;
  if (!newState.dashboardVersion) return newState;
  const { configuration } = newState.dashboardVersion;

  const dataset = configuration.datasets[payload.postData.dataset.id];
  if (dataset) {
    dataset.schema = payload.dataset_preview?.schema;
    dataset._rows = payload.dataset_preview?._rows;
    dataset._error = payload.dataset_preview?._error;
    dataset._loading = false;
  }

  return newState;
};

export const fetchShareIdSuccess = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;

  newState.shareData.id = payload.share_id;
  if (payload.share_link_url) newState.shareData.share_link_url = payload.share_link_url;

  return newState;
};

export const fetchExportImageUrlSuccess = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;

  newState.shareData.imageExportUrl = payload.screenshot_url;

  return newState;
};

export const fetchExportPdfUrlSuccess = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;

  newState.shareData.pdfExportUrl = payload.screenshot_url;

  return newState;
};

export const startDownloadCsv = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;
  if (!newState.dashboardVersion) return newState;
  const { configuration } = newState.dashboardVersion;

  configuration.data_panels[payload.postData.id]._downloadCsvLoading = true;

  return newState;
};

export const endDownloadCsv = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;
  if (!newState.dashboardVersion) return newState;
  const { configuration } = newState.dashboardVersion;

  configuration.data_panels[payload.postData.id]._downloadCsvLoading = false;

  return newState;
};

const setDptLoading = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;
  if (!newState.dashboardVersion) return newState;
  const { configuration } = newState.dashboardVersion;

  (payload.ids as string[]).forEach((id) => {
    configuration.data_panels[id]._loading = payload.loading;
  });
  return newState;
};

const updateAdHocOperationInstructions = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;
  if (!newState.dashboardVersion) return newState;

  const { configuration } = newState.dashboardVersion;
  configuration.data_panels[payload.dataPanelTemplateId]._adHocOperationInstructions =
    payload.adHocOperationInstructions;

  return newState;
};

export default createReducer<EmbedDashboardReducer>(initialState, {
  [`${ACTION.EMBED_FETCH_DASHBOARD}_SUCCESS`]: fetchDashboardSuccess,
  [`${ACTION.EMBED_FETCH_DATA_PANEL_TEMPLATE}_REQUEST`]: fetchDataPanelTemplateRequest,
  [`${ACTION.EMBED_FETCH_DATA_PANEL_TEMPLATE}_ERROR`]: fetchDataPanelTemplateError,
  [`${ACTION.EMBED_FETCH_DATA_PANEL_TEMPLATE}_SUCCESS`]: fetchDataPanelTemplateSuccess,
  [`${ACTION.EMBED_FETCH_SECONDARY_DATA}_REQUEST`]: fetchSecondaryDataRequest,
  [`${ACTION.EMBED_FETCH_SECONDARY_DATA}_SUCCESS`]: fetchSecondaryDataSuccess,
  [`${ACTION.EMBED_FETCH_DATA_PANEL_ROW_COUNT}_SUCCESS`]: fetchDataPanelRowCountSuccess,
  [`${ACTION.EMBED_FETCH_DASHBOARD_DATASET_PREVIEW}_SUCCESS`]: fetchDatasetPreviewSuccess,
  [`${ACTION.FETCH_SHARE_ID}_SUCCESS`]: fetchShareIdSuccess,
  [`${ACTION.FETCH_PDF_EXPORT_URL}_SUCCESS`]: fetchExportPdfUrlSuccess,
  [`${ACTION.FETCH_IMAGE_EXPORT_URL}_SUCCESS`]: fetchExportImageUrlSuccess,
  [`${ACTION.EMBED_DOWNLOAD_DATA_PANEL_TEMPLATE_CSV}_REQUEST`]: startDownloadCsv,
  [`${ACTION.EMBED_DOWNLOAD_DATA_PANEL_TEMPLATE_CSV}_ERROR`]: endDownloadCsv,
  [`${ACTION.EMBED_DOWNLOAD_DATA_PANEL_TEMPLATE_CSV}_SUCCESS`]: endDownloadCsv,
  [ACTION.EMBED_SET_DPT_LOADING]: setDptLoading,
  [ACTION.EMBED_UPDATE_AD_HOC_OPERATION_INSTRUCTIONS]: updateAdHocOperationInstructions,
});
