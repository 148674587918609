/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/index';
import { Icon, Popover, Position } from '@blueprintjs/core';

import OperatorDropdown from 'pages/dashboardPage/DashboardDatasetView/Header/OperatorDropdown';
import Button from 'shared/Button';
import FilterConfigMenu from './FilterConfigMenu';

import {
  FilterClause,
  FilterOperatorType,
  FilterValueType,
  FilterValueDateType,
  FilterValueRelativeDateType,
  FilterValueSourceType,
} from 'constants/types';
import {
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_RELATIVE_PICKER,
} from 'constants/dataPanelEditorConstants';
import { DashboardElement } from 'types/dashboardTypes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.lightBlue,
    border: `1px solid ${theme.palette.ds.lightBlue}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: 3,
    marginBottom: theme.spacing(1),

    '&:last-child': {
      marginBottom: 0,
    },

    '&:hover': {
      border: `1px solid ${theme.palette.ds.blue}`,
      cursor: 'pointer',
    },
  },
  popoverTargetContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterPopover: {
    display: 'flex',
    alignItems: 'baseline',
    padding: theme.spacing(2),
  },
  operatorDropdown: {
    marginRight: theme.spacing(2),
  },
  name: {
    fontWeight: 600,
    color: '#182026',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  caretIcon: {
    marginLeft: theme.spacing(3),
  },
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginRight: -theme.spacing(1),
    alignSelf: 'flex-start',
  },
}));

export type Props = {
  clause: FilterClause;
  onRemoveCol: () => void;
  onOperatorSelect: (operator: FilterOperatorType) => void;
  onFilterValueUpdate: (value: FilterValueType) => void;
  onFilterValueSourceSelect: (source: FilterValueSourceType) => void;
  onFilterValueVariableSelect: (variableId: string) => void;
  dashboardElements?: DashboardElement[];
};

const convertDashDateToSlashDate = (date: string) => {
  return date.replace(/-/g, '/');
};

export default function DroppedFilterColumn({
  clause,
  onRemoveCol,
  onOperatorSelect,
  onFilterValueUpdate,
  onFilterValueSourceSelect,
  onFilterValueVariableSelect,
  dashboardElements,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Popover
        fill
        minimal
        position={Position.BOTTOM_RIGHT}
        content={
          <div className={classes.filterPopover} onClick={(e) => e.stopPropagation()}>
            <OperatorDropdown
              className={classes.operatorDropdown}
              selectedColumn={clause.filterColumn}
              selectedOperator={clause.filterOperation}
              onOperatorSelect={onOperatorSelect}
            />
            <FilterConfigMenu
              tall
              selectedColumn={clause.filterColumn}
              selectedOperator={clause.filterOperation}
              filterValue={clause.filterValue}
              filterValueSource={clause.filterValueSource}
              filterValueVariableId={clause.filterValueVariableId}
              onFilterValueUpdate={onFilterValueUpdate}
              onFilterValueSourceSelect={onFilterValueSourceSelect}
              onFilterValueVariableSelect={onFilterValueVariableSelect}
              dashboardElements={dashboardElements}
            />
            <Button
              minimal
              className={classes.deleteIconButton}
              icon="trash"
              onClick={() => onRemoveCol()}
            />
          </div>
        }>
        <div className={classes.popoverTargetContainer}>
          <div className={classes.name}>
            {clause.filterColumn?.name}{' '}
            {isFilterValueSet(clause)
              ? clause.filterOperation?.name
              : clause.filterOperation?.selectionValue}{' '}
            {renderFilterValue(clause)}
          </div>
          <Icon color={theme.palette.ds.blue} className={classes.caretIcon} icon="caret-down" />
        </div>
      </Popover>
    </div>
  );
}

const isFilterValueSet = (clause: FilterClause) => {
  return clause.filterValueSource === FilterValueSourceType.VARIABLE
    ? clause.filterValueVariableId !== undefined
    : clause.filterValue !== undefined;
};

const renderFilterValue = (clause: FilterClause) => {
  if (clause.filterValueSource === FilterValueSourceType.VARIABLE) {
    return `{{ ${clause.filterValueVariableId || ''} }}`;
  }

  if (
    !clause.filterValue ||
    !clause.filterOperation?.selectionValue ||
    FILTER_OPS_NO_VALUE.has(clause.filterOperation.id)
  )
    return;
  if (FILTER_OPS_DATE_PICKER.has(clause.filterOperation.id)) {
    const filterValueDate = clause.filterValue as FilterValueDateType;
    return filterValueDate.startDate
      ? convertDashDateToSlashDate(filterValueDate.startDate.toString())
      : undefined;
  } else if (FILTER_OPS_DATE_RANGE_PICKER.has(clause.filterOperation.id)) {
    const filterValueDate = clause.filterValue as FilterValueDateType;
    const startDate = filterValueDate.startDate
      ? convertDashDateToSlashDate(filterValueDate.startDate.toString())
      : undefined;
    const endDate = filterValueDate.endDate
      ? convertDashDateToSlashDate(filterValueDate.endDate.toString())
      : undefined;
    if (startDate && endDate) return `${startDate} and ${endDate}`;
  } else if (FILTER_OPS_RELATIVE_PICKER.has(clause.filterOperation.id)) {
    const filterValueRelativeDate = clause.filterValue as FilterValueRelativeDateType;
    if (filterValueRelativeDate.number && filterValueRelativeDate.relativeTimeType) {
      return `${filterValueRelativeDate.number} ${filterValueRelativeDate.relativeTimeType.name}`;
    }
  } else {
    return clause.filterValue;
  }
};
