/** @format */

import React, { ReactNode } from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Icon, IButtonProps } from '@blueprintjs/core';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const BUTTON_HEIGHT = 30;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.bp3-button': {
      justifyContent: 'space-between',
      backgroundImage: 'none',
      boxShadow: 'none',
      border: `1px solid ${theme.palette.ds.grey400}`,
      backgroundColor: theme.palette.ds.white,
      height: BUTTON_HEIGHT,

      '&:hover:not(.disabled)': {
        boxShadow: 'none',
        border: `1px solid ${theme.palette.ds.hovered.grey400}`,
        backgroundColor: theme.palette.ds.white,
      },

      '&:active:not(.disabled)': {
        boxShadow: 'none',
        border: `1px solid ${theme.palette.ds.blue}`,
        backgroundColor: theme.palette.ds.white,
      },
    },
    '& .bp3-button-text': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      margin: 0,
    },
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonText: {
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  buttonTextWithIcon: {
    display: 'flex',
  },
  buttonTextWithCancelBtn: {
    width: 'calc(100% - 20px)',
  },
  buttonIcon: {
    marginRight: theme.spacing(2),
    display: 'flex',
    '& .bp3-icon': {
      margin: `0px !important`,
    },

    '&.noText': {
      marginRight: 0,
    },
  },
  cancelButton: {
    '&.bp3-button': {
      height: 20,
      width: 20,
      minHeight: 0,
      minWidth: 0,
      padding: 0,
      marginRight: 2,
    },
  },
}));

type PassedProps = {
  children?: ReactNode;
  className?: string;
  showCancelBtn?: boolean;
  onCancelClick?: () => void;
  style?: React.CSSProperties;
  hideText?: boolean;
};

type Props = IButtonProps & PassedProps;

const DropdownButton = (props: Props) => {
  const classes = useStyles(props);
  const { disabled, icon, text, onCancelClick, showCancelBtn, hideText, ...otherProps } = props;
  return (
    <Button
      disabled={disabled}
      {...otherProps}
      className={cx(
        classes.root,
        {
          disabled: disabled,
        },
        GLOBAL_STYLE_CLASSNAMES.container.outline.dropdownInputBorder,
        GLOBAL_STYLE_CLASSNAMES.container.shadow.dropdownInputShadow,
        GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.dropdownInputBorderHover,
        GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownInputBorder,
        GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont,
        props.className,
      )}>
      <div className={classes.buttonContent}>
        <div
          className={cx(
            classes.buttonText,
            { [classes.buttonTextWithCancelBtn]: showCancelBtn },
            { [classes.buttonTextWithIcon]: icon },
          )}>
          {icon && (
            <div className={cx(classes.buttonIcon, { noText: hideText })}>
              <Icon icon={icon} />
            </div>
          )}
          {hideText ? undefined : text}
        </div>
        {showCancelBtn && (
          <Button
            disabled={disabled}
            className={classes.cancelButton}
            minimal
            icon="cross"
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              e.stopPropagation();
              onCancelClick && onCancelClick();
            }}
          />
        )}
      </div>
    </Button>
  );
};

export default DropdownButton;
