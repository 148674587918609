/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';
import { Spinner } from '@blueprintjs/core';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const TITLE_HEIGHT = 42;
const DEFAULT_EMPTY_TEXT = 'No data available';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      overflow: 'hidden',
    },
    titleContainer: {
      padding: theme.spacing(6),
      paddingBottom: 0,

      '&.CENTER_ALIGN': {
        textAlign: 'center',
      },
      '&.RIGHT_ALIGN': {
        textAlign: 'right',
      },
    },
    title: {
      color: '#AAAAAA',
      fontWeight: 500,
      fontSize: 16,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    numberContainer: {
      padding: ` 0 ${theme.spacing(6)}px`,
      height: `calc(100% - ${TITLE_HEIGHT}px - 40px)`,
      display: 'flex',
      alignItems: 'center',

      '&.CENTER_ALIGN': {
        justifyContent: 'center',
      },
      '&.RIGHT_ALIGN': {
        justifyContent: 'flex-end',
      },
    },

    numberUnitContainer: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    number: {
      fontSize: 24,
      fontWeight: 'bold',
    },
    units: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      fontSize: 16,
    },
    noDataText: {
      fontSize: 16,
      padding: theme.spacing(6),
      paddingTop: 0,

      '&.CENTER_ALIGN': {
        textAlign: 'center',
      },
      '&.RIGHT_ALIGN': {
        textAlign: 'right',
      },
    },
  });

type PassedProps = {
  title?: string;
  alignment?: string;
  loading?: boolean;
  emptyText?: string;
};

type Props = PassedProps & WithStyles<typeof styles>;

class SingleNumberDisplayEmpty extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.renderTitle()}
        {this.renderNumber()}
        {this.renderNoDataText()}
      </div>
    );
  }

  renderTitle = () => {
    const { classes, title, alignment } = this.props;
    if (title === undefined) return;

    return (
      <div className={cx(classes.titleContainer, alignment)}>
        <div className={cx(classes.title, GLOBAL_STYLE_CLASSNAMES.text.h3.base)}>{title}</div>
      </div>
    );
  };

  renderNumber = () => {
    const { classes, title, alignment, loading } = this.props;

    if (loading) {
      return (
        <div className={cx(classes.numberContainer, { noTitle: title === undefined }, alignment)}>
          <div className={classes.numberUnitContainer}>
            <Spinner size={30} />
          </div>
        </div>
      );
    }

    return (
      <div className={cx(classes.numberContainer, { noTitle: title === undefined }, alignment)}>
        <div className={classes.numberUnitContainer}>
          <div className={cx(classes.number, GLOBAL_STYLE_CLASSNAMES.text.primaryColor)}>-</div>
        </div>
      </div>
    );
  };

  renderNoDataText = () => {
    const { classes, alignment, emptyText } = this.props;
    return (
      <div className={cx(classes.noDataText, alignment, GLOBAL_STYLE_CLASSNAMES.text.primaryColor)}>
        {emptyText || DEFAULT_EMPTY_TEXT}
      </div>
    );
  };
}

export default withStyles(styles)(SingleNumberDisplayEmpty);
