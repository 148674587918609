/** @format */

import React from 'react';
import _ from 'underscore';
import { cloneDeep } from 'lodash';

import XRegExp from 'xregexp';

import {
  FilterClause,
  PivotOperationInstructions,
  OPERATION_TYPES,
  VISUALIZATION_OPERATIONS,
  V2_VISUALIZATION_OPERATIONS,
  FilterValueDateType,
  FilterValueRelativeDateType,
  NumberDisplayFormat,
  DateDisplayFormat,
  StringDisplayFormat,
  BooleanDisplayFormat,
  SortClause,
  AggedChartColumnInfo,
  KPIPeriodColumnInfo,
  NumberDisplayOptions,
  GradientType,
  FilterValueSourceType,
  NumberDisplayDisplayType,
} from 'constants/types';
import {
  DataPanelTemplate,
  FilterOperation,
  PivotOperation,
  VisualizeOperation,
  DataPanelConfigReducerState,
  Dataset,
} from 'actions/types';
import {
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_RELATIVE_PICKER,
  FILTER_OPS_MULTISELECT,
} from 'constants/dataPanelEditorConstants';
import {
  DATE_PART_INPUT_AGG,
  DATE_TYPES,
  NUMBER_TYPES,
  PeriodRangeTypes,
  STRING,
  V2_VIZ_INSTRUCTION_TYPE,
} from 'constants/dataConstants';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { exhaustiveCheck } from 'utils/general';
import { instructionsReadyToDisplay } from 'pages/dashboardPage/charts/numberTrend';

const variableRegex = /(\{\{(?<variable>.+?)\}\})/g;

export const getDatapanelConfigReducerState = (dpt: DataPanelTemplate) => ({
  filterOperation: dpt.filter_op,
  pivotOperation: dpt.group_by_op,
  visualizeOperation: dpt.visualize_op,
  sortOperation: dpt.sort_op,
});

export type AdditionalRecomputeData = {
  columnNamesWithGradient?: string[];
};

export type ShouldRecomputeDataPanelResult = {
  shouldRecompute: boolean;
  changedOpType?: OPERATION_TYPES;
};

export const shouldRecomputeDataForDataPanel = (
  prevConfig: DataPanelConfigReducerState,
  newConfig: DataPanelConfigReducerState,
): ShouldRecomputeDataPanelResult => {
  const result: ShouldRecomputeDataPanelResult = {
    shouldRecompute: false,
    changedOpType: undefined,
  };
  const prevConfigClean = cleanConfig(cloneDeep(prevConfig));
  const newConfigClean = cleanConfig(cloneDeep(newConfig));

  if (
    prevConfigClean.filterOperation?.instructions.matchOnAll !==
      newConfigClean.filterOperation?.instructions.matchOnAll ||
    differentFilterOps(
      prevConfigClean.filterOperation?.instructions.filterClauses,
      newConfigClean.filterOperation?.instructions.filterClauses,
    )
  ) {
    result.shouldRecompute = true;
    result.changedOpType = OPERATION_TYPES.FILTER;
  }

  if (
    differentSortOps(
      prevConfigClean.sortOperation?.instructions.sortColumns,
      newConfigClean.sortOperation?.instructions.sortColumns,
    )
  ) {
    result.shouldRecompute = true;
    result.changedOpType = OPERATION_TYPES.SORT;
  }

  if (
    differentPivotOps(
      prevConfigClean.pivotOperation?.instructions,
      newConfigClean.pivotOperation?.instructions,
    )
  ) {
    result.shouldRecompute = true;
    result.changedOpType = OPERATION_TYPES.GROUP_BY;
  }

  if (
    visualizeOperationNeedsRecompute(
      prevConfigClean.visualizeOperation,
      newConfigClean.visualizeOperation,
    )
  ) {
    result.shouldRecompute = true;
    result.changedOpType = undefined;
  }

  if (!isDataPanelReadyToCompute(newConfigClean)) {
    result.shouldRecompute = false;
    result.changedOpType = undefined;
  }

  return result;
};

export const shouldRecomputeSecondaryDataForDataPanel = (
  prevConfig: DataPanelConfigReducerState,
  newConfig: DataPanelConfigReducerState,
) => {
  let shouldRecompute = false;

  if (isGradientAddedToTable(prevConfig.visualizeOperation, newConfig.visualizeOperation)) {
    shouldRecompute = true;
  }

  if (isGoalQueryAddedToTable(prevConfig.visualizeOperation, newConfig.visualizeOperation)) {
    shouldRecompute = true;
  }

  return shouldRecompute;
};

export const isDataPanelReadyToCompute = (config: DataPanelConfigReducerState): boolean => {
  if (!config.visualizeOperation) return false;

  const { operation_type: operationType, instructions } = config.visualizeOperation;

  if (V2_VISUALIZATION_OPERATIONS.indexOf(config.visualizeOperation.operation_type) > -1) {
    if (
      operationType === OPERATION_TYPES.VISUALIZE_TABLE ||
      operationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER
    ) {
      return true;
    } else if (
      operationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
      operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
    ) {
      return !!instructions.V2_KPI?.aggColumn;
    } else if (operationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
      const trendConfig = instructions.V2_KPI_TREND;
      return instructionsReadyToDisplay(trendConfig);
    } else if (operationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
      return (
        !!instructions.V2_BOX_PLOT?.groupingColumn &&
        !!instructions.V2_BOX_PLOT?.calcColumns?.length
      );
    } else {
      const chartInstructions = config.visualizeOperation.instructions.V2_TWO_DIMENSION_CHART;

      if (chartInstructions?.categoryColumn?.bucket?.id === DATE_PART_INPUT_AGG) {
        return !!chartInstructions.categoryColumn.bucketElemId;
      }

      return !!chartInstructions?.aggColumns?.length && !!chartInstructions.categoryColumn;
    }
  }

  return true;
};

// If the visualization switches from being a table to visualization, or vise versa
// then recompute the dpt
export const visualizeOperationNeedsRecompute = (
  oldVizOp?: VisualizeOperation,
  newVizOp?: VisualizeOperation,
) => {
  if (oldVizOp === undefined) return newVizOp !== undefined;
  if (newVizOp === undefined) return oldVizOp !== undefined;

  const oldVizIsViz = VISUALIZATION_OPERATIONS.indexOf(oldVizOp.operation_type) > -1;
  const newVizIsViz = VISUALIZATION_OPERATIONS.indexOf(newVizOp.operation_type) > -1;
  if (oldVizIsViz !== newVizIsViz) return true;

  if (V2_VISUALIZATION_OPERATIONS.indexOf(newVizOp.operation_type) > -1) {
    if (
      V2_VIZ_INSTRUCTION_TYPE[oldVizOp.operation_type] !==
      V2_VIZ_INSTRUCTION_TYPE[newVizOp.operation_type]
    )
      return true;

    return !_.isEqual(
      {
        V2_TWO_DIMENSION_CHART: {
          aggColumns: removeFriendlyNameFromAggs(
            newVizOp.instructions.V2_TWO_DIMENSION_CHART?.aggColumns,
          ),
          categoryColumn: newVizOp.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn,
          colorColumn: newVizOp.instructions.V2_TWO_DIMENSION_CHART?.colorColumn,
        },
        V2_KPI: {
          aggColumn: newVizOp.instructions.V2_KPI?.aggColumn,
          trendColumn: newVizOp.instructions.V2_KPI?.trendColumn,
        },
        V2_BOX_PLOT: {
          groupingColumn: newVizOp.instructions.V2_BOX_PLOT?.groupingColumn,
          calcColumns: newVizOp.instructions.V2_BOX_PLOT?.calcColumns,
        },
        V2_KPI_TREND: {
          aggColumn: newVizOp.instructions.V2_KPI_TREND?.aggColumn,
          periodColumn: removeDatesIfNotCompleteOrNotCustom(
            newVizOp.instructions.V2_KPI_TREND?.periodColumn,
          ),
          periodComparisonRange: newVizOp.instructions.V2_KPI_TREND?.periodComparisonRange,
          trendGrouping: newVizOp.instructions.V2_KPI_TREND?.trendGrouping,
          hideTrendLines: newVizOp.instructions.V2_KPI_TREND?.hideTrendLines,
        },
      },
      {
        V2_TWO_DIMENSION_CHART: {
          aggColumns: removeFriendlyNameFromAggs(
            oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.aggColumns,
          ),
          categoryColumn: oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn,
          colorColumn: oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.colorColumn,
        },
        V2_KPI: {
          aggColumn: oldVizOp.instructions.V2_KPI?.aggColumn,
          trendColumn: oldVizOp.instructions.V2_KPI?.trendColumn,
        },
        V2_BOX_PLOT: {
          groupingColumn: oldVizOp.instructions.V2_BOX_PLOT?.groupingColumn,
          calcColumns: oldVizOp.instructions.V2_BOX_PLOT?.calcColumns,
        },
        V2_KPI_TREND: {
          aggColumn: oldVizOp.instructions.V2_KPI_TREND?.aggColumn,
          periodColumn: removeDatesIfNotCompleteOrNotCustom(
            oldVizOp.instructions.V2_KPI_TREND?.periodColumn,
          ),
          periodComparisonRange: oldVizOp.instructions.V2_KPI_TREND?.periodComparisonRange,
          trendGrouping: oldVizOp.instructions.V2_KPI_TREND?.trendGrouping,
          hideTrendLines: oldVizOp.instructions.V2_KPI_TREND?.hideTrendLines,
        },
      },
    );
  }
  return false;
};

export const isSecondaryDataRequired = (displayOptions?: NumberDisplayOptions) => {
  const { displayType, displayTypeOptions, format, gradientType, useColumnMaxForGoal } =
    displayOptions || {};

  const hasGradient = [GradientType.DIVERGING, GradientType.LINEAR].includes(
    gradientType ?? GradientType.NONE,
  );
  const hasPercentColumnMaxGoal = format === NumberDisplayFormat.PERCENT && useColumnMaxForGoal;
  const hasProgressBarColumnMaxGoal =
    displayType === NumberDisplayDisplayType.PROGRESS_BAR &&
    displayTypeOptions?.useColumnMaxForProgressBarGoal;

  return hasGradient || hasPercentColumnMaxGoal || hasProgressBarColumnMaxGoal;
};

const isGradientAddedToTable = (oldVizOp?: VisualizeOperation, newVizOp?: VisualizeOperation) => {
  let shouldRefetch = false;
  const oldSchemaDisplayOptions = oldVizOp?.instructions.VISUALIZE_TABLE.schemaDisplayOptions || {};
  const newSchemaDisplayOptions = newVizOp?.instructions.VISUALIZE_TABLE.schemaDisplayOptions || {};

  Object.keys(newSchemaDisplayOptions).forEach((columnName) => {
    /**
     * Casting as union type with undefined because the column may not actually be a number column, so
     * we should use optional chaining when accessing gradientType, otherwise we may get a runtime error.
     * Unioning with undefined forces us to use optional chaining.
     */
    const oldDisplayOptions = oldSchemaDisplayOptions[columnName] as
      | NumberDisplayOptions
      | undefined;
    const newDisplayOptions = newSchemaDisplayOptions[columnName] as
      | NumberDisplayOptions
      | undefined;

    // If secondary data was already required, we already fetched it
    if (isSecondaryDataRequired(oldDisplayOptions)) {
      return;
    }

    const oldSchemaGradientType = oldDisplayOptions?.gradientType;
    const newSchemaGradientType = newDisplayOptions?.gradientType;

    if (
      (oldSchemaGradientType === GradientType.NONE || oldSchemaGradientType === undefined) &&
      [GradientType.DIVERGING, GradientType.LINEAR].includes(
        newSchemaGradientType || GradientType.NONE,
      )
    ) {
      shouldRefetch = true;
    }
  });

  return shouldRefetch;
};

const isGoalQueryAddedToTable = (oldVizOp?: VisualizeOperation, newVizOp?: VisualizeOperation) => {
  let isProgressBarQueryAdded = false;
  let isChangedToFormatWithGoalQuery = false;
  const oldSchemaDisplayOptions = oldVizOp?.instructions.VISUALIZE_TABLE.schemaDisplayOptions || {};
  const newSchemaDisplayOptions = newVizOp?.instructions.VISUALIZE_TABLE.schemaDisplayOptions || {};

  Object.keys(newSchemaDisplayOptions).forEach((columnName) => {
    /**
     * Casting as union type with undefined because the column may not actually be a number column, so
     * we should use optional chaining when accessing gradientType, otherwise we may get a runtime error.
     * Unioning with undefined forces us to use optional chaining.
     */
    const oldDisplayOptions = oldSchemaDisplayOptions[columnName] as
      | NumberDisplayOptions
      | undefined;
    const newDisplayOptions = newSchemaDisplayOptions[columnName] as
      | NumberDisplayOptions
      | undefined;

    // If secondary data was already required, we already fetched it
    if (isSecondaryDataRequired(oldDisplayOptions)) {
      return;
    }

    const {
      displayType: oldSchemaDisplayType,
      displayTypeOptions: oldSchemaDisplayTypeOptions,
      format: oldSchemaFormat,
      useColumnMaxForGoal: oldSchemaUsesColumnMax,
    } = oldDisplayOptions ?? {};
    const {
      displayType: newSchemaDisplayType,
      displayTypeOptions: newSchemaDisplayTypeOptions,
      format: newSchemaFormat,
      useColumnMaxForGoal: newSchemaUsesColumnMax,
    } = newDisplayOptions ?? {};

    if (
      (!oldSchemaUsesColumnMax && newSchemaUsesColumnMax) ||
      (!oldSchemaDisplayTypeOptions?.useColumnMaxForProgressBarGoal &&
        newSchemaDisplayTypeOptions?.useColumnMaxForProgressBarGoal)
    ) {
      isProgressBarQueryAdded = true;
    }

    if (
      oldSchemaFormat !== NumberDisplayFormat.PERCENT &&
      newSchemaFormat === NumberDisplayFormat.PERCENT &&
      newSchemaUsesColumnMax
    ) {
      isChangedToFormatWithGoalQuery = true;
    }

    if (
      oldSchemaDisplayType !== NumberDisplayDisplayType.PROGRESS_BAR &&
      newSchemaDisplayType === NumberDisplayDisplayType.PROGRESS_BAR &&
      newSchemaDisplayTypeOptions?.useColumnMaxForProgressBarGoal
    ) {
      isChangedToFormatWithGoalQuery = true;
    }
  });

  return isProgressBarQueryAdded || isChangedToFormatWithGoalQuery;
};

const removeFriendlyNameFromAggs = (aggCols?: AggedChartColumnInfo[]) => {
  return _.map(aggCols || [], (aggCol) => ({
    agg: aggCol.agg,
    column: {
      ...aggCol.column,
      friendly_name: undefined,
    },
  }));
};

const removeDatesIfNotCompleteOrNotCustom = (periodColumn?: KPIPeriodColumnInfo) => {
  if (!periodColumn) return;

  if (
    periodColumn.periodRange === PeriodRangeTypes.CUSTOM_RANGE ||
    periodColumn.periodRange === PeriodRangeTypes.DATE_RANGE_INPUT ||
    periodColumn.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN
  ) {
    return periodColumn;
  } else {
    return {
      column: periodColumn.column,
      periodRange: periodColumn.periodRange,
      trendDateOffset: periodColumn.trendDateOffset,
    };
  }
};

// Removes configs that do not impact the resulting table (incomplete configs)
export const cleanConfig = (config: DataPanelConfigReducerState) => {
  if (config.filterOperation) cleanFilterConfig(config.filterOperation);
  if (config.pivotOperation) cleanPivotConfig(config.pivotOperation);
  if (config.visualizeOperation) cleanVisualizeConfig(config.visualizeOperation);
  return config;
};

const cleanFilterConfig = (filterOperation: FilterOperation) => {
  filterOperation.instructions.filterClauses = _.reject(
    filterOperation.instructions.filterClauses,
    isSingleFilterClauseIncomplete,
  );
};

export const isSingleFilterClauseIncomplete = (clause: FilterClause) => {
  if (!clause.filterColumn || !clause.filterOperation) return true;

  // If the filter is a variable-based filter value, then it will be incomplete
  // if the variable is not yet specified
  if (clause.filterValueSource === FilterValueSourceType.VARIABLE) {
    return clause.filterValueVariableId === undefined;
  }

  if (FILTER_OPS_MULTISELECT.has(clause.filterOperation.id)) {
    if (clause.filterValue === undefined) {
      return true;
    } else {
      try {
        const filterValue = JSON.parse(clause.filterValue as string);
        return !(Array.isArray(filterValue) || filterValue.length > 0);
      } catch {
        return true;
      }
    }
  }

  if (
    !FILTER_OPS_NO_VALUE.has(clause.filterOperation.id) &&
    (clause.filterValue === undefined || clause.filterValue === null)
  )
    return true;

  if (FILTER_OPS_DATE_RANGE_PICKER.has(clause.filterOperation.id)) {
    const rangeValue = clause.filterValue as FilterValueDateType;
    return !rangeValue.startDate || !rangeValue.endDate;
  } else if (FILTER_OPS_DATE_PICKER.has(clause.filterOperation.id)) {
    const dateValue = clause.filterValue as FilterValueDateType;
    return !dateValue.startDate;
  } else if (FILTER_OPS_RELATIVE_PICKER.has(clause.filterOperation.id)) {
    const relativeDateValue = clause.filterValue as FilterValueRelativeDateType;
    return !relativeDateValue.number || !relativeDateValue.relativeTimeType;
  }

  return false;
};

export const cleanPivotConfig = (pivotOperation: PivotOperation) => {
  pivotOperation.instructions.pivotedOnCols = _.filter(
    pivotOperation.instructions.pivotedOnCols,
    (col) => !!col.name,
  );

  pivotOperation.instructions.aggregations = _.reject(
    pivotOperation.instructions.aggregations,
    (agg) => !agg.aggedOnColumn || !agg.type,
  );
  return pivotOperation;
};

const cleanVisualizeConfig = (visualizeOperation: VisualizeOperation) => {
  visualizeOperation.instructions.VISUALIZE_LINE_OR_BAR_CHART.lineColumns = _.filter(
    visualizeOperation.instructions.VISUALIZE_LINE_OR_BAR_CHART.lineColumns,
    (col) => !!col && !!col.column && !!col.column.name,
  );
};

export const differentFilterOps = (prevClauses?: FilterClause[], newClauses?: FilterClause[]) => {
  if (prevClauses === undefined) return newClauses !== undefined;
  if (newClauses === undefined) return prevClauses !== undefined;

  return listsAreDifferent(prevClauses, newClauses);
};

export const differentSortOps = (prevClauses?: SortClause[], newClauses?: SortClause[]) => {
  if (prevClauses === undefined) return newClauses !== undefined;
  if (newClauses === undefined) return prevClauses !== undefined;

  return listsAreDifferent(prevClauses, newClauses);
};

export const differentPivotOps = (
  prevInst?: PivotOperationInstructions,
  newInst?: PivotOperationInstructions,
) => {
  if (prevInst === undefined) return newInst !== undefined;
  if (newInst === undefined) return prevInst !== undefined;

  return (
    listsAreDifferent(prevInst.pivotedOnCols, newInst.pivotedOnCols) ||
    listsAreDifferent(prevInst.aggregations, newInst.aggregations)
  );
};

export const listsAreDifferent = (list1: unknown[], list2: unknown[]) => {
  if (list1.length !== list2.length) return true;

  return _.any(_.times(list1.length, (i) => !_.isEqual(list1[i], list2[i])));
};

export const csvDownloadSuccessText = (signedUrl: string) => {
  return (
    <div>
      If the CSV didn{"'"}t automatically download, click on
      <a href={signedUrl} style={{ paddingLeft: 4 }} target="_blank" rel="noopener noreferrer">
        this link
      </a>
      .
    </div>
  );
};

export const getDefaultFormatForType = (type: string) => {
  if (NUMBER_TYPES.has(type)) {
    return NumberDisplayFormat.NORMAL;
  } else if (DATE_TYPES.has(type)) {
    return DateDisplayFormat.NORMAL;
  } else if (type === STRING) {
    return StringDisplayFormat.NORMAL;
  }
};

export const getOppositeBooleanIcon = (format: BooleanDisplayFormat) => {
  switch (format) {
    case BooleanDisplayFormat.TICK:
    case BooleanDisplayFormat.CROSS:
      return BooleanDisplayFormat.BLANK;
    case BooleanDisplayFormat.BLANK:
      return BooleanDisplayFormat.TICK;
    default:
      exhaustiveCheck();
  }
};

export const isJsOrVariable = (s: string) => {
  try {
    JSON.parse(s);
    return true;
  } catch {
    const re = RegExp(/(\{\{.+?\}\})/);
    return re.test(s);
  }
};

export const resolveJsOrVariable = (s: string | undefined, variables: DashboardVariableMap) => {
  if (!s) return;
  try {
    JSON.parse(s);
    return true;
  } catch {
    const match = s.match(/(\{\{(?<variable>.+?)\}\})/);
    if (!match?.groups?.variable) return;
    const varName = match.groups.variable.trim();
    return variables[varName];
  }
};

export const replaceTemplatesWithValues = (
  s: string,
  variables: DashboardVariableMap,
  dashboardDatasets?: { [datasetId: string]: Dataset },
) => {
  const datasetNameToDataset: { [datasetName: string]: Dataset } = _.indexBy(
    _.values(dashboardDatasets ?? {}),
    'table_name',
  );

  //@ts-ignore
  XRegExp.forEach(s, variableRegex, (match) => {
    const varName = match[2]?.trim();
    if (varName) {
      let replacement = varName;
      if (varName in variables) replacement = String(variables[varName]);
      else if (dashboardDatasets) {
        const [datasetName, columnName, ...other] = varName.split('.');

        // nesting further than table.column_name isn't supported
        if (other.length === 0 && datasetName in datasetNameToDataset) {
          const dataset = datasetNameToDataset[datasetName];

          // just use the value in the first row because we're leaving it to the
          // customer to do any aggregation or work in SQL before passing the table
          // to this component
          replacement =
            // explicitly check undefined for the actual value because 0 is falsy,
            // but would be a valid value we want to display, for example
            dataset._rows && dataset._rows[0] && dataset._rows[0][columnName] !== undefined
              ? String(dataset._rows[0][columnName])
              : '';
        }
      }
      s = s.replace(match[0], replacement);
    }
  });
  return s;
};

export const isVisualizationSupportedForSourceType = (
  operationType: OPERATION_TYPES,
  sourceType?: string,
): [boolean, string] => {
  if (!sourceType) return [false, 'No source type'];

  switch (operationType) {
    case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
      switch (sourceType) {
        case 'mysql':
          return [false, 'Unsupported for MySQL'];
      }
  }

  return [true, ''];
};

export const getQueryTablesReferencedByTextElement = (
  text: string,
  datasets: { [datasetId: string]: Dataset },
) => {
  const datasetNameToDataset: { [datasetName: string]: Dataset } = _.indexBy(
    _.values(datasets),
    'table_name',
  );

  const queryTables: { id: string; name: string }[] = [];

  //@ts-ignore
  XRegExp.forEach(text, variableRegex, (match) => {
    const varName = match[2]?.trim();
    if (varName) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [datasetName, _, ...other] = varName.split('.');

      // nesting further than table.column_name isn't supported
      if (other.length === 0 && datasetName in datasetNameToDataset) {
        const dataset = datasetNameToDataset[datasetName];

        queryTables.push({ id: dataset.id, name: dataset.table_name });
      }
    }
  });

  return queryTables;
};

export const dataPanelRequiresPrimaryData = (dpt: DataPanelConfigReducerState) => {
  if (dpt.visualizeOperation?.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
    return !dpt.visualizeOperation.instructions.V2_KPI_TREND?.hideTrendLines;
  }

  return true;
};
