/** @format */

import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.grey100,
    color: theme.palette.ds.blue,

    padding: theme.spacing(3),
    borderRadius: 4,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&:hover': {
      cursor: 'pointer',

      backgroundColor: theme.palette.ds.hovered.grey100,
      color: theme.palette.ds.hovered.blue,

      textDecoration: 'none',
    },

    '&:active': {
      backgroundColor: theme.palette.ds.pressed.grey100,
      color: theme.palette.ds.pressed.blue,
    },
  },
  calloutText: {},
  rightIcon: {
    marginLeft: theme.spacing(3),
  },
}));

export type Props = {
  /**
   * Classname override
   */
  className?: string;

  /**
   * Text to be displayed in the link
   */
  text: string;

  /**
   * External URL location to open when clicked on (i.e. https://www.google.com/)
   */
  url?: string;

  /**
   * Explo URL path to open when clicked on (i.e. /home or /datasources)
   */
  to?: string;

  /**
   * Override to handle the onClick action (to open modals or trigger other actions)
   */
  onClick?: () => void;
};

const CalloutLink: React.FC<Props> = ({ className, text, to, url, onClick }) => {
  const classes = useStyles();

  const rootClasses = cx(classes.root, className);

  const calloutBody = (
    <>
      <div className={classes.calloutText}>{text}</div>
      <Icon icon="arrow-right" className={classes.rightIcon} />
    </>
  );

  if (to) {
    return (
      <Link className={rootClasses} to={to}>
        {calloutBody}
      </Link>
    );
  } else if (url) {
    return (
      <a className={rootClasses} href={url} target="_blank" rel="noopener noreferrer">
        {calloutBody}
      </a>
    );
  } else {
    return (
      <div className={rootClasses} onClick={() => onClick?.()}>
        {calloutBody}
      </div>
    );
  }
};

export default CalloutLink;
