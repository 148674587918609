/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';
import { Icon, Popover, Position } from '@blueprintjs/core';

import ChartSelectPopover from './ChartSelectPopover';

import { VIZ_TO_NAME, OP_TYPE_TO_BP3_ICON } from 'constants/dataConstants';
import { OPERATION_TYPES } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  chartTypeBtn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),

    '&:hover': {
      cursor: 'pointer',
    },
  },
  chattTypeInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  chartTypeIcon: {
    marginRight: theme.spacing(2),
    width: 30,
  },
  text: {},
}));

export type Props = {
  selectedChartType: string;
  sourceType?: string;
};

export default function VisualizationTypeSelector({ selectedChartType, sourceType }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Popover
        fill
        minimal
        captureDismiss
        position={Position.BOTTOM_LEFT}
        content={
          <ChartSelectPopover selectedChartType={selectedChartType} sourceType={sourceType} />
        }>
        <div className={classes.chartTypeBtn}>
          <div className={classes.chattTypeInfo}>
            <div className={classes.chartTypeIcon}>
              <Icon
                iconSize={20}
                icon={OP_TYPE_TO_BP3_ICON[selectedChartType as OPERATION_TYPES]}
              />
            </div>
            <div className={classes.text}>{VIZ_TO_NAME[selectedChartType]}</div>
          </div>
          <Icon icon="expand-all" />
        </div>
      </Popover>
    </div>
  );
}
