/** @format */
import React, { ReactNode } from 'react';
import produce from 'immer';
import { useTheme, makeStyles } from '@material-ui/core/index';

import { GlobalStyleConfig } from './types';
import { DEFAULT_OUTLINE_COLOR, GLOBAL_STYLE_CLASSNAMES } from './constants';
import { getCalculatedStyles, loadFonts } from './helpers';
import { GlobalStylesContext } from './GlobalStylesContext';

type Props = {
  globalStyleConfig: GlobalStyleConfig;
  children: (className: string) => ReactNode;
};

export const GlobalStylesProvider = ({ children, globalStyleConfig }: Props) => {
  const theme = useTheme();
  loadFonts(globalStyleConfig);

  const {
    base: {
      backgroundColorStyle: { backgroundColorStyle: baseBackgroundColorStyle },
      actionColor: {
        default: {
          backgroundColorStyle: defaultActionColorBackgroundColorStyle,
          blackOrWhiteColorStyle: defaultActionColorBlackOrWhiteColorStyle,
          borderColorStyle: defaultActionColorBorderColorStyle,
          boxShadowStyle: defaultActionColorBoxShadowStyle,
          boxShadowImportantStyle: defaultActionColorBoxShadowImportantStyle,
        },
        buttonColor: {
          colorStyle: buttonColorStyle,
          backgroundColorStyle: buttonColorBackgroundColorStyle,
          backgroundColorImportantStyle: buttonColorBackgroundColorImportantStyle,
          blackOrWhiteColorStyle: buttonColorBlackOrWhiteColorStyle,
          buttonHoverStyle,
          buttonActiveStyle,
          buttonActiveHoverStyle,
        },
        interactionStateColor: {
          borderColorStyle: interactionStateColorBorderColorStyle,
          boxShadowStyle: interactionStateColorBoxShadowStyle,
          boxShadowImportantStyle: interactionStateColorBoxShadowImportantStyle,
        },
      },
    },
    container: {
      fill: {
        backgroundColorStyle: containerFillBackgroundColorStyle,
        blackOrWhiteColorStyle: containerFillBlackOrWhiteColorStyle,
        offsetBackgroundColorStyle: containerFillOffsetBackgroundColorStyle,
      },
      outline: {
        borderStyle: containerOutlineBorderStyle,
        boxShadowStyle: containerOutlineBoxShadowStyle,
        boxShadowInsetStyle: containerOutlineBoxShadowInsetStyle,
        boxShadowInsetImportantStyle: containerOutlineBoxShadowInsetImportantStyle,
      },
      shadow: { shadowStyle: containerShadowStyle },
      padding: { paddingStyle: containerPaddingStyle },
      cornerRadius: {
        default: {
          borderRadiusStyle: defaultContainerCornerRadiusBorderRadiusStyle,
          borderTopLeftRadiusStyle: defaultContainerCornerRadiusBorderTopLeftRadiusStyle,
          borderTopRightRadiusStyle: defaultContainerCornerRadiusBorderTopRightRadiusStyle,
          borderBottomRightRadiusStyle: defaultContainerCornerRadiusBorderBottomRightRadiusStyle,
          borderBottomLeftRadiusStyle: defaultContainerCornerRadiusBorderBottomLeftRadiusStyle,
        },
        buttonCornerRadius: { borderRadiusStyle: buttonCornerRadiusBorderRadiusStyle },
      },
    },
    text: {
      primaryColor: { colorStyle: primaryTextColorStyle },
      secondaryColor: {
        colorStyle: textSecondaryColorStyle,
        borderLeftColorStyle: textSecondaryColorBorderLeftColorStyle,
        borderRightColorStyle: textSecondaryColorBorderRightColorStyle,
        borderTopColorStyle: textSecondaryColorBorderTopColorStyle,
      },
      overrides: {
        h1: { primaryStyle: h1TextPrimaryStyle },
        h2: { primaryStyle: h2TextPrimaryStyle },
        h3: { primaryStyle: h3TextPrimaryStyle, secondaryStyle: h3TextSecondaryStyle },
        body: {
          primaryStyle: bodyTextPrimaryStyle,
          primaryWithoutColorStyle: bodyTextPrimaryWithoutColorStyle,
          secondaryColorStyle: bodyTextSecondaryColorStyle,
          secondaryStyle: bodyTextSecondaryStyle,
        },
        smallBody: {
          primaryStyle: smallBodyTextPrimaryStyle,
          secondaryStyle: smallBodyTextSecondaryStyle,
        },
        smallHeading: {
          secondaryStyle: smallHeadingTextSecondaryStyle,
          secondaryWithoutColorStyle: smallHeadingTextSecondaryWithoutColorStyle,
          secondaryFillImportantStyle: smallHeadingSecondaryFillImportantStyle,
        },
      },
    },
  } = getCalculatedStyles(globalStyleConfig, theme);

  const useStyles = makeStyles({
    core: {
      /**
       * Base // Background Color
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.backgroundColor.backgroundColor}`]: {
        ...baseBackgroundColorStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.backgroundColor.datePickerHeaderBackgroundColor}`]: {
        '& .react-datepicker__header': {
          ...baseBackgroundColorStyle,
        },
      },
      /**
       * Base // Action Color // Default
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.backgroundColor}`]: {
        ...defaultActionColorBackgroundColorStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownInputMenuItem}`]: {
        '&.bp3-active': {
          ...defaultActionColorBackgroundColorStyle,
          ...defaultActionColorBlackOrWhiteColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownFilterInputOutline}`]: {
        '& .bp3-input:focus': {
          ...defaultActionColorBoxShadowStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownInputBorder}`]: {
        '&:active:not(.disabled)': {
          ...defaultActionColorBorderColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.multiSelectInputOutlineActive}`]: {
        '& .bp3-tag-input.bp3-active': {
          ...defaultActionColorBoxShadowImportantStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.multiSelectInputTag}`]: {
        '& .bp3-tag': {
          ...defaultActionColorBackgroundColorStyle,
          ...defaultActionColorBlackOrWhiteColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.datePickerInputBorderActive}`]: {
        '&:active:not(.disabled).bp3-active': {
          ...defaultActionColorBoxShadowImportantStyle,
        },

        '&:focus:not(.disabled)': {
          ...defaultActionColorBoxShadowImportantStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.buttonBorderActive}`]: {
        '&.bp3-button': {
          ...defaultActionColorBoxShadowImportantStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.datePickerDaySelected}`]: {
        '& .react-datepicker__day--keyboard-selected': {
          ...defaultActionColorBackgroundColorStyle,
          ...defaultActionColorBlackOrWhiteColorStyle,
        },
        '& .react-datepicker__day--selected': {
          ...defaultActionColorBackgroundColorStyle,
          ...defaultActionColorBlackOrWhiteColorStyle,
        },
        '& .react-datepicker__day--in-selecting-range,.react-datepicker__day--in-range': {
          ...defaultActionColorBackgroundColorStyle,
          ...defaultActionColorBlackOrWhiteColorStyle,
        },
        '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected': {
          ...defaultActionColorBackgroundColorStyle,
          ...defaultActionColorBlackOrWhiteColorStyle,
        },
      },
      /**
       * Base // Action Color // Button
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.backgroundColor}`]: {
        ...buttonColorBackgroundColorStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.color}`]: {
        ...buttonColorStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonBackgroundColor}`]: {
        '&.bp3-button:not(.bp3-disabled)': {
          ...buttonColorBackgroundColorStyle,
          ...buttonColorBlackOrWhiteColorStyle,
          '& .bp3-icon': {
            ...buttonColorBlackOrWhiteColorStyle,
          },
        },
        '&.bp3-button:hover:not(.bp3-disabled)': {
          backgroundImage: 'none',
          ...buttonHoverStyle,
        },
        '&.bp3-button:active:not(.bp3-disabled)': {
          ...buttonActiveStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonBackgroundColorImportant}`]: {
        '&.bp3-button': {
          ...buttonColorBackgroundColorImportantStyle,
          ...buttonColorBlackOrWhiteColorStyle,
          '& .bp3-icon': {
            ...buttonColorBlackOrWhiteColorStyle,
          },
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonColor}`]: {
        '&.bp3-button': {
          ...buttonColorStyle,
          '& .bp3-icon': {
            ...buttonColorStyle,
          },
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.shareButtonHoverColor}`]: {
        '&.bp3-button.bp3-disabled.bp3-button:hover': {
          ...buttonColorBackgroundColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.toggleSwitchInteraction}`]: {
        '&.bp3-button:hover': {
          backgroundImage: 'none',
          ...buttonHoverStyle,
        },
        '&.bp3-active': {
          ...buttonActiveStyle,
        },
        '&.bp3-active:hover': {
          ...buttonActiveHoverStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.toggleBackgroundColor}`]: {
        '&.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator': {
          ...buttonColorBackgroundColorStyle,
        },
      },
      /**
       * Base // Action Color // Interaction States
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.dropdownInputBorderHover}`]: {
        '&:hover:not(.disabled)': {
          ...interactionStateColorBorderColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.datePickerInputBorderHover}`]: {
        '&:hover:not(.disabled)': {
          ...interactionStateColorBoxShadowImportantStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.multiSelectInputBorderHover}`]: {
        '& .bp3-tag-input:hover:not(.disabled)': {
          ...interactionStateColorBoxShadowStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.buttonBorderHover}`]: {
        '&.bp3-button:hover': {
          ...interactionStateColorBorderColorStyle,
        },
      },
      /**
       * Container // Fill
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor}`]: {
        ...containerFillBackgroundColorStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor}`]: {
        ...containerFillOffsetBackgroundColorStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.fill.buttonBackgroundColor}`]: {
        '&.bp3-button': {
          backgroundImage: 'none',
          ...containerFillBackgroundColorStyle,
          ...containerFillBlackOrWhiteColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.fill.datePickerMonthContainerFill}`]: {
        '& .react-datepicker__month-container': {
          ...containerFillBackgroundColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.fill.datePickerTimeContainerFill}`]: {
        '& .react-datepicker__time': {
          ...containerFillBackgroundColorStyle,
        },
      },
      /**
       * Container // Outline
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.outline.exportModalBorder}`]: {
        '& .bp3-popover': {
          ...containerOutlineBorderStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.outline.dropdownInputBorder}`]: {
        '&.bp3-button': {
          ...containerOutlineBorderStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.outline.popoverBorder}`]: {
        '& .bp3-transition-container': {
          '& .bp3-popover': {
            ...containerOutlineBorderStyle,
          },
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.outline.multiSelectInputBorder}`]: {
        '& .bp3-tag-input': {
          ...containerOutlineBoxShadowInsetStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.outline.dropdownFilterInputBorder}`]: {
        '& .bp3-input': {
          ...containerOutlineBoxShadowStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.outline.buttonBorder}`]: {
        '&.bp3-button': {
          boxShadow: 'none',
          ...containerOutlineBorderStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.outline.boxShadow}`]: {
        ...containerOutlineBoxShadowInsetImportantStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.outline.border}`]: {
        ...containerOutlineBorderStyle,
      },
      /**
       * Container // Shadow
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow}`]: {
        ...containerShadowStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.shadow.dropdownInputShadow}`]: {
        '&.bp3-button': {
          ...containerShadowStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.shadow.multiSelectInputShadow}`]: {
        '& .bp3-tag-input': {
          ...containerShadowStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.shadow.buttonShadow}`]: {
        '&.bp3-button': {
          ...containerShadowStyle,
        },
      },
      /**
       * Container // Padding
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.padding.default.padding}`]: {
        ...containerPaddingStyle,
      },
      /**
       * Container // Corner radius
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.exportModalCornerRadius}`]: {
        '& .bp3-popover': {
          ...defaultContainerCornerRadiusBorderRadiusStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadius}`]: {
        ...defaultContainerCornerRadiusBorderRadiusStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadiusTop}`]: {
        ...defaultContainerCornerRadiusBorderTopLeftRadiusStyle,
        ...defaultContainerCornerRadiusBorderTopRightRadiusStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadiusBottom}`]: {
        ...defaultContainerCornerRadiusBorderBottomLeftRadiusStyle,
        ...defaultContainerCornerRadiusBorderBottomRightRadiusStyle,
      },
      /**
       * Container // Button corner radius
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.buttons.buttonCornerRadius}`]: {
        '&.bp3-button': {
          ...buttonCornerRadiusBorderRadiusStyle,
        },
      },
      /**
       * Text // PrimaryColor
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.primaryColor.color}`]: {
        ...primaryTextColorStyle,
      },
      /**
       * Text // SecondaryColor
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color}`]: {
        ...textSecondaryColorStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.datePickerYearDropdownIconColor}`]: {
        '& .react-datepicker__year-read-view--down-arrow': {
          ...textSecondaryColorBorderTopColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.datePickerMonthDropdownIconColor}`]: {
        '& .react-datepicker__month-read-view--down-arrow': {
          ...textSecondaryColorBorderTopColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.datePickerNavigationPreviousColor}`]: {
        '& .react-datepicker__navigation--previous': {
          ...textSecondaryColorBorderRightColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.datePickerNavigationNextColor}`]: {
        '& .react-datepicker__navigation--next': {
          ...textSecondaryColorBorderLeftColorStyle,
        },
      },
      /**
       * Text // Overrides // H1
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.h1.base}`]: {
        ...h1TextPrimaryStyle,
      },
      /**
       * Text // Overrides // H2
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.h2.base}`]: {
        ...h2TextPrimaryStyle,
      },
      /**
       * Text // Overrides // H3
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.h3.base}`]: {
        ...h3TextPrimaryStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.h3.secondary}`]: {
        ...h3TextSecondaryStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.h3.datePickerHeader}`]: {
        '& .react-datepicker__current-month': {
          ...h3TextPrimaryStyle,
        },
        '& .react-datepicker-time__header': {
          ...h3TextPrimaryStyle,
        },
      },
      /**
       * Text // Overrides // Body
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont}`]: {
        ...bodyTextPrimaryWithoutColorStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.body.primary}`]: {
        ...bodyTextPrimaryStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.body.secondary}`]: {
        ...bodyTextSecondaryStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont}`]: {
        '&.bp3-button': {
          ...bodyTextPrimaryWithoutColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.body.button.secondaryColor}`]: {
        '&.bp3-button': {
          ...bodyTextSecondaryColorStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.body.dropdownMenuItem}`]: {
        '&.bp3-menu-item': {
          ...bodyTextPrimaryStyle,
        },
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.body.dropdownFilterInput}`]: {
        '& .bp3-input': {
          ...bodyTextSecondaryStyle,
        },
      },
      /**
       * Text // Overrides // Small body
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.smallBody.secondary}`]: {
        ...smallBodyTextSecondaryStyle,
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.smallBody.datePickerBody}`]: {
        '& .react-datepicker__day-name': {
          ...smallBodyTextPrimaryStyle,
        },
        '& .react-datepicker__day': {
          ...smallBodyTextPrimaryStyle,
        },
        '& .react-datepicker__time-list-item': {
          ...smallBodyTextPrimaryStyle,
        },
        '& .react-datepicker__header__dropdown': {
          ...smallBodyTextPrimaryStyle,
        },
      },
      /**
       * Text // Overrides // Small heading
       */
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.smallHeading.secondary}`]: {
        ...smallHeadingTextSecondaryStyle,
        fontWeight: 'bold',
      },
      [`& .${GLOBAL_STYLE_CLASSNAMES.text.smallHeading.chartAxisLabels}`]: {
        ...smallHeadingTextSecondaryWithoutColorStyle,
        ...smallHeadingSecondaryFillImportantStyle,
      },
    },
  });
  const classes = useStyles();

  return (
    <GlobalStylesContext.Provider
      value={{ globalStyleConfig: transformGlobalStyleConfig(globalStyleConfig) }}>
      {children(classes.core)}
    </GlobalStylesContext.Provider>
  );
};

function transformGlobalStyleConfig(globalStyleConfig: GlobalStyleConfig) {
  const newConfig = produce(globalStyleConfig, (draft) => {
    if (!draft.container.outline.enabled) {
      draft.container.outline.color = DEFAULT_OUTLINE_COLOR;
    }
  });

  return newConfig;
}
