/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';
import _ from 'underscore';
import { cloneDeep } from 'lodash';

import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import Button from 'shared/Button';
import DropdownSelect from 'shared/DropdownSelect';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import YAxisConfigPanel from 'pages/dataPanelEditorPage/yAxisConfigPanel';

import { LineColumn, SelectedDropdownInputItem } from 'constants/types';
import { EMPTY_Y_AXIS_CONFIG } from 'constants/dataConstants';
import {
  Y_AXIS_RANGE_OPTIONS,
  Y_AXIS_RANGE_OPTIONS_BY_ID,
} from 'constants/dataPanelEditorConstants';
import { VisualizeLineOrBarChartInstructions, OPERATION_TYPES } from 'constants/types';
import { TableColumn } from 'actions/types';
import { Intent, Switch } from '@blueprintjs/core';
import { AppToaster } from 'toaster';

const styles = (theme: Theme) =>
  createStyles({
    yAxisConfigPanel: {
      marginBottom: theme.spacing(3),
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    addBtn: {
      width: '100%',
      marginBottom: theme.spacing(4),
    },
    dynamicYAxisRangeMinValue: {
      marginRight: theme.spacing(2),
    },
    dynamicYAxisRangeValues: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
    operatorSelect: {
      marginBottom: theme.spacing(2),
    },
    labelConfigContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
    },
    switchStyles: {
      '&.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator': {
        backgroundColor: theme.palette.ds.blue,
      },
    },
  });

type PassedProps = {
  headerText: string;
  schema?: TableColumn[];
  instructions: VisualizeLineOrBarChartInstructions;
  operationType: OPERATION_TYPES;
  updateVisualizeOperation: (
    newVisualizeLineChartInstructions: VisualizeLineOrBarChartInstructions,
  ) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class YAxisConfiguration extends React.Component<Props> {
  render() {
    const { classes, instructions, headerText } = this.props;
    return (
      <div className={classes.root}>
        <ConfigSectionHeader isSubTitle={true} title={headerText} />
        {this.renderFixedAxisRangeConfig()}
        {this.renderLabelConfig()}
        {instructions.lineColumns.map(this.renderYAxisConfigPanel)}
        <Button
          className={classes.addBtn}
          type="secondary"
          onClick={this.createYAxisConfig}
          text="Add Value"
        />
      </div>
    );
  }

  renderFixedAxisRangeConfig = () => {
    const { classes, instructions, updateVisualizeOperation } = this.props;
    const selectedItem = instructions.yAxisRangeConfig
      ? instructions.yAxisRangeConfig.option
      : {
          name: Y_AXIS_RANGE_OPTIONS_BY_ID['DYNAMIC'].name,
          id: Y_AXIS_RANGE_OPTIONS_BY_ID['DYNAMIC'].id,
        };
    return (
      <div>
        <div className={classes.operatorSelect}>
          <DropdownSelect
            selectedItem={selectedItem}
            onChange={(item: SelectedDropdownInputItem) => {
              instructions.yAxisRangeConfig = {
                ...instructions.yAxisRangeConfig,
                ...{ option: Y_AXIS_RANGE_OPTIONS_BY_ID[item.id] },
              };
              updateVisualizeOperation(instructions);
            }}
            filterable={false}
            options={Y_AXIS_RANGE_OPTIONS.map((op) => ({
              name: op.name,
              id: op.id,
            }))}
            noSelectionText=""
            minimal
            fillWidth
          />
        </div>
        {selectedItem && selectedItem.id === 'FIXED' && (
          <div className={classes.dynamicYAxisRangeValues}>
            <div className={classes.dynamicYAxisRangeMinValue}>
              <InputWithBlurSave
                initialValue={
                  (instructions.yAxisRangeConfig && instructions.yAxisRangeConfig.min) || ''
                }
                onNewValueSubmitted={(value: string) => {
                  if (this.isRangeValid('min', value, instructions)) {
                    instructions.yAxisRangeConfig = {
                      ...instructions.yAxisRangeConfig,
                      ...{ min: value || undefined },
                    };
                  }
                  updateVisualizeOperation(instructions);
                }}
                placeholder="Min"
              />
            </div>
            <InputWithBlurSave
              initialValue={
                (instructions.yAxisRangeConfig && instructions.yAxisRangeConfig.max) || ''
              }
              onNewValueSubmitted={(value: string) => {
                if (this.isRangeValid('max', value, instructions)) {
                  instructions.yAxisRangeConfig = {
                    ...instructions.yAxisRangeConfig,
                    ...{ max: value || undefined },
                  };
                }
                updateVisualizeOperation(instructions);
              }}
              placeholder="Max"
            />
          </div>
        )}
      </div>
    );
  };

  renderLabelConfig = () => {
    const { operationType, classes, instructions, updateVisualizeOperation } = this.props;

    if (operationType !== OPERATION_TYPES.VISUALIZE_HORIZ_BAR_CHART) return;

    return (
      <div className={classes.labelConfigContainer}>
        <div>Labels Inline</div>
        <Switch
          className={classes.switchStyles}
          checked={instructions.labelMirrored}
          onChange={() => {
            if (instructions.labelMirrored) {
              instructions.labelMirrored = false;
            } else {
              instructions.labelMirrored = true;
            }
            updateVisualizeOperation(instructions);
          }}
        />
      </div>
    );
  };

  renderYAxisConfigPanel = (lineColumn: LineColumn, index: number) => {
    const { schema, classes, operationType } = this.props;
    return (
      <YAxisConfigPanel
        operationType={operationType}
        key={_.uniqueId(`column_${index}`)}
        index={index}
        schema={schema || []}
        className={classes.yAxisConfigPanel}
        lineColumn={lineColumn}
        onDelete={() => this.deleteYAxisConfig(index)}
        onUpdate={(lineColumn: LineColumn) => this.updateYAxisConfig(lineColumn, index)}
        filterRemainingYAxisColumns={this.filterRemainingYAxisColumns}
      />
    );
  };

  showRangeToast = (message: string) => {
    AppToaster.show({
      message: message,
      icon: 'error',
      timeout: 5000,
      intent: Intent.WARNING,
    });
  };

  isRangeValid = (
    bound: 'min' | 'max',
    value: string,
    instructions: VisualizeLineOrBarChartInstructions,
  ) => {
    if (isNaN(Number(value))) {
      this.showRangeToast('Value entered must be a number');
      return false;
    } else if (bound === 'min' && Number(instructions.yAxisRangeConfig?.max) <= Number(value)) {
      this.showRangeToast('Min must be less than max');
      return false;
    } else if (bound === 'max' && Number(instructions.yAxisRangeConfig?.min) >= Number(value)) {
      this.showRangeToast('Max must be greater than min');
      return false;
    }
    return true;
  };

  createYAxisConfig = () => {
    const { instructions, updateVisualizeOperation } = this.props;
    const newLines = cloneDeep(instructions.lineColumns);
    newLines.push(cloneDeep(EMPTY_Y_AXIS_CONFIG));
    instructions.lineColumns = newLines;
    updateVisualizeOperation(instructions);
  };

  deleteYAxisConfig = (index: number) => {
    const { instructions, updateVisualizeOperation } = this.props;
    const newLines = cloneDeep(instructions.lineColumns);
    newLines.splice(index, 1);
    instructions.lineColumns = newLines;
    updateVisualizeOperation(instructions);
  };

  updateYAxisConfig = (lineColumn: LineColumn, index: number) => {
    const { instructions, updateVisualizeOperation } = this.props;
    const newLines = cloneDeep(instructions.lineColumns);

    newLines[index] = lineColumn;
    instructions.lineColumns = newLines;

    updateVisualizeOperation(instructions);
  };

  filterRemainingYAxisColumns = (tableColumns: TableColumn[]) => {
    const { instructions } = this.props;

    return tableColumns.filter(
      (col) =>
        !_.find(
          instructions.lineColumns,
          (line: LineColumn) => line.column && line.column.name === col.name,
        ),
    );
  };
}

export default withStyles(styles)(YAxisConfiguration);
