/** @format */

import { Action } from 'reducers/rootReducer';
import { createReducer } from './utils';
import { ACTION, EmailCadence } from 'actions/types';
import _ from 'underscore';

export interface EmailReducerState {
  emailCadenceList?: EmailCadence[];
}

const emailReducerInitialState: EmailReducerState = {};

const fetchEmailCadenceListSuccess = (newState: EmailReducerState, action: Action) => {
  const { payload } = action;
  newState.emailCadenceList = _.sortBy(
    payload.email_cadence_list,
    (emailCadence) => emailCadence.id,
  );
  return newState;
};

const createDashboardEmailSuccess = (newState: EmailReducerState, action: Action) => {
  const { payload } = action;
  newState.emailCadenceList?.push(payload.cadence);
  return newState;
};

const editDashboardEmailSuccess = (newState: EmailReducerState, action: Action) => {
  const { payload } = action;
  const cadence = newState.emailCadenceList?.find(
    (emailCadence) => emailCadence.dashboard_template_id === payload.cadence.dashboard_template_id,
  );
  if (cadence) {
    cadence.hour = payload.cadence.hour;
    cadence.minute = payload.cadence.minute;
    cadence.subject = payload.cadence.subject;
    cadence.from_email = payload.cadence.from_email;
    cadence.day_of_week = payload.cadence.day_of_week;
    cadence.week_of_month = payload.cadence.week_of_month;
    cadence.timezone = payload.cadence.timezone;
  }
  return newState;
};

const deleteDashboardEmailSuccess = (newState: EmailReducerState, action: Action) => {
  const { payload } = action;

  if (!newState.emailCadenceList) return newState;

  newState.emailCadenceList = _.filter(
    newState.emailCadenceList,
    (emailCadence) =>
      emailCadence.dashboard_template_id !== parseInt(payload.dashboard_template_id),
  );

  return newState;
};

export default createReducer<EmailReducerState>(emailReducerInitialState, {
  [`${ACTION.FETCH_EMAIL_CADENCE_LIST}_SUCCESS`]: fetchEmailCadenceListSuccess,
  [`${ACTION.CREATE_DASHBOARD_EMAIL}_SUCCESS`]: createDashboardEmailSuccess,
  [`${ACTION.EDIT_DASHBOARD_EMAIL}_SUCCESS`]: editDashboardEmailSuccess,
  [`${ACTION.DELETE_DASHBOARD_EMAIL}_SUCCESS`]: deleteDashboardEmailSuccess,
});
