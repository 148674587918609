/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Button, ButtonGroup, IconName } from '@blueprintjs/core';
import cloneDeep from 'lodash/cloneDeep';

import TextAreaWithBlurSave from 'pages/dataPanelEditorPage/textAreaWithBlurSave';
import {
  TextDashboardElemConfig,
  DashboardElementConfig,
  TEXT_ELEM_SIZES,
} from 'types/dashboardTypes';

import DropdownSelect from 'shared/DropdownSelect';
import { TEXT_ELEM_SIZE_CONFIGS } from 'constants/dashboardConstants';
import { SelectedDropdownInputItem } from 'constants/types';
import { ALIGNMENT_FORMATS, VERT_ALIGNMENT_FORMATS } from 'constants/dataConstants';
import { Dataset } from 'actions/types';
import { getQueryTablesReferencedByTextElement } from 'utils/dataPanelConfigUtils';

const ORDERED_ALIGNMENTS = [
  ALIGNMENT_FORMATS.LEFT_ALIGN,
  ALIGNMENT_FORMATS.CENTER_ALIGN,
  ALIGNMENT_FORMATS.RIGHT_ALIGN,
];

const ORDERED_VERT_ALIGNMENTS = [
  VERT_ALIGNMENT_FORMATS.TOP,
  VERT_ALIGNMENT_FORMATS.CENTER,
  VERT_ALIGNMENT_FORMATS.BOTTOM,
];

const styles = (theme: Theme) => ({
  root: {},
  configInput: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  fontSizeInput: {
    marginTop: theme.spacing(3),
    width: '50%',
  },
  sideTextDisplay: {
    color: '#696969',
    fontSize: 'xx-small',
  },
});

type PassedProps = {
  config: TextDashboardElemConfig;
  datasets: Record<string, Dataset>;
  updateConfig: (newConfig: DashboardElementConfig) => void;
};

type Props = RouteComponentProps & WithStyles<typeof styles> & PassedProps;

type State = {};

class TextElementConfigPanel extends React.Component<Props, State> {
  state: State = {};

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderTextInput()}
        {this.renderSideText()}
        {this.renderTextSizeDropdown()}
        {this.renderHorizontalAlignmentButtons()}
        {this.renderVertAlignmentButtons()}
        {this.renderStyleButtons()}
      </div>
    );
  }

  renderTextInput = () => {
    const { config, classes, updateConfig, datasets } = this.props;
    return (
      <TextAreaWithBlurSave
        containerClassName={classes.configInput}
        initialValue={config.text || ''}
        onNewValueSubmitted={(newValue: string) => {
          config.text = newValue;
          config.queryTables = getQueryTablesReferencedByTextElement(newValue, datasets);
          updateConfig(config);
        }}
        placeholder=""
        label="Label"
      />
    );
  };

  renderSideText = () => {
    const { classes } = this.props;
    return <div className={classes.sideTextDisplay}>We now support markdown!</div>;
  };

  renderTextSizeDropdown = () => {
    const { classes, config, updateConfig } = this.props;
    return (
      <div className={classes.configInput}>
        <DropdownSelect
          fillWidth
          minimal
          filterable={false}
          selectedItem={
            config.textSize
              ? TEXT_ELEM_SIZE_CONFIGS[config.textSize]
              : TEXT_ELEM_SIZE_CONFIGS[TEXT_ELEM_SIZES.SMALL]
          }
          onChange={(item: SelectedDropdownInputItem) => {
            updateConfig({ ...config, textSize: item.id as TEXT_ELEM_SIZES });
          }}
          options={[
            TEXT_ELEM_SIZE_CONFIGS[TEXT_ELEM_SIZES.SMALL],
            TEXT_ELEM_SIZE_CONFIGS[TEXT_ELEM_SIZES.MEDIUM],
            TEXT_ELEM_SIZE_CONFIGS[TEXT_ELEM_SIZES.LARGE],
          ]}
          label="Text Size"
          noSelectionText=""
        />
      </div>
    );
  };

  renderHorizontalAlignmentButtons = () => {
    const { classes, config, updateConfig } = this.props;

    const selectedAlignmentId = config.alignmentHoriz || ALIGNMENT_FORMATS.LEFT_ALIGN.id;

    return (
      <div className={classes.configInput}>
        <ButtonGroup fill>
          {ORDERED_ALIGNMENTS.map((alignment) => (
            <Button
              key={`text-elem-config-${alignment.id}`}
              icon={alignment.icon as IconName}
              active={selectedAlignmentId === alignment.id}
              onClick={() => {
                updateConfig({
                  ...config,
                  alignmentHoriz: alignment.id,
                });
              }}
            />
          ))}
        </ButtonGroup>
      </div>
    );
  };

  renderVertAlignmentButtons = () => {
    const { classes, config, updateConfig } = this.props;

    const selectedAlignmentId = config.alignmentVertical || VERT_ALIGNMENT_FORMATS.TOP_ALIGN.id;

    return (
      <div className={classes.configInput}>
        <ButtonGroup fill>
          {ORDERED_VERT_ALIGNMENTS.map((alignment) => (
            <Button
              key={`text-elem-config-${alignment.id}`}
              icon={alignment.icon as IconName}
              active={selectedAlignmentId === alignment.id}
              onClick={() => {
                updateConfig({
                  ...config,
                  alignmentVertical: alignment.id,
                });
              }}
            />
          ))}
        </ButtonGroup>
      </div>
    );
  };

  renderStyleButtons = () => {
    const { classes, config, updateConfig } = this.props;

    return (
      <div className={classes.configInput}>
        <ButtonGroup fill>
          <Button
            icon="bold"
            active={false}
            onClick={() => {
              const newConfig = cloneDeep(config);
              newConfig.text = this.formatTextBold(newConfig.text || '');
              updateConfig(newConfig);
            }}
          />

          <Button
            icon="italic"
            active={false}
            onClick={() => {
              const newConfig = cloneDeep(config);
              newConfig.text = this.formatTextItalic(newConfig.text || '');
              updateConfig(newConfig);
            }}
          />
        </ButtonGroup>
      </div>
    );
  };

  formatTextBold = (text: string) => {
    return `**${text}**`;
  };

  formatTextItalic = (text: string) => {
    return `*${text}*`;
  };
}

export default withRouter(withStyles(styles)(TextElementConfigPanel));
