/** @format */

import { Action } from 'reducers/rootReducer';
import { ACTION, EndUserGroup } from 'actions/types';
import { createReducer } from './utils';

export interface EndUserGroupsReducerState {
  groups: EndUserGroup[];
}

const endUserGroupsReducerInitialState: EndUserGroupsReducerState = {
  groups: [],
};

const fetchEndUserGroupsSuccess = (newState: EndUserGroupsReducerState, action: Action) => {
  newState.groups = action.payload.end_user_groups;
  return newState;
};

const createEndUserGroupSuccess = (newState: EndUserGroupsReducerState, action: Action) => {
  newState.groups.push(action.payload.end_user_group);
  return newState;
};

const deleteEndUserGroupsSuccess = (newState: EndUserGroupsReducerState, action: Action) => {
  newState.groups = newState.groups.filter((group) => group.id !== action.payload.id);
  return newState;
};

const editEndUserGroupsSuccess = (newState: EndUserGroupsReducerState, action: Action) => {
  const user = newState.groups.find((endUser) => endUser.id === action.payload.id);
  if (user) {
    user.name = action.payload.postData.name;
    user.parent_schema_datasource_mapping = action.payload.postData.mapping;
    user.provided_id = action.payload.postData.provided_id;
  }
  return newState;
};

const syncParentSchemaSuccess = (newState: EndUserGroupsReducerState, action: Action) => {
  newState.groups = action.payload.end_user_groups;
  return newState;
};

export default createReducer<EndUserGroupsReducerState>(endUserGroupsReducerInitialState, {
  [`${ACTION.FETCH_END_USER_GROUPS}_SUCCESS`]: fetchEndUserGroupsSuccess,
  [`${ACTION.CREATE_END_USER_GROUP}_SUCCESS`]: createEndUserGroupSuccess,
  [`${ACTION.DELETE_END_USER_GROUP}_SUCCESS`]: deleteEndUserGroupsSuccess,
  [`${ACTION.EDIT_END_USER_GROUP}_SUCCESS`]: editEndUserGroupsSuccess,
  [`${ACTION.SYNC_PARENT_SCHEMA}_SUCCESS`]: syncParentSchemaSuccess,
});
