/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';
import cx from 'classnames';

import FilterValueInput from 'pages/dataPanelEditorPage/filterValueInput';
import DropdownSelect from 'shared/DropdownSelect';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';

import {
  FilterOperatorType,
  FilterValueType,
  FilterValueDateType,
  SelectedDropdownInputItem,
} from 'constants/types';
import { TableColumn } from 'actions/types';
import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';
import { FILTER_OPERATORS, FILTER_OPERATOR_TYPES_BY_ID } from 'constants/dataPanelEditorConstants';

const styles = (theme: Theme) =>
  createStyles({
    columnSelect: {
      marginBottom: theme.spacing(2),
    },
    operatorSelect: {
      marginBottom: theme.spacing(2),
    },
  });

type PassedProps = {
  containerClassName?: string;
  columnOptionClassName?: string;
  operatorOptionClassName?: string;
  valueInputClassName?: string;
  baseSchema: TableColumn[];
  selectedColumn?: TableColumn;
  selectedOperator?: FilterOperatorType;
  filterValue?: FilterValueType;
  onColumnSelect: (column: TableColumn) => void;
  onOperatorSelect: (operator: FilterOperatorType) => void;
  onFilterValueUpdate: (value: FilterValueType) => void;
  hideIcon?: boolean;
  adHocFilterSettings?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

class FilterEditingOptions extends React.Component<Props> {
  render() {
    const { containerClassName } = this.props;
    return (
      <div className={containerClassName}>
        {this.renderColumnDropdown()}
        {this.renderOperatorDropdown()}
        {this.renderValueInput()}
      </div>
    );
  }

  renderColumnDropdown = () => {
    const {
      classes,
      columnOptionClassName,
      baseSchema,
      selectedColumn,
      onColumnSelect,
      hideIcon,
    } = this.props;
    const columnBySchemaName = _.indexBy(baseSchema, 'name');

    return (
      <div className={cx(classes.columnSelect, columnOptionClassName)}>
        <DropdownSelect
          selectedItem={
            selectedColumn
              ? {
                  name: selectedColumn.friendly_name || selectedColumn.name,
                  id: selectedColumn.name,
                  icon: SCHEMA_DATA_TYPES_BY_ID[selectedColumn.type].icon,
                }
              : undefined
          }
          onChange={(item: SelectedDropdownInputItem) =>
            onColumnSelect(columnBySchemaName[item.id])
          }
          filterable={false}
          options={baseSchema.map((col) => ({
            name: col.friendly_name || col.name,
            id: col.name,
            icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
          }))}
          noSelectionText="Column"
          minimal
          fillWidth
          showIcon={!hideIcon}
        />
      </div>
    );
  };

  renderOperatorDropdown = () => {
    const {
      classes,
      operatorOptionClassName,
      selectedColumn,
      selectedOperator,
      onOperatorSelect,
    } = this.props;

    const relevantOperators = _.filter(FILTER_OPERATORS, (op) =>
      selectedColumn ? op.supported_column_types.has(selectedColumn.type) : false,
    );

    return (
      <div className={cx(classes.operatorSelect, operatorOptionClassName)}>
        <DropdownSelect
          disabled={!selectedColumn}
          selectedItem={
            selectedOperator
              ? {
                  name: selectedOperator.name,
                  id: selectedOperator.id,
                }
              : undefined
          }
          onChange={(item: SelectedDropdownInputItem) =>
            onOperatorSelect(FILTER_OPERATOR_TYPES_BY_ID[item.id])
          }
          filterable={false}
          options={relevantOperators.map((op) => ({
            name: op.selectionValue,
            id: op.id,
          }))}
          noSelectionText="Operator"
          minimal
          fillWidth
        />
      </div>
    );
  };

  renderValueInput = () => {
    const {
      selectedColumn,
      valueInputClassName,
      selectedOperator,
      onFilterValueUpdate,
      filterValue,
      adHocFilterSettings,
    } = this.props;

    if (!selectedColumn || !selectedOperator) {
      return <InputGroup type="text" disabled className="bp3-fill" placeholder="Value" />;
    }

    return (
      <div className={valueInputClassName}>
        <FilterValueInput
          adHocFilterSettings={adHocFilterSettings}
          filterOperation={selectedOperator}
          filterColumn={selectedColumn}
          filterValue={filterValue}
          updateFilterValue={onFilterValueUpdate}
          updateStartDate={this.updateStartDate}
          updateEndDate={this.updateEndDate}
        />
      </div>
    );
  };

  updateStartDate = (startDate: string | null) => {
    const { filterValue, onFilterValueUpdate } = this.props;
    let newVal: FilterValueType;
    if (!filterValue) {
      newVal = {
        startDate: startDate ?? undefined,
      };
    } else {
      newVal = {
        ...(filterValue as FilterValueDateType),
        startDate: startDate ?? undefined,
      };
    }

    onFilterValueUpdate(newVal);
  };

  updateEndDate = (endDate: string | null) => {
    const { filterValue, onFilterValueUpdate } = this.props;
    let newVal: FilterValueType;
    if (!filterValue) {
      newVal = {
        endDate: endDate ?? undefined,
      };
    } else {
      newVal = {
        ...(filterValue as FilterValueDateType),
        endDate: endDate ?? undefined,
      };
    }

    onFilterValueUpdate(newVal);
  };
}

export default withStyles(styles)(FilterEditingOptions);
