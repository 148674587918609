/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Icon, IconName } from '@blueprintjs/core';

import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';
import { Schema } from 'constants/types';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles({
  tableColumnHeaderText: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-between',
  },
  leftItems: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',

    '&.LEFT_ALIGN': {
      justifyContent: 'flex-start',
    },
    '&.CENTER_ALIGN': {
      justifyContent: 'center',
    },
    '&.RIGHT_ALIGN': {
      justifyContent: 'flex-end',
    },
  },
  hoverIcon: {
    opacity: 0.5,
    visibility: 'hidden',

    '&.hidden': {
      display: 'none',
    },
  },
});

type Props = {
  headerList: Schema;
  index: number;
  header: string;
  rightIcon?: IconName;
  isSortable: boolean;
  alignment?: string;
};

export default function ColumnHeaderText(props: Props) {
  const { headerList, index, header, rightIcon, isSortable, alignment } = props;
  const classes = useStyles();

  let schemaType = SCHEMA_DATA_TYPES_BY_ID[headerList[index].type];
  if (!schemaType) {
    schemaType = SCHEMA_DATA_TYPES_BY_ID.STRING;
  }
  return (
    <div
      className={cx(
        'bp3-table-truncated-text',
        classes.tableColumnHeaderText,
        GLOBAL_STYLE_CLASSNAMES.text.h3.base,
      )}>
      <div className={cx(classes.leftItems, alignment)}>{header}</div>
      {rightIcon ? (
        <Icon iconSize={12} icon={rightIcon} />
      ) : isSortable ? (
        <Icon
          className={cx(
            classes.hoverIcon,
            { hidden: !isSortable },
            'columnSortIcon',
            GLOBAL_STYLE_CLASSNAMES.text.h3.base,
          )}
          icon="arrow-down"
          iconSize={12}
        />
      ) : null}
    </div>
  );
}
