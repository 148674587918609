/** @format */
import React from 'react';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { SelectedDropdownInputItem } from 'constants/types';
import { saveDefaultLocaleCode, saveUseBrowserLocale } from 'actions/internationalizationActions';
import { AppToaster } from 'toaster';
import { Intent, Checkbox } from '@blueprintjs/core';
import SettingsLanguageDropdown from './SettingsLanguageDropdown';
import CalloutLink from 'shared/CalloutLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `0 ${theme.spacing(8)}px 125px ${theme.spacing(8)}px`,
  },
  subsection: {
    fontSize: 20,
    fontWeight: 600,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  checkboxContainer: {},
  languageContainer: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(6),
  },
  subtitle: {
    color: theme.palette.ds.grey700,
    marginBottom: theme.spacing(3),
  },
  documentationBox: {
    marginTop: theme.spacing(8),
  },
}));

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

function SettingsInternationalizationSection(props: Props) {
  const { team, saveDefaultLocaleCode, saveUseBrowserLocale } = props;
  const classes = useStyles();

  const onDefaultLanguageSelect = (item: SelectedDropdownInputItem) => {
    saveDefaultLocaleCode(
      {
        postData: {
          locale_code: item.id,
        },
      },
      undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        AppToaster.show({
          message: response.error_msg
            ? response.error_msg
            : 'Something went wrong. Please try again or contact support@explo.co if the error continues.',
          icon: 'error',
          timeout: 3000,
          intent: Intent.DANGER,
        });
      },
    );
  };

  const onUserBrowserLocaleClick = () => {
    if (!team) return;
    saveUseBrowserLocale(
      {
        postData: {
          setting: !team.use_browser_locale,
        },
      },
      undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        AppToaster.show({
          message: response.error_msg
            ? response.error_msg
            : 'Something went wrong. Please try again or contact support@explo.co if the error continues.',
          icon: 'error',
          timeout: 3000,
          intent: Intent.DANGER,
        });
      },
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.subsection}>Internationalization</div>
      </div>
      <div className={classes.subtitle}>
        {
          "This affects the formatting of numbers, currencies, and dates on your customer's dashboards"
        }
      </div>
      <SettingsLanguageDropdown
        containerClassName={classes.languageContainer}
        label="Default Language"
        localeCode={team?.default_locale_code}
        onLanguageSelect={onDefaultLanguageSelect}
      />
      <Checkbox
        checked={team?.use_browser_locale}
        className={classes.checkboxContainer}
        label="Use Browser Language (when possible)"
        onChange={onUserBrowserLocaleClick}
      />
      <CalloutLink
        className={classes.documentationBox}
        url="https://docs.explo.co/embedding-documentation/internationalization"
        text="Learn more about internationalization and how it affects your dashboards"
      />
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  team: state.teamData.data,
});

const mapDispatchToProps = { saveDefaultLocaleCode, saveUseBrowserLocale };

export default connect(mapStateToProps, mapDispatchToProps)(SettingsInternationalizationSection);
