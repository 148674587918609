/** @format */

import { Layout } from '@explo-tech/react-grid-layout';
import {
  ACTION,
  DashboardEnvironmentTag,
  DashboardParam,
  DashboardVersion,
  DataPanelTemplate,
  Dataset,
  TableColumn,
  TableRow,
  UserInfo,
} from './types';
import { OPERATION_TYPES } from 'constants/types';
import {
  DashboardVariableMap,
  DashboardElementConfig,
  DASHBOARD_ELEMENT_TYPES,
  DashboardElement,
} from 'types/dashboardTypes';
import { defineAPIAction } from 'actions/actionUtils';

export type CreateDashboardTemplateDataPanelV2Args = {
  dashId: number;
  name: string;
  newLayout: Layout[];
  datasetId: string;
  vizType?: OPERATION_TYPES;
  containerId?: string;
};

export const createDashboardTemplateDataPanelV2 = (
  args: CreateDashboardTemplateDataPanelV2Args,
) => ({
  type: ACTION.CREATE_TEMPLATE_DATA_PANEL_V2,
  payload: {
    ...args,
  },
});

export const duplicateDashboardItem = (args: DuplicateDashboardItemArgs) => ({
  type: ACTION.DUPLICATE_DASHBOARD_ITEM,
  payload: {
    ...args,
  },
});

export type FetchEditorDatasetDataPayload = {
  postData: {
    query?: string;
    dataset: Dataset;
    variables: DashboardVariableMap;
  };
};

export type FetchEditorDatasetDataResponse = {
  dataset_preview?: {
    schema: TableColumn[];
    _rows: TableRow[];
    _error: string;
  };
  _total_row_count?: number;
};

export type CreateDashboardDatasetV2Args = {
  name: string;
  dashId: number;
  parentSchemaId: number;
};

export const createDashboardDatasetV2 = (args: CreateDashboardDatasetV2Args) => ({
  type: ACTION.CREATE_DATASET_V2,
  payload: {
    ...args,
  },
});

export type DeleteDashboardDatasetV2Args = {
  datasetId: string;
};

export const deleteDashboardDatasetV2 = (args: DeleteDashboardDatasetV2Args) => ({
  type: ACTION.DELETE_DASHBOARD_DATASET,
  payload: {
    ...args,
  },
});

export type EditDashboardDatasetNameArgs = {
  datasetId: string;
  name: string;
};

export const editDashboardDatasetName = (args: EditDashboardDatasetNameArgs) => ({
  type: ACTION.EDIT_DASHBOARD_DATASET_NAME,
  payload: {
    ...args,
  },
});

export type SaveDashboardDatasetQueryArgs = {
  query?: string;
  dataset: Dataset;
  schema: TableColumn[];
  variables: DashboardVariableMap;
};

export const saveDashboardDatasetQuery = (args: SaveDashboardDatasetQueryArgs) => ({
  type: ACTION.SAVE_DASHBOARD_DATASET_QUERY,
  payload: {
    ...args,
  },
});

export type DeleteDataPanelV2Args = {
  id: string;
};

export const deleteDataPanelV2 = (args: DeleteDataPanelV2Args) => ({
  type: ACTION.DELETE_DATA_PANEL_V2,
  payload: {
    ...args,
  },
});

export type UpdateElementConfigV2Args = {
  elementId: string;
  config: DashboardElementConfig;
};

export const updateElementConfigV2 = (args: UpdateElementConfigV2Args) => ({
  type: ACTION.UPDATE_ELEMENT_CONFIG,
  payload: {
    ...args,
  },
});

export type DeleteElementConfigV2Args = {
  elementId: string;
};

export const deleteDashboardElementV2 = (args: DeleteElementConfigV2Args) => ({
  type: ACTION.DELETE_DASHBOARD_ELEMENT,
  payload: {
    ...args,
  },
});

export type RenameDataPanelV2Args = {
  id: string;
  name: string;
  providedId?: string;
};

export const renameDataPanelV2 = (args: RenameDataPanelV2Args) => ({
  type: ACTION.RENAME_DATA_PANEL_TEMPLATE,
  payload: {
    ...args,
  },
});

export type CreateNewDashboardVersionResponse = {
  dashboard_version: DashboardVersion;
};

export const { actionFn: createNewDashboardVersion } = defineAPIAction<
  CreateNewDashboardVersionResponse
>(ACTION.CREATE_NEW_DASHBOARD_VERSION, 'dashboards', 'create_new_dashboard_version', 'POST');

export type SwitchEditingDashboardVersionArgs = {
  dashboardVersion: DashboardVersion;
};

export const switchCurrentlyEditingDashboardVersion = (
  args: SwitchEditingDashboardVersionArgs,
) => ({
  type: ACTION.SWITCH_CURRENTLY_EDITING_DASHBOARD_VERSION,
  payload: {
    ...args,
  },
});

export type PublishNewDashboardVersionResponse = {
  dashboard_version: DashboardVersion;
};

export const { actionFn: publishNewDashboardVersion } = defineAPIAction<
  PublishNewDashboardVersionResponse
>(ACTION.PUBLISH_NEW_DASHBOARD_VERSION, 'dashboards', 'publish_new_dashboard_version', 'POST');

export type FetchDashboardVersionsResponse = {
  versions: DashboardVersion[];
  authors: UserInfo[];
  tags: DashboardEnvironmentTag[];
};

export const { actionFn: fetchDashboardVersions } = defineAPIAction<FetchDashboardVersionsResponse>(
  ACTION.FETCH_DASHBOARD_VERSIONS,
  'dashboards',
  'list_versions',
  'GET',
);

export type SaveDashboardElementUpdatesArgs = {
  id: string;
  config?: DashboardElementConfig;
  name?: string;
};

export const saveDashboardElementUpdates = (args: SaveDashboardElementUpdatesArgs) => ({
  type: ACTION.SAVE_DASHBOARD_ELEMENT_UPDATES,
  payload: {
    ...args,
  },
});

export type CreateDashboardElementArgs = {
  elementType: DASHBOARD_ELEMENT_TYPES;
  initialConfig: DashboardElementConfig;
  dashId: number;
  newLayout: Layout[];
  containerId?: string;
};

export type DuplicateDashboardItemArgs = {
  dashboardItem: DataPanelTemplate | DashboardElement;
  itemType: DASHBOARD_ELEMENT_TYPES;
  dashId: number;
};

export const createDashboardElement = (args: CreateDashboardElementArgs) => ({
  type: ACTION.CREATE_DASHBOARD_TEMPLATE_ELEMENT,
  payload: {
    ...args,
  },
});

export const { actionFn: saveDashboardDraft } = defineAPIAction(
  ACTION.SAVE_DASHBOARD_DRAFT,
  'dashboards',
  'save_draft',
  'POST',
);

export const { actionFn: updateEnvironmentTag } = defineAPIAction<{}>(
  ACTION.UPDATE_ENVIRONMENT_TAG,
  'dashboards',
  'update_environment_tag',
  'POST',
);

export const { actionFn: createEnvironmentTag } = defineAPIAction<{}>(
  ACTION.CREATE_ENVIRONMENT_TAG,
  'dashboards',
  'create_environment_tag',
  'POST',
);

export const clearDashboardConfigReducer = () => ({
  type: ACTION.CLEAR_DASHBOARD_CONFIG_REDUCER,
});

export type UpdateDashboardParamsArgs = {
  newParams: Record<string, DashboardParam>;
};

export const updateDashboardTemplateParams = (args: UpdateDashboardParamsArgs) => ({
  type: ACTION.UPDATE_DASHBOARD_TEMPLATE_PARAMS,
  payload: {
    ...args,
  },
});

export const { actionFn: deleteCurrentDashboardDraft } = defineAPIAction(
  ACTION.DELETE_CURRENT_DASHBOARD_DRAFT,
  'dashboards',
  'delete_draft',
  'POST',
);

export type SetDPTLoadingArgs = {
  ids: string[];
  loading: boolean;
};

export const setDptLoading = (args: SetDPTLoadingArgs) => ({
  type: ACTION.SET_DPT_LOADING,
  payload: {
    ...args,
  },
});
