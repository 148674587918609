/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { withStyles, createStyles } from '@material-ui/styles';
import { WithStyles } from '@material-ui/core/index';
import { Intent } from '@blueprintjs/core';
import { parseISO } from 'date-fns';
import { AppToaster } from 'toaster';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import DatePickerInput from 'shared/DatePickerInput';
import DropdownSelect from 'shared/DropdownSelect';

import { DashboardVariable } from 'types/dashboardTypes';
import { SelectedDropdownInputItem } from 'constants/types';

const styles = () =>
  createStyles({
    valueSelector: {
      width: '100%',
    },
  });

export enum InputType {
  INPUT = 'input',
  DATETIME = 'datetime',
  DROPDOWN = 'dropdown',
}

type PassedProps = {
  className?: string;
  name?: string;
  inputType: InputType;
  dropdownOptions?: SelectedDropdownInputItem[];
  value?: DashboardVariable;
  onValueChange: (newValue?: DashboardVariable) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class DashboardVariableEntry extends React.Component<Props> {
  render = () => {
    const { className } = this.props;

    return <div className={className}>{this.renderVariableInput()}</div>;
  };

  renderVariableInput = () => {
    const { inputType, classes, value, onValueChange, dropdownOptions } = this.props;

    switch (inputType) {
      case InputType.INPUT:
        return (
          <InputWithBlurSave
            hideRightIconInteractions
            containerClassName={cx(classes.valueSelector)}
            initialValue={
              value ? JSON.stringify(value as string | number | string[] | number[]) : ''
            }
            onNewValueSubmitted={(newValue) => {
              if (!newValue) return onValueChange(undefined);
              try {
                newValue = JSON.parse(newValue);
                onValueChange(newValue);
              } catch {
                AppToaster.show({
                  message: `Please enter the variable as valid javascript. Strings should have quotes.`,
                  icon: 'error',
                  timeout: 10000,
                  intent: Intent.WARNING,
                });
                return;
              }
            }}
            placeholder=""
          />
        );
      case InputType.DATETIME:
        return (
          <DatePickerInput
            withPortal
            showCancelBtn
            showTimeSelect={false}
            className={classes.valueSelector}
            selectedValue={typeof value === 'string' ? parseISO(value) : (value as Date)}
            onNewValueSelect={(newValue) => {
              onValueChange((newValue as Date) || undefined);
            }}
            onCancelClick={() => {
              onValueChange(undefined);
            }}
          />
        );
      case InputType.DROPDOWN: {
        let selectedItem;

        if (dropdownOptions && value) {
          selectedItem = _.find(dropdownOptions, (option) => option.id === value);
        }

        return (
          <DropdownSelect
            minimal
            fillWidth
            usePortal
            containerClassName={classes.valueSelector}
            selectedItem={selectedItem}
            onChange={(item: SelectedDropdownInputItem) => onValueChange(item.id)}
            filterable={false}
            options={dropdownOptions || []}
            noSelectionText=""
            showCancelBtn
            onCancelClick={() => onValueChange(undefined)}
          />
        );
      }
    }
  };
}

export default withStyles(styles)(DashboardVariableEntry);
