/** @format */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import cx from 'classnames';
import { Icon, IconName } from '@blueprintjs/core';

export type TabOption = {
  name: string;
  id: string;
  icon?: IconName | JSX.Element;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  tabBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#727A86',
    height: '100%',
    fontWeight: 500,
    fontSize: 14,
    borderBottom: `2px solid #FFFFFF00`, // transparent

    '&:hover': {
      color: theme.palette.ds.blue,
      cursor: 'pointer',
    },
  },
  activeTabBtn: {
    color: theme.palette.ds.blue,
    borderBottom: `2px solid ${theme.palette.ds.blue}`,
  },
  tabIcon: {
    marginRight: theme.spacing(3),
  },
}));

type Props = {
  className?: any;
  tabClassName?: any;
  tabs: TabOption[];
  tabIconSize?: number;
  selectedTabId?: string;
  onTabSelect: (tabId: string) => void;
};

const NavTabs = (props: Props) => {
  const { className, tabClassName, selectedTabId, tabIconSize, tabs, onTabSelect } = props;
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      {tabs.map((tab) => (
        <div
          key={`nav-tab-option-${tab.id}`}
          className={cx(classes.tabBtn, tabClassName, {
            [classes.activeTabBtn]: selectedTabId === tab.id,
          })}
          onClick={() => onTabSelect(tab.id)}>
          {tab.icon && (
            <Icon icon={tab.icon as any} className={classes.tabIcon} iconSize={tabIconSize} />
          )}
          {tab.name}
        </div>
      ))}
    </div>
  );
};

export default NavTabs;
