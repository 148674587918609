/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { WithStyles, Theme } from '@material-ui/core/index';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { ButtonGroup, Button, Intent } from '@blueprintjs/core';
import { AppToaster } from 'toaster';

import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import ColumnSelectionDropdown from 'pages/dataPanelEditorPage/visualizeSections/shared/columnSelectionDropdown';
import CalculationsEditingPanel from 'pages/dataPanelEditorPage/calculationsEditingPanel';
import DropdownSelect from 'shared/DropdownSelect';
import InputConfig from 'pages/dataPanelEditorPage/visualizeSections/shared/inputConfig';
import SplitInputs from 'pages/dataPanelEditorPage/visualizeSections/shared/splitInputs';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { PivotChartDirection, ViusalizePivotChartInstructions } from 'constants/types';
import { OPERATION_TYPES, PivotOperationAggregation } from 'constants/types';
import { GROUPED_CHART_TYPES } from 'constants/dataConstants';
import { EMPTY_AGGREGATION } from 'constants/dataPanelEditorConstants';
import { isJsOrVariable } from 'utils/dataPanelConfigUtils';
import { DataPanelTemplate } from 'actions/types';
import InputLabel from 'shared/InputLabel';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 100,
    },
    configurationInput: {
      marginBottom: theme.spacing(4),
    },
    axisMinInput: {
      marginRight: theme.spacing(3),
    },
  });

type PassedProps = {
  instructions: ViusalizePivotChartInstructions;
  dataPanelTemplate: DataPanelTemplate;
};

type Props = PassedProps &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  RouteComponentProps &
  ReturnType<typeof mapStateToProps>;

class VisualizePivotChartSection extends React.Component<Props> {
  render() {
    const { classes, instructions, dataPanelTemplate, updateVisualizeOperation } = this.props;
    if (!dataPanelTemplate || !dataPanelTemplate._schema) return <div></div>;

    const mainAxisName =
      !instructions.chartDirection || instructions.chartDirection === PivotChartDirection.VERTICAL
        ? 'X-Axis Column'
        : 'Y-Axis Column';
    const aggAxis =
      !instructions.chartDirection || instructions.chartDirection === PivotChartDirection.VERTICAL
        ? 'Y-Axis Column'
        : 'X-Axis Column';

    return (
      <div className={classes.root}>
        <ConfigSectionHeader title="Configure grouped chart" />
        <ColumnSelectionDropdown
          label={mainAxisName}
          schema={dataPanelTemplate._schema}
          selectedColumnName={instructions.xAxisColumn?.name}
          onSelect={(column) => {
            const newInstrunctions = cloneDeep(instructions);
            newInstrunctions.xAxisColumn = column;
            updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_PIVOT_CHART);
          }}
        />
        <ColumnSelectionDropdown
          label="Grouping Column"
          schema={dataPanelTemplate._schema}
          selectedColumnName={instructions.yAxisColumn?.name}
          onSelect={(column) => {
            const newInstrunctions = cloneDeep(instructions);
            newInstrunctions.yAxisColumn = column;
            updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_PIVOT_CHART);
          }}
        />
        <InputLabel text="Aggregation" />
        <CalculationsEditingPanel
          hasLabel
          className={classes.configurationInput}
          baseSchema={dataPanelTemplate._schema}
          aggregation={instructions.aggregation || cloneDeep(EMPTY_AGGREGATION)}
          onUpdate={(pivotOperationAggregation: PivotOperationAggregation) => {
            const newInstrunctions = cloneDeep(instructions);
            newInstrunctions.aggregation = pivotOperationAggregation;
            updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_PIVOT_CHART);
          }}
        />
        <ConfigSectionHeader title="Format" />
        <DropdownSelect
          minimal
          fillWidth
          showIcon
          filterable={false}
          containerClassName={classes.configurationInput}
          label="Chart Type"
          selectedItem={
            instructions.chartTypeId
              ? GROUPED_CHART_TYPES[instructions.chartTypeId]
              : GROUPED_CHART_TYPES.STACKED_BAR
          }
          onChange={(item) => {
            const newInstrunctions = cloneDeep(instructions);
            newInstrunctions.chartTypeId = item.id;
            updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_PIVOT_CHART);
          }}
          noSelectionText=""
          options={Object.values(GROUPED_CHART_TYPES)}
        />

        <ButtonGroup fill className={classes.configurationInput}>
          <Button
            icon="timeline-bar-chart"
            active={
              !instructions.chartDirection ||
              instructions.chartDirection === PivotChartDirection.VERTICAL
            }
            onClick={() => {
              const newInstrunctions = cloneDeep(instructions);
              newInstrunctions.chartDirection = PivotChartDirection.VERTICAL;
              updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_PIVOT_CHART);
            }}>
            Vertical
          </Button>
          <Button
            icon="horizontal-bar-chart"
            active={instructions.chartDirection === PivotChartDirection.HORIZONTAL}
            onClick={() => {
              const newInstrunctions = cloneDeep(instructions);
              newInstrunctions.chartDirection = PivotChartDirection.HORIZONTAL;
              updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_PIVOT_CHART);
            }}>
            Horizontal
          </Button>
        </ButtonGroup>

        <SplitInputs>
          <InputConfig
            className={cx(classes.configurationInput, classes.axisMinInput)}
            value={instructions.axisMin || ''}
            placeHolder="Min"
            onUpdate={(newValue: string) => {
              if (!newValue || isJsOrVariable(newValue)) {
                const newInstrunctions = cloneDeep(instructions);
                newInstrunctions.axisMin = newValue || undefined;
                updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_PIVOT_CHART);
              } else {
                AppToaster.show({
                  message: `Please enter the min as valid javascript or as a variable surrounded by {{}}`,
                  icon: 'error',
                  timeout: 10000,
                  intent: Intent.WARNING,
                });
              }
            }}
            label={`${mainAxisName} Min`}
          />

          <InputConfig
            className={classes.configurationInput}
            value={instructions.axisMax || ''}
            placeHolder="Max"
            onUpdate={(newValue: string) => {
              if (!newValue || isJsOrVariable(newValue)) {
                const newInstrunctions = cloneDeep(instructions);
                newInstrunctions.axisMax = newValue || undefined;
                updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_PIVOT_CHART);
              } else {
                AppToaster.show({
                  message: `Please enter the max as valid javascript or as a variable surrounded by {{}}`,
                  icon: 'error',
                  timeout: 10000,
                  intent: Intent.WARNING,
                });
              }
            }}
            label={`${mainAxisName} Max`}
          />
        </SplitInputs>

        <InputConfig
          className={classes.configurationInput}
          value={
            instructions.aggMinStepSize === 0 || instructions.aggMinStepSize
              ? String(instructions.aggMinStepSize)
              : '-'
          }
          placeHolder="1, 2, 4, etc..."
          onUpdate={(newValue: string) => {
            const intValue = parseInt(newValue);
            if (newValue === '' || intValue === 0 || intValue) {
              const newInstrunctions = cloneDeep(instructions);
              newInstrunctions.aggMinStepSize = newValue === '' ? undefined : intValue;
              updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_PIVOT_CHART);
            } else {
              AppToaster.show({
                message: `Please enter the fontsize as a number`,
                icon: 'error',
                timeout: 10000,
                intent: Intent.WARNING,
              });
            }
          }}
          label={`${aggAxis} Min Step Size`}
        />

        <InputConfig
          className={classes.configurationInput}
          value={instructions.colors || ''}
          onUpdate={(newValue: string) => {
            const newInstrunctions = cloneDeep(instructions);
            newInstrunctions.colors = newValue;
            updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_PIVOT_CHART);
          }}
          label="Colors (comma separated)"
          placeHolder="#0069ED,#FFFFFF,#000000, etc"
        />

        <InputConfig
          className={classes.configurationInput}
          value={
            instructions.labelFontSize === 0 || instructions.labelFontSize
              ? String(instructions.labelFontSize)
              : '12'
          }
          placeHolder="11, 12, 14, etc..."
          onUpdate={(newValue: string) => {
            const intValue = parseInt(newValue);
            if (intValue === 0 || intValue) {
              const newInstrunctions = cloneDeep(instructions);
              newInstrunctions.labelFontSize = intValue;
              updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_PIVOT_CHART);
            } else {
              AppToaster.show({
                message: `Please enter the fontsize as a number`,
                icon: 'error',
                timeout: 10000,
                intent: Intent.WARNING,
              });
            }
          }}
          label="Labels Font Size"
        />

        <InputConfig
          className={classes.configurationInput}
          value={
            instructions.titleFontSize === 0 || instructions.titleFontSize
              ? String(instructions.titleFontSize)
              : '12'
          }
          placeHolder="11, 12, 14, etc..."
          onUpdate={(newValue: string) => {
            const intValue = parseInt(newValue);
            if (intValue === 0 || intValue) {
              const newInstrunctions = cloneDeep(instructions);
              newInstrunctions.titleFontSize = intValue;
              updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_PIVOT_CHART);
            } else {
              AppToaster.show({
                message: `Please enter the fontsize as a number`,
                icon: 'error',
                timeout: 10000,
                intent: Intent.WARNING,
              });
            }
          }}
          label="Title Font Size"
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateVisualizeOperation,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VisualizePivotChartSection)),
);
