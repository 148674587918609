/** @format */

import React from 'react';
import Highcharts from 'highcharts';
import { dayjs } from 'utils/localizationUtils';
import ReactDOMServer from 'react-dom/server';
import _ from 'underscore';

import HighCharts from './highCharts';
import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';
import BaseTooltip from './tooltips/BaseTooltip';

import {
  getColorColNames,
  xAxisFormat,
  yAxisFormat,
  formatLegend,
  formatValue,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  areRequiredVariablesSetTwoDimViz,
  formatLabel,
} from './utils';
import { V2TwoDimensionChartInstructions } from 'constants/types';
import { DATE_TYPES, V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { TableColumn } from 'actions/types';
import { Point } from 'highcharts';
import { GlobalStyleConfig } from 'globalStyles/types';

declare global {
  interface PointOptionsObject {
    custom: Record<string, boolean | number | string>;
  }
}

type Props = {
  backgroundColor: string;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
  schema: TableColumn[];
  grouped?: boolean;
  horizontal?: boolean;
  area?: boolean;
  normalize?: boolean;
  globalStyleConfig: GlobalStyleConfig;
};

type State = {};

class HeatMap extends React.PureComponent<Props, State> {
  getChartId = () => {
    return `pivotChartContainer${this.props.dataPanelTemplateId}`;
  };

  instructionsReadyToDisplay = (instructions?: V2TwoDimensionChartInstructions) => {
    return !!(
      isTwoDimVizInstructionsReadyToDisplay(instructions) && instructions?.colorColumn?.column
    );
  };

  render() {
    const { instructions, loading, variables } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    if (loading || !this.instructionsReadyToDisplay(instructions) || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }
    return <HighCharts chartOptions={this._spec()} />;
  }

  _spec = (): Highcharts.Options | undefined => {
    const { previewData, schema, instructions, backgroundColor, globalStyleConfig } = this.props;
    if (schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();

    const { xCategories, yCategories } = this.getAxisCategories();
    const { aggColName } = getColorColNames(schema);
    const { valueFormatId, decimalPlaces } = this.getValueFormat();

    return {
      chart: {
        type: this.getChartType(),
        plotBorderWidth: 1,
        backgroundColor,
      },
      series: [
        //@ts-ignore
        {
          ...this.transformData(),
          nullColor: '#FFFFFF',
          borderWidth: 1,
          borderColor: '#cccccc',
        },
      ],
      title: {
        text: undefined,
      },
      plotOptions: {
        heatmap: {
          dataLabels: {
            enabled: instructions?.chartSpecificFormat?.heatMap?.showCellLabels,
            style: {
              textOutline: 'none',
              ...getLabelStyle(globalStyleConfig, 'primary'),
            },
            formatter: function () {
              return formatValue({
                value: this.point.value || 0,
                decimalPlaces,
                formatId: valueFormatId,
                hasCommas: true,
              });
            },
          },
        },
        series: {
          animation: false,
        },
      },
      yAxis: {
        categories: yCategories,
        ...yAxisFormat(globalStyleConfig, instructions?.yAxisFormats?.[0]),
        labels: {
          style: getLabelStyle(globalStyleConfig, 'secondary'),
        },
      },
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        categories: xCategories,
        labels: {
          style: getLabelStyle(globalStyleConfig, 'secondary'),
        },
      },
      colorAxis: {
        minColor: globalStyleConfig.visualizations.gradientPalette.hue1,
        maxColor: globalStyleConfig.visualizations.gradientPalette['hue2'],
        startOnTick: false,
        endOnTick: false,
      },
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
      },
      tooltip: {
        formatter: function () {
          const xName = getPointCategoryName(this.point, 'x');
          const yName = getPointCategoryName(this.point, 'y');

          return ReactDOMServer.renderToStaticMarkup(
            <BaseTooltip
              header={`${xName}, ${yName}`}
              points={[
                {
                  color: String(this.point.color),
                  name: aggColName,
                  value: this.point.value || 0,
                },
              ]}
              valueFormat={valueFormatId}
              decimalPlaces={decimalPlaces}
              globalStyleConfig={globalStyleConfig}
            />,
          );
        },
        padding: 0,
        borderWidth: 0,
        borderRadius: 0,
        backgroundColor: '#FFFFFF00',
        shadow: false,
        useHTML: true,
        outside: true,
      },
    };
  };

  getChartType = () => {
    return 'heatmap';
  };

  getValueFormat = () => {
    const { instructions } = this.props;

    return {
      valueFormatId:
        instructions?.yAxisFormats?.[0]?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
      decimalPlaces: instructions?.yAxisFormats?.[0]?.decimalPlaces ?? 2,
    };
  };

  getXAxisColName = () => {
    const { schema } = this.props;

    return schema[0].name;
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;

    if (
      !previewData ||
      !DATE_TYPES.has(instructions?.categoryColumn?.column.type || '') ||
      !schema ||
      schema.length === 0
    )
      return;

    const xAxisColName = this.getXAxisColName();

    previewData.forEach((row) => {
      if (!instructions?.categoryColumn?.column.type) return;
      row[xAxisColName] = dayjs.utc(row[xAxisColName]).valueOf();
    });
  };

  getAxisCategories = () => {
    const { instructions, previewData, schema } = this.props;
    // if (!DATE_TYPES.has(instructions?.categoryColumn?.column.type || ''))
    //   return { xCategories: undefined, yCategories: undefined };
    const { xAxisColName, colorColName } = getColorColNames(schema);
    const xCategories = Array.from(
      new Set(
        previewData.map((row) =>
          formatLabel(
            row[xAxisColName],
            instructions?.categoryColumn?.column.type,
            instructions?.categoryColumn?.bucket?.id,
            instructions?.xAxisFormat?.dateFormat,
            instructions?.xAxisFormat?.stringFormat,
          ),
        ),
      ),
    );
    const yCategories = Array.from(
      new Set(
        previewData.map((row) =>
          formatLabel(
            row[colorColName],
            instructions?.colorColumn?.column.type,
            instructions?.colorColumn?.bucket?.id,
          ),
        ),
      ),
    );
    return { xCategories, yCategories };
  };

  transformData = () => {
    const { instructions, schema } = this.props;

    if (
      !instructions?.aggColumns ||
      instructions.aggColumns.length === 0 ||
      !schema ||
      schema.length === 0
    )
      return [];

    return this.transformColorData(schema);
  };

  transformColorData = (schema: TableColumn[]) => {
    const { previewData, instructions } = this.props;
    const { xAxisColName, colorColName, aggColName } = getColorColNames(schema);
    const { xCategories, yCategories } = this.getAxisCategories();

    const dataByXAxis = _.groupBy(previewData, (row) =>
      formatLabel(
        row[xAxisColName],
        instructions?.categoryColumn?.column.type,
        instructions?.categoryColumn?.bucket?.id,
        instructions?.xAxisFormat?.dateFormat,
        instructions?.xAxisFormat?.stringFormat,
      ),
    );

    if (!xCategories || !yCategories) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const series: { type: string; data: any } = {
      type: this.getChartType(),
      data: [],
    };

    xCategories.forEach((xCategory, x) => {
      const dataByYAxis = _.indexBy(dataByXAxis[xCategory], (row) =>
        formatLabel(
          row[colorColName],
          instructions?.colorColumn?.column.type,
          instructions?.colorColumn?.bucket?.id,
        ),
      );
      yCategories.forEach((yCategory, y) => {
        if (dataByYAxis[yCategory]) {
          const row = dataByYAxis[yCategory];
          series.data.push([x, y, row[aggColName]]);
        } else {
          series.data.push([x, y, 0]);
        }
      });
    });

    return series;
  };
}

function getPointCategoryName(point: Point, dimension: string) {
  const series = point.series,
    isY = dimension === 'y',
    axis = series[isY ? 'yAxis' : 'xAxis'];
  return axis.categories[point[isY ? 'y' : 'x'] || 0];
}

export default HeatMap;
