/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import FlexBox, { HorizontalAlignment } from 'components/core/FlexBox';
import Button from 'shared/Button';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.ds.grey400}`,
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },
}));

type Props = {
  disabled?: boolean;
  onBack: () => void;
  onNext: () => void;
  secondary?: boolean;
  text: string;
};

export default function StepFooter({ disabled, onBack, onNext, secondary, text }: Props) {
  const classes = useStyles();

  return (
    <FlexBox className={classes.footer} horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}>
      <Button minimal icon="arrow-left" onClick={onBack} />
      <Button
        disabled={disabled}
        type={secondary ? 'secondary' : 'primary'}
        onClick={onNext}
        text={text}
      />
    </FlexBox>
  );
}
