/** @format */
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  OPERATION_TYPES,
  SelectedDropdownInputItem,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { cloneDeep } from 'lodash';
import React from 'react';
import { getColDisplayText } from '../../DataConfigTab/vizConfigs/utils';
import MultiYAxisConfig from './MultiYAxisConfig';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  DEFAULT_Y_AXIS_CHART_INDEX,
  DEFAULT_Y_AXIS_FORMAT_INDEX,
  getYAxisFormatIndex,
} from 'pages/dashboardPage/charts/utils/multiYAxisUtils';
import equal from 'fast-deep-equal/es6/react';
import _ from 'lodash';
import CollapsibleGroup from 'shared/CollapsibleGroup';
import DropdownSelect from 'shared/DropdownSelect';

export type Props = {
  visualizationType: string;
  instructions: V2TwoDimensionChartInstructions;
  isForHorizontalChart?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
  headerText: {
    fontWeight: 500,
    fontSize: 12,
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: 6,
    marginTop: theme.spacing(3),
  },
  axisGroup: {
    margin: `0 ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(3),
  },
}));

export default function YAxisGroupConfig({
  visualizationType,
  instructions,
  isForHorizontalChart,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const axisName = isForHorizontalChart ? 'X-Axis' : 'Y-Axis';

  return (
    <div className={classes.root}>
      {instructions.yAxisFormats?.map((yAxisFormat, index) => {
        return (
          <CollapsibleGroup
            className={classes.axisGroup}
            title={getAxisName(index, axisName)}
            isNotDeletable={instructions?.yAxisFormats?.length === 1}
            onDelete={() => {
              const newInstructions = cloneDeep(instructions);
              if (!newInstructions.yAxisFormats) return;
              const deletedYAxisFormatId = newInstructions.yAxisFormats[index].id;
              newInstructions.yAxisFormats?.splice(index, 1);
              newInstructions.aggColumns
                ?.filter((aggColumn) => aggColumn.yAxisFormatId === deletedYAxisFormatId)
                .forEach((aggColumn) => {
                  aggColumn.yAxisFormatId =
                    newInstructions.yAxisFormats?.[DEFAULT_Y_AXIS_CHART_INDEX]?.id;
                });
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}>
            <MultiYAxisConfig
              key={yAxisFormat?.id}
              visualizationType={visualizationType}
              instructions={instructions}
              yAxisFormatId={yAxisFormat?.id}
            />
          </CollapsibleGroup>
        );
      })}
      {<div className={classes.headerText}>Assign Axes</div>}
      {instructions.aggColumns?.map((aggColumn) => {
        const header = `${aggColumn.column.friendly_name || getColDisplayText(aggColumn)}`;
        return (
          <DropdownSelect
            filterable={false}
            containerClassName={classes.configInput}
            noSelectionText={`Select ${axisName}`}
            options={
              instructions.yAxisFormats?.map((yAxisFormat, index) => {
                return {
                  id: yAxisFormat.id,
                  name: getAxisName(index, axisName),
                } as SelectedDropdownInputItem;
              }) || []
            }
            selectedItem={getAxisSelectedItem(axisName, aggColumn.yAxisFormatId, instructions)}
            onChange={(newItem) => {
              const newInstructions = cloneDeep(instructions);
              const newAggColumn = _.find(newInstructions.aggColumns, (newAggColumn) =>
                equal(aggColumn, newAggColumn),
              );
              if (!newAggColumn) return;
              newAggColumn.yAxisFormatId = newItem.id;
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            label={header}
            minimal
            btnMinimal
            fillWidth
          />
        );
      })}
    </div>
  );
}

const getAxisSelectedItem = (
  axisName: string,
  yAxisFormatId?: string,
  instructions?: V2TwoDimensionChartInstructions,
) => {
  const calculatedYAxisFormatIndex = yAxisFormatId
    ? getYAxisFormatIndex(yAxisFormatId, instructions)
    : DEFAULT_Y_AXIS_FORMAT_INDEX;
  if (calculatedYAxisFormatIndex === -1) return undefined;
  return {
    id: yAxisFormatId,
    name: getAxisName(calculatedYAxisFormatIndex, axisName),
  } as SelectedDropdownInputItem;
};
const getAxisName = (yAxisFormatIndex: number, axisName: string) => {
  return `${axisName} #${yAxisFormatIndex + 1}`;
};
