/** @format */

import { AdHocOperationInstructions } from 'actions/types';
import { FilterOperationInstructions, Schema, UserTransformedSchema } from 'constants/types';
import { GlobalStylesContext } from 'globalStyles';
import React, { useContext } from 'react';
import TransformationSettingsButton from '../DataTable/TransformationSettingsButton';
import FilterButton from './FilterButton';
import Header from './Header';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { GlobalStyleConfig } from 'globalStyles/types';

const useStyles = makeStyles((theme: Theme) => ({
  header: ({ globalStyleConfig }: { globalStyleConfig: GlobalStyleConfig }) => ({
    padding: `${theme.spacing(5)}px ${globalStyleConfig.container.padding.default}px`,
    paddingBottom: theme.spacing(5),
    borderBottom: `1px solid ${globalStyleConfig.container.outline.color}`,
  }),
}));

type Props = {
  adHocOperationInstructions: AdHocOperationInstructions;
  error?: boolean;
  infoTooltipText?: string;
  isSchemaCustomizationEnabled?: boolean;
  isFilteringDisabled?: boolean;
  loading?: boolean;
  onAdHocFilterInfoUpdate: (adHocFilterInfo: FilterOperationInstructions) => void;
  panelName: string;
  schema: Schema;
  updateUserTransformedSchema?: (userTransformedSchema: UserTransformedSchema) => void;
  userTransformedSchema?: UserTransformedSchema;
};

export default function DataTableHeader({
  adHocOperationInstructions,
  error,
  infoTooltipText,
  isSchemaCustomizationEnabled,
  isFilteringDisabled,
  loading,
  onAdHocFilterInfoUpdate,
  panelName,
  schema,
  updateUserTransformedSchema,
  userTransformedSchema,
}: Props) {
  const { globalStyleConfig } = useContext(GlobalStylesContext);
  const classes = useStyles({ globalStyleConfig });

  const dataTableHeaderActions = (
    <>
      {isSchemaCustomizationEnabled && (
        <TransformationSettingsButton
          loading={loading}
          schema={schema}
          userTransformedSchema={userTransformedSchema}
          updateUserTransformedSchema={(schema) => {
            updateUserTransformedSchema?.(schema);
          }}
        />
      )}
      {!isFilteringDisabled && (
        <FilterButton
          adHocFilterInfo={adHocOperationInstructions?.filterInfo}
          onAdHocFilterInfoUpdate={onAdHocFilterInfoUpdate}
          schema={isSchemaCustomizationEnabled ? userTransformedSchema ?? schema : schema}
          error={!!error}
          loading={loading}
        />
      )}
    </>
  );

  return (
    <Header
      actions={dataTableHeaderActions}
      className={classes.header}
      infoTooltipText={infoTooltipText}
      inheritBaseBgColor
      loading={loading}
      panelName={panelName}
    />
  );
}
