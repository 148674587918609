/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';
import { IconName } from '@blueprintjs/core';

import Button from 'shared/Button';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.lightBlue,
    border: `1px solid ${theme.palette.ds.lightBlue}`,
    padding: `0px ${theme.spacing(2)}px`,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 32,

    '&:last-child': {
      marginBottom: 0,
    },

    '&:hover': {
      border: `1px solid ${theme.palette.ds.blue}`,
      cursor: 'pointer',
    },
  },
  name: {
    fontWeight: 600,
    color: '#182026',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  iconBtn: {
    marginLeft: theme.spacing(3),
    minWidth: 24,
    minHeight: 24,

    '&.bp3-button': {
      '&:hover': {
        backgroundColor: `${theme.palette.ds.hovered.lightBlue} !important`,
      },
    },
    '&.bp3-button,.bp3-icon': {
      color: theme.palette.ds.blue,
    },
  },
}));

export type Props = {
  className?: string;
  columnName?: string;
  icon: IconName;
  onIconClick?: () => void;
};

export default function DroppedColumn({ columnName, className, icon, onIconClick }: Props) {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.name}>{columnName}</div>
      <Button
        compact
        minimal
        className={classes.iconBtn}
        icon={icon}
        onClick={() => onIconClick?.()}
      />
    </div>
  );
}
