/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { EditableText, Intent, Button } from '@blueprintjs/core';

const styles = (theme: Theme) => ({
  root: {},
  pageNumberRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageNumberEditableText: {
    marginLeft: 5,
    marginRight: 5,
    width: 50,

    '& .bp3-editable-text-content': {
      textAlign: 'center' as 'center',
    },
  },
  flexContainer: {
    display: 'flex',
  },
  pageNumberSpinner: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

type PassedProps = {
  className?: string;
  loading?: boolean;
  currentPageNumber: number;
  changePage: (newPage: number) => void;
  pageNumberInputVal: number;
  maxPageNumber: number;
  pageNumberInputIsInvalid: boolean;
  changePageNumberInputVal: (pageNumber: number) => void;
  changePageNumberisInvalid: (val: boolean) => void;
};

type Props = PassedProps & WithStyles<typeof styles> & RouteComponentProps;

class TablePageNavigation extends React.Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.pageNumberRow}>
        {this.renderPrevPageButton()}
        {this.renderPageNumInput()}
        {this.renderNextPageButton()}
      </div>
    );
  }

  renderPageNumInput = () => {
    const {
      classes,
      loading,
      pageNumberInputVal,
      maxPageNumber,
      pageNumberInputIsInvalid,
      changePageNumberInputVal,
    } = this.props;

    if (loading) {
      return <div className={classes.flexContainer}>Page 0</div>;
    }

    return (
      <div>
        Page
        <EditableText
          className={classes.pageNumberEditableText}
          placeholder="#"
          selectAllOnFocus={true}
          value={pageNumberInputVal.toString()}
          onConfirm={(value: string) => this.evaluatePageNumberInput(value, maxPageNumber)}
          intent={pageNumberInputIsInvalid ? Intent.DANGER : Intent.NONE}
          disabled={maxPageNumber === 1}
          onChange={(value: string) => changePageNumberInputVal(parseInt(0 + value))}
        />
        {`of ${maxPageNumber}`}
      </div>
    );
  };

  renderPrevPageButton = () => {
    const { loading, currentPageNumber, changePage } = this.props;

    return (
      <Button
        icon="chevron-left"
        disabled={loading || currentPageNumber <= 1}
        minimal
        onClick={() => {
          changePage(currentPageNumber - 1);
        }}
      />
    );
  };

  renderNextPageButton = () => {
    const { loading, currentPageNumber, changePage, maxPageNumber } = this.props;

    return (
      <Button
        icon="chevron-right"
        disabled={loading || currentPageNumber >= maxPageNumber}
        minimal
        onClick={() => {
          changePage(currentPageNumber + 1);
        }}
      />
    );
  };

  evaluatePageNumberInput = (value: string, maxPageNumber: number) => {
    const { currentPageNumber, changePage, changePageNumberisInvalid } = this.props;
    if (!/^\d+$/.test(value)) {
      changePageNumberisInvalid(true);
    } else {
      const newPage = parseInt(value);
      if (maxPageNumber === 0 || newPage === 0 || newPage > maxPageNumber) {
        changePageNumberisInvalid(true);
      } else {
        changePageNumberisInvalid(false);
        if (newPage !== currentPageNumber) changePage(newPage);
      }
    }
  };
}

export default withRouter(withStyles(styles)(TablePageNavigation));
