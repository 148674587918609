/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';
import { useDrop } from 'react-dnd';

import DroppedFilterColumn from './DroppedFilterColumn';

import { ItemTypes, ConfigColumnItem } from 'constants/dragAndDrop';
import { TableColumn } from 'actions/types';
import {
  FilterClause,
  FilterOperatorType,
  FilterValueType,
  FilterValueSourceType,
} from 'constants/types';
import { DashboardElement } from 'types/dashboardTypes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.ds.grey600,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    border: `1px solid ${theme.palette.ds.white}`, // to account for hover border
  },
  colIsOver: {
    border: `1px dashed ${theme.palette.ds.blue}`,
    boxShadow: `0px 0px 2px ${theme.palette.ds.blue}`,
  },
}));

export type Props = {
  onColDropped: (col: TableColumn) => void;
  clauses: FilterClause[];
  onRemoveFilterClause: (index: number) => void;
  onOperatorSelect: (index: number, operator: FilterOperatorType) => void;
  onFilterValueUpdate: (index: number, value: FilterValueType) => void;
  onFilterValueSourceSelect: (index: number, source: FilterValueSourceType) => void;
  onFilterValueVariableSelect: (index: number, variableId: string) => void;
  dashboardElements?: DashboardElement[];
};

export default function DroppableFilterColumnSection({
  onColDropped,
  clauses,
  onRemoveFilterClause,
  onOperatorSelect,
  onFilterValueUpdate,
  onFilterValueSourceSelect,
  onFilterValueVariableSelect,
  dashboardElements,
}: Props) {
  const classes = useStyles();
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CONFIGURATION_COLUMN,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item: ConfigColumnItem) => {
      onColDropped(item.data);
    },
  });
  const validFilters = clauses.filter((clause) => clause.filterColumn);
  return (
    <div ref={drop} className={cx(classes.root, { [classes.colIsOver]: isOver })}>
      {validFilters.length === 0 && 'Drop field here...'}
      {validFilters.map((clause, i) => (
        <DroppedFilterColumn
          key={`filter-clause-${i}-${clause.filterColumn?.name}`}
          clause={clause}
          onRemoveCol={() => onRemoveFilterClause(i)}
          onOperatorSelect={(operator) => onOperatorSelect(i, operator)}
          onFilterValueUpdate={(value) => onFilterValueUpdate(i, value)}
          onFilterValueSourceSelect={(source) => onFilterValueSourceSelect(i, source)}
          onFilterValueVariableSelect={(variableId) => onFilterValueVariableSelect(i, variableId)}
          dashboardElements={dashboardElements}
        />
      ))}
    </div>
  );
}
