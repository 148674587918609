/** @format */

import React from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import {
  DashboardElement,
  DashboardVariable,
  DashboardVariableMap,
  DASHBOARD_ELEMENT_TYPES,
} from 'types/dashboardTypes';
import { DashboardParam, DataPanelTemplate, EndUserGroup } from 'actions/types';

const useStyles = makeStyles((theme: Theme) => ({
  variablesContainer: {
    fontFamily: 'Source Code Pro',
    overflow: 'auto',
    padding: theme.spacing(1.5),
  },
  variable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    borderRadius: 2,

    '&:hover': {
      backgroundColor: theme.palette.ds.lightBlue,
    },
  },
  variableName: {
    fontSize: 12,
  },
  variableValue: {
    fontSize: 12,
    textAlign: 'right',
  },
  sectionContainer: {
    padding: `${theme.spacing(2)}px 0`,
  },
  sectionHeader: {
    color: theme.palette.ds.grey700,
    fontSize: 12,
    textTransform: 'uppercase',
    marginLeft: 6,
    marginBottom: theme.spacing(1),
    fontWeight: 500,
  },
}));

type Props = {
  variables: DashboardVariableMap;
  dashboardInputElements: DashboardElement[];
  dataPanelsWithDrilldown: DataPanelTemplate[];
  selectedUserGroupId?: number;
  endUserGroups: EndUserGroup[];
  dashboardParams: Record<string, DashboardParam>;
};

export default function RawVariableList({
  variables,
  dashboardInputElements,
  dataPanelsWithDrilldown,
  selectedUserGroupId,
  endUserGroups,
  dashboardParams,
}: Props) {
  const classes = useStyles();

  const groupEndUserGroupsById = _.indexBy(endUserGroups, 'id');
  const selectedUserGroup = selectedUserGroupId && groupEndUserGroupsById[selectedUserGroupId];

  return (
    <div className={classes.variablesContainer}>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>Customer</div>
        <div className={classes.variable}>
          <div className={classes.variableName}>user_group_id</div>
          <div className={classes.variableValue}>
            {JSON.stringify(selectedUserGroup ? selectedUserGroup.provided_id : undefined)}
          </div>
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>Dashboard Elements</div>
        {dashboardInputElements.map((element) => {
          let value = variables[element.name];

          if (element.element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) {
            if (!value) {
              value = JSON.stringify(value);
              return undefined;
            }
            const valueObj = value as Record<string, DashboardVariable>;
            return (
              <>
                {Object.keys(valueObj).map((key) => (
                  <div className={classes.variable} key={`variable-${element.name}-${key}`}>
                    <div className={classes.variableName}>{`${element.name}.${key}`}</div>
                    <div className={classes.variableValue}>{JSON.stringify(valueObj[key])}</div>
                  </div>
                ))}
              </>
            );
          } else {
            value = JSON.stringify(value);
          }

          return (
            <div className={classes.variable} key={`variable-${element.name}`}>
              <div className={classes.variableName}>{element.name}</div>
              <div className={classes.variableValue}>{value}</div>
            </div>
          );
        })}
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>Data Panels</div>
        {dataPanelsWithDrilldown.map((dpt) => {
          const dptId = dpt.provided_id || dpt.id;
          const value = variables[dptId];
          if (!value) {
            return (
              <div className={classes.variable} key={`variable-${dptId}`}>
                <div className={classes.variableName}>{`${dptId}`}</div>
                <div className={classes.variableValue}></div>
              </div>
            );
          }

          const valueObj = value as Record<string, DashboardVariable>;
          return (
            <>
              {Object.keys(valueObj).map((key) => (
                <div className={classes.variable} key={`variable-${dptId}-${key}`}>
                  <div className={classes.variableName}>{`${dptId}.${key}`}</div>
                  <div className={classes.variableValue}>{JSON.stringify(valueObj[key])}</div>
                </div>
              ))}
            </>
          );
        })}
      </div>

      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>Custom Variables</div>

        {Object.values(dashboardParams).map((param) => {
          const value = variables[param.name];

          return (
            <div className={classes.variable} key={`custom-variable-preview-${param.name}`}>
              <div className={classes.variableName}>{param.name}</div>
              <div className={classes.variableValue}>{JSON.stringify(value)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
