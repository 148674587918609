/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/index';
import { FormGroup, TextArea, Intent } from '@blueprintjs/core';

import FullPageContentContainer from 'shared/FullPageContentContainer';
import ConnectStepByStep from '../ConnectStepByStep';
import ConnectFAQs from '../ConnectFAQs';
import InputWithTag from 'shared/InputWithTag';
import CalloutLink from 'shared/CalloutLink';
import ToggleSwitchInput from 'shared/ToggleSwitchInput';

import { ConnectDataSourceStep, EXPLO_EGRESS_IPS } from '../constants';
import { DBConnectionConfig } from '../types';
import { SupportedDataSource } from 'actions/types';

const useStyles = makeStyles((theme: Theme) => ({
  body: {},
  helpTooltip: {
    margin: theme.spacing(1.5),
  },
  credentialInput: {
    marginBottom: theme.spacing(4),
  },
  sectionHeader: {
    fontWeight: 600,
    fontSize: 16,
    marginBottom: theme.spacing(1),
  },
  sshTunnelHeader: {
    marginBottom: theme.spacing(4),
  },
  sectionText: {
    fontSize: 14,
    marginBottom: theme.spacing(4),
  },
  whitelistCallout: {
    marginBottom: theme.spacing(8),
  },
}));

export type Props = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  onBackClicked: () => void;
  testingConnLoading?: boolean;
};

const SecurityConfiguration: React.FC<Props> = ({
  config,
  updateConfig,
  onNextClicked,
  onBackClicked,
  testingConnLoading,
}) => {
  if (!config.selectedDataSource)
    return <div>Error: A data source type must be selected before proceeding</div>;

  const dataSource = config.selectedDataSource;
  const configuration = config.dataSourceConfig || {};

  return (
    <FullPageContentContainer
      headerTitle="Security"
      primaryActionConfig={{
        text: 'Next',
        disabled: !isSecurityConfigComplete(dataSource, configuration),
        onClick: onNextClicked,
        loading: testingConnLoading,
      }}
      backBtnConfig={{
        onClick: onBackClicked,
      }}
      leftSideBarTopContent={<ConnectStepByStep currentStep={ConnectDataSourceStep.SECURITY} />}
      leftSideBarBottomContent={<ConnectFAQs />}
      bodyContent={<SecurityConfigurationBody config={config} updateConfig={updateConfig} />}
    />
  );
};

export type BodyProps = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
};

export const SecurityConfigurationBody: React.FC<BodyProps> = ({ config, updateConfig }) => {
  const classes = useStyles();

  if (!config.selectedDataSource) return <div>Oops</div>;

  const dataSource = config.selectedDataSource;
  const configurationSchema = dataSource.configuration_schema;
  const configuration = config.dataSourceConfig || {};

  return (
    <div className={classes.body}>
      <div className={classes.sectionHeader}>Whitelisting</div>
      <div className={classes.sectionText}>
        If your database is behind a firewall, please whitelist Explo’s egress IPs:
        <b> {EXPLO_EGRESS_IPS.join(', ')}.</b>
      </div>
      <CalloutLink
        className={classes.whitelistCallout}
        url="https://docs.explo.co/database-setup/connecting-to-your-database-or-data-warehouse#secure-connections"
        text="How to whitelist Explo's egress IPs"
      />
      <div className={cx(classes.sectionHeader, classes.sshTunnelHeader)}>SSH Tunnel</div>
      {configurationSchema &&
        configurationSchema.order.map((propertyName: string) => {
          const properties = configurationSchema.properties;
          if (
            properties[propertyName].depends_on &&
            !configuration[properties[propertyName].depends_on || '']
          ) {
            return null;
          }

          if (!properties[propertyName].security_page) {
            return null;
          }

          if (properties[propertyName].type === 'checkbox') {
            return (
              <ToggleSwitchInput
                labelOnRight
                flexTogether
                largeText
                className={classes.credentialInput}
                key={`add_source_field_${dataSource.type}_${propertyName}`}
                switchOn={configuration[propertyName] || false}
                onChange={() => {
                  configuration[propertyName] = !configuration[propertyName];
                  updateConfig({
                    ...config,
                    dataSourceConfig: configuration,
                  });
                }}
                label={properties[propertyName].label}
                helpText={properties[propertyName].help_tooltip}
              />
            );
          } else if (properties[propertyName].type === 'textarea') {
            return (
              <FormGroup
                label={properties[propertyName].label}
                labelInfo={properties[propertyName].optional ? undefined : '*'}
                key={`add_source_field_${dataSource.type}_${propertyName}`}>
                <TextArea
                  fill
                  value={configuration[propertyName] || ''}
                  placeholder={properties[propertyName].placeholder}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    configuration[propertyName] = e.target.value;
                    updateConfig({
                      ...config,
                      dataSourceConfig: configuration,
                    });
                  }}
                />
              </FormGroup>
            );
          } else {
            return (
              <InputWithTag
                className={classes.credentialInput}
                label={properties[propertyName].label}
                key={`add_source_field_${dataSource.type}_${propertyName}`}
                type={properties[propertyName].type}
                value={configuration[propertyName] || ''}
                placeholder={properties[propertyName].placeholder}
                helpText={properties[propertyName].help_tooltip}
                statusInfo={{
                  statusIcon: configuration[propertyName] ? 'tick' : undefined,
                  statusIntent: Intent.SUCCESS,
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  configuration[propertyName] =
                    properties[propertyName].type === 'number'
                      ? parseInt(e.target.value)
                      : e.target.value;
                  updateConfig({
                    ...config,
                    dataSourceConfig: configuration,
                  });
                }}
              />
            );
          }
        })}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSecurityConfigComplete = (dataSource: SupportedDataSource, configuration: any) => {
  const properties = dataSource.configuration_schema.properties;

  return _.all(
    _.map(dataSource.configuration_schema.order, (propertyName: string) => {
      const propertyConfig = properties[propertyName];

      if (!propertyConfig.security_page) return true;

      if (
        (propertyConfig.depends_on && !configuration[propertyConfig.depends_on || '']) ||
        propertyConfig.optional
      ) {
        return true;
      }

      // A checkbox field is configured since if it is not present, it is false
      if (propertyConfig.type === 'checkbox') {
        return true;
      }

      return (
        configuration[propertyName] !== null &&
        configuration[propertyName] !== undefined &&
        configuration[propertyName] !== ''
      );
    }),
  );
};

export default SecurityConfiguration;
