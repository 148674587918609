/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
  },
  fill: {
    width: '100%',
  },
  lineRoot: {
    width: 'calc(100% - 8px)',
    backgroundColor: theme.palette.ds.grey400,
    height: 4,
    position: 'relative',
  },
  labelsContainer: {
    display: 'flex',
    paddingBottom: theme.spacing(1.5),
    width: '100%',
    fontSize: 10,
  },
  label: {
    flex: 1,
    cursor: 'pointer',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  breakpoint: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: theme.palette.ds.grey400,
    position: 'absolute',
    bottom: -3,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.ds.grey500,
    },
  },
  breakpointInnerCircle: {
    width: 4,
    height: 4,
    backgroundColor: theme.palette.ds.white,
    marginLeft: 3,
    marginTop: 3,
    borderRadius: 5,

    '&.selected': {
      width: 6,
      height: 6,
      marginLeft: 2,
      marginTop: 2,
      borderRadius: 6,
    },
  },
  selectedInnerCircle: {
    width: 4,
    height: 4,
    backgroundColor: theme.palette.ds.blue,
    borderRadius: 3,
    position: 'absolute',
    top: 3,
    left: 3,
  },
  breakpointContainer: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    cursor: 'pointer',
    top: -4,
    position: 'absolute',
  },
  breakpointSelected: {
    width: 10,
    height: 10,
    backgroundColor: theme.palette.ds.blue,
    bottom: -3,

    '&:hover': {
      backgroundColor: theme.palette.ds.blue,
    },

    '&:active': {
      backgroundColor: theme.palette.ds.grey600,
    },
  },
  betweenBreakpoints: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: theme.palette.ds.grey400,
    position: 'absolute',
    bottom: 0,
  },
  betweenBreakpointSelected: {
    backgroundColor: theme.palette.ds.blue,
  },
  lowerBound: {
    left: -4,
  },
  afterLowerBound: {
    left: 'calc(12.5% - 2px)',
  },
  lowerQuartile: {
    left: 'calc(25% - 4px)',
  },
  afterLowerQuartile: {
    left: 'calc(37.5% - 2px)',
  },
  median: {
    left: 'calc(50% - 4px)',
  },
  afterMedian: {
    left: 'calc(62.5% - 2px)',
  },
  upperQuartile: {
    right: 'calc(25% - 4px)',
  },
  afterUpperQuartile: {
    right: 'calc(12.5% - 2px)',
  },
  upperBound: {
    right: -4,
  },
}));

export type Props = {
  /**
   * An array of 5 numbers increasing in value
   */
  breakpoints: number[];
  /**
   * Optional classname to be applied to the wrapper div of the component
   */
  className?: string;
  /**
   * The actual value being selected by the user
   */
  value: number;
  /**
   * Function to update the value being selected
   */
  setValue: (value: number) => void;
  /**
   * A list of up to 3 elements to be placed as labels in the left, center, and right
   */
  labels?: string[];
  /**
   * Whether or not the component should fill the width of its container
   */
  fill?: boolean;
};

export default function LineSelect({
  className,
  breakpoints,
  value,
  setValue,
  labels,
  fill,
}: Props) {
  const classes = useStyles();

  const LABEL_ALIGN_CLASSES = [undefined, classes.textAlignCenter, classes.textAlignRight];

  const SELECTION_CLASSES = [
    classes.lowerBound,
    classes.afterLowerBound,
    classes.lowerQuartile,
    classes.afterLowerQuartile,
    classes.median,
    classes.afterMedian,
    classes.upperQuartile,
    classes.afterUpperQuartile,
    classes.upperBound,
  ];
  const SELECTION_ENABLED = [
    value <= breakpoints[0],
    value > breakpoints[0] && value < breakpoints[1],
    value === breakpoints[1],
    value > breakpoints[1] && value < breakpoints[2],
    value === breakpoints[2],
    value > breakpoints[2] && value < breakpoints[3],
    value === breakpoints[3],
    value > breakpoints[3] && value < breakpoints[4],
    value >= breakpoints[4],
  ];

  return (
    <div className={cx(classes.root, { [classes.fill]: fill }, className)}>
      <div className={classes.labelsContainer}>
        {labels?.map((label, idx) => (
          <div
            key={label}
            className={cx(classes.label, LABEL_ALIGN_CLASSES[idx])}
            onClick={() => {
              const gapSize = (breakpoints.length - labels.length) / (labels.length - 1);
              setValue(breakpoints[idx + idx * gapSize]);
            }}>
            {label}
          </div>
        ))}
      </div>
      <div className={classes.lineRoot}>
        {SELECTION_CLASSES.map((selectionClass, idx) => {
          const isBreakpoint = idx % 2 === 0;
          const isSelected = SELECTION_ENABLED[idx];

          return (
            <div
              key={selectionClass}
              className={cx(selectionClass, {
                [classes.breakpoint]: isBreakpoint,
                [classes.breakpointSelected]: isBreakpoint && isSelected,
                [classes.betweenBreakpoints]: !isBreakpoint,
                [classes.betweenBreakpointSelected]: !isBreakpoint && isSelected,
              })}>
              <div
                className={isBreakpoint ? classes.breakpointContainer : ''}
                onClick={() => {
                  if (!isBreakpoint) return;

                  setValue(breakpoints[idx / 2]);
                }}></div>
              {isBreakpoint && (
                <div
                  className={cx(classes.breakpointInnerCircle, {
                    selected: isSelected,
                  })}
                />
              )}
              {isSelected && <div className={classes.selectedInnerCircle} />}
            </div>
          );
        })}
      </div>
    </div>
  );
}
