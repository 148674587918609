/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { VisualizeNumberInstructions } from 'constants/types';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(4),
    },
  });

type PassedProps = {
  visualizeNumberInstructions: VisualizeNumberInstructions;
  updateVisualizeOperation: (newVisualizeNumberInstructions: VisualizeNumberInstructions) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class NumberProgressGoalInput extends React.Component<Props> {
  render() {
    const { classes, visualizeNumberInstructions } = this.props;
    const { layoutConfig } = visualizeNumberInstructions;

    return (
      <div className={classes.root}>
        <InputWithBlurSave
          label="Progress Goal"
          initialValue={String(layoutConfig.NUMBER.progressGoal || 0)}
          onNewValueSubmitted={this.onUnitsChange}
          placeholder="Enter Progress Bar Goal"
        />
      </div>
    );
  }

  onUnitsChange = (newValue: string) => {
    const { visualizeNumberInstructions, updateVisualizeOperation } = this.props;

    visualizeNumberInstructions.layoutConfig.NUMBER.progressGoal = parseInt(newValue) || 0;

    updateVisualizeOperation(visualizeNumberInstructions);
  };
}

export default withStyles(styles)(NumberProgressGoalInput);
