/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';
import { Switch } from '@blueprintjs/core';

import InputLabel from 'shared/InputLabel';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&.flexTogether': {
      justifyContent: 'flex-start',
    },
  },
  label: {
    marginBottom: 0,
    marginRight: theme.spacing(2),

    '&.onRight': {
      marginRight: 0,
      marginLeft: theme.spacing(2),
    },
  },
  switchStyles: {
    '&.bp3-control': {
      marginBottom: 0,
      // to account for the white space to the right of the switch
      marginRight: -10,
    },
    '&.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator': {
      backgroundColor: theme.palette.ds.blue,
    },
  },
}));

export type Props = {
  /**
   * optional class to wrap entire component
   */
  className?: string;
  /**
   * optional class to wrap the label element
   */
  labelClassName?: string;
  /**
   * optional label shown above the input in the top left
   */
  label?: string;
  /**
   * If true, label will be on right side of switch
   */
  labelOnRight?: boolean;
  /**
   * Flag to disable input from user input
   */
  disabled?: boolean;
  /**
   * Whether the toggle is enabled or not
   */
  switchOn?: boolean;
  /**
   * Function that runs on change events
   */
  onChange: (newValue: boolean) => void;
  /**
   * If provided, an informational tooltip will show up next to the label
   */
  helpText?: string;
  /**
   * If provided, font size will be normal body font size (14px)
   */
  largeText?: boolean;
  /**
   * If provided, the label and switch will be kept together in the flex box
   */
  flexTogether?: boolean;
};

const ToggleSwitchInput: React.FC<Props> = ({
  className,
  label,
  labelClassName,
  disabled,
  labelOnRight,
  switchOn,
  onChange,
  helpText,
  largeText,
  flexTogether,
}) => {
  const classes = useStyles();

  const labelElem = (
    <InputLabel
      text={label || ''}
      className={cx(
        classes.label,
        labelClassName,
        GLOBAL_STYLE_CLASSNAMES.text.smallBody.secondary,
        { onRight: labelOnRight },
      )}
      helpText={helpText}
      largeText={largeText}
    />
  );

  return (
    <div className={cx(classes.root, className, { flexTogether: flexTogether })}>
      {label && !labelOnRight && labelElem}
      <Switch
        className={cx(
          classes.switchStyles,
          GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.toggleBackgroundColor,
        )}
        checked={switchOn}
        onChange={() => {
          onChange(!switchOn);
        }}
        disabled={disabled}
      />
      {label && labelOnRight && labelElem}
    </div>
  );
};

export default ToggleSwitchInput;
