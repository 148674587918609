/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { format } from 'utils/localizationUtils';

import TooltipWrapper from './TooltipWrapper';
import { formatValue } from './../utils';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { GlobalStyleConfig } from 'globalStyles/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
    minWidth: 200,
    border: `1px solid #D4D9DF`,
    borderRadius: 3,
    backgroundColor: theme.palette.ds.white,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 600,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    borderBottom: `1px solid #D4D9DF`,
    backgroundColor: theme.palette.ds.grey100,
  },
  points: {
    padding: theme.spacing(1),
  },
  pointsTable: {
    backgroundColor: theme.palette.ds.white,
    width: '100%',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  pointContainer: {
    '& td': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },

    '&.selected': {
      backgroundColor: theme.palette.ds.grey200,
    },
  },
  pointNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pointColor: {
    width: 6,
    height: 6,
    borderRadius: 6,
    marginRight: theme.spacing(1),
  },
  pointName: {
    marginRight: theme.spacing(1),
  },
  pointValue: {
    fontWeight: 600,
    marginLeft: theme.spacing(3),
  },
  pctValue: {
    fontWeight: 600,
    marginLeft: theme.spacing(3),
  },
}));

export type PointData = {
  color: string;
  name: string;
  value?: number;
  selected?: boolean;
  decimalPlaces?: number;
  valueFormat?: string;
};

export type Props = {
  header: string;
  points: PointData[];
  includePct?: boolean;
  valueFormat?: string;
  decimalPlaces?: number;
  globalStyleConfig?: GlobalStyleConfig;
};

function BaseTooltip({
  header,
  points,
  includePct,
  valueFormat,
  decimalPlaces,
  globalStyleConfig,
}: Props) {
  const classes = useStyles();

  const totalValue = _.reduce(_.compact(_.pluck(points, 'value')), (s, v) => s + v, 0);

  return (
    <div
      className={classes.root}
      style={{
        fontFamily:
          globalStyleConfig &&
          (globalStyleConfig.text.overrides.smallBody?.font
            ? globalStyleConfig.text.primaryFont
            : globalStyleConfig.text.secondaryFont),
      }}>
      <div className={classes.header}>{header}</div>
      <div className={classes.points}>
        <table className={cx(classes.pointsTable, { includePct })}>
          {points.map((point) => (
            <tr
              className={cx(classes.pointContainer, { selected: point.selected })}
              key={_.uniqueId('chart-tooltip-item')}>
              <td className={classes.pointNameContainer}>
                <div className={classes.pointColor} style={{ backgroundColor: point.color }} />
                <div className={classes.pointName}>{point.name}</div>
              </td>
              <td className={classes.pointValue}>
                {formatValue({
                  value: point.value || 0,
                  decimalPlaces: point.decimalPlaces || decimalPlaces || 0,
                  formatId: point.valueFormat || valueFormat || V2_NUMBER_FORMATS.NUMBER.id,
                  hasCommas: true,
                })}
              </td>
              {includePct ? (
                <td className={classes.pctValue}>
                  {format('.2f')(((point.value || 0) / totalValue) * 100)}%
                </td>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
}

export default function BaseTooltipWrapper(props: Props) {
  return (
    <TooltipWrapper>
      <BaseTooltip {...props} />
    </TooltipWrapper>
  );
}
