/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core/index';
import Button from 'shared/Button';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const styles = () =>
  createStyles({
    errorStateContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

type PassedProps = {
  text: string;
};

type Props = PassedProps & RouteComponentProps & WithStyles<typeof styles>;

class ErrorState extends React.Component<Props> {
  render() {
    const { classes, text, history } = this.props;

    return (
      <div className={classes.errorStateContainer}>
        <p>{text}</p>
        <Button
          minimal
          onClick={() => history.push('/home')}
          text="Click here to go back to the homepage."
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ErrorState));
