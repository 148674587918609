/** @format */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import cx from 'classnames';
import { Icon, IconName } from '@blueprintjs/core';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import FlexBox, { VerticalAlignment, HorizontalAlignment } from 'components/core/FlexBox';

import { saveDashboardDraft } from 'actions/dashboardV2Actions';
import { DashboardVersionConfig, DashboardVersionInfo } from 'actions/types';
import { createDebouncedFn } from 'utils/general';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: 2,
    color: theme.palette.ds.grey800,
    fontSize: 12,
    backgroundColor: theme.palette.ds.grey200,
  },
  savingText: {
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
}));

export type Props = {
  className?: string;
  versionInfo: DashboardVersionInfo;
};

const debounceFn = createDebouncedFn(2000);

const SavingInfo: FunctionComponent<Props> = (props) => {
  const { className, versionInfo } = props;
  const theme = useTheme();
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [saveWorked, setSaveWorked] = useState<boolean | undefined>(true);
  const { currentDashboardTemplate } = useSelector((state: ReduxState) => ({
    currentDashboardTemplate: state.dashboard.currentDashboardTemplate,
  }));

  useEffect(() => {
    const onConfigUpdated = (
      e: CustomEvent<{ config: DashboardVersionConfig; version: DashboardVersionInfo }>,
    ) => {
      setSaving(true);
      debounceFn(() => {
        setSaveWorked(undefined);
        dispatch(
          saveDashboardDraft(
            {
              id: currentDashboardTemplate?.id,
              postData: {
                config: e.detail.config,
                version_number: e.detail.version.version_number,
              },
            },
            () => {
              setSaving(false);
              setSaveWorked(true);
            },
            () => {
              setSaving(false);
              setSaveWorked(false);
            },
          ),
        );
      });
    };
    //@ts-ignore
    window.addEventListener('dashboardConfigUpdated', onConfigUpdated);
    return () => {
      //@ts-ignore
      window.removeEventListener('dashboardConfigUpdated', onConfigUpdated);
    };
  }, [currentDashboardTemplate, dispatch]);

  let icon: IconName = 'refresh';

  if (!saving) {
    if (saveWorked) {
      icon = 'tick';
    } else if (saveWorked === false) {
      icon = 'error';
    }
  }

  return (
    <FlexBox
      verticalAlignment={VerticalAlignment.CENTER}
      horizontalAlignment={HorizontalAlignment.CENTER}
      className={cx(classes.root, className)}>
      <Icon icon={icon} iconSize={12} color={theme.palette.ds.grey800} />{' '}
      <div className={classes.savingText}>
        Version: {versionInfo.version_number} {versionInfo.is_draft ? '(draft)' : ''}
      </div>
    </FlexBox>
  );
};

export default SavingInfo;
