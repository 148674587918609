/** @format */

import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';
import { ButtonGroup } from '@blueprintjs/core';

import DropdownSelect from 'shared/DropdownSelect';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ColorPickerButton from 'shared/ColorPicker';
import InputLabel from 'shared/InputLabel';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2KPIChartInstructions } from 'constants/types';
import { SelectedDropdownInputItem } from 'constants/types';
import { V2_NUMBER_FORMATS, TIME_DIFF_FORMATS } from 'constants/dataConstants';
import Button from 'shared/Button';
import { getCategoricalColors } from 'globalStyles';
import { ReduxState } from 'reducers/rootReducer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
  label: {
    padding: `0px ${theme.spacing(3)}px`,
  },
  colorPicker: {
    width: '100%',
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2KPIChartInstructions;
};

export default function KPIValueConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { globalStyleConfig } = useSelector((state: ReduxState) => ({
    globalStyleConfig: state.styleConfig.current,
  }));

  const selectedFormat = instructions.valueFormat?.numberFormat || V2_NUMBER_FORMATS.PLAIN_TEXT;
  const selectedTimeFormat = instructions.valueFormat?.timeFormat || TIME_DIFF_FORMATS.STANDARD;

  return (
    <div className={classes.root}>
      {' '}
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={instructions.valueFormat?.units}
        onNewValueSubmitted={(newValue: string) => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.valueFormat = {
            ...newInstructions.valueFormat,
            units: newValue,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Unit"
      />
      <DropdownSelect
        containerClassName={classes.configInput}
        selectedItem={selectedFormat}
        onChange={(item: SelectedDropdownInputItem) => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.valueFormat = {
            ...newInstructions.valueFormat,
            numberFormat: item,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        filterable={false}
        options={Object.values(V2_NUMBER_FORMATS)}
        noSelectionText="Plain Text"
        minimal
        btnMinimal
        fillWidth
        label="Format"
      />
      {selectedFormat.id === V2_NUMBER_FORMATS.TIME.id && (
        <>
          <DropdownSelect
            containerClassName={classes.configInput}
            selectedItem={selectedTimeFormat}
            onChange={(item: SelectedDropdownInputItem) => {
              const newInstructions = cloneDeep(instructions);

              newInstructions.valueFormat = {
                ...newInstructions.valueFormat,
                timeFormat: item,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            filterable={false}
            options={Object.values(TIME_DIFF_FORMATS)}
            noSelectionText="Standard"
            minimal
            btnMinimal
            fillWidth
            label="Time Format"
          />
          {instructions.valueFormat?.timeFormat?.id === TIME_DIFF_FORMATS.CUSTOM.id && (
            <InputWithBlurSave
              containerClassName={classes.configInput}
              initialValue={instructions.valueFormat?.timeCustomerFormat}
              onNewValueSubmitted={(newValue: string) => {
                const newInstructions = cloneDeep(instructions);
                newInstructions.valueFormat = {
                  ...newInstructions.valueFormat,
                  timeCustomerFormat: newValue,
                };
                dispatch(
                  updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                );
              }}
              placeholder="DD days HH:mm:ss"
              label="Custom Time Format"
            />
          )}
        </>
      )}
      {selectedFormat.id !== V2_NUMBER_FORMATS.PLAIN_TEXT.id &&
        selectedFormat.id !== V2_NUMBER_FORMATS.TIME.id && (
          <InputWithBlurSave
            containerClassName={classes.configInput}
            initialValue={String(instructions.valueFormat?.decimalPlaces ?? 2)}
            onNewValueSubmitted={(newValue: string) => {
              const intValue = parseInt(newValue);
              const newInstructions = cloneDeep(instructions);
              if (intValue === 0 || intValue) {
                newInstructions.valueFormat = {
                  ...newInstructions.valueFormat,
                  decimalPlaces: intValue,
                };
                dispatch(
                  updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                );
              }
            }}
            label="Decimal Places"
          />
        )}
      {(visualizationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 ||
        (selectedFormat.id !== V2_NUMBER_FORMATS.PLAIN_TEXT.id &&
          visualizationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2)) && (
        <InputWithBlurSave
          containerClassName={classes.configInput}
          initialValue={String(instructions.valueFormat?.multiplyFactor ?? 1)}
          onNewValueSubmitted={(newValue: string) => {
            const floatValue = parseFloat(newValue);
            const newInstructions = cloneDeep(instructions);
            if (newValue === '' || floatValue === 0 || floatValue) {
              newInstructions.valueFormat = {
                ...newInstructions.valueFormat,
                multiplyFactor: newValue ? floatValue : undefined,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }
          }}
          label="Multiplier"
        />
      )}
      {visualizationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 && (
        <>
          <div className={classes.configInput}>
            <InputLabel text="Text Format" />
            <ButtonGroup fill>
              <Button
                bordered
                icon="bold"
                type="secondary"
                active={instructions.valueFormat?.bold}
                onClick={() => {
                  const newInstructions = cloneDeep(instructions);

                  newInstructions.valueFormat = {
                    ...newInstructions.valueFormat,
                    bold: !newInstructions.valueFormat?.bold,
                  };
                  dispatch(
                    updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                  );
                }}
              />
              <Button
                bordered
                icon="italic"
                type="secondary"
                active={instructions.valueFormat?.italic}
                onClick={() => {
                  const newInstructions = cloneDeep(instructions);

                  newInstructions.valueFormat = {
                    ...newInstructions.valueFormat,
                    italic: !newInstructions.valueFormat?.italic,
                  };
                  dispatch(
                    updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                  );
                }}
              />
            </ButtonGroup>
          </div>
        </>
      )}
      {visualizationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 && (
        <>
          <InputWithBlurSave
            containerClassName={classes.configInput}
            initialValue={String(instructions.valueFormat?.progressGoal ?? '')}
            onNewValueSubmitted={(newValue: string) => {
              const intValue = parseInt(newValue);
              const newInstructions = cloneDeep(instructions);
              if (newValue === '' || intValue === 0 || intValue) {
                newInstructions.valueFormat = {
                  ...newInstructions.valueFormat,
                  progressGoal: newValue ? intValue : undefined,
                };
                dispatch(
                  updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                );
              }
            }}
            label="Progress Goal"
          />

          <InputLabel className={classes.label} text="Progress Bar Color" />
          <ColorPickerButton
            className={cx(classes.configInput, classes.colorPicker)}
            color={
              instructions.valueFormat?.progressBarColor ||
              getCategoricalColors(globalStyleConfig)[0]
            }
            colorPalette={getCategoricalColors(globalStyleConfig)}
            onColorChange={(hex: string) => {
              const newInstructions = cloneDeep(instructions);

              newInstructions.valueFormat = {
                ...newInstructions.valueFormat,
                progressBarColor: hex,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
          />
        </>
      )}
    </div>
  );
}
