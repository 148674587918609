/** @format */

import React, { useContext } from 'react';
import { Icon, Popover, Position } from '@blueprintjs/core';
import Divider from '@material-ui/core/Divider';
import {
  createStyles,
  withStyles,
  WithStyles,
  WithTheme,
  withTheme,
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
import copy from 'copy-to-clipboard';
import 'react-resizable/css/styles.css';
import parse from 'url-parse';

import '@explo-tech/react-grid-layout/css/styles.css';
import { ErrorResponse, ExportUrlResponse } from 'actions/responseTypes';
import { ShareData } from 'actions/types';
import FlexBox, { FlexDirection, VerticalAlignment } from 'components/core/FlexBox';
import FlexItem from 'components/core/FlexItem';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';
import Button from 'shared/Button';
import DownloadPopover from 'shared/DownloadPopover';
import InputLabel from 'shared/InputLabel/InputLabel';
import { DashboardVariableMap, ExportElemConfig } from 'types/dashboardTypes';
import { getUrlSanitizedDashboardVars, handleDownloadBlocked } from 'utils/dashboardUtils';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { EXPORT_SVG_ICON } from 'constants/iconConstants';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    popoverContainer: {
      '& .bp3-popover.bp3-minimal': {
        margin: `${theme.spacing(1)}px 0px 0px !important`,
      },
      '& .bp3-popover': {
        boxShadow: 'none',
        border: `1px solid ${theme.palette.ds.grey200}`,
        borderRadius: '8px',
        overflow: 'hidden',
      },
    },
    exportButton: {
      width: '100%',
      boxShadow: 'none !important',

      '& .bp3-spinner .bp3-spinner-track': {
        stroke: theme.palette.ds.grey100,
      },

      '&.bp3-button .bp3-button-text': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    exportButtonContainer: {
      // Vertically aligns export element with dropdowns
      paddingTop: 17,
    },
    popoverText: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px `,
    },
    popoverTarget: {
      display: 'block !important',
    },
    sharePopoverContainer: {
      width: 350,
    },
    headerInfoContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(4),
      paddingBottom: 0,
    },
    headerTitle: {
      fontWeight: 500,
      marginBottom: theme.spacing(0.5),
    },
    headerSubtitle: {
      fontSize: 12,
      color: theme.palette.ds.grey800,
    },
    linkContainer: {
      padding: theme.spacing(4),
      paddingBottom: 0,
    },
    linkButtons: {
      '&.bp3-button': {
        height: 30,
        marginRight: theme.spacing(1),
      },
    },
    linkSettingsContainer: {
      padding: theme.spacing(4),
    },
    linkInput: {
      marginRight: theme.spacing(1),
    },
    passwordRequiredLabel: {
      color: `${theme.palette.ds.grey900} !important`,
    },
    switchInput: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    },
    switchInputLabel: {
      marginLeft: theme.spacing(2),
      marginBottom: 0,
    },
    topDivider: {
      marginTop: theme.spacing(2),
    },
  });

type PassedProps = {
  config: ExportElemConfig;
  exportVars: DashboardVariableMap;
  imageExportUrlLoading?: boolean;
  pdfExportUrlLoading?: boolean;
  fetchImageExportUrl: (
    onSuccess?: (data: ExportUrlResponse) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => void;
  fetchPdfExportUrl: (
    onSuccess?: (data: ExportUrlResponse) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => void;
  fetchShareData?: (password?: string, isStrictViewingMode?: boolean) => void;
  shareData?: ShareData;
  shareLinkLoading?: boolean;
  disabled?: boolean;
  isSharedView?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles> & WithTheme;

type State = {
  isExportOptionsOpen?: boolean;
  isStrictViewingMode?: boolean;
  pdfDownloadError?: boolean;
  pdfOpenFailed?: boolean;
  pdfOpenBlocked?: boolean;
  imageDownloadError?: boolean;
  imageOpenFailed?: boolean;
  imageOpenBlocked?: boolean;
  inputPassword?: string;
  passwordHasBeenSet?: boolean;
};

class DashboardExportElement extends React.Component<Props, State> {
  state: State = { isStrictViewingMode: false, inputPassword: '' };

  render() {
    const { classes, config, shareLinkLoading, disabled } = this.props;

    return (
      // We need padding on the outside because we run into issues trying to drag popover content
      <div className={classes.exportButtonContainer}>
        <Popover
          minimal
          portalClassName={cx(
            classes.popoverContainer,
            GLOBAL_STYLE_CLASSNAMES.container.outline.exportModalBorder,
            GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.exportModalCornerRadius,
          )}
          content={this.renderPopoverContent()}
          onClose={this.onExportPopoverClose}
          isOpen={this.isPopOverOpen()}
          targetClassName={classes.popoverTarget}
          portalContainer={document.getElementById(this.context.dashboardLayoutTagId) ?? undefined}
          position={Position.BOTTOM}
          disabled={disabled}>
          <Button
            type="primary"
            disabled={disabled}
            onClick={() => {
              if (!config.passwordEnabled) {
                this.getOrCreateShareId();
              }
              this.setState({ isExportOptionsOpen: true });
            }}
            loading={shareLinkLoading}
            text={config.label}
            icon={<Icon icon="export" />}
            className={cx(
              classes.exportButton,
              GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonBackgroundColor,
              GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.shareButtonHoverColor,
              GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont,
              GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.buttons.buttonCornerRadius,
              GLOBAL_STYLE_CLASSNAMES.container.shadow.buttonShadow,
            )}
          />
        </Popover>
      </div>
    );
  }

  getOrCreateShareId = () => {
    const { fetchShareData } = this.props;
    const { isStrictViewingMode, inputPassword } = this.state;
    fetchShareData?.(inputPassword, isStrictViewingMode);
  };

  getShareUrl = () => {
    const { shareData, exportVars, shareLinkLoading, isSharedView } = this.props;
    const domain = shareData?.share_link_url || process.env.REACT_APP_URL;
    let shareUrl: string;
    if (!isSharedView) {
      shareUrl = shareLinkLoading
        ? 'Generating...'
        : parse(`${domain}share/${shareData?.id}`)
            .set('query', getUrlSanitizedDashboardVars(exportVars))
            .toString();
    } else {
      // We shouldn't do a query to get the share id in share view since there's no way for it to change
      shareUrl = window.location.href;
    }
    return shareUrl;
  };

  renderPopoverContent = () => {
    const {
      config,
      shareData,
      fetchShareData,
      classes,
      shareLinkLoading,
      isSharedView,
    } = this.props;
    const { isStrictViewingMode, inputPassword, passwordHasBeenSet } = this.state;
    const showPasswordElements = !isSharedView && config.passwordEnabled;
    if (!config.passwordEnabled && (!fetchShareData || !shareData)) {
      return (
        <div className={classes.popoverText}>
          Unable to share this dashboard. It{"'"}s likely not customer specific.
        </div>
      );
    }

    const shareUrl = this.getShareUrl();

    const showPasswordInput =
      showPasswordElements &&
      (!passwordHasBeenSet || (passwordHasBeenSet && shareLinkLoading && !shareData));
    const showShareUrl = !showPasswordInput;

    return (
      <div
        className={cx(
          classes.sharePopoverContainer,
          GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor,
        )}>
        <div className={classes.headerInfoContainer}>
          <div>
            <div className={cx(classes.headerTitle, GLOBAL_STYLE_CLASSNAMES.text.h3.base)}>
              Share to web
            </div>
            <div
              className={cx(
                classes.headerSubtitle,
                GLOBAL_STYLE_CLASSNAMES.text.smallBody.secondary,
              )}>
              {config.passwordEnabled
                ? 'Only people with the password can view'
                : 'Anyone with the link can view'}
            </div>
          </div>
        </div>
        <div className={classes.linkContainer}>
          {showShareUrl && (
            <FlexBox direction={FlexDirection.ROW} verticalAlignment={VerticalAlignment.CENTER}>
              <InputGroup className={classes.linkInput} value={shareUrl} fill readOnly />
              <>
                <Button
                  type="primary"
                  className={cx(
                    classes.linkButtons,
                    GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonBackgroundColor,
                    GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont,
                  )}
                  onClick={() => {
                    copy(shareUrl);
                  }}
                  text="Copy"
                />
                {showPasswordElements && (
                  <Button
                    type="primary"
                    className={cx(
                      classes.linkButtons,
                      GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonBackgroundColor,
                      GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont,
                    )}
                    onClick={() =>
                      this.setState({
                        passwordHasBeenSet: false,
                        inputPassword: undefined,
                      })
                    }
                    icon="refresh"
                  />
                )}
              </>
            </FlexBox>
          )}
          {showPasswordInput && (
            <>
              <InputLabel className={classes.passwordRequiredLabel} text="Password (Required)" />
              <FlexBox direction={FlexDirection.ROW}>
                <InputGroup
                  className={classes.linkInput}
                  value={inputPassword}
                  placeholder="Enter a password..."
                  fill
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ inputPassword: e.target.value });
                  }}
                  type="password"
                />
                <Button
                  type="primary"
                  className={cx(
                    classes.linkButtons,
                    GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonBackgroundColor,
                    GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont,
                  )}
                  onClick={() => {
                    this.getOrCreateShareId();
                    this.setState({ passwordHasBeenSet: true });
                  }}
                  text="Set"
                  icon="lock"
                  loading={shareLinkLoading}
                />
              </FlexBox>
            </>
          )}
        </div>
        {!isSharedView && (
          <div className={classes.linkSettingsContainer}>
            <SwitchInput
              className={classes.switchInput}
              labelClassName={classes.switchInputLabel}
              switchOn={!isStrictViewingMode}
              onChange={() => {
                // fire off the call before setting state as setState is
                // async so we want to immediately use it's eventual value
                this.getOrCreateShareId();
                this.setState({ isStrictViewingMode: !isStrictViewingMode });
              }}
              label="Allow viewers to interact with the filters"
            />
          </div>
        )}
        <Divider className={classes.topDivider} />
        {this.renderDownloadImage()}
        <Divider />
        {this.renderDownloadPDF()}
      </div>
    );
  };

  renderDownloadImage = () => {
    const { shareData, fetchImageExportUrl, imageExportUrlLoading } = this.props;
    const { imageDownloadError, imageOpenFailed, imageOpenBlocked } = this.state;

    return (
      <DownloadContentRow
        isRecommended
        text="Download as an image"
        exportUrl={shareData?.imageExportUrl}
        isDownloadError={imageDownloadError}
        isDownloadOpenFailed={imageOpenFailed}
        isDownloadLoading={imageExportUrlLoading}
        isDownloadBlocked={imageOpenBlocked}
        onDownloadClicked={() => {
          fetchImageExportUrl(
            (data: ExportUrlResponse) => {
              const newWindow = window.open(data.screenshot_url);
              this.setState({
                imageDownloadError: false,
              });
              handleDownloadBlocked(
                () =>
                  this.setState({
                    imageOpenBlocked: true,
                  }),
                newWindow,
              );

              if (newWindow === null) {
                this.setState({
                  imageOpenFailed: true,
                });
              }
            },
            () =>
              this.setState({
                imageDownloadError: true,
                imageOpenFailed: false,
              }),
          );
        }}
        onPopoverLinkClicked={() =>
          this.setState({
            imageDownloadError: false,
            imageOpenFailed: false,
          })
        }
      />
    );
  };

  renderDownloadPDF = () => {
    const { shareData, fetchPdfExportUrl, pdfExportUrlLoading } = this.props;
    const { pdfDownloadError, pdfOpenFailed, pdfOpenBlocked } = this.state;

    return (
      <DownloadContentRow
        text="Download as a pdf"
        exportUrl={shareData?.imageExportUrl}
        isDownloadError={pdfDownloadError}
        isDownloadOpenFailed={pdfOpenFailed}
        isDownloadLoading={pdfExportUrlLoading}
        isDownloadBlocked={pdfOpenBlocked}
        onDownloadClicked={() => {
          fetchPdfExportUrl(
            (data: ExportUrlResponse) => {
              const newWindow = window.open(data.screenshot_url);
              this.setState({
                pdfDownloadError: false,
              });

              if (newWindow === null) {
                this.setState({
                  pdfOpenFailed: true,
                });
              }
              handleDownloadBlocked(
                () =>
                  this.setState({
                    pdfOpenBlocked: true,
                  }),
                newWindow,
              );
            },
            () =>
              this.setState({
                imageDownloadError: true,
                imageOpenFailed: false,
              }),
          );
        }}
        onPopoverLinkClicked={() =>
          this.setState({
            pdfDownloadError: false,
            pdfOpenFailed: false,
          })
        }
      />
    );
  };

  isPopOverOpen = () => {
    const { shareLinkLoading } = this.props;
    const { isExportOptionsOpen, passwordHasBeenSet } = this.state;

    if (shareLinkLoading && !passwordHasBeenSet) {
      return false;
    } else {
      return isExportOptionsOpen;
    }
  };

  onExportPopoverClose = (event?: React.SyntheticEvent<HTMLElement, Event>) => {
    if (event && event.type === 'mousedown') {
      this.setState(() => ({ isExportOptionsOpen: false }));
    }
  };
}

const downloadContentRowStyles = makeStyles((theme: Theme) => ({
  dividedContent: {
    padding: '5px 10px',
  },
  downloadButton: {
    '& .bp3-spinner .bp3-spinner-track': {
      stroke: theme.palette.ds.grey100,
    },
    borderRadius: '8px !important',
  },
  weightedDownloadText: {
    fontSize: 12,
    fontWeight: 500,
  },
  mutedDownloadText: {
    fontSize: 12,
    color: theme.palette.ds.grey800,
  },
}));

DashboardExportElement.contextType = DashboardLayoutContext;

export type DownloadContentRowProps = {
  text: string;
  isRecommended?: boolean;
  exportUrl?: string;
  isDownloadError?: boolean;
  isDownloadOpenFailed?: boolean;
  isDownloadLoading?: boolean;
  isDownloadBlocked?: boolean;
  onDownloadClicked: () => void;
  onPopoverLinkClicked?: () => void;
};

export const DownloadContentRow: React.FC<DownloadContentRowProps> = ({
  text,
  onDownloadClicked,
  isRecommended,
  exportUrl,
  isDownloadLoading,
  isDownloadError,
  isDownloadOpenFailed,
  isDownloadBlocked,
  onPopoverLinkClicked,
}) => {
  const classes = downloadContentRowStyles();
  const { globalStyleConfig } = useContext(GlobalStylesContext);

  return (
    <FlexBox className={classes.dividedContent} verticalAlignment={VerticalAlignment.CENTER}>
      <FlexItem>
        <span
          className={cx(classes.weightedDownloadText, GLOBAL_STYLE_CLASSNAMES.text.body.primary)}>
          {text}
        </span>
        {isRecommended && (
          <span
            className={cx(classes.mutedDownloadText, GLOBAL_STYLE_CLASSNAMES.text.body.secondary)}>
            {' '}
            (recommended)
          </span>
        )}
      </FlexItem>
      <DownloadPopover
        link={exportUrl}
        isError={isDownloadError}
        isOpen={isDownloadError || isDownloadOpenFailed || isDownloadBlocked}
        showLink={isDownloadBlocked}
        onLinkClick={onPopoverLinkClicked}>
        <Button
          minimal
          className={classes.downloadButton}
          loading={isDownloadLoading}
          onClick={onDownloadClicked}
          icon={
            <Icon
              icon={EXPORT_SVG_ICON(globalStyleConfig.base.actionColor.default)}
              iconSize={16}
            />
          }
        />
      </DownloadPopover>
    </FlexBox>
  );
};

export default withStyles(styles)(withTheme(DashboardExportElement));
