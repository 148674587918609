/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';

import ColorPickerButton from 'shared/ColorPicker';
import Button from 'shared/Button';

import { ColorPalette, ColorPaletteV2 } from 'constants/types';
import { PALETTE_TO_COLORS } from 'constants/colorConstants';
import { constructCustomPaleete } from 'pages/dashboardPage/charts/utils';
import { getCategoricalColors, getDivergingColors, getGradientColors } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    columnGap: '12px',
    rowGap: '8px',
  },
  colorCode: {
    height: '100%',
    width: '100%',
  },
  colorPickerBtn: {
    width: 36,
    height: 36,
  },
  addColorBtn: {
    width: 36,
    height: 36,
  },
}));

export type Props = {
  className?: string;
  palette: ColorPalette | ColorPaletteV2;
  customColors?: string;
  globalStyleConfig: GlobalStyleConfig;
  saveCustomColorConfig: (colorPalette: string[]) => void;
};

export default function ColorPaletteDisplay({
  className,
  palette,
  customColors,
  globalStyleConfig,
  saveCustomColorConfig,
}: Props) {
  const classes = useStyles();

  let colorList =
    palette === ColorPalette.CUSTOM
      ? constructCustomPaleete(customColors)
      : PALETTE_TO_COLORS[palette];

  switch (palette) {
    case ColorPaletteV2.CATEGORICAL:
      colorList = getCategoricalColors(globalStyleConfig);
      break;
    case ColorPaletteV2.DIVERGING:
      colorList = getDivergingColors(globalStyleConfig);
      break;
    case ColorPaletteV2.GRADIENT:
      colorList = getGradientColors(globalStyleConfig);
      break;
  }

  if (!colorList)
    return (
      <div className={className}>
        <div className={classes.root}>
          <div
            key={_.uniqueId('color-palette-display-')}
            className={classes.colorCode}
            style={{ backgroundColor: '#CECECE' }}
          />
        </div>
      </div>
    );

  return (
    <div className={cx(className, classes.root)}>
      {colorList.map((colorHex, index) => (
        <ColorPickerButton
          key={`color-palette-display-${colorHex}`}
          btnClassName={classes.colorPickerBtn}
          color={colorHex}
          colorPalette={getCategoricalColors(globalStyleConfig)}
          onColorChange={(newColor) => {
            const palette = cloneDeep(colorList) || [];
            palette[index] = newColor;
            saveCustomColorConfig(palette);
          }}
          onCancel={() => {
            const palette = cloneDeep(colorList) || [];
            palette.splice(index, 1);
            saveCustomColorConfig(palette);
          }}
        />
      ))}
      <Button
        icon="plus"
        className={classes.addColorBtn}
        onClick={() => {
          const palette = cloneDeep(colorList) || [];
          palette.push('#0069ED');
          saveCustomColorConfig(palette);
        }}
      />
    </div>
  );
}
