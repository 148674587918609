/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import '@explo-tech/react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import DashboardTextElement from './dashboardTextElement';
import DashboardDropdownElement from './dashboardDropdownElement';
import DashboardMultiSelectElement from './dashboardMultiSelectElement';
import DashboardDatepickerElement from './dashboardDatepickerElement';
import DashboardExportElement from './dashboardExportElement';
import DashboardSwitchElement from './dashboardSwitchElement';
import DashboardDateRangePickerElement from './DashboardDateRangePickerElement';
import DashboardImageElement from './DashboardImageElement';
import DashboardDateGroupSwitchElement from './DashboardDateGroupSwitchElement';
import DashboardTimePeriodDropdownElement from './DashboardTimePeriodDropdownElement';

import {
  DashboardElement,
  TextDashboardElemConfig,
  DropdownDashboardElemConfig,
  DatepickerElemConfig,
  ExportElemConfig,
  DashboardVariable,
  ContainerElemConfig,
  DASHBOARD_ELEMENT_TYPES,
  DashboardVariableMap,
  ImageElemConfig,
  DateGroupSwitchConfig,
  VIEW_MODE,
  TimePeriodDropdownDashboardElemConfig,
} from 'types/dashboardTypes';
import DashboardContainerElement from './DashboardContainerElement';
import { PassedProps as ElementGridLayoutProps } from 'components/DashboardLayout/ElementGridLayout';

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
  },
  containerRoot: {
    height: '100%',
  },
});

type PassedProps = {
  dashboardElement: DashboardElement;
  dashboardLayoutProps: ElementGridLayoutProps;
  variables: DashboardVariableMap;
  value?: DashboardVariable;
  onNewValueSelect: (newvalue: DashboardVariable) => void;
  exportVars: { [variableName: string]: DashboardVariable };
  disableInputs?: boolean;
  isResizing: boolean;
  isMobileView?: boolean;
  viewMode: VIEW_MODE;
  portalId?: string;
};

type Props = PassedProps & WithStyles<typeof styles>;

class DashboardElementView extends React.PureComponent<Props> {
  render() {
    const { classes, dashboardElement } = this.props;

    return (
      <div
        className={cx(classes.root, {
          [classes.containerRoot]:
            dashboardElement.element_type === DASHBOARD_ELEMENT_TYPES.CONTAINER,
        })}>
        {this.renderElement()}
      </div>
    );
  }

  renderElement = () => {
    const {
      dashboardLayoutProps,
      dashboardElement,
      value,
      onNewValueSelect,
      exportVars,
      disableInputs,
      variables,
      isResizing,
      isMobileView,
      viewMode,
      portalId,
    } = this.props;
    const {
      dashboardDatasets,
      fetchShareData,
      shareData,
      shareLinkLoading,
      imageExportUrlLoading,
      pdfExportUrlLoading,
      fetchImageExportUrl,
      fetchPdfExportUrl,
    } = dashboardLayoutProps;

    switch (dashboardElement.element_type) {
      case DASHBOARD_ELEMENT_TYPES.TEXT:
        return (
          <DashboardTextElement
            config={dashboardElement.config as TextDashboardElemConfig}
            variables={variables}
            dashboardDatasets={dashboardDatasets}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
        return (
          <DashboardDropdownElement
            disabled={disableInputs}
            config={dashboardElement.config as DropdownDashboardElemConfig}
            value={value}
            dashboardDatasets={dashboardDatasets}
            onNewValueSelect={onNewValueSelect}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
        return (
          <DashboardTimePeriodDropdownElement
            disabled={disableInputs}
            config={dashboardElement.config as TimePeriodDropdownDashboardElemConfig}
            value={value as number}
            onNewValueSelect={onNewValueSelect}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
        return (
          <DashboardMultiSelectElement
            disabled={disableInputs}
            config={dashboardElement.config as DropdownDashboardElemConfig}
            value={value}
            dashboardDatasets={dashboardDatasets}
            onNewValueSelect={onNewValueSelect}
          />
        );

      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
        return (
          <DashboardDatepickerElement
            disabled={disableInputs}
            config={dashboardElement.config as DatepickerElemConfig}
            value={value}
            onNewValueSelect={onNewValueSelect}
            portalId={portalId}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        return (
          <DashboardDateRangePickerElement
            disabled={disableInputs}
            config={dashboardElement.config as DatepickerElemConfig}
            value={value}
            onNewValueSelect={onNewValueSelect}
            portalId={portalId}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.EXPORT:
        return (
          <DashboardExportElement
            config={dashboardElement.config as ExportElemConfig}
            fetchShareData={fetchShareData}
            shareData={shareData}
            shareLinkLoading={shareLinkLoading}
            imageExportUrlLoading={imageExportUrlLoading}
            pdfExportUrlLoading={pdfExportUrlLoading}
            fetchImageExportUrl={fetchImageExportUrl}
            fetchPdfExportUrl={fetchPdfExportUrl}
            exportVars={exportVars}
            isSharedView={viewMode === VIEW_MODE.SHARE}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.IMAGE:
        return (
          <DashboardImageElement
            config={dashboardElement.config as ImageElemConfig}
            variables={variables}
          />
        );

      case DASHBOARD_ELEMENT_TYPES.SWITCH:
        return (
          <DashboardSwitchElement
            disabled={disableInputs}
            config={dashboardElement.config as DropdownDashboardElemConfig}
            value={value}
            dashboardDatasets={dashboardDatasets}
            onNewValueSelect={onNewValueSelect}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
        return (
          <DashboardDateGroupSwitchElement
            disabled={disableInputs}
            config={dashboardElement.config as DateGroupSwitchConfig}
            value={value}
            onNewValueSelect={onNewValueSelect}
          />
        );

      case DASHBOARD_ELEMENT_TYPES.CONTAINER:
        return (
          <DashboardContainerElement
            id={dashboardElement.id}
            config={dashboardElement.config as ContainerElemConfig}
            dashboardLayoutProps={dashboardLayoutProps}
            isResizing={isResizing}
            isMobileView={isMobileView}
            viewMode={viewMode}
          />
        );
    }
  };
}

export default withStyles(styles)(DashboardElementView);
