/** @format */

import React, { useState } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Icon } from '@blueprintjs/core';

import Button from 'shared/Button';

const styles = (theme: Theme) => ({
  cancelBtn: {
    marginRight: theme.spacing(2),
    width: `calc(50% - ${theme.spacing(1)}px)`, // to offset the margin Right
  },
  finalDeleteBtn: {
    width: `calc(50% - ${theme.spacing(1)}px)`,
  },
  deleteBtn: {
    width: 72,
  },
});

const useStyles = makeStyles(styles);

type PassedProps = {
  onDelete: () => void;
  className?: string;
  loading?: boolean;
};

export default function DeleteConfirmButton({ onDelete, className, loading }: PassedProps) {
  const classes = useStyles();
  const [isDeleteToggled, setIsDeleteToggled] = useState<boolean>(false);

  return isDeleteToggled && !loading ? (
    <div className={className}>
      <Button
        className={classes.cancelBtn}
        type="secondary"
        icon={<Icon icon="cross" />}
        onClick={() => {
          setIsDeleteToggled(false);
        }}
      />
      <Button
        className={classes.finalDeleteBtn}
        type="destructive"
        icon={<Icon icon="trash" />}
        onClick={onDelete}
      />
    </div>
  ) : (
    <Button
      className={cx(classes.deleteBtn, className)}
      type="destructive"
      loading={loading}
      disabled={loading}
      onClick={() => {
        setIsDeleteToggled(true);
      }}
      text="Delete"
    />
  );
}
