/** @format */

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';

import { Icon } from '@blueprintjs/core';

import Button from 'shared/Button';
import { FilterOperatorType, FilterValueType } from 'constants/types';
import { TableColumn } from 'actions/types';
import ColumnDropdown from './ColumnDropdown';
import OperatorDropdown from './OperatorDropdown';
import ValueInput from './ValueInput';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    '& .bp3-button': {
      borderRadius: 4,
      boxShadow: 'none !important',
      backgroundColor: theme.palette.ds.white,
      color: theme.palette.ds.black,
      height: 20,
      minHeight: 18,
      minWidth: 18,
    },
    '& .bp3-button .bp3-icon, .bp3-button .bp3-icon-standard, .bp3-button .bp3-icon-large': {
      color: `${theme.palette.ds.grey800} !important`,
    },
    '& .bp3-button-text': {
      color: `${theme.palette.ds.grey800}`,
    },
  },
  columnOrOperatorOption: {
    display: 'flex',
    marginRight: theme.spacing(2),
    '& .bp3-button': {
      border: `1px solid ${theme.palette.ds.white}`,
      fontSize: 12,
    },
    '& .bp3-input-group .bp3-input': {
      border: `1px solid ${theme.palette.ds.white}`,
    },
  },
  deleteIconButton: {
    marginRight: theme.spacing(1),
  },
  operatorAndValueContainer: {
    width: 300,
    display: 'flex',
    alignItems: 'center',
  },
}));

export type Props = {
  baseSchema: TableColumn[];
  selectedColumn?: TableColumn;
  selectedOperator?: FilterOperatorType;
  filterValue?: FilterValueType;
  onColumnSelect: (column: TableColumn) => void;
  onOperatorSelect: (operator: FilterOperatorType) => void;
  onFilterValueUpdate: (value: FilterValueType) => void;
  onDelete: () => void;
  adHocFilterSettings?: boolean;
};

export default function FilterRow({ onDelete, ...props }: Props) {
  const classes = useStyles();
  const theme: Theme = useTheme();

  return (
    <div className={classes.container}>
      <Button
        minimal
        className={classes.deleteIconButton}
        icon={<Icon icon="cross" iconSize={12} color={theme.palette.ds.grey800} />}
        onClick={onDelete}
      />
      <ColumnDropdown className={classes.columnOrOperatorOption} {...props} />
      <span className={classes.operatorAndValueContainer}>
        <OperatorDropdown className={classes.columnOrOperatorOption} {...props} />
        <ValueInput {...props} />
      </span>
    </div>
  );
}
