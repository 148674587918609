/** @format */

import React from 'react';
import _ from 'underscore';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { cloneDeep } from 'lodash';
import { Checkbox, Intent } from '@blueprintjs/core';
import { AppToaster } from 'toaster';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import DropdownSelect from 'shared/DropdownSelect';

import { DashboardElementConfig, DateGroupSwitchConfig } from 'types/dashboardTypes';
import { TREND_GROUPING_OPTIONS, TrendGroupingOptions } from 'constants/dataConstants';
import { getDateGroupSwitchOptions } from 'utils/dashboardUtils';

const styles = (theme: Theme) => ({
  configInput: {
    marginTop: theme.spacing(3),
  },
  configHeader: {
    fontWeight: 500,
    fontSize: '14',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(4),
  },
  groupingSection: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(3),
  },
  groupingCheckbox: {
    margin: 0,
    marginTop: 24,
  },
  groupingInput: {
    width: '100%',
  },
});

type PassedProps = {
  config: DateGroupSwitchConfig;
  updateConfig: (newConfig: DashboardElementConfig) => void;
};

type Props = RouteComponentProps & WithStyles<typeof styles> & PassedProps;

type State = {};

class DataGroupSwitchElementConfigPanel extends React.Component<Props, State> {
  state: State = {};

  render() {
    const { classes, config, updateConfig } = this.props;

    const availableGroupingOptions = getDateGroupSwitchOptions(config);
    const availableOptionsById = _.indexBy(availableGroupingOptions, 'id');

    const selectedDefault = config.defaultGroupingOption
      ? availableOptionsById[config.defaultGroupingOption]
      : availableOptionsById[TrendGroupingOptions.MONTHLY];

    return (
      <div>
        <InputWithBlurSave
          containerClassName={classes.configInput}
          initialValue={config.label}
          onNewValueSubmitted={(newValue: string) => {
            const newConfig = cloneDeep(config);
            newConfig.label = newValue;
            updateConfig(newConfig);
          }}
          label="Label"
        />
        <DropdownSelect
          containerClassName={classes.configInput}
          filterable={false}
          label="Default Value"
          selectedItem={selectedDefault}
          onChange={(newvalue) => {
            const newConfig = cloneDeep(config);
            newConfig.defaultGroupingOption = newvalue.id as TrendGroupingOptions;
            updateConfig(newConfig);
          }}
          noSelectionText="Select Default Option"
          options={availableGroupingOptions}
          minimal
          fillWidth
          showIcon
        />

        <div className={classes.configHeader}>Group Configurations</div>
        {Object.values(TREND_GROUPING_OPTIONS).map((groupingOption) => {
          const configOptionsForType = (config.groupingOptionByType &&
            config.groupingOptionByType[groupingOption.id]) || { name: '', exclude: false };
          return (
            <div
              className={classes.groupingSection}
              key={`date-group-switch-config-${groupingOption.id}`}>
              <Checkbox
                className={classes.groupingCheckbox}
                onClick={() => {
                  const newConfig = cloneDeep(config);
                  newConfig.groupingOptionByType = {
                    ...newConfig.groupingOptionByType,
                    [groupingOption.id]: {
                      ...configOptionsForType,
                      exclude: !configOptionsForType.exclude,
                    },
                  };

                  if (groupingOption.id === selectedDefault.id) {
                    return AppToaster.show({
                      message: `The default option must be available. `,
                      icon: 'error',
                      timeout: 3000,
                      intent: Intent.WARNING,
                    });
                  }

                  if (getDateGroupSwitchOptions(newConfig).length < 2) {
                    return AppToaster.show({
                      message: `At least 2 date grouping options must be available`,
                      icon: 'error',
                      timeout: 3000,
                      intent: Intent.WARNING,
                    });
                  }

                  updateConfig(newConfig);
                }}
                checked={!configOptionsForType.exclude}
              />
              <InputWithBlurSave
                containerClassName={classes.groupingInput}
                initialValue={configOptionsForType.name || groupingOption.name}
                onNewValueSubmitted={(newValue: string) => {
                  const newConfig = cloneDeep(config);
                  newConfig.groupingOptionByType = {
                    ...newConfig.groupingOptionByType,
                    [groupingOption.id]: {
                      ...configOptionsForType,
                      name: newValue,
                    },
                  };
                  updateConfig(newConfig);
                }}
                label={groupingOption.name}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(DataGroupSwitchElementConfigPanel));
