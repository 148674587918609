/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';

import DropdownSelect from 'shared/DropdownSelect';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions, REGION_TYPES } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2TwoDimensionChartInstructions;
};

export default function MapDataConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mapRegionToString = (region: REGION_TYPES) => {
    if (region === REGION_TYPES.WORLD) return 'World';
    else return 'United States';
  };

  return (
    <div className={classes.root}>
      <DropdownSelect
        containerClassName={classes.configInput}
        fillWidth
        minimal
        selectedItem={{
          name: mapRegionToString(
            instructions.chartSpecificFormat?.mapChart?.region || REGION_TYPES.WORLD,
          ),
          id: instructions.chartSpecificFormat?.mapChart?.region || REGION_TYPES.WORLD,
        }}
        onChange={(newValue) => {
          const newInstructions = cloneDeep(instructions);
          if (newInstructions.chartSpecificFormat && newInstructions.chartSpecificFormat.mapChart)
            newInstructions.chartSpecificFormat.mapChart.region = newValue.id as REGION_TYPES;
          else
            newInstructions.chartSpecificFormat = {
              mapChart: { region: newValue.id as REGION_TYPES },
            };

          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        filterable={false}
        options={[
          { name: mapRegionToString(REGION_TYPES.WORLD), id: REGION_TYPES.WORLD },
          { name: mapRegionToString(REGION_TYPES.UNITED_STATES), id: REGION_TYPES.UNITED_STATES },
        ]}
        noSelectionText=""
      />
    </div>
  );
}
