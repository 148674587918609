/** @format */
import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import { FilterOperationInstructions, Schema, VisualizeTableInstructions } from 'constants/types';
import FlexBox, { FlexDirection } from 'components/core/FlexBox';
import FlexItem from 'components/core/FlexItem';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { AdHocOperationInstructions, TableRow, VisualizeOperation } from 'actions/types';
import DataTableHeader from '../../Header/DataTableHeader';
import StepFooter from '../StepFooter';
import DataTable from '../../DataTable/DataTable';

const useStyles = makeStyles((theme: Theme) => ({
  fillHeight: {
    height: '100%',
  },
  tableContainer: {
    borderTop: `1px solid ${theme.palette.ds.grey200}`,
    minHeight: 0,
  },
}));

type Props = {
  adHocOperationInstructions: AdHocOperationInstructions;
  error?: boolean;
  loading?: boolean;
  onAdHocFilterInfoUpdate: (adHocFilterInfo: FilterOperationInstructions) => void;
  onAdHocOperationInstructionsChange: (
    adHocOperationInstructions: AdHocOperationInstructions,
  ) => void;
  onBack: () => void;
  onNext: () => void;
  reportName?: string;
  rows: TableRow[];
  schema: Schema;
  secondaryData: TableRow[];
  sourceDataRowCount?: number;
  updateVisualizeOperation: (visualizeInstructions: VisualizeTableInstructions) => void;
  visualizeOperation: VisualizeOperation;
};

export default function PreviewStep({
  adHocOperationInstructions,
  error,
  loading,
  onAdHocFilterInfoUpdate,
  onAdHocOperationInstructionsChange,
  onBack,
  onNext,
  reportName,
  rows,
  schema,
  secondaryData,
  sourceDataRowCount,
  updateVisualizeOperation,
  visualizeOperation,
}: Props) {
  const classes = useStyles();

  return (
    <FlexBox
      className={cx(classes.fillHeight, GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor)}
      direction={FlexDirection.COLUMN}>
      <FlexItem className={classes.tableContainer}>
        <DataTableHeader
          isSchemaCustomizationEnabled={false}
          adHocOperationInstructions={adHocOperationInstructions}
          error={!!error}
          infoTooltipText="This is a preview of how your data will appear"
          loading={loading}
          onAdHocFilterInfoUpdate={onAdHocFilterInfoUpdate}
          panelName={reportName ?? 'Preview'}
          schema={schema}
        />
        <DataTable
          adHocOperationInstructions={adHocOperationInstructions}
          canDownloadDataPanel={false}
          onAdHocOperationInstructionsChange={onAdHocOperationInstructionsChange}
          isViewOnly={false}
          previewData={rows}
          schema={schema}
          secondaryData={secondaryData}
          sourceDataRowCount={sourceDataRowCount}
          updateVisualizeOperation={updateVisualizeOperation}
          visualizeOperation={visualizeOperation}
        />
      </FlexItem>
      <StepFooter onBack={onBack} onNext={onNext} text="Export" />
    </FlexBox>
  );
}
