/** @format */

import { Action } from 'reducers/rootReducer';
import { ACTION, EndUserGroup } from 'actions/types';

export interface EndUserGroupInfoReducerState {
  data?: EndUserGroup;
}

const EndUserGroupInfoReducerInitialState: EndUserGroupInfoReducerState = {};

export default (
  state: EndUserGroupInfoReducerState = EndUserGroupInfoReducerInitialState,
  action: Action,
): EndUserGroupInfoReducerState => {
  const newState = Object.assign({}, state);
  const { payload } = action;

  switch (action.type) {
    case `${ACTION.FETCH_END_USER_GROUP_INFO}_SUCCESS`:
      newState.data = payload.end_user_group;
      return newState;
    default:
      return state;
  }
};
