/** @format */

import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { EmbedFetchShareData, ErrorResponse } from 'actions/responseTypes';
import { embedFetchShareData, embedFetchShareDataWithPassword } from 'actions/shareActions';
import EmbeddedDashboard, { LoadingState } from 'components/EmbeddedDashboard';
import SharePasswordRequiredPage from './sharePasswordRequiredPage';
import { makeStyles } from '@material-ui/styles';

type MatchParams = {
  shareId: string;
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export default function SharedDashboardPage() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [faviconUrl, setFaviconUrl] = useState<string>('');
  const [siteTitle, setSiteTitle] = useState<string>('');
  const [isStrictViewingMode, setIsStrictViewingMode] = useState<boolean>(false);
  const [userGroupToken, setUserGroupToken] = useState<string>('');
  const [dashboardEmbedId, setDashboardEmbedId] = useState<string>('');
  const [versionNumber, setVersionNumber] = useState<number>();
  const [isPasswordProtected, setIsPasswordProtected] = useState<boolean>(false);
  const [passwordWasVerified, setPasswordWasVerified] = useState<boolean>(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    params: { shareId },
    url,
  } = useRouteMatch<MatchParams>();

  useEffect(() => {
    dispatch(
      embedFetchShareData(
        { id: shareId, userGroupToken },
        (shareData: EmbedFetchShareData) => {
          setUserGroupToken(shareData.user_group_token);
          setDashboardEmbedId(shareData.dashboard_embed_id);
          setVersionNumber(shareData.version_number);
          setIsLoading(false);
          setIsStrictViewingMode(shareData.is_strict_viewing_mode);
          setFaviconUrl(shareData.favicon_url ?? '');
          setSiteTitle(shareData.site_title ?? '');
        },
        (error: ErrorResponse) => {
          // API returns 405 when we send a GET for a password-protected link
          if (error.status === 405) {
            setIsPasswordProtected(true);
            setIsLoading(false);
          }
        },
      ),
    );
  }, [dispatch, shareId, userGroupToken]);

  useEffect(() => {
    const faviconElement = document.getElementById('site-favicon') as HTMLAnchorElement;
    const siteTitleElement = document.getElementById('site-title') as HTMLAnchorElement;

    if (faviconElement && faviconUrl !== '')
      faviconElement.href = `https://www.google.com/s2/favicons?domain=${faviconUrl}`;
    if (siteTitleElement && siteTitle !== '') siteTitleElement.innerHTML = `${siteTitle}`;
  }, [faviconUrl, siteTitle]);

  if (isLoading) {
    return <LoadingState type="shared" />;
  }

  return (
    <div className={classes.root}>
      {isPasswordProtected && !passwordWasVerified && (
        <SharePasswordRequiredPage
          fetchShareData={(password: string, onFailure: () => void) => {
            dispatch(
              embedFetchShareDataWithPassword(
                {
                  id: shareId,
                  userGroupToken,
                  postData: { password: password },
                },
                (shareData: EmbedFetchShareData) => {
                  setUserGroupToken(shareData.user_group_token);
                  setDashboardEmbedId(shareData.dashboard_embed_id);
                  setVersionNumber(shareData.version_number);
                  setIsStrictViewingMode(shareData.is_strict_viewing_mode);
                  setIsLoading(false);
                  setPasswordWasVerified(true);
                },
                (e: ErrorResponse) => {
                  if (e.status === 403) onFailure();
                },
              ),
            );
          }}
        />
      )}
      {(!isPasswordProtected || passwordWasVerified) && (
        <EmbeddedDashboard
          type="shared"
          // Keeping the old way to set strict mode through the URL for backwards compatability
          isStrict={isStrictViewingMode || url.toLocaleUpperCase().endsWith('STRICT')}
          userGroupToken={userGroupToken}
          dashboardEmbedId={dashboardEmbedId}
          versionNumber={versionNumber}
        />
      )}
    </div>
  );
}
