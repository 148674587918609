/** @format */
import { defineAPIAction, defineEmbedAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';

import {
  EmbedFetchDashboardData,
  EmbedFetchShareData,
  DownloadDataPanelTemplateCsvData,
  DownloadDataPanelTemplatePdfData,
  FetchDashboardDatasetPreviewData,
  FetchDataPanelTemplateData,
  ExportUrlResponse,
} from 'actions/responseTypes';
import { FetchDashboardVersionsResponse, SetDPTLoadingArgs } from './dashboardV2Actions';
import { UpdateAdHocOperationInstructionsArgs } from './dataPanelTemplateAction';

export const embedFetchDashboard = defineEmbedAction<EmbedFetchDashboardData>(
  ACTION.EMBED_FETCH_DASHBOARD,
  'embed',
  'dashboard',
  'POST',
);

export const embedFetchDataPanelTemplate = defineEmbedAction<FetchDataPanelTemplateData>(
  ACTION.EMBED_FETCH_DATA_PANEL_TEMPLATE,
  'embed',
  'get_data_panel_template_v2',
  'POST',
);

export const embedFetchDataPanelRowCount = defineEmbedAction(
  ACTION.EMBED_FETCH_DATA_PANEL_ROW_COUNT,
  'embed',
  'get_data_panel_template_v2',
  'POST',
);

export const embedDownloadDataPanelTemplateCsv = defineEmbedAction<
  DownloadDataPanelTemplateCsvData
>(ACTION.EMBED_DOWNLOAD_DATA_PANEL_TEMPLATE_CSV, 'embed', 'get_data_panel_template_v2', 'POST');

export const embedFetchSecondaryData = defineEmbedAction(
  ACTION.EMBED_FETCH_SECONDARY_DATA,
  'embed',
  'get_data_panel_template_v2',
  'POST',
);

export const embedFetchDashboardDatasetPreview = defineEmbedAction<
  FetchDashboardDatasetPreviewData
>(ACTION.EMBED_FETCH_DASHBOARD_DATASET_PREVIEW, 'embed', 'get_preview', 'POST');

export const embedFetchShareData = defineEmbedAction<EmbedFetchShareData>(
  ACTION.EMBED_FETCH_SHARE_DATA,
  'share',
  'get_share_data',
  'GET',
);

export const embedFetchShareDataWithPassword = defineEmbedAction<EmbedFetchShareData>(
  ACTION.EMBED_FETCH_SHARE_DATA,
  'share',
  'get_share_data',
  'POST',
);

export const embedFetchShareId = defineEmbedAction(
  ACTION.FETCH_SHARE_ID,
  'embed',
  'get_share_id',
  'POST',
);

export const embedFetchPdfExportUrl = defineEmbedAction<ExportUrlResponse>(
  ACTION.FETCH_PDF_EXPORT_URL,
  'export',
  'dashboard/pdf',
  'POST',
);

export const embedFetchImageExportUrl = defineEmbedAction<ExportUrlResponse>(
  ACTION.FETCH_IMAGE_EXPORT_URL,
  'export',
  'dashboard/image',
  'POST',
);

export const { actionFn: saveFavicon } = defineAPIAction(
  ACTION.SAVE_FAVICON,
  'teams',
  'save_favicon',
  'POST',
);

export const embedFetchDashboardVersions = defineEmbedAction<FetchDashboardVersionsResponse>(
  ACTION.FETCH_DASHBOARD_VERSIONS,
  'embed',
  'list_versions',
  'POST',
);

export const embedDownloadDataPanelTemplatePdf = defineEmbedAction<
  DownloadDataPanelTemplatePdfData
>(ACTION.EMBED_DOWNLOAD_DATA_PANEL_TEMPLATE_PDF, 'export', 'data_panel_template/pdf', 'POST');

export const embedSetDptLoading = (args: SetDPTLoadingArgs) => ({
  type: ACTION.EMBED_SET_DPT_LOADING,
  payload: {
    ...args,
  },
});

export const embedUpdateAdHocOperationInstructions = (
  args: UpdateAdHocOperationInstructionsArgs,
) => ({
  type: ACTION.EMBED_UPDATE_AD_HOC_OPERATION_INSTRUCTIONS,
  payload: {
    ...args,
  },
});
