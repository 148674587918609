/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import DropdownSelect from 'shared/DropdownSelect';
import ColorPicker from 'pages/GlobalCustomStylesPage/CustomStylesColorPicker';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';

import { PlotLineType, GoalLineFormat } from 'constants/types';
import { PLOT_LINE_CONFIG } from 'constants/dashboardConstants';
import RangeInput from 'shared/RangeInput';
import FlexBox, { HorizontalAlignment, VerticalAlignment } from 'components/core/FlexBox';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.ds.grey400,
    marginBottom: theme.spacing(2),
  },
  lineTypeDropdown: {
    width: 186,
  },
  lineWidthInput: {
    width: 55,
  },
}));

export type Props = {
  updateConfig: (newConfig: GoalLineFormat) => void;
  config: GoalLineFormat;
};

export default function GoalLineConfig({ updateConfig, config }: Props) {
  const classes = useStyles();

  const selectedLineType = config.lineType || PlotLineType.SOLID;

  return (
    <div className={classes.root}>
      {config.isGoalBand ? (
        <RangeInput
          label="Goal Range"
          className={classes.configInput}
          startVal={String(config.goalValue || '100')}
          endVal={config.goalValueMax}
          onNewRange={(newStart?: string, newEnd?: string) => {
            const newConfig = {
              ...config,
            };

            // setting the values manually here rather than in the splay above since newStart and
            // new end can be undefined, which wouldn't get picked up if we do it in the splay.
            newConfig.goalValue = newStart;
            newConfig.goalValueMax = newEnd;

            updateConfig(newConfig);
          }}
        />
      ) : (
        <InputWithBlurSave
          containerClassName={classes.configInput}
          label="Goal Value"
          initialValue={String(config.goalValue ?? 100)}
          onNewValueSubmitted={(newValue: string) => {
            updateConfig({
              ...config,
              goalValue: newValue,
            });
          }}
        />
      )}
      <FlexBox
        className={classes.configInput}
        verticalAlignment={VerticalAlignment.CENTER}
        horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}>
        <SwitchInput
          switchOn={config.isGoalBand}
          onChange={() =>
            updateConfig({
              ...config,
              isGoalBand: !config.isGoalBand,
            })
          }
          label="Goal Band"
          labelOnRight
        />
        <ColorPicker
          color={config.lineColor || '#FF0000'}
          onClose={(newColor: string) =>
            updateConfig({
              ...config,
              lineColor: newColor,
            })
          }
        />
      </FlexBox>

      <div className={classes.divider} />

      <FlexBox
        className={classes.configInput}
        verticalAlignment={VerticalAlignment.BOTTOM}
        horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}>
        <InputWithBlurSave
          label="Label"
          initialValue={config.label}
          onNewValueSubmitted={(newValue: string) =>
            updateConfig({
              ...config,
              label: newValue,
            })
          }
        />
        <ColorPicker
          color={config.labelColor || '#FF0000'}
          onClose={(newColor: string) =>
            updateConfig({
              ...config,
              labelColor: newColor,
            })
          }
        />
      </FlexBox>

      <FlexBox
        className={classes.configInput}
        verticalAlignment={VerticalAlignment.BOTTOM}
        horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}>
        <DropdownSelect
          minimal
          btnMinimal
          fillWidth
          containerClassName={classes.lineTypeDropdown}
          disabled={config.isGoalBand}
          label="Line Type"
          selectedItem={PLOT_LINE_CONFIG[selectedLineType]}
          onChange={(item) =>
            updateConfig({
              ...config,
              lineType: item.id as PlotLineType,
            })
          }
          filterable={false}
          options={Object.values(PLOT_LINE_CONFIG)}
          noSelectionText="Select a Line Type"
        />

        <InputWithBlurSave
          disabled={config.isGoalBand}
          hideRightIconInteractions
          onNewValueSubmitted={(newWidthString) => {
            const newWidth = Number(newWidthString.replace('px', ''));
            if (isNaN(newWidth) || newWidth <= 0) return;
            updateConfig({
              ...config,
              lineWidth: newWidth,
            });
          }}
          initialValue={`${config.lineWidth || 1}px`}
          containerClassName={classes.lineWidthInput}
        />
      </FlexBox>
    </div>
  );
}
