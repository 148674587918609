/** @format */

import _ from 'underscore';
import { cloneDeep } from 'lodash';
import { Action } from 'reducers/rootReducer';
import { DashboardTemplate, DashboardParam, ACTION } from 'actions/types';
import { configureDataPanels } from 'utils/general';
import {
  ContainerElemConfig,
  DashboardElementConfig,
  DASHBOARD_ELEMENT_TYPES,
} from 'types/dashboardTypes';
import { Layout } from '@explo-tech/react-grid-layout';
import { ErrorResponse } from 'actions/responseTypes';

export interface DashboardReducerState {
  currentDashboardTemplate?: DashboardTemplate;
  dashboardTemplateList?: DashboardTemplate[];
  backendDashboardParamsCopy?: Set<number>;
  cachedContainerLayouts: Record<string, Layout[]>;
  error?: ErrorResponse;
  errorMsg?: string;
}

const dashboardReducerInitialState: DashboardReducerState = {
  cachedContainerLayouts: {},
};

export default (
  state: DashboardReducerState = dashboardReducerInitialState,
  action: Action,
): DashboardReducerState => {
  const newState = Object.assign({}, state);
  const { payload } = action;

  const updateElementConfig = (idx: number, config: DashboardElementConfig) => {
    if (newState.currentDashboardTemplate) {
      newState.currentDashboardTemplate = cloneDeep(newState.currentDashboardTemplate);
      newState.currentDashboardTemplate.dashboard_elements[idx].config = config;
    }
  };

  switch (action.type) {
    case 'CREATE_DASHBOARD_TEMPLATE_SUCCESS':
      if (!newState.dashboardTemplateList) return newState;

      newState.currentDashboardTemplate = payload.new_dashboard_template;
      newState.dashboardTemplateList.push(payload.new_dashboard_template);
      newState.backendDashboardParamsCopy = new Set([]);
      return newState;
    case 'CLONE_DASHBOARD_TEMPLATE_SUCCESS':
      if (!newState.dashboardTemplateList) return newState;

      newState.currentDashboardTemplate = payload.new_dashboard_template;
      newState.dashboardTemplateList.push(payload.new_dashboard_template);
      newState.backendDashboardParamsCopy = new Set([]);
      return newState;
    case `${ACTION.EMBED_FETCH_DASHBOARD}_ERROR`:
      newState.errorMsg =
        payload.errorData && payload.errorData.detail
          ? payload.errorData.detail
          : payload.errorData;
      return newState;
    case `${ACTION.DELETE_DASHBOARD_TEMPLATE}_SUCCESS`:
      if (newState.dashboardTemplateList) {
        const deletedIndex = _.findIndex(newState.dashboardTemplateList, { id: payload.id });
        newState.dashboardTemplateList.splice(deletedIndex, 1);
      }
      return newState;
    case 'FETCH_DASHBOARD_TEMPLATE_SUCCESS':
      newState.currentDashboardTemplate = payload.dashboard_template;
      newState.error = undefined;
      if (!newState.currentDashboardTemplate) return newState;

      newState.backendDashboardParamsCopy = new Set(
        payload.dashboard_template.params.map((param: DashboardParam) => param.id),
      );
      newState.currentDashboardTemplate.cachedLayout = _.map(
        newState.currentDashboardTemplate.layout || [],
        _.clone,
      );
      newState.cachedContainerLayouts = {};
      newState.currentDashboardTemplate.dashboard_elements.forEach((element) => {
        if (element.element_type === DASHBOARD_ELEMENT_TYPES.CONTAINER) {
          newState.cachedContainerLayouts[
            element.id
          ] = (element.config as ContainerElemConfig).layout;
        }
      });
      return newState;
    case 'FETCH_DASHBOARD_TEMPLATE_ERROR':
      newState.error = payload.errorData;
      return newState;
    case 'FETCH_DASHBOARD_TEMPLATE_LIST_SUCCESS':
      newState.dashboardTemplateList = payload.dashboard_template_list;
      return newState;
    case `${ACTION.RENAME_DASHBOARD_TEMPLATE}_SUCCESS`:
      if (newState.dashboardTemplateList) {
        const renamedIndex = _.findIndex(newState.dashboardTemplateList, { id: payload.id });
        newState.dashboardTemplateList[renamedIndex].name = payload.postData.name;
      }
      return newState;
    case 'ADD_DASHBOARD_PARAM':
      if (!newState.currentDashboardTemplate) return newState;
      newState.currentDashboardTemplate.params.push(payload.dashboardParam);
      return newState;
    case 'REMOVE_DASHBOARD_PARAM':
      if (!newState.currentDashboardTemplate) return newState;
      newState.currentDashboardTemplate.params.splice(payload.index, 1);
      return newState;
    case 'UPDATE_DASHBOARD_PARAM':
      if (!newState.currentDashboardTemplate) return newState;
      newState.currentDashboardTemplate.params[payload.index] = payload.dashboardParam;
      return newState;
    case 'UPDATE_DASHBOARD_TEMPLATE_PARAMS_SUCCESS':
      if (!newState.currentDashboardTemplate) return newState;
      newState.backendDashboardParamsCopy = new Set(
        payload.params.map((param: DashboardParam) => param.id),
      );
      newState.currentDashboardTemplate.params = payload.params;
      return newState;
    case `${ACTION.CREATE_TEMPLATE_DATA_PANEL}_SUCCESS`:
      if (newState.currentDashboardTemplate) {
        payload.data_panel_template = configureDataPanels([payload.data_panel_template])[0];
        newState.currentDashboardTemplate.data_panels.push(payload.data_panel_template);
        if (!payload.data_panel_template.container_id) {
          newState.currentDashboardTemplate.layout = payload.new_layout;
          newState.currentDashboardTemplate.cachedLayout = _.map(
            newState.currentDashboardTemplate.layout || [],
            _.clone,
          );
        } else {
          const indextoUpdate = newState.currentDashboardTemplate.dashboard_elements.findIndex(
            (element) => element.id === payload.data_panel_template.container_id,
          );
          updateElementConfig(indextoUpdate, { layout: payload.new_layout });
          newState.cachedContainerLayouts[
            newState.currentDashboardTemplate.dashboard_elements[indextoUpdate].id
          ] = payload.new_layout;
        }
      }
      return newState;
    case `${ACTION.CREATE_DASHBOARD_TEMPLATE_ELEMENT}_SUCCESS`:
      if (newState.currentDashboardTemplate) {
        if (!payload.dashboard_element.container_id) {
          newState.currentDashboardTemplate.layout = payload.dashboard_layout;
          newState.currentDashboardTemplate.cachedLayout = _.map(
            newState.currentDashboardTemplate.layout || [],
            _.clone,
          );
        } else {
          const indextoUpdate = newState.currentDashboardTemplate.dashboard_elements.findIndex(
            (element) => element.id === payload.dashboard_element.container_id,
          );
          updateElementConfig(indextoUpdate, { layout: payload.dashboard_layout });
          newState.cachedContainerLayouts[
            newState.currentDashboardTemplate.dashboard_elements[indextoUpdate].id
          ] = payload.dashboard_layout;
        }

        newState.currentDashboardTemplate.dashboard_elements.push(payload.dashboard_element);
      }
      return newState;
    case `${ACTION.SAVE_SHARE_LINK_TITLE}_REQUEST`:
      if (!newState.currentDashboardTemplate) return newState;
      newState.currentDashboardTemplate.share_link_title = payload.postData.share_link_title;
      return newState;
    default:
      return state;
  }
};
