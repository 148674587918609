/** @format */

import Cookies from 'js-cookie';
import * as Redux from 'redux';
import axios, { Method, AxiosError, AxiosResponse } from 'axios';
import { ErrorResponse } from 'actions/responseTypes';
import { createAction } from '@reduxjs/toolkit';

export interface ActionFnArgs {
  id?: string | number | null;
  postData?: Record<string, unknown>;
  headerTeamApiToken?: string;
  userGroupToken?: string;
  errorData?: ErrorResponse;
}

/**
 * Checks if the status is a 4XX or 5XX
 */
const isErrorHttpStatus = (httpStatus: number) => {
  const statusCodeCategory = Math.floor(httpStatus / 100);
  return statusCodeCategory === 4 || statusCodeCategory === 5;
};

export type ActionFn<T> = (
  args?: ActionFnArgs,
  onSuccess?: (data: T) => void,
  onError?: (errorMsg: ErrorResponse) => void,
) => void;

export function defineAPIAction<T>(
  actionName: string,
  urlBeforeId: string,
  urlAfterId: string,
  requestType: Method,
) {
  const requestAction = createAction<ActionFnArgs>(`${actionName}_REQUEST`);

  const successAction = createAction<ActionFnArgs & T>(`${actionName}_SUCCESS`);

  const errorAction = createAction<ActionFnArgs & { errorData?: ErrorResponse }>(
    `${actionName}_ERROR`,
  );

  const actionFn: ActionFn<T> = (
    args: ActionFnArgs = {},
    onSuccess?: (data: T) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => {
    let url = args.id ? `${urlBeforeId}/${args.id}/` : `${urlBeforeId}/`;
    if (urlAfterId && urlAfterId !== '') {
      url += `${urlAfterId}/`;
    }
    return (dispatch: Redux.Dispatch) => {
      dispatch(requestAction(args));
      const authToken = Cookies.get('spheres_auth_token');
      const teamExploAuthToken = args.headerTeamApiToken;
      return axios({
        url: process.env.REACT_APP_API_URL + url,
        method: requestType,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken ? 'Token ' + authToken : '',
          ...(teamExploAuthToken && { 'Explo-Authorization': `Token ${teamExploAuthToken}` }),
        },
        data: args.postData ? args.postData : undefined,
      })
        .then((response: AxiosResponse) => {
          const { data } = response;
          if ((data.success !== 0 || data.status === 'OK') && !isErrorHttpStatus(response.status)) {
            // Note: data.status is for django-rest-passwordreset.
            dispatch(successAction({ ...args, ...data }));
            onSuccess?.(data);
          } else {
            dispatch(errorAction({ ...args, ...data }));
            onError?.(data);
          }
        })
        .catch((error: AxiosError) => {
          console.error(error.response);
          dispatch(
            errorAction({
              ...args,
              errorData: error.response && {
                ...error.response.data,
                status: error.response.status,
              },
            }),
          );
          onError?.(error.response && { ...error.response.data, status: error.response.status });
        });
    };
  };

  return { actionFn, requestAction, successAction, errorAction };
}

export function defineEmbedAction<T>(
  actionName: string,
  urlBeforeId: string,
  urlAfterId: string,
  requestType: Method,
) {
  const actionRequest = (args: ActionFnArgs) => ({
    type: `${actionName}_REQUEST`,
    payload: {
      ...args,
    },
  });

  const actionSuccess = (args: ActionFnArgs, response?: T) => ({
    type: `${actionName}_SUCCESS`,
    payload: {
      ...args,
      ...response,
    },
  });

  const actionError = (args: ActionFnArgs, response?: object) => ({
    type: `${actionName}_ERROR`,
    payload: {
      ...args,
      ...response,
    },
  });

  const actionFn: ActionFn<T> = (
    args: ActionFnArgs = {},
    onSuccess?: (data: T) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => {
    let url = args.id ? `${urlBeforeId}/${args.id}/` : `${urlBeforeId}/`;
    if (urlAfterId && urlAfterId !== '') {
      url += `${urlAfterId}/`;
    }
    return (dispatch: Redux.Dispatch) => {
      dispatch(actionRequest(args));

      return axios({
        url: process.env.REACT_APP_API_URL + url,
        method: requestType,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Explo-Authorization': args.headerTeamApiToken
            ? `Token ${args.headerTeamApiToken}`
            : undefined,
          'User-Group-Token': args.userGroupToken ? args.userGroupToken : undefined,
        },
        data: args.postData ? args.postData : undefined,
      })
        .then((response: AxiosResponse) => {
          const { data } = response;
          if (!data.success) {
            dispatch(actionError(args, data));
            onError?.(data);
          } else {
            dispatch(actionSuccess(args, data));
            onSuccess?.(data);
          }
        })
        .catch((error: AxiosError) => {
          console.error(error.response);
          dispatch(
            actionError({
              ...args,
              errorData: error.response && {
                ...error.response.data,
                status: error.response.status,
              },
            }),
          );
          onError?.(
            error.response && {
              ...error.response.data,
              status: error.response.status,
            },
          );
        });
    };
  };

  return actionFn;
}

export function defineAnalyticsAction<T>(
  actionName: string,
  urlBase: string,
  analyticsMethod: string,
  requestType: Method,
) {
  const actionRequest = (args: ActionFnArgs) => ({
    type: `${actionName}_REQUEST`,
    payload: {
      ...args,
    },
  });

  const actionSuccess = (args: ActionFnArgs, response?: T) => ({
    type: `${actionName}_SUCCESS`,
    payload: {
      ...args,
      ...response,
    },
  });

  const actionError = (args: ActionFnArgs, response?: object) => ({
    type: `${actionName}_ERROR`,
    payload: {
      ...args,
      ...response,
    },
  });

  const actionFn: ActionFn<T> = (
    args: ActionFnArgs = {},
    onSuccess?: (data: T) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => {
    const url = `${urlBase}/${analyticsMethod}/`;

    return (dispatch: Redux.Dispatch) => {
      dispatch(actionRequest(args));

      return axios({
        url: process.env.REACT_APP_ANALYTICS_API_URL + url,
        method: requestType,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Token ${process.env.REACT_APP_ANALYTICS_API_TOKEN}`,
        },
        data: args.postData ? args.postData : undefined,
      })
        .then((response: AxiosResponse) => {
          const { data } = response;
          if (!data.success) {
            dispatch(actionError(args, data));
            onError?.(data);
          } else {
            dispatch(actionSuccess(args, data));
            onSuccess?.(data);
          }
        })
        .catch((error: AxiosError) => {
          console.error(error.response);
          dispatch(
            actionError({
              ...args,
              errorData: error.response && {
                ...error.response.data,
                status: error.response.status,
              },
            }),
          );
          onError?.(
            error.response && {
              ...error.response.data,
              status: error.response.status,
            },
          );
        });
    };
  };

  return actionFn;
}
