/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { useHistory } from 'react-router-dom';

import DotsDropdown from 'components/dotsDropdown';
import Tag from 'components/core/Tag';

import { integrationsInfo } from 'constants/dataSourceConstants';
import PostgresIcon from 'images/marketing/integration_logos/postgresql_icon.svg';

const dataSourceByType = _.indexBy(Object.values(integrationsInfo), 'source_type');

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.white,
    padding: `0px ${theme.spacing(4)}px`,
    marginBottom: theme.spacing(4),
    height: 50,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.ds.grey200}`,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  panelLeft: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  panelRight: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: 30,
    marginRight: theme.spacing(6),
  },
  actionsMenu: {
    marginLeft: theme.spacing(2),
  },
  providedIdTag: {
    marginLeft: theme.spacing(2),
    color: theme.palette.ds.grey700,
    fontSize: 12,
    width: '50%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

type Props = {
  className?: string;
  type: string;
  name: string;
  id: number;
  providedId?: string;
  isDefault?: boolean;
  hasNoSyncedTables?: boolean;
  openRenameDataSourceModal: () => void;
  openDeleteDataSourceModal: () => void;
  openSyncTablesModal: () => void;
};

export default function DataSourcesPage({
  name,
  className,
  type,
  id,
  providedId,
  isDefault,
  hasNoSyncedTables,
  openRenameDataSourceModal,
  openDeleteDataSourceModal,
  openSyncTablesModal,
}: Props) {
  const classes = useStyles();
  const history = useHistory();
  const dataSourceInfo = dataSourceByType[type];
  const dataSourceImg = dataSourceInfo?.datasourceIconImg || PostgresIcon;

  return (
    <div
      className={cx(classes.root, className)}
      onClick={() => {
        if (hasNoSyncedTables) openSyncTablesModal();
        else history.push(`/datasources/${id}`);
      }}>
      <div className={classes.panelLeft}>
        <img src={dataSourceImg} alt="data source logo" className={classes.logo} />
        {name}
        <div className={classes.providedIdTag}>({`ID: ${providedId ?? String(id)}`})</div>
      </div>
      <div className={classes.panelRight}>
        {isDefault && <Tag name="Default" />}
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <DotsDropdown
            actions={[
              {
                text: 'Rename ID',
                onClick: openRenameDataSourceModal,
                icon: 'edit',
              },
              {
                text: 'Delete Data Source',
                onClick: openDeleteDataSourceModal,
                icon: 'trash',
              },
            ]}
            className={classes.actionsMenu}
          />
        </div>
      </div>
    </div>
  );
}
