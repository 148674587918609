/** @format */

import { Action } from 'reducers/rootReducer';
import _ from 'underscore';
import { createReducer } from './utils';
import { DataSource, ACTION } from 'actions/types';

export interface DataSourceListReducerState {
  dataSources: DataSource[];
}

const initialState: DataSourceListReducerState = {
  dataSources: [],
};

const listDataSourcesSuccess = (newState: DataSourceListReducerState, action: Action) => {
  newState.dataSources = _.sortBy(action.payload.dataSources, (datasource) => datasource.id);
  return newState;
};

const renameDataSourceIdSuccess = (newState: DataSourceListReducerState, action: Action) => {
  const editedDataSource = _.find(
    newState.dataSources,
    (dataSource) => dataSource.id === action.payload.id,
  );

  if (editedDataSource) {
    editedDataSource.provided_id = action.payload.postData.provided_id;
  }

  return newState;
};

const syncParentSchemaSuccess = (newState: DataSourceListReducerState, action: Action) => {
  newState.dataSources = _.sortBy(action.payload.data_sources, (datasource) => datasource.id);
  return newState;
};

const deleteDataSourceSuccess = (newState: DataSourceListReducerState, action: Action) => {
  const { payload } = action;
  if (newState.dataSources) {
    const deletedIndex = _.findIndex(newState.dataSources, { id: payload.data_source_id });
    newState.dataSources.splice(deletedIndex, 1);
  }
  return newState;
};

export default createReducer<DataSourceListReducerState>(initialState, {
  [`${ACTION.LIST_TEAM_DATA_SOURCES}_SUCCESS`]: listDataSourcesSuccess,
  [`${ACTION.RENAME_DATA_SOURCE_ID}_SUCCESS`]: renameDataSourceIdSuccess,
  [`${ACTION.SYNC_PARENT_SCHEMA}_SUCCESS`]: syncParentSchemaSuccess,
  [`${ACTION.DELETE_DATA_SOURCE}_SUCCESS`]: deleteDataSourceSuccess,
});
