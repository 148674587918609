/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { WithStyles } from '@material-ui/core/index';
import { connect } from 'react-redux';

import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import SelectValueColumn from 'pages/dataPanelEditorPage/visualizeSections/pieChart/selectValueColumn';
import SelectCategoryColumn from 'pages/dataPanelEditorPage/visualizeSections/pieChart/selectCategoryColumn';
import SelectPieChartType from 'pages/dataPanelEditorPage/visualizeSections/pieChart/selectPieChartType';
import SelectPieChartLegendLocation from 'pages/dataPanelEditorPage/visualizeSections/pieChart/selectPieChartLegendLocation';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { VisualizePieChartInstruction } from 'constants/types';
import { OPERATION_TYPES } from 'constants/types';
import { DataPanelTemplate } from 'actions/types';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 200,
    },
  });

type PassedProps = {
  instructions: VisualizePieChartInstruction;
  dataPanelTemplate: DataPanelTemplate;
};

type Props = PassedProps &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  RouteComponentProps &
  ReturnType<typeof mapStateToProps>;

class VisualizePieChartSection extends React.Component<Props> {
  render() {
    const { classes, instructions, dataPanelTemplate, updateVisualizeOperation } = this.props;
    if (!dataPanelTemplate || !dataPanelTemplate._schema) return <div></div>;

    return (
      <div className={classes.root}>
        <ConfigSectionHeader title="Configure pie chart" />
        <SelectCategoryColumn
          schema={dataPanelTemplate._schema}
          instructions={instructions}
          updateVisualizeOperation={(newInstructions: VisualizePieChartInstruction) => {
            updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_PIE_CHART);
          }}
        />
        <SelectValueColumn
          schema={dataPanelTemplate._schema}
          instructions={instructions}
          updateVisualizeOperation={(newInstructions: VisualizePieChartInstruction) => {
            updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_PIE_CHART);
          }}
        />
        <SelectPieChartType
          instructions={instructions}
          updateVisualizeOperation={(newInstructions: VisualizePieChartInstruction) => {
            updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_PIE_CHART);
          }}
        />
        <SelectPieChartLegendLocation
          instructions={instructions}
          updateVisualizeOperation={(newInstructions: VisualizePieChartInstruction) => {
            updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_PIE_CHART);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateVisualizeOperation,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VisualizePieChartSection)),
);
