/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';
import {
  Menu,
  MenuItem,
  Button,
  Position,
  Popover,
  Tag,
  Icon,
  Intent,
  Tooltip,
} from '@blueprintjs/core';

const styles = (theme: Theme) => ({
  root: {},
  menuItemTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0,
  },
  datasetActionMenu: {
    visibility: 'hidden' as 'hidden',
  },
  menuItemTooltipContainer: {
    display: 'inline-block',
    width: '100%',
  },
  menuItemTag: {
    marginLeft: `${theme.spacing(2)}px`,
  },
  menuItemErrorTag: {
    // red 8%
    backgroundColor: 'rgba(229, 29, 0, 0.08)',
    color: theme.palette.ds.red,
  },
  menuItemDraftTag: {
    // black 8%
    backgroundColor: 'rgba(10, 17, 30, 0.08)',
    color: theme.palette.ds.grey800,
  },
  menuItemNewTag: {
    // dark blue 8%
    backgroundColor: 'rgba(0, 105, 237, 0.08)',
    color: theme.palette.ds.blue,
  },
  menuItemActiveTag: {
    // white 16%
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
    color: theme.palette.ds.white,
  },
  menuItemTextContainerSelected: {
    '&.bp3-menu-item': {
      '&.bp3-active': {
        backgroundColor: theme.palette.ds.blue,
      },
    },
  },
  menuItemPopover: {
    marginLeft: 'auto',
  },
  menuItemIcon: {
    paddingRight: `${theme.spacing(1)}px`,
  },
});

type PassedProps = {
  onClick: () => void;
  onEditClicked?: () => void;
  onDeleteClicked?: () => void;
  active: boolean;
  draft?: boolean;
  error?: boolean;
  new?: boolean;
  used?: boolean;
  name: string;
  editable?: boolean;
  loading?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  mouseHover: boolean;
  actionsMenuOpen: boolean;
};

class DatasetMenuItem extends React.Component<Props, State> {
  mouseOutTimer: undefined | number;

  state: State = {
    mouseHover: false,
    actionsMenuOpen: false,
  };

  render() {
    const { onClick, active, name, classes } = this.props;
    return (
      <Tooltip
        content={name}
        className={classes.menuItemTooltipContainer}
        targetClassName={classes.menuItemTooltipContainer}
        position={Position.RIGHT}
        usePortal={true}
        boundary="window">
        <MenuItem
          onMouseOver={this.onMouseOver}
          onMouseLeave={this.onMouseOut}
          text={this.renderDatasetItemText()}
          active={active}
          onClick={onClick}
          className={cx({
            [classes.menuItemTextContainerSelected]: active,
          })}
        />
      </Tooltip>
    );
  }

  renderDatasetItemText = () => {
    const { classes, name, used } = this.props;
    return (
      <div className={classes.menuItemTextContainer}>
        {used && (
          <Icon
            className={classes.menuItemIcon}
            icon="full-circle"
            intent={Intent.PRIMARY}
            iconSize={6} // 6 is the size of the dot icon
          />
        )}
        <div className={classes.menuItemText}>{name}</div>
        {this.renderDatasetItemTags()}
        {this.renderDatasetActionsMenu()}
      </div>
    );
  };

  renderDatasetActionsMenu = () => {
    const { classes, onEditClicked, onDeleteClicked, editable, loading } = this.props;
    const { mouseHover, actionsMenuOpen } = this.state;

    if (!editable) {
      return <div></div>;
    }

    return (
      <Popover
        hoverCloseDelay={0}
        inheritDarkTheme={false}
        disabled={!mouseHover || loading}
        isOpen={actionsMenuOpen}
        onClose={() => this.setState({ actionsMenuOpen: false })}
        className={classes.menuItemPopover}
        position={Position.BOTTOM}>
        <Button
          className={cx({
            [classes.datasetActionMenu]: !mouseHover && !loading,
            'bp3-dark': loading,
          })}
          icon="more"
          minimal
          small
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ actionsMenuOpen: !actionsMenuOpen });
          }}
          loading={loading}
        />
        <Menu>
          <MenuItem
            icon="edit"
            text="Edit Name"
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              e.preventDefault();
              e.stopPropagation();
              onEditClicked?.();
            }}
          />
          {editable && (
            <MenuItem
              icon="cross"
              text="Delete"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                e.preventDefault();
                e.stopPropagation();
                onDeleteClicked?.();
              }}
            />
          )}
        </Menu>
      </Popover>
    );
  };

  renderDatasetItemTags = () => {
    const { error, draft, new: newItem, classes, active } = this.props;
    return (
      <div>
        {draft && (
          <Tag
            className={cx(classes.menuItemTag, {
              [classes.menuItemDraftTag]: !active,
              [classes.menuItemActiveTag]: active,
            })}>
            Draft
          </Tag>
        )}
        {error && (
          <Tag
            className={cx(classes.menuItemTag, {
              [classes.menuItemErrorTag]: !active,
              [classes.menuItemActiveTag]: active,
            })}>
            Error
          </Tag>
        )}
        {newItem && (
          <Tag
            className={cx(classes.menuItemTag, {
              [classes.menuItemNewTag]: !active,
              [classes.menuItemActiveTag]: active,
            })}>
            New
          </Tag>
        )}
      </div>
    );
  };

  onMouseOver = () => {
    const { mouseHover } = this.state;
    clearTimeout(this.mouseOutTimer);
    !mouseHover && this.setState({ mouseHover: true });
  };

  onMouseOut = () => {
    const { mouseHover } = this.state;
    this.mouseOutTimer = setTimeout(() => {
      mouseHover && this.setState({ mouseHover: false });
    }, 300);
  };
}

export default withStyles(styles)(DatasetMenuItem);
