/** @format */

import React from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import cx from 'classnames';

import { Classes, Menu, MenuItem, MenuDivider, Icon, IconName } from '@blueprintjs/core';

import Button from 'shared/Button';

import { Dataset } from 'actions/types';
import { titleCase } from 'utils/graphUtils';
import Modal from 'components/core/Modal';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.white,
  },
  modalBodyList: {
    height: '40vh',
    backgroundColor: theme.palette.ds.white,
    overflowY: 'auto' as 'auto',
    border: `1px solid ${theme.palette.ds.grey200}`,
    borderRadius: '4px',
    marginBottom: theme.spacing(6),
  },
  actionTitle: {
    fontSize: 16,
    marginBottom: theme.spacing(5),
    fontWeight: 500,
    marginTop: theme.spacing(-5),
  },
  selectedOption: {
    backgroundColor: theme.palette.ds.white,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  selectedOptionText: {},
  selectedOptionValue: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 60px)',
  },
  selectedOptionValueName: {
    marginLeft: theme.spacing(2),
    width: '90%',
    overflow: 'hidden',
    whitespace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  createButton: {
    width: theme.spacing(26),
    height: theme.spacing(8),
    display: 'flex',
    justifyConent: 'center',
    alignItem: 'center',
  },
  cancelButton: {
    width: theme.spacing(21),
    height: theme.spacing(8),
    display: 'flex',
    justifyConent: 'center',
    alignItem: 'center',
  },
  selectedMenuItem: {
    background: theme.palette.ds.lightBlue,
  },
});

type PassedProps = {
  modalOpen: boolean;
  closeModal: () => void;
  onCreate: (datasetId: string, name: string) => void;
  createLoading?: boolean;
  dashboardTemplateId: number;
};

type State = {
  selectedDataset?: Dataset;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  PassedProps &
  WithStyles<typeof styles>;

class AddDataPanelModal extends React.Component<Props> {
  state: State = {};

  render() {
    const { classes, modalOpen, closeModal, createLoading } = this.props;
    const { selectedDataset } = this.state;
    return (
      <Modal
        className={classes.root}
        modalOpen={modalOpen}
        onClose={closeModal}
        title="Add a new data panel">
        <div className={Classes.DIALOG_BODY}>
          <div className={classes.actionTitle}>Select a table</div>
          {this.renderDatasetList()}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              type="secondary"
              onClick={() => closeModal()}
              className={classes.cancelButton}
              text="Cancel"
            />
            <Button
              type="primary"
              onClick={this.onCreateClicked}
              disabled={!selectedDataset || createLoading}
              loading={createLoading}
              className={classes.createButton}
              text="Create"
            />
          </div>
        </div>
      </Modal>
    );
  }

  onCreateClicked = () => {
    const { onCreate } = this.props;
    const { selectedDataset } = this.state;
    if (!selectedDataset) return;
    if (!selectedDataset) return;
    onCreate(selectedDataset.id, titleCase(selectedDataset.table_name));
  };

  renderDatasetList = () => {
    const { classes, dashboardDatasets } = this.props;

    const dashboardDatasetsOrdered = _.sortBy(dashboardDatasets, (dataset) => dataset.table_name);

    return (
      <div className={classes.modalBodyList}>
        <Menu>
          <MenuDivider title="Dashboard Datasets"></MenuDivider>
          {dashboardDatasetsOrdered.length === 0 && (
            <MenuItem text="No datasets. Create one!" disabled />
          )}
          {dashboardDatasetsOrdered.map((dataset) => this.renderDatasetItem(dataset))}
        </Menu>
      </div>
    );
  };

  renderDatasetItem = (dataset: Dataset) => {
    const { selectedDataset } = this.state;
    const { classes } = this.props;

    // Making sure that unnamed datasets would still be displayed on the data panel selection screen
    return (
      <MenuItem
        key={`dataset-add-data-panel-${dataset.id}`}
        text={dataset.table_name || 'Untitled'}
        onClick={() => this.setState({ selectedDataset: dataset })}
        className={cx({ [classes.selectedMenuItem]: dataset.id === selectedDataset?.id })}
        icon="th"
      />
    );
  };

  renderSelectedOption = (selectText: string, selectedName: string, icon: IconName) => {
    const { classes } = this.props;

    return (
      <div className={classes.selectedOption}>
        <div className={classes.selectedOptionText}>{selectText}: </div>
        <div className={classes.selectedOptionValue}>
          <Icon icon={icon} /> <div className={classes.selectedOptionValueName}>{selectedName}</div>
        </div>
      </div>
    );
  };

  renderConfirmPage = () => {
    const { selectedDataset } = this.state;
    if (!selectedDataset) return;
    return <>{this.renderSelectedOption('Dataset', selectedDataset.table_name, 'th')}</>;
  };
}

const mapStateToProps = (state: ReduxState) => ({
  dashboardDatasets: Object.values(state.dashboardEditConfig.config?.datasets || {}),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddDataPanelModal));
