/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Tooltip, Icon, Intent } from '@blueprintjs/core';

import { INFORMATION_ICON } from 'constants/iconConstants';

const useStyles = makeStyles((theme: Theme) => ({
  tooltipWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
  },
  popover: {
    maxWidth: 300,
  },
  infoIcon: {
    '& > span': {
      display: 'flex',
    },
  },
}));

type Props = {
  infoTooltipText?: string;
  className?: string;
};

export default function InformationIcon({ className, infoTooltipText }: Props) {
  const classes = useStyles();
  return (
    <Tooltip
      intent={Intent.NONE}
      className={cx(classes.tooltipWrapper, className)}
      targetClassName={classes.infoIcon}
      popoverClassName={classes.popover}
      content={infoTooltipText}>
      <Icon iconSize={14} icon={INFORMATION_ICON} color="#CCCCCC" />
    </Tooltip>
  );
}
