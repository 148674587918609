/** @format */

import axios from 'axios';

export const fetchGeoJson = (s3FileName: string, keyName: string) => {
  axios({
    url: `${process.env.REACT_APP_EMBED_ASSET_URL}geojson/${s3FileName}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      const { data, status } = response;
      if (status !== 200) {
        console.log('Fetching worldGeoJson.json failed', response);
      } else {
        window.exploAssets[keyName] = data;
      }
    })
    .catch((error) => {
      console.log('Fetching worldGeoJson.json errored', error);
    });
};
