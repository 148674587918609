/** @format */

import React, { useContext } from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { cloneDeep } from 'lodash';

import InputLabel from 'shared/InputLabel';
import ColorPickerButton from 'shared/ColorPicker';
import Button from 'shared/Button';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { TableRow, TableColumn } from 'actions/types';
import {
  StringDisplayFormat,
  StringDisplayOptions,
  VisualizeTableInstructions,
} from 'constants/types';
import { DEFAULT_CATEGORY_COLORS } from 'constants/colorConstants';
import { getCategoricalColors, GlobalStylesContext } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  categoryRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  colorPicker: {
    marginRight: theme.spacing(4),
  },
  colorPickerBtn: {
    width: 32,
    height: 32,
  },
  categoryName: {
    color: theme.palette.ds.black,
  },
  addCategoryBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',

    '&.bp3-button': {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      borderRadius: 4,
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'inherit',
      backgroundImage: 'none',
      justifyContent: 'left',

      backgroundColor: theme.palette.ds.grey300,

      '&:hover': {
        backgroundColor: theme.palette.ds.hovered.grey300,
      },
      '&:active': {
        backgroundColor: theme.palette.ds.pressed.grey300,
      },

      '&.bp3-disabled': {
        backgroundColor: theme.palette.ds.grey400,
        color: theme.palette.ds.grey700,
        '&.bp3-button:hover': {
          backgroundColor: theme.palette.ds.grey400,
        },
      },
      '&.bp3-active': {
        boxShadow: 'none',
      },
    },
  },
  addedCategoryInput: {
    width: '100%',
  },
  removeCategoryBtn: {},
}));

type Props = {
  addFieldsToDisplayOptions: (
    newFields: Partial<StringDisplayOptions> & {
      format: StringDisplayFormat;
    },
  ) => void;
  column: TableColumn;
  instructions: VisualizeTableInstructions;
  dataPanelData: TableRow[];
};

export default function CategoryFieldColorAssignment({
  addFieldsToDisplayOptions,
  column,
  instructions,
  dataPanelData,
}: Props) {
  const classes = useStyles();
  const context = useContext(GlobalStylesContext);
  const { format: currentFormat, categoryColorAssignments, addedCategories } = (instructions
    .schemaDisplayOptions?.[column.name] as StringDisplayOptions) ?? {
    format: StringDisplayFormat.NORMAL,
  };

  const columnCategories = new Set(_.pluck(dataPanelData, column.name));

  return (
    <>
      <InputLabel className={classes.label} text="Color Palette" />
      {Array.from(columnCategories).map((category, index) => {
        return (
          <div className={classes.categoryRow} key={`color-category-${category}`}>
            <ColorPickerButton
              className={classes.colorPicker}
              btnClassName={classes.colorPickerBtn}
              color={categoryColorAssignments?.[category] || DEFAULT_CATEGORY_COLORS[index % 12]}
              colorPalette={getCategoricalColors(context.globalStyleConfig)}
              onColorChange={(newColor) => {
                const newCategoryColorAssignments = cloneDeep(categoryColorAssignments || {});
                newCategoryColorAssignments[category] = newColor;

                addFieldsToDisplayOptions({
                  format: currentFormat,
                  categoryColorAssignments: newCategoryColorAssignments,
                });
              }}
            />
            <div className={classes.categoryName}>{String(category)}</div>
          </div>
        );
      })}
      {addedCategories?.map(({ name, color }, index) => {
        return (
          <div className={classes.categoryRow} key={`color-category-${name}-${index}`}>
            <ColorPickerButton
              className={classes.colorPicker}
              btnClassName={classes.colorPickerBtn}
              color={color}
              colorPalette={getCategoricalColors(context.globalStyleConfig)}
              onColorChange={(newColor) => {
                const newAddedCategories = cloneDeep(addedCategories || []);
                newAddedCategories[index].color = newColor;

                addFieldsToDisplayOptions({
                  format: currentFormat,
                  addedCategories: newAddedCategories,
                });
              }}
            />
            <InputWithBlurSave
              hideRightIconInteractions
              containerClassName={classes.addedCategoryInput}
              initialValue={name}
              onNewValueSubmitted={(newName) => {
                const newAddedCategories = cloneDeep(addedCategories || []);
                newAddedCategories[index].name = newName;

                addFieldsToDisplayOptions({
                  format: currentFormat,
                  addedCategories: newAddedCategories,
                });
              }}
            />
            <Button
              minimal
              className={classes.removeCategoryBtn}
              icon="cross"
              onClick={() => {
                const newAddedCategories = cloneDeep(addedCategories || []);

                newAddedCategories.splice(index, 1);

                addFieldsToDisplayOptions({
                  format: currentFormat,
                  addedCategories: newAddedCategories,
                });
              }}
            />
          </div>
        );
      })}
      <Button
        className={classes.addCategoryBtn}
        text="Add a category"
        icon="plus"
        onClick={() => {
          const newAddedCategories = cloneDeep(addedCategories || []);
          newAddedCategories.push({
            name: '',
            color: context.globalStyleConfig.visualizations.categoricalPalette.hue1,
          });
          addFieldsToDisplayOptions({
            format: currentFormat,
            addedCategories: newAddedCategories,
          });
        }}
      />
    </>
  );
}
