/** @format */

import { Popover } from '@blueprintjs/core';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import copy from 'copy-to-clipboard';
import React, { useContext } from 'react';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import { GlobalStylesContext } from 'globalStyles';
import Button from 'shared/Button';
import { GlobalStyleConfig } from 'globalStyles/types';

const useStyles = makeStyles((theme: Theme) => ({
  popoverContainer: (inputs: [Props, GlobalStyleConfig]) => ({
    '& .bp3-popover.bp3-minimal': {
      margin: `0px 0px ${theme.spacing(2.5)}px 0px !important`,
      maxWidth: 300,
    },
    '& .bp3-popover': {
      boxShadow: `0px 0px 12px rgba(0, 0, 0, 0.1)`,
      borderRadius: 4,
      backgroundColor: theme.palette.ds.white,
      padding: inputs[0]?.isOpen ? theme.spacing(3) : 0,
    },
  }),
  downloadPopoverText: (inputs: [Props, GlobalStyleConfig]) => ({
    fontSize: theme.typography.caption.fontSize,
    fontFamily: inputs[1].text.primaryFont,
    lineHeight: '16px',
    textAlign: 'left',
    '& a': {
      color: theme.palette.ds.blue,
    },
  }),
  link: { paddingLeft: 4 },
  copyInputGroup: (inputs: [Props, GlobalStyleConfig]) => ({
    marginTop: theme.spacing(2),
    '& .bp3-input': {
      fontFamily: `${inputs[1].text.primaryFont}`,
    },
  }),
  copyButton: (inputs: [Props, GlobalStyleConfig]) => ({
    '&.bp3-button': {
      height: 30,
      margin: '0 !important',
      fontFamily: inputs[1].text.primaryFont,
      backgroundColor: inputs[1].base.actionColor.default,
      '&.bp3-button:hover': {
        backgroundColor: inputs[1].base.actionColor.default,
      },
    },
  }),
}));

export type Props = {
  isError?: boolean;
  isOpen?: boolean;
  link?: string;
  showLink?: boolean;
  onClose?: () => void;
  onLinkClick?: () => void;
};

export const DownloadPopover: React.FC<Props> = (props) => {
  const { children, isOpen, onClose } = props;
  const { globalStyleConfig } = useContext(GlobalStylesContext);

  const classes = useStyles([props, globalStyleConfig]);

  return (
    <Popover
      portalClassName={classes.popoverContainer}
      minimal
      content={<PopoverContent {...props} />}
      onClose={onClose}
      isOpen={isOpen}
      position="top-right">
      {children}
    </Popover>
  );
};

const PopoverContent: React.FC<Props> = (props) => {
  const { isOpen, isError, link, showLink, onLinkClick } = props;
  const { globalStyleConfig } = useContext(GlobalStylesContext);

  const classes = useStyles([props, globalStyleConfig]);

  if (!isOpen) return <></>;
  if (isError) {
    return (
      <div className={classes.downloadPopoverText}>
        The download failed. If this continues, please contact
        <a
          href="mailto:support@explo.co"
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer">
          support@explo.co
        </a>
        .
      </div>
    );
  } else if (link) {
    return showLink ? (
      <>
        <div className={classes.downloadPopoverText}>
          If the download didn{"'"}t start automatically, copy the link to your clipboard.
        </div>
        <InputGroup
          readOnly
          className={classes.copyInputGroup}
          type="text"
          value={link}
          rightElement={
            <Button
              type="primary"
              className={classes.copyButton}
              onClick={() => {
                copy(link);
              }}
              text="Copy"
            />
          }
        />
      </>
    ) : (
      <div className={classes.downloadPopoverText}>
        If the download didn{"'"}t start automatically, click on
        <a
          href={link}
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onLinkClick}>
          this link
        </a>
        .
      </div>
    );
  }

  return <div></div>;
};
