/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Theme, WithStyles } from '@material-ui/core/index';
import { cloneDeep } from 'lodash';

import EditingPanel from 'pages/dataPanelEditorPage/editingPanel';
import DropdownSelect from 'shared/DropdownSelect';

import { AggregationColumnInfo, SelectedDropdownInputItem } from 'constants/types';
import { TableColumn } from 'actions/types';
import { DATE_TYPES, SCHEMA_DATA_TYPES_BY_ID, DATE_PIVOT_AGGS } from 'constants/dataConstants';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    columnSelect: {
      marginBottom: theme.spacing(2),
    },
    operatorSelect: {
      marginBottom: theme.spacing(2),
    },
  });

type PassedProps = {
  baseSchema: TableColumn[];
  className?: string;
  pivotedOnCol: AggregationColumnInfo;
  onDelete: () => void;
  onUpdate: (aggregationColumnInfo: AggregationColumnInfo) => void;
};

type Props = PassedProps & WithStyles<typeof styles> & RouteComponentProps;

class GroupingEditingPanel extends React.Component<Props> {
  render() {
    const { className, onDelete } = this.props;
    return (
      <EditingPanel className={className} onDelete={onDelete}>
        {this.renderColumnDropdown()}
        {this.renderBucketSizeSelector()}
      </EditingPanel>
    );
  }

  renderColumnDropdown = () => {
    const { classes, baseSchema, pivotedOnCol, onUpdate } = this.props;

    return (
      <div className={classes.columnSelect}>
        <DropdownSelect
          selectedItem={
            pivotedOnCol.type
              ? {
                  name: pivotedOnCol.name,
                  id: pivotedOnCol.name,
                  icon: SCHEMA_DATA_TYPES_BY_ID[pivotedOnCol.type].icon,
                }
              : undefined
          }
          onChange={(item) => {
            const newPivotedOnCol = _.findWhere(baseSchema, {
              name: item.name,
            });
            newPivotedOnCol && onUpdate(newPivotedOnCol);
          }}
          filterable={false}
          options={baseSchema.map((col) => ({
            ...col,
            icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
            id: col.name,
          }))}
          noSelectionText="Column"
          minimal
          fillWidth
          showIcon
        />
      </div>
    );
  };

  renderBucketSizeSelector = () => {
    const { pivotedOnCol, onUpdate } = this.props;

    if (DATE_TYPES.has(pivotedOnCol.type)) {
      return (
        <DropdownSelect
          selectedItem={pivotedOnCol.aggBucket as SelectedDropdownInputItem}
          onChange={(item) => {
            const newPivotedCol = cloneDeep(pivotedOnCol);
            newPivotedCol.aggBucket = item;
            onUpdate(newPivotedCol);
          }}
          noSelectionText="Select Date Group"
          options={DATE_PIVOT_AGGS}
          minimal
          fillWidth
          filterable={false}
        />
      );
    } else return;
  };
}

export default withRouter(withStyles(styles)(GroupingEditingPanel));
