/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Theme, WithStyles } from '@material-ui/core/index';
import { connect } from 'react-redux';
import { Intent, Menu, NonIdealState, Icon, Tooltip, Position } from '@blueprintjs/core';
import sqlFormatter from 'sql-formatter';
import { AppToaster } from 'toaster';

import Button from 'shared/Button';
import DatasetMenuItem from 'pages/manageDataTablesPage/datasetMenuItem';
import DashboardDatasetModal from 'pages/dashboardPage/dashboardDatasetEditor/DashboardDatasetModal';
import ConfirmationModal from 'components/modals/confirmationModal';
import SQLEditor from 'components/sqlEditor/editor';
import BaseDataTable from 'components/dataTable/baseDataTable';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import TablePageNavigation from 'components/dataTable/tablePageNavigation';
import PageNumberIndicator from 'components/dataTable/pageNumberIndicator';
import NavTabs from 'components/core/navTabs';
import DropdownSelect from 'shared/DropdownSelect';

import { fetchEditorDatasetPreview, fetchEditorDatasetRowCount } from 'actions/datasetActions';
import {
  createDashboardDatasetV2,
  deleteDashboardDatasetV2,
  editDashboardDatasetName,
  saveDashboardDatasetQuery,
} from 'actions/dashboardV2Actions';
import { Dataset, ParentSchema } from 'actions/types';
import { trackEvent } from 'analytics/exploAnalytics';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { checkDashboardDatasetDataForDuplicates } from 'utils/queryUtils';
import {
  datasetEditorShowsDatasetsAsDropdown,
  datasetEditorShowsDatasetsAsList,
} from 'constants/dataPanelEditorConstants';

export const DATASET_LIST_WIDTH = 300;
export const MENU_HEIGHT = 50;
export const INITIAL_DATASET_EDITOR_HEIGHT = 300;
const DATASET_PREVIEW_CONTROLS_BAR_HEIGHT = 40;

const DATASET_VIEWS = {
  QUERY: {
    id: 'QUERY',
    name: 'Query',
  },
  PREVIEW: {
    id: 'PREVIEW',
    name: 'Preview',
  },
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      borderTop: `1px solid ${theme.palette.ds.grey400}`,
      backgroundColor: theme.palette.ds.white,
    },
    actionBtn: {
      marginRight: theme.spacing(1),
    },
    lastActionBtn: {
      marginRight: 0,
    },
    editorBody: {
      height: `100%`,
      display: 'flex',
      alignItems: 'center',
    },
    menuDivider: {
      fontWeight: 'bold' as 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `0 ${theme.spacing(3)}px`,
      height: MENU_HEIGHT,
      borderBottom: `1px solid ${theme.palette.ds.grey400}`,
    },
    datasetList: {
      width: DATASET_LIST_WIDTH,
      height: '100%',
      borderRight: `1px solid ${theme.palette.ds.grey400}`,
      backgroundColor: theme.palette.ds.white,
    },
    editor: {
      height: '100%',
      width: `calc(100% - ${DATASET_LIST_WIDTH}px)`,
    },
    fullWidthEditor: {
      height: '100%',
      width: '100%',
    },
    editorTopBar: {
      backgroundColor: theme.palette.ds.white,
      borderBottom: `1px solid ${theme.palette.ds.grey400}`,
      height: MENU_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(3),
    },
    selectDatasetTopBar: {
      backgroundColor: theme.palette.ds.white,
      height: MENU_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.ds.grey400}`,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    datasetDropdown: {
      width: `calc(100% - 80px)`,
    },
    editorTopBarDatasetActions: {},
    editorDatasetViewTabs: {
      height: MENU_HEIGHT,
    },
    editorDatasetViewTab: {
      padding: `0 ${theme.spacing(4)}px`,
    },
    editorContainer: {
      height: `calc(100% - ${MENU_HEIGHT}px)`,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
    },
    editorBtn: {
      marginRight: theme.spacing(3),
    },
    datasetPreviewTable: {
      height: `calc(100% - ${DATASET_PREVIEW_CONTROLS_BAR_HEIGHT}px)`,
    },
    datasetError: {
      backgroundColor: theme.palette.ds.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    previewDatasetControls: {
      height: DATASET_PREVIEW_CONTROLS_BAR_HEIGHT,
      borderTop: `1px solid ${theme.palette.ds.grey500}`,
      backgroundColor: theme.palette.ds.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    pageNumberIndicator: {
      marginRight: theme.spacing(3),
    },
    datasetListContainer: {
      height: `calc(100% - ${MENU_HEIGHT}px)`,
      overflowY: 'auto',
    },
    datasetEditor: {
      width: '100%',
      height: '100%',
    },
  });

type PassedProps = {
  dashboardTemplateId: number;
  isOpen?: boolean;
  toggleEditorVisibility: () => void;
  datasets: { [datasetId: string]: Dataset };
  selectedDataPanelSourceDatasetId?: string;
  parentSchemas: ParentSchema[];
  schemaTablesMap: { [schemaId: string]: { [datasetId: string]: Dataset } };
  pageWidth: number | null;
  dashboardVars: DashboardVariableMap;
  selectedUserGroupId?: number;
};

type Props = PassedProps &
  WithStyles<typeof styles> &
  typeof mapDispatchToProps &
  RouteComponentProps;

type State = {
  selectedDatasetId?: string;
  datasetQueries: { [datasetId: string]: string };
  actionLoadingDatasetId?: string;
  editDatasetModalOpen?: boolean;
  createDatasetModalOpen?: boolean;
  editDataset?: Dataset;
  deleteDatasetModalOpen?: boolean;
  deleteDataset?: Dataset;
  datasetEditorView: string;
  currentPreviewPage: number;
  pageNavVal: number;
  pageNavValid: boolean;
};

class DashboardDatasetEditor extends React.Component<Props, State> {
  state: State = {
    datasetEditorView: DATASET_VIEWS.QUERY.id,
    currentPreviewPage: 1,
    pageNavVal: 1,
    pageNavValid: false,
    datasetQueries: {},
  };

  componentDidMount() {
    if (
      this.state.selectedDatasetId === undefined &&
      Object.values(this.props.datasets).length > 0
    ) {
      this.switchSelectedDataset(_.sortBy(Object.values(this.props.datasets))[0]);
    }
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  currentQuery = () => {
    const { datasetQueries, selectedDatasetId } = this.state;
    if (!selectedDatasetId) return;

    return datasetQueries[selectedDatasetId];
  };

  handleKeyDown = (event: KeyboardEvent) => {
    const dataset = this.getSelectedDataset();
    if (!dataset) return;

    if (event.metaKey && event.shiftKey && event.key === 'f') {
      this.formatSqlQuery();
    } else if (event.metaKey && event.key === 'Enter') {
      this.getTablePreview();
    } else if (event.metaKey && event.shiftKey && event.key === 's') {
      if (this.currentQuery() === dataset.query) return;
      this.saveDashboardDataset();
    }
  };

  componentDidUpdate(prevProps: Props) {
    const newDatasets = Object.keys(this.props.datasets);
    const oldDatasets = Object.keys(prevProps.datasets);
    if (
      this.state.selectedDatasetId === undefined &&
      Object.values(this.props.datasets).length > 0
    ) {
      this.switchSelectedDataset(_.sortBy(Object.values(this.props.datasets))[0]);
    } else if (newDatasets.length > oldDatasets.length) {
      const diff = newDatasets.filter((x) => !oldDatasets.includes(x));
      if (diff.length === 1) {
        this.switchSelectedDataset(this.props.datasets[diff[0]]);
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.renderEditorBody()}
        {this.renderCreateDatasetModal()}
        {this.renderEditDatasetModal()}
        {this.renderDeleteDatasetModal()}
      </div>
    );
  }

  switchSelectedDataset = (newDataset: Dataset) => {
    const {
      fetchEditorDatasetPreview,
      fetchEditorDatasetRowCount,
      dashboardVars,
      selectedUserGroupId,
    } = this.props;
    const { datasetQueries } = this.state;
    const datasetHasNoError = newDataset._error == null;
    const datasetReadyToCompute = newDataset.query;

    const postData = {
      dataset: newDataset,
      variables: {
        ...dashboardVars,
      },
      end_user_group_id: selectedUserGroupId,
    };

    if (datasetHasNoError && !newDataset._rows && datasetReadyToCompute) {
      fetchEditorDatasetPreview({ postData });
    }
    if (datasetHasNoError && !newDataset._total_row_count && datasetReadyToCompute) {
      fetchEditorDatasetRowCount({ postData: { ...postData, rowCount: true } });
    }

    const stateUpdate = {
      selectedDatasetId: newDataset.id,
      currentPreviewPage: 1,
      pageNavVal: 1,
      pageNavValid: false,
      datasetQueries: {
        [newDataset.id]: newDataset.query || '',
        ...datasetQueries,
      },
    };
    this.setState(stateUpdate);
  };

  renderCollapseButton = () => {
    const { classes, toggleEditorVisibility, isOpen } = this.props;

    return (
      <Button
        minimal
        icon={isOpen ? 'double-chevron-down' : 'double-chevron-up'}
        className={cx(classes.actionBtn, classes.lastActionBtn)}
        onClick={() => toggleEditorVisibility()}
      />
    );
  };

  renderEditorBody = () => {
    const { classes, pageWidth } = this.props;

    return (
      <div className={classes.editorBody}>
        {pageWidth && datasetEditorShowsDatasetsAsList(pageWidth) && this.renderDatasetList()}
        {this.renderDatasetEditor()}
      </div>
    );
  };

  renderSelectDatasetTopBar = () => {
    const { classes, datasets, isOpen } = this.props;
    const { selectedDatasetId } = this.state;
    const dropdownInputs = _.map(datasets, (dataset) => {
      return { name: this.getDatasetName(dataset), id: dataset.id };
    });

    return (
      <div className={classes.selectDatasetTopBar}>
        <div className={classes.datasetDropdown}>
          <DropdownSelect
            fillWidth
            minimal
            disabled={!isOpen}
            selectedItem={_.find(
              dropdownInputs,
              (dropdownInput) => dropdownInput.id === selectedDatasetId,
            )}
            onChange={(selectedInput) => {
              const newDataset = _.find(datasets, (dataset) => dataset.id === selectedInput.id);
              newDataset && this.switchSelectedDataset(newDataset);
              trackEvent('Selected dataset', {
                dataset_id: newDataset?.id,
                dataset_name: newDataset,
              });
            }}
            options={dropdownInputs}
            noSelectionText="Select a dataset"
          />
        </div>
        <div>
          <Button
            minimal
            icon="plus"
            disabled={!isOpen}
            onClick={() => {
              this.setState({ createDatasetModalOpen: true });
            }}
          />
          {this.renderCollapseButton()}
        </div>
      </div>
    );
  };

  renderDatasetList = () => {
    const { classes, datasets } = this.props;
    return (
      <div className={classes.datasetList}>
        {this.renderDatasetListHeader()}
        <Menu className={classes.datasetListContainer}>
          {_.sortBy(Object.values(datasets), 'table_name').map((dataset) =>
            this.renderDatasetItem(dataset),
          )}
        </Menu>
      </div>
    );
  };

  renderDatasetListHeader = () => {
    const { classes, isOpen } = this.props;
    return (
      <div className={classes.menuDivider}>
        <div>Datasets</div>
        <div>
          <Button
            minimal
            icon="plus"
            disabled={!isOpen}
            onClick={() => {
              this.setState({ createDatasetModalOpen: true });
            }}
          />
        </div>
      </div>
    );
  };

  renderDatasetItem = (dataset: Dataset) => {
    const { selectedDatasetId, actionLoadingDatasetId, datasetQueries } = this.state;
    const { selectedDataPanelSourceDatasetId, datasets } = this.props;
    const tableName = this.getDatasetName(dataset);
    const query = datasetQueries[dataset.id];

    return (
      <DatasetMenuItem
        editable
        key={`dataset-navbar-item-${dataset.id}`}
        onClick={() => {
          this.switchSelectedDataset(dataset);
          trackEvent('Selected dataset', {
            dataset_id: dataset.id,
            dataset_name: tableName,
          });
        }}
        onEditClicked={() => this.setState({ editDatasetModalOpen: true, editDataset: dataset })}
        onDeleteClicked={() =>
          this.setState({ deleteDatasetModalOpen: true, deleteDataset: dataset })
        }
        name={tableName}
        active={selectedDatasetId === dataset.id}
        draft={query != null && query !== datasets[dataset.id].query}
        error={dataset._error != null}
        new={dataset._is_new}
        used={dataset.id === selectedDataPanelSourceDatasetId}
        loading={actionLoadingDatasetId === dataset.id}
      />
    );
  };

  renderCreateDatasetModal = () => {
    const { createDashboardDatasetV2, dashboardTemplateId, parentSchemas } = this.props;
    const { createDatasetModalOpen: createDatasetNameModalOpen } = this.state;
    if (!createDatasetNameModalOpen) return;

    return (
      <DashboardDatasetModal
        title="Create Dataset"
        buttonTitle="Create"
        modalOpen={createDatasetNameModalOpen}
        onClose={() => this.setState({ createDatasetModalOpen: false })}
        onSubmit={(table_name: string, parent_schema_id: number) => {
          createDashboardDatasetV2({
            name: table_name,
            dashId: dashboardTemplateId,
            parentSchemaId: parent_schema_id,
          });
          trackEvent('Created new dataset', {});
        }}
        parentSchemas={parentSchemas}
        defaultParentSchema={parentSchemas.length === 1 ? parentSchemas[0] : undefined}
        errorState={this.errorWithDatasetNameEntry}
      />
    );
  };

  errorWithDatasetNameEntry = (newVal?: string) => {
    const { datasets } = this.props;
    const { editDataset } = this.state;
    const currentDatasetNames = Object.values(datasets).map((dataset) => dataset.table_name);

    if (newVal && currentDatasetNames.includes(newVal) && editDataset?.table_name !== newVal) {
      return {
        isErrorState: true,
        errorMsg:
          'There is already a dataset with this name. Please choose another name for the dataset.',
      };
    }
    return { isErrorState: false };
  };

  renderEditDatasetModal = () => {
    const { editDashboardDatasetName, parentSchemas } = this.props;
    const { editDatasetModalOpen, editDataset } = this.state;
    if (!editDatasetModalOpen || !editDataset) return;
    const schemasById = _.indexBy(parentSchemas || [], 'id');

    return (
      <DashboardDatasetModal
        title="Edit Dataset"
        buttonTitle="Save"
        datasetName={editDataset.table_name}
        modalOpen={editDatasetModalOpen}
        onClose={() => this.setState({ editDatasetModalOpen: false })}
        onSubmit={(name: string, parent_schema_id: number) => {
          this.setState({
            actionLoadingDatasetId: editDataset.id,
          });

          editDashboardDatasetName({
            datasetId: editDataset.id,
            name,
          });
          this.setState({
            actionLoadingDatasetId: undefined,
          });
          trackEvent('Edited dataset name', {
            dataset_id: editDataset.id,
            dataset_name: name,
            parentSchemaId: parent_schema_id,
          });
        }}
        parentSchemas={parentSchemas}
        defaultParentSchema={schemasById[editDataset.parent_schema_id]}
        errorState={this.errorWithDatasetNameEntry}
      />
    );
  };

  renderDeleteDatasetModal = () => {
    const { deleteDashboardDatasetV2, datasets } = this.props;
    const { deleteDatasetModalOpen, deleteDataset } = this.state;

    if (!deleteDatasetModalOpen || !deleteDataset) return;

    return (
      <ConfirmationModal
        isDestructive
        modalOpen={deleteDatasetModalOpen}
        closeModal={() => this.setState({ deleteDatasetModalOpen: false })}
        modalTitle="Are you sure you want to delete this dataset?"
        cancelBtnText="Cancel"
        confirmBtnText={`Delete ${deleteDataset.table_name}`}
        onConfirm={() => {
          this.setState({
            actionLoadingDatasetId: deleteDataset.id,
            deleteDatasetModalOpen: false,
          });
          deleteDashboardDatasetV2({ datasetId: deleteDataset.id });

          this.setState({
            actionLoadingDatasetId: undefined,
            deleteDataset: undefined,
          });

          const newSelectedDataset = _.first(_.sortBy(Object.values(datasets), 'table_name'));
          if (newSelectedDataset) this.switchSelectedDataset(newSelectedDataset);
          trackEvent('Deleted dataset', {
            dataset_id: deleteDataset.id,
          });
        }}
      />
    );
  };

  renderDatasetEditor = () => {
    const { classes, datasets, pageWidth } = this.props;
    const { selectedDatasetId } = this.state;

    if (!selectedDatasetId || !datasets[selectedDatasetId]) {
      return;
    }

    return (
      <div
        className={
          pageWidth && datasetEditorShowsDatasetsAsList(pageWidth)
            ? classes.editor
            : classes.fullWidthEditor
        }>
        {pageWidth &&
          datasetEditorShowsDatasetsAsDropdown(pageWidth) &&
          this.renderSelectDatasetTopBar()}
        {this.renderDatasetEditorMenu()}
        <div className={classes.editorContainer}>
          <div className={classes.datasetEditor}>{this.renderDatasetEditorBody()}</div>
        </div>
      </div>
    );
  };

  renderDatasetEditorMenu = () => {
    const { classes, datasets, isOpen, pageWidth } = this.props;
    const { selectedDatasetId } = this.state;
    const datasetQuery = this.currentQuery();

    if (!selectedDatasetId || !datasets[selectedDatasetId]) {
      return;
    }

    const dataset = datasets[selectedDatasetId];

    return (
      <div className={classes.editorTopBar}>
        {this.renderToggleEditorViewActions()}
        <div className={classes.editorTopBarDatasetActions}>
          <Button
            type="secondary"
            className={classes.editorBtn}
            disabled={!isOpen || !datasetQuery || datasetQuery.length === 0}
            onClick={this.getTablePreview}
            loading={false}
            text="Preview"
          />
          <Button
            type="primary"
            className={classes.editorBtn}
            disabled={!isOpen || datasetQuery === dataset.query || false}
            onClick={this.saveDashboardDataset}
            loading={false}
            icon="play"
            text="Save"
          />
          {this.renderSqlFormatter()}
          {pageWidth && datasetEditorShowsDatasetsAsList(pageWidth) && this.renderCollapseButton()}
        </div>
      </div>
    );
  };

  getTablePreview = () => {
    const {
      fetchEditorDatasetPreview,
      fetchEditorDatasetRowCount,
      dashboardVars,
      selectedUserGroupId,
    } = this.props;
    const datasetQuery = this.currentQuery();

    const dataset = this.getSelectedDataset();
    if (!dataset) return;

    const postData = {
      query: datasetQuery,
      dataset: dataset,
      variables: {
        ...dashboardVars,
      },
      end_user_group_id: selectedUserGroupId,
    };

    this.setState({ datasetEditorView: DATASET_VIEWS.PREVIEW.id });
    fetchEditorDatasetPreview({ postData }, () => {
      fetchEditorDatasetRowCount({ postData: { ...postData, rowCount: true } });
    });
    this.setState({ currentPreviewPage: 1, pageNavVal: 1, pageNavValid: false });
    trackEvent('Ran code', {
      dataset_id: dataset.id,
      dataset_query: datasetQuery,
    });
  };

  saveDashboardDataset = () => {
    const {
      saveDashboardDatasetQuery,
      fetchEditorDatasetPreview,
      fetchEditorDatasetRowCount,
      dashboardVars,
      selectedUserGroupId,
    } = this.props;
    const datasetQuery = this.currentQuery();

    const dataset = this.getSelectedDataset();
    if (!dataset) return;

    const postData = {
      query: datasetQuery,
      dataset: dataset,
      end_user_group_id: selectedUserGroupId,
      variables: {
        ...dashboardVars,
      },
    };

    this.setState({ datasetEditorView: DATASET_VIEWS.PREVIEW.id });
    fetchEditorDatasetPreview({ postData }, (data) => {
      saveDashboardDatasetQuery({ ...postData, schema: data.dataset_preview.schema });

      fetchEditorDatasetRowCount({ postData: { ...postData, rowCount: true } });
      const potentialDuplicates = checkDashboardDatasetDataForDuplicates(data);
      if (potentialDuplicates.length !== 0) {
        AppToaster.show({
          message: (
            <div>
              The saved query joins tables with duplicate column names (
              {potentialDuplicates.join(', ')}) and may not work properly with the dashboard.{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.explo.co/troubleshooting/common-errors/duplicate-ambiguous-column-names">
                Click here for more info
              </a>
            </div>
          ),
          icon: 'warning-sign',
          timeout: 10000,
          intent: Intent.WARNING,
        });
      }
    });

    trackEvent('Saved query', {
      dataset_id: dataset.id,
      dataset_query: datasetQuery,
    });
  };

  getSelectedDataset = () => {
    const { datasets } = this.props;
    const { selectedDatasetId } = this.state;

    if (!selectedDatasetId || !datasets[selectedDatasetId]) {
      return;
    }
    return datasets[selectedDatasetId];
  };

  renderSqlFormatter = () => {
    const { classes, isOpen } = this.props;

    return (
      <Tooltip
        content={
          <>
            Format SQL (<Icon icon="key-command" />
            <Icon icon="key-shift" />
            F)
          </>
        }
        position={Position.TOP}>
        <Button
          minimal
          icon="wrench"
          disabled={!isOpen}
          className={classes.actionBtn}
          onClick={this.formatSqlQuery}
        />
      </Tooltip>
    );
  };

  formatSqlQuery = () => {
    const { datasetQueries, selectedDatasetId } = this.state;
    const datasetQuery = this.currentQuery();
    if (!selectedDatasetId || !datasetQuery) return;

    this.setState({
      datasetQueries: {
        ...datasetQueries,
        [selectedDatasetId]: sqlFormatter.format(datasetQuery || '', {
          indent: '    ',
        }),
      },
    });
  };

  renderToggleEditorViewActions = () => {
    const { classes, isOpen } = this.props;
    const { datasetEditorView } = this.state;
    if (!isOpen) return <div></div>;

    return (
      <NavTabs
        className={classes.editorDatasetViewTabs}
        tabClassName={classes.editorDatasetViewTab}
        tabs={[DATASET_VIEWS.QUERY, DATASET_VIEWS.PREVIEW]}
        selectedTabId={datasetEditorView}
        onTabSelect={(tabId) => this.setState({ datasetEditorView: tabId })}
      />
    );
  };

  renderDatasetEditorBody = () => {
    const { datasetEditorView } = this.state;

    switch (datasetEditorView) {
      case DATASET_VIEWS.QUERY.id:
        return this.renderDatasetQueryEditor();
      case DATASET_VIEWS.PREVIEW.id:
        return this.renderDatasetPreviewBody();
    }
  };

  renderDatasetQueryEditor = () => {
    const { schemaTablesMap, datasets } = this.props;
    const { datasetQueries, selectedDatasetId } = this.state;
    const datasetQuery = this.currentQuery();
    const activeSchema = selectedDatasetId
      ? schemaTablesMap[datasets[selectedDatasetId].parent_schema_id]
      : {};

    const columnNames: string[] = [];
    Object.values(activeSchema).forEach((table) => {
      if (table.schema) {
        table.schema.forEach((column) => {
          columnNames.push(table.table_name + '.' + column.name);
        });
      }
    });

    return (
      <SQLEditor
        query={datasetQuery || ''}
        onChange={(newQuery) => {
          if (!selectedDatasetId) return;
          this.setState({
            datasetQueries: {
              ...datasetQueries,
              [selectedDatasetId]: newQuery,
            },
          });
        }}
        tableNames={_.pluck(Object.values(activeSchema), 'table_name')}
        columnNames={_.uniq(columnNames)}
      />
    );
  };

  renderDatasetPreviewBody = () => {
    return (
      <>
        {this.renderDatasetPreview()}
        {this.renderDatasetPreviewControls()}
      </>
    );
  };

  renderDatasetPreview = () => {
    const { datasets } = this.props;
    const { selectedDatasetId } = this.state;
    const datasetQuery = this.currentQuery();

    if (!selectedDatasetId || !datasets[selectedDatasetId]) {
      return this.renderDatasetLoadingTable();
    }

    const selectedDataset = datasets[selectedDatasetId];

    if (selectedDataset._error) {
      return this.renderDatasetTableError(datasets[selectedDatasetId]);
    }

    if (!selectedDataset.query && !datasetQuery) {
      return this.renderDatasetNoQuery();
    }
    if (!selectedDataset._rows) {
      if (selectedDataset._loading) {
        return this.renderDatasetLoadingTable();
      } else {
        // they have not run the preview for the query
        return this.renderRunPreview();
      }
    }

    return this.renderDatasetPreviewTable(selectedDataset);
  };

  renderDatasetLoadingTable = () => {
    const { classes } = this.props;
    return <LoadingDataTable className={classes.datasetPreviewTable} maxRows={50} />;
  };

  renderDatasetTableError = (dataset: Dataset) => {
    const { classes } = this.props;
    return (
      <div className={cx(classes.datasetPreviewTable, classes.datasetError)}>
        <NonIdealState
          title="There was a problem with your query"
          icon={<Icon icon="error" intent={Intent.DANGER} iconSize={50} />}
          description={dataset._error}
        />
      </div>
    );
  };

  renderDatasetNoQuery = () => {
    const { classes } = this.props;
    return (
      <div className={cx(classes.datasetPreviewTable, classes.datasetError)}>
        <NonIdealState
          title="Write a query to get started"
          icon="console"
          description="The resulting table will show up here once you write some SQL"
        />
      </div>
    );
  };

  renderRunPreview = () => {
    const { classes } = this.props;
    return (
      <div className={cx(classes.datasetPreviewTable, classes.datasetError)}>
        <NonIdealState
          title="Click the preview button"
          icon="eye-open"
          description="This will run your query but not save it to the dataset. To save, press the save button."
        />
      </div>
    );
  };

  renderDatasetPreviewTable = (dataset: Dataset) => {
    if (!dataset._rows) return;

    const { classes } = this.props;
    return (
      <BaseDataTable
        className={classes.datasetPreviewTable}
        schema={dataset._schema || dataset.schema || []}
        rows={dataset._rows}
        maxRows={50}
        isSortable={false}
        enableColumnResizing
        shouldTruncateText={true}
      />
    );
  };

  renderDatasetPreviewControls = () => {
    const {
      classes,
      datasets,
      fetchEditorDatasetPreview,
      fetchEditorDatasetRowCount,
      dashboardVars,
      selectedUserGroupId,
    } = this.props;
    const { selectedDatasetId, currentPreviewPage, pageNavVal, pageNavValid } = this.state;
    const datasetQuery = this.currentQuery();

    if (!selectedDatasetId || !datasets[selectedDatasetId]) {
      return;
    }
    const dataset = datasets[selectedDatasetId];
    const totalRowCount = dataset._total_row_count;

    return (
      <div className={classes.previewDatasetControls}>
        <TablePageNavigation
          loading={totalRowCount === undefined}
          currentPageNumber={currentPreviewPage}
          changePage={(pageNumber: number) => {
            const postData = {
              query: datasetQuery,
              dataset: dataset,
              variables: {
                ...dashboardVars,
              },
              end_user_group_id: selectedUserGroupId,
            };

            fetchEditorDatasetPreview(
              {
                postData: { ...postData, offset: (pageNumber - 1) * 50 },
              },
              () => {
                fetchEditorDatasetRowCount({
                  postData: { ...postData, rowCount: true },
                });
              },
            );
            this.setState({ currentPreviewPage: pageNumber, pageNavVal: pageNumber });
          }}
          pageNumberInputVal={pageNavVal}
          maxPageNumber={totalRowCount ? Math.ceil(totalRowCount / 50) : 1}
          pageNumberInputIsInvalid={pageNavValid}
          changePageNumberInputVal={(pageNumber: number) =>
            this.setState({ pageNavVal: pageNumber })
          }
          changePageNumberisInvalid={(val: boolean) => this.setState({ pageNavValid: val })}
        />
        <PageNumberIndicator
          className={classes.pageNumberIndicator}
          currentPage={currentPreviewPage}
          rowsPerPage={50}
          totalRows={totalRowCount || 0}
        />
      </div>
    );
  };

  getDatasetName = (dataset: Dataset) => {
    return !dataset.table_name || dataset.table_name.length === 0 ? 'Untitled' : dataset.table_name;
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createDashboardDatasetV2,
  editDashboardDatasetName,
  deleteDashboardDatasetV2,
  fetchEditorDatasetPreview,
  fetchEditorDatasetRowCount,
  saveDashboardDatasetQuery,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardDatasetEditor)),
);
