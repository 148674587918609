/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';
import { IconName } from '@blueprintjs/core';

import Button from 'shared/Button';
import FlexBox, { VerticalAlignment, HorizontalAlignment } from 'components/core/FlexBox';

export const SETTINGS_HEADER_HEIGHT = 33;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: SETTINGS_HEADER_HEIGHT,
    backgroundColor: theme.palette.ds.grey200,
    fontWeight: 600,
    fontSize: 12,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    borderBottom: `1px solid ${theme.palette.ds.grey200}`,
    borderTop: `1px solid ${theme.palette.ds.grey200}`,

    '&.noTopBorder': {
      borderTop: 'none',
    },
  },
}));

export type Props = {
  className?: string;
  name: string;
  noTopBorder?: boolean;
  actionBtnConfig?: {
    actionBtnIcon: IconName | JSX.Element;
    onActionBtnClicked?: () => void;
  };
  rightContent?: JSX.Element;
};

export default function SettingHeader({
  className,
  name,
  noTopBorder,
  actionBtnConfig,
  rightContent,
}: Props) {
  const classes = useStyles();

  return (
    <FlexBox
      verticalAlignment={VerticalAlignment.CENTER}
      horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}
      className={cx(classes.root, className, { noTopBorder })}>
      {name}
      {actionBtnConfig && (
        <Button
          minimal
          compact
          icon={actionBtnConfig.actionBtnIcon}
          onClick={() => actionBtnConfig.onActionBtnClicked?.()}
        />
      )}
      {rightContent}
    </FlexBox>
  );
}
