/** @format */

import React from 'react';
import produce from 'immer';
import _ from 'underscore';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';

import ToggleSwitchInput from 'shared/ToggleSwitchInput';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, VisualizeTableInstructions } from 'constants/types';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import SettingHeader from '../../DataConfigTab/SettingHeader';
import { TABLE_ROW_HEIGHT } from 'components/dataTable/baseDataTable';

const MIN_ROW_HEIGHT = 30;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
  subHeader: {
    padding: `0px ${theme.spacing(3)}px`,
    color: theme.palette.ds.grey900,
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  subSection: {
    marginBottom: theme.spacing(5),
  },
}));

export type Props = {
  instructions: VisualizeTableInstructions;
  visualizationType: string;
};

export default function TableStylingConfig({ instructions, visualizationType }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <SettingHeader name="Styling" />
      <div className={classes.root}>
        <div className={classes.subHeader}>Columns</div>
        <div className={classes.subSection}>
          <ToggleSwitchInput
            className={classes.configInput}
            switchOn={instructions.isColumnLinesEnabled}
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isColumnLinesEnabled = !draft.isColumnLinesEnabled;
              });

              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            label="Column lines"
          />
          <ToggleSwitchInput
            className={classes.configInput}
            switchOn={instructions.isColumnHeadersBolded}
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isColumnHeadersBolded = !draft.isColumnHeadersBolded;
              });

              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            label="Bold column headers"
          />
          <ToggleSwitchInput
            className={classes.configInput}
            switchOn={instructions.isFirstColumnBolded}
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isFirstColumnBolded = !draft.isFirstColumnBolded;
              });

              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            label="Bold first column"
          />
        </div>

        <div className={classes.subHeader}>Rows</div>
        <div className={classes.subSection}>
          <ToggleSwitchInput
            className={classes.configInput}
            switchOn={!instructions.isRowLinesDisabled}
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isRowLinesDisabled = !draft.isRowLinesDisabled;
              });

              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            label="Row lines"
          />

          <InputWithBlurSave
            containerClassName={classes.configInput}
            label="Row Height"
            initialValue={String(instructions.rowHeight || TABLE_ROW_HEIGHT)}
            onNewValueSubmitted={(newHeightString: string) => {
              const newHeight = parseInt(newHeightString);

              if (newHeight < MIN_ROW_HEIGHT || _.isNaN(newHeight)) return;

              const newInstructions = produce(instructions, (draft) => {
                draft.rowHeight = newHeight;
              });

              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            helpText="The minimum row height is 30. If cell text is wrapped, this will only impact the header row."
            placeholder="Enter a row height"
          />
        </div>

        <div className={classes.subHeader}>Cells</div>
        <div className={classes.subSection}>
          <ToggleSwitchInput
            className={classes.configInput}
            switchOn={!instructions.shouldTruncateText}
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.shouldTruncateText = !draft.shouldTruncateText;
              });

              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            label="Wrap text"
          />
        </div>
      </div>
    </>
  );
}
