/** @format */

import { FetchDashboardDatasetPreviewData } from 'actions/responseTypes';
import React from 'react';

export const checkDashboardDatasetDataForDuplicates = (
  datasetPreview: FetchDashboardDatasetPreviewData,
) => {
  const potentialDuplicates: string[] = [];
  const columns = datasetPreview.dataset_preview.schema;

  // matches any string that ends with a number
  const regex = /([A-z]*)\d+$/;

  columns.forEach((column) => {
    const match = column.name.match(regex);
    if (match && columns.some((c) => c.name === match[1])) {
      potentialDuplicates.push(match[1]);
    }
  });

  return potentialDuplicates;
};

const INVALID_DATE_TIME_PARSE_INFO = (
  <div>
    The query generated by this panel is attempted to do time operations on a date field. Please
    ensure that the field you are operating on has a time part before doing hour, minute, or second
    operations
    <br />
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://docs.explo.co/troubleshooting/common-errors/error-parsing-date-time">
      Click here for more info.
    </a>
  </div>
);

const AMBIGUOUS_COLUMN_REFERENCE = (
  <div>
    The query generated by this panel references an ambiguous column name. This is likely because
    the dataset used by this panel contains columns with duplicate names.
    <br />
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://docs.explo.co/troubleshooting/common-errors/duplicate-ambiguous-column-names">
      Click here for more info.
    </a>
  </div>
);

const TABLE_DOES_NOT_EXIST = (
  <div>
    The data source you are viewing is incompatible with its schema. This is likely because you have
    added a data source to a schema, but the data source does not contain all tables defined by the
    default data source of the schema.
    <br />
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://docs.explo.co/troubleshooting/common-errors/table-does-not-exist">
      Click here for more info.
    </a>
  </div>
);

const errorRegexes = [
  {
    regx: /column reference.*is ambiguous/i,
    description: AMBIGUOUS_COLUMN_REFERENCE,
  },
  {
    regx: /invalid value.*for.*date\/time/i,
    description: INVALID_DATE_TIME_PARSE_INFO,
  },
  {
    regx: /datepart.*not supported/i,
    description: INVALID_DATE_TIME_PARSE_INFO,
  },
  // Postgres
  {
    regx: /relation.*does not exist/i,
    description: TABLE_DOES_NOT_EXIST,
  },
  // MySql
  {
    regx: /table.*doesn't exist/i,
    description: TABLE_DOES_NOT_EXIST,
  },
  // BigQuery
  {
    regx: /table name.*missing dataset/i,
    description: TABLE_DOES_NOT_EXIST,
  },
  // Rockset
  {
    regx: /collection.*does not exist/i,
    description: TABLE_DOES_NOT_EXIST,
  },
];

export const parseErrorMessage = (errorMessage: string | undefined) =>
  errorRegexes.find((er) => errorMessage?.match(er.regx))?.description;
