/** @format */

import { Intent } from '@blueprintjs/core';
import { ErrorResponse } from 'actions/responseTypes';
import { AppToaster } from 'toaster';

export const showErrorContactSupportToast = (response: ErrorResponse) => {
  AppToaster.show({
    message: response.detail
      ? response.detail
      : 'Something went wrong. Please try again or contact support@explo.co if the error continues.',
    icon: 'error',
    timeout: 3000,
    intent: Intent.DANGER,
  });
};
