/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';

import { pageView, trackEvent } from 'analytics/exploAnalytics';

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
  },
});

type MatchParams = {
  pageName: string;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

class NotionAnalyticsPage extends React.Component<Props> {
  componentDidMount() {
    const userId = this.props.currentUser.id ? this.props.currentUser.id : null;
    const email = this.props.currentUser.email ? this.props.currentUser.email : null;

    pageView(`${this.props.match.params.pageName}`);

    if (userId && email) {
      trackEvent('Viewed notion page', {
        user_id: userId,
        email: email,
        notion_page: this.props.match.params.pageName,
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <br></br>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotionAnalyticsPage)),
);
