/** @format */

import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Intent } from '@blueprintjs/core';
import { Prompt } from 'react-router';

import { NAVBAR_HEIGHT } from 'components/pages/navbar';
import { ReduxState } from 'reducers/rootReducer';
import { AppToaster } from 'toaster';
import {
  updateGlobalStyles,
  saveGlobalStyles,
  resetGlobalStyles,
  fetchGoogleFonts,
} from 'actions/styleConfigActions';
import CustomizeStyleConfigPane from './CustomizeStyleConfigPane';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': unknown;
    }
  }
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
    width: '100%',
  },
  content: {
    flex: 1,
    overflowY: 'scroll',
    height: '100%',
  },
}));

export function CustomizeStylesPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [allChangesSaved, setAllChangesSaved] = useState<boolean>(true);
  const { globalStyleConfig, googleFonts } = useSelector((state: ReduxState) => ({
    globalStyleConfig: state.styleConfig.editing,
    googleFonts: state.styleConfig.googleFonts,
  }));

  useEffect(() => {
    dispatch(fetchGoogleFonts());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  });

  const alertUser = (event: BeforeUnloadEvent) => {
    if (allChangesSaved) return;
    event.preventDefault();
    event.returnValue = '';
  };

  const stylesToApply = cloneDeep(globalStyleConfig);
  stylesToApply.base.numColumns = 12;

  return (
    <div className={classes.root}>
      <Prompt
        when={!allChangesSaved}
        message="You have unsaved changes to the styles. If you leave this page your changes will be lost."
      />
      <CustomizeStyleConfigPane
        styleConfig={globalStyleConfig}
        updateConfig={(newConfig) => {
          dispatch(updateGlobalStyles({ newConfig }));
          setAllChangesSaved(false);
        }}
        saveConfig={() => {
          dispatch(
            saveGlobalStyles({ postData: { config_v2: globalStyleConfig } }, () => {
              AppToaster.show({
                message: 'Styles saved successfully',
                icon: 'tick',
                timeout: 3000,
                intent: Intent.SUCCESS,
              });
              setAllChangesSaved(true);
            }),
          );
        }}
        resetConfig={() => {
          dispatch(resetGlobalStyles());
        }}
        googleFonts={googleFonts}
      />
      <div key={JSON.stringify(globalStyleConfig)} className={classes.content}>
        {/* Embedding the exhaustive dashboard */}
        <explo-dashboard
          dashboardEmbedId="aovARZVAlV"
          userGroupToken="8898b421f165453944df5e1bb7a792ee5f6c09ce1251a6afbfd10e608e4a4fe5"
          updateUrlParams={true}
          isProduction={false}
          environment="development"
          custom-styles={JSON.stringify(stylesToApply)}
        />
      </div>
    </div>
  );
}
