/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';
// @ts-ignore
import FunnelGraph from 'funnel-graph-js';

import ResizeObserver from 'react-resize-observer';

import { HEADER_HEIGHT } from 'pages/dashboardPage/DashboardDatasetView/DashboardDatasetView';

import 'funnel-graph-js/dist/css/theme.min.css';
import 'funnel-graph-js/dist/css/main.min.css';

const styles = (theme: Theme) =>
  createStyles({
    chartContainer: {
      height: `calc(100% - ${HEADER_HEIGHT}px)`,
      width: '100%',
      padding: theme.spacing(3),
      overflow: 'hidden',
    },
    root: {
      width: '100%',
      height: '100%',

      '& .svg-funnel-js__label': {
        width: 0,
        borderColor: `${theme.palette.ds.grey200} !important`,
        padding: `0 ${theme.spacing(2)}px !important`,
      },

      '& .label__title': {
        color: `inherit !important`,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& .label__value': {
        color: `inherit !important`,
      },
      '& .label__percentage': {
        fontSize: '12px !important',
        color: `inherit !important`,
      },
    },
  });

type PassedProps = {
  dataPanelTemplateId: string;
  textColor: string;
  labels: (string | number)[];
  values: number[];
  colors: string[];
};

type State = {};

type Props = PassedProps & WithStyles<typeof styles>;

class FunnelChart extends React.Component<Props, State> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chart: any;
  componentDidMount() {
    this.chart = new FunnelGraph(this.getChartOptions());
    this.chart.draw();
  }

  componentDidUpdate(oldProps: Props) {
    const { labels, values, colors } = this.props;

    if (
      !_.isEqual(labels, oldProps.labels) ||
      !_.isEqual(values, oldProps.values) ||
      !_.isEqual(colors, oldProps.colors)
    ) {
      this.chart && this.chart.update(this.getChartOptions());
    }
  }

  stringLabels = () => {
    const { labels } = this.props;

    return _.map(labels, String);
  };

  getChartOptions = () => {
    const { values, colors, dataPanelTemplateId } = this.props;
    return {
      container: `.explo-funnel-${dataPanelTemplateId}`,
      gradientDirection: 'horizontal',
      data: {
        labels: this.stringLabels(),
        colors: colors,
        values: values,
      },
      displayPercent: true,
      direction: 'horizontal',
    };
  };

  render() {
    const { classes, textColor, dataPanelTemplateId } = this.props;
    return (
      <div className={classes.chartContainer}>
        <div
          className={cx(classes.root, `explo-funnel-${dataPanelTemplateId}`)}
          style={{ color: textColor }}
        />

        <ResizeObserver
          onResize={() => this.chart && this.chart.updateHeight() && this.chart.updateWidth()}
        />
      </div>
    );
  }
}

export default withStyles(styles)(FunnelChart);
