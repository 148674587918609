/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 48,
    padding: `0 ${theme.spacing(4)}px`,
    borderRadius: 4,
    backgroundColor: theme.palette.ds.white,
    boxShadow: '-2px -2px 4px #F5F5F5, 0px 4px 4px rgba(0, 0, 0, 0.15)',
    color: '#333333',
    border: 'none',
    fontSize: 14,

    '&::placeholder': {
      color: '#828282',
    },
  },
}));

type Props = {
  className?: string;
  placeholder: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  disabled?: boolean;
};

const InputField = (props: Props) => {
  const classes = useStyles();
  return (
    <input
      className={cx(props.className, classes.root)}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      type={props.type}
      disabled={props.disabled}
    />
  );
};

export default InputField;
