/** @format */

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { DashboardTemplate } from 'actions/types';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';
import { DashboardElementConfig, ExportElemConfig } from 'types/dashboardTypes';

const styles = (theme: Theme) => ({
  root: {},
  configInput: {
    marginTop: theme.spacing(3),
  },
  infoCard: {
    backgroundColor: theme.palette.ds.lightBlue,
    borderRadius: 4,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '100%',
    height: '100%',
  },
  infoCardText: {
    fontSize: 12,
    color: theme.palette.ds.blue,
  },
});

type PassedProps = {
  config: ExportElemConfig;
  dashboard: DashboardTemplate;
  updateConfig: (newConfig: DashboardElementConfig) => void;
  saveShareLinkTitle: (newValue: string) => void;
};

type Props = RouteComponentProps & WithStyles<typeof styles> & PassedProps;

type State = {};

class ExportElementConfigPanel extends React.Component<Props, State> {
  state: State = {};

  render() {
    const { classes } = this.props;

    return <div className={classes.root}>{this.renderLabelInput()}</div>;
  }

  renderLabelInput = () => {
    const { config, classes, dashboard, saveShareLinkTitle, updateConfig } = this.props;
    return (
      <>
        <InputWithBlurSave
          containerClassName={classes.configInput}
          initialValue={config.label}
          onNewValueSubmitted={(newValue: string) => {
            config.label = newValue;
            updateConfig(config);
          }}
          label="Button Label"
        />
        <InputWithBlurSave
          containerClassName={classes.configInput}
          initialValue={dashboard.share_link_title}
          onNewValueSubmitted={(newValue) => saveShareLinkTitle(newValue)}
          label="Share Page Title"
        />
        <div className={classes.infoCard}>
          <div className={classes.infoCardText}>
            This page title will apply to any share page linked from any share button on this
            dashboard.
          </div>
        </div>
        <SwitchInput
          className={classes.configInput}
          switchOn={config?.passwordEnabled}
          onChange={() => {
            config.passwordEnabled = !config.passwordEnabled;
            updateConfig(config);
          }}
          label="Force Password Protection"
        />
      </>
    );
  };
}

export default withRouter(withStyles(styles)(ExportElementConfigPanel));
