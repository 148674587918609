/** @format */
import React, { useState } from 'react';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import _ from 'underscore';

import DropdownSelect from 'shared/DropdownSelect/DropdownSelect';
import { SUPPORTED_LOCALES } from 'utils/localizationUtils';
import { SelectedDropdownInputItem, SupportedLocale } from 'constants/types';

type PassedProps = {
  label: string;
  localeCode?: string;
  onLanguageSelect: (item: SelectedDropdownInputItem) => void;
  containerClassName?: string;
};

type Props = PassedProps & ReturnType<typeof mapStateToProps>;

const localeCodeToSupportedLanguage = _.indexBy(SUPPORTED_LOCALES, (locale) => locale.localeCode);
const languageOptions = SUPPORTED_LOCALES.map((locale) => {
  return {
    name: locale.name,
    id: locale.localeCode,
  };
});

const getSupportedLanguageFromLocaleCode = (localeCode?: string) =>
  localeCode ? localeCodeToSupportedLanguage[localeCode] : undefined;

function SettingsLanguageDropdown(props: Props) {
  const { label, localeCode, onLanguageSelect, containerClassName } = props;

  const [language, setLanguage] = useState<SupportedLocale | undefined>(
    getSupportedLanguageFromLocaleCode(localeCode),
  );
  React.useEffect(() => {
    setLanguage(getSupportedLanguageFromLocaleCode(localeCode));
  }, [localeCode]);

  return (
    <div className={containerClassName}>
      <DropdownSelect
        fillWidth
        minimal
        filterable={false}
        label={label}
        noSelectionText={'Choose a language'}
        onChange={onLanguageSelect}
        options={languageOptions}
        selectedItem={
          language
            ? {
                name: language.name,
                id: language.localeCode,
              }
            : undefined
        }></DropdownSelect>
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  team: state.teamData.data,
});

export default connect(mapStateToProps)(SettingsLanguageDropdown);
