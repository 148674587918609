/** @format */

import React from 'react';
import _ from 'underscore';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { getColDisplayText } from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/vizConfigs/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2TwoDimensionChartInstructions;
  firstColOnly?: boolean;
};

export default function BarLineDataLabelsConfig({
  visualizationType,
  instructions,
  firstColOnly,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  let aggCols = instructions.aggColumns;
  if (firstColOnly) {
    aggCols = aggCols?.slice(0, 1);
  }

  return (
    <div className={classes.root}>
      {aggCols?.map((col) => (
        <InputWithBlurSave
          key={`bar-line-labels-${col.column.name}`}
          containerClassName={classes.configInput}
          initialValue={col.column.friendly_name || getColDisplayText(col)}
          onNewValueSubmitted={(newValue: string) => {
            const newInstructions = cloneDeep(instructions);

            const changedCol = _.find(
              newInstructions.aggColumns || [],
              (newCol) => newCol.column.name === col.column.name,
            );

            if (changedCol) changedCol.column.friendly_name = newValue;

            dispatch(
              updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
            );
          }}
          label={getColDisplayText(col)}
        />
      ))}
    </div>
  );
}
