/** @format */
import React, { useState, useContext } from 'react';
import _ from 'underscore';
import { Menu, MenuItem } from '@blueprintjs/core';
import { ItemListRenderer, ItemPredicate, ItemRenderer, MultiSelect } from '@blueprintjs/select';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import cx from 'classnames';

import { SelectedDropdownInputItem } from 'constants/types';
import InputLabel from 'shared/InputLabel';
import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';

const useStyles = makeStyles((theme: Theme) => ({
  fullWidthRoot: {
    width: '100%',
    position: 'relative',

    '& .bp3-popover-target': {
      width: '100%',
    },

    '& .bp3-transition-container': {
      minWidth: '100%',
      transform: 'translate3d(0px, 27px, 0px) !important',

      '& .bp3-popover': {
        width: '100%',
        boxShadow: 'none',
        border: `1px solid ${theme.palette.ds.grey300}`,
      },
    },
  },
  menuList: {
    maxHeight: 200,
    overflow: 'auto',
  },
  dropdownMenuItem: (styleConfig: GlobalStyleConfig) => ({
    '&.bp3-menu-item': {
      display: 'flex',
      alignItems: 'center',
      '&.bp3-active': {
        backgroundColor: styleConfig.base.actionColor.default || theme.palette.ds.blue,
      },
    },
  }),
  input: {
    height: 30,
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.ds.grey500,
      borderRadius: 5,
    },
  },
}));

const ExploMultiSelect = MultiSelect.ofType<SelectedDropdownInputItem>();

type Props = {
  className?: string;
  containerClassName?: string;
  selectedItems?: SelectedDropdownInputItem[];
  onItemSelected: (item: SelectedDropdownInputItem) => void;
  unselectIndex: (index: number) => void;
  options: SelectedDropdownInputItem[];
  noSelectionText: string;
  filterable?: boolean;
  disabled?: boolean;
  minimal?: boolean;
  fillWidth?: boolean;
  label?: string;
  useFakeLabel?: boolean;
  onCancelClick?: () => void;
  placeholder?: string;
  disableOnNoItems?: boolean;
};

const MultiSelectElem = (props: Props) => {
  const [query, setQuery] = useState('');
  const context = useContext(GlobalStylesContext);
  const classes = useStyles(context.globalStyleConfig);

  const simpleFilterPredicate: ItemPredicate<SelectedDropdownInputItem> = (query, item) => {
    return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  const isItemSelected = (item: SelectedDropdownInputItem) => {
    return (
      props.selectedItems &&
      _.findIndex(props.selectedItems, (selectedItem) => selectedItem.id === item.id) > -1
    );
  };

  const itemRenderer: ItemRenderer<SelectedDropdownInputItem> = (
    item: SelectedDropdownInputItem,
    { handleClick, modifiers, query },
  ) => {
    let itemText;
    if (query.length > 0) {
      const text = item.name.split(query);
      itemText = [];
      for (let i = 0; i <= text.length - 2; i++) {
        itemText.push(text[i]);
        itemText.push(<b key={_.uniqueId('query_text')}>{query}</b>);
      }
      itemText.push(text[text.length - 1]);
    } else {
      itemText = item.name;
    }

    return (
      <MenuItem
        className={cx(
          classes.dropdownMenuItem,
          GLOBAL_STYLE_CLASSNAMES.text.body.dropdownMenuItem,
          GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownInputMenuItem,
        )}
        active={modifiers.active}
        icon={isItemSelected(item) ? 'tick' : 'blank'}
        text={itemText}
        key={_.uniqueId(`${item.id}_`)}
        onClick={handleClick}
      />
    );
  };

  const itemListRenderer: ItemListRenderer<SelectedDropdownInputItem> = ({
    filteredItems,
    items,
    query,
    renderItem,
    itemsParentRef,
  }) => {
    let itemsList;
    if (filteredItems.length === 0) {
      itemsList = <MenuItem disabled={true} text="No results." />;
    } else if (query.length === 0) {
      itemsList = items.map((item, idx) => renderItem(item, idx));
    } else {
      itemsList = filteredItems.map((item, idx) => renderItem(item, idx));
    }

    return (
      <Menu className={classes.menuList} ulRef={itemsParentRef}>
        {itemsList}
      </Menu>
    );
  };

  return (
    <div className={props.containerClassName}>
      {(props.label || props.useFakeLabel) && (
        <InputLabel text={props.label || ''} fakeLabel={props.useFakeLabel} />
      )}
      <ExploMultiSelect
        placeholder={props.placeholder}
        selectedItems={props.selectedItems}
        className={cx(
          {
            [classes.fullWidthRoot]: props.fillWidth,
          },
          GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.multiSelectInputBorderHover,
          GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.multiSelectInputOutlineActive,
          GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.multiSelectInputTag,
          GLOBAL_STYLE_CLASSNAMES.container.outline.multiSelectInputBorder,
          GLOBAL_STYLE_CLASSNAMES.container.shadow.multiSelectInputShadow,
          GLOBAL_STYLE_CLASSNAMES.container.outline.popoverBorder,
          props.className,
        )}
        items={props.options}
        itemPredicate={simpleFilterPredicate}
        itemRenderer={itemRenderer}
        itemListRenderer={itemListRenderer}
        noResults={<MenuItem disabled={true} text="No results." />}
        onItemSelect={(item) => {
          props.onItemSelected(item);
          setQuery('');
        }}
        popoverProps={{
          minimal: props.minimal,
          usePortal: !props.fillWidth,
        }}
        query={query}
        onQueryChange={setQuery}
        tagInputProps={{
          disabled: props.disabled || (props.disableOnNoItems && props.options.length === 0),
          onRemove: (_, index: number) => props.unselectIndex(index),
          className: cx(classes.input, GLOBAL_STYLE_CLASSNAMES.text.body.primary),
        }}
        tagRenderer={(item) => item.name}
      />
    </div>
  );
};

export default MultiSelectElem;
