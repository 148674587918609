/** @format */

import { createReducer } from '@reduxjs/toolkit';

import { loginUserSuccess } from 'actions/authAction';
import {
  updateGlobalStyles,
  resetGlobalStyles,
  saveGlobalStylesSuccess,
  fetchGoogleFontsSuccess,
} from 'actions/styleConfigActions';
import { DEFAULT_GLOBAL_STYLE_CONFIG, getBaseGlobalStyles } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';

export type StyleConfigReducerState = {
  current: GlobalStyleConfig;
  editing: GlobalStyleConfig;
  googleFonts?: string[];
};

const initialState: StyleConfigReducerState = {
  current: DEFAULT_GLOBAL_STYLE_CONFIG,
  editing: DEFAULT_GLOBAL_STYLE_CONFIG,
};

const styleConfigReducer = createReducer(initialState, (builder) => {
  builder
    // LOGIN_USER_SUCCESS
    .addCase(loginUserSuccess, (state, { payload }) => {
      if (payload.team) {
        const baseGlobalStyles = getBaseGlobalStyles(payload.team.style_config_v2);
        state.current = baseGlobalStyles;
        state.editing = baseGlobalStyles;
      }
    })
    // UPDATE_GLOBAL_STYLES
    .addCase(updateGlobalStyles, (state, { payload }) => {
      state.editing = payload.newConfig;
    })
    // RESET_GLOBAL_STYLES
    .addCase(resetGlobalStyles, (state) => {
      state.editing = state.current;
    })
    // SAVE_GLOBAL_STYLES
    .addCase(saveGlobalStylesSuccess, (state, { payload }) => {
      state.current = payload.config_v2;
      state.editing = payload.config_v2;
    })
    // FETCH_GOOGLE_FONTS_SUCCESS
    .addCase(fetchGoogleFontsSuccess, (state, { payload }) => {
      state.googleFonts = payload.items;
    })
    .addDefaultCase((state) => state);
});

export default styleConfigReducer;
