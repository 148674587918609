/** @format */

import React from 'react';

import { Classes, Dialog, Intent, FormGroup } from '@blueprintjs/core';
import { makeStyles } from '@material-ui/core/styles';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import Button from 'shared/Button';

const useStyles = makeStyles(() => ({
  formGroupValidationErrorState: {
    marginBottom: 0,
  },
  formGroupValidationNoError: {
    marginBottom: 20,
  },
}));

type Props = {
  initialDataPanelName: string;
  initialDataPanelId: string;
  modalOpen: boolean;
  closeModal: () => void;
  onSubmit: (newName: string, newId: string) => void;
  existingDataPanelProvidedIds: string[];
};

const DataPanelNameModal = ({
  initialDataPanelName,
  initialDataPanelId,
  modalOpen,
  closeModal,
  onSubmit,
  existingDataPanelProvidedIds,
}: Props) => {
  const [dataPanelName, setDataPanelName] = React.useState(initialDataPanelName);
  const [dataPanelId, setDataPanelId] = React.useState(initialDataPanelId);
  const classes = useStyles();

  const isNameError = !dataPanelName;
  const isIdError = !dataPanelId || existingDataPanelProvidedIds.indexOf(dataPanelId) > -1;

  const onFormSubmit = () => {
    if (!isNameError && !isIdError) {
      onSubmit(dataPanelName, dataPanelId);
      setDataPanelName('');
      setDataPanelId('');
      closeModal();
    }
  };

  return (
    <Dialog isOpen={modalOpen} onClose={closeModal} title="Data Panel Name">
      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          className={
            isNameError ? classes.formGroupValidationErrorState : classes.formGroupValidationNoError
          }
          intent={isNameError ? Intent.DANGER : Intent.NONE}
          helperText={isNameError && 'Name cannot be empty'}
          labelFor="name-input"
          label="Name">
          <InputGroup
            intent={isNameError ? Intent.DANGER : Intent.NONE}
            large={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDataPanelName(e.target.value);
            }}
            placeholder="Enter a name"
            onKeyPress={(e) => {
              if (e.key === 'Enter') onFormSubmit();
            }}
            defaultValue={dataPanelName}
          />
        </FormGroup>

        <FormGroup
          className={
            isIdError ? classes.formGroupValidationErrorState : classes.formGroupValidationNoError
          }
          intent={isIdError ? Intent.DANGER : Intent.NONE}
          labelFor="id-input"
          helperText={
            !dataPanelId
              ? 'ID cannot be empty'
              : existingDataPanelProvidedIds.indexOf(dataPanelId) > -1
              ? 'Data Panel IDs ust be unique'
              : undefined
          }
          label="Id">
          <InputGroup
            intent={isIdError ? Intent.DANGER : Intent.NONE}
            large={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDataPanelId(e.target.value);
            }}
            placeholder="Enter an ID"
            onKeyPress={(e) => {
              if (e.key === 'Enter') onFormSubmit();
            }}
            defaultValue={dataPanelId}
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            type="primary"
            onClick={() => {
              onFormSubmit();
            }}
            disabled={isNameError || isIdError}
            text="Submit"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DataPanelNameModal;
