/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createLoadingSelector } from 'reducers/api/selectors';

import LoadingBody from 'components/loadingBody';
import PageHeader from 'components/pageHeader';
import EmptyState from 'components/EmptyPageActionCallout';

import {
  fetchEndUserGroups,
  addEndUserGroup,
  editEndUserGroup,
  deleteEndUserGroup,
} from 'actions/endUserGroupActions';
import { ACTION } from 'actions/types';
import { listTeamDataSources } from 'actions/dataSourceActions';
import { fetchParentSchemas } from 'actions/parentSchemaActions';
import CustomersModal from 'pages/customersPage/customersModal';
import CustomerListItem from 'pages/customersPage/customerListItem';
import { EndUserGroup } from 'actions/types';
import ConfirmationModal from 'components/modals/confirmationModal';

import { pageView } from 'analytics/exploAnalytics';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: `${theme.spacing(5)}px ${theme.spacing(6)}px`,
      overflowY: 'auto',
    },
    listContainer: {
      marginTop: theme.spacing(6),
    },
  });

type MatchParams = {};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  createCustomersModalOpen: boolean;
  editCustomerModalOpen: boolean;
  deleteCustomerConfirmModalOpen: boolean;
  selectedGroup?: EndUserGroup;
};

class CustomersPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    props.listTeamDataSources();

    document.title = 'Explo | Customers';

    props.fetchEndUserGroups();
    props.fetchParentSchemas();

    this.state = {
      createCustomersModalOpen: false,
      editCustomerModalOpen: false,
      deleteCustomerConfirmModalOpen: false,
      selectedGroup: undefined,
    };
  }

  componentDidMount() {
    listTeamDataSources();
    pageView('Customers Page');
  }

  render() {
    const { classes, endUserGroupsLoading, parentSchemaLoading } = this.props;
    if (endUserGroupsLoading || parentSchemaLoading) return <LoadingBody />;
    return (
      <div className={classes.root}>
        {this.renderAddGroupModal()}
        {this.renderHeader()}
        {this.renderEmptyState()}
        {this.renderList()}
        {this.renderEditModal()}
        {this.renderDeleteCustomerConfirmModal()}
      </div>
    );
  }

  renderEmptyState = () => {
    const { endUserGroups } = this.props;

    if (endUserGroups.length === 0) {
      return (
        <EmptyState text="Connect to our API to sync your customer list, or add customers manually here" />
      );
    }
  };

  renderAddGroupModal = () => {
    const { addEndUserGroup, dataSources, parentSchemas } = this.props;
    const { createCustomersModalOpen } = this.state;
    if (!createCustomersModalOpen || !parentSchemas) return;
    return (
      <CustomersModal
        modalOpen={createCustomersModalOpen}
        closeModal={() => this.setState({ createCustomersModalOpen: false })}
        modalTitle="Add a Customer"
        buttonName="Create"
        onSubmit={(name: string, id: string, mapping: Record<string, string>) => {
          addEndUserGroup(
            {
              postData: {
                name: name,
                id: id,
                mapping: mapping,
              },
            },
            () => this.setState({ createCustomersModalOpen: false }),
          );
        }}
        dataSources={dataSources}
        parentSchema={parentSchemas}
      />
    );
  };

  renderEditModal = () => {
    const { editCustomerModalOpen, selectedGroup } = this.state;
    const { dataSources, editEndUserGroup, parentSchemas } = this.props;
    if (!editCustomerModalOpen || !parentSchemas) return;

    return (
      <CustomersModal
        modalOpen={editCustomerModalOpen}
        closeModal={() => this.setState({ editCustomerModalOpen: false })}
        modalTitle="Edit Customer"
        buttonName="Confirm"
        onSubmit={(name: string, id: string, mapping: Record<string, string>) => {
          editEndUserGroup(
            {
              id: selectedGroup?.id,
              postData: {
                name: name,
                mapping: mapping,
                provided_id: id,
              },
            },
            () => this.setState({ editCustomerModalOpen: false }),
          );
        }}
        dataSources={dataSources}
        parentSchema={parentSchemas}
        selectedGroup={selectedGroup}
      />
    );
  };

  renderDeleteCustomerConfirmModal = () => {
    const { deleteCustomerConfirmModalOpen, selectedGroup } = this.state;
    const { deleteEndUserGroup } = this.props;

    if (!deleteCustomerConfirmModalOpen) return;

    return (
      <ConfirmationModal
        isDestructive
        modalOpen={deleteCustomerConfirmModalOpen}
        closeModal={() => this.setState({ deleteCustomerConfirmModalOpen: false })}
        modalTitle="Are you sure you want to delete this customer?"
        cancelBtnText="Cancel"
        confirmBtnText="Delete"
        onConfirm={() => {
          this.setState({
            deleteCustomerConfirmModalOpen: false,
          });
          selectedGroup &&
            deleteEndUserGroup({ id: selectedGroup.id }, () => {
              this.setState({
                selectedGroup: undefined,
              });
            });
        }}
      />
    );
  };

  renderHeader = () => {
    return (
      <PageHeader
        title="Customers"
        primaryActionButtonText="Create New Customer"
        onPrimaryActionButtonClick={() => {
          this.setState({ createCustomersModalOpen: true });
        }}
      />
    );
  };

  renderList = () => {
    const { classes, endUserGroups, dataSources } = this.props;
    if (!endUserGroups) return;
    return (
      <div className={classes.listContainer}>
        {endUserGroups.map((group) => (
          <div key={`${group.id}`}>
            <CustomerListItem
              group={group}
              dataSources={dataSources}
              onEditClicked={() =>
                this.setState({ editCustomerModalOpen: true, selectedGroup: group })
              }
              onDeleteClicked={() =>
                this.setState({ deleteCustomerConfirmModalOpen: true, selectedGroup: group })
              }
            />
          </div>
        ))}
      </div>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  endUserGroupsLoading: createLoadingSelector([ACTION.FETCH_END_USER_GROUPS], true)(state),
  endUserGroups: state.endUserGroups.groups,
  dataSources: state.dataSourceList.dataSources,
  parentSchemas: state.parentSchemas.allParentSchema,
  parentSchemaLoading: createLoadingSelector([ACTION.FETCH_PARENT_SCHEMAS], false)(state),
});

const mapDispatchToProps = {
  fetchEndUserGroups,
  addEndUserGroup,
  listTeamDataSources,
  editEndUserGroup,
  fetchParentSchemas,
  deleteEndUserGroup,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomersPage)),
);
