/** @format */
import React from 'react';
import { Intent } from '@blueprintjs/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';

import { EndUserGroup } from 'actions/types';
import PageListItem from 'components/pageListItem';
import { DataSource } from 'actions/types';

const styles = (theme: Theme) =>
  createStyles({
    deleteButton: {
      marginTop: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.ds.grey500}`,
      paddingTop: theme.spacing(2),
    },
  });

type PassedProps = {
  group: EndUserGroup;
  dataSources: DataSource[];
  onEditClicked: () => void;
  onDeleteClicked: () => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

function CustomerListItem(props: Props) {
  const { group, onDeleteClicked, onEditClicked, classes } = props;
  return (
    <div>
      <PageListItem
        title={group.name}
        key={`end-user-group-item-${group.id}`}
        itemActions={[
          {
            text: 'Edit',
            onClick: () => {
              onEditClicked();
            },
            icon: 'edit',
          },
          {
            text: 'Delete',
            onClick: () => {
              onDeleteClicked();
            },
            icon: 'trash',
            className: classes.deleteButton,
            intent: Intent.DANGER,
          },
        ]}
      />
    </div>
  );
}

export default withStyles(styles)(CustomerListItem);
