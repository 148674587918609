/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';

const styles = () => ({
  root: {},
});

type PassedProps = {
  className?: string;
  currentPage: number;
  rowsPerPage: number;
  totalRows: number;
  minimal?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

class PageNumberIndicator extends React.Component<Props> {
  render() {
    const { classes, minimal, className, currentPage, rowsPerPage, totalRows } = this.props;

    if (totalRows === 0) {
      return <div className={cx(classes.root, className)}>No results</div>;
    }

    const startRow = (currentPage - 1) * rowsPerPage + 1;
    const endRow = Math.min(startRow + rowsPerPage - 1, totalRows);

    const text = minimal
      ? `${startRow} - ${endRow} of ${totalRows}`
      : `Showing ${startRow} - ${endRow} of ${totalRows} results`;

    return <div className={cx(classes.root, className)}>{text}</div>;
  }
}

export default withStyles(styles)(PageNumberIndicator);
