/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';

import DroppableFilterColumnSection from './DroppableFilterColumnSection';

import { FilterOperationInstructions } from 'constants/types';
import {
  createFilterClause,
  deleteFilterClause,
  selectFilterOperator,
  updateFilterValue,
  updateFilterValueSource,
  updateFilterValueVariable,
} from 'actions/dataPanelConfigActions';
import { DashboardElement } from 'types/dashboardTypes';

const useStyles = makeStyles(() => ({
  root: {},
}));

export type Props = {
  instructions: FilterOperationInstructions;
  dashboardElements?: DashboardElement[];
};

export default function FilterConfigSection({ instructions, dashboardElements }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <DroppableFilterColumnSection
        clauses={instructions.filterClauses}
        onColDropped={(col) => dispatch(createFilterClause(col))}
        onRemoveFilterClause={(i) => dispatch(deleteFilterClause(i))}
        onOperatorSelect={(i, operator) => dispatch(selectFilterOperator(i, operator))}
        onFilterValueUpdate={(i, value) => dispatch(updateFilterValue(i, value))}
        onFilterValueSourceSelect={(i, source) =>
          dispatch(updateFilterValueSource({ index: i, newSource: source }))
        }
        onFilterValueVariableSelect={(i, variableId) =>
          dispatch(updateFilterValueVariable({ index: i, variableId }))
        }
        dashboardElements={dashboardElements}
      />
    </div>
  );
}
