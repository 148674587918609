/** @format */

import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { EmbedFetchShareData } from 'actions/responseTypes';
import { embedFetchShareData } from 'actions/shareActions';
import EmbeddedDashboard, { LoadingState } from 'components/EmbeddedDashboard';

type MatchParams = {
  shareId: string;
};

export default function PdfDashboardPage() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userGroupToken, setUserGroupToken] = useState<string>('');
  const [dashboardEmbedId, setDashboardEmbedId] = useState<string>('');
  const [versionNumber, setVersionNumber] = useState<number>();
  const dispatch = useDispatch();
  const {
    params: { shareId },
  } = useRouteMatch<MatchParams>();

  useEffect(() => {
    dispatch(
      embedFetchShareData({ id: shareId, userGroupToken }, (shareData: EmbedFetchShareData) => {
        setUserGroupToken(shareData.user_group_token);
        setDashboardEmbedId(shareData.dashboard_embed_id);
        setVersionNumber(shareData.version_number);
        setIsLoading(false);
      }),
    );
  }, [dispatch, shareId, userGroupToken]);

  if (isLoading) {
    return <LoadingState type="shared" />;
  }

  return (
    <EmbeddedDashboard
      type="pdf"
      userGroupToken={userGroupToken}
      dashboardEmbedId={dashboardEmbedId}
      versionNumber={versionNumber}
    />
  );
}
