/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { IconName } from '@blueprintjs/core';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import CategoryFieldColorAssignment from './CategoryFieldColorAssignment';

import { TableColumn, TableRow } from 'actions/types';
import {
  StringDisplayFormat,
  StringDisplayOptions,
  VisualizeTableInstructions,
} from 'constants/types';
import { ORDERED_ALIGNMENTS } from 'constants/dataConstants';
import { TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  addFieldsToDisplayOptions: (
    newFields: Partial<StringDisplayOptions> & {
      format: StringDisplayFormat;
    },
  ) => void;
  column: TableColumn;
  instructions: VisualizeTableInstructions;
  dataPanelData: TableRow[];
};

export default function StringFormatConfiguration({
  addFieldsToDisplayOptions,
  column,
  instructions,
  dataPanelData,
}: Props) {
  const classes = useStyles();
  const { format: currentFormat, label: currentLabel, alignment: currentAlignment } = (instructions
    .schemaDisplayOptions?.[column.name] as StringDisplayOptions) ?? {
    format: StringDisplayFormat.NORMAL,
  };

  const onButtonClick = (newFormat: StringDisplayFormat) => {
    if (newFormat === currentFormat) {
      return;
    }

    addFieldsToDisplayOptions({ format: newFormat });
  };

  return (
    <>
      <ToggleButtonGroup fillWidth className={classes.input} label="Format">
        {Object.values(StringDisplayFormat).map((format) => (
          <ToggleButton
            key={format}
            active={format === currentFormat}
            onClick={() => {
              onButtonClick(format);
            }}
            text={format}
          />
        ))}
      </ToggleButtonGroup>
      {currentFormat === StringDisplayFormat.LINK && (
        <InputWithBlurSave
          containerClassName={classes.input}
          label="Label"
          initialValue={currentLabel}
          onNewValueSubmitted={(newLabel) => {
            addFieldsToDisplayOptions({ format: currentFormat, label: newLabel });
          }}
        />
      )}
      {currentFormat === StringDisplayFormat.CATEGORY && (
        <CategoryFieldColorAssignment
          column={column}
          instructions={instructions}
          addFieldsToDisplayOptions={addFieldsToDisplayOptions}
          dataPanelData={dataPanelData}
        />
      )}
      <ToggleButtonGroup fillWidth className={classes.input} label="Alignment">
        {ORDERED_ALIGNMENTS.map((alignment) => (
          <ToggleButton
            key={`alignment-${alignment.id}-${column.name}`}
            active={(currentAlignment || TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT) === alignment.id}
            icon={alignment.icon as IconName}
            onClick={() => {
              addFieldsToDisplayOptions({ alignment: alignment.id, format: currentFormat });
            }}
          />
        ))}
      </ToggleButtonGroup>
    </>
  );
}
