/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  flex: ({ flex }: Pick<Props, 'flex'>) => ({
    flex: flex ?? 1,
  }),
}));

type Props = React.HTMLAttributes<HTMLDivElement> & {
  flex?: number;
};

const FlexItem: React.FunctionComponent<Props> = ({ children, flex, className, ...otherProps }) => {
  const classes = useStyles({ flex });

  return (
    <div className={cx(classes.flex, className)} {...otherProps}>
      {children}
    </div>
  );
};

export default FlexItem;
