/** @format */

import { IconName } from '@blueprintjs/core';
import { TableColumn, TableRow } from 'actions/types';
import { ColorHex } from 'constants/colorConstants';
import { TextElemHorizAlignments, TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';
import { COMBO_CHART_DATA_FORMATS } from 'constants/dashboardConstants';
import {
  PeriodRangeTypes,
  PeriodComparisonRangeTypes,
  TrendGroupingOptions,
  STRING_FORMATS,
} from 'constants/dataConstants';
/** @format */

export type RecordItem = {
  id: string;
  name: string;
};

export interface JoinType {
  id: string;
  name: string;
  icon: string;
}

export interface JoinTypes {
  [joinType: string]: JoinType;
}

export interface ChartColumnInfo {
  name?: string;
  friendly_name?: string;
  type?: string;
  dataFormat?: COMBO_CHART_DATA_FORMATS;
}

export interface AggedChartColumnInfo {
  column: ChartColumnInfo;
  agg: AggregationType;
  yAxisFormatId?: string;
}

export interface CategoryChartColumnInfo {
  column: ChartColumnInfo;
  bucket?: AggregationBucket;
  bucketElemId?: string;
}

export interface TimeSeriesDataFormat {
  hideLatestPeriodData?: boolean;
  zeroMissingDates?: boolean;
}

export interface ConfigurableColumnInfo {
  column: ChartColumnInfo;
  bucket?: AggregationBucket;
  agg?: AggregationType;
}

export interface ColumnInfo {
  name: string;
  friendly_name?: string;
  type: string;
}

export type DataType = string;

export type SelectableDataTypes = DataType[];

export type Schema = ColumnInfo[];

export interface FilterOperatorType {
  id: string;
  name: string;
  selectionValue: string;
  supported_column_types: Set<string>;
}

export interface FilterOperatorTypes {
  [FilterOperatorName: string]: FilterOperatorType;
}

export interface YAxisRangeOptionsType {
  id: string;
  name: string;
  selectionValue: string;
}

export interface YAxisRangeOptionsTypes {
  [YAxisRangeOptionName: string]: YAxisRangeOptionsType;
}

export interface FilterOperatorsById {
  [filterOperatorType: string]: FilterOperatorType;
}

export interface OperationType {
  id: string;
  name: string;
  icon?: string;
  disabled?: boolean;
  help_center_link?: string;
}

export interface OperationTypes {
  [OperationName: string]: OperationType;
}

export enum Aggregation {
  COUNT = 'COUNT',
  COUNT_DISTINCT = 'COUNT_DISTINCT',
  AVG = 'AVG',
  SUM = 'SUM',
  MIN = 'MIN',
  MAX = 'MAX',
  '25_PERCENTILE' = '25_PERCENTILE',
  MEDIAN = 'MEDIAN',
  '75_PERCENTILE' = '75_PERCENTILE',
}

export interface AggregationType {
  id: string;
  name: string;
  selectionValue: string;
  formula?: string;
}

export type AggregationTypes = Record<Aggregation, AggregationType>;

export enum OPERATION_TYPES {
  FILTER = 'FILTER',
  GROUP_BY = 'GROUP_BY',
  SORT = 'SORT',

  // V1 visualizations
  VISUALIZE_TABLE = 'VISUALIZE_TABLE',
  VISUALIZE_LINE_CHART = 'VISUALIZE_LINE_CHART',
  VISUALIZE_BAR_CHART = 'VISUALIZE_BAR_CHART',
  VISUALIZE_HORIZ_BAR_CHART = 'VISUALIZE_HORIZ_BAR_CHART',
  VISUALIZE_NUMBER = 'VISUALIZE_NUMBER',
  VISUALIZE_CHOROPLETH_MAP = 'VISUALIZE_CHOROPLETH_MAP',
  VISUALIZE_FUNNEL = 'VISUALIZE_FUNNEL',
  VISUALIZE_PIE_CHART = 'VISUALIZE_PIE_CHART',
  VISUALIZE_COMBO_CHART = 'VISUALIZE_COMBO_CHART',
  VISUALIZE_HEAT_MAP = 'VISUALIZE_HEAT_MAP',
  VISUALIZE_PIVOT_CHART = 'VISUALIZE_PIVOT_CHART',

  // V2 visualizations
  VISUALIZE_VERTICAL_BAR_V2 = 'VISUALIZE_VERTICAL_BAR_V2',
  VISUALIZE_VERTICAL_100_BAR_V2 = 'VISUALIZE_VERTICAL_100_BAR_V2',
  VISUALIZE_VERTICAL_GROUPED_BAR_V2 = 'VISUALIZE_VERTICAL_GROUPED_BAR_V2',

  VISUALIZE_HORIZONTAL_BAR_V2 = 'VISUALIZE_HORIZONTAL_BAR_V2',
  VISUALIZE_HORIZONTAL_100_BAR_V2 = 'VISUALIZE_HORIZONTAL_100_BAR_V2',
  VISUALIZE_HORIZONTAL_GROUPED_BAR_V2 = 'VISUALIZE_HORIZONTAL_GROUPED_BAR_V2',

  VISUALIZE_PIE_CHART_V2 = 'VISUALIZE_PIE_CHART_V2',
  VISUALIZE_DONUT_CHART_V2 = 'VISUALIZE_DONUT_CHART_V2',

  VISUALIZE_LINE_CHART_V2 = 'VISUALIZE_LINE_CHART_V2',
  VISUALIZE_AREA_CHART_V2 = 'VISUALIZE_AREA_CHART_V2',
  VISUALIZE_AREA_100_CHART_V2 = 'VISUALIZE_AREA_100_CHART_V2',

  VISUALIZE_COMBO_CHART_V2 = 'VISUALIZE_COMBO_CHART_V2',

  VISUALIZE_HEAT_MAP_V2 = 'VISUALIZE_HEAT_MAP_V2',
  VISUALIZE_FUNNEL_V2 = 'VISUALIZE_FUNNEL_V2',
  VISUALIZE_NUMBER_V2 = 'VISUALIZE_NUMBER_V2',
  VISUALIZE_BOX_PLOT_V2 = 'VISUALIZE_BOX_PLOT_V2',
  VISUALIZE_PROGRESS_V2 = 'VISUALIZE_PROGRESS_V2',
  VISUALIZE_NUMBER_TREND_V2 = 'VISUALIZE_NUMBER_TREND_V2',
  VISUALIZE_MAP_V2 = 'VISUALIZE_MAP_V2',

  VISUALIZE_REPORT_BUILDER = 'VISUALIZE_REPORT_BUILDER',
}

export const VISUALIZATION_OPERATIONS = [
  OPERATION_TYPES.VISUALIZE_LINE_CHART,
  OPERATION_TYPES.VISUALIZE_BAR_CHART,
  OPERATION_TYPES.VISUALIZE_HORIZ_BAR_CHART,
  OPERATION_TYPES.VISUALIZE_NUMBER,
  OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
  OPERATION_TYPES.VISUALIZE_FUNNEL,
  OPERATION_TYPES.VISUALIZE_PIE_CHART,
  OPERATION_TYPES.VISUALIZE_HEAT_MAP,
  OPERATION_TYPES.VISUALIZE_PIVOT_CHART,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
  OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2,
  OPERATION_TYPES.VISUALIZE_FUNNEL_V2,
  OPERATION_TYPES.VISUALIZE_NUMBER_V2,
  OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2,
  OPERATION_TYPES.VISUALIZE_PROGRESS_V2,
  OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2,
  OPERATION_TYPES.VISUALIZE_MAP_V2,
];

export const V2_CHART_VISUALIZATION_OPERATIONS = [
  OPERATION_TYPES.VISUALIZE_NUMBER_V2,
  OPERATION_TYPES.VISUALIZE_PROGRESS_V2,
  OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2,

  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,

  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,

  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,

  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2,

  OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2,
  OPERATION_TYPES.VISUALIZE_FUNNEL_V2,
  OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2,
  OPERATION_TYPES.VISUALIZE_MAP_V2,
];

export const V2_VISUALIZATION_OPERATIONS = [
  ...V2_CHART_VISUALIZATION_OPERATIONS,
  OPERATION_TYPES.VISUALIZE_TABLE,
  OPERATION_TYPES.VISUALIZE_REPORT_BUILDER,
];

export enum ChartTypeSections {
  PERFORMANE_INDICATOR = 'Performance Indicator',
  BAR_CHART = 'Bar Chart',
  LINE_CHART = 'Line Chart',
  AREA_CHART = 'Area Chart',
  PIE_CHART = 'Pie Chart',
  SINGLE_VALUE = 'Single Value',
  TABLE = 'Data Table',
  SPECIAL = 'SPECIAL',
}

export enum REGION_TYPES {
  UNITED_STATES = 'UNITED_STATES',
  WORLD = 'WORLD',
}

export enum EMAIL_FREQUENCY {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

export type EmailCadenceTime = {
  hour?: number;
  minute?: number;
  // unused
  timezone?: string;
  isPm?: boolean;
};

export type RegionMetaData = {
  id: REGION_TYPES;
  text: string;
  icon?: string;
  center: [number, number];
  zoom: number;
};

export type OperationIconsDictionary = Record<OPERATION_TYPES, IconName | JSX.Element>;

export interface OperationsByIdType {
  [operationType: string]: OperationType;
}

export type FilterValueDateType = {
  startDate?: string | Date;
  endDate?: string | Date;
};

export type FilterValueRelativeDateType = {
  number?: number;
  relativeTimeType?: { id: string; name: string };
};

export type FilterValueType =
  | string
  | number
  | string[]
  | number[]
  | undefined
  | FilterValueDateType
  | FilterValueRelativeDateType;

export enum FilterValueSourceType {
  INPUT = 'INPUT',
  VARIABLE = 'VARIABLE',
}

export interface FilterClause {
  filterColumn?: TableColumn;
  filterOperation?: FilterOperatorType;
  filterValue: FilterValueType;
  filterValueSource?: FilterValueSourceType;
  filterValueVariableId?: string;
}

export interface FilterOperationInstructions {
  matchOnAll: boolean;
  filterClauses: FilterClause[];
}

export type UserTransformedColumn = ColumnInfo & { isVisible: boolean };
export type UserTransformedSchema = UserTransformedColumn[];

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortClause {
  column: ColumnInfo | null;
  order: SortOrder | null;
}

export interface SortOperationInstructions {
  sortColumns: SortClause[];
}

export interface TableMetaInfo {
  flowId: number;
  operationId: number;
  datasetId: number;
  name: string;
}

export type TableData = TableRow[];

export interface EnrichOperationInstructions {
  joinTable: TableMetaInfo;
  joinType: string;
  columnsToJoinOn: ColumnToJoinOn[];
}

export interface ColumnToJoinOn {
  joinColumn: string;
  sourceColumn: string;
}

export interface ColumnToUnionOn {
  resultColumnName?: string;
  sourceColumn?: string | null;
  unionColumn?: string | null;
}

export interface UnionOperationInstructions {
  unionTable: TableMetaInfo;
  columnsToUnionOn: ColumnToUnionOn[];
  unionType: string;
  unionColumnMatchType: string;
}

export interface SchemaChange {
  col: string;
  newColName?: string | null;
  keepCol: boolean;
}

export enum DateDisplayFormat {
  NORMAL = 'MM/DD/YYYY',
  NUMERIC_SHORT = 'MM-DD-YY',
  NUMERIC_LONG = 'DD-MM-YYYY',
  DATE_TIMESTAMP = 'DD/MM/YYYY (hh:mm:ss)',
  VERBAL_SHORT = 'Mon Day, Year',
  VERBAL_LONG = 'Month Day, Year',
  CUSTOM = 'Custom',
}

export type DateDisplayOptions = {
  format: DateDisplayFormat;
  customFormat?: string;
  alignment?: TEXT_ELEM_HORIZ_ALIGNMENTS;
};

export enum StringDisplayFormat {
  NORMAL = 'Normal',
  CATEGORY = 'Category',
  LINK = 'Link',
}

export type StringDisplayOptions = {
  format: StringDisplayFormat;
  label?: string;
  alignment?: TEXT_ELEM_HORIZ_ALIGNMENTS;
  categoryColorAssignments?: Record<string | number, string>;
  addedCategories?: { name: string; color: string }[];
};

export enum NumberDisplayFormat {
  NORMAL = 'Normal',
  CURRENCY = 'Currency ($)',
  PERCENT = 'Percent (%)',
  TIME = 'Time (00:00)',
}

export enum NumberDisplayDisplayType {
  VALUE = 'Value',
  PROGRESS_BAR = 'Progress Bar',
}

export type NumberDisplayTypeOptions = {
  progressBarGoal?: number;
  useColumnMaxForProgressBarGoal?: boolean;
};

export enum GradientType {
  NONE = 'None',
  LINEAR = 'Linear',
  DIVERGING = 'Diverging',
}

export type GradientShape = {
  hue1: string;
  hue2: string;
  hue3: string;
};

export enum GradientPointType {
  COMPUTED = 'Computed',
  NUMBER = 'Number',
}

export type GradientPointOptions = {
  type?: GradientPointType;
  number?: number;
};

export type GradientOptions = {
  minpoint?: GradientPointOptions;
  midpoint?: GradientPointOptions;
  maxpoint?: GradientPointOptions;
};

export type NumberDisplayOptions = {
  decimalPlaces?: number;
  hasCommas?: boolean;
  format: NumberDisplayFormat;
  goal?: number;
  timeFormat?: NumberFormatOption;
  timeCustomFormat?: string;
  useColumnMaxForGoal?: boolean;
  displayType?: NumberDisplayDisplayType;
  displayTypeOptions?: NumberDisplayTypeOptions;
  gradientType?: GradientType;
  gradient?: Partial<GradientShape>;
  gradientOptions?: GradientOptions;
  alignment?: TEXT_ELEM_HORIZ_ALIGNMENTS;
};

export enum BooleanDisplayFormat {
  TICK = 'tick',
  CROSS = 'cross',
  BLANK = 'blank',
}

export type BooleanDisplayOptions = {
  trueIcon: BooleanDisplayFormat;
  falseIcon: BooleanDisplayFormat;
  alignment?: TEXT_ELEM_HORIZ_ALIGNMENTS;
};

export type DisplayOptions =
  | DateDisplayOptions
  | StringDisplayOptions
  | NumberDisplayOptions
  | BooleanDisplayOptions;

export type TableJoinColumnConfig = {
  joinOn?: boolean;
  joinTable?: { id: string; name: string };
  joinColumn?: { id: string; name: string; column: TableColumn };
  joinDisplayColumn?: { id: string; name: string; column: TableColumn };
};

export type VisualizeTableInstructions = {
  changeSchemaList: SchemaChange[];
  columnWidths?: Array<number | null | undefined>;
  shouldTruncateText?: boolean;
  isSchemaCustomizationEnabled?: boolean;
  isFilteringDisabled?: boolean;
  isFooterHidden?: boolean;
  isDownloadButtonHidden?: boolean;
  isHeaderHidden?: boolean;
  rowHeight?: number;
  isColumnSortingDisabled?: boolean;
  isColumnLinesEnabled?: boolean;
  isRowLinesDisabled?: boolean;
  isColumnHeadersBolded?: boolean;
  isFirstColumnBolded?: boolean;
  schemaDisplayOptions?: Record<string, DisplayOptions & TableJoinColumnConfig>;
  generalFormat?: V2GeneralInstructions;
  pdfFormat?: TablePDFFormat;
};

export type TablePDFFormat = {
  headerEnabled?: boolean;
  leftOption?: SECTION_OPTIONS;
  leftContent?: string;
  centerOption?: SECTION_OPTIONS;
  centerContent?: string;
  rightOption?: SECTION_OPTIONS;
  rightContent?: string;
};

export enum SECTION_OPTIONS {
  BLANK = 'BLANK',
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}

export type ChartViewType = {
  id: string;
  icon: string;
  name: string;
};

export type VisualizeLineOrBarChartInstructions = {
  xAxisColumn?: ChartColumnInfo;
  lineColumns: LineColumn[];
  viewType?: ChartViewType;
  labelMirrored?: boolean;
  yAxisRangeConfig?: {
    option?: YAxisRangeOptionsType;
    min?: string;
    max?: string;
  };
};

export type LineColumn = {
  column?: ChartColumnInfo;
  color?: ColorHex;
  numberFormat?: NumberFormatOption;
  lineOrBar?: string;
};

export interface VisualizeNumberDisplayFormat {
  title?: string;
  hideTitle: boolean;
  icon?: string;
  units?: string;
  hideUnits: boolean;
  alignment?: string;
  emptyText?: string;
  isProgess?: boolean;
  progressColor?: string;
}

export type NumberFormatOption = { id: string; name: string };

export type VisualizeNumberLayoutConfigForNumber = {
  column?: ChartColumnInfo;
  numberFormat?: NumberFormatOption;
  multiplyFactor: number;
  decimalPlaces: number;
  progressGoal?: number;
};

export type VisualizeNumberLayoutConfig = {
  NUMBER: VisualizeNumberLayoutConfigForNumber;
  RATIO: object;
};

export type VisualizeNumberInstructions = {
  layout: string;
  layoutConfig: VisualizeNumberLayoutConfig;
  displayFormat: VisualizeNumberDisplayFormat;
};

export type VisualizeChoroplethMapInstructions = {
  region: string;
  regionColumn?: ChartColumnInfo;
  densityColumn?: ChartColumnInfo;
};

export type VisualizeFunnelInstruction = {
  categoryColumn?: ChartColumnInfo;
  amountColumn?: ChartColumnInfo;
  startColor?: string;
  endColor?: string;
};

export type VisualizeHeatMapInstructions = {
  xAxisColumn?: ChartColumnInfo;
  yAxisColumn?: ChartColumnInfo;
  aggregation?: PivotOperationAggregation;
  showText?: boolean;
  cellTextSize?: number;
  textColor?: string;
  startColor?: string;
  endColor?: string;
  xAxisLabelAngle?: number;
  labelFontSize?: number;
  titleFontSize?: number;
};

export type VisualizePieChartInstruction = {
  categoryColumn?: ChartColumnInfo;
  valueColumn?: ChartColumnInfo;
  layoutType: string;
  legendLocation?: string;
};

export enum PivotChartDirection {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

export type ViusalizePivotChartInstructions = {
  xAxisColumn?: ChartColumnInfo;
  yAxisColumn?: ChartColumnInfo;
  aggregation?: PivotOperationAggregation;
  colors?: string;
  chartTypeId?: string;
  chartDirection?: PivotChartDirection;
  axisMin?: string;
  axisMax?: string;
  labelFontSize?: number;
  titleFontSize?: number;
  aggMinStepSize?: number;
};

export type BaseAxisFormat = {
  title?: string;
  showTitle?: boolean;
};

export enum SortOption {
  DEFAULT = 'Default',
  AGG_AXIS_ASC = 'Ascending by Y-Axis',
  AGG_AXIS_DESC = 'Descending by Y-Axis',
}

export type XAxisFormat = BaseAxisFormat & {
  showBarValues?: boolean;
  barCornerRadius?: number;
  hideTotalValues?: boolean;
  sortOption?: SortOption;
  dateFormat?: string;
  stringFormat?: { format?: STRING_FORMATS; replaceUnderscores?: boolean };
  maxCategories?: number;
  hideAxisTicks?: boolean;
};

export type YAxisFormat = BaseAxisFormat & {
  id?: string;
  numberFormat?: NumberFormatOption;
  decimalPlaces?: number;
  showDecimals?: boolean;
  min?: number;
  max?: number;
  colorFromAggColumn?: AggedChartColumnInfo;
  oppositeAligned?: boolean;
};

export enum LegendPosition {
  TOP = 'Top',
  BOTTOM = 'Bottom',
  LEFT = 'Left',
  RIGHT = 'Right',
  AUTO = 'Auto',
}

export type LegendFormat = {
  showLegend?: boolean;
  position?: LegendPosition;
  title?: string;
  showTitle?: boolean;
  hideLegend?: boolean;
};

export type PieChartFormat = {
  hideChartValues?: boolean;
  useColorForLabel?: boolean;
  maxCategories?: number;
};

export type HeatMapFormat = {
  showCellLabels?: boolean;
};

export type FunnelChartFormat = {
  hideChartValues?: boolean;
  useStageForLabel?: boolean;
};

export enum LineElasticity {
  STRAIGHT = 'Straight',
  CURVED = 'Curved',
}

export type LineChartFormat = {
  elasticity?: LineElasticity;
  hideMarkers?: boolean;
  lineWidth?: number;
};

export type MapChartFormat = {
  region?: REGION_TYPES;
};

export enum ColorPalette {
  DEFAULT = 'Default',
  GREEN = 'Green',
  BLUE = 'Blue',
  CUSTOM = 'Custom Palette',
}

export enum ColorPaletteV2 {
  CATEGORICAL = 'Categorical',
  DIVERGING = 'Diverging',
  GRADIENT = 'Gradient',
}

export type ColorFormat = {
  selectedPalette?: ColorPalette | ColorPaletteV2;
  customColors?: string;
};

export type ChartSpecificFormat = {
  pieChart?: PieChartFormat;
  heatMap?: HeatMapFormat;
  funnelChart?: FunnelChartFormat;
  lineChart?: LineChartFormat;
  mapChart?: MapChartFormat;
  timeSeriesDataFormat?: TimeSeriesDataFormat;
};

export enum PlotLineType {
  SOLID = 'Solid',
  SHORT_DASH = 'ShortDash',
  SHORT_DOT = 'ShortDot',
  DOT = 'Dot',
  DASH = 'Dash',
  LONG_DASH = 'LongDash',
}

export type GoalLineFormat = {
  showGoalLine?: boolean;
  goalValue?: string;
  goalValueMax?: string;
  isGoalBand?: boolean;
  lineType?: PlotLineType;
  lineColor?: string;
  lineWidth?: number;
  label?: string;
  labelColor?: string;
};

export type TooltipFormat = {
  showPct?: boolean;
};

export type V2TwoDimensionChartInstructions = {
  aggColumns?: AggedChartColumnInfo[];
  categoryColumn?: CategoryChartColumnInfo;
  colorColumn?: CategoryChartColumnInfo;
  xAxisFormat?: XAxisFormat;
  yAxisFormats?: YAxisFormat[];
  legendFormat?: LegendFormat;
  generalFormat?: V2GeneralInstructions;
  tooltipFormat?: TooltipFormat;
  colorFormat?: ColorFormat;
  chartSpecificFormat?: ChartSpecificFormat;
  goalLines?: GoalLineFormat[];
  drilldown?: DrilldownConfig;
};

export type DrilldownConfig = {
  categorySelectEnabled?: boolean;
};

export type KPIGeneralFormat = V2GeneralInstructions & {
  title?: string;
  subtitle?: string;
  alignment?: TextElemHorizAlignments;
};

export type KPIValueFormat = {
  units?: string;
  emptyText?: string;
  numberFormat?: NumberFormatOption;
  multiplyFactor?: number;
  decimalPlaces?: number;
  progressGoal?: number;
  progressBarColor?: string;
  bold?: boolean;
  italic?: boolean;
  timeFormat?: NumberFormatOption;
  timeCustomerFormat?: string;
  horizAlignment?: TextElemHorizAlignments;
};

export type KPITitleFormat = {
  horizAlignment?: TextElemHorizAlignments;
};

export type KPITrendFormat = {
  label?: string;
  isNumber?: boolean;
  trendColorsReversed?: boolean;
};

export type V2KPIChartInstructions = {
  aggColumn?: AggedChartColumnInfo;
  trendColumn?: CategoryChartColumnInfo;
  generalFormat?: KPIGeneralFormat;
  valueFormat?: KPIValueFormat;
  trendFormat?: KPITrendFormat;
  displayFormat?: VisualizeNumberDisplayFormat;
};

export type V2GeneralInstructions = {
  showTooltip?: boolean;
  tooltipText?: string;
};

export type BoxPlotFormat = {
  seriesLabelByColumn?: { [columnName: string]: string };
  isVertical?: boolean;
  hideWhisker?: boolean;
  fillColorByColumn?: { [columnName: string]: string };
  medianColorByColumn?: { [columnName: string]: string };
};

export type V2BoxPlotInstructions = {
  groupingColumn?: CategoryChartColumnInfo;
  calcColumns?: ChartColumnInfo[];
  xAxisFormat?: XAxisFormat;
  yAxisFormat?: YAxisFormat;
  legendFormat?: LegendFormat;
  boxPlotFormat?: BoxPlotFormat;
};

export interface KPIPeriodColumnInfo {
  column: ChartColumnInfo;
  periodRange: PeriodRangeTypes;
  customStartDate?: string | Date;
  customEndDate?: string | Date;
  rangeElemId?: string;
  timePeriodElemId?: string;
  trendDateOffset?: number;
}

export interface KPITrendDisplayFormat {
  comparisonColor?: string;
  showAbsoluteChange?: boolean;
  showTrendChangePeriodLabel?: boolean;
  periodColor?: string;
  trendColorsReversed?: boolean;
  useTrendTag?: boolean;
}

export type V2KPITrendInstructions = {
  aggColumn?: AggedChartColumnInfo;
  periodColumn?: KPIPeriodColumnInfo;
  periodComparisonRange?: PeriodComparisonRangeTypes;
  trendGrouping?: TrendGroupingOptions;
  valueFormat?: KPIValueFormat;
  titleFormat?: KPITitleFormat;
  displayFormat?: KPITrendDisplayFormat;
  generalFormat?: V2GeneralInstructions;
  textOnlyFormat?: V2KPITrendTextOnlyInstructions;
  hideTrendLines?: boolean;
};

export type V2KPITrendTextOnlyInstructions = {
  subtitle?: string;
};

// New instruction keys need to be made optional/nullable
// because all existing data panel templates won't have them present
// when the new instruction is added
export type VisualizeOperationInstructions = {
  VISUALIZE_TABLE: VisualizeTableInstructions;
  VISUALIZE_LINE_OR_BAR_CHART: VisualizeLineOrBarChartInstructions;
  VISUALIZE_NUMBER: VisualizeNumberInstructions;
  VISUALIZE_CHOROPLETH_MAP: VisualizeChoroplethMapInstructions;
  VISUALIZE_FUNNEL: VisualizeFunnelInstruction;
  VISUALIZE_PIE_CHART: VisualizePieChartInstruction;
  VISUALIZE_HEAT_MAP: VisualizeHeatMapInstructions;
  VISUALIZE_PIVOT_CHART: ViusalizePivotChartInstructions;
  V2_TWO_DIMENSION_CHART?: V2TwoDimensionChartInstructions;
  V2_KPI?: V2KPIChartInstructions;
  V2_BOX_PLOT?: V2BoxPlotInstructions;
  V2_KPI_TREND?: V2KPITrendInstructions;
};

export interface ChangeSchemaOperationInstructions {
  changeSchemaList: SchemaChange[];
}

export interface AggregationColumnInfo extends ColumnInfo {
  aggBucket?: AggregationBucket;
}

export interface AdvancedColumnInfo extends ColumnInfo {
  friendly_name: string;
  id: string;
}

export type AxisPivotChartInstructions = {
  rowColumn: AggregationColumnInfo | null;
  colColumn: AggregationColumnInfo | null;
  aggregation: PivotOperationAggregation;
};

export type NavBarTab = {
  id: string;
  href: string;
  name: string;
  icon: IconName | JSX.Element;
};
// Group By Types

export type PivotOperationInstructions = {
  pivotedOnCols: AggregationColumnInfo[];
  aggregations: PivotOperationAggregation[];
};

export interface AggregationColumnInfo extends ColumnInfo {
  aggBucket?: AggregationBucket;
}

export type AggregationBucket = SelectedDropdownInputItem;

export interface SelectedDropdownInputItem {
  name: string;
  id: string;
  icon?: IconName | JSX.Element;
  value?: string | number;
}

export type PivotOperationAggregation = {
  type: AggregationType | null;
  aggedOnColumn: ColumnInfo | null;
  additionalCol?: ColumnInfo;
  aggLabel?: string;
};

// Dedup Types

export type DedupOperationInstructions = {
  colsToDedup: AggregationColumnInfo[];
  sortColumns: SortClause[];
  isAllColsSelected: boolean;
};

export type OperationInstructions =
  | FilterOperationInstructions
  | EnrichOperationInstructions
  | UnionOperationInstructions
  | ChangeSchemaOperationInstructions
  | PivotOperationInstructions
  | DedupOperationInstructions
  | SortOperationInstructions
  | AxisPivotChartInstructions;

export interface BaseOperationInstructionByType {
  [operationId: string]: () => OperationInstructions;
}

export interface DatasetData {
  cached_schema: Schema;
  cached_preview: TableData;
}

export type DatasetsData = DatasetData[];

export interface DelimiterType {
  id: string;
  name: string;
  value: string | null;
}

export interface DelimiterTypes {
  [delimiterType: string]: DelimiterType;
}

export interface TimeStampItem {
  format: string;
  name: string;
  parentType: string;
}

export interface SchemaDataType {
  name: string;
  icon: IconName | JSX.Element;
}

export interface SchemaDataTypesById {
  [schemaDataTypeName: string]: SchemaDataType;
}

export interface MenuOption {
  name: string;
  onClick: () => void;
  icon: string;
}

// will use Unions (|) to expand this in the future
export type OperationInputTableRow = SortClause;

// this needs to be kept in sync with models.ReportedAnalyticsAction
export enum REPORTED_ANALYTIC_ACTION_TYPES {
  DASHBOARD_PAGE_VIEWED = 'Dashboard Page Viewed',
  SHARED_DASHBOARD_PAGE_VIEWED = 'Shared Dashboard Page Viewed',
  SHARED_CHART_VIEWS = 'Shared Chart Viewed',
  SHARE_BUTTON_CLICKED = 'Share Button Clicked',
  CSV_DOWNLOADED = 'CSV Downloaded',
  DPT_PDF_DOWNLOADED = 'DPT_PDF_DOWNLOADED',
  TABLE_SORTED = 'Table Sorted',
  TABLED_PAGED = 'Table Paged',
  TABLE_FILTERED = 'Table Filtered',
  DROPDOWN_SELECTED = 'Dropdown Selected',
  MULTISELECT_SELECTED = 'Multiselect Selected',
  DATEPICKER_SELECTED = 'Datepicker Selected',
}

export interface SupportedLocale {
  name: string;
  localeCode: string;
}

export interface NumberDefinition {
  decimal: string;
  thousands: string;
  grouping: number[];
  currency: string[];
  percent?: string;
}

export interface LocaleDefinition {
  locale: ILocale;
  numbers: NumberDefinition;
}

export interface NoDataConfig {
  hideChartIcon?: boolean;
  noDataText?: string;
  noDataFontSize?: number;
}

export interface VisualizeOperationGeneralFormatOptions {
  hideDownloadButton?: boolean;
  noDataState?: NoDataConfig;
}

export interface DropdownOption {
  id: string;
  value: string | number;
  name: string;
}
