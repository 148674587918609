/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core';
import { Tooltip, Position } from '@blueprintjs/core';

import SettingHeader from '../SettingHeader';
import DroppableColumnSection from './DroppableColumnSection';
import GenericDroppableColumnSection from './GenericDroppableColumnSection';
import GenericDropdownSection from './GenericDropdownSection';
import ValueInput from 'pages/dashboardPage/DashboardDatasetView/Header/ValueInput';
import DropdownSelect from 'shared/DropdownSelect';
import Button from 'shared/Button';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import InputWithTag from 'shared/InputWithTag';

import {
  FilterValueDateType,
  FilterValueType,
  OPERATION_TYPES,
  V2KPITrendInstructions,
} from 'constants/types';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { defaultAggByType, defaultPeriodType } from './utils';
import {
  PeriodComparisonRangeTypes,
  PeriodRangeTypes,
  PERIOD_COMPARISON_RANGE_TYPES,
  PERIOD_RANGE_OPTIONS,
  TrendGroupingOptions,
  TREND_GROUPING_OPTIONS,
  DATETIME,
  TIMESTAMP,
  DATE,
  FORMULA_AGG_TYPE,
} from 'constants/dataConstants';
import { FILTER_OPERATOR_TYPES_BY_ID } from 'constants/dataPanelEditorConstants';
import {
  DashboardElement,
  DASHBOARD_ELEMENT_TYPES,
  DateRangePickerElemConfig,
  TimePeriodDropdownDashboardElemConfig,
} from 'types/dashboardTypes';

const useStyles = makeStyles((theme: Theme) => ({
  customRangeDateSelectors: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  configInput: { padding: theme.spacing(3), paddingTop: 0 },
  customFormulaField: {
    color: theme.palette.ds.grey600,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
}));

export type Props = {
  instructions: V2KPITrendInstructions;
  chartType: string;
  loading?: boolean;
  dashboardElements?: DashboardElement[];
};

export default function KPITrendConfig({
  instructions,
  chartType,
  loading,
  dashboardElements,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const canPeriodRangeUseOffset = (range?: PeriodRangeTypes) => {
    return (
      range !== undefined &&
      range !== PeriodRangeTypes.CUSTOM_RANGE &&
      range !== PeriodRangeTypes.DATE_RANGE_INPUT &&
      range !== PeriodRangeTypes.TIME_PERIOD_DROPDOWN
    );
  };

  const aggIsCustomFormula = instructions.aggColumn?.agg.id === FORMULA_AGG_TYPE.id;

  return (
    <div>
      <SettingHeader
        name="Aggregation"
        rightContent={
          <Tooltip
            content={
              aggIsCustomFormula ? 'Aggregate fields on the table' : 'Custom aggregation formula'
            }
            position={Position.BOTTOM}
            usePortal>
            <Button
              minimal
              compact
              icon={aggIsCustomFormula ? 'th' : 'function'}
              onClick={() => {
                const newInstructions = cloneDeep(instructions);
                const newAggCol = aggIsCustomFormula
                  ? undefined
                  : {
                      column: {},
                      agg: FORMULA_AGG_TYPE,
                    };
                newInstructions.aggColumn = newAggCol;
                dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
              }}
            />
          </Tooltip>
        }
      />
      {aggIsCustomFormula ? (
        <div className={classes.customFormulaField}>
          <InputWithBlurSave
            initialValue={instructions.aggColumn?.agg.formula || ''}
            onNewValueSubmitted={(newVal: string) => {
              const newInstructions = cloneDeep(instructions);
              newInstructions.aggColumn = {
                column: { name: 'custom_formula' },
                agg: { ...FORMULA_AGG_TYPE, formula: newVal },
              };
              dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
            }}
          />
        </div>
      ) : (
        <DroppableColumnSection
          columns={instructions.aggColumn ? [instructions.aggColumn] : []}
          onColDropped={(col) => {
            const newInstructions = cloneDeep(instructions);
            newInstructions.aggColumn = {
              column: col,
              agg: defaultAggByType(col.type),
            };
            dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
          }}
          onColOptionChanged={(option) => {
            const newInstructions = cloneDeep(instructions);
            if (newInstructions.aggColumn)
              newInstructions.aggColumn.agg = { ...option, selectionValue: 'Aggregation' };
            dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
          }}
          onRemoveCol={() => {
            const newInstructions = cloneDeep(instructions);
            newInstructions.aggColumn = undefined;
            dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
          }}
          disableEdits={loading}
        />
      )}
      <SettingHeader name="Period Range" />
      <GenericDroppableColumnSection
        allowedTypes={[DATE, DATETIME, TIMESTAMP]}
        column={instructions.periodColumn ? instructions.periodColumn : undefined}
        selectedOption={
          instructions.periodColumn?.periodRange &&
          PERIOD_RANGE_OPTIONS[instructions.periodColumn?.periodRange]
        }
        options={Object.values(PERIOD_RANGE_OPTIONS)}
        onColDropped={(col) => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.periodColumn = {
            column: col,
            periodRange: defaultPeriodType(),
          };
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
        onColOptionChanged={(option) => {
          const newInstructions = cloneDeep(instructions);
          if (newInstructions.periodColumn) {
            newInstructions.periodColumn = {
              ...newInstructions.periodColumn,
              periodRange: option.id as PeriodRangeTypes,
            };
            if (
              option.id === PeriodRangeTypes.DATE_RANGE_INPUT ||
              option.id === PeriodRangeTypes.TIME_PERIOD_DROPDOWN
            ) {
              newInstructions.periodColumn = {
                ...newInstructions.periodColumn,
                customEndDate: undefined,
                customStartDate: undefined,
              };
            }
          }
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
        onRemoveCol={() => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.periodColumn = undefined;
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
        disableEdits={loading}
      />

      {instructions.periodColumn?.periodRange === PeriodRangeTypes.CUSTOM_RANGE && (
        <div className={classes.customRangeDateSelectors}>
          <ValueInput
            tall
            preventFutureDates
            selectedColumn={{
              name: instructions.periodColumn?.column.name || '',
              type: instructions.periodColumn?.column.type || '',
            }}
            selectedOperator={FILTER_OPERATOR_TYPES_BY_ID.DATE_IS_BETWEEN}
            filterValue={{
              startDate: instructions.periodColumn?.customStartDate,
              endDate: instructions.periodColumn?.customEndDate,
            }}
            onFilterValueUpdate={(newValue: FilterValueType) => {
              const newRange = newValue as FilterValueDateType;
              const newInstructions = cloneDeep(instructions);

              if (!newInstructions.periodColumn) return;

              newInstructions.periodColumn = {
                ...newInstructions.periodColumn,
                customStartDate: newRange.startDate,
                customEndDate: newRange.endDate,
              };

              dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
            }}
          />
        </div>
      )}
      {instructions.periodColumn?.periodRange === PeriodRangeTypes.DATE_RANGE_INPUT && (
        <div className={classes.customRangeDateSelectors}>
          <DropdownSelect
            filterable={false}
            selectedItem={
              instructions.periodColumn.rangeElemId
                ? {
                    id: instructions.periodColumn.rangeElemId,
                    name: instructions.periodColumn.rangeElemId,
                  }
                : undefined
            }
            onChange={(newValue) => {
              const newInstructions = cloneDeep(instructions);
              if (!newInstructions.periodColumn) return;

              newInstructions.periodColumn.rangeElemId = newValue.id;

              dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
            }}
            noSelectionText="Select an Input"
            options={filterForValidElementsForDateRangeInput(dashboardElements).map((elem) => ({
              id: elem.name,
              name: elem.name,
            }))}
            minimal
            fillWidth
            showIcon
          />
        </div>
      )}
      {instructions.periodColumn?.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN && (
        <div className={classes.customRangeDateSelectors}>
          <DropdownSelect
            filterable={false}
            selectedItem={
              instructions.periodColumn.timePeriodElemId
                ? {
                    id: instructions.periodColumn.timePeriodElemId,
                    name: instructions.periodColumn.timePeriodElemId,
                  }
                : undefined
            }
            onChange={(newValue) => {
              const newInstructions = cloneDeep(instructions);
              if (!newInstructions.periodColumn) return;

              newInstructions.periodColumn.timePeriodElemId = newValue.id;

              dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
            }}
            noSelectionText="Select an Input"
            options={filterForValidElementsForTimePeriodDropdown(dashboardElements).map((elem) => ({
              id: elem.name,
              name: elem.name,
            }))}
            minimal
            fillWidth
            showIcon
          />
        </div>
      )}
      {canPeriodRangeUseOffset(instructions.periodColumn?.periodRange) && (
        <InputWithTag
          className={classes.configInput}
          value={
            instructions.periodColumn?.trendDateOffset
              ? instructions.periodColumn.trendDateOffset?.toString()
              : '0'
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newInstructions = cloneDeep(instructions);
            if (!newInstructions?.periodColumn) return;
            newInstructions.periodColumn.trendDateOffset = parseInt(e.target.value);
            dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
          }}
          placeholder="0"
          label="Offset (Days Ago)"
          helpText="This sets the period range's offset e.g. if the period range is 'Last 4 Weeks' and the offset is 2, 
          the period range will be between 2 days ago and 4 weeks ago from 2 days ago"
        />
      )}
      <SettingHeader name="Comparison Range" />
      <GenericDropdownSection
        options={Object.values(PERIOD_COMPARISON_RANGE_TYPES)}
        selectedOption={
          PERIOD_COMPARISON_RANGE_TYPES[
            instructions.periodComparisonRange || PeriodComparisonRangeTypes.PREVIOUS_PERIOD
          ]
        }
        onOptionChanged={(option) => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.periodComparisonRange = option.id as PeriodComparisonRangeTypes;
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
        disableEdits={loading}
      />
      <SettingHeader name="Grouping" />
      <GenericDropdownSection
        options={Object.values(TREND_GROUPING_OPTIONS)}
        selectedOption={
          TREND_GROUPING_OPTIONS[instructions.trendGrouping || TrendGroupingOptions.WEEKLY]
        }
        onOptionChanged={(option) => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.trendGrouping = option.id as TrendGroupingOptions;
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
        disableEdits={loading}
      />
    </div>
  );
}

const filterForValidElementsForDateRangeInput = (dashboardElements?: DashboardElement[]) => {
  if (!dashboardElements) return [];
  return dashboardElements.filter((elem) => {
    if (elem.element_type !== DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) return false;
    const config = elem.config as DateRangePickerElemConfig;

    return !!config.defaultDateRange;
  });
};

const filterForValidElementsForTimePeriodDropdown = (dashboardElements?: DashboardElement[]) => {
  if (!dashboardElements) return [];
  return dashboardElements.filter((elem) => {
    if (elem.element_type !== DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN) return false;
    const config = elem.config as TimePeriodDropdownDashboardElemConfig;

    return !!config.defaultValue && !!config.values && config.values.length > 0;
  });
};
