/** @format */

import { Action } from 'reducers/rootReducer';
import { SupportedDataSource } from 'actions/types';

export interface SupportedDataSourcesReducerState {
  loading: boolean;
  dataSources?: SupportedDataSource[];
}

const teamDataReducerInitialState: SupportedDataSourcesReducerState = {
  loading: false,
};

export default (
  state: SupportedDataSourcesReducerState = teamDataReducerInitialState,
  action: Action,
): SupportedDataSourcesReducerState => {
  const newState = Object.assign({}, state);
  const { payload } = action;

  switch (action.type) {
    case 'FETCH_SUPPORTED_DATA_SOURCES_REQUEST':
      newState.loading = true;
      return newState;
    case 'FETCH_SUPPORTED_DATA_SOURCES_SUCCESS':
      newState.loading = false;
      newState.dataSources = payload.supported_sources;
      return newState;
    default:
      return state;
  }
};
