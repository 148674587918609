/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';

import ValueInput, {
  ValueInputProps,
} from 'pages/dashboardPage/DashboardDatasetView/Header/ValueInput';
import DropdownSelect from 'shared/DropdownSelect';
import FlexBox, { VerticalAlignment } from 'components/core/FlexBox';
import { FilterValueSourceType } from 'constants/types';
import { IconName } from '@blueprintjs/core';
import { DashboardElement } from 'types/dashboardTypes';
import { filterForValidFilterElementsBasedOnType } from 'utils/dashboardUtils';

const VALUE_SOURCE_TYPE_OPTIONS = {
  [FilterValueSourceType.INPUT]: {
    name: 'Manual',
    icon: 'manually-entered-data' as IconName,
    id: FilterValueSourceType.INPUT,
  },
  [FilterValueSourceType.VARIABLE]: {
    name: 'Variable',
    icon: 'variable' as IconName,
    id: FilterValueSourceType.VARIABLE,
  },
};

const useStyles = makeStyles(() => ({
  root: {
    '& input': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  varOrValDropdown: {
    marginRight: -1,

    '& .bp3-button': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  variableDropdown: {
    '& .bp3-button': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));

export type Props = ValueInputProps & {
  filterValueSource?: FilterValueSourceType;
  filterValueVariableId?: string;
  onFilterValueSourceSelect: (source: FilterValueSourceType) => void;
  onFilterValueVariableSelect: (variableId: string) => void;
  dashboardElements?: DashboardElement[];
};

export default function FilterConfigMenu({
  tall,
  selectedColumn,
  selectedOperator,
  filterValue,
  onFilterValueUpdate,
  filterValueSource,
  filterValueVariableId,
  onFilterValueSourceSelect,
  onFilterValueVariableSelect,
  dashboardElements,
}: Props) {
  const classes = useStyles();

  const variableOptions = filterForValidFilterElementsBasedOnType(
    dashboardElements,
    selectedOperator,
  );
  const selectedSourceType = filterValueSource || FilterValueSourceType.INPUT;

  return (
    <FlexBox verticalAlignment={VerticalAlignment.TOP} className={classes.root}>
      <DropdownSelect
        minimal
        fillWidth
        showIcon
        hideBtnCaret
        hideSelectedText
        containerClassName={classes.varOrValDropdown}
        filterable={false}
        disabled={variableOptions.length === 0}
        selectedItem={VALUE_SOURCE_TYPE_OPTIONS[selectedSourceType]}
        onChange={(newValue) => onFilterValueSourceSelect(newValue.id as FilterValueSourceType)}
        noSelectionText="Var"
        options={[
          VALUE_SOURCE_TYPE_OPTIONS[FilterValueSourceType.INPUT],
          VALUE_SOURCE_TYPE_OPTIONS[FilterValueSourceType.VARIABLE],
        ]}
      />
      {selectedSourceType === FilterValueSourceType.INPUT && (
        <ValueInput
          tall={tall}
          selectedColumn={selectedColumn}
          selectedOperator={selectedOperator}
          filterValue={filterValue}
          onFilterValueUpdate={onFilterValueUpdate}
        />
      )}
      {selectedSourceType === FilterValueSourceType.VARIABLE && (
        <DropdownSelect
          minimal
          fillWidth
          containerClassName={classes.variableDropdown}
          filterable={false}
          selectedItem={
            filterValueVariableId
              ? { id: filterValueVariableId, name: filterValueVariableId }
              : undefined
          }
          onChange={(newValue) => onFilterValueVariableSelect(newValue.id)}
          noSelectionText="Select Variable"
          options={variableOptions.map((elem) => ({ id: elem.name, name: elem.name }))}
        />
      )}
    </FlexBox>
  );
}
