/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import SidePaneHeader from 'components/SidePaneHeader';

export const SIDE_PANE_WIDTH = 350;
export const WIDE_SIDE_PANE_WIDTH = 400;
export const SIDE_PANE_HEADER_HEIGHT = 56;

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: Props) => ({
    width: props.wide ? WIDE_SIDE_PANE_WIDTH : SIDE_PANE_WIDTH,
    height: `100%`,
    backgroundColor: theme.palette.ds.white,
    borderRight: `1px solid ${theme.palette.ds.grey400}`,
    overflowY: 'auto',
    overflowX: 'hidden',
  }),
  header: (props: Props) => ({
    width: props.wide ? WIDE_SIDE_PANE_WIDTH : undefined,
  }),
}));

type Props = {
  backButtonPath?: string;
  title?: string;
  wide?: boolean;
};

const SidePane: React.FunctionComponent<Props> = (props) => {
  const { backButtonPath, children, title } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {title && (
        <SidePaneHeader className={classes.header} title={title} backButtonPath={backButtonPath} />
      )}
      {children}
    </div>
  );
};

export default SidePane;
