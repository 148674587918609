/** @format */

import React from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';

import SettingHeader from '../SettingHeader';
import DroppableColumnSection from './DroppableColumnSection';

import { OPERATION_TYPES, V2BoxPlotInstructions } from 'constants/types';
import { INTEGER_DATA_TYPE, FLOAT } from 'constants/dataConstants';
import { updateClonedVisualizationInstructions } from 'actions/dataPanelConfigActions';
import { resolveCategoryColDropped } from './utils';

const useStyles = makeStyles(() => ({
  root: {},
}));

export type Props = {
  instructions: V2BoxPlotInstructions;
  chartType: string;
  loading?: boolean;
};

export default function BoxPlotConfig({ instructions, chartType, loading }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const updateInstructions = (modifyInstructions: (instructions: V2BoxPlotInstructions) => void) =>
    updateClonedVisualizationInstructions(
      instructions,
      chartType as OPERATION_TYPES,
      dispatch,
      modifyInstructions,
    );

  return (
    <div className={classes.root}>
      <SettingHeader name="Grouping" />
      <DroppableColumnSection
        columns={instructions.groupingColumn ? [instructions.groupingColumn] : []}
        onColDropped={(col) => {
          updateInstructions((instructions) => {
            instructions.groupingColumn = resolveCategoryColDropped(
              col,
              instructions.groupingColumn,
            );
          });
        }}
        onColOptionChanged={(option) => {
          updateInstructions((instructions) => {
            if (instructions.groupingColumn) instructions.groupingColumn.bucket = option;
          });
        }}
        onRemoveCol={() => {
          updateInstructions((instructions) => {
            instructions.groupingColumn = undefined;
          });
        }}
        disableEdits={loading}
      />
      <SettingHeader name="Calculation" />
      <DroppableColumnSection
        columns={
          instructions.calcColumns ? instructions.calcColumns.map((column) => ({ column })) : []
        }
        allowedTypes={[INTEGER_DATA_TYPE, FLOAT]}
        onColDropped={(column) => {
          if (_.some(instructions.calcColumns ?? [], (col) => col.name === column.name)) {
            return;
          }
          updateInstructions((instructions) => {
            instructions.calcColumns = instructions.calcColumns
              ? [...instructions.calcColumns, column]
              : [column];
          });
        }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onColOptionChanged={() => {}}
        onRemoveCol={(removedCol) => {
          updateInstructions((instructions) => {
            instructions.calcColumns = instructions.calcColumns?.filter(
              (col) => col.name !== removedCol,
            );
            if (instructions.boxPlotFormat?.seriesLabelByColumn) {
              instructions.boxPlotFormat.seriesLabelByColumn = _.omit(
                instructions.boxPlotFormat.seriesLabelByColumn,
                removedCol ?? '',
              );
            }
          });
        }}
        disableEdits={loading}
      />
    </div>
  );
}
