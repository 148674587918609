/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { dayjs, DATE_FORMAT_TO_LOCALIZED_FORMAT } from 'utils/localizationUtils';
import { withStyles, WithStyles } from '@material-ui/styles';
import { parseISO } from 'date-fns';
import { Button } from '@blueprintjs/core';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import DatePickerInput from 'shared/DatePickerInput';
import DropdownSelect from 'shared/DropdownSelect';

import { DateRangePickerElemConfig, DEFAULT_DATE_RANGES } from 'types/dashboardTypes';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { getDefaultRangeValues } from 'utils/dashboardUtils';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    rangeDropdown: {
      flex: '1 1 auto',

      '& .bp3-button': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },

      '&.empty-dropdown': {
        paddingBottom: 3,
      },
    },
    datePickerBtn: {
      '&.bp3-button': {
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        // To match blueprint input
        backgroundColor: theme.palette.ds.white,
        borderRadius: 3,

        '&:hover': {
          backgroundColor: theme.palette.ds.white,
        },
      },
    },

    datePicker: {
      flex: '1 1 auto',
      minWidth: 0,
    },
    datePickerBtnWithDropdown: {
      '&.bp3-button': {
        borderTopLeftRadius: '0 !important',
        borderBottomLeftRadius: '0 !important',
        borderLeft: '0 !important',
      },
    },
    datePickerBtnWithCancelBtn: {
      '&.bp3-button': {
        paddingRight: 25,
      },
    },
  });

type PassedProps = {
  className?: string;
  config: DateRangePickerElemConfig;
  value?: any;
  onNewValueSelect: (newvalue: any) => void;
  disabled?: boolean;
  withPortal?: boolean;
  noLabel?: boolean;
  noDropdown?: boolean;
  portalId?: string;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  currentValue?: any;
  isDatePickerOpen: boolean;
};

class DashboardDateRangePickerElement extends React.Component<Props, State> {
  state: State = {
    currentValue: this.props.value,
    isDatePickerOpen: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (!_.isEqual(prevProps.value, this.props.value)) {
      this.setState({
        currentValue: this.props.value,
      });
    }
  }

  render() {
    const {
      classes,
      className,
      config,
      value,
      onNewValueSelect,
      disabled,
      withPortal,
      noDropdown,
      noLabel,
      portalId,
    } = this.props;
    const { currentValue } = this.state;

    const startDate = currentValue?.startDate;
    const endDate = currentValue?.endDate;

    const startDateParsed = typeof startDate === 'string' ? parseISO(startDate) : startDate;
    const endDateParsed = typeof endDate === 'string' ? parseISO(endDate) : endDate;

    const selectedRange = getSelectedRangeFromDates(startDateParsed, endDateParsed);

    return (
      <div className={cx(classes.root, className)}>
        {config.includeRangeDropdown && !noDropdown && (
          <DropdownSelect
            minimal
            fillWidth
            showIcon
            containerClassName={cx(classes.rangeDropdown, {
              'empty-dropdown': !startDateParsed && !endDateParsed,
            })}
            filterable={false}
            label={config.label}
            useFakeLabel={config.label === ''}
            selectedItem={
              selectedRange && {
                id: selectedRange,
                name: selectedRange,
              }
            }
            onChange={(newValue) => {
              const { startDate: newStart, endDate: newEnd } = getDefaultRangeValues(
                newValue.id as DEFAULT_DATE_RANGES,
              );

              this.setState({
                currentValue: {
                  startDate: newStart,
                  endDate: newEnd,
                },
              });

              onNewValueSelect({
                startDate: newStart,
                endDate: newEnd,
              });
            }}
            noSelectionText="Select a range"
            options={Object.values(DEFAULT_DATE_RANGES).map((range) => ({
              id: range,
              name: range,
            }))}
          />
        )}
        <DatePickerInput
          selectsRange
          // There's a bug in the react date picker library where portalId is not used if withPortal is set.
          withPortal={!portalId ? withPortal : undefined}
          portalId={portalId}
          className={classes.datePicker}
          disabled={disabled}
          selectedValue={startDateParsed}
          startDate={startDateParsed}
          endDate={endDateParsed}
          label={noLabel ? undefined : config.label}
          useFakeLabel={noLabel ? undefined : config.includeRangeDropdown || config.label === ''}
          onNewValueSelect={(date) => {
            date = date as [Date, Date];

            this.setState({
              currentValue: {
                startDate: date[0],
                endDate: date[1],
              },
            });

            if (date[0] && date[1]) {
              onNewValueSelect({
                startDate: date[0],
                endDate: date[1],
              });
            }
          }}
          showCancelBtn={!config.disableCancel}
          onCancelClick={() => onNewValueSelect(undefined)}
          onCalendarOpen={() => {
            this.setState({ isDatePickerOpen: true });
          }}
          onCalendarClose={() => {
            const readyToCompute = !!(startDateParsed && endDateParsed);

            if (!readyToCompute) {
              this.setState({ currentValue: value });
            }

            this.setState({ isDatePickerOpen: false });
          }}
          monthsShown={2}
          customInput={(onClick, ref) => (
            <Button
              onClick={onClick}
              ref={ref}
              className={cx(
                classes.datePickerBtn,
                { [classes.datePickerBtnWithCancelBtn]: !config.disableCancel },
                { [classes.datePickerBtnWithDropdown]: config.includeRangeDropdown },
                {
                  [GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.buttonBorderActive]: this.state
                    .isDatePickerOpen,
                },
                GLOBAL_STYLE_CLASSNAMES.container.outline.buttonBorder,
                GLOBAL_STYLE_CLASSNAMES.container.shadow.buttonShadow,
                GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont,
                GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.buttonBorderHover,
              )}
              text={renderInputButtonText(startDateParsed, endDateParsed)}
              fill
            />
          )}
        />
      </div>
    );
  }
}

export const renderInputButtonText = (startDate: Date | null, endDate: Date | null) => {
  // We show the dates in the date picker in local time but store them in the `variables` in
  // UTC. Similar to the `DatePickerInput`, we need to convert to local time when displaying
  const currentDate = dayjs();

  const startDayjs = dayjs(startDate || undefined);
  const startDateInYear = startDayjs.isSame(currentDate, 'year');
  const startFormat = startDateInYear
    ? DATE_FORMAT_TO_LOCALIZED_FORMAT['MMM D']
    : DATE_FORMAT_TO_LOCALIZED_FORMAT['MMM D, YYYY'];

  const endDayjs = dayjs(endDate || undefined);
  const endDateInYear = endDayjs.isSame(currentDate, 'year');
  const endFormat = endDateInYear
    ? DATE_FORMAT_TO_LOCALIZED_FORMAT['MMM D']
    : DATE_FORMAT_TO_LOCALIZED_FORMAT['MMM D, YYYY'];

  if (startDate && endDate) {
    return `${startDayjs.format(startFormat)}—${endDayjs.format(endFormat)}`;
  } else if (startDate && !endDate) {
    return `${startDayjs.format(startFormat)}— `;
  }

  return '';
};

const getSelectedRangeFromDates = (startDate: Date | null, endDate: Date | null) => {
  const startDayjs = dayjs(startDate || undefined);
  const endDayjs = dayjs(endDate || undefined);

  let matchingRange = undefined;

  Object.values(DEFAULT_DATE_RANGES).forEach((rangeType) => {
    const { startDate: defaultStart, endDate: defaultEnd } = getDefaultRangeValues(rangeType);

    if (startDayjs.diff(defaultStart, 'days') === 0 && endDayjs.diff(defaultEnd, 'days') === 0) {
      matchingRange = rangeType;
    }
  });

  return matchingRange;
};

export default withStyles(styles)(DashboardDateRangePickerElement);
