/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { WithStyles } from '@material-ui/core/index';
import { connect } from 'react-redux';

import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import XAxisConfiguration from 'pages/dataPanelEditorPage/visualizeSections/xAxisConfiguration';
import YAxisConfiguration from 'pages/dataPanelEditorPage/visualizeSections/yAxisConfiguration';
import SelectChartViewConfiguration from 'pages/dataPanelEditorPage/visualizeSections/selectChartViewConfiguration';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { VisualizeLineOrBarChartInstructions, OPERATION_TYPES, SortClause } from 'constants/types';
import SortConfiguration from './SortConfigMenu';
import { DataPanelTemplate } from 'actions/types';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
  });

type PassedProps = {
  instructions: VisualizeLineOrBarChartInstructions;
  operationType: OPERATION_TYPES;
  dataPanelTemplate: DataPanelTemplate;
  sortClauses?: SortClause[];
};

type Props = PassedProps &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  RouteComponentProps &
  ReturnType<typeof mapStateToProps>;

class VisualizeLineOrBarChartSection extends React.Component<Props> {
  render() {
    const {
      classes,
      dataPanelTemplate,
      instructions,
      operationType,
      updateVisualizeOperation,
      sortClauses,
    } = this.props;
    if (!dataPanelTemplate || !dataPanelTemplate._schema) return <div></div>;

    return (
      <div className={classes.root}>
        <ConfigSectionHeader title="Configure axes" />
        <XAxisConfiguration
          headerText={this.getXAxisConfigText()}
          schema={dataPanelTemplate._schema}
          visualizeLineOrBarChartInstructions={instructions}
          updateVisualizeOperation={(
            newVisualizeLineChartInstructions: VisualizeLineOrBarChartInstructions,
          ) => {
            updateVisualizeOperation(newVisualizeLineChartInstructions, operationType);
          }}
        />
        <YAxisConfiguration
          headerText={this.getYAxisConfigText()}
          operationType={operationType}
          schema={dataPanelTemplate._schema}
          instructions={instructions}
          updateVisualizeOperation={(
            newVisualizeLineChartInstructions: VisualizeLineOrBarChartInstructions,
          ) => {
            updateVisualizeOperation(newVisualizeLineChartInstructions, operationType);
          }}
        />
        {operationType !== OPERATION_TYPES.VISUALIZE_LINE_CHART && (
          <SortConfiguration
            schema={dataPanelTemplate._schema}
            visualizeLineOrBarChartInstructions={instructions}
            sortClauses={sortClauses}
          />
        )}
        {operationType !== OPERATION_TYPES.VISUALIZE_COMBO_CHART && (
          <div>
            <ConfigSectionHeader title="Select view" />
            <SelectChartViewConfiguration
              operationType={operationType}
              visualizeLineOrBarChartInstructions={instructions}
              updateVisualizeOperation={(
                newVisualizeLineChartInstructions: VisualizeLineOrBarChartInstructions,
              ) => {
                updateVisualizeOperation(newVisualizeLineChartInstructions, operationType);
              }}
            />
          </div>
        )}
      </div>
    );
  }

  getXAxisConfigText = () => {
    const { operationType } = this.props;

    if (operationType === OPERATION_TYPES.VISUALIZE_HORIZ_BAR_CHART) return 'Y-AXIS';
    return 'X-AXIS';
  };

  getYAxisConfigText = () => {
    const { operationType } = this.props;

    if (operationType === OPERATION_TYPES.VISUALIZE_HORIZ_BAR_CHART) return 'X-AXIS';
    return 'Y-AXIS';
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateVisualizeOperation,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VisualizeLineOrBarChartSection)),
);
