/** @format */

import { defineAPIAction } from 'actions/actionUtils';
import { ACTION } from './types';

export const { actionFn: createDashboardEmail } = defineAPIAction(
  ACTION.CREATE_DASHBOARD_EMAIL,
  'emails',
  'create_email_cadence',
  'POST',
);

export const { actionFn: deleteDashboardEmail } = defineAPIAction(
  ACTION.DELETE_DASHBOARD_EMAIL,
  'emails',
  'delete_email_cadence',
  'POST',
);

export const { actionFn: editDashboardEmail } = defineAPIAction(
  ACTION.EDIT_DASHBOARD_EMAIL,
  'emails',
  'edit_email_cadence',
  'POST',
);

export const { actionFn: fetchEmailCadenceList } = defineAPIAction(
  ACTION.FETCH_EMAIL_CADENCE_LIST,
  'emails',
  'get_email_cadence_list',
  'GET',
);

export const { actionFn: sendTestEmail } = defineAPIAction(
  ACTION.SEND_TEST_EMAIL,
  'emails',
  'send_test_email',
  'POST',
);
