/** @format */

import { Position, Toaster } from '@blueprintjs/core';

// WARNING: Don't import this into a file present in the embedded component since the
// toaster loads prior to the DOM loading and so it can't attach to the DOM and crashes

export const AppToaster = Toaster.create({
  className: 'recipe-toaster',
  position: Position.BOTTOM,
  // Weird Blueprint bug where if we make maxToasts 1, the file upload progress toast (fileUtils.js) dismisses itself.
  // TODO: figure out what the hell is up with blue print
  maxToasts: 3,
});
