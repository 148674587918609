/** @format */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { ReduxState } from 'reducers/rootReducer';

import SetupFlowPage from 'components/pages/setupFlowPage';

import { loginUser } from 'actions/authAction';
import { pageView } from 'analytics/exploAnalytics';

import { ROUTES } from 'constants/routes';

const useStyles = makeStyles((theme: Theme) => ({
  emaiSentText: {
    color: theme.palette.ds.white,
    fontSize: 14,
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
}));

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & RouteComponentProps;

const CheckYourEmailPageV2 = (props: Props) => {
  return (
    <SetupFlowPage
      showLogoutButton
      title="Verify your email"
      pageBody={<CheckYourEmailPagebody {...props} />}
    />
  );
};

const CheckYourEmailPagebody = (props: Props) => {
  const { currentUser, history } = props;
  useEffect(() => {
    pageView('Check Your Email');
    if (currentUser.email_verified) {
      history.push(ROUTES.HOME_APP_PAGE);
    }
  }, [currentUser, history]);

  const classes = useStyles();

  return (
    <div>
      <div className={classes.emaiSentText}>
        In the next few minutes, you will receive an email sent to {props.currentUser.email}. Please
        click the verification link to get started!
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  loginUser,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckYourEmailPageV2));
