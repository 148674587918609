/** @format */

import { Action } from 'reducers/rootReducer';
import { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { createReducer, resolveSecondaryData } from './utils';

import {
  DashboardVersionConfig,
  DataPanelTemplate,
  DashboardVersionInfo,
  ACTION,
} from 'actions/types';
import { EMPTY_FILTER_CONFIG, V2_VIZ_INSTRUCTION_TYPE } from 'constants/dataConstants';
import {
  OPERATION_TYPES,
  VisualizeTableInstructions,
  VisualizeLineOrBarChartInstructions,
  VisualizeNumberInstructions,
  VisualizeChoroplethMapInstructions,
  VisualizeFunnelInstruction,
  VisualizeHeatMapInstructions,
  VisualizePieChartInstruction,
  ViusalizePivotChartInstructions,
  V2TwoDimensionChartInstructions,
  V2KPIChartInstructions,
  V2BoxPlotInstructions,
  V2KPITrendInstructions,
} from 'constants/types';
import {
  CreateDashboardTemplateDataPanelV2Args,
  CreateDashboardDatasetV2Args,
  FetchEditorDatasetDataPayload,
  FetchEditorDatasetDataResponse,
  DeleteDataPanelV2Args,
  UpdateElementConfigV2Args,
  DeleteElementConfigV2Args,
  DeleteDashboardDatasetV2Args,
  RenameDataPanelV2Args,
  SwitchEditingDashboardVersionArgs,
  PublishNewDashboardVersionResponse,
  SaveDashboardElementUpdatesArgs,
  CreateDashboardElementArgs,
  EditDashboardDatasetNameArgs,
  SaveDashboardDatasetQueryArgs,
  UpdateDashboardParamsArgs,
  SetDPTLoadingArgs,
  DuplicateDashboardItemArgs,
} from 'actions/dashboardV2Actions';
import { UpdateAdHocOperationInstructionsArgs } from 'actions/dataPanelTemplateAction';
import {
  UpdateSelectedChartPayload,
  ResetEditedDataPanelPayload,
  UpdateVisualizeOperationPayload,
  CreateFilterClausePayload,
  DeleteFilterClausePayload,
  SelectFilterColumnPayload,
  SelectFilterOperatorPayload,
  UpdateFilterValuePayload,
  UpdateFilterMatchPayload,
  CreateSortClausePayload,
  DeleteSortClausePayload,
  SelectSortColumnPayload,
  SelectSortOrderPayload,
  CreatePivotedOnColPayload,
  DeletePivotedOnColPayload,
  UpdatePivotedOnColPayload,
  CreateAggregationPayload,
  DeleteAggrgationPayload,
  UpdateAggregationPayload,
  UpdateFilterValueSourcePayload,
  UpdateFilterValueVariablePayload,
  UpdateGeneralFormatOptionsPayload,
} from 'actions/dataPanelConfigActions';
import { EMPTY_DATA_PANEL_STATE } from 'constants/dashboardConstants';
import _, { cloneDeep } from 'lodash';
import {
  isDataPanelReadyToCompute,
  shouldRecomputeDataForDataPanel,
  getDatapanelConfigReducerState,
} from 'utils/dataPanelConfigUtils';
import {
  deriveVisInstrFromPivotInstr,
  deriveFunnelChartInstr,
  derivePieChartInstr,
} from 'utils/reducers/datapanelConfigReducerUtils';
import {
  EMPTY_FILTER_CLAUSE,
  EMPTY_PIVOTED_ON_COL,
  EMPTY_AGGREGATION,
  EMPTY_SORT_CLAUSE,
} from 'constants/dataPanelEditorConstants';
import {
  getDefaultElementName,
  removeUnsavedDashboardConfigFields,
  updateUserInputFieldsWithNewElemName,
  updateUserInputFieldsWithDeletedElem,
  newOperatorShouldClearSelectedVariable,
  newOperatorDoesntHaveVariableOption,
  resolveSecondaryLayout,
  getLayoutHeightInRows,
  placeElementInLayout as placeDuplicatedElementInLayout,
  addItemToConfigLayouts,
} from 'utils/dashboardUtils';
import {
  ContainerElemConfig,
  DashboardElement,
  DASHBOARD_ELEMENT_TYPES,
} from 'types/dashboardTypes';

export interface DashboardEditConfigReducerState {
  currentEditingDptId?: string;
  config?: DashboardVersionConfig;
  versionInfo?: DashboardVersionInfo;
}

const initialState: DashboardEditConfigReducerState = {};

const switchCurrentlyEditingDashboardVersion = (
  newState: DashboardEditConfigReducerState,
  action: PayloadAction<SwitchEditingDashboardVersionArgs>,
) => {
  const { dashboardVersion } = action.payload;

  if (dashboardVersion.version_number === newState.versionInfo?.version_number) return newState;
  newState.config = dashboardVersion.configuration;
  newState.versionInfo = {
    is_draft: dashboardVersion.is_draft,
    version_number: dashboardVersion.version_number,
    change_comments: dashboardVersion.change_comments,
  };
  return newState;
};

const publishNewDashboardVersion = (
  newState: DashboardEditConfigReducerState,
  action: PayloadAction<PublishNewDashboardVersionResponse>,
) => {
  const { dashboard_version } = action.payload;
  newState.versionInfo = {
    is_draft: dashboard_version.is_draft,
    version_number: dashboard_version.version_number,
    change_comments: dashboard_version.change_comments,
  };
  return newState;
};

const fetchDashboardTemplateSuccess = (
  newState: DashboardEditConfigReducerState,
  action: Action,
) => {
  const { payload } = action;
  newState.config = payload.dashboard_version.configuration;
  newState.versionInfo = {
    is_draft: payload.dashboard_version.is_draft,
    version_number: payload.dashboard_version.version_number,
    change_comments: payload.dashboard_version.change_comments,
  };
  return newState;
};

const clearReducer = (newState: DashboardEditConfigReducerState) => {
  newState.config = undefined;
  newState.versionInfo = undefined;
  return newState;
};

const fetchDataPanelTemplateError = (newState: DashboardEditConfigReducerState, action: Action) => {
  const { payload } = action;
  if (!newState.config) return newState;
  newState.config.data_panels[payload.postData.id]._loading = false;
  newState.config.data_panels[payload.postData.id]._error =
    'There was an error fetching the results';
  return newState;
};

const fetchDataPanelTemplateRequest = (
  newState: DashboardEditConfigReducerState,
  action: Action,
) => {
  const { payload } = action;
  if (!newState.config) return newState;
  newState.config.data_panels[payload.postData.id]._loading = true;
  if (payload.postData)
    newState.config.data_panels[payload.postData.id]._adHocOperationInstructions = {
      ...newState.config.data_panels[payload.postData.id]._adHocOperationInstructions,
      currentPage: payload.postData.page_number || 1,
      sortInfo: payload.postData.sort_info,
      filterInfo: payload.postData.filter_info || { ...EMPTY_FILTER_CONFIG },
    };
  return newState;
};

const fetchDataPanelTemplateSuccess = (
  newState: DashboardEditConfigReducerState,
  action: Action,
) => {
  const { payload } = action;
  if (!newState.config) return newState;
  newState.config.data_panels[payload.postData.id] = {
    ...newState.config.data_panels[payload.postData.id],
    ...payload.data_panel_template,
  };
  newState.config.data_panels[payload.postData.id]._loading = false;
  if (payload.postData)
    newState.config.data_panels[payload.postData.id]._adHocOperationInstructions = {
      ...newState.config.data_panels[payload.postData.id]._adHocOperationInstructions,
      currentPage: payload.postData.page_number || 1,
      sortInfo: payload.postData.sort_info,
      filterInfo: payload.postData.filter_info || { ...EMPTY_FILTER_CONFIG },
    };

  // handle deriving visualization for v2 data panels
  const updatedDPT = newState.config.data_panels[payload.postData.id];

  if (
    payload.postData.op_type === OPERATION_TYPES.GROUP_BY &&
    updatedDPT.group_by_op &&
    updatedDPT.visualize_op
  ) {
    updatedDPT.visualize_op.instructions.VISUALIZE_LINE_OR_BAR_CHART = deriveVisInstrFromPivotInstr(
      updatedDPT.group_by_op,
      payload.data_panel_template._schema,
      updatedDPT.visualize_op.instructions.VISUALIZE_LINE_OR_BAR_CHART,
    );
    updatedDPT.visualize_op.instructions.VISUALIZE_FUNNEL = deriveFunnelChartInstr(
      updatedDPT.group_by_op,
      payload.data_panel_template._schema,
    );
    updatedDPT.visualize_op.instructions.VISUALIZE_PIE_CHART = derivePieChartInstr(
      updatedDPT.group_by_op,
      payload.data_panel_template._schema,
    );
  }

  return newState;
};

const fetchSecondaryDataRequest = (newState: DashboardEditConfigReducerState, action: Action) => {
  const { payload } = action;
  if (!newState.config) return newState;

  const currentDataPanel = newState.config.data_panels[payload.postData.id];

  if (currentDataPanel._outstandingSecondaryDataRequests === undefined) {
    currentDataPanel._outstandingSecondaryDataRequests = 0;
  }

  currentDataPanel._outstandingSecondaryDataRequests++;

  return newState;
};

const fetchSecondaryDataSuccess = (newState: DashboardEditConfigReducerState, action: Action) => {
  const { payload } = action;
  if (!newState.config) return newState;

  const currentDataPanel = newState.config.data_panels[payload.postData.id];

  // outstanding requests should never be undefined, but need to appease TS
  if (currentDataPanel._outstandingSecondaryDataRequests !== undefined) {
    currentDataPanel._outstandingSecondaryDataRequests--;
  }

  currentDataPanel._secondaryData = resolveSecondaryData(
    currentDataPanel,
    payload.data_panel_template as DataPanelTemplate,
  );

  return newState;
};

const fetchDataPanelTemplateRowCountSuccess = (
  newState: DashboardEditConfigReducerState,
  action: Action,
) => {
  const { payload } = action;
  if (!newState.config) return newState;
  if (payload.postData)
    newState.config.data_panels[payload.postData.id]._total_row_count = payload._total_row_count;
  return newState;
};

const downloadDataPanelTemplateCsvStart = (
  config: DashboardVersionConfig,
  payload: { postData: { id: string } },
) => {
  config.data_panels[payload.postData.id]._downloadCsvLoading = true;
  return config;
};

const downloadDataPanelTemplateCsvComplete = (
  config: DashboardVersionConfig,
  payload: { postData: { id: string } },
) => {
  config.data_panels[payload.postData.id]._downloadCsvLoading = false;
  return config;
};

const updateDashboardTemplateLayout = (
  newState: DashboardEditConfigReducerState,
  action: Action,
) => {
  const { payload } = action;
  if (!newState.config) return newState;

  newState.config.dashboard_layout = cloneDeep(payload.layout);

  if (newState.config.pdf_layout) {
    newState.config.pdf_layout = resolveSecondaryLayout(payload.layout, newState.config.pdf_layout);
  }

  return newState;
};

const updateDashboardPdfLayout = (newState: DashboardEditConfigReducerState, action: Action) => {
  const { payload } = action;
  if (!newState.config) return newState;

  newState.config.pdf_layout = cloneDeep(payload.layout);

  return newState;
};

const createDashboardElement = (
  config: DashboardVersionConfig,
  payload: CreateDashboardElementArgs,
) => {
  const newElement = {
    id: `dash${payload.dashId}-${uuidv4()}`,
    name: getDefaultElementName(payload.elementType, config),
    element_type: payload.elementType,
    config: payload.initialConfig,
    container_id: payload.containerId,
  };

  config.elements[newElement.id] = newElement;

  addItemToConfigLayouts(config, payload, newElement.id);

  return config;
};

const createTemplateDataPanel = (
  newState: DashboardEditConfigReducerState,
  action: PayloadAction<CreateDashboardTemplateDataPanelV2Args>,
) => {
  const { payload } = action;
  if (!newState.config) return newState;

  const newDataPanel = EMPTY_DATA_PANEL_STATE(
    `dash${payload.dashId}-${uuidv4()}`,
    payload.name,
    payload.datasetId,
    payload.vizType,
    payload.containerId,
  );
  newState.config.data_panels[newDataPanel.id] = newDataPanel;

  addItemToConfigLayouts(newState.config, payload, newDataPanel.id);

  return newState;
};

const duplicateDashboardItem = (
  newState: DashboardEditConfigReducerState,
  action: PayloadAction<DuplicateDashboardItemArgs>,
) => {
  if (!newState.config) return newState;
  const { dashboardItem, itemType, dashId } = action.payload;
  const config = newState.config;

  // either the whole dashboard or the container that holds this element
  let layout = config.dashboard_layout;

  if (dashboardItem.container_id) {
    const containerConfig = config.elements[dashboardItem.container_id]
      .config as ContainerElemConfig;

    // if we're in a container, then we want to be editing the container's layout
    layout = containerConfig.layout;
  }

  const elem = layout.find((item) => item.i === dashboardItem.id);
  if (!elem) return newState;

  const newElementConfig = cloneDeep(dashboardItem);
  const newElementLayout = cloneDeep(elem);
  const newLayout = cloneDeep(layout);

  placeDuplicatedElementInLayout({
    newElementLayout: newElementLayout,
    newElementConfig: newElementConfig,
    layout: newLayout,
    config: config,
    // place the cloned item at the bottom of the column that contains the parent element
    yStart: getLayoutHeightInRows(layout, elem?.x, elem.x + elem.w - 1),
    elementIsDataPanel: itemType === DASHBOARD_ELEMENT_TYPES.DATA_PANEL,
    dashId: dashId,
  });

  if (itemType === DASHBOARD_ELEMENT_TYPES.CONTAINER) {
    // for container elements, we have to clone all of the contained elements
    const newContainerConfig = (newElementConfig as DashboardElement).config as ContainerElemConfig;
    // clone this so we can iterate over it
    const oldLayout = cloneDeep(newContainerConfig.layout);
    // but clear the layout so that we can add to it fresh
    newContainerConfig.layout = [];

    oldLayout.forEach((newContainerElementLayout) => {
      const elementIsDataPanel = newContainerElementLayout.i in config.data_panels;
      // we have to clone deep here so that only the duplicated panel gets the new container_id below
      const newContainerElementConfig = cloneDeep(
        elementIsDataPanel
          ? config.data_panels[newContainerElementLayout.i]
          : config.elements[newContainerElementLayout.i],
      );

      // we have to manually swap the container id over to our new duped container
      newContainerElementConfig.container_id = newElementConfig.id;
      placeDuplicatedElementInLayout({
        newElementLayout: newContainerElementLayout,
        newElementConfig: newContainerElementConfig,
        layout: newContainerConfig.layout,
        config: config,
        yStart: newContainerElementLayout.y,
        elementIsDataPanel: elementIsDataPanel,
        dashId: dashId,
      });
    });
  }

  return newState;
};

const setDptLoading = (config: DashboardVersionConfig, payload: SetDPTLoadingArgs) => {
  payload.ids.forEach((id) => {
    if (config.data_panels[id]) config.data_panels[id]._loading = payload.loading;
  });
  return config;
};

const fetchDatasetDataRequest = (
  newState: DashboardEditConfigReducerState,
  action: PayloadAction<FetchEditorDatasetDataPayload & FetchEditorDatasetDataResponse>,
) => {
  const { payload } = action;
  if (!newState.config) return newState;

  const fetchingDataset = newState.config.datasets[payload.postData.dataset.id];
  if (fetchingDataset) {
    fetchingDataset._schema = undefined;
    fetchingDataset._rows = undefined;
    fetchingDataset._total_row_count = undefined;
    fetchingDataset._error = undefined;
    fetchingDataset._loading = true;
  }
  return newState;
};

const fetchDatasetDataError = (
  newState: DashboardEditConfigReducerState,
  action: PayloadAction<FetchEditorDatasetDataPayload & FetchEditorDatasetDataResponse>,
) => {
  const { payload } = action;
  if (!newState.config) return newState;

  const fetchingDataset = newState.config.datasets[payload.postData.dataset.id];
  if (fetchingDataset) {
    fetchingDataset._error = payload.dataset_preview
      ? payload.dataset_preview._error
      : 'Internal Error';
    fetchingDataset._loading = false;
  }
  return newState;
};

const fetchDatasetDataSuccess = (
  newState: DashboardEditConfigReducerState,
  action: PayloadAction<FetchEditorDatasetDataPayload & FetchEditorDatasetDataResponse>,
) => {
  const { payload } = action;
  if (!newState.config) return newState;

  const fetchingDataset = newState.config.datasets[payload.postData.dataset.id];
  if (fetchingDataset) {
    fetchingDataset._schema = payload.dataset_preview?.schema;
    fetchingDataset._rows = payload.dataset_preview?._rows;
    fetchingDataset._error = undefined;
    fetchingDataset._loading = false;
  }
  return newState;
};

const fetchDatasetRowCountSuccess = (
  newState: DashboardEditConfigReducerState,
  action: PayloadAction<FetchEditorDatasetDataPayload & FetchEditorDatasetDataResponse>,
) => {
  const { payload } = action;
  if (!newState.config) return newState;

  const fetchingDataset = newState.config.datasets[payload.postData.dataset.id];
  if (fetchingDataset) {
    fetchingDataset._total_row_count = payload._total_row_count;
  }
  return newState;
};

const createNewDataset = (
  config: DashboardVersionConfig,
  payload: CreateDashboardDatasetV2Args,
) => {
  const newDataset = {
    id: `dash${payload.dashId}-${uuidv4()}`,
    table_name: payload.name,
    _is_new: true,
    parent_schema_id: payload.parentSchemaId,
  };
  config.datasets[newDataset.id] = newDataset;
  return config;
};

const editDashboardDatasetName = (
  config: DashboardVersionConfig,
  payload: EditDashboardDatasetNameArgs,
) => {
  config.datasets[payload.datasetId].table_name = payload.name;
  return config;
};

const deleteDashobardDataset = (
  config: DashboardVersionConfig,
  payload: DeleteDashboardDatasetV2Args,
) => {
  delete config.datasets[payload.datasetId];
  return config;
};

const saveDashboardDatasetQuery = (
  config: DashboardVersionConfig,
  payload: SaveDashboardDatasetQueryArgs,
) => {
  config.datasets[payload.dataset.id].query = payload.query;
  config.datasets[payload.dataset.id].schema = payload.schema;
  return config;
};

const updateVisualizeOperation = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: UpdateVisualizeOperationPayload,
) => {
  const oldDPT = cloneDeep(editingDPT);

  if (editingDPT && editingDPT.visualize_op.instructions) {
    if (payload.operationType === OPERATION_TYPES.VISUALIZE_TABLE) {
      editingDPT.visualize_op.instructions.VISUALIZE_TABLE = payload.visualizeInstructions as VisualizeTableInstructions;
    } else if (
      payload.operationType === OPERATION_TYPES.VISUALIZE_BAR_CHART ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_LINE_CHART ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_HORIZ_BAR_CHART ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_COMBO_CHART
    ) {
      editingDPT.visualize_op.instructions.VISUALIZE_LINE_OR_BAR_CHART = payload.visualizeInstructions as VisualizeLineOrBarChartInstructions;
    } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_NUMBER) {
      editingDPT.visualize_op.instructions.VISUALIZE_NUMBER = payload.visualizeInstructions as VisualizeNumberInstructions;
    } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP) {
      editingDPT.visualize_op.instructions.VISUALIZE_CHOROPLETH_MAP = payload.visualizeInstructions as VisualizeChoroplethMapInstructions;
    } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_FUNNEL) {
      editingDPT.visualize_op.instructions.VISUALIZE_FUNNEL = payload.visualizeInstructions as VisualizeFunnelInstruction;
    } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_PIE_CHART) {
      editingDPT.visualize_op.instructions.VISUALIZE_PIE_CHART = payload.visualizeInstructions as VisualizePieChartInstruction;
    } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_HEAT_MAP) {
      editingDPT.visualize_op.instructions.VISUALIZE_HEAT_MAP = payload.visualizeInstructions as VisualizeHeatMapInstructions;
    } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_PIVOT_CHART) {
      editingDPT.visualize_op.instructions.VISUALIZE_PIVOT_CHART = payload.visualizeInstructions as ViusalizePivotChartInstructions;
    } else if (
      payload.operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_PIE_CHART_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_LINE_CHART_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_AREA_CHART_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_FUNNEL_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_MAP_V2
    ) {
      editingDPT.visualize_op.instructions.V2_TWO_DIMENSION_CHART = payload.visualizeInstructions as V2TwoDimensionChartInstructions;
    } else if (
      payload.operationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
    ) {
      editingDPT.visualize_op.instructions.V2_KPI = payload.visualizeInstructions as V2KPIChartInstructions;
    } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
      editingDPT.visualize_op.instructions.V2_BOX_PLOT = payload.visualizeInstructions as V2BoxPlotInstructions;
    } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
      editingDPT.visualize_op.instructions.V2_KPI_TREND = payload.visualizeInstructions as V2KPITrendInstructions;
    }

    if (oldDPT) {
      const oldConfigReducerState = getDatapanelConfigReducerState(oldDPT);
      const editingConfigReducerState = getDatapanelConfigReducerState(editingDPT);

      // If the updated instructions necessitates a recompute, we should update the DPT state accordingly
      // to inidicate that it is loading
      const { shouldRecompute } = shouldRecomputeDataForDataPanel(
        oldConfigReducerState,
        editingConfigReducerState,
      );

      editingDPT._loading = shouldRecompute;
    }
  }
  return config;
};

const deleteDataPanel = (config: DashboardVersionConfig, payload: DeleteDataPanelV2Args) => {
  delete config.data_panels[payload.id];
  return config;
};

const renameDataPanel = (config: DashboardVersionConfig, payload: RenameDataPanelV2Args) => {
  config.data_panels[payload.id].name = payload.name;
  config.data_panels[payload.id].provided_id = payload.providedId;

  return config;
};

const updateElementConfig = (
  config: DashboardVersionConfig,
  payload: UpdateElementConfigV2Args,
) => {
  config.elements[payload.elementId].config = cloneDeep(payload.config);
  return config;
};

const saveDashboardElementUpdates = (
  config: DashboardVersionConfig,
  payload: SaveDashboardElementUpdatesArgs,
) => {
  if (payload.name) {
    const oldName = config.elements[payload.id].name;
    config.elements[payload.id].name = cloneDeep(payload.name);
    updateUserInputFieldsWithNewElemName(config, oldName, payload.name);
  }
  return config;
};

const deleteDashboardElement = (
  config: DashboardVersionConfig,
  payload: DeleteElementConfigV2Args,
) => {
  const elementName = config.elements[payload.elementId].name;
  delete config.elements[payload.elementId];
  updateUserInputFieldsWithDeletedElem(config, elementName);

  return config;
};

const updateDashboardParams = (
  config: DashboardVersionConfig,
  payload: UpdateDashboardParamsArgs,
) => {
  config.params = payload.newParams;
  return config;
};

const selectDashboardDptToEdit = (newState: DashboardEditConfigReducerState, action: Action) => {
  newState.currentEditingDptId = action.payload.dpt.id;
  return newState;
};

const updateSelectedChart = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: UpdateSelectedChartPayload,
) => {
  if (
    V2_VIZ_INSTRUCTION_TYPE[editingDPT.visualize_op.operation_type] !==
    V2_VIZ_INSTRUCTION_TYPE[payload.operationType]
  ) {
    editingDPT.visualize_op.operation_type = payload.operationType;
    editingDPT._loading = isDataPanelReadyToCompute({
      filterOperation: editingDPT.filter_op,
      pivotOperation: editingDPT.group_by_op,
      visualizeOperation: editingDPT.visualize_op,
      sortOperation: editingDPT.sort_op,
    });
    editingDPT._rows = undefined;
    editingDPT._schema = undefined;
    editingDPT._error = undefined;
    editingDPT._secondaryData = undefined;
  }

  editingDPT.visualize_op.operation_type = payload.operationType;

  if (
    payload.operationType === OPERATION_TYPES.VISUALIZE_BAR_CHART ||
    payload.operationType === OPERATION_TYPES.VISUALIZE_LINE_CHART ||
    payload.operationType === OPERATION_TYPES.VISUALIZE_HORIZ_BAR_CHART ||
    payload.operationType === OPERATION_TYPES.VISUALIZE_COMBO_CHART
  ) {
    editingDPT.visualize_op.instructions.VISUALIZE_LINE_OR_BAR_CHART.viewType = undefined;
  }

  return config;
};

const resetEditingDataPanel = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: ResetEditedDataPanelPayload,
) => {
  config.data_panels[editingDPT.id] = payload.dpt;
  return config;
};

const createFilterClause = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: CreateFilterClausePayload,
) => {
  if (payload.column) {
    const clause = cloneDeep(EMPTY_FILTER_CLAUSE);
    clause.filterColumn = payload.column;
    editingDPT.filter_op.instructions.filterClauses.push(clause);
  } else {
    editingDPT.filter_op.instructions.filterClauses.push(EMPTY_FILTER_CLAUSE);
  }
  return config;
};

const deleteFilterClause = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: DeleteFilterClausePayload,
) => {
  editingDPT.filter_op.instructions.filterClauses.splice(payload.index, 1);
  return config;
};

const selectFilterColumn = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: SelectFilterColumnPayload,
) => {
  const oldColumn = editingDPT.filter_op.instructions.filterClauses[payload.index].filterColumn;
  if (oldColumn && oldColumn.type !== payload.column.type) {
    editingDPT.filter_op.instructions.filterClauses[payload.index].filterOperation = undefined;
    editingDPT.filter_op.instructions.filterClauses[payload.index].filterValue = undefined;
  }
  editingDPT.filter_op.instructions.filterClauses[payload.index].filterColumn = payload.column;
  return config;
};

const selectFilterOperator = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: SelectFilterOperatorPayload,
) => {
  if (
    newOperatorShouldClearSelectedVariable(
      payload.operator,
      editingDPT.filter_op.instructions.filterClauses[payload.index].filterOperation,
    )
  ) {
    editingDPT.filter_op.instructions.filterClauses[
      payload.index
    ].filterValueVariableId = undefined;
  }

  if (newOperatorDoesntHaveVariableOption(payload.operator)) {
    editingDPT.filter_op.instructions.filterClauses[payload.index].filterValueSource = undefined;
  }

  editingDPT.filter_op.instructions.filterClauses[payload.index].filterOperation = payload.operator;
  return config;
};

const updateFilterValue = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: UpdateFilterValuePayload,
) => {
  editingDPT.filter_op.instructions.filterClauses[payload.index].filterValue = payload.value;
  return config;
};

const updateFilterMatch = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: UpdateFilterMatchPayload,
) => {
  editingDPT.filter_op.instructions.matchOnAll = payload.value;
  return config;
};

const updateFilterValueSource = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: UpdateFilterValueSourcePayload,
) => {
  editingDPT.filter_op.instructions.filterClauses[payload.index].filterValueSource =
    payload.newSource;
  return config;
};

const updateFilterValueVariable = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: UpdateFilterValueVariablePayload,
) => {
  editingDPT.filter_op.instructions.filterClauses[payload.index].filterValueVariableId =
    payload.variableId;
  return config;
};

const createSortClause = (config: DashboardVersionConfig, editingDPT: DataPanelTemplate) => {
  editingDPT.sort_op.instructions.sortColumns.push(EMPTY_SORT_CLAUSE);
  return config;
};

const deleteSortClause = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: DeleteSortClausePayload,
) => {
  editingDPT.sort_op.instructions.sortColumns.splice(payload.index, 1);
  return config;
};

const selectSortColumn = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: SelectSortColumnPayload,
) => {
  editingDPT.sort_op.instructions.sortColumns[payload.index].column = payload.column;
  return config;
};

const selectSortOrder = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: SelectSortOrderPayload,
) => {
  editingDPT.sort_op.instructions.sortColumns[payload.index].order = payload.order;
  return config;
};

const createPivotedOnCol = (config: DashboardVersionConfig, editingDPT: DataPanelTemplate) => {
  editingDPT.group_by_op.instructions.pivotedOnCols.push(EMPTY_PIVOTED_ON_COL);
  return config;
};

const deletePivotedOnCol = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: DeletePivotedOnColPayload,
) => {
  editingDPT.group_by_op.instructions.pivotedOnCols.splice(payload.index, 1);
  return config;
};

const updatePivotedOnCol = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: UpdatePivotedOnColPayload,
) => {
  editingDPT.group_by_op.instructions.pivotedOnCols[payload.index] = payload.aggregationColumnInfo;
  return config;
};

const createAggregation = (config: DashboardVersionConfig, editingDPT: DataPanelTemplate) => {
  editingDPT.group_by_op.instructions.aggregations.push(EMPTY_AGGREGATION);
  return config;
};

const deleteAggregation = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: DeleteAggrgationPayload,
) => {
  editingDPT.group_by_op.instructions.aggregations.splice(payload.index, 1);
  return config;
};

const updateAggregation = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: UpdateAggregationPayload,
) => {
  editingDPT.group_by_op.instructions.aggregations[payload.index] =
    payload.pivotOperationAggregation;
  return config;
};

const updateGeneralFormatOptions = (
  config: DashboardVersionConfig,
  editingDPT: DataPanelTemplate,
  payload: UpdateGeneralFormatOptionsPayload,
) => {
  editingDPT.visualize_op.generalFormatOptions = payload.generalFormatOptions;
  return config;
};

const updateAdHocOperationInstructions = (
  config: DashboardVersionConfig,
  payload: UpdateAdHocOperationInstructionsArgs,
) => {
  const { adHocOperationInstructions, dataPanelTemplateId } = payload;
  config.data_panels[dataPanelTemplateId]._adHocOperationInstructions = adHocOperationInstructions;

  return config;
};

function actionWithConfig<T>(
  actionFn: (config: DashboardVersionConfig, payload: T) => DashboardVersionConfig,
) {
  return (newState: DashboardEditConfigReducerState, action: PayloadAction<T>) => {
    const { payload } = action;
    if (!newState.config) return newState;
    newState.config = actionFn(newState.config, payload);

    return newState;
  };
}

function actionWithConfigAndEditingDPT<T>(
  actionFn: (
    config: DashboardVersionConfig,
    editingDPT: DataPanelTemplate,
    payload: T,
  ) => DashboardVersionConfig,
) {
  return (newState: DashboardEditConfigReducerState, action: PayloadAction<T>) => {
    const { payload } = action;
    if (!newState.config) return newState;
    const editingDPT =
      newState.currentEditingDptId && newState.config.data_panels[newState.currentEditingDptId];

    if (!editingDPT) return newState;
    newState.config = actionFn(newState.config, editingDPT, payload);
    return newState;
  };
}

export default createReducer<DashboardEditConfigReducerState>(
  initialState,
  {
    [ACTION.SWITCH_CURRENTLY_EDITING_DASHBOARD_VERSION]: switchCurrentlyEditingDashboardVersion,
    [`${ACTION.PUBLISH_NEW_DASHBOARD_VERSION}_SUCCESS`]: publishNewDashboardVersion,

    // Data Panel Reducers
    [ACTION.CLEAR_DASHBOARD_CONFIG_REDUCER]: clearReducer,
    [ACTION.SET_DPT_LOADING]: actionWithConfig<SetDPTLoadingArgs>(setDptLoading),
    [`${ACTION.FETCH_DASHBOARD_TEMPLATE}_SUCCESS`]: fetchDashboardTemplateSuccess,
    [`${ACTION.FETCH_DASHBOARD_TEMPLATE}_REQUEST`]: clearReducer,
    [`${ACTION.FETCH_DATA_PANEL_TEMPLATE}_ERROR`]: fetchDataPanelTemplateError,
    [`${ACTION.FETCH_DATA_PANEL_TEMPLATE}_REQUEST`]: fetchDataPanelTemplateRequest,
    [`${ACTION.FETCH_DATA_PANEL_TEMPLATE}_SUCCESS`]: fetchDataPanelTemplateSuccess,
    [`${ACTION.FETCH_SECONDARY_DATA}_REQUEST`]: fetchSecondaryDataRequest,
    [`${ACTION.FETCH_SECONDARY_DATA}_SUCCESS`]: fetchSecondaryDataSuccess,
    [`${ACTION.FETCH_DATA_PANEL_TEMPLATE_ROW_COUNT}_SUCCESS`]: fetchDataPanelTemplateRowCountSuccess,
    [`${ACTION.DOWNLOAD_DATA_PANEL_TEMPLATE_CSV}_REQUEST`]: actionWithConfig<{
      postData: { id: string };
    }>(downloadDataPanelTemplateCsvStart),
    [`${ACTION.DOWNLOAD_DATA_PANEL_TEMPLATE_CSV}_ERROR`]: actionWithConfig<{
      postData: { id: string };
    }>(downloadDataPanelTemplateCsvComplete),
    [`${ACTION.DOWNLOAD_DATA_PANEL_TEMPLATE_CSV}_SUCCESS`]: actionWithConfig<{
      postData: { id: string };
    }>(downloadDataPanelTemplateCsvComplete),
    [ACTION.CREATE_TEMPLATE_DATA_PANEL_V2]: createTemplateDataPanel,
    [ACTION.DUPLICATE_DASHBOARD_ITEM]: duplicateDashboardItem,
    [ACTION.UPDATE_AD_HOC_OPERATION_INSTRUCTIONS]: actionWithConfig<
      UpdateAdHocOperationInstructionsArgs
    >(updateAdHocOperationInstructions),

    // Dataset Editor Reducers
    [`${ACTION.FETCH_EDITOR_DATASET_PREVIEW}_REQUEST`]: fetchDatasetDataRequest,
    [`${ACTION.FETCH_EDITOR_DATASET_PREVIEW}_ERROR`]: fetchDatasetDataError,
    [`${ACTION.FETCH_EDITOR_DATASET_PREVIEW}_SUCCESS`]: fetchDatasetDataSuccess,
    [`${ACTION.FETCH_EDITOR_DATASET_ROW_COUNT}_SUCCESS`]: fetchDatasetRowCountSuccess,
    [ACTION.SAVE_DASHBOARD_DATASET_QUERY]: actionWithConfig<SaveDashboardDatasetQueryArgs>(
      saveDashboardDatasetQuery,
    ),
    [`${ACTION.FETCH_DASHBOARD_DATASET_PREVIEW}_SUCCESS`]: fetchDatasetDataSuccess,
    [ACTION.CREATE_DATASET_V2]: actionWithConfig<CreateDashboardDatasetV2Args>(createNewDataset),
    [ACTION.DELETE_DASHBOARD_DATASET]: actionWithConfig<DeleteDashboardDatasetV2Args>(
      deleteDashobardDataset,
    ),
    [ACTION.EDIT_DASHBOARD_DATASET_NAME]: actionWithConfig<EditDashboardDatasetNameArgs>(
      editDashboardDatasetName,
    ),

    // Edit Dashboard Reducers
    [ACTION.UPDATE_DASHBOARD_TEMPLATE_LAYOUT]: updateDashboardTemplateLayout,
    [ACTION.UPDATE_DASHBOARD_PDF_LAYOUT]: updateDashboardPdfLayout,
    [ACTION.UPDATE_VISUALIZE_OP]: actionWithConfigAndEditingDPT<UpdateVisualizeOperationPayload>(
      updateVisualizeOperation,
    ),
    [ACTION.DELETE_DATA_PANEL_V2]: actionWithConfig<DeleteDataPanelV2Args>(deleteDataPanel),
    [ACTION.CREATE_DASHBOARD_TEMPLATE_ELEMENT]: actionWithConfig<CreateDashboardElementArgs>(
      createDashboardElement,
    ),
    [ACTION.UPDATE_ELEMENT_CONFIG]: actionWithConfig<UpdateElementConfigV2Args>(
      updateElementConfig,
    ),
    [ACTION.DELETE_DASHBOARD_ELEMENT]: actionWithConfig<DeleteElementConfigV2Args>(
      deleteDashboardElement,
    ),
    [ACTION.SAVE_DASHBOARD_ELEMENT_UPDATES]: actionWithConfig<SaveDashboardElementUpdatesArgs>(
      saveDashboardElementUpdates,
    ),
    [ACTION.UPDATE_DASHBOARD_TEMPLATE_PARAMS]: actionWithConfig<UpdateDashboardParamsArgs>(
      updateDashboardParams,
    ),

    [ACTION.SELECT_DASHBOARD_DPT_TO_EDIT]: selectDashboardDptToEdit,
    [ACTION.UPDATE_SELECTED_CHART]: actionWithConfigAndEditingDPT<UpdateSelectedChartPayload>(
      updateSelectedChart,
    ),
    [ACTION.RESET_EDITED_DATA_PANEL]: actionWithConfigAndEditingDPT<ResetEditedDataPanelPayload>(
      resetEditingDataPanel,
    ),
    [ACTION.RENAME_DATA_PANEL_TEMPLATE]: actionWithConfig<RenameDataPanelV2Args>(renameDataPanel),

    // V1 data panel actions
    [ACTION.CREATE_FILTER_CLAUSE]: actionWithConfigAndEditingDPT<CreateFilterClausePayload>(
      createFilterClause,
    ),
    [ACTION.DELETE_FILTER_CLAUSE]: actionWithConfigAndEditingDPT<DeleteFilterClausePayload>(
      deleteFilterClause,
    ),
    [ACTION.SELECT_FILTER_COLUMN]: actionWithConfigAndEditingDPT<SelectFilterColumnPayload>(
      selectFilterColumn,
    ),
    [ACTION.SELECT_FILTER_OPERATOR]: actionWithConfigAndEditingDPT<SelectFilterOperatorPayload>(
      selectFilterOperator,
    ),
    [ACTION.UPDATE_FILTER_VALUE]: actionWithConfigAndEditingDPT<UpdateFilterValuePayload>(
      updateFilterValue,
    ),
    [ACTION.UPDATE_FILTER_MATCH]: actionWithConfigAndEditingDPT<UpdateFilterMatchPayload>(
      updateFilterMatch,
    ),
    [ACTION.UPDATE_FILTER_VALUE_SOURCE]: actionWithConfigAndEditingDPT<
      UpdateFilterValueSourcePayload
    >(updateFilterValueSource),
    [ACTION.UPDATE_FILTER_VALUE_VARIABLE]: actionWithConfigAndEditingDPT<
      UpdateFilterValueVariablePayload
    >(updateFilterValueVariable),

    [ACTION.CREATE_SORT_CLAUSE]: actionWithConfigAndEditingDPT<CreateSortClausePayload>(
      createSortClause,
    ),
    [ACTION.DELETE_SORT_CLAUSE]: actionWithConfigAndEditingDPT<DeleteSortClausePayload>(
      deleteSortClause,
    ),
    [ACTION.SELECT_SORT_COLUMN]: actionWithConfigAndEditingDPT<SelectSortColumnPayload>(
      selectSortColumn,
    ),
    [ACTION.SELECT_SORT_ORDER]: actionWithConfigAndEditingDPT<SelectSortOrderPayload>(
      selectSortOrder,
    ),

    [ACTION.CREATE_PIVOTED_ON_COL]: actionWithConfigAndEditingDPT<CreatePivotedOnColPayload>(
      createPivotedOnCol,
    ),
    [ACTION.DELETE_PIVOTED_ON_COL]: actionWithConfigAndEditingDPT<DeletePivotedOnColPayload>(
      deletePivotedOnCol,
    ),
    [ACTION.UPDATE_PIVOTED_ON_COL]: actionWithConfigAndEditingDPT<UpdatePivotedOnColPayload>(
      updatePivotedOnCol,
    ),
    [ACTION.CREATE_AGGREGATION]: actionWithConfigAndEditingDPT<CreateAggregationPayload>(
      createAggregation,
    ),
    [ACTION.DELETE_AGGREGATION]: actionWithConfigAndEditingDPT<DeleteAggrgationPayload>(
      deleteAggregation,
    ),
    [ACTION.UPDATE_AGGREGATION]: actionWithConfigAndEditingDPT<UpdateAggregationPayload>(
      updateAggregation,
    ),
    [ACTION.UPDATE_VISUALIZE_OPERATION_GENERAL_FORMAT_OPTIONS]: actionWithConfigAndEditingDPT<
      UpdateGeneralFormatOptionsPayload
    >(updateGeneralFormatOptions),
  },
  (
    oldState: DashboardEditConfigReducerState,
    newState: DashboardEditConfigReducerState,
    actionType: string,
  ) => {
    if (!oldState.config || !newState.config) return;
    const oldConfig = removeUnsavedDashboardConfigFields(oldState.config);
    const newConfig = removeUnsavedDashboardConfigFields(newState.config);
    if (_.isEqual(oldConfig, newConfig) && actionType !== ACTION.UPDATE_VISUALIZE_OP) return;
    setTimeout(() => {
      window.dispatchEvent(
        new CustomEvent('dashboardConfigUpdated', {
          detail: {
            config: newConfig,
            version: newState.versionInfo,
          },
        }),
      );
    }, 200);
  },
);
