/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';

import SettingHeader from '../SettingHeader';
import DroppableColumnSection from './DroppableColumnSection';

import { OPERATION_TYPES, V2KPIChartInstructions } from 'constants/types';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { defaultAggByType } from './utils';
import { DATE_TYPES, PIVOT_AGG_TYPES } from 'constants/dataConstants';

const useStyles = makeStyles(() => ({
  root: {},
}));

export type Props = {
  instructions: V2KPIChartInstructions;
  chartType: string;
};

export default function KPIVizConfig({ instructions, chartType }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <SettingHeader name="Aggregation" />
      <DroppableColumnSection
        columns={instructions.aggColumn ? [instructions.aggColumn] : []}
        onColDropped={(col) => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.aggColumn = {
            column: col,
            agg: defaultAggByType(col.type),
          };
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
        onColOptionChanged={(option) => {
          const newInstructions = cloneDeep(instructions);
          if (newInstructions.aggColumn)
            newInstructions.aggColumn.agg = { ...option, selectionValue: 'Aggregation' };
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
        onRemoveCol={() => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.aggColumn = undefined;
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
      />
      <SettingHeader name="Trend" />
      <DroppableColumnSection
        columns={instructions.trendColumn ? [instructions.trendColumn] : []}
        onColDropped={(col) => {
          if (DATE_TYPES.has(col.type)) {
            const newInstructions = cloneDeep(instructions);
            newInstructions.trendColumn = {
              column: col,
              bucket: PIVOT_AGG_TYPES.DATE_MONTH,
            };
            dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
          }
        }}
        onColOptionChanged={(option) => {
          const newInstructions = cloneDeep(instructions);
          if (newInstructions.trendColumn) newInstructions.trendColumn.bucket = option;
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
        onRemoveCol={() => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.trendColumn = undefined;
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
      />
    </div>
  );
}
