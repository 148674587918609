/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';

import SwitchInput from 'pages/dataPanelEditorPage/switchInput';

import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import { VisualizeOperationGeneralFormatOptions } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export type Props = {
  generalFormatOptions: VisualizeOperationGeneralFormatOptions;
};

export default function DownloadConfig({ generalFormatOptions }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <SwitchInput
        className={classes.configInput}
        switchOn={!generalFormatOptions?.hideDownloadButton}
        onChange={() => {
          const newFormat = cloneDeep(generalFormatOptions);
          newFormat.hideDownloadButton = !generalFormatOptions?.hideDownloadButton;

          dispatch(updateGeneralFormatOptions(newFormat));
        }}
        label="Show Download Button"
      />
    </div>
  );
}
