/** @format */

import React from 'react';
import _ from 'underscore';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';

import GettingStarted from './StepPages/GettingStarted';
import SelectDatabase from './StepPages/SelectDatabase';
import EnterCredentials from './StepPages/EnterCredentials';
import SecurityConfiguration from './StepPages/SecurityConfiguration';
import ReviewConfiguration from './StepPages/ReviewConfiguration';

import { ConnectDataSourceStep } from './constants';
import {
  listTeamDataSources,
  fetchSupportedDataSources,
  testDataSourceConnection,
  connectDataSource,
} from 'actions/dataSourceActions';
import { fetchParentSchemas, createParentSchema } from 'actions/parentSchemaActions';
import { DBConnectionConfig } from './types';
import { ROUTES } from 'constants/routes';
import { parseJsonFields } from 'utils/general';

import { trackEvent } from 'analytics/exploAnalytics';
import { ParentSchema } from 'actions/types';

const styles = () => ({
  root: {
    height: '100%',
    width: '100%',
  },
});

type MatchParams = {};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  currentStep: ConnectDataSourceStep;
  connectionConfig: DBConnectionConfig;
  selectedSchema?: ParentSchema;
  selectedSchemaIsNew?: boolean;
  testingConnWorked?: boolean;
  testingConnLoading?: boolean;
  testingConnError?: string;
  connectLoading?: boolean;
};

class ConnectDataSourceFlow extends React.Component<Props, State> {
  state: State = {
    currentStep: ConnectDataSourceStep.GETTING_STARTED,
    connectionConfig: {
      name: '',
      providedId: '',
    },
  };

  constructor(props: Props) {
    super(props);

    props.listTeamDataSources();
    props.fetchSupportedDataSources();
    props.fetchParentSchemas({}, (result) => {
      if (result.parent_schemas.length === 0) {
        // Create a schema for the team if there is none. Not passing
        // in a name here means that we'll name it default_{team_name}
        props.createParentSchema({}, (data) => {
          trackEvent('Created default schema', {
            schema: data.schema,
          });

          this.setState({
            selectedSchemaIsNew: true,
            selectedSchema: data.schema,
          });
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    return <div className={classes.root}>{this.renderCurrentStep()}</div>;
  }

  renderCurrentStep = () => {
    const { parentSchemas, dataSources } = this.props;
    const {
      currentStep,
      connectionConfig,
      connectLoading,
      testingConnLoading,
      testingConnWorked,
      testingConnError,
      selectedSchema,
      selectedSchemaIsNew,
    } = this.state;

    switch (currentStep) {
      case ConnectDataSourceStep.GETTING_STARTED:
        return (
          <GettingStarted
            config={connectionConfig}
            updateConfig={(newConfig) => this.updateConfig(newConfig)}
            onNextClicked={() => this.updateStep(ConnectDataSourceStep.SELECT_DB)}
            parentSchemas={parentSchemas}
            selectedSchema={selectedSchema}
            selectedSchemaIsNew={selectedSchemaIsNew}
            setSelectedSchema={this.setSelectedSchema}
            existingDataSources={dataSources}
          />
        );
      case ConnectDataSourceStep.SELECT_DB:
        return (
          <SelectDatabase
            config={connectionConfig}
            updateConfig={(newConfig) => this.updateConfig(newConfig)}
            onNextClicked={() => this.updateStep(ConnectDataSourceStep.ENTER_CREDS)}
            onBackClicked={() => this.updateStep(ConnectDataSourceStep.GETTING_STARTED)}
          />
        );
      case ConnectDataSourceStep.ENTER_CREDS:
        return (
          <EnterCredentials
            config={connectionConfig}
            updateConfig={(newConfig) => this.updateConfig(newConfig)}
            onNextClicked={() => this.updateStep(ConnectDataSourceStep.SECURITY)}
            onBackClicked={() => this.updateStep(ConnectDataSourceStep.SELECT_DB)}
          />
        );
      case ConnectDataSourceStep.SECURITY:
        return (
          <SecurityConfiguration
            config={connectionConfig}
            updateConfig={(newConfig) => this.updateConfig(newConfig)}
            onNextClicked={() => this.testConnectionCredentials()}
            onBackClicked={() => this.updateStep(ConnectDataSourceStep.ENTER_CREDS)}
            testingConnLoading={testingConnLoading}
          />
        );
      case ConnectDataSourceStep.REVIEW:
        return (
          <ReviewConfiguration
            config={connectionConfig}
            updateConfig={(newConfig) => this.updateConfig(newConfig)}
            onNextClicked={() =>
              testingConnWorked ? this.connectDataSource() : this.testConnectionCredentials()
            }
            onBackClicked={() => this.updateStep(ConnectDataSourceStep.SECURITY)}
            connectLoading={connectLoading}
            testingConnWorked={testingConnWorked}
            testingConnError={testingConnError}
            testingConnLoading={testingConnLoading}
            parentSchemas={parentSchemas}
            selectedSchema={selectedSchema}
            selectedSchemaIsNew={selectedSchemaIsNew}
            setSelectedSchema={this.setSelectedSchema}
            existingDataSources={dataSources}
          />
        );
    }
  };

  updateStep = (newStep: ConnectDataSourceStep) => {
    this.setState({ currentStep: newStep });
  };

  updateConfig = (newConfig: DBConnectionConfig) => {
    this.setState({ connectionConfig: newConfig });
  };

  setSelectedSchema = (schema: ParentSchema, isNew?: boolean) => {
    const { connectionConfig } = this.state;
    const defaultSourceType = isNew
      ? connectionConfig.selectedDataSource
      : this.getDefaultSourceType(schema);

    this.setState({
      selectedSchema: schema,
      selectedSchemaIsNew: isNew,
      connectionConfig: {
        ...connectionConfig,
        selectedDataSource: defaultSourceType,
        selectedDataSourceIsLocked: defaultSourceType && !isNew,
      },
    });
  };

  getDefaultSourceType = (selectedSchema: ParentSchema) => {
    const { dataSources, supportedDataSources } = this.props;
    const sourcesByType = _.indexBy(supportedDataSources.dataSources || [], 'type');

    const defaultDataSource = dataSources.find(
      (dataSource) =>
        dataSource.parent_schema_id === selectedSchema.id && dataSource.default === true,
    );

    if (!defaultDataSource) return;

    return sourcesByType[defaultDataSource.source_type];
  };

  testConnectionCredentials = () => {
    const { testDataSourceConnection } = this.props;
    const { connectionConfig } = this.state;

    if (!connectionConfig.selectedDataSource) return;

    this.setState({ testingConnLoading: true, testingConnWorked: undefined, testingConnError: '' });

    const { parsedConfig, error } = parseJsonFields(
      connectionConfig.selectedDataSource,
      connectionConfig.dataSourceConfig,
    );
    if (error !== undefined) {
      this.setState({
        testingConnLoading: false,
        testingConnWorked: false,
        testingConnError: error?.toString(),
      });
      this.updateStep(ConnectDataSourceStep.REVIEW);
      return;
    }

    testDataSourceConnection(
      {
        postData: {
          name: connectionConfig.name,
          type: connectionConfig.selectedDataSource.type,
          configuration: parsedConfig,
        },
      },
      () => {
        this.setState({ testingConnLoading: false, testingConnWorked: true, testingConnError: '' });
        this.updateStep(ConnectDataSourceStep.REVIEW);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        this.setState({
          testingConnLoading: false,
          testingConnWorked: false,
          testingConnError:
            response.error_msg ||
            'There was an error with your credentials. Please ensure they are correct.',
        });
        this.updateStep(ConnectDataSourceStep.REVIEW);
      },
    );
  };

  connectDataSource = () => {
    const { connectDataSource } = this.props;
    const { connectionConfig, selectedSchema } = this.state;

    this.setState({ connectLoading: true, testingConnWorked: undefined, testingConnError: '' });

    if (!connectionConfig.selectedDataSource || !selectedSchema) return;

    const { parsedConfig, error } = parseJsonFields(
      connectionConfig.selectedDataSource,
      connectionConfig.dataSourceConfig,
    );
    if (error !== undefined) {
      this.setState({
        testingConnLoading: false,
        testingConnWorked: false,
        testingConnError: error?.toString(),
      });
      this.updateStep(ConnectDataSourceStep.REVIEW);
      return;
    }

    connectDataSource(
      {
        postData: {
          name: connectionConfig.name,
          provided_id: connectionConfig.providedId,
          type: connectionConfig.selectedDataSource.type,
          configuration: parsedConfig,
          schema: selectedSchema,
        },
      },
      () => {
        this.props.history.push(ROUTES.DATA_SOURCES_PAGE);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        this.setState({
          testingConnLoading: false,
          testingConnWorked: false,
          testingConnError:
            response?.error_msg ||
            'There was an error with your credentials. Please check your firewall settings and ensure the above information is correct.',
          connectLoading: false,
        });
      },
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  supportedDataSources: state.supportedDataSources,
  parentSchemas: state.parentSchemas.allParentSchema,
  dataSources: state.dataSourceList.dataSources,
});

const mapDispatchToProps = {
  fetchSupportedDataSources,
  testDataSourceConnection,
  connectDataSource,
  fetchParentSchemas,
  createParentSchema,
  listTeamDataSources,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConnectDataSourceFlow)),
);
