/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';
import { Button, ButtonGroup } from '@blueprintjs/core';

import { VisualizeNumberInstructions } from 'constants/types';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      columnGap: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
  });

type PassedProps = {
  visualizeNumberInstructions: VisualizeNumberInstructions;
  updateVisualizeOperation: (newVisualizeNumberInstructions: VisualizeNumberInstructions) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class NumberLayoutConfiguration extends React.Component<Props> {
  render() {
    const { classes, visualizeNumberInstructions } = this.props;

    return (
      <div className={classes.root}>
        <ButtonGroup fill>
          <Button
            active={!visualizeNumberInstructions.displayFormat.isProgess}
            onClick={() => this.toggleNumberLayout(false)}>
            Number
          </Button>
          <Button
            active={visualizeNumberInstructions.displayFormat.isProgess}
            onClick={() => this.toggleNumberLayout(true)}>
            Progress Bar
          </Button>
        </ButtonGroup>
      </div>
    );
  }

  toggleNumberLayout = (isProgress: boolean) => {
    const { visualizeNumberInstructions, updateVisualizeOperation } = this.props;

    visualizeNumberInstructions.displayFormat.isProgess = isProgress;

    updateVisualizeOperation(visualizeNumberInstructions);
  };
}

export default withStyles(styles)(NumberLayoutConfiguration);
