/** @format */

import React from 'react';
import cx from 'classnames';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { DashboardVariableMap, ImageElemConfig } from 'types/dashboardTypes';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { replaceTemplatesWithValues } from 'utils/dataPanelConfigUtils';
import FlexBox, { VerticalAlignment, HorizontalAlignment } from 'components/core/FlexBox';

const styles = () =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',

      overflow: 'hidden',
      position: 'relative',
    },
    empty: {
      fontStyle: 'italic',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: 0,
    },
    imageFitContainer: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  });

type PassedProps = {
  config: ImageElemConfig;
  variables: DashboardVariableMap;
};

type Props = PassedProps & WithStyles<typeof styles>;

class DashboardImageElement extends React.Component<Props> {
  render() {
    const { classes, config, variables } = this.props;

    if (!config.imageUrl)
      return <div className={cx(classes.root, classes.empty)}>Enter an image URL</div>;

    return (
      <FlexBox
        horizontalAlignment={HorizontalAlignment.CENTER}
        verticalAlignment={VerticalAlignment.CENTER}
        className={classes.root}>
        <img
          className={cx(
            { [classes.image]: !config.fitEntireImage },
            { [classes.imageFitContainer]: config.fitEntireImage },
            GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadius,
          )}
          src={replaceTemplatesWithValues(config.imageUrl, variables)}
          alt="dashboard custom"
        />
      </FlexBox>
    );
  }
}

export default withStyles(styles)(DashboardImageElement);
