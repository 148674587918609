/** @format */

import PostgresIcon from 'images/marketing/integration_logos/postgresql_icon.svg';
import MySQLIcon from 'images/marketing/integration_logos/mysql_icon.svg';
import SnowflakeIcon from 'images/marketing/integration_logos/snowflake_icon.svg';
import RedshiftIcon from 'images/marketing/integration_logos/redshift_icon.svg';
import BigqueryIcon from 'images/marketing/integration_logos/bigquery_icon.svg';
import MongoDB from 'images/marketing/integration_logos/mongo_icon.svg';
import SqlServerIcon from 'images/marketing/integration_logos/sqlserver_icon.svg';
import RocksetIcon from 'images/marketing/integration_logos/rockset.png';
import AzureIcon from 'images/marketing/integration_logos/azure.png';

type IntegrationsData = {
  source_type: string;
  datasourceIconImg: string;
  credentialsLink?: string;
};

export const integrationsInfo: { [id: string]: IntegrationsData } = {
  postgres: {
    source_type: 'postgres',
    datasourceIconImg: PostgresIcon,
    credentialsLink:
      'https://docs.explo.co/database-setup/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
  mysql: {
    source_type: 'mysql',
    datasourceIconImg: MySQLIcon,
    credentialsLink:
      'https://docs.explo.co/database-setup/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
  snowflake: {
    source_type: 'snowflake',
    datasourceIconImg: SnowflakeIcon,
    credentialsLink:
      'https://docs.explo.co/database-setup/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
  redshift: {
    source_type: 'redshift',
    datasourceIconImg: RedshiftIcon,
    credentialsLink:
      'https://docs.explo.co/database-setup/connecting-to-your-database-or-data-warehouse#redshift',
  },
  bigquery: {
    source_type: 'big_query',
    datasourceIconImg: BigqueryIcon,
    credentialsLink:
      'https://docs.explo.co/database-setup/connecting-to-your-database-or-data-warehouse#bigquery',
  },
  mongodb: {
    source_type: 'mongodb',
    datasourceIconImg: MongoDB,
  },
  microsoft_sql_server: {
    source_type: 'microsoft_sql_server',
    datasourceIconImg: SqlServerIcon,
    credentialsLink:
      'https://docs.explo.co/database-setup/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
  rockset: {
    source_type: 'rockset',
    datasourceIconImg: RocksetIcon,
  },
  azure: {
    source_type: 'azure_sql_db',
    datasourceIconImg: AzureIcon,
    credentialsLink:
      'https://docs.explo.co/database-setup/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
};
