/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/index';

import { TablePDFFormat, SECTION_OPTIONS } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 40,
    marginBottom: theme.spacing(4),
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  centerSection: {
    justifyContent: 'center',
  },
  rightSection: {
    justifyContent: 'flex-end',
  },
}));

export type Props = {
  pdfConfig: TablePDFFormat;
};

const PDFHeader: React.FC<Props> = ({ pdfConfig }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PDFHeaderSection sectionType={pdfConfig.leftOption} sectionContent={pdfConfig.leftContent} />
      <PDFHeaderSection
        className={classes.centerSection}
        sectionType={pdfConfig.centerOption}
        sectionContent={pdfConfig.centerContent}
      />
      <PDFHeaderSection
        className={classes.rightSection}
        sectionType={pdfConfig.rightOption}
        sectionContent={pdfConfig.rightContent}
      />
    </div>
  );
};

const useSectionStyles = makeStyles(() => ({
  root: {
    width: '30%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  sectionImg: {
    height: '100%',
  },
}));

export type SectionProps = {
  className?: string;
  sectionType?: SECTION_OPTIONS;
  sectionContent?: string;
};

const PDFHeaderSection: React.FC<SectionProps> = ({ className, sectionType, sectionContent }) => {
  const classes = useSectionStyles();

  return (
    <div className={cx(classes.root, className)}>
      {sectionType === SECTION_OPTIONS.IMAGE && (
        <img src={sectionContent} alt="section" className={classes.sectionImg} />
      )}
      {sectionType === SECTION_OPTIONS.TEXT && sectionContent}
    </div>
  );
};

export default PDFHeader;
