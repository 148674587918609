/** @format */

import _ from 'underscore';
import parse from 'url-parse';
import { dayjs } from 'utils/localizationUtils';
import { v4 as uuidv4 } from 'uuid';

/**
 * TODO: Add type declarations for utils so we don't need ts-ignore
 */
// @ts-ignore
import { Layout, utils } from '@explo-tech/react-grid-layout';

import {
  DashboardVersionConfig,
  DataPanelTemplate,
  Dataset,
  TableColumn,
  TableRow,
} from 'actions/types';
import { ELEMS_NEED_DATASETS, GENERIC_DROPPING_ID } from 'constants/dashboardConstants';
import {
  ColumnInfo,
  GradientShape,
  GradientType,
  NumberDisplayOptions,
  FilterOperatorType,
  FilterValueSourceType,
  OPERATION_TYPES,
  SchemaChange,
  PivotOperationAggregation,
  Aggregation,
  GradientOptions,
  GradientPointType,
  Schema,
  UserTransformedSchema,
  VisualizeTableInstructions,
} from 'constants/types';
import {
  DashboardElement,
  DashboardVariable,
  DashboardVariableMap,
  DropdownDashboardElemConfig,
  DatepickerElemConfig,
  DASHBOARD_ELEMENT_TYPES,
  RELATIVE_DATE_OPTIONS,
  DEFAULT_DATE_TYPES,
  DEFAULT_DATE_RANGES,
  ContainerElemConfig,
  DateRangePickerElemConfig,
  NumberColumnMetrics,
  MetricsByColumn,
  DateGroupSwitchConfig,
  VIEW_MODE,
  TextDashboardElemConfig,
  TimePeriodDropdownDashboardElemConfig,
} from 'types/dashboardTypes';
import { INPUT_TYPES } from 'pages/dashboardPage/elementConfig/dropdownElementConfigPanel';
import { cloneDeep } from 'lodash';
import {
  AGGREGATIONS_TYPES,
  NUMBER_TYPES,
  PeriodRangeTypes,
  TrendGroupingOptions,
  DATE_PART_INPUT_AGG,
  TREND_GROUP_OPTION_TO_PIVOT_AGG,
  PIVOT_AGG_TYPES,
  VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN,
  VIZ_OPS_WITH_GOAL_LINES,
} from 'constants/dataConstants';
import { areRequiredVariablesSet as areNumberTrendVarsSet } from 'pages/dashboardPage/charts/numberTrend';
import {
  DEFAULT_GRADIENT,
  FILTER_OPERATOR_TYPES_BY_ID,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_MULTISELECT,
  FILTER_OPS_RELATIVE_PICKER,
} from 'constants/dataPanelEditorConstants';
import { TREND_GROUPING_OPTIONS } from 'constants/dataConstants';
import { getPercentageRatio, mixColors } from './general';
import {
  isSecondaryDataRequired,
  getQueryTablesReferencedByTextElement,
} from './dataPanelConfigUtils';
import { titleCase } from 'utils/graphUtils';
import { isJoinConfigReady } from 'pages/dashboardPage/DataPanelConfigV2/FormatConfigTab/formatSections/TableColumnsConfig/TableColumnsConfig';

export const droppingElementId = (elemType?: string) => {
  if (elemType === undefined) return '';
  return `dropping-element-${elemType}`;
};

export const elemIdFromDropId = (dropId: string) => {
  return dropId.split('-element-')[1];
};

export const cleanLayout = (layout: Layout[]) => {
  return _.filter(
    layout,
    (config) => config.i !== undefined && config.i !== null && config.i !== 'null',
  );
};

export const dataPanelTemplatesAdded = (
  oldDPTs: DataPanelTemplate[],
  newDPTs: DataPanelTemplate[],
) => {
  const oldIds = new Set(_.pluck(oldDPTs, 'id'));
  const newIDs = _.pluck(newDPTs, 'id');

  return _.filter(newIDs, (id) => !oldIds.has(id));
};

export const dashboardElementsAdded = (
  oldElems: DashboardElement[],
  newElems: DashboardElement[],
) => {
  const oldIds = new Set(_.pluck(oldElems, 'id'));
  const newIDs = _.pluck(newElems, 'id');

  return _.filter(newIDs, (id) => !oldIds.has(id));
};

const getDatasetIdsFromElems = (elems: DashboardElement[]) => {
  const results: string[] = [];
  let dropdownConfig: DropdownDashboardElemConfig;

  elems.forEach((elem) => {
    if (ELEMS_NEED_DATASETS.has(elem.element_type)) {
      dropdownConfig = elem.config as DropdownDashboardElemConfig;
      if (
        dropdownConfig.valuesConfig.valuesSource === INPUT_TYPES.QUERY.id &&
        dropdownConfig.valuesConfig.queryTable
      ) {
        results.push(dropdownConfig.valuesConfig.queryTable.id);
      }
    }
  });
  return results;
};

export const datasetsChanged = (oldElems?: DashboardElement[], newElems?: DashboardElement[]) => {
  let oldDatasetIds: string[] = [];
  let newDatasetsIds: string[] = [];
  if (oldElems) {
    oldDatasetIds = getDatasetIdsFromElems(oldElems);
  }
  if (newElems) {
    newDatasetsIds = getDatasetIdsFromElems(newElems);
  }

  const results: string[] = [];
  const oldIdsSet = new Set(oldDatasetIds);
  newDatasetsIds.forEach((newId) => {
    if (!oldIdsSet.has(newId)) {
      results.push(newId);
    }
  });

  return results;
};

export const getDashboardElemsWithDefaultQueryValues = (elems: DashboardElement[]) => {
  return _.filter(elems, (elem) => {
    if (
      elem.element_type === DASHBOARD_ELEMENT_TYPES.DROPDOWN ||
      elem.element_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT ||
      elem.element_type === DASHBOARD_ELEMENT_TYPES.SWITCH
    ) {
      const config = elem.config as DropdownDashboardElemConfig;
      if (
        config.valuesConfig.valuesSource === INPUT_TYPES.QUERY.id &&
        config.valuesConfig.queryDefaultFirstValue
      ) {
        return true;
      }
    }

    return false;
  });
};

export const getDashboardElemsUsingDatasets = (elems: DashboardElement[], datasets: Dataset[]) => {
  const datasetIds = new Set(datasets.map((dataset) => dataset.id));
  return _.filter(elems, (elem) => {
    if (
      elem.element_type === DASHBOARD_ELEMENT_TYPES.DROPDOWN ||
      elem.element_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT ||
      elem.element_type === DASHBOARD_ELEMENT_TYPES.SWITCH
    ) {
      const config = elem.config as DropdownDashboardElemConfig;
      if (
        config.valuesConfig.valuesSource === INPUT_TYPES.QUERY.id &&
        config.valuesConfig.queryTable &&
        datasetIds.has(config.valuesConfig.queryTable.id)
      ) {
        return true;
      }
    }

    return false;
  });
};

export const getDatasetIdsForElems = (
  elems?: DashboardElement[],
  excludeDefaultValueDatasets?: boolean,
) => {
  const datasetIdsToFetchForFilterElems: string[] = [];
  const datasetIdsToFetchForTextElems: string[] = [];

  elems?.forEach((dashboardElement: DashboardElement) => {
    if (ELEMS_NEED_DATASETS.has(dashboardElement.element_type)) {
      const dropdownConfig = dashboardElement.config as DropdownDashboardElemConfig;
      if (
        dropdownConfig.valuesConfig.valuesSource === INPUT_TYPES.QUERY.id &&
        dropdownConfig.valuesConfig.queryTable &&
        (!excludeDefaultValueDatasets || !dropdownConfig.valuesConfig.queryDefaultFirstValue)
      ) {
        datasetIdsToFetchForFilterElems.push(dropdownConfig.valuesConfig.queryTable.id);
      }
    }

    if (dashboardElement.element_type === DASHBOARD_ELEMENT_TYPES.TEXT) {
      const textConfig = dashboardElement.config as TextDashboardElemConfig;
      if (textConfig.queryTables) {
        datasetIdsToFetchForTextElems.push(..._.pluck(textConfig.queryTables, 'id'));
      }
    }
  });

  if (excludeDefaultValueDatasets) {
    const elemsWithDefault = getDashboardElemsWithDefaultQueryValues(elems || []);
    const datasetsWithDefaults = extractDatasetIdsFromElems(elemsWithDefault);
    const filteredFilterElemDatasets = [...datasetIdsToFetchForFilterElems].filter(
      (x) => !datasetsWithDefaults.has(x),
    );
    return new Set(filteredFilterElemDatasets.concat(datasetIdsToFetchForTextElems));
  } else {
    return new Set(datasetIdsToFetchForFilterElems.concat(datasetIdsToFetchForTextElems));
  }
};

export const getDatasetIdsForDataPanels = (
  dashboardDatasets: Record<string, Dataset>,
  dataPanels?: DataPanelTemplate[],
) => {
  const datasetIds = new Set<string>();

  dataPanels?.forEach((dataPanel) => {
    if (dataPanel.visualize_op?.operation_type === OPERATION_TYPES.VISUALIZE_TABLE) {
      Object.values(
        dataPanel.visualize_op.instructions.VISUALIZE_TABLE.schemaDisplayOptions || [],
      ).forEach((colConfig) => {
        if (isJoinConfigReady(colConfig) && colConfig.joinTable?.id) {
          datasetIds.add(colConfig.joinTable.id);
        }
      });
    }
    if (VIZ_OPS_WITH_GOAL_LINES.has(dataPanel.visualize_op?.operation_type)) {
      dataPanel.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.goalLines?.forEach(
        (goalConfig) => {
          const tablesInGoalStart = getQueryTablesReferencedByTextElement(
            String(goalConfig.goalValue),
            dashboardDatasets,
          );
          tablesInGoalStart.forEach((table) => datasetIds.add(table.id));
          if (goalConfig.isGoalBand) {
            const tablesInGoalEnd = getQueryTablesReferencedByTextElement(
              String(goalConfig.goalValueMax),
              dashboardDatasets,
            );
            tablesInGoalEnd.forEach((table) => datasetIds.add(table.id));
          }
        },
      );
    }
  });

  return datasetIds;
};

export const extractDatasetIdsFromElems = (elems: DashboardElement[]) => {
  return new Set(
    _.compact(
      _.map(elems, (elem) => {
        const config = elem.config as DropdownDashboardElemConfig;
        return config.valuesConfig.queryTable?.id;
      }),
    ),
  );
};

export const datasetUsesVariable = (variableName: string, datasetQuery?: string) => {
  return datasetQuery?.match(new RegExp(`{{\\s*${variableName}\\s*}}`))?.length;
};

export const isValueInTableRows = (rows: TableRow[], key: string, value?: DashboardVariable) => {
  if (!value) return false;

  return rows.some((row) => row[key] === value);
};

export const getDefaultVariablesFromDashElements = (
  elems?: DashboardElement[],
  variablesDefaultValues?: DashboardVariableMap,
) => {
  const variables: Record<string, DashboardVariable> = {};

  if (!elems) return {};

  elems.forEach((elem) => {
    if (
      elem.element_type === DASHBOARD_ELEMENT_TYPES.DROPDOWN ||
      elem.element_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT ||
      elem.element_type === DASHBOARD_ELEMENT_TYPES.SWITCH
    ) {
      const config = elem.config as DropdownDashboardElemConfig;
      if (
        config.valuesConfig.valuesSource === INPUT_TYPES.MANUAL.id &&
        config.valuesConfig.manualDefaultValue
      ) {
        try {
          const defaultValue = JSON.parse(config.valuesConfig.manualDefaultValue);

          const manualValues: DashboardVariable[] = JSON.parse(config.valuesConfig.manualValues);
          const valueOverride = variablesDefaultValues?.[elem.name];
          if (!manualValues.includes(valueOverride)) {
            console.error(
              `Invalid value ${valueOverride} passed for variable ${elem.name}. Ensure that the type of the input (e.g. number) matches the type of the values in the editor.`,
            );
          } else {
            variables[elem.name] = valueOverride;
            return;
          }

          variables[elem.name] = defaultValue;
        } catch {
          return;
        }
      }
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN) {
      const config = elem.config as TimePeriodDropdownDashboardElemConfig;
      if (config.defaultValue) {
        // only set the default value if there is an option that represents it in the values config
        const selectedOption = _.find(
          config.values,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (option: any) => option.value === config.defaultValue,
        );
        if (selectedOption) {
          variables[elem.name] = config.defaultValue;
        }
      }
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATEPICKER) {
      const config = elem.config as DatepickerElemConfig;
      if (config.defaultType !== DEFAULT_DATE_TYPES.EXACT && config.relativeDefaultValue) {
        switch (config.relativeDefaultValue) {
          case RELATIVE_DATE_OPTIONS.CURRENT_DAY:
            variables[elem.name] = dayjs().startOf('day').toDate();
            return variables;
          case RELATIVE_DATE_OPTIONS.SEVEN_DAYS_AGO:
            variables[elem.name] = dayjs().subtract(7, 'days').startOf('day').toDate();
            return variables;
          case RELATIVE_DATE_OPTIONS.THIRTY_DAYS_AGO:
            variables[elem.name] = dayjs().subtract(30, 'days').startOf('day').toDate();
            return variables;
          case RELATIVE_DATE_OPTIONS.ONE_YEAR_AGO:
            variables[elem.name] = dayjs().subtract(365, 'days').startOf('day').toDate();
            return variables;
          case RELATIVE_DATE_OPTIONS.START_OF_WEEK:
            variables[elem.name] = dayjs().startOf('week').startOf('day').toDate();
            return variables;
          case RELATIVE_DATE_OPTIONS.START_OF_MONTH:
            variables[elem.name] = dayjs().startOf('month').startOf('day').toDate();
            return variables;
          case RELATIVE_DATE_OPTIONS.START_OF_YEAR:
            variables[elem.name] = dayjs().startOf('year').startOf('day').toDate();
            return variables;
          case RELATIVE_DATE_OPTIONS.END_OF_MONTH:
            variables[elem.name] = dayjs().endOf('month').startOf('day').toDate();
            return variables;
          case RELATIVE_DATE_OPTIONS.END_OF_WEEK:
            variables[elem.name] = dayjs().endOf('week').startOf('day').toDate();
            return variables;
          case RELATIVE_DATE_OPTIONS.END_OF_YEAR:
            variables[elem.name] = dayjs().endOf('year').startOf('day').toDate();
            return variables;
        }
      } else if (config.defaultValue) {
        variables[elem.name] = config.defaultValue;
      }
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) {
      const config = elem.config as DateRangePickerElemConfig;
      if (!config.defaultDateRange) return;

      variables[elem.name] = getDefaultRangeValues(config.defaultDateRange);
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH) {
      const config = elem.config as DateGroupSwitchConfig;
      variables[elem.name] = config.defaultGroupingOption || TrendGroupingOptions.MONTHLY;
    }
  });

  return variables;
};

export const getDefaultRangeValues = (defaultRangeType: DEFAULT_DATE_RANGES) => {
  switch (defaultRangeType) {
    case DEFAULT_DATE_RANGES.THIS_WEEK:
      return {
        startDate: dayjs().startOf('week').startOf('day').toDate(),
        endDate: dayjs().toDate(),
      };
    case DEFAULT_DATE_RANGES.THIS_MONTH:
      return {
        startDate: dayjs().startOf('month').startOf('day').toDate(),
        endDate: dayjs().toDate(),
      };
    case DEFAULT_DATE_RANGES.THIS_YEAR:
      return {
        startDate: dayjs().startOf('year').startOf('day').toDate(),
        endDate: dayjs().toDate(),
      };
    case DEFAULT_DATE_RANGES.LAST_7_DAYS:
      return {
        startDate: dayjs().subtract(7, 'days').startOf('day').toDate(),
        endDate: dayjs().startOf('day').toDate(),
      };
    case DEFAULT_DATE_RANGES.LAST_30_DAYS:
      return {
        startDate: dayjs().subtract(30, 'days').startOf('day').toDate(),
        endDate: dayjs().startOf('day').toDate(),
      };
    case DEFAULT_DATE_RANGES.LAST_3_MONTHS:
      return {
        startDate: dayjs().subtract(3, 'months').startOf('day').toDate(),
        endDate: dayjs().startOf('day').toDate(),
      };
    case DEFAULT_DATE_RANGES.LAST_YEAR:
      return {
        startDate: dayjs().subtract(1, 'year').startOf('day').toDate(),
        endDate: dayjs().startOf('day').toDate(),
      };
  }
};

export const getUrlSanitizedDashboardVars = (exportVars: DashboardVariableMap) => {
  const vars: { [key: string]: string } = {};

  Object.keys(exportVars).forEach((key) => {
    if (!exportVars[key]) return;

    const s = JSON.stringify(exportVars[key]);
    vars[key] = s;
  });

  return vars;
};

export const getUrlParamStringFromDashVars = (exportVars: DashboardVariableMap): string => {
  const sanitizedVars = getUrlSanitizedDashboardVars(exportVars);

  // first convert the variable dictionary into a URL
  let dummyUrl = parse('https://example.com');
  dummyUrl.set('query', sanitizedVars);

  // then read in the generated URL and pull out the unparsed query
  dummyUrl = parse(dummyUrl.toString());
  return ((dummyUrl.query as unknown) as string) || '?';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeUnderscoreFields = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (key.startsWith('_')) delete obj[key];
  });

  return obj;
};

export const removeUnsavedDashboardConfigFields = (config: DashboardVersionConfig) => {
  const cleanConfig = cloneDeep(config);
  Object.values(cleanConfig.datasets).map(removeUnderscoreFields);
  Object.values(cleanConfig.elements).map(removeUnderscoreFields);
  Object.values(cleanConfig.data_panels).map(removeUnderscoreFields);
  cleanConfig.dashboard_layout &&
    cleanConfig.dashboard_layout.forEach((layout) => {
      Object.keys(layout).forEach((layoutKey) => {
        // @ts-ignore
        if (layout[layoutKey] === undefined) delete layout[layoutKey];
      });
    });

  return cleanConfig;
};

export const getDefaultElementName = (elementType: string, config: DashboardVersionConfig) => {
  const otherElemNamesForType = _.pluck(
    _.filter(config.elements, (elem) => elem.element_type === elementType),
    'name',
  );
  const defaultElemNames = _.filter(otherElemNamesForType, (name) =>
    name.startsWith(`${elementType.toLowerCase()}-`),
  );
  const defaultElemNums = _.compact(
    _.map(defaultElemNames, (name) => parseInt(name.split('-')[1])),
  );
  const nextNum = Math.max(...defaultElemNums, 0) + 1;
  return `${elementType.toLowerCase()}-${nextNum}`;
};

export function processLayout({
  layout,
  dataPanelTemplates,
  dashboardElements,
  viewMode,
}: {
  layout: Layout[];
  dashboardElements?: DashboardElement[];
  dataPanelTemplates: DataPanelTemplate[];
  viewMode: VIEW_MODE;
}) {
  const elementMap = _.indexBy(dashboardElements || [], 'id');
  const panelMap = _.indexBy(dataPanelTemplates, 'id');

  _.forEach(layout, (panel) => {
    if (elementMap[panel.i]) {
      const element = elementMap[panel.i];
      if (element && element.element_type === DASHBOARD_ELEMENT_TYPES.CONTAINER) {
        const config = element.config as ContainerElemConfig;
        const layout =
          viewMode === VIEW_MODE.PDF ? config.pdfLayout || config.layout : config.layout;

        const { minHeight, minWidth } = getContainerLayoutMinimumSize(layout);
        panel.minH = Math.max(minHeight, 2);
        panel.minW = Math.max(minWidth, 2);
      } else if (element.element_type === DASHBOARD_ELEMENT_TYPES.SWITCH) {
        panel.minW = 3;
        panel.maxH = 1;
      }
    } else if (panelMap[panel.i]) {
      panel.minH = getPanelMinHeight(panelMap[panel.i]);
    }
  });
  return layout;
}

export const getPanelMinHeight = (panel: DataPanelTemplate) => {
  if (
    panel.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 ||
    panel.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2
  ) {
    return 1;
  } else {
    return 3;
  }
};

export const validateLayout = (layout: Layout[], containerRows: number) => {
  const maxHeight = Math.max(...layout.map((l) => (l.i !== 'null' ? l.y + l.h : 0)));

  return maxHeight < containerRows;
};

export const getContainerLayoutMinimumSize = (layout: Layout[]) => {
  const minWidth = Math.max(
    ...layout.map((l) => (l.i !== 'null' && l.i !== 'containerPlaceholder' ? l.x + l.w : 0)),
  );
  const minHeight = Math.max(
    ...layout.map((l) => (l.i !== 'null' && l.i !== 'containerPlaceholder' ? l.y + l.h : 0)),
  );
  /**
   * We add 1 to the height because height of a container in the main dashboard is 1 row
   * taller than the grid layout inside the container
   */
  return { minWidth, minHeight: minHeight + 1 };
};

export const fillVisualizeTableConfigToKeepCols = (
  changeSchemaList: SchemaChange[],
  computedSchema: TableColumn[],
) => {
  const instructionsColNames = new Set(_.pluck(changeSchemaList, 'col'));
  const colChangeByColName = _.indexBy(changeSchemaList, 'col');
  return computedSchema.map((tableColumn: TableColumn) => {
    if (!instructionsColNames.has(tableColumn.name)) {
      return {
        col: tableColumn.name,
        keepCol: true,
        newColName: titleCase(tableColumn.friendly_name ?? tableColumn.name),
      };
    } else {
      return colChangeByColName[tableColumn.name];
    }
  });
};

export function formatContainerElementHeightForMobile(
  layout: Layout[],
  elements: DashboardElement[],
): Layout[] {
  const newLayout = cloneDeep(layout);
  const containerMap = _.indexBy(
    elements.filter((e) => e.element_type === DASHBOARD_ELEMENT_TYPES.CONTAINER),
    'id',
  );

  newLayout.forEach((layoutElem) => {
    const container = containerMap[layoutElem.i];
    if (!container) return;
    const containerLayout = (container.config as ContainerElemConfig).layout;
    layoutElem.h = getContainerLayoutMinimumSize(compactLayout(containerLayout)).minHeight;
  });

  return newLayout;
}

export function compactLayout(layout: Layout[]) {
  const { compact, correctBounds } = utils;
  return compact(correctBounds(cloneDeep(layout), { cols: 2 }), 'vertical', 2);
}

export const incorporateNewColumns = (dpt: DataPanelTemplate) => {
  const newDpt = cloneDeep(dpt);
  const allCols = newDpt._schema || [];

  const changeSchemaList = newDpt.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList;
  const changeSchemaByColName = _.indexBy(changeSchemaList, 'col');
  let hasExtraCol = false;
  allCols.forEach((col) => {
    if (!changeSchemaByColName[col.name]) {
      hasExtraCol = hasExtraCol || true;
    }
  });

  if (hasExtraCol) {
    newDpt.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList = [];
  }

  return newDpt;
};

export function incorporateUserSchemaOverrides(
  dpt: DataPanelTemplate,
  userTransformedSchema: UserTransformedSchema,
) {
  const newDpt = cloneDeep(dpt);

  const changeSchemaList = newDpt.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList;
  const changeSchemaByColName = _.indexBy(changeSchemaList, 'col');

  newDpt.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList = userTransformedSchema.map(
    (userTransformedCol) => {
      const originalChangeSchema = changeSchemaByColName[userTransformedCol.name];
      return {
        col: userTransformedCol.name,
        newColName: userTransformedCol?.friendly_name || originalChangeSchema.newColName,
        keepCol: userTransformedCol.isVisible,
      };
    },
  );

  newDpt._adHocOperationInstructions?.filterInfo?.filterClauses.map((clause) => {
    if (!clause.filterColumn) return;

    const renamedCol = userTransformedSchema.find((col) => clause.filterColumn?.name === col.name);

    if (renamedCol) {
      clause.filterColumn.name = renamedCol.friendly_name || renamedCol.name;
    }
  });

  return newDpt;
}

export const processUserInputConfig = (
  variables: DashboardVariableMap,
  dpt?: DataPanelTemplate,
) => {
  if (!dpt) return dpt;
  const newDpt = cloneDeep(dpt);

  newDpt.filter_op.instructions.filterClauses.forEach((filterClause) => {
    if (filterClause.filterValueSource === FilterValueSourceType.VARIABLE) {
      if (!filterClause.filterValueVariableId) {
        filterClause.filterValue = undefined;
        return;
      }
      const value = variables[filterClause.filterValueVariableId];

      if (value === undefined) {
        // if the variable value is undefined, meaning unset, then it doesn't matter what type
        // the operation or column is, just set the filter value to undefined
        filterClause.filterValue = undefined;
      } else if (filterClause.filterOperation && filterClause.filterColumn) {
        const filterOpId = filterClause.filterOperation.id;
        if (FILTER_OPS_DATE_PICKER.has(filterOpId)) {
          // operations that do date filters on a single date (ie date is after X)
          // have values that take the form of { startDate: x }
          filterClause.filterValue = {
            startDate: value as string,
          };
        } else if (FILTER_OPS_DATE_RANGE_PICKER.has(filterOpId)) {
          const dateRangeValue = value as {
            startDate: Date;
            endDate: Date;
          };
          filterClause.filterValue = dateRangeValue;
        } else if (FILTER_OPS_RELATIVE_PICKER.has(filterOpId)) {
          // There is no variable element that supports what the relative date picker does
          // so always make the value undefined if it is trying to use a varaiable for this filter
          filterClause.filterValue = undefined;
        } else if (filterOpId === FILTER_OPERATOR_TYPES_BY_ID.STRING_IS_IN.id) {
          filterClause.filterValue = value as string[];
        } else if (filterOpId === FILTER_OPERATOR_TYPES_BY_ID.NUMBER_IS_IN.id) {
          filterClause.filterValue = value as number[];
        } else {
          if (NUMBER_TYPES.has(filterClause.filterColumn.type)) {
            // if the column being filtered is a number, confirm the value is a number and set it.
            // otherwise set the value to undefined. If the value is not a number, that would result in
            // the SQL code crashing since we'd be trying to filter a number column with a non-number val
            if (_.isNumber(value) && !_.isNaN(value)) {
              filterClause.filterValue = value as number;
            } else {
              filterClause.filterValue = undefined;
            }
          } else {
            // similarly, if it is not a number string, ie a char field, then cast the value to a string
            // to prevent type errors on the SQL query when ran
            filterClause.filterValue = String(value);
          }
        }
      }
    }
  });

  if (
    newDpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.bucket?.id ===
    DATE_PART_INPUT_AGG
  ) {
    if (newDpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART.categoryColumn?.bucketElemId) {
      const selectedGroupInput =
        variables[
          newDpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART.categoryColumn.bucketElemId
        ];
      newDpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART.categoryColumn.bucket =
        TREND_GROUP_OPTION_TO_PIVOT_AGG[selectedGroupInput as TrendGroupingOptions];
    }
  }

  if (newDpt.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
    const config = newDpt.visualize_op.instructions.V2_KPI_TREND;

    if (
      config?.periodColumn?.periodRange === PeriodRangeTypes.DATE_RANGE_INPUT &&
      config.periodColumn.rangeElemId
    ) {
      const rangeVariableValue = variables[config.periodColumn.rangeElemId] as {
        startDate: Date;
        endDate: Date;
      };

      if (rangeVariableValue) {
        config.periodColumn.customStartDate = dayjs(rangeVariableValue.startDate).format(
          'YYYY-MM-DD',
        );
        config.periodColumn.customEndDate = dayjs(rangeVariableValue.endDate).format('YYYY-MM-DD');
      } else {
        config.periodColumn.customStartDate = undefined;
        config.periodColumn.customEndDate = undefined;
      }
    } else if (
      config?.periodColumn?.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN &&
      config.periodColumn.timePeriodElemId
    ) {
      const timePeriodVariableValue = variables[config.periodColumn.timePeriodElemId] as number;

      if (timePeriodVariableValue) {
        config.periodColumn.customStartDate = dayjs
          .utc()
          .subtract(timePeriodVariableValue, 'minutes')
          .toISOString();
        config.periodColumn.customEndDate = dayjs.utc().toISOString();
      } else {
        config.periodColumn.customStartDate = undefined;
        config.periodColumn.customEndDate = undefined;
      }
    }

    return newDpt;
  }

  return newDpt;
};

export const areRequiredUserInputsSet = (
  variables: DashboardVariableMap,
  dpt?: DataPanelTemplate,
) => {
  if (!dpt) return dpt;

  if (dpt.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
    return areNumberTrendVarsSet(variables, dpt.visualize_op.instructions.V2_KPI_TREND);
  }

  return true;
};

export const getNumberColumnNames = (dataPanelTemplate: DataPanelTemplate, dataset: Dataset) => {
  const { visualize_op: visualizeOperation } = dataPanelTemplate;

  if (visualizeOperation.operation_type !== OPERATION_TYPES.VISUALIZE_TABLE) {
    return [];
  }

  return _.pluck(dataset.schema?.filter((column) => NUMBER_TYPES.has(column.type)) || [], 'name');
};

export const getAsynchronousSecondaryDataInstructions = (
  dataPanelTemplate: DataPanelTemplate,
  dataset: Dataset,
): DataPanelTemplate[] => {
  const { visualize_op: visualizeOperation } = dataPanelTemplate;
  switch (visualizeOperation.operation_type) {
    case OPERATION_TYPES.VISUALIZE_TABLE:
    case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
      return getSecondaryDataInstructionsForDataTable(dataPanelTemplate, dataset);
    case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
      return [getSecondaryDataForNumberTrendInstructions(dataPanelTemplate)];
    default:
      return [];
  }
};

const getSecondaryDataForNumberTrendInstructions = (dataPanelTemplate: DataPanelTemplate) => {
  const newInstructions = cloneDeep(dataPanelTemplate);
  newInstructions.visualize_op.instructions.V2_KPI_TREND = {
    ...newInstructions.visualize_op.instructions.V2_KPI_TREND,
    ...{ trendGrouping: undefined },
  };
  return newInstructions;
};

export const getSecondaryDataInstructionsForDataTable = (
  dataPanelTemplate: DataPanelTemplate,
  dataset?: Dataset,
): DataPanelTemplate[] => {
  const aggregations = [Aggregation.MIN, Aggregation.AVG, Aggregation.MAX];
  const displayOptions =
    dataPanelTemplate.visualize_op.instructions.VISUALIZE_TABLE.schemaDisplayOptions;

  if (!displayOptions || !dataset) return [];

  const columnsWithDisplayOptions = Object.keys(displayOptions);
  const numberColumnsWithDisplayOptions = columnsWithDisplayOptions.filter((displayColumnName) =>
    dataset.schema?.find(
      (column) => column.name === displayColumnName && NUMBER_TYPES.has(column.type),
    ),
  );
  const columnNames = numberColumnsWithDisplayOptions.filter((column) => {
    const columnDisplayOptions = displayOptions[column] as NumberDisplayOptions;
    return isSecondaryDataRequired(columnDisplayOptions);
  });

  const secondaryDataInstructions = columnNames.map((columnName) => ({ columnName, aggregations }));

  const aggregationDPT = cloneDeep(dataPanelTemplate);
  const pivotAggregations = getPivotAggregationsForSecondaryData(
    dataset,
    secondaryDataInstructions,
  );
  aggregationDPT.group_by_op.instructions.aggregations = pivotAggregations;

  return [aggregationDPT];
};

export const getSynchronousSecondaryDataInstructions = (
  dataPanelTemplate: DataPanelTemplate,
): DataPanelTemplate[] => {
  const { visualize_op: visualizeOperation } = dataPanelTemplate;
  switch (visualizeOperation.operation_type) {
    case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
      return getSynchronousSecondaryDataInstructionsForBoxPlot(dataPanelTemplate);
    default:
      return [];
  }
};

export const getSynchronousSecondaryDataInstructionsForBoxPlot = (
  dataPanelTemplate: DataPanelTemplate,
): DataPanelTemplate[] => {
  /**
   * For BoxPlots backed by a Redshift DB, we need to fetch batches of metrics for different calc columns
   * one at a time. So we don't want to overwrite secondaryData, but append to it instead.
   */
  if (dataPanelTemplate._source_type !== 'redshift') return [];
  const calcColumns = dataPanelTemplate.visualize_op.instructions.V2_BOX_PLOT?.calcColumns;

  if (calcColumns === undefined || (calcColumns && calcColumns.length < 2)) return [];

  const additionalCalcColumnsToFetch = calcColumns.slice(1);

  return additionalCalcColumnsToFetch.map((calcColumn) => {
    const dataPanelTemplateForCalcColumn = cloneDeep(dataPanelTemplate);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    dataPanelTemplateForCalcColumn.visualize_op.instructions.V2_BOX_PLOT!.calcColumns = [
      calcColumn,
    ];

    return dataPanelTemplateForCalcColumn;
  });
};

export const getPivotAggregationsForSecondaryData = (
  dataset: Dataset,
  secondaryDataInstructions: {
    columnName: string;
    aggregations: Aggregation[];
  }[],
) => {
  const aggregations: PivotOperationAggregation[] = [];

  secondaryDataInstructions.forEach((instruction) => {
    const columnInfo = dataset.schema?.find(
      (column) => column.name === instruction.columnName,
    ) as ColumnInfo;
    instruction.aggregations.forEach((aggregation) => {
      aggregations.push({
        aggedOnColumn: columnInfo,
        type: AGGREGATIONS_TYPES[aggregation],
      });
    });
  });

  return aggregations;
};

export const getGradientColor = ({
  gradient,
  gradientType,
  gradientOptions,
  value,
  metrics,
}: {
  value: number;
  metrics: NumberColumnMetrics;
  gradient?: Partial<GradientShape>;
  gradientType?: GradientType;
  gradientOptions?: GradientOptions;
}) => {
  const color1 = gradient?.hue1 || DEFAULT_GRADIENT.hue1;
  const color2 = gradient?.hue2 || DEFAULT_GRADIENT.hue2;
  const color3 = gradient?.hue3 || DEFAULT_GRADIENT.hue3;

  const minpoint =
    (gradientOptions?.minpoint?.type === GradientPointType.NUMBER
      ? gradientOptions?.minpoint?.number
      : metrics.min) ?? 0;
  const midpoint =
    (gradientOptions?.midpoint?.type === GradientPointType.NUMBER
      ? gradientOptions?.midpoint?.number
      : metrics.avg) ?? 0;
  const maxpoint =
    (gradientOptions?.maxpoint?.type === GradientPointType.NUMBER
      ? gradientOptions?.maxpoint?.number
      : metrics.max) ?? 0;

  const boundRatioZeroToOne = (ratio: number) => {
    if (ratio > 1) return 1;
    else if (ratio < 0) return 0;
    return ratio;
  };

  let color = undefined;
  if (gradientType === GradientType.LINEAR) {
    const ratio = getPercentageRatio(minpoint, maxpoint, value);
    color = mixColors(color3, color1, boundRatioZeroToOne(ratio)).rgb().string();
  } else if (gradientType === GradientType.DIVERGING) {
    if (value < (midpoint ?? 0)) {
      const ratio = getPercentageRatio(minpoint, midpoint, value);
      color = mixColors(color2, color1, boundRatioZeroToOne(ratio)).rgb().string();
    } else {
      const ratio = getPercentageRatio(midpoint, maxpoint, value);
      color = mixColors(color3, color2, boundRatioZeroToOne(ratio)).rgb().string();
    }
  }

  return color;
};

export const updateUserInputFieldsWithNewElemName = (
  config: DashboardVersionConfig,
  oldName: string,
  newName: string,
) => {
  Object.values(config.data_panels).forEach((dpt) => {
    if (dpt.visualize_op?.instructions.V2_KPI_TREND?.periodColumn?.rangeElemId === oldName) {
      dpt.visualize_op.instructions.V2_KPI_TREND.periodColumn.rangeElemId = newName;
    }

    dpt.filter_op?.instructions.filterClauses.forEach((filterClause) => {
      if (filterClause.filterValueVariableId === oldName) {
        filterClause.filterValueVariableId = newName;
      }
    });

    if (
      dpt.visualize_op?.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.bucketElemId ===
      oldName
    ) {
      dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART.categoryColumn.bucketElemId = newName;
    }
  });
};

export const updateUserInputFieldsWithDeletedElem = (
  config: DashboardVersionConfig,
  deletedElemId: string,
) => {
  Object.values(config.data_panels).forEach((dpt) => {
    if (dpt.visualize_op?.instructions.V2_KPI_TREND?.periodColumn?.rangeElemId === deletedElemId) {
      dpt.visualize_op.instructions.V2_KPI_TREND.periodColumn.rangeElemId = undefined;
      dpt.visualize_op.instructions.V2_KPI_TREND.periodColumn.periodRange =
        PeriodRangeTypes.LAST_4_WEEKS;
    }

    dpt.filter_op?.instructions.filterClauses.forEach((filterClause) => {
      if (filterClause.filterValueVariableId === deletedElemId) {
        filterClause.filterValueVariableId = undefined;
      }
    });

    if (
      dpt.visualize_op?.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.bucketElemId ===
      deletedElemId
    ) {
      dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART.categoryColumn.bucketElemId = undefined;
      dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART.categoryColumn.bucket =
        PIVOT_AGG_TYPES.DATE_MONTH;
    }
  });
};

export const getMetricsByColumn = (secondaryData: TableRow[]): MetricsByColumn => {
  const metrics = secondaryData[0];
  if (!metrics) return {};

  const metricsByColumn: { [columnName: string]: NumberColumnMetrics } = {};

  Object.keys(metrics).forEach((columnNameAgg) => {
    const stringArr = columnNameAgg.split('_');
    const columnName = stringArr.slice(0, stringArr.length - 1).join('_');
    const agg = stringArr[stringArr.length - 1];

    metricsByColumn[columnName] = {
      ...metricsByColumn[columnName],
      [agg]: metrics[columnNameAgg],
    };
  });

  return metricsByColumn;
};

export const filterForValidFilterElementsBasedOnType = (
  dashboardElements?: DashboardElement[],
  filterOperation?: FilterOperatorType,
) => {
  if (!filterOperation || !dashboardElements) return [];
  else if (FILTER_OPS_DATE_PICKER.has(filterOperation.id)) {
    return dashboardElements.filter(
      (elem) => elem.element_type === DASHBOARD_ELEMENT_TYPES.DATEPICKER,
    );
  } else if (FILTER_OPS_DATE_RANGE_PICKER.has(filterOperation.id)) {
    return dashboardElements.filter(
      (elem) => elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER,
    );
  } else if (FILTER_OPS_RELATIVE_PICKER.has(filterOperation.id)) {
    // no elements support relative picker yet
    return [];
  } else if (FILTER_OPS_MULTISELECT.has(filterOperation.id)) { 
    return dashboardElements.filter(
      (elem) => elem.element_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT,
    );
  } else {
    return dashboardElements.filter(
      (elem) => elem.element_type === DASHBOARD_ELEMENT_TYPES.DROPDOWN,
    );
  }
};

export const newOperatorShouldClearSelectedVariable = (
  newOperation?: FilterOperatorType,
  oldOperation?: FilterOperatorType,
) => {
  if (!newOperation || !oldOperation) return true;
  if (FILTER_OPS_DATE_PICKER.has(newOperation.id) !== FILTER_OPS_DATE_PICKER.has(oldOperation.id))
    return true;
  if (
    FILTER_OPS_DATE_RANGE_PICKER.has(newOperation.id) !==
    FILTER_OPS_DATE_RANGE_PICKER.has(oldOperation.id)
  )
    return true;
  if (
    FILTER_OPS_RELATIVE_PICKER.has(newOperation.id) !==
    FILTER_OPS_RELATIVE_PICKER.has(oldOperation.id)
  )
    return true;
  return false;
};

export const newOperatorDoesntHaveVariableOption = (filterOperation?: FilterOperatorType) => {
  if (!filterOperation) return true;

  return FILTER_OPS_RELATIVE_PICKER.has(filterOperation.id);
};

export const getDateGroupSwitchOptions = (config: DateGroupSwitchConfig) => {
  return _.compact(
    Object.values(TREND_GROUPING_OPTIONS).map((groupingOption) => {
      const configForOption =
        config.groupingOptionByType && config.groupingOptionByType[groupingOption.id];

      if (configForOption?.exclude) return null;

      return {
        name: configForOption?.name || groupingOption.name,
        id: groupingOption.id,
      };
    }),
  );
};

export const getDefaultValueForNewElem = (elem: DashboardElement) => {
  if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH) {
    return TrendGroupingOptions.MONTHLY;
  }
};

export const getLayoutHeightInRows = (
  layout: Layout[],
  lowerXBound?: number,
  upperXBound?: number,
) => {
  return _.reduce<Layout, number>(
    layout,
    (prevMax, elem) => {
      if (elem.i === 'null') return prevMax;

      if (lowerXBound !== undefined && upperXBound !== undefined) {
        const elementStartX = elem.x;
        const elementEndX = elem.x + elem.w - 1;
        const elementDoesNotIntersect = elementStartX > upperXBound || elementEndX < lowerXBound;

        if (elementDoesNotIntersect) {
          return prevMax;
        }
      }

      return Math.max(prevMax, elem.h + elem.y);
    },
    0,
  );
};

export const resolveSecondaryLayout = (primaryLayout: Layout[], secondaryLayout: Layout[]) => {
  const wasItemAdded = primaryLayout.length > secondaryLayout.length;
  const wasItemDeleted = primaryLayout.length < secondaryLayout.length;

  if (wasItemAdded) {
    const addedItem = primaryLayout[primaryLayout.length - 1];

    const maxHeightAboveItem = getLayoutHeightInRows(
      secondaryLayout,
      addedItem.x,
      addedItem.x + addedItem.w - 1,
    );
    const addedItemForSecondaryLayout = {
      ...addedItem,
      y: maxHeightAboveItem,
    };

    return secondaryLayout.concat(addedItemForSecondaryLayout);
  } else if (wasItemDeleted) {
    const primaryLayoutItemIds: string[] = primaryLayout.map((item: Layout) => item.i);
    return secondaryLayout.filter((item) => primaryLayoutItemIds.includes(item.i));
  }

  return secondaryLayout;
};

export const getMainLayoutForNewContainerLayout = (
  mainLayout: Layout[],
  newContainerLayout: Layout[],
  containerId: string,
) => {
  const containerLayoutItem = mainLayout.find((item) => item.i === containerId);
  const doesLayoutFitInExistingContainer = validateLayout(
    newContainerLayout,
    containerLayoutItem?.h || 0,
  );

  if (doesLayoutFitInExistingContainer) {
    return mainLayout;
  }

  return mainLayout.map((item) => {
    if (item.i === containerId) {
      const maxRows = getLayoutHeightInRows(newContainerLayout);
      return { ...item, h: maxRows + 1 };
    }
    return item;
  });
};

export const getChangedSchema = (schema: Schema, instructions: VisualizeTableInstructions) => {
  const changeSchemaList = instructions.changeSchemaList;
  const changedSchema: Schema = [];
  const changeSchemaDictionary = _.indexBy(changeSchemaList, 'col');
  schema.forEach((columnInfo: ColumnInfo) => {
    if (columnInfo.name in changeSchemaDictionary) {
      const col = changeSchemaDictionary[columnInfo.name];
      if (col.keepCol) {
        if (col.newColName !== null && col.newColName !== '') {
          columnInfo.friendly_name = col.newColName;
        } else {
          columnInfo.friendly_name = titleCase(columnInfo.name);
        }
        changedSchema.push(columnInfo);
      }
    } else {
      columnInfo.friendly_name = titleCase(columnInfo.name);
      changedSchema.push(columnInfo);
    }
  });
  return changedSchema;
};

export const getExcludedColumns = (schema: Schema, instructions: VisualizeTableInstructions) => {
  const changeSchemaList = instructions.changeSchemaList;
  const changeSchemaDictionary = _.indexBy(changeSchemaList, 'col');
  return schema.filter((columnInfo: ColumnInfo) => {
    if (columnInfo.name in changeSchemaDictionary) {
      const col = changeSchemaDictionary[columnInfo.name];
      return !col.keepCol;
    }
    return false;
  });
};

/**
 * React-grid-layout uses the ordering of items to determine display precedence.
 * If a dropdown that is vertically rendered above a data panel is prior in the list,
 * the data panel will take display precedence over the menu of the dropdown.
 * To avoid this, we sort items by reverse y-position.
 */
export function getSortedGridItems(
  gridItems: (DataPanelTemplate | DashboardElement)[],
  layout: Layout[],
) {
  const layoutSortedByYPosition = _.sortBy(layout, (item) => -item.y);
  const sortedGridItems = layoutSortedByYPosition.map((layoutItem) => {
    if (!layoutItem.i) return undefined;
    return gridItems.find((gridItem) => gridItem.id === layoutItem.i);
  });

  return _.compact(sortedGridItems);
}

export const removeUserDisabledColumns = (schema: UserTransformedSchema) => {
  return schema.filter((column) => column.isVisible);
};

export const placeElementInLayout = (payload: {
  newElementLayout: Layout;
  newElementConfig: DashboardElement | DataPanelTemplate;
  layout: Layout[];
  config: DashboardVersionConfig;
  yStart: number;
  elementIsDataPanel: boolean;
  dashId: number;
}) => {
  const {
    newElementLayout,
    newElementConfig,
    layout,
    config,
    yStart,
    elementIsDataPanel,
    dashId,
  } = payload;

  newElementLayout.i = GENERIC_DROPPING_ID;
  newElementLayout.y = yStart;
  layout.push(newElementLayout);

  newElementConfig.id = `dash${dashId}-${uuidv4()}`;

  // calculate the new name, numbering after the first copy
  let newName = `${newElementConfig.name}_copy`;
  const itemNames = _.pluck(Object.values(config.elements), 'name').concat(
    _.pluck(Object.values(config.data_panels), 'name'),
  );

  const dupeNames = _.filter(itemNames, (name) => name.startsWith(`${newName}`));

  if (dupeNames !== []) {
    const dupeNums = _.compact(_.map(dupeNames, (name) => parseInt(name.split('_')[2])));
    const nextNum = Math.max(...dupeNums, 0) + 1;
    newName = `${newElementConfig.name}_copy_${nextNum}`;
  }
  newElementConfig.name = newName;

  if (elementIsDataPanel) {
    config.data_panels[newElementConfig.id] = newElementConfig as DataPanelTemplate;
  } else {
    config.elements[newElementConfig.id] = newElementConfig as DashboardElement;
  }

  addItemToConfigLayouts(
    config,
    {
      newLayout: layout,
      containerId: newElementConfig.container_id,
    },
    newElementConfig.id,
  );
};

export const addItemToConfigLayouts = (
  config: DashboardVersionConfig,
  payload: { newLayout: Layout[]; containerId?: string },
  elementId: string,
) => {
  const draggedElem = _.find(payload.newLayout, (elem) => elem?.i.startsWith(GENERIC_DROPPING_ID));

  if (!draggedElem) return config;

  draggedElem.i = elementId;
  draggedElem.isDraggable = undefined;

  if (payload.containerId) {
    const containerConfig = config.elements[payload.containerId].config as ContainerElemConfig;
    containerConfig.layout = payload.newLayout;

    config.dashboard_layout = getMainLayoutForNewContainerLayout(
      config.dashboard_layout,
      payload.newLayout,
      payload.containerId,
    );

    if (config.pdf_layout && containerConfig.pdfLayout) {
      const newContainerPdfLayout = resolveSecondaryLayout(
        payload.newLayout,
        containerConfig.pdfLayout,
      );
      containerConfig.pdfLayout = newContainerPdfLayout;

      config.pdf_layout = getMainLayoutForNewContainerLayout(
        config.pdf_layout,
        newContainerPdfLayout,
        payload.containerId,
      );
    }
  } else {
    config.dashboard_layout = payload.newLayout;

    if (config.pdf_layout) {
      config.pdf_layout = resolveSecondaryLayout(payload.newLayout, config.pdf_layout);
    }
  }
};

export const filterChangedDpt = (
  dataPanelTemplates: DataPanelTemplate[],
  changedVarName?: string,
) => {
  if (!changedVarName) return dataPanelTemplates;

  return dataPanelTemplates.filter((dpt) => {
    const dptId = dpt.provided_id || dpt.id;
    return dptId !== changedVarName;
  });
};

export const filterDptsWithDrilldowns = (dataPanelTemplates: DataPanelTemplate[]) => {
  return dataPanelTemplates.filter((dpt) => {
    if (VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN.has(dpt.visualize_op?.operation_type)) {
      return dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.drilldown?.categorySelectEnabled;
    }

    return false;
  });
};

export const handleDownloadBlocked = (
  updateStateOnDownloadBlocked: () => void,
  openedWindow: Window | null,
) =>
  /*
   * If the dashboard is run in an iframe sandbox, the popup will appear but the
   * actual download will be blocked, so we need to manually prompt the user to download it.
   * 1 second seems like a good enough window since if the download goes through the window
   * should almost immediately open and then close.
   */

  setTimeout(function () {
    if (openedWindow && !openedWindow.closed) {
      updateStateOnDownloadBlocked();
      openedWindow.close();
    }
  }, 1000);
