/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';
import { Button, ButtonGroup, IconName } from '@blueprintjs/core';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2KPIChartInstructions } from 'constants/types';
import { ALIGNMENT_FORMATS } from 'constants/dataConstants';

const ORDERED_ALIGNMENTS = [
  ALIGNMENT_FORMATS.LEFT_ALIGN,
  ALIGNMENT_FORMATS.CENTER_ALIGN,
  ALIGNMENT_FORMATS.RIGHT_ALIGN,
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2KPIChartInstructions;
};

export default function KPIGeneralConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedAlignment =
    instructions.generalFormat?.alignment || ALIGNMENT_FORMATS.CENTER_ALIGN.id;

  return (
    <div className={classes.root}>
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={instructions.generalFormat?.title}
        onNewValueSubmitted={(newValue: string) => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.generalFormat = {
            ...newInstructions.generalFormat,
            title: newValue,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Title"
      />
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={instructions.generalFormat?.subtitle}
        onNewValueSubmitted={(newValue: string) => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.generalFormat = {
            ...newInstructions.generalFormat,
            subtitle: newValue,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Description"
      />
      <ButtonGroup fill className={classes.configInput}>
        {ORDERED_ALIGNMENTS.map((alignment) => (
          <Button
            key={alignment.id}
            icon={alignment.icon as IconName}
            active={selectedAlignment === alignment.id}
            onClick={() => {
              const newInstructions = cloneDeep(instructions);

              newInstructions.generalFormat = {
                ...newInstructions.generalFormat,
                alignment: alignment.id,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
          />
        ))}
      </ButtonGroup>
    </div>
  );
}
