/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Theme, WithStyles } from '@material-ui/core/index';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

import { OPERATION_TYPES } from 'constants/types';
import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import NumberLayoutConfiguration from 'pages/dataPanelEditorPage/visualizeSections/numberLayoutConfiguration';
import NumberColumnConfiguration from 'pages/dataPanelEditorPage/visualizeSections/numberColumnConfiguration';
import NumberFormatConfiguration from 'pages/dataPanelEditorPage/visualizeSections/numberFormatConfiguration';
import NumberMultiplyConfiguration from 'pages/dataPanelEditorPage/visualizeSections/numberMultiplyConfiguration';
import NumberUnitConfiguration from 'pages/dataPanelEditorPage/visualizeSections/numberUnitConfiguration';
import NumberAlignmentConfiguration from 'pages/dataPanelEditorPage/visualizeSections/numberAlignmentConfiguration';
import NumberEmptyTextInput from 'pages/dataPanelEditorPage/visualizeSections/numberEmptyTextInput';
import NumberProgressGoalInput from 'pages/dataPanelEditorPage/visualizeSections/numberProgressGoalInput';
import ColorPickerGroup from 'pages/dataPanelEditorPage/visualizeSections/shared/colorPickerGroup';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { VisualizeNumberInstructions } from 'constants/types';
import { DataPanelTemplate } from 'actions/types';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    configSection: {
      marginBottom: theme.spacing(4),
    },
  });

type PassedProps = {
  instructions: VisualizeNumberInstructions;
  dataPanelTemplate: DataPanelTemplate;
};

type Props = PassedProps &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  RouteComponentProps &
  ReturnType<typeof mapStateToProps>;

class VisualizeNumberSection extends React.Component<Props> {
  render() {
    const { classes, dataPanelTemplate, instructions, updateVisualizeOperation } = this.props;
    if (!dataPanelTemplate || !dataPanelTemplate._schema) return <div></div>;

    return (
      <div className={classes.root}>
        <ConfigSectionHeader title="Layout" />
        <NumberLayoutConfiguration
          visualizeNumberInstructions={instructions}
          updateVisualizeOperation={(
            newVisualizeNumberInstructions: VisualizeNumberInstructions,
          ) => {
            updateVisualizeOperation(
              newVisualizeNumberInstructions,
              OPERATION_TYPES.VISUALIZE_NUMBER,
            );
          }}
        />

        <ConfigSectionHeader title="Number" />
        <NumberColumnConfiguration
          schema={dataPanelTemplate._schema}
          visualizeNumberInstructions={instructions}
          updateVisualizeOperation={(
            newVisualizeNumberInstructions: VisualizeNumberInstructions,
          ) => {
            updateVisualizeOperation(
              newVisualizeNumberInstructions,
              OPERATION_TYPES.VISUALIZE_NUMBER,
            );
          }}
        />
        <NumberFormatConfiguration
          visualizeNumberInstructions={instructions}
          updateVisualizeOperation={(
            newVisualizeNumberInstructions: VisualizeNumberInstructions,
          ) => {
            updateVisualizeOperation(
              newVisualizeNumberInstructions,
              OPERATION_TYPES.VISUALIZE_NUMBER,
            );
          }}
        />
        <NumberMultiplyConfiguration
          visualizeNumberInstructions={instructions}
          updateVisualizeOperation={(
            newVisualizeNumberInstructions: VisualizeNumberInstructions,
          ) => {
            updateVisualizeOperation(
              newVisualizeNumberInstructions,
              OPERATION_TYPES.VISUALIZE_NUMBER,
            );
          }}
        />
        {instructions.displayFormat.isProgess === true && (
          <ColorPickerGroup
            label="Progress Bar Color"
            className={classes.configSection}
            color={instructions.displayFormat.progressColor || '#0069ED'}
            onChange={(newColor: string) => {
              const newInstrunctions = cloneDeep(instructions);
              newInstrunctions.displayFormat.progressColor = newColor;
              updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_NUMBER);
            }}
          />
        )}

        {instructions.displayFormat.isProgess === true && (
          <NumberProgressGoalInput
            visualizeNumberInstructions={instructions}
            updateVisualizeOperation={(
              newVisualizeNumberInstructions: VisualizeNumberInstructions,
            ) => {
              updateVisualizeOperation(
                newVisualizeNumberInstructions,
                OPERATION_TYPES.VISUALIZE_NUMBER,
              );
            }}
          />
        )}

        <ConfigSectionHeader title="Format" />
        <NumberUnitConfiguration
          visualizeNumberInstructions={instructions}
          updateVisualizeOperation={(
            newVisualizeNumberInstructions: VisualizeNumberInstructions,
          ) => {
            updateVisualizeOperation(
              newVisualizeNumberInstructions,
              OPERATION_TYPES.VISUALIZE_NUMBER,
            );
          }}
        />
        <NumberAlignmentConfiguration
          visualizeNumberInstructions={instructions}
          updateVisualizeOperation={(
            newVisualizeNumberInstructions: VisualizeNumberInstructions,
          ) => {
            updateVisualizeOperation(
              newVisualizeNumberInstructions,
              OPERATION_TYPES.VISUALIZE_NUMBER,
            );
          }}
        />
        <NumberEmptyTextInput
          visualizeNumberInstructions={instructions}
          updateVisualizeOperation={(
            newVisualizeNumberInstructions: VisualizeNumberInstructions,
          ) => {
            updateVisualizeOperation(
              newVisualizeNumberInstructions,
              OPERATION_TYPES.VISUALIZE_NUMBER,
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateVisualizeOperation,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VisualizeNumberSection)),
);
