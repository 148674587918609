/** @format */

import React from 'react';
import cx from 'classnames';
import { ProgressBar as BlueprintProgressBar } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

type StyleProps = Pick<Props, 'backgroundColor' | 'color'>;
const useStyles = makeStyles((theme: Theme) => ({
  progressBar: {
    borderRadius: 4,
    height: 25,
    backgroundColor: (props: StyleProps) => props.backgroundColor,
    '&.bp3-progress-bar .bp3-progress-meter': {
      backgroundColor: (props: StyleProps) => props.color || theme.palette.ds.blue,
      borderRadius: 4,
    },
  },
}));

type Props = {
  className?: string;
  value: number;
  color?: string;
  backgroundColor?: string;
};

export default function ProgressBar({ className, value, color, backgroundColor }: Props) {
  const classes = useStyles({ backgroundColor, color });
  return (
    <BlueprintProgressBar
      animate={false}
      stripes={false}
      className={cx(classes.progressBar, className)}
      value={value}
    />
  );
}
