/** @format */

import React from 'react';
import { dayjs } from 'utils/localizationUtils';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import { ROUTES } from 'constants/routes';

import PageWithNavbar from 'components/pages/pagewithNavBar';

type PassedProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageComponent: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  permissionFns?: any;
  withNavigation?: boolean;
  noMatch?: boolean;
  onboardingPage?: boolean;
  activeTabId?: string;
  exact?: boolean;
  path?: string;
};

type Props = PassedProps & ReturnType<typeof mapStateToProps>;

const PrivateRoute = ({
  pageComponent: Component,
  withNavigation,
  noMatch,
  currentUser,
  permissionFns,
  onboardingPage,
  activeTabId,
  ...rest
}: Props) => {
  const routeComponent = (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser.id === null || currentUser.id === undefined) {
          if (props.location.pathname === ROUTES.LOGIN) {
            return <Component {...props} />;
          }
          return noMatch ? <Component {...props} /> : <Redirect to={{ pathname: ROUTES.LOGIN }} />;
        } else if (props.location.pathname === ROUTES.LOGIN) {
          return <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />;
          // } else if (
          //   !currentUser.email_verified &&
          //   props.location.pathname !== ROUTES.CHECK_YOUR_EMAIL
          // ) {
          //   return <Redirect to={{ pathname: ROUTES.CHECK_YOUR_EMAIL }} />;
          // } else if (
          //   !currentUser.email_verified &&
          //   props.location.pathname === ROUTES.CHECK_YOUR_EMAIL
          // ) {
          //   return <Component {...props} />;
        } else if (props.location.pathname === ROUTES.SETTINGS_PAGE)
          return <Component {...props} />;
        else if (!currentUser.has_team && props.location.pathname !== ROUTES.JOIN_TEAM) {
          return <Redirect to={{ pathname: ROUTES.JOIN_TEAM }} />;
        } else if (!currentUser.has_team && props.location.pathname === ROUTES.JOIN_TEAM) {
          return <Component {...props} />;
        } else if (
          !currentUser.team_data_source_connected &&
          props.location.pathname !== ROUTES.CONNECT_DATA_SOURCE
        ) {
          return <Redirect to={{ pathname: ROUTES.CONNECT_DATA_SOURCE }} />;
        } else if (
          !currentUser.team_data_source_connected &&
          props.location.pathname === ROUTES.CONNECT_DATA_SOURCE
        ) {
          return <Component {...props} />;
        } else if (
          currentUser.team &&
          currentUser.team.trial_expiration_date &&
          dayjs().isAfter(dayjs(currentUser.team.trial_expiration_date))
        ) {
          return props.location.pathname === ROUTES.TRIAL_EXPIRED ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: ROUTES.TRIAL_EXPIRED }} />
          );
        } else {
          if (permissionFns) {
            for (let i = 0; i < permissionFns.length; i += 1) {
              const userPassesPermission = permissionFns[i](currentUser);
              if (!userPassesPermission) {
                return <Redirect to={{ pathname: ROUTES.HOME }} />;
              }
            }
          }
          return noMatch ? (
            <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />
          ) : (
            <Component {...props} />
          );
        }
      }}
    />
  );
  return withNavigation ? (
    <PageWithNavbar removeLeftRoutes={onboardingPage} activeTabId={activeTabId}>
      {routeComponent}
    </PageWithNavbar>
  ) : (
    routeComponent
  );
};

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(PrivateRoute);
