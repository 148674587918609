/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/index';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import InputLabel from 'shared/InputLabel';
import Tag from '../Tag/Tag';
import { IconName, Intent } from '@blueprintjs/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .bp3-input-action': {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      marginRight: 5,
    },
  },
  inputClass: {
    '& input[type="number"]': {
      '-webkit-appearance': 'textfield',
      '-moz-appearance': 'textfield',
      appearance: 'textfield',
    },
    '& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button ': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  statusTag: {
    marginLeft: theme.spacing(3),
  },
}));

export type Props = {
  /**
   * optional class to wrap entire component
   */
  className?: string;
  /**
   * optional label shown above the input in the top left
   */
  label?: string;
  /**
   * Flag to disable input from user input
   */
  disabled?: boolean;
  /**
   * Text to show when no input has been made
   */
  placeholder?: string;
  /**
   * Type of the input
   */
  type?: string;
  /**
   * Text to show when no input has been made. Required since this is a controlled component
   */
  value: string;
  /**
   * Function that runs on change events
   */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * If provided, an informational tooltip will show up next to the label
   */
  helpText?: string;
  /**
   * Information on the status tag to show
   */
  statusInfo?: {
    /**
     * Intent of the status tag - controls color
     */
    statusIntent?: Intent;
    /**
     * Icon to optionally show on the tag
     */
    statusIcon?: IconName;
    /**
     * If provided, an informational tooltip will show up next to the label
     */
    statusText?: string;
  };
};

const InputWithTag: React.FC<Props> = ({
  className,
  label,
  disabled,
  placeholder,
  type,
  value,
  onChange,
  statusInfo,
  helpText,
}) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <InputLabel text={label || ''} helpText={helpText} />
      <InputGroup
        className={classes.inputClass}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        type={type}
        onChange={onChange}
        rightElement={
          statusInfo && (
            <Tag
              className={classes.statusTag}
              intent={statusInfo.statusIntent}
              leftIcon={statusInfo.statusIcon}
              text={statusInfo.statusText}
            />
          )
        }
      />
    </div>
  );
};

export default InputWithTag;
