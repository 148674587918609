/** @format */

import React from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';
import { IconName } from '@blueprintjs/icons';

import DropdownSelect from 'shared/DropdownSelect';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';
import { OPERATION_TYPES, V2KPITrendInstructions } from 'constants/types';
import { SelectedDropdownInputItem } from 'constants/types';
import { V2_NUMBER_FORMATS, TIME_DIFF_FORMATS, ORDERED_ALIGNMENTS } from 'constants/dataConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
  colorPaletteDisplay: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  groupedInput: {
    width: '50%',

    '&:first-child': {
      marginRight: theme.spacing(3),
    },
  },
  groupedInputsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2KPITrendInstructions;
};

export default function KPITrendValueConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedFormat = instructions.valueFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;
  const selectedTimeFormat = instructions.valueFormat?.timeFormat || TIME_DIFF_FORMATS.STANDARD;
  const selectedAlignment =
    instructions.valueFormat?.horizAlignment || TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER;

  return (
    <div className={classes.root}>
      <ToggleButtonGroup fillWidth className={classes.configInput} label="Format">
        {Object.values(V2_NUMBER_FORMATS).map((numberFormat) => (
          <ToggleButton
            key={numberFormat.id}
            active={numberFormat.id === selectedFormat.id}
            onClick={() => {
              const newInstructions = cloneDeep(instructions);

              newInstructions.valueFormat = {
                ...newInstructions.valueFormat,
                numberFormat: numberFormat,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            text={numberFormat.abbreviation}
          />
        ))}
      </ToggleButtonGroup>

      <DropdownSelect
        containerClassName={classes.configInput}
        selectedItem={selectedFormat}
        onChange={(item: SelectedDropdownInputItem) => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.valueFormat = {
            ...newInstructions.valueFormat,
            numberFormat: item,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        filterable={false}
        options={Object.values(V2_NUMBER_FORMATS)}
        noSelectionText="Plain Text"
        minimal
        btnMinimal
        fillWidth
        label="Format"
      />
      {selectedFormat.id === V2_NUMBER_FORMATS.TIME.id && (
        <>
          <DropdownSelect
            containerClassName={classes.configInput}
            selectedItem={selectedTimeFormat}
            onChange={(item: SelectedDropdownInputItem) => {
              const newInstructions = cloneDeep(instructions);

              newInstructions.valueFormat = {
                ...newInstructions.valueFormat,
                timeFormat: item,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            filterable={false}
            options={Object.values(TIME_DIFF_FORMATS)}
            noSelectionText="Standard"
            minimal
            btnMinimal
            fillWidth
            label="Time Format"
          />
          {instructions.valueFormat?.timeFormat?.id === TIME_DIFF_FORMATS.CUSTOM.id && (
            <InputWithBlurSave
              containerClassName={classes.configInput}
              initialValue={instructions.valueFormat?.timeCustomerFormat}
              onNewValueSubmitted={(newValue: string) => {
                const newInstructions = cloneDeep(instructions);
                newInstructions.valueFormat = {
                  ...newInstructions.valueFormat,
                  timeCustomerFormat: newValue,
                };
                dispatch(
                  updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                );
              }}
              placeholder="DD days HH:mm:ss"
              label="Custom Time Format"
            />
          )}
        </>
      )}
      {selectedFormat.id !== V2_NUMBER_FORMATS.PLAIN_TEXT.id &&
        selectedFormat.id !== V2_NUMBER_FORMATS.TIME.id && (
          <InputWithBlurSave
            containerClassName={classes.configInput}
            initialValue={String(instructions.valueFormat?.decimalPlaces ?? 2)}
            onNewValueSubmitted={(newValue: string) => {
              const intValue = parseInt(newValue);
              const newInstructions = cloneDeep(instructions);
              if (intValue === 0 || intValue) {
                newInstructions.valueFormat = {
                  ...newInstructions.valueFormat,
                  decimalPlaces: intValue,
                };
                dispatch(
                  updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                );
              }
            }}
            label="Decimal Places"
          />
        )}

      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={instructions.valueFormat?.units}
        onNewValueSubmitted={(newValue: string) => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.valueFormat = {
            ...newInstructions.valueFormat,
            units: newValue,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Unit"
      />

      <div className={cx(classes.configInput, classes.groupedInputsContainer)}>
        <ToggleButtonGroup fillWidth className={classes.groupedInput} label="Bold">
          <ToggleButton
            key="no-bold-selection-kpi"
            active={!instructions.valueFormat?.bold}
            onClick={() => {
              const newInstructions = cloneDeep(instructions);

              newInstructions.valueFormat = {
                ...newInstructions.valueFormat,
                bold: !instructions.valueFormat?.bold,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            text=" "
          />
          <ToggleButton
            key="bold-selection-kpi"
            active={instructions.valueFormat?.bold}
            onClick={() => {
              const newInstructions = cloneDeep(instructions);

              newInstructions.valueFormat = {
                ...newInstructions.valueFormat,
                bold: !instructions.valueFormat?.bold,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            icon="bold"
          />
        </ToggleButtonGroup>

        <ToggleButtonGroup fillWidth className={classes.groupedInput} label="Italic">
          <ToggleButton
            key="no-italic-selection-kpi"
            active={!instructions.valueFormat?.italic}
            onClick={() => {
              const newInstructions = cloneDeep(instructions);

              newInstructions.valueFormat = {
                ...newInstructions.valueFormat,
                italic: !instructions.valueFormat?.italic,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            text=" "
          />
          <ToggleButton
            key="italic-selection-kpi"
            active={instructions.valueFormat?.italic}
            onClick={() => {
              const newInstructions = cloneDeep(instructions);

              newInstructions.valueFormat = {
                ...newInstructions.valueFormat,
                italic: !instructions.valueFormat?.italic,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            icon="italic"
          />
        </ToggleButtonGroup>
      </div>

      {instructions?.hideTrendLines && (
        <ToggleButtonGroup fillWidth className={classes.configInput}>
          {ORDERED_ALIGNMENTS.map((alignment) => (
            <ToggleButton
              key={alignment.id}
              active={alignment.id === selectedAlignment}
              onClick={() => {
                const newInstructions = cloneDeep(instructions);

                newInstructions.valueFormat = {
                  ...newInstructions.valueFormat,
                  horizAlignment: alignment.id,
                };
                dispatch(
                  updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                );
              }}
              icon={alignment.icon as IconName}
            />
          ))}
        </ToggleButtonGroup>
      )}
    </div>
  );
}
