/** @format */

import React from 'react';
import cx from 'classnames';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import InputLabel from 'shared/InputLabel';

type Props = {
  className?: string;
  value: string;
  onUpdate: (newValue: string) => void;
  label: string;
  placeHolder?: string;
};

export default class InputConfig extends React.Component<Props> {
  render = () => {
    const { label, onUpdate, className, value, placeHolder } = this.props;

    return (
      <div className={cx(className)}>
        {label && <InputLabel text={label} />}
        <InputWithBlurSave
          initialValue={value || ''}
          onNewValueSubmitted={onUpdate}
          placeholder={placeHolder}
        />
      </div>
    );
  };
}
