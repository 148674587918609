/** @format */

import * as types from 'constants/types';
import {
  STRING,
  INTEGER_DATA_TYPE,
  FLOAT,
  TIMESTAMP,
  DATETIME,
  DATE,
  BOOLEAN,
  PIE_CHART_TYPES,
  LEGEND_LOCATIONS,
} from 'constants/dataConstants';
import { DATE_FORMAT_TO_LOCALIZED_FORMAT } from 'utils/localizationUtils';

export const ALLOWED_DATA_SOURCES_FOR_DP_DOWNLOADS = [
  'postgres',
  'redshift',
  'mysql',
  'big_query',
  'snowflake',
];

export const Y_AXIS_RANGE_OPTIONS_BY_ID: types.YAxisRangeOptionsTypes = {
  FIXED: {
    id: 'FIXED',
    name: 'Fixed axis range',
    selectionValue: 'Fixed axis range',
  },
  DYNAMIC: {
    id: 'DYNAMIC',
    name: 'Dynamic axis range',
    selectionValue: 'Dynamic axis range',
  },
};

export const Y_AXIS_RANGE_OPTIONS = Object.values(Y_AXIS_RANGE_OPTIONS_BY_ID);

export const FILTER_OPERATOR_TYPES_BY_ID: types.FilterOperatorTypes = {
  STRING_IS: {
    id: 'STRING_IS',
    name: 'is',
    selectionValue: 'is...',
    supported_column_types: new Set([STRING]),
  },
  STRING_IS_NOT: {
    id: 'STRING_IS_NOT',
    name: 'is not',
    selectionValue: 'is not...',
    supported_column_types: new Set([STRING]),
  },
  STRING_IS_IN: {
    id: 'STRING_IS_IN',
    name: 'is in',
    selectionValue: 'is in...',
    supported_column_types: new Set([STRING]),
  },
  STRING_CONTAINS: {
    id: 'STRING_CONTAINS',
    name: 'contains',
    selectionValue: 'contains...',
    supported_column_types: new Set([STRING]),
  },
  STRING_DOES_NOT_CONTAIN: {
    id: 'STRING_DOES_NOT_CONTAIN',
    name: 'does not contain',
    selectionValue: 'does not contain...',
    supported_column_types: new Set([STRING]),
  },
  NUMBER_EQ: {
    id: 'NUMBER_EQ',
    name: '=',
    selectionValue: '=',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT]),
  },
  NUMBER_NEQ: {
    id: 'NUMBER_NEQ',
    name: '≠',
    selectionValue: '≠',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT]),
  },
  NUMBER_LT: {
    id: 'NUMBER_LT',
    name: '<',
    selectionValue: '<',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT]),
  },
  NUMBER_GT: {
    id: 'NUMBER_GT',
    name: '>',
    selectionValue: '>',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT]),
  },
  NUMBER_LTE: {
    id: 'NUMBER_LTE',
    name: '≤',
    selectionValue: '≤',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT]),
  },
  NUMBER_GTE: {
    id: 'NUMBER_GTE',
    name: '≥',
    selectionValue: '≥',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT]),
  },
  NUMBER_IS_IN: {
    id: 'NUMBER_IS_IN',
    name: 'is in',
    selectionValue: 'is in...',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT]),
  },
  DATE_IS: {
    id: 'DATE_IS',
    name: 'is',
    selectionValue: 'is...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_IS_NOT: {
    id: 'DATE_IS_NOT',
    name: 'is not',
    selectionValue: 'is not...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_IS_BETWEEN: {
    id: 'DATE_IS_BETWEEN',
    name: 'is between',
    selectionValue: 'is between...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_LT: {
    id: 'DATE_LT',
    name: 'is before',
    selectionValue: 'is before...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_LTE: {
    id: 'DATE_LTE',
    name: 'is on or before',
    selectionValue: 'is on or before...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_GT: {
    id: 'DATE_GT',
    name: 'is after',
    selectionValue: 'is after...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_GTE: {
    id: 'DATE_GTE',
    name: 'is on or after',
    selectionValue: 'is on or after...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_PREVIOUS: {
    id: 'DATE_PREVIOUS',
    name: 'previous',
    selectionValue: 'previous...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_NEXT: {
    id: 'DATE_NEXT',
    name: 'next',
    selectionValue: 'next...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  BOOLEAN_IS_TRUE: {
    id: 'BOOLEAN_IS_TRUE',
    name: 'is true',
    selectionValue: 'is true',
    supported_column_types: new Set([BOOLEAN]),
  },
  BOOLEAN_IS_FALSE: {
    id: 'BOOLEAN_IS_FALSE',
    name: 'is false',
    selectionValue: 'is false',
    supported_column_types: new Set([BOOLEAN]),
  },
  IS_EMPTY: {
    id: 'IS_EMPTY',
    name: 'is empty',
    selectionValue: 'is empty',
    supported_column_types: new Set([
      STRING,
      INTEGER_DATA_TYPE,
      FLOAT,
      TIMESTAMP,
      DATETIME,
      DATE,
      BOOLEAN,
    ]),
  },
  IS_NOT_EMPTY: {
    id: 'IS_NOT_EMPTY',
    name: 'is not empty',
    selectionValue: 'is not empty',
    supported_column_types: new Set([
      STRING,
      INTEGER_DATA_TYPE,
      FLOAT,
      TIMESTAMP,
      DATETIME,
      DATE,
      BOOLEAN,
    ]),
  },
};

export const FILTER_OPS_NO_VALUE = new Set([
  FILTER_OPERATOR_TYPES_BY_ID.BOOLEAN_IS_TRUE.id,
  FILTER_OPERATOR_TYPES_BY_ID.BOOLEAN_IS_FALSE.id,
  FILTER_OPERATOR_TYPES_BY_ID.IS_EMPTY.id,
  FILTER_OPERATOR_TYPES_BY_ID.IS_NOT_EMPTY.id,
]);

export const FILTER_OPS_DATE_PICKER = new Set([
  FILTER_OPERATOR_TYPES_BY_ID.DATE_IS.id,
  FILTER_OPERATOR_TYPES_BY_ID.DATE_IS_NOT.id,
  FILTER_OPERATOR_TYPES_BY_ID.DATE_LT.id,
  FILTER_OPERATOR_TYPES_BY_ID.DATE_LTE.id,
  FILTER_OPERATOR_TYPES_BY_ID.DATE_GT.id,
  FILTER_OPERATOR_TYPES_BY_ID.DATE_GTE.id,
]);

export const FILTER_OPS_DATE_RANGE_PICKER = new Set([
  FILTER_OPERATOR_TYPES_BY_ID.DATE_IS_BETWEEN.id,
]);

export const FILTER_OPS_RELATIVE_PICKER = new Set([
  FILTER_OPERATOR_TYPES_BY_ID.DATE_PREVIOUS.id,
  FILTER_OPERATOR_TYPES_BY_ID.DATE_NEXT.id,
]);

export const FILTER_OPS_MULTISELECT = new Set([
  FILTER_OPERATOR_TYPES_BY_ID.STRING_IS_IN.id,
  FILTER_OPERATOR_TYPES_BY_ID.NUMBER_IS_IN.id
])

export const FILTER_OPERATORS = Object.keys(FILTER_OPERATOR_TYPES_BY_ID).map(
  (key) => FILTER_OPERATOR_TYPES_BY_ID[key],
);

export const EMPTY_FILTER_CLAUSE: types.FilterClause = {
  filterColumn: undefined,
  filterOperation: undefined,
  filterValue: undefined,
};

export const EMPTY_SORT_CLAUSE: types.SortClause = {
  column: null,
  order: null,
};

export const EMPTY_PIVOTED_ON_COL: types.AggregationColumnInfo = {
  name: '',
  type: '',
};

export const EMPTY_AGGREGATION: types.PivotOperationAggregation = {
  type: null,
  aggedOnColumn: null,
};

export const EMPTY_LINE_OR_BAR_CHART_INSTR: types.VisualizeLineOrBarChartInstructions = {
  xAxisColumn: undefined,
  lineColumns: [{ column: undefined, color: undefined }],
};

export const EMPTY_PIE_CHART_INSTR: types.VisualizePieChartInstruction = {
  layoutType: PIE_CHART_TYPES.pie.id,
  legendLocation: LEGEND_LOCATIONS.right.id,
};

export const DATE_RELATIVE_OPTIONS_BY_ID = {
  DAYS: {
    id: 'DAYS',
    name: 'Days',
  },
  WEEKS: {
    id: 'WEEKS',
    name: 'Weeks',
  },
  MONTHS: {
    id: 'MONTHS',
    name: 'Months',
  },
  YEARS: {
    id: 'YEARS',
    name: 'Years',
  },
};

export const DATE_RELATIVE_OPTIONS: { id: string; name: string }[] = [
  DATE_RELATIVE_OPTIONS_BY_ID.DAYS,
  DATE_RELATIVE_OPTIONS_BY_ID.WEEKS,
  DATE_RELATIVE_OPTIONS_BY_ID.MONTHS,
  DATE_RELATIVE_OPTIONS_BY_ID.YEARS,
];

export const DATE_DISPLAY_FORMAT_TO_DAYJS_FORMAT = {
  [types.DateDisplayFormat.NORMAL]: DATE_FORMAT_TO_LOCALIZED_FORMAT['MM/DD/YYYY'],
  [types.DateDisplayFormat.NUMERIC_LONG]: DATE_FORMAT_TO_LOCALIZED_FORMAT['DD-MM-YYYY'],
  [types.DateDisplayFormat.NUMERIC_SHORT]: DATE_FORMAT_TO_LOCALIZED_FORMAT['MM-DD-YY'],
  [types.DateDisplayFormat.VERBAL_LONG]: DATE_FORMAT_TO_LOCALIZED_FORMAT['MMMM D, YYYY'],
  [types.DateDisplayFormat.VERBAL_SHORT]: DATE_FORMAT_TO_LOCALIZED_FORMAT['MMM D, YYYY'],
  [types.DateDisplayFormat.DATE_TIMESTAMP]:
    DATE_FORMAT_TO_LOCALIZED_FORMAT['DD/MM/YYYY (HH:mm:ss)'],
  [types.DateDisplayFormat.CUSTOM]: '',
};

export const SORT_ORDERS = [types.SortOrder.ASC, types.SortOrder.DESC];

export const DEFAULT_GRADIENT: types.GradientShape = {
  hue1: '#57BB8A',
  hue2: '#FFD666',
  hue3: '#E67C73',
};

export const GRADIENT_FORMATS: { [id: string]: types.RecordItem } = {
  [types.GradientType.NONE]: {
    id: types.GradientType.NONE,
    name: types.GradientType.NONE,
  },
  [types.GradientType.LINEAR]: {
    id: types.GradientType.LINEAR,
    name: types.GradientType.LINEAR,
  },
  [types.GradientType.DIVERGING]: {
    id: types.GradientType.DIVERGING,
    name: types.GradientType.DIVERGING,
  },
};

export enum DATASET_EDITOR_BREAKPOINT_SCREEN_SIZES {
  SMALL = 780,
  MEDIUM = 1100,
  LARGE = 1280,
}

export const datasetEditorShowsDatasetsAsDropdown = (pageWidth: number) => {
  return (
    DATASET_EDITOR_BREAKPOINT_SCREEN_SIZES.SMALL <= pageWidth &&
    pageWidth <= DATASET_EDITOR_BREAKPOINT_SCREEN_SIZES.MEDIUM
  );
};

export const datasetEditorNotShown = (pageWidth: number) => {
  return pageWidth < DATASET_EDITOR_BREAKPOINT_SCREEN_SIZES.SMALL;
};

export const datasetEditorShowsDatasetsAsList = (pageWidth: number) => {
  return pageWidth > DATASET_EDITOR_BREAKPOINT_SCREEN_SIZES.MEDIUM;
};
