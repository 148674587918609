/** @format */

import React from 'react';
import { Popover, Menu, MenuItem, Icon } from '@blueprintjs/core';
import { Theme, withTheme, WithTheme } from '@material-ui/core/styles';
import { withStyles, WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ReduxState } from 'reducers/rootReducer';
import { logoutUser } from 'actions/authAction';

import Button from 'shared/Button';
import NavTabs from 'components/core/navTabs';

import ExploCircleLogo from 'images/branding/explo_circle_logo.png';
import { ROUTES } from 'constants/routes';
import { NavBarTab } from 'constants/types';
import { Statsig } from 'statsig-react';
import FeatureGates from 'featureGates/featureGates';

export const NAVBAR_HEIGHT = 56;

export const NAVBAR_TABS: Record<string, NavBarTab> = {
  DATA: {
    id: 'DATA',
    href: ROUTES.DATA_SOURCES_PAGE,
    name: 'Data',
    icon: 'database',
  },
  DASHBOARDS: {
    id: 'DASHBOARDS',
    href: ROUTES.HOME_APP_PAGE,
    name: 'Dashboards',
    icon: 'timeline-bar-chart',
  },
  CUSTOMERS: {
    id: 'CUSTOMERS',
    href: ROUTES.CUSTOMERS_PAGE,
    name: 'Customers',
    icon: 'people',
  },
  ANALYTICS: {
    id: 'ANALYTICS',
    href: ROUTES.ANALYTICS,
    name: 'Analytics',
    icon: 'timeline-line-chart',
  },
};

const styles = (theme: Theme) => ({
  root: {
    height: NAVBAR_HEIGHT,
    backgroundColor: theme.palette.ds.white,
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(6)}px`,
    borderBottom: `2px solid ${theme.palette.ds.grey400}`,
  },
  navBarRoutesContainer: {
    height: '100%',
  },
  navBarRoute: {
    marginRight: theme.spacing(2),
    width: 145,
    fontWeight: 500,
    fontSize: 14,
  },
  logoBtn: {
    marginRight: 60,
    '&:hover': {
      backgroundColor: 'transparent !important',
    },

    '&:active': {
      backgroundColor: 'transparent !important',
    },
  },
  logo: {
    height: 30,
  },
  leftNavOptions: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
  },
  rightNavOptions: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
  },
  rightNavItem: {
    marginLeft: theme.spacing(8),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  questionItem: {
    marginRight: theme.spacing(8),
  },
  spinner: {
    color: theme.palette.ds.grey700,
  },
  profileIcon: {
    borderRadius: '50%',
    backgroundColor: theme.palette.ds.grey700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    fontSize: 14,
    color: theme.palette.ds.white,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  activeTab: {
    color: theme.palette.ds.blue,
    borderBottom: `2px solid ${theme.palette.ds.blue}`,
  },
  helpCenterBtn: {
    marginRight: theme.spacing(3),
  },
});

type PassedProps = {
  removeLeftRoutes?: boolean;
  activeTabId?: string;
};

type Props = PassedProps &
  typeof mapDispatchToProps &
  RouteComponentProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles<typeof styles> &
  WithTheme;

class NavBar extends React.Component<Props> {
  render() {
    const { classes, logoutUser, history, theme } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.leftNavOptions}>
          <div>
            <Button
              minimal
              icon={<img className={classes.logo} src={ExploCircleLogo} alt="logo for explo" />}
              onClick={() => history.push('/home')}
              className={classes.logoBtn}></Button>
          </div>
          {this.renderLeftRoutes()}
        </div>
        <div className={classes.rightNavOptions}>
          <Button
            minimal
            className={classes.helpCenterBtn}
            icon={<Icon icon="help" color={theme.palette.ds.grey700} />}
            href="https://docs.explo.co/"
          />
          <Popover>
            <div className={classes.profileIcon}>{this.getUserInitials()}</div>
            <Menu key="user-menu">
              <MenuItem icon="cog" text="Settings" onClick={() => history.push('/settings')} />
              <MenuItem
                icon="highlight"
                text="Custom Styles"
                onClick={() => history.push('/styles')}
              />
              <MenuItem
                icon="log-out"
                text="Logout"
                onClick={() => {
                  logoutUser();
                }}
              />
            </Menu>
          </Popover>
        </div>
      </div>
    );
  }

  getUserInitials = () => {
    const { currentUser } = this.props;

    const firstInitial = currentUser.first_name
      ? currentUser.first_name.charAt(0).toUpperCase()
      : 'A';
    const secondInitial = currentUser.last_name
      ? currentUser.last_name.charAt(0).toUpperCase()
      : '';

    return `${firstInitial}${secondInitial}`;
  };

  renderLeftRoutes = () => {
    const { classes, history, removeLeftRoutes, activeTabId } = this.props;
    if (removeLeftRoutes) return;

    const orderedTabs = [NAVBAR_TABS.DATA, NAVBAR_TABS.DASHBOARDS, NAVBAR_TABS.CUSTOMERS];
    const showAnalyticsPage = Statsig.checkGate(FeatureGates.SHOW_ANALYTICS_TAB);

    if (showAnalyticsPage) {
      orderedTabs.push(NAVBAR_TABS.ANALYTICS);
    }

    return (
      <NavTabs
        className={classes.navBarRoutesContainer}
        tabClassName={classes.navBarRoute}
        tabs={orderedTabs}
        selectedTabId={activeTabId}
        onTabSelect={(tabId) => history.push(NAVBAR_TABS[tabId].href)}
        tabIconSize={20}
      />
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  logoutUser,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTheme(NavBar))),
);
