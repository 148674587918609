/** @format */

/** @format */

import React, { useCallback, useEffect, useState } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'url-parse';

import { ActionFn, ActionFnArgs } from 'actions/actionUtils';
import {
  DownloadDataPanelTemplateCsvData,
  DownloadDataPanelTemplatePdfData,
  EmbedFetchDashboardData,
  ErrorResponse,
  ExportUrlResponse,
  FetchDashboardDatasetPreviewData,
  FetchDataPanelTemplateData,
  FetchDataPanelTemplateRowCountData,
} from 'actions/responseTypes';
import {
  embedDownloadDataPanelTemplateCsv,
  embedDownloadDataPanelTemplatePdf,
  embedFetchDashboard,
  embedFetchDashboardDatasetPreview,
  embedFetchDataPanelRowCount,
  embedFetchDataPanelTemplate,
  embedFetchShareId,
  embedSetDptLoading,
  embedFetchSecondaryData,
  embedFetchImageExportUrl,
  embedFetchPdfExportUrl,
  embedUpdateAdHocOperationInstructions,
  embedFetchDashboardVersions,
} from 'actions/shareActions';
import { ACTION, Team } from 'actions/types';
import { pageView } from 'analytics/exploAnalytics';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import { createLoadingSelector } from 'embeddedContent/reducers/api/selectors';
import { ReduxState } from 'embeddedContent/reducers/rootReducer';
import { DashboardVariableMap, VIEW_MODE } from 'types/dashboardTypes';
import { GlobalStylesProvider } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { EmbeddedDashboardType } from './types';
import LoadingState from './LoadingState';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { createAnalyticsEventTracker, sendAnalyticsEvent } from 'utils/analyticsUtils';
import { loadLocale } from 'utils/localizationUtils';
import _ from 'underscore';

const useStyles = makeStyles((theme: Theme) => ({
  spinner: ({ type }: Props) => ({
    ...(['shared', 'iframe'].includes(type)
      ? {
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }
      : {
          width: '100%',
          height: '100%',
          margin: theme.spacing(2),
        }),
  }),
  errorMessage: {
    margin: theme.spacing(10),
    fontSize: 24,
    padding: theme.spacing(10),
    backgroundColor: '#FAE1E1',
    borderRadius: 8,
  },
}));

type AnalyticsMetadata = {
  team_id: number;
  team_name: string;
  end_user_group_id: number;
  end_user_group_name: string;
  dashboard_template_id: number;
  dashboard_template_name: string;
};

type Props = {
  dashboardEmbedId: string;
  type: EmbeddedDashboardType;
  userGroupToken: string;
  customStyles?: GlobalStyleConfig;
  embeddedVariables?: DashboardVariableMap;
  environment?: string;
  versionNumber?: number;
  isProduction?: string;
  isStrict?: boolean;
  refreshMinutes?: number;
  updateUrlParams?: boolean;
  localeCode?: string;
};

export default function EmbeddedDashboard(props: Props) {
  const {
    type,
    customStyles,
    dashboardEmbedId,
    embeddedVariables,
    environment: environmentProp,
    versionNumber,
    isProduction: isProductionProp,
    isStrict,
    refreshMinutes,
    updateUrlParams,
    userGroupToken,
    localeCode,
  } = props;
  const classes = useStyles(props);

  const [visitorId, setVisitorId] = useState<string>('');
  const [userGroupName, setUserGroupName] = useState<string>('');
  const [analyticsMetadata, setAnalyticsMetadata] = useState<AnalyticsMetadata | undefined>();
  const [team, setTeam] = useState<Team>();
  const [urlVariables] = useState<DashboardVariableMap>(getQueryVariables());

  const dispatch = useDispatch();
  const {
    dashboardConfig,
    dashboardLoading,
    dashboardTemplate,
    dashboardVersionNumber,
    dashboardVersionId,
    embedError,
    shareData,
    shareLinkLoading,
    imageExportUrlLoading,
    pdfExportUrlLoading,
    globalStyleConfig,
  } = useSelector((state: ReduxState) => ({
    dashboardTemplate: state.embedDashboard.dashboardTemplate,
    dashboardLoading: createLoadingSelector([ACTION.EMBED_FETCH_DASHBOARD], true)(state),
    shareData: state.embedDashboard.shareData,
    shareLinkLoading: createLoadingSelector([ACTION.FETCH_SHARE_ID], false)(state),
    imageExportUrlLoading: createLoadingSelector([ACTION.FETCH_IMAGE_EXPORT_URL], false)(state),
    pdfExportUrlLoading: createLoadingSelector([ACTION.FETCH_PDF_EXPORT_URL], false)(state),
    dashboardConfig: state.embedDashboard.dashboardVersion?.configuration,
    dashboardVersionNumber: state.embedDashboard.dashboardVersion?.version_number,
    dashboardVersionId: state.embedDashboard.dashboardVersion?.id,
    globalStyleConfig: state.embedDashboardStyle.globalStyleConfig,
    embedError: state.dashboard.errorMsg,
  }));

  const onLoad = () => {
    sendInitialPageView();
    fetchDashboardData();
  };
  useEffect(onLoad, [dashboardEmbedId, userGroupToken, embedFetchDashboardVersions]);

  const sendInitialPageView = () => {
    switch (type) {
      case 'shared':
        if (isStrict) pageView('Shared Dashboard Page - Strict Format');
        else pageView('Shared Dashboard Page');
        break;
      case 'iframe':
        if (isStrict) pageView('Iframe Dashboard - Strict Format');
        else pageView('Iframe Dashboard');
        break;
      case 'embedded':
        break;
    }
  };

  const fetchDashboardData = () => {
    const queryVariables = getQueryVariables();
    const environment = environmentProp || queryVariables['environment'];
    const isProduction = isProductionProp ?? queryVariables['is_production'];

    dispatch(
      embedFetchDashboard(
        {
          userGroupToken,
          postData: {
            dashboard_embed_id: dashboardEmbedId,
            version_number: versionNumber,
            environment,
          },
        },
        (data: EmbedFetchDashboardData) => {
          const metadata = {
            team_id: data.team.id,
            team_name: data.team.team_name,
            end_user_group_id: data.end_user_group.id,
            end_user_group_name: data.end_user_group.name,
            dashboard_template_id: data.dashboard_template.id,
            dashboard_template_name: data.dashboard_template.name,
          };

          const sendPageViewAnalytics = async () => {
            // Get the (browser) visitor identifier:
            const fp = await FingerprintJS.load();
            const result = await fp.get();

            const pageViewType =
              type === 'shared'
                ? REPORTED_ANALYTIC_ACTION_TYPES.SHARED_DASHBOARD_PAGE_VIEWED
                : REPORTED_ANALYTIC_ACTION_TYPES.DASHBOARD_PAGE_VIEWED;

            // Send page view analytics
            sendAnalyticsEvent(
              pageViewType,
              result.visitorId,
              {
                ...metadata,
                is_production: isProduction ? isProduction === 'true' : undefined,
                environment,
                is_strict: isStrict && { isStrict: true },
                embed_source: type === 'shared' ? 'share' : 'iframe',
              },
              data.team.analytics_reporting_actions,
              data.team.analytics_reporting_url,
              data.team.analytics_reporting_token,
            );

            setVisitorId(result.visitorId);
          };

          sendPageViewAnalytics();
          setUserGroupName(data.end_user_group.name);
          setAnalyticsMetadata(metadata);
          setTeam(data.team as Team);
          loadLocale({
            passedLocaleCode: getLocaleCodeOrDefault(localeCode),
            teamLocaleCode: data.team.default_locale_code,
            useBrowserLocale: data.team.use_browser_locale,
          });
        },
      ),
    );
  };

  const analyticsEventTracker = useCallback(
    (eventType: REPORTED_ANALYTIC_ACTION_TYPES) =>
      createAnalyticsEventTracker(
        visitorId,
        type,
        team,
        analyticsMetadata,
      )(eventType, { embed_source: 'share' }),
    [visitorId, type, analyticsMetadata, team],
  );

  const downloadCsvWrapper: ActionFn<DownloadDataPanelTemplateCsvData> = useCallback(
    (
      data?: ActionFnArgs,
      onSuccess?: (data: DownloadDataPanelTemplateCsvData) => void,
      onError?: (errorMsg: ErrorResponse) => void,
    ) => {
      data = data || {};

      dispatch(
        embedDownloadDataPanelTemplateCsv(
          {
            ...data,
            userGroupToken,
            postData: {
              ...data.postData,
            },
          },
          onSuccess,
          onError,
        ),
      );

      analyticsEventTracker(REPORTED_ANALYTIC_ACTION_TYPES.CSV_DOWNLOADED);
    },
    [userGroupToken, analyticsEventTracker, dispatch],
  );

  const downloadDptPdfWrapper: ActionFn<DownloadDataPanelTemplatePdfData> = useCallback(
    (
      data?: ActionFnArgs,
      onSuccess?: (data: DownloadDataPanelTemplatePdfData) => void,
      onError?: (errorMsg: ErrorResponse) => void,
    ) => {
      data = data || {};

      dispatch(
        embedDownloadDataPanelTemplatePdf(
          {
            ...data,
            userGroupToken,
            postData: {
              ...data.postData,
            },
          },
          onSuccess,
          onError,
        ),
      );

      analyticsEventTracker(REPORTED_ANALYTIC_ACTION_TYPES.DPT_PDF_DOWNLOADED);
    },
    [userGroupToken, analyticsEventTracker, dispatch],
  );

  const fetchDataPanelTemplateRowCountWrapper: ActionFn<FetchDataPanelTemplateRowCountData> = useCallback(
    (data?: ActionFnArgs) => {
      data = data || {};

      return dispatch(
        embedFetchDataPanelRowCount({
          ...data,
          userGroupToken,
          postData: {
            ...data.postData,
          },
        }),
      );
    },
    [userGroupToken, dispatch],
  );

  const fetchDataPanelTemplateWrapper: ActionFn<FetchDataPanelTemplateData> = useCallback(
    (data?: ActionFnArgs, onSuccess?: (data: FetchDataPanelTemplateData) => void) => {
      data = data || {};

      return dispatch(
        embedFetchDataPanelTemplate(
          {
            ...data,
            userGroupToken,
            postData: {
              ...data.postData,
            },
          },
          onSuccess,
        ),
      );
    },
    [userGroupToken, dispatch],
  );

  const fetchDatasetPreviewWrapper: ActionFn<FetchDashboardDatasetPreviewData> = useCallback(
    (data?: ActionFnArgs, onSuccess?: (data: FetchDashboardDatasetPreviewData) => void) => {
      data = data || {};

      return dispatch(
        embedFetchDashboardDatasetPreview(
          {
            ...data,
            userGroupToken,
            postData: {
              ...data.postData,
            },
          },
          onSuccess,
        ),
      );
    },
    [userGroupToken, dispatch],
  );

  const fetchShareDataWrapper = useCallback(
    (password?: string, isStrictViewingMode?: boolean) => {
      const commonPostData = {
        dashboard_embed_id: dashboardEmbedId,
      };
      const dashboardId = dashboardTemplate?.id;

      dispatch(
        embedFetchDashboardVersions(
          { id: dashboardTemplate?.id, userGroupToken, postData: commonPostData },
          (data) => {
            const environmentTagId = dashboardId
              ? _.find(
                  data.tags,
                  (tag) =>
                    tag.dashboard_versions_by_dashboard?.[dashboardId] === dashboardVersionId,
                )?.id
              : undefined;

            dispatch(
              embedFetchShareId({
                userGroupToken,
                postData: {
                  ...commonPostData,
                  ...(!environmentTagId && { version_number: dashboardVersionNumber }),
                  ...(environmentTagId && { environment_tag_id: environmentTagId }),
                  ...(password && { password: password }),
                  ...{
                    is_strict_viewing_mode: isStrictViewingMode === true,
                  },
                },
              }),
            );
          },
        ),
      );

      analyticsEventTracker(REPORTED_ANALYTIC_ACTION_TYPES.SHARE_BUTTON_CLICKED);
    },
    [
      dispatch,
      dashboardEmbedId,
      userGroupToken,
      dashboardVersionNumber,
      dashboardVersionId,
      analyticsEventTracker,
      dashboardTemplate,
    ],
  );

  const fetchImageExportUrlWrapper = useCallback(
    (
      data?: ActionFnArgs,
      onSuccess?: (data: ExportUrlResponse) => void,
      onError?: (errorMsg: ErrorResponse) => void,
    ) => {
      dispatch(
        embedFetchImageExportUrl(
          {
            ...data,
            userGroupToken,
            postData: {
              ...data?.postData,
              dashboard_embed_id: dashboardEmbedId,
            },
          },
          onSuccess,
          onError,
        ),
      );
    },
    [dispatch, dashboardEmbedId, userGroupToken],
  );

  const fetchPdfExportUrlWrapper = useCallback(
    (
      data?: ActionFnArgs,
      onSuccess?: (data: ExportUrlResponse) => void,
      onError?: (errorMsg: ErrorResponse) => void,
    ) => {
      dispatch(
        embedFetchPdfExportUrl(
          {
            ...data,
            userGroupToken,
            postData: {
              ...data?.postData,
              dashboard_embed_id: dashboardEmbedId,
            },
          },
          onSuccess,
          onError,
        ),
      );
    },
    [dispatch, dashboardEmbedId, userGroupToken],
  );

  const fetchSecondaryDataWrapper: ActionFn<FetchDataPanelTemplateData> = useCallback(
    (data?: ActionFnArgs) => {
      data = data || {};

      return dispatch(
        embedFetchSecondaryData({
          ...data,
          userGroupToken,
          postData: {
            ...data.postData,
          },
        }),
      );
    },
    [dispatch, userGroupToken],
  );

  if (dashboardLoading) {
    return <LoadingState type={type} />;
  } else if (embedError) {
    return <div className={classes.errorMessage}>{embedError}</div>;
  } else if (!dashboardTemplate || !dashboardConfig) {
    return (
      <div className={classes.errorMessage}>
        There was an error loading the dashboard. Please contact your support team for help.
      </div>
    );
  }
  return (
    <GlobalStylesProvider globalStyleConfig={{ ...globalStyleConfig, ...customStyles }}>
      {(globalStylesClassName) => (
        <div id="shared-explo-dashboard" className={globalStylesClassName}>
          {dashboardTemplate && dashboardConfig && (
            <DashboardLayout
              isViewOnly
              analyticsEventTracker={analyticsEventTracker}
              dashboardDatasets={dashboardConfig.datasets}
              dashboardElements={Object.values(dashboardConfig.elements)}
              dashboardLayout={
                type === 'pdf'
                  ? dashboardConfig.pdf_layout || dashboardConfig.dashboard_layout
                  : dashboardConfig.dashboard_layout
              }
              dashboardTemplate={dashboardTemplate}
              dataPanelTemplates={Object.values(dashboardConfig.data_panels)}
              dashboardVersionNumber={dashboardVersionNumber}
              disableInputs={isStrict}
              downloadDataPanelCsv={downloadCsvWrapper}
              downloadDataPanelPdf={downloadDptPdfWrapper}
              editBaseOn={false}
              fetchDataPanelRowCount={fetchDataPanelTemplateRowCountWrapper}
              fetchDataPanelTemplate={fetchDataPanelTemplateWrapper}
              fetchDatasetPreview={fetchDatasetPreviewWrapper}
              fetchSecondaryData={fetchSecondaryDataWrapper}
              fetchShareData={fetchShareDataWrapper}
              fetchImageExportUrl={fetchImageExportUrlWrapper}
              fetchPdfExportUrl={fetchPdfExportUrlWrapper}
              imageExportUrlLoading={imageExportUrlLoading}
              pdfExportUrlLoading={pdfExportUrlLoading}
              refreshMinutes={getRefreshMinutesOrDefault(refreshMinutes)}
              setDptLoading={(...args) => {
                dispatch(embedSetDptLoading(...args));
              }}
              shareData={shareData}
              shareLinkLoading={shareLinkLoading}
              updateAdHocOperationInstructions={(args) => {
                dispatch(embedUpdateAdHocOperationInstructions(args));
              }}
              updateUrlParams={updateUrlParams || ['shared', 'iframe'].includes(type)}
              userGroupName={userGroupName}
              variablesDefaultValues={{ ...urlVariables, ...embeddedVariables }}
              viewMode={getViewMode(type)}
            />
          )}
        </div>
      )}
    </GlobalStylesProvider>
  );
}

const getViewMode = (type: string) => {
  if (type === 'pdf') return VIEW_MODE.PDF;
  else if (type === 'shared') return VIEW_MODE.SHARE;
  else return VIEW_MODE.DEFAULT;
};

export function getQueryVariables() {
  const rawVars = parse(window.location.href, true).query;
  const queryVariables: DashboardVariableMap = {};

  if (rawVars) {
    Object.keys(rawVars).forEach((key) => {
      // ignore explo provided variables
      if (['refresh_minutes', 'userTransformedSchema'].includes(key)) return;

      const val = rawVars[key];
      if (val) queryVariables[key] = JSON.parse(val);
    });
  }

  return queryVariables;
}

function getRefreshMinutesOrDefault(refreshMinutes?: number) {
  if (refreshMinutes) return refreshMinutes;

  const rawVars = parse(window.location.href, true).query;

  if (rawVars && rawVars['refresh_minutes']) return JSON.parse(rawVars['refresh_minutes']);

  return undefined;
}

function getLocaleCodeOrDefault(localeCode?: string) {
  if (localeCode) return localeCode;

  const rawVars = parse(window.location.href, true).query;

  if (rawVars && rawVars['locale_code']) return JSON.parse(rawVars['locale_code']);

  return undefined;
}
