/** @format */

import React from 'react';
import { connect } from 'react-redux';
import validator from 'validator';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Classes, Intent, FormGroup } from '@blueprintjs/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import Button from 'shared/Button';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import Modal from 'components/core/Modal';

const useStyles = (theme: Theme) =>
  createStyles({
    formGroupValidationErrorState: {
      marginBottom: theme.spacing(0),
    },
    formGroupValidationNoError: {
      marginBottom: theme.spacing(5),
    },
  });

type PassedProps = {
  modalOpen: boolean;
  closeModal: () => void;
  onSubmit: (newDashboardTitle: string) => void;
};

type Props = PassedProps & WithStyles<typeof useStyles>;

const AddWhitelistDomainModal = (props: Props) => {
  const { classes } = props;
  const [textFieldVal, setTextFieldVal] = React.useState('');

  const isErrorState =
    textFieldVal !== '' &&
    !validator.matches(textFieldVal, /^http(s)?:\/{2}([A-z0-9\-*]+\.)+[A-z*]+$/);

  const onClose = () => {
    setTextFieldVal('');
    props.closeModal();
  };

  return (
    <Modal modalOpen={props.modalOpen} onClose={onClose} title="Add new whitelist rule">
      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          className={
            isErrorState
              ? classes.formGroupValidationErrorState
              : classes.formGroupValidationNoError
          }
          helperText={isErrorState ? 'Invalid Rule' : undefined}
          intent={isErrorState ? Intent.DANGER : Intent.NONE}
          labelFor="text-input">
          <InputGroup
            intent={isErrorState ? Intent.DANGER : Intent.NONE}
            large={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTextFieldVal(e.target.value);
            }}
            placeholder="https://*.domain.com"
            onKeyPress={(e) => {
              if (e.key === 'Enter' && textFieldVal.trim() && !isErrorState) {
                props.onSubmit(textFieldVal);
                setTextFieldVal('');
              }
            }}
            defaultValue={textFieldVal}
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            type="primary"
            onClick={() => {
              if (textFieldVal.trim() && !isErrorState) {
                props.onSubmit(textFieldVal);
                setTextFieldVal('');
              }
            }}
            disabled={!textFieldVal.trim() || isErrorState}
            text="Save rule"
          />
        </div>
      </div>
    </Modal>
  );
};

export default connect()(withStyles(useStyles)(AddWhitelistDomainModal));
