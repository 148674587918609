/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';
import { IconName } from '@blueprintjs/icons';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';
import { OPERATION_TYPES, V2KPITrendInstructions } from 'constants/types';
import { ORDERED_ALIGNMENTS } from 'constants/dataConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2KPITrendInstructions;
};

export default function KPITrendTitleConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedAlignment =
    instructions.titleFormat?.horizAlignment || TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER;

  return (
    <div className={classes.root}>
      {instructions?.hideTrendLines && (
        <>
          <InputWithBlurSave
            containerClassName={classes.configInput}
            initialValue={instructions.textOnlyFormat?.subtitle}
            onNewValueSubmitted={(newValue: string) => {
              const newInstructions = cloneDeep(instructions);

              newInstructions.textOnlyFormat = {
                ...newInstructions.textOnlyFormat,
                subtitle: newValue,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            label="Subtitle"
          />
          <ToggleButtonGroup fillWidth className={classes.configInput}>
            {ORDERED_ALIGNMENTS.map((alignment) => (
              <ToggleButton
                key={alignment.id}
                active={alignment.id === selectedAlignment}
                onClick={() => {
                  const newInstructions = cloneDeep(instructions);

                  newInstructions.titleFormat = {
                    ...newInstructions.titleFormat,
                    horizAlignment: alignment.id,
                  };
                  dispatch(
                    updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                  );
                }}
                icon={alignment.icon as IconName}
              />
            ))}
          </ToggleButtonGroup>
        </>
      )}
    </div>
  );
}
