/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Spinner } from '@blueprintjs/core';

import InformationIcon from './InformationIcon';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  titleText: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipses',
  },
  titleLoadingSpinner: {
    marginLeft: theme.spacing(3),
  },
}));

type Props = {
  panelName: string;
  loading?: boolean;
  infoTooltipText?: string;
};

export default function PanelName({ panelName, loading, infoTooltipText }: Props) {
  const classes = useStyles();
  return (
    <div className={cx(classes.titleText, GLOBAL_STYLE_CLASSNAMES.text.h2.base)}>
      {panelName || ''}
      {infoTooltipText !== undefined && <InformationIcon infoTooltipText={infoTooltipText} />}
      {loading && <Spinner className={classes.titleLoadingSpinner} size={17} />}
    </div>
  );
}
