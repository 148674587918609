/** @format */

import React from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core';

import SettingHeader from '../SettingHeader';
import DroppableColumnSection from './DroppableColumnSection';
import DropdownSelect from 'shared/DropdownSelect';

import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  resolveCategoryColDropped,
  resolveAggColDropped,
  filterForValidDateGroupElements,
} from './utils';
import { AppToaster } from 'toaster';
import { DashboardElement } from 'types/dashboardTypes';
import { DATE_PART_INPUT_AGG } from 'constants/dataConstants';
import { DEFAULT_Y_AXIS_FORMAT_INDEX } from 'pages/dashboardPage/charts/utils/multiYAxisUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  categoryVariableInput: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },
}));

export type Props = {
  instructions: V2TwoDimensionChartInstructions;
  chartType: string;
  categoryName?: string;
  aggName?: string;
  colorName?: string;
  maxAggs?: number;
  supportColor?: boolean;
  loading?: boolean;
  dashboardElements?: DashboardElement[];
};

export default function TwoDimensionVizConfig({
  instructions,
  chartType,
  categoryName = 'X-Axis',
  aggName = 'Y-Axis',
  colorName = 'Color',
  maxAggs,
  supportColor,
  loading,
  dashboardElements,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const aggColumns = instructions.aggColumns || [];

  return (
    <div className={classes.root}>
      <SettingHeader name={categoryName} />
      <DroppableColumnSection
        columns={instructions.categoryColumn ? [instructions.categoryColumn] : []}
        onColDropped={(col) => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.categoryColumn = resolveCategoryColDropped(
            col,
            newInstructions.categoryColumn,
          );
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
        onColOptionChanged={(option) => {
          const newInstructions = cloneDeep(instructions);
          if (newInstructions.categoryColumn) newInstructions.categoryColumn.bucket = option;
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
        onRemoveCol={() => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.categoryColumn = undefined;
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
        disableEdits={loading}
        dashboardElements={dashboardElements}
      />
      {instructions.categoryColumn?.bucket?.id === DATE_PART_INPUT_AGG && (
        <div className={classes.categoryVariableInput}>
          <DropdownSelect
            filterable={false}
            selectedItem={
              instructions.categoryColumn.bucketElemId
                ? {
                    id: instructions.categoryColumn.bucketElemId,
                    name: instructions.categoryColumn.bucketElemId,
                  }
                : undefined
            }
            onChange={(newValue) => {
              const newInstructions = cloneDeep(instructions);
              if (!newInstructions.categoryColumn) return;

              newInstructions.categoryColumn.bucketElemId = newValue.id;

              dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
            }}
            noSelectionText="Select an Input"
            options={filterForValidDateGroupElements(dashboardElements).map((elem) => ({
              id: elem.name,
              name: elem.name,
            }))}
            minimal
            fillWidth
            showIcon
          />
        </div>
      )}

      <SettingHeader name={aggName} />
      <DroppableColumnSection
        columns={maxAggs ? aggColumns.slice(0, maxAggs) : aggColumns}
        onColDropped={(col) => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.aggColumns = resolveAggColDropped(
            col,
            newInstructions.aggColumns,
            maxAggs,
            instructions.yAxisFormats?.[DEFAULT_Y_AXIS_FORMAT_INDEX].id,
            (toastInfo) => AppToaster.show(toastInfo),
          );
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
        onColOptionChanged={(option, name, aggType) => {
          const newInstructions = cloneDeep(instructions);
          const changedCol = _.find(
            newInstructions.aggColumns || [],
            (col) => col.column.name === name && (!aggType || col.agg.name === aggType.name),
          );
          if (!changedCol) return;

          const isDuplicateCol =
            newInstructions.aggColumns &&
            _.some(
              newInstructions.aggColumns.map(
                (aggChartInfo) =>
                  aggChartInfo.agg.name === option?.name && aggChartInfo.column.name === name,
              ),
            );

          if (isDuplicateCol) {
            AppToaster.show({
              message: `The selected aggregation is already present for this column. Duplicates are not allowed.`,
              icon: 'warning-sign',
              timeout: 5000,
            });

            return;
          }

          changedCol.agg = { ...option, selectionValue: 'Aggregation' };

          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
        onRemoveCol={(name, aggType) => {
          const newInstructions = cloneDeep(instructions);
          const removedColIndex = _.findIndex(
            newInstructions.aggColumns || [],
            (col) => col.column.name === name && (!aggType || col.agg.name === aggType.name),
          );
          newInstructions.aggColumns?.splice(removedColIndex, 1);
          dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
        }}
        disableEdits={loading}
      />
      {supportColor && (
        <>
          <SettingHeader name={colorName} />
          <DroppableColumnSection
            columns={instructions.colorColumn ? [instructions.colorColumn] : []}
            onColDropped={(col) => {
              const newInstructions = cloneDeep(instructions);
              newInstructions.colorColumn = resolveCategoryColDropped(
                col,
                newInstructions.colorColumn,
              );
              newInstructions.aggColumns = newInstructions.aggColumns?.slice(0, 1);
              dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
            }}
            onColOptionChanged={(option) => {
              const newInstructions = cloneDeep(instructions);
              if (newInstructions.colorColumn) newInstructions.colorColumn.bucket = option;
              dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
            }}
            onRemoveCol={() => {
              const newInstructions = cloneDeep(instructions);
              newInstructions.colorColumn = undefined;
              dispatch(updateVisualizeOperation(newInstructions, chartType as OPERATION_TYPES));
            }}
            disableEdits={loading}
          />
        </>
      )}
    </div>
  );
}
