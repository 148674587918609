/** @format */

import React from 'react';

import { Classes, Dialog, Intent, FormGroup } from '@blueprintjs/core';
import { makeStyles } from '@material-ui/core/styles';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import Button from 'shared/Button';

const useStyles = makeStyles(() => ({
  formGroupValidationErrorState: {
    marginBottom: 0,
  },
  formGroupValidationNoError: {
    marginBottom: 20,
  },
}));

type Props = {
  resourceName?: string;
  modalOpen: boolean;
  closeModal: () => void;
  modalTitle: string;
  buttonName: string;
  onSubmit: (newDashboardTitle: string) => void;
  textFieldPlaceholder?: string;
  errorState?: (textFieldVal?: string) => { isErrorState: boolean; errorMsg?: string };
  allowEmptyText?: boolean;
};

const TextFieldModal = (props: Props) => {
  const { errorState, allowEmptyText } = props;
  const [textFieldVal, setTextFieldVal] = React.useState(props.resourceName);
  const classes = useStyles();

  const { isErrorState, errorMsg } = errorState
    ? errorState(textFieldVal)
    : { isErrorState: false, errorMsg: undefined };

  return (
    <Dialog isOpen={props.modalOpen} onClose={props.closeModal} title={props.modalTitle}>
      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          className={
            isErrorState
              ? classes.formGroupValidationErrorState
              : classes.formGroupValidationNoError
          }
          helperText={errorMsg}
          intent={isErrorState ? Intent.DANGER : Intent.NONE}
          labelFor="text-input">
          <InputGroup
            intent={isErrorState ? Intent.DANGER : Intent.NONE}
            large={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTextFieldVal(e.target.value);
            }}
            placeholder={props.textFieldPlaceholder}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && (allowEmptyText || textFieldVal !== '')) {
                props.onSubmit(textFieldVal || '');
                setTextFieldVal('');
                props.closeModal();
              }
            }}
            defaultValue={props.resourceName}
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            type="primary"
            onClick={() => {
              props.onSubmit(textFieldVal || '');
              setTextFieldVal('');
              props.closeModal();
            }}
            disabled={
              isErrorState ||
              (!allowEmptyText &&
                (!textFieldVal || textFieldVal === '' || textFieldVal.trim() === ''))
            }
            text={props.buttonName}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default TextFieldModal;
