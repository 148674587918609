/** @format */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { FormGroup, Intent } from '@blueprintjs/core';
import { ReduxState } from 'reducers/rootReducer';
import { AppToaster } from 'toaster';

import SetupFlowPage from 'components/pages/setupFlowPage';
import InputField from 'explo-ds/forms/marketing/inputField';
import Button from 'shared/Button';
import { createLoadingSelector } from 'reducers/api/selectors';

import { registerUser } from 'actions/authAction';
import { ACTION } from 'actions/types';
import { pageView } from 'analytics/exploAnalytics';

import { passwordResetRequest } from 'actions/authAction';

const useStyles = makeStyles((theme: Theme) => ({
  signupInput: {
    width: '100%',
    height: 40,
    marginTop: 2,
  },
  signInButton: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
}));

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & RouteComponentProps;

const ForgotPasswordPage = (props: Props) => {
  return <SetupFlowPage title="Forgot Password" pageBody={<ForgotPasswordBody {...props} />} />;
};

const ForgotPasswordBody = (props: Props) => {
  const { passwordResetRequest, passwordResetRequestLoading } = props;
  const [email, setEmail] = useState('');
  const classes = useStyles();

  useEffect(() => {
    pageView('Forgot password');
  });

  const onPasswordResetRequestSubmitted = () => {
    if (email === '') {
      AppToaster.show({
        message: `Please enter email.`,
        icon: 'error',
        timeout: 3000,
      });
    } else {
      passwordResetRequest(
        {
          postData: {
            email: email,
          },
        },
        () => {
          AppToaster.show({
            message: `Reset email sent successfully.`,
            icon: 'endorsed',
            timeout: 3000,
            intent: Intent.SUCCESS,
          });
          setEmail('');
        },
        () => {
          AppToaster.show({
            message: `Something went wrong. Please verify that the email is correct.`,
            icon: 'error',
            timeout: 3000,
            intent: Intent.DANGER,
          });
        },
      );
    }
  };

  return (
    <div>
      <FormGroup label="Email">
        <InputField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={classes.signupInput}
          placeholder=""
        />
      </FormGroup>

      <Button
        className={classes.signInButton}
        type="primary"
        onClick={() => onPasswordResetRequestSubmitted()}
        loading={passwordResetRequestLoading}
        text="Send Reset Password Email"
      />
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  passwordResetRequestLoading: createLoadingSelector([ACTION.PASSWORD_RESET_REQUEST], false)(state),
});

const mapDispatchToProps = {
  registerUser,
  passwordResetRequest,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPasswordPage));
