/** @format */

import { defineAPIAction } from 'actions/actionUtils';
import { SaveLocaleCodeData, SaveBooleanSettingData } from './responseTypes';
import { ACTION } from './types';

export const { actionFn: saveDefaultLocaleCode } = defineAPIAction<SaveLocaleCodeData>(
  ACTION.SAVE_DEFAULT_LOCALE_CODE,
  'teams',
  'save_default_locale_code',
  'POST',
);

export const { actionFn: saveUseBrowserLocale } = defineAPIAction<SaveBooleanSettingData>(
  ACTION.SAVE_USE_BROWSER_LOCALE,
  'teams',
  'save_use_browser_locale',
  'POST',
);
