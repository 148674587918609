/** @format */
import React, { useState } from 'react';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import { Intent } from '@blueprintjs/core';

import SettingsTeamMemberList from 'pages/settingsPage/settingsTeamMemberList';
import InviteTeamMembersModal from 'pages/settingsPage/inviteMembersModal';

import { inviteTeammate } from 'actions/authAction';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { AppToaster } from 'toaster';
import Button from 'shared/Button';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `0px ${theme.spacing(8)}px`,
  },
  subsection: {
    fontSize: 20,
    fontWeight: 600,
  },
  membersHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

function SettingsMembersSection(props: Props) {
  const [isInviteTeammatesModalOpen, setInviteTeammatesModal] = useState<boolean>(false);
  const { currentUser, inviteTeammate } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.membersHeader}>
        <div className={classes.subsection}>Members</div>
        <Button
          onClick={() => setInviteTeammatesModal(true)}
          text="Invite Teammates"
          type="primary"
        />
      </div>
      <SettingsTeamMemberList />
      <InviteTeamMembersModal
        closeModal={() => {
          setInviteTeammatesModal(false);
        }}
        onSubmit={(email: string) => {
          inviteTeammate(
            {
              postData: {
                email: email,
                team_id: currentUser.team?.id,
                inviter: currentUser.first_name,
                resend: false,
              },
            },
            () => {
              AppToaster.show({
                message: `Invite sent successfully to ${email}`,
                icon: 'endorsed',
                timeout: 3000,
                intent: Intent.SUCCESS,
              });
              setInviteTeammatesModal(false);
            },
            (response: any) => {
              AppToaster.show({
                message: response.error_msg
                  ? response.error_msg
                  : 'Something went wrong. Please try again or contact support@explo.co if the error continues.',
                icon: 'error',
                timeout: 3000,
                intent: Intent.DANGER,
              });
            },
          );
        }}
        modalOpen={isInviteTeammatesModalOpen}
      />
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = { inviteTeammate };

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMembersSection);
