/** @format */

import { dayjs, DATE_FORMAT_TO_LOCALIZED_FORMAT } from 'utils/localizationUtils';

import * as types from 'constants/types';
import { OPERATION_TYPES, REGION_TYPES, RegionMetaData, RecordItem } from 'constants/types';
import {
  DECIMAL_ICON,
  TEXT_ICON,
  NUMBER_ICON,
  V2_PIE_CHART_ICON,
  V2_DONUT_CHART_ICON,
  V2_AREA_CHART_ICON,
  V2_LINE_CHART_ICON,
  V2_AREA_100_CHART_ICON,
  V2_VERT_BAR_CHART_ICON,
  V2_VERT_100_BAR_CHART_ICON,
  V2_COMBO_CHART_ICON,
  V2_VERT_GROUPED_BAR_CHART_ICON,
  V2_HORIZ_BAR_CHART_ICON,
  V2_HORIZ_100_BAR_CHART_ICON,
  V2_HORIZ_GROUPED_BAR_CHART_ICON,
  V2_FUNNEL_CHART_ICON,
  V2_HEAT_MAP_CHART_ICON,
  V2_KPI_CHART_ICON,
  V2_BOX_PLOT_CHART_ICON,
  V2_PROGRESS_CHART_ICON,
  V2_NUMBER_TREND_ICON,
  V2_MAP_CHART_ICON,
  REPORT_BUILDER_ICON,
} from 'constants/iconConstants';
import { IconName } from '@blueprintjs/core';
import {
  TextElemHorizAlignments,
  TextElemVerticalAlignments,
  TEXT_ELEM_HORIZ_ALIGNMENTS,
  TEXT_ELEM_VERTICAL_ALIGNMENTS,
} from 'types/dashboardTypes';

export const INTEGER_DATA_TYPE: types.DataType = 'INTEGER';
export const UNSIGNED_INTEGER: types.DataType = 'unsigned integer';
export const FLOAT: types.DataType = 'FLOAT';
export const STRING: types.DataType = 'STRING';
export const BOOLEAN: types.DataType = 'BOOLEAN';
export const DATETIME: types.DataType = 'DATETIME';
export const DATE: types.DataType = 'DATE';
export const TIMESTAMP: types.DataType = 'TIMESTAMP';

export const NUMBER_TYPES = new Set([INTEGER_DATA_TYPE, UNSIGNED_INTEGER, FLOAT]);

export const VIZ_OP_WITH_CSV_DOWNLOAD = new Set([
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
  OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2,
  OPERATION_TYPES.VISUALIZE_FUNNEL_V2,
  OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_MAP_V2,
]);

export const VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN = new Set([
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
]);

export const VIZ_OPS_WITH_GOAL_LINES = new Set([
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
]);

export const DATE_TYPES = new Set([TIMESTAMP, DATETIME, DATE]);

export const SELECTABLE_DATA_TYPES: types.SelectableDataTypes = [
  STRING,
  INTEGER_DATA_TYPE,
  FLOAT,
  BOOLEAN,
  TIMESTAMP,
];

export const MAX_ROWS_TO_PREVIEW = 50;

export const SCHEMA_DATA_TYPES_BY_ID: types.SchemaDataTypesById = {
  [INTEGER_DATA_TYPE]: {
    name: 'Integer',
    icon: NUMBER_ICON,
  },
  [UNSIGNED_INTEGER]: {
    name: 'Unsigned Integer',
    icon: NUMBER_ICON,
  },
  [FLOAT]: {
    name: 'Decimal',
    icon: DECIMAL_ICON,
  },
  [STRING]: {
    name: 'Text',
    icon: TEXT_ICON,
  },
  [BOOLEAN]: {
    name: 'Boolean',
    icon: 'tick',
  },
  [DATETIME]: {
    name: 'Date',
    icon: 'calendar',
  },
  [DATE]: {
    name: 'Date',
    icon: 'calendar',
  },
  [TIMESTAMP]: {
    name: 'Timestamp',
    icon: 'calendar',
  },
};

// should only use values in DayJS.OpUnitType
export const PIVOT_AGG_TYPES = {
  DATE_HOUR: {
    id: 'DATE_HOUR',
    name: 'Hour',
    getExample: (dateTime: dayjs.Dayjs) =>
      dateTime.format(DATE_FORMAT_TO_LOCALIZED_FORMAT['HH:00 M/D']),
  },
  DATE_DAY: {
    id: 'DATE_DAY',
    name: 'Day',
    getExample: (dateTime: dayjs.Dayjs) =>
      dateTime.format(DATE_FORMAT_TO_LOCALIZED_FORMAT['MMM D, YYYY']),
  },
  DATE_WEEK: {
    id: 'DATE_WEEK',
    name: 'Week',
    getExample: (dateTime: dayjs.Dayjs) =>
      dateTime.clone().startOf('isoWeek').format(DATE_FORMAT_TO_LOCALIZED_FORMAT['MMM D, YYYY']),
  },
  DATE_MONTH: {
    id: 'DATE_MONTH',
    name: 'Month',
    getExample: (dateTime: dayjs.Dayjs) =>
      dateTime.format(DATE_FORMAT_TO_LOCALIZED_FORMAT['MMM YYYY']),
  },
  DATE_YEAR: {
    id: 'DATE_YEAR',
    name: 'Year',
    getExample: (dateTime: dayjs.Dayjs) => dateTime.format(DATE_FORMAT_TO_LOCALIZED_FORMAT['YYYY']),
  },
  DATE_PART_WEEK_DAY: {
    id: 'DATE_PART_WEEK_DAY',
    name: 'Day of Week',
    getExample: (dateTime: dayjs.Dayjs) => dateTime.format(DATE_FORMAT_TO_LOCALIZED_FORMAT['ddd']),
  },
  DATE_PART_MONTH_DAY: {
    id: 'DATE_PART_MONTH_DAY',
    name: 'Day of Month',
    getExample: (dateTime: dayjs.Dayjs) => dateTime.format(DATE_FORMAT_TO_LOCALIZED_FORMAT['D']),
  },
  DATE_PART_MONTH: {
    id: 'DATE_PART_MONTH',
    name: 'Month of Year',
    getExample: (dateTime: dayjs.Dayjs) => dateTime.format(DATE_FORMAT_TO_LOCALIZED_FORMAT['MMM']),
  },
  DATE_PART_HOUR: {
    id: 'DATE_PART_HOUR',
    name: 'Hour of Day',
    getExample: (dateTime: dayjs.Dayjs) => dateTime.format(DATE_FORMAT_TO_LOCALIZED_FORMAT['ha']),
  },
};

export const DATE_PIVOT_AGGS = [
  PIVOT_AGG_TYPES.DATE_HOUR,
  PIVOT_AGG_TYPES.DATE_DAY,
  PIVOT_AGG_TYPES.DATE_WEEK,
  PIVOT_AGG_TYPES.DATE_MONTH,
  PIVOT_AGG_TYPES.DATE_YEAR,
];

export const DATE_PART_AGGS = [
  PIVOT_AGG_TYPES.DATE_PART_WEEK_DAY,
  PIVOT_AGG_TYPES.DATE_PART_MONTH_DAY,
  PIVOT_AGG_TYPES.DATE_PART_MONTH,
  PIVOT_AGG_TYPES.DATE_PART_HOUR,
];

export const DATE_PART_INPUT_AGG = 'DATE_PART_INPUT_AGG';

export const AGGREGATIONS_TYPES: types.AggregationTypes = {
  COUNT: {
    id: 'COUNT',
    name: 'Count',
    selectionValue: 'Count',
  },
  COUNT_DISTINCT: {
    id: 'COUNT_DISTINCT',
    name: 'Count Distinct',
    selectionValue: 'Count Distinct',
  },
  AVG: {
    id: 'AVG',
    name: 'Avg',
    selectionValue: 'Avg',
  },
  SUM: {
    id: 'SUM',
    name: 'Sum',
    selectionValue: 'Sum',
  },
  MIN: {
    id: 'MIN',
    name: 'Min',
    selectionValue: 'Min',
  },
  MAX: {
    id: 'MAX',
    name: 'Max',
    selectionValue: 'Max',
  },
  '25_PERCENTILE': {
    id: '25_PERCENTILE',
    name: '25th Percentile',
    selectionValue: '25th Percentile',
  },
  MEDIAN: {
    id: 'MEDIAN',
    name: 'Median',
    selectionValue: 'Median',
  },
  '75_PERCENTILE': {
    id: '75_PERCENTILE',
    name: '75th Percentile',
    selectionValue: '75th Percentile',
  },
};

export const FORMULA_AGG_TYPE: types.AggregationType = {
  id: 'FORMULA',
  name: 'Formula',
  selectionValue: 'Formula',
};

export const AGGREGATIONS_LIST = Object.keys(AGGREGATIONS_TYPES).map(
  (key) => AGGREGATIONS_TYPES[key as types.Aggregation],
);

export const NON_NUMBER_AGGREGATIONS_LIST = Object.keys(AGGREGATIONS_TYPES)
  .filter((id) => id === AGGREGATIONS_TYPES.COUNT.id || id === AGGREGATIONS_TYPES.COUNT_DISTINCT.id)
  .map((key) => AGGREGATIONS_TYPES[key as types.Aggregation]);

export const OP_TYPE_TO_BP3_ICON: types.OperationIconsDictionary = {
  VISUALIZE_TABLE: 'th',
  VISUALIZE_LINE_CHART: 'timeline-line-chart',
  VISUALIZE_BAR_CHART: 'timeline-bar-chart',
  VISUALIZE_NUMBER: 'numerical',
  VISUALIZE_HORIZ_BAR_CHART: 'horizontal-bar-chart',
  VISUALIZE_COMBO_CHART: 'chart',
  VISUALIZE_CHOROPLETH_MAP: 'map',
  VISUALIZE_FUNNEL: 'filter',
  VISUALIZE_PIE_CHART: 'pie-chart',
  VISUALIZE_HEAT_MAP: 'heat-grid',
  VISUALIZE_PIVOT_CHART: 'stacked-chart',
  FILTER: 'filter',
  SORT: 'sort',
  GROUP_BY: 'filter',
  VISUALIZE_VERTICAL_BAR_V2: V2_VERT_BAR_CHART_ICON,
  VISUALIZE_VERTICAL_100_BAR_V2: V2_VERT_100_BAR_CHART_ICON,
  VISUALIZE_VERTICAL_GROUPED_BAR_V2: V2_VERT_GROUPED_BAR_CHART_ICON,
  VISUALIZE_HORIZONTAL_BAR_V2: V2_HORIZ_BAR_CHART_ICON,
  VISUALIZE_HORIZONTAL_100_BAR_V2: V2_HORIZ_100_BAR_CHART_ICON,
  VISUALIZE_HORIZONTAL_GROUPED_BAR_V2: V2_HORIZ_GROUPED_BAR_CHART_ICON,
  VISUALIZE_PIE_CHART_V2: V2_PIE_CHART_ICON,
  VISUALIZE_DONUT_CHART_V2: V2_DONUT_CHART_ICON,
  VISUALIZE_LINE_CHART_V2: V2_LINE_CHART_ICON,
  VISUALIZE_AREA_CHART_V2: V2_AREA_CHART_ICON,
  VISUALIZE_AREA_100_CHART_V2: V2_AREA_100_CHART_ICON,
  VISUALIZE_COMBO_CHART_V2: V2_COMBO_CHART_ICON,
  VISUALIZE_HEAT_MAP_V2: V2_HEAT_MAP_CHART_ICON,
  VISUALIZE_FUNNEL_V2: V2_FUNNEL_CHART_ICON,
  VISUALIZE_NUMBER_V2: V2_KPI_CHART_ICON,
  VISUALIZE_BOX_PLOT_V2: V2_BOX_PLOT_CHART_ICON,
  VISUALIZE_PROGRESS_V2: V2_PROGRESS_CHART_ICON,
  VISUALIZE_NUMBER_TREND_V2: V2_NUMBER_TREND_ICON,
  VISUALIZE_MAP_V2: V2_MAP_CHART_ICON,
  VISUALIZE_REPORT_BUILDER: REPORT_BUILDER_ICON,
};

export const VIZ_TO_SECTION: Record<string, string> = {
  [types.OPERATION_TYPES.VISUALIZE_NUMBER_V2]: types.ChartTypeSections.PERFORMANE_INDICATOR,
  [types.OPERATION_TYPES.VISUALIZE_PROGRESS_V2]: types.ChartTypeSections.PERFORMANE_INDICATOR,
  [types.OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2]: types.ChartTypeSections.PERFORMANE_INDICATOR,

  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2]: types.ChartTypeSections.BAR_CHART,
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2]: types.ChartTypeSections.BAR_CHART,
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2]: types.ChartTypeSections.BAR_CHART,
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2]: types.ChartTypeSections.BAR_CHART,
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2]: types.ChartTypeSections.BAR_CHART,
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2]: types.ChartTypeSections.BAR_CHART,

  [types.OPERATION_TYPES.VISUALIZE_LINE_CHART_V2]: types.ChartTypeSections.LINE_CHART,
  [types.OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2]: types.ChartTypeSections.LINE_CHART,

  [types.OPERATION_TYPES.VISUALIZE_AREA_CHART_V2]: types.ChartTypeSections.AREA_CHART,
  [types.OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2]: types.ChartTypeSections.AREA_CHART,

  [types.OPERATION_TYPES.VISUALIZE_PIE_CHART_V2]: types.ChartTypeSections.PIE_CHART,
  [types.OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2]: types.ChartTypeSections.PIE_CHART,

  [types.OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2]: types.ChartTypeSections.SPECIAL,
  [types.OPERATION_TYPES.VISUALIZE_FUNNEL_V2]: types.ChartTypeSections.SPECIAL,
  [types.OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2]: types.ChartTypeSections.SPECIAL,
  [types.OPERATION_TYPES.VISUALIZE_MAP_V2]: types.ChartTypeSections.SPECIAL,
};

export const VIZ_TO_NAME: Record<string, string> = {
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2]: 'Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2]: '100% Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2]: 'Grouped Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2]: 'Horizontal Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2]: '100% Horizontal Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2]: 'Horizontal Grouped Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_PIE_CHART_V2]: 'Pie Chart',
  [types.OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2]: 'Donut Chart',
  [types.OPERATION_TYPES.VISUALIZE_LINE_CHART_V2]: 'Line Chart',
  [types.OPERATION_TYPES.VISUALIZE_AREA_CHART_V2]: 'Area Chart',
  [types.OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2]: '100% Area Chart',
  [types.OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2]: 'Combo Chart',
  [types.OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2]: 'Heat Map',
  [types.OPERATION_TYPES.VISUALIZE_FUNNEL_V2]: 'Funnel',
  [types.OPERATION_TYPES.VISUALIZE_NUMBER_V2]: 'KPI',
  [types.OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2]: 'Box Plot',
  [types.OPERATION_TYPES.VISUALIZE_PROGRESS_V2]: 'Progress',
  [types.OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2]: 'KPI Trend',
  [types.OPERATION_TYPES.VISUALIZE_MAP_V2]: 'Geographic Map',
};

// If chart types have different types in this map, then when a user switches
// between charts of different types, it reloads the data from the backend.
// The implication is that the way the data needs to be calculated is different
// and so we have to refresh the data with the new chart type/instructions
export const V2_VIZ_INSTRUCTION_TYPE: Record<string, string> = {
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_PIE_CHART_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_LINE_CHART_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_AREA_CHART_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_FUNNEL_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2]: 'Box & Whisker Plot',
  [types.OPERATION_TYPES.VISUALIZE_NUMBER_V2]: 'KPI',
  [types.OPERATION_TYPES.VISUALIZE_PROGRESS_V2]: 'KPI',
  [types.OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2]: 'Number Trend',
  [types.OPERATION_TYPES.VISUALIZE_MAP_V2]: 'Two-dimensional',
};

export const EMPTY_Y_AXIS_CONFIG: types.LineColumn = {
  column: undefined,
  color: undefined,
};

export const NUMBER_FORMATS = {
  NORMAL: {
    id: 'NORMAL',
    name: 'Normal',
  },
  CURRENCY: {
    id: 'CURRENCY',
    name: 'Currency ($)',
  },
  PERCENT: {
    id: 'PERCENT',
    name: 'Percent (%)',
  },
};

export const V2_NUMBER_FORMATS = {
  PLAIN_TEXT: {
    id: 'PLAIN_TEXT',
    name: 'Plain Text',
    abbreviation: 'abbr.',
  },
  NUMBER: {
    id: 'NUMBER',
    name: 'Number',
    abbreviation: '#',
  },

  CURRENCY: {
    id: 'CURRENCY',
    name: 'Currency ($)',
    abbreviation: '$',
  },
  PERCENT: {
    id: 'PERCENT',
    name: 'Percent (%)',
    abbreviation: '%',
  },
  TIME: {
    id: 'TIME',
    name: 'Time (00:00)',
    abbreviation: '00:00',
  },
};

export enum STRING_FORMATS {
  DEFAULT = 'Default',
  UPPERCASE = 'Uppercase',
  LOWERCASE = 'Lowercase',
  TITLE_CASE = 'Title Case',
  CAMEL_CASE = 'Camel Case',
  SENTENCE_CASE = 'Sentence Case',
}

export const TIME_DIFF_FORMATS = {
  STANDARD: {
    id: 'STANDARD',
    name: 'Standard',
  },
  ABBREVATION: {
    id: 'ABBREVATION',
    name: 'Abbreviated',
  },
  CUSTOM: {
    id: 'CUSTOME',
    name: 'Custom',
  },
};

export const ALIGNMENT_FORMATS: Record<
  string,
  { id: TextElemHorizAlignments; icon: string; name: string }
> = {
  LEFT_ALIGN: {
    id: TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT,
    icon: 'alignment-left',
    name: 'left',
  },
  CENTER_ALIGN: {
    id: TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER,
    icon: 'alignment-vertical-center',
    name: 'center',
  },
  RIGHT_ALIGN: {
    id: TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT,
    icon: 'alignment-right',
    name: 'right',
  },
};

export const ORDERED_ALIGNMENTS = [
  ALIGNMENT_FORMATS.LEFT_ALIGN,
  ALIGNMENT_FORMATS.CENTER_ALIGN,
  ALIGNMENT_FORMATS.RIGHT_ALIGN,
];

export const VERT_ALIGNMENT_FORMATS: Record<
  string,
  { id: TextElemVerticalAlignments; icon: string; name: string }
> = {
  TOP: {
    id: TEXT_ELEM_VERTICAL_ALIGNMENTS.TOP,
    icon: 'alignment-top',
    name: 'top',
  },
  CENTER: {
    id: TEXT_ELEM_VERTICAL_ALIGNMENTS.CENTER,
    icon: 'alignment-horizontal-center',
    name: 'center',
  },
  BOTTOM: {
    id: TEXT_ELEM_VERTICAL_ALIGNMENTS.BOTTOM,
    icon: 'alignment-bottom',
    name: 'bottom',
  },
};

export const ORDERED_VERT_ALIGNMENTS = [
  VERT_ALIGNMENT_FORMATS.TOP,
  VERT_ALIGNMENT_FORMATS.CENTER,
  VERT_ALIGNMENT_FORMATS.BOTTOM,
];

export const PIE_CHART_TYPES: { [id: string]: RecordItem } = {
  pie: {
    id: 'pie',
    name: 'Pie chart',
  },
  doughnut: {
    id: 'doughnut',
    name: 'Donut chart',
  },
};

export const LEGEND_LOCATIONS: { [id: string]: RecordItem } = {
  right: {
    id: 'right',
    name: 'Right',
  },
  top: {
    id: 'top',
    name: 'Top',
  },
  left: {
    id: 'left',
    name: 'Left',
  },
  bottom: {
    id: 'bottom',
    name: 'Bottom',
  },
};

export const EMPTY_FILTER_CONFIG: types.FilterOperationInstructions = {
  matchOnAll: true,
  filterClauses: [],
};

type ChartViewType = { id: string; text: string; icon: string };
type ChartViewsGroupType = { [key: string]: ChartViewType };

const BAR_CHART_VIEWS = {
  GROUPED: {
    id: 'GROUPED',
    text: 'Grouped',
    icon: 'grouped-bar-chart',
  },
  STACKED: {
    id: 'STACKED',
    text: 'Stacked',
    icon: 'stacked-chart',
  },
};

const HORIZ_BAR_CHART_VIEWS = {
  GROUPED: {
    id: 'GROUPED',
    text: 'Grouped',
    icon: 'grouped-bar-chart',
  },
  STACKED: {
    id: 'STACKED',
    text: 'Stacked',
    icon: 'stacked-chart',
  },
};

const LINE_CHART_VIEWS = {
  NORMAL: {
    id: 'NORMAL',
    text: 'Normal',
    icon: 'timeline-line-chart',
  },
  AREA: {
    id: 'AREA',
    text: 'Area',
    icon: 'timeline-area-chart',
  },
};

const COMBO_CHART_VIEWS = {
  NORMAL: {
    id: 'NORMAL',
    text: 'Normal',
    icon: 'chart',
  },
  AREA: {
    id: 'AREA',
    text: 'Area',
    icon: 'chart',
  },
};

export const CHART_VIEW_TYPES: Record<string, ChartViewsGroupType> = {
  [OPERATION_TYPES.VISUALIZE_BAR_CHART]: BAR_CHART_VIEWS,
  [OPERATION_TYPES.VISUALIZE_HORIZ_BAR_CHART]: HORIZ_BAR_CHART_VIEWS,
  [OPERATION_TYPES.VISUALIZE_LINE_CHART]: LINE_CHART_VIEWS,
  [OPERATION_TYPES.VISUALIZE_COMBO_CHART]: COMBO_CHART_VIEWS,
};

export const DEFAULT_CHART_VIEW: { [key: string]: ChartViewType } = {
  [OPERATION_TYPES.VISUALIZE_BAR_CHART]: BAR_CHART_VIEWS.GROUPED,
  [OPERATION_TYPES.VISUALIZE_HORIZ_BAR_CHART]: HORIZ_BAR_CHART_VIEWS.GROUPED,
  [OPERATION_TYPES.VISUALIZE_LINE_CHART]: LINE_CHART_VIEWS.NORMAL,
  [OPERATION_TYPES.VISUALIZE_COMBO_CHART]: COMBO_CHART_VIEWS.NORMAL,
};

export const REGIONS: { [key: string]: RegionMetaData } = {
  [REGION_TYPES.UNITED_STATES]: {
    id: REGION_TYPES.UNITED_STATES,
    text: 'United States',
    center: [51.5, -110.0],
    zoom: 2,
  },
  [REGION_TYPES.WORLD]: {
    id: REGION_TYPES.WORLD,
    text: 'World',
    center: [41.9, -20.7],
    zoom: 1,
  },
};

export type GroupedChartType = { id: string; name: string; icon: IconName };

export const GROUPED_CHART_TYPES: Record<string, GroupedChartType> = {
  STACKED_BAR: {
    id: 'STACKED_BAR',
    name: 'Stacked Bars',
    icon: 'stacked-chart',
  },
  FULL_STACKED_BAR: {
    id: 'FULL_STACKED_BAR',
    name: 'Full Stacked Bars',
    icon: 'full-stacked-chart',
  },
  NORMAL_LINE: {
    id: 'NORMAL_LINE',
    name: 'Lines',
    icon: 'timeline-line-chart',
  },
  AREA_LINE_CHART: {
    id: 'AREA_LINE_CHART',
    name: 'Stacked Area Chart',
    icon: 'timeline-area-chart',
  },
};

export const DEFAULT_REGION: string = REGION_TYPES.WORLD;

export const DEFAULT_BOOLEAN_DISPLAY_OPTIONS: types.BooleanDisplayOptions = {
  falseIcon: types.BooleanDisplayFormat.CROSS,
  trueIcon: types.BooleanDisplayFormat.TICK,
};

export const BOOLEAN_DISPLAY_FORMATS: types.BooleanDisplayFormat[] = [
  types.BooleanDisplayFormat.TICK,
  types.BooleanDisplayFormat.CROSS,
  types.BooleanDisplayFormat.BLANK,
];

export enum PeriodRangeTypes {
  TODAY = 'Today',
  LAST_7_DAYS = 'Last 7 days',
  LAST_4_WEEKS = 'Last 4 weeks',
  LAST_3_MONTHS = 'Last 3 months',
  LAST_12_MONTHS = 'Last 12 months',
  MONTH_TO_DATE = 'Month to date',
  YEAR_TO_DATE = 'Year to date',
  CUSTOM_RANGE = 'Custom Range',
  DATE_RANGE_INPUT = 'Date Range Input',
  TIME_PERIOD_DROPDOWN = 'Time Period Dropdown',
}

export const PERIOD_RANGE_OPTIONS = {
  [PeriodRangeTypes.TODAY]: {
    id: PeriodRangeTypes.TODAY,
    name: 'Today',
  },
  [PeriodRangeTypes.LAST_7_DAYS]: {
    id: PeriodRangeTypes.LAST_7_DAYS,
    name: 'Last 7 days',
  },
  [PeriodRangeTypes.LAST_4_WEEKS]: {
    id: PeriodRangeTypes.LAST_4_WEEKS,
    name: 'Last 4 weeks',
  },
  [PeriodRangeTypes.LAST_3_MONTHS]: {
    id: PeriodRangeTypes.LAST_3_MONTHS,
    name: 'Last 3 months',
  },
  [PeriodRangeTypes.LAST_12_MONTHS]: {
    id: PeriodRangeTypes.LAST_12_MONTHS,
    name: 'Last 12 months',
  },
  [PeriodRangeTypes.MONTH_TO_DATE]: {
    id: PeriodRangeTypes.MONTH_TO_DATE,
    name: 'Month to date',
  },
  [PeriodRangeTypes.YEAR_TO_DATE]: {
    id: PeriodRangeTypes.YEAR_TO_DATE,
    name: 'Year to date',
  },
  [PeriodRangeTypes.CUSTOM_RANGE]: {
    id: PeriodRangeTypes.CUSTOM_RANGE,
    name: 'Custom Range',
  },
  [PeriodRangeTypes.DATE_RANGE_INPUT]: {
    id: PeriodRangeTypes.DATE_RANGE_INPUT,
    name: 'Date Range Input',
  },
  [PeriodRangeTypes.TIME_PERIOD_DROPDOWN]: {
    id: PeriodRangeTypes.TIME_PERIOD_DROPDOWN,
    name: 'Time Period Dropdown',
  },
};

export enum PeriodComparisonRangeTypes {
  PREVIOUS_PERIOD = 'Previous Period',
  PREVIOUS_MONTH = 'Previous Month',
  PREVIOUS_YEAR = 'Previous Year',
  NO_COMPARISON = 'No Comparison',
}

export const PERIOD_COMPARISON_RANGE_TYPES = {
  [PeriodComparisonRangeTypes.PREVIOUS_PERIOD]: {
    id: PeriodComparisonRangeTypes.PREVIOUS_PERIOD,
    name: 'Previous Period',
  },
  [PeriodComparisonRangeTypes.PREVIOUS_MONTH]: {
    id: PeriodComparisonRangeTypes.PREVIOUS_MONTH,
    name: 'Previous Month',
  },
  [PeriodComparisonRangeTypes.PREVIOUS_YEAR]: {
    id: PeriodComparisonRangeTypes.PREVIOUS_YEAR,
    name: 'Previous Year',
  },
  [PeriodComparisonRangeTypes.NO_COMPARISON]: {
    id: PeriodComparisonRangeTypes.NO_COMPARISON,
    name: 'No Comparison',
  },
};

export enum TrendGroupingOptions {
  HOURLY = 'Hourly',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}

export const TREND_GROUPING_OPTIONS = {
  [TrendGroupingOptions.HOURLY]: {
    id: TrendGroupingOptions.HOURLY,
    name: 'Hourly',
  },
  [TrendGroupingOptions.DAILY]: {
    id: TrendGroupingOptions.DAILY,
    name: 'Daily',
  },
  [TrendGroupingOptions.WEEKLY]: {
    id: TrendGroupingOptions.WEEKLY,
    name: 'Weekly',
  },
  [TrendGroupingOptions.MONTHLY]: {
    id: TrendGroupingOptions.MONTHLY,
    name: 'Monthly',
  },
  [TrendGroupingOptions.YEARLY]: {
    id: TrendGroupingOptions.YEARLY,
    name: 'Yearly',
  },
};

export const TREND_GROUP_OPTION_TO_PIVOT_AGG = {
  [TrendGroupingOptions.HOURLY]: PIVOT_AGG_TYPES.DATE_HOUR,
  [TrendGroupingOptions.DAILY]: PIVOT_AGG_TYPES.DATE_DAY,
  [TrendGroupingOptions.WEEKLY]: PIVOT_AGG_TYPES.DATE_WEEK,
  [TrendGroupingOptions.MONTHLY]: PIVOT_AGG_TYPES.DATE_MONTH,
  [TrendGroupingOptions.YEARLY]: PIVOT_AGG_TYPES.DATE_YEAR,
};
