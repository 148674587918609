/** @format */

import React from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';

import DraggableColumn from './DraggableColumn';

import { TableColumn } from 'actions/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  colContainer: {
    padding: `0 ${theme.spacing(1)}px`,
    marginTop: theme.spacing(1),
  },
}));

export type Props = {
  schema: TableColumn[];
  loading?: boolean;
};

export default function VisualizationConfigTableColumns({ schema, loading }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.colContainer}>
        {schema.map((col) => (
          <DraggableColumn
            key={_.uniqueId(`column-selectors-`)}
            name={col.name}
            type={col.type}
            disableDrag={loading}
          />
        ))}
      </div>
    </div>
  );
}
