/** @format */

import React from 'react';

import VegaChart from 'pages/dashboardPage/charts/vegaChart';

import { PivotChartDirection, ViusalizePivotChartInstructions } from 'constants/types';
import { AGGREGATIONS_TYPES, GROUPED_CHART_TYPES, DATE_TYPES } from 'constants/dataConstants';
import { titleCase, aggTitle } from 'utils/graphUtils';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { resolveJsOrVariable } from 'utils/dataPanelConfigUtils';

type Props = {
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: ViusalizePivotChartInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
};

const AGG_TYPE_TO_VEGA_AGG_TYPE = {
  [AGGREGATIONS_TYPES.COUNT.id]: 'count',
  [AGGREGATIONS_TYPES.COUNT_DISTINCT.id]: 'distinct',
  [AGGREGATIONS_TYPES.AVG.id]: 'average',
  [AGGREGATIONS_TYPES.SUM.id]: 'sum',
  [AGGREGATIONS_TYPES.MIN.id]: 'min',
  [AGGREGATIONS_TYPES.MAX.id]: 'max',
};

type State = {};

class PivotChart extends React.PureComponent<Props, State> {
  getChartId = () => {
    return `pivotChartContainer${this.props.dataPanelTemplateId}`;
  };

  instructionsReadyToDisplay = (instructions?: ViusalizePivotChartInstructions) => {
    return !!(
      instructions &&
      instructions.aggregation?.aggedOnColumn &&
      instructions.aggregation?.type &&
      instructions.xAxisColumn &&
      instructions.yAxisColumn
    );
  };

  render() {
    const { instructions, loading } = this.props;
    return (
      <VegaChart
        loading={loading}
        chartId={this.getChartId()}
        chartConfig={!loading && this._spec()}
        configReadyToDisplay={this.instructionsReadyToDisplay(instructions)}
      />
    );
  }

  // eslint-disable-next-line
  _spec = (): any => {
    const { previewData, instructions } = this.props;
    const aggType = instructions?.aggregation?.type?.id;
    const aggColName = instructions?.aggregation?.aggedOnColumn?.name;
    const xAxisColName = instructions?.xAxisColumn?.name;
    const yAxisColName = instructions?.yAxisColumn?.name;

    if (!aggType || !aggColName || !xAxisColName || !yAxisColName) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();

    const aggConfig = {
      aggregate: AGG_TYPE_TO_VEGA_AGG_TYPE[aggType],
      field: aggColName,
      type: 'quantitative',
      title: instructions?.aggregation?.aggLabel || aggTitle(aggType, aggColName),
      stack: this.getStackType(),
      format: this.getAggFormat(),
      axis: {
        labelFontSize: instructions?.labelFontSize,
        titleFontSize: instructions?.titleFontSize,
        titlePadding: 16,
      },
    };

    const groupingConfig = {
      field: xAxisColName,
      title: titleCase(xAxisColName),
      type: this.getXAxisType(),
      timeUnit: this.getTimeUnit(),
      axis: this.getAxisFormat(),
      scale: this.getAxisFixedRange(),
    };

    const isVertical =
      !instructions?.chartDirection ||
      instructions?.chartDirection === PivotChartDirection.VERTICAL;

    return {
      $schema: 'https://vega.github.io/schema/vega-lite/v4.json',
      description:
        'A population pyramid for the US in 2000, created using stack. See https://vega.github.io/vega-lite/examples/concat_population_pyramid.html for a variant of this created using concat.',
      data: {
        name: 'datum',
        values: previewData,
      },
      width: 'container',
      height: 'container',
      mark: { type: this.getMarkType(), tooltip: true, interpolate: 'monotone' },
      encoding: {
        y: isVertical ? aggConfig : groupingConfig,
        x: isVertical ? groupingConfig : aggConfig,
        color: {
          field: yAxisColName,
          scale: {
            range: instructions?.colors ? instructions.colors.split(',') : undefined,
          },
          legend: {
            labelFontSize: instructions?.labelFontSize,
            titleFontSize: instructions?.titleFontSize,
          },
        },
      },
      config: {
        axisX: { grid: false, tickMinStep: !isVertical && instructions?.aggMinStepSize },
        axisY: { grid: true, tickMinStep: isVertical && instructions?.aggMinStepSize },
      },
    };
  };

  getAxisFixedRange = () => {
    const { instructions, variables } = this.props;

    const lowerBound = resolveJsOrVariable(instructions?.axisMin, variables);
    const upperBound = resolveJsOrVariable(instructions?.axisMax, variables);
    if (!lowerBound || !upperBound) return;
    return { domain: { unionWith: [lowerBound?.toString(), upperBound?.toString()] } };
  };

  getMarkType = () => {
    const { instructions } = this.props;

    if (!instructions?.chartTypeId) return 'bar';
    switch (instructions.chartTypeId) {
      case GROUPED_CHART_TYPES.STACKED_BAR.id:
        return 'bar';
      case GROUPED_CHART_TYPES.FULL_STACKED_BAR.id:
        return 'bar';
      case GROUPED_CHART_TYPES.NORMAL_LINE.id:
        return 'line';
      case GROUPED_CHART_TYPES.AREA_LINE_CHART.id:
        return 'area';
      default:
        return 'bar';
    }
  };

  getStackType = () => {
    const { instructions } = this.props;

    if (!instructions?.chartTypeId) return 'bar';

    return instructions.chartTypeId === GROUPED_CHART_TYPES.FULL_STACKED_BAR.id
      ? 'normalize'
      : undefined;
  };

  getAggFormat = () => {
    const { instructions } = this.props;

    if (!instructions?.chartTypeId) return undefined;

    return instructions.chartTypeId === GROUPED_CHART_TYPES.FULL_STACKED_BAR.id ? '.2%' : undefined;
  };

  getXAxisType = () => {
    const { instructions } = this.props;
    const xAxisType = instructions?.xAxisColumn?.type || '';
    if (DATE_TYPES.has(xAxisType)) return 'temporal';
    return 'ordinal';
  };

  getTimeUnit = () => {
    const { instructions } = this.props;
    if (DATE_TYPES.has(instructions?.xAxisColumn?.type || '')) return 'yearmonthdate';
  };

  getAxisFormat = () => {
    const { instructions } = this.props;
    const sharedConfig = {
      labelFontSize: instructions?.labelFontSize,
      titleFontSize: instructions?.titleFontSize,
      titlePadding: 16,
    };
    if (DATE_TYPES.has(instructions?.xAxisColumn?.type || ''))
      return {
        ...sharedConfig,
        format: '%b %d',
      };
    return sharedConfig;
  };

  processDatesData = () => {
    const { instructions, previewData } = this.props;

    if (!DATE_TYPES.has(instructions?.xAxisColumn?.type || '')) return;

    previewData.forEach((row) => {
      if (!instructions?.xAxisColumn?.name) return;
      const date = row[instructions.xAxisColumn.name] as string;
      let datePart = date;
      if (date.indexOf('T') > -1) {
        datePart = date.split('T')[0];
      }
      row[instructions.xAxisColumn.name] = datePart + 'T18:00:00.000Z';
    });
  };
}

export default PivotChart;
