/** @format */
import React, { useState } from 'react';
import { Menu, MenuItem, Icon } from '@blueprintjs/core';

import { SETTINGS_LEFT_MENU_SECTIONS } from 'constants/settingsPageConstants';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import ScrollIntoView from 'react-scroll-into-view';

const useStyles = makeStyles((theme: Theme) => ({
  sidePanel: {
    borderRight: `1px solid ${theme.palette.ds.grey500}`,
    height: '100%',
    width: '200px',
  },
  icon: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: theme.palette.ds.grey700,
  },
  selectedIcon: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: theme.palette.ds.blue,
  },
  subsection: {
    marginLeft: theme.spacing(10),
    fontWeight: 400,
    color: theme.palette.ds.grey700,
  },
  selectedSubsection: {
    marginLeft: theme.spacing(10),
    fontWeight: 400,
    color: theme.palette.ds.blue,
  },
  header: {
    fontSize: 20,
    fontWeight: 600,
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    marginTop: theme.spacing(3),
  },
  section: {
    marginLeft: theme.spacing(5),
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    color: theme.palette.ds.grey700,
    paddingTop: theme.spacing(6),
  },
  selectedSection: {
    marginLeft: theme.spacing(5),
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    color: theme.palette.ds.blue,
    paddingTop: theme.spacing(6),
  },
}));

export default function SettingsLeftNav() {
  const [selectedSection, setSelectedSection] = useState<string>('Account');
  const [selectedSubsection, setSubselectedSection] = useState<string>('Profile');
  const onChange = (sectionName: string, subsectionName: string) => {
    setSelectedSection(sectionName);
    setSubselectedSection(subsectionName);
  };
  const classes = useStyles();

  return (
    <div className={classes.sidePanel}>
      <div className={classes.header}>Settings</div>
      <div>
        {SETTINGS_LEFT_MENU_SECTIONS.map((section) => (
          <Menu key={section.groupName}>
            <div
              className={
                selectedSection === section.groupName ? classes.selectedSection : classes.section
              }>
              <Icon
                icon={section.icon}
                className={
                  selectedSection === section.groupName ? classes.selectedIcon : classes.icon
                }
              />
              {section.groupName}
            </div>
            {section.subsections.map((subsection) => {
              const normalizedSubsectionName = subsection.replace(' ', '');

              return (
                <ScrollIntoView
                  selector={`#${normalizedSubsectionName}`}
                  key={normalizedSubsectionName}>
                  <div className="mdl-button mdl-js-button mdl-button--raised">
                    <MenuItem
                      className={
                        selectedSubsection === normalizedSubsectionName
                          ? classes.selectedSubsection
                          : classes.subsection
                      }
                      text={subsection}
                      onClick={() => onChange(section.groupName, normalizedSubsectionName)}
                    />
                  </div>
                </ScrollIntoView>
              );
            })}
          </Menu>
        ))}
      </div>
    </div>
  );
}
