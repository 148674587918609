/** @format */

import React from 'react';
import _ from 'underscore';
import { cloneDeep } from 'lodash';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Theme, WithStyles } from '@material-ui/core/index';

import EditingPanel from 'pages/dataPanelEditorPage/editingPanel';
import DropdownSelect from 'shared/DropdownSelect';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { DataType, PivotOperationAggregation, SelectedDropdownInputItem } from 'constants/types';
import { TableColumn } from 'actions/types';
import {
  AGGREGATIONS_LIST,
  SCHEMA_DATA_TYPES_BY_ID,
  NON_NUMBER_AGGREGATIONS_LIST,
  NUMBER_TYPES,
} from 'constants/dataConstants';
import { IconName } from '@blueprintjs/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    columnSelect: {
      marginBottom: theme.spacing(2),
    },
    labelInput: {
      marginTop: theme.spacing(2),
    },
    bucketSizeIntegerInput: {
      width: 150,
    },
  });

type PassedProps = {
  baseSchema: TableColumn[];
  className?: string;
  aggregation: PivotOperationAggregation;
  onDelete?: () => void;
  onUpdate: (pivotOperationAggregation: PivotOperationAggregation) => void;
  hasLabel?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles> & RouteComponentProps;

class CalculationsEditingPanel extends React.Component<Props> {
  render() {
    const { className, onDelete } = this.props;
    return (
      <EditingPanel className={className} onDelete={onDelete}>
        {this.renderColumnDropdown()}
        {this.renderCalculationTypeSelector()}
        {this.renderLabelInput()}
      </EditingPanel>
    );
  }

  renderColumnDropdown = () => {
    const { classes, baseSchema, aggregation, onUpdate } = this.props;
    const { aggedOnColumn } = aggregation;

    return (
      <div className={classes.columnSelect}>
        <DropdownSelect
          selectedItem={
            aggedOnColumn
              ? ({
                  name: aggedOnColumn.name,
                  id: aggedOnColumn.name,
                  icon: SCHEMA_DATA_TYPES_BY_ID[aggedOnColumn.type].icon,
                } as SelectedDropdownInputItem)
              : undefined
          }
          onChange={(item: SelectedDropdownInputItem) => {
            const selectedItem = item as { name: string; id: string; icon: IconName; type: string };
            const aggCopy = cloneDeep(aggregation);
            const prevAggColumn = aggCopy.aggedOnColumn;
            if (prevAggColumn && prevAggColumn.type !== selectedItem.type) aggCopy.type = null;
            const calculationColumn = _.findWhere(baseSchema, {
              name: selectedItem.name,
            });
            aggCopy.aggedOnColumn = calculationColumn || null;
            onUpdate(aggCopy);
          }}
          filterable={false}
          options={baseSchema.map((col) => ({
            ...col,
            icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
            id: col.name,
          }))}
          noSelectionText="Column"
          minimal
          fillWidth
          showIcon
        />
      </div>
    );
  };

  renderCalculationTypeSelector = () => {
    const { aggregation, onUpdate } = this.props;

    return (
      <DropdownSelect
        disabled={aggregation.aggedOnColumn === null}
        selectedItem={aggregation.type as SelectedDropdownInputItem}
        onChange={(item) => {
          const selectedItem = item as { name: string; id: string; selectionValue: string };
          const aggCopy = cloneDeep(aggregation);
          aggCopy.type = selectedItem;
          onUpdate(aggCopy);
        }}
        noSelectionText="Select an Aggregation"
        options={
          this.getAggListForColType(
            aggregation.aggedOnColumn && (aggregation.aggedOnColumn.type as DataType),
          ) as SelectedDropdownInputItem[]
        }
        minimal
        fillWidth
      />
    );
  };

  getAggListForColType = (colType: DataType | null) => {
    if (!colType) {
      return [];
    }
    if (NUMBER_TYPES.has(colType)) {
      return AGGREGATIONS_LIST;
    } else {
      return NON_NUMBER_AGGREGATIONS_LIST;
    }
  };

  renderLabelInput = () => {
    const { classes, aggregation, onUpdate, hasLabel } = this.props;

    if (!hasLabel) return;

    return (
      <InputWithBlurSave
        containerClassName={classes.labelInput}
        initialValue={aggregation.aggLabel || ''}
        onNewValueSubmitted={(newValue: string) => {
          const aggCopy = cloneDeep(aggregation);
          aggCopy.aggLabel = newValue;
          onUpdate(aggCopy);
        }}
        placeholder="Label"
      />
    );
  };
}

export default withRouter(withStyles(styles)(CalculationsEditingPanel));
