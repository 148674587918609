/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';
import { Switch } from '@blueprintjs/core';

import InputLabel from 'shared/InputLabel';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    label: {
      marginBottom: 0,
      marginRight: theme.spacing(2),

      '&.onRight': {
        marginRight: 0,
        marginLeft: theme.spacing(2),
      },
    },
    switchStyles: {
      '&.bp3-control': {
        marginBottom: 0,
        // to account for the white space to the right of the switch
        marginRight: -10,
      },
      '&.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator': {
        backgroundColor: theme.palette.ds.blue,
      },
    },
  });

type PassedProps = {
  onChange: (newValue: boolean) => void;
  className?: string;
  label?: string;
  labelClassName?: string;
  switchOn?: boolean;
  labelOnRight?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

class SwitchInput extends React.Component<Props> {
  render = () => {
    const {
      classes,
      className,
      switchOn,
      onChange,
      label,
      labelClassName,
      labelOnRight,
    } = this.props;

    const labelElem = (
      <InputLabel
        text={label || ''}
        className={cx(
          classes.label,
          labelClassName,
          GLOBAL_STYLE_CLASSNAMES.text.smallBody.secondary,
          { onRight: labelOnRight },
        )}
      />
    );

    return (
      <div className={cx(classes.root, className)}>
        {label && !labelOnRight && labelElem}
        <Switch
          className={cx(
            classes.switchStyles,
            GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.toggleBackgroundColor,
          )}
          checked={switchOn}
          onChange={() => {
            onChange(!switchOn);
          }}
        />
        {label && labelOnRight && labelElem}
      </div>
    );
  };
}

export default withStyles(styles)(SwitchInput);
