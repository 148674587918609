/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import sqlFormatter from 'sql-formatter';
import Highlight from 'react-highlight';

import SettingHeader, {
  SETTINGS_HEADER_HEIGHT,
} from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/SettingHeader';

import { DataPanelTemplate } from 'actions/types';

const useStyles = makeStyles((theme: Theme) => ({
  queryContainer: {
    fontFamily: 'Source Code Pro',
    padding: `0 ${theme.spacing(2)}px`,
    whiteSpace: 'pre',
    height: `calc(100% - ${SETTINGS_HEADER_HEIGHT}px + 3px)`,
    overflow: 'auto',
  },
  noSelectionContainer: {
    padding: theme.spacing(3),
    height: `calc(100% + 3px)`, // no section header for empty selection area
    overflow: 'auto',
  },
}));

type Props = {
  dataPanel?: DataPanelTemplate;
  noDataPanelSelected?: boolean;
};

export default function QueryDebugger({ dataPanel, noDataPanelSelected }: Props) {
  const classes = useStyles();

  if (!dataPanel || noDataPanelSelected) {
    return (
      <>
        <div className={classes.noSelectionContainer}>
          Select a data panel on the dashboard to use the debugger
        </div>
      </>
    );
  }

  const formattedQuery = sqlFormatter.format(dataPanel._query || '', {
    indent: '  ',
  });

  return (
    <>
      <SettingHeader name={dataPanel.name} />
      <div className={classes.queryContainer}>
        <Highlight>{formattedQuery}</Highlight>
      </div>
    </>
  );
}
