/** @format */

import React from 'react';
import validator from 'validator';

import { Classes, Intent, FormGroup } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import InputLabel from 'shared/InputLabel';
import DropdownSelect from 'shared/DropdownSelect';
import { ReduxState } from 'reducers/rootReducer';
import Modal from 'components/core/Modal';
import { EMAIL_FREQUENCY, EmailCadenceTime } from 'constants/types';
import Button from 'shared/Button';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION, EmailCadence } from 'actions/types';
import DeleteConfirmButton from 'components/core/DeleteConfirmButton';
import { sendTestEmail } from 'actions/emailActions';
import { getFrequency, getHour } from 'utils/emailUtils';
import EmailTimeSelection from './emailTimeSelection';
import EmailDaySelection from './emailDaySelection';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '600px',
    },
    description: {
      color: theme.palette.ds.grey700,
      fontWeight: 400,
      fontSize: '12px',
    },
    headerMessage: {
      marginTop: theme.spacing(-10),
      paddingBottom: theme.spacing(2),
      color: theme.palette.ds.grey900,
    },
    formContainer: {
      marginBottom: theme.spacing(0),
      paddingTop: theme.spacing(3),
    },
    weekdaySelect: {
      paddingTop: theme.spacing(3),
    },
    timeSelect: {
      display: 'flex',
      paddingTop: theme.spacing(3),
      gap: `${theme.spacing(6)}px`,
      justifyContent: 'space-between',
    },
    daySelect: {},
    actionButtons: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    rightButtonContainer: {
      display: 'flex',
    },
    testEmailContainer: {
      marginTop: theme.spacing(8),
      backgroundColor: theme.palette.ds.grey200,
      padding: `${theme.spacing(2.5)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
        3,
      )}px`,
      borderRadius: `${theme.spacing(1)}px`,
    },
    testEmailHeader: {
      fontSize: 14,
      fontWeight: 600,
    },
    testEmailDescription: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      color: theme.palette.ds.grey900,
    },
    testEmailContentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',
    },
    testEmailInput: {
      flexGrow: 2,
    },
    testEmailButton: {
      backgroundColor: `${theme.palette.ds.grey300} !important`,
    },
    dayContainer: {
      display: 'flex',
      alignItems: 'end',
      flexGrow: 1,
      justifyContent: 'space-between',
    },
    dayText: {
      paddingBottom: '5px',
    },
  });

type PassedProps = {
  modalOpen: boolean;
  closeModal: () => void;
  modalTitle: string;
  emailCadence?: EmailCadence;
  onSubmit: (
    fromEmail: string,
    subject: string,
    time: EmailCadenceTime,
    dayOfWeek?: number,
    weekOfMonth?: number,
  ) => void;
  onDelete?: () => void;
  onSendTestEmail: (fromEmail: string, subject: string) => void;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  PassedProps &
  WithStyles<typeof styles>;

const SetupEmailModal = ({
  onSubmit,
  modalOpen,
  closeModal,
  modalTitle,
  classes,
  signUpLoading,
  emailCadence,
  onDelete,
  onSendTestEmail,
}: Props) => {
  const [fromEmail, setFromEmail] = React.useState('support@explo.co'); // TODO put back when we support this again emailCadence?.from_email || '');
  const [subject, setSubject] = React.useState(emailCadence?.subject || '');
  const [frequency, setFrequency] = React.useState<EMAIL_FREQUENCY | undefined>(
    getFrequency(emailCadence),
  );
  const [dayOfWeek, setDayOfWeek] = React.useState<number | undefined>(emailCadence?.day_of_week);
  const [time, setTime] = React.useState<EmailCadenceTime>({
    hour: getHour(emailCadence),
    minute: emailCadence?.minute,
    isPm: (emailCadence?.hour ?? 0) >= 12,
    timezone: emailCadence?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [weekOfMonth, setWeekOfMonth] = React.useState<number | undefined>(
    emailCadence?.week_of_month ? emailCadence?.week_of_month : undefined,
  );
  const [testEmail, setTestEmail] = React.useState('');

  const validateFrequency = () =>
    frequency === EMAIL_FREQUENCY.DAILY ||
    (frequency === EMAIL_FREQUENCY.MONTHLY &&
      weekOfMonth !== undefined &&
      dayOfWeek !== undefined) ||
    (frequency === EMAIL_FREQUENCY.WEEKLY && dayOfWeek !== undefined);

  const validateTime = () =>
    time && time.hour && time.minute !== undefined && time.isPm !== undefined && time.timezone;

  const isFormReadyToSubmit = () => {
    return (
      validator.isEmail(fromEmail) &&
      !validator.isEmpty(subject ?? '', { ignore_whitespace: true }) &&
      validateTime() &&
      validateFrequency()
    );
  };

  return (
    <Modal modalOpen={modalOpen} onClose={closeModal} title={modalTitle} className={classes.root}>
      <div className={Classes.DIALOG_BODY}>
        <div className={classes.headerMessage}>
          {'Set up recurring emails to be sent to dashboard users.'}
        </div>
        <FormGroup className={classes.formContainer} labelFor="text-input">
          <InputLabel text={'From Address'} />
          <InputGroup
            disabled
            intent={fromEmail === '' || validator.isEmail(fromEmail) ? Intent.NONE : Intent.DANGER}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFromEmail(e.target.value);
            }}
            placeholder="support@explo.co"
            value={fromEmail}
          />
          <div className={classes.description}>
            This is the address the recipient will be receiving the email from. Contact Explo
            support if you would like the email to be sent from your own domain
          </div>
        </FormGroup>
        <FormGroup className={classes.formContainer} labelFor="text-input">
          <InputLabel text={'Email Subject'} />
          <InputGroup
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSubject(e.target.value);
            }}
            placeholder="Scheduled Report"
            value={subject}
          />
        </FormGroup>
        <div className={classes.formContainer}>
          <InputLabel text={'Frequency'} />
          <DropdownSelect
            fillWidth
            minimal
            showIcon
            filterable={false}
            noSelectionText="Select Frequency"
            onChange={(item) => setFrequency(item.name as EMAIL_FREQUENCY)}
            options={Object.values(EMAIL_FREQUENCY).map((emailFrequency) => ({
              id: emailFrequency,
              name: emailFrequency,
            }))}
            selectedItem={
              frequency
                ? {
                    id: frequency,
                    name: frequency,
                  }
                : undefined
            }
          />
          {(frequency === EMAIL_FREQUENCY.MONTHLY || frequency === EMAIL_FREQUENCY.WEEKLY) && (
            <EmailDaySelection
              dayOfWeek={dayOfWeek}
              frequency={frequency}
              onSetDayOfWeek={(dayOfWeek: number) => setDayOfWeek(dayOfWeek)}
              onSetWeekOfMonth={(weekOfMonth: number) => setWeekOfMonth(weekOfMonth)}
              weekOfMonth={weekOfMonth}
            />
          )}
        </div>
        <EmailTimeSelection
          onSetTime={(time: EmailCadenceTime) => setTime(time)}
          emailCadenceTime={time}
        />
        <div className={classes.testEmailContainer}>
          <div className={classes.testEmailHeader}>Test Email</div>
          <div className={classes.testEmailDescription}>
            {"The test email will be populated with data from one of your customer's users."}
          </div>
          <div className={classes.testEmailContentContainer}>
            <div>
              <InputLabel text={'Email Address'} />
              <InputGroup
                intent={
                  testEmail === '' || validator.isEmail(testEmail) ? Intent.NONE : Intent.DANGER
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setTestEmail(e.target.value);
                }}
                placeholder="support@explo.co"
                value={testEmail}
                className={classes.testEmailInput}
              />
            </div>
            <Button
              className={classes.testEmailButton}
              disabled={!validator.isEmail(testEmail) || !subject}
              onClick={() =>
                validator.isEmail(testEmail) && subject && onSendTestEmail(testEmail, subject)
              }
              text="Send Test"
            />
          </div>
        </div>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={emailCadence ? classes.actionButtons : Classes.DIALOG_FOOTER_ACTIONS}>
          <div>{onDelete && <DeleteConfirmButton onDelete={() => onDelete()} />}</div>
          <div className={classes.rightButtonContainer}>
            <Button
              disabled={!isFormReadyToSubmit()}
              loading={signUpLoading}
              onClick={() =>
                isFormReadyToSubmit() && onSubmit(fromEmail, subject, time, dayOfWeek, weekOfMonth)
              }
              text="Confirm"
              type="primary"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  signUpLoading: createLoadingSelector(
    [ACTION.CREATE_DASHBOARD_EMAIL, ACTION.EDIT_DASHBOARD_EMAIL],
    false,
  )(state),
});

const mapDispatchToProps = { sendTestEmail };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SetupEmailModal));
