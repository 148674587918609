/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { IconName } from '@blueprintjs/core';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import ToggleSwitchInput from 'shared/ToggleSwitchInput';
import GoalInputRow from './GoalInputRow';
import DropdownSelect from 'shared/DropdownSelect';

import { TableColumn } from 'actions/types';
import {
  NumberDisplayFormat,
  NumberDisplayOptions,
  VisualizeTableInstructions,
} from 'constants/types';

import { TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';
import { ORDERED_ALIGNMENTS, TIME_DIFF_FORMATS } from 'constants/dataConstants';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  addFieldsToDisplayOptions: (
    newFields: Partial<NumberDisplayOptions> & {
      format: NumberDisplayFormat;
    },
  ) => void;
  column: TableColumn;
  instructions: VisualizeTableInstructions;
};

export default function NumberFormatConfiguration({
  addFieldsToDisplayOptions,
  column,
  instructions,
}: Props) {
  const classes = useStyles();
  const displayOptions = instructions.schemaDisplayOptions?.[column.name] as NumberDisplayOptions;
  const { decimalPlaces, hasCommas, goal, useColumnMaxForGoal, timeFormat } = displayOptions || {};
  const currentFormat = displayOptions?.format || NumberDisplayFormat.NORMAL;
  const currentAlignment = displayOptions?.alignment;

  const onFormatButtonClick = (newFormat: NumberDisplayFormat) => {
    if (newFormat === currentFormat) {
      return;
    }

    addFieldsToDisplayOptions({ format: newFormat });
  };

  return (
    <>
      <ToggleButtonGroup fillWidth className={classes.input} label="Formatting">
        <ToggleButton
          active={currentFormat === NumberDisplayFormat.NORMAL}
          onClick={() => {
            onFormatButtonClick(NumberDisplayFormat.NORMAL);
          }}
          text="-"
        />
        <ToggleButton
          active={currentFormat === NumberDisplayFormat.CURRENCY}
          onClick={() => {
            onFormatButtonClick(NumberDisplayFormat.CURRENCY);
          }}
          text="$"
        />
        <ToggleButton
          active={currentFormat === NumberDisplayFormat.PERCENT}
          onClick={() => {
            onFormatButtonClick(NumberDisplayFormat.PERCENT);
          }}
          text="%"
        />
        <ToggleButton
          active={currentFormat === NumberDisplayFormat.TIME}
          onClick={() => {
            onFormatButtonClick(NumberDisplayFormat.TIME);
          }}
          icon="time"
        />
      </ToggleButtonGroup>
      {currentFormat !== NumberDisplayFormat.TIME && (
        <>
          <ToggleSwitchInput
            className={classes.input}
            onChange={() => {
              addFieldsToDisplayOptions({ format: currentFormat, hasCommas: !hasCommas });
            }}
            label="Include thousands-separators"
            switchOn={hasCommas}
          />
          <InputWithBlurSave
            containerClassName={classes.input}
            label="Decimal places"
            initialValue={String(decimalPlaces ?? 0)}
            onNewValueSubmitted={(newValue) => {
              if (isNaN(Number(newValue))) {
                return;
              }

              addFieldsToDisplayOptions({ format: currentFormat, decimalPlaces: Number(newValue) });
            }}
          />
        </>
      )}
      {currentFormat === NumberDisplayFormat.TIME && (
        <>
          <DropdownSelect
            containerClassName={classes.input}
            selectedItem={timeFormat || TIME_DIFF_FORMATS.STANDARD}
            onChange={(item) =>
              addFieldsToDisplayOptions({ format: currentFormat, timeFormat: item })
            }
            filterable={false}
            options={Object.values(TIME_DIFF_FORMATS)}
            noSelectionText="Standard"
            minimal
            btnMinimal
            fillWidth
            label="Time Format"
          />
          {displayOptions.timeFormat?.id === TIME_DIFF_FORMATS.CUSTOM.id && (
            <InputWithBlurSave
              containerClassName={classes.input}
              initialValue={displayOptions.timeCustomFormat}
              onNewValueSubmitted={(newValue: string) =>
                addFieldsToDisplayOptions({ format: currentFormat, timeCustomFormat: newValue })
              }
              placeholder="DD days HH:mm:ss"
              label="Custom Time Format"
            />
          )}
        </>
      )}

      {currentFormat === NumberDisplayFormat.PERCENT && (
        <GoalInputRow
          type="max"
          updateExactValue={(newValue) => {
            addFieldsToDisplayOptions({ format: currentFormat, goal: newValue });
          }}
          updateUsesColumnCalculation={(newValue) => {
            addFieldsToDisplayOptions({ format: currentFormat, useColumnMaxForGoal: newValue });
          }}
          exactValue={goal}
          usesColumnCalculation={useColumnMaxForGoal}
          label="Denominator"
        />
      )}
      <ToggleButtonGroup fillWidth className={classes.input} label="Alignment">
        {ORDERED_ALIGNMENTS.map((alignment) => (
          <ToggleButton
            key={`alignment-${alignment.id}-${column.name}`}
            active={(currentAlignment || TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT) === alignment.id}
            icon={alignment.icon as IconName}
            onClick={() => {
              addFieldsToDisplayOptions({ alignment: alignment.id, format: currentFormat });
            }}
          />
        ))}
      </ToggleButtonGroup>
    </>
  );
}
