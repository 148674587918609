/** @format */
import React from 'react';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Intent } from '@blueprintjs/core';
import { AppToaster } from 'toaster';

import CalloutLink from 'shared/CalloutLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `125px ${theme.spacing(8)}px`,
  },
  subsection: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: theme.spacing(8),
  },
  membersHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  apiTokenBox: {
    border: '1px solid',
    borderColor: theme.palette.ds.grey500,
    height: '32px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  documentationBox: {
    marginTop: theme.spacing(4),
  },
  apiTokenText: {
    paddingLeft: theme.spacing(3),
  },
  copy: {
    color: theme.palette.ds.blue,
    fontWeight: 500,
    paddingRight: theme.spacing(3),
  },
}));

type Props = ReturnType<typeof mapStateToProps>;

function SettingsAPITokensSection(props: Props) {
  const { currentUser } = props;
  const classes = useStyles();
  const apiToken = currentUser.team ? currentUser.team.api_token : '';

  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiToken);
    AppToaster.show({
      message: 'API Token Copied To Clipboard',
      icon: 'endorsed',
      timeout: 3000,
      intent: Intent.SUCCESS,
    });
  };
  return (
    <div className={classes.root}>
      <div className={classes.subsection}>API Tokens</div>
      <div onClick={() => copyToClipboard()} className={classes.apiTokenBox}>
        <div className={classes.apiTokenText}>{apiToken}</div>
        <div className={classes.copy}>Copy</div>
      </div>
      <CalloutLink
        className={classes.documentationBox}
        url="https://docs.explo.co/end-user-group-api"
        text="Review the developer documentation to learn more"
      />
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsAPITokensSection);
