/** @format */

import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { Theme } from '@material-ui/core/index';

import SettingHeader from '../SettingHeader';
import DroppableColumnSection from './DroppableColumnSection';
import DroppedColumn from './DroppedColumn';

import { OPERATION_TYPES, Schema, VisualizeTableInstructions } from 'constants/types';
import { updateClonedVisualizationInstructions } from 'actions/dataPanelConfigActions';
import { getChangedSchema, getExcludedColumns } from 'utils/dashboardUtils';
import { getColDisplayText } from './utils';
import { TableColumn } from 'actions/types';

const useStyles = makeStyles((theme: Theme) => ({
  excludedHeader: {
    marginBottom: 1,
  },
  excludedColSection: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  droppedColumn: {
    marginBottom: theme.spacing(1),
  },
}));

export type Props = {
  instructions: VisualizeTableInstructions;
  schema: Schema;
  chartType: string;
  loading?: boolean;
};

export default function TableConfig({ instructions, chartType, loading, schema }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [excludedCollapsed, setExcludedCollapsed] = useState<boolean>(true);

  const changedSchema = useMemo(() => getChangedSchema(schema, instructions), [
    schema,
    instructions,
  ]);

  const excludedColumns = useMemo(() => getExcludedColumns(schema, instructions), [
    schema,
    instructions,
  ]);

  const updateInstructions = (
    modifyInstructions: (instructions: VisualizeTableInstructions) => void,
  ) =>
    updateClonedVisualizationInstructions(
      instructions,
      chartType as OPERATION_TYPES,
      dispatch,
      modifyInstructions,
    );

  const addColumnToTable = (col: TableColumn) => {
    updateInstructions((instructions) => {
      const schemaChangeIndex = instructions.changeSchemaList.findIndex(
        (change) => change.col === col.name,
      );
      if (schemaChangeIndex !== -1) {
        instructions.changeSchemaList[schemaChangeIndex].keepCol = true;
      } else {
        instructions.changeSchemaList.push({
          col: col.name,
          keepCol: true,
          newColName: col.name,
        });
      }
    });
  };

  return (
    <div>
      <SettingHeader name="Columns included" />
      <DroppableColumnSection
        columns={changedSchema.map((column) => ({ column }))}
        onColDropped={addColumnToTable}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onColOptionChanged={() => {}}
        onRemoveCol={(col) => {
          updateInstructions((instructions) => {
            const schemaChangeIndex = instructions.changeSchemaList.findIndex(
              (change) => change.col === col,
            );
            if (schemaChangeIndex !== -1) {
              instructions.changeSchemaList[schemaChangeIndex].keepCol = false;
            } else {
              instructions.changeSchemaList.push({
                col: col ?? '',
                keepCol: false,
                newColName: col ?? '',
              });
            }
          });
        }}
        disableEdits={loading}
      />
      <SettingHeader
        className={classes.excludedHeader}
        name="Columns excluded"
        actionBtnConfig={{
          actionBtnIcon: excludedCollapsed ? 'caret-down' : 'caret-up',
          onActionBtnClicked: () => setExcludedCollapsed(!excludedCollapsed),
        }}
      />
      {!excludedCollapsed && (
        <div className={classes.excludedColSection}>
          {excludedColumns.map((col) => (
            <DroppedColumn
              key={`table-excluded-column-${col.name}`}
              className={classes.droppedColumn}
              columnName={getColDisplayText({ column: col })}
              icon="plus"
              onIconClick={() => addColumnToTable(col)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
