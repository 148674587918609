/** @format */

import _ from 'underscore';
import { Action } from 'reducers/rootReducer';
import { ACTION, Dataset } from 'actions/types';
import { ErrorResponse } from 'actions/responseTypes';
export interface DatasetsReducer {
  loading: boolean;
  fetchingPreview: boolean;
  datasets: { [datasetId: string]: Dataset };
  error?: ErrorResponse;
}

const datasetsReducerInitialState: DatasetsReducer = {
  loading: false,
  fetchingPreview: false,
  datasets: {},
};

export default (
  state: DatasetsReducer = datasetsReducerInitialState,
  action: Action,
): DatasetsReducer => {
  const newState = Object.assign({}, state);
  const { payload } = action;

  switch (action.type) {
    case 'FETCH_DATASETS_REQUEST':
      newState.loading = true;
      return newState;

    case 'FETCH_DATASETS_SUCCESS':
      newState.loading = false;
      newState.datasets = _.indexBy(payload.datasets, 'id');
      newState.error = undefined;
      return newState;

    case 'FETCH_DATASETS_ERROR':
      newState.loading = false;
      newState.error = payload.errorData;
      return newState;

    case `${ACTION.FETCH_DATASET_PREVIEW}_REQUEST`: {
      newState.fetchingPreview = true;
      const fetchingDataset = newState.datasets[payload.postData.dataset.id];
      if (fetchingDataset) {
        fetchingDataset.schema = undefined;
        fetchingDataset._rows = undefined;
        fetchingDataset._loading = true;
      }
      return newState;
    }
    case `${ACTION.FETCH_DATASET_PREVIEW}_ERROR`: {
      newState.fetchingPreview = false;
      const fetchedErrorDataset = newState.datasets[payload.postData.dataset.id];
      if (fetchedErrorDataset) {
        fetchedErrorDataset._error = payload.dataset_preview._error;
        fetchedErrorDataset._loading = false;
      }
      return newState;
    }
    case `${ACTION.FETCH_DATASET_PREVIEW}_SUCCESS`: {
      newState.fetchingPreview = false;
      const fetchedDataset = newState.datasets[payload.postData.dataset.id];
      if (fetchedDataset) {
        fetchedDataset._error = undefined;
        fetchedDataset.schema = payload.dataset_preview.schema;
        fetchedDataset._rows = payload.dataset_preview._rows;
        fetchedDataset._loading = false;
      }
      return newState;
    }
    case `${ACTION.FETCH_DATASET_ROW_COUNT}_SUCCESS`: {
      const fetchedRowCountDataset = newState.datasets[payload.postData.dataset.id];
      if (fetchedRowCountDataset) {
        fetchedRowCountDataset._total_row_count = payload._total_row_count;
      }
      return newState;
    }
    default:
      return state;
  }
};
