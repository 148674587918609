/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';
import { Spinner } from '@blueprintjs/core';

import ProgressBar from 'components/ProgressBar';

import { NumberFormatOption } from 'constants/types';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { formatValue } from './charts/utils';
import _ from 'underscore';

const TITLE_HEIGHT = 42;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
  },
  titleContainer: {
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px`,
    paddingBottom: 0,

    '&.CENTER_ALIGN': {
      textAlign: 'center',
    },
    '&.RIGHT_ALIGN': {
      textAlign: 'right',
    },
  },
  title: {
    color: '#AAAAAA',
    fontWeight: 500,
    fontSize: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  progressGoal: {
    fontWeight: 400,
    fontSize: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.ds.grey800,
  },
  progressBar: {
    height: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  numberContainer: {
    padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    height: `calc(100% - ${TITLE_HEIGHT}px)`,
    alignItems: 'center',
    fontSize: 36,

    '&.CENTER_ALIGN': {
      textAlign: 'center',
    },
    '&.RIGHT_ALIGN': {
      textAlign: 'right',
    },
  },
}));

type PassedProps = {
  title?: string;
  number: number;
  numberFormat: NumberFormatOption;
  units?: string;
  multiplier: number;
  alignment?: string;
  decimalPlaces: number;
  loading?: boolean;
  isProgress?: boolean;
  progressGoal?: number;
  progressColor: string;
};

type Props = PassedProps;
type PropsWithStyles = Props & { classes: ReturnType<typeof useStyles> };

export default function SingleNumberDisplay(props: Props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {Title({ ...props, classes })}
      {Number({ ...props, classes })}
    </div>
  );
}

const Title = ({ title, alignment, classes }: PropsWithStyles) => {
  if (title === undefined) return <></>;

  return (
    <div className={cx(classes.titleContainer, alignment)}>
      <div className={cx(classes.title, GLOBAL_STYLE_CLASSNAMES.text.h3.base)}>{title}</div>
    </div>
  );
};

const Number = (props: PropsWithStyles) => {
  const { title, alignment, loading, classes } = props;
  if (loading) {
    return (
      <div className={cx(classes.numberContainer, { noTitle: title === undefined }, alignment)}>
        <Spinner size={30} />
      </div>
    );
  }

  return (
    <div
      className={cx(
        classes.numberContainer,
        { noTitle: title === undefined },
        GLOBAL_STYLE_CLASSNAMES.text.primaryColor,
        alignment,
      )}>
      <b>{FormattedNumber(props)}</b>
      <span className={classes.progressGoal}>
        {' '}
        {ProgressGoalValue({ ...props, classes })} {Units(props)}
      </span>

      {ProgressBarContainer({ ...props, classes })}
    </div>
  );
};

const ProgressGoalValue = (props: PropsWithStyles) => {
  const { progressGoal, isProgress, classes } = props;

  if (!isProgress) return <></>;

  return <span className={classes.progressGoal}>/{progressGoal || 0} </span>;
};

const ProgressBarContainer = (props: PropsWithStyles) => {
  const { number, progressGoal, multiplier, isProgress } = props;
  if (!isProgress) return <></>;

  const denominator = Math.max(progressGoal || number, 1);
  const finalRatio = (number * multiplier) / denominator;
  return <ProgressBar value={progressGoal ? finalRatio : 0} color={props.progressColor} />;
};

const FormattedNumber = ({ number, multiplier, numberFormat, decimalPlaces }: Props) =>
  _.isNumber(number)
    ? formatValue({
        value: number,
        decimalPlaces,
        formatId: numberFormat.id,
        multiplier,
        hasCommas: true,
      })
    : 'No data';

const Units = (props: Props) => {
  const { units } = props;

  if (units === undefined) return;

  return units;
};
