/** @format */

import React, { RefObject } from 'react';
import _ from 'underscore';
import Chart from 'chart.js';

import { COLOR_LIST } from 'constants/colorConstants';
import { titleCase, convertHexToRGBA } from 'utils/graphUtils';
import { numberWithCommas } from 'utils/general';

// import {
//   chartConfigIsSame,
// } from 'utils/graphUtils';

type Props = {
  dataPanelTemplateId: string;
  chartType: string;
  legendLocation: string;
  textColor: string;
  labels: any[];
  values: any[];
};

class PieChart extends React.PureComponent<Props> {
  chartRef: RefObject<any> = React.createRef();
  chart: any = null;

  UNSAFE_componentWillMount() {
    Chart.pluginService.register({
      afterDraw: function (chart: any) {
        if (chart.config.type !== 'pie' && chart.config.type !== 'doughnut') {
          return;
        }
        if (chart.tooltip._active && chart.tooltip._active.length && chart.scales['left-axis']) {
          const activePoint = chart.controller.tooltip._active[0];
          const ctx = chart.ctx;
          const x = activePoint.tooltipPosition().x;
          const topY = chart.scales['left-axis'].top;
          const bottomY = chart.scales['left-axis'].bottom;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = 'rgba(155, 155, 155, 0.5)';
          ctx.stroke();
          ctx.restore();
        }
      },
    });
  }

  componentDidMount() {
    const myChartRef = this.chartRef.current.getContext('2d');

    this.chart = new Chart(myChartRef, this.getChartConfig());
  }

  componentDidUpdate(oldProps: Props) {
    const { labels, values, chartType, legendLocation } = this.props;
    if (
      _.isEqual(labels, oldProps.labels) &&
      _.isEqual(values, oldProps.values) &&
      _.isEqual(chartType, oldProps.chartType) &&
      _.isEqual(legendLocation, oldProps.legendLocation)
    ) {
      return;
    }

    // necessary to change chart type
    // Ref: TU/94dyt2hc
    const myChartRef = this.chartRef.current.getContext('2d');

    // Remove the old chart and all its event handles
    if (this.chart) this.chart.destroy();
    const newConfig = this.getChartConfig();
    this.chart = new Chart(myChartRef, newConfig);
  }

  getChartConfig = (): any => {
    const { chartType, values, labels, legendLocation, textColor } = this.props;
    const numValues = values.length;

    return {
      type: chartType,
      data: {
        datasets: [
          {
            data: values,
            backgroundColor: _.times(numValues, (n) =>
              convertHexToRGBA(COLOR_LIST[n % COLOR_LIST.length].primary, 0.2),
            ),
            hoverBackgroundColor: _.times(
              numValues,
              (n) => COLOR_LIST[n % COLOR_LIST.length].primary,
            ),
            borderColor: _.times(numValues, (n) => COLOR_LIST[n % COLOR_LIST.length].primary),
            labels: _.map(labels, titleCase) as any[],
          },
        ],

        labels: _.map(labels, titleCase) as any[],
      },
      options: {
        legend: {
          position: legendLocation,
          labels: {
            fontColor: textColor,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          caretSize: 0,
          displayColors: false,
          callbacks: {
            label: function (tooltipItem: any, data: any) {
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

              const label = data.datasets[tooltipItem.datasetIndex].labels[tooltipItem.index];
              return ' ' + label + ': ' + numberWithCommas(value);
            },
          },
          mode: 'index',
          intersect: false,
          backgroundColor: 'rgba(0, 0, 0, 0.65)',
          titleFontSize: 14,
          titleMarginBottom: 10,
          bodyFontSize: 14,
          bodySpacing: 8,
        },
      },
    };
  };

  render() {
    const { dataPanelTemplateId } = this.props;
    return <canvas id={`explo-chart-${dataPanelTemplateId}`} ref={this.chartRef} />;
  }
}

export default PieChart;
