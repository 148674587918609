/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';

import GoalLineConfig from './GoalLineConfig';
import CollapsibleGroup from 'shared/CollapsibleGroup';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions, GoalLineFormat } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
  goalLineGroup: {
    margin: `0 ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(3),
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2TwoDimensionChartInstructions;
};

export default function GoalLineGroupConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      {instructions.goalLines?.map((goalLineConfig: GoalLineFormat, index: number) => (
        <CollapsibleGroup
          className={classes.goalLineGroup}
          title={`Goal Line #${index + 1}`}
          onDelete={() => {
            const newInstructions = cloneDeep(instructions);
            if (newInstructions.goalLines) {
              newInstructions.goalLines.splice(index, 1);

              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }
          }}>
          <GoalLineConfig
            config={goalLineConfig}
            updateConfig={(newConfig: GoalLineFormat) => {
              const newInstructions = cloneDeep(instructions);
              if (newInstructions.goalLines) {
                newInstructions.goalLines[index] = newConfig;

                dispatch(
                  updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                );
              }
            }}
          />
        </CollapsibleGroup>
      ))}
    </div>
  );
}
