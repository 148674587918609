/** @format */

import { createAction } from '@reduxjs/toolkit';
import { defineAPIAction } from 'actions/actionUtils';
import { GlobalStyleConfig } from 'globalStyles/types';
import { SaveStyleConfigV2Response, FetchGoogleFontsResponse } from './responseTypes';
import { ACTION } from './types';

export const updateGlobalStyles = createAction<{ newConfig: GlobalStyleConfig }>(
  ACTION.UPDATE_GLOBAL_STYLES,
);

export const {
  actionFn: saveGlobalStyles,
  successAction: saveGlobalStylesSuccess,
} = defineAPIAction<SaveStyleConfigV2Response>(
  ACTION.SAVE_GLOBAL_STYLES,
  'teams',
  'save_style_config_v2',
  'POST',
);

export const resetGlobalStyles = createAction(ACTION.RESET_GLOBAL_STYLES);

export const {
  actionFn: fetchGoogleFonts,
  successAction: fetchGoogleFontsSuccess,
} = defineAPIAction<FetchGoogleFontsResponse>(ACTION.FETCH_GOOGLE_FONTS, 'google_fonts', '', 'GET');
