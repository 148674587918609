/** @format */

import React from 'react';
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

import { Classes, FormGroup } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import DropdownSelect from 'shared/DropdownSelect';
import Button from 'shared/Button';
import { DataSource, EndUserGroup, ParentSchema } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import Modal from 'components/core/Modal';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '600px',
    },
    label: {
      color: theme.palette.ds.grey900,
      fontSize: 12,
      fontWeight: 400,
      marginTop: theme.spacing(3),
    },
    headerMessage: {
      marginTop: theme.spacing(-5),
    },
    schemaContainer: {
      display: 'flex',
      color: theme.palette.ds.grey600,
      backgroundColor: theme.palette.ds.grey200,
      paddingBottom: `${theme.spacing(3)}px`,
      marginTop: `${theme.spacing(3)}px`,
      borderRadius: theme.spacing(1),
      flexDirection: 'column',
    },
    schemaRowContainer: {
      padding: `0 ${theme.spacing(3)}px`,
      flexBasis: '100%',
    },
    formContainer: {
      marginBottom: 0,
    },
  });

type PassedProps = {
  modalOpen: boolean;
  closeModal: () => void;
  modalTitle: string;
  buttonName: string;
  onSubmit: (name: string, provided_id: string, mapping: Record<string, string>) => void;
  dataSources: DataSource[];
  parentSchema: ParentSchema[];
  selectedGroup?: EndUserGroup;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  PassedProps &
  WithStyles<typeof styles>;

const CustomersModal = ({
  classes,
  selectedGroup,
  dataSources,
  parentSchema,
  onSubmit,
  modalOpen,
  closeModal,
  modalTitle,
  buttonName,
  endUserGroupsLoading,
}: Props) => {
  const [editorGroup, setEditorGroup] = React.useState({
    name: selectedGroup?.name || '',
    provided_id: selectedGroup?.provided_id || '',
    mapping: selectedGroup?.parent_schema_datasource_mapping || {},
  });

  const isFormReadyToSubmit = Boolean(
    editorGroup.name &&
      editorGroup.provided_id &&
      editorGroup.name.trim() &&
      editorGroup.provided_id.trim(),
  );
  const onSubmitInternal = (endUserGroup: {
    name: string;
    provided_id: string;
    mapping: Record<string, string>;
  }) => {
    if (isFormReadyToSubmit) {
      onSubmit(endUserGroup.name, endUserGroup.provided_id, endUserGroup.mapping);
    }
  };
  const onCancelClick = (schemaId: number) => {
    const newMapping = cloneDeep(editorGroup.mapping);
    delete newMapping[schemaId];
    setEditorGroup({
      ...editorGroup,
      mapping: newMapping,
    });
  };

  const dataSourcesBySchemaId = _.groupBy(dataSources, (datasource) => datasource.parent_schema_id);

  const renderSchemasSection = (schema: ParentSchema) => {
    const dataSourcesForSchema = dataSourcesBySchemaId[schema.id];

    if (!dataSourcesForSchema) return;

    const selectedDataSource =
      schema.id in editorGroup.mapping
        ? _.find(
            dataSourcesForSchema,
            (dataSource) => String(dataSource.id) === editorGroup.mapping[schema.id],
          )
        : undefined;

    return (
      <div className={classes.schemaRowContainer} key={_.uniqueId('schema-section-')}>
        <div className={classes.label}>{schema.name}</div>
        <DropdownSelect
          minimal
          fillWidth
          showIcon
          showCancelBtn
          noSelectionText="Select Data Source"
          selectedItem={
            selectedDataSource
              ? {
                  id: String(selectedDataSource.id),
                  name: selectedDataSource.name,
                }
              : undefined
          }
          onChange={(item) =>
            setEditorGroup({
              ...editorGroup,
              mapping: { ...editorGroup.mapping, [schema.id]: item.id },
            })
          }
          onCancelClick={() => onCancelClick(schema.id)}
          options={dataSourcesForSchema.map((dataSource) => ({
            id: String(dataSource.id),
            name: dataSource.name,
          }))}
        />
      </div>
    );
  };

  return (
    <Modal modalOpen={modalOpen} onClose={closeModal} title={modalTitle} className={classes.root}>
      <div className={Classes.DIALOG_BODY}>
        {/* this div is actually important to styling regardless of whether we display text in it */}
        <div className={classes.headerMessage}>
          {!selectedGroup &&
            'If you’ve connected to our API then new customers will be added automatically.'}
        </div>
        <FormGroup className={classes.formContainer} labelFor="text-input">
          <div className={classes.label}>Name</div>
          <InputGroup
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEditorGroup({ ...editorGroup, name: e.target.value });
            }}
            value={editorGroup.name}
            placeholder="Explo Co."
            onKeyPress={(e) => {
              if (e.key === 'Enter' && isFormReadyToSubmit) {
                onSubmitInternal(editorGroup);
              }
            }}
          />
        </FormGroup>
        <FormGroup className={classes.formContainer} labelFor="text-input">
          <div className={classes.label}>End User Group ID</div>
          <InputGroup
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEditorGroup({ ...editorGroup, provided_id: e.target.value });
            }}
            value={editorGroup.provided_id}
            placeholder="12a34b56"
            onKeyPress={(e) => {
              if (e.key === 'Enter' && isFormReadyToSubmit) {
                onSubmitInternal(editorGroup);
              }
            }}
          />
        </FormGroup>
        <div className={classes.schemaContainer}>
          {parentSchema.map((schema) => renderSchemasSection(schema))}
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            disabled={!isFormReadyToSubmit}
            loading={endUserGroupsLoading}
            onClick={() => {
              isFormReadyToSubmit && onSubmitInternal(editorGroup);
            }}
            text={buttonName}
            type="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  endUserGroupsLoading: createLoadingSelector([ACTION.CREATE_END_USER_GROUP], false)(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomersModal));
