/** @format */

import React from 'react';
import _ from 'underscore';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { cloneDeep } from 'lodash';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import DropdownSelect from 'shared/DropdownSelect';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';
import NavTabs from 'components/core/navTabs';
import InputConfig from 'pages/dataPanelEditorPage/visualizeSections/shared/inputConfig';

import { DropdownDashboardElemConfig, DashboardElementConfig } from 'types/dashboardTypes';
import { Dataset } from 'actions/types';
import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';
import { SelectedDropdownInputItem } from 'constants/types';

export const INPUT_TYPES = {
  MANUAL: {
    id: 'MANUAL',
    name: 'Manual',
  },
  QUERY: {
    id: 'QUERY',
    name: 'Query',
  },
};

const styles = (theme: Theme) => ({
  root: {},
  sideTextDisplay: {
    color: '#696969',
    fontSize: 'xx-small',
  },
  configInput: {
    marginTop: theme.spacing(3),
  },
  timeSelectConfig: {
    color: theme.palette.ds.grey700,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  toggleTabContainer: {
    height: 40,
    borderBottom: `1px solid ${theme.palette.ds.grey200}`,
    borderTop: `1px solid ${theme.palette.ds.grey200}`,
    margin: `0 -${theme.spacing(6)}px`,
    marginTop: theme.spacing(3),
  },
  toggleBtn: {
    width: '50%',
  },
});

type PassedProps = {
  config: DropdownDashboardElemConfig;
  datasets: Record<string, Dataset>;
  updateConfig: (newConfig: DashboardElementConfig) => void;
  isSwitch?: boolean;
};

type Props = RouteComponentProps & WithStyles<typeof styles> & PassedProps;

type State = {
  selectedTabId: string;
};

class DropdownElementConfigPanel extends React.Component<Props, State> {
  state: State = {
    selectedTabId: this.props.config.valuesConfig.valuesSource || INPUT_TYPES.MANUAL.id,
  };

  render() {
    const { classes, isSwitch } = this.props;

    return (
      <div className={classes.root}>
        {this.renderLabelInput()}
        {!isSwitch && this.renderPlaceholderText()}
        {!isSwitch && this.renderSearchableFlag()}
        {!isSwitch && this.renderCancelableFlag()}
        {!isSwitch && this.renderDisableOnNoItemsFlag()}
        {this.renderInputTypeTabs()}
        {this.renderConfigBody()}
      </div>
    );
  }

  renderInputTypeTabs = () => {
    const { classes } = this.props;
    const { selectedTabId } = this.state;
    return (
      <NavTabs
        className={classes.toggleTabContainer}
        tabClassName={classes.toggleBtn}
        tabs={[INPUT_TYPES.MANUAL, INPUT_TYPES.QUERY]}
        selectedTabId={selectedTabId}
        onTabSelect={(tabId) => this.switchInputTab(tabId)}
      />
    );
  };

  switchInputTab = (newTabId: string) => {
    const { config, updateConfig } = this.props;

    config.valuesConfig.valuesSource = newTabId;
    updateConfig(config);

    this.setState({ selectedTabId: newTabId });
  };

  renderConfigBody = () => {
    const { selectedTabId } = this.state;

    switch (selectedTabId) {
      case INPUT_TYPES.MANUAL.id:
        return this.renderManualConfig();
      case INPUT_TYPES.QUERY.id:
        return this.renderQueryConfig();
    }
  };

  renderManualConfig = () => {
    return (
      <div>
        {this.renderValuesInput()}
        {this.renderSideMessage()}
        {this.renderDisplaysInput()}
        {this.renderDefaultValueInput()}
      </div>
    );
  };

  renderLabelInput = () => {
    const { config, classes, updateConfig } = this.props;
    return (
      <>
        <InputWithBlurSave
          containerClassName={classes.configInput}
          initialValue={config.label}
          onNewValueSubmitted={(newValue: string) => {
            config.label = newValue;
            updateConfig(config);
          }}
          placeholder="Enter an input label"
          label="Label"
        />
      </>
    );
  };

  renderSideMessage = () => {
    const { isSwitch, classes } = this.props;
    let placeholderText = '';
    if (isSwitch) {
      placeholderText = 'A maximum of 5 options will be shown';
    }
    return <div className={classes.sideTextDisplay}>{placeholderText}</div>;
  };

  renderValuesInput = () => {
    const { isSwitch, config, classes, updateConfig } = this.props;
    const valuesArrStr = config.valuesConfig.manualValues || '';

    let error;
    try {
      if (valuesArrStr) {
        const valuesArr = JSON.parse(valuesArrStr);
        if (!Array.isArray(valuesArr)) error = 'Must be valid javascript array.';
      }
    } catch {
      error = 'Must be valid javascript array.';
    }

    let placeholderText = '[1, 2, 3]';
    if (isSwitch) {
      placeholderText = '["day", "week", "month"]';
    }
    return (
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={config.valuesConfig.manualValues || ''}
        onNewValueSubmitted={(newValue: string) => {
          config.valuesConfig.manualValues = newValue;
          updateConfig(config);
        }}
        placeholder={placeholderText}
        label="Values"
        errorText={error}
      />
    );
  };

  renderDefaultValueInput = () => {
    const { classes, config, updateConfig } = this.props;

    return (
      <InputConfig
        className={classes.configInput}
        value={config.valuesConfig.manualDefaultValue || ''}
        onUpdate={(newValue: string) => {
          const newConfig = cloneDeep(config);
          newConfig.valuesConfig.manualDefaultValue = newValue;
          updateConfig(newConfig);
        }}
        label="Default Value"
        placeHolder=""
      />
    );
  };

  renderDisplaysInput = () => {
    const { isSwitch, config, classes, updateConfig } = this.props;
    const displaysArrStr = config.valuesConfig.manualDisplays || '';

    let error;
    try {
      if (displaysArrStr) {
        const displaysArr = JSON.parse(displaysArrStr);
        if (!Array.isArray(displaysArr)) error = 'Must be valid javascript array.';
      }
    } catch {
      error = 'Must be valid javascript array.';
    }

    let placeholderText = '["One", "Two", "Three"]';
    if (isSwitch) {
      placeholderText = '["Day", "Week", "Month"]';
    }

    return (
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={config.valuesConfig.manualDisplays || ''}
        onNewValueSubmitted={(newValue: string) => {
          config.valuesConfig.manualDisplays = newValue;
          updateConfig(config);
        }}
        placeholder={placeholderText}
        label="Displays"
        errorText={error}
      />
    );
  };

  renderPlaceholderText = () => {
    const { config, classes, updateConfig } = this.props;
    return (
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={config.placeholderText}
        onNewValueSubmitted={(newValue: string) => {
          config.placeholderText = newValue;
          updateConfig(config);
        }}
        placeholder="Enter a placeholder"
        label="Placeholder"
      />
    );
  };

  renderSearchableFlag = () => {
    const { config, classes, updateConfig } = this.props;

    return (
      <SwitchInput
        className={classes.timeSelectConfig}
        switchOn={!config.disableSearch}
        onChange={() => {
          config.disableSearch = !config.disableSearch;
          updateConfig(config);
        }}
        label="Enable filtering"
      />
    );
  };

  renderCancelableFlag = () => {
    const { config, classes, updateConfig } = this.props;

    return (
      <SwitchInput
        className={classes.timeSelectConfig}
        switchOn={!config.disableCancel}
        onChange={() => {
          config.disableCancel = !config.disableCancel;
          updateConfig(config);
        }}
        label="Enable clearing selection"
      />
    );
  };

  renderDisableOnNoItemsFlag = () => {
    const { config, classes, updateConfig } = this.props;

    return (
      <SwitchInput
        className={classes.timeSelectConfig}
        switchOn={config.disableOnNoItems}
        onChange={() => {
          config.disableOnNoItems = !config.disableOnNoItems;
          updateConfig(config);
        }}
        label="Disable dropdown if there are no items"
      />
    );
  };

  renderQueryConfig = () => {
    const { isSwitch } = this.props;
    return (
      <div>
        {this.renderDatasetSelector()}
        {this.renderValueColumnSelector()}
        {this.renderSideMessage()}
        {!isSwitch && this.renderDisplayColumnSelector()}
        {this.renderQueryDefaultValueToggle()}
      </div>
    );
  };

  renderDatasetSelector = () => {
    const { classes, config, updateConfig, datasets } = this.props;

    const savedDatasets = Object.values(datasets).filter((dataset) => !!dataset.schema);
    const selectedDataset =
      config.valuesConfig.queryTable && datasets[config.valuesConfig.queryTable.id];

    return (
      <div className={classes.configInput}>
        <DropdownSelect
          selectedItem={
            selectedDataset && {
              id: selectedDataset.id,
              name: selectedDataset.table_name || 'Untitled',
            }
          }
          onChange={(item: SelectedDropdownInputItem) => {
            const newConfig = {
              ...config,
              valuesConfig: {
                ...config.valuesConfig,
                queryValueColumn: undefined,
                queryDisplayColumn: undefined,
                queryTable: {
                  id: item.id,
                  name: item.name,
                },
              },
            };

            updateConfig(newConfig);
          }}
          filterable={false}
          options={savedDatasets.map((dataset: Dataset) => ({
            id: String(dataset.id),
            name: dataset.table_name || 'Untitled',
          }))}
          noSelectionText="Select dataset"
          minimal
          fillWidth
          showCancelBtn
          label="Dataset"
          onCancelClick={() => {
            const newConfig = {
              ...config,
              valuesConfig: {
                ...config.valuesConfig,
                queryValueColumn: undefined,
                queryDisplayColumn: undefined,
                queryTable: {
                  id: undefined,
                  name: undefined,
                },
              },
            };
            updateConfig(newConfig);
          }}
        />
      </div>
    );
  };

  renderValueColumnSelector = () => {
    const { classes, config, updateConfig, datasets } = this.props;

    const selectedDataset =
      config.valuesConfig.queryTable && datasets[config.valuesConfig.queryTable.id];
    const schemaByName =
      selectedDataset && selectedDataset.schema ? _.indexBy(selectedDataset.schema, 'name') : {};
    const selectedColumn =
      config.valuesConfig.queryValueColumn &&
      schemaByName[config.valuesConfig.queryValueColumn.name];

    return (
      <div className={classes.configInput}>
        <DropdownSelect
          selectedItem={
            selectedColumn && {
              id: selectedColumn.name,
              name: selectedColumn.name,
              icon: SCHEMA_DATA_TYPES_BY_ID[selectedColumn.type].icon,
            }
          }
          onChange={(item: SelectedDropdownInputItem) => {
            config.valuesConfig.queryValueColumn = item;
            updateConfig(config);
          }}
          showIcon
          filterable={false}
          disabled={selectedDataset === undefined}
          options={
            selectedDataset && selectedDataset.schema
              ? selectedDataset.schema.map((col) => ({
                  name: col.name,
                  id: col.name,
                  icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
                }))
              : []
          }
          noSelectionText="Select column"
          minimal
          fillWidth
          showCancelBtn
          label="Values"
          onCancelClick={() => {
            config.valuesConfig.queryValueColumn = undefined;
            updateConfig(config);
          }}
        />
      </div>
    );
  };

  renderDisplayColumnSelector = () => {
    const { classes, config, updateConfig, datasets } = this.props;

    const selectedDataset =
      config.valuesConfig.queryTable && datasets[config.valuesConfig.queryTable.id];
    const schemaByName =
      selectedDataset && selectedDataset.schema ? _.indexBy(selectedDataset.schema, 'name') : {};
    const selectedColumn =
      config.valuesConfig.queryDisplayColumn &&
      schemaByName[config.valuesConfig.queryDisplayColumn.name];

    return (
      <div className={classes.configInput}>
        <DropdownSelect
          selectedItem={
            selectedColumn && {
              id: selectedColumn.name,
              name: selectedColumn.name,
              icon: SCHEMA_DATA_TYPES_BY_ID[selectedColumn.type].icon,
            }
          }
          onChange={(item: SelectedDropdownInputItem) => {
            config.valuesConfig.queryDisplayColumn = item;
            updateConfig(config);
          }}
          showIcon
          filterable={false}
          disabled={selectedDataset === undefined}
          options={
            selectedDataset && selectedDataset.schema
              ? selectedDataset.schema.map((col) => ({
                  name: col.name,
                  id: col.name,
                  icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
                }))
              : []
          }
          noSelectionText="Select column"
          minimal
          fillWidth
          showCancelBtn
          label="Displays (optional)"
          onCancelClick={() => {
            config.valuesConfig.queryDisplayColumn = undefined;
            updateConfig(config);
          }}
        />
      </div>
    );
  };

  renderQueryDefaultValueToggle = () => {
    const { isSwitch, classes, config, updateConfig } = this.props;
    const placeholderText = isSwitch
      ? 'Default switch to first value'
      : 'Default dropdown to first value';
    return (
      <SwitchInput
        className={classes.timeSelectConfig}
        switchOn={config.valuesConfig.queryDefaultFirstValue}
        onChange={() => {
          config.valuesConfig.queryDefaultFirstValue = !config.valuesConfig.queryDefaultFirstValue;
          updateConfig(config);
        }}
        label={placeholderText}
      />
    );
  };
}

export default withRouter(withStyles(styles)(DropdownElementConfigPanel));
