/** @format */

import { createMuiTheme } from '@material-ui/core/styles';

interface CustomShadows {
  basic: string;
  sideNav: string;
}

export interface DesignSystemColors {
  blue: '#0069ED';
  lightBlue: '#EBF3FE';
  red: '#E51D00';
  lightRed: '#FDEDEB';
  green: '#7ACC39';
  lightGreen: '#F2FAEB';
  yellow: '#FCD508';
  black: '#212121';
  white: '#FFFFFF';

  grey100: '#FAFAFA';
  grey200: '#F6F6F6';
  grey300: '#EEEEEE';
  grey400: '#E0E0E0';
  grey500: '#BDBDBD';
  grey600: '#9E9E9E';
  grey700: '#757575';
  grey800: '#616161';
  grey900: '#424242';

  hovered: {
    grey100: '#F6F6F6';
    grey200: '#F2F2F2';
    grey300: '#E6E6E6';
    grey400: '#D8D8D8';
    grey500: '#B7B7B7';
    grey800: '#5B5B5B';
    blue: '#0460D5';
    lightBlue: '#E2EDFD';
    green: '#75C238';
    lightGreen: '#EDF8E4';
    red: '#D51D03';
    lightRed: '#FCE5E2';
    white: '#FAFAFA';
  };

  pressed: {
    grey100: '#F1F1F1';
    grey200: '#EDEDED';
    grey300: '#DEDEDE';
    grey400: '#D1D1D1';
    grey500: '#B1B1B1';
    grey800: '#575757';
    blue: '#055DCC';
    lightBlue: '#D8E8FD';
    green: '#71BB37';
    lightGreen: '#E8F6DD';
    red: '#CD1D04';
    lightRed: '#FBDCD8';
    white: '#F6F6F6';
  };
}

interface EnvTagColors {
  blue: '#0069ED';
  green: '#7ACC39';
  yellow: '#FCD508';
  red: '#E51D00';
  purple: '#967FFF';
  lightPurple: '#7E9AFF';
}

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteOptions {
    ds: DesignSystemColors;
    envTagColors: EnvTagColors;
  }

  interface Palette {
    ds: DesignSystemColors;
    envTagColors: EnvTagColors;
  }
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface ThemeOptions {
    customShadows: CustomShadows;
  }

  interface Theme {
    customShadows: CustomShadows;
  }
}

const theme = createMuiTheme({
  palette: {
    ds: {
      blue: '#0069ED',
      lightBlue: '#EBF3FE',
      red: '#E51D00',
      lightRed: '#FDEDEB',
      green: '#7ACC39',
      lightGreen: '#F2FAEB',
      yellow: '#FCD508',
      black: '#212121',
      white: '#FFFFFF',

      grey100: '#FAFAFA',
      grey200: '#F6F6F6',
      grey300: '#EEEEEE',
      grey400: '#E0E0E0',
      grey500: '#BDBDBD',
      grey600: '#9E9E9E',
      grey700: '#757575',
      grey800: '#616161',
      grey900: '#424242',

      hovered: {
        grey100: '#F6F6F6',
        grey200: '#F2F2F2',
        grey300: '#E6E6E6',
        grey400: '#D8D8D8',
        grey500: '#B7B7B7',
        grey800: '#5B5B5B',
        blue: '#0460D5',
        lightBlue: '#E2EDFD',
        green: '#75C238',
        lightGreen: '#EDF8E4',
        red: '#D51D03',
        lightRed: '#FCE5E2',
        white: '#FAFAFA',
      },

      pressed: {
        grey100: '#F1F1F1',
        grey200: '#EDEDED',
        grey300: '#DEDEDE',
        grey400: '#D1D1D1',
        grey500: '#B1B1B1',
        grey800: '#575757',
        blue: '#055DCC',
        lightBlue: '#D8E8FD',
        green: '#71BB37',
        lightGreen: '#E8F6DD',
        red: '#CD1D04',
        lightRed: '#FBDCD8',
        white: '#F6F6F6',
      },
    },
    envTagColors: {
      blue: '#0069ED',
      green: '#7ACC39',
      yellow: '#FCD508',
      red: '#E51D00',
      purple: '#967FFF',
      lightPurple: '#7E9AFF',
    },
  },
  spacing: 4,
  customShadows: {
    basic: '0px 4px 5px rgba(0, 0, 0, 0.25);',
    sideNav:
      '0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);',
  },
});

export default theme;
