/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';

import { DataSource } from 'actions/types';

import DropdownSelect from 'shared/DropdownSelect';
import DeleteConfirmButton from 'components/core/DeleteConfirmButton';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    color: theme.palette.ds.grey600,
    backgroundColor: theme.palette.ds.grey200,
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(5),
    borderRadius: theme.spacing(1),
    alignItems: 'flex-end',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  inputsContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  inputContainer: {
    padding: `0 ${theme.spacing(1)}px`,
    flex: '1 1 0px',
    width: 0,
  },
  deleteContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spacing(18),
  },
  inputTitle: {
    color: theme.palette.ds.grey900,
    fontSize: 12,
  },
}));

export type Props = {
  dataSources: DataSource[];
  schemaName: string;
  onDelete: () => void;
  onSchemaNameChange: (val: string) => void;
  onDefaultDataSourceSelected: (id: number) => void;
};

export default function ManageSchemasSection({
  dataSources,
  schemaName,
  onDelete,
  onSchemaNameChange,
  onDefaultDataSourceSelected,
}: Props) {
  const classes = useStyles();

  const defaultDataSource = _.find(dataSources, (datasource) => datasource.default);

  return (
    <div className={cx(classes.root)}>
      <div className={classes.inputsContainer}>
        <div className={classes.inputContainer}>
          <div className={classes.inputTitle}>Schema name</div>
          <InputWithBlurSave
            hideRightIconInteractions
            onNewValueSubmitted={onSchemaNameChange}
            initialValue={schemaName}
          />
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputTitle}>Default data source</div>
          <DropdownSelect
            minimal
            fillWidth
            showIcon
            usePortal
            noSelectionText="Select a Data Source"
            selectedItem={
              defaultDataSource
                ? {
                    id: String(defaultDataSource.id),
                    name: defaultDataSource.name,
                  }
                : undefined
            }
            onChange={(item) => onDefaultDataSourceSelected(parseInt(item.id))}
            options={dataSources.map((dataSource) => ({
              id: String(dataSource.id),
              name: dataSource.name,
            }))}
          />
        </div>
      </div>
      <div>
        <DeleteConfirmButton onDelete={onDelete} className={classes.deleteContainer} />
      </div>
    </div>
  );
}
