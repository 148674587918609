/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import { RouteComponentProps } from 'react-router';
import { addEmailToWaitlist } from 'utils/landingPageUtils';

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
  },
});

type MatchParams = {
  source: string;
  email: string;
};

type Props = RouteComponentProps<MatchParams> & WithStyles<typeof styles>;

class EmailFormSubmissionRedirectPage extends React.Component<Props> {
  componentDidMount() {
    addEmailToWaitlist(
      this.props.match.params.email,
      () => {
        window.location.href = 'https://form.typeform.com/to/jyPnu9GP';
      },
      () => {
        window.location.href = 'https://explo.co';
      },
      this.props.match.params.source,
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <br></br>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(EmailFormSubmissionRedirectPage));
