/** @format */

import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { Layout } from '@explo-tech/react-grid-layout';

import DashboardDatasetView from 'pages/dashboardPage/DashboardDatasetView';
import PDFExportTableView from './PDFExportTableView/PDFExportTableView';

import { GlobalStylesContext } from 'globalStyles';
import {
  OPERATION_TYPES,
  REPORTED_ANALYTIC_ACTION_TYPES,
  UserTransformedSchema,
} from 'constants/types';
import { ActionFn } from 'actions/actionUtils';
import {
  processUserInputConfig,
  removeUnderscoreFields,
  areRequiredUserInputsSet,
  getSynchronousSecondaryDataInstructions,
  getAsynchronousSecondaryDataInstructions,
} from 'utils/dashboardUtils';
import { DashboardVariableMap } from 'types/dashboardTypes';
import {
  DataPanelTemplate,
  DashboardTemplate,
  Dashboard,
  Dataset,
  AdHocOperationInstructions,
} from 'actions/types';
import { ActionFnArgs } from 'actions/actionUtils';
import {
  FetchDataPanelTemplateData,
  FetchDataPanelTemplateRowCountData,
} from 'actions/responseTypes';
import { isDataPanelReadyToCompute } from 'utils/dataPanelConfigUtils';
import { cloneDeep } from 'lodash';
import { SetDPTLoadingArgs } from 'actions/dashboardV2Actions';
import { ALLOWED_DATA_SOURCES_FOR_DP_DOWNLOADS } from 'constants/dataPanelEditorConstants';

const MAX_NUM_TABLE_ROWS = 200;

const styles = () =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      position: 'relative',
    },
  });

export type PassedProps = {
  ref?: React.Ref<unknown>;
  analyticsEventTracker?: (
    eventName: REPORTED_ANALYTIC_ACTION_TYPES,
    metaData?: Record<string, string | number>,
  ) => void;
  containerRows?: number;
  dashboardTemplate: DashboardTemplate | Dashboard;
  dataPanelTemplate: DataPanelTemplate;
  dataPanelId: string;
  dashboardLayout: Layout[];
  dashboardDatasets: { [datasetId: string]: Dataset };
  dashboardVersionNumber?: number;
  fetchDataPanelRowCount: ActionFn<FetchDataPanelTemplateRowCountData>;
  fetchDataPanelTemplate: ActionFn<FetchDataPanelTemplateData>;
  fetchSecondaryData: ActionFn<FetchDataPanelTemplateData>;
  setDptLoading: (args: SetDPTLoadingArgs) => void;
  userGroupId?: number;
  userGroupName?: string;
  variables: DashboardVariableMap;
  userTransformedSchema: UserTransformedSchema;
  adHocOperationInstructions: AdHocOperationInstructions;
  reportName?: string;
};

type Props = PassedProps & WithStyles<typeof styles>;

/**
 * A standalone layout to render a single chart or data table.
 * Distinct from DashboardLayout which takes in the entire dashboard and renders every
 * element of it.
 */
class ChartLayout extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.fetchDataPanelTemplateData();
  }

  fetchDataPanelTemplateData = () => {
    const {
      dashboardTemplate,
      dataPanelTemplate,
      dashboardDatasets,
      variables,
      setDptLoading,
      adHocOperationInstructions,
    } = this.props;

    const dptConfig = {
      filterOperation: dataPanelTemplate.filter_op,
      sortOperation: dataPanelTemplate.sort_op,
      pivotOperation: dataPanelTemplate.group_by_op,
      visualizeOperation: dataPanelTemplate.visualize_op,
    };

    const dataset = dashboardDatasets[dataPanelTemplate.table_id];

    if (
      !isDataPanelReadyToCompute(dptConfig) ||
      !areRequiredUserInputsSet(variables, dataPanelTemplate)
    ) {
      return setDptLoading({ ids: [dataPanelTemplate.id], loading: false });
    }

    this.fetchDataPanelTemplateWrapper(
      {
        postData: {
          dataset,
          noBaseSchema: true,
          // Since the dataPanelTemplate is stored in the redux store if a data panel template has been loaded before,
          // we need to deep copy it before any modifications, otherwise underscore fields in the redux store will be wiped too.
          config: removeUnderscoreFields(cloneDeep(dataPanelTemplate)),
          dashboardTemplateId: dashboardTemplate.id,
          id: dataPanelTemplate.id,
          sort_info: adHocOperationInstructions.sortInfo,
          filter_info: adHocOperationInstructions.filterInfo,
        },
      },
      (response) => {
        this.fetchDataPanelTemplateSecondaryData(
          {
            ...dataPanelTemplate,
            ...response.data_panel_template,
          },
          true,
        );
      },
    );

    this.fetchDataPanelTemplateSecondaryData(dataPanelTemplate);

    if (dataPanelTemplate.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_TABLE) {
      this.fetchDataPanelRowCountWrapper({
        postData: {
          dataset,
          id: dataPanelTemplate.id,
          config: dataPanelTemplate,
          dashboardTemplateId: dashboardTemplate.id,
          filter_info: adHocOperationInstructions.filterInfo,
        },
      });
    }
  };

  fetchDataPanelTemplateSecondaryData = (
    dataPanelTemplate: DataPanelTemplate,
    afterMainFetch?: boolean,
  ) => {
    const { dashboardDatasets, dashboardTemplate } = this.props;
    const dataset = dashboardDatasets[dataPanelTemplate.table_id];
    const secondaryInstructions = afterMainFetch
      ? getSynchronousSecondaryDataInstructions(dataPanelTemplate)
      : getAsynchronousSecondaryDataInstructions(dataPanelTemplate, dataset);

    secondaryInstructions.forEach((instructions) => {
      this.fetchSecondaryDataWrapper({
        postData: {
          dataset,
          config: removeUnderscoreFields(instructions),
          dashboardTemplateId: dashboardTemplate.id,
          id: dataPanelTemplate.id,
        },
      });
    });
  };

  fetchDataPanelTemplateWrapper = (
    data: ActionFnArgs,
    onSuccess?: ((data: FetchDataPanelTemplateData) => void) | undefined,
  ) => {
    const { fetchDataPanelTemplate, userGroupId, variables } = this.props;

    return fetchDataPanelTemplate(
      {
        ...data,
        postData: {
          ...data.postData,
          config: processUserInputConfig(variables, data.postData?.config as DataPanelTemplate),
          noBaseSchema: true,
          variables,
          end_user_group_id: userGroupId,
          query_limit: MAX_NUM_TABLE_ROWS,
        },
      },
      onSuccess,
    );
  };

  fetchDataPanelRowCountWrapper = (data: ActionFnArgs) => {
    const { fetchDataPanelRowCount, userGroupId, variables } = this.props;

    return fetchDataPanelRowCount({
      ...data,
      postData: {
        ...data.postData,
        variables,
        end_user_group_id: userGroupId,
        rowCount: true,
      },
    });
  };

  fetchSecondaryDataWrapper = (data: ActionFnArgs) => {
    const { fetchSecondaryData, userGroupId, variables } = this.props;

    return fetchSecondaryData({
      ...data,
      postData: {
        ...data.postData,
        variables,
        end_user_group_id: userGroupId,
        noBaseSchema: true,
      },
    });
  };

  render() {
    const {
      classes,
      analyticsEventTracker,
      dataPanelTemplate,
      variables,
      userTransformedSchema,
      reportName,
      dashboardDatasets,
    } = this.props;

    if (
      [OPERATION_TYPES.VISUALIZE_TABLE, OPERATION_TYPES.VISUALIZE_REPORT_BUILDER].includes(
        dataPanelTemplate.visualize_op.operation_type,
      )
    ) {
      return (
        <PDFExportTableView
          tableName={reportName || dataPanelTemplate.name}
          dataPanelTemplate={dataPanelTemplate}
          defaultUserTransformedSchema={userTransformedSchema}
        />
      );
    }

    return (
      <div className={classes.root}>
        <DashboardDatasetView
          isViewOnly
          isInContainer={false}
          variables={variables}
          editBaseOn={false}
          loading={
            dataPanelTemplate._loading === true ||
            dataPanelTemplate._loading === undefined ||
            !!dataPanelTemplate._outstandingSecondaryDataRequests
          }
          secondaryDataLoading={!!dataPanelTemplate._outstandingSecondaryDataRequests}
          error={dataPanelTemplate._error}
          dataPanelTemplateId={dataPanelTemplate.id}
          dataPanelProvidedId={dataPanelTemplate.provided_id || dataPanelTemplate.id}
          previewData={dataPanelTemplate._rows}
          schema={dataPanelTemplate._schema || []}
          panelName={dataPanelTemplate.name || ''}
          sourceDataRowCount={dataPanelTemplate._total_row_count}
          visualizeOperation={dataPanelTemplate.visualize_op}
          onAdHocOperationInstructionsUpdated={() => console.log('Not supported in chart view')}
          onDownloadPanelCsv={() => console.log('Not supported in chart view')}
          onDownloadPanelPdf={() => console.log('Not supported in chart view')}
          adHocOperationInstructions={dataPanelTemplate._adHocOperationInstructions || {}}
          secondaryData={dataPanelTemplate._secondaryData || []}
          canDownloadDataPanel={ALLOWED_DATA_SOURCES_FOR_DP_DOWNLOADS.includes(
            dataPanelTemplate._source_type ?? '',
          )}
          clearDownloadCsvInfo={() => console.log('Not supported in chart view')}
          analyticsEventTracker={analyticsEventTracker}
          isSelected={false}
          defaultUserTransformedSchema={userTransformedSchema}
          dashboardDatasets={dashboardDatasets}
        />
      </div>
    );
  }
}

ChartLayout.contextType = GlobalStylesContext;

export default withStyles(styles)(ChartLayout);
