/** @format */

import { ReportedAnalyticsAction, Team } from 'actions/types';
import axios from 'axios';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';

interface ReportData {
  report_actions: ReportedAnalyticsAction[];
  report_url: string | undefined;
  report_token: string | undefined;
}

interface PostData {
  event_name: REPORTED_ANALYTIC_ACTION_TYPES;
  user_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: any;
  report_data: ReportData;
}

export const sendAnalyticsEvent = (
  eventType: REPORTED_ANALYTIC_ACTION_TYPES,
  userId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: any,
  reportActions: ReportedAnalyticsAction[],
  reportUrl: string | undefined,
  reportToken: string | undefined,
) => {
  const reportData = {
    report_actions: reportActions,
    report_url: reportUrl,
    report_token: reportToken,
  };
  const postData = {
    event_name: eventType,
    user_id: userId,
    properties: properties,
    report_data: reportData,
  };

  const url =
    eventType === REPORTED_ANALYTIC_ACTION_TYPES.DASHBOARD_PAGE_VIEWED ||
    eventType === REPORTED_ANALYTIC_ACTION_TYPES.SHARED_DASHBOARD_PAGE_VIEWED
      ? 'page_event'
      : 'track_event';

  sendAnalyticsEventHelper(url, postData);
};

export const createAnalyticsEventTracker = (
  userId: string,
  dashboardType: string,
  team?: Team,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  analyticsMetadata?: any,
) => {
  return (
    eventName: REPORTED_ANALYTIC_ACTION_TYPES,
    metaData?: Record<string, string | number>,
  ) => {
    const properties = {
      dashboard_type: dashboardType,
      ...analyticsMetadata,
      ...metaData,
    };

    sendAnalyticsEvent(
      eventName,
      userId,
      properties,
      team?.analytics_reporting_actions || [],
      team?.analytics_reporting_url,
      team?.analytics_reporting_token,
    );
  };
};

export const isValidHttpUrl = (s: string) => {
  let url;

  try {
    url = new URL(s);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const sendAnalyticsEventHelper = (url: string, postData: PostData) => {
  axios({
    url: `${process.env.REACT_APP_ANALYTICS_API_URL}analytics/${url}/`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${process.env.REACT_APP_ANALYTICS_API_TOKEN}`,
    },
    data: postData,
  }).catch(() => {
    // we don't need to do anything with this error, but we need to catch
    // so that the page doesn't break if the request fails
    return;
  });
};
