/** @format */
import { Action } from 'reducers/rootReducer';
import { ACTION, DataPanelTemplate } from 'actions/types';
import { DashboardElementConfig } from 'types/dashboardTypes';
import { cloneDeep } from 'lodash';

export interface EditingDashboardElementReducer {
  oldConfig?: DashboardElementConfig;
  oldDataPanel?: DataPanelTemplate;
}

const EditingDashboardElementReducerInitialState: EditingDashboardElementReducer = {};

export default (
  state: EditingDashboardElementReducer = EditingDashboardElementReducerInitialState,
  action: Action,
): EditingDashboardElementReducer => {
  const newState = Object.assign({}, state);
  const { payload } = action;

  switch (action.type) {
    case ACTION.SELECT_DASHBOARD_ELEMENT_TO_EDIT:
      newState.oldConfig = cloneDeep(payload.dashboardElementConfig);
      return newState;
    case `${ACTION.SAVE_DASHBOARD_ELEMENT_CONFIG}_SUCCESS`:
      newState.oldConfig = cloneDeep(payload.postData.config);
      return newState;
    case ACTION.SELECT_DASHBOARD_DPT_TO_EDIT:
      newState.oldDataPanel = cloneDeep(payload.dpt);
      return newState;

    default:
      return state;
  }
};
