/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';

import SwitchInput from 'pages/dataPanelEditorPage/switchInput';
import DropdownSelect from 'shared/DropdownSelect';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { DATE_TYPES, V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { SelectedDropdownInputItem } from 'constants/types';
import {
  DEFAULT_Y_AXIS_FORMAT_INDEX,
  updateYAxisFormat,
} from 'pages/dashboardPage/charts/utils/multiYAxisUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2TwoDimensionChartInstructions;
};

export default function PieChartConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const yAxisFormat = instructions.yAxisFormats?.[DEFAULT_Y_AXIS_FORMAT_INDEX];
  const selectedFormat = yAxisFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;

  return (
    <div className={classes.root}>
      <SwitchInput
        className={classes.configInput}
        switchOn={!instructions.chartSpecificFormat?.pieChart?.hideChartValues}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.chartSpecificFormat = {
            ...newInstructions.chartSpecificFormat,
            pieChart: {
              ...newInstructions.chartSpecificFormat?.pieChart,
              hideChartValues: !newInstructions.chartSpecificFormat?.pieChart?.hideChartValues,
            },
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Show Chart Values"
      />
      <SwitchInput
        className={classes.configInput}
        switchOn={instructions.chartSpecificFormat?.pieChart?.useColorForLabel}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.chartSpecificFormat = {
            ...newInstructions.chartSpecificFormat,
            pieChart: {
              ...newInstructions.chartSpecificFormat?.pieChart,
              useColorForLabel: !newInstructions.chartSpecificFormat?.pieChart?.useColorForLabel,
            },
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="User Color Values for Labels"
      />

      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={String(instructions?.chartSpecificFormat?.pieChart?.maxCategories ?? '')}
        onNewValueSubmitted={(newValue: string) => {
          let intValue: number | undefined = parseInt(newValue);
          intValue = intValue && intValue > 0 ? intValue : undefined;
          const newInstructions = cloneDeep(instructions);

          newInstructions.chartSpecificFormat = {
            ...newInstructions.chartSpecificFormat,
            pieChart: {
              ...newInstructions.chartSpecificFormat?.pieChart,
              maxCategories: intValue,
            },
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Max Categories"
      />

      <DropdownSelect
        containerClassName={classes.configInput}
        selectedItem={selectedFormat}
        onChange={(item: SelectedDropdownInputItem) => {
          const newYAxisFormat = {
            ...yAxisFormat,
            numberFormat: item,
          };
          dispatch(
            updateVisualizeOperation(
              updateYAxisFormat(newYAxisFormat, instructions),
              visualizationType as OPERATION_TYPES,
            ),
          );
        }}
        filterable={false}
        options={[V2_NUMBER_FORMATS.NUMBER, V2_NUMBER_FORMATS.CURRENCY, V2_NUMBER_FORMATS.PERCENT]}
        noSelectionText="Plain Text"
        minimal
        btnMinimal
        fillWidth
        label="Value Format"
      />
      {selectedFormat.id !== V2_NUMBER_FORMATS.PLAIN_TEXT.id && (
        <InputWithBlurSave
          containerClassName={classes.configInput}
          initialValue={String(yAxisFormat?.decimalPlaces ?? 2)}
          onNewValueSubmitted={(newValue: string) => {
            const intValue = parseInt(newValue);
            if (intValue === 0 || intValue) {
              const newYAxisFormat = {
                ...yAxisFormat,
                decimalPlaces: intValue,
              };
              dispatch(
                updateVisualizeOperation(
                  updateYAxisFormat(newYAxisFormat, instructions),
                  visualizationType as OPERATION_TYPES,
                ),
              );
            }
          }}
          label="Decimal Places"
        />
      )}
      {DATE_TYPES.has(instructions.categoryColumn?.column.type || '') && (
        <InputWithBlurSave
          containerClassName={classes.configInput}
          initialValue={instructions.xAxisFormat?.dateFormat}
          onNewValueSubmitted={(newValue: string) => {
            const newInstructions = cloneDeep(instructions);

            newInstructions.xAxisFormat = {
              ...newInstructions.xAxisFormat,
              dateFormat: newValue,
            };
            dispatch(
              updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
            );
          }}
          label="Category Date Format"
        />
      )}
    </div>
  );
}
