/** @format */

import { V2TwoDimensionChartInstructions } from 'constants/types';
import _, { cloneDeep } from 'lodash';
import { TIME_COLUMN_TYPES } from 'utils/operationTypeUtils';

export const formatTwoDimensionalData = (
  previewData: Record<string, string | number>[],
  instructions?: V2TwoDimensionChartInstructions,
) => {
  const columnType = instructions?.categoryColumn?.column.type;
  const formatData = _.flow(formatTimeSeriesData);
  // Preview data is stored in the redux store, we shouldn't directly modify it.
  return formatData(cloneDeep(previewData), instructions, columnType) as Record<
    string,
    string | number
  >[];
};

const formatTimeSeriesData = (
  previewData: Record<string, string | number>[],
  instructions?: V2TwoDimensionChartInstructions,
  columnType?: string,
) => {
  if (!TIME_COLUMN_TYPES.has(columnType ?? '')) return previewData;
  if (instructions?.chartSpecificFormat?.timeSeriesDataFormat?.hideLatestPeriodData) {
    previewData.pop();
  }

  return previewData;
};
