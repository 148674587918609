/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2KPIChartInstructions } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
  colorPaletteDisplay: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2KPIChartInstructions;
};

export default function KPITrendConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={instructions.trendFormat?.label}
        onNewValueSubmitted={(newValue: string) => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.trendFormat = {
            ...newInstructions.trendFormat,
            label: newValue,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Label"
      />
      <SwitchInput
        className={classes.configInput}
        switchOn={instructions.trendFormat?.isNumber}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.trendFormat = {
            ...newInstructions.trendFormat,
            isNumber: !instructions.trendFormat?.isNumber,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Use Number"
      />
      <SwitchInput
        className={classes.configInput}
        switchOn={instructions.trendFormat?.trendColorsReversed}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.trendFormat = {
            ...newInstructions.trendFormat,
            trendColorsReversed: !instructions.trendFormat?.trendColorsReversed,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Reverse Trend Colors"
      />
    </div>
  );
}
