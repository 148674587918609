/** @format */

import React from 'react';
import _ from 'underscore';
import { ThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import theme from 'theme';

export type Props = {
  children: JSX.Element;
};

const generateClassName = createGenerateClassName({
  productionPrefix: _.uniqueId('explo-embed-tooltip'),
});

export default function TooltipWrapper(props: Props) {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StylesProvider>
  );
}
