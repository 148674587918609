/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const CONTENT =
  'Containers hold other elements. Drag new elements into the container to configure them.';

const useStyles = makeStyles((theme: Theme) => ({
  configText: {
    padding: `0 ${theme.spacing(6)}px`,
    marginTop: theme.spacing(4),
    color: theme.palette.ds.grey900,
  },
}));

export default function ContainerElementConfigPanel() {
  const classes = useStyles();
  return <div className={classes.configText}>{CONTENT}</div>;
}
