/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, WithStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';

import { DropdownDashboardElemConfig } from 'types/dashboardTypes';
import ExploMultiSelect from 'components/multiSelect';
import { Dataset } from 'actions/types';
import { constructOptions } from './dashboardDropdownElement';

const styles = () => ({
  root: {},
});

type PassedProps = {
  config: DropdownDashboardElemConfig;
  dashboardDatasets: { [datasetId: string]: Dataset };
  onNewValueSelect: (newvalue: any) => void;
  value?: any;
  disabled?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

class DashboardMultiSelectElement extends React.Component<Props> {
  render() {
    const { classes, config, value, dashboardDatasets, disabled } = this.props;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const options: any[] = constructOptions(config, dashboardDatasets);

    let selectedOptions;
    if (!value) {
      selectedOptions = [];
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selectedOptions = _.filter(options, (option: any) => value.indexOf(option.value) > -1);
    }

    // TODO: account for when the default value is not in the options

    return (
      <div className={classes.root}>
        <ExploMultiSelect
          fillWidth
          minimal
          disabled={disabled}
          disableOnNoItems={config.disableOnNoItems}
          filterable={!config.disableSearch}
          label={config.label}
          noSelectionText={config.placeholderText || ''}
          onItemSelected={this.onItemSelected}
          options={options}
          placeholder={config.placeholderText}
          selectedItems={selectedOptions}
          unselectIndex={this.unselectIndex}
          useFakeLabel={config.label === ''}
        />
      </div>
    );
  }

  onItemSelected = (item: any) => {
    const { value, onNewValueSelect } = this.props;
    onNewValueSelect(onItemSelected(item, value));
  };

  unselectIndex = (index: number) => {
    const { value, onNewValueSelect } = this.props;
    onNewValueSelect(onIndexUnselected(index, value));
  };
}

export const onItemSelected = (item: any, value: any) => {
  let newValues = value ? cloneDeep(value) : [];

  if (newValues.indexOf(item.value) > -1) {
    newValues = _.filter(newValues, (value) => value !== item.value);
  } else {
    newValues.push(item.value);
  }

  return newValues.length > 0 ? newValues : undefined;
};

export const onIndexUnselected = (index: number, value: any) => {
  const newValues = value ? cloneDeep(value) : [];
  if (newValues.length > 0) {
    newValues.splice(index, 1);
  }

  return newValues.length > 0 ? newValues : undefined;
};

export default withStyles(styles)(DashboardMultiSelectElement);
