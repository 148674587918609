/** @format */
import { trackEvent } from 'analytics/exploAnalytics';
import { AppToaster } from 'toaster';
import { sha256 } from 'js-sha256';
import { User } from 'actions/types';
import Cookies from 'js-cookie';
import { Intent } from '@blueprintjs/core';
import { identify } from 'analytics/exploAnalytics';

export const getHubspotCookie = () => {
  const cookies = '; ' + document.cookie;
  const cookiePart = cookies.split('; ' + process.env.REACT_APP_HUBSPOT_COOKIE_NAME + '=').pop();
  if (cookiePart) {
    return cookiePart.split(';').shift();
  } else {
    return '';
  }
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const googleOAuthVerificationOnSuccess = (token: string) => {
  // setting the user's token so they are authenticated for this browser
  Cookies.set('spheres_auth_token', token);
  window.location.href = '/home';
};

export const googleOAuthVerificationOnFailure = (errorMessage?: string) => {
  AppToaster.show({
    message: errorMessage ? errorMessage : `SSO login failed.`,
    icon: 'error',
    intent: Intent.DANGER,
    timeout: 3000,
  });
};

export const userLoginAnalytics = (user: any) => {
  identify(user.id, {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    has_team: user.has_team,
    profile_data: user.profile_data,
  });
  pingCustomerOnlineSlack(user.first_name, user.last_name, user.email, user.team?.team_name);
};

const pingSlack = (message: string): void => {
  if (!process.env.REACT_APP_SLACK_SIGNUPS_PING_URL) {
    return;
  }

  fetch(process.env.REACT_APP_SLACK_SIGNUPS_PING_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: JSON.stringify({
      text: message,
    }),
  });
};

export const addEmailToWaitlist = (
  email: string,
  onSuccess: () => void,
  onError: (response: Record<string, string>) => void,
  source: string,
) => {
  const hubspotSignUpFailedMessage = `<!channel> ${email} just signed up for Explo, but their Hubspot submission failed`;

  fetch(
    'https://api.hsforms.com/submissions/v3/integration/submit/' +
      process.env.REACT_APP_HUBSPOT_PORTAL_ID +
      '/' +
      process.env.REACT_APP_HUBSPOT_BETA_SIGNUP_FORM_GUID,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        submittedAt: Date.now(),
        fields: [
          {
            name: 'email',
            value: email,
          },
          {
            name: 'lifecyclestage',
            value: 'Subscriber',
          },
        ],
        context: {
          hutk: getHubspotCookie(),
          pageUri: 'https://explo.co/',
          pageName: 'Explo',
        },
      }),
    },
  )
    .then((resp) => {
      if (resp.status && resp.status >= 400) {
        pingSlack(hubspotSignUpFailedMessage);
      }
    })
    .catch((error) => {
      pingSlack(hubspotSignUpFailedMessage);
      onError(error);
    });

  trackEvent('Landing page sign up', {
    sign_up_email: email,
    source: source,
  });
  pingSlack(`${email} just signed up for Explo!`);
  onSuccess();
};

export const pingCustomerOnlineSlack = (
  firstName: string,
  lastName: string,
  email: string,
  team: string,
): void => {
  if (!process.env.REACT_APP_SLACK_ONLINE_PING_URL) {
    return;
  }
  if (
    email.includes('@explo.co') ||
    email.includes('lodestone') ||
    email.includes('@trustkaz.com') ||
    email.includes('lsspl+') ||
    email.includes('@mailinator.com')
  ) {
    return;
  }

  fetch(process.env.REACT_APP_SLACK_ONLINE_PING_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: JSON.stringify({
      text: `<!channel> ${firstName} ${lastName} (${email}) from ${
        team || ''
      } just logged into Explo`,
    }),
  });
};

export const onRegistrationSubmit = (
  termsChecked: boolean,
  password: string,
  password2: string,
  signupEmail: string,
  firstName: string,
  lastName: string,
  registerUser: (
    first_name: string,
    last_name: string,
    email: string,
    password1: string,
    password2: string,
    sucessCallback: (user: User) => void,
    errorCallback: (response: Record<string, string>) => void,
  ) => void,
  onSuccess: (user: User) => void,
  onError?: (errorMsg: string) => void,
) => {
  if (!password || !password2 || !signupEmail || !firstName || !lastName) {
    AppToaster.show({
      message: 'Please fill out all fields before submitting.',
      icon: 'error',
      timeout: 5000,
      intent: Intent.DANGER,
    });
  } else if (!termsChecked) {
    AppToaster.show({
      message: 'Please read and accept the terms of service before submitting.',
      icon: 'error',
      timeout: 5000,
      intent: Intent.DANGER,
    });
  } else if (password !== password2) {
    AppToaster.show({
      message: 'Passwords do not match.',
      icon: 'error',
      timeout: 5000,
      intent: Intent.DANGER,
    });
  } else if (password.length < 6) {
    AppToaster.show({
      message: 'Password should contain at least 6 characters.',
      icon: 'error',
      timeout: 5000,
      intent: Intent.DANGER,
    });
  } else {
    fetch(
      'https://api.hsforms.com/submissions/v3/integration/submit/' +
        process.env.REACT_APP_HUBSPOT_PORTAL_ID +
        '/' +
        process.env.REACT_APP_HUBSPOT_ACCOUNT_SIGNUP_FORM_GUID,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          submittedAt: Date.now(),
          fields: [
            {
              name: 'email',
              value: signupEmail,
            },
            {
              name: 'firstname',
              value: firstName,
            },
            {
              name: 'lastname',
              value: lastName,
            },
          ],
          context: {
            hutk: getHubspotCookie(),
            pageUri: 'https://explo.co/',
            pageName: 'Explo',
          },
        }),
      },
    );

    registerUser(
      firstName,
      lastName,
      signupEmail,
      sha256(password),
      sha256(password2),
      onSuccess,
      (response: Record<string, string>) => {
        if (Object.keys(response).length > 0) {
          const key = Object.keys(response)[0];
          onError && onError(response[key][0]);
          AppToaster.show({
            message: response[key][0],
            icon: 'error',
            timeout: 5000,
            intent: Intent.DANGER,
          });
        }
      },
    );
  }
};
