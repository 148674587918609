/** @format */
import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import FlexBox, { VerticalAlignment, HorizontalAlignment } from 'components/core/FlexBox';
import { Icon } from '@blueprintjs/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  nameInput: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  colorOption: {
    width: 20,
    height: 20,
    borderRadius: 4,
    marginRight: theme.spacing(1),

    '&:hover': {
      cursor: 'pointer',
    },

    '&:last-child': {
      marginRight: 0,
    },
  },
}));

export type Props = {
  className?: string;
  name: string;
  color: string;
  updateName: (newName: string) => void;
  updateColor: (ewColor: string) => void;
};

const TagEditPopover: FunctionComponent<Props> = (props) => {
  const { className, name, color, updateName, updateColor } = props;
  const classes = useStyles();
  const theme = useTheme();

  const ENV_TAG_COLORS = [
    theme.palette.envTagColors.blue,
    theme.palette.envTagColors.green,
    theme.palette.envTagColors.yellow,
    theme.palette.envTagColors.red,
    theme.palette.envTagColors.purple,
    theme.palette.envTagColors.lightPurple,
  ];

  return (
    <div className={cx(classes.root, className)}>
      <InputWithBlurSave
        containerClassName={classes.nameInput}
        hideRightIconInteractions
        isCompact
        initialValue={name}
        onNewValueSubmitted={(newValue: string) => {
          if (!newValue) return;

          updateName(newValue.toLowerCase().replace(/[\W\s+]+/g, '_'));
        }}
      />
      <FlexBox
        verticalAlignment={VerticalAlignment.CENTER}
        horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}>
        {ENV_TAG_COLORS.map((colorHex) => (
          <FlexBox
            verticalAlignment={VerticalAlignment.CENTER}
            horizontalAlignment={HorizontalAlignment.CENTER}
            key={`env-tag-color-${colorHex}`}
            className={classes.colorOption}
            style={{ backgroundColor: colorHex }}
            onClick={() => updateColor(colorHex)}>
            {colorHex === color && <Icon color={theme.palette.ds.white} icon="small-tick" />}
          </FlexBox>
        ))}
      </FlexBox>
    </div>
  );
};

export default TagEditPopover;
