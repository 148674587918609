/** @format */
import React, { useState } from 'react';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import { IconName, Intent } from '@blueprintjs/core';
import Button from 'shared/Button';

import minimatch from 'minimatch';

import { createWhitelistDomain } from 'actions/whitelistDomainActions';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { AppToaster } from 'toaster';
import parse from 'url-parse';

import AddWhitelistDomainModal from './addWhitelistDomainModal';
import { ACTION, WhitelistDomain } from 'actions/types';
import { createLoadingSelector } from 'reducers/api/selectors';
import InputWithTag from 'shared/InputWithTag';
import SettingsDomainWhitelistRule from './settingsDomainWhitelistRule';

import CalloutLink from 'shared/CalloutLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `0 ${theme.spacing(8)}px 125px ${theme.spacing(8)}px`,
  },
  subsection: {
    fontSize: 20,
    fontWeight: 600,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rulesContainerHeader: {
    fontWeight: 500,
    fontSize: '16px',
  },
  testContainer: {
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(8),
  },
  testHeader: {
    color: theme.palette.ds.grey900,
  },
  testInput: {},
  documentationBox: {
    marginTop: theme.spacing(4),
  },
  subtitle: {
    color: theme.palette.ds.grey700,
    marginBottom: theme.spacing(3),
  },
}));

type VerificationState =
  | {
      statusIntent?: Intent;
      statusIcon?: IconName;
    }
  | undefined;

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

function SettingsDomainWhitelistSection(props: Props) {
  const { currentUser, createWhitelistDomain, whitelistDomains, whitelistDomainsLoading } = props;
  const classes = useStyles();

  const [isAddWhitelistDomainModalOpen, setAddWhitelistDomainModalOpen] = useState<boolean>(false);
  const [urlVerificationStatus, setUrlVerificationStatus] = useState<VerificationState>(undefined);
  const [testedUrl, setTestedUrl] = useState<string>('');

  const onTestUrlChange = (url: string) => {
    let newStatus: VerificationState = undefined;

    if (url) {
      if (testUrl(url, whitelistDomains)) {
        newStatus = {
          statusIntent: Intent.SUCCESS,
          statusIcon: 'tick',
        };
      } else {
        newStatus = {
          statusIntent: Intent.DANGER,
          statusIcon: 'cross',
        };
      }
    }

    setTestedUrl(url);
    setUrlVerificationStatus(newStatus);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.subsection}>Domain Whitelisting</div>
        <Button
          onClick={() => setAddWhitelistDomainModalOpen(true)}
          text="Add a Rule"
          type="primary"
        />
      </div>
      <div className={classes.subtitle}>
        {'Allow your domains to view your dashboards using either hard-coded urls or * wildcards.'}
      </div>
      <div className={classes.testContainer}>
        <InputWithTag
          label="Test a URL"
          className={classes.testInput}
          value={testedUrl}
          placeholder="http://test.example.com"
          onChange={(e) => onTestUrlChange(e.target.value)}
          statusInfo={urlVerificationStatus}
        />
      </div>
      <div>
        <div className={classes.rulesContainerHeader}>Rules</div>
        {!whitelistDomainsLoading &&
          whitelistDomains &&
          whitelistDomains.map((whitelistDomain) => (
            <SettingsDomainWhitelistRule
              key={`whitelist-domain-rule=${whitelistDomain.id}`}
              rule={whitelistDomain}
            />
          ))}
      </div>
      <AddWhitelistDomainModal
        closeModal={() => {
          setAddWhitelistDomainModalOpen(false);
        }}
        onSubmit={(rule: string) => {
          createWhitelistDomain(
            {
              postData: {
                name: rule,
                team_id: currentUser.team?.id,
              },
            },
            () => {
              setAddWhitelistDomainModalOpen(false);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (response: any) => {
              AppToaster.show({
                message: response.error_msg
                  ? response.error_msg
                  : 'Something went wrong. Please try again or contact support@explo.co if the error continues.',
                icon: 'error',
                timeout: 3000,
                intent: Intent.DANGER,
              });
            },
          );
        }}
        modalOpen={isAddWhitelistDomainModalOpen}
      />
      <CalloutLink
        className={classes.documentationBox}
        url="https://docs.explo.co/embedding-documentation/whitelisting-your-domain"
        text="Review the developer documentation to learn more"
      />
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
  whitelistDomainsLoading: createLoadingSelector([ACTION.FETCH_WHITELIST_DOMAIN], false)(state),
  whitelistDomains: state.whitelistDomains.whitelist_domains,
});

const testUrl = (url: string, whitelistDomains: WhitelistDomain[] | undefined) => {
  // pass in an empty base url so it doesn't use the browser's location
  const parsedTestUrl = parse(url, {});

  if (!whitelistDomains) return true;

  // duplicates the logic in request_handlers/handlers.py in the backend
  for (const whitelistDomain of whitelistDomains) {
    // less important to pass in an empty base url here
    // but doing it for consistency and safety
    const parsedPattern = parse(whitelistDomain.name, {});

    if (parsedPattern.protocol !== parsedTestUrl.protocol) continue;

    let splitTestUrl = parsedTestUrl.host.split('.');
    let splitPattern = parsedPattern.host.split('.');

    // it's valid for either to not include a subdomain,
    // so if xor either has www, remove it so that we can
    // check the other parts of the url
    if (splitPattern[0] === 'www' && splitTestUrl[0] !== 'www') {
      splitPattern = splitPattern.slice(1);
    } else if (splitTestUrl[0] === 'www' && splitPattern[0] !== 'www') {
      splitTestUrl = splitTestUrl.slice(1);
    }

    // now we know that the two should have the same number of nested domains
    // so if they don't we can short-circuit checking against this pattern
    if (splitPattern.length !== splitTestUrl.length) continue;

    let match = true;

    // iterate through the subdomains,
    // checking each part for a match
    for (let i = 0; i < splitPattern.length; i++) {
      if (!minimatch(splitTestUrl[i], splitPattern[i])) match = false;
    }

    // if we've made it to the end of iteration
    // and haven't found a mistmatch, then we've
    // found a matching domain!
    if (match) return true;
  }

  return false;
};

const mapDispatchToProps = { createWhitelistDomain };

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDomainWhitelistSection);
