/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';

import VisualizationTypeSelector from './VisualizationTypeSelector';
import TwoDimensionVizConfig from './vizConfigs/TwoDimensionVizConfig';
import KPIVizConfig from './vizConfigs/KPIVizConfig';
import KPITrendConfig from './vizConfigs/KPITrendConfig';
import BoxPlotConfig from './vizConfigs/BoxPlotConfig';
import VisualizationConfigTableColumns from './VisualizationConfigTableColumns';
import SettingHeader from './SettingHeader';
import FilterConfigSection from './FilterConfigSection';

import {
  OPERATION_TYPES,
  VisualizeOperationInstructions,
  FilterOperationInstructions,
  V2_CHART_VISUALIZATION_OPERATIONS,
} from 'constants/types';
import { TableColumn } from 'actions/types';
import { DashboardElement } from 'types/dashboardTypes';
import TableConfig from './vizConfigs/TableConfig';

const useStyles = makeStyles(() => ({
  root: {},
}));

export type Props = {
  visualizationType: string;
  schema: TableColumn[];
  vizInstructions: VisualizeOperationInstructions;
  filterInstructions: FilterOperationInstructions;
  loading?: boolean;
  dashboardElements?: DashboardElement[];
  sourceType?: string;
};

export default function DataConfigTab({
  visualizationType,
  schema,
  vizInstructions,
  filterInstructions,
  loading,
  dashboardElements,
  sourceType,
}: Props) {
  const classes = useStyles();

  const sharedTwoDimConfigProps = {
    chartType: visualizationType,
    instructions: vizInstructions.V2_TWO_DIMENSION_CHART || {},
    loading: loading,
    dashboardElements: dashboardElements,
  };

  const renderVizConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            supportColor={visualizationType !== OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2}
            maxAggs={vizInstructions.V2_TWO_DIMENSION_CHART?.colorColumn ? 1 : undefined}
          />
        );
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            supportColor
            maxAggs={vizInstructions.V2_TWO_DIMENSION_CHART?.colorColumn ? 1 : undefined}
            categoryName="Y-Axis"
            aggName="X-Axis"
          />
        );
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            categoryName="Color"
            aggName="Angle"
            maxAggs={1}
          />
        );
      case OPERATION_TYPES.VISUALIZE_MAP_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            categoryName="Region Column"
            aggName="Density Column"
            maxAggs={1}
          />
        );
      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            categoryName="Stage"
            aggName="Amount"
            maxAggs={1}
          />
        );

      case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            categoryName="X-Axis"
            aggName="Aggregation"
            supportColor
            colorName="Y-Axis"
            maxAggs={1}
          />
        );
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
        return (
          <KPIVizConfig chartType={visualizationType} instructions={vizInstructions.V2_KPI || {}} />
        );
      case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
        return (
          <KPIVizConfig chartType={visualizationType} instructions={vizInstructions.V2_KPI || {}} />
        );
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
        return (
          <KPITrendConfig
            chartType={visualizationType}
            instructions={vizInstructions.V2_KPI_TREND || {}}
            loading={loading}
            dashboardElements={dashboardElements}
          />
        );
      case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
        return (
          <BoxPlotConfig
            chartType={visualizationType}
            instructions={vizInstructions.V2_BOX_PLOT || {}}
            loading={loading}
          />
        );
      case OPERATION_TYPES.VISUALIZE_TABLE:
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
        return (
          <TableConfig
            chartType={OPERATION_TYPES.VISUALIZE_TABLE}
            instructions={vizInstructions.VISUALIZE_TABLE || {}}
            schema={schema}
            loading={loading}
          />
        );

      default:
        return <div></div>;
    }
  };

  return (
    <div className={classes.root}>
      {V2_CHART_VISUALIZATION_OPERATIONS.includes(visualizationType as OPERATION_TYPES) && (
        <>
          <SettingHeader noTopBorder name="Chart Type" />
          <VisualizationTypeSelector
            selectedChartType={visualizationType}
            sourceType={sourceType}
          />
        </>
      )}
      {renderVizConfig()}
      <SettingHeader name="Filter" />
      <FilterConfigSection
        instructions={filterInstructions}
        dashboardElements={dashboardElements}
      />
      <SettingHeader name="Fields" />
      <VisualizationConfigTableColumns schema={schema} loading={loading} />
    </div>
  );
}
