/** @format */

import React from 'react';

import { ButtonGroup } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { getTimeZones } from '@vvo/tzdb';

import InputLabel from 'shared/InputLabel';
import DropdownSelect from 'shared/DropdownSelect';
import { EmailCadenceTime } from 'constants/types';
import _ from 'underscore';

const styles = (theme: Theme) =>
  createStyles({
    timeSelect: {
      display: 'flex',
      gap: `${theme.spacing(2)}px`,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    timeContainer: {
      paddingTop: theme.spacing(3),
    },
    timezoneSelect: {
      flex: '1 1 0',
    },
  });

type PassedProps = {
  emailCadenceTime: EmailCadenceTime;
  onSetTime: (time: EmailCadenceTime) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

const HOURS = Array.from(Array(12).keys(), (time) => time + 1);
const MINUTES = Array.from(Array(60).keys());

const raw_timezones = getTimeZones({ includeUtc: true });
const timezones_by_alternative_name = _.groupBy(
  raw_timezones,
  (timezone) => timezone.alternativeName,
);
const TIMEZONES = raw_timezones
  .sort((a, b) => a.rawOffsetInMinutes - b.rawOffsetInMinutes)
  .map((timezone) => {
    // timezone.name is always something like Country/More Specific/(optional) More Specific.
    // We just want the last, most specific part
    const region = timezone.name.split('/').pop()?.replace('_', ' ');
    // if there are multiple timezones with this alternative name (like Eastern Time),
    // then we want to also show the more specific region for this option
    const name =
      `(GMT${timezone.rawFormat.split(' ')[0]}) ${timezone.alternativeName}` +
      (timezones_by_alternative_name[timezone.alternativeName].length > 1 ? ` - ${region}` : '');
    return {
      name: name,
      id: timezone.name,
    };
  });

const browser_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const EmailTimeSelection = ({ classes, emailCadenceTime, onSetTime }: Props) => {
  return (
    <div className={classes.timeContainer}>
      <InputLabel text={'Time'} />
      <div className={classes.timeSelect}>
        <ButtonGroup>
          <DropdownSelect
            minimal
            showIcon
            filterable={false}
            noSelectionText="HH"
            onChange={(item) => onSetTime({ ...emailCadenceTime, hour: Number(item.name) })}
            options={HOURS.map((hour) => ({
              id: String(hour),
              name: String(hour),
            }))}
            selectedItem={
              emailCadenceTime?.hour
                ? {
                    id: String(emailCadenceTime.hour),
                    name: String(emailCadenceTime.hour),
                  }
                : undefined
            }
          />
          <DropdownSelect
            minimal
            showIcon
            filterable={false}
            noSelectionText="MM"
            onChange={(item) => onSetTime({ ...emailCadenceTime, minute: Number(item.id) })}
            options={MINUTES.map((minute) => ({
              id: String(minute),
              name: String(minute).length === 2 ? String(minute) : `0${minute}`,
            }))}
            selectedItem={
              emailCadenceTime?.minute !== undefined
                ? {
                    id: String(emailCadenceTime.minute),
                    name:
                      String(emailCadenceTime.minute).length === 2
                        ? String(emailCadenceTime.minute)
                        : `0${emailCadenceTime.minute}`,
                  }
                : undefined
            }
          />
          <DropdownSelect
            minimal
            showIcon
            filterable={false}
            noSelectionText=""
            onChange={(item) => onSetTime({ ...emailCadenceTime, isPm: item.id === 'PM' })}
            options={[
              {
                id: 'AM',
                name: 'AM',
              },
              {
                id: 'PM',
                name: 'PM',
              },
            ]}
            selectedItem={
              emailCadenceTime?.isPm
                ? {
                    id: 'PM',
                    name: 'PM',
                  }
                : {
                    id: 'AM',
                    name: 'AM',
                  }
            }
          />
        </ButtonGroup>
        <DropdownSelect
          fillWidth
          minimal
          showIcon
          containerClassName={classes.timezoneSelect}
          filterable={false}
          noSelectionText=""
          onChange={(item) => onSetTime({ ...emailCadenceTime, timezone: item.id })}
          options={TIMEZONES}
          selectedItem={_.find(
            TIMEZONES,
            (timezone) => (emailCadenceTime.timezone ?? browser_timezone) === timezone.id,
          )}
        />
      </div>
    </div>
  );
};

export default connect(null, null)(withStyles(styles)(EmailTimeSelection));
