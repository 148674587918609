/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Switch } from '@blueprintjs/core';
import { ACTION } from 'actions/types';

import FullPagePanel from 'components/pages/fullPagePanel';
import Button from 'shared/Button';

import { DataTable } from 'actions/types';
import { fetchAllDataSourceTables, syncSourceTables } from 'actions/dataSourceActions';
import { fetchParentSchemas } from 'actions/parentSchemaActions';
import { createLoadingSelector } from 'reducers/api/selectors';

import LoadingSpinner from 'images/loading_spinner.gif';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    padding: `60px 0`,
    overflowY: 'scroll' as 'scroll',
  },
  loadingBody: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingSpinner: {
    width: 75,
  },
  tableList: {
    display: 'grid',
    gridTemplateColumns: '.8fr .2fr',
    rowGap: `${theme.spacing(3)}px`,
  },
  tableListHeaderContainer: {
    marginBottom: theme.spacing(5),
  },
  tableListHeader: {
    fontSize: 16,
    fontWeight: 'bold' as 'bold',
  },
  tableListTables: {
    maxHeight: '40vh',
    overflowY: 'auto' as 'auto',
  },
  tableListSyncCol: {
    textAlign: 'center' as 'center',
  },
  switchStyles: {
    '&.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator': {
      backgroundColor: theme.palette.ds.blue,
    },
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  actionButton: {
    marginLeft: theme.spacing(3),
    width: 150,
  },
});

type MatchParams = {
  schemaId: string;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  selectDatasetModalOpen: boolean;
  tablesToIgnore: { [table: string]: DataTable };
  schemaId?: string;
};

class SyncDataTablesPage extends React.Component<Props, State> {
  state: State = {
    selectDatasetModalOpen: false,
    tablesToIgnore: {},
    schemaId: this.props.match.params.schemaId,
  };

  constructor(props: Props) {
    super(props);

    if (!props.match.params.schemaId) {
      props.fetchParentSchemas({}, (result) => {
        const schemaId = result.parent_schemas[0].id;
        props.fetchAllDataSourceTables({ id: schemaId });
        this.setState({ schemaId: String(schemaId) });
      });
    } else {
      props.fetchAllDataSourceTables({ id: props.match.params.schemaId });
    }
  }

  render() {
    const { fetchAllTablesLoading, fetchParentSchema } = this.props;

    if (fetchAllTablesLoading || fetchParentSchema) {
      return this.renderLoadingBody();
    } else {
      return this.renderSnycPanel();
    }
  }

  renderLoadingBody = () => {
    const { classes } = this.props;

    return (
      <div className={classes.loadingBody}>
        <img className={classes.loadingSpinner} src={LoadingSpinner} alt="loading spinner" />
      </div>
    );
  };

  renderSnycPanel = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <FullPagePanel
          title="Select Tables to Sync"
          description="Only the tables selected will be available to configure reports and data panels.">
          {this.renderTableList()}
          {this.renderActions()}
        </FullPagePanel>
      </div>
    );
  };

  renderTableList = () => {
    const { classes, allTables } = this.props;
    const { tablesToIgnore } = this.state;

    return (
      <>
        <div className={cx(classes.tableList, classes.tableListHeaderContainer)}>
          <div className={classes.tableListHeader}>Table Name</div>
          <div className={cx(classes.tableListSyncCol, classes.tableListHeader)}>Sync On</div>
        </div>
        <div className={cx(classes.tableList, classes.tableListTables)}>
          {_.sortBy(allTables, (table) => table.name).map((table: DataTable) => {
            return (
              <React.Fragment key={`table-name-${table.name}`}>
                <div>{table.name} </div>
                <div className={classes.tableListSyncCol}>
                  <Switch
                    className={classes.switchStyles}
                    checked={!tablesToIgnore[table.name]}
                    onChange={() => {
                      if (tablesToIgnore[table.name]) {
                        delete tablesToIgnore[table.name];
                      } else {
                        tablesToIgnore[table.name] = table;
                      }
                      this.setState({ tablesToIgnore });
                    }}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </>
    );
  };

  renderActions = () => {
    const { classes, allTables, syncSourceTables, syncSourceTablesLoading } = this.props;
    const { tablesToIgnore, schemaId } = this.state;

    return (
      <div className={classes.actionsContainer}>
        <Button
          className={classes.actionButton}
          type="primary"
          disabled={Object.keys(tablesToIgnore).length === allTables.length}
          loading={syncSourceTablesLoading}
          onClick={() =>
            syncSourceTables(
              {
                id: schemaId,
                postData: { ignore_table_names: Object.keys(tablesToIgnore) },
              },
              () => {
                this.props.history.goBack();
              },
            )
          }
          text="Continue"
        />
      </div>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  tablesLoading: state.dataSource.loading,
  allTables: state.dataSource.allTables,
  fetchAllTablesLoading: createLoadingSelector([ACTION.FETCH_ALL_DATA_SOURCE_TABLES], true)(state),
  fetchParentSchema: createLoadingSelector([ACTION.FETCH_PARENT_SCHEMAS], false)(state),
  syncSourceTablesLoading: createLoadingSelector([ACTION.SYNC_SOURCE_TABLES], false)(state),
});

const mapDispatchToProps = {
  fetchAllDataSourceTables,
  syncSourceTables,
  fetchParentSchemas,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SyncDataTablesPage)),
);
