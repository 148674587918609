/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { connect } from 'react-redux';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';

import { Code, CopyBlock, codepen } from 'react-code-blocks';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: 500,
      width: '60vw',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
    headerText: {
      fontWeight: 'bold' as 'bold',
      fontSize: 18,
      marginBottom: theme.spacing(2),
    },
    bodyText: {
      marginBottom: theme.spacing(4),
    },
    underCodeBlock: {
      marginTop: theme.spacing(5),
    },
  });

type PassedProps = {
  modalOpen: boolean;
  closeModal: () => void;
  dashboardEmbedId: string;
  dashboardName: string;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  PassedProps &
  WithStyles<typeof styles>;

class EmbedModal extends React.Component<Props> {
  render() {
    const { modalOpen, closeModal, classes } = this.props;
    if (!modalOpen) return <></>;

    const importInstructionsCode = '<script src="https://embed.explo.co/bundle.js"></script>';

    return (
      <Dialog
        isOpen={modalOpen}
        onClose={closeModal}
        title={'Embed Instructions'}
        className={classes.root}>
        <div className={Classes.DIALOG_BODY}>
          <div className={classes.modalContent}>
            <div className={classes.headerText}>0. Have Explo Whitelist Domains</div>
            <div className={classes.bodyText}>
              Message Explo support to whitelist your domains so that the embed will load on your
              website. For development purposes, we have{' '}
              <Code text="localhost" language="javascript" theme={codepen} /> on ports 3000, 3001,
              3002, 6700, 8080 whitelisted already.
            </div>
            <div className={classes.headerText}>1. Import Explo JS library</div>
            <div className={classes.bodyText}>
              In the <Code text="<head>" language="javascript" theme={codepen} /> of your{' '}
              <Code text="index.html" language="javascript" theme={codepen} /> include the following
              script imports
            </div>
            <CopyBlock
              text={importInstructionsCode}
              theme={codepen}
              language="javascript"
              wrapLines={false}
            />
            <div className={cx(classes.headerText, classes.underCodeBlock)}>
              2. Embed Component Into Frontend
            </div>
            <div className={classes.bodyText}>
              We use Web Components to power our embedded component, meaning it is compatible with
              every frontend framework (React, Vue, etc) and vanilla javascript. Below are some
              examples on how to embed the component. Check out our embed documentation{' '}
              <a
                href={
                  'https://docs.explo.co/embedding-documentation/option-1-web-component/step-2-embed-the-web-component'
                }
                target="_blank"
                rel="noopener noreferrer">
                here
              </a>
              .
            </div>
            {this.renderEmbedCode()}
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button intent={Intent.NONE} onClick={() => closeModal()}>
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }

  renderEmbedCode = () => {
    const { dashboardEmbedId } = this.props;

    const embedCode = `<explo-dashboard\n  dash-user-group-token="${dashboardEmbedId}:USER_GROUP_TOKEN"\n  updateUrlParams={true}\n  isProduction={true}\n  environment="production"\n  refresh-minutes={10}\n  variables={JSON.stringify({\n    element1: 'value',\n    element2: 'value2',\n  })}\n />\n`;

    return <CopyBlock text={embedCode} theme={codepen} language="javascript" wrapLines={false} />;
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmbedModal));
