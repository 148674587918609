/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { dayjs, DATE_FORMAT_TO_LOCALIZED_FORMAT } from 'utils/localizationUtils';
import { createStyles, withStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core';

import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';
import HighCharts from './highCharts';
import TrendPctChange from './shared/trendPctChange';
import InformationIcon from 'pages/dashboardPage/DashboardDatasetView/Header/InformationIcon';
import NumberTrendTextPanel from '../../../shared/charts/numberTrendTextPanel';

import { NoDataConfig, V2KPITrendInstructions } from 'constants/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { TableColumn } from 'actions/types';
import {
  FORMULA_AGG_TYPE,
  PeriodComparisonRangeTypes,
  PeriodRangeTypes,
  TrendGroupingOptions,
  V2_NUMBER_FORMATS,
} from 'constants/dataConstants';
import { formatValue } from './utils';
import { replaceTemplatesWithValues } from 'utils/dataPanelConfigUtils';
import { getCategoricalColors, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';

declare global {
  interface PointOptionsObject {
    custom: Record<string, boolean | number | string>;
  }
}
type ChartData = {
  name: string;
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  color: string;
}[];

const PERIOD_INDEX = 1;
const COMPARISON_INDEX = 0;
const DATE_INDEX = 0;
const AGG_INDEX = 1;

const styles = (theme: Theme) =>
  createStyles({
    chartContainer: {
      height: 'calc(100% - 77px)',
      display: 'flex',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    chartTitle: {
      fontSize: `14px !important`,
      fontWeight: 600,
      marginRight: theme.spacing(2),
      whiteSpace: 'nowrap',
      maxWidth: '60%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.ds.grey800,
    },
    noDataChartTitle: {
      maxWidth: '100%',
    },
    chartTitleInfoIcon: {
      marginLeft: 0,
      marginRight: theme.spacing(2),
    },
    aggregatedValuesContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    aggValue: {
      fontSize: 20,
    },
    comparisonAggValue: (props: PassedProps) => ({
      color: props.instructions?.displayFormat?.comparisonColor || theme.palette.ds.grey700,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    }),
    dateRange: {
      fontSize: 12,
      fontWeight: 100,
      color: theme.palette.ds.grey800,
    },
    chartDateRange: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: -2,
      color: theme.palette.ds.grey800,
      fontSize: 12,
    },
    aggValueUnit: {
      fontSize: 16,
    },
    periodAggValue: (props: PassedProps) => ({
      color:
        props.instructions?.displayFormat?.periodColor ||
        props.globalStyleConfig.visualizations.categoricalPalette.hue1,
      fontWeight: 600,
    }),
    emptyDataContainer: {
      height: '100%',
    },
    noDataText: (props: PassedProps) => ({
      height: 'calc(100% - 20px)',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      fontSize: props.noDataInstructions?.noDataFontSize || 36,
      fontWeight: 'unset',
    }),
  });

type TotalAggregatedValues = {
  periodRange: number;
  comparisonRange: number;
};

type PassedProps = {
  loading?: boolean;
  backgroundColor: string;
  previewData: Record<string, string | number>[];
  aggValuesLoading?: boolean;
  aggregatedValues?: TotalAggregatedValues;
  instructions?: V2KPITrendInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
  schema: TableColumn[];
  infoTooltipText?: string;
  title: string;
  globalStyleConfig: GlobalStyleConfig;
  noDataInstructions?: NoDataConfig;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  hoveredIndex?: number;
  data?: ChartData;
};

export const areRequiredVariablesSet = (
  variables: DashboardVariableMap,
  instructions?: V2KPITrendInstructions,
) => {
  if (instructions?.periodColumn?.periodRange === PeriodRangeTypes.DATE_RANGE_INPUT) {
    const rangeVarId = instructions?.periodColumn?.rangeElemId;

    if (!rangeVarId) return true;

    const rangeVariable = variables[rangeVarId] as {
      startDate: Date;
      endDate: Date;
    };

    return !!(rangeVariable?.startDate && rangeVariable?.endDate);
  } else if (instructions?.periodColumn?.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN) {
    const timeVarId = instructions?.periodColumn?.timePeriodElemId;

    if (!timeVarId) return true;

    const rangeVariable = variables[timeVarId] as number;

    return !!rangeVariable;
  } else {
    return true;
  }
};

export const instructionsReadyToDisplay = (instructions?: V2KPITrendInstructions) => {
  const customRangeValid =
    instructions?.periodColumn?.periodRange !== PeriodRangeTypes.CUSTOM_RANGE ||
    (instructions.periodColumn.customEndDate && instructions.periodColumn.customStartDate);
  const rangeInputValue =
    instructions?.periodColumn?.periodRange !== PeriodRangeTypes.DATE_RANGE_INPUT ||
    instructions.periodColumn.rangeElemId;

  const timePeriodValue =
    instructions?.periodColumn?.periodRange !== PeriodRangeTypes.TIME_PERIOD_DROPDOWN ||
    instructions.periodColumn.timePeriodElemId;

  const aggReady =
    instructions?.aggColumn?.agg.id !== FORMULA_AGG_TYPE.id || instructions?.aggColumn?.agg.formula;

  return !!(
    instructions &&
    instructions.aggColumn?.column &&
    aggReady &&
    instructions.periodColumn?.column &&
    customRangeValid &&
    rangeInputValue &&
    timePeriodValue
  );
};

class NumberTrend extends React.PureComponent<Props, State> {
  state: State = {};

  constructor(props: Props) {
    super(props);

    if (
      !props.loading &&
      instructionsReadyToDisplay(props.instructions) &&
      props.schema?.length >= 2
    ) {
      this.state = {
        data: this.processTrendData(),
      };
    }
  }

  getBuiltInVarsMap = () => {
    const { data } = this.state;
    if (!data) return {};
    return {
      current_period: this.getPeriodDateRange(data),
      comparison_period: this.getComparisonDateRange(data),
    };
  };

  componentDidUpdate(prevProps: Props) {
    // Don't check props.loading as it's improperly set right now
    // (it will be set to true despite the previewData being already fetched)
    // and the previewData being different should be enough info to confidently
    // re-process the trend data
    if (
      instructionsReadyToDisplay(this.props.instructions) &&
      this.props.schema?.length >= 2 &&
      (!_.isEqual(prevProps.previewData, this.props.previewData) ||
        !_.isEqual(prevProps.instructions, this.props.instructions))
    ) {
      this.setState({
        data: this.processTrendData(),
      });
    }
  }

  render() {
    const { instructions, previewData, loading, variables, aggValuesLoading } = this.props;

    const requiredVarNotsSet = !areRequiredVariablesSet(variables, instructions);

    const isDataLoading = instructions?.hideTrendLines ? aggValuesLoading : loading;

    if (isDataLoading || !instructionsReadyToDisplay(instructions) || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }

    if (instructions?.hideTrendLines) {
      return this.renderNumberTrendTextPanel();
    }

    if (!previewData || previewData.length === 0) {
      return this.renderNoDataBody();
    }

    return this.renderNumberTrend();
  }

  renderNoDataBody = () => {
    const { classes, title, noDataInstructions } = this.props;
    return (
      <div
        className={cx(classes.emptyDataContainer, GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont)}>
        <div className={classes.titleContainer}>
          <div
            className={cx(
              classes.chartTitle,
              classes.noDataChartTitle,
              GLOBAL_STYLE_CLASSNAMES.text.body.secondary,
            )}>
            {title}
          </div>
        </div>
        <div className={classes.noDataText}>{noDataInstructions?.noDataText || 'No Data'}</div>
      </div>
    );
  };

  renderNumberTrendTextPanel = () => {
    const { instructions, title, noDataInstructions, aggregatedValues } = this.props;

    const usesComparison = this.getComparisonRange() !== PeriodComparisonRangeTypes.NO_COMPARISON;
    const periodAggregatedValue = aggregatedValues?.periodRange || 0;
    const comparisonAggregatedValue =
      (usesComparison ? aggregatedValues?.comparisonRange : undefined) || 0;
    const trendChangeVal = instructions?.displayFormat?.showAbsoluteChange
      ? this.getNumericChange(periodAggregatedValue, comparisonAggregatedValue)
      : this.getPctChange(periodAggregatedValue, comparisonAggregatedValue);
    const trendChangeValFormat = instructions?.displayFormat?.showAbsoluteChange
      ? V2_NUMBER_FORMATS.NUMBER
      : V2_NUMBER_FORMATS.PERCENT;
    const subtitle = replaceTemplatesWithValues(
      instructions?.textOnlyFormat?.subtitle ?? '',
      this.getBuiltInVarsMap(),
    );

    return (
      <NumberTrendTextPanel
        noData={!aggregatedValues}
        decimalPlaces={instructions?.valueFormat?.decimalPlaces}
        infoTooltipText={instructions?.generalFormat?.tooltipText}
        number={aggregatedValues?.periodRange || 0}
        numberFormat={instructions?.valueFormat?.numberFormat}
        showTrendChangePeriodLabel={instructions?.displayFormat?.showTrendChangePeriodLabel}
        showTooltipText={instructions?.generalFormat?.showTooltip}
        subtitle={subtitle}
        trendChangeVal={usesComparison ? trendChangeVal : undefined}
        trendChangeValLabel={this.getComparisonRange()}
        trendChangeValFormat={trendChangeValFormat}
        trendColorsReversed={instructions?.displayFormat?.trendColorsReversed}
        title={title}
        units={instructions?.valueFormat?.units}
        timeFormatId={instructions?.valueFormat?.timeFormat?.id}
        customTimeFormat={instructions?.valueFormat?.timeCustomerFormat}
        noDataInstructions={noDataInstructions}
        bold={instructions?.valueFormat?.bold}
        italic={instructions?.valueFormat?.italic}
        valueAlignment={instructions?.valueFormat?.horizAlignment}
        titleAlignment={instructions?.titleFormat?.horizAlignment}
        useTrendTag={instructions?.displayFormat?.useTrendTag}
      />
    );
  };

  renderNumberTrend = () => {
    const { classes } = this.props;
    const { data } = this.state;

    if (!data) return <div />;

    return (
      <>
        {this.renderChartAggregatedValues(data)}
        <HighCharts className={classes.chartContainer} chartOptions={this._spec(data)} />
        {this.renderChartDateRange(data)}
      </>
    );
  };

  renderChartDateRange = (data: ChartData) => {
    const { classes } = this.props;
    const periodData = data[PERIOD_INDEX].data;
    const startDate = this.formatDateRange(dayjs(periodData[0][DATE_INDEX]), true);
    const endDate = this.formatDateRange(
      dayjs(periodData[periodData.length - 1][DATE_INDEX]),
      true,
      true,
    );

    return (
      <div className={cx(classes.chartDateRange, GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont)}>
        <div>{startDate}</div>
        <div>{endDate}</div>
      </div>
    );
  };

  renderChartAggregatedValues = (data: ChartData) => {
    const { classes, instructions, title, infoTooltipText } = this.props;
    const useComparison = this.getComparisonRange() !== PeriodComparisonRangeTypes.NO_COMPARISON;
    const periodAggregatedValue = this.getTimeRangeAggregatedValue(data, PERIOD_INDEX);
    const comparisonAggregatedValue = this.getTimeRangeAggregatedValue(data, COMPARISON_INDEX);

    const pctChange = useComparison
      ? this.getPctChange(periodAggregatedValue, comparisonAggregatedValue)
      : undefined;

    return (
      <div className={GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont}>
        <div className={classes.titleContainer}>
          <div className={cx(classes.chartTitle, GLOBAL_STYLE_CLASSNAMES.text.h2.base)}>
            {title}
          </div>
          {infoTooltipText && (
            <InformationIcon
              className={classes.chartTitleInfoIcon}
              infoTooltipText={infoTooltipText}
            />
          )}
          {pctChange !== undefined && (
            <TrendPctChange instructions={instructions} pctChange={pctChange} />
          )}
        </div>
        <div className={classes.aggregatedValuesContainer}>
          <div className={classes.periodAggValue}>
            <div className={classes.aggValue}>
              {formatValue({
                value: periodAggregatedValue,
                decimalPlaces: instructions?.valueFormat?.decimalPlaces ?? 2,
                formatId:
                  instructions?.valueFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
                hasCommas: true,
                timeFormatId: instructions?.valueFormat?.timeFormat?.id,
                customTimeFormat: instructions?.valueFormat?.timeCustomerFormat,
              })}{' '}
              <span className={classes.aggValueUnit}>{instructions?.valueFormat?.units}</span>
            </div>
            <div className={classes.dateRange}>{this.getPeriodDateRange(data)}</div>
          </div>
          {useComparison && (
            <div className={classes.comparisonAggValue}>
              <div className={classes.aggValue}>
                {formatValue({
                  value: comparisonAggregatedValue,
                  decimalPlaces: instructions?.valueFormat?.decimalPlaces ?? 2,
                  formatId:
                    instructions?.valueFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
                  hasCommas: true,
                  timeFormatId: instructions?.valueFormat?.timeFormat?.id,
                  customTimeFormat: instructions?.valueFormat?.timeCustomerFormat,
                })}{' '}
                <span className={classes.aggValueUnit}>{instructions?.valueFormat?.units}</span>
              </div>
              <div className={classes.dateRange}>{this.getComparisonDateRange(data)}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  getPctChange = (base: number, comparison: number) => {
    let change = 0;
    if (base === comparison) {
      change = 0;
    } else if (base === 0) {
      change = -1;
    } else if (comparison === 0) {
      change = 1;
    } else {
      change = base / comparison - 1;
    }

    return change;
  };

  getNumericChange = (base: number, comparison: number) => {
    return base - comparison;
  };

  getTimeRangeAggregatedValue = (data: ChartData, timeRangeIndex: number) => {
    const { hoveredIndex } = this.state;
    const { aggregatedValues } = this.props;

    const timeRangeData = data[timeRangeIndex].data;

    if (hoveredIndex !== undefined) {
      return timeRangeData[hoveredIndex] ? timeRangeData[hoveredIndex][AGG_INDEX] : 0;
    } else {
      return timeRangeIndex === PERIOD_INDEX
        ? aggregatedValues?.periodRange
        : aggregatedValues?.comparisonRange;
    }
  };

  getPeriodDateRange = (data: ChartData) => {
    return this.getDateRange(data[PERIOD_INDEX].data);
  };

  getComparisonDateRange = (data: ChartData) => {
    return this.getDateRange(data[COMPARISON_INDEX].data);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDateRange = (compData: any) => {
    const { hoveredIndex } = this.state;
    if (hoveredIndex !== undefined) {
      if (!compData[hoveredIndex]) return '-';
      const periodStartDate = dayjs(compData[hoveredIndex][DATE_INDEX]);
      return this.formatDateRange(periodStartDate);
    } else {
      const startDate = this.formatDateRange(dayjs(compData[0][DATE_INDEX]), true);
      const endDate = this.formatDateRange(
        dayjs(compData[compData.length - 1][DATE_INDEX]),
        true,
        true,
      );
      return `${startDate} - ${endDate}`;
    }
  };

  formatDateRange = (date: dayjs.Dayjs, showSingleDate?: boolean, isEndDate?: boolean) => {
    const trendGrouping = this.getTrendGrouping();
    const localizedDateFormat = DATE_FORMAT_TO_LOCALIZED_FORMAT['MMM D'];
    // I18N TODO
    switch (trendGrouping) {
      case TrendGroupingOptions.DAILY:
        return date.format(localizedDateFormat);
      case TrendGroupingOptions.WEEKLY: {
        const endDate = date.add(6, 'days');
        if (showSingleDate) {
          if (isEndDate) {
            return endDate.format(localizedDateFormat);
          } else {
            return date.format(localizedDateFormat);
          }
        } else {
          return `${date.format(localizedDateFormat)} - ${endDate.format(localizedDateFormat)}`;
        }
      }
      case TrendGroupingOptions.MONTHLY:
        return date.format(DATE_FORMAT_TO_LOCALIZED_FORMAT['MMM YYYY']);
      case TrendGroupingOptions.YEARLY:
        return date.format(DATE_FORMAT_TO_LOCALIZED_FORMAT['YYYY']);
    }
  };

  _spec = (data: ChartData): Highcharts.Options | undefined => {
    const { previewData, schema, backgroundColor } = this.props;
    if (schema?.length === 0 || !previewData) return;

    const setHoverIndex = (index?: number) => {
      this.setState({ hoveredIndex: index });
    };

    return {
      chart: {
        type: 'line',
        backgroundColor,
      },
      //@ts-ignore
      series: data,
      title: {
        text: undefined,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
        series: {
          animation: false,
          point: {
            events: {
              mouseOver: function () {
                setHoverIndex(this.index);
              },
              mouseOut: function () {
                setHoverIndex(undefined);
              },
            },
          },
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: undefined,
        },
      },
      xAxis: {
        crosshair: true,
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
        lineWidth: 1,
        lineColor: '#EEEEEE',
        minorGridLineWidth: 0,
        minorTickLength: 0,
        tickLength: 0,
      },

      tooltip: {
        enabled: false,
      },
    };
  };

  getPeriodColumnName = () => {
    const { schema } = this.props;

    return schema[0].name;
  };

  getAggColumnName = () => {
    const { schema } = this.props;

    return schema[1].name;
  };

  getPeriodAndComparisonDates = () => {
    const { periodStartDate, periodEndDate, periodDates } = this.getPeriodDates();
    const comparisonDates = this.getComparisonDates(
      periodStartDate,
      periodEndDate,
      periodDates.length,
    );

    return { periodDates, comparisonDates };
  };

  processTrendData = () => {
    const { previewData, instructions, globalStyleConfig } = this.props;
    if (!previewData) return undefined;

    const periodColumnName = this.getPeriodColumnName();
    const aggColumnName = this.getAggColumnName();

    const { periodDates, comparisonDates } = this.getPeriodAndComparisonDates();

    previewData.forEach((row) => {
      if (!instructions?.periodColumn?.column.type) return;
      row[periodColumnName] = dayjs
        .utc(row[periodColumnName])
        .format(DATE_FORMAT_TO_LOCALIZED_FORMAT['MM/DD/YYYY']);
    });

    const dataByDate = _.indexBy(previewData, periodColumnName);
    const series: Record<
      string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { name: string; type: string; data: any; color: string; lineWidth?: number; opacity?: number }
    > = {
      period: {
        type: 'line',
        name: 'Period',
        data: [],
        color:
          instructions?.displayFormat?.periodColor || getCategoricalColors(globalStyleConfig)[0],
      },
      comparison: {
        type: 'line',
        name: 'Comparison',
        data: [],
        color: instructions?.displayFormat?.comparisonColor || '#757575',
        lineWidth: 1,
        opacity: 0.6,
      },
    };

    periodDates.forEach((date) => {
      let aggValue = 0;
      if (dataByDate[date]) {
        aggValue = _.isNumber(dataByDate[date][aggColumnName])
          ? (dataByDate[date][aggColumnName] as number)
          : 0;
      }
      const entry = [date, aggValue];
      series.period.data.push(entry);
    });

    comparisonDates.forEach((date) => {
      let aggValue = 0;
      if (dataByDate[date]) {
        aggValue = _.isNumber(dataByDate[date][aggColumnName])
          ? (dataByDate[date][aggColumnName] as number)
          : 0;
      }
      const entry = [date, aggValue];
      series.comparison.data.push(entry);
    });

    return [series.comparison, series.period];
  };

  getPeriodRange = () => {
    const { instructions } = this.props;
    return instructions?.periodColumn?.periodRange || PeriodRangeTypes.LAST_4_WEEKS;
  };

  getComparisonRange = () => {
    const { instructions } = this.props;
    return instructions?.periodComparisonRange || PeriodComparisonRangeTypes.PREVIOUS_PERIOD;
  };

  getTrendGrouping = () => {
    const { instructions } = this.props;
    return instructions?.trendGrouping || TrendGroupingOptions.WEEKLY;
  };

  getCustomEndDateDayjs = (dateFormat?: string) => {
    const { instructions } = this.props;
    return instructions?.periodColumn?.customEndDate
      ? dayjs(instructions.periodColumn.customEndDate, dateFormat)
      : dayjs();
  };

  getCustomStartDateDayjs = (dateFormat?: string) => {
    const { instructions } = this.props;
    return instructions?.periodColumn?.customStartDate
      ? dayjs(instructions.periodColumn.customStartDate, dateFormat)
      : dayjs();
  };

  getPeriodDates = () => {
    const periodEndDate = this.getPeriodEndDate();
    const periodStartDate = this.getPeriodStartDate(periodEndDate);

    const periodDates: string[] = [];
    this.enumerateDatesByGroup(periodStartDate, periodEndDate, periodDates);
    return {
      periodStartDate,
      periodEndDate,
      periodDates,
    };
  };

  getComparisonDates = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, numIntervals: number) => {
    const comparisonRange = this.getComparisonRange();
    const periodRange = this.getPeriodRange();

    const comparisonDates: string[] = [];
    let comparisonStartDate;
    let comparisonEndDate;

    switch (comparisonRange) {
      case PeriodComparisonRangeTypes.PREVIOUS_PERIOD:
        comparisonStartDate = this.shiftDateByGrouping(startDate, -numIntervals);
        comparisonEndDate = this.shiftDateByGrouping(startDate, -1);
        break;
      case PeriodComparisonRangeTypes.PREVIOUS_MONTH:
        switch (periodRange) {
          case PeriodRangeTypes.TODAY:
          case PeriodRangeTypes.CUSTOM_RANGE:
          case PeriodRangeTypes.DATE_RANGE_INPUT:
          case PeriodRangeTypes.TIME_PERIOD_DROPDOWN:
          case PeriodRangeTypes.LAST_7_DAYS:
            comparisonStartDate = this.getEntryStartDateFromGrouping(
              startDate.subtract(1, 'month'),
            );
            comparisonEndDate = this.getEntryStartDateFromGrouping(endDate.subtract(1, 'month'));
            break;
          case PeriodRangeTypes.LAST_4_WEEKS:
            comparisonStartDate = this.shiftDateByGrouping(startDate, -numIntervals);
            comparisonEndDate = this.shiftDateByGrouping(endDate, -numIntervals);
            break;
          case PeriodRangeTypes.LAST_3_MONTHS:
          case PeriodRangeTypes.LAST_12_MONTHS:
            comparisonStartDate = this.shiftDateByGrouping(startDate, -this.numGroupsInMonth());
            comparisonEndDate = this.shiftDateByGrouping(endDate, -this.numGroupsInMonth());
            break;
          case PeriodRangeTypes.MONTH_TO_DATE:
            comparisonStartDate = this.getEntryStartDateFromGrouping(
              endDate.subtract(1, 'month').startOf('month'),
            );
            comparisonEndDate = this.shiftDateByGrouping(comparisonStartDate, numIntervals - 1);
            break;
          case PeriodRangeTypes.YEAR_TO_DATE:
            comparisonStartDate = this.shiftDateByGrouping(startDate, -this.numGroupsInMonth());
            comparisonEndDate = this.shiftDateByGrouping(endDate, -this.numGroupsInMonth());
            break;
        }
        break;
      case PeriodComparisonRangeTypes.PREVIOUS_YEAR:
        switch (periodRange) {
          case PeriodRangeTypes.TODAY:
          case PeriodRangeTypes.LAST_7_DAYS:
          case PeriodRangeTypes.CUSTOM_RANGE:
          case PeriodRangeTypes.DATE_RANGE_INPUT:
          case PeriodRangeTypes.TIME_PERIOD_DROPDOWN:
            comparisonStartDate = this.getEntryStartDateFromGrouping(startDate.subtract(1, 'year'));
            comparisonEndDate = this.getEntryStartDateFromGrouping(endDate.subtract(1, 'year'));
            break;
          case PeriodRangeTypes.LAST_4_WEEKS:
          case PeriodRangeTypes.LAST_3_MONTHS:
          case PeriodRangeTypes.LAST_12_MONTHS:
            comparisonStartDate = this.shiftDateByGrouping(startDate, -this.numGroupsInYear());
            comparisonEndDate = this.shiftDateByGrouping(endDate, -this.numGroupsInYear());
            break;
          case PeriodRangeTypes.MONTH_TO_DATE:
            comparisonStartDate = this.getEntryStartDateFromGrouping(
              endDate.subtract(1, 'year').startOf('month'),
            );
            comparisonEndDate = this.shiftDateByGrouping(comparisonStartDate, numIntervals - 1);
            break;
          case PeriodRangeTypes.YEAR_TO_DATE:
            comparisonStartDate = this.getEntryStartDateFromGrouping(
              endDate.subtract(1, 'year').startOf('year'),
            );
            comparisonEndDate = this.shiftDateByGrouping(comparisonStartDate, numIntervals - 1);
            break;
        }
        break;
      case PeriodComparisonRangeTypes.NO_COMPARISON:
        return [];
    }

    //@ts-ignore
    this.enumerateDatesByGroup(comparisonStartDate, comparisonEndDate, comparisonDates);

    return comparisonDates;
  };

  enumerateDatesByGroup = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, datesList: string[]) => {
    if (endDate.diff(startDate, 'days') <= 0) {
      datesList.push(endDate.format(DATE_FORMAT_TO_LOCALIZED_FORMAT['MM/DD/YYYY']));
      return;
    }

    const trendGrouping = this.getTrendGrouping();

    datesList.push(startDate.format(DATE_FORMAT_TO_LOCALIZED_FORMAT['MM/DD/YYYY']));

    switch (trendGrouping) {
      case TrendGroupingOptions.HOURLY:
        startDate = startDate.add(1, 'hour');
        break;
      case TrendGroupingOptions.DAILY:
        startDate = startDate.add(1, 'day');
        break;
      case TrendGroupingOptions.WEEKLY:
        startDate = startDate.add(1, 'week');
        break;
      case TrendGroupingOptions.MONTHLY:
        startDate = startDate.add(1, 'month');
        break;
      case TrendGroupingOptions.YEARLY:
        startDate = startDate.add(1, 'year');
        break;
    }
    this.enumerateDatesByGroup(startDate, endDate, datesList);
  };

  getPeriodEndDate = () => {
    const { instructions } = this.props;
    const periodRange = this.getPeriodRange();

    // dayjs() gets the current day
    const periodRangeOffset = instructions?.periodColumn?.trendDateOffset ?? 0;
    let periodEndDate = dayjs().subtract(periodRangeOffset, 'day');

    if (
      periodRange === PeriodRangeTypes.CUSTOM_RANGE ||
      periodRange === PeriodRangeTypes.DATE_RANGE_INPUT
    ) {
      periodEndDate = this.getCustomEndDateDayjs('YYYY-MM-DD');
    } else if (periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN) {
      periodEndDate = this.getCustomEndDateDayjs();
    }
    return this.getEntryStartDateFromGrouping(periodEndDate);
  };

  getPeriodStartDate = (periodEndDate: dayjs.Dayjs) => {
    const periodRange = this.getPeriodRange();
    const trendGrouping = this.getTrendGrouping();

    let periodStartDate = periodEndDate;

    switch (periodRange) {
      case PeriodRangeTypes.CUSTOM_RANGE:
      case PeriodRangeTypes.DATE_RANGE_INPUT:
        periodStartDate = this.getCustomStartDateDayjs('YYYY-MM-DD');
        break;
      case PeriodRangeTypes.TIME_PERIOD_DROPDOWN:
        periodStartDate = this.getCustomStartDateDayjs();
        break;
      // The start date and end date for TODAY are the same
      case PeriodRangeTypes.TODAY:
        break;
      case PeriodRangeTypes.LAST_7_DAYS:
        periodStartDate = periodEndDate.subtract(6, 'days');
        break;
      case PeriodRangeTypes.LAST_4_WEEKS:
        // When the dates are in days, we need to subtract the full 4 weeks
        if (trendGrouping === TrendGroupingOptions.DAILY) {
          periodStartDate = periodEndDate.subtract(4, 'weeks');
        } else {
          periodStartDate = periodEndDate.subtract(3, 'weeks');
        }
        break;
      case PeriodRangeTypes.LAST_3_MONTHS:
        periodStartDate = periodEndDate.subtract(2, 'months');
        break;
      case PeriodRangeTypes.LAST_12_MONTHS:
        periodStartDate = periodEndDate.subtract(11, 'months');
        break;
      case PeriodRangeTypes.MONTH_TO_DATE:
        periodStartDate = this.getEntryStartDateFromGrouping(periodEndDate.startOf('month'));
        break;
      case PeriodRangeTypes.YEAR_TO_DATE:
        periodStartDate = this.getEntryStartDateFromGrouping(periodEndDate.startOf('year'));
        break;
    }

    return this.getEntryStartDateFromGrouping(periodStartDate);
  };

  getEntryStartDateFromGrouping = (date: dayjs.Dayjs) => {
    const trendGrouping = this.getTrendGrouping();

    switch (trendGrouping) {
      case TrendGroupingOptions.HOURLY:
      case TrendGroupingOptions.DAILY:
        return date;
      case TrendGroupingOptions.WEEKLY:
        return date.startOf('isoWeek');
      case TrendGroupingOptions.MONTHLY:
        return date.startOf('month');
      case TrendGroupingOptions.YEARLY:
        return date.startOf('year');
    }
  };

  shiftDateByGrouping = (date: dayjs.Dayjs, shift: number) => {
    const trendGrouping = this.getTrendGrouping();
    switch (trendGrouping) {
      case TrendGroupingOptions.HOURLY:
        return date.add(shift, 'hours');
      case TrendGroupingOptions.DAILY:
        return date.add(shift, 'days');
      case TrendGroupingOptions.WEEKLY:
        return date.add(shift, 'weeks');
      case TrendGroupingOptions.MONTHLY:
        return date.add(shift, 'months');
      case TrendGroupingOptions.YEARLY:
        return date.add(shift, 'years');
      default:
        return date;
    }
  };

  numGroupsInMonth = () => {
    const trendGrouping = this.getTrendGrouping();
    switch (trendGrouping) {
      case TrendGroupingOptions.HOURLY:
        return 30 * 24;
      case TrendGroupingOptions.DAILY:
        return 30;
      case TrendGroupingOptions.WEEKLY:
        return 4;
      case TrendGroupingOptions.MONTHLY:
        return 1;
      case TrendGroupingOptions.YEARLY:
        return 1;
    }
  };

  numGroupsInYear = () => {
    const trendGrouping = this.getTrendGrouping();
    switch (trendGrouping) {
      case TrendGroupingOptions.HOURLY:
        return 365 * 24;
      case TrendGroupingOptions.DAILY:
        return 365;
      case TrendGroupingOptions.WEEKLY:
        return 52;
      case TrendGroupingOptions.MONTHLY:
        return 12;
      case TrendGroupingOptions.YEARLY:
        return 1;
    }
  };
}

export default withStyles(styles)(NumberTrend);
