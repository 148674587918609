/** @format */
import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import DatePicker from 'react-datepicker';
import { Button } from '@blueprintjs/core';

import InputLabel from 'shared/InputLabel';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

import { dayjs, getFormatForDatePicker } from 'utils/localizationUtils';

const useStyles = makeStyles({
  root: {
    position: 'relative',

    '& .react-datepicker-wrapper': {
      width: '100%',
    },

    '& .react-datepicker': {
      display: 'flex',
    },
  },
  datePicker: {
    textOverflow: 'ellipsis',
    padding: '0 10PX',
    borderWidth: 0,
    fontSize: 14,
    height: 30,
    width: '100%',
    borderRadius: 3,
    boxShadow: 'inset 0px 0px 0px 1px #c1cee0',
  },
  cancelButton: {
    '&.bp3-button': {
      position: 'absolute',
      bottom: 5,
      right: 3,
      height: 20,
      width: 20,
      minHeight: 0,
      minWidth: 0,
      padding: 0,
      marginRight: 2,
    },
  },
  datePickerContainer: {
    position: 'relative',
  },
  datePickerBtnWithCancelBtn: {
    paddingRight: 25,
  },
});

export type Props = {
  className?: string;
  label?: string;
  selectedValue?: Date;
  startDate?: Date;
  endDate?: Date;
  minDate?: Date | null;
  maxDate?: Date | null;
  onNewValueSelect: (date: Date | [Date, Date] | null) => void;
  showCancelBtn?: boolean;
  showTimeSelect?: boolean;
  onCancelClick?: () => void;
  disabled?: boolean;
  withPortal?: boolean;
  monthsShown?: number;
  selectsRange?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customInput?: (onClick: any, ref: any) => JSX.Element;
  onCalendarClose?: () => void;
  onCalendarOpen?: () => void;
  placeholder?: string;
  /**
   * If passed in, the input will be positioned as if it had a label (with spacing above it)
   * but no words will be visible
   */
  useFakeLabel?: boolean;
  portalId?: string;
};

/*
  Note: The react-datepicker takes in user inputs as local time, we convert to UTC
  to store the date, but the convert it back to local time to display so that it looks
  the same as they selected in the UI
*/

const DatePickerInput = (props: Props) => {
  const {
    className,
    label,
    selectedValue,
    onNewValueSelect,
    showCancelBtn,
    onCancelClick,
    showTimeSelect,
    disabled,
    withPortal,
    startDate,
    endDate,
    minDate,
    maxDate,
    selectsRange,
    monthsShown,
    customInput,
    onCalendarClose,
    onCalendarOpen,
    useFakeLabel,
    placeholder,
    portalId,
  } = props;
  const classes = useStyles(props);

  const CustomInputElement =
    // eslint-disable-next-line
    customInput && forwardRef(({ onClick }: any, ref) => customInput(onClick, ref));

  return (
    <div
      className={cx(
        classes.root,
        GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.datePickerDaySelected,
        GLOBAL_STYLE_CLASSNAMES.base.backgroundColor.datePickerHeaderBackgroundColor,
        GLOBAL_STYLE_CLASSNAMES.container.fill.datePickerMonthContainerFill,
        GLOBAL_STYLE_CLASSNAMES.container.fill.datePickerTimeContainerFill,
        GLOBAL_STYLE_CLASSNAMES.text.h3.datePickerHeader,
        GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.datePickerNavigationPreviousColor,
        GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.datePickerNavigationNextColor,
        GLOBAL_STYLE_CLASSNAMES.text.smallBody.datePickerBody,
        GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.datePickerYearDropdownIconColor,
        GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.datePickerMonthDropdownIconColor,
        className,
      )}>
      {(label || useFakeLabel) && <InputLabel text={label || ''} fakeLabel={useFakeLabel} />}
      <div className={classes.datePickerContainer}>
        <DatePicker
          disabled={disabled}
          className={cx(
            classes.datePicker,
            { [classes.datePickerBtnWithCancelBtn]: showCancelBtn },
            GLOBAL_STYLE_CLASSNAMES.container.outline.boxShadow,
            GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow,
            GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.datePickerInputBorderHover,
            GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.datePickerInputBorderActive,
            GLOBAL_STYLE_CLASSNAMES.text.body.primary,
          )}
          selected={selectedValue || null}
          onChange={(date) => {
            if (selectsRange) {
              const [date1, date2] = date as [Date, Date];
              // The user may have selected a time, but then toggled off
              // show time select. In this case, we have to clear the
              // time originally set by the user, and default to 0 local time
              if (!showTimeSelect) {
                date1?.setHours(0);
                date1?.setMinutes(0);
                date2?.setHours(0);
                date2?.setMinutes(0);
              }
              onNewValueSelect([date1, date2]);
            } else {
              date = date as Date;
              // The user may have selected a time, but then toggled off
              // show time select. In this case, we have to clear the
              // time originally set by the user, and default to 0 local time
              if (!showTimeSelect) {
                date?.setHours(0);
                date?.setMinutes(0);
              }
              onNewValueSelect(date);
            }
          }}
          placeholderText={placeholder}
          showMonthDropdown={!monthsShown || monthsShown < 2}
          showYearDropdown={!monthsShown || monthsShown < 2}
          startDate={startDate || null}
          endDate={endDate || null}
          minDate={minDate}
          maxDate={maxDate}
          showTimeSelect={showTimeSelect}
          dateFormat={
            showTimeSelect
              ? getFormatForDatePicker('MM/DD/YYYY h:mm aa', dayjs().locale())
              : getFormatForDatePicker('MM/DD/YYYY', dayjs().locale())
          }
          withPortal={withPortal}
          monthsShown={monthsShown}
          selectsRange={selectsRange}
          shouldCloseOnSelect={false}
          customInput={CustomInputElement && <CustomInputElement />}
          onCalendarClose={onCalendarClose}
          onCalendarOpen={onCalendarOpen}
          portalId={portalId}
          popperModifiers={{
            // This needs to be disabled otherwise the popper in containers won't work properly
            preventOverflow: {
              enabled: false,
            },
          }}
        />
        {selectedValue && showCancelBtn && (
          <Button
            disabled={disabled}
            className={classes.cancelButton}
            minimal
            icon="cross"
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              e.stopPropagation();
              onCancelClick && onCancelClick();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DatePickerInput;
