/** @format */

import { Action } from 'reducers/rootReducer';
import { ACTION, Team } from 'actions/types';
import { createReducer } from './utils';

export interface TeamDataReducerState {
  loading: boolean;
  data?: Team;
  invitedEmails: string[];
}

const teamDataReducerInitialState: TeamDataReducerState = {
  loading: false,
  invitedEmails: [],
};

const fetchUserTeamRequest = (newState: TeamDataReducerState) => {
  newState.loading = true;
  return newState;
};

const fetchUserTeamSuccess = (newState: TeamDataReducerState, action: Action) => {
  const { payload } = action;
  newState.loading = false;
  newState.data = payload.team;
  newState.invitedEmails = payload.invited_emails;
  return newState;
};

const inviteTeamateSuccess = (newState: TeamDataReducerState, action: Action) => {
  const { payload } = action;
  if (!newState.invitedEmails.includes(payload.email)) newState.invitedEmails.push(payload.email);
  return newState;
};

const changeUserProfileNameSuccess = (newState: TeamDataReducerState, action: Action) => {
  const { payload } = action;
  const user = newState.data?.team_members.find((member) => member.id === payload.postData.id);
  if (user) {
    user.first_name = payload.first_name;
    user.last_name = payload.last_name;
  }
  return newState;
};

const saveAnalyticsConfigSuccess = (newState: TeamDataReducerState, action: Action) => {
  const { payload } = action;
  if (!newState.data) return newState;

  newState.data.analytics_reporting_actions = payload.analytics_reporting_actions;
  newState.data.analytics_reporting_url = payload.analytics_reporting_url;
  newState.data.analytics_reporting_token = payload.analytics_reporting_token;
  return newState;
};

const saveDefaultLocaleCodeSuccess = (newState: TeamDataReducerState, action: Action) => {
  const { payload } = action;
  if (!newState.data) return newState;

  newState.data.default_locale_code = payload.locale_code;
  return newState;
};

const saveUseBrowserLocaleSuccess = (newState: TeamDataReducerState, action: Action) => {
  const { payload } = action;
  if (!newState.data) return newState;

  newState.data.use_browser_locale = payload.setting;
  return newState;
};

const saveFaviconSuccess = (newState: TeamDataReducerState, action: Action) => {
  const { payload } = action;
  if (!newState.data) return newState;

  newState.data.favicon_url = payload.favicon_url;
  return newState;
};

export default createReducer<TeamDataReducerState>(teamDataReducerInitialState, {
  [`${ACTION.FETCH_USER_TEAM}_SUCCESS`]: fetchUserTeamRequest,
  [`${ACTION.FETCH_USER_TEAM}_SUCCESS`]: fetchUserTeamSuccess,
  [`${ACTION.INVITE_TEAMMATE}_SUCCESS`]: inviteTeamateSuccess,
  [`${ACTION.CHANGE_USER_PROFILE_NAME}_SUCCESS`]: changeUserProfileNameSuccess,
  [`${ACTION.SAVE_ANALYTICS_CONFIG}_SUCCESS`]: saveAnalyticsConfigSuccess,
  [`${ACTION.SAVE_DEFAULT_LOCALE_CODE}_SUCCESS`]: saveDefaultLocaleCodeSuccess,
  [`${ACTION.SAVE_USE_BROWSER_LOCALE}_SUCCESS`]: saveUseBrowserLocaleSuccess,
  [`${ACTION.SAVE_FAVICON}_SUCCESS`]: saveFaviconSuccess,
});
