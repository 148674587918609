/** @format */

import React from 'react';
import Chart from 'chart.js';
import _ from 'underscore';
import { dayjs, DATE_FORMAT_TO_LOCALIZED_FORMAT } from 'utils/localizationUtils';

import { numberWithCommas } from 'utils/general';
import { DATE_TYPES } from 'constants/dataConstants';
import {
  colorLuminance,
  convertHexToRGBA,
  indexOffirstLineOfAxis,
  titleCase,
} from 'utils/graphUtils';

import { OPERATION_TYPES } from 'constants/types';
import { COLOR_LIST } from 'constants/colorConstants';
import { formatValue } from 'pages/dashboardPage/charts/utils';

class LineOrBarChart extends React.PureComponent {
  chartRef = React.createRef();
  chart = null;

  UNSAFE_componentWillMount() {
    Chart.pluginService.register({
      afterDraw: function (chart) {
        if (chart.config.type !== 'line') {
          return;
        }
        if (chart.tooltip._active && chart.tooltip._active.length && chart.scales['left-axis']) {
          const activePoint = chart.controller.tooltip._active[0];
          const ctx = chart.ctx;
          const x = activePoint.tooltipPosition().x;
          const topY = chart.scales['left-axis'].top;
          const bottomY = chart.scales['left-axis'].bottom;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = 'rgba(155, 155, 155, 0.5)';
          ctx.stroke();
          ctx.restore();
        }
      },
    });
  }

  componentDidMount() {
    const myChartRef = this.chartRef.current.getContext('2d');

    this.chart = new Chart(myChartRef, this.getChartConfig());
  }

  componentDidUpdate(oldProps) {
    const {
      xAxis,
      chartKey,
      chartType,
      multiYAxis,
      viewTypeId,
      columns,
      data,
      mirrorLabels,
      yAxisRangeConfig,
    } = this.props;

    if (!data || data.length === 0 || data[0][xAxis] === undefined) {
      return;
    }

    // Don't update the chart if the chart config did not change
    if (
      _.isEqual(chartKey, oldProps.chartKey) &&
      _.isEqual(chartType, oldProps.chartType) &&
      _.isEqual(multiYAxis, oldProps.multiYAxis) &&
      _.isEqual(xAxis, oldProps.xAxis) &&
      _.isEqual(viewTypeId, oldProps.viewTypeId) &&
      _.isEqual(columns, oldProps.columns) &&
      _.isEqual(data, oldProps.data) &&
      _.isEqual(mirrorLabels, oldProps.mirrorLabels) &&
      _.isEqual(yAxisRangeConfig, oldProps.yAxisRangeConfig)
    ) {
      return;
    }

    // necessary to change chart type
    // Ref: TU/94dyt2hc
    const myChartRef = this.chartRef.current.getContext('2d');

    // Remove the old chart and all its event handles
    if (this.chart) this.chart.destroy();
    this.chart = new Chart(myChartRef, this.getChartConfig());
  }

  formatLabel = (value, colType, xAxisFormat) => {
    if (DATE_TYPES.has(colType)) {
      const dateVal = dayjs(value);
      return dateVal.isValid()
        ? dateVal.format(xAxisFormat || DATE_FORMAT_TO_LOCALIZED_FORMAT['MM/DD/YYYY'])
        : 'Invalid Date';
    }

    if (typeof value === 'string') {
      return titleCase(value);
    }

    return value;
  };

  getNumberOrUndefined = (numString) => {
    if (numString === '' || numString === undefined) return undefined;
    return Number(numString);
  };

  getChartConfig = () => {
    const {
      data,
      xAxis,
      xAxisFormat,
      columns,
      schema,
      multiYAxis,
      viewTypeId,
      textColor,
      yAxisRangeConfig,
      chartType,
      operationType,
    } = this.props;
    const isFixed = yAxisRangeConfig?.option?.id === 'FIXED';
    const max = isFixed ? this.getNumberOrUndefined(yAxisRangeConfig.max) : undefined;
    const min = isFixed ? this.getNumberOrUndefined(yAxisRangeConfig.min) : undefined;

    const cleanColumns = columns.filter((config) => {
      if (!data || data.length === 0) return false;
      return !!config.column && data[0][config.column.name] !== undefined;
    });
    const colByName = _.indexBy(schema, 'name');
    const xAxisCol = colByName[xAxis];

    return {
      type: chartType,
      data: {
        //Bring in data
        labels: data.map((obj) => this.formatLabel(obj[xAxis], xAxisCol.type, xAxisFormat)),
        datasets: cleanColumns.map((config, index) => {
          const axisId = multiYAxis ? config.yAxis || 'left-axis' : 'left-axis';
          return {
            label: config.column.friendly_name || config.column.name,
            data: data.map((obj) => obj[config.column.name]),
            numberFormatId: config.numberFormat?.id,
            type:
              operationType === OPERATION_TYPES.VISUALIZE_COMBO_CHART
                ? config.lineOrBar || chartType
                : chartType,
            yAxisID: axisId,
            ...this.getColumnConfig(index),
          };
        }),
      },
      options: {
        legend: {
          display: cleanColumns.length > 1,
        },
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                // display: true,
                // labelString: xAxis,
              },
              gridLines: {
                drawOnChartArea: false,
                color: textColor,
                zeroLineColor: textColor,
              },
              stacked: viewTypeId === 'STACKED',
              ticks: {
                beginAtZero: true,
                fontColor: textColor,
                max,
                min,
              },
            },
          ],
          yAxes: this.getYAxes(),
        },
        tooltips: {
          caretSize: 0,
          displayColors: cleanColumns.length > 1,
          callbacks: {
            label: (tooltipItem, data) => {
              const itemDataset = data.datasets[tooltipItem.datasetIndex];
              const value = itemDataset.data[tooltipItem.index];
              if (cleanColumns.length === 1)
                return (
                  ' ' +
                  formatValue({
                    value,
                    decimalPlaces: 0,
                    formatId: itemDataset.numberFormatId,
                    hasCommas: true,
                  })
                );

              const label = itemDataset.label;
              return (
                ' ' +
                label +
                ': ' +
                formatValue({
                  value,
                  decimalPlaces: 0,
                  formatId: itemDataset.numberFormatId,
                  hasCommas: true,
                })
              );
            },
            labelColor: function (tooltipItem, chart) {
              const primaryColor =
                chartType === 'line'
                  ? chart.config.data.datasets[tooltipItem.datasetIndex].borderColor
                  : chart.config.data.datasets[tooltipItem.datasetIndex].backgroundColor;
              return {
                borderColor: primaryColor,
                backgroundColor: primaryColor,
              };
            },
          },
          mode: 'index',
          intersect: false,
          backgroundColor: 'rgba(0, 0, 0, 0.65)',
          titleFontSize: 14,
          titleMarginBottom: 10,
          bodyFontSize: 14,
          bodySpacing: 8,
        },
      },
    };
  };

  getColumnConfig = (index) => {
    const { columns, viewTypeId, mirrorLabels, operationType, chartType } = this.props;
    const primaryColor = columns[index].color || COLOR_LIST[index % COLOR_LIST.length].primary;
    const secondaryColor = colorLuminance(primaryColor, 0.5);
    const columnType =
      operationType === OPERATION_TYPES.VISUALIZE_COMBO_CHART
        ? columns[index].lineOrBar || chartType
        : chartType;
    if (columnType === 'bar' || columnType === 'horizontalBar') {
      return {
        borderColor: primaryColor,
        borderWidth: mirrorLabels ? 0 : 2,
        backgroundColor: convertHexToRGBA(primaryColor, 0.2),
        hoverBackgroundColor: mirrorLabels ? convertHexToRGBA(secondaryColor, 0.2) : secondaryColor,
      };
    } else if (columnType === 'line') {
      return {
        borderColor: primaryColor,
        backgroundColor: convertHexToRGBA(primaryColor, 0.2),
        pointBackgroundColor: '#FFF',
        pointHoverBackgroundColor: primaryColor,
        pointRadius: 0,
        pointHoverRadius: 3,
        fill: viewTypeId === 'AREA' ? this.getFillNum(index) : false,
      };
    }
  };

  getFillNum = (index) => {
    switch (index) {
      case 0:
        return 'origin';
      case 1:
        return '-1';
      default:
        return index - 1;
    }
  };

  getYAxes = () => {
    const {
      multiYAxis,
      columns,
      chartType,
      viewTypeId,
      mirrorLabels,
      textColor,
      yAxisRangeConfig,
    } = this.props;
    const isFixed =
      yAxisRangeConfig && yAxisRangeConfig.option && yAxisRangeConfig.option.id === 'FIXED';
    const max = isFixed ? this.getNumberOrUndefined(yAxisRangeConfig.max) : undefined;
    const min = isFixed ? this.getNumberOrUndefined(yAxisRangeConfig.min) : undefined;
    let yAxes;

    if (chartType === 'horizontalBar') {
      yAxes = [
        {
          ticks: {
            beginAtZero: true,
            userCallback: function (value) {
              return `  ${value}`;
            },
            fontColor: multiYAxis
              ? columns[indexOffirstLineOfAxis(columns, 'left-axis')].color
              : textColor,
            fontSize: 13,
            mirror: mirrorLabels,
          },
          id: 'left-axis',
          position: 'left',
          gridLines: {
            drawOnChartArea: false,
            display: !mirrorLabels,
            color: textColor,
            zeroLineColor: textColor,
          },
          stacked: viewTypeId === 'STACKED',
        },
      ];
    } else {
      yAxes = [
        {
          ticks: {
            beginAtZero: true,
            userCallback: function (value) {
              return numberWithCommas(value);
            },
            fontColor: multiYAxis
              ? columns[indexOffirstLineOfAxis(columns, 'left-axis')].color
              : textColor,
            fontSize: 13,
            autoSkip: true,
            maxTicksLimit: 10,
            max,
            min,
          },
          id: 'left-axis',
          position: 'left',
          gridLines: {
            drawOnChartArea: false,
            color: textColor,
            zeroLineColor: textColor,
          },
          stacked: viewTypeId === 'AREA' || viewTypeId === 'STACKED',
        },
      ];
    }

    if (multiYAxis) {
      yAxes.push({
        ticks: {
          beginAtZero: true,
          userCallback: function (value) {
            return numberWithCommas(value);
          },
          fontColor: columns[indexOffirstLineOfAxis(columns, 'right-axis')].color,
          fontSize: 13,
          autoSkip: true,
          maxTicksLimit: 10,
        },
        id: 'right-axis',
        position: 'right',
        gridLines: {
          drawOnChartArea: false,
          color: textColor,
          zeroLineColor: textColor,
        },
      });
    }

    return yAxes;
  };

  render() {
    const { dataPanelTemplateId } = this.props;
    return <canvas id={`explo-chart-${dataPanelTemplateId}`} ref={this.chartRef} />;
  }
}

export default LineOrBarChart;
