/** @format */
import React, { useEffect, useState } from 'react';
import produce from 'immer';

import { FilterOperationInstructions, Schema, VisualizeTableInstructions } from 'constants/types';
import { getChangedSchema, removeUserDisabledColumns } from 'utils/dashboardUtils';
import { AdHocOperationInstructions, TableRow, VisualizeOperation } from 'actions/types';
import { ReportBuilderStep, REPORT_BUILDER_STEP_ORDER } from './constants';
import NameReportStep from './Steps/NameReportStep';
import StartStep from './Steps/StartStep';
import SelectContentStep from './Steps/SelectContentStep';
import { UserTransformedSchema } from 'constants/types';
import PreviewStep from './Steps/PreviewStep';
import ExportStep from './Steps/ExportStep';
import { DownloadChartInfo } from 'components/DashboardLayout/DashboardLayout';
import NeedsConfigurationPanel from '../needsConfigurationPanel';

type ReportConfiguration = {
  name?: string;
  userTransformedSchema: UserTransformedSchema;
};

type Props = {
  adHocOperationInstructions: AdHocOperationInstructions;
  downloadChartInfo?: DownloadChartInfo;
  error?: boolean;
  loading?: boolean;
  onAdHocFilterInfoUpdate: (adHocFilterInfo: FilterOperationInstructions) => void;
  onAdHocOperationInstructionsChange: (
    adHocOperationInstructions: AdHocOperationInstructions,
  ) => void;
  onDownloadPanelCsv: (schema?: UserTransformedSchema) => void;
  onDownloadPanelPdf: (
    adHocOperationInstructions: AdHocOperationInstructions,
    schema?: UserTransformedSchema,
    reportName?: string,
  ) => void;
  rows: Record<string, string | number>[];
  secondaryData: TableRow[];
  schema: Schema;
  sourceDataRowCount?: number;
  updateVisualizeOperation: (visualizeInstructions: VisualizeTableInstructions) => void;
  visualizeOperation: VisualizeOperation;
};

export default function ReportBuilder({
  adHocOperationInstructions,
  downloadChartInfo,
  error,
  loading,
  onAdHocFilterInfoUpdate,
  onAdHocOperationInstructionsChange,
  onDownloadPanelCsv,
  onDownloadPanelPdf,
  rows,
  secondaryData,
  schema,
  sourceDataRowCount,
  updateVisualizeOperation,
  visualizeOperation,
}: Props) {
  const [currentStep, setCurrentStep] = useState<ReportBuilderStep>(REPORT_BUILDER_STEP_ORDER[0]);
  const [reportConfiguration, setReportConfiguration] = useState<ReportConfiguration>({
    userTransformedSchema: getChangedSchema(
      schema,
      visualizeOperation.instructions.VISUALIZE_TABLE,
    ).map((col) => ({
      ...col,
      isVisible: false,
    })),
  });

  useEffect(
    function checkIfColumnsRemovedFromSchema() {
      const maybeChangedSchema = getChangedSchema(
        schema,
        visualizeOperation.instructions.VISUALIZE_TABLE,
      );
      const currentUserTransformedSchema = reportConfiguration.userTransformedSchema;
      let newUserSchema: UserTransformedSchema = [];

      const isColumnAdded = maybeChangedSchema.length > currentUserTransformedSchema.length;
      const isColumnRemoved = maybeChangedSchema.length < currentUserTransformedSchema.length;

      if (isColumnAdded) {
        newUserSchema = produce(currentUserTransformedSchema, (draft) => {
          maybeChangedSchema.forEach((maybeAddedColumn, index) => {
            const isNewlyAddedColumn = !draft.some((col) => col.name === maybeAddedColumn.name);

            if (isNewlyAddedColumn) {
              draft.splice(index, 0, { ...maybeAddedColumn, isVisible: false });
            }
          });
        });
      } else if (isColumnRemoved) {
        newUserSchema = produce(currentUserTransformedSchema, (draft) => {
          draft.forEach((oldCol, index) => {
            const isColumnDeleted = !maybeChangedSchema.some(
              (newCol) => newCol.name === oldCol.name,
            );

            if (isColumnDeleted) {
              draft.splice(index, 1);
            }
          });
        });
      } else {
        return;
      }

      setReportConfiguration({ ...reportConfiguration, userTransformedSchema: newUserSchema });
    },
    [reportConfiguration, schema, visualizeOperation],
  );

  if (loading && !reportConfiguration.userTransformedSchema.length) {
    return <NeedsConfigurationPanel fullHeight loading />;
  }

  switch (currentStep) {
    case ReportBuilderStep.START:
      return (
        <StartStep
          onNext={() => {
            setCurrentStep(ReportBuilderStep.NAME_REPORT);
          }}
        />
      );
    case ReportBuilderStep.NAME_REPORT:
      return (
        <NameReportStep
          onNext={() => {
            setCurrentStep(ReportBuilderStep.SELECT_CONTENT);
          }}
          name={reportConfiguration.name}
          updateReportName={(newName) => {
            const newConfiguration = produce(reportConfiguration, (draft) => {
              draft.name = newName;
            });

            setReportConfiguration(newConfiguration);
          }}
        />
      );
    case ReportBuilderStep.SELECT_CONTENT:
      return (
        <SelectContentStep
          onBack={() => {
            setCurrentStep(ReportBuilderStep.NAME_REPORT);
          }}
          onNext={() => {
            setCurrentStep(ReportBuilderStep.PREVIEW);
          }}
          userTransformedSchema={reportConfiguration.userTransformedSchema}
          setUserTransformedSchema={(newSchema) => {
            const newConfiguration = produce(reportConfiguration, (draft) => {
              draft.userTransformedSchema = newSchema;
            });

            setReportConfiguration(newConfiguration);
          }}
        />
      );
    case ReportBuilderStep.PREVIEW:
      return (
        <PreviewStep
          adHocOperationInstructions={adHocOperationInstructions}
          error={error}
          loading={loading}
          onAdHocFilterInfoUpdate={onAdHocFilterInfoUpdate}
          onAdHocOperationInstructionsChange={onAdHocOperationInstructionsChange}
          onBack={() => {
            setCurrentStep(ReportBuilderStep.SELECT_CONTENT);
          }}
          onNext={() => {
            setCurrentStep(ReportBuilderStep.EXPORT);
          }}
          reportName={reportConfiguration.name}
          rows={rows}
          secondaryData={secondaryData}
          schema={removeUserDisabledColumns(reportConfiguration.userTransformedSchema)}
          sourceDataRowCount={sourceDataRowCount}
          updateVisualizeOperation={updateVisualizeOperation}
          visualizeOperation={visualizeOperation}
        />
      );
    case ReportBuilderStep.EXPORT:
      return (
        <ExportStep
          downloadChartInfo={downloadChartInfo}
          onDownloadPanelCsv={() => {
            onDownloadPanelCsv(reportConfiguration.userTransformedSchema);
          }}
          onDownloadPanelPdf={() => {
            onDownloadPanelPdf(
              adHocOperationInstructions,
              reportConfiguration.userTransformedSchema,
              reportConfiguration.name,
            );
          }}
          onBack={() => {
            setCurrentStep(ReportBuilderStep.PREVIEW);
          }}
          onNext={() => {
            setCurrentStep(ReportBuilderStep.START);

            setReportConfiguration({
              userTransformedSchema: getChangedSchema(
                schema,
                visualizeOperation.instructions.VISUALIZE_TABLE,
              ).map((col) => ({
                ...col,
                isVisible: false,
              })),
            });
          }}
        />
      );
  }
}
