/** @format */

import _ from 'underscore';
import {
  dayjs,
  DATE_FORMAT_TO_LOCALIZED_FORMAT,
  localeUses12HourClock,
  format,
} from 'utils/localizationUtils';

import { Dataset, TableColumn } from 'actions/types';
import {
  BaseAxisFormat,
  XAxisFormat,
  ColorPalette,
  ColorPaletteV2,
  GoalLineFormat,
  LegendFormat,
  LegendPosition,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { LEGEND_CONFIG_BY_POS } from './../constants';
import { TooltipPositionerPointObject } from 'highcharts';
import {
  PIVOT_AGG_TYPES,
  V2_NUMBER_FORMATS,
  TrendGroupingOptions,
  DATE_PART_INPUT_AGG,
  DATE_TYPES,
  TIME_DIFF_FORMATS,
  STRING_FORMATS,
  STRING,
} from 'constants/dataConstants';
import { PALETTE_TO_COLORS } from 'constants/colorConstants';
import {
  GLOBAL_STYLE_CLASSNAMES,
  TEXT_SIZE_OFFSET_MAP,
  getCategoricalColors,
  getDivergingColors,
  getGradientColors,
} from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { replaceTemplatesWithValues } from 'utils/dataPanelConfigUtils';

export const getColorColNames = (schema: TableColumn[]) => {
  const xAxisColName = schema[0].name;
  // if there is a color but only 2 columns, that means that the xAxis and color column are the same
  const colorColName = schema[2] ? schema[1].name : xAxisColName;
  const aggColName = schema[2] ? schema[2].name : schema[1].name;

  return {
    xAxisColName,
    colorColName,
    aggColName,
  };
};

export const getLabelStyle = (
  globalStyleConfig: GlobalStyleConfig,
  fallback: 'primary' | 'secondary',
  colorOverride?: string,
) => {
  const fontFamily =
    globalStyleConfig.text.overrides.smallBody?.font ||
    (fallback === 'primary'
      ? globalStyleConfig.text.primaryFont
      : globalStyleConfig.text.secondaryFont);

  return {
    fontSize: `${
      globalStyleConfig.text.overrides.smallBody?.size ||
      globalStyleConfig.text.textSize + TEXT_SIZE_OFFSET_MAP['smallBody']
    }px`,
    color:
      colorOverride ||
      globalStyleConfig.text.overrides.smallBody?.color ||
      (fallback === 'primary'
        ? globalStyleConfig.text.primaryColor
        : globalStyleConfig.text.secondaryColor),
    fontWeight: 'normal',
    ...(fontFamily && { fontFamily }),
  };
};

export const xAxisFormat = (globalStyleConfig: GlobalStyleConfig, xAxisFormat?: XAxisFormat) => {
  if (!xAxisFormat) return {};

  return {
    title: {
      enabled: Boolean(xAxisFormat.showTitle),
      text: xAxisFormat.title,
      margin: 12,
      style: {
        fontWeight: 'bold',
        color:
          globalStyleConfig?.text.overrides.smallHeading?.color ||
          globalStyleConfig?.text.secondaryColor,
      },
    },
    className: GLOBAL_STYLE_CLASSNAMES.text.smallHeading.secondary,
    tickWidth: xAxisFormat.hideAxisTicks ? 0 : 1,
  };
};

export const yAxisFormat = (
  globalStyleConfig: GlobalStyleConfig,
  yAxisFormat?: BaseAxisFormat,
  colorOverride?: string,
) => {
  if (!yAxisFormat) return { title: { enabled: false } };

  return {
    title: {
      enabled: Boolean(yAxisFormat.showTitle),
      text: yAxisFormat.title,
      margin: 12,
      style: {
        fontWeight: 'bold',
        color:
          colorOverride ||
          globalStyleConfig?.text.overrides.smallHeading?.color ||
          globalStyleConfig?.text.secondaryColor,
      },
    },
    className: GLOBAL_STYLE_CLASSNAMES.text.smallHeading.secondary,
  };
};

export const formatLegend = (globalStyleConfig: GlobalStyleConfig, legendFormat?: LegendFormat) => {
  let fontFamily =
    globalStyleConfig?.text.overrides.smallBody?.font || globalStyleConfig?.text.secondaryFont;

  const styles = {
    itemStyle: {
      fontSize: `${
        globalStyleConfig.text.overrides.smallBody?.size ||
        globalStyleConfig.text.textSize + TEXT_SIZE_OFFSET_MAP['smallBody']
      }px`,
      color: `${
        globalStyleConfig?.text.overrides.smallBody?.color || globalStyleConfig?.text.secondaryColor
      }`,
      ...(fontFamily && { fontFamily }),
      fontWeight: 'normal',
    },
  };
  if (!legendFormat) return { ...LEGEND_CONFIG_BY_POS[LegendPosition.AUTO], ...styles };

  fontFamily =
    globalStyleConfig?.text.overrides.smallHeading?.font || globalStyleConfig?.text.secondaryFont;

  return {
    ...LEGEND_CONFIG_BY_POS[legendFormat.position || LegendPosition.AUTO],
    enabled: !legendFormat.hideLegend,
    title: {
      text: legendFormat.showTitle ? legendFormat.title : undefined,
      style: {
        fontWeight: 'bold',
        fontSize: `${
          globalStyleConfig?.text.overrides.smallHeading?.size ||
          globalStyleConfig?.text.textSize + TEXT_SIZE_OFFSET_MAP['smallHeading']
        }px`,
        color: `${
          globalStyleConfig?.text.overrides.smallHeading?.color ||
          globalStyleConfig?.text.secondaryColor
        }`,
        ...(fontFamily && { fontFamily }),
      },
    },
    ...styles,
  };
};

export const labelPositioner = (
  plotLeft: number,
  plotTop: number,
  plotHeight: number,
  point: TooltipPositionerPointObject,
  labelWidth: number,
  labelHeight: number,
) => {
  let yPosition = point.plotY;
  if (point.plotY + labelHeight > plotHeight) {
    yPosition = Math.max(plotTop - 10, plotHeight - labelHeight);
  }
  if (point.plotX - labelWidth < 0) {
    return {
      x: point.plotX + labelWidth / 2,
      y: yPosition,
    };
  }
  return {
    x: point.plotX - labelWidth,
    y: yPosition,
  };
};

const titleString = (s: string) => {
  if (s.length === 0) return s;
  else if (s.length === 1) return s.toUpperCase();

  return s[0].toUpperCase().concat(s.slice(1).toLowerCase());
};

export const formatLabel = (
  value: number | string,
  colType?: string,
  bucket?: string,
  dateFormatOverride?: string,
  stringFormatOverride?: { format?: STRING_FORMATS; replaceUnderscores?: boolean },
) => {
  if (colType) {
    if (DATE_TYPES.has(colType) && dateFormatOverride)
      // this is a customer-input override so we don't want to localize
      return dayjs.utc(value).format(dateFormatOverride);

    if (colType === STRING && stringFormatOverride) {
      let stringValue = String(value);
      if (stringFormatOverride.replaceUnderscores)
        stringValue = stringReplaceAll(stringValue, '_', ' ');
      switch (stringFormatOverride.format) {
        case STRING_FORMATS.CAMEL_CASE: {
          const parts = stringValue.trim().split(/\s+/);
          const titledParts = parts.map((part, i) =>
            i === 0 ? part.toLowerCase() : titleString(part),
          );
          return titledParts.join(' ');
        }
        case STRING_FORMATS.SENTENCE_CASE: {
          const parts = stringValue.trim().split(/\s+/);
          const titledParts = parts.map((part, i) =>
            i !== 0 ? part.toLowerCase() : titleString(part),
          );
          return titledParts.join(' ');
        }
        case STRING_FORMATS.LOWERCASE:
          return stringValue.toLowerCase();
        case STRING_FORMATS.UPPERCASE:
          return stringValue.toUpperCase();
        case STRING_FORMATS.TITLE_CASE: {
          const parts = stringValue.trim().split(/\s+/);
          const titledParts = parts.map(titleString);
          return titledParts.join(' ');
        }
        default:
          return stringValue;
      }
    }

    switch (bucket) {
      case PIVOT_AGG_TYPES.DATE_HOUR.id:
        return dayjs.utc(value).format(DATE_FORMAT_TO_LOCALIZED_FORMAT['HH:00 M/D']);
      case PIVOT_AGG_TYPES.DATE_DAY.id:
      case PIVOT_AGG_TYPES.DATE_WEEK.id:
        return dayjs.utc(value).format(DATE_FORMAT_TO_LOCALIZED_FORMAT['MM/DD/YYYY']);
      case PIVOT_AGG_TYPES.DATE_MONTH.id:
        return dayjs.utc(value).format(DATE_FORMAT_TO_LOCALIZED_FORMAT['MMM YYYY']);
      case PIVOT_AGG_TYPES.DATE_YEAR.id:
        return dayjs.utc(value).format(DATE_FORMAT_TO_LOCALIZED_FORMAT['YYYY']);
      case PIVOT_AGG_TYPES.DATE_PART_MONTH.id:
        return dayjs.months()[value as number];
      case PIVOT_AGG_TYPES.DATE_PART_WEEK_DAY.id:
        return dayjs.weekdays()[value as number];
      case PIVOT_AGG_TYPES.DATE_PART_HOUR.id:
        value = value as number;
        if (!localeUses12HourClock) return value.toString();
        if (value === 0) return '12am';
        if (value < 12) return `${value}am`;
        if (value === 12) return '12pm';
        else return `${value - 12}pm`;
    }
  }
  return String(value);
};

export const getColorPalette = (
  globalStyleConfig: GlobalStyleConfig,
  palette?: ColorPalette | ColorPaletteV2,
  customColors?: string,
) => {
  if (palette === ColorPalette.CUSTOM) {
    return constructCustomPaleete(customColors);
  }
  if (!palette) palette = ColorPaletteV2.CATEGORICAL;

  switch (palette) {
    case ColorPaletteV2.CATEGORICAL:
      return getCategoricalColors(globalStyleConfig);
    case ColorPaletteV2.DIVERGING:
      return getDivergingColors(globalStyleConfig);
    case ColorPaletteV2.GRADIENT:
      return getGradientColors(globalStyleConfig);
  }

  return PALETTE_TO_COLORS[palette];
};

export const constructCustomPaleete = (customColors?: string) => {
  if (!customColors) return;

  return customColors.split(',');
};

export const formatValue = ({
  value,
  decimalPlaces,
  formatId,
  isAbbreviated,
  multiplier,
  hasCommas,
  timeFormatId,
  customTimeFormat,
}: {
  value: number;
  decimalPlaces?: number;
  formatId?: string;
  isAbbreviated?: boolean;
  multiplier?: number;
  hasCommas?: boolean;
  timeFormatId?: string;
  customTimeFormat?: string;
}) => {
  value *= multiplier ?? 1;
  const separator = hasCommas ? ',' : '';

  const abbreviatedFormatArg = `.2${value < 1 && value > -1 ? 'r' : 's'}`;
  if (formatId === V2_NUMBER_FORMATS.CURRENCY.id) {
    if (isAbbreviated) return `${format(`$${abbreviatedFormatArg}`)(value)}`;
    return `${format(`$${separator}.${decimalPlaces}f`)(value)}`;
  } else if (formatId === V2_NUMBER_FORMATS.PERCENT.id) {
    if (isAbbreviated) return `${format(abbreviatedFormatArg)(value * 100)}%`;
    return `${format(`${separator}.${decimalPlaces}f`)(value * 100)}%`;
  } else if (formatId === V2_NUMBER_FORMATS.NUMBER.id) {
    if (isAbbreviated) {
      if (value < 100) return `${format(abbreviatedFormatArg)(value)}`;
      return `${format('.2s')(value)}`;
    }
    return `${format(`${separator}.${decimalPlaces}f`)(value)}`;
  } else if (formatId === V2_NUMBER_FORMATS.TIME.id) {
    return formatTimeFromSeconds(value, timeFormatId, customTimeFormat);
  } else {
    if (isAbbreviated) {
      return format(abbreviatedFormatArg)(value);
    }
    const decimalFormatArg = `.${decimalPlaces}f`;
    return format(`${separator}${decimalPlaces !== undefined ? decimalFormatArg : ''}`)(value);
  }
};

export const formatTimeFromSeconds = (
  time: number,
  timeFormatId?: string,
  customTimeFormat?: string,
) => {
  const days = Math.floor(time / (60 * 60 * 24));
  let remainingSeconds = time % (60 * 60 * 24);
  const hours = Math.floor(remainingSeconds / (60 * 60));
  remainingSeconds = remainingSeconds % (60 * 60);
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = Math.round(remainingSeconds % 60);

  if (timeFormatId === TIME_DIFF_FORMATS.ABBREVATION.id) {
    let amount = 0;
    let unit = '';
    if (days > 0) {
      amount = days + (hours >= 12 ? 1 : 0);
      unit = 'day';
    } else if (hours > 0) {
      amount = hours + (minutes >= 30 ? 1 : 0);
      unit = 'hour';
    } else if (minutes > 0) {
      amount = minutes + (seconds >= 30 ? 1 : 0);
      unit = 'minute';
    } else {
      amount = seconds;
      unit = 'second';
    }
    return `${amount} ${unit}${amount !== 1 ? 's' : ''}`;
  } else if (timeFormatId === TIME_DIFF_FORMATS.CUSTOM.id && customTimeFormat) {
    const hh = `${hours < 10 ? '0' : ''}${hours}`;
    const mm = `${minutes < 10 ? '0' : ''}${minutes}`;
    const ss = `${seconds < 10 ? '0' : ''}${seconds}`;

    let formatted = stringReplaceAll(customTimeFormat, 'hh', hh);
    formatted = stringReplaceAll(formatted, 'mm', mm);
    formatted = stringReplaceAll(formatted, 'ss', ss);
    formatted = stringReplaceAll(formatted, 'HH', String(hours));
    formatted = stringReplaceAll(formatted, 'MM', String(minutes));
    formatted = stringReplaceAll(formatted, 'SS', String(seconds));
    formatted = stringReplaceAll(formatted, 'DD', String(days));

    return formatted;
  }

  return `${days} day${days !== 1 ? 's' : ''}, ${hours}:${minutes}:${seconds}`;
};

export const stringReplaceAll = (s: string, match: string, newVal: string) => {
  const re = new RegExp(match, 'g');

  return s.replace(re, newVal);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const goalLineFormat = (
  configs?: GoalLineFormat[],
  variables?: DashboardVariableMap,
  dashboardDatasets?: Record<string, Dataset>,
): any => {
  if (!configs) return { plotLines: [] };

  return {
    plotLines: _.compact(
      configs.map((goalLineConfig) => {
        if (goalLineConfig.isGoalBand) return undefined;

        const valueString = replaceTemplatesWithValues(
          goalLineConfig.goalValue || '100',
          variables || {},
          dashboardDatasets,
        );

        const valueNum = parseFloat(valueString);

        if (isNaN(valueNum)) return undefined;

        return {
          color: goalLineConfig.lineColor || '#FF0000',
          dashStyle: goalLineConfig.lineType,
          value: valueNum,
          width: goalLineConfig.lineWidth || 1,
          zIndex: 1,
          label: {
            text: goalLineConfig.label || '',
            align: 'right',
            x: -4,
            y: -8,
            style: {
              color: goalLineConfig.labelColor || '#FF0000',
            },
          },
        };
      }),
    ),
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const goalBandFormat = (
  configs?: GoalLineFormat[],
  variables?: DashboardVariableMap,
  dashboardDatasets?: Record<string, Dataset>,
): any => {
  if (!configs) return { plotLines: [] };

  return {
    plotBands: _.compact(
      configs.map((goalLineConfig) => {
        if (!goalLineConfig.isGoalBand) return undefined;

        const startValueString = replaceTemplatesWithValues(
          String(goalLineConfig.goalValue || '100'),
          variables || {},
          dashboardDatasets,
        );
        const startValueNum = parseFloat(startValueString);

        const endValueString = replaceTemplatesWithValues(
          String(goalLineConfig.goalValueMax || String(Number.MAX_VALUE)),
          variables || {},
          dashboardDatasets,
        );
        const endValueNum = parseFloat(endValueString);

        if (isNaN(startValueNum) || isNaN(endValueNum)) return undefined;

        return {
          color: goalLineConfig.lineColor || '#FF0000',
          dashStyle: goalLineConfig.lineType,
          from: startValueNum,
          to: endValueNum,
          width: goalLineConfig.lineWidth || 1,
          zIndex: 1,
          label: {
            text: goalLineConfig.label || '',
            align: 'right',
            x: -4,
            style: {
              color: goalLineConfig.labelColor || '#FF0000',
            },
          },
        };
      }),
    ),
  };
};

export const isTwoDimVizInstructionsReadyToDisplay = (
  instructions?: V2TwoDimensionChartInstructions,
) => {
  if (instructions?.categoryColumn?.bucket?.id === DATE_PART_INPUT_AGG) {
    return !!instructions.categoryColumn.bucketElemId;
  }

  return !!(
    instructions &&
    instructions.categoryColumn?.column &&
    instructions.aggColumns &&
    instructions.aggColumns.length > 0
  );
};

export const areRequiredVariablesSetTwoDimViz = (
  variables: DashboardVariableMap,
  instructions?: V2TwoDimensionChartInstructions,
) => {
  if (instructions?.categoryColumn?.bucket?.id !== DATE_PART_INPUT_AGG) return true;

  const groupingElemId = instructions?.categoryColumn?.bucketElemId;

  if (!groupingElemId) return true;

  const groupingOption = variables[groupingElemId] as TrendGroupingOptions;

  return !!groupingOption;
};
