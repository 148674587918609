/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import SidePane from 'components/SidePane';
import { GlobalStyleConfig } from 'globalStyles/types';
import Button from 'shared/Button';
import BaseConfigSection from './BaseConfigSection';
import CardsConfigSection from './CardsConfigSection';
import TextConfigSection from './TextConfigSection';
import VisualizationsConfigSection from './VisualizationsConfigSection';
import FlexBox from 'components/core/FlexBox';
import { WIDE_SIDE_PANE_WIDTH } from 'components/SidePane';

const useStyles = makeStyles((theme: Theme) => ({
  paneContent: {
    padding: `0 ${theme.spacing(6)}px`,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(10),
  },
  sectionSpacing: {
    marginTop: theme.spacing(10),
  },
  buttonSection: {
    borderTop: `1px solid ${theme.palette.ds.grey200}`,
    padding: `0 ${theme.spacing(6)}px`,
    backgroundColor: theme.palette.ds.white,
    bottom: 0,
    position: 'fixed',
    width: WIDE_SIDE_PANE_WIDTH,
  },
  button: {
    margin: `${theme.spacing(4)}px 0`,
    flex: 1,
  },
  cancelButton: {
    marginRight: theme.spacing(4),
  },
}));

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
  saveConfig: () => void;
  resetConfig: () => void;
  googleFonts?: string[];
};

export default function CustomizeStyleConfigPane({
  styleConfig,
  updateConfig,
  saveConfig,
  resetConfig,
  googleFonts,
}: Props) {
  const classes = useStyles();

  return (
    <SidePane wide title="Styling">
      <div className={classes.paneContent}>
        <BaseConfigSection styleConfig={styleConfig} updateConfig={updateConfig} />
        <div className={classes.sectionSpacing}>
          <CardsConfigSection styleConfig={styleConfig} updateConfig={updateConfig} />
        </div>
        <div className={classes.sectionSpacing}>
          <TextConfigSection
            styleConfig={styleConfig}
            updateConfig={updateConfig}
            googleFonts={googleFonts}
          />
        </div>
        <div className={classes.sectionSpacing}>
          <VisualizationsConfigSection styleConfig={styleConfig} updateConfig={updateConfig} />
        </div>
        <div className={classes.sectionSpacing}></div>
      </div>
      <FlexBox className={classes.buttonSection}>
        <Button
          type="secondary"
          className={cx(classes.button, classes.cancelButton)}
          onClick={resetConfig}
          text="Reset"
        />
        <Button
          type="primary"
          className={classes.button}
          onClick={() => {
            saveConfig();
          }}
          text="Save"
        />
      </FlexBox>
    </SidePane>
  );
}
