/** @format */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';

import ColorPickerButton from 'shared/ColorPicker';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2KPITrendInstructions } from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';
import { getCategoricalColors } from 'globalStyles';
import InputLabel from 'shared/InputLabel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
  colorPaletteDisplay: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  colorPicker: {
    width: '100%',
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2KPITrendInstructions;
};

export default function KPITrendTrendConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { globalStyleConfig } = useSelector((state: ReduxState) => ({
    globalStyleConfig: state.styleConfig.current,
  }));

  // Some options won't work with the Charts version of the KPI Trend panel so hide them when the trend lines are being shown.
  // TODO: Migrate the chart w/ trend lines enabled to use these options
  // https://linear.app/explo/issue/PD-934/migrate-kpi-trend-w-lines-enabled-to-use-trend-change-options
  return (
    <div className={classes.root}>
      <SwitchInput
        className={classes.configInput}
        switchOn={instructions?.hideTrendLines}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);
          dispatch(
            updateVisualizeOperation(
              { ...newInstructions, hideTrendLines: !newInstructions.hideTrendLines },
              visualizationType as OPERATION_TYPES,
            ),
          );
        }}
        label="Hide Trend Lines"
      />
      {instructions?.hideTrendLines && (
        <>
          <SwitchInput
            className={classes.configInput}
            switchOn={instructions.displayFormat?.showAbsoluteChange}
            onChange={() => {
              const newInstructions = cloneDeep(instructions);

              newInstructions.displayFormat = {
                ...newInstructions.displayFormat,
                showAbsoluteChange: !instructions.displayFormat?.showAbsoluteChange,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            label="Show Absolute Trend Change"
          />
          <SwitchInput
            className={classes.configInput}
            switchOn={instructions.displayFormat?.showTrendChangePeriodLabel}
            onChange={() => {
              const newInstructions = cloneDeep(instructions);

              newInstructions.displayFormat = {
                ...newInstructions.displayFormat,
                showTrendChangePeriodLabel: !instructions.displayFormat?.showTrendChangePeriodLabel,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            label="Show Trend Change Period Label"
          />
          <SwitchInput
            className={classes.configInput}
            switchOn={instructions.displayFormat?.useTrendTag}
            onChange={() => {
              const newInstructions = cloneDeep(instructions);

              newInstructions.displayFormat = {
                ...newInstructions.displayFormat,
                useTrendTag: !instructions.displayFormat?.useTrendTag,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }}
            label="Use Trend Tag Format"
          />
        </>
      )}
      {!instructions?.hideTrendLines && (
        <>
          <div className={classes.configInput}>
            <InputLabel text="Main Trend Color" />
            <ColorPickerButton
              className={classes.colorPicker}
              color={
                instructions.displayFormat?.periodColor ||
                getCategoricalColors(globalStyleConfig)[0]
              }
              colorPalette={getCategoricalColors(globalStyleConfig)}
              onColorChange={(newColor: string) => {
                const newInstructions = cloneDeep(instructions);

                newInstructions.displayFormat = {
                  ...newInstructions.displayFormat,
                  periodColor: newColor,
                };
                dispatch(
                  updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                );
              }}
            />
          </div>
          <div className={classes.configInput}>
            <InputLabel text="Comparison Trend Color" />
            <ColorPickerButton
              className={classes.colorPicker}
              color={instructions.displayFormat?.comparisonColor || '#757575'}
              colorPalette={getCategoricalColors(globalStyleConfig)}
              onColorChange={(newColor: string) => {
                const newInstructions = cloneDeep(instructions);

                newInstructions.displayFormat = {
                  ...newInstructions.displayFormat,
                  comparisonColor: newColor,
                };
                dispatch(
                  updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                );
              }}
            />
          </div>
        </>
      )}
      <SwitchInput
        className={classes.configInput}
        switchOn={instructions.displayFormat?.trendColorsReversed}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.displayFormat = {
            ...newInstructions.displayFormat,
            trendColorsReversed: !instructions.displayFormat?.trendColorsReversed,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Reverse Trend Label Colors"
      />
    </div>
  );
}
