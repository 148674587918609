/** @format */

import React from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';

import DashboardButtonGroupInput from 'components/dashboardButtonGroupInput';

import { DateGroupSwitchConfig, DashboardVariable } from 'types/dashboardTypes';
import { getDateGroupSwitchOptions } from 'utils/dashboardUtils';

const styles = () =>
  createStyles({
    root: {
      '& .bp3-button-group .bp3-button, .bp3-button-group .bp3-button.bp3-active, .bp3-button-group .bp3-button:hover': {
        zIndex: 0,
      },
    },
  });

type PassedProps = {
  config: DateGroupSwitchConfig;
  value?: DashboardVariable;
  onNewValueSelect: (newvalue: DashboardVariable) => void;
  disabled?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

class DashboardDateGroupSwitchElement extends React.Component<Props> {
  render() {
    const { classes, config, disabled, value, onNewValueSelect } = this.props;

    const options = getDateGroupSwitchOptions(config);

    let selectedItem = undefined;
    if (value) {
      selectedItem = options.find((option) => option.id === value);
    }

    return (
      <div className={classes.root}>
        <DashboardButtonGroupInput
          disabled={disabled}
          fillWidth
          preventUnselect
          selectedItem={selectedItem}
          onChange={(item) => {
            onNewValueSelect(item?.id);
          }}
          options={options}
          label={config.label}
          useFakeLabel={config.label === ''}
        />
      </div>
    );
  }
}

export default withStyles(styles)(DashboardDateGroupSwitchElement);
