/** @format */

import React from 'react';
import { connect } from 'react-redux';
import validator from 'validator';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Classes, Dialog, Intent, FormGroup } from '@blueprintjs/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import Button from 'shared/Button';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';

import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';

const useStyles = (theme: Theme) =>
  createStyles({
    formGroupValidationErrorState: {
      marginBottom: theme.spacing(0),
    },
    formGroupValidationNoError: {
      marginBottom: theme.spacing(5),
    },
  });

type PassedProps = {
  resourceName?: string;
  modalOpen: boolean;
  closeModal: () => void;
  onSubmit: (newDashboardTitle: string) => void;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  PassedProps &
  WithStyles<typeof useStyles>;

const InviteTeammatesModal = (props: Props) => {
  const { classes, inviteTeammateLoading } = props;
  const [textFieldVal, setTextFieldVal] = React.useState('');
  const isErrorState = textFieldVal !== '' && !validator.isEmail(textFieldVal);

  return (
    <Dialog isOpen={props.modalOpen} onClose={() => props.closeModal()} title="Invite Team Members">
      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          className={
            isErrorState
              ? classes.formGroupValidationErrorState
              : classes.formGroupValidationNoError
          }
          helperText={isErrorState ? 'Invalid Email' : undefined}
          intent={isErrorState ? Intent.DANGER : Intent.NONE}
          labelFor="text-input">
          <InputGroup
            intent={isErrorState ? Intent.DANGER : Intent.NONE}
            large={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTextFieldVal(e.target.value);
            }}
            placeholder="name@domain.com"
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                textFieldVal.trim() &&
                !isErrorState &&
                !inviteTeammateLoading
              )
                props.onSubmit(textFieldVal);
            }}
            defaultValue={props.resourceName}
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            type="primary"
            onClick={() => {
              if (textFieldVal.trim() && !isErrorState && !inviteTeammateLoading)
                props.onSubmit(textFieldVal);
            }}
            disabled={!textFieldVal.trim() || isErrorState}
            loading={inviteTeammateLoading}
            text="Send Invites"
          />
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  inviteTeammateLoading: createLoadingSelector([ACTION.INVITE_TEAMMATE], false)(state),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(useStyles)(InviteTeammatesModal));
