/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/index';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    textAlign: 'right',
  },
  docLinkContainer: {
    marginTop: theme.spacing(1),
  },
  docLink: {
    color: theme.palette.ds.grey900,
  },
}));

export type Props = {
  className?: string;
};

const ConnectFAQs: React.FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.docLinkContainer}>
        <a
          href="https://docs.explo.co/database-setup/connecting-to-your-database-or-data-warehouse"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.docLink}>
          FAQs
        </a>
      </div>
      <div className={classes.docLinkContainer}>
        <a
          href="https://docs.explo.co/security/security-overview"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.docLink}>
          Security Overview
        </a>
      </div>
    </div>
  );
};

export default ConnectFAQs;
