/** @format */

import cloneDeep from 'lodash/cloneDeep';

import { PivotOperation, TableColumn } from 'actions/types';
import {
  EMPTY_LINE_OR_BAR_CHART_INSTR,
  EMPTY_PIE_CHART_INSTR,
} from 'constants/dataPanelEditorConstants';
import { VisualizeLineOrBarChartInstructions, VisualizeFunnelInstruction } from 'constants/types';
import { COLOR_LIST } from 'constants/colorConstants';
import { cleanPivotConfig } from 'utils/dataPanelConfigUtils';

export const deriveVisInstrFromPivotInstr = (
  pivotOperation: PivotOperation,
  schema: TableColumn[],
  visLineOrBarInstr: VisualizeLineOrBarChartInstructions,
) => {
  const cleanedPivotOp = cleanPivotConfig(cloneDeep(pivotOperation));

  // if complete instructions
  if (
    cleanedPivotOp.instructions.pivotedOnCols.length > 0 &&
    cleanedPivotOp.instructions.aggregations.length > 0
  ) {
    visLineOrBarInstr.xAxisColumn = {
      name: schema[0].name,
      type: schema[0].type,
    };
    const shiftBy = cleanedPivotOp.instructions.pivotedOnCols.length;
    visLineOrBarInstr.lineColumns = cleanedPivotOp.instructions.aggregations.map((agg, index) => ({
      column: {
        name: schema[index + shiftBy].name,
        type: schema[index + shiftBy].type,
      },
      color: COLOR_LIST[index % COLOR_LIST.length].primary,
    }));
  } else {
    visLineOrBarInstr = EMPTY_LINE_OR_BAR_CHART_INSTR;
  }
  return visLineOrBarInstr;
};

export const deriveFunnelChartInstr = (pivotOperation: PivotOperation, schema: TableColumn[]) => {
  const funnelInstructions: VisualizeFunnelInstruction = {};
  const cleanedPivotOp = cleanPivotConfig(cloneDeep(pivotOperation));

  // if complete instructions
  if (
    cleanedPivotOp.instructions.pivotedOnCols.length > 0 &&
    cleanedPivotOp.instructions.aggregations.length > 0
  ) {
    funnelInstructions.categoryColumn = {
      name: schema[0].name,
      type: schema[0].type,
    };
    funnelInstructions.amountColumn = {
      name: schema[1].name,
      type: schema[1].type,
    };
  }

  return funnelInstructions;
};

export const derivePieChartInstr = (pivotOperation: PivotOperation, schema: TableColumn[]) => {
  const pieChartInstructions = EMPTY_PIE_CHART_INSTR;
  const cleanedPivotOp = cleanPivotConfig(cloneDeep(pivotOperation));

  // if complete instructions
  if (
    cleanedPivotOp.instructions.pivotedOnCols.length > 0 &&
    cleanedPivotOp.instructions.aggregations.length > 0
  ) {
    pieChartInstructions.categoryColumn = {
      name: schema[0].name,
      type: schema[0].type,
    };
    pieChartInstructions.valueColumn = {
      name: schema[1].name,
      type: schema[1].type,
    };
  }

  return pieChartInstructions;
};
