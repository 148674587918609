/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { withStyles, WithStyles, withTheme, WithTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { connect } from 'react-redux';

import { saveDashboardDraft } from 'actions/dashboardV2Actions';
import FlexBox, { VerticalAlignment, HorizontalAlignment } from 'components/core/FlexBox';
import Button from 'shared/Button';
import DropdownSelect from 'shared/DropdownSelect';
import SavingInfo from './SavingInfo';

import { SIDE_PANE_HEADER_HEIGHT } from 'components/SidePane';
import SidePaneHeader from 'components/SidePaneHeader';
import { DashboardVersion, DashboardVersionInfo, EndUserGroup } from 'actions/types';
import { VIEW_MODE } from 'types/dashboardTypes';
import { ReduxState } from 'reducers/rootReducer';
import { createStyles } from '@material-ui/core/index';
import { EMBED_ICON, LEFT_PANE_ICON, RIGHT_PANE_ICON, PDF_ICON } from 'constants/iconConstants';
import FlexItem from 'components/core/FlexItem';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: SIDE_PANE_HEADER_HEIGHT,
      backgroundColor: theme.palette.ds.white,
      borderBottom: `1px solid ${theme.palette.ds.grey400}`,
      padding: `0 ${theme.spacing(5)}px`,
      width: '100%',
    },
    viewAsText: {
      marginRight: theme.spacing(3),
      width: 'max-content',
    },
    headerDropdown: {
      marginRight: theme.spacing(3),
    },
    editButtons: {
      marginLeft: theme.spacing(3),
    },
    header: {
      marginLeft: -theme.spacing(5),
      marginRight: theme.spacing(5),
      borderTop: 'none',
      borderBottom: 'none',
    },
    manageVersionsBtn: {
      marginRight: theme.spacing(3),
      width: 'max-content',
    },
    savingInfo: {
      marginRight: theme.spacing(3),
    },
    embedViewModeButton: {
      '&.bp3-button': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        padding: theme.spacing(2),
      },
    },
    pdfViewModeButton: {
      '&.bp3-button': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        padding: theme.spacing(2),
      },
    },
    inactiveViewModeButton: {
      '&.bp3-button': {
        border: `1px solid ${theme.palette.ds.grey400}`,
        backgroundColor: theme.palette.ds.grey100,
      },
      '&.bp3-button:hover': {
        backgroundColor: theme.palette.ds.hovered.grey100,
      },
      '&.bp3-button:active': {
        backgroundColor: theme.palette.ds.pressed.grey100,
      },
    },
    activeViewModeButton: {
      '&.bp3-button': {
        border: `1px solid ${theme.palette.ds.blue}`,
        backgroundColor: theme.palette.ds.lightBlue,
      },
      '&.bp3-button:hover': {
        backgroundColor: theme.palette.ds.hovered.lightBlue,
      },
      '&.bp3-button:active': {
        backgroundColor: theme.palette.ds.pressed.lightBlue,
      },
    },
    layoutButtonGroup: {
      marginRight: theme.spacing(6),
    },
    isLayoutClosed: {
      color: `${theme.palette.ds.grey400} !important`,
    },
    layoutButton: {
      height: 34,

      '&.bp3-button': {
        padding: `${theme.spacing(2)}px 0`,
      },
    },
    isLayoutHidden: {
      visibility: 'hidden',
    },
  });

type PassedProps = {
  className?: string;
  headerTitle: string;
  dashboardVersionInfo: DashboardVersionInfo;
  endUserGroups: EndUserGroup[];
  selectUserGroup: (userGroupId?: number) => void;
  selectedUserGroupId?: number;
  viewMode: VIEW_MODE;
  setViewMode: (viewMode: VIEW_MODE) => void;
  inEditMode?: boolean;
  onEditClicked: () => void;
  onPreviewClicked: () => void;
  manageVersionsClicked: () => void;
  switchEditModeLoading?: boolean;
  onReturnMostCurrentVersionClicked: () => void;
  allDashboardVersions?: DashboardVersion[];
  rightPaneIsOpen: boolean;
  leftPaneIsOpen: boolean;
  toggleLeftPane: () => void;
  toggleRightPane: () => void;
};

type Props = PassedProps &
  WithStyles<typeof styles> &
  WithTheme &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

class DashboardEditBanner extends React.Component<Props> {
  render() {
    const { classes, className, headerTitle } = this.props;

    return (
      <FlexBox
        horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}
        verticalAlignment={VerticalAlignment.CENTER}
        className={cx(classes.root, className)}>
        <SidePaneHeader className={classes.header} title={headerTitle} backButtonPath="/home" />
        <FlexItem>{this.renderCustomerSelector()}</FlexItem>
        <FlexBox flex={1} horizontalAlignment={HorizontalAlignment.CENTER}>
          {this.renderEditorLayoutButtonsGroup()}
          {this.renderViewModeButtonGroup()}
        </FlexBox>
        <FlexBox flex={1} horizontalAlignment={HorizontalAlignment.END}>
          {this.renderEditButtons()}
        </FlexBox>
      </FlexBox>
    );
  }

  renderViewModeButtonGroup = () => {
    const { classes, setViewMode, theme, viewMode } = this.props;
    return (
      <div>
        <Button
          minimal
          className={cx(classes.embedViewModeButton, {
            [classes.activeViewModeButton]: viewMode === VIEW_MODE.DEFAULT,
            [classes.inactiveViewModeButton]: viewMode !== VIEW_MODE.DEFAULT,
          })}
          icon={EMBED_ICON(
            viewMode === VIEW_MODE.DEFAULT ? theme.palette.ds.blue : theme.palette.ds.grey400,
          )}
          onClick={() => setViewMode(VIEW_MODE.DEFAULT)}
        />
        <Button
          minimal
          className={cx(classes.pdfViewModeButton, {
            [classes.activeViewModeButton]: viewMode === VIEW_MODE.PDF,
            [classes.inactiveViewModeButton]: viewMode !== VIEW_MODE.PDF,
          })}
          icon={PDF_ICON(
            viewMode === VIEW_MODE.PDF ? theme.palette.ds.blue : theme.palette.ds.grey400,
          )}
          onClick={() => setViewMode(VIEW_MODE.PDF)}
        />
      </div>
    );
  };

  renderEditorLayoutButtonsGroup = () => {
    const {
      classes,
      leftPaneIsOpen,
      rightPaneIsOpen,
      toggleLeftPane,
      toggleRightPane,
      inEditMode,
      viewMode,
    } = this.props;

    if (!inEditMode) return;

    return (
      <FlexBox
        className={cx(classes.layoutButtonGroup, {
          [classes.isLayoutHidden]: viewMode !== VIEW_MODE.DEFAULT,
        })}
        verticalAlignment={VerticalAlignment.CENTER}>
        <Button
          className={cx(classes.layoutButton, { [classes.isLayoutClosed]: !leftPaneIsOpen })}
          minimal
          icon={LEFT_PANE_ICON}
          onClick={toggleLeftPane}
        />
        <Button
          className={cx(classes.layoutButton, { [classes.isLayoutClosed]: !rightPaneIsOpen })}
          minimal
          icon={RIGHT_PANE_ICON}
          onClick={toggleRightPane}
        />
      </FlexBox>
    );
  };

  renderCustomerSelector = () => {
    const { classes, endUserGroups, selectUserGroup, selectedUserGroupId } = this.props;

    const groupEndUserGroupsById = _.indexBy(endUserGroups, 'id');
    const selectedUserGroup = selectedUserGroupId && groupEndUserGroupsById[selectedUserGroupId];

    return (
      <FlexBox verticalAlignment={VerticalAlignment.CENTER}>
        <div className={classes.viewAsText}>Viewing as</div>
        <DropdownSelect
          minimal
          fillWidth
          showIcon
          selectedItem={
            selectedUserGroup
              ? {
                  id: String(selectedUserGroup.id),
                  name: selectedUserGroup.name,
                }
              : undefined
          }
          onChange={(item) => selectUserGroup(parseInt(item.id))}
          noSelectionText="Select customer"
          options={endUserGroups.map((userGroup) => ({
            id: String(userGroup.id),
            name: userGroup.name,
          }))}
          showCancelBtn
          onCancelClick={() => selectUserGroup(undefined)}
        />
      </FlexBox>
    );
  };

  renderEditButtons = () => {
    const { classes, inEditMode } = this.props;

    return (
      <FlexBox verticalAlignment={VerticalAlignment.CENTER} className={classes.editButtons}>
        {this.renderSavingInfo()}
        <FlexBox>
          {inEditMode ? this.renderPreviewDashboardButton() : this.renderEditDashboardButton()}
        </FlexBox>
      </FlexBox>
    );
  };

  renderSavingInfo = () => {
    const { classes, dashboardVersionInfo } = this.props;

    return <SavingInfo className={classes.savingInfo} versionInfo={dashboardVersionInfo} />;
  };

  renderEditDashboardButton = () => {
    const {
      classes,
      dashboardVersionInfo,
      onEditClicked,
      manageVersionsClicked,
      switchEditModeLoading,
      allDashboardVersions,
      onReturnMostCurrentVersionClicked,
    } = this.props;

    const isMostRecentVersion =
      dashboardVersionInfo.version_number !== allDashboardVersions?.length;

    // There should always be at least 1 dashboard version (the one created when the dashboard is created)
    // so if the length of `allDashboardVersions` is 0, the dashboard versions haven't loaded yet
    if (allDashboardVersions?.length && isMostRecentVersion) {
      return (
        <>
          <Button
            className={classes.manageVersionsBtn}
            disabled={switchEditModeLoading}
            type="secondary"
            onClick={() => manageVersionsClicked()}
            text="Version Control"
          />

          <Button
            disabled={switchEditModeLoading}
            loading={switchEditModeLoading}
            type="primary"
            onClick={() => onReturnMostCurrentVersionClicked()}
            text="Return to Current Draft"
          />
        </>
      );
    }

    return (
      <>
        <Button
          className={classes.manageVersionsBtn}
          disabled={switchEditModeLoading}
          type="secondary"
          onClick={() => !switchEditModeLoading && manageVersionsClicked()}
          text="Version Control"
        />

        <Button
          disabled={switchEditModeLoading || allDashboardVersions?.length === 0}
          loading={switchEditModeLoading}
          type="primary"
          onClick={() => !switchEditModeLoading && onEditClicked()}
          text="Edit"
        />
      </>
    );
  };

  renderPreviewDashboardButton = () => {
    const { onPreviewClicked, switchEditModeLoading } = this.props;
    return (
      <Button
        disabled={switchEditModeLoading}
        loading={switchEditModeLoading}
        type="primary"
        onClick={() => !switchEditModeLoading && onPreviewClicked()}
        text="Preview"
      />
    );
  };
}
const mapStateToProps = (state: ReduxState) => ({
  currentDashboardTemplate: state.dashboard.currentDashboardTemplate,
  currentDashboardEditConfig: state.dashboardEditConfig,
});

const mapDispatchToProps = {
  saveDashboardDraft,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withTheme(DashboardEditBanner)));
