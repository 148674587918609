/** @format */

import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';

import DropdownSelect from 'shared/DropdownSelect';
import ColorPaletteDisplay from 'components/ColorPaletteDisplay';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { ColorPaletteV2, OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { ColorPalette } from 'constants/types';
import { SelectedDropdownInputItem } from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
  colorPaletteDisplay: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2TwoDimensionChartInstructions;
};

export default function ColorConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  /**
   * We use useSelector here instead of useContext because the context around content other than the DashboardLayout
   * uses the default theme to avoid using custom styles in Explo app components. But here we want to use the user's configured
   * theme to determine what to display, not the default.
   */
  const { globalStyleConfig } = useSelector((state: ReduxState) => ({
    globalStyleConfig: state.styleConfig.current,
  }));

  const defaultPalette = ColorPaletteV2.CATEGORICAL;
  const selectedPalette = instructions.colorFormat?.selectedPalette || defaultPalette;

  const paletteOptions: SelectedDropdownInputItem[] = Object.values(ColorPalette).map(
    (palette) => ({
      id: palette,
      name: palette,
    }),
  );
  const paletteOptionsV2: SelectedDropdownInputItem[] = Object.values(ColorPaletteV2).map(
    (palette) => ({
      id: palette,
      name: palette,
    }),
  );

  return (
    <div className={classes.root}>
      <DropdownSelect
        containerClassName={classes.configInput}
        selectedItem={{ id: selectedPalette, name: selectedPalette }}
        onChange={(item: SelectedDropdownInputItem) => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.colorFormat = {
            ...newInstructions.colorFormat,
            selectedPalette: item.id as ColorPalette | ColorPaletteV2,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        filterable={false}
        options={paletteOptions.concat(paletteOptionsV2)}
        noSelectionText="Select Palette"
        minimal
        btnMinimal
        fillWidth
        label="Color Palette"
      />
      <ColorPaletteDisplay
        globalStyleConfig={globalStyleConfig}
        className={cx(classes.configInput, classes.colorPaletteDisplay)}
        palette={selectedPalette}
        customColors={instructions.colorFormat?.customColors}
        saveCustomColorConfig={(colorPalette: string[]) => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.colorFormat = {
            ...newInstructions.colorFormat,
            selectedPalette: ColorPalette.CUSTOM,
            customColors: colorPalette.join(','),
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
      />
    </div>
  );
}
