/** @format */

import { Layout } from 'react-grid-layout';

import { TrendGroupingOptions } from 'constants/dataConstants';

export enum TEXT_ELEM_HORIZ_ALIGNMENTS {
  LEFT = 'LEFT_ALIGN',
  CENTER = 'CENTER_ALIGN',
  RIGHT = 'RIGHT_ALIGN',
}

export enum TEXT_ELEM_VERTICAL_ALIGNMENTS {
  TOP = 'TOP',
  CENTER = 'CENTER',
  BOTTOM = 'BOTTOM',
}

export enum TEXT_ELEM_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum DASHBOARD_ELEMENT_TYPES {
  DATA_TABLE = 'DATA_TABLE',
  REPORT_BUILDER = 'REPORT_BUILDER',
  DATA_PANEL = 'DATA_PANEL',
  TEXT = 'TEXT',
  DROPDOWN = 'DROPDOWN',
  TIME_PERIOD_DROPDOWN = 'TIME_PERIOD_DROPDOWN',
  MULTISELECT = 'MULTISELECT',
  DATEPICKER = 'DATEPICKER',
  DATE_RANGE_PICKER = 'DATE_RANGE_PICKER',
  EXPORT = 'EXPORT',
  CONTAINER = 'CONTAINER',
  BAR_CHART = 'BAR_CHART',
  SWITCH = 'SWITCH',
  DATE_GROUP_SWITCH = 'DATE_GROUP_SWITCH',
  IMAGE = 'IMAGE',
}

export type DashboardVariable =
  | string
  | number
  | string[]
  | number[]
  | Date
  | undefined
  | { startDate: Date; endDate: Date }
  | { category: any; color?: any };
export type DashboardVariableMap = Record<string, DashboardVariable>;

export type TextElemHorizAlignments =
  | TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER
  | TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT
  | TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT;

export type TextElemVerticalAlignments =
  | TEXT_ELEM_VERTICAL_ALIGNMENTS.CENTER
  | TEXT_ELEM_VERTICAL_ALIGNMENTS.TOP
  | TEXT_ELEM_VERTICAL_ALIGNMENTS.BOTTOM;

export type TextDashboardElemConfig = {
  text?: string;
  renderMarkdown?: boolean;
  renderHTML?: boolean;
  alignmentHoriz: TextElemHorizAlignments;
  alignmentVertical: TextElemVerticalAlignments;
  textColor: string;
  textSize?: TEXT_ELEM_SIZES;
  queryTables?: { id: string; name: string }[];
};

export type DropdownValuesConfig = {
  valuesSource: string;
  queryTable?: { id: string; name: string };
  queryValueColumn?: { id: string; name: string };
  queryDisplayColumn?: { id: string; name: string };
  queryDefaultFirstValue?: boolean;
  manualValues: string;
  manualDisplays: string;
  manualDefaultValue?: string;
};

export type DropdownDashboardElemConfig = {
  disableCancel?: boolean;
  disableOnNoItems?: boolean;
  disableSearch?: boolean;
  initialValue?: string;
  label: string;
  placeholderText?: string;
  valuesConfig: DropdownValuesConfig;
};

export type TimePeriodDropdownDashboardElemConfig = {
  label: string;
  initialValue?: string;
  placeholderText?: string;
  enableSearch?: boolean;
  enableCancel?: boolean;
  values: { id: string; value: number; name: string }[];
  defaultValue?: number;
};

export type DatepickerElemConfig = {
  label: string;
  hideTimeSelect?: boolean;
  initialValue?: string;
  placeholderText?: string;
  valueFormat: string;
  defaultType: DEFAULT_DATE_TYPES;
  dateRangeType: DATE_RANGE_TYPES;
  defaultValue?: string | Date;
  disableCancel?: boolean;
  relativeDefaultValue?: RELATIVE_DATE_OPTIONS;
  relativeDateRange?: RELATIVE_DATE_RANGES;
  minValue?: Date;
  maxValue?: Date;
};

export type DateRangePickerElemConfig = {
  label: string;
  defaultDateRange?: DEFAULT_DATE_RANGES;
  includeRangeDropdown?: boolean;
  disableCancel?: boolean;
};

export type SwitchElemConfig = DropdownDashboardElemConfig;

export type ExportElemConfig = {
  label: string;
  passwordEnabled: boolean;
  siteTitle?: string;
  faviconUrl?: string;
};

export type ContainerElemConfig = {
  layout: Layout[];
  pdfLayout?: Layout[];
};

export type ImageElemConfig = {
  imageUrl?: string;
  fitEntireImage?: boolean;
};

export type DateGroupSwitchConfig = {
  label?: string;
  groupingOptionByType?: Record<string, { name?: string; exclude?: boolean }>;
  defaultGroupingOption: TrendGroupingOptions;
};

export type DashboardElementConfig =
  | TextDashboardElemConfig
  | DropdownDashboardElemConfig
  | DatepickerElemConfig
  | DateRangePickerElemConfig
  | ExportElemConfig
  | SwitchElemConfig
  | ContainerElemConfig
  | ImageElemConfig
  | DateGroupSwitchConfig
  | TimePeriodDropdownDashboardElemConfig;

export interface DashboardElement {
  id: string;
  name: string;
  element_type: DASHBOARD_ELEMENT_TYPES;
  container_id?: string;
  dashboard_template_id?: number;
  dashboard_instance_id?: number;
  config: DashboardElementConfig;
}
export type NumberColumnMetrics = {
  min?: number;
  avg?: number;
  max?: number;
};
export interface MetricsByColumn {
  [columnName: string]: NumberColumnMetrics;
}

export enum RELATIVE_DATE_OPTIONS {
  CURRENT_DAY = 'Current day',
  SEVEN_DAYS_AGO = '7 days ago',
  THIRTY_DAYS_AGO = '30 days ago',
  ONE_YEAR_AGO = '365 days ago',
  START_OF_WEEK = 'Start of the week (Sunday)',
  START_OF_MONTH = 'Start of the month',
  START_OF_YEAR = 'Start of the year',
  END_OF_WEEK = 'End of the week',
  END_OF_MONTH = 'End of the month',
  END_OF_YEAR = 'End of the year',
}

export enum RELATIVE_DATE_RANGES {
  THIS_WEEK = 'This week',
  THIS_MONTH = 'This month',
  THIS_YEAR = 'This year',
  WITHIN_A_WEEK = 'Within 7 days',
  WITHIN_A_MONTH = 'Within 30 days',
  WITHIN_A_YEAR = 'Within 365 days',
  WITHIN_THREE_YEARS = 'Within 3 years',
  WITHIN_FIVE_YEARS = 'Within 5 years',
  WITHIN_TEN_YEARS = 'Within 10 years',
  PAST_DATES = 'Past dates only',
}

export enum DEFAULT_DATE_RANGES {
  THIS_WEEK = 'This week',
  THIS_MONTH = 'This month',
  THIS_YEAR = 'This year',
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  LAST_3_MONTHS = 'Last 3 months',
  LAST_YEAR = 'Last year',
}

export enum DEFAULT_DATE_TYPES {
  RELATIVE = 'relative',
  EXACT = 'exact',
}

export enum DATE_RANGE_TYPES {
  RELATIVE = 'relative',
  EXACT = 'exact',
}

export enum VIEW_MODE {
  DEFAULT = 'default',
  SHARE = 'share',
  PDF = 'pdf',
}
