/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';
import { useDrag } from 'react-dnd';
import { Icon } from '@blueprintjs/core';

import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';
import { ItemTypes } from 'constants/dragAndDrop';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: 3,

    '&:hover': {
      backgroundColor: theme.palette.ds.lightBlue,
      cursor: 'pointer',
    },
  },
  name: {
    marginLeft: theme.spacing(2),
  },
}));

export type Props = {
  name: string;
  type: string;
  disableDrag?: boolean;
};

export default function VisualizationConfigTableColumns({ name, type, disableDrag }: Props) {
  const classes = useStyles();
  const dragData = useDrag({
    item: { type: ItemTypes.CONFIGURATION_COLUMN, data: { name, type } },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: !disableDrag,
  });

  return (
    <div className={classes.root} ref={dragData[1]}>
      <Icon icon={SCHEMA_DATA_TYPES_BY_ID[type].icon} />
      <div className={classes.name}>{name}</div>
    </div>
  );
}
