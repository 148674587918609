/** @format */

import React from 'react';
import _ from 'underscore';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

import { DropdownDashboardElemConfig, DashboardVariable } from 'types/dashboardTypes';
import DropdownSelect from 'shared/DropdownSelect';
import { Dataset } from 'actions/types';
import { INPUT_TYPES } from 'pages/dashboardPage/elementConfig/dropdownElementConfigPanel';
import { DropdownOption, SelectedDropdownInputItem } from 'constants/types';

type PassedProps = {
  config: DropdownDashboardElemConfig;
  dashboardDatasets: { [datasetId: string]: Dataset };
  onNewValueSelect: (newvalue: DashboardVariable) => void;
  value?: DashboardVariable;
  disabled?: boolean;
};

type Props = PassedProps;

class DashboardDropdownElement extends React.Component<Props> {
  render() {
    const { config, value, onNewValueSelect, dashboardDatasets, disabled } = this.props;

    const options = constructOptions(config, dashboardDatasets);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let selectedOption = _.find(options, (option: any) => option.value === value);

    // If the selected value is not in the options, it is coming from a default variable.
    // In this case create your own option for this
    if (value && !selectedOption) {
      selectedOption = { id: String(value), name: String(value) };
    }

    return (
      <div className={GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow}>
        <DropdownSelect
          fillWidth
          minimal
          disabled={disabled}
          disableOnNoItems={config.disableOnNoItems}
          selectedItem={selectedOption}
          onChange={(item) => {
            onNewValueSelect(item.value);
          }}
          filterable={!config.disableSearch}
          options={options}
          noSelectionText={config.placeholderText || ''}
          label={config.label}
          useFakeLabel={config.label === ''}
          showCancelBtn={!config.disableCancel}
          onCancelClick={() => onNewValueSelect(undefined)}
        />
      </div>
    );
  }
}

export const constructOptions = (
  config: DropdownDashboardElemConfig,
  dashboardDatasets: { [datasetId: string]: Dataset },
): SelectedDropdownInputItem[] => {
  if (config.valuesConfig.valuesSource === INPUT_TYPES.MANUAL.id) {
    return constructManualOptions(config);
  } else if (config.valuesConfig.valuesSource === INPUT_TYPES.QUERY.id) {
    return constructQueryOptions(config, dashboardDatasets);
  }

  return [];
};

const constructManualOptions = (
  config: DropdownDashboardElemConfig,
): SelectedDropdownInputItem[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let valuesArr: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let displaysArr: any[] = [];

  // parse values JSON. if it is not valid or not an array, return no options
  try {
    valuesArr = JSON.parse(config.valuesConfig.manualValues);
    if (!Array.isArray(valuesArr)) return [];
  } catch {
    return valuesArr;
  }

  // prase displays JSON. if it is not valid or not an array, don't use displays
  try {
    const displaysArrJson = JSON.parse(config.valuesConfig.manualDisplays);
    if (Array.isArray(displaysArrJson)) displaysArr = displaysArrJson;
  } catch {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return _.map(valuesArr, (value: any, index: number) => {
    const option = {
      id: `option-${index}`,
      value: value,
      name: String(value),
    };

    if (displaysArr[index]) option.name = displaysArr[index];

    return option;
  });
};

const constructQueryOptions = (
  config: DropdownDashboardElemConfig,
  dashboardDatasets: { [datasetId: string]: Dataset },
): SelectedDropdownInputItem[] => {
  const { queryTable, queryValueColumn, queryDisplayColumn } = config.valuesConfig;
  if (!queryTable || !queryValueColumn) return [];
  const dataset = dashboardDatasets[queryTable.id];

  if (!dataset || !dataset._rows) return [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return dataset._rows.reduce(
    (acc, row) => {
      const value = row[queryValueColumn.name];
      const name = (queryDisplayColumn && row[queryDisplayColumn.name]) || value;

      if (!(value in acc.values)) {
        acc.rows.push({
          id: _.uniqueId('dash_dropdown_elem'),
          value: value,
          name: String(name),
        });
        acc.values[value] = 1;
      }

      return acc;
    },
    { rows: [] as DropdownOption[], values: {} as Record<string | number, number> },
  ).rows;
};

export default DashboardDropdownElement;
