/** @format */

import _ from 'underscore';

import { Action } from 'reducers/rootReducer';
import { createReducer } from './utils';

import { ACTION, WhitelistDomain } from 'actions/types';

export interface WhitelistDomainReducerState {
  whitelist_domains?: WhitelistDomain[];
}

const whitelistDomainReducerInitialState: WhitelistDomainReducerState = {
  whitelist_domains: [],
};

const fetchWhiteListDomainsSuccess = (newState: WhitelistDomainReducerState, action: Action) => {
  const { payload } = action;
  newState.whitelist_domains = _.sortBy(
    payload.whitelist_domains,
    (whitelist_domain) => whitelist_domain.id,
  );
  return newState;
};

const createWhitelistDomainSucess = (newState: WhitelistDomainReducerState, action: Action) => {
  const { payload } = action;
  if (!newState.whitelist_domains) return newState;

  newState.whitelist_domains.push(payload.whitelist_domain);

  newState.whitelist_domains = _.sortBy(
    newState.whitelist_domains,
    (whitelist_domain) => whitelist_domain.id,
  );
  return newState;
};

const deleteWhitelistDomainSuccess = (newState: WhitelistDomainReducerState, action: Action) => {
  const { payload } = action;
  if (newState.whitelist_domains) {
    const deletedIndex = _.findIndex(newState.whitelist_domains, { id: payload.id });
    newState.whitelist_domains.splice(deletedIndex, 1);
  }
  return newState;
};

export default createReducer<WhitelistDomainReducerState>(whitelistDomainReducerInitialState, {
  [`${ACTION.FETCH_WHITELIST_DOMAIN}_SUCCESS`]: fetchWhiteListDomainsSuccess,
  [`${ACTION.CREATE_WHITELIST_DOMAIN}_SUCCESS`]: createWhitelistDomainSucess,
  [`${ACTION.DELETE_WHITELIST_DOMAIN}_SUCCESS`]: deleteWhitelistDomainSuccess,
});
