/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Theme, WithStyles } from '@material-ui/core/index';
import { connect } from 'react-redux';

import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import Button from 'shared/Button';

import {
  createPivotedOnCol,
  createAggregation,
  deletePivotedOnCol,
  deleteAggregation,
  updateAggregation,
  updatePivotedOnCol,
} from 'actions/dataPanelConfigActions';
import { TableColumn, PivotOperation } from 'actions/types';
import GroupingEditingPanel from 'pages/dataPanelEditorPage/groupingEditingPanel';
import CalculationsEditingPanel from 'pages/dataPanelEditorPage/calculationsEditingPanel';
import { AggregationColumnInfo, PivotOperationAggregation } from 'constants/types';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    summarizePanel: {
      marginBottom: theme.spacing(4),
    },
    addBtn: {
      width: '100%',
    },
    panelSection: {
      marginBottom: theme.spacing(10),
    },
  });

type PassedProps = {
  baseSchema: TableColumn[];
  pivotOperation?: PivotOperation;
};

type Props = PassedProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  RouteComponentProps;

class SummarizeConfigMenu extends React.Component<Props> {
  render() {
    const { classes, pivotOperation } = this.props;

    // Insert loading state here later
    if (!pivotOperation) return;

    return (
      <div className={classes.root}>
        {this.renderGroupingSection()}
        {this.renderCalculationsSection()}
      </div>
    );
  }

  renderGroupingSection = () => {
    const {
      classes,
      createPivotedOnCol,
      baseSchema,
      pivotOperation,
      deletePivotedOnCol,
      updatePivotedOnCol,
    } = this.props;
    return (
      <div className={classes.panelSection}>
        <ConfigSectionHeader title="Grouping" />
        {pivotOperation?.instructions.pivotedOnCols.map(
          (pivotedOnCol: AggregationColumnInfo, index: number) => (
            <GroupingEditingPanel
              key={_.uniqueId('grouping_panel_')}
              className={classes.summarizePanel}
              baseSchema={baseSchema}
              pivotedOnCol={pivotedOnCol}
              onDelete={() => {
                deletePivotedOnCol(index);
              }}
              onUpdate={(aggregationColumnInfo: AggregationColumnInfo) => {
                updatePivotedOnCol(index, aggregationColumnInfo);
              }}
            />
          ),
        )}
        <Button
          className={classes.addBtn}
          type="secondary"
          onClick={() => createPivotedOnCol()}
          text="Add a Grouping"
        />
      </div>
    );
  };

  renderCalculationsSection = () => {
    const {
      classes,
      createAggregation,
      pivotOperation,
      baseSchema,
      deleteAggregation,
      updateAggregation,
    } = this.props;
    return (
      <div className={classes.panelSection}>
        <ConfigSectionHeader title="Calculations" />
        {pivotOperation?.instructions.aggregations.map(
          (aggregation: PivotOperationAggregation, index: number) => (
            <CalculationsEditingPanel
              key={_.uniqueId('calculations_panel_')}
              className={classes.summarizePanel}
              baseSchema={baseSchema}
              aggregation={aggregation}
              onDelete={() => {
                deleteAggregation(index);
              }}
              onUpdate={(pivotOperationAggregation: PivotOperationAggregation) => {
                updateAggregation(index, pivotOperationAggregation);
              }}
            />
          ),
        )}
        <Button
          className={classes.addBtn}
          type="secondary"
          onClick={() => createAggregation()}
          text="Add a Calculation"
        />
      </div>
    );
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createPivotedOnCol,
  createAggregation,
  deletePivotedOnCol,
  deleteAggregation,
  updateAggregation,
  updatePivotedOnCol,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SummarizeConfigMenu)),
);
