/** @format */

import { ACTION } from 'actions/types';
import get from 'lodash/get';
import { ReduxState } from '../rootReducer';

export const createLoadingSelector = (actions: ACTION[], isInitiallyLoading = true) => (
  state: ReduxState,
) => {
  // default loading is true
  const loadingValues = actions.map((action: ACTION) =>
    get(state, `loading.${action}`, isInitiallyLoading),
  );
  // will only say that it is not loading (false) if all the actions are not loading
  return loadingValues.some((bool: boolean) => bool);
};
