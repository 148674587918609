/** @format */
import React from 'react';
import { SizeMe } from 'react-sizeme';

export interface WithWidthProps {
  width?: number;
}

const withWidth = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P & WithWidthProps> => {
  // @ts-ignore
  // eslint-disable-next-line react/display-name
  return React.forwardRef((props, ref) => {
    return <SizeMe>{({ size }) => <Component width={size.width} ref={ref} {...props} />}</SizeMe>;
  });
};

export default withWidth;
