/** @format */

import { Action } from 'reducers/rootReducer';
import { DataTable } from 'actions/types';
import { ErrorResponse } from 'actions/responseTypes';

export interface DataSourcesReducerState {
  loading: boolean;
  syncTablesLoading: boolean;
  allTables: DataTable[];
  error?: ErrorResponse;
}

const dataSourceReducerInitialState: DataSourcesReducerState = {
  loading: false,
  syncTablesLoading: false,
  allTables: [],
};

export default (
  state: DataSourcesReducerState = dataSourceReducerInitialState,
  action: Action,
): DataSourcesReducerState => {
  const newState = Object.assign({}, state);
  const { payload } = action;

  switch (action.type) {
    case 'FETCH_ALL_DATA_SOURCE_TABLES_REQUEST':
      newState.loading = true;
      return newState;
    case 'FETCH_ALL_DATA_SOURCE_TABLES_SUCCESS':
      newState.loading = false;
      newState.allTables = payload.schema;
      newState.error = undefined;
      return newState;
    case 'FETCH_ALL_DATA_SOURCE_TABLES_ERROR':
      newState.error = payload.errorData;
      return newState;
    case 'SYNC_SOURCE_TABLES_REQUEST':
      newState.syncTablesLoading = true;
      return newState;
    case 'SYNC_SOURCE_TABLES_SUCCESS':
    case 'SYNC_SOURCE_TABLES_ERROR':
      newState.syncTablesLoading = false;
      return newState;
    default:
      return state;
  }
};
