/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import { TableColumn } from 'actions/types';
import {
  NumberDisplayFormat,
  NumberDisplayOptions,
  VisualizeTableInstructions,
  GradientType,
  GradientPointType,
} from 'constants/types';
import ToggleSwitchInput from 'shared/ToggleSwitchInput';
import GoalInputRow from './GoalInputRow';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import GradientColorPicker from './GradientColorPicker';
import FlexBox from 'components/core/FlexBox';
import { DEFAULT_GRADIENT } from 'constants/dataPanelEditorConstants';

/**
 * According to style spec here: https://www.figma.com/file/JnQKSyP0S1iYcvKVBChu8Z/Table-Component?node-id=911%3A4475
 */
const POINT_CONFIGURATION_FLEX = 3.33;

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    marginTop: theme.spacing(2),
  },
  pointConfigurationContainer: {
    marginLeft: theme.spacing(2),
  },
}));

type Props = {
  addFieldsToDisplayOptions: (
    newFields: Partial<NumberDisplayOptions> & {
      format: NumberDisplayFormat;
    },
  ) => void;
  column: TableColumn;
  instructions: VisualizeTableInstructions;
};

export default function GradientConfiguration({
  addFieldsToDisplayOptions,
  column,
  instructions,
}: Props) {
  const classes = useStyles();
  const { format: currentFormat, gradientType, gradient, gradientOptions } = (instructions
    .schemaDisplayOptions?.[column.name] as NumberDisplayOptions) ?? {
    format: NumberDisplayFormat.NORMAL,
  };

  const isGradientEnabled = gradientType && gradientType !== GradientType.NONE;
  const { hue1, hue2, hue3 } = {
    hue1: gradient?.hue1 || DEFAULT_GRADIENT.hue1,
    hue2: gradient?.hue2 || DEFAULT_GRADIENT.hue2,
    hue3: gradient?.hue3 || DEFAULT_GRADIENT.hue3,
  };
  const { maxpoint, midpoint, minpoint } = gradientOptions ?? {};

  const getOnHueChanged = (hue: 'hue1' | 'hue2' | 'hue3') => (newHue: string) => {
    addFieldsToDisplayOptions({
      format: currentFormat,
      gradient: {
        ...gradient,
        [hue]: newHue,
      },
    });
  };

  return (
    <>
      <ToggleSwitchInput
        largeText
        onChange={() => {
          addFieldsToDisplayOptions({
            format: currentFormat,
            gradientType: isGradientEnabled ? GradientType.NONE : GradientType.LINEAR,
          });
        }}
        label="Gradient"
        switchOn={isGradientEnabled}
      />
      {isGradientEnabled && (
        <>
          <ToggleButtonGroup fillWidth className={classes.input}>
            <ToggleButton
              active={gradientType === GradientType.LINEAR}
              text="Linear"
              onClick={() => {
                addFieldsToDisplayOptions({
                  format: currentFormat,
                  gradientType: GradientType.LINEAR,
                });
              }}
            />
            <ToggleButton
              active={gradientType === GradientType.DIVERGING}
              text="Diverging"
              onClick={() => {
                addFieldsToDisplayOptions({
                  format: currentFormat,
                  gradientType: GradientType.DIVERGING,
                });
              }}
            />
          </ToggleButtonGroup>
          <FlexBox>
            <GradientColorPicker
              className={classes.input}
              hues={gradientType === GradientType.LINEAR ? [hue1, hue3] : [hue1, hue2, hue3]}
              onHuesChanged={
                gradientType === GradientType.LINEAR
                  ? [getOnHueChanged('hue1'), getOnHueChanged('hue3')]
                  : [getOnHueChanged('hue1'), getOnHueChanged('hue2'), getOnHueChanged('hue3')]
              }
            />
            <div className={classes.pointConfigurationContainer}>
              <GoalInputRow
                flex={POINT_CONFIGURATION_FLEX}
                type="min"
                usesColumnCalculation={minpoint?.type !== GradientPointType.NUMBER}
                exactValue={minpoint?.number}
                updateExactValue={(newValue) => {
                  addFieldsToDisplayOptions({
                    format: currentFormat,
                    gradientOptions: {
                      ...gradientOptions,
                      minpoint: {
                        ...minpoint,
                        number: newValue,
                      },
                    },
                  });
                }}
                updateUsesColumnCalculation={(newValue) => {
                  addFieldsToDisplayOptions({
                    format: currentFormat,
                    gradientOptions: {
                      ...gradientOptions,
                      minpoint: {
                        ...minpoint,
                        type: newValue ? GradientPointType.COMPUTED : GradientPointType.NUMBER,
                      },
                    },
                  });
                }}
              />
              {gradientType === GradientType.DIVERGING && (
                <GoalInputRow
                  flex={POINT_CONFIGURATION_FLEX}
                  type="mid"
                  usesColumnCalculation={midpoint?.type !== GradientPointType.NUMBER}
                  exactValue={midpoint?.number}
                  updateExactValue={(newValue) => {
                    addFieldsToDisplayOptions({
                      format: currentFormat,
                      gradientOptions: {
                        ...gradientOptions,
                        midpoint: {
                          ...midpoint,
                          number: newValue,
                        },
                      },
                    });
                  }}
                  updateUsesColumnCalculation={(newValue) => {
                    addFieldsToDisplayOptions({
                      format: currentFormat,
                      gradientOptions: {
                        ...gradientOptions,
                        midpoint: {
                          ...midpoint,
                          type: newValue ? GradientPointType.COMPUTED : GradientPointType.NUMBER,
                        },
                      },
                    });
                  }}
                />
              )}
              <GoalInputRow
                flex={POINT_CONFIGURATION_FLEX}
                type="max"
                usesColumnCalculation={maxpoint?.type !== GradientPointType.NUMBER}
                exactValue={maxpoint?.number}
                updateExactValue={(newValue) => {
                  addFieldsToDisplayOptions({
                    format: currentFormat,
                    gradientOptions: {
                      ...gradientOptions,
                      maxpoint: {
                        ...maxpoint,
                        number: newValue,
                      },
                    },
                  });
                }}
                updateUsesColumnCalculation={(newValue) => {
                  addFieldsToDisplayOptions({
                    format: currentFormat,
                    gradientOptions: {
                      ...gradientOptions,
                      maxpoint: {
                        ...maxpoint,
                        type: newValue ? GradientPointType.COMPUTED : GradientPointType.NUMBER,
                      },
                    },
                  });
                }}
              />
            </div>
          </FlexBox>
        </>
      )}
    </>
  );
}
