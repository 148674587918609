/** @format */

import React from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/index';
import { FormGroup, TextArea, Intent } from '@blueprintjs/core';

import FullPageContentContainer from 'shared/FullPageContentContainer';
import ConnectStepByStep from '../ConnectStepByStep';
import ConnectFAQs from '../ConnectFAQs';
import InputWithTag from 'shared/InputWithTag';
import CalloutLink from 'shared/CalloutLink';
import ToggleSwitchInput from 'shared/ToggleSwitchInput';

import { ConnectDataSourceStep } from '../constants';
import { DBConnectionConfig } from '../types';
import { integrationsInfo } from 'constants/dataSourceConstants';
import { SupportedDataSource } from 'actions/types';

const dataSourceByType = _.indexBy(Object.values(integrationsInfo), 'source_type');

const useStyles = makeStyles((theme: Theme) => ({
  checkboxContainer: {
    display: 'flex',
  },
  helpTooltip: {
    margin: theme.spacing(1.5),
  },
  credentialInput: {
    marginBottom: theme.spacing(4),
  },
}));

export type Props = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  onBackClicked: () => void;
};

const EnterCredentials: React.FC<Props> = ({
  config,
  updateConfig,
  onNextClicked,
  onBackClicked,
}) => {
  if (!config.selectedDataSource) return <div>Oops</div>;

  const dataSource = config.selectedDataSource;
  const configuration = config.dataSourceConfig || {};

  return (
    <FullPageContentContainer
      headerTitle="Enter Credentials"
      headerSubtitle="If you don’t have your database credentials, contact your system administrator."
      primaryActionConfig={{
        text: 'Next',
        disabled: !areCredentialsComplete(dataSource, configuration),
        onClick: onNextClicked,
      }}
      backBtnConfig={{
        onClick: onBackClicked,
      }}
      leftSideBarTopContent={<ConnectStepByStep currentStep={ConnectDataSourceStep.ENTER_CREDS} />}
      leftSideBarBottomContent={<ConnectFAQs />}
      bodyContent={<EnterCredentialsBody config={config} updateConfig={updateConfig} />}
    />
  );
};

export type BodyProps = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
};

export const EnterCredentialsBody: React.FC<BodyProps> = ({ config, updateConfig }) => {
  const classes = useStyles();

  if (!config.selectedDataSource)
    return <div>Error: A data source type must be selected before proceeding</div>;

  const dataSource = config.selectedDataSource;
  const helpCenterUrl = dataSourceByType[dataSource.type].credentialsLink;
  const configurationSchema = dataSource.configuration_schema;
  const configuration = config.dataSourceConfig || {};

  return (
    <div>
      <>
        {configurationSchema &&
          configurationSchema.order.map((propertyName: string) => {
            const properties = configurationSchema.properties;
            if (
              properties[propertyName].depends_on &&
              !configuration[properties[propertyName].depends_on || '']
            ) {
              return null;
            }

            if (properties[propertyName].security_page) {
              return null;
            }

            if (properties[propertyName].type === 'checkbox') {
              return (
                <ToggleSwitchInput
                  labelOnRight
                  flexTogether
                  largeText
                  className={classes.checkboxContainer}
                  key={`add_source_field_${dataSource.type}_${propertyName}`}
                  switchOn={configuration[propertyName] || false}
                  onChange={() => {
                    const newDataSourceConfig = {
                      ...configuration,
                      [propertyName]: !configuration[propertyName],
                    };
                    updateConfig({ ...config, dataSourceConfig: newDataSourceConfig });
                  }}
                  label={properties[propertyName].label}
                  helpText={properties[propertyName].help_tooltip}
                />
              );
            } else if (properties[propertyName].type === 'textarea') {
              return (
                <FormGroup
                  label={properties[propertyName].label}
                  labelInfo={properties[propertyName].optional ? undefined : '*'}
                  key={`add_source_field_${dataSource.type}_${propertyName}`}>
                  <TextArea
                    fill
                    value={configuration[propertyName] || ''}
                    placeholder={properties[propertyName].placeholder}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      const newDataSourceConfig = {
                        ...configuration,
                        [propertyName]: e.target.value,
                      };
                      updateConfig({
                        ...config,
                        dataSourceConfig: newDataSourceConfig,
                      });
                    }}
                  />
                </FormGroup>
              );
            } else {
              return (
                <InputWithTag
                  className={classes.credentialInput}
                  label={properties[propertyName].label}
                  key={`add_source_field_${dataSource.type}_${propertyName}`}
                  type={properties[propertyName].type}
                  value={configuration[propertyName] || ''}
                  placeholder={properties[propertyName].placeholder}
                  helpText={properties[propertyName].help_tooltip}
                  statusInfo={{
                    statusIcon: configuration[propertyName] ? 'tick' : undefined,
                    statusIntent: Intent.SUCCESS,
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newDataSourceConfig = {
                      ...configuration,
                      [propertyName]:
                        properties[propertyName].type === 'number'
                          ? parseInt(e.target.value)
                          : e.target.value,
                    };
                    updateConfig({
                      ...config,
                      dataSourceConfig: newDataSourceConfig,
                    });
                  }}
                />
              );
            }
          })}
      </>
      {helpCenterUrl && (
        <CalloutLink url={helpCenterUrl} text={`How to find ${dataSource.name} credentials`} />
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areCredentialsComplete = (dataSource: SupportedDataSource, configuration: any) => {
  const properties = dataSource.configuration_schema.properties;

  return _.all(
    _.map(dataSource.configuration_schema.order, (propertyName: string) => {
      if (properties[propertyName].security_page) return true;

      if (
        (properties[propertyName].depends_on &&
          !configuration[properties[propertyName].depends_on || '']) ||
        properties[propertyName].optional
      ) {
        return true;
      }
      return (
        configuration[propertyName] !== null &&
        configuration[propertyName] !== undefined &&
        configuration[propertyName] !== '' &&
        (configuration[propertyName] !== 'number' || !isNaN(configuration[propertyName]))
      );
    }),
  );
};

export default EnterCredentials;
