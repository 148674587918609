/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {},
  pdfPage: {
    height: 792,
    width: '100%',
    overflow: 'hidden',
  },
}));

export type Props = {
  className?: string;
  children: JSX.Element[];
};

const PDFLayout: React.FC<Props> = ({ className, children }) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      {children.map((child, index) => (
        <div key={`pdf-page-${index}`} className={classes.pdfPage}>
          {child}
        </div>
      ))}
    </div>
  );
};

export default PDFLayout;
