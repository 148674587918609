/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/index';
import { Icon, IconName, Intent } from '@blueprintjs/core';

import FlexBox, { VerticalAlignment, HorizontalAlignment } from 'components/core/FlexBox';

type UseStylesArgs = {
  clickable?: boolean;
  iconOnly?: boolean;
  inverted?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 2,
    fontSize: 12,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    height: 'fit-content',
  },
  rounded: {
    borderRadius: 12,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
  },
  roundedSpacious: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  spacious: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
  },
  iconOnly: {
    padding: theme.spacing(0),
  },
  intentNone: ({ inverted, clickable }: UseStylesArgs) =>
    inverted
      ? {
          backgroundColor: theme.palette.ds.grey800,
          color: theme.palette.ds.white,
          ...(clickable && {
            '&:hover': {
              backgroundColor: theme.palette.ds.hovered.grey800,
            },
            '&:active': {
              backgroundColor: theme.palette.ds.pressed.grey800,
            },
          }),
        }
      : {
          backgroundColor: theme.palette.ds.grey300,
          color: theme.palette.ds.grey900,
          ...(clickable && {
            '&:hover': {
              backgroundColor: theme.palette.ds.hovered.grey300,
            },
            '&:active': {
              backgroundColor: theme.palette.ds.pressed.grey300,
            },
          }),
        },
  intentDanger: ({ inverted, clickable }: UseStylesArgs) =>
    inverted
      ? {
          backgroundColor: theme.palette.ds.red,
          color: theme.palette.ds.white,
          '&:hover': {
            backgroundColor: theme.palette.ds.hovered.red,
          },
          '&:active': {
            backgroundColor: theme.palette.ds.pressed.red,
          },
        }
      : {
          backgroundColor: theme.palette.ds.lightRed,
          color: theme.palette.ds.red,
          ...(clickable && {
            '&:hover': {
              backgroundColor: theme.palette.ds.hovered.lightRed,
            },
            '&:active': {
              backgroundColor: theme.palette.ds.pressed.lightRed,
            },
          }),
        },
  intentSuccess: ({ inverted, clickable }: UseStylesArgs) =>
    inverted
      ? {
          backgroundColor: theme.palette.ds.green,
          color: theme.palette.ds.white,
          ...(clickable && {
            '&:hover': {
              backgroundColor: theme.palette.ds.hovered.green,
            },
            '&:active': {
              backgroundColor: theme.palette.ds.pressed.green,
            },
          }),
        }
      : {
          backgroundColor: theme.palette.ds.lightGreen,
          color: theme.palette.ds.green,
          ...(clickable && {
            '&:hover': {
              backgroundColor: theme.palette.ds.hovered.lightGreen,
            },
            '&:active': {
              backgroundColor: theme.palette.ds.pressed.lightGreen,
            },
          }),
        },
  intentPrimary: ({ inverted, clickable }: UseStylesArgs) =>
    inverted
      ? {
          backgroundColor: theme.palette.ds.blue,
          color: theme.palette.ds.white,
          ...(clickable && {
            '&:hover': {
              backgroundColor: theme.palette.ds.hovered.blue,
            },
            '&:active': {
              backgroundColor: theme.palette.ds.pressed.blue,
            },
          }),
        }
      : {
          backgroundColor: theme.palette.ds.lightBlue,
          color: theme.palette.ds.blue,
          ...(clickable && {
            '&:hover': {
              backgroundColor: theme.palette.ds.hovered.lightBlue,
            },
            '&:active': {
              backgroundColor: theme.palette.ds.pressed.lightBlue,
            },
          }),
        },
  icon: ({ iconOnly }: UseStylesArgs) => ({
    marginRight: theme.spacing(1),
    ...(iconOnly && {
      marginRight: 0,
      padding: theme.spacing(1.5),
    }),
  }),
  clickable: {
    cursor: 'pointer',
  },
}));

export type Props = {
  /**
   * Optional class to wrap entire component
   */
  className?: string;
  /**
   * The intent defines the color of the tag
   */
  intent?: Intent;
  /**
   * Inverts the colors of the text and background
   */
  inverted?: boolean;
  /**
   * Icon to show on left inside of tag
   */
  leftIcon?: IconName;
  /**
   * Callback function fired on click
   */
  onClick?: () => void;
  /**
   * Whether the corners of the tag are rounded
   */
  rounded?: boolean;
  /**
   * Increase padding
   */
  spacious?: boolean;
  /**
   * Text to display in tag
   */
  text?: string;
};

const Tag: React.FC<Props> = ({
  className,
  intent = 'none',
  inverted = false,
  leftIcon,
  onClick,
  rounded,
  spacious,
  text,
}) => {
  const clickable = onClick !== undefined;
  const iconOnly = !text;

  const classes = useStyles({ clickable, iconOnly, inverted });

  return (
    <FlexBox
      horizontalAlignment={HorizontalAlignment.CENTER}
      verticalAlignment={VerticalAlignment.CENTER}
      className={cx(classes.root, className, {
        [classes.rounded]: rounded,
        [classes.roundedSpacious]: rounded && spacious,
        [classes.spacious]: spacious,
        [classes.intentNone]: intent === Intent.NONE,
        [classes.intentDanger]: intent === Intent.DANGER,
        [classes.intentSuccess]: intent === Intent.SUCCESS,
        [classes.intentPrimary]: intent === Intent.PRIMARY,
        [classes.clickable]: clickable,
        [classes.iconOnly]: iconOnly,
      })}
      onClick={onClick}>
      {leftIcon && <Icon className={classes.icon} iconSize={12} icon={leftIcon} />}
      {text}
    </FlexBox>
  );
};

export default Tag;
