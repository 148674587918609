/** @format */

import React from 'react';
import cx from 'classnames';
import {
  createStyles,
  withStyles,
  WithStyles,
  WithTheme,
  withTheme,
} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Popover } from '@blueprintjs/core';

import ExploButton from 'shared/Button';
import { DownloadContentRow } from 'pages/dashboardPage/dashboardElement/dashboardExportElement';

import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { EXPORT_SVG_ICON } from 'constants/iconConstants';
import { DownloadChartInfo } from 'components/DashboardLayout/DashboardLayout';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    popoverContainer: {
      '& .bp3-popover.bp3-minimal': {
        margin: `${theme.spacing(1)}px 0px 0px !important`,
      },
      '& .bp3-popover': {
        boxShadow: 'none',
        border: `1px solid ${theme.palette.ds.grey200}`,
        borderRadius: '8px',
        overflow: 'hidden',
      },
    },
    exportButton: {
      marginLeft: theme.spacing(3),
      boxShadow: 'none !important',
      '& .bp3-spinner .bp3-spinner-track': {
        stroke: theme.palette.ds.grey100,
      },
      '& .bp3-button': {
        borderRadius: '8px !important',
      },
    },
    popoverTarget: {
      display: 'block !important',
    },
    sharePopoverContainer: {
      width: 200,
      // This is the value needed to align the main
      // download button with the child download buttons
      marginRight: -11,
    },
    hideDownloadBtn: {
      visibility: 'hidden',
    },
  });

type PassedProps = {
  disabled?: boolean;
  onDownloadPanelCsv: () => void;
  onDownloadPanelPdf: () => void;
  downloadChartInfo?: DownloadChartInfo;
  hidden?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles> & WithTheme;

type State = {
  isExportOptionsOpen?: boolean;
};

class DownloadChartButton extends React.Component<Props, State> {
  static contextType = GlobalStylesContext;
  context!: React.ContextType<typeof GlobalStylesContext>;
  state: State = {};

  render() {
    const { classes, disabled, hidden } = this.props;
    const { isExportOptionsOpen } = this.state;

    return (
      // We need padding on the outside because we run into issues trying to drag popover content
      <DashboardLayoutContext.Consumer>
        {(dashboardlayoutContext) => (
          <Popover
            position="top-right"
            minimal
            portalContainer={
              document.getElementById(dashboardlayoutContext.dashboardLayoutTagId) ?? undefined
            }
            portalClassName={cx(
              classes.popoverContainer,
              GLOBAL_STYLE_CLASSNAMES.container.outline.exportModalBorder,
              GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.exportModalCornerRadius,
            )}
            content={this.renderPopoverContent()}
            onClose={this.onExportPopoverClose}
            isOpen={isExportOptionsOpen}
            targetClassName={classes.popoverTarget}
            disabled={disabled || hidden}>
            <ExploButton
              minimal
              disabled={disabled}
              onClick={() => {
                this.setState({ isExportOptionsOpen: true });
              }}
              icon={EXPORT_SVG_ICON(this.context.globalStyleConfig.text.secondaryColor)}
              className={cx(classes.exportButton, { [classes.hideDownloadBtn]: hidden })}
            />
          </Popover>
        )}
      </DashboardLayoutContext.Consumer>
    );
  }

  renderPopoverContent = () => {
    const { classes } = this.props;

    return (
      <div
        className={cx(
          classes.sharePopoverContainer,
          GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor,
        )}>
        {this.renderDownloadCSV()}
        <Divider />
        {this.renderDownloadPDF()}
      </div>
    );
  };

  renderDownloadCSV = () => {
    const { downloadChartInfo, onDownloadPanelCsv } = this.props;

    return (
      <DownloadContentRow
        text="Download as CSV"
        exportUrl={downloadChartInfo?.csv.downloadUrl}
        isDownloadError={downloadChartInfo?.csv.errored}
        isDownloadOpenFailed={downloadChartInfo?.csv.isPopupBlocked}
        isDownloadLoading={downloadChartInfo?.csv.loading}
        isDownloadBlocked={downloadChartInfo?.csv.isDownloadBlocked}
        onDownloadClicked={onDownloadPanelCsv}
      />
    );
  };

  renderDownloadPDF = () => {
    const { downloadChartInfo, onDownloadPanelPdf } = this.props;

    return (
      <DownloadContentRow
        text="Download as PDF"
        exportUrl={downloadChartInfo?.pdf.downloadUrl}
        isDownloadError={downloadChartInfo?.pdf.errored}
        isDownloadOpenFailed={downloadChartInfo?.pdf.isPopupBlocked}
        isDownloadLoading={downloadChartInfo?.pdf.loading}
        isDownloadBlocked={downloadChartInfo?.pdf.isDownloadBlocked}
        onDownloadClicked={onDownloadPanelPdf}
      />
    );
  };

  onExportPopoverClose = (event?: React.SyntheticEvent<HTMLElement, Event>) => {
    if (event && event.type === 'mousedown') {
      this.setState(() => ({ isExportOptionsOpen: false }));
    }
  };
}

export default withStyles(styles)(withTheme(DownloadChartButton));
