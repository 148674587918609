/** @format */

import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import emptyStateArrow from 'images/app/emptyStateArrow.png';

const styles = (theme: Theme) =>
  createStyles({
    emptyStateContainer: { marginLeft: 'auto', marginRight: '100px' },
    emptyStateArrow: {
      float: 'right',
      marginRight: theme.spacing(3),
    },
    emptyStateText: {
      float: 'right',
      marginRight: theme.spacing(30),
      marginTop: '-10px',
      width: '175px',
      height: '35px',
      fontSize: '14px',
      textAlign: 'right',
      lineHeight: '18px',
      color: theme.palette.ds.grey800,
    },
  });

type PassedProps = {
  text: string;
};

type Props = PassedProps & WithStyles<typeof styles>;

class EmptyState extends React.Component<Props> {
  render() {
    const { classes, text } = this.props;

    return (
      <div className={classes.emptyStateContainer}>
        <div>
          <img
            className={classes.emptyStateArrow}
            src={emptyStateArrow}
            alt="call out indicating an action to take on an empty page"
          />
        </div>
        <div>
          <p className={classes.emptyStateText}>{text}</p>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(EmptyState);
