/** @format */

export default {
  currentUser: {
    loginLoading: false,
    registrationLoading: false,
  },
  teamData: {
    loading: true,
    data: null,
  },
};
