/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';
import _ from 'underscore';

import DroppableComponentItem from 'pages/dashboardPage/droppableComponentItem';
import Button from 'shared/Button';

import { DROPPABLE_DASHBOARD_ELEMENT_CONFIGS, ELEMENT_GROUPS } from 'constants/dashboardConstants';
import { SIDE_PANE_WIDTH } from 'components/SidePane';
import { DashboardTemplate } from 'actions/types';
import SettingHeader from './DataPanelConfigV2/DataConfigTab/SettingHeader';

const GROUPED_ELEMS = _.groupBy(DROPPABLE_DASHBOARD_ELEMENT_CONFIGS, 'group');

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: SIDE_PANE_WIDTH,
      height: `100%`,
      backgroundColor: theme.palette.ds.white,
      borderRight: `1px solid ${theme.palette.ds.grey400}`,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    layoutHeader: {
      marginTop: theme.spacing(5),
      padding: `0 ${theme.spacing(6)}px`,
    },
    droppableComponent: {
      marginBottom: theme.spacing(3),
    },
    addComponents: {
      marginTop: theme.spacing(4),
    },
    droppableElementContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
      paddingBottom: theme.spacing(2),
    },
    editDashboardLayoutBtn: {
      marginTop: theme.spacing(4),
    },
    editDashboardLayoutSaveBtnContainer: {
      marginTop: theme.spacing(4),
    },
    editLayoutContainer: {
      padding: `0 ${theme.spacing(6)}px`,
    },
    config: {
      padding: `0 ${theme.spacing(6)}px`,
    },
  });

type PassedProps = {
  createDataPanelLoading?: boolean;
  editDashboardModeEnabled?: boolean;
  toggleEditDashboardMode: () => void;
  onCreateDataPanel: () => void;
  onNewDashboardElementDrag: (elementType?: string) => void;
  pageWidth: number | null;
  dashboardTemplate: DashboardTemplate;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {};

class EditDashboardConfig extends React.Component<Props, State> {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderEditLayoutBtn()}
        {this.renderAddComponentsSection()}
      </div>
    );
  }

  renderEditLayoutBtn = () => {
    const { classes, toggleEditDashboardMode, editDashboardModeEnabled } = this.props;

    let buttons;
    if (editDashboardModeEnabled) {
      buttons = (
        <div className={classes.editDashboardLayoutSaveBtnContainer}>
          <Button
            fillWidth
            type="primary"
            onClick={() => {
              toggleEditDashboardMode();
            }}
            text="Done Editing Layout"
          />
        </div>
      );
    } else {
      buttons = (
        <Button
          fillWidth
          className={classes.editDashboardLayoutBtn}
          type="secondary"
          icon="edit"
          onClick={toggleEditDashboardMode}
          text="Edit Dashboard Layout"
        />
      );
    }

    return (
      <>
        <SettingHeader name="Layout" />
        <div className={classes.editLayoutContainer}>{buttons}</div>
      </>
    );
  };

  renderAddComponentsSection = () => {
    const { classes, onNewDashboardElementDrag } = this.props;
    return (
      <div className={classes.addComponents}>
        {[
          ELEMENT_GROUPS.CHARTS,
          ELEMENT_GROUPS.CONTROLS,
          ELEMENT_GROUPS.EXPORT,
          ELEMENT_GROUPS.DISPLAY,
        ].map((group) => (
          <div key={`elem-drop-group-${group}`}>
            <SettingHeader name={group} />
            <div className={classes.droppableElementContainer}>
              {GROUPED_ELEMS[group].map((config) => (
                <DroppableComponentItem
                  className={classes.droppableComponent}
                  key={`droppabled-dash-elem-${config.id}`}
                  icon={config.icon}
                  name={config.name}
                  onDragStart={() => onNewDashboardElementDrag(config.id)}
                  onDragEnd={() => onNewDashboardElementDrag(undefined)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };
}

export default withStyles(styles)(EditDashboardConfig);
