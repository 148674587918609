/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { connect } from 'react-redux';

import { pageView } from 'analytics/exploAnalytics';

import SettingsLeftNav from './settingsLeftNav';
import SettingsProfileSection from './settingsProfileSection';
import SettingsMembersSection from './settingsMembersSection';
import SettingsAPITokensSection from './settingsAPITokensSection';
import SettingsAnalyticsSection from './settingsAnalyticsSection';
import SettingsDomainWhitelistSection from './settingsDomainWhitelistSection';
import SettingsShareLinkSection from './settingsShareLinkSection';

import { fetchUserTeam } from 'actions/teamActions';
import { fetchWhitelistDomains } from 'actions/whitelistDomainActions';
import SettingsInternationalizationSection from './SettingsInternationalizationSection';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      left: '0%',
      bottom: '100%',
      background: theme.palette.ds.white,
    },
    bodyContainer: {
      overflowY: 'auto',
      background: theme.palette.ds.white,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: theme.spacing(3),
    },
    body: {
      maxWidth: 1000,
      width: '100%',
    },
  });

type MatchParams = {};

type Props = RouteComponentProps<MatchParams> &
  WithStyles<typeof styles> &
  typeof mapDispatchToProps;

class SettingsPage extends React.Component<Props> {
  componentDidMount() {
    const { fetchUserTeam, fetchWhitelistDomains } = this.props;
    fetchUserTeam();
    fetchWhitelistDomains();
    pageView('Settings');
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <SettingsLeftNav />
        {this.renderBody()}
      </div>
    );
  }

  renderBody = () => {
    const { classes } = this.props;
    return (
      <div className={classes.bodyContainer}>
        <div className={classes.body}>
          <div id="Profile">
            <SettingsProfileSection />
          </div>
          <div id="Members">
            <SettingsMembersSection />
          </div>
          <div id="APITokens">
            <SettingsAPITokensSection />
          </div>
          <div id="Analytics">
            <SettingsAnalyticsSection />
          </div>
          <div id="DomainWhitelisting">
            <SettingsDomainWhitelistSection />
          </div>
          <div id="Internationalization">
            <SettingsInternationalizationSection />
          </div>
          <div id="Share Links">
            <SettingsShareLinkSection />
          </div>
        </div>
      </div>
    );
  };
}

const mapDispatchToProps = {
  fetchUserTeam,
  fetchWhitelistDomains,
};

export default connect(null, mapDispatchToProps)(withRouter(withStyles(styles)(SettingsPage)));
