/** @format */

import React, { useContext } from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/index';

import BaseDataTable from 'components/dataTable/baseDataTable';
import PDFLayout from './PDFLayout';
import PDFTableFooter from './PDFTableFooter';
import PDFHeader from './PDFHeader';

import { DataPanelTemplate } from 'actions/types';
import { UserTransformedSchema } from 'constants/types';
import { getMetricsByColumn, removeUserDisabledColumns } from 'utils/dashboardUtils';
import { GlobalStyleConfig } from 'globalStyles/types';
import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const ROWS_PER_PAGE_NORMAL = 20;
const ROWS_PER_PAGE_PDF = 17;

const useStyles = makeStyles((theme: Theme) => ({
  pageContent: {
    padding: theme.spacing(5),
  },
  tableName: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    fontSize: 20,
    fontWeight: 600,
  },
  table: (styleConfig: GlobalStyleConfig) => ({
    border: `1px solid ${styleConfig.container.outline.color}`,
  }),
}));

export type Props = {
  tableName: string;
  dataPanelTemplate: DataPanelTemplate;
  defaultUserTransformedSchema: UserTransformedSchema;
};

const PDFExportTableView: React.FC<Props> = ({
  tableName,
  dataPanelTemplate,
  defaultUserTransformedSchema,
}) => {
  const context = useContext(GlobalStylesContext);
  const classes = useStyles(context.globalStyleConfig);

  const visualizeOperation = dataPanelTemplate.visualize_op;
  let numRows = ROWS_PER_PAGE_NORMAL;
  if (visualizeOperation.instructions.VISUALIZE_TABLE.pdfFormat?.headerEnabled) {
    numRows = ROWS_PER_PAGE_PDF;
  }

  const rows = dataPanelTemplate._rows || [];
  const numPages = Math.ceil(rows.length / numRows);

  return (
    <PDFLayout className={GLOBAL_STYLE_CLASSNAMES.base.backgroundColor.backgroundColor}>
      {_.times(numPages, (i) => {
        const startRow = i * numRows;
        const currRows = rows.slice(i * numRows, (i + 1) * numRows);

        return (
          <div className={classes.pageContent}>
            {visualizeOperation.instructions.VISUALIZE_TABLE.pdfFormat?.headerEnabled && (
              <PDFHeader pdfConfig={visualizeOperation.instructions.VISUALIZE_TABLE.pdfFormat} />
            )}
            <div className={classes.tableName}>{tableName}</div>
            <BaseDataTable
              noBorderRadius
              fill
              truncateEmptyRowSpace
              useFriendlyNameForHeader
              unrestrictedHeight
              disableRowHeader
              shouldTruncateText
              isSortable
              loading={false}
              className={classes.table}
              rows={currRows}
              schema={removeUserDisabledColumns(defaultUserTransformedSchema)}
              maxRows={numRows}
              metricsByColumn={getMetricsByColumn(dataPanelTemplate._secondaryData || [])}
              columnWidths={visualizeOperation.instructions.VISUALIZE_TABLE.columnWidths}
              schemaDisplayOptions={
                visualizeOperation.instructions.VISUALIZE_TABLE.schemaDisplayOptions
              }
            />
            <PDFTableFooter
              startRow={startRow + 1}
              numCurrentRows={currRows.length}
              totalRows={rows.length}
              pageNumber={i + 1}
              numPages={numPages}
            />
          </div>
        );
      })}
    </PDFLayout>
  );
};

export default PDFExportTableView;
