/** @format */
import { Action } from 'reducers/rootReducer';
import { cloneDeep } from 'lodash';

import { ACTION } from 'actions/types';
import { DEFAULT_GLOBAL_STYLE_CONFIG, getBaseGlobalStyles } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { createReducer } from './utils';

export interface EmbedDashboardReducer {
  globalStyleConfig: GlobalStyleConfig;
}

const initialState: EmbedDashboardReducer = {
  globalStyleConfig: cloneDeep(DEFAULT_GLOBAL_STYLE_CONFIG),
};

export const fetchDashboardSuccess = (newState: EmbedDashboardReducer, action: Action) => {
  const { payload } = action;
  newState.globalStyleConfig = cloneDeep(getBaseGlobalStyles(payload.style_config_v2));
  return newState;
};

export default createReducer<EmbedDashboardReducer>(initialState, {
  [`${ACTION.EMBED_FETCH_DASHBOARD}_SUCCESS`]: fetchDashboardSuccess,
});
