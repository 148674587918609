/** @format */
import React, { useState } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Divider, Icon } from '@blueprintjs/core';

import FlexBox, { VerticalAlignment } from 'components/core/FlexBox';
import { Dataset, TableColumn, TableRow } from 'actions/types';
import FlexItem from 'components/core/FlexItem';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ToggleSwitchInput from 'shared/ToggleSwitchInput';
import BooleanFormatConfiguration from './BooleanFormatConfiguration';
import StringFormatConfiguration from './StringFormatConfiguration';
import DateFormatConfiguration from './DateFormatConfiguration';
import NumberFormatConfiguration from './NumberConfiguration/NumberFormatConfiguration';
import ProgressBarConfiguration from './NumberConfiguration/ProgressBarConfiguration';
import GradientConfiguration from './NumberConfiguration/GradientConfiguration';
import EnrichColumnConfiguration from './EnrichColumnConfiguration';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { BOOLEAN, DATE_TYPES, NUMBER_TYPES, STRING } from 'constants/dataConstants';
import {
  DateDisplayFormat,
  DisplayOptions,
  NumberDisplayFormat,
  OPERATION_TYPES,
  Schema,
  StringDisplayFormat,
  VisualizeTableInstructions,
  TableJoinColumnConfig,
} from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  columnConfigRoot: {
    margin: theme.spacing(3),
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  columnConfigRow: ({ isExpanded }: { isExpanded?: boolean }) => ({
    backgroundColor: theme.palette.ds.grey300,
    borderRadius: 4,
    ...{ borderBottomLeftRadius: isExpanded ? 0 : 4, borderBottomRightRadius: isExpanded ? 0 : 4 },
    color: theme.palette.ds.grey900,
    padding: `0px ${theme.spacing(2)}px`,
    paddingLeft: 8,
    height: 32,
    cursor: 'pointer',
    '&.bp3-icon': {
      color: theme.palette.ds.grey900,
    },
  }),
  columnNameText: {
    fontWeight: 600,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  columnConfigMenu: {
    backgroundColor: theme.palette.ds.grey100,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  section: {
    padding: theme.spacing(3),
  },
  divider: {
    margin: 0,
  },
}));

type Props = {
  instructions: VisualizeTableInstructions;
  schema: Schema;
  dashboardDatasets: Record<string, Dataset>;
  dataPanelData: TableRow[];
};

export default function TableColumnsConfig({
  instructions,
  schema,
  dashboardDatasets,
  dataPanelData,
}: Props) {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const updateInstructions = (instructions: VisualizeTableInstructions) => {
    dispatch(updateVisualizeOperation(instructions, OPERATION_TYPES.VISUALIZE_TABLE));
  };

  return (
    <>
      <ToggleSwitchInput
        className={classes.configInput}
        switchOn={!instructions.isColumnSortingDisabled}
        onChange={() => {
          const newInstructions = produce(instructions, (draft) => {
            draft.isColumnSortingDisabled = !draft.isColumnSortingDisabled;
          });

          dispatch(updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_TABLE));
        }}
        label="Sorting"
      />
      {schema.map((col) => (
        <TableColumnConfig
          key={col.name}
          column={col}
          instructions={instructions}
          updateInstructions={updateInstructions}
          dashboardDatasets={dashboardDatasets}
          dataPanelData={dataPanelData}
        />
      ))}
    </>
  );
}

type TableColumnConfigProps = {
  column: TableColumn;
  instructions: VisualizeTableInstructions;
  updateInstructions: (instructions: VisualizeTableInstructions) => void;
  dashboardDatasets: Record<string, Dataset>;
  dataPanelData: TableRow[];
};

function TableColumnConfig(props: TableColumnConfigProps) {
  const { column, instructions, updateInstructions, dataPanelData, dashboardDatasets } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles({ isExpanded });
  const columnConfig = instructions.schemaDisplayOptions?.[column.name];

  const schemaChangeIndex = instructions.changeSchemaList.findIndex(
    (schemaChange) => schemaChange.col === column.name,
  );

  const columnDisplayName = column.friendly_name || column.name;

  const addFieldsToDisplayOptions = (
    newFields: Partial<DisplayOptions> & {
      format: NumberDisplayFormat | DateDisplayFormat | StringDisplayFormat;
    },
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      const newSchemaDisplayOptions = {
        ...draft.schemaDisplayOptions,
        [column.name]: {
          ...draft.schemaDisplayOptions?.[column.name],
          ...newFields,
        },
      };

      draft.schemaDisplayOptions = newSchemaDisplayOptions;
    });

    updateInstructions(newInstructions);
  };

  let columnToDisplay = column;
  if (columnConfig && isJoinConfigReady(columnConfig) && columnConfig.joinDisplayColumn) {
    columnToDisplay = columnConfig.joinDisplayColumn.column;
  }

  return (
    <div className={classes.columnConfigRoot}>
      <FlexBox
        className={classes.columnConfigRow}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        verticalAlignment={VerticalAlignment.CENTER}>
        <FlexItem>
          <span className={classes.columnNameText}>{columnDisplayName}</span>
        </FlexItem>
        <Icon iconSize={16} icon={isExpanded ? 'caret-down' : 'caret-right'} />
      </FlexBox>
      {isExpanded && (
        <div className={classes.columnConfigMenu}>
          <div className={classes.section}>
            <InputWithBlurSave
              label="Column Heading"
              initialValue={columnDisplayName}
              onNewValueSubmitted={(newColName) => {
                const newInstructions = produce(instructions, (draft) => {
                  if (schemaChangeIndex !== -1) {
                    draft.changeSchemaList[schemaChangeIndex].newColName = newColName;
                  } else {
                    draft.changeSchemaList.push({ col: column.name, keepCol: true, newColName });
                  }
                });

                updateInstructions(newInstructions);
              }}
            />
            <EnrichColumnConfiguration
              column={column}
              instructions={instructions}
              updateInstructions={updateInstructions}
              dashboardDatasets={dashboardDatasets}
            />
          </div>
          {(!columnConfig?.joinOn || isJoinConfigReady(columnConfig)) && (
            <>
              <Divider className={classes.divider} />
              <div className={classes.section}>
                {columnToDisplay.type === BOOLEAN && <BooleanFormatConfiguration {...props} />}
                {columnToDisplay.type === STRING && (
                  <StringFormatConfiguration
                    addFieldsToDisplayOptions={addFieldsToDisplayOptions}
                    column={column}
                    instructions={instructions}
                    dataPanelData={dataPanelData}
                  />
                )}
                {DATE_TYPES.has(columnToDisplay.type) && (
                  <DateFormatConfiguration
                    addFieldsToDisplayOptions={addFieldsToDisplayOptions}
                    column={column}
                    instructions={instructions}
                  />
                )}
                {NUMBER_TYPES.has(columnToDisplay.type) && (
                  <NumberFormatConfiguration
                    addFieldsToDisplayOptions={addFieldsToDisplayOptions}
                    column={column}
                    instructions={instructions}
                  />
                )}
              </div>
              {NUMBER_TYPES.has(columnToDisplay.type) && (
                <>
                  <Divider className={classes.divider} />
                  <div className={classes.section}>
                    <ProgressBarConfiguration
                      addFieldsToDisplayOptions={addFieldsToDisplayOptions}
                      column={column}
                      instructions={instructions}
                    />
                  </div>
                  <Divider className={classes.divider} />
                  <div className={classes.section}>
                    <GradientConfiguration
                      addFieldsToDisplayOptions={addFieldsToDisplayOptions}
                      column={column}
                      instructions={instructions}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export const isJoinConfigReady = (colConfig: TableJoinColumnConfig) => {
  return (
    colConfig.joinOn && colConfig.joinColumn && colConfig.joinColumn && colConfig.joinDisplayColumn
  );
};
