/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Button from 'shared/Button';
import { SIDE_PANE_WIDTH, SIDE_PANE_HEADER_HEIGHT } from 'components/SidePane';

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    height: SIDE_PANE_HEADER_HEIGHT,
    width: SIDE_PANE_WIDTH,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderBottom: `1px solid ${theme.palette.ds.grey400}`,
    borderRight: `1px solid ${theme.palette.ds.grey400}`,
    padding: `0 ${theme.spacing(6)}px`,
  },
  backBtn: {
    marginRight: theme.spacing(2),
    marginLeft: -10,
    marginTop: -3,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

type Props = {
  title: string;
  backButtonPath?: string;
  className?: string;
};

export default function SidePaneHeader({ backButtonPath, className, title }: Props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={cx(classes.headerContainer, className)}>
      {backButtonPath && (
        <Button
          minimal
          icon="arrow-left"
          className={classes.backBtn}
          onClick={() => history.push(backButtonPath)}
        />
      )}
      <div className={classes.title}>{title}</div>
    </div>
  );
}
