/** @format */

import { GlobalStyleConfig, GlobalStyleTextConfig } from './types';

export const INTERACTION_STATE_OPACITY = 0.1;
export const ACTION_COLOR_OUTLINE_OPACITY = 0.3;
export const DROP_SHADOW_OPACITY = 0.25;
export const BACKGROUND_COLOR_OFFSET = 0.02;

export const TEXT_SIZE_OFFSET_MAP: Record<keyof GlobalStyleTextConfig['overrides'], number> = {
  h1: 8,
  h2: 6,
  h3: 2,
  body: 0,
  smallHeading: -2,
  smallBody: -2,
};

export const DEFAULT_OUTLINE_COLOR = '#D9E0E9';

export const DEFAULT_GLOBAL_STYLE_CONFIG: GlobalStyleConfig = {
  base: {
    numColumns: 12,
    actionColor: {
      default: '#0069ED',
    },
    backgroundColor: '#FAFAFA',
    spacing: {
      default: 20,
    },
  },
  container: {
    fill: '#FFFFFF',
    cornerRadius: {
      default: 4,
    },
    outline: {
      enabled: true,
      weight: 1,
      color: DEFAULT_OUTLINE_COLOR,
    },
    padding: {
      default: 20,
    },
    shadow: {
      enabled: false,
      color: '#000000',
      size: 4,
    },
  },
  text: {
    overrides: {},
    primaryColor: '#000000',
    secondaryColor: '#626262',
    textSize: 14,
  },
  visualizations: {
    categoricalPalette: {
      hue1: '#0069ED',
      hue2: '#907EFF',
      hue3: '#7E9AFF',
      hue4: '#7ACC39',
      hue5: '#FF8750',
      hue6: '#FCD508',
    },
    divergingPalette: {
      hue1: '#7ACC39',
      hue2: '#FFFFFF',
      hue3: '#0069ED',
    },
    gradientPalette: {
      hue1: '#FFFFFF',
      hue2: '#0069ED',
    },
  },
};

export const GLOBAL_STYLE_CLASSNAMES = {
  base: {
    backgroundColor: {
      backgroundColor: 'explo-base-backgroundColor_backgroundColor',
      datePickerHeaderBackgroundColor: 'explo-base-backgroundColor_datePickerHeaderBackgroundColor',
    },
    actionColor: {
      default: {
        backgroundColor: 'explo-base-actionColor-default_backgroundColor',
        color: 'explo-base-actionColor-default_color',
        dropdownInputMenuItem: 'explo-base-actionColor-default_dropdownInputMenuItem',
        dropdownInputBorder: 'explo-base-actionColor-default_dropdownInputBorder',
        dropdownFilterInputOutline: 'explo-base-actionColor-default_dropdownFilterInputOutline',
        multiSelectInputOutlineActive: 'explo-base-actionColor-default_multiSelectInputOutline',
        multiSelectInputTag: 'explo-base-actionColor-default_multiSelectInputTag',
        datePickerInputBorderActive: 'explo-base-actionColor-default_datePickerInputBorder',
        buttonBorderActive: 'explo-base-actionColor-default_buttonBorderActive',
        datePickerDaySelected: 'explo-base-actionColor-default_datePickerDaySelected',
      },
      buttonColor: {
        backgroundColor: 'explo-base-actionColor-buttonColor_backgroundColor',
        color: 'explo-base-actionColor-buttonColor_color',
        buttonBackgroundColor: 'explo-base-actionColor-buttonColor_buttonBackgroundColor',
        buttonBackgroundColorImportant:
          'explo-base-actionColor-buttonColor_buttonBackgroundColorImportant',
        buttonColor: 'explo-base-actionColor-buttonColor_buttonColor',
        shareButtonHoverColor: 'explo-base-actionColor-buttonColor_shareButtonHoverColor',
        toggleBackgroundColor: 'explo-base-actionColor-buttonColor_toggleBackgroundColor',
        toggleSwitchInteraction: 'explo-base-actionColor-buttonColor_toggleSwitchInteraction',
      },
      interactionStates: {
        dropdownInputBorderHover:
          'explo-base-actionColor-interactionStates_dropdownInputBorderHover',
        datePickerInputBorderHover:
          'explo-base-actionColor-interactionStates_datePickerInputBorderHover',
        multiSelectInputBorderHover:
          'explo-base-actionColor-interactionStates_multiSelectInputBorderHover',
        buttonBorderHover: 'explo-base-actionColor-default_buttonBorderHover',
      },
    },
  },
  container: {
    fill: {
      backgroundColor: 'explo-container-fill_backgroundColor',
      offsetBackgroundColor: 'explo-container-fill_offsetBackgroundColor',
      datePickerMonthContainerFill: 'explo-container-fill_datePickerMonthContainerFill',
      datePickerTimeContainerFill: 'explo-container-fill_datePickerTimeContainerFill',
      buttonBackgroundColor: 'explo-container-fill_buttonBackgroundColor',
    },
    outline: {
      boxShadow: 'explo-container-outline_boxShadow',
      border: 'explo-container-outline_border',
      exportModalBorder: 'explo-container-outline_exportModalBorder',
      dropdownInputBorder: 'explo-container-outline_dropdownInputBorder',
      popoverBorder: 'explo-container-outline_popoverBorder',
      multiSelectInputBorder: 'explo-container-outline_multiSelectInputBorder',
      dropdownFilterInputBorder: 'explo-container-outline_dropdownFilterInputBorder',
      buttonBorder: 'explo-container-outline_buttonBorder',
    },
    shadow: {
      dropShadow: 'explo-container-shadow_dropShadow',
      dropdownInputShadow: 'explo-container-outline_dropdownInputShadow',
      multiSelectInputShadow: 'explo-container-outline_multiSelectInputShadow',
      buttonShadow: 'explo-container-outline_buttonShadow',
    },
    padding: {
      default: {
        padding: 'explo-container-padding_padding',
      },
    },
    cornerRadius: {
      default: {
        borderRadius: 'explo-container-cornerRadius-default_borderRadius',
        borderRadiusTop: 'explo-container-cornerRadius-default_borderRadiusTop',
        borderRadiusBottom: 'explo-container-cornerRadius-default_borderRadiusBottom',
        exportModalCornerRadius: 'explo-container-cornerRadius-default_exportModalCornerRadius',
      },
      buttons: {
        buttonCornerRadius: 'explo-container-cornerRadius-default_buttonCornerRadius',
      },
    },
  },
  text: {
    primaryColor: {
      color: 'explo-text-primaryColor_color',
    },
    secondaryColor: {
      color: 'explo-text-secondaryColor_color',
      datePickerNavigationPreviousColor:
        'explo-text-secondaryColor_datePickerNavigationPreviousColor',
      datePickerNavigationNextColor: 'explo-text-secondaryColor_datePickerNavigationNextColor',
      datePickerMonthDropdownIconColor:
        'explo-text-secondaryColor_datePickerMonthDropdownIconColor',
      datePickerYearDropdownIconColor: 'explo-text-secondaryColor_datePickerYearDropdownIconColor',
    },
    h1: {
      base: 'explo-text-h1',
    },
    h2: {
      base: 'explo-text-h2',
    },
    h3: {
      base: 'explo-text-h3',
      secondary: 'explo-text-secondary-h3',
      datePickerHeader: 'explo-text-h3_datePickerHeader',
    },
    body: {
      primary: 'explo-text-body_primary',
      primaryFont: 'explo-text-body_primaryFont',
      secondary: 'explo-text-body_secondary',
      button: {
        primaryFont: 'explo-text-body-button_primaryFont',
        secondaryColor: 'explo-text-body-button_secondaryColor',
      },
      dropdownMenuItem: 'explo-text-body_dropdownMenuItem',
      dropdownFilterInput: 'explo-text-body_dropdownFilterInput',
    },
    smallBody: {
      secondary: 'explo-text-smallBody_secondary',
      datePickerBody: 'explo-text-smallBody_datePickerBody',
    },
    smallHeading: {
      secondary: 'explo-text-smallHeading_secondary',
      chartAxisLabels: 'explo-text-smallHeading_chartAxisLabels',
    },
  },
};
