/** @format */

import { Action } from 'reducers/rootReducer';
import { createReducer } from './utils';
import { ACTION } from 'actions/types';

import { loadLocale } from 'utils/localizationUtils';

export type InternationalizationReducerState = {
  teamLocaleCode?: string;
  teamUseBrowserLocale?: boolean;
};

const internationalizationInitialState: InternationalizationReducerState = {};

const loginUserSuccess = (newState: InternationalizationReducerState, action: Action) => {
  const { payload } = action;

  loadLocale({
    teamLocaleCode: payload.team?.default_locale_code,
    useBrowserLocale: payload.team?.use_browser_locale,
  });
  newState.teamLocaleCode = payload.team?.default_locale_code;
  newState.teamUseBrowserLocale = payload.team?.use_browser_locale;
  return newState;
};

const saveDefaultLocaleCodeSuccess = (
  newState: InternationalizationReducerState,
  action: Action,
) => {
  const { payload } = action;

  loadLocale({
    teamLocaleCode: payload.locale_code,
    useBrowserLocale: newState.teamUseBrowserLocale,
  });
  newState.teamLocaleCode = payload.locale_code;
  return newState;
};

const saveUseBrowserLocaleSuccess = (
  newState: InternationalizationReducerState,
  action: Action,
) => {
  const { payload } = action;

  loadLocale({
    teamLocaleCode: newState.teamLocaleCode,
    useBrowserLocale: payload.setting,
  });
  newState.teamUseBrowserLocale = payload.setting;
  return newState;
};

export default createReducer<InternationalizationReducerState>(internationalizationInitialState, {
  [`${ACTION.LOGIN_USER}_SUCCESS`]: loginUserSuccess,
  [`${ACTION.SAVE_DEFAULT_LOCALE_CODE}_SUCCESS`]: saveDefaultLocaleCodeSuccess,
  [`${ACTION.SAVE_USE_BROWSER_LOCALE}_SUCCESS`]: saveUseBrowserLocaleSuccess,
});
