/** @format */

import _ from 'underscore';
import { Action } from 'reducers/rootReducer';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  CreateNewDashboardVersionResponse,
  FetchDashboardVersionsResponse,
  PublishNewDashboardVersionResponse,
} from 'actions/dashboardV2Actions';
import { DashboardVersion, UserInfo, DashboardEnvironmentTag, ACTION } from 'actions/types';
import { createReducer } from './utils';

export interface DashboardVersionsReducerState {
  versions: DashboardVersion[];
  authors: Record<number, UserInfo>;
  tags: DashboardEnvironmentTag[];
}

const initialState: DashboardVersionsReducerState = {
  versions: [],
  authors: {},
  tags: [],
};

const fetchDashboardVersions = (
  newState: DashboardVersionsReducerState,
  action: PayloadAction<FetchDashboardVersionsResponse>,
) => {
  const { payload } = action;

  const newVersionsById = _.indexBy(payload.versions, 'id');

  newState.versions.forEach((version) => {
    if (!newVersionsById[version.id]) {
      payload.versions.push(version);
    }
  });

  newState.versions = payload.versions;
  newState.authors = _.indexBy(payload.authors, 'id');
  newState.tags = payload.tags;
  return newState;
};

const createNewDashboardVersion = (
  newState: DashboardVersionsReducerState,
  action: PayloadAction<CreateNewDashboardVersionResponse>,
) => {
  newState.versions.push(action.payload.dashboard_version);
  return newState;
};

const publishNewDashboardVersion = (
  newState: DashboardVersionsReducerState,
  action: PayloadAction<PublishNewDashboardVersionResponse>,
) => {
  const { payload } = action;

  const publishedVersion = _.findWhere(newState.versions, { id: payload.dashboard_version.id });

  if (publishedVersion) {
    publishedVersion.is_draft = payload.dashboard_version.is_draft;
    publishedVersion.published_by_id = payload.dashboard_version.published_by_id;
    publishedVersion.change_comments = payload.dashboard_version.change_comments;
    publishedVersion.version_saved_at = payload.dashboard_version.version_saved_at;
  }
  return newState;
};

const clearDashboardVersions = (newState: DashboardVersionsReducerState) => {
  newState.versions = [];
  return newState;
};

const updateEnvironmentTag = (newState: DashboardVersionsReducerState, action: Action) => {
  const { postData } = action.payload;
  const tag = _.findWhere(newState.tags, { id: postData.tag_id });
  if (tag) {
    if (postData.name) tag.name = postData.name;
    if (postData.color_hex) tag.color_hex = postData.color_hex;
    if (postData.dashboard_versions_by_dashboard)
      tag.dashboard_versions_by_dashboard = postData.dashboard_versions_by_dashboard;
  }
  return newState;
};

const createEnvironmentTag = (newState: DashboardVersionsReducerState, action: Action) => {
  newState.tags.push(action.payload.tag);
  return newState;
};

const deleteCurrentDashboardDraftSuccess = (
  newState: DashboardVersionsReducerState,
  action: Action,
) => {
  const deletedVersionNumber = action.payload.postData.version_number;

  const index = newState.versions.findIndex(
    (version) => version.version_number === deletedVersionNumber,
  );

  newState.versions.splice(index, 1);

  return newState;
};

export default createReducer<DashboardVersionsReducerState>(initialState, {
  [`${ACTION.FETCH_DASHBOARD_VERSIONS}_SUCCESS`]: fetchDashboardVersions,
  [`${ACTION.FETCH_DASHBOARD_VERSIONS}_REQUEST`]: clearDashboardVersions,
  [`${ACTION.CREATE_NEW_DASHBOARD_VERSION}_SUCCESS`]: createNewDashboardVersion,
  [`${ACTION.PUBLISH_NEW_DASHBOARD_VERSION}_SUCCESS`]: publishNewDashboardVersion,
  [`${ACTION.UPDATE_ENVIRONMENT_TAG}_REQUEST`]: updateEnvironmentTag,
  [`${ACTION.CREATE_ENVIRONMENT_TAG}_SUCCESS`]: createEnvironmentTag,
  [`${ACTION.DELETE_CURRENT_DASHBOARD_DRAFT}_SUCCESS`]: deleteCurrentDashboardDraftSuccess,
});
