/** @format */

import React, { useState, useContext, useRef, useEffect, memo } from 'react';
import { dayjs, DATE_FORMAT_TO_LOCALIZED_FORMAT } from 'utils/localizationUtils';
import _ from 'underscore';
import cx from 'classnames';
import Color from 'color';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import {
  Cell,
  Column,
  ColumnHeaderCell,
  SelectionModes,
  Table,
  RenderMode,
  IRegion,
} from '@blueprintjs/table';
import { IProps, IconName, Icon, Tooltip } from '@blueprintjs/core';
import ResizeObserver from 'react-resize-observer';

import ColumnHeaderText from 'components/dataTable/columnHeaderText';

import { Dataset, TableRow } from 'actions/types';
import {
  BooleanDisplayOptions,
  ColumnInfo,
  DateDisplayFormat,
  DateDisplayOptions,
  DisplayOptions,
  GradientType,
  NumberDisplayDisplayType,
  NumberDisplayFormat,
  NumberDisplayOptions,
  Schema,
  SortOrder,
  StringDisplayFormat,
  StringDisplayOptions,
  TableJoinColumnConfig,
  VisualizeNumberInstructions,
} from 'constants/types';
import {
  BOOLEAN,
  DATE_TYPES,
  NUMBER_TYPES,
  STRING,
  V2_NUMBER_FORMATS,
} from 'constants/dataConstants';
import { DEFAULT_CATEGORY_COLORS } from 'constants/colorConstants';
import { SortInfo } from 'actions/types';
import { DATE_DISPLAY_FORMAT_TO_DAYJS_FORMAT } from 'constants/dataPanelEditorConstants';
import ProgressBar from 'components/ProgressBar';
import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import FlexBox, { VerticalAlignment as FlexboxVerticalAlignment } from 'components/core/FlexBox';
import FlexItem from 'components/core/FlexItem';
import { GlobalStyleConfig } from 'globalStyles/types';
import { getGradientColor } from 'utils/dashboardUtils';
import { MetricsByColumn } from 'types/dashboardTypes';
import { mixColors } from 'utils/general';
import { formatValue } from 'pages/dashboardPage/charts/utils';
import { isJoinConfigReady } from 'pages/dashboardPage/DataPanelConfigV2/FormatConfigTab/formatSections/TableColumnsConfig/TableColumnsConfig';

export const GENERIC_TABLE_HEIGHT = 277;
const TABLE_CORNER_DIMENSIONS = 30;
export const TABLE_ROW_HEIGHT = 30;
const DEFAULT_COL_WIDTH = 100;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  tableHeight: {
    height: GENERIC_TABLE_HEIGHT,
  },
  noBorderRadius: {
    borderRadius: 0,
  },
  tableColumnHeaderText: {
    display: 'flex',
    alignItems: 'center',
  },
  tableColumnHeaderTypeIcon: {
    marginRight: theme.spacing(2),
  },
  grayedColumn: {
    opacity: 0.3,
  },
  table: {
    flex: 1,
  },
  tableTheme: {
    width: '100%',

    '& .bp3-table-selection-enabled.bp3-table-column-headers .bp3-table-header': {
      cursor: 'pointer',
    },
    '& .bp3-table-menu': {
      backgroundColor: theme.palette.ds.grey200,
    },
    '& .bp3-table-row-headers .bp3-table-header': {
      backgroundColor: theme.palette.ds.white,
    },

    '& .bp3-table-header:hover > .bp3-table-column-name': {
      backgroundColor: 'inherit',
    },

    '& .bp3-table-quadrant': {
      backgroundColor: 'transparent',
    },
    '& .bp3-table-truncated-text': {
      width: '100%',
    },
  },
  tableCell: (styleConfigAndProps: GlobalStyleConfig & Props) => ({
    display: 'flex',
    alignItems: 'center',
    height: '100%',

    '&.firstCell': {
      paddingLeft: styleConfigAndProps.container.padding.default,
    },

    '&.LEFT_ALIGN': {
      textAlign: 'left',
    },
    '&.CENTER_ALIGN': {
      textAlign: 'center',
    },
    '&.RIGHT_ALIGN': {
      textAlign: 'right',
    },
  }),
  columnHeaderCell: (styleConfigAndProps: GlobalStyleConfig & Props) => ({
    paddingRight: 1,
    height: styleConfigAndProps.rowHeight || TABLE_ROW_HEIGHT,

    '&.firstCell .bp3-table-column-name-text': {
      paddingLeft: styleConfigAndProps.container.padding.default,
    },

    '&.bolded': {
      fontWeight: 'bold',
    },

    '&:hover .columnSortIcon': {
      visibility: styleConfigAndProps.disableColumnSorting ? 'hidden' : 'initial',
    },

    '& .bp3-table-column-name': {
      height: '100% !important',
    },
  }),
  categoryCellData: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: 16,
    display: 'inline-block',
  },
  progressBar: {
    height: 12,
  },
  progressBarValue: {
    fontSize: 12,
    marginRight: theme.spacing(1),
  },
  progressBarTooltip: {
    width: '100%',
  },
}));

const useStyleContextStyles = makeStyles({
  table: (styleConfig: GlobalStyleConfig) => ({
    '& .bp3-table-quadrant-scroll-container': {
      '&::-webkit-scrollbar': {
        borderLeft: `1px solid ${styleConfig.container.outline.color}`,
        width: 10,
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: 10,
        backgroundColor: styleConfig.text.secondaryColor,
      },
    },
  }),
});

type Props = {
  className?: string;
  schema: Schema;
  loading?: boolean;
  isSortable: boolean;
  maxRows: number;
  rows: TableRow[];
  selectedColumns?: Set<string>;
  disableRowHeader?: boolean;
  extractCellData?: (rowIndex: number, colIndex: number, rows: TableRow[]) => string | number;
  numFrozenColumns?: number;
  fill?: boolean;
  noBorderRadius?: boolean;
  truncateEmptyRowSpace?: boolean;
  unrestrictedHeight?: boolean;
  useFriendlyNameForHeader?: boolean;
  shouldTruncateText?: boolean;
  sortInfo?: SortInfo;
  metricsByColumn?: MetricsByColumn;
  onColumnSelect?: (colIndex: number, schema: Schema) => void;
  enableColumnResizing?: boolean;
  columnWidths?: Array<number | null | undefined>;
  onColumnWidthChanged?: (index: number, size: number) => void;
  schemaDisplayOptions?: Record<string, DisplayOptions & TableJoinColumnConfig>;
  visualizeNumberInstructions?: VisualizeNumberInstructions;
  rowHeight?: number;
  disableColumnSorting?: boolean;
  rowLinesDisabled?: boolean;
  columnLinesEnabled?: boolean;
  isColumnHeadersBolded?: boolean;
  isFirstColumnBolded?: boolean;
  dashboardDatasets?: Record<string, Dataset>;
};

const userSelectedSingleColumn = (region: IRegion) => {
  return region && !region.rows && region.cols && region.cols[0] === region.cols[1];
};

// eslint-disable-next-line
const BaseDataTable = memo((props: Props) => {
  const {
    schema,
    rows,
    extractCellData,
    noBorderRadius,
    truncateEmptyRowSpace,
    unrestrictedHeight,
    useFriendlyNameForHeader,
    sortInfo,
    isSortable,
    enableColumnResizing,
    columnWidths,
    onColumnWidthChanged,
    shouldTruncateText,
    schemaDisplayOptions,
    visualizeNumberInstructions,
    metricsByColumn,
    rowHeight,
    rowLinesDisabled,
    columnLinesEnabled,
    isColumnHeadersBolded,
    isFirstColumnBolded,
    dashboardDatasets,
  } = props;
  const context = useContext(GlobalStylesContext);
  const classes = useStyles({ ...context.globalStyleConfig, ...props });
  const styleContextClasses = useStyleContextStyles(context.globalStyleConfig);
  const theme: Theme = useTheme();
  const tableInstance = useRef<Table>(null);
  const [tableWidth, setTableWidth] = useState(-1);
  const [columnToCategoryToColorMap, setColumnToCategoryToColorMap] = useState<
    Record<string, Record<string | number, string>>
  >({});

  const addCategoryToColorMap = (
    columnName: string,
    category: string | number,
    assignedColor?: string,
  ) => {
    const numKeys = Object.keys(columnToCategoryToColorMap[columnName] || {}).length;
    const color = assignedColor || DEFAULT_CATEGORY_COLORS[numKeys % 12];
    setColumnToCategoryToColorMap((current) => {
      if (!current[columnName]) current[columnName] = {};
      current[columnName][category] = color;
      return current;
    });

    return color;
  };

  useEffect(() => {
    // after the table renders, resize it for any text that needs to wrap
    !shouldTruncateText && resizeRowHeightToFitContent();
  });

  const joinMapping: Record<string, Record<string | number, string | number>> = {};

  const setColumnWidth = () => {
    if (!tableWidth || tableWidth === -1 || schema.length * DEFAULT_COL_WIDTH >= tableWidth) {
      return undefined;
    }

    const EXTRA_WIDTH_PADDING = 10;

    return (tableWidth - EXTRA_WIDTH_PADDING) / schema.length;
  };

  const getColumnWidths = () => {
    if (!columnWidths) return undefined;

    if (schema.length > columnWidths.length) {
      // if columns are added, then we need `columnWidths` variable will have less entries than
      // the # of columns. In that case, Blueprint will crash. Prevent that by padding the array
      // with undefined which defaults the col width for the new columns
      return columnWidths.concat(_.times(schema.length - columnWidths.length, () => undefined));
    } else {
      return _.first(columnWidths, schema.length);
    }
  };

  const resizeRowHeightToFitContent = () => {
    tableInstance.current &&
      tableInstance.current.resizeRowsByApproximateHeight((rowIndex: number, colIndex: number) =>
        String(getCellData(rowIndex, colIndex)),
      );
  };

  const getCellData = (rowIndex: number, colIndex: number) => {
    const header = schema[colIndex].name;
    const cellData = rows[rowIndex][header];
    return cellData === undefined ? '' : cellData;
  };

  const cellRenderer = (rowIndex: number, colIndex: number) => {
    const cellData = extractCellData
      ? extractCellData(rowIndex, colIndex, rows)
      : getCellData(rowIndex, colIndex);
    const column = schema[colIndex];
    let backgroundColor = undefined;
    let color = undefined;
    const displayOptions = schemaDisplayOptions?.[column.name];

    if (
      metricsByColumn?.[column.name] &&
      displayOptions &&
      (displayOptions as NumberDisplayOptions).gradientType
    ) {
      const value = Number(cellData);
      if (cellData !== null && !isNaN(value)) {
        const {
          gradient,
          gradientType,
          gradientOptions,
          displayType,
        } = displayOptions as NumberDisplayOptions;
        backgroundColor =
          displayType !== NumberDisplayDisplayType.PROGRESS_BAR
            ? getGradientColor({
                value,
                gradient,
                gradientType,
                gradientOptions,
                metrics: metricsByColumn[column.name],
              })
            : undefined;
        color = new Color(backgroundColor).isDark()
          ? theme.palette.ds.white
          : theme.palette.ds.black;
      }
    }

    return (
      <Cell
        wrapText={!shouldTruncateText}
        className={cx(
          classes.tableCell,
          GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor,
          GLOBAL_STYLE_CLASSNAMES.text.body.primary,
          schemaDisplayOptions?.[column.name]?.alignment,
          { firstCell: colIndex === 0 },
        )}
        style={{
          boxShadow: `inset ${columnLinesEnabled ? '-1px' : '0'} ${
            rowLinesDisabled ? '0' : '-1px'
          } 0 ${context.globalStyleConfig.container.outline.color}, inset 0px 0 0`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          ...(backgroundColor && { backgroundColor, color }),
          fontWeight: isFirstColumnBolded && colIndex === 0 ? 'bold' : undefined,
        }}>
        {formatCellData(cellData, column)}
      </Cell>
    );
  };

  const formatCellData = (cellData: string | number, column: ColumnInfo) => {
    if (cellData === null || cellData === undefined) return '';

    if (!schemaDisplayOptions?.[column.name]) {
      if (DATE_TYPES.has(column.type)) {
        return dayjs.utc(cellData).format(DATE_FORMAT_TO_LOCALIZED_FORMAT['MM/DD/YYYY h:mm aa']);
      } else if (column.type === BOOLEAN && ['true', 'false'].includes(String(cellData))) {
        return (
          <>
            <Icon
              icon={String(cellData) === 'true' ? 'tick' : 'cross'}
              className={GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.color}
            />
          </>
        );
      } else if (NUMBER_TYPES.has(column.type)) {
        return formatValue({
          value: Number(cellData),
          decimalPlaces: 0,
          formatId: V2_NUMBER_FORMATS.PLAIN_TEXT.id,
          hasCommas: false,
        });
      }
      return String(cellData);
    }

    const colConfig = schemaDisplayOptions[column.name];

    let colType = column.type;

    if (
      dashboardDatasets &&
      isJoinConfigReady(colConfig) &&
      colConfig.joinDisplayColumn &&
      colConfig.joinTable?.id
    ) {
      if (!joinMapping[colConfig.joinTable.id]) {
        const dataset = dashboardDatasets[colConfig.joinTable.id];
        joinMapping[dataset.id] = {};
        dataset._rows?.forEach((row) => {
          if (!colConfig.joinColumn || !colConfig.joinDisplayColumn) return;
          joinMapping[dataset.id][row[colConfig.joinColumn.name]] =
            row[colConfig.joinDisplayColumn.name];
        });
      }

      cellData = joinMapping[colConfig.joinTable.id][cellData] ?? '';
      colType = colConfig.joinDisplayColumn.column.type;
    }

    if (DATE_TYPES.has(colType)) {
      const dateVal = dayjs.utc(cellData);
      const dateFormatOption = schemaDisplayOptions[column.name] as DateDisplayOptions;
      const dayjsFormat =
        dateFormatOption.format === DateDisplayFormat.CUSTOM
          ? dateFormatOption.customFormat ?? ''
          : DATE_DISPLAY_FORMAT_TO_DAYJS_FORMAT[dateFormatOption.format];

      if (!dateVal.isValid()) {
        return 'Invalid Date';
      }

      return dateVal.format(dayjsFormat);
    } else if (NUMBER_TYPES.has(colType)) {
      const value = Number(cellData);
      const {
        decimalPlaces,
        format,
        goal,
        useColumnMaxForGoal,
        gradientType,
        gradient,
        gradientOptions,
        displayType,
        displayTypeOptions,
        hasCommas,
        timeFormat,
        timeCustomFormat,
      } = schemaDisplayOptions[column.name] as NumberDisplayOptions;
      const goalValue = useColumnMaxForGoal ? metricsByColumn?.[column.name]?.max : goal;
      const usesGradient = [GradientType.DIVERGING, GradientType.LINEAR].includes(
        gradientType ?? GradientType.NONE,
      );
      const gradientColor =
        usesGradient && metricsByColumn?.[column.name]
          ? getGradientColor({
              value,
              gradient,
              gradientType,
              gradientOptions,
              metrics: metricsByColumn?.[column.name],
            })
          : undefined;

      let formattedValue: string;
      switch (format) {
        case NumberDisplayFormat.CURRENCY:
          formattedValue = formatValue({
            value,
            decimalPlaces: decimalPlaces ?? 2,
            formatId: V2_NUMBER_FORMATS.CURRENCY.id,
            hasCommas,
          });
          break;
        case NumberDisplayFormat.PERCENT:
          formattedValue = goalValue
            ? formatValue({
                value: value / goalValue,
                decimalPlaces: decimalPlaces ?? 0,
                formatId: V2_NUMBER_FORMATS.PERCENT.id,
                hasCommas,
              })
            : formatValue({
                value,
                decimalPlaces: decimalPlaces ?? 0,
                formatId: V2_NUMBER_FORMATS.PERCENT.id,
                hasCommas,
              });
          break;
        case NumberDisplayFormat.TIME:
          formattedValue = formatValue({
            value,
            formatId: V2_NUMBER_FORMATS.TIME.id,
            hasCommas,
            timeFormatId: timeFormat?.id,
            customTimeFormat: timeCustomFormat,
          });
          break;
        default:
          formattedValue = formatValue({
            value,
            decimalPlaces,
            formatId: V2_NUMBER_FORMATS.PLAIN_TEXT.id,
            hasCommas,
          });
          break;
      }

      if (displayType === NumberDisplayDisplayType.PROGRESS_BAR) {
        const progressBarGoal = displayTypeOptions?.useColumnMaxForProgressBarGoal
          ? metricsByColumn?.[column.name]?.max
          : displayTypeOptions?.progressBarGoal;
        return (
          <Tooltip
            targetClassName={classes.progressBarTooltip}
            content={`${value} / ${progressBarGoal}`}>
            <FlexBox verticalAlignment={FlexboxVerticalAlignment.CENTER}>
              <div className={classes.progressBarValue}>{formattedValue}</div>
              <FlexItem>
                <ProgressBar
                  className={classes.progressBar}
                  color={
                    usesGradient && metricsByColumn?.[column.name]
                      ? gradientColor
                      : visualizeNumberInstructions?.displayFormat.progressColor ||
                        theme.palette.ds.blue
                  }
                  backgroundColor={
                    gradientColor && metricsByColumn?.[column.name]
                      ? mixColors(gradientColor, theme.palette.ds.white, 0.5).rgb().string()
                      : visualizeNumberInstructions?.displayFormat.progressColor ||
                        theme.palette.ds.lightBlue
                  }
                  value={progressBarGoal ? value / progressBarGoal : 0}
                />
              </FlexItem>
            </FlexBox>
          </Tooltip>
        );
      }

      return formattedValue;
    } else if (colType === STRING) {
      const { format, label, categoryColorAssignments, addedCategories } = schemaDisplayOptions[
        column.name
      ] as StringDisplayOptions;
      const cellDataString = String(cellData);

      switch (format) {
        case StringDisplayFormat.CATEGORY: {
          const categoryToColorMap = columnToCategoryToColorMap?.[column.name] || {};
          const addedCategoriesByName = _.indexBy(addedCategories || [], 'name');

          let backgroundColor: string | undefined;
          if (addedCategoriesByName[cellData]) {
            backgroundColor = addedCategoriesByName[cellData].color;
            if (backgroundColor !== categoryToColorMap[cellData])
              addCategoryToColorMap(column.name, cellData, backgroundColor);
          } else if (categoryColorAssignments?.[cellData]) {
            backgroundColor = categoryColorAssignments?.[cellData];
            if (backgroundColor !== categoryToColorMap[cellData])
              addCategoryToColorMap(column.name, cellData, backgroundColor);
          } else if (categoryToColorMap[cellData]) {
            backgroundColor = categoryToColorMap[cellData];
          } else {
            backgroundColor = addCategoryToColorMap(column.name, cellData);
          }

          return (
            <span className={classes.categoryCellData} style={{ backgroundColor }}>
              {cellDataString}
            </span>
          );
        }
        case StringDisplayFormat.LINK:
          return (
            <a href={cellDataString} target="_blank" rel="noopener noreferrer">
              {label}
            </a>
          );
      }
    } else if (colType === BOOLEAN) {
      const { falseIcon, trueIcon } = schemaDisplayOptions[column.name] as BooleanDisplayOptions;
      const cellDataString = String(cellData);

      if (!['true', 'false'].includes(cellDataString)) {
        return 'Invalid boolean';
      }

      return (
        <Icon
          icon={cellDataString === 'true' ? trueIcon : falseIcon}
          className={GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.color}
        />
      );
    }

    return String(cellData);
  };

  const renderDefaultNameRenderer = (header: string, index?: number) => {
    let rightIcon: IconName | undefined = undefined;
    if (index === undefined) return <div></div>;

    if (sortInfo && schema[index].name === sortInfo.column_name) {
      rightIcon = sortInfo.order === SortOrder.ASC ? 'arrow-down' : 'arrow-up';
    }

    return (
      <ColumnHeaderText
        headerList={schema}
        index={index}
        header={header}
        rightIcon={rightIcon}
        isSortable={isSortable}
        alignment={schemaDisplayOptions?.[schema[index].name]?.alignment}
      />
    );
  };

  const nameRenderer = (
    columnName: string,
    columnInternalName: string,
    index: number,
    renderColumnHeaderTextFunction: (header: string, index?: number) => React.ReactElement<IProps>,
  ) => {
    const resultFn = () => (
      <ColumnHeaderCell
        className={cx(
          classes.columnHeaderCell,
          GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor,
          schemaDisplayOptions?.[columnInternalName]?.alignment,
          { bolded: isColumnHeadersBolded, firstCell: index === 0 },
        )}
        style={{
          boxShadow: columnLinesEnabled
            ? `0 1px 0 ${context.globalStyleConfig.container.outline.color}, inset -1px 0 0 ${context.globalStyleConfig.container.outline.color}`
            : `0 1px 0 ${context.globalStyleConfig.container.outline.color}`,
        }}
        name={columnName}
        // @ts-ignore
        truncated={false}
        nameRenderer={renderColumnHeaderTextFunction}
      />
    );

    return resultFn;
  };

  const columns =
    schema &&
    schema.map((columnInfo, index) => {
      const columnName = useFriendlyNameForHeader ? columnInfo.friendly_name : columnInfo.name;
      return (
        <Column
          cellRenderer={cellRenderer}
          columnHeaderCellRenderer={nameRenderer(
            columnName || '',
            columnInfo.name,
            index,
            renderDefaultNameRenderer,
          )}
          key={index}
          name={columnInfo.name}
        />
      );
    });

  return (
    <div
      className={cx(
        classes.root,
        {
          [classes.noBorderRadius]: noBorderRadius,
          [classes.tableHeight]: !unrestrictedHeight,
        },
        props.className,
      )}
      style={
        truncateEmptyRowSpace && rows.length < 13
          ? { height: rows.length * TABLE_ROW_HEIGHT + TABLE_CORNER_DIMENSIONS }
          : undefined
      }>
      <Table
        className={cx(
          { [classes.table]: props.fill },
          classes.tableTheme,
          styleContextClasses.table,
          GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor,
        )}
        enableColumnResizing={enableColumnResizing}
        renderMode={RenderMode.BATCH_ON_UPDATE}
        numFrozenColumns={props.numFrozenColumns}
        enableRowHeader={!props.disableRowHeader}
        numRows={rows ? Math.min(rows.length, props.maxRows) : props.maxRows}
        key={_.uniqueId('table')}
        selectionModes={SelectionModes.COLUMNS_AND_CELLS}
        ref={tableInstance}
        onSelection={
          isSortable
            ? (selectedRegions: IRegion[]) => {
                if (userSelectedSingleColumn(selectedRegions[0]) && selectedRegions[0].cols) {
                  props.onColumnSelect && props.onColumnSelect(selectedRegions[0].cols[0], schema);
                }
              }
            : undefined
        }
        getCellClipboardData={(row, col) =>
          getCellData(row, col) === null ? '' : String(getCellData(row, col))
        }
        defaultColumnWidth={setColumnWidth()}
        defaultRowHeight={rowHeight || TABLE_ROW_HEIGHT}
        onColumnWidthChanged={(index: number, size: number) => {
          onColumnWidthChanged && onColumnWidthChanged(index, size);
          !shouldTruncateText && resizeRowHeightToFitContent();
        }}
        columnWidths={getColumnWidths()}>
        {columns}
      </Table>
      <ResizeObserver onResize={(rect) => setTableWidth(rect.width)} />
    </div>
  );
});

export default BaseDataTable;
