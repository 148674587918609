/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Theme, WithStyles } from '@material-ui/core/index';
import { cloneDeep } from 'lodash';

import { OPERATION_TYPES } from 'constants/types';
import EditingPanel from 'pages/dataPanelEditorPage/editingPanel';
import DropdownSelect from 'shared/DropdownSelect';
import ColorPicker from 'pages/dataPanelEditorPage/colorPicker';
import LineBarSelect from 'pages/dataPanelEditorPage/lineBarSelect';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { LineColumn } from 'constants/types';
import { TableColumn } from 'actions/types';
import { NUMBER_TYPES, SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';
import { titleCase } from 'utils/graphUtils';
import { ColorHex, COLOR_LIST } from 'constants/colorConstants';
import { SelectedDropdownInputItem } from 'constants/types';
import { NUMBER_FORMATS } from 'constants/dataConstants';

const styles = (theme: Theme) =>
  createStyles({
    columnSelect: {
      marginBottom: theme.spacing(2),
    },
    operatorSelect: {
      marginBottom: theme.spacing(2),
    },
    colorAndAxisSelectorContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(2),
    },
    chartTypeSelector: {
      marginLeft: theme.spacing(3),
    },
    columnSelector: {
      marginLeft: theme.spacing(3),
      width: '84%',

      '&.smaller': {
        width: '57%',
      },
    },
    labelContainer: {
      marginBottom: theme.spacing(2),
    },
  });

type PassedProps = {
  schema: TableColumn[];
  className?: string;
  index: number;
  lineColumn: LineColumn;
  operationType: OPERATION_TYPES;
  onDelete: () => void;
  onUpdate: (lineColumn: LineColumn) => void;
  filterRemainingYAxisColumns: (tableColumns: TableColumn[]) => TableColumn[];
};

type Props = PassedProps & WithStyles<typeof styles> & RouteComponentProps;

class YAxisConfigPanel extends React.Component<Props> {
  render() {
    const { className, onDelete } = this.props;
    return (
      <EditingPanel className={className} onDelete={onDelete}>
        {this.renderColorAndAxisSelector()}
        {this.renderColumnLabelInput()}
        {this.renderColumnFormatInput()}
      </EditingPanel>
    );
  }

  renderColorAndAxisSelector = () => {
    const { classes, lineColumn } = this.props;
    return (
      <div className={classes.colorAndAxisSelectorContainer}>
        {this.renderLineColorPicker()}
        {this.renderColumnTypeSelector(lineColumn)}
        {this.renderLineColumnDropdown()}
      </div>
    );
  };

  renderLineColorPicker = () => {
    const { lineColumn, index } = this.props;
    return (
      <ColorPicker
        selectedColor={lineColumn.color || COLOR_LIST[index % COLOR_LIST.length].primary}
        onUpdate={this.onColorUpdate}
        colors={_.pluck(COLOR_LIST, 'primary')}
      />
    );
  };

  renderColumnTypeSelector = (lineColumn: LineColumn) => {
    const { operationType, classes } = this.props;
    if (operationType !== OPERATION_TYPES.VISUALIZE_COMBO_CHART) return <div></div>;
    return (
      <div className={classes.chartTypeSelector}>
        <LineBarSelect
          onComboUpdateChartType={this.onComboUpdateChartType}
          lineColumn={lineColumn}
        />
      </div>
    );
  };

  renderLineColumnDropdown = () => {
    const { schema, lineColumn, classes, operationType, filterRemainingYAxisColumns } = this.props;
    return (
      <div
        className={cx(classes.columnSelector, {
          smaller: operationType === OPERATION_TYPES.VISUALIZE_COMBO_CHART,
        })}>
        <DropdownSelect
          selectedItem={
            lineColumn.column
              ? lineColumn.column.name && lineColumn.column.type
                ? {
                    name: lineColumn.column.name,
                    id: lineColumn.column.name,
                    icon: SCHEMA_DATA_TYPES_BY_ID[lineColumn.column.type].icon,
                  }
                : undefined
              : undefined
          }
          onChange={(item: SelectedDropdownInputItem) => this.onSelectedColUpdate(item.name)}
          filterable={false}
          options={filterRemainingYAxisColumns(
            schema.filter((col) => NUMBER_TYPES.has(col.type)),
          ).map((col) => ({
            ...col,
            icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
            id: col.name,
          }))}
          noSelectionText="Axis column"
          minimal
          fillWidth
          showIcon
        />
      </div>
    );
  };

  onColorUpdate = (colorHex: ColorHex) => {
    const { onUpdate, lineColumn } = this.props;
    const newLineCol = cloneDeep(lineColumn);
    newLineCol.color = colorHex;
    onUpdate(newLineCol);
  };

  onComboUpdateChartType = (chartType: string) => {
    const { onUpdate, lineColumn } = this.props;
    const newLineCol = cloneDeep(lineColumn);
    newLineCol.lineOrBar = chartType;
    onUpdate(newLineCol);
  };

  onSelectedColUpdate = (columnName: string) => {
    const { schema, onUpdate, lineColumn } = this.props;
    const schemaByName = _.indexBy(schema, 'name');
    const newLineCol = cloneDeep(lineColumn);

    newLineCol.column = {
      name: columnName,
      type: schemaByName[columnName].type,
      friendly_name: titleCase(columnName),
    };
    onUpdate(newLineCol);
  };

  renderColumnLabelInput = () => {
    const { lineColumn, classes } = this.props;

    return (
      <InputWithBlurSave
        containerClassName={classes.labelContainer}
        initialValue={lineColumn.column ? lineColumn.column.friendly_name || '' : ''}
        onNewValueSubmitted={this.onLabelChange}
        placeholder="Label"
      />
    );
  };

  onLabelChange = (newValue: string) => {
    const { onUpdate, lineColumn } = this.props;
    const newLineCol = cloneDeep(lineColumn);

    if (!newLineCol.column) {
      newLineCol.column = {
        name: undefined,
        friendly_name: newValue,
        type: undefined,
      };
    } else {
      newLineCol.column.friendly_name = newValue;
    }

    onUpdate(newLineCol);
  };

  renderColumnFormatInput = () => {
    const { lineColumn } = this.props;
    if (lineColumn.column && lineColumn.column.type && !NUMBER_TYPES.has(lineColumn.column.type))
      return;

    return (
      <DropdownSelect
        selectedItem={lineColumn.numberFormat || NUMBER_FORMATS.NORMAL}
        onChange={this.onColumnFormatChange}
        noSelectionText="Select format"
        options={Object.values(NUMBER_FORMATS)}
        minimal
        fillWidth
        showIcon
      />
    );
  };

  onColumnFormatChange = (formatOption: SelectedDropdownInputItem) => {
    const { onUpdate, lineColumn } = this.props;
    const newLineCol = cloneDeep(lineColumn);

    newLineCol.numberFormat = formatOption;
    onUpdate(newLineCol);
  };
}

export default withRouter(withStyles(styles)(YAxisConfigPanel));
