/**
 * @format
 */

import { DataPanelTemplate } from 'actions/types';
import { DashboardElement } from 'types/dashboardTypes';

export function isArray<T>(value: T | T[]): value is T[] {
  return !!(value as T[]).map;
}

export function isDashboardElement(
  item: DataPanelTemplate | DashboardElement,
): item is DashboardElement {
  return !!(item as DashboardElement).element_type;
}
