/** @format */

import React from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import EditingPanel from 'pages/dataPanelEditorPage/editingPanel';

import { SelectedDropdownInputItem, SortClause, SortOrder } from 'constants/types';
import { TableColumn } from 'actions/types';
import DropdownSelect from 'shared/DropdownSelect';
import { NUMBER_TYPES, SCHEMA_DATA_TYPES_BY_ID, STRING } from 'constants/dataConstants';
import { SORT_ORDERS } from 'constants/dataPanelEditorConstants';
import IconButtonGroup from 'components/IconButtonGroup';

const useStyles = makeStyles((theme: Theme) => ({
  sortPanel: {
    marginBottom: theme.spacing(4),
  },
  flexContainer: {
    display: 'flex',
  },
  dropdownInputContainer: {
    width: '74%',
    marginRight: theme.spacing(3),
  },
}));

type Props = {
  sortClause: SortClause;
  schema: TableColumn[];
  sortedByColumnNames?: string[];
  onDelete: () => void;
  onColumnSelect: (column: TableColumn) => void;
  onOrderSelect: (order: SortOrder) => void;
};

export default function SortEditingPanel({
  sortedByColumnNames,
  sortClause,
  schema,
  onDelete,
  onColumnSelect,
  onOrderSelect,
}: Props) {
  const { column, order } = sortClause;
  const classes = useStyles();
  const columnBySchemaName = _.indexBy(schema, 'name');

  return (
    <EditingPanel className={classes.sortPanel} onDelete={onDelete}>
      <div className={classes.flexContainer}>
        <div className={classes.dropdownInputContainer}>
          <DropdownSelect
            fillWidth
            minimal
            showIcon
            filterable={false}
            noSelectionText="Column"
            options={schema.flatMap((col) => {
              if (sortedByColumnNames?.includes(col.name)) {
                return [];
              }
              return {
                name: col.name,
                id: col.name,
                icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
              };
            })}
            selectedItem={
              column
                ? {
                    name: column.name,
                    id: column.name,
                    icon: SCHEMA_DATA_TYPES_BY_ID[column.type].icon,
                  }
                : undefined
            }
            onChange={(item: SelectedDropdownInputItem) => {
              onColumnSelect(columnBySchemaName[item.id]);
            }}
          />
        </div>
        <IconButtonGroup
          callbacks={SORT_ORDERS.map((sortOrder) => () => {
            onOrderSelect(sortOrder);
          })}
          active={order ? SORT_ORDERS.indexOf(order) : undefined}
          icons={
            column?.type === STRING
              ? ['sort-alphabetical', 'sort-alphabetical-desc']
              : NUMBER_TYPES.has(column?.type ?? '')
              ? ['sort-numerical', 'sort-numerical-desc']
              : ['sort-asc', 'sort-desc']
          }
        />
      </div>
    </EditingPanel>
  );
}
