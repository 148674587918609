/** @format */

import React from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';

import { AggedChartColumnInfo } from 'constants/types';
import { COMBO_CHART_DATA_FORMATS } from 'constants/dashboardConstants';

const styles = (theme: Theme) => ({
  lineBarSelectWrapper: {
    marginBottom: theme.spacing(2),
    width: '100%',
    marginRight: theme.spacing(2),
  },
  lineBarSelect: {
    marginBottom: theme.spacing(2),
    width: '100%',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  lineOrBarButton: {
    width: 'calc(50% - 12px)',
  },
  label: {
    fontSize: 11,
    color: theme.palette.ds.grey700,
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
});
type PassedProps = {
  onComboUpdateChartType: (chartType: COMBO_CHART_DATA_FORMATS) => void;
  column: AggedChartColumnInfo;
  label?: string;
};

type Props = WithStyles<typeof styles> & PassedProps;

class LineBarSelect extends React.Component<Props> {
  render() {
    const { onComboUpdateChartType, column, classes, label } = this.props;
    const selectedType = column.column.dataFormat || COMBO_CHART_DATA_FORMATS.LINE;

    return (
      <div>
        <div>
          {label && <div className={classes.label}>{label}</div>}
          <ButtonGroup className={classes.lineBarSelect}>
            <Button
              onClick={() => onComboUpdateChartType(COMBO_CHART_DATA_FORMATS.BAR)}
              id="barButton"
              icon="timeline-bar-chart"
              active={selectedType === COMBO_CHART_DATA_FORMATS.BAR}
              className={classes.lineOrBarButton}>
              Bar
            </Button>
            <Button
              onClick={() => onComboUpdateChartType(COMBO_CHART_DATA_FORMATS.LINE)}
              id="lineButton"
              icon="timeline-line-chart"
              active={selectedType === COMBO_CHART_DATA_FORMATS.LINE}
              className={classes.lineOrBarButton}>
              Line
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(LineBarSelect);
