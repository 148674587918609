/** @format */
import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Popover, Position, Checkbox } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { cloneDeep } from 'lodash';

import Tag from 'components/core/Tag';
import FlexBox, { VerticalAlignment, HorizontalAlignment } from 'components/core/FlexBox';
import Button from 'shared/Button';
import TagEditPopover from './TagEditPopover';

import { ACTION, DashboardEnvironmentTag } from 'actions/types';
import { createLoadingSelector } from 'reducers/api/selectors';
import { updateEnvironmentTag, createEnvironmentTag } from 'actions/dashboardV2Actions';
import { TRIPLE_DOT_MENU } from 'constants/iconConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 175,
  },
  tagMenuOption: {
    paddingLeft: theme.spacing(2),
    borderRadius: 4,
    marginBottom: theme.spacing(1),
  },
  tagCheckbox: {
    margin: 0,
  },
  tag: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
  },
  tagEditBtn: {
    marginLeft: theme.spacing(4),
    '&.bp3-button': {
      backgroundColor: 'transparent',
      '&.bp3-button:hover': {
        backgroundColor: theme.palette.ds.grey300,
      },
      '&.bp3-button:active': {
        backgroundColor: theme.palette.ds.hovered.grey300,
      },
    },
    '&.bp3-button.bp3-active': {
      backgroundColor: theme.palette.ds.hovered.grey300,
      boxShadow: 'none',
      '&.bp3-button.bp3-active': {
        backgroundColor: theme.palette.ds.pressed.grey300,
      },
    },
  },
  createNewBtn: {
    width: '100%',
  },
}));

export type PassedProps = {
  className?: string;
  tags: DashboardEnvironmentTag[];
  dashboardTemplateId: number;
  versionId: number;
};

type Props = PassedProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const PublishToDropdown: FunctionComponent<Props> = (props) => {
  const {
    className,
    tags,
    updateEnvironmentTag,
    createEnvironmentTag,
    createTagLoading,
    versionId,
    dashboardTemplateId,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={cx(classes.root, className)}>
      {tags.map((tag) => (
        <FlexBox
          key={`tag-${tag.id}`}
          verticalAlignment={VerticalAlignment.CENTER}
          horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}
          className={classes.tagMenuOption}>
          <FlexBox verticalAlignment={VerticalAlignment.CENTER}>
            <Checkbox
              className={classes.tagCheckbox}
              onClick={() => {
                const newVersionMap = cloneDeep(tag.dashboard_versions_by_dashboard || {});
                if (newVersionMap[dashboardTemplateId] === versionId) {
                  delete newVersionMap[dashboardTemplateId];
                } else {
                  newVersionMap[dashboardTemplateId] = versionId;
                }
                updateEnvironmentTag({
                  postData: { tag_id: tag.id, dashboard_versions_by_dashboard: newVersionMap },
                });
              }}
              checked={
                tag.dashboard_versions_by_dashboard &&
                tag.dashboard_versions_by_dashboard[dashboardTemplateId] === versionId
              }
            />
            <Tag name={tag.name} backgroundColor={tag.color_hex} className={classes.tag} />
          </FlexBox>
          <div
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              e.preventDefault();
              e.stopPropagation();
            }}>
            <Popover
              minimal
              hoverCloseDelay={0}
              position={Position.BOTTOM_RIGHT}
              content={
                <TagEditPopover
                  name={tag.name}
                  color={tag.color_hex}
                  updateName={(newName: string) =>
                    updateEnvironmentTag({ postData: { tag_id: tag.id, name: newName } })
                  }
                  updateColor={(newColor: string) =>
                    updateEnvironmentTag({ postData: { tag_id: tag.id, color_hex: newColor } })
                  }
                />
              }>
              <Button
                minimal
                className={classes.tagEditBtn}
                icon={TRIPLE_DOT_MENU(theme.palette.ds.grey900)}
              />
            </Popover>
          </div>
        </FlexBox>
      ))}
      <Button
        minimal
        disabled={createTagLoading}
        loading={createTagLoading}
        className={classes.createNewBtn}
        icon="plus"
        onClick={() => !createTagLoading && createEnvironmentTag({})}
        text="Create new"
      />
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  createTagLoading: createLoadingSelector([ACTION.CREATE_ENVIRONMENT_TAG], false)(state),
});

const mapDispatchToProps = {
  updateEnvironmentTag,
  createEnvironmentTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishToDropdown);
