/** @format */

import * as Redux from 'redux';
import { createAction } from '@reduxjs/toolkit';
import { User, Team, ACTION } from 'actions/types';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

import { defineAPIAction } from 'actions/actionUtils';
import { UserAuthenticationToken } from 'actions/responseTypes';

export const verifyEmail = () => (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: 'VERIFY_EMAIL',
    payload: {},
  });
};

export const logoutUser = () => ({
  type: 'LOGOUT_USER',
  payload: {},
});

const loginUserRequest = () => ({
  type: 'LOGIN_USER_REQUEST',
});

const loginUserError = (errorMsg: string) => ({
  type: 'LOGIN_USER_ERROR',
  payload: errorMsg,
});

export const loginUserSuccess = createAction<User>('LOGIN_USER_SUCCESS');

export function loginUser(
  email: string,
  password: string,
  successCallback: (user: User) => void,
  errorCallback: (response: object) => void,
) {
  return function (dispatch: Redux.Dispatch<any>) {
    dispatch(loginUserRequest());
    return fetch(process.env.REACT_APP_API_URL + 'rest-auth/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (!data.key) {
          dispatch(loginUserError(''));
          errorCallback(data);
        } else {
          Cookies.set('spheres_auth_token', data.key);
          dispatch(loginUserSuccess(data.user));
          successCallback(data.user);
        }
      });
  };
}

const registerUserRequest = () => ({
  type: 'REGISTER_USER_REQUEST',
});

const registerUserError = (errorMsg: string) => ({
  type: 'REGISTER_USER_ERROR',
  payload: errorMsg,
});

const registerUserSuccess = (user: User) => ({
  type: 'REGISTER_USER_SUCCESS',
  payload: user,
});

export function registerUser(
  first_name: string,
  last_name: string,
  email: string,
  password1: string,
  password2: string,
  sucessCallback: (user: User) => void,
  errorCallback: (response: object) => void,
) {
  return function (dispatch: Redux.Dispatch<any>) {
    dispatch(registerUserRequest());
    return fetch(process.env.REACT_APP_API_URL + 'rest-auth/registration/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        first_name: first_name,
        last_name: last_name,
        email: email,
        password1: password1,
        password2: password2,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (!data.key) {
          dispatch(registerUserError(''));
          errorCallback(data);
        } else {
          Cookies.set('spheres_auth_token', data.key);
          dispatch(registerUserSuccess(data.user));
          sucessCallback(data.user);
        }
      });
  };
}

const createTeamRequest = () => ({
  type: 'CREATE_TEAM_REQUEST',
});

const createTeamError = () => ({
  type: 'CREATE_TEAM_ERROR',
});

const createTeamSuccess = (team: Team) => ({
  type: 'CREATE_TEAM_SUCCESS',
  payload: {
    team,
  },
});

export const createTeam = (
  teamName: string,
  onSuccess: () => void,
  onError: (errorMsg: string) => void,
) => {
  return function (dispatch: Redux.Dispatch<any>) {
    dispatch(createTeamRequest());
    return fetch(process.env.REACT_APP_API_URL + 'teams/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
      },
      body: JSON.stringify({
        team_name: teamName,
        join_team: true,
      }),
    })
      .then((resp) => {
        if (resp.status && resp.status >= 400) {
          throw resp.statusText;
        }
        return resp.json();
      })
      .then((data) => {
        if (!data.success) {
          dispatch(createTeamError());
          onError(data.msg);
        } else {
          createTeamSuccess(data);
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(createTeamError());
        onError(error);
      });
  };
};

const addUserToTeamRequest = () => ({
  type: 'ADD_USER_TO_TEAM_REQUEST',
});

const addUserToTeamError = () => ({
  type: 'ADD_USER_TO_TEAM_ERROR',
});

const addUserToTeamSuccess = (team: Team) => ({
  type: 'ADD_USER_TO_TEAM_SUCCESS',
  payload: {
    team,
  },
});

export const addUserToTeam = (
  userId: number | string,
  inviteCode: string,
  onSuccess: () => void,
  onError: (errorMsg: any) => void,
  inviteHash?: string,
) => {
  return function (dispatch: Redux.Dispatch<any>) {
    dispatch(addUserToTeamRequest());
    return axios
      .request<any>({
        url: process.env.REACT_APP_API_URL + 'teams/add_team_member/',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
        },
        data: {
          invite_code: inviteCode,
          user_id: userId,
          invite_hash: inviteHash,
        },
      })
      .then((response: AxiosResponse) => {
        const { data } = response;
        if (!data.success) {
          dispatch(addUserToTeamError());
          onError(data.msg);
        } else {
          addUserToTeamSuccess(data);
          onSuccess();
        }
      })
      .catch((error: AxiosError) => {
        dispatch(addUserToTeamError());
        onError(error.response);
      });
  };
};

export const { actionFn: passwordResetRequest } = defineAPIAction(
  ACTION.PASSWORD_RESET_REQUEST,
  'password_reset',
  '',
  'POST',
);

export const { actionFn: passwordResetValidateToken } = defineAPIAction(
  ACTION.PASSWORD_RESET_VALIDATE_TOKEN,
  'password_reset',
  'validate_token',
  'POST',
);

export const { actionFn: passwordResetConfirm } = defineAPIAction(
  ACTION.PASSWORD_RESET_CONFIRM,
  'password_reset',
  'confirm',
  'POST',
);

export const { actionFn: changeUserProfileName } = defineAPIAction(
  ACTION.CHANGE_USER_PROFILE_NAME,
  'settings',
  'change_user_profile_name',
  'POST',
);

export const { actionFn: inviteTeammate } = defineAPIAction(
  ACTION.INVITE_TEAMMATE,
  'teams',
  'invite_users',
  'POST',
);

export const { actionFn: fetchSignupEmail } = defineAPIAction(
  ACTION.FETCH_SIGNUP_EMAIL,
  'settings',
  'get_signup_info',
  'POST',
);

export const { actionFn: acceptInvite } = defineAPIAction(
  ACTION.ACCEPT_INVITE,
  'teams',
  'accept_invite',
  'POST',
);

export const { actionFn: googleOAuthVerification } = defineAPIAction<UserAuthenticationToken>(
  ACTION.GOOGLE_OAUTH_VERIFICATION,
  'auth',
  'google_oauth_verification',
  'POST',
);
