/** @format */
import { IconName } from '@blueprintjs/core';

export enum SETTINGS_SUB_SECTION {
  PROFILE = 'Profile',
  MEMBERS = 'Members',
  API_TOKENS = 'API Tokens',
  ANALYTICS = 'Analytics',
  DOMAIN_WHITELISTING = 'Domain Whitelisting',
  INTERNATIONALIZATION = 'Internationalization',

  // unused
  WHITELIST_IP = 'IP Whitelisting',
  DOMAIN_SETTINGS = 'Domain Settings',
  BILLING = 'Billing',
}

const ACCOUNT_GROUP: SettingLeftMenuSection = {
  groupName: 'Account',
  icon: 'user',
  subsections: [SETTINGS_SUB_SECTION.PROFILE, SETTINGS_SUB_SECTION.MEMBERS],
};

const DEVELOPER_GROUP: SettingLeftMenuSection = {
  groupName: 'Developer',
  icon: 'code',
  subsections: [
    SETTINGS_SUB_SECTION.API_TOKENS,
    SETTINGS_SUB_SECTION.ANALYTICS,
    SETTINGS_SUB_SECTION.DOMAIN_WHITELISTING,
    SETTINGS_SUB_SECTION.INTERNATIONALIZATION,
  ],
};

type SettingLeftMenuSection = {
  groupName: string;
  icon: IconName;
  subsections: SETTINGS_SUB_SECTION[];
};

export const SETTINGS_LEFT_MENU_SECTIONS = [ACCOUNT_GROUP, DEVELOPER_GROUP];
