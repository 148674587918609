/** @format */

import { identify } from 'analytics/exploAnalytics';
import { Action } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import cloneDeep from 'lodash/cloneDeep';
import { User } from 'actions/types';

import { userLoginAnalytics } from 'utils/landingPageUtils';

export interface AuthReducerState extends User {
  loginLoading?: boolean;
  registrationLoading?: boolean;
  joinTeamLoading?: boolean;
  createTeamLoading?: boolean;
  team_data_source_connected?: boolean;
  team_tables_synced?: boolean;
}

const authReducerInitialState: AuthReducerState = {};
export default (
  state: AuthReducerState = authReducerInitialState,
  action: Action,
): AuthReducerState => {
  const newState = cloneDeep(state);
  const { payload } = action;

  switch (action.type) {
    case 'LOGIN_USER_REQUEST':
      newState.loginLoading = true;
      return newState;
    case 'LOGIN_USER_ERROR':
      newState.loginLoading = false;
      return newState;
    case 'LOGIN_USER_SUCCESS': {
      const user = cloneDeep(Object.assign({}, state, action.payload));
      user.loginLoading = false;
      userLoginAnalytics(user);

      return user;
    }
    case `${ACTION.GOOGLE_OAUTH_VERIFICATION}_SUCCESS`: {
      const user = cloneDeep(Object.assign({}, state, action.payload.user));
      user.loginLoading = false;
      userLoginAnalytics(user);

      return user;
    }
    case 'REGISTER_USER_REQUEST':
      newState.registrationLoading = true;
      return newState;
    case 'REGISTER_USER_ERROR':
      newState.registrationLoading = false;
      return newState;
    case 'REGISTER_USER_SUCCESS': {
      const newUser = cloneDeep(Object.assign({}, state, action.payload));
      identify(newUser.id, {
        first_name: newUser.first_name,
        last_name: newUser.last_name,
        email: newUser.email,
        profile_data: newUser.profile_data,
      });

      newUser.registrationLoading = false;
      return newUser;
    }
    case 'VERIFY_EMAIL':
      if (!state) {
        return newState;
      } else {
        newState.email_verified = true;
        return newState;
      }
    case 'ADD_USER_TO_TEAM_REQUEST':
      newState.joinTeamLoading = true;
      return newState;
    case 'CREATE_TEAM_REQUEST':
      newState.createTeamLoading = true;
      return newState;
    case 'ADD_USER_TO_TEAM_ERROR':
    case 'ADD_USER_TO_TEAM_SUCCESS':
      newState.joinTeamLoading = false;
      return newState;
    case 'ADD_USER_TO_TEAM_FROM_INVITE_SUCCESS':
      newState.joinTeamLoading = false;
      return newState;
    case 'CREATE_TEAM_ERROR':
    case 'CREATE_TEAM_SUCCESS':
      newState.createTeamLoading = false;
      return newState;
    case 'CONNECT_DATA_SOURCE_SUCCESS':
      newState.team_data_source_connected = true;
      return newState;
    case 'SYNC_SOURCE_TABLES_SUCCESS':
      newState.team_tables_synced = true;
      return newState;
    case 'CHANGE_USER_PROFILE_NAME_SUCCESS':
      newState.first_name = payload.first_name;
      newState.last_name = payload.last_name;
      return newState;
    default:
      return newState;
  }
};
