/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { Theme, withTheme, WithTheme } from '@material-ui/core/styles';
import GridLayout, {
  Responsive as BaseResponsiveGridLayout,
  WidthProvider,
  Layout,
} from '@explo-tech/react-grid-layout';

import DashboardDatasetView from 'pages/dashboardPage/DashboardDatasetView';
import DashboardElementContainer from './DashboardElementContainer';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import {
  droppingElementId,
  getDraggingConfig,
  MOBILE_BREAKPOINT_WIDTH,
  DASHBOARD_ROW_HEIGHT,
  PDF_PAGE_HEIGHT,
  PDF_PAGE_BREAK_HEIGHT,
  PDF_EDITOR_PAGE_BORDER_WIDTH,
  PDF_EDITOR_MARGIN_SIZE,
  ROWS_PER_PDF_PAGE,
} from 'constants/dashboardConstants';
import {
  validateLayout,
  processLayout,
  formatContainerElementHeightForMobile,
  processUserInputConfig,
  getLayoutHeightInRows,
  resolveSecondaryLayout,
  getSortedGridItems,
} from 'utils/dashboardUtils';
import { isDashboardElement } from 'utils/typeUtils';
import {
  ContainerElemConfig,
  DashboardElement,
  DashboardVariable,
  DashboardVariableMap,
  VIEW_MODE,
} from 'types/dashboardTypes';
import {
  DataPanelTemplate,
  DashboardTemplate,
  Dashboard,
  Dataset,
  ShareData,
  AdHocOperationInstructions,
} from 'actions/types';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import withWidth from 'components/HOCs/withWidth';
import { UpdateElementConfigV2Args } from 'actions/dashboardV2Actions';
import { ALLOWED_DATA_SOURCES_FOR_DP_DOWNLOADS } from 'constants/dataPanelEditorConstants';
import { GlobalStyleConfig } from 'globalStyles/types';
import { REPORTED_ANALYTIC_ACTION_TYPES, UserTransformedSchema } from 'constants/types';
import { ErrorResponse, ExportUrlResponse } from 'actions/responseTypes';
import { DownloadChartInfo } from './DashboardLayout';

const MARGIN = 12;

const ResponsiveGridLayout = WidthProvider(BaseResponsiveGridLayout);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      overflowY: 'auto',
      flexDirection: 'column',
      position: 'relative',
    },
    pdfEditor: {
      marginTop: theme.spacing(8),
      /**
       * We want to force the background to be transparent in the PDF view because the "background"
       * is created by the renderPdfEditorPages function
       */
      backgroundColor: 'transparent !important',
      overflowY: 'visible',
    },
    containerRoot: {
      borderRadius: 8,
      /**
       * The container takes up an extra row, which minus the 1px border on each side means there will be
       * ROW_HEIGHT - 2 - MARGIN px of extra space on the bottom. By adding this top padding, the content will be
       * vertically centered when the container height is minimized.
       */
      paddingTop: (DASHBOARD_ROW_HEIGHT - 2 - MARGIN) / 2,
      overflowY: 'hidden',
    },
    editableDashboardLayout: {
      minHeight: `5000px !important`,
    },
    containerDashboardLayout: {
      minHeight: `100% !important`,
    },
    fullHeightLayout: {
      height: '100% !important',
    },
    dataTable: { height: '100%' },
    dashboardPanel: {
      position: 'relative',
    },
    dashboardElementPanel: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    editableDashboardElement: {
      position: 'relative',

      '&:hover': {
        outline: `2px solid ${theme.palette.ds.hovered.lightBlue}`,
        outlineOffset: 5,

        '& .dashboardElementIdTag': {
          visibility: 'initial',
        },
      },
    },
    editableContainerElement: {
      position: 'relative',

      '&:hover': {
        outline: `2px solid ${theme.palette.ds.hovered.lightBlue}`,
        outlineOffset: 5,

        '& .dashboardContainerIdTag': {
          visibility: 'initial',
        },
      },
    },
    dashboardPanelMenu: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    dotBg: {
      backgroundImage: `radial-gradient(circle at 1px 1px, ${theme.palette.ds.grey600} 1px, transparent 0)`,
      backgroundSize: 'calc(10% - 0.1px) 50px',
      height: 'calc(5000px - 48px)',
      width: 'calc(100% - 48px)',
      position: 'absolute',
    },
    selectedItem: {
      outline: `2px solid ${theme.palette.ds.blue}`,
      outlineOffset: 5,

      '&:hover': {
        outline: `2px solid ${theme.palette.ds.hovered.blue} !important`,

        '& .dashboardElementIdTag': {
          backgroundColor: `${theme.palette.ds.hovered.blue} !important`,
        },
      },

      '& .dashboardElementIdTag': {
        visibility: 'initial',
        backgroundColor: `${theme.palette.ds.blue} !important`,
        color: `${theme.palette.ds.white} !important`,
      },
    },
    emptyContainerState: {
      width: '100% !important',
      height: '100% !important',
      transform: 'none !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.ds.grey600,
    },
  });

export type PassedProps = {
  ref?: React.Ref<unknown>;
  analyticsEventTracker?: (
    eventName: REPORTED_ANALYTIC_ACTION_TYPES,
    metaData?: Record<string, string | number>,
  ) => void;
  clearDownloadCsvInfo: () => void;
  columns?: number;
  containerId?: string;
  containerRows?: number;
  dashboardDatasets: { [datasetId: string]: Dataset };
  dashboardElements?: DashboardElement[];
  dashboardTemplate: DashboardTemplate | Dashboard;
  dataPanelTemplates: DataPanelTemplate[];
  dashboardLayout: Layout[];
  disableInputs?: boolean;
  downloadChartInfoByPanel?: { [dataPanelId: string]: DownloadChartInfo };
  draggingElementType?: string;
  editBaseOn?: boolean;
  editableDashboard?: boolean;
  pdfExportUrlLoading?: boolean;
  imageExportUrlLoading?: boolean;
  fetchImageExportUrl: (
    onSuccess?: (data: ExportUrlResponse) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => void;
  fetchPdfExportUrl: (
    onSuccess?: (data: ExportUrlResponse) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => void;
  fetchShareData?: (password?: string, isStrictViewingMode?: boolean) => void;
  globalStyleConfig: GlobalStyleConfig;
  isViewOnly: boolean;
  onDrop: (newLayout: Layout[], layoutItem: Layout, event: Event, containerId?: string) => void;
  onDashboardItemSelect?: (type: string, id: string) => void;
  onDownloadPanelPdf: (
    dataPanelTemplate: DataPanelTemplate,
    adHocOperationInstructions: AdHocOperationInstructions,
    userTransformedSchema?: UserTransformedSchema,
    reportName?: string,
  ) => void;
  onDownloadDataPanelCsv: (
    dataPanelTemplate: DataPanelTemplate,
    schema?: UserTransformedSchema,
  ) => void;
  removeDroppingPlaceholders?: (() => void)[];
  selectedDashboardItem?: {
    elementType: string;
    elementId: string;
  };
  setVariable: (varName: string, value: DashboardVariable, onVariableUpdated?: () => void) => void;
  shareData?: ShareData;
  shareLinkLoading?: boolean;
  updateDashboardTemplateLayout?: (newLayout: Layout[]) => void;
  updateElementConfig?: (args: UpdateElementConfigV2Args) => void;
  updateUrlParams?: boolean;
  userGroupId?: number;
  userGroupName?: string;
  width?: number;
  onAdHocOperationInstructionsUpdated: (
    dataPanelTemplateId: string,
    adHocOperationInstructions: AdHocOperationInstructions,
    skipRowCount?: boolean,
  ) => void;
  variables: DashboardVariableMap;
  viewMode: VIEW_MODE;
};

type Props = PassedProps & WithStyles<typeof styles> & WithTheme;

type State = {
  isResizing: boolean;
};

class ElementGridLayout extends React.Component<Props, State> {
  gridLayout: React.RefObject<GridLayout>;

  state: State = {
    isResizing: false,
  };

  constructor(props: Props) {
    super(props);

    this.gridLayout = React.createRef();
  }

  isMobileView() {
    const breakpoint = MOBILE_BREAKPOINT_WIDTH;
    return (this.props.width ?? 0) <= breakpoint && this.props.isViewOnly;
  }

  render() {
    const { classes, containerId, isViewOnly, removeDroppingPlaceholders, viewMode } = this.props;

    const isPdfEditor = !isViewOnly && viewMode === VIEW_MODE.PDF && !containerId;

    return (
      <div
        className={cx(classes.root, {
          [classes.containerRoot]: containerId,
          [classes.pdfEditor]: isPdfEditor,
          [cx(GLOBAL_STYLE_CLASSNAMES.base.backgroundColor.backgroundColor)]: !containerId,
          [cx(
            GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor,
            GLOBAL_STYLE_CLASSNAMES.container.outline.border,
            GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow,
            GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadius,
            GLOBAL_STYLE_CLASSNAMES.container.padding.default.padding,
          )]: containerId,
        })}
        id="scrollableLayout"
        onDragEnter={() => {
          removeDroppingPlaceholders?.forEach((removePlaceholder) => {
            removePlaceholder();
          });
        }}>
        {isPdfEditor && this.renderPdfEditorPages()}
        {this.renderGridLayout()}
      </div>
    );
  }

  renderPdfEditorPages = () => {
    const { dashboardLayout, theme } = this.props;
    const numberOfPages = Math.ceil(getLayoutHeightInRows(dashboardLayout) / ROWS_PER_PDF_PAGE);

    return _.times(numberOfPages, (pageNumber) => {
      return (
        <div
          key={pageNumber}
          style={{
            position: 'absolute',
            top: pageNumber * PDF_PAGE_HEIGHT + PDF_PAGE_BREAK_HEIGHT / 2,
            height: PDF_PAGE_HEIGHT - PDF_PAGE_BREAK_HEIGHT - PDF_EDITOR_PAGE_BORDER_WIDTH,
            width: '100%',
            backgroundColor: theme.palette.ds.white,
            border: `${PDF_EDITOR_PAGE_BORDER_WIDTH}px solid ${theme.palette.ds.grey400}`,
          }}
        />
      );
    });
  };

  removeDroppingPlaceholder = () => {
    const { dashboardLayout, dashboardElements, dataPanelTemplates, viewMode } = this.props;

    // @ts-ignore
    this.gridLayout?.current?.removeDroppingPlaceholder();
    this.gridLayout?.current?.setState({
      layout: processLayout({
        layout: dashboardLayout,
        dashboardElements,
        dataPanelTemplates,
        viewMode,
      }),
    });
  };

  renderGridLayout = () => {
    const {
      classes,
      columns,
      containerId,
      editBaseOn,
      dataPanelTemplates,
      dashboardElements,
      draggingElementType,
      editableDashboard,
      isViewOnly,
      dashboardLayout,
      containerRows,
      width,
      globalStyleConfig,
      viewMode,
    } = this.props;

    let layout = processLayout({
      layout: dashboardLayout,
      dashboardElements,
      dataPanelTemplates,
      viewMode,
    });
    if (this.isMobileView()) {
      layout = formatContainerElementHeightForMobile(layout, dashboardElements || []);
    }

    const gridLayoutChildren = getSortedGridItems(
      [
        ...this.filterContainedElements(dataPanelTemplates, containerId),
        ...this.filterContainedElements(dashboardElements || [], containerId),
      ],
      layout,
    ).map((gridItem) => {
      if (isDashboardElement(gridItem)) {
        return this.getDashboardElement(gridItem);
      }

      return this.renderDataPanel(gridItem);
    });

    if (draggingElementType) {
      gridLayoutChildren.push(this.renderDroppingElement());
    }

    const isEmptyContainer = containerId && !gridLayoutChildren.length;

    if (
      viewMode === VIEW_MODE.DEFAULT &&
      editableDashboard &&
      isEmptyContainer &&
      !draggingElementType
    ) {
      gridLayoutChildren.push(
        <div key="containerPlaceholder" className={classes.emptyContainerState}>
          Drag new elements into the container
        </div>,
      );
    }

    const marginSize =
      viewMode === VIEW_MODE.PDF ? PDF_EDITOR_MARGIN_SIZE : globalStyleConfig.base.spacing.default;

    const numRows = containerRows ?? 1;

    const sharedLayoutProps = {
      className: cx('layout', {
        [classes.editableDashboardLayout]: editableDashboard,
        [classes.containerDashboardLayout]: containerId,
      }),
      draggableCancel: 'input,textarea,.bp3-popover-wrapper',
      isDraggable: !isEmptyContainer && editBaseOn,
      isResizable: !isEmptyContainer && editBaseOn,
      // we don't allow nested containers
      isDroppable: containerId
        ? editableDashboard && draggingElementType !== DASHBOARD_ELEMENT_TYPES.CONTAINER
        : editableDashboard,
      onDrop: editableDashboard ? this.onDrop : undefined,
      compactType: this.dashboardIsEmpty() || viewMode === 'pdf' ? null : undefined,
      droppingItem: getDraggingConfig(draggingElementType),
      useCSSTransforms: !!editableDashboard,
      margin: [marginSize, marginSize] as [number, number],
      style:
        viewMode === 'pdf'
          ? {
              marginTop: -(PDF_EDITOR_MARGIN_SIZE / 2),
              marginBottom: -(PDF_EDITOR_MARGIN_SIZE / 2),
            }
          : undefined,
    };

    if (isViewOnly && !containerId) {
      return (
        <ResponsiveGridLayout
          layouts={{ lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }}
          cols={{
            lg: columns || globalStyleConfig.base.numColumns,
            md: columns || globalStyleConfig.base.numColumns,
            sm: columns || globalStyleConfig.base.numColumns,
            xs: 2,
            xxs: 2,
          }}
          /**
           * In order to account for internal container padding, we need to dynamically calculate
           * the rowHeight so the vertical spacing will be symmetric
           */
          rowHeight={
            containerId
              ? (DASHBOARD_ROW_HEIGHT * numRows -
                  globalStyleConfig.base.spacing.default -
                  2 * globalStyleConfig.container.padding.default) /
                (numRows - 1)
              : DASHBOARD_ROW_HEIGHT
          }
          {...sharedLayoutProps}>
          {gridLayoutChildren}
        </ResponsiveGridLayout>
      );
    }

    /**
     * This width will only be used for container layouts.
     * The height that we get from react-sizeme in DashboardContainerElement includes the padding and border widths of DashboardLayout
     */
    const containerWidth =
      width &&
      width -
        globalStyleConfig.container.padding.default * 2 -
        2 * (globalStyleConfig.container.outline.weight || 0);

    return (
      <GridLayout
        width={containerId ? containerWidth : width}
        ref={this.gridLayout}
        layout={layout}
        cols={columns || globalStyleConfig.base.numColumns}
        onDragStart={(_layout, _oldItem, _newItem, _placeholder, e) => {
          e.stopPropagation();
        }}
        onResizeStart={() => {
          this.setState({ isResizing: true });
        }}
        onResizeStop={() => {
          this.setState({ isResizing: false });
        }}
        onLayoutChange={(newLayout: Layout[]) => {
          this.handleLayoutChanged(newLayout, layout);
        }}
        /**
         * In order to account for internal container padding, we need to dynamically calculate
         * the rowHeight so the vertical spacing will be symmetric
         */
        rowHeight={
          containerId
            ? (DASHBOARD_ROW_HEIGHT * numRows -
                globalStyleConfig.base.spacing.default -
                2 * globalStyleConfig.container.padding.default) /
              (numRows - 1)
            : DASHBOARD_ROW_HEIGHT
        }
        {...sharedLayoutProps}>
        {gridLayoutChildren}
      </GridLayout>
    );
  };

  filterContainedElements<T extends DashboardElement | DataPanelTemplate>(
    elements: T[],
    containerId?: string,
  ) {
    return elements.filter((element) =>
      containerId
        ? element.container_id === containerId
        : element.container_id === undefined || element.container_id === null,
    );
  }

  dashboardIsEmpty = () => {
    const { dataPanelTemplates } = this.props;

    return dataPanelTemplates.length === 0;
  };

  onDrop = (layout: Layout[], layoutItem: Layout, event: Event) => {
    if (layoutItem === undefined) {
      return;
    }
    event.stopPropagation();
    const { containerId, onDrop } = this.props;
    onDrop(layout, layoutItem, event, containerId);
  };

  renderDataPanel = (dataPanelTemplate: DataPanelTemplate) => {
    const {
      classes,
      clearDownloadCsvInfo,
      downloadChartInfoByPanel,
      editBaseOn,
      onDashboardItemSelect,
      editableDashboard,
      selectedDashboardItem,
      analyticsEventTracker,
      containerId,
      isViewOnly,
      onAdHocOperationInstructionsUpdated,
      onDownloadDataPanelCsv,
      onDownloadPanelPdf,
      variables,
      setVariable,
      dashboardDatasets,
    } = this.props;

    dataPanelTemplate = processUserInputConfig(variables, dataPanelTemplate) || dataPanelTemplate;

    const isSelected =
      selectedDashboardItem &&
      (selectedDashboardItem.elementType === DASHBOARD_ELEMENT_TYPES.DATA_PANEL ||
        selectedDashboardItem.elementType === DASHBOARD_ELEMENT_TYPES.DATA_TABLE) &&
      selectedDashboardItem.elementId === dataPanelTemplate.id;

    return (
      <div
        key={dataPanelTemplate.id}
        className={cx(classes.dashboardPanel, {
          [classes.selectedItem]: isSelected,
          [classes.editableDashboardElement]: editableDashboard,
        })}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          !editBaseOn &&
            editableDashboard &&
            onDashboardItemSelect &&
            onDashboardItemSelect(DASHBOARD_ELEMENT_TYPES.DATA_PANEL, dataPanelTemplate.id);
          e.stopPropagation();
        }}>
        <DashboardDatasetView
          isViewOnly={isViewOnly}
          isInContainer={!!containerId}
          variables={variables}
          editBaseOn={editBaseOn}
          loading={
            dataPanelTemplate._loading === true ||
            dataPanelTemplate._loading === undefined ||
            !!dataPanelTemplate._outstandingSecondaryDataRequests
          }
          secondaryDataLoading={!!dataPanelTemplate._outstandingSecondaryDataRequests}
          error={dataPanelTemplate._error}
          dataPanelTemplateId={dataPanelTemplate.id}
          dataPanelProvidedId={dataPanelTemplate.provided_id || dataPanelTemplate.id}
          previewData={dataPanelTemplate._rows}
          schema={dataPanelTemplate._schema || []}
          panelName={dataPanelTemplate.name || ''}
          sourceDataRowCount={dataPanelTemplate._total_row_count}
          visualizeOperation={dataPanelTemplate.visualize_op}
          downloadCsvLoading={dataPanelTemplate._downloadCsvLoading}
          onAdHocOperationInstructionsUpdated={onAdHocOperationInstructionsUpdated}
          onDownloadPanelPdf={(
            adHocOperationInstructions: AdHocOperationInstructions,
            userTransformedSchema?: UserTransformedSchema,
            reportName?: string,
          ) =>
            onDownloadPanelPdf(
              dataPanelTemplate,
              adHocOperationInstructions,
              userTransformedSchema,
              reportName,
            )
          }
          onDownloadPanelCsv={(userTransformedSchema?: UserTransformedSchema) =>
            onDownloadDataPanelCsv(dataPanelTemplate, userTransformedSchema)
          }
          adHocOperationInstructions={dataPanelTemplate._adHocOperationInstructions || {}}
          secondaryData={dataPanelTemplate._secondaryData || []}
          canDownloadDataPanel={ALLOWED_DATA_SOURCES_FOR_DP_DOWNLOADS.includes(
            dataPanelTemplate._source_type ?? '',
          )}
          downloadChartInfo={
            downloadChartInfoByPanel && downloadChartInfoByPanel[dataPanelTemplate.id]
          }
          clearDownloadCsvInfo={clearDownloadCsvInfo}
          analyticsEventTracker={analyticsEventTracker}
          isSelected={isSelected}
          setVariable={(newValue: DashboardVariable) =>
            setVariable(dataPanelTemplate.provided_id || dataPanelTemplate.id, newValue)
          }
          dashboardDatasets={dashboardDatasets}
        />
      </div>
    );
  };

  getDashboardElement = (elem: DashboardElement) => {
    const {
      classes,
      containerId,
      disableInputs,
      editBaseOn,
      editableDashboard,
      selectedDashboardItem,
      onDashboardItemSelect,
      removeDroppingPlaceholders,
      setVariable,
      variables,
      viewMode,
    } = this.props;
    const { isResizing } = this.state;

    const isSelected =
      selectedDashboardItem &&
      selectedDashboardItem.elementType === elem.element_type &&
      selectedDashboardItem.elementId === elem.id;

    return (
      <div
        key={elem.id}
        className={cx(classes.dashboardElementPanel, {
          [classes.selectedItem]: isSelected,
          [classes.editableDashboardElement]:
            editableDashboard && elem.element_type !== DASHBOARD_ELEMENT_TYPES.CONTAINER,
          [classes.editableContainerElement]:
            editableDashboard && elem.element_type === DASHBOARD_ELEMENT_TYPES.CONTAINER,
        })}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          !editBaseOn &&
            editableDashboard &&
            onDashboardItemSelect &&
            onDashboardItemSelect(elem.element_type, elem.id);
          e.stopPropagation();
        }}>
        <DashboardElementContainer
          dashboardElement={elem}
          isInContainer={!!containerId}
          isResizing={isResizing}
          variables={variables}
          setVariable={setVariable}
          dashboardLayoutProps={{
            ...this.props,
            // We don't want to pass styles, and allows us to use easy syntax above
            ...{ classes: undefined },
            removeDroppingPlaceholders: [
              ...(removeDroppingPlaceholders || []),
              () => {
                this.removeDroppingPlaceholder();
              },
            ],
          }}
          isMobileView={this.isMobileView()}
          viewMode={viewMode}
          disableInputs={disableInputs ?? false}
        />
      </div>
    );
  };

  renderDroppingElement = () => {
    const { dashboardLayout, draggingElementType } = this.props;
    if (!dashboardLayout) return <div></div>;

    const elemsById = _.indexBy(dashboardLayout, 'i');
    if (elemsById[droppingElementId(draggingElementType)]) {
      const droppingElement = elemsById[droppingElementId(draggingElementType)];

      return <div key={droppingElement.i}></div>;
    }

    return <div></div>;
  };

  handleLayoutChanged = (layout: Layout[], oldLayout: Layout[]) => {
    const {
      containerId,
      containerRows,
      updateDashboardTemplateLayout,
      updateElementConfig,
      draggingElementType,
      dataPanelTemplates,
      dashboardElements,
      viewMode,
    } = this.props;
    if (draggingElementType) return;

    if (!containerId) {
      updateDashboardTemplateLayout && updateDashboardTemplateLayout(layout);
      return;
    }

    if (!validateLayout(layout, containerRows ?? 0)) {
      /**
       * When an the layout is changed, react-grid-layout changes the layout in it's state which
       * it uses to determine how to display the items. Passing the `layout` prop won't override this
       * state, so we need to use the ref to control it
       */
      this.gridLayout.current?.setState({ layout: oldLayout });
      updateElementConfig &&
        updateElementConfig({ elementId: containerId, config: { layout: oldLayout } });
      return;
    }

    const gridLayoutChildren = (this.filterContainedElements(dataPanelTemplates, containerId) as (
      | DashboardElement
      | DataPanelTemplate
    )[]).concat(this.filterContainedElements(dashboardElements || [], containerId));

    if (gridLayoutChildren.length === 0 && !draggingElementType) {
      this.gridLayout?.current?.setState({
        layout: [],
      });
    }

    const containerElement = dashboardElements?.find((element) => element.id === containerId);
    const containerConfig = containerElement?.config as ContainerElemConfig;

    if (viewMode === 'pdf') {
      updateElementConfig &&
        updateElementConfig({
          elementId: containerId,
          config: { ...containerConfig, pdfLayout: layout },
        });
      return;
    }

    let newConfig: ContainerElemConfig = { layout };

    if (containerConfig.pdfLayout) {
      newConfig = {
        ...newConfig,
        pdfLayout: resolveSecondaryLayout(layout, containerConfig.pdfLayout),
      };
    }

    updateElementConfig && updateElementConfig({ elementId: containerId, config: newConfig });
  };
}

export default withWidth(withStyles(styles)(withTheme(ElementGridLayout)));
