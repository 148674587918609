/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  rowCount: {
    fontSize: 14,
    fontWeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  rowCountError: {
    color: theme.palette.ds.red,
  },
}));

type Props = {
  rowCount: number;
  error?: boolean;
};

export default function RowCount({ error, rowCount }: Props) {
  const classes = useStyles();

  if (error) {
    return (
      <div className={cx(classes.rowCount, GLOBAL_STYLE_CLASSNAMES.text.body.secondary)}>
        <div className={classes.rowCountError}>Error fetching row count</div>
      </div>
    );
  }

  return (
    <div
      className={cx(
        classes.rowCount,
        GLOBAL_STYLE_CLASSNAMES.text.body.secondary,
      )}>{`${rowCount.toLocaleString()} Results`}</div>
  );
}
