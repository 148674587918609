/** @format */
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { ErrorResponse } from 'actions/responseTypes';
import { saveFavicon } from 'actions/shareActions';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { ReduxState } from 'reducers/rootReducer';
import InputLabel from 'shared/InputLabel';
import { showErrorContactSupportToast } from 'shared/sharedToasts';
import validator from 'validator';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `0 ${theme.spacing(8)}px 125px ${theme.spacing(8)}px`,
  },
  header: {
    fontSize: 20,
    fontWeight: 600,
  },
  subtitle: {
    color: theme.palette.ds.grey700,
    marginBottom: theme.spacing(8),
  },
  saveButton: {
    '&.bp3-button': {
      height: 30,
      margin: 0,
    },
  },
}));

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

function SettingsShareLinkSection(props: Props) {
  const { faviconUrl, saveFavicon } = props;

  const classes = useStyles();
  const [faviconUrlInternal, setFaviconUrlInternal] = useState(faviconUrl ?? '');
  const [isValidUrl, setIsValidUrl] = useState(true);

  useEffect(() => {
    // Fixes race condition where faviconUrl hasn't loaded yet but we set faviconUrlInternal
    if (faviconUrl !== '') setFaviconUrlInternal(faviconUrl as string);
  }, [faviconUrl]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>Share Links</div>
      <div className={classes.subtitle}>
        {'Configure globally applied customizations to your share links and share link pages.'}
      </div>
      <InputLabel
        text="Page Favicon URL"
        helpText="This should be your site's domain. We'll automatically grab the favicon from there and show it on your share link pages!"
      />
      <InputWithBlurSave
        initialValue={faviconUrlInternal}
        placeholder="www.yourdomain.com"
        onNewValueSubmitted={(newValue: string) => {
          setFaviconUrlInternal(newValue);
          const isValidUrl = validator.isURL(newValue) || newValue === '';
          setIsValidUrl(isValidUrl);

          if (isValidUrl) {
            saveFavicon(
              {
                postData: {
                  favicon_url: newValue,
                },
              },
              undefined,
              (response: ErrorResponse) => {
                showErrorContactSupportToast(response);
              },
            );
          }
        }}
        errorText={isValidUrl ? undefined : 'Invalid URL'}
      />
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  faviconUrl: state.teamData.data?.favicon_url,
});

const mapDispatchToProps = { saveFavicon };

export default connect(mapStateToProps, mapDispatchToProps)(SettingsShareLinkSection);
