/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';

import DropdownSelect from 'shared/DropdownSelect';

import { SelectedDropdownInputItem, VisualizeChoroplethMapInstructions } from 'constants/types';
import { TableColumn } from 'actions/types';
import { SCHEMA_DATA_TYPES_BY_ID, NUMBER_TYPES } from 'constants/dataConstants';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(6),
    },
  });

type PassedProps = {
  schema: TableColumn[];
  instructions: VisualizeChoroplethMapInstructions;
  updateVisualizeOperation: (
    newVisualizeLineChartInstructions: VisualizeChoroplethMapInstructions,
  ) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class MapDensityColumnConfiguration extends React.Component<Props> {
  render() {
    const { classes, schema, instructions } = this.props;
    const { densityColumn } = instructions;

    return (
      <div className={classes.root}>
        <DropdownSelect
          label="Density Column"
          selectedItem={
            densityColumn?.name && densityColumn?.type
              ? {
                  name: densityColumn.name,
                  id: densityColumn.name,
                  icon: SCHEMA_DATA_TYPES_BY_ID[densityColumn.type].icon,
                }
              : undefined
          }
          onChange={this.onDensityColChange}
          noSelectionText="Select column"
          options={schema
            .filter((col) => NUMBER_TYPES.has(col.type))
            .map((col) => ({
              name: col.name,
              id: col.name,
              icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
            }))}
          minimal
          fillWidth
          showIcon
        />{' '}
      </div>
    );
  }

  onDensityColChange = (item: SelectedDropdownInputItem) => {
    const { instructions, updateVisualizeOperation, schema } = this.props;

    const selectedColumn = _.findWhere(schema, {
      name: item.name,
    });

    instructions.densityColumn = selectedColumn;
    updateVisualizeOperation(instructions);
  };
}

export default withStyles(styles)(MapDensityColumnConfiguration);
