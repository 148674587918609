/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import LoadingSpinner from 'images/loading_spinner.gif';

const styles = () => ({
  loadingBody: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingSpinner: {
    width: 75,
  },
});

type Props = WithStyles<typeof styles>;

class LoadingBody extends React.Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.loadingBody}>
        <img className={classes.loadingSpinner} src={LoadingSpinner} alt="loading spinner" />
      </div>
    );
  }
}

export default withStyles(styles)(LoadingBody);
