/** @format */

import { TableColumn } from 'actions/types';
import _ from 'underscore';
import {
  CategoryChartColumnInfo,
  AggedChartColumnInfo,
  ConfigurableColumnInfo,
  AggregationType,
} from 'constants/types';
import { IToastProps } from '@blueprintjs/core';
import {
  DATE_TYPES,
  PIVOT_AGG_TYPES,
  NUMBER_TYPES,
  AGGREGATIONS_TYPES,
  DATE_PIVOT_AGGS,
  DATE_PART_AGGS,
  AGGREGATIONS_LIST,
  NON_NUMBER_AGGREGATIONS_LIST,
  DATE_PART_INPUT_AGG,
  PeriodRangeTypes,
} from 'constants/dataConstants';
import { dayjs } from 'utils/localizationUtils';
import { DashboardElement, DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';

export const resolveCategoryColDropped = (
  newCol: TableColumn,
  config?: CategoryChartColumnInfo,
) => {
  const bucket = DATE_TYPES.has(newCol.type) ? PIVOT_AGG_TYPES.DATE_MONTH : undefined;
  if (config) {
    config.column = newCol;
    config.bucket = bucket;
    return config;
  } else {
    return {
      column: newCol,
      bucket,
    };
  }
};

export const defaultAggByType = (type: string) => {
  if (NUMBER_TYPES.has(type)) return AGGREGATIONS_TYPES.SUM;
  return AGGREGATIONS_TYPES.COUNT;
};

export const defaultPeriodType = () => {
  return PeriodRangeTypes.LAST_4_WEEKS;
};

export const resolveAggColDropped = (
  newCol: TableColumn,
  columns?: AggedChartColumnInfo[],
  maxAggs?: number,
  yAxisFormatId?: string,
  onError?: (toastProps: IToastProps) => void,
) => {
  columns = columns || [];
  if (maxAggs) columns = columns.splice(0, maxAggs);

  // build a mapping of {column name: [list agg type]}
  const currentColNamesToColInfo = _.groupBy(columns, (col) => col.column.name);
  let newAgg: AggregationType = defaultAggByType(newCol.type);

  if (currentColNamesToColInfo[newCol.name]) {
    const currentAggTypes = _.pluck(currentColNamesToColInfo[newCol.name], 'agg');
    const firstNewAgg = getNextAggType(newCol, currentAggTypes);
    if (!firstNewAgg) {
      onError?.({
        message: `The selected column is already present for all possible aggregations. Duplicates are not allowed.`,
        icon: 'warning-sign',
        timeout: 5000,
      });

      return columns;
    } else {
      newAgg = firstNewAgg;
    }
  }

  const newAggCol = {
    column: newCol,
    agg: newAgg,
    yAxisFormatId: yAxisFormatId,
  };
  if (columns.length === maxAggs) {
    columns[maxAggs - 1] = newAggCol;
  } else {
    columns.push(newAggCol);
  }

  return columns;
};

export const getMaybeFirstNonMatchingAgg = (
  validAggTypes: AggregationType[],
  currentAggTypes?: AggregationType[],
): AggregationType | undefined => {
  currentAggTypes = currentAggTypes || [];
  const currentAggTypesSet = new Set(_.pluck(currentAggTypes, 'name'));
  return _.compact(
    validAggTypes.map((aggType) => {
      if (!currentAggTypesSet.has(aggType.name)) {
        return aggType;
      } else {
        return undefined;
      }
    }),
  )[0];
};

export const getNextAggType = (newCol: TableColumn, currAggTypes?: AggregationType[]) => {
  if (NUMBER_TYPES.has(newCol.type)) {
    return getMaybeFirstNonMatchingAgg(AGGREGATIONS_LIST, currAggTypes);
  } else {
    return getMaybeFirstNonMatchingAgg(NON_NUMBER_AGGREGATIONS_LIST, currAggTypes);
  }
};

export const getColDisplayText = (col: ConfigurableColumnInfo) => {
  if (col.bucket) {
    return `${col.bucket.name.toUpperCase()}(${col.column.name || ''})`;
  } else if (col.agg) {
    return `${col.agg.name.toUpperCase()}(${col.column.name || ''})`;
  }
  return col.column.name;
};

export const getGroupOptions = (
  col: ConfigurableColumnInfo,
  dashboardElements?: DashboardElement[],
): {
  title?: string;
  options: { id: string; name: string; getExample?: (dateTime: dayjs.Dayjs) => string }[];
}[] => {
  if (col.bucket) {
    if (DATE_TYPES.has(col.column.type || '')) {
      const dateGroupOptions = [
        { title: 'Dates and Times', options: DATE_PIVOT_AGGS },
        {
          title: 'Date Parts',
          options: DATE_PART_AGGS,
        },
      ];
      const dateGroupElements = filterForValidDateGroupElements(dashboardElements);
      if (dateGroupElements.length > 0) {
        dateGroupOptions.push({
          title: 'User Inputs',
          options: [
            {
              id: DATE_PART_INPUT_AGG,
              name: 'Date Group Switch',
              getExample: () => '',
            },
          ],
        });
      }
      return dateGroupOptions;
    }
  } else if (col.agg) {
    if (NUMBER_TYPES.has(col.column.type || '')) {
      return [{ options: Object.values(AGGREGATIONS_TYPES) }];
    } else {
      return [{ options: NON_NUMBER_AGGREGATIONS_LIST }];
    }
  }
  return [];
};

export const filterForValidDateGroupElements = (dashboardElements?: DashboardElement[]) => {
  if (!dashboardElements) return [];
  return dashboardElements.filter(
    (elem) => elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH,
  );
};
