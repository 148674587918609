/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.lightBlue,
    borderRadius: 4,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    height: '100%',
    fontSize: 12,
    color: theme.palette.ds.blue,
  },
}));

export type Props = {
  /**
   * Classname override
   */
  className?: string;

  /**
   * Text to be displayed in the card
   */
  text: string;
};

const InfoCard: React.FC<Props> = ({ className, text }) => {
  const classes = useStyles();

  return <div className={cx(classes.root, className)}>{text}</div>;
};

export default InfoCard;
