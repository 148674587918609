/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';

import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import ColorPicker from 'pages/dataPanelEditorPage/colorPicker';

import { VisualizeFunnelInstruction } from 'constants/types';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(6),
    },
    colorSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(4),
    },
    colorOptionText: {},
  });

type PassedProps = {
  instructions: VisualizeFunnelInstruction;
  updateVisualizeOperation: (newInstructions: VisualizeFunnelInstruction) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class SelectFunnelColors extends React.Component<Props> {
  render() {
    const { classes, instructions, updateVisualizeOperation } = this.props;

    return (
      <div className={classes.root}>
        <ConfigSectionHeader isSubTitle={true} title="Funnel Colors" />
        <div className={classes.colorSection}>
          <div className={classes.colorOptionText}>Start Color</div>
          <ColorPicker
            selectedColor={instructions.startColor || '#0069ED'}
            colors={[]}
            onUpdate={(newColor: string) => {
              instructions.startColor = newColor;
              updateVisualizeOperation(instructions);
            }}
          />
        </div>

        <div className={classes.colorSection}>
          <div className={classes.colorOptionText}>End Color</div>
          <ColorPicker
            selectedColor={instructions.endColor || '#3890FF'}
            colors={[]}
            onUpdate={(newColor: string) => {
              instructions.endColor = newColor;
              updateVisualizeOperation(instructions);
            }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SelectFunnelColors);
