/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, createStyles } from '@material-ui/styles';
import { WithStyles } from '@material-ui/core/index';

import ColorPicker from 'pages/dataPanelEditorPage/colorPicker';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    colorSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    colorOptionText: {},
  });

type PassedProps = {
  className?: string;
  label: string;
  color: string;
  onChange: (newColor: string) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class ColorPickerGroup extends React.Component<Props> {
  render() {
    const { classes, className, color, onChange, label } = this.props;

    return (
      <div className={cx(classes.root, className)}>
        <div className={classes.colorSection}>
          <div className={classes.colorOptionText}>{label}</div>
          <ColorPicker selectedColor={color || '#0069ED'} colors={[]} onUpdate={onChange} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ColorPickerGroup);
