/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';
import { useDrop } from 'react-dnd';

import GenericDroppedColumn from './GenericDroppedColumn';

import { ItemTypes, ConfigColumnItem } from 'constants/dragAndDrop';
import { TableColumn } from 'actions/types';
import { ChartColumnInfo, DataType } from 'constants/types';
import { AppToaster } from 'toaster';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.ds.grey600,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    border: `1px solid ${theme.palette.ds.white}`, // to account for hover border
  },
  colIsOver: {
    border: `1px dashed ${theme.palette.ds.blue}`,
    boxShadow: `0px 0px 2px ${theme.palette.ds.blue}`,
  },
}));

export type Props = {
  onColDropped: (col: TableColumn) => void;
  column?: { column: ChartColumnInfo };
  options?: { id: string; name: string }[];
  selectedOption?: { id: string; name: string };
  onColOptionChanged: (option: { id: string; name: string }, name?: string) => void;
  onRemoveCol: (name?: string) => void;
  disableEdits?: boolean;
  allowedTypes?: DataType[];
};

export default function GenericDroppableColumnSection({
  onColDropped,
  column,
  options,
  onColOptionChanged,
  onRemoveCol,
  disableEdits,
  selectedOption,
  allowedTypes,
}: Props) {
  const classes = useStyles();
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CONFIGURATION_COLUMN,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item: ConfigColumnItem) => {
      if (allowedTypes && allowedTypes.indexOf(item.data.type) < 0) {
        AppToaster.show({
          message: `Only columns of the follow type are allowed for that configuration: ${allowedTypes.join(
            ', ',
          )}`,
          timeout: 5000,
        });
        return;
      }
      onColDropped(item.data);
    },
  });
  return (
    <div ref={drop} className={cx(classes.root, { [classes.colIsOver]: isOver })}>
      {!column ? (
        'Drop field here...'
      ) : (
        <GenericDroppedColumn
          key={_.uniqueId('droppable-column-')}
          column={column}
          options={options}
          selectedOption={selectedOption}
          onColOptionChanged={(option) => onColOptionChanged(option, column.column.name)}
          onRemoveCol={() => onRemoveCol(column.column.name)}
          disableEdits={disableEdits}
        />
      )}
    </div>
  );
}
