/** @format */

import Cookies from 'js-cookie';

export function loginUser(
  email: string,
  password: string,
  successCallback: (data: any) => void,
  errorCallback: (data: any) => void,
) {
  fetch(process.env.REACT_APP_API_URL + 'rest-auth/login/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (!data.key) {
        errorCallback(data);
      } else {
        Cookies.set('spheres_auth_token', data.key);
        successCallback(data.user);
      }
    });
}

export function verifyEmail(
  key: string,
  successCallback: (data: any) => void,
  errorCallback?: (data: any) => void,
) {
  fetch(process.env.REACT_APP_API_URL + 'rest-auth/registration/verify-email/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      key,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data.detail !== 'ok') {
        errorCallback && errorCallback(data);
      } else {
        successCallback(data);
      }
    });
}

export function logoutUser(successCallback: () => void) {
  Cookies.remove('spheres_auth_token');
  successCallback();
}

export function fetchProfile(successCallback: (data: any) => void, failureCallback: () => void) {
  const token = Cookies.get('spheres_auth_token');

  if (token) {
    fetch(process.env.REACT_APP_API_URL + 'fetch-profile/', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Token ' + token,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {
          Cookies.remove('spheres_auth_token');
          failureCallback();
        } else {
          successCallback(data.user);
        }
      });
  } else {
    failureCallback();
  }
}
