/** @format */

import { Card } from '@blueprintjs/core';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
import FlexBox, {
  FlexDirection,
  HorizontalAlignment,
  VerticalAlignment,
} from 'components/core/FlexBox';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import React, { useState } from 'react';
import Button from 'shared/Button';

const useStyles = makeStyles((theme: Theme) => ({
  mainLabel: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.ds.black,
    marginBottom: theme.spacing(7),
  },
  card: {
    height: 238,
    width: 400,
    backgroundColor: theme.palette.ds.white,
  },
  root: { width: '100%', height: '100vh', backgroundColor: theme.palette.ds.grey100 },
  inputs: {
    height: 32,
    width: 320,
  },
  inputsContainer: {
    width: '100%',
    height: '100%',
  },
  passwordInput: {
    marginBottom: theme.spacing(7),
  },
}));

type PassedProps = {
  fetchShareData: (password: string, onFailure: () => void) => void;
};

type Props = PassedProps;

export default function SharePasswordRequiredPage(props: Props) {
  const [inputPassword, setInputPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordVerificationFailed, setPasswordVerificationFailed] = useState<boolean>(false);

  const classes = useStyles();
  const { fetchShareData } = props;

  return (
    <>
      <FlexBox
        className={classes.root}
        horizontalAlignment={HorizontalAlignment.CENTER}
        verticalAlignment={VerticalAlignment.CENTER}
        direction={FlexDirection.COLUMN}>
        <Card className={classes.card}>
          <FlexBox
            className={classes.inputsContainer}
            horizontalAlignment={HorizontalAlignment.CENTER}
            verticalAlignment={VerticalAlignment.CENTER}
            direction={FlexDirection.COLUMN}>
            <div className={classes.mainLabel}> This dashboard is password protected. </div>
            <InputWithBlurSave
              containerClassName={cx(classes.inputs, classes.passwordInput)}
              initialValue={inputPassword}
              onNewValueSubmitted={(newValue: string) => {
                setInputPassword(newValue);
              }}
              type="password"
              placeholder="Enter password here..."
              hideRightIconInteractions
              errorText={
                passwordVerificationFailed ? 'Incorrect password, please try again.' : undefined
              }
            />
            <Button
              type="primary"
              className={classes.inputs}
              onClick={() => {
                setLoading(true);
                fetchShareData(inputPassword, () => {
                  setPasswordVerificationFailed(true);
                  setLoading(false);
                });
              }}
              text="Enter"
              loading={loading}
            />
          </FlexBox>
        </Card>
      </FlexBox>
    </>
  );
}
