/** @format */

import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Intent, TextArea } from '@blueprintjs/core';
import InputLabel from 'shared/InputLabel';

const styles = () => ({
  textArea: {
    width: '100%',
    font: 'inherit',
  },
});

type PassedProps = {
  initialValue?: string;
  onNewValueSubmitted: (newValue: string) => void;
  errorText?: string;
  placeholder?: string;
  containerClassName?: string;
  label?: string;
};

type Props = WithStyles<typeof styles> & PassedProps;

type State = {
  value?: string;
  inputFocused: boolean;
};

export class TextAreaWithBlurSave extends Component<Props, State> {
  inputRef: HTMLTextAreaElement | null = null;

  state: State = {
    value: this.props.initialValue,
    inputFocused: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({ value: this.props.initialValue });
      this.inputRef?.blur();
    }
  }

  render = () => {
    const { value } = this.state;
    const {
      containerClassName,
      initialValue,
      errorText,
      placeholder,
      onNewValueSubmitted,
      classes,
      label,
    } = this.props;

    return (
      <div className={containerClassName}>
        {label && <InputLabel text={label} />}
        <TextArea
          className={classes.textArea}
          placeholder={placeholder}
          intent={errorText ? Intent.DANGER : Intent.NONE}
          value={value}
          onFocus={() => {
            this.setState({ inputFocused: true });
          }}
          onBlur={() => {
            this.setState({ value: initialValue, inputFocused: false });
            onNewValueSubmitted(value || '');
          }}
          onKeyPress={this.onKeyPress}
          onChange={(e) => {
            this.setState({ value: e.target.value });
          }}
          inputRef={(ref) => (this.inputRef = ref)}
        />
      </div>
    );
  };

  onKeyPress = (event?: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { onNewValueSubmitted } = this.props;
    const { value } = this.state;
    const code = event?.keyCode || event?.which;
    if (code === 13 && value) {
      onNewValueSubmitted(value || '');
    }
  };
}

export default withStyles(styles)(TextAreaWithBlurSave);
