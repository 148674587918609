/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.white,
    padding: `${theme.spacing(8)}px ${theme.spacing(6)}px`,
    borderRadius: 16,
    minWidth: 500,
  },
  title: {
    fontSize: 24,
    marginBottom: theme.spacing(3),
  },
  description: {
    width: 500,
    marginBottom: theme.spacing(6),
    color: theme.palette.ds.grey600,
  },
});

type PassedProps = {
  className?: string;
  title: string;
  description?: string;
};

type Props = PassedProps & WithStyles<typeof styles>;

class FullPagePanel extends React.Component<Props> {
  render() {
    const { classes, className, title, description } = this.props;

    return (
      <div className={cx(classes.root, className)}>
        <div className={classes.title}>{title}</div>
        <div className={classes.description}>{description}</div>
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(styles)(FullPagePanel);
