/** @format */
import React, { useState } from 'react';
import _ from 'underscore';
import { Icon, Menu, MenuItem } from '@blueprintjs/core';
import { Select, IItemListRendererProps, IItemRendererProps } from '@blueprintjs/select';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import cx from 'classnames';

import DropdownButton from 'explo-ds/buttons/dropdownButton';

import { SelectedDropdownInputItem } from 'constants/types';
import InputLabel from 'shared/InputLabel';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

const useStyles = makeStyles((theme: Theme) => ({
  fullWidthRoot: {
    width: '100%',
    position: 'relative',

    '& .bp3-popover-target': {
      width: '100%',
    },

    '& .bp3-transition-container': {
      minWidth: '100%',
      transform: 'translate3d(0px, 27px, 0px) !important',

      '& .bp3-popover': {
        width: '100%',
        boxShadow: 'none',
        border: `1px solid ${theme.palette.ds.grey300}`,
      },
    },
  },
  fullWidthBtn: {
    width: '100%',
  },
  fullWidthPopover: {
    width: '100%',
  },
  menuList: {
    maxHeight: 200,
    overflow: 'auto',

    '& li': {
      marginBottom: 0,
    },
  },
  placeholderText: {
    color: '#9DA9B3 !important',
  },
  buttonErrorState: {
    boxShadow: `inset 0 0 0 1px ${theme.palette.ds.red} !important`,
  },
  dropdownBtn: {},
  dropdownMenuItem: {
    '&.bp3-menu-item': {
      display: 'flex',
      alignItems: 'center',
      '&.bp3-active': {
        backgroundColor: theme.palette.ds.blue,
      },
    },
  },

  createItemBtn: {
    margin: 5,
    padding: `5px 7px`,
    borderRadius: 2,
    color: theme.palette.ds.blue,
    backgroundColor: theme.palette.ds.white,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#E4E9ED',
      cursor: 'pointer',

      '&.isCreating': {
        backgroundColor: 'initial',
        cursor: 'initial',
      },
    },

    '&:active': {
      backgroundColor: '#D5DADF',

      '&.isCreating': {
        backgroundColor: 'initial',
        cursor: 'initial',
      },
    },
  },
  createItemIcon: {
    color: theme.palette.ds.blue,
    marginRight: theme.spacing(1),
  },
  newItemInputContainer: {
    marginTop: -3,
    marginBottom: -4,

    '& .bp3-input': {
      textAlign: 'left',
    },
  },
}));

export type Props = {
  btnMinimal?: boolean;
  buttonErrorState?: boolean;
  buttonStyle?: React.CSSProperties;
  className?: string;
  containerClassName?: string;
  createItemPlaceholderText?: string;
  createItemText?: string;
  disabled?: boolean;
  fillWidth?: boolean;
  filterable?: boolean;
  hideBtnCaret?: boolean;
  hideSelectedText?: boolean;
  isSourceDataset?: boolean;
  label?: string;
  minimal?: boolean;
  noSelectionText: string;
  onCancelClick?: () => void;
  onChange: (item: SelectedDropdownInputItem) => void;
  onCreateItem?: (name: string) => void;
  options: SelectedDropdownInputItem[];
  popoverClassName?: string;
  portalContainer?: HTMLElement;
  selectedItem?: SelectedDropdownInputItem;
  showCancelBtn?: boolean;
  showIcon?: boolean;
  useFakeLabel?: boolean;
  usePortal?: boolean;
  disableOnNoItems?: boolean;
};

const DropdownSelect = ({ usePortal = false, ...props }: Props) => {
  const classes = useStyles();
  const [isCreatingItem, setIsCreatingItem] = useState<boolean>(false);
  const [newItemName, setNewItemName] = useState<string>('');

  const simpleFilterPredicate = (query: string, item: SelectedDropdownInputItem) => {
    return item.name.toString().toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  const isDisabled =
    props.disabled ||
    props.isSourceDataset ||
    (props.disableOnNoItems && props.options.length === 0);

  const itemRenderer = (
    item: SelectedDropdownInputItem,
    { handleClick, query }: IItemRendererProps,
  ) => {
    let itemText;
    if (query.length > 0) {
      // replace each instance of the query with a bolded version (wrapped in <b />)
      // so that the item shows the query highlighted in it when searching
      const textPartsWithoutQuery = item.name.split(query);
      itemText = [];
      for (let i = 0; i < textPartsWithoutQuery.length - 1; i++) {
        itemText.push(textPartsWithoutQuery[i]);
        itemText.push(<b key={_.uniqueId('query_text')}>{query}</b>);
      }
      itemText.push(textPartsWithoutQuery[textPartsWithoutQuery.length - 1]);
    } else {
      itemText = item.name;
    }

    return (
      <MenuItem
        className={cx(
          classes.dropdownMenuItem,
          GLOBAL_STYLE_CLASSNAMES.text.body.dropdownMenuItem,
          GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownInputMenuItem,
        )}
        active={item.id === props.selectedItem?.id}
        text={itemText}
        key={_.uniqueId(`${item.id}_`)}
        onClick={handleClick}
        icon={item.icon}
      />
    );
  };

  const itemListRenderer = ({
    filteredItems,
    renderItem,
    itemsParentRef,
  }: IItemListRendererProps<SelectedDropdownInputItem>) => {
    let itemsList;
    if (filteredItems.length === 0) {
      itemsList = <MenuItem disabled text="No results." />;
    } else {
      itemsList = filteredItems.map(renderItem);
    }

    return (
      <div>
        <Menu className={classes.menuList} ulRef={itemsParentRef}>
          {itemsList}
        </Menu>
        {props.onCreateItem && (
          <div
            className={cx(classes.createItemBtn, { isCreating: isCreatingItem })}
            onClick={() => setIsCreatingItem(true)}>
            <Icon icon="plus" className={classes.createItemIcon} />
            {isCreatingItem ? (
              <InputWithBlurSave
                hideRightIconInteractions
                isCompact
                fillWidth
                placeholder={props.createItemPlaceholderText || 'Enter item name'}
                containerClassName={classes.newItemInputContainer}
                initialValue={newItemName}
                onNewValueSubmitted={(text: string) => {
                  setNewItemName(text);
                  props.onCreateItem?.(text);
                }}
              />
            ) : (
              props.createItemText || 'Create Item'
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={props.containerClassName}>
      {(props.label || props.useFakeLabel) && (
        <InputLabel text={props.label || ''} fakeLabel={props.useFakeLabel} />
      )}
      <Select
        className={cx(
          {
            [classes.fullWidthRoot]: props.fillWidth,
          },
          GLOBAL_STYLE_CLASSNAMES.container.outline.dropdownFilterInputBorder,
          GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownFilterInputOutline,
          GLOBAL_STYLE_CLASSNAMES.container.outline.popoverBorder,
          GLOBAL_STYLE_CLASSNAMES.text.body.dropdownFilterInput,
          props.className,
        )}
        disabled={isDisabled}
        items={props.options}
        itemPredicate={simpleFilterPredicate}
        itemRenderer={itemRenderer}
        itemListRenderer={itemListRenderer}
        noResults={<MenuItem disabled={true} text="No results." />}
        onItemSelect={props.onChange}
        popoverProps={{
          minimal: props.minimal,
          popoverClassName: props.popoverClassName,
          usePortal: !props.fillWidth || usePortal,
          portalContainer: props.portalContainer,
        }}
        filterable={props.filterable === undefined ? true : props.filterable}>
        <DropdownButton
          className={cx(
            classes.dropdownBtn,
            { [classes.placeholderText]: !props.selectedItem },
            { [classes.buttonErrorState]: props.buttonErrorState },
            { [classes.fullWidthBtn]: props.fillWidth },
          )}
          style={props.buttonStyle}
          icon={props.showIcon ? props.selectedItem && props.selectedItem.icon : undefined}
          rightIcon={props.hideBtnCaret ? undefined : 'caret-down'}
          text={props.selectedItem ? props.selectedItem.name : props.noSelectionText}
          hideText={props.hideSelectedText}
          disabled={isDisabled}
          minimal={props.btnMinimal}
          showCancelBtn={props.selectedItem ? props.showCancelBtn : false}
          onCancelClick={props.onCancelClick}
        />
      </Select>
    </div>
  );
};

export default DropdownSelect;
