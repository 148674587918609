/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';
import { useDispatch } from 'react-redux';
import { Icon, Tooltip, Position, Classes } from '@blueprintjs/core';

import { V2_CHART_VISUALIZATION_OPERATIONS } from 'constants/types';
import { OP_TYPE_TO_BP3_ICON, VIZ_TO_SECTION, VIZ_TO_NAME } from 'constants/dataConstants';
import { updateSelectedChart } from 'actions/dataPanelConfigActions';
import { isVisualizationSupportedForSourceType } from 'utils/dataPanelConfigUtils';

const visualizationsBySection = _.groupBy(
  V2_CHART_VISUALIZATION_OPERATIONS,
  (op) => VIZ_TO_SECTION[op],
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 250,
    padding: theme.spacing(4),
    paddingBottom: 0,
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
  },
  sectionContainer: {
    paddingBottom: theme.spacing(4),
  },
  sectionName: {
    fontWeight: 600,
    fontSize: 12,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
  },
  optionsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    rowGap: '12px',
    columnGap: '12px',
  },
  chartOption: {
    backgroundColor: theme.palette.ds.grey200,
    border: `1px solid ${theme.palette.ds.grey200}`, // so that the hover doesn't take space
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',

    '&.selected': {
      boxShadow: '0 4px 6px rgb(0 0 0 / 12%)',
      border: '1px solid #a3e0ff',
    },

    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0 4px 6px rgb(0 0 0 / 12%)',
      border: '1px solid #a3e0ff',
    },

    '&.disabled': {
      cursor: 'default',
      boxShadow: 'none',
      border: `1px solid ${theme.palette.ds.grey200}`, // so that the hover doesn't take space
      filter: 'grayscale(100%)',
      opacity: '50%',
    },
  },
  chartTooltip: {
    width: '100%',
  },
}));

export type Props = {
  selectedChartType: string;
  sourceType?: string;
};

export default function ChartSelectPopover({ selectedChartType, sourceType }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      {Object.keys(visualizationsBySection).map((section) => (
        <div key={`select-chart-type-section-${section}`} className={classes.sectionContainer}>
          <div className={classes.sectionName}>{section}</div>
          <div className={classes.optionsContainer}>
            {visualizationsBySection[section].map((chartType) => {
              const [enabled, reason] = isVisualizationSupportedForSourceType(
                chartType,
                sourceType,
              );
              return (
                <Tooltip
                  openOnTargetFocus={false}
                  targetClassName={classes.chartTooltip}
                  key={`select-chart-type-option-${chartType}`}
                  content={enabled ? VIZ_TO_NAME[chartType] : reason}
                  position={Position.BOTTOM}>
                  <div
                    className={cx(classes.chartOption, Classes.POPOVER_DISMISS, {
                      selected: chartType === selectedChartType,
                      disabled: !enabled,
                    })}
                    onClick={() => enabled && dispatch(updateSelectedChart(chartType))}>
                    <Icon iconSize={24} icon={OP_TYPE_TO_BP3_ICON[chartType]} />
                  </div>
                </Tooltip>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
