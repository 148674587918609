/** @format */

import React from 'react';

import VegaChart from 'pages/dashboardPage/charts/vegaChart';

import { VisualizeHeatMapInstructions } from 'constants/types';
import { AGGREGATIONS_TYPES } from 'constants/dataConstants';
import { titleCase } from 'utils/graphUtils';

type Props = {
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: VisualizeHeatMapInstructions;
  dataPanelTemplateId: string;
};

const AGG_TYPE_TO_VEGA_AGG_TYPE = {
  [AGGREGATIONS_TYPES.COUNT.id]: 'count',
  [AGGREGATIONS_TYPES.COUNT_DISTINCT.id]: 'distinct',
  [AGGREGATIONS_TYPES.AVG.id]: 'average',
  [AGGREGATIONS_TYPES.SUM.id]: 'sum',
  [AGGREGATIONS_TYPES.MIN.id]: 'min',
  [AGGREGATIONS_TYPES.MAX.id]: 'max',
};

type State = {};

class HeatMap extends React.PureComponent<Props, State> {
  getHeatMapId = () => {
    return `heatMapContainer${this.props.dataPanelTemplateId}`;
  };

  instructionsReadyToDisplay = (instructions?: VisualizeHeatMapInstructions) => {
    return !!(
      instructions &&
      instructions.aggregation?.aggedOnColumn &&
      instructions.aggregation?.type &&
      instructions.xAxisColumn &&
      instructions.yAxisColumn
    );
  };

  render() {
    const { instructions, loading } = this.props;
    return (
      <VegaChart
        loading={loading}
        chartId={this.getHeatMapId()}
        chartConfig={this._spec()}
        configReadyToDisplay={this.instructionsReadyToDisplay(instructions)}
      />
    );
  }

  // eslint-disable-next-line
  _spec = (): any => {
    const { previewData, instructions } = this.props;
    const aggType = instructions?.aggregation?.type?.id;
    const aggColName = instructions?.aggregation?.aggedOnColumn?.name;
    const xAxisColName = instructions?.xAxisColumn?.name;
    const yAxisColName = instructions?.yAxisColumn?.name;

    if (!aggType || !aggColName || !xAxisColName || !yAxisColName) return;

    return {
      $schema: 'https://vega.github.io/schema/vega-lite/v4.json',
      description:
        'A population pyramid for the US in 2000, created using stack. See https://vega.github.io/vega-lite/examples/concat_population_pyramid.html for a variant of this created using concat.',
      data: {
        name: 'datum',
        values: previewData,
      },
      selection: {
        highlight: { type: 'single', empty: 'none', on: 'mouseover' },
      },
      transform: [
        {
          aggregate: [
            { op: AGG_TYPE_TO_VEGA_AGG_TYPE[aggType], field: aggColName, as: 'aggregation_field' },
          ],
          groupby: [xAxisColName, yAxisColName],
        },
      ],
      width: 'container',
      height: 'container',
      mark: 'bar',
      encoding: {
        y: {
          field: yAxisColName,
          title: titleCase(yAxisColName),
          axis: {
            labelFontSize: instructions?.labelFontSize,
            titleFontSize: instructions?.titleFontSize,
          },
        },
        x: {
          field: xAxisColName,
          title: titleCase(xAxisColName),
          axis: {
            labelAngle:
              instructions?.xAxisLabelAngle === 0 || instructions?.xAxisLabelAngle
                ? instructions?.xAxisLabelAngle
                : -90,
            labelFontSize: instructions?.labelFontSize,
            titleFontSize: instructions?.titleFontSize,
            titlePadding: 16,
          },
        },
        tooltip: [
          { field: yAxisColName, title: titleCase(yAxisColName) },
          { field: xAxisColName, title: titleCase(xAxisColName) },
          {
            title: 'Total',
            field: 'aggregation_field',
          },
        ],
      },
      layer: this.getLayers(),
      config: {
        axis: { grid: true, tickBand: 'extent' },
      },
    };
  };

  getLayers = () => {
    const { instructions } = this.props;
    // eslint-disable-next-line
    const layers: any = [
      {
        mark: 'rect',
        encoding: {
          color: {
            field: 'aggregation_field',
            type: 'quantitative',
            title: '',
            legend: { direction: 'vertical', gradientLength: 120 },
            scale: {
              range: [instructions?.startColor || '#F1F5FB', instructions?.endColor || '#0069ED'],
            },
          },
        },
      },
    ];

    if (instructions?.showText) {
      layers.push({
        mark: { type: 'text', fontSize: instructions.cellTextSize },
        encoding: {
          text: {
            field: 'aggregation_field',
            type: 'quantitative',
            format: ',',
          },
          color: {
            condition: { test: "datum['num_people'] < 10000000", value: 'black' },
            value: 'black',
          },
        },
      });
    }

    return layers;
  };
}

export default HeatMap;
