/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import FlexBox from './core/FlexBox';
import FlexItem from './core/FlexItem';
import ColorPicker from './ColorPicker';
import { mixColors } from 'utils/general';

const useStyles = makeStyles((theme: Theme) => ({
  coloredDiv: {
    borderTop: `1px solid ${theme.palette.ds.grey300}`,
    borderBottom: `1px solid ${theme.palette.ds.grey300}`,
  },
  colorPicker: {
    '& .bp3-popover-target': {
      display: 'block',
    },
  },
  colorPickerButton: {
    '&.bp3-button': {
      border: 'none',
    },
  },
  linearLeftColorPicker: {
    '&.bp3-button': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      border: `1px solid ${theme.palette.ds.grey300}`,
      borderRight: 'none',
    },
  },
  linearCenterColorPicker: {
    '&.bp3-button': {
      borderRadius: 0,
      borderTop: `1px solid ${theme.palette.ds.grey300}`,
      borderBottom: `1px solid ${theme.palette.ds.grey300}`,
    },
  },
  linearRightColorPicker: {
    '&.bp3-button': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      border: `1px solid ${theme.palette.ds.grey300}`,
      borderLeft: 'none',
    },
  },
}));

type Props = {
  /**
   * A list of either 2 or 3 hues
   */
  hues: string[];
  /**
   * A list of either 2 or 3 callbacks fired on hue change
   */
  onHuesChanged: ((newHue: string) => void)[];
  /**
   * A list of either 2 or 3 labels to be used for the color pickers
   */
  labels?: string[];
};

export default function ColorPaletteInput({ hues, onHuesChanged, labels }: Props) {
  const classes = useStyles();

  const color1 = hues[0];
  const color2 = hues[1];

  const label1 = labels && labels[0];
  const label2 = labels && labels[1];

  if (hues.length === 2) {
    return (
      <FlexBox>
        <FlexItem>
          <ColorPicker
            fill
            className={classes.colorPicker}
            label={label1}
            buttonClassName={cx(classes.colorPickerButton, classes.linearLeftColorPicker)}
            color={hues[0]}
            onClose={onHuesChanged[0]}
          />
        </FlexItem>
        <FlexItem
          className={classes.coloredDiv}
          style={{ backgroundColor: mixColors(color1, color2, 0.75).rgb().toString() }}
        />
        <FlexItem
          className={classes.coloredDiv}
          style={{ backgroundColor: mixColors(color1, color2, 0.5).rgb().toString() }}
        />
        <FlexItem
          className={classes.coloredDiv}
          style={{ backgroundColor: mixColors(color1, color2, 0.25).rgb().toString() }}
        />
        <FlexItem>
          <ColorPicker
            fill
            className={classes.colorPicker}
            label={label2}
            buttonClassName={cx(classes.colorPickerButton, classes.linearRightColorPicker)}
            color={hues[1]}
            onClose={onHuesChanged[1]}
          />
        </FlexItem>
      </FlexBox>
    );
  }

  const color3 = hues[2];
  const label3 = labels && labels[2];

  return (
    <FlexBox>
      <FlexItem>
        <ColorPicker
          fill
          className={classes.colorPicker}
          label={label1}
          buttonClassName={cx(classes.colorPickerButton, classes.linearLeftColorPicker)}
          color={hues[0]}
          onClose={onHuesChanged[0]}
        />
      </FlexItem>
      <FlexItem
        className={classes.coloredDiv}
        style={{ backgroundColor: mixColors(color1, color2, 0.66).rgb().toString() }}
      />
      <FlexItem
        className={classes.coloredDiv}
        style={{ backgroundColor: mixColors(color1, color2, 0.33).rgb().toString() }}
      />
      <FlexItem>
        <ColorPicker
          fill
          className={classes.colorPicker}
          label={label2}
          buttonClassName={cx(classes.colorPickerButton, classes.linearCenterColorPicker)}
          color={hues[1]}
          onClose={onHuesChanged[1]}
        />
      </FlexItem>
      <FlexItem
        className={classes.coloredDiv}
        style={{ backgroundColor: mixColors(color2, color3, 0.66).rgb().toString() }}
      />
      <FlexItem
        className={classes.coloredDiv}
        style={{ backgroundColor: mixColors(color2, color3, 0.33).rgb().toString() }}
      />
      <FlexItem>
        <ColorPicker
          fill
          className={classes.colorPicker}
          label={label3}
          buttonClassName={cx(classes.colorPickerButton, classes.linearRightColorPicker)}
          color={hues[2]}
          onClose={onHuesChanged[2]}
        />
      </FlexItem>
    </FlexBox>
  );
}
