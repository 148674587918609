/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { WithStyles } from '@material-ui/core/index';
import { connect } from 'react-redux';

import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import MapRegionColumnConfiguration from 'pages/dataPanelEditorPage/visualizeSections/mapRegionColumnConfiguration';
import MapDensityColumnConfiguration from 'pages/dataPanelEditorPage/visualizeSections/mapDensityColumnConfiguration';
import SelectMapRegionConfiguration from 'pages/dataPanelEditorPage/visualizeSections/selectMapRegionConfiguration';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { VisualizeChoroplethMapInstructions, OPERATION_TYPES } from 'constants/types';
import { DataPanelTemplate } from 'actions/types';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
  });

type PassedProps = {
  instructions: VisualizeChoroplethMapInstructions;
  dataPanelTemplate: DataPanelTemplate;
};

type Props = PassedProps &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  RouteComponentProps &
  ReturnType<typeof mapStateToProps>;

class VisualizeChoroplethMapSection extends React.Component<Props> {
  render() {
    const { classes, dataPanelTemplate, instructions, updateVisualizeOperation } = this.props;
    if (!dataPanelTemplate || !dataPanelTemplate._schema) return <div></div>;

    return (
      <div className={classes.root}>
        <ConfigSectionHeader title="Select region" />
        <SelectMapRegionConfiguration
          instructions={instructions}
          updateVisualizeOperation={(
            newVisualizeChoroplethMapInstructions: VisualizeChoroplethMapInstructions,
          ) => {
            updateVisualizeOperation(
              newVisualizeChoroplethMapInstructions,
              OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
            );
          }}
        />
        <ConfigSectionHeader title="Configure map" />
        <MapRegionColumnConfiguration
          schema={dataPanelTemplate._schema}
          instructions={instructions}
          updateVisualizeOperation={(
            newVisualizeChoroplethMapInstructions: VisualizeChoroplethMapInstructions,
          ) => {
            updateVisualizeOperation(
              newVisualizeChoroplethMapInstructions,
              OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
            );
          }}
        />
        <MapDensityColumnConfiguration
          schema={dataPanelTemplate._schema}
          instructions={instructions}
          updateVisualizeOperation={(
            newVisualizeChoroplethMapInstructions: VisualizeChoroplethMapInstructions,
          ) => {
            updateVisualizeOperation(
              newVisualizeChoroplethMapInstructions,
              OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateVisualizeOperation,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VisualizeChoroplethMapSection)),
);
