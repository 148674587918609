/** @format */

import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getOrCreateEndUserGroup } from 'actions/endUserGroupActions';
import LoadingBody from 'components/loadingBody';
import { ReduxState } from 'reducers/rootReducer';
import { isProductionEnv } from 'utils/environmentUtils';

const useStyles = makeStyles(() => ({
  content: {
    // Setting the flex to 1 and wdith to 100% is needed otherwise the embedded component won't work
    flex: 1,
    overflowY: 'scroll',
    height: '100%',
    width: '100%',
  },
}));

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const IN_APP_ANALYTICS_DASHBOARD_ID = process.env.REACT_APP_IN_APP_ANALYTICS_DASHBOARD_ID ?? '';
const EXPLO_TEAM_API_TOKEN = process.env.REACT_APP_EXPLO_TEAM_API_TOKEN;
const IS_PROD = isProductionEnv();

// For non prod envs. we link an overall explo analytics dashboard with an internal user group
const NON_PROD_USER_GROUP_TOKEN =
  'ca16f740d63bdb626daa5df8745239322c3ea05ae4e8808da3d7a969497d1bd3';
const NON_PROD_EMBED_ID = '3dK1o6nxl0';

const AnalyticsPage = (props: Props) => {
  const classes = useStyles();
  const [userGroupToken, setUserGroupToken] = useState();

  const { getOrCreateEndUserGroup } = props;
  const { teamName, teamId } = props;

  useEffect(() => {
    getOrCreateEndUserGroup(
      {
        postData: {
          name: teamName,
          user_group_id: teamId,
        },
        headerTeamApiToken: EXPLO_TEAM_API_TOKEN,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (data: any) => {
        setUserGroupToken(data.user_group.token);
      },
    );
  }, [getOrCreateEndUserGroup, teamName, teamId]);

  if (userGroupToken === undefined) {
    return <LoadingBody />;
  }

  return (
    <div className={classes.content}>
      <explo-dashboard
        dashboardEmbedId={IS_PROD ? IN_APP_ANALYTICS_DASHBOARD_ID : NON_PROD_EMBED_ID}
        userGroupToken={IS_PROD ? userGroupToken : NON_PROD_USER_GROUP_TOKEN}
        updateUrlParams={true}
        isProduction={true}
      />
      )
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  teamName: state.currentUser.team?.team_name,
  teamId: state.currentUser.team?.id,
});

const mapDispatchToProps = { getOrCreateEndUserGroup };

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage);
