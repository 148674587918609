/** @format */

import React from 'react';
import produce from 'immer';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { IconName } from '@blueprintjs/core';

import FlexBox from 'components/core/FlexBox';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import FlexItem from 'components/core/FlexItem';

import { TableColumn } from 'actions/types';
import {
  BooleanDisplayFormat,
  BooleanDisplayOptions,
  VisualizeTableInstructions,
} from 'constants/types';
import { BOOLEAN_DISPLAY_FORMATS, DEFAULT_BOOLEAN_DISPLAY_OPTIONS } from 'constants/dataConstants';
import { getOppositeBooleanIcon } from 'utils/dataPanelConfigUtils';
import { ORDERED_ALIGNMENTS } from 'constants/dataConstants';
import { TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  firstChild: {
    marginRight: theme.spacing(2),
  },
  input: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  column: TableColumn;
  instructions: VisualizeTableInstructions;
  updateInstructions: (newInstructions: VisualizeTableInstructions) => void;
};

export default function BooleanFormatConfiguration({
  column,
  instructions,
  updateInstructions,
}: Props) {
  const classes = useStyles();
  const displayOptions =
    (instructions.schemaDisplayOptions?.[column.name] as BooleanDisplayOptions) || {};
  const currentOptions: BooleanDisplayOptions = {
    falseIcon: displayOptions.falseIcon || DEFAULT_BOOLEAN_DISPLAY_OPTIONS.falseIcon,
    trueIcon: displayOptions.trueIcon || DEFAULT_BOOLEAN_DISPLAY_OPTIONS.trueIcon,
  };
  const currentAlignment = displayOptions.alignment || TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT;

  const onButtonClick = (
    displayProperty: keyof BooleanDisplayOptions,
    format: BooleanDisplayFormat,
  ) => {
    const oppositeDisplayProperty = getOppositeDisplayProperty(displayProperty);
    const currentIcon = currentOptions[displayProperty];

    if (format === currentIcon) {
      return;
    }

    const newInstructions = produce(instructions, (draft) => {
      const newSchemaDisplayOptions = {
        ...draft.schemaDisplayOptions,
        [column.name]: {
          ...currentOptions,
          [displayProperty]: format,
        },
      };

      if (format === currentOptions[oppositeDisplayProperty]) {
        (newSchemaDisplayOptions[column.name] as BooleanDisplayOptions)[
          oppositeDisplayProperty
        ] = getOppositeBooleanIcon(format);
      }

      draft.schemaDisplayOptions = newSchemaDisplayOptions;
    });

    updateInstructions(newInstructions);
  };

  return (
    <>
      <FlexBox className={classes.root}>
        <FlexItem className={classes.firstChild}>
          <ToggleButtonGroup fillWidth label="True">
            {BOOLEAN_DISPLAY_FORMATS.map((format) => (
              <ToggleButton
                key={`true_${format}`}
                active={currentOptions.trueIcon === format}
                icon={format}
                onClick={() => {
                  onButtonClick('trueIcon', format);
                }}
              />
            ))}
          </ToggleButtonGroup>
        </FlexItem>
        <FlexItem>
          <ToggleButtonGroup fillWidth label="False">
            {BOOLEAN_DISPLAY_FORMATS.map((format) => (
              <ToggleButton
                key={`false_${format}`}
                active={currentOptions.falseIcon === format}
                icon={format}
                onClick={() => {
                  onButtonClick('falseIcon', format);
                }}
              />
            ))}
          </ToggleButtonGroup>
        </FlexItem>
      </FlexBox>
      <ToggleButtonGroup fillWidth className={classes.input} label="Alignment">
        {ORDERED_ALIGNMENTS.map((alignment) => (
          <ToggleButton
            key={`alignment-${alignment.id}-${column.name}`}
            active={currentAlignment === alignment.id}
            icon={alignment.icon as IconName}
            onClick={() => {
              const newInstructions = produce(instructions, (draft) => {
                const newSchemaDisplayOptions = {
                  ...draft.schemaDisplayOptions,
                  [column.name]: {
                    ...currentOptions,
                    alignment: alignment.id,
                  },
                };

                draft.schemaDisplayOptions = newSchemaDisplayOptions;
              });

              updateInstructions(newInstructions);
            }}
          />
        ))}
      </ToggleButtonGroup>
    </>
  );
}

function getOppositeDisplayProperty(option: keyof BooleanDisplayOptions) {
  return option === 'falseIcon' ? 'trueIcon' : 'falseIcon';
}
