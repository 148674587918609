/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/index';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

type Props = {
  className?: string;
  children: JSX.Element[];
};

export default function SplitInputs({ className, children }: Props) {
  const classes = useStyles();

  return <div className={cx(classes.root, className)}>{children}</div>;
}
