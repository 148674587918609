/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';
import { useDrop } from 'react-dnd';

import DroppedColumn from './DroppedGroupingColumn';

import { ItemTypes, ConfigColumnItem } from 'constants/dragAndDrop';
import { TableColumn } from 'actions/types';
import { AggregationType, ConfigurableColumnInfo, DataType } from 'constants/types';
import { AppToaster } from 'toaster';
import { DashboardElement } from 'types/dashboardTypes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.ds.grey600,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    border: `1px solid ${theme.palette.ds.white}`, // to account for hover border
  },
  colIsOver: {
    border: `1px dashed ${theme.palette.ds.blue}`,
    boxShadow: `0px 0px 2px ${theme.palette.ds.blue}`,
  },
}));

export type Props = {
  onColDropped: (col: TableColumn) => void;
  columns: ConfigurableColumnInfo[];
  onColOptionChanged: (
    option: { id: string; name: string },
    name?: string,
    aggType?: AggregationType,
  ) => void;
  onRemoveCol: (name?: string, aggType?: AggregationType) => void;
  disableEdits?: boolean;
  allowedTypes?: DataType[];
  dashboardElements?: DashboardElement[];
};

export default function DroppableColumnSection({
  onColDropped,
  columns,
  onColOptionChanged,
  onRemoveCol,
  disableEdits,
  allowedTypes,
  dashboardElements,
}: Props) {
  const classes = useStyles();
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CONFIGURATION_COLUMN,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item: ConfigColumnItem) => {
      if (allowedTypes && allowedTypes.indexOf(item.data.type) < 0) {
        AppToaster.show({
          message: `Only columns of the follow type are allowed for that configuration: ${allowedTypes.join(
            ', ',
          )}`,
          timeout: 5000,
        });
        return;
      }
      onColDropped(item.data);
    },
  });
  return (
    <div ref={drop} className={cx(classes.root, { [classes.colIsOver]: isOver })}>
      {columns.length === 0 && 'Drop field here...'}
      {columns.map((colConfig) => (
        <DroppedColumn
          key={_.uniqueId('droppable-column-')}
          column={colConfig}
          onColOptionChanged={(option) =>
            onColOptionChanged(option, colConfig.column.name, colConfig.agg)
          }
          onRemoveCol={() => onRemoveCol(colConfig.column.name, colConfig.agg)}
          disableEdits={disableEdits}
          dashboardElements={dashboardElements}
        />
      ))}
    </div>
  );
}
