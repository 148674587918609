/** @format */

import React, { useRef } from 'react';
import produce from 'immer';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Icon } from '@blueprintjs/core';
import { useDrag, useDrop } from 'react-dnd';

import FlexBox, { VerticalAlignment } from 'components/core/FlexBox';
import Checkbox from 'components/checkbox';
import { DraggableDataTableRow, ItemTypes } from 'constants/dragAndDrop';
import { UserTransformedSchema } from 'constants/types';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 350,
    overflowY: 'auto',
  },
  row: {
    padding: theme.spacing(1),
  },
  checkbox: {
    margin: `0px ${theme.spacing(1)}px`,
  },
  input: {
    '& .bp3-input': {
      height: 24,
    },
  },
}));

type Props = {
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  schema: UserTransformedSchema;
  updateSchema: (newSchema: UserTransformedSchema) => void;
};

export default function TransformationSettingsMenu({ moveRow, schema, updateSchema }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {schema.map((col, idx) => (
        <TransformationRow
          key={`${idx}-${col}`}
          classes={classes}
          index={idx}
          moveRow={moveRow}
          schema={schema}
          updateSchema={updateSchema}
        />
      ))}
    </div>
  );
}

type TransformationRowProps = {
  classes: ReturnType<typeof useStyles>;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  schema: UserTransformedSchema;
  updateSchema: (newSchema: UserTransformedSchema) => void;
};

function TransformationRow({
  classes,
  index,
  moveRow,
  schema,
  updateSchema,
}: TransformationRowProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.DATA_TABLE_ROW, data: { index } },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.DATA_TABLE_ROW,
    drop: (item: DraggableDataTableRow) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.data.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      moveRow(dragIndex, hoverIndex);
      item.data.index = hoverIndex;
    },
  });

  const column = schema[index];
  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity, cursor: 'move' }}>
      <FlexBox className={classes.row} verticalAlignment={VerticalAlignment.CENTER}>
        <Icon
          icon="drag-handle-vertical"
          className={GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color}
        />
        <Checkbox
          className={classes.checkbox}
          isChecked={column.isVisible}
          onChange={() => {
            const newSchema = produce(schema, (draft) => {
              draft[index].isVisible = !draft[index].isVisible;
            });

            updateSchema(newSchema);
          }}
        />
        <InputWithBlurSave
          hideRightIconInteractions
          className={classes.input}
          initialValue={column.friendly_name}
          onNewValueSubmitted={(newColumnName) => {
            const newSchema = produce(schema, (draft) => {
              draft[index].friendly_name = newColumnName;
            });

            updateSchema(newSchema);
          }}
        />
      </FlexBox>
    </div>
  );
}
