/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { cloneDeep } from 'lodash';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';

import { DashboardElementConfig, ImageElemConfig } from 'types/dashboardTypes';

const styles = (theme: Theme) => ({
  configInput: {
    marginTop: theme.spacing(3),
  },
});

type PassedProps = {
  config: ImageElemConfig;
  updateConfig: (newConfig: DashboardElementConfig) => void;
};

type Props = RouteComponentProps & WithStyles<typeof styles> & PassedProps;

type State = {};

class ImageElementConfigPanel extends React.Component<Props, State> {
  state: State = {};

  render() {
    const { classes, config, updateConfig } = this.props;

    return (
      <div>
        <InputWithBlurSave
          containerClassName={classes.configInput}
          initialValue={config.imageUrl}
          onNewValueSubmitted={(newValue: string) => {
            const newConfig = cloneDeep(config);
            newConfig.imageUrl = newValue;
            updateConfig(newConfig);
          }}
          label="Image URL"
        />
        <SwitchInput
          className={classes.configInput}
          switchOn={config.fitEntireImage}
          onChange={() => {
            const newConfig = cloneDeep(config);
            newConfig.fitEntireImage = !config.fitEntireImage;
            updateConfig(newConfig);
          }}
          label="Fit entire image in container"
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ImageElementConfigPanel));
