/** @format */
import React, { useState } from 'react';
import { ReduxState } from 'reducers/rootReducer';
import { connect, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { FormGroup, Checkbox, Intent } from '@blueprintjs/core';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import _ from 'underscore';
import { saveAnalyticsConfig } from 'actions/analyticsActions';
import { isValidHttpUrl } from 'utils/analyticsUtils';
import { AppToaster } from 'toaster';
import { createDebouncedFn } from 'utils/general';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `0px ${theme.spacing(8)}px 125px ${theme.spacing(8)}px`,
  },
  subsection: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.ds.grey700,
    marginBottom: theme.spacing(3),
  },
  checkbox: {
    '& input:checked ~ .bp3-control-indicator': {
      backgroundColor: `${theme.palette.ds.blue} !important`,
    },
  },
}));

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const actionTypes: string[] = Object.values(REPORTED_ANALYTIC_ACTION_TYPES);

const debounceFn = createDebouncedFn(2000);

function SettingsAnalyticsSection(props: Props) {
  const { team } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [analyticsReportingUrl, setAnalyticsReportingUrl] = useState(team?.analytics_reporting_url);
  const [analyticsReportingActions, setAnalyticsReportingActions] = useState(
    new Set(_.pluck(team?.analytics_reporting_actions || [], 'name')),
  );
  const [analyticsReportingToken, setAnalyticsReportingToken] = useState(
    team?.analytics_reporting_token,
  );
  React.useEffect(() => {
    setAnalyticsReportingUrl(team?.analytics_reporting_url);
    setAnalyticsReportingActions(new Set(_.pluck(team?.analytics_reporting_actions || [], 'name')));
    setAnalyticsReportingToken(team?.analytics_reporting_token);
  }, [team]);

  const onConfigUpdated = (
    actions: Set<string>,
    url: string | undefined,
    token: string | undefined,
  ) => {
    debounceFn(() => {
      if (url && !isValidHttpUrl(url)) {
        AppToaster.show({
          message: 'The input URL must be a valid URL.',
          icon: 'tick-circle',
          timeout: 5000,
          intent: Intent.DANGER,
        });
        return;
      }
      dispatch(
        saveAnalyticsConfig(
          {
            postData: {
              analytics_reporting_actions: Array.from(actions),
              analytics_reporting_url: url,
              analytics_reporting_token: token,
            },
          },
          () => {
            AppToaster.show({
              message: 'Analytics settings saved successfully',
              icon: 'endorsed',
              timeout: 5000,
              intent: Intent.SUCCESS,
            });
          },
        ),
      );
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.subsection}>Analytics</div>
      <div className={classes.subtitle}>
        {"Send Analytics about your customer's dashboard usage to your database."}
      </div>
      <FormGroup label="URL">
        <InputGroup
          type="text"
          value={analyticsReportingUrl || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setAnalyticsReportingUrl(e.target.value);
            onConfigUpdated(analyticsReportingActions, e.target.value, analyticsReportingToken);
          }}
        />
      </FormGroup>
      <FormGroup label="Authorization Token">
        <InputGroup
          type="text"
          value={analyticsReportingToken || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setAnalyticsReportingToken(e.target.value);
            onConfigUpdated(analyticsReportingActions, analyticsReportingUrl, e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        {actionTypes.map((action) => (
          <Checkbox
            className={classes.checkbox}
            key={action}
            label={action}
            checked={analyticsReportingActions.has(action)}
            onChange={() => {
              const newState = new Set(analyticsReportingActions);
              if (analyticsReportingActions.has(action)) {
                newState.delete(action);
              } else {
                newState.add(action);
              }
              setAnalyticsReportingActions(newState);
              onConfigUpdated(newState, analyticsReportingUrl, analyticsReportingToken);
            }}
          />
        ))}
      </FormGroup>
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  team: state.teamData.data,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsAnalyticsSection);
