/** @format */

export enum ROUTES {
  HOME = '/',
  EXPLO_HOME = '/business-intelligence',
  ABOUT = '/about-us',
  CAREERS = '/careers',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password/:resetToken',
  SIGNUP = '/signup',
  CHANGELOG = '/changelog',
  USE_CASES = '/use-cases/:useCaseId',
  INTEGRATIONS = '/integrations/:integrationId',
  CHANGELOG_ENTRY = '/changelog/:entryUrlId',
  CHECK_YOUR_EMAIL = '/check-your-email',
  VERIFY_EMAIL = '/verify-email/:verification_id',
  ONBOARDING = '/onboarding',
  SETUP = '/setup',
  HOME_APP_PAGE = '/home',
  JOIN_TEAM = '/join-team',
  TEAM = '/team',
  TRIAL_EXPIRED = '/trial-expired',
  CONNECT_DATA_SOURCE = '/connect',
  SWAP_DATA_SOURCE = '/swap-datasource',
  SYNC_DATA_TABLES = '/sync-tables/:schemaId',
  SYNC_DATA_TABLES_NO_SCHEMA = '/sync-tables',
  DASHBOARD_PAGE = '/dashboard/:dashboardTemplateId',
  DATA_SOURCES_PAGE = '/datasources',
  MANAGE_DATA_TABLES = '/datasources/:dataSourceId',
  ADD_DATA_SOURCE = '/add-datasource',
  SETTINGS_PAGE = '/settings',
  GLOBAL_CUSTOM_STYLES_PAGE = '/styles',
  CUSTOMERS_PAGE = '/customers',
  SHARED_DASHBOARD = '/share/:shareId',
  SHARED_DASHBOARD_STRICT = '/share/:shareId/strict',
  SHARE_CHART = '/share/:shareId/chart/:dataPanelId',
  PDF_DASHBOARD = '/pdf/:shareId',
  IFRAME = '/iframe/:dashboardId/:userGroupToken/:environment?',
  NOTION_ANALYTICS = '/notion-analytics/:pageName',
  EMAIL_FORM_SUBMISSION_REDIRECT = '/email-form-submission/:source/:email',
  ANALYTICS = '/analytics',
}
