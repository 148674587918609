/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { cloneDeep } from 'lodash';
import { Intent } from '@blueprintjs/core';
import { AppToaster } from 'toaster';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import DropdownSelect from 'shared/DropdownSelect';
import CollapsibleGroup from 'shared/CollapsibleGroup';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';
import InfoCard from 'shared/InfoCard';

import {
  DashboardElementConfig,
  TimePeriodDropdownDashboardElemConfig,
} from 'types/dashboardTypes';
import SettingHeader from '../DataPanelConfigV2/DataConfigTab/SettingHeader';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `0 -${theme.spacing(6)}px`, // cancel the padding from the config since we apply our own
  },
  section: {
    margin: `${theme.spacing(3)}px 0`,
  },
  configInput: {
    marginBottom: theme.spacing(2),
    padding: `0 ${theme.spacing(3)}px`,
  },
  dateOption: {
    marginBottom: theme.spacing(3),
    margin: `0 ${theme.spacing(3)}px`,
  },
  dateOptionInput: {
    marginTop: theme.spacing(3),
    padding: `0 ${theme.spacing(3)}px`,
  },
  nameInput: {
    marginBottom: theme.spacing(3),
  },
  infoCard: {
    marginBottom: theme.spacing(3),
    margin: `0 ${theme.spacing(3)}px`,
  },
}));

type Props = {
  config: TimePeriodDropdownDashboardElemConfig;
  updateConfig: (newConfig: DashboardElementConfig) => void;
};

export default function TimePeriodDropdownElementConfigPanel({ config, updateConfig }: Props) {
  const classes = useStyles();

  const availableOptionsByValue = _.indexBy(config.values, 'value');

  const selectedDefault = config.defaultValue
    ? availableOptionsByValue[config.defaultValue]
    : undefined;

  return (
    <div className={classes.root}>
      <SettingHeader name="General" />
      <div className={classes.section}>
        <InputWithBlurSave
          containerClassName={classes.configInput}
          initialValue={config.label}
          onNewValueSubmitted={(newValue: string) => {
            const newConfig = cloneDeep(config);
            newConfig.label = newValue;
            updateConfig(newConfig);
          }}
          label="Label"
        />
        <SwitchInput
          className={classes.configInput}
          switchOn={config.enableSearch}
          onChange={() => {
            config.enableSearch = !config.enableSearch;
            updateConfig(config);
          }}
          label="Enable filtering"
        />
        <SwitchInput
          className={classes.configInput}
          switchOn={config.enableCancel}
          onChange={() => {
            config.enableCancel = !config.enableCancel;
            updateConfig(config);
          }}
          label="Enable clearing selection"
        />

        <DropdownSelect
          fillWidth
          minimal
          showIcon
          containerClassName={classes.configInput}
          filterable={false}
          label="Default value"
          noSelectionText="Select default option"
          onChange={(newvalue) => {
            const newConfig = cloneDeep(config);
            newConfig.defaultValue = newvalue.value as number;
            updateConfig(newConfig);
          }}
          options={config.values}
          selectedItem={selectedDefault}
        />
      </div>
      <SettingHeader
        name="Date options"
        actionBtnConfig={{
          actionBtnIcon: 'plus',
          onActionBtnClicked: () => {
            const newConfig = cloneDeep(config);
            newConfig.values.push({ id: '240', value: 240, name: 'Last 4 hours' });
            updateConfig(newConfig);
          },
        }}
      />
      <div className={classes.section}>
        <InfoCard
          className={classes.infoCard}
          text="Enter the number of minutes into the past each option should be filtering. (e.g. 15 filters 15 minutes into the past, 240 filters 4 hours into the past, etc)"
        />
        {config.values.map((dateOption, index) => {
          return (
            <CollapsibleGroup
              key={`date-dropdown-config-${index}`}
              className={classes.dateOption}
              title={dateOption.name}
              onDelete={() => {
                const newConfig = cloneDeep(config);
                newConfig.values.splice(index, 1);
                updateConfig(newConfig);
              }}>
              <>
                <InputWithBlurSave
                  containerClassName={classes.dateOptionInput}
                  initialValue={String(dateOption.value)}
                  onNewValueSubmitted={(newValue: string) => {
                    const newConfig = cloneDeep(config);
                    const intValue = parseInt(newValue);
                    if (intValue === 0 || intValue) {
                      if (intValue === newConfig.values[index].value) return;
                      if (_.pluck(newConfig.values, 'value').indexOf(intValue) >= 0) {
                        AppToaster.show({
                          message: 'Duplicate values are not allowed',
                          icon: 'warning-sign',
                          timeout: 5000,
                          intent: Intent.WARNING,
                        });
                        return;
                      }
                      newConfig.values[index].value = intValue;
                      newConfig.values[index].id = String(intValue);
                      updateConfig(newConfig);
                    }
                  }}
                  label="Minutes"
                />

                <InputWithBlurSave
                  containerClassName={cx(classes.dateOptionInput, classes.nameInput)}
                  initialValue={dateOption.name}
                  onNewValueSubmitted={(newValue: string) => {
                    const newConfig = cloneDeep(config);
                    newConfig.values[index].name = newValue;
                    updateConfig(newConfig);
                  }}
                  label="Name"
                />
              </>
            </CollapsibleGroup>
          );
        })}
      </div>
    </div>
  );
}
