/** @format */

import React from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Spinner, Intent, Icon } from '@blueprintjs/core';
import { AppToaster } from 'toaster';
import { inviteTeammate } from 'actions/authAction';

import { User } from 'actions/types';

const styles = (theme: Theme) =>
  createStyles({
    loadingBody: {
      height: 200,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    member: {
      display: 'flex',
      marginTop: theme.spacing(3),
    },
    memberName: {
      marginLeft: theme.spacing(4),
      fontWeight: 500,
    },
    memberEmail: {
      color: theme.palette.ds.grey700,
      fontSize: 12,
      marginTop: theme.spacing(-2),
      marginLeft: theme.spacing(4),
    },
    profileIconSmall: {
      borderRadius: '50%',
      backgroundColor: theme.palette.ds.grey700,
      color: theme.palette.ds.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      height: 40,
      fontSize: 18,
    },
    searhBarWrapper: {
      position: 'relative',
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(8),
    },
    searchBarInput: {
      border: '1px solid #C1CEE0',
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
      paddingLeft: theme.spacing(8),
      borderRadius: '20px',
      height: '40px',
      color: theme.palette.ds.black,
      boxShadow: 'none',
      outline: 'none',
      fontSize: 14,
      width: '100%',
    },
    searchBarIcon: {
      width: 16,
      height: 16,
      position: 'absolute',
      left: 12,
      top: 'calc(50% - 8px)',
    },
    invitedMember: {
      padding: `0px ${theme.spacing(8)}`,
      display: 'flex',
      marginTop: theme.spacing(3),
      alignItems: 'center',
    },
    inviteSentIcon: {
      borderRadius: '50%',
      backgroundColor: theme.palette.ds.grey200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      height: 40,
      fontSize: 20,
    },
    invitedEmail: {
      fontWeight: 500,
      marginLeft: theme.spacing(8),
    },
    resendInvite: {
      color: theme.palette.ds.blue,
      fontWeight: 500,
      '&:hover': {
        color: theme.palette.ds.grey800,
        cursor: 'pointer',
      },
    },
    invitedText: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    memberList: {
      maxHeight: '350px',
      overflowY: 'auto',
    },
  });

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles>;

type State = {
  memberSearchQuery: string;
};

const compareUsers = (user1: User, user2: User) => {
  if (!user1.first_name || !user1.last_name || !user2.first_name || !user2.last_name) return 0;
  if (user1.first_name.toLowerCase() < user2.first_name.toLowerCase()) return -1;
  else if (
    user1.first_name.toLowerCase() === user2.first_name.toLowerCase() &&
    user1.last_name.toLowerCase() < user2.last_name.toLowerCase()
  )
    return -1;
  else return 1;
};

class SettingsTeamMemberList extends React.Component<Props, State> {
  state: State = {
    memberSearchQuery: '',
  };

  render = () => {
    const { classes, teamData } = this.props;
    if (teamData.loading) {
      return (
        <div className={classes.loadingBody}>
          <Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_STANDARD} />
        </div>
      );
    } else {
      return (
        <div>
          {this.renderSearchBar()}
          <div className={classes.memberList}>
            {this.renderInvitedEmails()} {this.renderTeamMemberList()}
          </div>
        </div>
      );
    }
  };

  renderTeamMemberList = () => {
    const { teamData } = this.props;
    const { memberSearchQuery } = this.state;
    if (!teamData.data) return;

    const searchedTeamMembers = _.filter(
      teamData.data.team_members,
      (user) =>
        !memberSearchQuery ||
        !user.email ||
        user.first_name?.toLowerCase().includes(memberSearchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(memberSearchQuery.toLowerCase()),
    );

    return searchedTeamMembers.sort(compareUsers).map(this.renderTeamMember);
  };

  renderInvitedEmails = () => {
    const { invitedTeammates } = this.props;
    const { memberSearchQuery } = this.state;
    const searchedInvitedTeammates = _.filter(
      invitedTeammates,
      (email) =>
        !memberSearchQuery || email.toLowerCase().includes(memberSearchQuery.toLowerCase()),
    );
    return searchedInvitedTeammates.sort().map(this.renderInvitedEmail);
  };

  renderInvitedEmail = (email: string) => {
    const { classes } = this.props;
    return (
      <div className={classes.invitedMember}>
        <div className={classes.inviteSentIcon}></div>
        <div className={classes.invitedText}>
          <div key={email} className={classes.invitedEmail}>
            {email}
          </div>
          <div className={classes.resendInvite} onClick={() => this.resendInvite(email)}>
            Resend Invite
          </div>
        </div>
      </div>
    );
  };

  resendInvite = (email: string) => {
    const { currentUser, inviteTeammate } = this.props;
    inviteTeammate(
      {
        postData: {
          email: email,
          team_id: currentUser.team?.id,
          inviter: currentUser.first_name,
          resend: true,
        },
      },
      () => {
        AppToaster.show({
          message: `Invite sent successfully to ${email}`,
          icon: 'endorsed',
          timeout: 3000,
          intent: Intent.SUCCESS,
        });
      },
      (response: any) => {
        AppToaster.show({
          message: response.error_msg
            ? response.error_msg
            : 'Something went wrong. Please try again or contact support@explo.co if the error continues.',
          icon: 'error',
          timeout: 3000,
          intent: Intent.DANGER,
        });
      },
    );
  };

  renderTeamMember = (user: User) => {
    const { classes, currentUser } = this.props;

    return (
      <div key={`team-user-member-${user.id}`} className={classes.member}>
        <div className={classes.profileIconSmall}>
          {user.first_name ? user.first_name.charAt(0).toUpperCase() : 'A'}
          {user.last_name ? user.last_name.charAt(0).toUpperCase() : ''}
        </div>
        <div className={classes.memberName}>
          <p className={classes.memberName}>
            {user.first_name} {user.last_name}
            {currentUser.id === user.id ? ' (You)' : ''}
          </p>
          <p className={classes.memberEmail}>{user.email}</p>
        </div>
      </div>
    );
  };

  renderSearchBar = () => {
    const { classes } = this.props;
    const { memberSearchQuery } = this.state;
    return (
      <div className={classes.searhBarWrapper}>
        <Icon className={classes.searchBarIcon} icon={'search'} />
        <input
          className={classes.searchBarInput}
          placeholder="Search"
          value={memberSearchQuery}
          onChange={(e) => this.setState({ memberSearchQuery: e.currentTarget.value })}
        />
      </div>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  teamData: state.teamData,
  invitedTeammates: state.teamData.invitedEmails,
  currentUser: state.currentUser,
});

const mapDispatchToProps = { inviteTeammate };

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SettingsTeamMemberList),
);
