/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';

import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import DropdownSelect from 'shared/DropdownSelect';

import { SelectedDropdownInputItem, VisualizeLineOrBarChartInstructions } from 'constants/types';
import { TableColumn } from 'actions/types';
import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(6),
    },
  });

type PassedProps = {
  headerText: string;
  schema: TableColumn[];
  visualizeLineOrBarChartInstructions: VisualizeLineOrBarChartInstructions;
  updateVisualizeOperation: (
    newVisualizeLineChartInstructions: VisualizeLineOrBarChartInstructions,
  ) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class XAxisConfiguration extends React.Component<Props> {
  render() {
    const { classes, schema, visualizeLineOrBarChartInstructions, headerText } = this.props;
    const { xAxisColumn } = visualizeLineOrBarChartInstructions;

    return (
      <div className={classes.root}>
        <ConfigSectionHeader isSubTitle={true} title={headerText} />
        <DropdownSelect
          selectedItem={
            xAxisColumn
              ? xAxisColumn.name && xAxisColumn.type
                ? {
                    name: xAxisColumn.name,
                    id: xAxisColumn.name,
                    icon: SCHEMA_DATA_TYPES_BY_ID[xAxisColumn.type].icon,
                  }
                : undefined
              : undefined
          }
          onChange={this.onXAxisChange}
          noSelectionText="Axis column"
          options={schema.map((col) => ({
            name: col.name,
            id: col.name,
            icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
          }))}
          minimal
          fillWidth
          showIcon
        />{' '}
      </div>
    );
  }

  onXAxisChange = (item: SelectedDropdownInputItem) => {
    const { visualizeLineOrBarChartInstructions, updateVisualizeOperation, schema } = this.props;

    const selectedColumn = _.findWhere(schema, {
      name: item.name,
    });

    visualizeLineOrBarChartInstructions.xAxisColumn = selectedColumn;
    updateVisualizeOperation(visualizeLineOrBarChartInstructions);
  };
}

export default withStyles(styles)(XAxisConfiguration);
