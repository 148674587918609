/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Theme, WithStyles } from '@material-ui/core/index';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import IconButton from 'explo-ds/buttons/iconButton';
import VisualizeLineOrBarChartSection from 'pages/dataPanelEditorPage/visualizeSections/visualizeLineOrBarChartSection';
import VisualizeNumberSection from 'pages/dataPanelEditorPage/visualizeSections/visualizeNumberSection';
import VisualizeChoroplethMapSection from 'pages/dataPanelEditorPage/visualizeSections/visualizeChoroplethMapSection';
import VisualizeFunnelSection from 'pages/dataPanelEditorPage/visualizeSections/visualizeFunnelSection';
import VisualizePieChartSection from 'pages/dataPanelEditorPage/visualizeSections/pieChart/visualizePieChartSection';
import VisualizeHeatMapSection from 'pages/dataPanelEditorPage/visualizeSections/heatMap';
import VisualizePivotChart from 'pages/dataPanelEditorPage/visualizeSections/pivotChart';

import { OP_TYPE_TO_BP3_ICON, PIE_CHART_TYPES } from 'constants/dataConstants';
import { OPERATION_TYPES } from 'constants/types';
import { updateSelectedChart } from 'actions/dataPanelConfigActions';
import { DataPanelTemplate, SortOperation, VisualizeOperation } from 'actions/types';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    chartSelectionContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      rowGap: `${theme.spacing(3)}px`,
      columnGap: theme.spacing(3),
      marginBottom: theme.spacing(6),
    },
  });

type PassedProps = {
  visualizeOperation?: VisualizeOperation;
  sortOperation?: SortOperation;
  dataPanelTemplate: DataPanelTemplate;
};

type Props = PassedProps &
  WithStyles<typeof styles> &
  RouteComponentProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

class VisualizeConfigMenu extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.renderSelectTypeOfChart()}
        {this.renderVisualizeSection()}
      </div>
    );
  }

  renderSelectTypeOfChart = () => {
    const { classes } = this.props;

    return (
      <>
        <ConfigSectionHeader title="Select a type of chart" />
        <div className={classes.chartSelectionContainer}>
          {this.renderChartSelectionButton(OPERATION_TYPES.VISUALIZE_BAR_CHART)}
          {this.renderChartSelectionButton(OPERATION_TYPES.VISUALIZE_HORIZ_BAR_CHART)}
          {this.renderChartSelectionButton(OPERATION_TYPES.VISUALIZE_LINE_CHART)}
          {this.renderChartSelectionButton(OPERATION_TYPES.VISUALIZE_NUMBER)}
          {this.renderChartSelectionButton(OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP)}
          {this.renderChartSelectionButton(OPERATION_TYPES.VISUALIZE_FUNNEL)}
          {this.renderChartSelectionButton(OPERATION_TYPES.VISUALIZE_PIE_CHART)}
          {this.renderChartSelectionButton(OPERATION_TYPES.VISUALIZE_HEAT_MAP)}
          {this.renderChartSelectionButton(OPERATION_TYPES.VISUALIZE_PIVOT_CHART)}
          {this.renderChartSelectionButton(OPERATION_TYPES.VISUALIZE_COMBO_CHART)}
        </div>
      </>
    );
  };

  renderChartSelectionButton = (operationType: OPERATION_TYPES) => {
    const { visualizeOperation, updateSelectedChart } = this.props;
    return (
      <IconButton
        onClick={() => updateSelectedChart(operationType)}
        selected={visualizeOperation?.operation_type === operationType}
        btnIcon={OP_TYPE_TO_BP3_ICON[operationType]}
      />
    );
  };

  renderVisualizeSection = () => {
    const { visualizeOperation, sortOperation, dataPanelTemplate } = this.props;
    if (!visualizeOperation) return;

    switch (visualizeOperation.operation_type) {
      case OPERATION_TYPES.VISUALIZE_BAR_CHART:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART:
      case OPERATION_TYPES.VISUALIZE_HORIZ_BAR_CHART:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART:
        return (
          <VisualizeLineOrBarChartSection
            dataPanelTemplate={dataPanelTemplate}
            sortClauses={sortOperation?.instructions.sortColumns}
            instructions={visualizeOperation.instructions.VISUALIZE_LINE_OR_BAR_CHART}
            operationType={visualizeOperation.operation_type}
          />
        );
      case OPERATION_TYPES.VISUALIZE_NUMBER:
        return (
          <VisualizeNumberSection
            dataPanelTemplate={dataPanelTemplate}
            instructions={visualizeOperation.instructions.VISUALIZE_NUMBER}
          />
        );
      case OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP:
        return (
          <VisualizeChoroplethMapSection
            dataPanelTemplate={dataPanelTemplate}
            instructions={
              visualizeOperation.instructions.VISUALIZE_CHOROPLETH_MAP ||
              cloneDeep({
                region: 'WORLD',
              })
            }
          />
        );
      case OPERATION_TYPES.VISUALIZE_FUNNEL:
        return (
          <VisualizeFunnelSection
            dataPanelTemplate={dataPanelTemplate}
            instructions={visualizeOperation.instructions.VISUALIZE_FUNNEL || cloneDeep({})}
          />
        );
      case OPERATION_TYPES.VISUALIZE_PIE_CHART:
        return (
          <VisualizePieChartSection
            dataPanelTemplate={dataPanelTemplate}
            instructions={
              visualizeOperation.instructions.VISUALIZE_PIE_CHART ||
              cloneDeep({
                layoutType: PIE_CHART_TYPES.pie.id,
              })
            }
          />
        );
      case OPERATION_TYPES.VISUALIZE_HEAT_MAP:
        return (
          <VisualizeHeatMapSection
            dataPanelTemplate={dataPanelTemplate}
            instructions={visualizeOperation.instructions.VISUALIZE_HEAT_MAP || cloneDeep({})}
          />
        );
      case OPERATION_TYPES.VISUALIZE_PIVOT_CHART:
        return (
          <VisualizePivotChart
            dataPanelTemplate={dataPanelTemplate}
            instructions={visualizeOperation.instructions.VISUALIZE_PIVOT_CHART || cloneDeep({})}
          />
        );
    }
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateSelectedChart,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VisualizeConfigMenu)),
);
