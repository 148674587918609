/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { NonIdealState } from '@blueprintjs/core';

import { pageView } from 'analytics/exploAnalytics';

const styles = (theme: Theme) => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    backgroundColor: theme.palette.ds.grey100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  expirationPanel: {
    backgroundColor: theme.palette.ds.white,
    width: '60vw',
    maxWidth: 600,
    border: `1px solid ${theme.palette.ds.grey500}`,
    borderRadius: 4,
    padding: `${theme.spacing(12)}px ${theme.spacing(6)}px`,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },
});

type Props = WithStyles<typeof styles>;

class TrialExpiredPage extends React.Component<Props> {
  componentDidMount() {
    pageView('Trial Expired Page');
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.expirationPanel}>
          <NonIdealState
            icon="error"
            title="Your trial has expired"
            description="Thanks for trying Explo! Your trial period has expired. In order to continue using Explo, you will have to move to a paid plan. Please contact us at support@explo.co to sign up for a plan."
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TrialExpiredPage);
