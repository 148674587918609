/** @format */

import { DataPanelTemplate, User } from 'actions/types';
import _ from 'underscore';
import Color from 'color';
import { Regions } from '@blueprintjs/table';
import { Table } from '@blueprintjs/table';
import { Theme } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash';

import { SupportedDataSource } from 'actions/types';
import { format } from 'd3-format';

const COLUMN_WIDTH = 150;

export const userName = (user: User) => {
  return user.first_name + ' ' + user.last_name;
};

export const numberWithCommas = (num: number) => format(',d')(num);

export const isUUID = (potentialUUIDString: string) => {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    potentialUUIDString,
  );
};

export const isFirstCharacterANumber = (name: string) => {
  const firstChar = name.charAt(0);
  return firstChar <= '9' && firstChar >= '0';
};

export const namesWithLeadingNumbersExist = (names: string[]) => {
  return (
    _.filter(names, (name) => {
      return isFirstCharacterANumber(name);
    }).length > 0
  );
};

export const duplicateNamesExist = (names: string[]) => {
  return names.some((item, idx) => {
    return names.indexOf(item) !== idx;
  });
};

export const removeAccentsFromString = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const accentMarksExistInNames = (names: string[]) => {
  return (
    _.filter(names, (name) => {
      return removeAccentsFromString(name) !== name;
    }).length > 0
  );
};

export const nonAlphaNumericCharactersExistInNames = (names: string[]) => {
  return (
    _.filter(names, (name) => {
      return name.match(/^[a-z0-9_]+$/i) === null;
    }).length > 0
  );
};

export const hasUpperCase = (str: string) => {
  const stri = /[A-Z]/.test(str);
  return stri;
};

export const upperCaseCharactersExistInNames = (names: string[]) => {
  return (
    _.filter(names, (name) => {
      return hasUpperCase(name);
    }).length > 0
  );
};

const columnIndexIsUnviewable = (columnIndex: number, previewTableRef: Table) => {
  // @ts-ignore
  const scrollLeftOffset = previewTableRef.scrollContainerElement.scrollLeft;
  const bottomIndex = Math.ceil(scrollLeftOffset / COLUMN_WIDTH);
  // @ts-ignore
  const topIndex = Math.ceil(
    // @ts-ignore
    (previewTableRef.scrollContainerElement.clientWidth + scrollLeftOffset) / COLUMN_WIDTH,
  );
  return columnIndex + 1 <= bottomIndex || columnIndex + 1 >= topIndex;
};

export const safeScrollToColumn = (columnIndex: number, previewTableRef?: Table) => {
  if (!previewTableRef) {
    console.log(
      'Internal Error: PreviewTable Reference (previewTableRef) is missing when scrolling to a table column.',
    );
  } else {
    if (columnIndexIsUnviewable(columnIndex, previewTableRef)) {
      previewTableRef.scrollToRegion(Regions.column(columnIndex));
    }
  }
};

// Ref: TU/htzpdkws
export function array_move<T>(arr: (T | undefined)[], old_index: number, new_index: number) {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

// _.findIndex returns -1 if the element cannot be found in the array, this checks for that
export function removeElementAtIndex<T>(index: number, array: T[]) {
  return index !== -1 && array.splice(index, 1);
}

export function findCommonElements<T>(arr1: T[], arr2: T[]) {
  return arr1.some((item) => arr2.includes(item));
}

export const configureDataPanels = (dataPanelTemplates: DataPanelTemplate[]) => {
  dataPanelTemplates = dataPanelTemplates.map((dataPanelTemplate: DataPanelTemplate) => {
    dataPanelTemplate._adHocOperationInstructions = {
      currentPage: 1,
      filterInfo: { matchOnAll: true, filterClauses: [] },
    };
    return dataPanelTemplate;
  });
  return dataPanelTemplates;
};

export function longestStringLength(strings: string[]) {
  let longestLength = 0;
  strings.forEach((str) => {
    if (str.length > longestLength) {
      longestLength = str.length;
    }
  });

  return longestLength;
}

export function exhaustiveCheck(): never {
  throw new Error('Exhaustive check failed');
}

// TU/5czd2wxs
export function mixColors(color1: string, color2: string, ratio: number) {
  const color1Object = new Color(color1);
  const color2Object = new Color(color2);

  const r1 = color1Object.red();
  const g1 = color1Object.green();
  const b1 = color1Object.blue();
  const r2 = color2Object.red();
  const g2 = color2Object.green();
  const b2 = color2Object.blue();

  let r = Math.ceil(r1 * ratio + r2 * (1 - ratio));
  let g = Math.ceil(g1 * ratio + g2 * (1 - ratio));
  let b = Math.ceil(b1 * ratio + b2 * (1 - ratio));
  r = Math.min(255, r);
  g = Math.min(255, g);
  b = Math.min(255, b);

  return new Color(`rgb(${r}, ${g}, ${b})`);
}

export function getTextColorForBackground(backgroundColor: string, theme: Theme) {
  return new Color(backgroundColor).isDark() ? theme.palette.ds.white : theme.palette.ds.black;
}

export const getPercentageRatio = (min: number, max: number, value: number) => {
  if (min >= 0 && max >= 0) {
    /**
     * We use || -0.0001 to avoid dividing by zero
     */
    return (value - min) / (max - min || 0.0001);
  }
  return 1 - Math.abs(value - max) / Math.abs(min - max || 0.0001);
};

export const createDebouncedFn = (milliseconds: number) =>
  _.debounce((fn: () => void) => {
    fn();
  }, milliseconds);

export function shiftItemsInList<T>(arr: T[], from: number, to: number) {
  const element = arr[from];
  arr.splice(from, 1);
  arr.splice(to, 0, element);
}

export const parseJsonFields = (dataSource: SupportedDataSource, configuration: any) => {
  const parsedConfig = cloneDeep(configuration);

  const properties = dataSource.configuration_schema.properties;

  let error: string | undefined;
  _.map(dataSource.configuration_schema.order, (propertyName: string) => {
    if (properties[propertyName].type === 'json') {
      try {
        const noNewLines = configuration[propertyName].replace(/\n/g, '\\n');
        parsedConfig[propertyName] = JSON.parse(noNewLines);
      } catch (err) {
        error = err;
      }
    }
  });

  return { parsedConfig, error };
};
