/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';

import DropdownSelect from 'shared/DropdownSelect';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { SelectedDropdownInputItem, LineElasticity } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2TwoDimensionChartInstructions;
};

export default function LineChartConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedElasticity =
    instructions.chartSpecificFormat?.lineChart?.elasticity || LineElasticity.CURVED;

  return (
    <div className={classes.root}>
      <DropdownSelect
        containerClassName={classes.configInput}
        selectedItem={{
          id: selectedElasticity,
          name: selectedElasticity,
        }}
        onChange={(item: SelectedDropdownInputItem) => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.chartSpecificFormat = {
            ...newInstructions.chartSpecificFormat,
            lineChart: {
              ...newInstructions.chartSpecificFormat?.lineChart,
              elasticity: item.id as LineElasticity,
            },
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        filterable={false}
        options={[
          { id: LineElasticity.CURVED, name: LineElasticity.CURVED },
          { id: LineElasticity.STRAIGHT, name: LineElasticity.STRAIGHT },
        ]}
        noSelectionText="Select Elasticity"
        minimal
        btnMinimal
        fillWidth
        label="Line Elasticity"
      />
      <SwitchInput
        className={classes.configInput}
        switchOn={!instructions.chartSpecificFormat?.lineChart?.hideMarkers}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.chartSpecificFormat = {
            ...newInstructions.chartSpecificFormat,
            lineChart: {
              ...newInstructions.chartSpecificFormat?.lineChart,
              hideMarkers: !instructions.chartSpecificFormat?.lineChart?.hideMarkers,
            },
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Show Markers"
      />
      <SwitchInput
        className={classes.configInput}
        // We need to default this to false to maintain backwards compatibility
        switchOn={instructions.chartSpecificFormat?.timeSeriesDataFormat?.zeroMissingDates}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.chartSpecificFormat = {
            ...newInstructions.chartSpecificFormat,
            timeSeriesDataFormat: {
              ...newInstructions.chartSpecificFormat?.timeSeriesDataFormat,
              zeroMissingDates: !instructions.chartSpecificFormat?.timeSeriesDataFormat
                ?.zeroMissingDates,
            },
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Zero Missing Date Data"
      />
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={String(instructions.chartSpecificFormat?.lineChart?.lineWidth ?? 2)}
        onNewValueSubmitted={(newValue: string) => {
          const newInstructions = cloneDeep(instructions);
          const newInt = newValue === '' ? 2 : parseInt(newValue);

          if (!isNaN(newInt) && newInt >= 0) {
            newInstructions.chartSpecificFormat = {
              ...newInstructions.chartSpecificFormat,
              lineChart: {
                ...newInstructions.chartSpecificFormat?.lineChart,
                lineWidth: newInt,
              },
            };
            dispatch(
              updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
            );
          }
        }}
        label="Line Width"
      />
    </div>
  );
}
