/** @format */

import React from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { LineColumn } from 'constants/types';

type Props = {
  onComboUpdateChartType: (chartType: string) => void;
  lineColumn: LineColumn;
};

class LineBarSelect extends React.Component<Props> {
  render() {
    const { onComboUpdateChartType, lineColumn } = this.props;
    const selectedType = lineColumn.lineOrBar || 'bar';

    return (
      <div>
        <ButtonGroup>
          <Button
            onClick={() => onComboUpdateChartType('bar')}
            id="barButton"
            icon="timeline-bar-chart"
            active={selectedType === 'bar'}
          />
          <Button
            onClick={() => onComboUpdateChartType('line')}
            id="lineButton"
            icon="timeline-line-chart"
            active={selectedType === 'line'}
          />
        </ButtonGroup>
      </div>
    );
  }
}
export default LineBarSelect;
