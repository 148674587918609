/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import FlexBox, { VerticalAlignment } from 'components/core/FlexBox';
import FlexItem from 'components/core/FlexItem';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    marginTop: theme.spacing(2),
  },
  goalButton: {
    '& .bp3-button': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      fontWeight: 'normal',
    },
  },
  goalInputFlexItem: {
    minWidth: 0,
  },
  goalInputContainer: {
    paddingLeft: theme.spacing(2),
  },
  goalInput: {
    '& .bp3-input': {
      height: 35,
      textAlign: 'center',
      padding: 0,
      // Blueprint uses 10px of right padding applied directly to the element so we
      // use !important to override
      paddingRight: '0px !important',
    },
  },
}));

type Props = {
  exactValue?: number;
  flex?: number;
  label?: string;
  type: 'max' | 'mid' | 'min';
  usesColumnCalculation?: boolean;
  updateExactValue: (newValue: number) => void;
  updateUsesColumnCalculation: (newValue: boolean) => void;
};

export default function GoalInputRow({
  exactValue,
  flex,
  label,
  type,
  usesColumnCalculation,
  updateExactValue,
  updateUsesColumnCalculation,
}: Props) {
  const classes = useStyles();

  return (
    <FlexBox verticalAlignment={VerticalAlignment.BOTTOM} className={classes.input}>
      <FlexItem flex={flex ?? 2}>
        <ToggleButtonGroup fillWidth className={classes.goalButton} label={label}>
          <ToggleButton
            active={usesColumnCalculation}
            text={`Column ${type}`}
            onClick={() => {
              !usesColumnCalculation && updateUsesColumnCalculation(true);
            }}
          />
          <ToggleButton
            active={!usesColumnCalculation}
            text="Exact value"
            onClick={() => {
              usesColumnCalculation && updateUsesColumnCalculation(false);
            }}
          />
        </ToggleButtonGroup>
      </FlexItem>
      <FlexItem className={classes.goalInputFlexItem}>
        <InputWithBlurSave
          hideRightIconInteractions
          className={classes.goalInput}
          containerClassName={classes.goalInputContainer}
          disabled={usesColumnCalculation}
          initialValue={String(exactValue ?? '1')}
          onNewValueSubmitted={(newValue) => {
            if (isNaN(Number(newValue))) {
              return;
            }

            updateExactValue(Number(newValue));
          }}
        />
      </FlexItem>
    </FlexBox>
  );
}
