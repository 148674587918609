/** @format */

import React, { useState } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/index';
import { Icon } from '@blueprintjs/core';

import Button from 'shared/Button';
import FlexBox, { VerticalAlignment, HorizontalAlignment } from 'components/core/FlexBox';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 4,
    backgroundColor: theme.palette.ds.grey100,
  },
  headerDeleteBtn: {
    '&:hover': {
      backgroundColor: '#ffcccb !important',
      '& $headerDeleteIcon': {
        color: `${theme.palette.ds.red} !important`,
      },
    },
  },
  headerDeleteIcon: {
    color: '#7D91AE !important', // switch to grey500 once added to theme
  },
  header: {
    padding: `0 ${theme.spacing(2)}px`,
    height: 32,
    borderRadius: 4,
    backgroundColor: theme.palette.ds.grey200,

    '&.open': {
      backgroundColor: theme.palette.ds.grey300,
    },

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.ds.grey300,
    },
  },
  rightHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  chevronIcon: {
    marginRight: theme.spacing(1),
  },
  title: {
    color: theme.palette.ds.grey900,
    fontSize: 14,
    fontWeight: 600,
  },
  configContainer: {
    paddingBottom: 1, // this is required to make the margin from the config show up
  },
}));

export type Props = {
  /**
   * optional class to wrap entire component
   */
  className?: string;
  /**
   * content to go inside of group when expanded
   */

  children: JSX.Element;
  /**
   * title of group to show up on the top button
   */
  title: string;
  /**
   * if passed in, the delete button won't show up
   */
  isNotDeletable?: boolean;
  /**
   * handle when the user selects to delete
   */
  onDelete: () => void;
};

const CollapsibleGroup: React.FC<Props> = ({
  className,
  title,
  children,
  isNotDeletable,
  onDelete,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={cx(classes.root, className)}>
      <FlexBox
        verticalAlignment={VerticalAlignment.CENTER}
        horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}
        className={cx(classes.header, { open: isOpen })}
        onClick={() => setIsOpen(!isOpen)}>
        <div className={classes.rightHeader}>
          <Icon className={classes.chevronIcon} icon={isOpen ? 'chevron-down' : 'chevron-right'} />
          <div className={classes.title}>{title}</div>
        </div>
        {!isNotDeletable && (
          <Button
            minimal
            className={classes.headerDeleteBtn}
            icon={<Icon className={classes.headerDeleteIcon} icon="trash" iconSize={14} />}
            onClick={(e) => {
              onDelete();
              e.stopPropagation();
            }}
          />
        )}
      </FlexBox>
      {isOpen && <div className={classes.configContainer}>{children}</div>}
    </div>
  );
};

export default CollapsibleGroup;
