/** @format */
import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { dayjs } from 'utils/localizationUtils';
import { Popover, Position, Menu, MenuItem } from '@blueprintjs/core';
import { connect } from 'react-redux';

import Tag from 'components/core/Tag';
import FlexBox, { VerticalAlignment, HorizontalAlignment } from 'components/core/FlexBox';
import Button from 'shared/Button';
import PublishToDropdown from './PublishToDropdown';

import { DashboardEnvironmentTag, DashboardVersion, UserInfo } from 'actions/types';
import { switchCurrentlyEditingDashboardVersion } from 'actions/dashboardV2Actions';
import { TRIPLE_DOT_MENU } from 'constants/iconConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),

    '&:hover': {
      backgroundColor: theme.palette.ds.grey100,
      borderRadius: 4,
    },
  },
  versionInfoContainer: {
    width: '100%',
  },
  versionNameAndTags: {
    flexWrap: 'wrap',

    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  versionNumber: {
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  createdDate: {
    marginRight: theme.spacing(2),
    fontSize: 12,
    height: 16,
    borderRadius: 8,
    color: theme.palette.ds.grey800,
    padding: `0 ${theme.spacing(1)}px`,
    backgroundColor: 'rgba(10, 17, 30, 0.04)',
    width: 'max-content',
    whiteSpace: 'nowrap',
  },
  changeComment: {
    marginTop: theme.spacing(1),
    color: theme.palette.ds.grey500,
    fontSize: 12,
    whiteSpace: 'pre-wrap',
  },
  envTag: {
    marginRight: theme.spacing(2),
  },
  tripleDotButton: {
    '&.bp3-button': {
      backgroundColor: 'transparent',
      '&.bp3-button:hover': {
        backgroundColor: theme.palette.ds.grey300,
      },
      '&.bp3-button:active': {
        backgroundColor: theme.palette.ds.hovered.grey300,
      },
    },
    '&.bp3-button.bp3-active': {
      backgroundColor: theme.palette.ds.hovered.grey300,
      boxShadow: 'none',
      '&.bp3-button.bp3-active': {
        backgroundColor: theme.palette.ds.pressed.grey300,
      },
    },
  },
}));

export type Props = {
  className?: string;
  version: DashboardVersion;
  author?: UserInfo;
  tags: DashboardEnvironmentTag[];
  closeModal: () => void;
  dashboardTemplateId: number;
} & typeof mapDispatchToProps;

const VersionPanel: FunctionComponent<Props> = (props) => {
  const {
    className,
    tags,
    version,
    switchCurrentlyEditingDashboardVersion,
    closeModal,
    dashboardTemplateId,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={cx(classes.root, className)}>
      <FlexBox
        horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}
        verticalAlignment={VerticalAlignment.TOP}>
        <div className={classes.versionInfoContainer}>
          <FlexBox
            verticalAlignment={VerticalAlignment.CENTER}
            className={classes.versionNameAndTags}>
            <div className={classes.versionNumber}>Version {version.version_number}</div>
            <FlexBox
              verticalAlignment={VerticalAlignment.CENTER}
              horizontalAlignment={HorizontalAlignment.CENTER}
              className={classes.createdDate}>
              {dayjs(version.version_saved_at || version.created)
                // set the locale to english for this specific instance because
                // we don't want the app builder itself to be translated yet
                .locale('en-us')
                .fromNow()}
            </FlexBox>
            {tags.map(
              (tag) =>
                tag.dashboard_versions_by_dashboard &&
                tag.dashboard_versions_by_dashboard[dashboardTemplateId] === version.id && (
                  <Tag
                    key={`attached-tag-${tag.id}`}
                    name={tag.name}
                    backgroundColor={tag.color_hex}
                    className={classes.envTag}
                  />
                ),
            )}
          </FlexBox>
          <div className={classes.changeComment}>{version.change_comments}</div>
        </div>
        <FlexBox verticalAlignment={VerticalAlignment.CENTER}>
          <Popover
            minimal
            hoverCloseDelay={0}
            position={Position.BOTTOM_RIGHT}
            autoFocus={false}
            content={
              <Menu>
                <MenuItem
                  text="Preview"
                  onClick={() => {
                    switchCurrentlyEditingDashboardVersion({ dashboardVersion: version });
                    closeModal();
                  }}
                />
                <MenuItem text="Publish To">
                  <PublishToDropdown
                    tags={tags}
                    versionId={version.id}
                    dashboardTemplateId={dashboardTemplateId}
                  />
                </MenuItem>
              </Menu>
            }>
            <Button
              minimal
              className={classes.tripleDotButton}
              icon={TRIPLE_DOT_MENU(theme.palette.ds.grey900)}
            />
          </Popover>
        </FlexBox>
      </FlexBox>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  switchCurrentlyEditingDashboardVersion,
};

export default connect(mapStateToProps, mapDispatchToProps)(VersionPanel);
