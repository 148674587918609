/** @format */

import React from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/index';
import { Icon, Popover, Position, Menu, MenuItem, MenuDivider } from '@blueprintjs/core';

import { ChartColumnInfo } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.lightBlue,
    border: `1px solid ${theme.palette.ds.lightBlue}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: 3,
    marginBottom: theme.spacing(1),

    '&:last-child': {
      marginBottom: 0,
    },

    '&:hover': {
      border: `1px solid ${theme.palette.ds.blue}`,
      cursor: 'pointer',
    },
  },
  popoverTargetContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  name: {
    fontWeight: 600,
    color: '#182026',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  caretIcon: {
    marginLeft: theme.spacing(3),
  },
  groupHeader: {
    '&..bp3-heading': {
      paddingLeft: 0,
      color: theme.palette.ds.grey800,
    },
  },
  menuItemOption: {
    alignItems: 'center',
  },
}));

export type Props = {
  column: { column: ChartColumnInfo };
  options?: { id: string; name: string }[];
  selectedOption?: { id: string; name: string };
  onColOptionChanged: (option: { id: string; name: string }) => void;
  onRemoveCol: () => void;
  disableEdits?: boolean;
};

export default function GenericDroppedColumn({
  column,
  onColOptionChanged,
  options,
  selectedOption,
  onRemoveCol,
  disableEdits,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Popover
        fill
        minimal
        captureDismiss
        position={Position.BOTTOM_RIGHT}
        content={
          <Menu>
            {options?.map((option) => (
              <MenuItem
                className={classes.menuItemOption}
                icon={
                  selectedOption?.id === option.id && (
                    <Icon
                      icon="full-circle"
                      iconSize={6} // 6 is the size of the dot icon
                      color={theme.palette.ds.blue}
                    />
                  )
                }
                key={_.uniqueId('col-config-option')}
                text={option.name}
                onClick={() => !disableEdits && onColOptionChanged(option)}
                disabled={disableEdits}
              />
            ))}
            {options && options.length > 0 && <MenuDivider />}
            <MenuItem
              text="Remove"
              onClick={() => !disableEdits && onRemoveCol()}
              disabled={disableEdits}
            />
          </Menu>
        }>
        <div className={classes.popoverTargetContainer}>
          <div className={classes.name}>
            {selectedOption ? `${selectedOption.id}(${column.column.name})` : column.column.name}
          </div>
          <Icon color={theme.palette.ds.blue} className={classes.caretIcon} icon="caret-down" />
        </div>
      </Popover>
    </div>
  );
}
