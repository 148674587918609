/** @format */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { FormGroup, Checkbox, Divider } from '@blueprintjs/core';
import { GoogleLogin } from 'react-google-login';
import { ReduxState } from 'reducers/rootReducer';
import { User } from 'actions/types';

import SetupFlowPage from 'components/pages/setupFlowPage';
import InputField from 'explo-ds/forms/marketing/inputField';
import Button from 'shared/Button';

import { registerUser } from 'actions/authAction';
import { UserAuthenticationToken } from 'actions/responseTypes';
import { onRegistrationSubmit } from 'utils/landingPageUtils';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { pageView } from 'analytics/exploAnalytics';
import parse from 'url-parse';

import { ROUTES } from 'constants/routes';
import {
  fetchSignupEmail,
  googleOAuthVerification,
  inviteTeammate,
  addUserToTeam,
} from 'actions/authAction';
import {
  googleOAuthVerificationOnSuccess,
  googleOAuthVerificationOnFailure,
} from 'utils/landingPageUtils';

const useStyles = makeStyles((theme: Theme) => ({
  signupInput: {
    width: '100%',
    height: 40,
    marginTop: 2,
  },
  passwordInputGroup: {
    marginTop: theme.spacing(5),
  },
  signInButton: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
  checkboxInput: {
    marginTop: theme.spacing(5),

    '& input:checked ~ .bp3-control-indicator': {
      backgroundColor: `${theme.palette.ds.blue} !important`,
    },
  },
  checkboxText: {
    fontSize: 14,
    marginTop: '-21px',
  },
  checkboxLinks: {
    textDecoration: 'underline',
  },
  signupDisabledInput: {
    width: '100%',
    height: 40,
    marginTop: 2,
    cursor: 'not-allowed',
    background: theme.palette.ds.grey200,
  },
  googleSignupInput: {
    width: '100%',
    marginTop: theme.spacing(0.5),
    justifyContent: 'center',
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    // matches the FormGroup padding
    marginBottom: '15px',
    color: theme.palette.ds.grey700,
  },
  divider: {
    flexGrow: 1,
    // otherwsie the dividers render a little verticle line
    borderRight: 0,
  },
  dividerContainerText: {
    // align the text better with the divider lines
    paddingBottom: '2px',
  },
}));

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & RouteComponentProps;

const SignupPageV2 = (props: Props) => {
  const {
    registrationLoading,
    registerUser,
    fetchSignupEmail,
    addUserToTeam,
    googleOAuthVerification,
    ssoLoginLoading,
  } = props;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [signupEmail, setSignupEmail] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [invalidInviteMessage, setInvalidInviteMessage] = useState<string | undefined>(undefined);
  const urlQueryParams = parse(window.location.href, true).query;
  useEffect(() => {
    if (urlQueryParams.invite_hash) {
      fetchSignupEmail(
        {
          postData: {
            invite_hash: urlQueryParams.invite_hash,
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (response: any) => {
          if (response.invite_accepted) {
            props.history.push(`/login?invite_hash=${urlQueryParams.invite_hash}`);
          }
          if (!response.invite_code) {
            setInvalidInviteMessage(
              'The invite code is invalid. Please make sure you copied the invite link correctly or request a new invitation.',
            );
          } else {
            setSignupEmail(response.email);
            setInviteCode(response.invite_code);
          }
        },
        () =>
          setInvalidInviteMessage(
            'The invite code is invalid. Please make sure you copied the invite link correctly or request a new invitation.',
          ),
      );
    }
  }, [urlQueryParams.invite_hash, fetchSignupEmail, props.history]);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);

  const classes = useStyles();

  const addToTeam = (user: User) => {
    if (!user.id || !inviteCode) {
      props.history.push(ROUTES.JOIN_TEAM);
      return;
    }
    addUserToTeam(
      user.id,
      inviteCode,
      () => {
        // @ts-ignore
        window.location = ROUTES.HOME_APP_PAGE;
      },
      () => {
        // @ts-ignore
        window.location = ROUTES.HOME_APP_PAGE;
      },
      urlQueryParams.invite_hash,
    );
  };

  useEffect(() => {
    pageView('Signup');

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === 13) {
        onSubmit(
          termsChecked,
          password,
          password2,
          signupEmail,
          firstName,
          lastName,
          registerUser,
          (user: User) => {
            addToTeam(user);
          },
        );
        return;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  const formIncomplete =
    !password || !password2 || !signupEmail || !firstName || !lastName || !termsChecked;

  return (
    <SetupFlowPage
      title="Sign up"
      errorMsg={invalidInviteMessage}
      pageBody={
        <div>
          <FormGroup>
            <GoogleLogin
              className={classes.googleSignupInput}
              clientId={`${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`}
              buttonText="Sign up with Google"
              responseType="code"
              redirectUri={`${process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URI}`}
              accessType="offline"
              disabled={registrationLoading || ssoLoginLoading}
              onSuccess={(response) => {
                googleOAuthVerification(
                  {
                    postData: {
                      authorization_code: response.code,
                      invite_hash: urlQueryParams.invite_hash,
                    },
                  },
                  (response: UserAuthenticationToken) => {
                    googleOAuthVerificationOnSuccess(response.token);
                  },
                  /* eslint-disable  @typescript-eslint/no-explicit-any */
                  (response: any) => {
                    googleOAuthVerificationOnFailure(response?.error_msg);
                  },
                );
              }}
              onFailure={(response: any) => googleOAuthVerificationOnFailure(response?.details)}
            />
          </FormGroup>
          <div className={classes.dividerContainer}>
            <Divider className={classes.divider} />
            <div className={classes.dividerContainerText}>or</div>
            <Divider className={classes.divider} />
          </div>
          <FormGroup label="First name">
            <InputField
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={classes.signupInput}
              placeholder=""
            />
          </FormGroup>

          <FormGroup label="Last name">
            <InputField
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={classes.signupInput}
              placeholder=""
            />
          </FormGroup>

          <FormGroup label="Email address">
            <InputField
              value={signupEmail}
              onChange={(e) => setSignupEmail(e.target.value)}
              className={inviteCode ? classes.signupDisabledInput : classes.signupInput}
              placeholder=""
              disabled={!!inviteCode}
            />
          </FormGroup>

          <FormGroup className={classes.passwordInputGroup} label="Password">
            <InputField
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              className={classes.signupInput}
              placeholder=""
            />
          </FormGroup>
          <FormGroup className={classes.passwordInputGroup} label="Confirm password">
            <InputField
              value={password2}
              type="password"
              onChange={(e) => setPassword2(e.target.value)}
              className={classes.signupInput}
              placeholder=""
            />
          </FormGroup>
          <Checkbox
            className={classes.checkboxInput}
            large={true}
            checked={termsChecked}
            onChange={() => setTermsChecked(!termsChecked)}>
            <div className={classes.checkboxText}>
              Check here to indicate that you have read and agree to the Explo{' '}
              <a href="/terms_of_service.pdf" className={classes.checkboxLinks} target="_blank">
                Terms of Service
              </a>{' '}
              and{' '}
              <a href="/privacy_policy.pdf" className={classes.checkboxLinks} target="_blank">
                Privacy Policy
              </a>
              .
            </div>
          </Checkbox>
          <Button
            className={classes.signInButton}
            type="primary"
            disabled={formIncomplete}
            onClick={() =>
              !formIncomplete &&
              onSubmit(
                termsChecked,
                password,
                password2,
                signupEmail,
                firstName,
                lastName,
                registerUser,
                (user: User) => {
                  addToTeam(user);
                },
              )
            }
            loading={registrationLoading || ssoLoginLoading}
            text="Sign Up"
          />
        </div>
      }
    />
  );
};

const onSubmit = (
  termsChecked: boolean,
  password: string,
  password2: string,
  signupEmail: string,
  firstName: string,
  lastName: string,
  registerUser: any,
  onSuccess: (user: User) => void,
) => {
  onRegistrationSubmit(
    termsChecked,
    password,
    password2,
    signupEmail,
    firstName,
    lastName,
    registerUser,
    onSuccess,
  );
};

const mapStateToProps = (state: ReduxState) => ({
  registrationLoading: state.currentUser.registrationLoading,
  ssoLoginLoading: createLoadingSelector([ACTION.GOOGLE_OAUTH_VERIFICATION], false)(state),
});

const mapDispatchToProps = {
  registerUser,
  fetchSignupEmail,
  inviteTeammate,
  addUserToTeam,
  googleOAuthVerification,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignupPageV2));
