/** @format */

import React, { useState } from 'react';
import { InputGroup } from '@blueprintjs/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  searchBarWrapper: {
    position: 'relative',
  },
});

export type Props = {
  /**
   * Change event handler
   */
  onChange: (searchText: string) => void;
};

const SearchBar: React.FC<Props> = ({ onChange }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState<string>('');

  return (
    <div className={classes.searchBarWrapper}>
      <InputGroup
        placeholder="Search"
        round
        leftIcon="search"
        value={searchText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchText(event.currentTarget.value);
          onChange(event.currentTarget.value);
        }}
      />
    </div>
  );
};

export default SearchBar;
