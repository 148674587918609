/** @format */
import Cookies from 'js-cookie';

import { resetUser } from 'analytics/exploAnalytics';
import loadingReducer from 'reducers/api/loadingReducer';
import authReducer from 'reducers/authReducer';
import teamDataReducer from 'reducers/teamDataReducer';
import supportedDataSourcesReducer from 'reducers/supportedDataSourcesReducer';
import dataSourceReducer from 'reducers/dataSourceReducer';
import dataSourceListReducer from 'reducers/dataSourceListReducer';
import dashboardReducer from 'reducers/dashboardReducer';
import datasetsReducer from 'reducers/datasetsReducer';
import endUserGroupsReducer from 'reducers/endUserGroupsReducer';
import endUserGroupInfoReducer from 'reducers/endUserGroupInfoReducer';
import styleConfigReducer from 'reducers/styleConfigReducer';
import editingDashboardElementReducer from 'reducers/editingDashboardElementReducer';
import embedDashboardReducer from 'reducers/embedDashboardReducer';
import embedDashboardStyleReducer from 'reducers/embedDashboardStyleReducer';
import changelogEntriesReducer from 'reducers/changelogEntriesReducer';
import dashboardEditConfigReducer from 'reducers/dashboardEditConfigReducer';
import dashboardVersionsReducer from 'reducers/dashboardVersionsReducer';
import parentSchemaReducer from 'reducers/parentSchemaReducer';
import { combineReducers, Reducer } from 'redux';
import whitelistDomainReducer from './whitelistDomainReducer';
import internationalizationReducer from './internationalizationReducer';
import emailCadenceReducer from './emailReducer';

const appReducer = combineReducers({
  loading: loadingReducer,
  currentUser: authReducer,
  teamData: teamDataReducer,
  supportedDataSources: supportedDataSourcesReducer,
  dataSource: dataSourceReducer,
  dataSourceList: dataSourceListReducer,
  dashboard: dashboardReducer,
  datasets: datasetsReducer,
  endUserGroups: endUserGroupsReducer,
  endUserGroupInfo: endUserGroupInfoReducer,
  styleConfig: styleConfigReducer,
  editingDashboardElement: editingDashboardElementReducer,
  embedDashboard: embedDashboardReducer,
  embedDashboardStyle: embedDashboardStyleReducer,
  changelogs: changelogEntriesReducer,
  dashboardEditConfig: dashboardEditConfigReducer,
  dashboardVersions: dashboardVersionsReducer,
  parentSchemas: parentSchemaReducer,
  whitelistDomains: whitelistDomainReducer,
  internationalization: internationalizationReducer,
  emailCadence: emailCadenceReducer,
});

const rootReducer: Reducer<ReduxState, Action> = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    resetUser();
    Cookies.remove('spheres_auth_token');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type ReduxState = ReturnType<typeof appReducer>;

export default rootReducer;

export interface Action {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
