/** @format */

import React from 'react';

import EditingPanel from 'pages/dataPanelEditorPage/editingPanel';
import FilterEditingOptions from 'pages/dataPanelEditorPage/filterEditingOptions';

import { FilterOperatorType, FilterValueType } from 'constants/types';
import { TableColumn } from 'actions/types';

type PassedProps = {
  baseSchema: TableColumn[];
  className?: string;
  onDelete: () => void;
  selectedColumn?: TableColumn;
  selectedOperator?: FilterOperatorType;
  filterValue?: FilterValueType;
  onColumnSelect: (column: TableColumn) => void;
  onOperatorSelect: (operator: FilterOperatorType) => void;
  onFilterValueUpdate: (value: FilterValueType) => void;
};

type Props = PassedProps;

class FilterEditingPanel extends React.Component<Props> {
  render() {
    const { className, onDelete, ...otherProps } = this.props;
    return (
      <EditingPanel className={className} onDelete={onDelete}>
        <FilterEditingOptions {...otherProps} />
      </EditingPanel>
    );
  }
}

export default FilterEditingPanel;
