/** @format */

import React from 'react';
import _ from 'underscore';
import cloneDeep from 'lodash/cloneDeep';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import {
  FormGroup,
  Checkbox,
  Tooltip,
  Icon,
  Position,
  Intent,
  Callout,
  TextArea,
} from '@blueprintjs/core';

import FullPagePanel from 'components/pages/fullPagePanel';
import Button from 'shared/Button';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import DropdownSelect from 'shared/DropdownSelect';

import {
  listTeamDataSources,
  fetchSupportedDataSources,
  testDataSourceConnection,
  connectDataSource,
} from 'actions/dataSourceActions';
import { fetchParentSchemas, createParentSchema } from 'actions/parentSchemaActions';
import { SupportedDataSource, ParentSchema } from 'actions/types';
import { ROUTES } from 'constants/routes';
import { SelectedDropdownInputItem } from 'constants/types';

import { trackEvent } from 'analytics/exploAnalytics';
import TextFieldModal from 'components/modals/textFieldModal';

const ADD_SCHEMA_BTN_WIDTH = 30;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      width: '100%',
      padding: `60px 0`,
      overflowY: 'auto' as 'auto',
    },
    pagePanel: {
      width: 500,
    },
    checkboxContainer: {
      display: 'flex',
    },
    helpTooltip: {
      margin: theme.spacing(1.5),
    },
    actionsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    actionButton: {
      marginLeft: theme.spacing(3),
      width: 150,
    },
    testingConnectionContainer: {
      marginBottom: theme.spacing(5),
    },
    whitelistIpContainer: {
      marginBottom: theme.spacing(5),
      backgroundColor: theme.palette.ds.grey200,
    },
    schemaContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    schemaDropdownContainer: {
      flexGrow: 1,
      width: `calc(100% - ${ADD_SCHEMA_BTN_WIDTH}px)`,
    },
    whitelistIp: {
      fontWeight: 600,
    },
    checkbox: {
      '& input:checked ~ .bp3-control-indicator': {
        backgroundColor: `${theme.palette.ds.blue} !important`,
      },
    },
  });

type MatchParams = {};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  name: string;
  selectedType?: SupportedDataSource;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  configuration?: any;
  testingConnWorked?: boolean;
  testingConnLoading: boolean;
  testingConnError: string;
  connectDataSourceLoading: boolean;
  selectedSchema?: ParentSchema;
  selectedSchemaIsNew: boolean;
  newSchemaModalOpen: boolean;
};

class ConnectDataSourcePage extends React.Component<Props, State> {
  state: State = {
    name: '',
    testingConnLoading: false,
    testingConnError: '',
    connectDataSourceLoading: false,
    selectedSchemaIsNew: false,
    newSchemaModalOpen: false,
  };

  constructor(props: Props) {
    super(props);

    props.listTeamDataSources();
    props.fetchSupportedDataSources();
    props.fetchParentSchemas({}, (result) => {
      if (result.parent_schemas.length === 0) {
        // Create a schema for the team if there is none. Not passing
        // in a name here means that we'll name it default_{team_name}
        props.createParentSchema({}, (data) => {
          trackEvent('Created default schema', {
            schema: data.schema,
          });

          this.setState({
            selectedSchemaIsNew: true,
            selectedSchema: data.schema,
          });
        });
      }
    });
  }

  render() {
    const { supportedDataSources, classes, parentSchemas, dataSources } = this.props;
    const { selectedType, selectedSchema, selectedSchemaIsNew } = this.state;

    const sourcesByType = _.indexBy(supportedDataSources.dataSources || [], 'type');
    const parentSchemaById = _.indexBy(parentSchemas || [], 'id');
    const defaultDataSourcesByParentSchemaId = _.indexBy(
      dataSources.filter((datasource) => datasource.default),
      'parent_schema_id',
    );

    const defaultDataSource = selectedSchema
      ? defaultDataSourcesByParentSchemaId[selectedSchema.id]
      : undefined;

    const options = defaultDataSource
      ? [
          {
            id: defaultDataSource.source_type,
            name: defaultDataSource.source_type,
          },
        ]
      : supportedDataSources.dataSources?.map((source) => ({
          id: source.type,
          name: source.name,
        })) ?? [];

    const getDefaultSourceType = (selectedSchema: ParentSchema) => {
      const defaultDataSource = dataSources.find(
        (dataSource: { parent_schema_id: number; default: boolean }) =>
          dataSource.parent_schema_id === selectedSchema.id && dataSource.default === true,
      );

      if (!defaultDataSource) return;

      return sourcesByType[defaultDataSource.source_type];
    };

    return (
      <div className={classes.root}>
        <FullPagePanel className={classes.pagePanel} title="Connect a Database">
          <FormGroup label="Name" labelInfo="*">
            <InputGroup
              type="text"
              value={this.state.name}
              placeholder="e.g. Acme Inc. Replica"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({ name: e.target.value });
              }}
            />
          </FormGroup>
          <FormGroup label="Schema" labelInfo="*" contentClassName={cx(classes.schemaContainer)}>
            <DropdownSelect
              fillWidth
              minimal
              containerClassName={classes.schemaDropdownContainer}
              selectedItem={
                selectedSchema && { id: selectedSchema.id.toString(), name: selectedSchema.name }
              }
              onChange={(item: SelectedDropdownInputItem) => {
                const defaultSourceType = getDefaultSourceType(parentSchemaById[parseInt(item.id)]);

                !selectedSchemaIsNew &&
                  this.setState({
                    selectedSchema: parentSchemaById[parseInt(item.id)],
                    selectedType: defaultSourceType,
                    configuration:
                      defaultSourceType && this.defaultConfigForType(defaultSourceType),
                  });
              }}
              filterable={false}
              options={
                parentSchemas
                  ? parentSchemas.map((source) => ({
                      id: source.id.toString(),
                      name: source.name,
                    }))
                  : []
              }
              noSelectionText="Select a schema"
              disabled={selectedSchemaIsNew}
            />
            <Button
              minimal
              icon="plus"
              disabled={selectedSchemaIsNew}
              onClick={() => {
                !selectedSchemaIsNew && this.setState({ newSchemaModalOpen: true });
              }}
            />
          </FormGroup>
          <FormGroup label="Database Engine" labelInfo="*">
            <DropdownSelect
              fillWidth
              minimal
              onChange={(item: SelectedDropdownInputItem) =>
                this.setState({
                  selectedType: sourcesByType[item.id],
                  configuration: this.defaultConfigForType(sourcesByType[item.id]),
                })
              }
              filterable={false}
              selectedItem={selectedType && { id: selectedType.type, name: selectedType.name }}
              options={options}
              noSelectionText="e.g. PostgreSQL, BigQuery, etc."
              disabled={options.length === 1}
            />
          </FormGroup>

          {this.renderSourceOptions()}
          {this.renderWhitelistIps()}
          {this.renderTestingConnection()}
          {this.renderActions()}
          {this.renderCreateSchemaModal()}
        </FullPagePanel>
      </div>
    );
  }

  defaultConfigForType = (type: SupportedDataSource) => {
    const configuration: { [propertyName: string]: string | null | undefined } = {};

    _.each(Object.keys(type.configuration_schema.properties), (propertyName) => {
      configuration[propertyName] =
        type.configuration_schema.properties[propertyName].default !== undefined
          ? type.configuration_schema.properties[propertyName].default
          : null;
    });

    return configuration;
  };

  renderSourceOptions = () => {
    const { selectedType, configuration } = this.state;
    const { classes } = this.props;
    if (!selectedType) return;

    const properties = selectedType.configuration_schema.properties;

    return (
      <>
        {selectedType.configuration_schema.order.map((propertyName: string) => {
          if (
            properties[propertyName].depends_on &&
            !configuration[properties[propertyName].depends_on || '']
          ) {
            return null;
          }

          if (properties[propertyName].type === 'checkbox') {
            return (
              <div
                className={classes.checkboxContainer}
                key={`add_source_field_${selectedType.type}_${propertyName}`}>
                <FormGroup>
                  <Checkbox
                    className={classes.checkbox}
                    value={configuration[propertyName] || false}
                    onChange={() => {
                      configuration[propertyName] = !configuration[propertyName];
                      this.setState({ configuration });
                    }}>
                    {properties[propertyName].label}
                    {configuration[propertyName] && properties[propertyName].checked_label}
                  </Checkbox>
                </FormGroup>
                {properties[propertyName].help_tooltip && (
                  <Tooltip
                    className={classes.helpTooltip}
                    position={Position.TOP}
                    content={properties[propertyName].help_tooltip}>
                    <Icon icon="help" />
                  </Tooltip>
                )}
              </div>
            );
          } else if (properties[propertyName].type === 'textarea') {
            return (
              <FormGroup
                label={properties[propertyName].label}
                labelInfo={properties[propertyName].optional ? undefined : '*'}
                key={`add_source_field_${selectedType.type}_${propertyName}`}>
                <TextArea
                  fill
                  value={configuration[propertyName] || ''}
                  placeholder={properties[propertyName].placeholder}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    configuration[propertyName] = e.target.value;
                    this.setState({ configuration });
                  }}
                />
              </FormGroup>
            );
          } else {
            return (
              <FormGroup
                label={properties[propertyName].label}
                labelInfo={properties[propertyName].optional ? undefined : '*'}
                key={`add_source_field_${selectedType.type}_${propertyName}`}>
                <InputGroup
                  type={properties[propertyName].type}
                  value={configuration[propertyName] || ''}
                  placeholder={properties[propertyName].placeholder}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    configuration[propertyName] =
                      properties[propertyName].type === 'number'
                        ? parseInt(e.target.value)
                        : e.target.value;
                    this.setState({ configuration });
                  }}
                />
              </FormGroup>
            );
          }
        })}
      </>
    );
  };

  renderWhitelistIps = () => {
    const { classes } = this.props;
    return (
      <Callout intent={'none' as Intent} className={classes.whitelistIpContainer}>
        {"If your database is behind a firewall, please whitelist Explo's egress IPs: "}
        <span className={classes.whitelistIp}>54.211.43.19</span>,{' '}
        <span className={classes.whitelistIp}>52.55.98.121</span>,{' '}
        <span className={classes.whitelistIp}>3.214.169.94</span>,{' '}
        <span className={classes.whitelistIp}>54.156.141.148</span>.
      </Callout>
    );
  };

  renderTestingConnection = () => {
    const { classes } = this.props;
    const { testingConnWorked, testingConnLoading, testingConnError } = this.state;

    let contents, title, intent;
    if (testingConnWorked) {
      title = 'Test connection worked!';
      intent = Intent.SUCCESS;
    } else if (testingConnLoading) {
      title = 'Loading connection test...';
      intent = Intent.PRIMARY;
    } else if (testingConnError) {
      title = 'Test connection failed';
      contents = <div>{testingConnError}</div>;
      intent = Intent.DANGER;
    } else {
      return;
    }

    return (
      <Callout
        intent={intent as Intent}
        title={title}
        className={classes.testingConnectionContainer}>
        {contents}
      </Callout>
    );
  };

  renderActions = () => {
    const { classes } = this.props;
    const { testingConnLoading, connectDataSourceLoading } = this.state;

    return (
      <div className={classes.actionsContainer}>
        <Button
          className={classes.actionButton}
          type="secondary"
          onClick={() => this.configurationComplete() && this.testConnection()}
          disabled={!this.configurationComplete() || testingConnLoading}
          loading={testingConnLoading}
          text="Test"
        />
        <Button
          className={classes.actionButton}
          type="primary"
          disabled={!this.configurationComplete() || connectDataSourceLoading}
          loading={connectDataSourceLoading}
          onClick={() => this.configurationComplete() && this.connectDataSource()}
          text="Connect"
        />
      </div>
    );
  };

  renderCreateSchemaModal = () => {
    const { newSchemaModalOpen } = this.state;

    return (
      <TextFieldModal
        modalOpen={newSchemaModalOpen}
        closeModal={() => this.setState({ newSchemaModalOpen: false })}
        modalTitle="Add a new schema"
        buttonName="Save"
        textFieldPlaceholder="Schema Name"
        onSubmit={(name: string) =>
          this.setState({
            selectedSchemaIsNew: true,
            selectedSchema: { name: name, id: -1, team_id: -1 },
          })
        }
      />
    );
  };

  configurationComplete = () => {
    const { name, selectedType, configuration } = this.state;

    if (!selectedType) {
      return false;
    } else if (name.trim() === '') {
      return false;
    }

    const properties = selectedType.configuration_schema.properties;

    return _.all(
      _.map(selectedType.configuration_schema.order, (propertyName: string) => {
        if (
          (properties[propertyName].depends_on &&
            !configuration[properties[propertyName].depends_on || '']) ||
          properties[propertyName].optional
        ) {
          return true;
        }
        return (
          configuration[propertyName] !== null &&
          configuration[propertyName] !== undefined &&
          configuration[propertyName] !== ''
        );
      }),
    );
  };

  testConnection = () => {
    const { name, selectedType, selectedSchema } = this.state;

    if (!selectedType || !selectedSchema) return;

    this.setState({ testingConnLoading: true, testingConnWorked: undefined, testingConnError: '' });

    const { parsedConfig, error } = this.parseJsonFields();
    if (error !== null) {
      this.setState({
        testingConnLoading: false,
        testingConnWorked: false,
        testingConnError: error.toString(),
      });
      return;
    }

    this.props.testDataSourceConnection(
      {
        postData: {
          name: name,
          type: selectedType.type,
          configuration: parsedConfig,
        },
      },
      () => {
        this.setState({ testingConnLoading: false, testingConnWorked: true, testingConnError: '' });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        this.setState({
          testingConnLoading: false,
          testingConnWorked: false,
          testingConnError:
            response.error_msg ||
            'There was an error with your credentials. Please ensure they are correct.',
        });
      },
    );
  };

  connectDataSource = () => {
    const { name, selectedType, selectedSchema } = this.state;
    if (!selectedType || !selectedSchema) return;

    this.setState({
      testingConnLoading: true,
      testingConnWorked: undefined,
      testingConnError: '',
      connectDataSourceLoading: true,
    });
    const { parsedConfig, error } = this.parseJsonFields();
    if (error !== null) {
      this.setState({
        testingConnLoading: false,
        testingConnWorked: false,
        testingConnError: error.toString(),
      });
      return;
    }

    this.props.connectDataSource(
      {
        postData: {
          name: name,
          type: selectedType.type,
          configuration: parsedConfig,
          schema: selectedSchema,
        },
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      () => {
        this.props.history.push(ROUTES.DATA_SOURCES_PAGE);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        this.setState({
          testingConnLoading: false,
          testingConnWorked: false,
          testingConnError:
            response?.error_msg ||
            'There was an error with your credentials. Please check your firewall settings and ensure the above information is correct.',
          connectDataSourceLoading: false,
        });
      },
    );
  };

  parseJsonFields = () => {
    const { selectedType, configuration } = this.state;
    const parsedConfig = cloneDeep(configuration);
    if (!selectedType) {
      return { parsedConfig, error: '' };
    }

    const properties = selectedType.configuration_schema.properties;

    let error = null;
    _.map(selectedType.configuration_schema.order, (propertyName: string) => {
      if (properties[propertyName].type === 'json') {
        try {
          const noNewLines = configuration[propertyName].replace(/\n/g, '\\n');
          parsedConfig[propertyName] = JSON.parse(noNewLines);
        } catch (err) {
          error = err;
        }
      }
    });

    return { parsedConfig, error };
  };
}

const mapStateToProps = (state: ReduxState) => ({
  supportedDataSources: state.supportedDataSources,
  parentSchemas: state.parentSchemas.allParentSchema,
  dataSources: state.dataSourceList.dataSources,
});

const mapDispatchToProps = {
  fetchSupportedDataSources,
  testDataSourceConnection,
  connectDataSource,
  fetchParentSchemas,
  createParentSchema,
  listTeamDataSources,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConnectDataSourcePage)),
);
