/** @format */

import React from 'react';
import cx from 'classnames';
import { Classes, Dialog } from '@blueprintjs/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'shared/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.ds.white,
    borderRadius: 16,
    width: 'auto',
    padding: `${theme.spacing(6)}px ${theme.spacing(8)}px`,
  },

  modalTitle: {
    fontSize: 20,
    fontWeight: 600,
    margin: 0,
    marginBottom: theme.spacing(8),
  },

  modalFooter: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
}));

type Props = {
  modalOpen: boolean;
  closeModal: () => void;
  modalTitle: string;
  cancelBtnText: string;
  confirmBtnText: string;
  onConfirm: () => void;
  canEscapeKeyClose?: boolean;
  isDestructive?: boolean;
};

const ConfirmationModal = (props: Props) => {
  const {
    modalOpen,
    closeModal,
    isDestructive,
    modalTitle,
    cancelBtnText,
    confirmBtnText,
    onConfirm,
    canEscapeKeyClose,
  } = props;
  const classes = useStyles();

  let textLimit = confirmBtnText;
  if (confirmBtnText.length >= 30) textLimit = 'Delete datatset';

  return (
    <Dialog
      className={classes.root}
      isOpen={modalOpen}
      onClose={closeModal}
      canEscapeKeyClose={canEscapeKeyClose}>
      <div className={cx(Classes.DIALOG_BODY, classes.modalTitle)}>{modalTitle}</div>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <div className={cx(Classes.DIALOG_FOOTER, classes.modalFooter)}>
          <Button onClick={closeModal} text={cancelBtnText} type="secondary" />
          <Button
            onClick={onConfirm}
            text={textLimit}
            type={isDestructive ? 'destructive' : 'primary'}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
