/** @format */

import _ from 'underscore';
import { COLOR_LIST } from 'constants/colorConstants';
import { AGGREGATIONS_TYPES } from 'constants/dataConstants';
import {
  OPERATION_TYPES,
  V2_CHART_VISUALIZATION_OPERATIONS,
  VisualizeOperationInstructions,
} from 'constants/types';

export const chartConfigIsSame = (oldConfig: any, newConfig: any) => {
  return (
    _.isEqual(oldConfig.data.labels, newConfig.data.labels) &&
    oldConfig.data.datasets.length === newConfig.data.datasets.length &&
    _.isEqual(
      _.map(oldConfig.data.datasets, (d: any) => d.data),
      _.map(newConfig.data.datasets, (d: any) => d.data),
    ) &&
    _.isEqual(
      _.map(oldConfig.data.datasets, (d: any) => d.label),
      _.map(newConfig.data.datasets, (d: any) => d.label),
    ) &&
    _.isEqual(
      _.map(oldConfig.data.datasets, (d: any) => d.backgroundColor),
      _.map(newConfig.data.datasets, (d: any) => d.backgroundColor),
    ) &&
    _.isEqual(
      _.map(oldConfig.data.datasets, (d: any) => d.borderColor),
      _.map(newConfig.data.datasets, (d: any) => d.borderColor),
    ) &&
    _.isEqual(
      _.map(oldConfig.data.datasets, (d: any) => d.yAxisID),
      _.map(newConfig.data.datasets, (d: any) => d.yAxisID),
    ) &&
    _.isEqual(
      _.map(oldConfig.options.scales.yAxes, (d: any) => d.id),
      _.map(newConfig.options.scales.yAxes, (d: any) => d.id),
    ) &&
    _.isEqual(
      _.map(oldConfig.options.scales.xAxes, (d: any) => d.id),
      _.map(newConfig.options.scales.xAxes, (d: any) => d.id),
    )
  );
};

// TODO: type the instructions once the line/bar chart is typed
export const getSelectedLineColor = (instructions: any, lineIndex: number) => {
  const { multiAxis, lineColumns } = instructions;

  if (!multiAxis) {
    return lineColumns[lineIndex].color || COLOR_LIST[lineIndex % COLOR_LIST.length].primary;
  }

  const axisId = lineColumns[lineIndex].yAxis || 'left-axis';
  const axisRepresentativeLineIndex = indexOffirstLineOfAxis(lineColumns, axisId);
  return lineColumns[axisRepresentativeLineIndex].color;
};

export const indexOffirstLineOfAxis = (lines: any, axisId: string) => {
  return _.findIndex(lines, (line: any) =>
    axisId === 'left-axis'
      ? line.yAxis === undefined || line.yAxis === axisId
      : line.yAxis === axisId,
  );
};

export const colorLuminance = (hex: string, lum: number) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }
  return rgb;
};

export const convertHexToRGBA = (hexString: string, alpha: number) => {
  const hex = hexString.substr(1, 10);
  let c;
  let rgbaString = 'rgba(';
  for (let i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    rgbaString += `${c},`;
  }
  rgbaString += `${alpha})`;
  return rgbaString;
};

export const isMultiAxis = (instructions: any) => {
  if (!instructions.multiAxis) {
    return false;
  }

  const { lineColumns } = instructions;
  const axisTypes = new Set();

  lineColumns.map((column: any) => {
    if (column.yAxis === undefined) {
      axisTypes.add('left-axis');
    } else {
      axisTypes.add(column.yAxis);
    }
    return null;
  });

  return axisTypes.size >= 2;
};

export const titleCase = (s: string | number) => {
  if (!s) return '';

  s = String(s);

  s = s.replace(/_/g, ' ');

  // WIP - camel case to title case
  // if (s.indexOf(' ') === -1) {
  //   s = s.replace(/([A-Z])/g, ' $1');
  // }
  return capitalizeEachWord(s);
};

const capitalizeEachWord = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const aggTitle = (aggType: string, column: string) => {
  if (aggType === AGGREGATIONS_TYPES.COUNT.id || aggType === AGGREGATIONS_TYPES.COUNT_DISTINCT.id) {
    return 'Count of Records';
  }

  return `${titleCase(aggType)} of ${titleCase(column)}`;
};

export const getInformationTooltipInfo = (
  opType: OPERATION_TYPES,
  vizInstructions: VisualizeOperationInstructions,
) => {
  if (V2_CHART_VISUALIZATION_OPERATIONS.indexOf(opType) >= 0) {
    if (opType === OPERATION_TYPES.VISUALIZE_NUMBER_V2) {
      if (vizInstructions.V2_KPI?.generalFormat?.showTooltip) {
        return vizInstructions.V2_KPI.generalFormat?.tooltipText;
      }
    } else if (opType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
      if (vizInstructions.V2_KPI_TREND?.generalFormat?.showTooltip) {
        return vizInstructions.V2_KPI_TREND.generalFormat.tooltipText;
      }
    } else {
      if (vizInstructions.V2_TWO_DIMENSION_CHART?.generalFormat?.showTooltip) {
        return vizInstructions.V2_TWO_DIMENSION_CHART.generalFormat?.tooltipText;
      }
    }
  } else if (opType === OPERATION_TYPES.VISUALIZE_TABLE) {
    if (vizInstructions.VISUALIZE_TABLE.generalFormat?.showTooltip) {
      return vizInstructions.VISUALIZE_TABLE.generalFormat?.tooltipText ?? '';
    }
  }
};
