/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

import InformationIcon from 'pages/dashboardPage/DashboardDatasetView/Header/InformationIcon';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: 12,
    color: theme.palette.ds.grey900,
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    height: 16,

    '&.largeText': {
      fontSize: 14,
    },
  },
  fakeLabel: {
    visibility: 'hidden',
  },
  error: {
    color: theme.palette.ds.red,
    marginTop: theme.spacing(1),
    marginBottom: 0,
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
  },
}));

export type Props = {
  text: string;
  className?: string;
  error?: boolean;
  fakeLabel?: boolean;
  helpText?: string;
  largeText?: boolean;
};

export default function InputLabel({
  error,
  text,
  className,
  fakeLabel,
  helpText,
  largeText,
}: Props) {
  const classes = useStyles();
  return (
    <div
      className={cx(
        classes.label,
        {
          [classes.error]: error,
          [classes.fakeLabel]: fakeLabel,
          largeText: largeText,
        },
        GLOBAL_STYLE_CLASSNAMES.text.smallBody.secondary,
        className,
      )}>
      {fakeLabel ? '-' : text}
      {helpText && <InformationIcon className={classes.infoIcon} infoTooltipText={helpText} />}
    </div>
  );
}
