/** @format */
import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import cx from 'classnames';
import { Dialog } from '@blueprintjs/core';

import FlexBox, { VerticalAlignment, HorizontalAlignment } from './FlexBox';
import Button from 'shared/Button';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.white,
    borderRadius: 16,
  },
  header: {
    padding: theme.spacing(5),
    fontWeight: 600,
    fontSize: 20,
  },
}));

export type ModalProps = {
  className?: string;
  modalOpen?: boolean;
  onClose: () => void;
  title: string;
};

const Modal: FunctionComponent<ModalProps> = (props) => {
  const { className, children, modalOpen, onClose, title } = props;
  const classes = useStyles();

  return (
    <Dialog className={cx(classes.root, className)} isOpen={modalOpen} onClose={onClose}>
      <FlexBox
        className={classes.header}
        horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}
        verticalAlignment={VerticalAlignment.CENTER}>
        <div>{title}</div>
        <Button minimal icon="cross" onClick={onClose} />
      </FlexBox>
      {children}
    </Dialog>
  );
};

export default Modal;
