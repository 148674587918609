/** @format */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { FormGroup, Intent } from '@blueprintjs/core';
import { ReduxState } from 'reducers/rootReducer';
import { AppToaster } from 'toaster';
import { sha256 } from 'js-sha256';

import SetupFlowPage from 'components/pages/setupFlowPage';
import InputField from 'explo-ds/forms/marketing/inputField';
import Button from 'shared/Button';
import { createLoadingSelector } from 'reducers/api/selectors';

import { registerUser } from 'actions/authAction';
import { ACTION } from 'actions/types';
import { pageView } from 'analytics/exploAnalytics';

import { passwordResetConfirm } from 'actions/authAction';

const useStyles = makeStyles((theme: Theme) => ({
  signupInput: {
    width: '100%',
    height: 40,
    marginTop: 2,
  },
  passwordInputGroup: {
    marginTop: theme.spacing(5),
  },
  signInButton: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
}));

type MatchParams = {
  resetToken: string;
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<MatchParams>;

const ResetPasswordPage = (props: Props) => {
  return <SetupFlowPage title="Reset Password" pageBody={<ResetPasswordBody {...props} />} />;
};

const ResetPasswordBody = (props: Props) => {
  const { passwordResetConfirm, passwordResetConfirmLoading } = props;
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const classes = useStyles();

  useEffect(() => {
    pageView('Forgot password');
  });

  const onPasswordResetConfirmSubmitted = () => {
    if (password !== password2) {
      AppToaster.show({
        message: 'Passwords do not match.',
        icon: 'error',
        timeout: 3000,
        intent: Intent.DANGER,
      });
    } else if (password.length < 6) {
      AppToaster.show({
        message: 'Password should contain at least 6 characters.',
        icon: 'error',
        timeout: 5000,
        intent: Intent.DANGER,
      });
    } else if (password === '' && password2 === '') {
      AppToaster.show({
        message: 'Passwords are empty.',
        icon: 'error',
        timeout: 3000,
      });
    } else {
      passwordResetConfirm(
        {
          postData: {
            token: props.match.params.resetToken,
            password: sha256(password),
          },
        },
        () => {
          AppToaster.show({
            message: `Password reset successfully.`,
            icon: 'endorsed',
            timeout: 3000,
            intent: Intent.SUCCESS,
          });
          setPassword('');
          setPassword2('');
          props.history.push('/login');
        },
        () => {
          AppToaster.show({
            message: `Something went wrong. Please contact Explo support if this continues.`,
            icon: 'error',
            timeout: 3000,
            intent: Intent.DANGER,
          });
        },
      );
    }
  };

  return (
    <div>
      <FormGroup className={classes.passwordInputGroup} label="Password">
        <InputField
          value={password}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          className={classes.signupInput}
          placeholder=""
        />
      </FormGroup>
      <FormGroup className={classes.passwordInputGroup} label="Confirm password">
        <InputField
          value={password2}
          type="password"
          onChange={(e) => setPassword2(e.target.value)}
          className={classes.signupInput}
          placeholder=""
        />
      </FormGroup>

      <Button
        className={classes.signInButton}
        type="primary"
        onClick={() => onPasswordResetConfirmSubmitted()}
        loading={passwordResetConfirmLoading}
        text="Reset Password"
      />
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  passwordResetConfirmLoading: createLoadingSelector([ACTION.PASSWORD_RESET_CONFIRM], false)(state),
});

const mapDispatchToProps = {
  registerUser,
  passwordResetConfirm,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPasswordPage));
