/** @format */

import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import cx from 'classnames';

import Button from 'shared/Button';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerText: {
      fontWeight: 500,
      fontSize: `24px !important`,
      marginBottom: 0,
    },
    actionButtonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    secondaryButton: {
      marginRight: theme.spacing(5),
    },
    bottomBorder: {
      borderBottom: `1px solid ${theme.palette.ds.grey500}`,
    },
  });

type PassedProps = {
  title: string;
  primaryActionButtonText?: string;
  onPrimaryActionButtonClick?: () => void;
  secondaryActionButtonText?: string;
  onSecondaryActionButtonClick?: () => void;
  className?: string;
  displayBottomBorder?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

class PageHeader extends React.Component<Props> {
  render() {
    const { classes, title, className, displayBottomBorder } = this.props;

    return (
      <div className={cx(classes.root, className, { [classes.bottomBorder]: displayBottomBorder })}>
        <div className={classes.headerText}>{title}</div>
        <div className={classes.actionButtonsContainer}>
          {this.renderSecondardActionButton()}
          {this.renderPrimaryActionButton()}
        </div>
      </div>
    );
  }

  renderSecondardActionButton = () => {
    const { classes, secondaryActionButtonText, onSecondaryActionButtonClick } = this.props;

    if (!secondaryActionButtonText) return;

    return (
      <Button
        className={classes.secondaryButton}
        onClick={onSecondaryActionButtonClick}
        text={secondaryActionButtonText}
        type="secondary"
      />
    );
  };

  renderPrimaryActionButton = () => {
    const { primaryActionButtonText, onPrimaryActionButtonClick } = this.props;

    if (!primaryActionButtonText) return;

    return (
      <Button type="primary" text={primaryActionButtonText} onClick={onPrimaryActionButtonClick} />
    );
  };
}

export default withStyles(styles)(PageHeader);
