/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { parseISO } from 'date-fns';
import { cloneDeep } from 'lodash';
import { ButtonGroup, Button } from '@blueprintjs/core';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';
import DatePickerInput from 'shared/DatePickerInput';
import DropdownSelect from 'shared/DropdownSelect';

import {
  DatepickerElemConfig,
  DashboardElementConfig,
  RELATIVE_DATE_OPTIONS,
  DEFAULT_DATE_TYPES,
  DATE_RANGE_TYPES,
  RELATIVE_DATE_RANGES,
} from 'types/dashboardTypes';

const styles = (theme: Theme) => ({
  root: {
    '& .bp3-button-group .bp3-button': {
      zIndex: 0,
    },
  },
  configInput: {
    color: theme.palette.ds.grey700,
    marginTop: theme.spacing(4),
  },
  timeSelectConfig: {
    color: theme.palette.ds.grey700,
    marginTop: theme.spacing(5),
  },
  dafaultValueTypeToggle: {
    width: '100%',
  },
  defaultValueHeader: {
    fontWeight: 500,
    fontSize: '14',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(4),
  },
  relativeDateDropdown: {
    marginTop: theme.spacing(4),
  },
  defaultValueType: {
    width: '50%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

type PassedProps = {
  config: DatepickerElemConfig;
  updateConfig: (newConfig: DashboardElementConfig) => void;
};

type Props = RouteComponentProps & WithStyles<typeof styles> & PassedProps;

type State = {};

class DatepickerElementConfigPanel extends React.Component<Props, State> {
  state: State = {};

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderLabelInput()}
        {this.renderTimeSelectToggle()}
        {this.renderCancelableFlag()}
        {this.renderDefaultValuePicker()}
        {this.renderDateRangePicker()}
      </div>
    );
  }

  renderLabelInput = () => {
    const { config, classes, updateConfig } = this.props;
    return (
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={config.label || ''}
        onNewValueSubmitted={(newValue: string) => {
          config.label = newValue;
          updateConfig(config);
        }}
        placeholder=""
        label="Label"
      />
    );
  };

  renderTimeSelectToggle = () => {
    const { config, classes, updateConfig } = this.props;

    return (
      <SwitchInput
        className={classes.timeSelectConfig}
        switchOn={!config.hideTimeSelect}
        onChange={() => {
          config.hideTimeSelect = !config.hideTimeSelect;
          updateConfig(config);
        }}
        label="Allow time selection"
      />
    );
  };

  renderDefaultValuePicker = () => {
    const { classes, config, updateConfig } = this.props;

    return (
      <div>
        <div className={classes.defaultValueHeader}>Default Value</div>
        <ButtonGroup className={classes.dafaultValueTypeToggle}>
          <Button
            onClick={() => {
              const newConfig = cloneDeep(config);
              newConfig.defaultType = DEFAULT_DATE_TYPES.EXACT;
              updateConfig(newConfig);
            }}
            active={config.defaultType === DEFAULT_DATE_TYPES.EXACT}
            className={classes.defaultValueType}>
            Exact Date
          </Button>
          <Button
            onClick={() => {
              const newConfig = cloneDeep(config);
              newConfig.defaultType = DEFAULT_DATE_TYPES.RELATIVE;
              updateConfig(newConfig);
            }}
            active={config.defaultType !== DEFAULT_DATE_TYPES.EXACT}
            className={classes.defaultValueType}>
            Relative Date
          </Button>
        </ButtonGroup>
        {config.defaultType === DEFAULT_DATE_TYPES.EXACT
          ? this.renderExactDefaultValuePicker()
          : this.renderRelativeDefaultValuePicker()}
      </div>
    );
  };

  renderRelativeDefaultValuePicker = () => {
    const { config, updateConfig, classes } = this.props;

    return (
      <DropdownSelect
        containerClassName={classes.relativeDateDropdown}
        filterable={false}
        selectedItem={
          config.relativeDefaultValue
            ? { name: config.relativeDefaultValue, id: config.relativeDefaultValue }
            : undefined
        }
        onChange={(newvalue) => {
          this.setState({ selectedRelativeDate: newvalue.name });
          const newConfig = cloneDeep(config);
          newConfig.relativeDefaultValue = newvalue.name as RELATIVE_DATE_OPTIONS;
          updateConfig(newConfig);
        }}
        noSelectionText="Select Relative Date"
        options={Object.values(RELATIVE_DATE_OPTIONS).map((date) => ({
          id: date,
          name: date,
        }))}
        minimal
        fillWidth
        showIcon
      />
    );
  };

  renderExactDefaultValuePicker = () => {
    const { config, classes, updateConfig } = this.props;

    return (
      <DatePickerInput
        className={classes.configInput}
        selectedValue={
          typeof config.defaultValue === 'string'
            ? parseISO(config.defaultValue)
            : config.defaultValue
        }
        onNewValueSelect={(newvalue) => {
          const newConfig = cloneDeep(config);
          newConfig.defaultValue = (newvalue as Date) || undefined;
          updateConfig(newConfig);
        }}
        showCancelBtn
        placeholder="Select a date"
        showTimeSelect={!config.hideTimeSelect}
        onCancelClick={() => {
          const newConfig = cloneDeep(config);
          newConfig.defaultValue = undefined;
          updateConfig(newConfig);
        }}
      />
    );
  };

  renderDateRangePicker = () => {
    const { classes, config, updateConfig } = this.props;

    return (
      <div>
        <div className={classes.defaultValueHeader}>Selectable Range</div>
        <ButtonGroup className={classes.dafaultValueTypeToggle}>
          <Button
            onClick={() => {
              const newConfig = cloneDeep(config);
              newConfig.dateRangeType = DATE_RANGE_TYPES.EXACT;
              updateConfig(newConfig);
            }}
            active={config.dateRangeType === DATE_RANGE_TYPES.EXACT}
            className={classes.defaultValueType}>
            Exact Range
          </Button>
          <Button
            onClick={() => {
              const newConfig = cloneDeep(config);
              newConfig.dateRangeType = DATE_RANGE_TYPES.RELATIVE;
              updateConfig(newConfig);
            }}
            active={config.dateRangeType !== DATE_RANGE_TYPES.EXACT}
            className={classes.defaultValueType}>
            Relative Range
          </Button>
        </ButtonGroup>
        {config.dateRangeType === DATE_RANGE_TYPES.EXACT
          ? this.renderExactRangePicker()
          : this.renderRelativeRangePicker()}
      </div>
    );
  };

  renderRelativeRangePicker = () => {
    const { config, updateConfig, classes } = this.props;

    return (
      <div>
        <DropdownSelect
          containerClassName={classes.relativeDateDropdown}
          filterable={false}
          showCancelBtn
          onCancelClick={() => {
            const newConfig = cloneDeep(config);
            newConfig.relativeDateRange = undefined;
            updateConfig(newConfig);
          }}
          selectedItem={
            config.relativeDateRange
              ? { name: config.relativeDateRange, id: config.relativeDateRange }
              : undefined
          }
          onChange={(newvalue) => {
            const newConfig = cloneDeep(config);
            newConfig.relativeDateRange = newvalue.name as RELATIVE_DATE_RANGES;
            updateConfig(newConfig);
          }}
          noSelectionText="Select Relative range"
          options={Object.values(RELATIVE_DATE_RANGES).map((date) => ({
            id: date,
            name: date,
          }))}
          minimal
          fillWidth
          showIcon
        />
      </div>
    );
  };

  renderExactRangePicker = () => {
    return (
      <div>
        {this.renderMinDatePicker()}
        {this.renderMaxDatePicker()}
      </div>
    );
  };

  renderMinDatePicker = () => {
    const { config, classes, updateConfig } = this.props;

    return (
      <DatePickerInput
        className={classes.configInput}
        selectedValue={
          typeof config.minValue === 'string' ? parseISO(config.minValue) : config.minValue
        }
        label="Start Date"
        onNewValueSelect={(newvalue) => {
          const newConfig = cloneDeep(config);
          newConfig.minValue = (newvalue as Date) || undefined;
          updateConfig(newConfig);
        }}
        showCancelBtn
        showTimeSelect={!config.hideTimeSelect}
        onCancelClick={() => {
          const newConfig = cloneDeep(config);
          newConfig.minValue = undefined;
          updateConfig(newConfig);
        }}
      />
    );
  };

  renderMaxDatePicker = () => {
    const { config, classes, updateConfig } = this.props;

    return (
      <DatePickerInput
        className={classes.configInput}
        selectedValue={
          typeof config.maxValue === 'string' ? parseISO(config.maxValue) : config.maxValue
        }
        label="End Date"
        onNewValueSelect={(newvalue) => {
          const newConfig = cloneDeep(config);
          newConfig.maxValue = (newvalue as Date) || undefined;
          updateConfig(newConfig);
        }}
        showCancelBtn
        showTimeSelect={!config.hideTimeSelect}
        onCancelClick={() => {
          const newConfig = cloneDeep(config);
          newConfig.maxValue = undefined;
          updateConfig(newConfig);
        }}
      />
    );
  };

  renderCancelableFlag = () => {
    const { config, classes, updateConfig } = this.props;

    return (
      <SwitchInput
        className={classes.timeSelectConfig}
        switchOn={!config.disableCancel}
        onChange={() => {
          config.disableCancel = !config.disableCancel;
          updateConfig(config);
        }}
        label="Enable clearing selection"
      />
    );
  };
}

export default withRouter(withStyles(styles)(DatepickerElementConfigPanel));
