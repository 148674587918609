/** @format */

import React, { useContext } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';
import InputLabel from 'shared/InputLabel';
import ColorPickerButton from 'shared/ColorPicker';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2BoxPlotInstructions } from 'constants/types';
import { titleCase } from 'utils/graphUtils';
import { ReduxState } from 'reducers/rootReducer';
import { getCategoricalColors, GlobalStylesContext } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
  columnHeading: {
    fontSize: 12,
    fontWeight: 600,
  },
  colorOptions: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  colorPickerContainer: {
    width: '100%',
  },
  medianColor: {
    marginLeft: theme.spacing(3),
  },
  colorPicker: {
    width: '100%',
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2BoxPlotInstructions;
};

export default function BoxPlotConfig({ visualizationType, instructions }: Props) {
  const { calcColumns, boxPlotFormat } = instructions;
  const classes = useStyles();
  const dispatch = useDispatch();
  const context = useContext(GlobalStylesContext);
  /**
   * We use useSelector here instead of useContext because the context around content other than the DashboardLayout
   * uses the default theme to avoid using custom styles in Explo app components. But here we want to use the user's configured
   * theme to determine what to display, not the default.
   */
  const { globalStyleConfig } = useSelector((state: ReduxState) => ({
    globalStyleConfig: state.styleConfig.current,
  }));

  return (
    <div className={classes.root}>
      {calcColumns?.map((col, idx) => (
        <>
          <div className={classes.configInput}>
            <div className={classes.columnHeading}>
              {titleCase(col.name || `Column ${idx + 1}`)}
            </div>
            <InputWithBlurSave
              initialValue={String(
                boxPlotFormat?.seriesLabelByColumn?.[col.name || ''] || `Series ${idx + 1}`,
              )}
              onNewValueSubmitted={(newValue: string) => {
                const newInstructions = cloneDeep(instructions);
                newInstructions.boxPlotFormat = {
                  ...newInstructions.boxPlotFormat,
                  seriesLabelByColumn: {
                    ...newInstructions.boxPlotFormat?.seriesLabelByColumn,
                    ...(col.name && { [col.name]: newValue }),
                  },
                };
                dispatch(
                  updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                );
              }}
              label="Series label"
            />
          </div>
          <div className={classes.colorOptions}>
            <div className={classes.colorPickerContainer}>
              <InputLabel text="Fill color" />
              <ColorPickerButton
                className={classes.colorPicker}
                color={
                  instructions.boxPlotFormat?.fillColorByColumn?.[col.name || ''] ||
                  globalStyleConfig.visualizations.gradientPalette.hue2
                }
                colorPalette={getCategoricalColors(context.globalStyleConfig)}
                onColorChange={(newColor: string) => {
                  const newInstructions = cloneDeep(instructions);

                  newInstructions.boxPlotFormat = {
                    ...newInstructions.boxPlotFormat,
                    fillColorByColumn: {
                      ...newInstructions.boxPlotFormat?.fillColorByColumn,
                      ...(col.name && { [col.name]: newColor }),
                    },
                  };
                  dispatch(
                    updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                  );
                }}
              />
            </div>
            <div className={cx(classes.colorPickerContainer, classes.medianColor)}>
              <InputLabel text="Median color" />
              <ColorPickerButton
                className={classes.colorPicker}
                color={
                  instructions.boxPlotFormat?.medianColorByColumn?.[col.name || ''] ||
                  globalStyleConfig.visualizations.gradientPalette.hue1
                }
                colorPalette={getCategoricalColors(context.globalStyleConfig)}
                onColorChange={(newColor: string) => {
                  const newInstructions = cloneDeep(instructions);

                  newInstructions.boxPlotFormat = {
                    ...newInstructions.boxPlotFormat,
                    medianColorByColumn: {
                      ...newInstructions.boxPlotFormat?.medianColorByColumn,
                      ...(col.name && { [col.name]: newColor }),
                    },
                  };
                  dispatch(
                    updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
                  );
                }}
              />
            </div>
          </div>
        </>
      ))}
      <SwitchInput
        className={classes.configInput}
        switchOn={boxPlotFormat?.isVertical}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.boxPlotFormat = {
            ...newInstructions.boxPlotFormat,
            isVertical: !newInstructions.boxPlotFormat?.isVertical,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Vertical orientation"
      />
      <SwitchInput
        className={classes.configInput}
        switchOn={boxPlotFormat?.hideWhisker}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.boxPlotFormat = {
            ...newInstructions.boxPlotFormat,
            hideWhisker: !newInstructions.boxPlotFormat?.hideWhisker,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Hide whisker"
      />
    </div>
  );
}
