/** @format */

import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'underscore';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withStyles, createStyles } from '@material-ui/styles';
import { WithStyles } from '@material-ui/core/index';
import ResizeObserver from 'react-resize-observer';
import HighchartsHeatMap from 'highcharts/modules/heatmap';
import HighchartsFunnel from 'highcharts/modules/funnel';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsHeatMap(Highcharts);
HighchartsFunnel(Highcharts);
HighchartsMore(Highcharts);

const styles = () =>
  createStyles({
    chartContainer: {
      height: '100%',
    },
  });

type PassedProps = {
  chartOptions?: Highcharts.Options;
  className?: string;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  chartOptions?: Highcharts.Options;
  chartSize: {
    width?: number;
    height?: number;
  };
};

class HighCharts extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      chartOptions: props.chartOptions,
      chartSize: {},
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { chartOptions } = this.props;
    if (!_.isEqual(prevProps.chartOptions, chartOptions)) {
      this.setState({
        chartOptions,
      });
    }
  }

  render() {
    const { classes, className } = this.props;
    const { chartOptions, chartSize } = this.state;
    if (chartOptions?.chart) chartOptions.chart.width = chartSize.width;
    if (chartOptions?.chart) chartOptions.chart.height = chartSize.height;

    return (
      <div className={cx(classes.chartContainer, className)}>
        <HighchartsReact
          containerProps={{ style: { height: '100%', width: '100%' } }}
          highcharts={Highcharts}
          options={{ ...chartOptions, credits: { enabled: false } }}
          // constructorType={constructorType}
        />
        <ResizeObserver
          onResize={(rect) =>
            this.setState({ chartSize: { width: rect.width, height: rect.height } })
          }></ResizeObserver>
      </div>
    );
  }
}

export default withStyles(styles)(HighCharts);
