/** @format */

import { StatsigEnvironment } from 'statsig-react';

export const getStatsigEnvironment = (): StatsigEnvironment => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production-eu':
    case 'production-canada':
    case 'production':
      return { tier: 'production' };
    case 'dev':
    case 'development':
    case 'experimental':
      return { tier: 'development' };
    case 'staging':
      return { tier: 'staging' };
    default:
      // We choose to default to production so that any bugs in the statsig env logic won't affect prod environments
      console.error(
        'Could not resolve statsig feature gate environment, defaulting to production. Feature gates may behave unexpectedly.',
      );
      return { tier: 'production' };
  }
};
