/** @format */

import React, { useCallback } from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { parseISO } from 'date-fns';

import DropdownSelect from 'shared/DropdownSelect';
import DatePickerInput from 'shared/DatePickerInput';
import ExploMultiSelect from 'components/multiSelect';
import DashboardDateRangePickerElement from '../dashboardElement/DashboardDateRangePickerElement';

import { getDateMin, getDateMax } from '../dashboardElement/dashboardDatepickerElement';
import { constructOptions } from '../dashboardElement/dashboardDropdownElement';
import { onItemSelected, onIndexUnselected } from '../dashboardElement/dashboardMultiSelectElement';
import {
  DashboardElement,
  DashboardVariableMap,
  DASHBOARD_ELEMENT_TYPES,
  DatepickerElemConfig,
  DATE_RANGE_TYPES,
  DropdownDashboardElemConfig,
  DateGroupSwitchConfig,
  DashboardVariable,
  TimePeriodDropdownDashboardElemConfig,
} from 'types/dashboardTypes';
import { DashboardParam, Dataset, EndUserGroup } from 'actions/types';
import { getDateGroupSwitchOptions } from 'utils/dashboardUtils';
import DashboardVariableEntry, {
  InputType,
} from '../dashboardDatasetEditor/dashboardVariableEntry';
import { DATE_TYPES } from 'constants/dataConstants';

const useStyles = makeStyles((theme: Theme) => ({
  variablesContainer: {
    fontFamily: 'Source Code Pro',
    padding: theme.spacing(1.5),
    paddingBottom: 300,
  },
  variable: {
    padding: theme.spacing(1.5),
  },
  variableName: {
    fontSize: 12,
    fontFamily: 'Source Code Pro',
  },
  variableInput: {
    marginTop: theme.spacing(2),
  },
  datePickerBtn: {
    '&.bp3-button': {
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      // To match blueprint input
      backgroundColor: theme.palette.ds.white,
      borderRadius: 3,
      '&:hover': {
        backgroundColor: theme.palette.ds.white,
      },
    },
  },
  sectionContainer: {
    padding: `${theme.spacing(2)}px 0`,
  },
  sectionHeader: {
    color: theme.palette.ds.grey700,
    fontSize: 12,
    textTransform: 'uppercase',
    marginLeft: 6,
    marginBottom: theme.spacing(1),
    fontWeight: 500,
  },
}));

type Props = {
  variables: DashboardVariableMap;
  dashboardInputElements: DashboardElement[];
  selectedUserGroupId?: number;
  updateUserGroup: (userGroupId?: number) => void;
  endUserGroups: EndUserGroup[];
  dashboardDatasets: Record<number, Dataset>;
  dashboardParams: Record<string, DashboardParam>;
};

export default function EditableVariableList({
  variables,
  dashboardInputElements,
  selectedUserGroupId,
  updateUserGroup,
  endUserGroups,
  dashboardDatasets,
  dashboardParams,
}: Props) {
  const classes = useStyles();

  const groupEndUserGroupsById = _.indexBy(endUserGroups, 'id');
  const selectedUserGroup = selectedUserGroupId && groupEndUserGroupsById[selectedUserGroupId];

  const onNewValueSelect = useCallback((varName: string, newValue: DashboardVariable) => {
    window.dispatchEvent(
      new CustomEvent('updateVariableValue', {
        detail: {
          varName,
          newValue,
        },
      }),
    );
  }, []);

  return (
    <div className={classes.variablesContainer}>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>Customer</div>
        <div className={classes.variable}>
          <div className={classes.variableName}>user_group_id</div>
          <div className={classes.variableInput}>
            <DropdownSelect
              minimal
              fillWidth
              showIcon
              selectedItem={
                selectedUserGroup
                  ? {
                      id: String(selectedUserGroup.id),
                      name: selectedUserGroup.name,
                    }
                  : undefined
              }
              onChange={(item) => updateUserGroup(parseInt(item.id))}
              noSelectionText="Select customer"
              options={endUserGroups.map((userGroup) => ({
                id: String(userGroup.id),
                name: userGroup.name,
              }))}
              showCancelBtn
              onCancelClick={() => updateUserGroup(undefined)}
            />
          </div>
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>Dashboard Elements</div>
        {dashboardInputElements.map((element) => {
          const value = variables[element.name];

          switch (element.element_type) {
            case DASHBOARD_ELEMENT_TYPES.DATEPICKER: {
              const datepickerConfig = element.config as DatepickerElemConfig;
              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>

                  <DatePickerInput
                    withPortal
                    className={classes.variableInput}
                    minDate={
                      datepickerConfig.dateRangeType === DATE_RANGE_TYPES.EXACT
                        ? datepickerConfig.minValue
                        : getDateMin(datepickerConfig.relativeDateRange)
                    }
                    maxDate={
                      datepickerConfig.dateRangeType === DATE_RANGE_TYPES.EXACT
                        ? datepickerConfig.maxValue
                        : getDateMax(datepickerConfig.relativeDateRange)
                    }
                    selectedValue={typeof value === 'string' ? parseISO(value) : (value as Date)}
                    onNewValueSelect={(value) => onNewValueSelect(element.name, value as Date)}
                    showCancelBtn={!datepickerConfig.disableCancel}
                    showTimeSelect={!datepickerConfig.hideTimeSelect}
                    onCancelClick={() => onNewValueSelect(element.name, undefined)}
                  />
                </div>
              );
            }
            case DASHBOARD_ELEMENT_TYPES.DROPDOWN: {
              const dropdownConfig = element.config as DropdownDashboardElemConfig;
              const dropdownOptions = constructOptions(dropdownConfig, dashboardDatasets);
              const selectedDropdownOption = _.find(
                dropdownOptions,
                (option: any) => option.value === value,
              );

              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>
                  <DropdownSelect
                    fillWidth
                    filterable
                    minimal
                    containerClassName={classes.variableInput}
                    selectedItem={selectedDropdownOption}
                    onChange={(item) => onNewValueSelect(element.name, item.value)}
                    options={dropdownOptions}
                    noSelectionText={dropdownConfig.placeholderText || ''}
                    showCancelBtn={!dropdownConfig.disableCancel}
                    onCancelClick={() => onNewValueSelect(element.name, undefined)}
                  />
                </div>
              );
            }
            case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN: {
              const dropdownConfig = element.config as TimePeriodDropdownDashboardElemConfig;
              const dropdownOptions = dropdownConfig.values;
              const selectedDropdownOption = _.find(
                dropdownOptions,
                (option: any) => option.value === value,
              );

              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>
                  <DropdownSelect
                    fillWidth
                    filterable
                    minimal
                    containerClassName={classes.variableInput}
                    noSelectionText={dropdownConfig.placeholderText || ''}
                    onCancelClick={() => onNewValueSelect(element.name, undefined)}
                    onChange={(item) => onNewValueSelect(element.name, item.value)}
                    options={dropdownOptions}
                    selectedItem={selectedDropdownOption}
                    showCancelBtn={dropdownConfig.enableCancel}
                  />
                </div>
              );
            }
            case DASHBOARD_ELEMENT_TYPES.MULTISELECT: {
              const multiselectConfig = element.config as DropdownDashboardElemConfig;
              const multiselectOptions = constructOptions(multiselectConfig, dashboardDatasets);
              const selectedMultiselectOption = _.filter(
                multiselectOptions,
                (option: any) => !!value && (value as any).indexOf(option.value) > -1,
              );

              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>
                  <ExploMultiSelect
                    minimal
                    fillWidth
                    filterable
                    containerClassName={classes.variableInput}
                    placeholder={multiselectConfig.placeholderText}
                    selectedItems={selectedMultiselectOption}
                    onItemSelected={(item) =>
                      onNewValueSelect(element.name, onItemSelected(item, value))
                    }
                    unselectIndex={(index) =>
                      onNewValueSelect(element.name, onIndexUnselected(index, value))
                    }
                    options={multiselectOptions}
                    noSelectionText={multiselectConfig.placeholderText || ''}
                  />
                </div>
              );
            }
            case DASHBOARD_ELEMENT_TYPES.SWITCH: {
              const switchConfig = element.config as DropdownDashboardElemConfig;
              const switchOptions = constructOptions(switchConfig, dashboardDatasets).slice(0, 5);
              const selectedSwitchOption = _.find(
                switchOptions,
                (option: any) => option.value === value,
              );

              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>

                  <DropdownSelect
                    fillWidth
                    filterable
                    minimal
                    showCancelBtn
                    containerClassName={classes.variableInput}
                    selectedItem={selectedSwitchOption}
                    onChange={(item) => onNewValueSelect(element.name, item.value)}
                    options={switchOptions}
                    noSelectionText={switchConfig.placeholderText || ''}
                    onCancelClick={() => onNewValueSelect(element.name, undefined)}
                  />
                </div>
              );
            }

            case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH: {
              const dateSwitchConfig = element.config as DateGroupSwitchConfig;
              const dateSwitchOptions = getDateGroupSwitchOptions(dateSwitchConfig);
              const selectedDateSwitchOption = _.find(
                dateSwitchOptions,
                (option: any) => option.id === value,
              );
              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>

                  <DropdownSelect
                    fillWidth
                    filterable
                    minimal
                    containerClassName={classes.variableInput}
                    selectedItem={selectedDateSwitchOption}
                    onChange={(item) => onNewValueSelect(element.name, item.id)}
                    options={dateSwitchOptions}
                    noSelectionText={''}
                  />
                </div>
              );
            }
            case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER: {
              const daterangepickerConfig = element.config as DatepickerElemConfig;

              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>
                  <DashboardDateRangePickerElement
                    noLabel
                    withPortal
                    noDropdown
                    className={classes.variableInput}
                    config={daterangepickerConfig}
                    value={value}
                    onNewValueSelect={(newValue) => onNewValueSelect(element.name, newValue)}
                  />
                </div>
              );
            }
          }

          return undefined;
        })}
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>Custom Variables</div>

        {Object.values(dashboardParams).map((param) => {
          const inputType = DATE_TYPES.has(param.type) ? InputType.DATETIME : InputType.INPUT;
          return (
            <div className={classes.variable} key={`custom-variable-preview-${param.name}`}>
              <div className={classes.variableName}>{param.name}</div>
              <DashboardVariableEntry
                className={classes.variableInput}
                key={`custom-variables-entry-${param.name}`}
                name={param.name}
                inputType={inputType}
                value={variables[param.name]}
                onValueChange={(newValue) => {
                  onNewValueSelect(param.name, newValue);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
