/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Intent } from '@blueprintjs/core';

import TextFieldModal from 'components/modals/textFieldModal';
import LoadingBody from 'components/loadingBody';
import PageHeader from 'components/pageHeader';
import EmptyState from 'components/EmptyPageActionCallout';
import PageListItem from 'components/pageListItem';
import EmbedModal from 'pages/homeAppPage/embedModal';
import ConfirmationModal from 'components/modals/confirmationModal';
import SetupEmailModal from 'pages/homeAppPage/setupEmailModal';
import { EmailCadenceTime } from 'constants/types';
import { AppToaster } from 'toaster';

import {
  createDashboardTemplate,
  fetchDashboardTemplateList,
  deleteDashboardTemplate,
  renameDashboardTemplate,
  cloneDashboardTemplate,
} from 'actions/dashboardAction';
import {
  createDashboardEmail,
  fetchEmailCadenceList,
  editDashboardEmail,
  deleteDashboardEmail,
  sendTestEmail,
} from 'actions/emailActions';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION, DashboardTemplate, EmailCadence } from 'actions/types';
import { NAVBAR_HEIGHT } from 'components/pages/navbar';

import { pageView, trackEvent } from 'analytics/exploAnalytics';
import { getMilitaryTimeHour } from 'utils/emailUtils';
import Tag from 'shared/Tag';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.spacing(5)}px ${theme.spacing(6)}px`,
      overflowY: 'auto' as 'auto',
    },
    dashboardTemplateListContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      marginTop: theme.spacing(6),
    },
    dashboardTemplateListItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderRadius: 8,
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.ds.white,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    deleteButton: {
      marginTop: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.ds.grey500}`,
      paddingTop: theme.spacing(2),
    },
  });

type MatchParams = {};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  createDashboardModalOpen: boolean;
  setupEmailModalOpen: boolean;
  embedModalOpen: boolean;
  selectedDashboard?: DashboardTemplate;
  embedDashboardTemplate?: DashboardTemplate;
  deleteDashboardConfirmModalOpen: boolean;
  renameModalOpen: boolean;
  renameDashboardTemplateObj?: DashboardTemplate;
  actionLoadingDashboardTemplateId?: number;
};

class HomeAppPage extends React.Component<Props, State> {
  state: State = {
    createDashboardModalOpen: false,
    setupEmailModalOpen: false,
    selectedDashboard: undefined,
    embedModalOpen: false,
    deleteDashboardConfirmModalOpen: false,
    renameModalOpen: false,
  };

  componentDidMount = () => {
    const {
      dashboardTemplateList,
      fetchDashboardTemplateList,
      fetchEmailCadenceList,
      currentUser,
    } = this.props;
    document.title = 'Explo | Home';

    if (!dashboardTemplateList && currentUser.team) {
      fetchDashboardTemplateList({ id: currentUser.team.id });
      fetchEmailCadenceList({ id: currentUser.team.id });
    }
    pageView('Home App (Dashboards)');
  };

  render() {
    const { classes, createDashboardTemplateLoading, dashboardTemplateListLoading } = this.props;

    if (createDashboardTemplateLoading || dashboardTemplateListLoading) return <LoadingBody />;
    return (
      <div className={classes.root}>
        {this.renderHeader()}
        {this.renderEmptyAppPage()}
        {this.renderDashboardTemplateList()}
        {this.renderCreateDashboardModal()}
        {this.renderEmbedModal()}
        {this.renderDeleteDashboardConfirmModal()}
        {this.renderRenameDashboardModal()}
        {this.renderSetupEmailModal()}
      </div>
    );
  }

  renderHeader = () => {
    return (
      <PageHeader
        title="Dashboards"
        primaryActionButtonText="Create Dashboard"
        onPrimaryActionButtonClick={() => this.setState({ createDashboardModalOpen: true })}
      />
    );
  };

  renderEmptyAppPage = () => {
    const { dashboardTemplateList } = this.props;

    if (dashboardTemplateList?.length !== 0) return;

    return <EmptyState text="Get started by creating your first dashboard" />;
  };

  renderDashboardTemplateList = () => {
    const { dashboardTemplateList, classes } = this.props;

    if (!dashboardTemplateList) return;

    return (
      <div className={classes.dashboardTemplateListContainer}>
        {dashboardTemplateList.map(this.renderDashboardTemplateItem)}
      </div>
    );
  };

  renderDashboardTemplateItem = (dashboardTemplate: DashboardTemplate) => {
    const { history, cloneDashboardTemplate, classes, emailCadenceList } = this.props;
    const { actionLoadingDashboardTemplateId } = this.state;
    const emailCadence = emailCadenceList?.find(
      (cadence: EmailCadence) => cadence.dashboard_template_id === dashboardTemplate.id,
    );

    return (
      <PageListItem
        title={dashboardTemplate.name}
        key={`dashboard-template-${dashboardTemplate.id}`}
        onClick={() => history.push(`/dashboard/${dashboardTemplate.id}`)}
        loading={actionLoadingDashboardTemplateId === dashboardTemplate.id}
        tag={emailCadence ? 'Email' : undefined}
        itemActions={[
          {
            text: 'Embed',
            onClick: () => {
              this.setState({ embedModalOpen: true, embedDashboardTemplate: dashboardTemplate });
              trackEvent('Opened embed dashboard modal', {
                dashboard_template_id: dashboardTemplate.id,
              });
            },
            icon: 'insert',
          },
          {
            text: 'Rename',
            onClick: () => {
              this.setState({
                renameModalOpen: true,
                renameDashboardTemplateObj: dashboardTemplate,
              });
              trackEvent('Rename dashboard modal', {
                dashboard_template_id: dashboardTemplate.id,
              });
            },
            icon: 'edit',
          },
          {
            text: 'Duplicate',
            onClick: () => {
              this.setState({
                actionLoadingDashboardTemplateId: undefined,
              });
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              cloneDashboardTemplate({ id: dashboardTemplate.id }, (data: any) => {
                trackEvent('Created dashboard', {
                  dashboard_template_id: data.new_dashboard_template.id,
                  dashboard_name: `${dashboardTemplate} Copy 1`,
                });
                this.setState({
                  actionLoadingDashboardTemplateId: undefined,
                });
                history.push(`/dashboard/${data.new_dashboard_template.id}`);
              });
            },
            icon: 'duplicate',
          },
          {
            text: emailCadence ? 'Edit Email' : 'Set Up Email',
            onClick: () => {
              this.setState({
                selectedDashboard: dashboardTemplate,
                setupEmailModalOpen: true,
              });
            },
            icon: 'envelope',
            labelElement: <Tag intent={Intent.PRIMARY} text={'Beta'} />,
          },
          {
            text: 'Delete',
            onClick: () => {
              this.setState({
                selectedDashboard: dashboardTemplate,
                deleteDashboardConfirmModalOpen: true,
              });
            },
            icon: 'trash',
            className: classes.deleteButton,
            intent: Intent.DANGER,
          },
        ]}
      />
    );
  };

  renderCreateDashboardModal = () => {
    const { createDashboardModalOpen } = this.state;
    const { createDashboardTemplate, currentUser, history } = this.props;
    const team = currentUser.team;

    if (!team) return;

    return (
      <TextFieldModal
        modalOpen={createDashboardModalOpen}
        closeModal={() => this.setState({ createDashboardModalOpen: false })}
        modalTitle="Create a dashboard."
        buttonName="Create dashboard"
        textFieldPlaceholder="Enter Dashboard Name"
        onSubmit={(name: string) =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          createDashboardTemplate({ id: team.id, postData: { name } }, (data: any) => {
            trackEvent('Created dashboard', {
              dashboard_template_id: data.new_dashboard_template.id,
              dashboard_name: name,
            });
            history.push(`/dashboard/${data.new_dashboard_template.id}#edit`);
          })
        }
      />
    );
  };

  renderSetupEmailModal = () => {
    const { setupEmailModalOpen, selectedDashboard } = this.state;
    const {
      createDashboardEmail,
      emailCadenceList,
      editDashboardEmail,
      deleteDashboardEmail,
      sendTestEmail,
    } = this.props;
    const emailCadence = emailCadenceList?.find(
      (cadence) => cadence.dashboard_template_id === selectedDashboard?.id,
    );

    if (!setupEmailModalOpen || !selectedDashboard) return;

    return (
      <SetupEmailModal
        modalOpen={setupEmailModalOpen}
        closeModal={() => this.setState({ setupEmailModalOpen: false })}
        modalTitle={`Email Configuration for ${selectedDashboard.name}`}
        emailCadence={emailCadence}
        onDelete={
          emailCadence
            ? () => {
                this.setState({ setupEmailModalOpen: false });
                deleteDashboardEmail({
                  id: emailCadence.dashboard_template_id,
                });
              }
            : undefined
        }
        onSendTestEmail={(fromEmail: string, subject: string) => {
          sendTestEmail(
            {
              postData: {
                dashboard_template_id: selectedDashboard.id,
                email: fromEmail,
                subject: subject,
              },
            },
            () =>
              AppToaster.show({
                message: `An email has been sent to ${fromEmail}`,
                icon: 'endorsed',
                timeout: 5000,
                intent: Intent.SUCCESS,
              }),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (response: any) =>
              AppToaster.show({
                message:
                  response?.error_msg ||
                  'Something went wrong. Please try again or contact support@explo.co if the error continues.',
                icon: 'error',
                timeout: 5000,
                intent: Intent.DANGER,
              }),
          );
        }}
        onSubmit={(
          fromEmail: string,
          subject: string,
          time: EmailCadenceTime,
          dayOfWeek?: number,
          weekOfMonth?: number,
        ) => {
          const hour = getMilitaryTimeHour(time);
          const postData = {
            dashboard_template_id: selectedDashboard.id,
            from_email: fromEmail,
            subject: subject,
            hour: hour,
            minute: time.minute,
            day_of_week: dayOfWeek,
            week_of_month: weekOfMonth,
            timezone: time.timezone,
          };
          emailCadence
            ? editDashboardEmail(
                {
                  postData,
                },
                () => {
                  this.setState({ setupEmailModalOpen: false });
                  AppToaster.show({
                    message: 'Email Edited Successfully.',
                    icon: 'endorsed',
                    timeout: 3000,
                    intent: Intent.SUCCESS,
                  });
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (response: any) =>
                  AppToaster.show({
                    message:
                      response?.error_msg ||
                      'Something went wrong. Please try again or contact support@explo.co if the error continues.',
                    icon: 'error',
                    timeout: 3000,
                    intent: Intent.DANGER,
                  }),
              )
            : createDashboardEmail(
                {
                  postData,
                },
                () => {
                  this.setState({ setupEmailModalOpen: false });
                  AppToaster.show({
                    message: 'Email Configured Successfully.',
                    icon: 'endorsed',
                    timeout: 3000,
                    intent: Intent.SUCCESS,
                  });
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (response: any) =>
                  AppToaster.show({
                    message:
                      response?.error_msg ||
                      'Something went wrong. Please try again or contact support@explo.co if the error continues.',
                    icon: 'error',
                    timeout: 3000,
                    intent: Intent.DANGER,
                  }),
              );
        }}
      />
    );
  };

  renderEmbedModal = () => {
    const { embedModalOpen, embedDashboardTemplate } = this.state;

    if (!embedModalOpen || !embedDashboardTemplate) return;

    return (
      <EmbedModal
        modalOpen={embedModalOpen}
        closeModal={() => this.setState({ embedModalOpen: false })}
        dashboardEmbedId={embedDashboardTemplate.embed_id}
        dashboardName={embedDashboardTemplate.name}
      />
    );
  };

  renderDeleteDashboardConfirmModal = () => {
    const { deleteDashboardTemplate } = this.props;
    const { deleteDashboardConfirmModalOpen, selectedDashboard } = this.state;

    if (!deleteDashboardConfirmModalOpen) return;

    return (
      <ConfirmationModal
        isDestructive
        modalOpen={deleteDashboardConfirmModalOpen}
        closeModal={() => this.setState({ deleteDashboardConfirmModalOpen: false })}
        modalTitle="Are you sure you want to delete this dashboard?"
        cancelBtnText="Cancel"
        confirmBtnText="Delete for all users"
        onConfirm={() => {
          this.setState({
            actionLoadingDashboardTemplateId: selectedDashboard?.id,
            deleteDashboardConfirmModalOpen: false,
          });
          deleteDashboardTemplate({ id: selectedDashboard?.id }, () => {
            this.setState({
              actionLoadingDashboardTemplateId: undefined,
              selectedDashboard: undefined,
            });
            trackEvent('Deleted dashboard', {
              dashboard_template_id: selectedDashboard?.id,
            });
          });
        }}
      />
    );
  };

  renderRenameDashboardModal = () => {
    const { renameDashboardTemplate } = this.props;
    const { renameModalOpen, renameDashboardTemplateObj } = this.state;
    if (!renameModalOpen || !renameDashboardTemplateObj) return;

    return (
      <TextFieldModal
        resourceName={renameDashboardTemplateObj.name}
        modalOpen={renameModalOpen}
        closeModal={() => this.setState({ renameModalOpen: false })}
        modalTitle="Rename Dashboard"
        buttonName="Save"
        textFieldPlaceholder="Dashboard name"
        onSubmit={(name: string) => {
          this.setState({
            actionLoadingDashboardTemplateId: renameDashboardTemplateObj.id,
            renameModalOpen: false,
          });
          renameDashboardTemplate({ id: renameDashboardTemplateObj.id, postData: { name } }, () => {
            this.setState({
              actionLoadingDashboardTemplateId: undefined,
              renameDashboardTemplateObj: undefined,
            });
            trackEvent('Renamed dashboard template name', {
              dashboard_template_id: renameDashboardTemplateObj.id,
              dashboard_template_name: name,
            });
          });
        }}
      />
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
  createDashboardTemplateLoading: createLoadingSelector(
    [ACTION.CREATE_DASHBOARD_TEMPLATE, ACTION.CLONE_DASHBOARD_TEMPLATE],
    false,
  )(state),
  dashboardTemplateListLoading: createLoadingSelector([ACTION.FETCH_DASHBOARD_TEMPLATE_LIST])(
    state,
  ),
  dashboardTemplateList: state.dashboard.dashboardTemplateList,
  emailCadenceList: state.emailCadence.emailCadenceList,
});

const mapDispatchToProps = {
  createDashboardTemplate,
  deleteDashboardTemplate,
  fetchDashboardTemplateList,
  renameDashboardTemplate,
  cloneDashboardTemplate,
  createDashboardEmail,
  fetchEmailCadenceList,
  editDashboardEmail,
  deleteDashboardEmail,
  sendTestEmail,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HomeAppPage)),
);
