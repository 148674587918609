/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';

import SwitchInput from 'pages/dataPanelEditorPage/switchInput';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import { VisualizeOperationGeneralFormatOptions } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export type Props = {
  generalFormatOptions: VisualizeOperationGeneralFormatOptions;
  noIconPresent?: boolean;
};

export default function NoDataConfig({ generalFormatOptions, noIconPresent }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      {!noIconPresent && (
        <SwitchInput
          className={classes.configInput}
          switchOn={generalFormatOptions?.noDataState?.hideChartIcon}
          onChange={() => {
            const newFormat = cloneDeep(generalFormatOptions);
            newFormat.noDataState = {
              ...newFormat?.noDataState,
              hideChartIcon: !newFormat?.noDataState?.hideChartIcon,
            };

            dispatch(updateGeneralFormatOptions(newFormat));
          }}
          label="Hide Chart Icon"
        />
      )}
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={generalFormatOptions.noDataState?.noDataText || 'No data'}
        onNewValueSubmitted={(newValue: string) => {
          const newFormat = cloneDeep(generalFormatOptions);
          newFormat.noDataState = {
            ...newFormat?.noDataState,
            noDataText: newValue,
          };

          dispatch(updateGeneralFormatOptions(newFormat));
        }}
        label="No Data Text"
      />
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={String(generalFormatOptions.noDataState?.noDataFontSize || 36)}
        onNewValueSubmitted={(newValue: string) => {
          const intValue = parseInt(newValue);
          const newFormat = cloneDeep(generalFormatOptions);
          if (intValue === 0 || intValue) {
            newFormat.noDataState = {
              ...newFormat.noDataState,
              noDataFontSize: intValue,
            };

            dispatch(updateGeneralFormatOptions(newFormat));
          }
        }}
        label="Text Size"
      />
    </div>
  );
}
