/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Icon } from '@blueprintjs/core';

import Button from 'shared/Button';
import FilterRow from './FilterRow';
import { TableColumn } from 'actions/types';

import { FilterClause, FilterOperatorType, FilterValueType, Schema } from 'constants/types';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  addFilterButton: {
    boxShadow: 'none !important',
    height: 32,

    '& .bp3-spinner .bp3-spinner-track': {
      stroke: theme.palette.ds.grey100,
    },
  },
  addFilterText: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.ds.blue,
  },
  noFilterText: {
    fontSize: 12,
    color: theme.palette.ds.black,
  },
}));

type Props = {
  filterConfigs: FilterClause[];
  onColumnSelect: (column: TableColumn, idx: number) => void;
  onOperatorSelect: (operator: FilterOperatorType, idx: number) => void;
  onFilterValueUpdate: (value: FilterValueType, idx: number) => void;
  addFilter: () => void;
  deleteFilter: (idx: number) => void;
  schema: Schema;
};

export default function FilterDropdownMenu({
  filterConfigs,
  onColumnSelect,
  onOperatorSelect,
  onFilterValueUpdate,
  addFilter,
  deleteFilter,
  schema,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {filterConfigs.length === 0 ? (
        <div className={classes.noFilterText}>No filters applied</div>
      ) : (
        filterConfigs.map((currentFilterConfig, idx) => (
          <FilterRow
            key={idx}
            adHocFilterSettings
            baseSchema={schema}
            selectedColumn={currentFilterConfig.filterColumn}
            selectedOperator={currentFilterConfig.filterOperation}
            filterValue={currentFilterConfig.filterValue}
            onColumnSelect={(column: TableColumn) => onColumnSelect(column, idx)}
            onOperatorSelect={(operator: FilterOperatorType) => onOperatorSelect(operator, idx)}
            onFilterValueUpdate={(value: FilterValueType) => onFilterValueUpdate(value, idx)}
            onDelete={() => deleteFilter(idx)}
          />
        ))
      )}
      <Button
        minimal
        icon={
          <Icon
            className={GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.color}
            icon="plus"
            iconSize={10}
          />
        }
        onClick={addFilter}
        className={classes.addFilterButton}
        text={
          <div
            className={cx(
              classes.addFilterText,
              GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.color,
            )}>
            Add filter
          </div>
        }
      />
    </div>
  );
}
