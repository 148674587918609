/** @format */

import React, { useContext } from 'react';
import { Icon } from '@blueprintjs/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import Button from 'shared/Button';
import { GlobalStylesContext } from 'globalStyles';
import { EXPORT_SVG_ICON } from 'constants/iconConstants';
import DownloadPopover from 'shared/DownloadPopover';

const useStyles = makeStyles((theme: Theme) => ({
  csvDownloadPopoverText: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px `,
    fontSize: theme.typography.caption.fontSize,
  },
  popoverContainer: {
    '& .bp3-popover.bp3-minimal': {
      margin: `${theme.spacing(1)}px 0px 0px !important`,
    },
    '& .bp3-popover': {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.ds.grey700}`,
    },
  },
  downloadCsvSelectorButton: {
    boxShadow: 'none !important',
    marginLeft: theme.spacing(3),

    '& .bp3-spinner .bp3-spinner-track': {
      stroke: theme.palette.ds.grey100,
    },
  },
}));

export type Props = {
  clearDownloadCsvInfo?: () => void;
  downloadCsvLoading?: boolean;
  downloadDataPanelCsvErrored?: boolean;
  downloadDataPanelCsvLink?: string;
  onDownloadPanelCsv?: () => void;
  downloadDatapanelCsvPopupBlocked?: boolean;
  error?: boolean;
  loading?: boolean;
};

const getOnDownloadCsvPopoverClose = (clearDownloadCsvInfo: Props['clearDownloadCsvInfo']) => (
  event?: React.SyntheticEvent<HTMLElement>,
) => {
  if (event && event.type === 'mousedown') {
    clearDownloadCsvInfo && clearDownloadCsvInfo();
  }
};

export default function DownloadCsvButton({
  clearDownloadCsvInfo,
  downloadCsvLoading,
  downloadDataPanelCsvErrored,
  downloadDataPanelCsvLink,
  downloadDatapanelCsvPopupBlocked,
  onDownloadPanelCsv,
  error,
  loading,
}: Props) {
  const { globalStyleConfig } = useContext(GlobalStylesContext);
  const classes = useStyles();

  const button = (
    <Button
      minimal
      loading={downloadCsvLoading}
      disabled={loading || error}
      onClick={() => onDownloadPanelCsv && onDownloadPanelCsv()}
      icon={<Icon icon={EXPORT_SVG_ICON(globalStyleConfig.text.secondaryColor)} iconSize={14} />}
      className={classes.downloadCsvSelectorButton}
    />
  );

  // We only render the DownloadPopover if it's open, otherwise when we click the icon button,
  // it will continue to display as active when loading (dark background color) due to living inside a popover
  if (
    (downloadDataPanelCsvLink && downloadDatapanelCsvPopupBlocked) ||
    downloadDataPanelCsvErrored
  ) {
    return (
      <DownloadPopover
        isError={downloadDataPanelCsvErrored}
        link={downloadDataPanelCsvLink}
        isOpen={downloadDataPanelCsvErrored || downloadDatapanelCsvPopupBlocked}
        onClose={getOnDownloadCsvPopoverClose(clearDownloadCsvInfo)}>
        {button}
      </DownloadPopover>
    );
  }

  return button;
}
