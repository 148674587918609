/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';
import DropdownSelect from 'shared/DropdownSelect';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions, SortOption } from 'constants/types';
import { DATE_TYPES, STRING, STRING_FORMATS } from 'constants/dataConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2TwoDimensionChartInstructions;
  barValuesConfigurable?: boolean;
  enableSorting?: boolean;
};

export default function XAxisConfig({
  visualizationType,
  instructions,
  barValuesConfigurable,
  enableSorting,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedSort = instructions.xAxisFormat?.sortOption || SortOption.DEFAULT;
  const selectedStringFormat =
    instructions.xAxisFormat?.stringFormat?.format || STRING_FORMATS.DEFAULT;
  const totalValueConfigurable =
    barValuesConfigurable &&
    visualizationType !== OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2 &&
    visualizationType !== OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2 &&
    visualizationType !== OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2;

  const xAxisColType =
    // @ts-ignore - if box plot, check for grouping column
    instructions.categoryColumn?.column.type || instructions.groupingColumn?.column.type || '';

  return (
    <div className={classes.root}>
      {enableSorting && (
        <DropdownSelect
          containerClassName={classes.configInput}
          selectedItem={{ id: selectedSort, name: selectedSort }}
          onChange={(item) => {
            const newInstructions = cloneDeep(instructions);

            newInstructions.xAxisFormat = {
              ...newInstructions.xAxisFormat,
              sortOption: item.id as SortOption,
            };
            dispatch(
              updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
            );
          }}
          filterable={false}
          options={Object.values(SortOption).map((sortOption) => ({
            id: sortOption,
            name: sortOption,
          }))}
          noSelectionText="Select Sort"
          minimal
          btnMinimal
          fillWidth
          label="Sort Configuration"
        />
      )}
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={instructions.xAxisFormat?.title}
        onNewValueSubmitted={(newValue: string) => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.xAxisFormat = {
            ...newInstructions.xAxisFormat,
            title: newValue,
            showTitle: true,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Title"
      />
      <SwitchInput
        className={classes.configInput}
        switchOn={instructions.xAxisFormat?.showTitle}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.xAxisFormat = {
            ...newInstructions.xAxisFormat,
            showTitle: !newInstructions.xAxisFormat?.showTitle,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Show Title"
      />{' '}
      {DATE_TYPES.has(xAxisColType) && (
        <InputWithBlurSave
          containerClassName={classes.configInput}
          initialValue={instructions.xAxisFormat?.dateFormat}
          onNewValueSubmitted={(newValue: string) => {
            const newInstructions = cloneDeep(instructions);

            newInstructions.xAxisFormat = {
              ...newInstructions.xAxisFormat,
              dateFormat: newValue,
            };
            dispatch(
              updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
            );
          }}
          label="Date Format"
        />
      )}
      {xAxisColType === STRING && (
        <DropdownSelect
          minimal
          btnMinimal
          fillWidth
          showCancelBtn={false}
          containerClassName={classes.configInput}
          selectedItem={{ id: selectedStringFormat, name: selectedStringFormat }}
          onChange={(item) => {
            const newInstructions = cloneDeep(instructions);

            newInstructions.xAxisFormat = {
              ...newInstructions.xAxisFormat,
              stringFormat: {
                ...newInstructions.xAxisFormat?.stringFormat,
                format: item.id as STRING_FORMATS,
              },
            };
            dispatch(
              updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
            );
          }}
          filterable={false}
          options={Object.values(STRING_FORMATS).map((formatOption) => ({
            id: formatOption,
            name: formatOption,
          }))}
          noSelectionText="Select a format"
          label="String Format"
        />
      )}
      {xAxisColType === STRING && (
        <SwitchInput
          className={classes.configInput}
          switchOn={instructions.xAxisFormat?.stringFormat?.replaceUnderscores}
          onChange={() => {
            const newInstructions = cloneDeep(instructions);

            newInstructions.xAxisFormat = {
              ...newInstructions.xAxisFormat,
              stringFormat: {
                ...newInstructions.xAxisFormat?.stringFormat,
                replaceUnderscores: !newInstructions.xAxisFormat?.stringFormat?.replaceUnderscores,
              },
            };
            dispatch(
              updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
            );
          }}
          label="Remove underscores"
        />
      )}
      <SwitchInput
        className={classes.configInput}
        switchOn={instructions.xAxisFormat?.hideAxisTicks}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.xAxisFormat = {
            ...newInstructions.xAxisFormat,
            hideAxisTicks: !instructions.xAxisFormat?.hideAxisTicks,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Hide Axis Ticks"
      />
      {totalValueConfigurable && (
        <SwitchInput
          className={classes.configInput}
          switchOn={!instructions.xAxisFormat?.hideTotalValues}
          onChange={() => {
            const newInstructions = cloneDeep(instructions);

            newInstructions.xAxisFormat = {
              ...newInstructions.xAxisFormat,
              hideTotalValues: !instructions.xAxisFormat?.hideTotalValues,
            };
            dispatch(
              updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
            );
          }}
          label="Show Total Values"
        />
      )}
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={String(instructions.xAxisFormat?.maxCategories ?? '')}
        onNewValueSubmitted={(newValue: string) => {
          const newInstructions = cloneDeep(instructions);
          const intValue = parseInt(newValue || '0');
          if (intValue === 0 || intValue) {
            newInstructions.xAxisFormat = {
              ...newInstructions.xAxisFormat,
              maxCategories: intValue,
            };
            dispatch(
              updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
            );
          }
        }}
        label="Max Categories"
      />
      {barValuesConfigurable && (
        <SwitchInput
          className={classes.configInput}
          switchOn={instructions.xAxisFormat?.showBarValues}
          onChange={() => {
            const newInstructions = cloneDeep(instructions);

            newInstructions.xAxisFormat = {
              ...newInstructions.xAxisFormat,
              showBarValues: !instructions.xAxisFormat?.showBarValues,
            };
            dispatch(
              updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
            );
          }}
          label="Show Bar Values"
        />
      )}
      {barValuesConfigurable && (
        <InputWithBlurSave
          hideRightIconInteractions
          containerClassName={classes.configInput}
          initialValue={String(instructions.xAxisFormat?.barCornerRadius || 0)}
          onNewValueSubmitted={(newValue: string) => {
            const newInstructions = cloneDeep(instructions);
            const intValue = parseInt(newValue);
            if (intValue === 0 || intValue) {
              newInstructions.xAxisFormat = {
                ...newInstructions.xAxisFormat,
                barCornerRadius: intValue,
              };
              dispatch(
                updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES),
              );
            }
          }}
          label="Bar Corner Radius"
        />
      )}
    </div>
  );
}
