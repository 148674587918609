/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Theme, WithStyles } from '@material-ui/core/index';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(4),
    },
    compact: {
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: 16,
      fontWeight: 600,
    },
    subTitle: {
      fontSize: 14,
      fontWeight: 500,
    },
  });

type PassedProps = {
  title: string;
  rightElem?: React.ReactNode;
  className?: string;
  isSubTitle?: boolean;
  compact?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles> & RouteComponentProps;

type State = {};

class ConfigSectionHeader extends React.Component<Props, State> {
  render() {
    const { classes, title, rightElem, className, isSubTitle, compact } = this.props;

    return (
      <div className={cx(classes.root, { [classes.compact]: compact }, className)}>
        <div className={isSubTitle ? classes.subTitle : classes.title}>{title}</div>
        <div>{rightElem}</div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ConfigSectionHeader));
