/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { parseISO } from 'date-fns';
import { dayjs } from 'utils/localizationUtils';

import DatePickerInput from 'shared/DatePickerInput';
import { DatepickerElemConfig, RELATIVE_DATE_RANGES, DATE_RANGE_TYPES } from 'types/dashboardTypes';

const styles = (theme: Theme) => ({
  root: {},
  datePicker: {
    padding: '0 10PX',
    borderWidth: 0,
    borderRadius: 3,
    fontSize: 14,
    height: 30,
    boxShadow: `inset 0 0 0 1px ${theme.palette.ds.grey300} !important`,
    width: '100%',
  },
});

type PassedProps = {
  config: DatepickerElemConfig;
  value?: any;
  onNewValueSelect: (newvalue: any) => void;
  disabled?: boolean;
  portalId?: string;
};

type Props = PassedProps & WithStyles<typeof styles>;

export const getDateMin = (range?: RELATIVE_DATE_RANGES) => {
  switch (range) {
    case RELATIVE_DATE_RANGES.THIS_WEEK:
      return dayjs().startOf('week').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.THIS_MONTH:
      return dayjs().startOf('month').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.THIS_YEAR:
      return dayjs().startOf('year').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.WITHIN_A_WEEK:
      return dayjs().subtract(7, 'days').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.WITHIN_A_MONTH:
      return dayjs().subtract(30, 'days').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.WITHIN_A_YEAR:
      return dayjs().subtract(365, 'days').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.WITHIN_THREE_YEARS:
      return dayjs().subtract(3, 'years').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.WITHIN_FIVE_YEARS:
      return dayjs().subtract(5, 'years').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.WITHIN_TEN_YEARS:
      return dayjs().subtract(10, 'years').startOf('day').toDate();
    default:
      return undefined;
  }
};

export const getDateMax = (range?: RELATIVE_DATE_RANGES) => {
  switch (range) {
    case RELATIVE_DATE_RANGES.THIS_WEEK:
      return dayjs().endOf('week').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.THIS_MONTH:
      return dayjs().endOf('month').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.THIS_YEAR:
      return dayjs().endOf('year').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.WITHIN_A_WEEK:
      return dayjs().add(7, 'days').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.WITHIN_A_MONTH:
      return dayjs().add(30, 'days').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.WITHIN_A_YEAR:
      return dayjs().add(365, 'days').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.WITHIN_THREE_YEARS:
      return dayjs().add(3, 'years').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.WITHIN_FIVE_YEARS:
      return dayjs().add(5, 'years').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.WITHIN_TEN_YEARS:
      return dayjs().add(10, 'years').startOf('day').toDate();
    case RELATIVE_DATE_RANGES.PAST_DATES:
      return dayjs().toDate();
    default:
      return undefined;
  }
};

class DashboardDatepickerElement extends React.Component<Props> {
  render() {
    const { classes, config, value, onNewValueSelect, disabled, portalId } = this.props;

    return (
      <div className={classes.root}>
        <DatePickerInput
          disabled={disabled}
          minDate={
            config.dateRangeType === DATE_RANGE_TYPES.EXACT
              ? config.minValue
              : getDateMin(config.relativeDateRange)
          }
          maxDate={
            config.dateRangeType === DATE_RANGE_TYPES.EXACT
              ? config.maxValue
              : getDateMax(config.relativeDateRange)
          }
          selectedValue={typeof value === 'string' ? parseISO(value) : value}
          label={config.label}
          useFakeLabel={!config.label}
          onNewValueSelect={onNewValueSelect}
          showCancelBtn={!config.disableCancel}
          showTimeSelect={!config.hideTimeSelect}
          onCancelClick={() => onNewValueSelect(undefined)}
          portalId={portalId}
        />
      </div>
    );
  }
}

export default withStyles(styles)(DashboardDatepickerElement);
