/** @format */
import { defineAPIAction } from 'actions/actionUtils';
import { FetchDatasetsData, FetchTeamDataSourcesData } from 'actions/responseTypes';
import { ACTION } from 'actions/types';

export const { actionFn: fetchSupportedDataSources } = defineAPIAction(
  'FETCH_SUPPORTED_DATA_SOURCES',
  'datasources',
  'supported_data_sources',
  'GET',
);

export const { actionFn: testDataSourceConnection } = defineAPIAction(
  'TEST_DATA_SOURCE_CONNECTION',
  'datasources',
  'test_connection',
  'POST',
);

export const { actionFn: connectDataSource } = defineAPIAction(
  'CONNECT_DATA_SOURCE',
  'datasources',
  'connect',
  'POST',
);

export const { actionFn: fetchAllDataSourceTables } = defineAPIAction(
  ACTION.FETCH_ALL_DATA_SOURCE_TABLES,
  'datasources',
  'all_tables',
  'GET',
);

export const { actionFn: syncSourceTables } = defineAPIAction(
  ACTION.SYNC_SOURCE_TABLES,
  'datasources',
  'sync_source_tables',
  'POST',
);

export const { actionFn: fetchDatasets } = defineAPIAction<FetchDatasetsData>(
  'FETCH_DATASETS',
  'schema',
  'tables',
  'GET',
);

export const { actionFn: listTeamDataSources } = defineAPIAction<FetchTeamDataSourcesData>(
  ACTION.LIST_TEAM_DATA_SOURCES,
  'datasources',
  '',
  'GET',
);

export const { actionFn: renameDataSourceId } = defineAPIAction(
  ACTION.RENAME_DATA_SOURCE_ID,
  'datasources',
  '',
  'PATCH',
);

export const { actionFn: deleteDataSource } = defineAPIAction(
  ACTION.DELETE_DATA_SOURCE,
  'datasources',
  '',
  'DELETE',
);
