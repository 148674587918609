/** @format */
import { Layout } from '@explo-tech/react-grid-layout';

import { defineAPIAction } from 'actions/actionUtils';
import { ACTION } from './types';
import { DashboardElementConfig } from 'types/dashboardTypes';
import { ExportUrlResponse, FetchDashboardTemplateData } from 'actions/responseTypes';

// DashboardTemplate

export const { actionFn: createDashboardTemplate } = defineAPIAction(
  ACTION.CREATE_DASHBOARD_TEMPLATE,
  'dashboards',
  'create_dashboard_template',
  'POST',
);

export const { actionFn: cloneDashboardTemplate } = defineAPIAction(
  ACTION.CLONE_DASHBOARD_TEMPLATE,
  'dashboards',
  'duplicate',
  'POST',
);

export const { actionFn: deleteDashboardTemplate } = defineAPIAction(
  ACTION.DELETE_DASHBOARD_TEMPLATE,
  'dashboards',
  '',
  'DELETE',
);

export const { actionFn: fetchDashboardTemplate } = defineAPIAction<FetchDashboardTemplateData>(
  ACTION.FETCH_DASHBOARD_TEMPLATE,
  'dashboards',
  'get_dashboard_template',
  'GET',
);

export const { actionFn: fetchDashboardTemplateList } = defineAPIAction(
  ACTION.FETCH_DASHBOARD_TEMPLATE_LIST,
  'dashboards',
  'get_dashboard_template_list',
  'GET',
);

export const updateDashboardTemplateLayout = (layout: Layout[]) => ({
  type: ACTION.UPDATE_DASHBOARD_TEMPLATE_LAYOUT,
  payload: {
    layout,
  },
});

export const updateDashboardPdfLayout = (layout: Layout[]) => ({
  type: ACTION.UPDATE_DASHBOARD_PDF_LAYOUT,
  payload: {
    layout,
  },
});

export const { actionFn: renameDashboardTemplate } = defineAPIAction(
  ACTION.RENAME_DASHBOARD_TEMPLATE,
  'dashboards',
  'rename_dashboard_template',
  'POST',
);

export const { actionFn: saveShareLinkTitle } = defineAPIAction(
  ACTION.SAVE_SHARE_LINK_TITLE,
  'dashboards',
  'save_share_link_title',
  'POST',
);

// Configure dashboard elements

export const selectDashboardElementToEdit = (config: DashboardElementConfig) => ({
  type: ACTION.SELECT_DASHBOARD_ELEMENT_TO_EDIT,
  payload: {
    dashboardElementConfig: config,
  },
});

// Share Dashboard Actions

export const { actionFn: fetchShareId } = defineAPIAction(
  ACTION.FETCH_SHARE_ID,
  'dashboards',
  'get_share_id',
  'POST',
);

export const { actionFn: fetchExportPdfUrl } = defineAPIAction<ExportUrlResponse>(
  ACTION.FETCH_PDF_EXPORT_URL,
  'dashboards',
  'export/pdf',
  'POST',
);

export const { actionFn: fetchExportImageUrl } = defineAPIAction<ExportUrlResponse>(
  ACTION.FETCH_IMAGE_EXPORT_URL,
  'dashboards',
  'export/image',
  'POST',
);
