/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';
import { Button, ButtonGroup } from '@blueprintjs/core';

import {
  VisualizeLineOrBarChartInstructions,
  OPERATION_TYPES,
  ChartViewType,
} from 'constants/types';
import { CHART_VIEW_TYPES, DEFAULT_CHART_VIEW } from 'constants/dataConstants';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(6),
    },
  });

type PassedProps = {
  operationType: OPERATION_TYPES;
  visualizeLineOrBarChartInstructions: VisualizeLineOrBarChartInstructions;
  updateVisualizeOperation: (
    newVisualizeLineChartInstructions: VisualizeLineOrBarChartInstructions,
  ) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class SelectChartViewConfiguration extends React.Component<Props> {
  render() {
    const { classes, operationType, visualizeLineOrBarChartInstructions } = this.props;
    const selectedViewId = visualizeLineOrBarChartInstructions.viewType
      ? visualizeLineOrBarChartInstructions.viewType.id
      : DEFAULT_CHART_VIEW[operationType].id;

    return (
      <div className={classes.root}>
        <ButtonGroup fill>
          {Object.values(CHART_VIEW_TYPES[operationType]).map((chartType: any) => (
            <Button
              disabled={visualizeLineOrBarChartInstructions.lineColumns.length < 2}
              key={`${chartType.id}-${operationType}`}
              icon={chartType.icon as any}
              active={selectedViewId === chartType.id}
              onClick={() => this.onChartTypeSelected(chartType)}>
              {chartType.text}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    );
  }

  onChartTypeSelected = (chartType: ChartViewType) => {
    const { visualizeLineOrBarChartInstructions, updateVisualizeOperation } = this.props;

    if (visualizeLineOrBarChartInstructions.lineColumns.length < 2) return;

    visualizeLineOrBarChartInstructions.viewType = chartType;
    updateVisualizeOperation(visualizeLineOrBarChartInstructions);
  };
}

export default withStyles(styles)(SelectChartViewConfiguration);
