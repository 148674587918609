/** @format */

import React from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/index';
import { useSelector } from 'react-redux';

import FullPageContentContainer from 'shared/FullPageContentContainer';
import ConnectStepByStep from '../ConnectStepByStep';
import ConnectFAQs from '../ConnectFAQs';
import PanelButton from 'shared/PanelButton';

import { ConnectDataSourceStep } from '../constants';
import { DBConnectionConfig } from '../types';
import { ReduxState } from 'reducers/rootReducer';
import { integrationsInfo } from 'constants/dataSourceConstants';

const dataSourceByType = _.indexBy(Object.values(integrationsInfo), 'source_type');

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    rowGap: `${theme.spacing(3)}px`,
    columnGap: theme.spacing(3),
  },
}));

export type Props = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  onBackClicked: () => void;
};

const SelectDatabase: React.FC<Props> = ({
  config,
  updateConfig,
  onNextClicked,
  onBackClicked,
}) => {
  const classes = useStyles();

  const { supportedDataSources } = useSelector((state: ReduxState) => ({
    supportedDataSources: state.supportedDataSources,
  }));

  return (
    <FullPageContentContainer
      headerTitle="Select a database"
      primaryActionConfig={{
        text: 'Next',
        disabled: !config.selectedDataSource,
        onClick: onNextClicked,
      }}
      backBtnConfig={{
        onClick: onBackClicked,
      }}
      leftSideBarTopContent={<ConnectStepByStep currentStep={ConnectDataSourceStep.SELECT_DB} />}
      leftSideBarBottomContent={<ConnectFAQs />}
      bodyContent={
        <div className={classes.body}>
          {supportedDataSources.dataSources?.map((dataSource) => {
            return (
              <PanelButton
                key={`select-db-${dataSource.name}`}
                imgUrl={dataSourceByType[dataSource.type].datasourceIconImg}
                text={dataSource.name}
                selected={config.selectedDataSource?.name === dataSource.name}
                disabled={config.selectedDataSourceIsLocked}
                onClick={() => {
                  updateConfig({
                    ...config,
                    selectedDataSource: dataSource,
                  });
                }}
              />
            );
          })}
        </div>
      }
    />
  );
};

export default SelectDatabase;
