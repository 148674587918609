/** @format */

import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import parse from 'url-parse';
import { sha256 } from 'js-sha256';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import { AppToaster } from 'toaster';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { FormGroup, Intent, Divider } from '@blueprintjs/core';
import { ReduxState } from 'reducers/rootReducer';
import { Statsig } from 'statsig-react';

import SetupFlowPage from 'components/pages/setupFlowPage';
import InputField from 'explo-ds/forms/marketing/inputField';
import Button from 'shared/Button';

import { loginUser } from 'actions/authAction';
import { UserAuthenticationToken } from 'actions/responseTypes';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { pageView } from 'analytics/exploAnalytics';
import { fetchSignupEmail, googleOAuthVerification } from 'actions/authAction';
import {
  googleOAuthVerificationOnSuccess,
  googleOAuthVerificationOnFailure,
} from 'utils/landingPageUtils';
import FeatureGates from 'featureGates/featureGates';

const useStyles = makeStyles((theme: Theme) => ({
  signupInput: {
    width: '100%',
    height: 40,
    marginTop: 2,
  },
  passwordInputGroup: {
    marginTop: theme.spacing(5),
  },
  signInButton: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  forgotPassword: {
    marginTop: theme.spacing(3),
    cursor: 'pointer',
  },
  signupDisabledInput: {
    width: '100%',
    height: 40,
    marginTop: 2,
    cursor: 'not-allowed',
    background: theme.palette.ds.grey200,
  },
  googleSignupInput: {
    width: '100%',
    marginTop: theme.spacing(0.5),
    justifyContent: 'center',
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    // matches the FormGroup padding
    marginBottom: '15px',
    color: theme.palette.ds.grey700,
  },
  divider: {
    flexGrow: 1,
    // otherwsie the dividers render a little verticle line
    borderRight: 0,
  },
  dividerContainerText: {
    // align the text better with the divider lines
    paddingBottom: '2px',
  },
}));

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & RouteComponentProps;

const SignIngPageV2 = (props: Props) => {
  const {
    loginUser,
    loginLoading,
    ssoLoginLoading,
    fetchSignupEmail,
    googleOAuthVerification,
  } = props;
  const [signupEmail, setSignupEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const classes = useStyles();
  const urlQueryParams = parse(window.location.href, true).query;
  const onSubmit = useCallback(
    (email: string, password: string) => {
      loginUser(
        email,
        sha256(password),
        () => {
          setSignupEmail('');
          setPassword('');
          props.history.push('/home');
        },
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        (response: any) => {
          if (Object.keys(response).length > 0) {
            const key = Object.keys(response)[0];
            renderFailedToast(response[key][0]);
          }
        },
      );
    },
    [loginUser, props.history],
  );
  const enableSSO = Statsig.checkGate(FeatureGates.ENABLE_SSO);

  useEffect(() => {
    if (urlQueryParams.invite_hash) {
      fetchSignupEmail(
        {
          postData: {
            invite_hash: urlQueryParams.invite_hash,
          },
        },
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        (response: any) => {
          setSignupEmail(response.email);
          setErrorMsg(
            'This invitation has already been used to create an account. Please sign in.',
          );
        },
      );
    }
    pageView('Login');

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === 13) {
        onSubmit(signupEmail, password);
        return;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    urlQueryParams.invite_hash,
    fetchSignupEmail,
    props.history,
    password,
    signupEmail,
    onSubmit,
  ]);
  return (
    <SetupFlowPage
      title="Sign in"
      errorMsg={errorMsg}
      pageBody={
        <div>
          {enableSSO && (
            <div>
              <FormGroup>
                <GoogleLogin
                  className={classes.googleSignupInput}
                  clientId={`${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`}
                  buttonText="Sign in with Google"
                  responseType="code"
                  redirectUri={`${process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URI}`}
                  accessType="offline"
                  disabled={loginLoading || ssoLoginLoading}
                  onSuccess={(response) => {
                    googleOAuthVerification(
                      {
                        postData: {
                          authorization_code: response.code,
                        },
                      },
                      (response: UserAuthenticationToken) => {
                        googleOAuthVerificationOnSuccess(response.token);
                      },
                      (response: any) => {
                        googleOAuthVerificationOnFailure(response?.error_msg);
                      },
                    );
                  }}
                  onFailure={(response: any) => googleOAuthVerificationOnFailure(response?.details)}
                />
              </FormGroup>
              <div className={classes.dividerContainer}>
                <Divider className={classes.divider} />
                <div className={classes.dividerContainerText}>or</div>
                <Divider className={classes.divider} />
              </div>
            </div>
          )}
          <FormGroup label="Email address">
            <InputField
              value={signupEmail}
              onChange={(e) => setSignupEmail(e.target.value)}
              className={errorMsg ? classes.signupDisabledInput : classes.signupInput}
              placeholder=""
              disabled={!!errorMsg}
            />
          </FormGroup>

          <FormGroup className={classes.passwordInputGroup} label="Password">
            <InputField
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              className={classes.signupInput}
              placeholder=""
            />
          </FormGroup>
          <Button
            className={classes.signInButton}
            type="primary"
            onClick={() => {
              onSubmit(signupEmail, password);
            }}
            loading={loginLoading || ssoLoginLoading}
            text="Sign In"
          />
          <div
            className={classes.forgotPassword}
            onClick={() => props.history.push('/forgot-password')}>
            Forgot Password?
          </div>
        </div>
      }
    />
  );
};

const renderFailedToast = (msg: string): void => {
  AppToaster.show({
    message: msg,
    icon: 'issue',
    timeout: 5000,
    intent: Intent.DANGER,
  });
};

const mapStateToProps = (state: ReduxState) => ({
  loginLoading: state.currentUser.loginLoading,
  ssoLoginLoading: createLoadingSelector([ACTION.GOOGLE_OAUTH_VERIFICATION], false)(state),
});

const mapDispatchToProps = {
  loginUser,
  fetchSignupEmail,
  googleOAuthVerification,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIngPageV2));
