/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { AppToaster } from 'toaster';
import { Intent } from '@blueprintjs/core';
import cx from 'classnames';

import DropdownElementConfigPanel from './dropdownElementConfigPanel';
import TimePeriodDropdownElementConfigPanel from './TimePeriodDropdownElementConfigPanel';
import DatepickerElementConfigPanel from './datepickerElementConfigPanel';
import TextElementConfigPanel from './textElementConfigPanel';
import ExportElementConfigPanel from './exportElementConfigPanel';
import ContainerElementConfigPanel from './ContainerElementConfigPanel';
import DateRangeElementConfigPanel from './DateRangeElementConfigPanel';
import ImageElementConfigPanel from './ImageElementConfigPanel';
import DataGroupSwitchElementConfigPanel from './DataGroupSwitchElementConfigPanel';
import Button from 'shared/Button';
import ConfirmationModal from 'components/modals/confirmationModal';

import { trackEvent } from 'analytics/exploAnalytics';
import {
  DashboardElementConfig,
  TextDashboardElemConfig,
  DropdownDashboardElemConfig,
  DatepickerElemConfig,
  ExportElemConfig,
  DashboardElement,
  DASHBOARD_ELEMENT_TYPES,
  DateRangePickerElemConfig,
  ImageElemConfig,
  DateGroupSwitchConfig,
  TimePeriodDropdownDashboardElemConfig,
} from 'types/dashboardTypes';
import { DashboardTemplate, Dataset } from 'actions/types';
import { saveDashboardElementUpdates } from 'actions/dashboardV2Actions';
import TextFieldModal from 'components/modals/textFieldModal';
import { SIDE_PANE_HEADER_HEIGHT } from 'components/SidePane';
import { saveShareLinkTitle } from 'actions/dashboardAction';

const styles = (theme: Theme) => ({
  root: {
    height: '100%',
    borderRight: `1px solid ${theme.palette.ds.grey400}`,
  },
  configurationHeader: {
    height: SIDE_PANE_HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(3)}px`,
    borderBottom: `1px solid ${theme.palette.ds.grey400}`,
  },
  elementIdTag: {
    fontWeight: 'bold' as 'bold',
    flex: 1,
  },
  configurationMenu: {
    height: `calc(100% - ${SIDE_PANE_HEADER_HEIGHT}px)`,
    overflowY: 'auto' as 'auto',
    padding: `0 ${theme.spacing(6)}px`,
  },
  pressedButton: {
    backgroundColor: `${theme.palette.ds.pressed.grey100} !important`,
    color: `${theme.palette.ds.grey900} !important`,
  },
});

type PassedProps = {
  dashboard: DashboardTemplate;
  dashboardElement: DashboardElement;
  unselectElement: () => void;
  updateConfig: (newConfig: DashboardElementConfig) => void;
  deleteElement: () => void;
  datasets: Record<number, Dataset>;
  currElementNames: Set<string>;
  duplicateElement: (dashboardElement: DashboardElement) => void;
};

type Props = RouteComponentProps &
  WithStyles<typeof styles> &
  PassedProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type State = {
  deleteConfirmationModalOpen?: boolean;
  renameDashboardElementModalOpen?: boolean;
  isDuplicatingItem: boolean;
  shouldShowDuplicateCompleteToast: boolean;
};

class ElementConfigPanel extends React.Component<Props, State> {
  state: State = {
    isDuplicatingItem: false,
    shouldShowDuplicateCompleteToast: false,
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderConfigurationHeader()}
        <div className={classes.configurationMenu}>{this.renderConfiguration()}</div>
        {this.renderDeleteConfirmationModal()}
        {this.renderRenameDataPanelModal()}
      </div>
    );
  }

  renderConfigurationHeader = () => {
    const { classes, dashboardElement, duplicateElement } = this.props;
    const { isDuplicatingItem, shouldShowDuplicateCompleteToast } = this.state;

    return (
      <div className={classes.configurationHeader}>
        <div className={classes.elementIdTag}>{dashboardElement.name}</div>
        <Button
          minimal
          className={cx({
            [classes.pressedButton]: shouldShowDuplicateCompleteToast,
          })}
          icon="edit"
          onClick={() => this.setState({ renameDashboardElementModalOpen: true })}
        />
        <Button
          minimal
          disabled={isDuplicatingItem || shouldShowDuplicateCompleteToast}
          icon={shouldShowDuplicateCompleteToast ? 'tick' : 'duplicate'}
          onClick={() => {
            this.setState({ isDuplicatingItem: true });
            duplicateElement(dashboardElement);
            this.setState({ isDuplicatingItem: false, shouldShowDuplicateCompleteToast: true });

            AppToaster.show({
              icon: 'endorsed',
              intent: Intent.SUCCESS,
              message: `${dashboardElement.name} has been duplicated below`,
              onDismiss: () => this.setState({ shouldShowDuplicateCompleteToast: false }),
              timeout: 3000,
            });
          }}
        />
        <Button
          minimal
          icon="trash"
          onClick={() => this.setState({ deleteConfirmationModalOpen: true })}
          type="destructive"
        />
      </div>
    );
  };

  renderConfiguration = () => {
    const { dashboard, datasets, dashboardElement, saveShareLinkTitle, updateConfig } = this.props;
    const { config, element_type: elementType } = dashboardElement;
    const clonedConfig = cloneDeep(config);

    switch (elementType) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
        return (
          <DropdownElementConfigPanel
            config={clonedConfig as DropdownDashboardElemConfig}
            updateConfig={updateConfig}
            datasets={datasets}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
        return (
          <TimePeriodDropdownElementConfigPanel
            config={clonedConfig as TimePeriodDropdownDashboardElemConfig}
            updateConfig={updateConfig}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
        return (
          <DropdownElementConfigPanel
            config={clonedConfig as DropdownDashboardElemConfig}
            updateConfig={updateConfig}
            datasets={datasets}
          />
        );

      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
        return (
          <DatepickerElementConfigPanel
            config={clonedConfig as DatepickerElemConfig}
            updateConfig={updateConfig}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        return (
          <DateRangeElementConfigPanel
            config={clonedConfig as DateRangePickerElemConfig}
            updateConfig={updateConfig}
          />
        );

      case DASHBOARD_ELEMENT_TYPES.TEXT:
        return (
          <TextElementConfigPanel
            config={clonedConfig as TextDashboardElemConfig}
            updateConfig={updateConfig}
            datasets={datasets}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.EXPORT:
        return (
          <ExportElementConfigPanel
            config={clonedConfig as ExportElemConfig}
            updateConfig={updateConfig}
            dashboard={dashboard}
            saveShareLinkTitle={(newTitle: string) => {
              saveShareLinkTitle({
                id: dashboard.id,
                postData: {
                  share_link_title: newTitle,
                },
              });
            }}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.IMAGE:
        return (
          <ImageElementConfigPanel
            config={clonedConfig as ImageElemConfig}
            updateConfig={updateConfig}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.SWITCH:
        return (
          <DropdownElementConfigPanel
            config={clonedConfig as DropdownDashboardElemConfig}
            updateConfig={updateConfig}
            datasets={datasets}
            isSwitch
          />
        );
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
        return (
          <DataGroupSwitchElementConfigPanel
            config={clonedConfig as DateGroupSwitchConfig}
            updateConfig={updateConfig}
          />
        );

      case DASHBOARD_ELEMENT_TYPES.CONTAINER:
        return <ContainerElementConfigPanel />;
      default:
        return null;
    }
  };

  renderDeleteConfirmationModal = () => {
    const { deleteElement, dashboardElement } = this.props;
    const { deleteConfirmationModalOpen } = this.state;

    if (!deleteConfirmationModalOpen) return;

    return (
      <ConfirmationModal
        isDestructive
        modalOpen={deleteConfirmationModalOpen}
        closeModal={() => this.setState({ deleteConfirmationModalOpen: false })}
        modalTitle="Are you sure you want to delete this element?"
        cancelBtnText="Cancel"
        confirmBtnText="Delete"
        onConfirm={() => {
          deleteElement();

          window.dispatchEvent(
            new CustomEvent('deleteDashboardVariable', {
              detail: {
                elemId: dashboardElement.id,
                name: dashboardElement.name,
              },
            }),
          );
        }}
      />
    );
  };

  renderRenameDataPanelModal = () => {
    const { dashboardElement, saveDashboardElementUpdates, currElementNames } = this.props;
    const { renameDashboardElementModalOpen } = this.state;

    if (!renameDashboardElementModalOpen) return;

    return (
      <TextFieldModal
        resourceName={dashboardElement.name}
        modalOpen={renameDashboardElementModalOpen}
        closeModal={() => this.setState({ renameDashboardElementModalOpen: false })}
        modalTitle="Enter Dashboard Element Name"
        buttonName="Save"
        textFieldPlaceholder="Dashboard element name"
        errorState={(val) => {
          if (!val)
            return {
              isErrorState: true,
              errorMsg: 'An element name is required and cannot be empty.',
            };
          if (val !== dashboardElement.name && currElementNames.has(val))
            return {
              isErrorState: true,
              errorMsg:
                'An element with this name on this dashboard already exists. Please choose a different one.',
            };
          return { isErrorState: false };
        }}
        onSubmit={(name: string) => {
          if (currElementNames.has(name)) return;
          if (name === dashboardElement.name) return;

          this.setState({ renameDashboardElementModalOpen: false });
          const oldName = dashboardElement.name;

          saveDashboardElementUpdates({ id: dashboardElement.id, name });

          window.dispatchEvent(
            new CustomEvent('renameDashboardVariable', {
              detail: {
                elemId: dashboardElement.id,
                oldName,
                newName: name,
              },
            }),
          );

          trackEvent('Renamed dashboard element', {
            dashboard_element_id: dashboardElement.id,
            name: name,
          });
        }}
      />
    );
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  saveDashboardElementUpdates,
  saveShareLinkTitle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(ElementConfigPanel)));
