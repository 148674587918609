/** @format */

import _ from 'underscore';

import { Action } from 'reducers/rootReducer';
import { createReducer } from './utils';

import { ParentSchema, Dataset, ACTION } from 'actions/types';

export interface ParentSchemaReducerState {
  allParentSchema?: ParentSchema[];
  schemaTablesMap: { [schemaId: string]: { [datasetId: string]: Dataset } };
  parentSchemaError?: string;
}

const parentSchemaReducerInitialState: ParentSchemaReducerState = {
  allParentSchema: [],
  schemaTablesMap: {},
};

const fetchParentSchemasSuccess = (newState: ParentSchemaReducerState, action: Action) => {
  const { payload } = action;
  newState.allParentSchema = _.sortBy(payload.parent_schemas, (schema) => schema.id);
  return newState;
};

const fetchAllSchemaTablesSuccess = (newState: ParentSchemaReducerState, action: Action) => {
  const { payload } = action;
  newState.schemaTablesMap = {};
  for (const key in payload.schema_tables) {
    newState.schemaTablesMap[key] = _.indexBy(payload.schema_tables[key], 'id');
  }
  return newState;
};

const createParentSchemaSuccess = (newState: ParentSchemaReducerState, action: Action) => {
  const { payload } = action;
  if (!newState.allParentSchema) return newState;

  newState.allParentSchema.push(payload.schema);

  newState.allParentSchema = _.sortBy(newState.allParentSchema, (schema) => schema.id);
  return newState;
};

const deleteParentSchemaSuccess = (newState: ParentSchemaReducerState, action: Action) => {
  const { payload } = action;
  if (newState.allParentSchema) {
    const deletedIndex = _.findIndex(newState.allParentSchema, { id: payload.id });
    newState.allParentSchema.splice(deletedIndex, 1);
  }
  return newState;
};

const editParentSchemaSuccess = (newState: ParentSchemaReducerState, action: Action) => {
  const { payload } = action;
  if (newState.allParentSchema) {
    const renamedIndex = _.findIndex(newState.allParentSchema, { id: payload.id });
    newState.allParentSchema[renamedIndex].name = payload.postData.name;
  }
  return newState;
};

const syncParentSchemaSuccess = (newState: ParentSchemaReducerState, action: Action) => {
  const { payload } = action;
  newState.allParentSchema = _.sortBy(payload.parent_schemas, (schema) => schema.id);
  return newState;
};

const syncParentSchemaError = (newState: ParentSchemaReducerState, action: Action) => {
  const { payload } = action;
  newState.parentSchemaError = payload._error;
  return newState;
};

export default createReducer<ParentSchemaReducerState>(parentSchemaReducerInitialState, {
  [`${ACTION.FETCH_PARENT_SCHEMAS}_SUCCESS`]: fetchParentSchemasSuccess,
  [`${ACTION.FETCH_ALL_SCHEMA_TABLES_FOR_TEAM}_SUCCESS`]: fetchAllSchemaTablesSuccess,
  [`${ACTION.CREATE_PARENT_SCHEMA}_SUCCESS`]: createParentSchemaSuccess,
  [`${ACTION.DELETE_PARENT_SCHEMA}_SUCCESS`]: deleteParentSchemaSuccess,
  [`${ACTION.EDIT_PARENT_SCHEMA}_SUCCESS`]: editParentSchemaSuccess,
  [`${ACTION.SYNC_PARENT_SCHEMA}_SUCCESS`]: syncParentSchemaSuccess,
  [`${ACTION.SYNC_PARENT_SCHEMA}_ERROR`]: syncParentSchemaError,
});
