/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';
import { Button, ButtonGroup } from '@blueprintjs/core';

import { VisualizeNumberInstructions } from 'constants/types';
import { ALIGNMENT_FORMATS } from 'constants/dataConstants';

const ORDERED_ALIGNMENTS = [
  ALIGNMENT_FORMATS.LEFT_ALIGN,
  ALIGNMENT_FORMATS.CENTER_ALIGN,
  ALIGNMENT_FORMATS.RIGHT_ALIGN,
];

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(4),
    },
  });

type PassedProps = {
  visualizeNumberInstructions: VisualizeNumberInstructions;
  updateVisualizeOperation: (newVisualizeNumberInstructions: VisualizeNumberInstructions) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class NumberAlignmentConfiguration extends React.Component<Props> {
  render() {
    const { classes, visualizeNumberInstructions } = this.props;
    const { displayFormat } = visualizeNumberInstructions;

    const selectedAlignment = displayFormat.alignment || ALIGNMENT_FORMATS.LEFT_ALIGN.id;

    return (
      <div className={classes.root}>
        <ButtonGroup fill>
          {ORDERED_ALIGNMENTS.map((alignment) => (
            <Button
              key={alignment.id}
              icon={alignment.icon as any}
              active={selectedAlignment === alignment.id}
              onClick={() => this.onAlignmentSelected(alignment.id)}
            />
          ))}
        </ButtonGroup>
      </div>
    );
  }

  onAlignmentSelected = (alignmentId: string) => {
    const { visualizeNumberInstructions, updateVisualizeOperation } = this.props;

    visualizeNumberInstructions.displayFormat.alignment = alignmentId;
    updateVisualizeOperation(visualizeNumberInstructions);
  };
}

export default withStyles(styles)(NumberAlignmentConfiguration);
