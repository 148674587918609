/** @format */

import React from 'react';
import Highcharts from 'highcharts';
import { dayjs } from 'utils/localizationUtils';
import ReactDOMServer from 'react-dom/server';

import HighCharts from './highCharts';
import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';
import BaseTooltip from './tooltips/BaseTooltip';

import { formatLabel, xAxisFormat, formatLegend, getLabelStyle } from './utils';
import { V2BoxPlotInstructions } from 'constants/types';
import { DATE_TYPES, V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { TableColumn, TableRow } from 'actions/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { getSingleYAxisInstructions } from './utils/multiYAxisUtils';

declare global {
  interface PointOptionsObject {
    custom: Record<string, boolean | number | string>;
  }
}

type Props = {
  backgroundColor: string;
  dataPanelTemplateId: string;
  globalStyleConfig: GlobalStyleConfig;
  instructions?: V2BoxPlotInstructions;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  secondaryData: TableRow[];
  schema: TableColumn[];
  variables: DashboardVariableMap;
};

type State = {};

class BoxPlot extends React.PureComponent<Props, State> {
  getChartId = () => {
    return `pivotChartContainer${this.props.dataPanelTemplateId}`;
  };

  instructionsReadyToDisplay = (instructions?: V2BoxPlotInstructions) => {
    return !!(instructions && instructions.calcColumns?.length && instructions.groupingColumn);
  };

  render() {
    const { instructions, loading } = this.props;
    if (loading || !this.instructionsReadyToDisplay(instructions)) {
      return <NeedsConfigurationPanel fullHeight loading={loading} />;
    }

    return <HighCharts chartOptions={this._spec()} />;
  }

  _spec = (): Highcharts.Options | undefined => {
    const { previewData, schema, instructions, backgroundColor, globalStyleConfig } = this.props;
    if (schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();
    const groupingColumngName = this.getGroupingColName();
    const { valueFormatId, decimalPlaces } = this.getValueFormat();

    return {
      chart: {
        type: this.getChartType(),
        inverted: !instructions?.boxPlotFormat?.isVertical,
        backgroundColor,
      },
      //@ts-ignore
      series: this.transformData(),
      title: {
        text: undefined,
      },
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        categories: this.getAxisCategoriesOrDefault(),
        type: this.getXAxisTypeOrDefault(),
        labels: {
          formatter: function () {
            return formatLabel(
              DATE_TYPES.has(instructions?.groupingColumn?.column.type || '')
                ? previewData[this.value][groupingColumngName]
                : this.value,
              instructions?.groupingColumn?.column.type,
              instructions?.groupingColumn?.bucket?.id,
              instructions?.xAxisFormat?.dateFormat,
              instructions?.xAxisFormat?.stringFormat,
            );
          },
          style: getLabelStyle(globalStyleConfig, 'secondary'),
        },
      },
      yAxis: getSingleYAxisInstructions(globalStyleConfig, instructions),
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
      },
      plotOptions: {
        boxplot: {
          lineWidth: 1,
          medianWidth: 1,
          stemWidth: instructions?.boxPlotFormat?.hideWhisker ? 0 : 2,
          whiskerLength: '25%',
          whiskerWidth: instructions?.boxPlotFormat?.hideWhisker ? 0 : 2,
        },
        series: {
          animation: false,
        },
      },
      tooltip: {
        formatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            <BaseTooltip
              decimalPlaces={decimalPlaces}
              header={formatLabel(
                DATE_TYPES.has(instructions?.groupingColumn?.column.type || '')
                  ? previewData[parseInt(this.point.category)][groupingColumngName]
                  : this.point.category,
                instructions?.groupingColumn?.column.type,
                instructions?.groupingColumn?.bucket?.id,
                instructions?.xAxisFormat?.dateFormat,
                instructions?.xAxisFormat?.stringFormat,
              )}
              points={[
                {
                  color: String(this.point.color),
                  name: 'Maximum',
                  value: this.point.options.high,
                },
                {
                  color: String(this.point.color),
                  name: 'Upper Quartile',
                  value: this.point.options.q3,
                },
                {
                  color: String(this.point.color),
                  name: 'Median',
                  value: this.point.options.median,
                },
                {
                  color: String(this.point.color),
                  name: 'Lower Quartile',
                  value: this.point.options.q1,
                },
                {
                  color: String(this.point.color),
                  name: 'Minimum',
                  value: this.point.options.low,
                },
              ]}
              globalStyleConfig={globalStyleConfig}
              valueFormat={valueFormatId}
            />,
          );
        },
        padding: 0,
        borderWidth: 0,
        borderRadius: 0,
        backgroundColor: '#FFFFFF00',
        shadow: false,
        useHTML: true,
        outside: true,
      },
    };
  };

  getValueFormat = () => {
    const { instructions } = this.props;

    return {
      valueFormatId: instructions?.yAxisFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
      decimalPlaces: instructions?.yAxisFormat?.decimalPlaces ?? 2,
    };
  };

  getChartType = () => {
    return 'boxplot';
  };

  getGroupingColName = () => {
    const { schema } = this.props;

    return schema[0].name;
  };

  getXAxisTypeOrDefault = () => {
    const { instructions } = this.props;

    if (DATE_TYPES.has(instructions?.groupingColumn?.column.type || '')) return 'datetime';
  };

  getAxisCategoriesOrDefault = () => {
    const { instructions, previewData } = this.props;
    if (DATE_TYPES.has(instructions?.groupingColumn?.column.type || '')) return;

    const xAxisColName = this.getGroupingColName();
    const categories = new Set(previewData.map((row) => String(row[xAxisColName])));
    return Array.from(categories);
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;

    if (
      !previewData ||
      !DATE_TYPES.has(instructions?.groupingColumn?.column.type || '') ||
      !schema ||
      schema.length === 0
    )
      return;

    const xAxisColName = this.getGroupingColName();

    previewData.forEach((row) => {
      if (!instructions?.groupingColumn?.column.type) return;
      row[xAxisColName] = dayjs.utc(row[xAxisColName]).valueOf();
    });
  };

  transformData = () => {
    const { instructions, schema, previewData, secondaryData, globalStyleConfig } = this.props;
    const { calcColumns, groupingColumn, boxPlotFormat } = instructions ?? {};

    if (!calcColumns?.length || !groupingColumn || !schema || (schema.length - 1) % 5 !== 0)
      return [];

    /**
     * This is only relevant for Redshift-backed charts - since we can only grab metrics for one calc column at a time,
     * we pass additional metrics through secondaryData.
     */
    const allData = [...previewData];
    for (let idx = 0; idx < secondaryData.length; idx++) {
      const numberOfGroupings = previewData.length;
      allData[idx % numberOfGroupings] = {
        ...allData[idx % numberOfGroupings],
        ...secondaryData[idx],
      };
    }

    const data: Highcharts.SeriesBoxplotOptions[] = [];
    for (let calcIdx = 0; calcIdx < calcColumns.length; calcIdx++) {
      const columnName = calcColumns[calcIdx]?.name || '';
      const fillColor =
        boxPlotFormat?.fillColorByColumn?.[columnName] ||
        globalStyleConfig.visualizations.gradientPalette.hue2;
      const medianColor =
        boxPlotFormat?.medianColorByColumn?.[columnName] ||
        globalStyleConfig.visualizations.gradientPalette.hue1;

      data.push({
        type: 'boxplot',
        data: allData.map((row) => [
          row[`${columnName}_min`],
          row[`${columnName}_25_percentile`],
          row[`${columnName}_median`],
          row[`${columnName}_75_percentile`],
          row[`${columnName}_max`],
        ]),
        name: boxPlotFormat?.seriesLabelByColumn?.[columnName] || `Series ${calcIdx + 1}`,
        color: fillColor,
        fillColor,
        // @ts-ignore
        lineColor: fillColor,
        whiskerColor: fillColor,
        stemColor: fillColor,
        medianColor,
      });
    }

    return data;
  };
}

export default BoxPlot;
