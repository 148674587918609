/** @format */

import React from 'react';
import _ from 'underscore';
import Highcharts from 'highcharts';
import { dayjs, format } from 'utils/localizationUtils';
import ReactDOMServer from 'react-dom/server';

import HighCharts from './highCharts';
import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';
import BaseTooltip from './tooltips/BaseTooltip';

import {
  getColorColNames,
  formatLegend,
  formatLabel,
  getColorPalette,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  areRequiredVariablesSetTwoDimViz,
} from './utils';
import { V2TwoDimensionChartInstructions } from 'constants/types';
import { DATE_TYPES, V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { TableColumn } from 'actions/types';
import { getColDisplayText } from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/vizConfigs/utils';
import { GlobalStyleConfig } from 'globalStyles/types';

declare global {
  interface PointOptionsObject {
    custom: Record<string, boolean | number | string>;
  }
}

type Props = {
  backgroundColor: string;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
  schema: TableColumn[];
  donut?: boolean;
  globalStyleConfig: GlobalStyleConfig;
};

type State = {};

class PieChart extends React.PureComponent<Props, State> {
  getChartId = () => {
    return `pivotChartContainer${this.props.dataPanelTemplateId}`;
  };

  render() {
    const { instructions, loading, variables } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    if (loading || !isTwoDimVizInstructionsReadyToDisplay(instructions) || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }
    return <HighCharts chartOptions={this._spec()} />;
  }

  _spec = (): Highcharts.Options | undefined => {
    const { previewData, schema, instructions, backgroundColor, globalStyleConfig } = this.props;
    if (schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();
    const { decimalPlaces } = this.getValueFormat();

    return {
      chart: {
        type: this.getChartType(),
        backgroundColor,
      },
      //@ts-ignore
      series: this.transformData(),
      title: {
        text: undefined,
      },
      colors: getColorPalette(
        globalStyleConfig,
        instructions?.colorFormat?.selectedPalette,
        instructions?.colorFormat?.customColors,
      ),
      plotOptions: {
        series: {
          animation: false,
        },
        pie: {
          allowPointSelect: true,
          innerSize: this.getInnerSize(),
          cursor: 'pointer',
          showInLegend: true,
          dataLabels: {
            enabled: !instructions?.chartSpecificFormat?.pieChart?.hideChartValues,
            formatter: function () {
              if (instructions?.chartSpecificFormat?.pieChart?.useColorForLabel) {
                return formatLabel(
                  this.point.name,
                  instructions?.categoryColumn?.column.type,
                  instructions?.categoryColumn?.bucket?.id,
                  instructions?.xAxisFormat?.dateFormat,
                  instructions?.xAxisFormat?.stringFormat,
                );
              }
              return `${format('.1f')(this.point.percentage || 0)}%`;
            },
            style: getLabelStyle(globalStyleConfig, 'primary'),
          },
        },
      },
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
        labelFormatter: function () {
          return formatLabel(
            this.name,
            instructions?.categoryColumn?.column.type,
            instructions?.categoryColumn?.bucket?.id,
            instructions?.xAxisFormat?.dateFormat,
            instructions?.xAxisFormat?.stringFormat,
          );
        },
      },
      tooltip: {
        formatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            <BaseTooltip
              decimalPlaces={decimalPlaces}
              header={formatLabel(
                this.point.name,
                instructions?.categoryColumn?.column.type,
                instructions?.categoryColumn?.bucket?.id,
                instructions?.xAxisFormat?.dateFormat,
                instructions?.xAxisFormat?.stringFormat,
              )}
              points={[
                {
                  color: String(this.color),
                  name: this.series.name,
                  value: this.point.y,
                },
              ]}
              valueFormat={
                instructions?.yAxisFormats?.[0]?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id
              }
              globalStyleConfig={globalStyleConfig}
            />,
          );
        },
        padding: 0,
        borderWidth: 0,
        borderRadius: 0,
        backgroundColor: '#FFFFFF00',
        shadow: false,
        useHTML: true,
        outside: true,
      },
    };
  };

  getChartType = () => {
    return 'pie';
  };

  getXAxisColName = () => {
    const { schema } = this.props;

    return schema[0].name;
  };

  getInnerSize = () => {
    const { donut } = this.props;

    if (donut) return '50%';
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;

    if (
      !previewData ||
      !DATE_TYPES.has(instructions?.categoryColumn?.column.type || '') ||
      !schema ||
      schema.length === 0
    )
      return;

    const xAxisColName = this.getXAxisColName();

    previewData.forEach((row) => {
      if (!instructions?.categoryColumn?.column.type) return;
      row[xAxisColName] = dayjs.utc(row[xAxisColName]).valueOf();
    });
  };

  getValueFormat = () => {
    const { instructions } = this.props;

    return {
      decimalPlaces: instructions?.yAxisFormats?.[0]?.decimalPlaces ?? 2,
    };
  };

  transformData = () => {
    // This is for when there are multiple bars/lines selected
    const { instructions, schema } = this.props;

    if (
      !instructions?.aggColumns ||
      instructions.aggColumns.length === 0 ||
      !schema ||
      schema.length === 0
    )
      return [];

    let series;
    if (instructions.colorColumn) series = this.transformColorData(schema);
    else series = this.transformAggColsData(schema);

    return this.truncateDataMaxCategories(series);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  truncateDataMaxCategories = (series?: { type: string; name: string; data: any }[]) => {
    const { instructions } = this.props;
    if (!series || !series[0]?.data) return [];
    const data = series[0].data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sortedData = _.sortBy(data, (entry: any) => -entry.y);
    const maxCategories =
      instructions?.chartSpecificFormat?.pieChart?.maxCategories ?? sortedData.length;

    const topEntries = sortedData.slice(0, maxCategories);
    const otherEntries = sortedData.slice(maxCategories);
    if (otherEntries.length > 0) {
      const total = _.reduce(otherEntries, (total, entry) => total + entry.y, 0);
      topEntries.push({
        name: 'Other',
        y: total,
      });
    }

    series[0].data = topEntries;
    return series;
  };

  transformAggColsData = (schema: TableColumn[]) => {
    const { previewData, instructions } = this.props;
    if (!instructions?.aggColumns) return;

    const xAxisColName = schema[0].name;
    const aggCol = instructions?.aggColumns[0];
    const aggColNames = [schema[1].name];

    const series: Record<
      string,
      { name: string; type: string; data: { name: string | number; y: number }[] }
    > = {};

    previewData.forEach((row) => {
      aggColNames.forEach((colName) => {
        const entry = {
          name: row[xAxisColName],
          y: row[colName] as number,
        };
        if (series[colName]) {
          series[colName].data.push(entry);
        } else {
          series[colName] = {
            type: this.getChartType(),
            name: aggCol.column.friendly_name || getColDisplayText(aggCol) || colName,
            data: [entry],
          };
        }
      });
    });

    return Object.values(series);
  };

  transformColorData = (schema: TableColumn[]) => {
    const { previewData, instructions } = this.props;
    const { xAxisColName, aggColName } = getColorColNames(schema);
    if (!instructions?.aggColumns) return;
    const aggCol = instructions?.aggColumns[0];

    const seriesData: Record<string, { name: string | number; y: number }> = {};

    previewData.forEach((row) => {
      const category = row[xAxisColName];
      if (seriesData[category]) {
        seriesData[category].y += row[aggColName] as number;
      } else {
        seriesData[category] = {
          name: category,
          y: row[aggColName] as number,
        };
      }
    });

    return [
      {
        type: this.getChartType(),
        name: aggCol.column.friendly_name || getColDisplayText(aggCol) || aggColName,
        data: Object.values(seriesData),
      },
    ];
  };
}

export default PieChart;
