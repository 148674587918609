/** @format */
import { cloneDeep } from 'lodash';
import { Dispatch } from 'react';

import { ACTION, DataPanelTemplate, TableColumn } from 'actions/types';
import {
  AggregationColumnInfo,
  PivotOperationAggregation,
  FilterOperatorType,
  OPERATION_TYPES,
  VisualizeTableInstructions,
  VisualizeLineOrBarChartInstructions,
  VisualizeNumberInstructions,
  VisualizeChoroplethMapInstructions,
  VisualizeFunnelInstruction,
  VisualizeHeatMapInstructions,
  VisualizePieChartInstruction,
  ViusalizePivotChartInstructions,
  V2TwoDimensionChartInstructions,
  V2KPIChartInstructions,
  V2KPITrendInstructions,
  FilterValueType,
  SortOrder,
  V2BoxPlotInstructions,
  FilterValueSourceType,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';

export const selectDashboardDataPanelToEdit = (dpt: DataPanelTemplate) => ({
  type: ACTION.SELECT_DASHBOARD_DPT_TO_EDIT,
  payload: {
    dpt,
  },
});

export type ResetEditedDataPanelPayload = {
  dpt: DataPanelTemplate;
};

export const resetEditedDataPanel = (oldDpt: DataPanelTemplate) => ({
  type: ACTION.RESET_EDITED_DATA_PANEL,
  payload: {
    dpt: oldDpt,
  },
});

// SORTING
export type CreateSortClausePayload = {};

export const createSortClause = () => ({
  type: ACTION.CREATE_SORT_CLAUSE,
});

export type DeleteSortClausePayload = {
  index: number;
};

export const deleteSortClause = (index: number) => ({
  type: ACTION.DELETE_SORT_CLAUSE,
  payload: {
    index,
  },
});

export type SelectSortColumnPayload = {
  index: number;
  column: TableColumn;
};

export const selectSortColumn = (index: number, column: TableColumn) => ({
  type: ACTION.SELECT_SORT_COLUMN,
  payload: {
    index,
    column,
  },
});

export type SelectSortOrderPayload = {
  index: number;
  order: SortOrder;
};

export const selectSortOrder = (index: number, order: SortOrder) => ({
  type: ACTION.SELECT_SORT_ORDER,
  payload: {
    index,
    order,
  },
});

// FILTER TAB

export type CreateFilterClausePayload = {
  column?: TableColumn;
};

export const createFilterClause = (column?: TableColumn) => ({
  type: ACTION.CREATE_FILTER_CLAUSE,
  payload: {
    column,
  },
});

export type DeleteFilterClausePayload = {
  index: number;
};

export const deleteFilterClause = (index: number) => ({
  type: ACTION.DELETE_FILTER_CLAUSE,
  payload: {
    index,
  },
});

export type SelectFilterColumnPayload = {
  index: number;
  column: TableColumn;
};

export const selectFilterColumn = (index: number, column: TableColumn) => ({
  type: ACTION.SELECT_FILTER_COLUMN,
  payload: {
    index,
    column,
  },
});

export type SelectFilterOperatorPayload = {
  index: number;
  operator?: FilterOperatorType;
};

export const selectFilterOperator = (index: number, operator?: FilterOperatorType) => ({
  type: ACTION.SELECT_FILTER_OPERATOR,
  payload: {
    index,
    operator,
  },
});

export type UpdateFilterValuePayload = {
  index: number;
  value: FilterValueType;
};

export const updateFilterValue = (index: number, value: FilterValueType) => ({
  type: ACTION.UPDATE_FILTER_VALUE,
  payload: {
    index,
    value,
  },
});

export type UpdateFilterMatchPayload = {
  value: boolean;
};

export const updateFilterMatch = (value: boolean) => ({
  type: ACTION.UPDATE_FILTER_MATCH,
  payload: {
    value,
  },
});

export type UpdateFilterValueSourcePayload = {
  index: number;
  newSource: FilterValueSourceType;
};

export const updateFilterValueSource = (payload: UpdateFilterValueSourcePayload) => ({
  type: ACTION.UPDATE_FILTER_VALUE_SOURCE,
  payload,
});

export type UpdateFilterValueVariablePayload = {
  index: number;
  variableId: string;
};

export const updateFilterValueVariable = (payload: UpdateFilterValueVariablePayload) => ({
  type: ACTION.UPDATE_FILTER_VALUE_VARIABLE,
  payload,
});

// SUMMARIZE TAB

// groupings

export type CreatePivotedOnColPayload = {};

export const createPivotedOnCol = () => ({
  type: ACTION.CREATE_PIVOTED_ON_COL,
});

export type DeletePivotedOnColPayload = {
  index: number;
};

export const deletePivotedOnCol = (index: number) => ({
  type: ACTION.DELETE_PIVOTED_ON_COL,
  payload: {
    index,
  },
});

export type UpdatePivotedOnColPayload = {
  index: number;
  aggregationColumnInfo: AggregationColumnInfo;
};

export const updatePivotedOnCol = (
  index: number,
  aggregationColumnInfo: AggregationColumnInfo,
) => ({
  type: ACTION.UPDATE_PIVOTED_ON_COL,
  payload: {
    index,
    aggregationColumnInfo,
  },
});

// calculations

export type CreateAggregationPayload = {};

export const createAggregation = () => ({
  type: ACTION.CREATE_AGGREGATION,
});

export type DeleteAggrgationPayload = {
  index: number;
};

export const deleteAggregation = (index: number) => ({
  type: ACTION.DELETE_AGGREGATION,
  payload: {
    index,
  },
});

export type UpdateAggregationPayload = {
  index: number;
  pivotOperationAggregation: PivotOperationAggregation;
};

export const updateAggregation = (
  index: number,
  pivotOperationAggregation: PivotOperationAggregation,
) => ({
  type: ACTION.UPDATE_AGGREGATION,
  payload: {
    index,
    pivotOperationAggregation,
  },
});

// VISUALIZE TAB

export type UpdateSelectedChartPayload = {
  operationType: OPERATION_TYPES;
};

export const updateSelectedChart = (operationType: OPERATION_TYPES) => ({
  type: ACTION.UPDATE_SELECTED_CHART,
  payload: {
    operationType,
  },
});

// Visualize Table Section

export type CombinedVisualizeInstructions =
  | VisualizeTableInstructions
  | VisualizeLineOrBarChartInstructions
  | VisualizeNumberInstructions
  | VisualizeChoroplethMapInstructions
  | VisualizeFunnelInstruction
  | VisualizeHeatMapInstructions
  | ViusalizePivotChartInstructions
  | VisualizePieChartInstruction
  | V2TwoDimensionChartInstructions
  | V2KPIChartInstructions
  | V2BoxPlotInstructions
  | V2KPITrendInstructions;

export type UpdateVisualizeOperationPayload = {
  visualizeInstructions: CombinedVisualizeInstructions;
  operationType: OPERATION_TYPES;
};

export const updateVisualizeOperation = (
  visualizeInstructions: CombinedVisualizeInstructions,
  operationType: OPERATION_TYPES,
) => ({
  type: ACTION.UPDATE_VISUALIZE_OP,
  payload: {
    visualizeInstructions,
    operationType,
  },
});

export type UpdateGeneralFormatOptionsPayload = {
  generalFormatOptions: VisualizeOperationGeneralFormatOptions;
};

export const updateGeneralFormatOptions = (
  generalFormatOptions: VisualizeOperationGeneralFormatOptions,
) => ({
  type: ACTION.UPDATE_VISUALIZE_OPERATION_GENERAL_FORMAT_OPTIONS,
  payload: {
    generalFormatOptions,
  },
});

export function updateClonedVisualizationInstructions<T>(
  oldInstructions: T,
  operationType: OPERATION_TYPES,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  modifyInstructions: (newInstructions: T) => void,
) {
  const newInstructions = cloneDeep(oldInstructions);
  modifyInstructions(newInstructions);
  dispatch(updateVisualizeOperation(newInstructions, operationType));
}
