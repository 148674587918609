/** @format */
import React, { useRef } from 'react';
import cx from 'classnames';
import produce from 'immer';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { useDrag, useDrop } from 'react-dnd';
import { Icon } from '@blueprintjs/core';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { UserTransformedSchema } from 'constants/types';
import FlexBox, { FlexDirection, VerticalAlignment } from 'components/core/FlexBox';
import FlexItem from 'components/core/FlexItem';
import Checkbox from 'components/checkbox';
import { DraggableReportBuilderRow, ItemTypes } from 'constants/dragAndDrop';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { shiftItemsInList } from 'utils/general';
import StepHeader from '../StepHeader';
import StepFooter from '../StepFooter';

type StylesParams = {
  isDraggable?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  columnHeaderContainer: {
    backgroundColor: theme.palette.ds.grey100,
    borderTop: `1px solid ${theme.palette.ds.grey200}`,
    borderBottom: `1px solid ${theme.palette.ds.grey200}`,
  },
  columnHeaderText: {
    marginLeft: theme.spacing(9),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  columnsParentContainer: {
    minHeight: 0,
  },
  columnsContainer: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    overflowY: 'scroll',
  },
  row: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  draggableRow: ({ isDraggable }: StylesParams) => ({
    ...(isDraggable ? { cursor: 'move' } : { paddingLeft: theme.spacing(4) }),
  }),
  checkbox: {
    margin: `0px ${theme.spacing(1)}px`,
  },
  inputContainer: {
    flex: 1,
  },
  input: {
    '& .bp3-input': {
      width: '100%',
      height: 24,
    },
  },
}));

type Props = {
  onBack: () => void;
  onNext: () => void;
  setUserTransformedSchema: (schema: UserTransformedSchema) => void;
  userTransformedSchema: UserTransformedSchema;
};

export default function SelectContentStep({
  onBack,
  onNext,
  userTransformedSchema,
  setUserTransformedSchema,
}: Props) {
  const classes = useStyles({});

  return (
    <FlexBox
      className={cx(classes.root, GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor)}
      direction={FlexDirection.COLUMN}>
      <StepHeader text="Select content" />
      <FlexBox className={classes.columnHeaderContainer}>
        <FlexItem
          className={cx(classes.columnHeaderText, GLOBAL_STYLE_CLASSNAMES.text.body.secondary)}>
          Columns available
        </FlexItem>
        <FlexItem
          className={cx(classes.columnHeaderText, GLOBAL_STYLE_CLASSNAMES.text.body.secondary)}>
          Columns unavailable
        </FlexItem>
      </FlexBox>
      <FlexBox className={classes.columnsParentContainer} flex={1}>
        <FlexItem className={classes.columnsContainer}>
          {userTransformedSchema.map((col, index) => {
            if (!col.isVisible) return null;
            return (
              <TransformationRow
                isDraggable
                key={JSON.stringify(col)}
                index={index}
                schema={userTransformedSchema}
                updateSchema={(newSchema) => {
                  setUserTransformedSchema(newSchema);
                }}
              />
            );
          })}
        </FlexItem>
        <FlexItem className={classes.columnsContainer}>
          {userTransformedSchema.map((col, index) => {
            if (col.isVisible) return null;
            return (
              <TransformationRow
                isDraggable={false}
                key={JSON.stringify(col)}
                index={index}
                schema={userTransformedSchema}
                updateSchema={(newSchema) => {
                  setUserTransformedSchema(newSchema);
                }}
              />
            );
          })}
        </FlexItem>
      </FlexBox>
      <StepFooter
        disabled={!userTransformedSchema.some((col) => col.isVisible)}
        onBack={onBack}
        onNext={onNext}
        text="Next"
      />
    </FlexBox>
  );
}

type TransformationRowProps = {
  index: number;
  isDraggable: boolean;
  schema: UserTransformedSchema;
  updateSchema: (newSchema: UserTransformedSchema) => void;
};

function TransformationRow({ index, isDraggable, schema, updateSchema }: TransformationRowProps) {
  const classes = useStyles({ isDraggable });
  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.REPORT_BUILDER_ROW, data: { index } },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.REPORT_BUILDER_ROW,
    drop: (item: DraggableReportBuilderRow) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.data.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      const newSchema = produce(schema, (draft) => {
        shiftItemsInList(draft, dragIndex, hoverIndex);
      });

      updateSchema(newSchema);
      item.data.index = hoverIndex;
    },
  });

  const column = schema[index];
  const opacity = isDragging ? 0 : 1;

  if (isDraggable) {
    drag(drop(ref));
  }

  return (
    <div ref={ref} className={classes.draggableRow} style={{ opacity }}>
      <FlexBox className={classes.row} verticalAlignment={VerticalAlignment.CENTER}>
        {isDraggable && (
          <Icon
            icon="drag-handle-vertical"
            className={GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color}
          />
        )}
        <Checkbox
          className={classes.checkbox}
          isChecked={column.isVisible}
          onChange={() => {
            const newSchema = produce(schema, (draft) => {
              draft[index].isVisible = !column.isVisible;
            });

            updateSchema(newSchema);
          }}
        />
        <InputWithBlurSave
          className={classes.input}
          containerClassName={classes.inputContainer}
          hideRightIconInteractions
          initialValue={column.friendly_name}
          onNewValueSubmitted={(newColumnName) => {
            const newSchema = produce(schema, (draft) => {
              draft[index].friendly_name = newColumnName;
            });

            updateSchema(newSchema);
          }}
        />
      </FlexBox>
    </div>
  );
}
