/** @format */

import {
  DashboardElementConfig,
  TEXT_ELEM_HORIZ_ALIGNMENTS,
  TEXT_ELEM_SIZES,
  TEXT_ELEM_VERTICAL_ALIGNMENTS,
  DASHBOARD_ELEMENT_TYPES,
} from 'types/dashboardTypes';
import {
  TEXT_ELEM_ICON,
  DROPDOWN_ELEM_ICON,
  TIME_PERIOD_DROPDOWN_ELEM_ICON,
  MULTISELECT_ELEM_ICON,
  DATEPICKER_ELEM_ICON,
  DATERANGE_ELEM_ICON,
  TOGGLE_SWITCH_ELEM_ICON,
  IMAGE_ELEM_ICON,
  CONTAINER_ELEM_ICON,
  SHARE_ELEM_ICON,
  DATA_TABLE_ICON,
  DATA_PANEL_V2_ICON,
  REPORT_BUILDER_ICON,
  KPI_CHART_ELEM_ICON,
  LINE_CHART_ELEM_ICON,
  AREA_CHART_ELEM_ICON,
  DONUT_CHART_ELEM_ICON,
  FUNNEL_CHART_ELEM_ICON,
  HEAT_MAP_ELEM_ICON,
  MAP_CHART_ELEM_ICON,
  BOX_PLOT_ELEM_ICON,
} from 'constants/iconConstants';
import { IconName } from '@blueprintjs/core';
import { OPERATION_TYPES, PlotLineType } from 'constants/types';
import { v4 as uuidv4 } from 'uuid';

export const MOBILE_BREAKPOINT_WIDTH = 768;

export const DASHBOARD_ROW_HEIGHT = 50;

export const NUM_MOBILE_COLUMNS = 2;

export const ROWS_PER_PDF_PAGE = 10;
export const PDF_PAGE_HEIGHT = 792;
export const PDF_PAGE_BREAK_HEIGHT = 10;
export const PDF_EDITOR_PAGE_BORDER_WIDTH = 1;
// PDF_EDITOR_MARGIN_SIZE = (PDF_PAGE_HEIGHT - (ROWS_PER_PDF_PAGE * DASHBOARD_ROW_HEIGHT)) / ROWS_PER_PDF_PAGE
export const PDF_EDITOR_MARGIN_SIZE = 29.2;

export const ELEMS_NEED_DATASETS = new Set([
  DASHBOARD_ELEMENT_TYPES.DROPDOWN,
  DASHBOARD_ELEMENT_TYPES.MULTISELECT,
  DASHBOARD_ELEMENT_TYPES.SWITCH,
]);

export const GENERIC_DROPPING_ID = 'dropping-element';

export const droppingElementId = (elemType?: string) => {
  if (elemType === undefined) return '';
  return `dropping-element-${elemType}`;
};

export const DRAGGING_ITEM_CONFIG_BY_TYPE: Record<string, { i: string; w: number; h: number }> = {
  [DASHBOARD_ELEMENT_TYPES.DATA_PANEL]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.DATA_PANEL),
    w: 5,
    h: 4,
  },
  [DASHBOARD_ELEMENT_TYPES.DATA_TABLE]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.DATA_TABLE),
    w: 5,
    h: 4,
  },
  [DASHBOARD_ELEMENT_TYPES.REPORT_BUILDER]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.REPORT_BUILDER),
    w: 6,
    h: 4,
  },
  [DASHBOARD_ELEMENT_TYPES.BAR_CHART]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.BAR_CHART),
    w: 5,
    h: 6,
  },
  [DASHBOARD_ELEMENT_TYPES.TEXT]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.TEXT),
    w: 3,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.DROPDOWN]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.DROPDOWN),
    w: 2,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN),
    w: 2,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.MULTISELECT]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.MULTISELECT),
    w: 2,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.DATEPICKER]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.DATEPICKER),
    w: 2,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER),
    w: 3,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.EXPORT]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.EXPORT),
    w: 2,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.IMAGE]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.IMAGE),
    w: 6,
    h: 3,
  },
  [DASHBOARD_ELEMENT_TYPES.SWITCH]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.SWITCH),
    w: 4,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH),
    w: 4,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.CONTAINER]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.CONTAINER),
    w: 5,
    h: 4,
  },
};

export const getDraggingConfig = (draggingElemType?: string) => {
  if (!draggingElemType) return;
  if (draggingElemType.indexOf('data-panel-') >= 0) {
    return {
      i: droppingElementId(draggingElemType),
      w: 5,
      h: 4,
    };
  }

  return DRAGGING_ITEM_CONFIG_BY_TYPE[draggingElemType];
};

export const TEXT_ELEM_SIZE_CONFIGS = {
  [TEXT_ELEM_SIZES.SMALL]: {
    id: TEXT_ELEM_SIZES.SMALL,
    name: 'Normal',
    fontSize: 'small',
  },
  [TEXT_ELEM_SIZES.MEDIUM]: {
    id: TEXT_ELEM_SIZES.MEDIUM,
    name: 'Large',
    fontSize: 'medium',
  },
  [TEXT_ELEM_SIZES.LARGE]: {
    id: TEXT_ELEM_SIZES.LARGE,
    name: 'Extra Large',
    fontSize: 'large',
  },
};

export const BASE_CONFIG_BY_DASH_ELEM: { [elemType: string]: DashboardElementConfig } = {
  [DASHBOARD_ELEMENT_TYPES.TEXT]: {
    alignmentHoriz: TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT,
    alignmentVertical: TEXT_ELEM_VERTICAL_ALIGNMENTS.TOP,
    textColor: '#000',
    textSize: TEXT_ELEM_SIZES.SMALL,
  },
  [DASHBOARD_ELEMENT_TYPES.DROPDOWN]: {
    label: 'Numbers',
    valuesConfig: {
      valuesSource: 'MANUAL',
      manualValues: '[1, 2, 3]',
      manualDisplays: '["One", "Two", "Three"]',
    },
  },
  [DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN]: {
    label: 'Filter',
    values: [
      { id: '15', value: 15, name: 'Last 15 minutes' },
      { id: '30', value: 30, name: 'Last 30 minutes' },
      { id: '60', value: 60, name: 'Last 1 hour' },
      { id: '1440', value: 1440, name: 'Last 24 hours' },
      { id: '10080', value: 10080, name: 'Last 7 days' },
    ],
  },

  [DASHBOARD_ELEMENT_TYPES.MULTISELECT]: {
    label: 'Numbers',
    valuesConfig: {
      valuesSource: 'MANUAL',
      manualValues: '[1, 2, 3]',
      manualDisplays: '["One", "Two", "Three"]',
    },
  },
  [DASHBOARD_ELEMENT_TYPES.DATEPICKER]: {
    label: 'Date',
    valueFormat: 'YYYY-MM-DD',
    hideTimeSelect: true,
  },
  [DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER]: {
    label: 'Date Range',
  },
  [DASHBOARD_ELEMENT_TYPES.EXPORT]: {
    label: 'Share',
  },
  [DASHBOARD_ELEMENT_TYPES.IMAGE]: {},
  [DASHBOARD_ELEMENT_TYPES.SWITCH]: {
    label: 'Switch',
    valuesConfig: {
      valuesSource: 'MANUAL',
      manualValues: '["day", "week", "month"]',
      manualDisplays: '["Day", "Week", "Month"]',
    },
  },
  [DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH]: {
    label: 'Date Grouping',
  },
  [DASHBOARD_ELEMENT_TYPES.CONTAINER]: {
    layout: [],
  },
};

export enum ELEMENT_GROUPS {
  CHARTS = 'Charts',
  CONTROLS = 'Controls',
  EXPORT = 'Export',
  DISPLAY = 'Display',
}

export const DROPPABLE_DASHBOARD_ELEMENT_CONFIGS: {
  id: string;
  icon: IconName | JSX.Element;
  name: string;
  group: ELEMENT_GROUPS;
}[] = [
  // CHARTS
  {
    id: DASHBOARD_ELEMENT_TYPES.DATA_TABLE,
    icon: DATA_TABLE_ICON,
    name: 'Data Table',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_NUMBER_V2}`,
    icon: KPI_CHART_ELEM_ICON,
    name: 'KPI Chart',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2}`,
    icon: DATA_PANEL_V2_ICON,
    name: 'Bar Chart',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_LINE_CHART_V2}`,
    icon: LINE_CHART_ELEM_ICON,
    name: 'Line Chart',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_AREA_CHART_V2}`,
    icon: AREA_CHART_ELEM_ICON,
    name: 'Area Chart',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_PIE_CHART_V2}`,
    icon: DONUT_CHART_ELEM_ICON,
    name: 'Pie Chart',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_FUNNEL_V2}`,
    icon: FUNNEL_CHART_ELEM_ICON,
    name: 'Funnel',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2}`,
    icon: HEAT_MAP_ELEM_ICON,
    name: 'Heat Map',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP}`,
    icon: MAP_CHART_ELEM_ICON,
    name: 'Map',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2}`,
    icon: BOX_PLOT_ELEM_ICON,
    name: 'Box Plot',
    group: ELEMENT_GROUPS.CHARTS,
  },

  // CONTROLS
  {
    id: DASHBOARD_ELEMENT_TYPES.DROPDOWN,
    icon: DROPDOWN_ELEM_ICON,
    name: 'Dropdown',
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN,
    icon: TIME_PERIOD_DROPDOWN_ELEM_ICON,
    name: 'Time Dropdown',
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.MULTISELECT,
    icon: MULTISELECT_ELEM_ICON,
    name: 'Multi-select',
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.DATEPICKER,
    icon: DATEPICKER_ELEM_ICON,
    name: 'Date Picker',
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER,
    icon: DATERANGE_ELEM_ICON,
    name: 'Date Range Picker',
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.SWITCH,
    icon: TOGGLE_SWITCH_ELEM_ICON,
    name: 'Toggle Switch',
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH,
    icon: TOGGLE_SWITCH_ELEM_ICON,
    name: 'Date Group Switch',
    group: ELEMENT_GROUPS.CONTROLS,
  },

  // EXPORTS
  {
    id: DASHBOARD_ELEMENT_TYPES.REPORT_BUILDER,
    icon: REPORT_BUILDER_ICON,
    name: 'Report Builder',
    group: ELEMENT_GROUPS.EXPORT,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.EXPORT,
    icon: SHARE_ELEM_ICON,
    name: 'Share Button',
    group: ELEMENT_GROUPS.EXPORT,
  },

  // DISPLAYS
  {
    id: DASHBOARD_ELEMENT_TYPES.TEXT,
    icon: TEXT_ELEM_ICON,
    name: 'Text',
    group: ELEMENT_GROUPS.DISPLAY,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.IMAGE,
    icon: IMAGE_ELEM_ICON,
    name: 'Image',
    group: ELEMENT_GROUPS.DISPLAY,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.CONTAINER,
    icon: CONTAINER_ELEM_ICON,
    name: 'Container',
    group: ELEMENT_GROUPS.DISPLAY,
  },
];

export const EMPTY_DATA_PANEL_STATE = (
  id: string,
  name: string,
  datasetId: string,
  vizType?: OPERATION_TYPES,
  containerId?: string,
) => ({
  id,
  name,
  container_id: containerId,
  table_id: datasetId,
  filter_op: {
    operation_type: OPERATION_TYPES.FILTER,
    instructions: {
      matchOnAll: true,
      filterClauses: [],
    },
  },
  group_by_op: {
    operation_type: OPERATION_TYPES.GROUP_BY,
    instructions: {
      pivotedOnCols: [],
      aggregations: [],
    },
  },
  sort_op: {
    operation_type: OPERATION_TYPES.SORT,
    instructions: {
      sortColumns: [],
    },
  },
  visualize_op: {
    operation_type: vizType || OPERATION_TYPES.VISUALIZE_TABLE,
    instructions: {
      VISUALIZE_PIVOT_CHART: {},
      VISUALIZE_TABLE: {
        changeSchemaList: [],
        schemaDisplayOptions: {},
      },
      VISUALIZE_LINE_OR_BAR_CHART: {
        xAxisColumn: undefined,
        lineColumns: [{ column: undefined, color: '#348CF7' }],
      },
      VISUALIZE_NUMBER: {
        layout: 'NUMBER',
        layoutConfig: {
          NUMBER: {
            column: undefined,
            numberFormat: undefined,
            multiplyFactor: 1,
            decimalPlaces: 0,
          },
          RATIO: {},
        },
        displayFormat: {
          title: undefined,
          hideTitle: false,
          icon: undefined,
          units: undefined,
          hideUnits: false,
        },
      },
      VISUALIZE_CHOROPLETH_MAP: {
        region: 'WORLD',
        regionColumn: undefined,
        densityColumn: undefined,
        mapConfiguration: undefined,
      },
      VISUALIZE_FUNNEL: {},
      VISUALIZE_PIE_CHART: {
        layoutType: 'pie',
      },
      VISUALIZE_HEAT_MAP: {
        layoutType: 'PIE',
      },
      V2_TWO_DIMENSION_CHART: {
        xAxisFormat: { hideTotalValues: true },
        yAxisFormats: [{ id: uuidv4() }],
      },
      V2_KPI: {},
      V2_KPI_TREND: {},
      V2_BOX_PLOT: {},
    },
  },
  _loading: false,
});
export enum COMBO_CHART_DATA_FORMATS {
  BAR = 'column',
  LINE = 'line',
}

export const PLOT_LINE_CONFIG: Record<PlotLineType, { id: PlotLineType; name: string }> = {
  [PlotLineType.SOLID]: {
    id: PlotLineType.SOLID,
    name: 'Solid',
  },
  [PlotLineType.DASH]: {
    id: PlotLineType.DASH,
    name: 'Dashes',
  },
  [PlotLineType.SHORT_DASH]: {
    id: PlotLineType.SHORT_DASH,
    name: 'Short Dashes',
  },
  [PlotLineType.LONG_DASH]: {
    id: PlotLineType.LONG_DASH,
    name: 'Long dashes',
  },
  [PlotLineType.DOT]: {
    id: PlotLineType.DOT,
    name: 'Dots',
  },
  [PlotLineType.SHORT_DOT]: {
    id: PlotLineType.SHORT_DOT,
    name: 'Short Dots',
  },
};
