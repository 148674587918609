/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';
import { Icon } from '@blueprintjs/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.ds.grey100,
      borderRadius: 4,
      padding: theme.spacing(3),
      position: 'relative',
      boxSizing: 'border-box',

      '&.deletable:hover': {
        boxShadow: `inset 0px 0px 0px 1px ${theme.palette.ds.grey600}`,

        '& .editing-panel-delete-btn': {
          visibility: 'visible',
        },
      },

      '& .editing-panel-delete-btn': {
        position: 'absolute',
        top: -10,
        right: -10,
        width: 20,
        height: 20,
        borderRadius: 20,
        border: `1px solid ${theme.palette.ds.grey600}`,
        backgroundColor: theme.palette.ds.grey100,
        visibility: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme.palette.ds.grey600,

          '& .editing-panel-delete-icon': {
            color: theme.palette.ds.grey100,
          },
        },

        '& .editing-panel-delete-icon': {
          color: theme.palette.ds.grey600,
        },
      },
    },
  });

type PassedProps = {
  className?: string;
  onDelete?: () => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class EditingPanel extends React.Component<Props> {
  render() {
    const { classes, className, onDelete } = this.props;
    return (
      <div className={cx(classes.root, className, { deletable: onDelete })}>
        {this.props.children}
        {this.renderDeleteButton()}
      </div>
    );
  }

  renderDeleteButton = () => {
    const { onDelete } = this.props;

    if (!onDelete) return;
    return (
      <div className="editing-panel-delete-btn" onClick={onDelete}>
        <Icon icon="cross" className="editing-panel-delete-icon" iconSize={12} />
      </div>
    );
  };
}

export default withStyles(styles)(EditingPanel);
