/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, useTheme } from '@material-ui/core/index';

import Button from 'shared/Button';
import { Icon, Intent, Tooltip } from '@blueprintjs/core';
import { withSize, SizeMeProps } from 'react-sizeme';

const VERTICAL_PADDING = 60;
const FOOTER_HEIGHT = 56;
const BANNER_HEIGHT = 50;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    height: `calc(100% - ${VERTICAL_PADDING * 2}px )`,
    position: 'relative',

    '&.centerAlign': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
    },
  },
  leftSideBar: {
    position: 'absolute',
    left: -190,
    top: 0,
    width: 150,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',

    '&.centerAlign': {
      position: 'initial',
      marginRight: theme.spacing(10),
    },
  },
  leftSideBarTop: {
    marginTop: theme.spacing(8),
  },
  leftSideBarBottom: {
    marginBottom: theme.spacing(2),
  },
  container: {
    width: 650,
    backgroundColor: theme.palette.ds.white,
    height: '100%',
    borderRadius: 8,
    border: `1px solid ${theme.palette.ds.grey400}`,
  },
  contentBody: {
    height: `calc(100% - ${FOOTER_HEIGHT}px)`,
    overflowY: 'auto',
  },
  contentBodyWithBanner: {
    height: `calc(100% - ${FOOTER_HEIGHT}px - ${BANNER_HEIGHT}px)`,
  },
  header: {
    padding: theme.spacing(8),
    paddingBottom: 0,
    marginBottom: theme.spacing(8),
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.ds.black,
  },
  headerSubtitle: {
    fontSize: 14,
    color: theme.palette.ds.grey900,
    marginTop: theme.spacing(2),
  },
  footer: {
    height: FOOTER_HEIGHT,
    borderTop: `1px solid ${theme.palette.ds.grey400}`,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  backButton: {},
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  primaryButton: {
    padding: `0 ${theme.spacing(11)}px`,
  },
  bodyContent: {
    padding: `0 ${theme.spacing(8)}px`,
  },
  bottomBanner: {
    height: BANNER_HEIGHT,
    padding: `0 ${theme.spacing(5)}px`,
    display: 'flex',
    alignItems: 'center',

    '&.success': {
      backgroundColor: theme.palette.ds.lightGreen,
    },
    '&.danger': {
      backgroundColor: theme.palette.ds.lightRed,
    },
  },
  bottomBannerIcon: {
    height: 16,
    width: 16,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(4),

    '&.success': {
      backgroundColor: theme.palette.ds.green,
    },
    '&.danger': {
      backgroundColor: theme.palette.ds.red,
    },
  },
  bottomBannerText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  bannerTooltip: {
    maxWidth: 400,
  },
  bannerTooltipTarget: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export type Props = {
  /**
   * optional class to wrap entire range input
   */
  className?: string;
  /**
   * text to display as header of the container
   */
  headerTitle?: string;
  /**
   * text to display as subtitle of header
   */
  headerSubtitle?: string;
  /**
   * configre the primary action button on the page
   */
  primaryActionConfig: {
    /**
     * text to on primary button in bottom right
     */
    text: string;
    /**
     * set the primary action to be disabled
     */
    disabled?: boolean;
    /**
     * handler when primary action is clicked
     */
    onClick: () => void;
    /**
     * Indicates if the primary button is in a loading state
     */
    loading?: boolean;
  };
  /**
   * configure the back button on the page. If not passed in, no back button shows
   */
  backBtnConfig?: {
    /**
     * handler when back button is clicked. If not provided, no bkac button appears
     */
    onClick: () => void;
    /**
     * set the back button to be disabled
     */
    disabled?: boolean;
  };
  /**
   * config of banner showing information on the bottom of the body above the footer
   */
  bottomBannerConfig?: {
    /**
     * text or JSX element to show in the banner
     */
    text?: string | JSX.Element;
    /**
     * the intent of the banner
     */
    intent?: Intent;
  };

  /**
   * content to be shown on the top left of the main container
   */
  leftSideBarTopContent?: JSX.Element;
  /**
   * content to be shown on the bottom left of the main container
   */
  leftSideBarBottomContent?: JSX.Element;
  /**
   * content to be shown in the actual main container body
   */
  bodyContent?: JSX.Element;
};

const LAYOUT_BREAKPOINT = 1440;

const FullPageContentContainer: React.FC<Props & SizeMeProps> = ({
  headerTitle,
  headerSubtitle,
  primaryActionConfig,
  backBtnConfig,
  leftSideBarTopContent,
  leftSideBarBottomContent,
  bodyContent,
  bottomBannerConfig,
  size,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div
        className={cx(classes.contentContainer, {
          centerAlign: size.width && size.width < LAYOUT_BREAKPOINT,
        })}>
        <div className={classes.container}>
          <div
            className={cx(classes.contentBody, {
              [classes.contentBodyWithBanner]: bottomBannerConfig,
            })}>
            {headerTitle && (
              <div className={classes.header}>
                <div className={classes.headerTitle}>{headerTitle}</div>
                {headerSubtitle && <div className={classes.headerSubtitle}>{headerSubtitle}</div>}
              </div>
            )}
            <div className={classes.bodyContent}>{bodyContent}</div>
          </div>
          {bottomBannerConfig && (
            <div
              className={cx(classes.bottomBanner, {
                success: bottomBannerConfig.intent === Intent.SUCCESS,
                danger: bottomBannerConfig.intent === Intent.DANGER,
              })}>
              <Icon
                icon={bottomBannerConfig.intent === Intent.SUCCESS ? 'tick' : 'cross'}
                className={cx(classes.bottomBannerIcon, {
                  success: bottomBannerConfig.intent === Intent.SUCCESS,
                  danger: bottomBannerConfig.intent === Intent.DANGER,
                })}
                iconSize={10}
                color={theme.palette.ds.white}
              />
              <div className={classes.bottomBannerText}>
                <Tooltip
                  content={<div className={classes.bannerTooltip}>{bottomBannerConfig.text}</div>}
                  targetClassName={classes.bannerTooltipTarget}>
                  {bottomBannerConfig.text}
                </Tooltip>
              </div>
            </div>
          )}
          <div className={classes.footer}>
            <div className={classes.backButton}>
              {backBtnConfig && (
                <Button
                  minimal
                  disabled={backBtnConfig.disabled}
                  onClick={backBtnConfig.onClick}
                  icon="arrow-left"
                />
              )}
            </div>
            <div className={classes.actionButtons}>
              <Button
                className={classes.primaryButton}
                disabled={primaryActionConfig.disabled}
                loading={primaryActionConfig.loading}
                onClick={primaryActionConfig.onClick}
                type="primary"
                text={primaryActionConfig.text}
              />
            </div>
          </div>
        </div>
        <div
          className={cx(classes.leftSideBar, {
            centerAlign: size.width && size.width < LAYOUT_BREAKPOINT,
          })}>
          <div className={classes.leftSideBarTop}>{leftSideBarTopContent}</div>
          <div className={classes.leftSideBarBottom}>{leftSideBarBottomContent}</div>
        </div>
      </div>
    </div>
  );
};

export default withSize()(FullPageContentContainer);
