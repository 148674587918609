/** @format */

import React from 'react';
import _ from 'underscore';
import Highcharts from 'highcharts';
import { dayjs, format } from 'utils/localizationUtils';
import ReactDOMServer from 'react-dom/server';

import HighCharts from './highCharts';
import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';

import {
  getColorColNames,
  xAxisFormat,
  formatLegend,
  formatLabel,
  getColorPalette,
  formatValue,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  areRequiredVariablesSetTwoDimViz,
} from './utils';
import { LineElasticity, SortOption, V2TwoDimensionChartInstructions } from 'constants/types';
import { DATE_TYPES } from 'constants/dataConstants';
import { DashboardVariableMap, DashboardVariable } from 'types/dashboardTypes';
import { Dataset, TableColumn } from 'actions/types';
import { getColDisplayText } from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/vizConfigs/utils';
import { GlobalStyleConfig } from 'globalStyles/types';
import { COMBO_CHART_DATA_FORMATS } from 'constants/dashboardConstants';
import {
  createYAxisBaseTooltip,
  getMultiYAxisInstructions,
  getSingleYAxisInstructions,
  getValueFormat,
  getYAxisChartIndex,
} from './utils/multiYAxisUtils';
import { formatTwoDimensionalData } from 'dataFormatters/twoDimensionalDataFormatter';

declare global {
  interface PointOptionsObject {
    custom: Record<string, boolean | number | string>;
  }
}

type Props = {
  backgroundColor: string;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
  schema: TableColumn[];
  normalize?: boolean;
  grouped?: boolean;
  horizontal?: boolean;
  globalStyleConfig: GlobalStyleConfig;
  isComboChart?: boolean;
  canUseMultiYAxis?: boolean;
  setVariable?: (value: DashboardVariable) => void;
  dataPanelProvidedId: string;
  dashboardDatasets: Record<string, Dataset>;
};

type State = {};

class BarChart extends React.PureComponent<Props, State> {
  getChartId = () => {
    return `pivotChartContainer${this.props.dataPanelTemplateId}`;
  };

  render() {
    const { instructions, loading, variables } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    if (loading || !isTwoDimVizInstructionsReadyToDisplay(instructions) || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }

    return <HighCharts chartOptions={this._spec()} />;
  }

  _spec = (): Highcharts.Options | undefined => {
    const {
      previewData,
      schema,
      instructions,
      normalize,
      backgroundColor,
      globalStyleConfig,
      canUseMultiYAxis,
      setVariable,
      variables,
      dashboardDatasets,
    } = this.props;
    if (schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();
    const stacking = this.getStacking();
    const { valueFormatId, decimalPlaces } = getValueFormat(instructions?.yAxisFormats?.[0]);
    const showMarkers = !instructions?.chartSpecificFormat?.lineChart?.hideMarkers;
    const lineWidth = instructions?.chartSpecificFormat?.lineChart?.lineWidth || 2;

    const data = this.filterToMaxCategories(this.transformData());

    return {
      chart: {
        type: this.getChartType(),
        backgroundColor,
      },
      //@ts-ignore
      series: data,
      title: {
        text: undefined,
      },
      colors: getColorPalette(
        globalStyleConfig,
        instructions?.colorFormat?.selectedPalette,
        instructions?.colorFormat?.customColors,
      ),
      plotOptions: {
        series: {
          animation: false,
          allowPointSelect: instructions?.drilldown?.categorySelectEnabled,
          cursor: instructions?.drilldown?.categorySelectEnabled ? 'pointer' : undefined,
          point: {
            events: {
              select: function () {
                if (!instructions?.drilldown?.categorySelectEnabled) return;
                if (instructions.colorColumn) {
                  setVariable?.({
                    category: this.category,
                    color: data[this.colorIndex].rawColorData,
                  });
                  return;
                }
                setVariable?.({ category: this.category });
              },
              unselect: function () {
                if (!instructions?.drilldown?.categorySelectEnabled) return;

                if (this.series.chart.getSelectedPoints().length > 0) return;
                setVariable?.(undefined);
              },
            },
          },
          stacking: stacking,
          states: {
            hover: {
              borderColor: '#000000',
            },
          },
          dataLabels: {
            enabled: instructions?.xAxisFormat?.showBarValues,

            formatter: function () {
              if (stacking === 'percent') return `${format('0.2f')(this.percentage || 0)}%`;
              return formatValue({
                value: this.y || 0,
                decimalPlaces,
                formatId: valueFormatId,
                hasCommas: true,
              });
            },

            style: {
              textOutline: 'none',
              ...getLabelStyle(globalStyleConfig, 'primary'),
            },
          },
          lineWidth: lineWidth,
          //@ts-ignore
          borderRadius: instructions?.xAxisFormat?.barCornerRadius,
        },
        spline: {
          marker: {
            enabled: showMarkers,
          },
        },
        line: {
          marker: {
            enabled: showMarkers,
          },
        },
      },
      yAxis: canUseMultiYAxis
        ? getMultiYAxisInstructions(globalStyleConfig, instructions, variables, dashboardDatasets)
        : getSingleYAxisInstructions(globalStyleConfig, instructions, variables, dashboardDatasets),
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        type: this.getXAxisType(),
        categories: this.getAxisCategories(),
        labels: {
          formatter: function () {
            return formatLabel(
              this.value,
              instructions?.categoryColumn?.column.type,
              instructions?.categoryColumn?.bucket?.id,
              instructions?.xAxisFormat?.dateFormat,
              instructions?.xAxisFormat?.stringFormat,
            );
          },
          style: getLabelStyle(globalStyleConfig, 'secondary'),
        },
      },
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
      },
      tooltip: {
        formatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            createYAxisBaseTooltip({
              tooltipFormatter: this,
              globalValueFormatId: valueFormatId,
              globalDecimalPlaces: decimalPlaces,
              globalStyleConfig: globalStyleConfig,
              instructions: instructions,
              includePercent: normalize || instructions?.tooltipFormat?.showPct,
            }),
          );
        },
        padding: 0,
        borderWidth: 0,
        borderRadius: 0,
        backgroundColor: '#FFFFFF00',
        shadow: false,
        useHTML: true,
        outside: true,
        followPointer: true,
      },
    };
  };

  getChartType = () => {
    const { horizontal } = this.props;
    if (horizontal) return 'bar';
    return 'column';
  };

  getComboChartType = (baseType?: COMBO_CHART_DATA_FORMATS) => {
    const { instructions } = this.props;

    if (baseType === COMBO_CHART_DATA_FORMATS.BAR) return baseType;

    return instructions?.chartSpecificFormat?.lineChart?.elasticity === LineElasticity.STRAIGHT
      ? 'line'
      : 'spline';
  };

  getXAxisColName = () => {
    const { schema } = this.props;

    return schema[0].name;
  };

  getXAxisType = () => {
    const { instructions } = this.props;
    if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return 'datetime';
  };

  getSortingEnable = () => {
    const { instructions } = this.props;
    return (
      instructions?.xAxisFormat?.sortOption === SortOption.AGG_AXIS_DESC ||
      instructions?.xAxisFormat?.sortOption === SortOption.AGG_AXIS_ASC
    );
  };

  getAxisCategories = () => {
    const { instructions, previewData, schema } = this.props;
    if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return;
    const xAxisColName = this.getXAxisColName();

    if (this.getSortingEnable()) {
      const valByCategory: Record<string, number> = {};
      previewData.forEach((row) => {
        const category = row[xAxisColName];
        if (!valByCategory[category]) {
          valByCategory[category] = 0;
        }
        if (instructions?.colorColumn) {
          const { aggColName } = getColorColNames(schema);
          valByCategory[category] += row[aggColName] as number;
        } else {
          const aggColNames = schema.map((col) => col.name).slice(1);
          aggColNames.map((aggCol) => (valByCategory[category] += row[aggCol] as number));
        }
      });
      return _.sortBy(Object.keys(valByCategory), (category) =>
        instructions?.xAxisFormat?.sortOption === SortOption.AGG_AXIS_ASC
          ? valByCategory[category]
          : -valByCategory[category],
      );
    } else {
      const categories = new Set(previewData.map((row) => String(row[xAxisColName])));
      return Array.from(categories);
    }
  };

  getStacking = () => {
    const { normalize, grouped } = this.props;

    if (normalize) {
      return 'percent';
    } else if (grouped) {
      return;
    } else {
      return 'normal';
    }
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;

    if (
      !previewData ||
      !DATE_TYPES.has(instructions?.categoryColumn?.column.type || '') ||
      !schema ||
      schema.length === 0
    )
      return;

    const xAxisColName = this.getXAxisColName();

    previewData.forEach((row) => {
      if (!instructions?.categoryColumn?.column.type) return;
      row[xAxisColName] = dayjs.utc(row[xAxisColName]).valueOf();
    });
  };

  bringLinesToFront = (
    seriesList: {
      name: string;
      type: string;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      data: any;
      color?: string | undefined;
    }[],
  ) => {
    return _.flatten(
      _.partition(seriesList, (series) => series.type !== 'line' && series.type !== 'spline'),
    );
  };

  transformData = () => {
    // This is for when there are multiple bars/lines selected
    const { instructions, schema, isComboChart } = this.props;
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');

    if (
      !instructions?.aggColumns ||
      instructions.aggColumns.length === 0 ||
      !schema ||
      schema.length === 0
    )
      return [];

    let seriesList;
    if (instructions.colorColumn) {
      seriesList = this.transformColorData(schema);
    } else {
      seriesList = this.transformAggColsData(schema);
    }

    if (isComboChart) seriesList = this.bringLinesToFront(seriesList);

    if (!isDate && this.getSortingEnable()) {
      const orderedCategory = this.getAxisCategories();
      seriesList.forEach((series) => {
        series.data = _.sortBy(series.data, (row: { name: string }) =>
          orderedCategory?.indexOf(row.name),
        );
      });
    }

    return seriesList;
  };

  transformColorData = (schema: TableColumn[]) => {
    const { instructions, previewData } = this.props;
    const { xAxisColName, colorColName, aggColName } = getColorColNames(schema);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');
    const categoryOrder = this.getAxisCategories();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const series: Record<
      string,
      { name: string; rawColorData: number | string; type: string; data: any; color?: string }
    > = {};

    previewData.forEach((row) => {
      if (isDate && row[xAxisColName] === undefined) return;
      const colorCategory = formatLabel(
        row[colorColName],
        instructions?.colorColumn?.column.type,
        instructions?.colorColumn?.bucket?.id,
      );
      const entry = isDate
        ? [row[xAxisColName], row[aggColName] as number]
        : {
            name: String(row[xAxisColName]),
            y: row[aggColName] as number,
            x: categoryOrder?.indexOf(String(row[xAxisColName])),
          };
      if (series[colorCategory]) {
        series[colorCategory].data.push(entry);
      } else {
        series[colorCategory] = {
          type: this.getChartType(),
          name: colorCategory,
          rawColorData: row[colorColName],
          data: [entry],
        };
      }
    });

    return Object.values(series);
  };

  selectedCategoryIfDrilldown = () => {
    const { variables, instructions, dataPanelProvidedId } = this.props;

    if (instructions?.drilldown?.categorySelectEnabled) {
      return variables[dataPanelProvidedId];
    }
  };

  transformAggColsData = (schema: TableColumn[]) => {
    const { previewData, instructions, isComboChart, canUseMultiYAxis } = this.props;
    const xAxisColName = schema[0].name;
    const aggCols = instructions?.aggColumns || [];
    const aggColNames = schema.map((col) => col.name).slice(1);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');

    const series: Record<
      string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { name: string; type: string; data: any; color?: string; yAxis?: number }
    > = {};

    formatTwoDimensionalData(previewData, instructions).forEach((row) => {
      aggColNames.forEach((colName, index) => {
        if (isDate && row[xAxisColName] === undefined) return;
        const aggCol = aggCols[index];

        const entry = isDate
          ? {
              x: row[xAxisColName],
              y: row[colName] as number,
              selected: this.selectedCategoryIfDrilldown() === row[xAxisColName],
            }
          : {
              name: String(row[xAxisColName]),
              y: row[colName] as number,
              selected: this.selectedCategoryIfDrilldown() === String(row[xAxisColName]),
            };

        if (series[colName]) {
          series[colName].data.push(entry);
        } else {
          series[colName] = {
            type: isComboChart
              ? this.getComboChartType(aggCol.column.dataFormat)
              : this.getChartType(),
            name: aggCol.column.friendly_name || getColDisplayText(aggCol) || colName,
            data: [entry],
            yAxis: getYAxisChartIndex(aggCol.yAxisFormatId, canUseMultiYAxis, instructions),
          };
        }
      });
    });
    return Object.values(series);
  };

  filterToMaxCategories = (data: any) => {
    const { instructions } = this.props;

    // PD-1183: max categories isn't going to be supported for charts broken down by color for now
    if (instructions?.colorColumn) return data;

    data.forEach((series: any) => {
      series.data = _.filter(series.data, (_, index) => {
        return instructions?.xAxisFormat?.maxCategories
          ? index < instructions?.xAxisFormat?.maxCategories
          : true;
      });
    });

    return data;
  };
}

export default BarChart;
