/** @format */
import { defineAPIAction } from 'actions/actionUtils';
import { ACTION } from './types';
import { FetchWhitelistDomainsData, CreateWhitelistDomainData } from 'actions/responseTypes';

export const { actionFn: fetchWhitelistDomains } = defineAPIAction<FetchWhitelistDomainsData>(
  ACTION.FETCH_WHITELIST_DOMAIN,
  'whitelist_domains',
  '',
  'GET',
);

export const { actionFn: createWhitelistDomain } = defineAPIAction<CreateWhitelistDomainData>(
  ACTION.CREATE_WHITELIST_DOMAIN,
  'whitelist_domains',
  '',
  'POST',
);

export const { actionFn: deleteWhitelistDomain } = defineAPIAction(
  ACTION.DELETE_WHITELIST_DOMAIN,
  'whitelist_domains',
  '',
  'DELETE',
);
