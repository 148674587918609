/** @format */
import { defineAPIAction } from 'actions/actionUtils';
import { ACTION } from './types';
import {
  FetchParentSchemasData,
  CreateParentSchemaData,
  FetchSchemaTablesMappingData,
  SyncParentSchemaData,
} from 'actions/responseTypes';

export const { actionFn: fetchParentSchemas } = defineAPIAction<FetchParentSchemasData>(
  ACTION.FETCH_PARENT_SCHEMAS,
  'schema',
  'get_schemas',
  'GET',
);

export const { actionFn: fetchAllSchemaTables } = defineAPIAction<FetchSchemaTablesMappingData>(
  ACTION.FETCH_ALL_SCHEMA_TABLES_FOR_TEAM,
  'schema',
  'all_tables',
  'GET',
);

export const { actionFn: createParentSchema } = defineAPIAction<CreateParentSchemaData>(
  ACTION.CREATE_PARENT_SCHEMA,
  'schema',
  'create_schema',
  'POST',
);

export const { actionFn: editParentSchema } = defineAPIAction<CreateParentSchemaData>(
  ACTION.EDIT_PARENT_SCHEMA,
  'schema',
  'edit',
  'POST',
);

export const { actionFn: deleteParentSchema } = defineAPIAction(
  ACTION.DELETE_PARENT_SCHEMA,
  'schema',
  'delete',
  'POST',
);

export const { actionFn: syncParentSchema } = defineAPIAction<SyncParentSchemaData>(
  ACTION.SYNC_PARENT_SCHEMA,
  'schema',
  'sync_schema',
  'POST',
);
