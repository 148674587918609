/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withTheme, WithTheme } from '@material-ui/core/styles';
import { Menu, MenuItem, Popover, Position, Button } from '@blueprintjs/core';

import { TRIPLE_DOT_MENU } from 'constants/iconConstants';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 220,
      backgroundColor: 'white',
      borderRadius: 3,
      border: `1px solid ${theme.palette.ds.blue}`,
    },
    actionsMenu: {
      overflow: 'auto',
    },
    operationActionsPopover: {
      boxShadow: 'none',
    },
    portal: {
      '& .bp3-transition-container': {
        zIndex: 0,
      },
    },
  });

type PassedProps = {
  actions: React.ComponentProps<typeof MenuItem>[];
  className?: string;
};

type Props = PassedProps & WithStyles<typeof styles> & WithTheme;

class DotsDropdown extends React.Component<Props> {
  render() {
    const { classes, className, theme } = this.props;

    return (
      <Popover
        portalClassName={classes.portal}
        popoverClassName={classes.operationActionsPopover}
        targetClassName={className}
        position={Position.BOTTOM_RIGHT}
        // captureDismiss={true}
        minimal
        content={<div className={classes.root}>{this.renderPanelMenu()}</div>}>
        <Button minimal icon={TRIPLE_DOT_MENU(theme.palette.ds.grey700)} />
      </Popover>
    );
  }

  renderPanelMenu = () => {
    const { classes, actions } = this.props;
    return (
      <Menu className={classes.actionsMenu}>
        {actions.map((actionConfig: React.ComponentProps<typeof MenuItem>) => (
          <MenuItem key={_.uniqueId('flow_action_item')} {...actionConfig} />
        ))}
      </Menu>
    );
  };
}

export default withStyles(styles)(withTheme(DotsDropdown));
