/** @format */

import React from 'react';
import { Dialog, Icon, InputGroup, Intent } from '@blueprintjs/core';
import { withStyles, WithStyles, withTheme, WithTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import _ from 'underscore';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Button from 'shared/Button';
import DeleteConfirmButton from 'components/core/DeleteConfirmButton';
import SearchBar from 'shared/SearchBar';
import DropdownSelect from 'shared/DropdownSelect';
import { STRING, SCHEMA_DATA_TYPES_BY_ID, SELECTABLE_DATA_TYPES } from 'constants/dataConstants';
import { DashboardParam } from 'actions/types';
import { updateDashboardTemplateParams } from 'actions/dashboardV2Actions';

import { ACTION } from 'actions/types';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ReduxState } from 'reducers/rootReducer';

const styles = (theme: Theme) => ({
  customVariableModal: {
    margin: `${theme.spacing(5)}px ${theme.spacing(5)}px 0px ${theme.spacing(5)}px`,
  },
  customVariablesContainer: {
    height: '40vh',
    overflowY: 'auto' as 'auto',
    marginBottom: theme.spacing(6),
  },
  customVariableRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing(5)}px 0px ${theme.spacing(5)}px`,
  },
  customVariableRowTextInput: {
    flexGrow: 1,
    margin: `0px ${theme.spacing(3)}px 0px ${theme.spacing(3)}px`,
  },
  customVariableAddIcon: {
    margin: `0px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
  },
  customVariableButtonRow: {
    display: 'flex',
    width: '100%',
  },
  customVariableNewVariableContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  customVariableCancelButton: {
    marginRight: theme.spacing(4),
  },
  typeDropdown: {
    width: 150,
  },
});

type PassedProps = {
  dashboardTemplateId: number;
  isOpen: boolean;
  onClose: () => void;
  closeModal: () => void;
  dashboardParams: Record<string, DashboardParam>;
};

type Props = PassedProps &
  WithStyles<typeof styles> &
  WithTheme &
  typeof mapDispatchToProps &
  PassedProps &
  WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type State = {
  searchText: string;
  customVariables: Record<string, DashboardParam>;
};

class ManageCustomVariableModal extends React.Component<Props, State> {
  state: State = {
    searchText: '',
    customVariables: cloneDeep(this.props.dashboardParams),
  };

  render() {
    const { classes, isOpen, onClose, theme } = this.props;
    return (
      <Dialog
        isCloseButtonShown={false}
        style={{ backgroundColor: theme.palette.ds.white }}
        isOpen={isOpen}
        onClose={onClose}
        title="Custom Variables">
        <div className={classes.customVariableModal}>
          {this.renderSearchBar()}
          {this.renderCustomVariableRows()}
          {this.renderButtonRow()}
        </div>
      </Dialog>
    );
  }

  renderSearchBar = () => {
    return (
      <SearchBar
        onChange={(newSearchText) => {
          this.setState({ searchText: newSearchText.toLowerCase() });
        }}
      />
    );
  };

  renderCustomVariableRows = () => {
    const { classes } = this.props;
    const { customVariables, searchText } = this.state;

    return (
      <div className={classes.customVariablesContainer}>
        {Object.values(customVariables)
          .filter((customVariable) =>
            (customVariable.name || '').toLowerCase().includes(searchText),
          )
          .map(this.renderCustomVariableRow)}
      </div>
    );
  };

  renderCustomVariableRow = (customVariable: DashboardParam) => {
    const { classes } = this.props;
    const { name, type } = customVariable;
    return (
      <div key={_.uniqueId('custom_variable_row')} className={classes.customVariableRow}>
        {this.renderCustomVariableTypeDropdown(customVariable.id, type)}
        {this.renderCustomVariableNameInput(customVariable.id, name)}
        <DeleteConfirmButton
          onDelete={() => {
            this.onVariableDelete(customVariable.id);
          }}
        />
      </div>
    );
  };

  onVariableChange = (fieldName: 'name' | 'type', id: string, newValue: string) => {
    this.setState((prevState) => {
      const { customVariables } = prevState;
      customVariables[id] = {
        ...customVariables[id],
        [fieldName]: newValue,
      };

      return {
        customVariables,
      };
    });
  };

  onVariableAdded = () => {
    const { dashboardTemplateId } = this.props;
    this.setState((prevState) => {
      const { customVariables } = prevState;
      const newId = `dash${dashboardTemplateId}-${uuidv4()}`;

      customVariables[newId] = {
        id: newId,
        name: '',
        type: STRING,
      };

      return {
        customVariables,
      };
    });
  };

  onVariableDelete = (id: string) => {
    this.setState((prevState) => {
      const { customVariables } = prevState;
      delete customVariables[id];

      return {
        customVariables,
      };
    });
  };

  renderCustomVariableTypeDropdown = (id: string, type?: string) => {
    const { classes } = this.props;
    return (
      <div className={classes.typeDropdown}>
        <DropdownSelect
          filterable={false}
          fillWidth
          showIcon={true}
          noSelectionText="Data Type"
          selectedItem={
            type
              ? {
                  id: type,
                  ...SCHEMA_DATA_TYPES_BY_ID[type],
                }
              : undefined
          }
          options={SELECTABLE_DATA_TYPES.map((dataType) => ({
            id: dataType,
            ...SCHEMA_DATA_TYPES_BY_ID[dataType],
          }))}
          onChange={(selectedType) => {
            this.onVariableChange('type', id, selectedType.id);
          }}
        />
      </div>
    );
  };

  renderCustomVariableNameInput = (id: string, name?: string) => {
    const { classes } = this.props;
    return (
      <InputGroup
        intent={this.isInvalidVariableName(name) ? Intent.DANGER : undefined}
        className={classes.customVariableRowTextInput}
        placeholder="variable_name"
        defaultValue={name}
        onBlur={(event) =>
          this.onVariableChange('name', id, this.cleanVariableName(event.currentTarget.value))
        }
      />
    );
  };

  renderButtonRow = () => {
    const { classes } = this.props;
    return (
      <div className={classes.customVariableButtonRow}>
        <div className={classes.customVariableNewVariableContainer}>
          {this.renderCreateButton()}
        </div>
        <div className={classes.customVariableCancelButton}>{this.renderCancelButton()}</div>
        {this.renderSaveButton()}
      </div>
    );
  };

  renderCreateButton = () => {
    const { theme } = this.props;
    return (
      <Button
        type="primary"
        icon={
          <Icon
            // Need to override default -7 horizontal margins, classname doesn't override
            style={{ marginLeft: 0, marginRight: `${theme.spacing(2)}px` }}
            icon="add"
            color={theme.palette.ds.white}
          />
        }
        onClick={this.onVariableAdded}
        text="New Variable"
      />
    );
  };

  renderCancelButton = () => {
    const { closeModal } = this.props;
    return <Button type="secondary" text="Cancel" onClick={closeModal} />;
  };

  renderSaveButton = () => {
    // comment disale save button once it is loading , when loading === True
    const { updateDashTemplateSaving } = this.props;
    return (
      <Button
        type="primary"
        onClick={this.onSave}
        disabled={this.variablesInvalid() || updateDashTemplateSaving}
        loading={updateDashTemplateSaving}
        text="Save"
      />
    );
  };

  onSave = () => {
    const { closeModal, updateDashTemplateSaving, updateDashboardTemplateParams } = this.props;
    const { customVariables } = this.state;

    if (this.variablesInvalid() || updateDashTemplateSaving) return;

    updateDashboardTemplateParams({ newParams: customVariables });
    closeModal();
  };

  variablesInvalid = () => {
    const { customVariables } = this.state;

    const variables = _.values(customVariables);

    return _.some(_.map(variables, (variable) => this.isInvalidVariableName(variable.name)));
  };

  isInvalidVariableName = (name?: string) => {
    if (!name || name.length === 0) return true;

    return false;
  };

  cleanVariableName = (name: string) => {
    name = name.trim();
    name = name.replace(/\s/g, '_');

    if (name.length > 0 && !isNaN(+name[0])) name = '_' + name;

    return name;
  };
}

const mapStateToProps = (state: ReduxState) => ({
  // comment checking if the panel is currently updating data
  updateDashTemplateSaving: createLoadingSelector(
    [ACTION.UPDATE_DASHBOARD_TEMPLATE_PARAMS],
    false,
  )(state),
});

const mapDispatchToProps = {
  updateDashboardTemplateParams,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withTheme(ManageCustomVariableModal)));
