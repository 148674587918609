/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Theme, WithStyles } from '@material-ui/core/index';
import { Icon } from '@blueprintjs/core';
import { connect } from 'react-redux';

import Button from 'shared/Button';
import FilterConfigMenu from 'pages/dataPanelEditorPage/filterConfigMenu';
import SummarizeConfigMenu from 'pages/dataPanelEditorPage/summarizeConfigMenu';
import VisualizeConfigMenu from 'pages/dataPanelEditorPage/visualizeConfigMenu';
import ConfirmationModal from 'components/modals/confirmationModal';
import TextFieldModal from 'components/modals/textFieldModal';
import NavTabs from 'components/core/navTabs';

import { ReduxState } from 'reducers/rootReducer';
import { ACTION, DataPanelTemplate, TableColumn, DataPanelConfigReducerState } from 'actions/types';
import { deleteDataPanelV2, renameDataPanelV2 } from 'actions/dashboardV2Actions';
import {
  shouldRecomputeDataForDataPanel,
  shouldRecomputeSecondaryDataForDataPanel,
} from 'utils/dataPanelConfigUtils';
import { trackEvent } from 'analytics/exploAnalytics';
import { createLoadingSelector } from 'reducers/api/selectors';

type ConfigTabInfo = { id: string; name: string };

const CONFIG_TABS: { [id: string]: ConfigTabInfo } = {
  FILTER: {
    id: 'FILTER',
    name: 'Filter',
  },
  SUMMARIZE: {
    id: 'SUMMARIZE',
    name: 'Summarize',
  },
  VISUALIZE: {
    id: 'VISUALIZE',
    name: 'Visualize',
  },
};

const TABS_HEIGHT = 40;
const HEADER_HEIGHT = 67;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.ds.white,
      borderRight: `1px solid ${theme.palette.ds.grey400}`,
    },
    bodyContainer: {
      backgroundColor: theme.palette.ds.white,
      height: `calc(100% - ${HEADER_HEIGHT}px)`,
    },
    configTabsMenu: {
      flexBasis: 0,
      height: TABS_HEIGHT,
      borderBottom: `1px solid ${theme.palette.ds.grey400}`,
    },
    configTabBtn: {
      width: '100%',
    },
    configMenu: {
      padding: theme.spacing(6),
      height: `calc(100% - ${TABS_HEIGHT}px)`,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    configurationHeader: {
      height: HEADER_HEIGHT,
      paddingRight: `${theme.spacing(2)}px`,
      paddingLeft: `${theme.spacing(6)}px`,
      borderBottom: `1px solid ${theme.palette.ds.grey400}`,
    },
    headerMainSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
    dataTableNameContainer: {
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.ds.grey500,
      marginRight: theme.spacing(2),
    },
    dataTableName: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    dataTableIcon: {
      marginRight: theme.spacing(2),
    },
    headerActions: {
      display: 'flex',
      alignItems: 'center',
    },
    headerActionBtn: {
      marginRight: theme.spacing(2),
    },
    elementIdTag: {
      fontWeight: 'bold' as 'bold',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  });

type PassedProps = {
  baseSchema: TableColumn[];
  computedSchema: TableColumn[];
  deleteDataPanelTemplate: () => void;
  dataPanelTemplate: DataPanelTemplate;
  unselectElement: () => void;
  dataPanelConfigState: DataPanelConfigReducerState;
  userGroupId?: number;
  sourceTableName?: string;
};

type Props = PassedProps &
  WithStyles<typeof styles> &
  RouteComponentProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type State = {
  selectedConfigTabId: string;
  deleteConfirmationModalOpen?: boolean;
  renameDataPanelModalOpen?: boolean;
};

class DataPanelConfigMenu extends React.Component<Props, State> {
  state: State = {
    selectedConfigTabId: CONFIG_TABS.VISUALIZE.id,
  };

  componentDidUpdate(prevProps: Props) {
    // Don't recompute if the config changes because we're switching between
    // different data panels
    if (prevProps.dataPanelTemplate.id !== this.props.dataPanelTemplate.id) return;

    const { shouldRecompute } = shouldRecomputeDataForDataPanel(
      prevProps.dataPanelConfigState,
      this.props.dataPanelConfigState,
    );
    const shouldRecomputeSecondaryData = shouldRecomputeSecondaryDataForDataPanel(
      prevProps.dataPanelConfigState,
      this.props.dataPanelConfigState,
    );

    window.dispatchEvent(
      new CustomEvent('dataPanelConfigUpdated', {
        detail: {
          shouldRecompute,
          shouldRecomputeSecondaryData,
          dataPanelTemplateId: this.props.dataPanelTemplate.id,
        },
      }),
    );
  }

  render = () => {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderDashboardTitle()}
        {this.renderConfigBody()}
        {this.renderDeleteConfirmationModal()}
        {this.renderRenameDataPanelModal()}
      </div>
    );
  };

  renderDashboardTitle = () => {
    const { classes, dataPanelTemplate, dataPanelRenameSaving, sourceTableName } = this.props;
    return (
      <div className={classes.configurationHeader}>
        <div className={classes.headerMainSection}>
          <div className={classes.elementIdTag}>{dataPanelTemplate.name}</div>
          <div className={classes.headerActions}>
            <Button
              minimal
              className={classes.headerActionBtn}
              disabled={dataPanelRenameSaving}
              icon="edit"
              loading={dataPanelRenameSaving}
              onClick={() => this.setState({ renameDataPanelModalOpen: true })}
            />
            <Button
              minimal
              className={classes.headerActionBtn}
              icon="trash"
              onClick={() => this.setState({ deleteConfirmationModalOpen: true })}
              type="destructive"
            />
          </div>
        </div>
        <div className={classes.dataTableNameContainer}>
          <Icon icon="th" className={classes.dataTableIcon} />
          <div className={classes.dataTableName}>{sourceTableName}</div>
        </div>
      </div>
    );
  };

  renderConfigBody() {
    const { classes } = this.props;
    return (
      <div className={classes.bodyContainer}>
        {this.renderTabsMenu()}
        <div className={classes.configMenu}>{this.renderTabConfigOptions()}</div>
      </div>
    );
  }

  renderTabsMenu = () => {
    const { classes } = this.props;
    const { selectedConfigTabId } = this.state;

    return (
      <NavTabs
        className={classes.configTabsMenu}
        tabClassName={classes.configTabBtn}
        tabs={[CONFIG_TABS.FILTER, CONFIG_TABS.SUMMARIZE, CONFIG_TABS.VISUALIZE]}
        selectedTabId={selectedConfigTabId}
        onTabSelect={(tabId) => this.switchConfigTab(tabId)}
      />
    );
  };

  switchConfigTab = (tabId: string) => {
    const { selectedConfigTabId } = this.state;
    if (selectedConfigTabId === tabId) return;
    this.setState({ selectedConfigTabId: tabId });
  };

  renderTabConfigOptions = () => {
    const { baseSchema, dataPanelConfigState, dataPanelTemplate } = this.props;
    const { selectedConfigTabId } = this.state;

    switch (selectedConfigTabId) {
      case CONFIG_TABS.FILTER.id:
        return (
          <FilterConfigMenu
            filterOperation={dataPanelConfigState.filterOperation}
            baseSchema={baseSchema}
          />
        );
      case CONFIG_TABS.SUMMARIZE.id:
        return (
          <SummarizeConfigMenu
            pivotOperation={dataPanelConfigState.pivotOperation}
            baseSchema={baseSchema}
          />
        );
      case CONFIG_TABS.VISUALIZE.id:
        return (
          <VisualizeConfigMenu
            dataPanelTemplate={dataPanelTemplate}
            visualizeOperation={dataPanelConfigState.visualizeOperation}
            sortOperation={dataPanelConfigState.sortOperation}
          />
        );
      default:
        return <div></div>;
    }
  };

  renderDeleteConfirmationModal = () => {
    const { deleteDataPanelV2, dataPanelTemplate, unselectElement } = this.props;
    const { deleteConfirmationModalOpen } = this.state;

    if (!deleteConfirmationModalOpen) return;

    return (
      <ConfirmationModal
        isDestructive
        modalOpen={deleteConfirmationModalOpen}
        closeModal={() => this.setState({ deleteConfirmationModalOpen: false })}
        modalTitle="Are you sure you want to delete this data panel?"
        cancelBtnText="Cancel"
        confirmBtnText="Delete"
        onConfirm={() => {
          this.setState({
            deleteConfirmationModalOpen: false,
          });
          unselectElement();
          deleteDataPanelV2({ id: dataPanelTemplate.id });

          trackEvent('Deleted data panel', {
            data_panel_template_id: dataPanelTemplate.id,
          });
        }}
      />
    );
  };

  renderRenameDataPanelModal = () => {
    const { renameDataPanelV2, dataPanelTemplate } = this.props;
    const { renameDataPanelModalOpen } = this.state;

    if (!renameDataPanelModalOpen) return;

    return (
      <TextFieldModal
        resourceName={dataPanelTemplate.name}
        modalOpen={renameDataPanelModalOpen}
        closeModal={() => this.setState({ renameDataPanelModalOpen: false })}
        modalTitle="Enter Data Panel Name"
        buttonName="Save"
        textFieldPlaceholder="Data panel name"
        onSubmit={(name: string) => {
          this.setState({ renameDataPanelModalOpen: false });
          renameDataPanelV2({ id: dataPanelTemplate.id, name });
          trackEvent('Renamed data panel', {
            data_panel_template_id: dataPanelTemplate.id,
            name: name,
          });
        }}
      />
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  dataPanelRenameSaving: createLoadingSelector([ACTION.RENAME_DATA_PANEL_TEMPLATE], false)(state),
});

const mapDispatchToProps = {
  deleteDataPanelV2,
  renameDataPanelV2,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(DataPanelConfigMenu)));
