/** @format */

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { Dataset } from 'actions/types';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  AggedChartColumnInfo,
  OPERATION_TYPES,
  V2BoxPlotInstructions,
  V2TwoDimensionChartInstructions,
  YAxisFormat,
} from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { TooltipFormatterContextObject, YAxisOptions } from 'highcharts';
import { cloneDeep } from 'lodash';
import _ from 'underscore';
import {
  formatLabel,
  formatValue,
  getColorPalette,
  getLabelStyle,
  goalBandFormat,
  goalLineFormat,
  yAxisFormat,
} from 'pages/dashboardPage/charts/utils';
import { getColDisplayText } from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/vizConfigs/utils';
import { Dispatch } from 'redux';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { v4 as uuidv4 } from 'uuid';
import BaseTooltip from '../tooltips/BaseTooltip';
import React from 'react';

export const getSingleYAxisInstructions = (
  globalStyleConfig: GlobalStyleConfig,
  instructions?: V2TwoDimensionChartInstructions | V2BoxPlotInstructions,
  variables?: DashboardVariableMap,
  dashboardDatasets?: Record<string, Dataset>,
): YAxisOptions => {
  // Historical context: When we migrated V2TwoDimensionChartInstructions over to yAxisFormats, the yAxisFormat field for
  // old charts wasn't deleted as a precaution. This means that a boxplot not only must have a yAxisFormat field, it must also
  // not have a yAxisFormats field, otherwise the instructions could actually be V2TwoDimensionChartInstructions for a
  // pre-multi-y-axis chart.
  const areBoxPlotInstructions =
    instructions && 'yAxisFormat' in instructions && !('yAxisFormats' in instructions);
  // Boxplots are the only chart that get yAxisFormat from somewhere other than V2TwoDimensionChartInstructions.yAxisFormats
  const yAxisFormat = areBoxPlotInstructions
    ? (instructions as V2BoxPlotInstructions).yAxisFormat
    : (instructions as V2TwoDimensionChartInstructions)?.yAxisFormats?.[
        DEFAULT_Y_AXIS_FORMAT_INDEX
      ];

  return buildYAxisOptions(
    globalStyleConfig,
    instructions,
    yAxisFormat,
    undefined,
    variables,
    dashboardDatasets,
  );
};

export const getMultiYAxisInstructions = (
  globalStyleConfig: GlobalStyleConfig,
  instructions?: V2TwoDimensionChartInstructions,
  variables?: DashboardVariableMap,
  dashboardDatasets?: Record<string, Dataset>,
): Array<YAxisOptions> => {
  const colors = getColorPalette(
    globalStyleConfig,
    instructions?.colorFormat?.selectedPalette,
    instructions?.colorFormat?.customColors,
  );

  return _.map(instructions?.yAxisFormats ?? [], (yAxisFormat) => {
    const colorOverrideIndex = instructions?.aggColumns?.findIndex((aggColumn) => {
      return (
        aggColumn.agg.name === yAxisFormat?.colorFromAggColumn?.agg.name &&
        aggColumn.column.name === yAxisFormat?.colorFromAggColumn?.column.name
      );
    });
    const colorOverride =
      colorOverrideIndex !== undefined && colorOverrideIndex >= 0
        ? colors?.[colorOverrideIndex % colors.length]
        : undefined;
    return buildYAxisOptions(
      globalStyleConfig,
      instructions,
      yAxisFormat,
      colorOverride,
      variables,
      dashboardDatasets,
    );
  });
};

/**
 * This function gets the index of the Y-Axis that we will assign the aggColumn data to in the chart.
 */
export const getYAxisChartIndex = (
  yAxisFormatId?: string,
  canUseMultiYAxis?: boolean,
  instructions?: V2TwoDimensionChartInstructions,
) => {
  const index = getYAxisFormatIndex(yAxisFormatId, instructions);
  if (!canUseMultiYAxis || !yAxisFormatId) return DEFAULT_Y_AXIS_CHART_INDEX;
  // If the index for the chart isn't found, default it to 0 so the chart doesn't crash.
  return index >= 0 ? index : DEFAULT_Y_AXIS_CHART_INDEX;
};

export const DEFAULT_Y_AXIS_CHART_INDEX = 0;
export const DEFAULT_Y_AXIS_FORMAT_INDEX = 0;

export const getYAxisFormatIndex = (
  yAxisFormatId?: string,
  instructions?: V2TwoDimensionChartInstructions,
) => {
  return _.findIndex(
    instructions?.yAxisFormats ?? [],
    (yAxisFormat) => yAxisFormat.id === yAxisFormatId,
  );
};

export const getYAxisFormat = (
  yAxisFormatId?: string,
  instructions?: V2TwoDimensionChartInstructions | V2BoxPlotInstructions,
  visualizationType?: string,
) => {
  if (visualizationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
    return (instructions as V2BoxPlotInstructions).yAxisFormat;
  }
  // Instructions must be for 2d charts at this point
  // Non multi-Y-Axis enabled charts won't deal with yAxisFormatId so we need to default their YAxisFormat to the first one.
  if (!yAxisFormatId)
    return (instructions as V2TwoDimensionChartInstructions)?.yAxisFormats?.[
      DEFAULT_Y_AXIS_FORMAT_INDEX
    ];
  const yAxisFormatIndex = getYAxisFormatIndex(yAxisFormatId, instructions);
  return (instructions as V2TwoDimensionChartInstructions)?.yAxisFormats?.[yAxisFormatIndex];
};

export const CAN_USE_MULTI_Y_AXIS_OPS = new Set([
  // Don't allow multi Y-Axis on 100% charts or stacked charts since the two formats are a dichotomy.
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
]);

const buildYAxisOptions = (
  globalStyleConfig: GlobalStyleConfig,
  instructions?: V2TwoDimensionChartInstructions | V2BoxPlotInstructions,
  yAxisFormatData?: YAxisFormat,
  colorOverride?: string,
  variables?: DashboardVariableMap,
  dashboardDatasets?: Record<string, Dataset>,
): YAxisOptions => {
  const { valueFormatId, decimalPlaces } = getValueFormat(yAxisFormatData);
  return {
    allowDecimals: yAxisFormatData?.showDecimals,
    ...yAxisFormat(globalStyleConfig, yAxisFormatData, colorOverride),
    min: yAxisFormatData?.min ?? null,
    max: yAxisFormatData?.max ?? null,
    stackLabels: {
      enabled: !instructions?.xAxisFormat?.hideTotalValues,
      // TODO: Explore ways of formatting the stack labels for Multi Y-Axis Options
      formatter: function () {
        return formatValue({
          value: this.cumulative || 0,
          decimalPlaces,
          formatId: valueFormatId,
          hasCommas: true,
        });
      },
      style: {
        textOutline: 'none',
        ...getLabelStyle(globalStyleConfig, 'primary'),
      },
    },
    labels: {
      // TODO: Explore ways of formatting the stack labels for Multi Y-Axis Options
      formatter: function () {
        if (yAxisFormatData?.showDecimals)
          return formatValue({
            value: this.value || 0,
            decimalPlaces,
            formatId: valueFormatId,
            hasCommas: true,
          });
        return formatValue({
          value: this.value || 0,
          decimalPlaces: 0,
          formatId: valueFormatId,
          hasCommas: true,
        });
      },
      style: getLabelStyle(globalStyleConfig, 'secondary', colorOverride),
    },
    opposite: yAxisFormatData?.oppositeAligned === true,
    ...goalLineFormat(
      (instructions as V2TwoDimensionChartInstructions)?.goalLines,
      variables,
      dashboardDatasets,
    ),
    ...goalBandFormat(
      (instructions as V2TwoDimensionChartInstructions)?.goalLines,
      variables,
      dashboardDatasets,
    ),
  };
};

export const getValueFormat = (yAxisFormat?: YAxisFormat) => {
  return {
    valueFormatId: yAxisFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
    decimalPlaces: yAxisFormat?.decimalPlaces ?? 2,
  };
};

export const addYAxis = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  instructions: V2TwoDimensionChartInstructions,
  visualizationType: string,
) => {
  const newInstructions = cloneDeep(instructions);
  if (!newInstructions.yAxisFormats) {
    newInstructions.yAxisFormats = [];
  }
  newInstructions.yAxisFormats.push({ id: uuidv4() });
  dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
};

export const updateYAxisFormat = (
  newYAxisFormat: YAxisFormat,
  instructions: V2TwoDimensionChartInstructions | V2BoxPlotInstructions,
  visualizationType?: string,
) => {
  const newInstructions = cloneDeep(instructions);
  if (visualizationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
    (newInstructions as V2BoxPlotInstructions).yAxisFormat = newYAxisFormat;
    return newInstructions;
  }
  // All 2d viz charts will use V2TwoDimensionChartInstructions.yAxisFormats
  const newTwoDimChartInstructions = newInstructions as V2TwoDimensionChartInstructions;
  if (!newTwoDimChartInstructions.yAxisFormats) {
    newTwoDimChartInstructions.yAxisFormats = [newYAxisFormat];
    return newTwoDimChartInstructions;
  }
  const yAxisFormatIndex = getYAxisFormatIndex(newYAxisFormat.id, instructions);
  newTwoDimChartInstructions.yAxisFormats[yAxisFormatIndex] = newYAxisFormat;
  return newTwoDimChartInstructions;
};

export const getAggColDisplayName = (aggColumn: AggedChartColumnInfo) => {
  return aggColumn.column.friendly_name || getColDisplayText(aggColumn) || '';
};

export const createYAxisBaseTooltip = ({
  tooltipFormatter,
  globalValueFormatId,
  globalDecimalPlaces,
  globalStyleConfig,
  instructions,
  includePercent,
}: {
  tooltipFormatter: TooltipFormatterContextObject;
  globalValueFormatId: string;
  globalDecimalPlaces: number;
  globalStyleConfig: GlobalStyleConfig;
  instructions?: V2TwoDimensionChartInstructions;
  includePercent?: boolean;
}) => (
  <BaseTooltip
    // this and globalValueFormatId are mostly just there for safety with backwards
    // compatibility. BaseTooltip should be using the axis valueFormatId and
    // decimalPlaces defined below
    decimalPlaces={globalDecimalPlaces}
    globalStyleConfig={globalStyleConfig}
    header={formatLabel(
      tooltipFormatter.point.category,
      instructions?.categoryColumn?.column.type,
      instructions?.categoryColumn?.bucket?.id,
      instructions?.xAxisFormat?.dateFormat,
      instructions?.xAxisFormat?.stringFormat,
    )}
    includePct={includePercent}
    points={tooltipFormatter.point.series.chart.axes.flatMap((axis, index) => {
      if (axis.isXAxis) return [];

      const { valueFormatId, decimalPlaces } = getValueFormat(
        // axes includes the x-axis, which is first, so we need to account for that
        instructions?.yAxisFormats?.[index - 1],
      );

      return axis.series.flatMap((s) => {
        const dataByName = _.indexBy(s.data, (d) => d.category);
        const dataPoint = dataByName[tooltipFormatter.point.category];
        if (!dataPoint) {
          return [];
        }
        return {
          color: String(dataPoint.color),
          name: s.name,
          value: dataPoint.y,
          selected: s.name === tooltipFormatter.series.name,
          valueFormatId: valueFormatId,
          decimalPlaces: decimalPlaces,
        };
      });
    })}
    valueFormat={globalValueFormatId}
  />
);
