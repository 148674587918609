/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { Layout } from '@explo-tech/react-grid-layout';
// @ts-ignore
import JSURL from 'jsurl';
import ElementGridLayout from './ElementGridLayout';

import { GlobalStylesContext } from 'globalStyles';
import {
  OPERATION_TYPES,
  REPORTED_ANALYTIC_ACTION_TYPES,
  UserTransformedSchema,
} from 'constants/types';
import { ActionFn } from 'actions/actionUtils';
import {
  elemIdFromDropId,
  dataPanelTemplatesAdded,
  dashboardElementsAdded,
  datasetsChanged,
  getDefaultVariablesFromDashElements,
  getDashboardElemsWithDefaultQueryValues,
  datasetUsesVariable,
  isValueInTableRows,
  incorporateNewColumns,
  getDatasetIdsForElems,
  extractDatasetIdsFromElems,
  processUserInputConfig,
  removeUnderscoreFields,
  areRequiredUserInputsSet,
  getDefaultValueForNewElem,
  getSynchronousSecondaryDataInstructions,
  getAsynchronousSecondaryDataInstructions,
  getUrlParamStringFromDashVars,
  incorporateUserSchemaOverrides,
  getDashboardElemsUsingDatasets,
  filterChangedDpt,
  getDatasetIdsForDataPanels,
  handleDownloadBlocked,
} from 'utils/dashboardUtils';
import {
  DashboardElement,
  DropdownDashboardElemConfig,
  DashboardVariable,
  DashboardVariableMap,
  VIEW_MODE,
} from 'types/dashboardTypes';
import {
  DataPanelTemplate,
  DashboardTemplate,
  Dashboard,
  Dataset,
  ShareData,
  AdHocOperationInstructions,
} from 'actions/types';
import { ActionFnArgs } from 'actions/actionUtils';
import {
  FetchDataPanelTemplateData,
  FetchDataPanelTemplateRowCountData,
  FetchDashboardDatasetPreviewData,
  DownloadDataPanelTemplateCsvData,
  DatasetPreviewData,
  ExportUrlResponse,
  ErrorResponse,
  PdfUrlResponse,
} from 'actions/responseTypes';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { SetDPTLoadingArgs, UpdateElementConfigV2Args } from 'actions/dashboardV2Actions';
import {
  isDataPanelReadyToCompute,
  dataPanelRequiresPrimaryData,
} from 'utils/dataPanelConfigUtils';
import { cloneDeep } from 'lodash';
import DashboardLayoutContext from './DashboardLayoutContext';
import { UpdateAdHocOperationInstructionsArgs } from 'actions/dataPanelTemplateAction';

const UNIQUE_ID_FOR_MOUNTING_PORTALS = _.uniqueId('explo-dashboard');

const styles = () =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      position: 'relative',
    },
    pdfEditor: {
      overflow: 'visible',
    },
  });

export type PassedProps = {
  ref?: React.Ref<unknown>;
  analyticsEventTracker?: (
    eventName: REPORTED_ANALYTIC_ACTION_TYPES,
    metaData?: Record<string, string | number>,
  ) => void;
  columns?: number;
  containerId?: string;
  containerRows?: number;
  dashboardDatasets: { [datasetId: string]: Dataset };
  dashboardElements?: DashboardElement[];
  dashboardTemplate: DashboardTemplate | Dashboard;
  dashboardLayout: Layout[];
  dashboardVersionNumber?: number;
  dataPanelTemplates: DataPanelTemplate[];
  disableInputs?: boolean;
  draggingElementType?: string;
  editBaseOn?: boolean;
  editableDashboard?: boolean;
  pdfExportUrlLoading?: boolean;
  imageExportUrlLoading?: boolean;
  fetchDataPanelRowCount: ActionFn<FetchDataPanelTemplateRowCountData>;
  fetchDataPanelTemplate: ActionFn<FetchDataPanelTemplateData>;
  fetchImageExportUrl: ActionFn<ExportUrlResponse>;
  fetchPdfExportUrl: ActionFn<ExportUrlResponse>;
  fetchSecondaryData: ActionFn<FetchDataPanelTemplateData>;
  downloadDataPanelCsv: ActionFn<DownloadDataPanelTemplateCsvData>;
  downloadDataPanelPdf?: ActionFn<PdfUrlResponse>;
  fetchDatasetPreview?: ActionFn<FetchDashboardDatasetPreviewData>;
  fetchShareData?: (password?: string, isStrictViewingMode?: boolean) => void;
  isViewOnly: boolean;
  onCloseConfigClicked?: () => void;
  onCreateDataPanel?: (
    newLayout: Layout[],
    visualizationType?: OPERATION_TYPES,
    containerId?: string,
  ) => void;
  onCreateNewDashboardElement?: (
    elemType: DASHBOARD_ELEMENT_TYPES,
    newLayout: Layout[],
    containerId?: string,
  ) => void;
  onDashboardItemSelect?: (type: string, id: string) => void;
  onVariablesChange?: (newVariables: DashboardVariableMap) => void;
  refreshMinutes?: number;
  removeDroppingPlaceholders?: (() => void)[];
  selectedDashboardItem?: {
    elementType: string;
    elementId: string;
  };
  setDptLoading: (args: SetDPTLoadingArgs) => void;
  shareData?: ShareData;
  shareLinkLoading?: boolean;
  updateAdHocOperationInstructions: (args: UpdateAdHocOperationInstructionsArgs) => void;
  updateDashboardTemplateLayout?: (newLayout: Layout[]) => void;
  updateElementConfig?: (args: UpdateElementConfigV2Args) => void;
  updateUrlParams?: boolean;
  userGroupId?: number;
  userGroupName?: string;
  variablesDefaultValues?: DashboardVariableMap;
  viewMode: VIEW_MODE;
};

type Props = PassedProps & WithStyles<typeof styles>;

export type DownloadPdfInfo = {
  loading?: boolean;
  downloadUrl?: string;
  errored?: boolean;
  isPopupBlocked?: boolean;
  isDownloadBlocked?: boolean;
};

export type DownloadCsvInfo = {
  loading?: boolean;
  downloadUrl?: string;
  errored?: boolean;
  isPopupBlocked?: boolean;
  isDownloadBlocked?: boolean;
};

export type DownloadChartInfo = {
  pdf: DownloadPdfInfo;
  csv: DownloadCsvInfo;
};

export type State = {
  downloadChartInfoByPanel: { [dataPanelId: string]: DownloadChartInfo }; // mapping data panel ID to download info
  intervalId?: number;
  isResizing: boolean;
  variables: DashboardVariableMap;
};

class DashboardLayout extends React.Component<Props, State> {
  gridLayout: React.RefObject<typeof ElementGridLayout>;

  state: State = {
    variables: {},
    isResizing: false,
    downloadChartInfoByPanel: {},
  };

  constructor(props: Props) {
    super(props);
    const { containerId, dashboardElements, variablesDefaultValues, refreshMinutes } = props;

    let elemDefaulVars = getDefaultVariablesFromDashElements(
      dashboardElements,
      variablesDefaultValues,
    );

    if (variablesDefaultValues) {
      elemDefaulVars = {
        ...elemDefaulVars,
        ...variablesDefaultValues,
      };
    }

    const dashboardElemsWithDefaultQueryValue = getDashboardElemsWithDefaultQueryValues(
      dashboardElements || [],
    );

    this.state = {
      variables: elemDefaulVars,
      isResizing: false,
      downloadChartInfoByPanel: {},
    };

    props.onVariablesChange?.(elemDefaulVars);

    this.gridLayout = React.createRef();

    if (containerId) {
      return;
    }

    if (dashboardElemsWithDefaultQueryValue.length > 0) {
      this.fetchDropdownQueryDefaults(dashboardElemsWithDefaultQueryValue, true, () => {
        this.fetchDashboardData();
      });
    } else {
      this.fetchDashboardData();
    }

    if (refreshMinutes) {
      this.setState({
        intervalId: setInterval(
          this.refreshDashboardData.bind(this),
          // convert minutes to milliseconds
          refreshMinutes * 60 * 1000,
        ),
      });
    }
  }

  fetchDropdownQueryDefaults = (
    defaultElems: DashboardElement[],
    calledOnMount?: boolean,
    onComplete?: () => void,
    changedElementName?: string,
  ) => {
    /**
     * If there are no default elements we don't need to do anything
     */
    if (defaultElems.length === 0) return onComplete?.();

    const { dashboardDatasets: existingDatasets, dataPanelTemplates, setDptLoading } = this.props;
    const datasetIdsForDefaultElems = extractDatasetIdsFromElems(defaultElems);

    setDptLoading({
      ids: _.pluck(filterChangedDpt(dataPanelTemplates, changedElementName), 'id'),
      loading: true,
    });

    const datasetPromiseList: unknown[] = [];
    const datasetById: Record<string, Pick<DatasetPreviewData, '_rows'> & { query: string }> = {};

    datasetIdsForDefaultElems.forEach((datasetId) => {
      const existingDataset = existingDatasets[datasetId];
      datasetPromiseList.push(
        this.fetchDatasetPreviewWrapper(datasetId, (data) => {
          datasetById[datasetId] = {
            _rows: data.dataset_preview._rows,
            query: existingDataset.query ?? '',
          };
        }),
      );
    });

    Promise.all(datasetPromiseList).then(() => {
      this.setDropdownQueryDefaults(datasetById, defaultElems, calledOnMount, changedElementName);

      onComplete?.();
    });
  };

  setDropdownQueryDefaults = (
    datasetById: Record<string, Pick<DatasetPreviewData, '_rows'> & { query: string }>,
    defaultElems: DashboardElement[],
    calledOnMount?: boolean,
    changedElementName?: string,
  ) => {
    const { variables } = this.state;

    if (defaultElems.length === 0) return;
    const defaultVars: Record<string, DashboardVariable> = {};

    defaultElems.forEach((elem) => {
      /**
       *  If the variable is set onMount (i.e. came from URL or embed variables)
       *  we don't want to override it
       */
      if (calledOnMount && variables[elem.name]) return;

      const elemWasCleared = !calledOnMount && !variables[elem.name];
      if (elem.name === changedElementName || elemWasCleared) return;

      const config = elem.config as DropdownDashboardElemConfig;
      if (!config.valuesConfig.queryTable || !config.valuesConfig.queryValueColumn) return;

      /**
       * We can return early if this element's dataset doesn't use the variable that was updated
       */
      const datasetForElement = datasetById[config.valuesConfig.queryTable.id];
      if (changedElementName && !datasetUsesVariable(changedElementName, datasetForElement.query))
        return;

      /**
       * If the current value of the element is still contained in the new dataset, we don't
       * need to reset it
       */
      const datasetRows = datasetForElement._rows;
      if (
        datasetRows?.length === 0 ||
        isValueInTableRows(
          datasetRows || [],
          config.valuesConfig.queryValueColumn?.name,
          variables[elem.name],
        )
      )
        return;

      const defaultValue = datasetRows?.[0][config.valuesConfig.queryValueColumn?.name];

      if (elem.element_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT) {
        defaultVars[elem.name] = [defaultValue] as string[] | number[];
      } else {
        defaultVars[elem.name] = defaultValue;
      }
    });

    const newVariables = {
      ...this.state.variables,
      ...defaultVars,
    };
    this.setState({ variables: newVariables });

    this.props.onVariablesChange?.(newVariables);
  };

  fetchDropdownUpdatesFromDefaults = (
    defaultElems: DashboardElement[],
    onComplete?: () => void,
    calledOnMount?: boolean,
    changedElementName?: string,
  ) => {
    const { dashboardDatasets, dashboardElements, setDptLoading, dataPanelTemplates } = this.props;
    const { variables } = this.state;
    const datasetIdsForDefaultElems = extractDatasetIdsFromElems(defaultElems);
    const defaultElemNames = defaultElems.map((elem) => elem.name);

    setDptLoading({
      ids: _.pluck(filterChangedDpt(dataPanelTemplates, changedElementName), 'id'),
      loading: true,
    });

    const datesetsToUpdate = Object.values(dashboardDatasets).filter((dataset) => {
      const isChangedDashElemInDataset =
        changedElementName && datasetUsesVariable(changedElementName, dataset.query);
      const areAnyDashElemsUsedInDataset = _.any(
        defaultElemNames.map((defaultElemName) =>
          datasetUsesVariable(defaultElemName, dataset.query),
        ),
      );
      return (
        !datasetIdsForDefaultElems.has(dataset.id) &&
        (isChangedDashElemInDataset || areAnyDashElemsUsedInDataset)
      );
    });

    const updatedElems = getDashboardElemsUsingDatasets(
      dashboardElements || [],
      datesetsToUpdate,
    ).filter((elem) => elem.name !== changedElementName && variables[elem.name] !== undefined);

    if (updatedElems.length === 0) return onComplete?.();

    const datasetPromiseList: unknown[] = [];
    const datasetById: Record<string, Pick<DatasetPreviewData, '_rows'> & { query: string }> = {};

    datesetsToUpdate.forEach((dataset) => {
      datasetPromiseList.push(
        this.fetchDatasetPreviewWrapper(dataset.id, (data) => {
          datasetById[dataset.id] = {
            _rows: data.dataset_preview._rows,
            query: dataset.query ?? '',
          };
        }),
      );
    });

    Promise.all(datasetPromiseList).then(() => {
      this.setDropdownUpdatesFromDefaults(
        datasetById,
        updatedElems,
        calledOnMount,
        changedElementName,
      );

      onComplete?.();
    });
  };

  setDropdownUpdatesFromDefaults = (
    datasetById: Record<string, Pick<DatasetPreviewData, '_rows'> & { query: string }>,
    updatedElems: DashboardElement[],
    calledOnMount?: boolean,
    changedElementName?: string,
  ) => {
    const { variables } = this.state;

    const newVars = cloneDeep(variables);

    if (updatedElems.length === 0) return;

    updatedElems.forEach((elem) => {
      /**
       *  If the variable is set onMount (i.e. came from URL or embed variables)
       *  we don't want to override it
       */
      if (calledOnMount && newVars[elem.name]) return;

      const elemWasCleared = !calledOnMount && !newVars[elem.name];
      if (elem.name === changedElementName || elemWasCleared) return;

      const config = elem.config as DropdownDashboardElemConfig;
      if (!config.valuesConfig.queryTable || !config.valuesConfig.queryValueColumn) return;

      /**
       * If the current value of the element is still contained in the new dataset, we don't
       * need to reset it
       */
      const datasetForElement = datasetById[config.valuesConfig.queryTable.id];
      const datasetRows = datasetForElement._rows;

      if (elem.element_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const varValues = newVars[elem.name] as any[];
        newVars[elem.name] = varValues.filter((value: string | number) => {
          return isValueInTableRows(
            datasetRows || [],
            config.valuesConfig.queryValueColumn?.name || '',
            value,
          );
        });
      } else if (
        isValueInTableRows(
          datasetRows || [],
          config.valuesConfig.queryValueColumn?.name,
          newVars[elem.name],
        )
      ) {
        return;
      } else {
        // if the value was not in the new dataset rows, mark it as undefined
        newVars[elem.name] = undefined;
      }
    });

    this.setState({ variables: newVars });
    this.props.onVariablesChange?.(newVars);
  };

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.userGroupId !== this.props.userGroupId ||
      prevProps.dashboardVersionNumber !== this.props.dashboardVersionNumber
    ) {
      this.refreshDashboardData();
      return;
    }

    if (
      this.props.variablesDefaultValues &&
      !_.isEqual(prevProps.variablesDefaultValues, this.props.variablesDefaultValues)
    ) {
      const newVariables = {
        ...this.state.variables,
        ...this.props.variablesDefaultValues,
      };
      this.setState({ variables: newVariables });
      this.props.onVariablesChange?.(newVariables);
    }

    // fetch the data for any new data panel templates.
    const newDPTIds = dataPanelTemplatesAdded(
      prevProps.dataPanelTemplates,
      this.props.dataPanelTemplates,
    );
    if (newDPTIds.length > 0) {
      const dptById = _.indexBy(this.props.dataPanelTemplates, 'id');
      newDPTIds.forEach((dptId) => this.fetchDataPanelTemplateData(dptById[dptId]));
    }

    const newDatasetIds = datasetsChanged(
      prevProps.dashboardElements,
      this.props.dashboardElements,
    );

    newDatasetIds.forEach((datasetId) => this.fetchDatasetPreviewWrapper(datasetId));

    const newElemIds = dashboardElementsAdded(
      prevProps.dashboardElements || [],
      this.props.dashboardElements || [],
    );
    if (newElemIds.length > 0) {
      const elemsById = _.indexBy(this.props.dashboardElements || [], 'id');
      const defaultElemValues: DashboardVariableMap = {};
      newElemIds.forEach((elemId) => {
        const newElem = elemsById[elemId];
        const defaultValueForElem = getDefaultValueForNewElem(newElem);
        if (defaultValueForElem) {
          defaultElemValues[newElem.name] = defaultValueForElem;
        }
      });

      const newVariables = {
        ...this.state.variables,
        ...defaultElemValues,
      };
      this.setState({ variables: newVariables });
      this.props.onVariablesChange?.(newVariables);
    }
  }

  fetchDashboardData = (changedVarName?: string) => {
    const { dataPanelTemplates, dashboardElements, dashboardDatasets } = this.props;

    filterChangedDpt(dataPanelTemplates, changedVarName).forEach(this.fetchDataPanelTemplateData);

    // Get the dataset previews of the dash datasets needed for the dropdowns that need them
    const uniqueDatasetIds = getDatasetIdsForElems(dashboardElements, true);
    uniqueDatasetIds.forEach((id) => this.fetchDatasetPreviewWrapper(id));

    // Get the dataset previews for any data panels that use secondary dataset data
    const dptDatasetIds = getDatasetIdsForDataPanels(dashboardDatasets, dataPanelTemplates);
    dptDatasetIds.forEach((id) => this.fetchDatasetPreviewWrapper(id));
  };

  fetchDataPanelTemplateData = (dataPanelTemplate: DataPanelTemplate) => {
    const { dashboardTemplate, dashboardDatasets, setDptLoading } = this.props;
    const { variables } = this.state;

    const dptConfig = {
      filterOperation: dataPanelTemplate.filter_op,
      sortOperation: dataPanelTemplate.sort_op,
      pivotOperation: dataPanelTemplate.group_by_op,
      visualizeOperation: dataPanelTemplate.visualize_op,
    };

    const dataset = dashboardDatasets[dataPanelTemplate.table_id];

    if (
      !isDataPanelReadyToCompute(dptConfig) ||
      !areRequiredUserInputsSet(variables, dataPanelTemplate)
    ) {
      return setDptLoading({ ids: [dataPanelTemplate.id], loading: false });
    }

    if (dataPanelRequiresPrimaryData(dptConfig)) {
      this.fetchDataPanelTemplateWrapper(
        {
          postData: {
            dataset,
            noBaseSchema: true,
            // Since the dataPanelTemplate is stored in the redux store if a data panel template has been loaded before,
            // we need to deep copy it before any modifications, otherwise underscore fields in the redux store will be wiped too.
            config: removeUnderscoreFields(cloneDeep(dataPanelTemplate)),
            dashboardTemplateId: dashboardTemplate.id,
            id: dataPanelTemplate.id,
          },
        },
        (response) => {
          this.fetchDataPanelTemplateSecondaryData(
            {
              ...dataPanelTemplate,
              ...response.data_panel_template,
            },
            true,
          );
        },
      );
    }

    this.fetchDataPanelTemplateSecondaryData(dataPanelTemplate);

    if (
      [OPERATION_TYPES.VISUALIZE_TABLE, OPERATION_TYPES.VISUALIZE_REPORT_BUILDER].includes(
        dataPanelTemplate.visualize_op.operation_type,
      )
    ) {
      this.fetchDataPanelRowCountWrapper({
        postData: {
          dataset,
          id: dataPanelTemplate.id,
          config: dataPanelTemplate,
          dashboardTemplateId: dashboardTemplate.id,
        },
      });
    }
  };

  fetchDataPanelTemplateSecondaryData = (
    dataPanelTemplate: DataPanelTemplate,
    afterMainFetch?: boolean,
  ) => {
    const { dashboardDatasets, dashboardTemplate } = this.props;
    const dataset = dashboardDatasets[dataPanelTemplate.table_id];
    const { variables } = this.state;

    const secondaryInstructions = afterMainFetch
      ? getSynchronousSecondaryDataInstructions(dataPanelTemplate)
      : getAsynchronousSecondaryDataInstructions(dataPanelTemplate, dataset);

    secondaryInstructions.forEach((instructions) => {
      this.fetchSecondaryDataWrapper({
        postData: {
          dataset,
          config: processUserInputConfig(variables, instructions),
          dashboardTemplateId: dashboardTemplate.id,
          id: dataPanelTemplate.id,
          isSecondaryDataQuery: true,
        },
      });
    });
  };

  fetchDataPanelTemplateWrapper = (
    data: ActionFnArgs,
    onSuccess?: ((data: FetchDataPanelTemplateData) => void) | undefined,
  ) => {
    const { fetchDataPanelTemplate, userGroupId, editableDashboard } = this.props;
    const { variables } = this.state;

    return fetchDataPanelTemplate(
      {
        ...data,
        postData: {
          ...data.postData,
          config: processUserInputConfig(variables, data.postData?.config as DataPanelTemplate),
          noBaseSchema: editableDashboard ? false : true,
          variables,
          end_user_group_id: userGroupId,
        },
      },
      onSuccess,
    );
  };

  fetchDataPanelRowCountWrapper = (data: ActionFnArgs) => {
    const { fetchDataPanelRowCount, userGroupId } = this.props;
    const { variables } = this.state;

    return fetchDataPanelRowCount({
      ...data,
      postData: {
        ...data.postData,
        config: processUserInputConfig(variables, data.postData?.config as DataPanelTemplate),
        variables,
        end_user_group_id: userGroupId,
        rowCount: true,
      },
    });
  };

  fetchSecondaryDataWrapper = (data: ActionFnArgs) => {
    const { fetchSecondaryData, userGroupId, editableDashboard } = this.props;
    const { variables } = this.state;

    return fetchSecondaryData({
      ...data,
      postData: {
        ...data.postData,
        variables,
        end_user_group_id: userGroupId,
        noBaseSchema: editableDashboard ? false : true,
      },
    });
  };

  fetchDatasetPreviewWrapper = (
    datasetId: string,
    onSuccess?: (data: FetchDashboardDatasetPreviewData) => void,
  ) => {
    const { fetchDatasetPreview, userGroupId, dashboardDatasets } = this.props;
    const { variables } = this.state;

    return (
      fetchDatasetPreview &&
      fetchDatasetPreview(
        {
          postData: {
            dataset: dashboardDatasets[datasetId],
            variables,
            end_user_group_id: userGroupId,
            query_limit: 3000,
          },
        },
        onSuccess,
      )
    );
  };

  onAdHocOperationInstructionsUpdated = (
    dataPanelTemplateId: string,
    adHocOperationInstructions: AdHocOperationInstructions,
    skipRowCount?: boolean,
  ) => {
    const {
      dashboardDatasets,
      dataPanelTemplates,
      dashboardTemplate,
      updateAdHocOperationInstructions,
    } = this.props;
    const dptById = _.indexBy(dataPanelTemplates, 'id');
    const dataPanelTemplate = dptById[dataPanelTemplateId];

    updateAdHocOperationInstructions({ dataPanelTemplateId, adHocOperationInstructions });

    this.fetchDataPanelTemplateWrapper({
      postData: {
        noBaseSchema: true,
        config: dataPanelTemplate,
        dashboardTemplateId: dashboardTemplate.id,
        id: dataPanelTemplate.id,
        dataset: dashboardDatasets[dataPanelTemplate.table_id],
        page_number: adHocOperationInstructions.currentPage,
        sort_info: adHocOperationInstructions.sortInfo,
        filter_info: adHocOperationInstructions.filterInfo,
      },
    });

    !skipRowCount &&
      this.fetchDataPanelRowCountWrapper({
        postData: {
          config: dataPanelTemplate,
          dashboardTemplateId: dashboardTemplate.id,
          id: dataPanelTemplate.id,
          dataset: dashboardDatasets[dataPanelTemplate.table_id],
          filter_info: adHocOperationInstructions.filterInfo,
          noBaseSchema: true,
        },
      });
  };

  getTransformedDataPanelTemplate = (
    dataPanelTemplate: DataPanelTemplate,
    userTransformedSchema?: UserTransformedSchema,
  ) => {
    const operationType = dataPanelTemplate.visualize_op.operation_type;
    const shouldUseTransformedSchemaForTable =
      operationType === OPERATION_TYPES.VISUALIZE_TABLE &&
      dataPanelTemplate.visualize_op.instructions.VISUALIZE_TABLE.isSchemaCustomizationEnabled;
    const shouldUseTransformedSchema =
      userTransformedSchema &&
      (shouldUseTransformedSchemaForTable ||
        operationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER);

    if (shouldUseTransformedSchema) {
      // We check the truthiness of userTransformedSchema above
      return {
        transformedDataPanelTemplate: incorporateUserSchemaOverrides(
          dataPanelTemplate,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          userTransformedSchema!,
        ),
        isSchemaTransformed: shouldUseTransformedSchema,
      };
    }
    return {
      transformedDataPanelTemplate: incorporateNewColumns(dataPanelTemplate),
      isSchemaTransformed: shouldUseTransformedSchema,
    };
  };

  onDownloadDataPanelCsv = (
    dataPanelTemplate: DataPanelTemplate,
    userTransformedSchema?: UserTransformedSchema,
  ) => {
    const { variables } = this.state;
    const { downloadDataPanelCsv, dashboardDatasets, userGroupId, dashboardTemplate } = this.props;

    const { transformedDataPanelTemplate } = this.getTransformedDataPanelTemplate(
      dataPanelTemplate,
      userTransformedSchema,
    );

    this.updateDownloadState(transformedDataPanelTemplate.id, {
      loading: true,
      errored: false,
      downloadUrl: undefined,
      isPopupBlocked: false,
    });

    downloadDataPanelCsv(
      {
        postData: {
          noBaseSchema: true,
          config: transformedDataPanelTemplate,
          dashboardTemplateId: dashboardTemplate.id,
          id: transformedDataPanelTemplate.id,
          dataset: dashboardDatasets[transformedDataPanelTemplate.table_id],
          variables,
          end_user_group_id: userGroupId,
          downloadCSV: true,
          sort_info: transformedDataPanelTemplate._adHocOperationInstructions?.sortInfo,
          filter_info: transformedDataPanelTemplate._adHocOperationInstructions?.filterInfo,
        },
      },
      (data) => {
        const openedWindow = window.open(data.signed_url);

        handleDownloadBlocked(() => {
          this.updateDownloadState(transformedDataPanelTemplate.id, {
            loading: false,
            errored: false,
            downloadUrl: data.signed_url,
            isPopupBlocked: false,
            isDownloadBlocked: true,
          });
        }, openedWindow);

        this.updateDownloadState(transformedDataPanelTemplate.id, {
          loading: false,
          errored: false,
          downloadUrl: data.signed_url,
          isPopupBlocked: openedWindow === null,
        });
      },
      () => {
        this.updateDownloadState(transformedDataPanelTemplate.id, {
          loading: false,
          errored: true,
          downloadUrl: undefined,
          isPopupBlocked: false,
        });
      },
    );
  };

  onDownloadPanelPdf = (
    dataPanelTemplate: DataPanelTemplate,
    adHocOperationInstructions: AdHocOperationInstructions,
    userTransformedSchema?: UserTransformedSchema,
    reportName?: string,
  ) => {
    const {
      downloadDataPanelPdf,
      dashboardVersionNumber,
      userGroupId,
      dashboardTemplate,
    } = this.props;
    const { variables } = this.state;

    const {
      transformedDataPanelTemplate,
      isSchemaTransformed,
    } = this.getTransformedDataPanelTemplate(dataPanelTemplate, userTransformedSchema);

    this.updateDownloadState(transformedDataPanelTemplate.id, undefined, {
      loading: true,
      errored: false,
      downloadUrl: undefined,
      isPopupBlocked: false,
    });

    // TODO: have adhoc operations apply to the downloaded PDF
    downloadDataPanelPdf?.(
      {
        postData: {
          user_group_id: userGroupId,
          dashboard_template_id: dashboardTemplate.id,
          data_panel_template_id: transformedDataPanelTemplate.id,
          version_number: dashboardVersionNumber,
          variable_query: getUrlParamStringFromDashVars({
            ...variables,
            reportName,
            userTransformedSchema: isSchemaTransformed
              ? JSURL.stringify(userTransformedSchema)
              : JSURL.stringify(
                  transformedDataPanelTemplate._schema?.map((col) => ({ ...col, isVisible: true })),
                ),
            adHocOps: JSURL.stringify(adHocOperationInstructions),
          }),
        },
      },
      (data) => {
        const openedWindow = window.open(data.pdf_url);
        this.updateDownloadState(transformedDataPanelTemplate.id, undefined, {
          loading: false,
          errored: false,
          downloadUrl: data.pdf_url,
          isPopupBlocked: openedWindow === null,
        });
        handleDownloadBlocked(() => {
          this.updateDownloadState(transformedDataPanelTemplate.id, {
            loading: false,
            errored: false,
            downloadUrl: data.pdf_url,
            isPopupBlocked: false,
            isDownloadBlocked: true,
          });
        }, openedWindow);
      },
      () => {
        this.updateDownloadState(transformedDataPanelTemplate.id, undefined, {
          loading: false,
          errored: true,
          downloadUrl: undefined,
          isPopupBlocked: false,
        });
      },
    );
  };

  updateDownloadState = (
    dataPanelId: string,
    newCsvState?: DownloadCsvInfo,
    newPdfState?: DownloadPdfInfo,
  ) => {
    this.setState((oldState) => {
      const downloadChartInfoByPanel = oldState.downloadChartInfoByPanel;

      return {
        ...oldState,
        downloadChartInfoByPanel: {
          ...downloadChartInfoByPanel,
          [dataPanelId]: {
            csv: {
              ...downloadChartInfoByPanel[dataPanelId]?.csv,
              ...newCsvState,
            },
            pdf: {
              ...downloadChartInfoByPanel[dataPanelId]?.pdf,
              ...newPdfState,
            },
          },
        },
      };
    });
  };

  fetchImageExportUrlWrapper = (
    onSuccess?: (data: ExportUrlResponse) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => {
    const {
      dashboardTemplate,
      dashboardVersionNumber,
      fetchImageExportUrl,
      userGroupId,
    } = this.props;
    const { variables } = this.state;

    fetchImageExportUrl(
      {
        postData: {
          user_group_id: userGroupId,
          dashboard_template_id: dashboardTemplate.id,
          version_number: dashboardVersionNumber,
          variable_query: getUrlParamStringFromDashVars(variables),
        },
      },
      onSuccess,
      onError,
    );
  };

  fetchPdfExportUrlWrapper = (
    onSuccess?: (data: ExportUrlResponse) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => {
    const {
      dashboardTemplate,
      dashboardVersionNumber,
      fetchPdfExportUrl,
      userGroupId,
    } = this.props;
    const { variables } = this.state;

    fetchPdfExportUrl(
      {
        postData: {
          user_group_id: userGroupId,
          dashboard_template_id: dashboardTemplate.id,
          version_number: dashboardVersionNumber,
          variable_query: getUrlParamStringFromDashVars(variables),
        },
      },
      onSuccess,
      onError,
    );
  };

  onDataPanelConfigUpdated = (
    e: CustomEvent<{
      dataPanelTemplateId: string;
      shouldRecompute: boolean;
      shouldRecomputeSecondaryData: boolean;
    }>,
  ) => {
    const { dataPanelTemplates } = this.props;
    const dptById = _.indexBy(dataPanelTemplates, 'id');
    const dpt = dptById[e.detail.dataPanelTemplateId];
    if (dpt && e.detail.shouldRecompute) this.fetchDataPanelTemplateData(dpt);
    else if (dpt && e.detail.shouldRecomputeSecondaryData)
      this.fetchDataPanelTemplateSecondaryData(dpt);
  };

  onVarRename = (e: CustomEvent<{ oldName: string; newName: string }>) => {
    const { variables } = this.state;

    if (variables[e.detail.oldName] !== undefined) {
      this.setState((state) => {
        state.variables[e.detail.newName] = state.variables[e.detail.oldName];
        delete state.variables[e.detail.oldName];
        this.props.onVariablesChange?.(state.variables);
        return state;
      });
    }
  };

  onVarDelete = (e: CustomEvent<{ name: string }>) => {
    const { variables } = this.state;

    if (variables[e.detail.name] !== undefined) {
      this.setState((state) => {
        delete state.variables[e.detail.name];
        this.props.onVariablesChange?.(state.variables);
        return state;
      });
    }
  };

  onUpdateVariableValueEvent = (
    e: CustomEvent<{ varName: string; newValue: DashboardVariable }>,
  ) => {
    const { varName, newValue } = e.detail;

    this.setState(
      (currentState) => {
        currentState.variables[varName] = newValue;

        this.props.onVariablesChange?.(currentState.variables);
        return currentState;
      },
      () => this.refreshDashboardData(varName),
    );
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    // @ts-ignore
    window.addEventListener('dataPanelConfigUpdated', this.onDataPanelConfigUpdated);
    // @ts-ignore
    window.addEventListener('renameDashboardVariable', this.onVarRename);
    // @ts-ignore
    window.addEventListener('deleteDashboardVariable', this.onVarDelete);
    // @ts-ignore
    window.addEventListener('updateVariableValue', this.onUpdateVariableValueEvent);
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    document.removeEventListener('keydown', this.handleKeyDown);

    if (intervalId) {
      clearInterval(intervalId);
    }

    // @ts-ignore
    window.removeEventListener('dataPanelConfigUpdated', this.onDataPanelConfigUpdated);
    // @ts-ignore
    window.removeEventListener('renameDashboardVariable', this.onVarRename);
    // @ts-ignore
    window.removeEventListener('deleteDashboardVariable', this.onVarDelete);
    // @ts-ignore
    window.removeEventListener('updateVariableValue', this.onUpdateVariableValueEvent);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    const { onCloseConfigClicked } = this.props;
    if (event.key === 'Escape') {
      onCloseConfigClicked && onCloseConfigClicked();
    }
  };

  render() {
    const {
      classes,
      onCloseConfigClicked,
      dashboardDatasets,
      dashboardElements,
      dashboardTemplate,
      dataPanelTemplates,
      dashboardLayout,
      disableInputs,
      analyticsEventTracker,
      draggingElementType,
      editBaseOn,
      editableDashboard,
      imageExportUrlLoading,
      pdfExportUrlLoading,
      fetchShareData,
      isViewOnly,
      onDashboardItemSelect,
      selectedDashboardItem,
      shareData,
      shareLinkLoading,
      updateDashboardTemplateLayout,
      updateElementConfig,
      updateUrlParams,
      userGroupId,
      userGroupName,
      viewMode,
    } = this.props;

    const { downloadChartInfoByPanel, variables } = this.state;

    return (
      <DashboardLayoutContext.Provider
        value={{
          dashboardLayoutTagId: UNIQUE_ID_FOR_MOUNTING_PORTALS,
        }}>
        <div
          id={UNIQUE_ID_FOR_MOUNTING_PORTALS}
          className={cx(classes.root, {
            [classes.pdfEditor]: viewMode === VIEW_MODE.PDF && !isViewOnly,
          })}
          onClick={() => onCloseConfigClicked && onCloseConfigClicked()}>
          <ElementGridLayout
            ref={this.gridLayout}
            analyticsEventTracker={analyticsEventTracker}
            clearDownloadCsvInfo={() => {
              console.log('deprecated');
            }}
            dashboardDatasets={dashboardDatasets}
            dashboardElements={dashboardElements}
            dashboardTemplate={dashboardTemplate}
            dataPanelTemplates={dataPanelTemplates}
            downloadChartInfoByPanel={downloadChartInfoByPanel}
            dashboardLayout={dashboardLayout}
            disableInputs={disableInputs}
            draggingElementType={draggingElementType}
            editBaseOn={editBaseOn}
            editableDashboard={editableDashboard}
            fetchShareData={fetchShareData}
            fetchImageExportUrl={this.fetchImageExportUrlWrapper}
            fetchPdfExportUrl={this.fetchPdfExportUrlWrapper}
            isViewOnly={isViewOnly}
            onDrop={this.onDrop}
            onDashboardItemSelect={onDashboardItemSelect}
            selectedDashboardItem={selectedDashboardItem}
            shareData={shareData}
            shareLinkLoading={shareLinkLoading}
            imageExportUrlLoading={imageExportUrlLoading}
            pdfExportUrlLoading={pdfExportUrlLoading}
            updateDashboardTemplateLayout={updateDashboardTemplateLayout}
            updateElementConfig={updateElementConfig}
            updateUrlParams={updateUrlParams}
            userGroupId={userGroupId}
            userGroupName={userGroupName}
            onAdHocOperationInstructionsUpdated={this.onAdHocOperationInstructionsUpdated}
            onDownloadDataPanelCsv={this.onDownloadDataPanelCsv}
            onDownloadPanelPdf={this.onDownloadPanelPdf}
            variables={variables}
            setVariable={(varName, value) =>
              this.setState(
                (currentState) => {
                  currentState.variables[varName] = value;
                  this.props.onVariablesChange?.(currentState.variables);
                  return currentState;
                },
                () => {
                  this.refreshDashboardData(varName);

                  !editableDashboard &&
                    updateUrlParams &&
                    window?.history?.replaceState &&
                    window.history.replaceState(
                      null,
                      'Explo',
                      getUrlParamStringFromDashVars(variables),
                    );
                },
              )
            }
            globalStyleConfig={this.context.globalStyleConfig}
            viewMode={viewMode}
          />
        </div>
      </DashboardLayoutContext.Provider>
    );
  }

  removeDroppingPlaceholder = () => {
    // @ts-ignore
    this.gridLayout.current?.removeDroppingPlaceholder();
  };

  onDrop = (layout: Layout[], layoutItem: Layout, event: Event, containerId?: string) => {
    const { onCreateDataPanel, onCreateNewDashboardElement } = this.props;
    const elemType = elemIdFromDropId(layoutItem.i);

    if (elemType.indexOf('data-panel-') >= 0) {
      const operationType = elemType.split('data-panel-')[1] as OPERATION_TYPES;
      onCreateDataPanel?.(layout, operationType, containerId);
      return;
    }

    switch (elemType) {
      case DASHBOARD_ELEMENT_TYPES.REPORT_BUILDER:
        return (
          onCreateDataPanel &&
          onCreateDataPanel(layout, OPERATION_TYPES.VISUALIZE_REPORT_BUILDER, containerId)
        );
      case DASHBOARD_ELEMENT_TYPES.DATA_TABLE:
        return onCreateDataPanel && onCreateDataPanel(layout, undefined, containerId);
      case DASHBOARD_ELEMENT_TYPES.TEXT:
      case DASHBOARD_ELEMENT_TYPES.EXPORT:
      case DASHBOARD_ELEMENT_TYPES.IMAGE:
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
      case DASHBOARD_ELEMENT_TYPES.SWITCH:
      case DASHBOARD_ELEMENT_TYPES.CONTAINER:
        return (
          onCreateNewDashboardElement && onCreateNewDashboardElement(elemType, layout, containerId)
        );
      default:
        return;
    }
  };

  refreshDashboardData = (changedVarName?: string) => {
    const { dashboardElements } = this.props;
    const elemsWithDefaults = getDashboardElemsWithDefaultQueryValues(
      dashboardElements?.filter((e) => e.name !== changedVarName) || [],
    );

    this.fetchDropdownQueryDefaults(
      elemsWithDefaults,
      false,
      () =>
        this.fetchDropdownUpdatesFromDefaults(
          elemsWithDefaults,
          () => this.fetchDashboardData(changedVarName),
          false,
          changedVarName,
        ),
      changedVarName,
    );
  };
}

DashboardLayout.contextType = GlobalStylesContext;

export default withStyles(styles)(DashboardLayout);
