/** @format */

import { PIVOT_AGG_TYPES } from 'constants/dataConstants';
import { AggregationBucket } from 'constants/types';
import { dayjs, DATE_FORMAT_TO_LOCALIZED_FORMAT } from 'utils/localizationUtils';

export const insertZeroesForMissingDateData = (
  previewData: Record<string, string | number>[],
  bucket: AggregationBucket,
  xAxisColName: string,
  yAxisColName: string,
) => {
  if (isAggregationByDate(bucket))
    insertUnsortedZeroesForMissingDates(previewData, bucket, xAxisColName, yAxisColName);
  else if (isAggregationByDatePart(bucket))
    insertUnsortedZeroesForMissingDateParts(previewData, bucket, xAxisColName, yAxisColName);
  else return;
  // Highcharts needs pre-sorted data
  previewData.sort((a, b) => {
    return dayjs.utc(a[xAxisColName]).valueOf() - dayjs.utc(b[xAxisColName]).valueOf();
  });
};

const insertUnsortedZeroesForMissingDates = (
  previewData: Record<string, string | number>[],
  bucket: AggregationBucket,
  xAxisColName: string,
  yAxisColName: string,
) => {
  for (let idx = 0; idx < previewData.length - 1; idx++) {
    const nextIdx = idx + 1;
    const currDate = dayjs.utc(previewData[idx][xAxisColName]);
    const nextDate = dayjs.utc(previewData[nextIdx][xAxisColName]);
    // this is the gap between points on the x-axis (e.g. if we're bucketing by day, 6/10 and 6/13 will have a gap of 2)
    const xAxisDateGap = nextDate.diff(currDate, bucket.name.toLowerCase() as dayjs.OpUnitType);
    if (xAxisDateGap > 1) {
      // Fill in each gap between the current and next date e.g. 1 day out, 2 days out, etc.
      for (let gapPart = 1; gapPart < xAxisDateGap; gapPart++) {
        // Reset the currDate to 12AM UTC before adding time onto it to avoid off-by-ones in certain edge cases
        const missingDate = dayjs
          .utc(currDate.format(DATE_FORMAT_TO_LOCALIZED_FORMAT['MM/DD/YYYY']))
          .add(gapPart, bucket.name.toLowerCase() as dayjs.OpUnitType);
        const zeroDatePreviewData: Record<string, string | number> = {};
        zeroDatePreviewData[xAxisColName] = missingDate.valueOf();
        zeroDatePreviewData[yAxisColName] = 0;
        // Push at the end and then sort later instead of insertion because it is less expensive
        // NOTE: Inserting into a linked list here would be the fastest, but choosing to sort for now because existing
        //       libraries don't easily support constant time insertion and it is a neglible improvement in real-world speed
        previewData.push(zeroDatePreviewData);
      }
    }
  }
};

const insertUnsortedZeroesForMissingDateParts = (
  previewData: Record<string, string | number>[],
  bucket: AggregationBucket,
  xAxisColName: string,
  yAxisColName: string,
) => {
  const existingDateParts = new Set();
  previewData.forEach((row) => existingDateParts.add(row[xAxisColName]));

  const start = 0;
  const end = DatePartToMaxBoundMapping.get(bucket.id);
  if (!end) return;
  for (let datePart = start; datePart < end; datePart++) {
    if (!existingDateParts.has(datePart)) {
      const zeroDatePartRow: Record<string, string | number> = {};
      zeroDatePartRow[xAxisColName] = datePart;
      zeroDatePartRow[yAxisColName] = 0;
      previewData.push(zeroDatePartRow);
    }
  }
};

const isAggregationByDate = (bucket: AggregationBucket) => {
  return (
    bucket.id === PIVOT_AGG_TYPES.DATE_HOUR.id ||
    bucket.id === PIVOT_AGG_TYPES.DATE_DAY.id ||
    bucket.id === PIVOT_AGG_TYPES.DATE_MONTH.id ||
    bucket.id === PIVOT_AGG_TYPES.DATE_YEAR.id ||
    bucket.id === PIVOT_AGG_TYPES.DATE_WEEK.id
  );
};

const isAggregationByDatePart = (bucket: AggregationBucket) => {
  return (
    bucket.id === PIVOT_AGG_TYPES.DATE_PART_HOUR.id ||
    bucket.id === PIVOT_AGG_TYPES.DATE_PART_MONTH.id ||
    bucket.id === PIVOT_AGG_TYPES.DATE_PART_MONTH_DAY.id ||
    bucket.id === PIVOT_AGG_TYPES.DATE_PART_WEEK_DAY.id
  );
};

const DatePartToMaxBoundMapping: Map<string, number> = new Map([
  [PIVOT_AGG_TYPES.DATE_PART_HOUR.id, 24],
  [PIVOT_AGG_TYPES.DATE_PART_MONTH.id, 12],
  [PIVOT_AGG_TYPES.DATE_PART_MONTH_DAY.id, 31],
  [PIVOT_AGG_TYPES.DATE_PART_WEEK_DAY.id, 7],
]);
