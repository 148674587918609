/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';
import DropdownSelect from 'shared/DropdownSelect';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { LegendPosition } from 'constants/types';
import { SelectedDropdownInputItem } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2TwoDimensionChartInstructions;
};

export default function LegendConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedPosition = instructions.legendFormat?.position || LegendPosition.AUTO;

  return (
    <div className={classes.root}>
      <DropdownSelect
        containerClassName={classes.configInput}
        selectedItem={{ id: selectedPosition, name: selectedPosition }}
        onChange={(item: SelectedDropdownInputItem) => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.legendFormat = {
            ...newInstructions.legendFormat,
            position: item.id as LegendPosition,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        filterable={false}
        options={Object.values(LegendPosition).map((position) => ({
          id: position,
          name: position,
        }))}
        noSelectionText="Select Position"
        minimal
        btnMinimal
        fillWidth
        label="Position"
      />

      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={instructions.legendFormat?.title}
        onNewValueSubmitted={(newValue: string) => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.legendFormat = {
            ...newInstructions.legendFormat,
            title: newValue,
            showTitle: true,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Title"
      />
      <SwitchInput
        className={classes.configInput}
        switchOn={instructions.legendFormat?.showTitle}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.legendFormat = {
            ...newInstructions.legendFormat,
            showTitle: !newInstructions.legendFormat?.showTitle,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Show Title"
      />
      <SwitchInput
        className={classes.configInput}
        switchOn={!instructions.legendFormat?.hideLegend}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.legendFormat = {
            ...newInstructions.legendFormat,
            hideLegend: !newInstructions.legendFormat?.hideLegend,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        label="Show Legend"
      />
    </div>
  );
}
