/** @format */

import React from 'react';
import { Spinner } from '@blueprintjs/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import { EmbeddedDashboardType } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  spinner: ({ type }: Props) => ({
    ...(['shared', 'iframe'].includes(type)
      ? {
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }
      : {
          width: '100%',
          height: '100%',
          margin: theme.spacing(2),
        }),
  }),
}));

type Props = {
  type: EmbeddedDashboardType;
};

export default function LoadingState(props: Props) {
  const { type } = props;
  const classes = useStyles(props);

  if (type === 'embedded') {
    return <Spinner className={classes.spinner} size={100} />;
  }
  return (
    <div className={classes.spinner}>
      <Spinner size={72} />
    </div>
  );
}
