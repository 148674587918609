/** @format */

import React from 'react';
import _ from 'underscore';

import DropdownSelect from 'shared/DropdownSelect';

import { SelectedDropdownInputItem } from 'constants/types';
import { FILTER_OPERATORS, FILTER_OPERATOR_TYPES_BY_ID } from 'constants/dataPanelEditorConstants';
import { Props as FilterRowProps } from './FilterRow';

type OperatorDropdownProps = { className?: string } & Pick<
  FilterRowProps,
  'selectedOperator' | 'selectedColumn' | 'onOperatorSelect'
>;
export default function OperatorDropdown({
  className,
  selectedColumn,
  selectedOperator,
  onOperatorSelect,
}: OperatorDropdownProps) {
  const relevantOperators = _.filter(FILTER_OPERATORS, (op) =>
    selectedColumn ? op.supported_column_types.has(selectedColumn.type) : false,
  );

  return (
    <span className={className}>
      <DropdownSelect
        selectedItem={
          selectedOperator
            ? relevantOperators.find((op) => op.id === selectedOperator.id)
            : undefined
        }
        onChange={(item: SelectedDropdownInputItem) =>
          onOperatorSelect(FILTER_OPERATOR_TYPES_BY_ID[item.id])
        }
        filterable={false}
        options={relevantOperators.map((op) => ({
          name: op.selectionValue,
          id: op.id,
        }))}
        noSelectionText="Operator"
        minimal
        fillWidth
      />
    </span>
  );
}
