/** @format */

import { makeStyles, Theme } from '@material-ui/core/styles';
import Modal from 'components/core/Modal';
import React from 'react';
import Button from 'shared/Button';

const useStyles = makeStyles((theme: Theme) => ({
  modalDescription: {
    padding: `0px ${theme.spacing(5)}px`,
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    height: theme.spacing(8),
    padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
  },
  button: {
    margin: `0px ${theme.spacing(1)}px`,
  },
}));

type Props = {
  modalOpen: boolean;
  closeModal: () => void;
  onSync: () => void;
};

export default function SyncTablesModal({ modalOpen, closeModal, onSync }: Props) {
  const classes = useStyles();
  return (
    <Modal modalOpen={modalOpen} onClose={closeModal} title="Unsynced Tables">
      <div className={classes.modalDescription}>
        The database you’re trying to access hasn’t be synced yet. To view the tables you must first
        sync the databases in this schema.
      </div>
      <div className={classes.buttonsContainer}>
        <div className={classes.buttonContainer}>
          <Button className={classes.button} onClick={closeModal} text="Not Yet" type="secondary" />
          <Button className={classes.button} onClick={onSync} text="Sync Tables" type="primary" />
        </div>
      </div>
    </Modal>
  );
}
