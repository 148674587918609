/** @format */

import React, { useContext } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

import DashboardElementView from 'pages/dashboardPage/dashboardElement/dashboardElementView';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import {
  DashboardElement,
  DashboardVariable,
  DashboardVariableMap,
  VIEW_MODE,
} from 'types/dashboardTypes';
import { PassedProps as ElementGridLayoutProps } from './ElementGridLayout';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';

const useDashboardElementStyles = makeStyles((theme: Theme) => ({
  dashboardElementIdTag: {
    visibility: 'hidden',
    position: 'absolute',
    fontSize: 11,
    top: -20,
    left: 0,
    height: 15,
    backgroundColor: theme.palette.ds.hovered.lightBlue,
    borderTopRightRadius: 3,
    borderTopLeftRadius: 3,
    padding: `0 ${theme.spacing(3)}px`,
    display: 'flex',
    alignItems: 'center',
    zIndex: 2,
    whiteSpace: 'nowrap',
  },
}));

export default function DashboardElementContainer(props: {
  dashboardElement: DashboardElement;
  disableInputs: boolean;
  setVariable: (variableName: string, value: DashboardVariable) => void;
  variables: DashboardVariableMap;
  isResizing: boolean;
  dashboardLayoutProps: ElementGridLayoutProps;
  isMobileView: boolean;
  viewMode: VIEW_MODE;
  isInContainer: boolean;
}) {
  const classes = useDashboardElementStyles();
  const {
    dashboardElement,
    dashboardLayoutProps,
    disableInputs,
    variables,
    setVariable,
    isInContainer,
    ...otherProps
  } = props;

  const { editableDashboard } = dashboardLayoutProps;
  const context = useContext(DashboardLayoutContext);

  const tagClassname =
    dashboardElement.element_type === DASHBOARD_ELEMENT_TYPES.CONTAINER
      ? 'dashboardContainerIdTag'
      : 'dashboardElementIdTag';

  return (
    <>
      {editableDashboard && dashboardElement.element_type !== DASHBOARD_ELEMENT_TYPES.CONTAINER && (
        <div className={cx(classes.dashboardElementIdTag, tagClassname)}>
          {dashboardElement.name}
        </div>
      )}
      <DashboardElementView
        dashboardLayoutProps={dashboardLayoutProps}
        dashboardElement={dashboardElement}
        value={variables[dashboardElement.name]}
        variables={{
          ...variables,
          user_group_name: dashboardLayoutProps.userGroupName,
        }}
        onNewValueSelect={(newValue) => setVariable(dashboardElement.name, newValue)}
        exportVars={variables}
        disableInputs={disableInputs}
        {...otherProps}
        portalId={isInContainer ? context.dashboardLayoutTagId : undefined}
      />
    </>
  );
}
