/** @format */

import React from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Theme, WithStyles } from '@material-ui/core/index';

import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import FilterEditingPanel from 'pages/dataPanelEditorPage/filterEditingPanel';
import Button from 'shared/Button';
import DropdownSelect from 'shared/DropdownSelect';

import { TableColumn, FilterOperation } from 'actions/types';
import { FilterOperatorType, FilterValueType, SelectedDropdownInputItem } from 'constants/types';
import {
  createFilterClause,
  deleteFilterClause,
  selectFilterColumn,
  selectFilterOperator,
  updateFilterValue,
  updateFilterMatch,
} from 'actions/dataPanelConfigActions';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    filterPanel: {
      marginBottom: theme.spacing(4),
    },
    addFilterBtn: {
      width: '100%',
    },
  });

type PassedProps = {
  baseSchema: TableColumn[];
  filterOperation?: FilterOperation;
};

type Props = PassedProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  RouteComponentProps;

class FilterConfigMenu extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <ConfigSectionHeader title="All filters" rightElem={this.renderAndOrSelector()} />
        {this.renderFilterPanels()}
        {this.renderAddFilterBtn()}
      </div>
    );
  }

  renderAndOrSelector = () => {
    const { filterOperation, updateFilterMatch } = this.props;

    if (!filterOperation) return;

    return (
      <div>
        <DropdownSelect
          selectedItem={
            filterOperation.instructions.matchOnAll
              ? { id: 'AND', name: 'AND' }
              : { id: 'OR', name: 'OR' }
          }
          onChange={(item: SelectedDropdownInputItem) => {
            if (item.id === 'AND') {
              updateFilterMatch(true);
            } else {
              updateFilterMatch(false);
            }
          }}
          filterable={false}
          options={[
            { id: 'AND', name: 'AND' },
            { id: 'OR', name: 'OR' },
          ]}
          noSelectionText="nice"
          minimal
          btnMinimal
        />
      </div>
    );
  };

  renderFilterPanels = () => {
    const {
      baseSchema,
      classes,
      filterOperation,
      deleteFilterClause,
      selectFilterColumn,
      selectFilterOperator,
      updateFilterValue,
    } = this.props;
    if (!filterOperation) return;

    return filterOperation.instructions.filterClauses.map((filterClause, index) => (
      <FilterEditingPanel
        key={_.uniqueId('filter_clause_')}
        className={classes.filterPanel}
        baseSchema={baseSchema}
        onDelete={() => deleteFilterClause(index)}
        selectedColumn={filterClause.filterColumn}
        selectedOperator={filterClause.filterOperation}
        filterValue={filterClause.filterValue}
        onColumnSelect={(column: TableColumn) => selectFilterColumn(index, column)}
        onOperatorSelect={(operator: FilterOperatorType) => selectFilterOperator(index, operator)}
        onFilterValueUpdate={(value: FilterValueType) => updateFilterValue(index, value)}
      />
    ));
  };

  renderAddFilterBtn = () => {
    const { classes, createFilterClause } = this.props;
    return (
      <Button
        className={classes.addFilterBtn}
        type="secondary"
        onClick={() => createFilterClause()}
        text="Add a Filter"
      />
    );
  };
}
const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createFilterClause,
  deleteFilterClause,
  selectFilterColumn,
  selectFilterOperator,
  updateFilterValue,
  updateFilterMatch,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FilterConfigMenu)),
);
