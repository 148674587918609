/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';
import { Button, ButtonGroup } from '@blueprintjs/core';

import { VisualizeChoroplethMapInstructions } from 'constants/types';
import { REGIONS, DEFAULT_REGION } from 'constants/dataConstants';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(6),
    },
  });

type PassedProps = {
  instructions: VisualizeChoroplethMapInstructions;
  updateVisualizeOperation: (
    newVisualizeLineChartInstructions: VisualizeChoroplethMapInstructions,
  ) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class SelectMapRegionConfiguration extends React.Component<Props> {
  render() {
    const { classes, instructions } = this.props;
    const selectedViewId = instructions.region ? instructions.region : DEFAULT_REGION;

    return (
      <div className={classes.root}>
        <ButtonGroup fill>
          {Object.values(REGIONS).map((region: any) => (
            <Button
              key={`${region.id}`}
              icon={region.icon ? (region.icon as any) : undefined}
              active={selectedViewId === region.id}
              onClick={() => this.onMapRegionSelected(region.id)}>
              {region.text}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    );
  }

  onMapRegionSelected = (region: string) => {
    const { instructions, updateVisualizeOperation } = this.props;

    instructions.region = region;
    updateVisualizeOperation(instructions);
  };
}

export default withStyles(styles)(SelectMapRegionConfiguration);
