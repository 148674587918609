/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { Spinner, FormGroup, Intent, TextArea } from '@blueprintjs/core';
import { AppToaster } from 'toaster';

import Modal from 'components/core/Modal';
import VersionPanel from './VersionPanel';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import Button from 'shared/Button';
import DeleteConfirmButton from 'components/core/DeleteConfirmButton';

import {
  switchCurrentlyEditingDashboardVersion,
  deleteCurrentDashboardDraft,
} from 'actions/dashboardV2Actions';
import { ACTION, DashboardVersion } from 'actions/types';
import FlexBox, { VerticalAlignment, HorizontalAlignment } from 'components/core/FlexBox';
import { createLoadingSelector } from 'reducers/api/selectors';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 700,
    },
    modalBody: {
      padding: `0 ${theme.spacing(6)}px`,
      height: 500,
      overflowY: 'auto',
    },
    draftContainer: {
      backgroundColor: theme.palette.ds.grey200,
      padding: theme.spacing(3),
      borderRadius: 4,
      marginBottom: theme.spacing(3),
    },
    noDraftContainer: {
      dispaly: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    currentDraftText: {
      fontWeight: 600,
      fontSize: 14,
      marginBottom: theme.spacing(3),
    },
    currentDraftVersionInput: {
      margin: 0,
      marginRight: theme.spacing(3),
      width: 70,
    },
    currentDraftMessageInput: {
      margin: 0,
      marginRight: theme.spacing(3),
      width: 410,

      '&.noDeleteButton': {
        width: 325,
      },
    },
    publishDraftBtn: {
      marginTop: 23,
    },
    deleteBtn: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    versionEditBtnContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    messageTextArea: {
      resize: 'none',
      width: '100%',
      height: '68px !important',
      fontFamily: 'unset',
    },
  });

type PassedProps = {
  modalOpen: boolean;
  closeModal: () => void;
  dashboardTemplateId: number;
  onPublishVersion: (versionNumber: number, message: string, onSuccess?: () => void) => void;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  PassedProps &
  WithStyles<typeof styles>;

type State = {
  draftChangeComment: string;
};

class ManageVersionsModal extends React.Component<Props> {
  state: State = {
    draftChangeComment: '',
  };

  render() {
    const { classes, modalOpen, closeModal } = this.props;

    return (
      <Modal className={classes.root} modalOpen={modalOpen} onClose={closeModal} title="Change Log">
        {this.renderModalBody()}
      </Modal>
    );
  }

  renderModalBody = () => {
    const { classes, versions } = this.props;
    const sortedVersions = _.sortBy(versions, (version) => -version.version_number);

    if (sortedVersions.length === 0)
      return (
        <FlexBox
          horizontalAlignment={HorizontalAlignment.CENTER}
          verticalAlignment={VerticalAlignment.CENTER}>
          <Spinner />
        </FlexBox>
      );

    return (
      <div className={classes.modalBody}>
        {this.renderCurrentDraft(sortedVersions)}
        {_.filter(sortedVersions, (version) => !version.is_draft).map(this.renderVersion)}
      </div>
    );
  };

  renderCurrentDraft = (sortedVersions: DashboardVersion[]) => {
    const {
      classes,
      onPublishVersion,
      publishLoading,
      switchCurrentlyEditingDashboardVersion,
      deleteCurrentDashboardDraft,
      dashboardTemplateId,
      deleteLoading,
      viewingDashboardVersion,
    } = this.props;
    const { draftChangeComment } = this.state;

    const mostRecentVersion = sortedVersions[0];

    if (!mostRecentVersion.is_draft) {
      return (
        <div className={cx(classes.draftContainer, classes.noDraftContainer)}>No current draft</div>
      );
    }

    const secondMostRecentVersion = sortedVersions[1];

    return (
      <div className={classes.draftContainer}>
        <div className={classes.currentDraftText}>Current Draft</div>
        <FlexBox verticalAlignment={VerticalAlignment.TOP}>
          <FormGroup className={classes.currentDraftVersionInput} label="Version">
            <InputGroup
              type="text"
              value={`${mostRecentVersion.version_number}`}
              readOnly
              disabled
            />
          </FormGroup>

          <FormGroup
            className={cx(classes.currentDraftMessageInput, {
              noDeleteButton: mostRecentVersion.version_number === 1,
            })}
            label="Description">
            <TextArea
              className={classes.messageTextArea}
              placeholder="Describe what is different"
              value={draftChangeComment}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                this.setState({ draftChangeComment: e.target.value })
              }
            />
          </FormGroup>
          <div className={classes.versionEditBtnContainer}>
            <Button
              className={classes.publishDraftBtn}
              disabled={!draftChangeComment || publishLoading}
              loading={publishLoading}
              type="primary"
              onClick={() =>
                !publishLoading &&
                draftChangeComment &&
                onPublishVersion(mostRecentVersion.version_number, draftChangeComment, () =>
                  this.setState({ draftChangeComment: '' }),
                )
              }
              text="Save Version"
            />
            {mostRecentVersion.version_number > 1 && (
              <DeleteConfirmButton
                className={classes.deleteBtn}
                loading={deleteLoading}
                onDelete={() => {
                  deleteCurrentDashboardDraft(
                    {
                      id: dashboardTemplateId,
                      postData: { version_number: mostRecentVersion.version_number },
                    },
                    () => {
                      if (
                        viewingDashboardVersion?.version_number === mostRecentVersion.version_number
                      )
                        switchCurrentlyEditingDashboardVersion({
                          dashboardVersion: secondMostRecentVersion,
                        });
                    },
                    () => {
                      AppToaster.show({
                        message:
                          'There was an error deleting the current draft. Please try again and contact support@explo.co if the problem persists.',
                        icon: 'error',
                        timeout: 5000,
                        intent: Intent.WARNING,
                      });
                    },
                  );
                }}
              />
            )}
          </div>
        </FlexBox>
      </div>
    );
  };

  renderVersion = (version: DashboardVersion) => {
    const { authors, tags, closeModal, dashboardTemplateId } = this.props;

    return (
      <VersionPanel
        key={`version-modal-version-${version.id}`}
        version={version}
        author={version.published_by_id ? authors[version.published_by_id] : undefined}
        tags={tags}
        closeModal={closeModal}
        dashboardTemplateId={dashboardTemplateId}
      />
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  publishLoading: createLoadingSelector([ACTION.PUBLISH_NEW_DASHBOARD_VERSION], false)(state),
  deleteLoading: createLoadingSelector([ACTION.DELETE_CURRENT_DASHBOARD_DRAFT], false)(state),
  versions: state.dashboardVersions.versions,
  authors: state.dashboardVersions.authors,
  tags: state.dashboardVersions.tags,
  viewingDashboardVersion: state.dashboardEditConfig.versionInfo,
});

const mapDispatchToProps = {
  switchCurrentlyEditingDashboardVersion,
  deleteCurrentDashboardDraft,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ManageVersionsModal));
