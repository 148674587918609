/** @format */

import React, { useState } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { DataPanelTemplate } from 'actions/types';

import LoadingDataTable from 'components/dataTable/loadingDataTable';
import BaseDataTable from 'components/dataTable/baseDataTable';
import TablePageNavigation from 'components/dataTable/tablePageNavigation';
import PageNumberIndicator from 'components/dataTable/pageNumberIndicator';

const DATASET_PREVIEW_CONTROLS_BAR_HEIGHT = 40;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: `calc(100% + 3px)`,
  },
  errorState: {
    padding: theme.spacing(3),
  },
  noSelectionState: {
    padding: theme.spacing(3),
    overflow: 'auto',
  },
  datasetPreviewTable: {
    height: `calc(100% - ${DATASET_PREVIEW_CONTROLS_BAR_HEIGHT}px)`,
  },
  datasetPreviewControls: {
    height: DATASET_PREVIEW_CONTROLS_BAR_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pageNumberIndicator: {
    marginLeft: theme.spacing(3),
  },
}));

type Props = {
  dataPanel?: DataPanelTemplate;
  noDataPanelSelected?: boolean;
};

export default function DataDebugger({ dataPanel, noDataPanelSelected }: Props) {
  const classes = useStyles();
  const [currentPreviewPage, setCurrentPreviewPage] = useState<number>(1);
  const [pageNavVal, setPageNavVal] = useState<number>(1);
  const [pageNavValid, setPageNavValid] = useState<boolean>(false);

  const totalRowCount = dataPanel?._rows?.length || 0;

  const tablePager = (
    <div className={classes.datasetPreviewControls}>
      <PageNumberIndicator
        minimal
        className={classes.pageNumberIndicator}
        currentPage={currentPreviewPage}
        rowsPerPage={50}
        totalRows={totalRowCount || 0}
      />

      <TablePageNavigation
        loading={dataPanel?._loading}
        currentPageNumber={currentPreviewPage}
        changePage={(pageNumber: number) => {
          setCurrentPreviewPage(pageNumber);
          setPageNavVal(pageNumber);
        }}
        pageNumberInputVal={pageNavVal}
        maxPageNumber={totalRowCount ? Math.ceil(totalRowCount / 50) : 1}
        pageNumberInputIsInvalid={pageNavValid}
        changePageNumberInputVal={setPageNavVal}
        changePageNumberisInvalid={setPageNavValid}
      />
    </div>
  );

  if (!dataPanel || noDataPanelSelected) {
    return (
      <div className={cx(classes.root, classes.noSelectionState)}>
        Select a data panel on the dashboard to use the debugger
      </div>
    );
  } else if (dataPanel._error) {
    return (
      <div className={cx(classes.root, classes.errorState)}>
        There was a problem fetching the data
      </div>
    );
  } else if (dataPanel._loading) {
    return (
      <div className={classes.root}>
        <LoadingDataTable className={classes.datasetPreviewTable} maxRows={100} />
        {tablePager}
      </div>
    );
  } else if (!dataPanel._rows) {
    return (
      <div className={cx(classes.root, classes.errorState)}>
        Configure this data panel to see the underlying data
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <BaseDataTable
        className={classes.datasetPreviewTable}
        schema={dataPanel._schema || []}
        rows={dataPanel._rows.slice(50 * (currentPreviewPage - 1))}
        maxRows={50}
        isSortable={false}
        enableColumnResizing
        shouldTruncateText={true}
      />
      {tablePager}
    </div>
  );
}
