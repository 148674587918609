/** @format */

import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';

import XAxisConfig from './formatSections/XAxisConfig';
import YAxisConfig from './formatSections/YAxisConfig';
import LegendConfig from './formatSections/LegendConfig';
import SettingHeader from '../DataConfigTab/SettingHeader';
import PieChartConfig from './formatSections/PieChartConfig';
import LineChartConfig from './formatSections/LineChartConfig';
import HeatMapConfig from './formatSections/HeatMapConfig';
import FunnelChartConfig from './formatSections/FunnelChartConfig';
import ColorConfig from './formatSections/ColorConfig';
import KPIGeneralConfig from './formatSections/KPIGeneralConfig';
import KPITrendTitleConfig from './formatSections/KPITrendTitleConfig';
import KPIValueConfig from './formatSections/KPIValueConfig';
import KPITrendConfig from './formatSections/KPITrendConfig';
import BarLineDataLabelsConfig from './formatSections/BarLineDataLabelsConfig';
import ComboChartColumnTypeConfig from './formatSections/ComboChartColumnTypeConfig';
import InformationTooltipConfig from './formatSections/InformationTooltipConfig';
import BoxPlotConfig from './formatSections/BoxPlotConfig';
import KPITrendValueConfig from './formatSections/KPITrendValueConfig';
import KPITrendTrendConfig from './formatSections/KPITrendTrendConfig';
import GoalLineGroupConfig from './formatSections/goalLines/GoalLineGroupConfig';
import MapDataConfig from './formatSections/mapDataConfig';
import TooltipConfig from './formatSections/TooltipConfig';
import TableHeaderConfig from './formatSections/TableHeaderConfig';
import TableStylingConfig from './formatSections/TableStylingConfig';
import TablePDFConfig from './formatSections/TablePDFConfig';
import DrilldownConfig from './formatSections/DrilldownConfig';
import NoDataConfig from './formatSections/NoDataConfig';

import { addGoalLine } from './formatSections/goalLines/utils';

import {
  OPERATION_TYPES,
  Schema,
  VisualizeOperationGeneralFormatOptions,
  VisualizeOperationInstructions,
} from 'constants/types';
import { addYAxis } from 'pages/dashboardPage/charts/utils/multiYAxisUtils';
import YAxisGroupConfig from './formatSections/YAxisGroupConfig';
import { VIZ_OP_WITH_CSV_DOWNLOAD } from 'constants/dataConstants';
import DownloadConfig from './formatSections/DownloadConfig';
import { getChangedSchema } from 'utils/dashboardUtils';
import TableColumnsConfig from './formatSections/TableColumnsConfig/TableColumnsConfig';
import TableFooterConfig from './formatSections/TableFooterConfig';
import FormatTimeSeriesDataConfig from './formatSections/FormatTimeSeriesDataConfig';
import { isTimeSeriesVisualization } from 'utils/operationTypeUtils';
import { Dataset, TableRow } from 'actions/types';

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 200,
  },
}));

export type Props = {
  instructions: VisualizeOperationInstructions;
  schema: Schema;
  visualizationType: string;
  generalFormatOptions?: VisualizeOperationGeneralFormatOptions;
  dashboardDatasets: Record<string, Dataset>;
  dataPanelData: TableRow[];
};

export default function FormatConfigTab({
  visualizationType,
  instructions,
  generalFormatOptions,
  schema,
  dashboardDatasets,
  dataPanelData,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const changedSchema = useMemo(() => getChangedSchema(schema, instructions.VISUALIZE_TABLE), [
    schema,
    instructions,
  ]);

  const xAxisHeader = <SettingHeader noTopBorder name="X-Axis" />;
  const yAxisHeader = <SettingHeader name="Y-Axis" />;
  const renderGroupAxesConfig = (isForHorizontalChart: boolean) => {
    return (
      <>
        <SettingHeader
          name={isForHorizontalChart ? 'X-Axes' : 'Y-Axes'}
          actionBtnConfig={{
            actionBtnIcon: 'plus',
            onActionBtnClicked: () =>
              addYAxis(dispatch, instructions.V2_TWO_DIMENSION_CHART || {}, visualizationType),
          }}
        />
        <YAxisGroupConfig
          visualizationType={visualizationType}
          instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
          isForHorizontalChart={isForHorizontalChart}
        />
      </>
    );
  };

  const renderXAxisConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
      case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
      case OPERATION_TYPES.VISUALIZE_TABLE:
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
        return;
      case OPERATION_TYPES.VISUALIZE_MAP_V2:
        return (
          <>
            <SettingHeader noTopBorder name="Region" />
            <MapDataConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
        return (
          <>
            {xAxisHeader}
            <YAxisConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
        return renderGroupAxesConfig(true);
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
        return (
          <>
            {xAxisHeader}
            <XAxisConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              barValuesConfigurable
              enableSorting
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
        return (
          <>
            {instructions.V2_BOX_PLOT?.boxPlotFormat?.isVertical ? xAxisHeader : yAxisHeader}
            <XAxisConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_BOX_PLOT || {}}
            />
          </>
        );
      default:
        return (
          <>
            {xAxisHeader}
            <XAxisConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
          </>
        );
    }
  };

  const renderYAxisConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
      case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
      case OPERATION_TYPES.VISUALIZE_MAP_V2:
      case OPERATION_TYPES.VISUALIZE_TABLE:
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
        return;
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
        return (
          <>
            {yAxisHeader}
            <XAxisConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              barValuesConfigurable
              enableSorting
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
        return (
          <>
            {instructions.V2_BOX_PLOT?.boxPlotFormat?.isVertical ? yAxisHeader : xAxisHeader}
            <YAxisConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_BOX_PLOT || {}}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
        return renderGroupAxesConfig(false);
      default:
        return (
          <>
            {yAxisHeader}
            <YAxisConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
          </>
        );
    }
  };

  const renderTwoDimensionChartTooltipConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
        return (
          <>
            <SettingHeader name="Tooltip" />
            <TooltipConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
          </>
        );
      default:
        return;
    }
  };

  const renderGoalLineConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
        return (
          <>
            <SettingHeader
              name="Goal Line"
              actionBtnConfig={{
                actionBtnIcon: 'plus',
                onActionBtnClicked: () =>
                  addGoalLine(
                    dispatch,
                    instructions.V2_TWO_DIMENSION_CHART || {},
                    visualizationType,
                  ),
              }}
            />
            <GoalLineGroupConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
          </>
        );
      default:
        return;
    }
  };

  const renderDataFormatConfig = () => {
    if (isTimeSeriesVisualization(visualizationType, instructions.V2_TWO_DIMENSION_CHART)) {
      return (
        <>
          <SettingHeader name={'Time Series'} />
          <FormatTimeSeriesDataConfig
            instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            visualizationType={visualizationType}
          />
        </>
      );
    }
  };

  const renderChartSpecificConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
        return (
          <>
            <SettingHeader name="Line Chart Options" />
            <LineChartConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
        return (
          <>
            <SettingHeader name="Pie Chart Options" />
            <PieChartConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
        return (
          <>
            <SettingHeader name="Heat Map Options" />
            <HeatMapConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
        return (
          <>
            <SettingHeader name="Funnel Chart Options" />
            <FunnelChartConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
      case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
        return (
          <>
            <SettingHeader name="General" />
            <KPIGeneralConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_KPI || {}}
            />
            <SettingHeader name="Value" />
            <KPIValueConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_KPI || {}}
            />
            <SettingHeader name="Trend" />
            <KPITrendConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_KPI || {}}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
        return (
          <>
            <SettingHeader name="Box Plot Options" />
            <BoxPlotConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_BOX_PLOT || {}}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
        return (
          <>
            {instructions.V2_KPI_TREND?.hideTrendLines && (
              <>
                <SettingHeader name="Title" />
                <KPITrendTitleConfig
                  visualizationType={visualizationType}
                  instructions={instructions.V2_KPI_TREND || {}}
                />
              </>
            )}

            <SettingHeader name="Value" />
            <KPITrendValueConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_KPI_TREND || {}}
            />
            <SettingHeader name="Trend" />
            <KPITrendTrendConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_KPI_TREND || {}}
            />
          </>
        );
      default:
        return;
    }
  };

  const renderLegendFormat = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
      case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
      case OPERATION_TYPES.VISUALIZE_MAP_V2:
      case OPERATION_TYPES.VISUALIZE_TABLE:
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
        return;
      case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
        return (
          <>
            <SettingHeader name="Legend" />
            <LegendConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_BOX_PLOT || {}}
            />
          </>
        );

      default:
        return (
          <>
            <SettingHeader name="Legend" />
            <LegendConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
          </>
        );
    }
  };

  const renderColorFormat = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
      case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
      case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
      case OPERATION_TYPES.VISUALIZE_MAP_V2:
      case OPERATION_TYPES.VISUALIZE_TABLE:
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
        return;
      default:
        return (
          <>
            <SettingHeader name="Colors" />
            <ColorConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
          </>
        );
    }
  };

  const renderDataLabelsFormat = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
        return (
          <>
            <SettingHeader name="Data Labels" />
            <BarLineDataLabelsConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              firstColOnly={!!instructions.V2_TWO_DIMENSION_CHART?.colorColumn?.column.name}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
        return (
          <>
            <SettingHeader name="Data Labels" />
            <BarLineDataLabelsConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              firstColOnly
            />
          </>
        );

      default:
        return;
    }
  };

  const renderComboChartColumnTypeFormat = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
        return (
          <>
            <SettingHeader name="Data Format" />
            <ComboChartColumnTypeConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              firstColOnly={!!instructions.V2_TWO_DIMENSION_CHART?.colorColumn?.column.name}
            />
          </>
        );
      default:
        return;
    }
  };

  const renderTableFormattingConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_TABLE:
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
        return (
          <>
            {visualizationType === OPERATION_TYPES.VISUALIZE_TABLE && (
              <TableHeaderConfig
                instructions={instructions.VISUALIZE_TABLE}
                visualizationType={visualizationType}
              />
            )}
            {visualizationType === OPERATION_TYPES.VISUALIZE_TABLE && (
              <TableFooterConfig
                instructions={instructions.VISUALIZE_TABLE}
                visualizationType={visualizationType}
              />
            )}
            <TablePDFConfig
              instructions={instructions.VISUALIZE_TABLE}
              visualizationType={OPERATION_TYPES.VISUALIZE_TABLE}
            />
            <TableStylingConfig
              instructions={instructions.VISUALIZE_TABLE || {}}
              visualizationType={OPERATION_TYPES.VISUALIZE_TABLE}
            />
          </>
        );
    }
  };

  const renderInfoConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_TABLE:
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
        return;
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
        return (
          <>
            <SettingHeader name="Information Tooltip" />
            <InformationTooltipConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_KPI_TREND || {}}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
        return (
          <>
            <SettingHeader name="Information Tooltip" />
            <InformationTooltipConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_KPI || {}}
            />
          </>
        );
      default:
        return (
          <>
            <SettingHeader name="Information Tooltip" />
            <InformationTooltipConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
          </>
        );
    }
  };

  const renderTableColumnsConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_TABLE:
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
        return (
          <>
            <SettingHeader name="Columns" />
            <TableColumnsConfig
              instructions={instructions.VISUALIZE_TABLE || {}}
              schema={changedSchema}
              dashboardDatasets={dashboardDatasets}
              dataPanelData={dataPanelData}
            />
          </>
        );
    }
  };

  const renderDownloadConfig = () => {
    if (!VIZ_OP_WITH_CSV_DOWNLOAD.has(visualizationType as OPERATION_TYPES)) return <></>;

    return (
      <>
        <SettingHeader name="Download Configuration" />
        <DownloadConfig generalFormatOptions={generalFormatOptions || {}} />
      </>
    );
  };

  const renderDrilldownConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
        return (
          <>
            <SettingHeader name="Drilldowns" />
            <DrilldownConfig
              visualizationType={visualizationType}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
          </>
        );
    }
  };

  const renderNoDataConfig = () => {
    return (
      <>
        <SettingHeader name="No Data Configuration" />
        <NoDataConfig
          generalFormatOptions={generalFormatOptions || {}}
          noIconPresent={
            visualizationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 ||
            visualizationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
            visualizationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
          }
        />
      </>
    );
  };

  return (
    <div className={classes.root}>
      {renderXAxisConfig()}
      {renderYAxisConfig()}
      {renderTwoDimensionChartTooltipConfig()}
      {renderGoalLineConfig()}
      {renderChartSpecificConfig()}
      {renderDataFormatConfig()}
      {renderLegendFormat()}
      {renderDataLabelsFormat()}
      {renderComboChartColumnTypeFormat()}
      {renderColorFormat()}
      {renderTableFormattingConfig()}
      {renderInfoConfig()}
      {renderTableColumnsConfig()}
      {renderDownloadConfig()}
      {renderDrilldownConfig()}
      {renderNoDataConfig()}
    </div>
  );
}
