/** @format */

import React from 'react';
import _ from 'underscore';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

import { DashboardVariable, TimePeriodDropdownDashboardElemConfig } from 'types/dashboardTypes';
import DropdownSelect from 'shared/DropdownSelect';

type PassedProps = {
  config: TimePeriodDropdownDashboardElemConfig;
  onNewValueSelect: (newvalue: DashboardVariable) => void;
  value?: number;
  disabled?: boolean;
};

type Props = PassedProps;

class DashboardTimePeriodDropdownElement extends React.Component<Props> {
  render() {
    const { config, value, onNewValueSelect, disabled } = this.props;

    const options = config.values;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let selectedOption = _.find(options, (option: any) => option.value === value);

    // If the selected value is not in the options, it is coming from a default variable.
    // In this case create your own option for this
    if (value && !selectedOption) {
      selectedOption = { id: String(value), value: value, name: String(value) };
    }

    return (
      <div className={GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow}>
        <DropdownSelect
          fillWidth
          minimal
          disabled={disabled}
          filterable={!!config.enableSearch}
          label={config.label}
          noSelectionText={config.placeholderText || ''}
          onCancelClick={() => onNewValueSelect(undefined)}
          onChange={(item) => {
            onNewValueSelect(item.value);
          }}
          options={options}
          selectedItem={selectedOption}
          showCancelBtn={config.enableCancel}
          useFakeLabel={config.label === ''}
        />
      </div>
    );
  }
}

export default DashboardTimePeriodDropdownElement;
