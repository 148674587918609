/** @format */

import React, { useContext } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/index';
import FlexBox, { HorizontalAlignment, VerticalAlignment } from 'components/core/FlexBox';

import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: (styleConfig: GlobalStyleConfig) => ({
    height: 32,
    padding: `0 ${theme.spacing(4)}px`,
    border: `1px solid ${styleConfig.container.outline.color}`,
    borderTop: 0,
  }),
}));

export type Props = {
  className?: string;
  startRow: number;
  numCurrentRows: number;
  totalRows: number;
  pageNumber: number;
  numPages: number;
};

const PDFTableFooter: React.FC<Props> = ({
  className,
  startRow,
  numCurrentRows,
  totalRows,
  pageNumber,
  numPages,
}) => {
  const context = useContext(GlobalStylesContext);
  const classes = useStyles(context.globalStyleConfig);

  return (
    <FlexBox
      className={cx(
        classes.root,
        className,
        GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor,
        GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color,
      )}
      verticalAlignment={VerticalAlignment.CENTER}
      horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}>
      <FlexBox verticalAlignment={VerticalAlignment.CENTER}>
        {startRow}-{startRow + numCurrentRows - 1} of {totalRows} results
      </FlexBox>
      <FlexBox verticalAlignment={VerticalAlignment.CENTER}>
        Page {pageNumber} of {numPages}
      </FlexBox>
    </FlexBox>
  );
};

export default PDFTableFooter;
