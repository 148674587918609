/** @format */
import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Button, ButtonGroup, Spinner } from '@blueprintjs/core';

import { SelectedDropdownInputItem } from 'constants/types';
import InputLabel from 'shared/InputLabel';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&.bp3-button': {
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  emptyText: {
    fontStyle: 'italic',
    fontColor: theme.palette.ds.grey800,
    marginTop: '10px',
  },
}));

type Props = {
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  selectedItem?: SelectedDropdownInputItem;
  onChange: (item: SelectedDropdownInputItem | undefined) => void;
  options: SelectedDropdownInputItem[];
  fillWidth?: boolean;
  label?: string;
  loading?: boolean;
  preventUnselect?: boolean;
  useFakeLabel?: boolean;
};

export default function DashboardButtonGroupInput(props: Props) {
  const classes = useStyles();
  const { disabled, options, selectedItem, label, loading, preventUnselect, useFakeLabel } = props;

  let uniqueOptions = _.uniq(options, false, (option) => option.name);

  if (uniqueOptions.length > 5) {
    uniqueOptions = uniqueOptions.slice(0, 5);
  }

  if (loading) {
    return (
      <Button fill>
        <Spinner size={Spinner.SIZE_SMALL} />
      </Button>
    );
  } else if (uniqueOptions.length === 0) {
    return (
      <Button fill disabled>
        <p className={classes.emptyText}>No button currently configured.</p>
      </Button>
    );
  }

  return (
    <div className={props.containerClassName}>
      {(label || useFakeLabel) && <InputLabel text={label || ''} fakeLabel={useFakeLabel} />}
      <ButtonGroup fill>
        {uniqueOptions.map((option) => {
          return (
            <Button
              disabled={disabled}
              className={cx(
                classes.button,
                GLOBAL_STYLE_CLASSNAMES.container.fill.buttonBackgroundColor,
                GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.toggleSwitchInteraction,
                GLOBAL_STYLE_CLASSNAMES.container.outline.buttonBorder,
                GLOBAL_STYLE_CLASSNAMES.container.shadow.buttonShadow,
                GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.buttons.buttonCornerRadius,
                GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont,
              )}
              active={option.name === selectedItem?.name}
              text={option.name}
              key={_.uniqueId(`${option.id}_`)}
              onClick={() => {
                if (option.name === selectedItem?.name) {
                  !preventUnselect && props.onChange(undefined);
                } else {
                  props.onChange(option);
                }
              }}
              fill
            />
          );
        })}
      </ButtonGroup>
    </div>
  );
}
