/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { WithStyles, Theme } from '@material-ui/core/index';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { AppToaster } from 'toaster';
import { Intent } from '@blueprintjs/core';

import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import ColumnSelectionDropdown from 'pages/dataPanelEditorPage/visualizeSections/shared/columnSelectionDropdown';
import CalculationsEditingPanel from 'pages/dataPanelEditorPage/calculationsEditingPanel';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';
import ColorPickerGroup from 'pages/dataPanelEditorPage/visualizeSections/shared/colorPickerGroup';
import InputConfig from 'pages/dataPanelEditorPage/visualizeSections/shared/inputConfig';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { VisualizeHeatMapInstructions } from 'constants/types';
import { OPERATION_TYPES, PivotOperationAggregation } from 'constants/types';
import { EMPTY_AGGREGATION } from 'constants/dataPanelEditorConstants';
import { DataPanelTemplate } from 'actions/types';
import InputLabel from 'shared/InputLabel';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    configurationInput: {
      marginBottom: theme.spacing(4),
    },
  });

type PassedProps = {
  instructions: VisualizeHeatMapInstructions;
  dataPanelTemplate: DataPanelTemplate;
};

type Props = PassedProps &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  RouteComponentProps &
  ReturnType<typeof mapStateToProps>;

class VisualizeHeatMapSection extends React.Component<Props> {
  render() {
    const { classes, instructions, dataPanelTemplate, updateVisualizeOperation } = this.props;
    if (!dataPanelTemplate || !dataPanelTemplate._schema) return <div></div>;

    return (
      <div className={classes.root}>
        <ConfigSectionHeader title="Configure heat map" />
        <ColumnSelectionDropdown
          label="X-Axis Column"
          schema={dataPanelTemplate._schema}
          selectedColumnName={instructions.xAxisColumn?.name}
          onSelect={(column) => {
            const newInstrunctions = cloneDeep(instructions);
            newInstrunctions.xAxisColumn = column;
            updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_HEAT_MAP);
          }}
        />
        <ColumnSelectionDropdown
          label="Y-Axis Column"
          schema={dataPanelTemplate._schema}
          selectedColumnName={instructions.yAxisColumn?.name}
          onSelect={(column) => {
            const newInstrunctions = cloneDeep(instructions);
            newInstrunctions.yAxisColumn = column;
            updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_HEAT_MAP);
          }}
        />
        <InputLabel text="Aggregation" />
        <CalculationsEditingPanel
          className={classes.configurationInput}
          baseSchema={dataPanelTemplate._schema}
          aggregation={instructions.aggregation || cloneDeep(EMPTY_AGGREGATION)}
          onUpdate={(pivotOperationAggregation: PivotOperationAggregation) => {
            const newInstrunctions = cloneDeep(instructions);
            newInstrunctions.aggregation = pivotOperationAggregation;
            updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_HEAT_MAP);
          }}
        />
        <ConfigSectionHeader title="Format" />
        <SwitchInput
          className={classes.configurationInput}
          switchOn={instructions.showText}
          onChange={() => {
            const newInstrunctions = cloneDeep(instructions);
            newInstrunctions.showText = !newInstrunctions.showText;
            updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_HEAT_MAP);
          }}
          label="Show text in cells"
        />
        {instructions.showText && (
          <InputConfig
            className={classes.configurationInput}
            value={
              instructions.cellTextSize === 0 || instructions.cellTextSize
                ? String(instructions.cellTextSize)
                : '12'
            }
            placeHolder="11, 12, 14, etc..."
            onUpdate={(newValue: string) => {
              const intValue = parseInt(newValue);
              if (intValue === 0 || intValue) {
                const newInstrunctions = cloneDeep(instructions);
                newInstrunctions.cellTextSize = intValue;
                updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_HEAT_MAP);
              } else {
                AppToaster.show({
                  message: `Please enter the text size as a number`,
                  icon: 'error',
                  timeout: 10000,
                  intent: Intent.WARNING,
                });
              }
            }}
            label="Cell Text Size"
          />
        )}
        <InputWithBlurSave
          containerClassName={classes.configurationInput}
          initialValue={
            instructions.xAxisLabelAngle !== undefined ? String(instructions.xAxisLabelAngle) : '90'
          }
          onNewValueSubmitted={(newAngle: string) => {
            const newInstrunctions = cloneDeep(instructions);
            const angleNum = parseInt(newAngle);
            newInstrunctions.xAxisLabelAngle = angleNum === 0 || angleNum ? angleNum : -90;
            updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_HEAT_MAP);
          }}
          label="X-Axis Label Angle"
        />

        <ColorPickerGroup
          label="Low Color"
          className={classes.configurationInput}
          color={instructions.startColor || '#F1F5FB'}
          onChange={(newColor: string) => {
            const newInstrunctions = cloneDeep(instructions);
            newInstrunctions.startColor = newColor;
            updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_HEAT_MAP);
          }}
        />

        <ColorPickerGroup
          label="High Color"
          className={classes.configurationInput}
          color={instructions.endColor || '#0069ED'}
          onChange={(newColor: string) => {
            const newInstrunctions = cloneDeep(instructions);
            newInstrunctions.endColor = newColor;
            updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_HEAT_MAP);
          }}
        />

        <InputConfig
          className={classes.configurationInput}
          value={
            instructions.labelFontSize === 0 || instructions.labelFontSize
              ? String(instructions.labelFontSize)
              : '12'
          }
          placeHolder="11, 12, 14, etc..."
          onUpdate={(newValue: string) => {
            const intValue = parseInt(newValue);
            if (intValue === 0 || intValue) {
              const newInstrunctions = cloneDeep(instructions);
              newInstrunctions.labelFontSize = intValue;
              updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_HEAT_MAP);
            } else {
              AppToaster.show({
                message: `Please enter the fontsize as a number`,
                icon: 'error',
                timeout: 10000,
                intent: Intent.WARNING,
              });
            }
          }}
          label="Labels Font Size"
        />

        <InputConfig
          className={classes.configurationInput}
          value={
            instructions.titleFontSize === 0 || instructions.titleFontSize
              ? String(instructions.titleFontSize)
              : '12'
          }
          placeHolder="11, 12, 14, etc..."
          onUpdate={(newValue: string) => {
            const intValue = parseInt(newValue);
            if (intValue === 0 || intValue) {
              const newInstrunctions = cloneDeep(instructions);
              newInstrunctions.titleFontSize = intValue;
              updateVisualizeOperation(newInstrunctions, OPERATION_TYPES.VISUALIZE_HEAT_MAP);
            } else {
              AppToaster.show({
                message: `Please enter the fontsize as a number`,
                icon: 'error',
                timeout: 10000,
                intent: Intent.WARNING,
              });
            }
          }}
          label="Title Font Size"
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateVisualizeOperation,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VisualizeHeatMapSection)),
);
