/** @format */

import { Dispatch } from 'react';
import { cloneDeep } from 'lodash';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';

export const addGoalLine = (
  dispatch: Dispatch<any>,
  instructions: V2TwoDimensionChartInstructions,
  visualizationType: string,
) => {
  const newInstructions = cloneDeep(instructions);
  if (!newInstructions.goalLines) {
    newInstructions.goalLines = [];
  }

  newInstructions.goalLines.push({});

  dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
};
