/** @format */

import React from 'react';
import _ from 'underscore';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Layout } from '@explo-tech/react-grid-layout';
import { Theme } from '@material-ui/core/styles';
import { SizeMe } from 'react-sizeme';

import ErrorState from 'components/ErrorState';
import LoadingBody from 'components/loadingBody';
import EditDashboardPage from 'pages/dashboardPage/editDashboardPage';
import ManageVersionsModal from './ManageVersionsModal';

import {
  fetchDashboardTemplate,
  updateDashboardTemplateLayout,
  updateDashboardPdfLayout,
  selectDashboardElementToEdit,
  fetchShareId,
  fetchExportImageUrl,
  fetchExportPdfUrl,
} from 'actions/dashboardAction';
import {
  createDashboardTemplateDataPanelV2,
  updateElementConfigV2,
  deleteDashboardElementV2,
  createNewDashboardVersion,
  switchCurrentlyEditingDashboardVersion,
  publishNewDashboardVersion,
  createDashboardElement,
  fetchDashboardVersions,
  clearDashboardConfigReducer,
  saveDashboardElementUpdates,
  deleteDataPanelV2,
  setDptLoading,
} from 'actions/dashboardV2Actions';
import { listTeamDataSources } from 'actions/dataSourceActions';
import { fetchEndUserGroups } from 'actions/endUserGroupActions';
import { fetchDashboardDatasetPreview } from 'actions/datasetActions';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { OPERATION_TYPES } from 'constants/types';
import {
  fetchDataPanelTemplate,
  downloadDataPanelTemplateCsv,
  downloadDataPanelPdf,
  fetchDataPanelTemplateRowCount,
  fetchSecondaryData,
} from 'actions/dataPanelTemplateAction';
import { selectDashboardDataPanelToEdit } from 'actions/dataPanelConfigActions';
import { fetchParentSchemas, fetchAllSchemaTables } from 'actions/parentSchemaActions';
import { BASE_CONFIG_BY_DASH_ELEM } from 'constants/dashboardConstants';
import { pageView, trackEvent } from 'analytics/exploAnalytics';
import { removeUnsavedDashboardConfigFields } from 'utils/dashboardUtils';
import { DASHBOARD_ELEMENT_TYPES, VIEW_MODE } from 'types/dashboardTypes';
import { SIDE_PANE_HEADER_HEIGHT } from 'components/SidePane';

const styles = (theme: Theme) =>
  createStyles({
    headerContainer: {
      height: SIDE_PANE_HEADER_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderBottom: `1px solid ${theme.palette.ds.grey200}`,
      padding: `0 ${theme.spacing(6)}px`,
    },
    backBtn: {
      marginRight: theme.spacing(2),
      marginLeft: -10,
      marginTop: -3,
    },
    dashboardName: {
      fontSize: 20,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    headerInfoContainer: {
      width: '90%',
    },
  });

type MatchParams = {
  dashboardTemplateId: string;
  edit?: string;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  downloadCsvInfo?: {
    dataPanelTemplateId: number;
    csvDownloadUrl?: string;
    errored?: boolean;
  };
  inEditMode?: boolean;
  viewMode: VIEW_MODE;
  manageVersionsModalOpen?: boolean;
};

class DashboardPage extends React.Component<Props, State> {
  state: State = {
    downloadCsvInfo: undefined,
    viewMode: VIEW_MODE.DEFAULT,
  };

  constructor(props: Props) {
    super(props);

    const {
      fetchDashboardTemplate,
      match,
      fetchEndUserGroups,
      listTeamDataSources,
      fetchAllSchemaTables,
      fetchDashboardVersions,
      fetchParentSchemas,
    } = this.props;

    const hash = window.location.hash;

    fetchDashboardTemplate({ id: match.params.dashboardTemplateId }, (data) => {
      document.title = `Explo | ${data.dashboard_template.name}`;

      if (hash === '#edit' && data.dashboard_version.is_draft) {
        this.setState({ inEditMode: true, viewMode: VIEW_MODE.DEFAULT });
        this.triggerPageResize();
      } else if (hash === '#edit_pdf' && data.dashboard_version.is_draft) {
        this.setState({ inEditMode: true, viewMode: VIEW_MODE.PDF });
        this.triggerPageResize();
      } else {
        window.location.hash = '';
      }

      this.updateEditModeUrlHash();

      fetchDashboardVersions({ id: data.dashboard_template.id });
    });
    fetchEndUserGroups();
    listTeamDataSources();
    fetchAllSchemaTables();
    fetchParentSchemas();
  }

  componentDidMount() {
    pageView('Dashboard');
  }

  componentWillUnmount() {
    this.props.clearDashboardConfigReducer();
  }

  setViewMode = (viewMode: VIEW_MODE) => {
    const { inEditMode } = this.state;
    this.setState({ viewMode });
    if (inEditMode) {
      this.updateEditModeUrlHash(viewMode);
    }
  };

  updateEditModeUrlHash = (newViewMode?: VIEW_MODE) => {
    const { viewMode: currentViewMode } = this.state;

    const viewMode = newViewMode ?? currentViewMode;

    let hash = '';
    switch (viewMode) {
      case VIEW_MODE.DEFAULT:
        hash = '#edit';
        break;
      case VIEW_MODE.PDF:
        hash = '#edit_pdf';
        break;
    }

    window.location.hash = hash;
  };

  render() {
    const {
      dashboardTemplateLoading,
      dashboardLoadingError,
      currentDashboardTemplate,
      createDataPanelLoading,
      updateDashboardTemplateLayout,
      updateDashboardPdfLayout,
      fetchDataPanelTemplate,
      fetchSecondaryData,
      fetchDataPanelTemplateRowCount,
      downloadDataPanelTemplateCsv,
      downloadDataPanelPdf,
      selectDashboardElementToEdit,
      fetchExportImageUrl,
      fetchExportPdfUrl,
      updateElementConfigV2,
      fetchDashboardDatasetPreview,
      selectDashboardDataPanelToEdit,
      endUserGroups,
      shareData,
      shareLinkLoading,
      imageExportUrlLoading,
      pdfExportUrlLoading,
      teamDataSources,
      dashboardConfig,
      dashboardVersionInfo,
      switchEditModeLoading,
      parentSchemaLoading,
      parentSchemas,
      schemaTablesMap,
      schemaMapLoading,
      setDptLoading,
    } = this.props;
    const { inEditMode, viewMode } = this.state;

    if (dashboardLoadingError) return <ErrorState text={dashboardLoadingError.detail} />;

    if (
      dashboardTemplateLoading ||
      !currentDashboardTemplate ||
      !dashboardConfig ||
      !dashboardVersionInfo ||
      parentSchemaLoading ||
      !parentSchemas ||
      schemaMapLoading ||
      !schemaTablesMap
    )
      return <LoadingBody />;

    return (
      <>
        <SizeMe>
          {({ size }) => (
            <EditDashboardPage
              viewMode={viewMode}
              setViewMode={this.setViewMode}
              setDptLoading={setDptLoading}
              dashboardVersionInfo={dashboardVersionInfo}
              switchEditModeLoading={switchEditModeLoading}
              onEditClicked={this.onEditClicked}
              onPreviewClicked={this.onPreviewClicked}
              manageVersionsClicked={() => this.setState({ manageVersionsModalOpen: true })}
              createDataPanelLoading={createDataPanelLoading}
              onLayoutUpdated={
                viewMode === VIEW_MODE.PDF
                  ? updateDashboardPdfLayout
                  : updateDashboardTemplateLayout
              }
              createDataPanel={this.createDataPanel}
              createDashboardElement={this.createDashboardElement}
              dashboard={currentDashboardTemplate}
              dataPanels={Object.values(dashboardConfig?.data_panels)}
              dashboardElements={Object.values(dashboardConfig?.elements)}
              dashboardDatasets={dashboardConfig.datasets}
              dashboardLayout={
                viewMode === VIEW_MODE.PDF
                  ? dashboardConfig.pdf_layout ?? dashboardConfig.dashboard_layout
                  : dashboardConfig.dashboard_layout
              }
              dashboardParams={dashboardConfig.params}
              headerTitle={currentDashboardTemplate.name}
              fetchDataPanelTemplate={fetchDataPanelTemplate}
              fetchSecondaryData={fetchSecondaryData}
              fetchDataPanelRowCount={fetchDataPanelTemplateRowCount}
              editableDashboard={inEditMode}
              downloadDataPanelCsv={downloadDataPanelTemplateCsv}
              downloadDataPanelPdf={downloadDataPanelPdf}
              selectDashboardElementToEdit={selectDashboardElementToEdit}
              selectDashboardDataPanelToEdit={selectDashboardDataPanelToEdit}
              editorDatasets={dashboardConfig.datasets}
              dashboardTemplateId={currentDashboardTemplate.id}
              updateElementConfig={updateElementConfigV2}
              deleteDashboardElement={this.deleteDashboardElement}
              deleteDataPanelTemplate={this.deleteDataPanelTemplate}
              fetchDatasetPreview={fetchDashboardDatasetPreview}
              endUserGroups={endUserGroups}
              teamDataSources={teamDataSources}
              fetchShareData={this.fetchShareDataWrapper}
              fetchImageExportUrl={fetchExportImageUrl}
              fetchPdfExportUrl={fetchExportPdfUrl}
              shareData={shareData}
              shareLinkLoading={shareLinkLoading}
              imageExportUrlLoading={imageExportUrlLoading}
              pdfExportUrlLoading={pdfExportUrlLoading}
              parentSchemas={parentSchemas}
              schemaTablesMap={schemaTablesMap}
              onReturnMostCurrentVersionClicked={this.onReturnMostCurrentVersionClicked}
              width={size.width}
              updateEditModeUrlHash={this.updateEditModeUrlHash}
            />
          )}
        </SizeMe>
        {this.renderManageVersionsModal()}
      </>
    );
  }

  renderManageVersionsModal = () => {
    const { currentDashboardTemplate } = this.props;
    const { manageVersionsModalOpen } = this.state;

    if (!manageVersionsModalOpen || !currentDashboardTemplate) return;

    return (
      <ManageVersionsModal
        dashboardTemplateId={currentDashboardTemplate.id}
        modalOpen={manageVersionsModalOpen}
        closeModal={() => this.setState({ manageVersionsModalOpen: false })}
        onPublishVersion={this.onPublishVersion}
      />
    );
  };

  triggerPageResize = () => {
    // We need to trigger a page resize whenever going in and out of the edit mode.
    // This is because the dashboard layout only resizes on window resize events
    setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
  };

  onReturnMostCurrentVersionClicked = () => {
    const {
      switchCurrentlyEditingDashboardVersion,
      dashboardVersionInfo,
      currentDashboardTemplate,
      allDashboardVersions,
    } = this.props;
    if (!dashboardVersionInfo || !currentDashboardTemplate) return;

    const sortedVersions = _.sortBy(allDashboardVersions, (version) => -version.version_number);

    if (sortedVersions.length > 0) {
      switchCurrentlyEditingDashboardVersion({ dashboardVersion: sortedVersions[0] });
      this.setState(this.triggerPageResize);
    }
    window.location.hash = '';
  };

  onEditClicked = () => {
    const {
      createNewDashboardVersion,
      switchCurrentlyEditingDashboardVersion,
      dashboardVersionInfo,
      currentDashboardTemplate,
      allDashboardVersions,
    } = this.props;
    if (!dashboardVersionInfo || !currentDashboardTemplate) return;

    if (!dashboardVersionInfo.is_draft) {
      const sortedVersions = _.sortBy(allDashboardVersions, (version) => -version.version_number);

      // If sortedVersions is empty, the dashboard versions have not loaded yet, which means they
      // couldn't have switched to a different version
      if (sortedVersions.length > 0 && sortedVersions[0].is_draft) {
        switchCurrentlyEditingDashboardVersion({ dashboardVersion: sortedVersions[0] });
      } else {
        createNewDashboardVersion({ id: currentDashboardTemplate.id }, (response) => {
          switchCurrentlyEditingDashboardVersion({ dashboardVersion: response.dashboard_version });
        });
      }
    }

    this.setState({ inEditMode: true }, this.triggerPageResize);
    this.updateEditModeUrlHash();
  };

  onPreviewClicked = () => {
    this.setState({ inEditMode: false }, this.triggerPageResize);
    window.location.hash = '';
  };

  onPublishVersion = (versionNumber: number, changeComments: string, onSuccess?: () => void) => {
    const { publishNewDashboardVersion, dashboardConfig, currentDashboardTemplate } = this.props;
    if (!currentDashboardTemplate || !dashboardConfig) return;

    publishNewDashboardVersion(
      {
        id: currentDashboardTemplate.id,
        postData: {
          config: removeUnsavedDashboardConfigFields(dashboardConfig),
          version_number: versionNumber,
          change_comments: changeComments,
        },
      },
      () => onSuccess?.(),
    );
  };

  fetchShareDataWrapper = (
    endUserGroupId: number,
    password?: string,
    isStrictViewingMode?: boolean,
  ) => {
    const {
      fetchShareId,
      match,
      dashboardVersionInfo,
      fetchDashboardVersions,
      currentDashboardTemplate,
    } = this.props;
    const commonPostData = {
      dashboard_template_id: parseInt(match.params.dashboardTemplateId),
      user_group_id: endUserGroupId,
    };
    const dashboardId = currentDashboardTemplate?.id;

    fetchDashboardVersions({ id: dashboardId }, (data) => {
      const currentDashboardVersion = _.find(
        data.versions,
        (version) => version.version_number === dashboardVersionInfo?.version_number,
      )?.id;
      const environmentTagId = dashboardId
        ? _.find(
            data.tags,
            (tag) => tag.dashboard_versions_by_dashboard?.[dashboardId] === currentDashboardVersion,
          )?.id
        : undefined;
      fetchShareId({
        postData: {
          ...commonPostData,
          ...(!environmentTagId && { version_number: dashboardVersionInfo?.version_number }),
          ...(environmentTagId && { environment_tag_id: environmentTagId }),
          ...(password && { password }),
          ...{
            is_strict_viewing_mode: isStrictViewingMode === true,
          },
        },
      });
    });
  };

  createDataPanel = (
    datasetId: string,
    name: string,
    newLayout: Layout[],
    containerId?: string,
    vizType?: OPERATION_TYPES,
    onSuccess?: () => void,
  ) => {
    const { currentDashboardTemplate, createDashboardTemplateDataPanelV2 } = this.props;
    if (!currentDashboardTemplate) return;

    createDashboardTemplateDataPanelV2({
      dashId: currentDashboardTemplate.id,
      name,
      newLayout,
      datasetId,
      vizType,
      containerId,
    });

    trackEvent('Added data panel', {
      dashboard_template_id: currentDashboardTemplate.id,
      selected_dataset_id: datasetId,
    });

    onSuccess && onSuccess();
  };

  createDashboardElement = (
    elemType: DASHBOARD_ELEMENT_TYPES,
    newLayout: Layout[],
    containerId?: string,
  ) => {
    const { createDashboardElement, currentDashboardTemplate } = this.props;

    if (currentDashboardTemplate) {
      createDashboardElement({
        elementType: elemType,
        initialConfig: { ...BASE_CONFIG_BY_DASH_ELEM[elemType] },
        dashId: currentDashboardTemplate.id,
        newLayout: newLayout,
        containerId: containerId,
      });
      trackEvent('Added dashboard element', {
        dashboard_template_id: currentDashboardTemplate.id,
        element_type: elemType,
      });
    }
  };

  deleteDashboardElement = (elementId: string, elementType: string) => {
    const { deleteDashboardElementV2 } = this.props;

    deleteDashboardElementV2({ elementId: elementId });

    trackEvent('Deleted dashboard element', {
      element_id: elementId,
      element_type: elementType,
    });
  };

  deleteDataPanelTemplate = (dataPanelId: string) => {
    const { deleteDataPanelV2 } = this.props;

    deleteDataPanelV2({ id: dataPanelId });

    trackEvent('Deleted data panel', {
      data_panel_template_id: dataPanelId,
    });
  };
}

const mapStateToProps = (state: ReduxState) => ({
  dashboardTemplateLoading: createLoadingSelector(
    [ACTION.FETCH_DASHBOARD_TEMPLATE, ACTION.FETCH_END_USER_GROUPS, ACTION.LIST_TEAM_DATA_SOURCES],
    false,
  )(state),
  switchEditModeLoading: createLoadingSelector(
    [ACTION.CREATE_NEW_DASHBOARD_VERSION, ACTION.PUBLISH_NEW_DASHBOARD_VERSION],
    false,
  )(state),
  createDataPanelLoading: createLoadingSelector([ACTION.CREATE_TEMPLATE_DATA_PANEL], false)(state),
  currentDashboardTemplate: state.dashboard.currentDashboardTemplate,
  editingDashboardElementConfig: state.editingDashboardElement.oldConfig,
  endUserGroups: state.endUserGroups.groups,
  teamDataSources: state.dataSourceList.dataSources,
  shareData: state.embedDashboard.shareData,
  shareLinkLoading: createLoadingSelector([ACTION.FETCH_SHARE_ID], false)(state),
  pdfExportUrlLoading: createLoadingSelector([ACTION.FETCH_PDF_EXPORT_URL], false)(state),
  imageExportUrlLoading: createLoadingSelector([ACTION.FETCH_IMAGE_EXPORT_URL], false)(state),
  dashboardLoadingError: state.dashboard.error,
  dashboardConfig: state.dashboardEditConfig.config,
  dashboardVersionInfo: state.dashboardEditConfig.versionInfo,
  allDashboardVersions: state.dashboardVersions.versions,
  parentSchemas: state.parentSchemas.allParentSchema,
  parentSchemaLoading: createLoadingSelector([ACTION.FETCH_PARENT_SCHEMAS], false)(state),
  schemaTablesMap: state.parentSchemas.schemaTablesMap,
  schemaMapLoading: createLoadingSelector([ACTION.FETCH_ALL_SCHEMA_TABLES_FOR_TEAM], false)(state),
});

const mapDispatchToProps = {
  fetchDashboardTemplate,
  fetchDataPanelTemplate,
  updateDashboardTemplateLayout,
  updateDashboardPdfLayout,
  fetchDataPanelTemplateRowCount,
  fetchSecondaryData,
  downloadDataPanelTemplateCsv,
  downloadDataPanelPdf,
  selectDashboardElementToEdit,
  updateElementConfigV2,
  deleteDataPanelV2,
  fetchDashboardDatasetPreview,
  fetchEndUserGroups,
  fetchShareId,
  fetchExportImageUrl,
  fetchExportPdfUrl,
  selectDashboardDataPanelToEdit,
  listTeamDataSources,
  createDashboardTemplateDataPanelV2,
  deleteDashboardElementV2,
  createNewDashboardVersion,
  switchCurrentlyEditingDashboardVersion,
  publishNewDashboardVersion,
  createDashboardElement,
  fetchDashboardVersions,
  clearDashboardConfigReducer,
  saveDashboardElementUpdates,
  fetchAllSchemaTables,
  fetchParentSchemas,
  setDptLoading,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardPage)),
);
