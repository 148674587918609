/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { IconName } from '@blueprintjs/core';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import DropdownSelect from 'shared/DropdownSelect';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { TableColumn } from 'actions/types';
import { DateDisplayFormat, DateDisplayOptions, VisualizeTableInstructions } from 'constants/types';
import { ORDERED_ALIGNMENTS } from 'constants/dataConstants';
import { TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  addFieldsToDisplayOptions: (
    newFields: Partial<DateDisplayOptions> & {
      format: DateDisplayFormat;
    },
  ) => void;
  column: TableColumn;
  instructions: VisualizeTableInstructions;
};

export default function DateFormatConfiguration({
  addFieldsToDisplayOptions,
  column,
  instructions,
}: Props) {
  const classes = useStyles();
  const { format: currentFormat, customFormat, alignment: currentAlignment } = (instructions
    .schemaDisplayOptions?.[column.name] as DateDisplayOptions) ?? {
    format: DateDisplayFormat.NORMAL,
  };

  const onButtonClick = (newFormat: DateDisplayFormat) => {
    if (newFormat === currentFormat) {
      return;
    }

    addFieldsToDisplayOptions({ format: newFormat });
  };

  return (
    <>
      <DropdownSelect
        fillWidth
        minimal
        containerClassName={classes.input}
        noSelectionText={DateDisplayFormat.NORMAL}
        label="Format"
        selectedItem={currentFormat && { id: currentFormat, name: currentFormat }}
        onChange={(item) => {
          onButtonClick(item.id as DateDisplayFormat);
        }}
        options={Object.values(DateDisplayFormat).map((format) => ({ id: format, name: format }))}
      />
      {currentFormat === DateDisplayFormat.CUSTOM && (
        <InputWithBlurSave
          fillWidth
          containerClassName={classes.input}
          label="Custom format"
          initialValue={customFormat}
          onNewValueSubmitted={(newFormat) => {
            addFieldsToDisplayOptions({ format: currentFormat, customFormat: newFormat });
          }}
        />
      )}
      <ToggleButtonGroup fillWidth className={classes.input} label="Alignment">
        {ORDERED_ALIGNMENTS.map((alignment) => (
          <ToggleButton
            key={`alignment-${alignment.id}-${column.name}`}
            active={(currentAlignment || TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT) === alignment.id}
            icon={alignment.icon as IconName}
            onClick={() => {
              addFieldsToDisplayOptions({ alignment: alignment.id, format: currentFormat });
            }}
          />
        ))}
      </ToggleButtonGroup>
    </>
  );
}
