/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';

import DropdownSelect from 'shared/DropdownSelect';

import { SelectedDropdownInputItem, VisualizePieChartInstruction } from 'constants/types';
import { LEGEND_LOCATIONS } from 'constants/dataConstants';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(4),
    },
  });

type PassedProps = {
  instructions: VisualizePieChartInstruction;
  updateVisualizeOperation: (newInstructions: VisualizePieChartInstruction) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class SelectPieChartLegendLocation extends React.Component<Props> {
  render() {
    const { classes, instructions } = this.props;

    return (
      <div className={classes.root}>
        <DropdownSelect
          label="Legend Location"
          selectedItem={
            instructions.legendLocation
              ? LEGEND_LOCATIONS[instructions.legendLocation]
              : LEGEND_LOCATIONS.right
          }
          onChange={this.onChange}
          noSelectionText="Select a location"
          options={Object.values(LEGEND_LOCATIONS)}
          minimal
          fillWidth
          showIcon
        />
      </div>
    );
  }

  onChange = (item: SelectedDropdownInputItem) => {
    const { instructions, updateVisualizeOperation } = this.props;

    instructions.legendLocation = item.id;
    updateVisualizeOperation(instructions);
  };
}

export default withStyles(styles)(SelectPieChartLegendLocation);
