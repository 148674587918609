/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';

import { Notifier } from '@airbrake/browser';

const styles = () => ({
  errorRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 100px',
    textAlign: 'center' as 'center',
  },
});

type PassedProps = {};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  hasError: boolean;
};

class InAppErrorBoundary extends React.Component<Props> {
  state: State = { hasError: false };

  airbrake = new Notifier({
    projectId: 261047,
    projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY || '',
    environment: process.env.REACT_APP_AIRBRAKE_ENVIRONMENT || '',
  });

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    this.airbrake.notify({
      error: error,
      params: { info: errorInfo },
    });
  }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={classes.errorRoot}>
          <h1>
            Oops! Something went wrong{' '}
            <span role="img" aria-label="laugh face">
              😅
            </span>
            . Please reload the page and contact support@explo.co if the problem persists.
          </h1>{' '}
        </div>
      );
    }

    return this.props.children;
  }
}

export default withStyles(styles)(InAppErrorBoundary);
