/** @format */

import { defineAPIAction } from 'actions/actionUtils';
import { ACTION } from './types';
import {
  CreateNewDashboardDatasetData,
  FetchDashboardDatasetPreviewData,
} from 'actions/responseTypes';

export const { actionFn: fetchDatasetPreview } = defineAPIAction(
  ACTION.FETCH_DATASET_PREVIEW,
  'datasources',
  'get_preview',
  'POST',
);

export const { actionFn: fetchDatasetRowCount } = defineAPIAction(
  ACTION.FETCH_DATASET_ROW_COUNT,
  'datasources',
  'get_preview',
  'POST',
);

// Dashboard Datasets

export const { actionFn: createNewDashboardDataset } = defineAPIAction<
  CreateNewDashboardDatasetData
>(ACTION.CREATE_NEW_DASHBOARD_DATASET, 'datasets', '', 'POST');

export const { actionFn: fetchDashboardDatasetPreview } = defineAPIAction<
  FetchDashboardDatasetPreviewData
>(ACTION.FETCH_DASHBOARD_DATASET_PREVIEW, 'datasets', 'get_preview', 'POST');

export const { actionFn: fetchEditorDatasetPreview } = defineAPIAction<
  FetchDashboardDatasetPreviewData
>(ACTION.FETCH_EDITOR_DATASET_PREVIEW, 'datasets', 'get_preview', 'POST');

export const { actionFn: fetchDashboardDatasetRowCount } = defineAPIAction(
  ACTION.FETCH_DASHBOARD_DATASET_ROW_COUNT,
  'datasets',
  'get_preview',
  'POST',
);

export const { actionFn: fetchEditorDatasetRowCount } = defineAPIAction<
  FetchDashboardDatasetPreviewData
>(ACTION.FETCH_EDITOR_DATASET_ROW_COUNT, 'datasets', 'get_preview', 'POST');
