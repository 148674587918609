/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import cx from 'classnames';
//@ts-ignore
import ScrollToTop from 'react-router-scroll-top';
import { StatsigProvider } from 'statsig-react';

import PrivateRoute from 'components/privateRoute';
import SignUpPage from 'pages/signUpPageV2';
import SignInPage from 'pages/signInPageV2';
import CheckYourEmailPage from 'pages/checkYourEmailPageV2';
import VerifyEmailPage from 'pages/verifyEmailPageV2';
import AnalyticsPage from 'pages/analyticsPage';
import HomeAppPage from 'pages/homeAppPage/homeAppPage';
import JoinTeamPage from 'pages/joinTeamPageV2';
import SharedDashboardPage from 'pages/sharedDashboardPage';
import SharedChartPage from 'pages/SharedChartPage';
import PdfDashboardPage from 'pages/PdfDashboardPage';
import NotionAnalyticsPage from 'pages/notionAnalyticsPage';
import EmailFormSubmissionRedirectPage from 'pages/EmailFormSubmissionRedirectPage';
import TrialExpiredPage from 'pages/trialExpiredPage';
import ConnectDataSourcePage from 'pages/connectDataSourcePage';
import ConnectDataSourceFlow from 'pages/ConnectDataSourceFlow/ConnectDataSourceFlow';
import SyncDataTablesPage from 'pages/syncDataTablesPage';
import ManageDataTablesPage from 'pages/manageDataTablesPage/manageDataTablesPage';
import SettingsPage from 'pages/settingsPage/settingsPage';
import DashboardPage from 'pages/dashboardPage/dashboardPage';
import CustomersPage from 'pages/customersPage/customersPage';
import CustomizeStylesPage from 'pages/GlobalCustomStylesPage';
import ForgotPasswordPage from 'pages/forgotPasswordPage';
import ResetPasswordPage from 'pages/resetPasswordPage';
import IframeDashboardPage from 'pages/IframeDashboardPage';
import DataSourcesPage from 'pages/DataSourcesPage';

import { loginUserSuccess, logoutUser } from './actions/authAction';
import { fetchProfile } from './auth/userAuth';
import { NAVBAR_TABS } from 'components/pages/navbar';
import { fetchGeoJson } from 'utils/geoJsonUtils';
import { DEFAULT_GLOBAL_STYLE_CONFIG, GlobalStylesContext } from 'globalStyles';

import { ROUTES } from 'constants/routes';

import LoadingSpinner from 'images/loading_spinner.gif';

import 'index.scss';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import '@explo-tech/react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { getStatsigEnvironment } from 'featureGates/utils';
import { Notifier } from '@airbrake/browser';

const styles = () => ({
  root: {
    fontFamily: "'Inter', sans-serif",
  },
  loadingRoot: {
    height: '100vh',
    perspective: '1px',
    overflowY: 'auto' as 'auto',
  },
  loadingPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  loadingSpinner: {
    width: 75,
    zIndex: 2,
  },
});

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles>;

type State = {
  loading: boolean;
};

class Routes extends Component<Props, State> {
  state = {
    loading: true,
  };

  airbrake = new Notifier({
    projectId: 261047,
    projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY || '',
    environment: process.env.REACT_APP_AIRBRAKE_ENVIRONMENT || '',
  });

  statsigErrorMsg =
    'No statsig API key provided in environment variables, all gates will evaluate to default.';

  componentDidMount() {
    // Custom subdomains should only be able to access the /share/ link, this ensures that.
    if (
      process.env.REACT_APP_URL &&
      // To ensure we're not on a Layer CI environment or local
      !['dev', 'development'].includes(process.env.REACT_APP_ENVIRONMENT ?? '') &&
      !window.location.href.includes(process.env.REACT_APP_URL) &&
      !window.location.href.includes('dev.explo.co') &&
      !window.location.href.includes('/share/')
    ) {
      window.location.href = 'https://app.explo.co/home';
    }

    fetchProfile(
      (user) => {
        this.props.loginUserSuccess(user);
        this.setState({ loading: false });
      },
      () => {
        this.props.logoutUser();
        this.setState({ loading: false });
      },
    );

    // Fetching GeoJsons
    window.exploAssets = {};
    fetchGeoJson('worldGeoJson.json', 'worldGeoJson');
    fetchGeoJson('unitedStatesGeoJson.json', 'unitedStatesGeoJson');
    fetchGeoJson('worldGeoJsonMapping.json', 'worldGeoJsonMapping');
    fetchGeoJson('unitedStatesGeoJsonMapping.json', 'unitedStatesGeoJsonMapping');
  }

  render() {
    const { classes, currentUser } = this.props;

    const statsigApiKey = process.env.REACT_APP_STATSIG_SDK_KEY;
    if (!statsigApiKey) {
      console.error(this.statsigErrorMsg);
      this.airbrake.notify(this.statsigErrorMsg);
    }

    return this.state.loading ? (
      <div className={classes.loadingRoot}>
        <div className={classes.loadingPage}>
          <img className={classes.loadingSpinner} src={LoadingSpinner} alt="loading spinner" />
        </div>
      </div>
    ) : (
      // TODO PD-1243: provide a loading component to initializingComponent
      <StatsigProvider
        sdkKey={statsigApiKey ?? ''}
        user={{
          userID: currentUser.team?.id,
          custom: { exploEnv: process.env.REACT_APP_ENVIRONMENT },
        }}
        options={{
          environment: getStatsigEnvironment(),
        }}
        waitForInitialization={statsigApiKey !== undefined}>
        {
          // We use the default here and override with actual styles when rendering DashboardLayout
        }
        <GlobalStylesContext.Provider
          value={{
            globalStyleConfig: DEFAULT_GLOBAL_STYLE_CONFIG,
          }}>
          <Router>
            <ScrollToTop />
            <div className={cx(classes.root, 'explo-routes')}>
              <Switch>
                <Route
                  exact
                  path={ROUTES.HOME}
                  component={() => <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />}
                />
                <Route exact path={ROUTES.VERIFY_EMAIL} component={VerifyEmailPage} />
                <Route exact path={ROUTES.SIGNUP} component={SignUpPage} />
                <Route
                  exact
                  path={ROUTES.EMAIL_FORM_SUBMISSION_REDIRECT}
                  component={EmailFormSubmissionRedirectPage}
                />
                <Route exact path={ROUTES.IFRAME} component={IframeDashboardPage} />
                <Route exact path={ROUTES.SHARED_DASHBOARD} component={SharedDashboardPage} />
                <Route
                  exact
                  path={ROUTES.SHARED_DASHBOARD_STRICT}
                  component={SharedDashboardPage}
                />
                <Route exact path={ROUTES.SHARE_CHART} component={SharedChartPage} />
                <Route exact path={ROUTES.PDF_DASHBOARD} component={PdfDashboardPage} />
                <Route exact path={ROUTES.NOTION_ANALYTICS} component={NotionAnalyticsPage} />
                <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordPage} />
                <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />
                <PrivateRoute exact path={ROUTES.LOGIN} pageComponent={SignInPage} />
                <PrivateRoute exact path={ROUTES.TRIAL_EXPIRED} pageComponent={TrialExpiredPage} />
                <PrivateRoute
                  exact
                  path={ROUTES.CHECK_YOUR_EMAIL}
                  pageComponent={CheckYourEmailPage}
                />
                <PrivateRoute exact path={ROUTES.JOIN_TEAM} pageComponent={JoinTeamPage} />
                <PrivateRoute
                  exact
                  path={ROUTES.HOME_APP_PAGE}
                  withNavigation
                  pageComponent={HomeAppPage}
                  activeTabId={NAVBAR_TABS.DASHBOARDS.id}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.DASHBOARD_PAGE}
                  withNavigation
                  pageComponent={DashboardPage}
                  activeTabId={NAVBAR_TABS.DASHBOARDS.id}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.CUSTOMERS_PAGE}
                  withNavigation
                  pageComponent={CustomersPage}
                  activeTabId={NAVBAR_TABS.CUSTOMERS.id}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.ANALYTICS}
                  withNavigation
                  pageComponent={AnalyticsPage}
                  activeTabId={NAVBAR_TABS.ANALYTICS.id}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.CONNECT_DATA_SOURCE}
                  withNavigation
                  onboardingPage
                  pageComponent={ConnectDataSourceFlow}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.ADD_DATA_SOURCE}
                  withNavigation
                  pageComponent={ConnectDataSourcePage}
                  activeTabId={NAVBAR_TABS.DATA.id}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.SYNC_DATA_TABLES}
                  withNavigation
                  pageComponent={SyncDataTablesPage}
                  activeTabId={NAVBAR_TABS.DATA.id}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.SYNC_DATA_TABLES_NO_SCHEMA}
                  withNavigation
                  pageComponent={SyncDataTablesPage}
                  activeTabId={NAVBAR_TABS.DATA.id}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.DATA_SOURCES_PAGE}
                  withNavigation
                  pageComponent={DataSourcesPage}
                  activeTabId={NAVBAR_TABS.DATA.id}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.MANAGE_DATA_TABLES}
                  withNavigation
                  pageComponent={ManageDataTablesPage}
                  activeTabId={NAVBAR_TABS.DATA.id}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.SETTINGS_PAGE}
                  withNavigation
                  pageComponent={SettingsPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.GLOBAL_CUSTOM_STYLES_PAGE}
                  withNavigation
                  pageComponent={CustomizeStylesPage}
                />
                <PrivateRoute noMatch pageComponent={HomeAppPage} />
              </Switch>
            </div>
          </Router>
        </GlobalStylesContext.Provider>
      </StatsigProvider>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  ...state,
});

const mapDispatchToProps = {
  loginUserSuccess,
  logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Routes));
