/** @format */

import { defineAPIAction } from 'actions/actionUtils';
import { ACTION, AdHocOperationInstructions } from 'actions/types';
import {
  FetchDataPanelTemplateData,
  FetchDataPanelTemplateRowCountData,
  DownloadDataPanelTemplateCsvData,
  PdfUrlResponse,
} from 'actions/responseTypes';

export const { actionFn: fetchDataPanelTemplate } = defineAPIAction<FetchDataPanelTemplateData>(
  ACTION.FETCH_DATA_PANEL_TEMPLATE,
  'data_panel_templates',
  'get_data_panel_data_v2',
  'POST',
);

export const { actionFn: fetchSecondaryData } = defineAPIAction<FetchDataPanelTemplateData>(
  ACTION.FETCH_SECONDARY_DATA,
  'data_panel_templates',
  'get_data_panel_data_v2',
  'POST',
);

export const { actionFn: fetchDataPanelTemplateRowCount } = defineAPIAction<
  FetchDataPanelTemplateRowCountData
>('FETCH_DATA_PANEL_TEMPLATE_ROW_COUNT', 'data_panel_templates', 'get_data_panel_data_v2', 'POST');

export const { actionFn: downloadDataPanelTemplateCsv } = defineAPIAction<
  DownloadDataPanelTemplateCsvData
>(
  ACTION.DOWNLOAD_DATA_PANEL_TEMPLATE_CSV,
  'data_panel_templates',
  'get_data_panel_data_v2',
  'POST',
);

export const { actionFn: downloadDataPanelPdf } = defineAPIAction<PdfUrlResponse>(
  ACTION.DOWNLOAD_DATA_PANEL_TEMPLATE_PDF,
  'data_panel_templates',
  'export/pdf',
  'POST',
);

export type UpdateAdHocOperationInstructionsArgs = {
  dataPanelTemplateId: string;
  adHocOperationInstructions: AdHocOperationInstructions;
};

export const updateAdHocOperationInstructions = (args: UpdateAdHocOperationInstructionsArgs) => ({
  type: ACTION.UPDATE_AD_HOC_OPERATION_INSTRUCTIONS,
  payload: {
    ...args,
  },
});
