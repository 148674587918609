/** @format */

import { Colors } from '@blueprintjs/core';
import { ColorPalette } from 'constants/types';

export type ColorHex = string;

export type ColorGroup = {
  primary: ColorHex;
  secondary: ColorHex;
};

export const BLUE: ColorGroup = {
  primary: '#0069ED',
  secondary: '#48AFF0',
};

export const GREEN: ColorGroup = {
  primary: '#0A6640',
  secondary: '#3DCC91',
};

export const ORANGE: ColorGroup = {
  primary: '#A66321',
  secondary: '#FFB366',
};

export const RED: ColorGroup = {
  primary: '#A82A2A',
  secondary: '#FF7373',
};

export const INDIGO: ColorGroup = {
  primary: '#5642A6',
  secondary: '#AD99FF',
};

export const GOLD: ColorGroup = {
  primary: '#A67908',
  secondary: '#FFC940',
};

export const COLOR_LIST: ColorGroup[] = [BLUE, GREEN, ORANGE, RED, INDIGO, GOLD];

export const DEFAULT_CATEGORY_COLORS = [
  '#FFC940',
  '#AD99FF',
  '#FF7373',
  '#FFB366',
  '#3DCC91',
  '#3DCC91',
  '#48AFF0',
  '#A67908',
  '#5642A6',
  '#A82A2A',
  '#A66321',
  '#0A6640',
  '#0069ED',
];

export const BP_BLUE = {
  primary: Colors.BLUE3,
};

export const BP_SEPIA = {
  primary: Colors.SEPIA3,
};

export const BP_ORANGE = {
  primary: Colors.ORANGE3,
};

export const BP_INDIGO = {
  primary: Colors.INDIGO3,
};

export const BP_TURQUOISE = {
  primary: Colors.TURQUOISE3,
};

export const BP_GOLD = {
  primary: Colors.GOLD3,
};

export const BP_COLORS = [BP_BLUE, BP_SEPIA, BP_ORANGE, BP_INDIGO, BP_TURQUOISE, BP_GOLD];

export const PALETTE_TO_COLORS: Record<string, string[]> = {
  [ColorPalette.DEFAULT]: [
    '#7cb5ec',
    '#434348',
    '#90ed7d',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
    '#e4d354',
    '#2b908f',
    '#f45b5b',
    '#91e8e1',
  ],
  [ColorPalette.CUSTOM]: [],
  [ColorPalette.BLUE]: [
    '#0069ed',
    '#1a78ef',
    '#3387f1',
    '#4d96f2',
    '#66a5f4',
    '#80b4f6',
    '#99c3f8',
    '#b3d2fa',
    '#cce1fb',
  ],
  [ColorPalette.GREEN]: [
    '#29a634',
    '#3eaf48',
    '#54b85d',
    '#69c171',
    '#7fca85',
    '#94d39a',
    '#a9dbae',
    '#bfe4c2',
    '#d4edd6',
  ],
};
