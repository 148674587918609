/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';

import AceEditor from 'react-ace';
import ResizeObserver from 'react-resize-observer';

import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/ext-language_tools';

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative' as 'relative',

    '& .ace_gutter': {
      zIndex: 2,
    },
  },
});

type PassedProps = {
  tableNames: string[];
  columnNames: string[];
  query: string;
  onChange: (newQuery: string) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  width?: number;
  height?: number;
};

class Editor extends React.Component<Props, State> {
  state: State = {};

  render() {
    const { classes, query, onChange } = this.props;
    const { width, height } = this.state;

    return (
      <div className={classes.root}>
        <AceEditor
          height={height ? `${height}px` : '100%'}
          width={width ? `${width}px` : '100%'}
          mode="sql"
          // eslint-disable-next-line react/no-string-refs
          ref="reactAceComponent"
          theme="xcode"
          value={query}
          onChange={onChange}
          setOptions={{
            //@ts-ignore
            enableBasicAutocompletion: [this.constructAutoCompleter()],
            enableLiveAutocompletion: true,
            enableSnippets: true,
          }}
          name="UNIQUE_ID_OF_DIV"
        />
        <ResizeObserver
          onResize={(rect) => {
            this.setState({ width: rect.width, height: rect.height });
          }}
        />
      </div>
    );
  }

  constructAutoCompleter = () => {
    const { tableNames, columnNames } = this.props;

    return {
      // @ts-ignore
      getCompletions: function (editor, session, pos, prefix, callback) {
        callback(null, [
          ...tableNames.map(function (tableName) {
            return {
              caption: tableName,
              value: tableName,
              meta: 'Table',
            };
          }),
          ...columnNames.map(function (columnName) {
            const colParts = columnName.split('.');
            return {
              caption: columnName,
              value: colParts[colParts.length - 1], // take the last part, since BQ has multiple parts in a table
              meta: 'Column',
            };
          }),
          ...session.$mode.$highlightRules.$keywordList.map(function (word: string) {
            return {
              caption: word.toUpperCase(),
              value: word.toUpperCase(),
              meta: 'keyword',
            };
          }),
        ]);
      },
    };
  };
}

export default withStyles(styles)(Editor);
