/** @format */
import React, { useState, useEffect, createRef } from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import useEventListener from '@use-it/event-listener';

import { Dataset, ParentSchema, DataPanelTemplate } from 'actions/types';
import {
  DashboardElement,
  DashboardVariableMap,
  DASHBOARD_ELEMENT_TYPES,
} from 'types/dashboardTypes';
import DashboardDatasetEditor, {
  MENU_HEIGHT,
  INITIAL_DATASET_EDITOR_HEIGHT,
} from 'pages/dashboardPage/dashboardDatasetEditor/dashboardDatasetEditor';

const useStyles = makeStyles((theme: Theme) => ({
  datasetEditorContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    position: 'absolute',
    bottom: 0,
    height: INITIAL_DATASET_EDITOR_HEIGHT,
  },
  datasetEditorDivider: {
    cursor: 'row-resize',
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0.25),
    opacity: 0,
  },
  datasetEditorHidden: {
    height: `0 !important`,
  },
  datasetEditorClosed: {
    height: `${MENU_HEIGHT}px !important`,
  },
}));

type Props = {
  dashboardElements?: DashboardElement[];
  editorDatasets: Record<string, Dataset>;
  dashboardTemplateId: number;
  inEditMode?: boolean;
  parentSchemas: ParentSchema[];
  schemaTablesMap: { [schemaId: string]: { [datasetId: string]: Dataset } };
  dataPanels: DataPanelTemplate[];
  selectedDashboardItem?: {
    elementType: string;
    elementId: string;
  };
  resizePreview: (newHeight: number) => void;
  pageWidth: number | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean, newHeight: number) => void;
  dashboardVars: DashboardVariableMap;
  selectedUserGroupId?: number;
};

function ResizeableDatasetEditor(props: Props) {
  const {
    editorDatasets,
    dashboardTemplateId,
    inEditMode,
    parentSchemas,
    schemaTablesMap,
    resizePreview,
    pageWidth,
    setIsOpen,
    isOpen,
    dashboardVars,
    selectedUserGroupId,
  } = props;
  const classes = useStyles();
  const [datasetEditorHeight, setDatasetEditorHeight] = useState<number>(
    INITIAL_DATASET_EDITOR_HEIGHT,
  );
  const [dragging, setDragging] = useState<boolean>(false);
  const [separatorYPosition, setSeparatorYPosition] = useState<number>();

  const resizableDatasetEditor = createRef<HTMLDivElement>();

  const onMouseMove = (e: React.MouseEvent) => {
    if (dragging && separatorYPosition && isOpen) {
      const newHeight = datasetEditorHeight - e.clientY + separatorYPosition;
      if (newHeight >= INITIAL_DATASET_EDITOR_HEIGHT / 2) {
        setSeparatorYPosition(e.clientY);
        setDatasetEditorHeight(newHeight);
      }
    }
  };

  const onMouseUp = () => {
    if (dragging) {
      setDragging(false);
      resizePreview(datasetEditorHeight);
    }
  };

  // @ts-ignore
  useEventListener('mousemove', onMouseMove);
  // @ts-ignore
  useEventListener('mouseup', onMouseUp);

  useEffect(() => {
    if (resizableDatasetEditor.current) {
      resizableDatasetEditor.current.style.height = `${datasetEditorHeight}px`;
    }
  }, [resizableDatasetEditor, datasetEditorHeight]);

  const getSourceDatasetIdOrDefault = () => {
    const { editorDatasets, dataPanels, selectedDashboardItem } = props;

    if (!selectedDashboardItem) return undefined;

    if (selectedDashboardItem.elementType === DASHBOARD_ELEMENT_TYPES.DATA_PANEL) {
      const panelsById = _.indexBy(dataPanels || [], 'id');
      const dataPanelTemplate = panelsById[selectedDashboardItem.elementId];
      return editorDatasets && editorDatasets[dataPanelTemplate.table_id]?.id;
    } else {
      return undefined;
    }
  };

  return (
    <div
      ref={resizableDatasetEditor}
      className={cx(classes.datasetEditorContainer, {
        [classes.datasetEditorHidden]: !inEditMode,
        [classes.datasetEditorClosed]: inEditMode && !isOpen,
      })}>
      {isOpen && (
        <div
          className={classes.datasetEditorDivider}
          onMouseDown={(e: React.MouseEvent) => {
            setSeparatorYPosition(e.clientY);
            setDragging(true);
          }}
          onMouseUp={() => {
            resizePreview(datasetEditorHeight);
            setDragging(false);
          }}
        />
      )}
      <DashboardDatasetEditor
        isOpen={isOpen}
        toggleEditorVisibility={() => {
          const newHeight = isOpen ? MENU_HEIGHT : datasetEditorHeight;
          setIsOpen(!isOpen, newHeight);
        }}
        datasets={editorDatasets}
        dashboardTemplateId={dashboardTemplateId}
        selectedDataPanelSourceDatasetId={getSourceDatasetIdOrDefault()}
        parentSchemas={parentSchemas}
        schemaTablesMap={schemaTablesMap}
        pageWidth={pageWidth}
        dashboardVars={dashboardVars}
        selectedUserGroupId={selectedUserGroupId}
      />
    </div>
  );
}

export default ResizeableDatasetEditor;
