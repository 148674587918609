/** @format */

import React, { useMemo } from 'react';
import cx from 'classnames';
import produce from 'immer';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import _ from 'underscore';

import BaseDataTable from 'components/dataTable/baseDataTable';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import { HEADER_HEIGHT } from 'pages/dashboardPage/DashboardDatasetView/DashboardDatasetView';
import DataTableFooter from './DataTableFooter';

import { AdHocOperationInstructions, Dataset, TableRow, VisualizeOperation } from 'actions/types';
import { MAX_ROWS_TO_PREVIEW } from 'constants/dataConstants';
import {
  Schema,
  SortOrder,
  VisualizeTableInstructions,
  REPORTED_ANALYTIC_ACTION_TYPES,
  UserTransformedSchema,
} from 'constants/types';
import { DownloadChartInfo } from 'components/DashboardLayout/DashboardLayout';
import {
  getMetricsByColumn,
  getChangedSchema,
  removeUserDisabledColumns,
} from 'utils/dashboardUtils';

const FOOTER_HEIGHT = 34;

const useStyles = makeStyles((theme: Theme) => ({
  dataTable: {
    height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px) !important`,

    '&.noHeader': {
      height: `calc(100% - ${FOOTER_HEIGHT}px) !important`,
    },
    '&.noFooter': {
      height: `calc(100% - ${HEADER_HEIGHT}px) !important`,
    },
    '&.noHeader.noFooter': {
      height: `calc(100%) !important`,
    },
  },
  loadingDataTable: {
    height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px) !important`,
    borderBottom: `1px solid ${theme.palette.ds.grey500}`,
    '&.noHeader': {
      height: `calc(100% - ${FOOTER_HEIGHT}px) !important`,
    },
    '&.noFooter': {
      height: `calc(100% - ${HEADER_HEIGHT}px) !important`,
    },
    '&.noHeader.noFooter': {
      height: `calc(100%) !important`,
    },
  },
}));

type Props = {
  analyticsEventTracker?: (
    eventName: REPORTED_ANALYTIC_ACTION_TYPES,
    metaData?: Record<string, string | number>,
  ) => void;
  adHocOperationInstructions: AdHocOperationInstructions;
  canDownloadDataPanel: boolean;
  downloadChartInfo?: DownloadChartInfo;
  onDownloadPanelPdf?: (
    adHocOperationInstructions: AdHocOperationInstructions,
    userTransformedSchema?: UserTransformedSchema,
  ) => void;
  onDownloadPanelCsv?: (userTransformedSchema?: UserTransformedSchema) => void;
  enableColumnResizing?: boolean;
  error?: boolean;
  isViewOnly: boolean;
  loading?: boolean;
  onAdHocOperationInstructionsChange: (
    adHocOperationInstructions: AdHocOperationInstructions,
  ) => void;
  previewData: Record<string, string | number>[];
  rowError?: boolean;
  schema: Schema;
  secondaryData: TableRow[];
  sourceDataRowCount?: number;
  updateVisualizeOperation: (visualizeInstructions: VisualizeTableInstructions) => void;
  userTransformedSchema?: UserTransformedSchema;
  visualizeOperation: VisualizeOperation;
  dashboardDatasets?: Record<string, Dataset>;
};

const getOnColumnSelect = ({
  adHocOperationInstructions,
  onAdHocOperationInstructionsChange,
  analyticsEventTracker,
}: Pick<
  Props,
  'adHocOperationInstructions' | 'onAdHocOperationInstructionsChange' | 'analyticsEventTracker'
>) => (colIndex: number, headerList: Schema) => {
  const colName = headerList[colIndex].name;
  const oldSortInfo = adHocOperationInstructions.sortInfo;

  let sortInfo = {
    column_name: colName,
    order: SortOrder.ASC,
  };

  if (oldSortInfo && oldSortInfo.column_name === colName) {
    sortInfo = {
      column_name: oldSortInfo.column_name,
      order: oldSortInfo.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
    };
  }

  const newAdHocOperationInstructions = produce(adHocOperationInstructions, (draft) => {
    draft.sortInfo = sortInfo;
  });

  onAdHocOperationInstructionsChange(newAdHocOperationInstructions);

  analyticsEventTracker &&
    analyticsEventTracker(REPORTED_ANALYTIC_ACTION_TYPES.TABLE_SORTED, {
      column_name: sortInfo.column_name,
      order: sortInfo.order.toString(),
    });
};

const getOnNewPage = ({
  adHocOperationInstructions,
  onAdHocOperationInstructionsChange,
  analyticsEventTracker,
  sourceDataRowCount,
}: Pick<
  Props,
  | 'adHocOperationInstructions'
  | 'onAdHocOperationInstructionsChange'
  | 'analyticsEventTracker'
  | 'sourceDataRowCount'
>) => (newPage: string) => {
  if (!sourceDataRowCount) return;

  const newPageNumber = Number.parseInt(newPage);
  const maxPageNumber = Math.ceil(sourceDataRowCount / 50);

  if (
    !newPageNumber ||
    newPageNumber < 1 ||
    newPageNumber > maxPageNumber ||
    adHocOperationInstructions.currentPage === newPageNumber
  ) {
    return;
  }

  const newAdHocOperationInstructions = produce(adHocOperationInstructions, (draft) => {
    draft.currentPage = newPageNumber;
  });

  onAdHocOperationInstructionsChange(newAdHocOperationInstructions);

  analyticsEventTracker &&
    analyticsEventTracker(REPORTED_ANALYTIC_ACTION_TYPES.TABLED_PAGED, {
      new_page: newPageNumber,
    });
};

const onTableColumnWidthsEdited = ({
  updateVisualizeOperation,
  enableColumnResizing,
  visualizeOperation,
  schema,
  isViewOnly,
}: Pick<
  Props,
  | 'updateVisualizeOperation'
  | 'enableColumnResizing'
  | 'visualizeOperation'
  | 'schema'
  | 'isViewOnly'
>) => (index: number, size: number) => {
  if (!enableColumnResizing || isViewOnly) return;
  const numHeaders = getChangedSchema(schema, visualizeOperation.instructions.VISUALIZE_TABLE)
    .length;

  const existingWidths =
    visualizeOperation.instructions.VISUALIZE_TABLE.columnWidths ?? _.times(numHeaders, () => null);

  const newWidths = produce(existingWidths, (draft) => {
    draft[index] = size;
  });

  const newOperation = produce(visualizeOperation.instructions.VISUALIZE_TABLE, (draft) => {
    draft.columnWidths = newWidths;
  });

  updateVisualizeOperation(newOperation);
};

export default function DataTable({
  adHocOperationInstructions,
  analyticsEventTracker,
  canDownloadDataPanel,
  downloadChartInfo,
  onDownloadPanelCsv,
  enableColumnResizing,
  error,
  isViewOnly,
  loading,
  onAdHocOperationInstructionsChange,
  onDownloadPanelPdf,
  previewData,
  rowError,
  schema,
  secondaryData,
  sourceDataRowCount,
  updateVisualizeOperation,
  userTransformedSchema,
  visualizeOperation,
  dashboardDatasets,
}: Props) {
  const classes = useStyles();
  const metricsByColumn = useMemo(() => getMetricsByColumn(secondaryData || []), [secondaryData]);

  const tableLoading = loading || !previewData;

  const isSchemaCustomizationEnabled =
    visualizeOperation.instructions.VISUALIZE_TABLE.isSchemaCustomizationEnabled;

  const footer = (
    <DataTableFooter
      loading={tableLoading}
      rowError={rowError}
      sourceDataRowCount={sourceDataRowCount}
      adHocOperationInstructions={adHocOperationInstructions}
      canDownloadDataPanel={canDownloadDataPanel}
      onNewPage={getOnNewPage({
        adHocOperationInstructions,
        onAdHocOperationInstructionsChange,
        analyticsEventTracker,
        sourceDataRowCount,
      })}
      downloadChartInfo={downloadChartInfo}
      disableDownload={loading || error}
      onDownloadPanelCsv={() =>
        isSchemaCustomizationEnabled
          ? onDownloadPanelCsv?.(userTransformedSchema)
          : onDownloadPanelCsv?.()
      }
      onDownloadPanelPdf={() =>
        isSchemaCustomizationEnabled
          ? onDownloadPanelPdf?.(adHocOperationInstructions, userTransformedSchema)
          : onDownloadPanelPdf?.(adHocOperationInstructions)
      }
      hideDownloadButton={visualizeOperation.instructions.VISUALIZE_TABLE.isDownloadButtonHidden}
    />
  );

  if (tableLoading) {
    return (
      <>
        <LoadingDataTable
          disableRowHeader
          className={cx(classes.loadingDataTable, {
            noHeader: visualizeOperation.instructions.VISUALIZE_TABLE.isHeaderHidden,
            noFooter: visualizeOperation.instructions.VISUALIZE_TABLE.isFooterHidden,
          })}
          maxRows={50}
          rowHeight={visualizeOperation.instructions.VISUALIZE_TABLE.rowHeight}
        />
        {!visualizeOperation.instructions.VISUALIZE_TABLE.isFooterHidden && footer}
      </>
    );
  }

  const tableSchema =
    isSchemaCustomizationEnabled && userTransformedSchema
      ? removeUserDisabledColumns(userTransformedSchema)
      : schema;

  return (
    <>
      <BaseDataTable
        noBorderRadius
        fill
        truncateEmptyRowSpace
        useFriendlyNameForHeader
        unrestrictedHeight
        disableRowHeader
        className={cx(classes.dataTable, {
          noHeader: visualizeOperation.instructions.VISUALIZE_TABLE.isHeaderHidden,
          noFooter: visualizeOperation.instructions.VISUALIZE_TABLE.isFooterHidden,
        })}
        loading={tableLoading}
        isSortable={!visualizeOperation.instructions.VISUALIZE_TABLE.isColumnSortingDisabled}
        rows={previewData || []}
        schema={tableSchema}
        maxRows={MAX_ROWS_TO_PREVIEW}
        rowHeight={visualizeOperation.instructions.VISUALIZE_TABLE.rowHeight}
        rowLinesDisabled={visualizeOperation.instructions.VISUALIZE_TABLE.isRowLinesDisabled}
        columnLinesEnabled={visualizeOperation.instructions.VISUALIZE_TABLE.isColumnLinesEnabled}
        isColumnHeadersBolded={
          visualizeOperation.instructions.VISUALIZE_TABLE.isColumnHeadersBolded
        }
        isFirstColumnBolded={visualizeOperation.instructions.VISUALIZE_TABLE.isFirstColumnBolded}
        onColumnSelect={getOnColumnSelect({
          adHocOperationInstructions,
          onAdHocOperationInstructionsChange,
          analyticsEventTracker,
        })}
        sortInfo={adHocOperationInstructions.sortInfo}
        metricsByColumn={metricsByColumn}
        shouldTruncateText={visualizeOperation.instructions.VISUALIZE_TABLE.shouldTruncateText}
        enableColumnResizing={enableColumnResizing}
        columnWidths={visualizeOperation.instructions.VISUALIZE_TABLE.columnWidths}
        onColumnWidthChanged={onTableColumnWidthsEdited({
          updateVisualizeOperation,
          enableColumnResizing,
          visualizeOperation,
          schema,
          isViewOnly,
        })}
        schemaDisplayOptions={visualizeOperation.instructions.VISUALIZE_TABLE.schemaDisplayOptions}
        dashboardDatasets={dashboardDatasets}
      />
      {footer}
    </>
  );
}
