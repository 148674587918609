/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme, WithStyles } from '@material-ui/core/index';
import { withStyles, createStyles } from '@material-ui/styles';
import { Icon } from '@blueprintjs/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    imgSection: {
      flex: `0 0 60px`,
      width: 75,
      height: 75,
      borderRadius: 4,
      backgroundColor: theme.palette.ds.grey100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
      overflow: 'hidden',

      transition: 'transform .25s ease-out, box-shadow .25s ease-out',

      '&:hover': {
        cursor: 'pointer',
        transform: 'translatez(0) scale(1.05)',
        boxShadow: '0px 0px 10px -6px rgba(0,0,0,0.75)',

        '& .infoTitle': {
          color: theme.palette.ds.blue,
        },
      },
    },
    elemName: {
      color: theme.palette.ds.grey900,
      fontSize: 12,
    },
  });

type PassedProps = {
  icon: any;
  name: string;
  className?: string;
  onDragStart: () => void;
  onDragEnd: () => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class DroppableComponentItem extends React.Component<Props> {
  render() {
    const { classes, className, name } = this.props;

    return (
      <div className={cx(classes.root, className)}>
        <div
          className="droppable-element"
          draggable={true}
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}>
          {this.renderImgSection()}
        </div>
        <div className={classes.elemName}>{name}</div>
      </div>
    );
  }

  onDragStart = (e: any) => {
    const { onDragStart } = this.props;

    // TODO: Figure out if a shadow dragging element could work
    // https://www.kryogenix.org/code/browser/custom-drag-image.html

    e.dataTransfer.setData('text/plain', '');

    onDragStart();
  };

  onDragEnd = () => {
    const { onDragEnd } = this.props;

    onDragEnd();
  };

  renderImgSection = () => {
    const { classes, icon } = this.props;

    return (
      <div className={cx(classes.imgSection, 'imgSection')}>
        <Icon icon={icon} />
      </div>
    );
  };
}

export default withStyles(styles)(DroppableComponentItem);
