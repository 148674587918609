/** @format */

import React from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import produce from 'immer';

import DropdownSelect from 'shared/DropdownSelect';
import ToggleSwitchInput from 'shared/ToggleSwitchInput';

import { Dataset, TableColumn } from 'actions/types';
import { VisualizeTableInstructions } from 'constants/types';
import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  configInput: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  column: TableColumn;
  instructions: VisualizeTableInstructions;
  updateInstructions: (newInstructions: VisualizeTableInstructions) => void;
  dashboardDatasets: Record<string, Dataset>;
};

export default function EnrichColumnConfiguration({
  column,
  instructions,
  updateInstructions,
  dashboardDatasets,
}: Props) {
  const classes = useStyles();
  const config = instructions.schemaDisplayOptions?.[column.name];

  const savedDatasets = Object.values(dashboardDatasets).filter((dataset) => !!dataset.schema);
  const selectedDataset = config?.joinTable && dashboardDatasets[config.joinTable.id];

  const schemaByName =
    selectedDataset && selectedDataset.schema ? _.indexBy(selectedDataset.schema, 'name') : {};
  const selectedJoinColumn = config?.joinColumn && schemaByName[config.joinColumn.name];
  const selectedDisplayColumn =
    config?.joinDisplayColumn && schemaByName[config.joinDisplayColumn.name];

  return (
    <div className={classes.root}>
      <ToggleSwitchInput
        className={classes.configInput}
        switchOn={config?.joinOn}
        onChange={() => {
          const newInstructions = produce(instructions, (draft) => {
            if (!draft.schemaDisplayOptions) return draft;
            draft.schemaDisplayOptions[column.name] = {
              ...draft.schemaDisplayOptions[column.name],
              joinOn: !config?.joinOn,
            };
          });

          updateInstructions(newInstructions);
        }}
        label="Join"
      />

      {config?.joinOn && (
        <>
          <DropdownSelect
            containerClassName={classes.configInput}
            selectedItem={
              selectedDataset && {
                id: selectedDataset.id,
                name: selectedDataset.table_name || 'Untitled',
              }
            }
            onChange={(item) => {
              const newInstructions = produce(instructions, (draft) => {
                if (!draft.schemaDisplayOptions) return draft;
                draft.schemaDisplayOptions[column.name] = {
                  ...draft.schemaDisplayOptions[column.name],
                  joinTable: { id: item.id, name: item.name },
                };
              });

              updateInstructions(newInstructions);
            }}
            filterable={false}
            options={savedDatasets.map((dataset: Dataset) => ({
              id: String(dataset.id),
              name: dataset.table_name || 'Untitled',
            }))}
            noSelectionText="Select dataset"
            minimal
            fillWidth
            showCancelBtn
            label="Dataset"
            onCancelClick={() => {
              const newInstructions = produce(instructions, (draft) => {
                if (!draft.schemaDisplayOptions) return draft;
                draft.schemaDisplayOptions[column.name] = {
                  ...draft.schemaDisplayOptions[column.name],
                  joinTable: undefined,
                };
              });

              updateInstructions(newInstructions);
            }}
          />
          <DropdownSelect
            containerClassName={classes.configInput}
            selectedItem={
              selectedJoinColumn && {
                id: selectedJoinColumn.name,
                name: selectedJoinColumn.name,
                icon: SCHEMA_DATA_TYPES_BY_ID[selectedJoinColumn.type].icon,
              }
            }
            onChange={(item) => {
              const newInstructions = produce(instructions, (draft) => {
                if (!draft.schemaDisplayOptions) return draft;
                draft.schemaDisplayOptions[column.name] = {
                  ...draft.schemaDisplayOptions[column.name],
                  joinColumn: { id: item.id, name: item.name, column: schemaByName[item.name] },
                };
              });

              updateInstructions(newInstructions);
            }}
            showIcon
            filterable={false}
            disabled={selectedDataset === undefined}
            options={
              selectedDataset && selectedDataset.schema
                ? selectedDataset.schema.map((col) => ({
                    name: col.name,
                    id: col.name,
                    icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
                  }))
                : []
            }
            noSelectionText="Select a column"
            minimal
            fillWidth
            showCancelBtn
            label="Join Column"
            onCancelClick={() => {
              const newInstructions = produce(instructions, (draft) => {
                if (!draft.schemaDisplayOptions) return draft;
                draft.schemaDisplayOptions[column.name] = {
                  ...draft.schemaDisplayOptions[column.name],
                  joinColumn: undefined,
                };
              });

              updateInstructions(newInstructions);
            }}
          />
          <DropdownSelect
            containerClassName={classes.configInput}
            selectedItem={
              selectedDisplayColumn && {
                id: selectedDisplayColumn.name,
                name: selectedDisplayColumn.name,
                icon: SCHEMA_DATA_TYPES_BY_ID[selectedDisplayColumn.type].icon,
              }
            }
            onChange={(item) => {
              const newInstructions = produce(instructions, (draft) => {
                if (!draft.schemaDisplayOptions) return draft;
                draft.schemaDisplayOptions[column.name] = {
                  ...draft.schemaDisplayOptions[column.name],
                  joinDisplayColumn: {
                    id: item.id,
                    name: item.name,
                    column: schemaByName[item.name],
                  },
                };
              });

              updateInstructions(newInstructions);
            }}
            showIcon
            filterable={false}
            disabled={selectedDataset === undefined || selectedJoinColumn === undefined}
            options={
              selectedDataset && selectedDataset.schema
                ? selectedDataset.schema.map((col) => ({
                    name: col.name,
                    id: col.name,
                    icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
                  }))
                : []
            }
            noSelectionText="Select a column"
            minimal
            fillWidth
            showCancelBtn
            label="Display Column"
            onCancelClick={() => {
              const newInstructions = produce(instructions, (draft) => {
                if (!draft.schemaDisplayOptions) return draft;
                draft.schemaDisplayOptions[column.name] = {
                  ...draft.schemaDisplayOptions[column.name],
                  joinDisplayColumn: undefined,
                };
              });

              updateInstructions(newInstructions);
            }}
          />
        </>
      )}
    </div>
  );
}
