/** @format */
import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import cx from 'classnames';

import FlexBox, { VerticalAlignment, HorizontalAlignment } from './FlexBox';

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: Props) => ({
    borderRadius: 2,
    fontSize: 14,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    backgroundColor: props.backgroundColor || theme.palette.ds.grey800,
    color: props.color || theme.palette.ds.white,
  }),
}));

export type Props = {
  className?: string;
  name: string;
  backgroundColor?: string;
  color?: string;
};

const Tag: FunctionComponent<Props> = (props) => {
  const { className, name } = props;
  const classes = useStyles(props);

  return (
    <FlexBox
      horizontalAlignment={HorizontalAlignment.CENTER}
      verticalAlignment={VerticalAlignment.CENTER}
      className={cx(classes.root, className)}>
      {name}
    </FlexBox>
  );
};

export default Tag;
