/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Menu, MenuDivider, Intent, NonIdealState, Icon, Divider } from '@blueprintjs/core';
import { Spinner } from '@blueprintjs/core';

import BaseDataTable from 'components/dataTable/baseDataTable';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import TablePageNavigation from 'components/dataTable/tablePageNavigation';
import PageNumberIndicator from 'components/dataTable/pageNumberIndicator';
import DatasetMenuItem from 'pages/manageDataTablesPage/datasetMenuItem';
import DropdownSelect from 'shared/DropdownSelect';
import ErrorState from 'components/ErrorState';
import { createLoadingSelector } from 'reducers/api/selectors';

import { fetchDatasets } from 'actions/dataSourceActions';
import { fetchDatasetPreview, fetchDatasetRowCount } from 'actions/datasetActions';
import { fetchParentSchemas } from 'actions/parentSchemaActions';
import { listTeamDataSources } from 'actions/dataSourceActions';
import { NAVBAR_HEIGHT } from 'components/pages/navbar';
import { Dataset, ACTION } from 'actions/types';

import { pageView, trackEvent } from 'analytics/exploAnalytics';
import { AppToaster } from 'toaster';
import { parseErrorMessage } from 'utils/queryUtils';
import Button from 'shared/Button';
import { ROUTES } from 'constants/routes';

const SIDEBAR_WIDTH = 250;
const DATASET_PREVIEW_CONTROLS_BAR_HEIGHT = 40;

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
  },
  sidebar: {
    height: '100%',
    width: SIDEBAR_WIDTH,
    backgroundColor: theme.palette.ds.white,
    overflowY: 'auto' as 'auto',
    borderRight: `1px solid ${theme.palette.ds.grey500}`,
  },
  menuDivider: {
    fontWeight: 'bold' as 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 5,
  },
  datasetViewer: {
    height: '100%',
    width: `calc(100vw - ${SIDEBAR_WIDTH}px)`,
  },
  datasetError: {
    backgroundColor: theme.palette.ds.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  datasetNavItem: {},
  sourceDataTable: {
    height: 'calc(100% - 40px)',
  },
  previewDatasetControls: {
    height: DATASET_PREVIEW_CONTROLS_BAR_HEIGHT,
    borderTop: `1px solid ${theme.palette.ds.grey500}`,
    backgroundColor: theme.palette.ds.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pageNumberIndicator: {
    marginRight: theme.spacing(3),
  },
  menuItemTextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dataSetSpinner: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    margin: '5px 0px',
    color: theme.palette.ds.grey300,
  },
  schemaDropdown: {
    padding: '0px 7px',
  },
  schemaTitle: {
    padding: '7px 0px',
  },
  errorCallout: {
    height: '100%',
    overflow: 'auto',

    '& .bp3-non-ideal-state-visual': {
      height: 50,
    },
    '& .bp3-icon-error': {
      paddingBottom: theme.spacing(3),
    },
  },
  unsyncedTables: {
    fontWeight: 500,
    fontSize: `12px !important`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 100px',
    textAlign: 'center' as 'center',
    height: '100%',
    width: `calc(100vw - ${SIDEBAR_WIDTH}px)`,
  },
  syncTablesButton: {
    padding: `8px`,
    fontWeight: 500,
  },
});

type MatchParams = {
  dataSourceId: string;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  selectedDatasetId?: string;
  datasetQuery?: string;
  currentPreviewPage: number;
  pageNavVal: number;
  pageNavValid: boolean;
  selectedSchemaId?: number;
  dataSourceId: string;
};

class ManageDataTablesPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const dataSourceId = props.match.params.dataSourceId;

    this.state = {
      currentPreviewPage: 1,
      pageNavVal: 1,
      pageNavValid: false,
      dataSourceId: dataSourceId,
    };
    document.title = 'Explo | Manage Data';

    this.props.listTeamDataSources({}, (results) => {
      const selectedDataSource = _.find(
        results.dataSources,
        (dataSource) => dataSource.id === parseInt(this.state.dataSourceId),
      );

      this.setState({
        selectedSchemaId: selectedDataSource?.parent_schema_id,
      });

      this.props.fetchDatasets({ id: selectedDataSource?.parent_schema_id }, () => {
        this.fetchDatasetRows(this.state.dataSourceId);
      });
    });
    this.props.fetchParentSchemas();
  }

  componentDidMount() {
    pageView('Manage Data Tables');
  }

  render() {
    const { classes, datasetLoadingError, loading, datasets } = this.props;

    if (datasetLoadingError) {
      return <ErrorState text={datasetLoadingError.detail} />;
    }
    return (
      <div className={classes.root}>
        <div className={classes.sidebar}>{this.renderDatasetsSidebar()}</div>
        <div className={classes.datasetViewer}>
          {loading && this.renderDatasetLoadingTable()}
          {_.isEmpty(datasets) && !loading && this.renderSyncTablesMessage()}
          {!_.isEmpty(datasets) && this.renderDatasetViewer()}
          {!_.isEmpty(datasets) && this.renderDatasetPreviewControls()}
        </div>
      </div>
    );
  }

  fetchDatasetRows = (dataSourceId: string) => {
    const { fetchDatasetPreview, fetchDatasetRowCount, datasets } = this.props;
    const { selectedDatasetId } = this.state;

    const selectedDataset = selectedDatasetId
      ? _.find(datasets, (dataset) => dataset.id === selectedDatasetId)
      : _.first(_.sortBy(Object.values(datasets), (dataset) => dataset.table_name));

    if (selectedDataset) {
      this.setState({
        selectedDatasetId: selectedDataset.id,
        dataSourceId: dataSourceId,
      });

      fetchDatasetPreview({ id: dataSourceId, postData: { dataset: selectedDataset } }, () => {
        fetchDatasetRowCount({
          id: dataSourceId,
          postData: { dataset: selectedDataset, rowCount: true },
        });
      });
    }
  };

  renderDatasetsSidebar = () => {
    const { classes, loading, datasets } = this.props;
    const { selectedSchemaId, dataSourceId } = this.state;
    if (loading || !selectedSchemaId || !dataSourceId) {
      return (
        <div className={classes.dataSetSpinner}>
          <Spinner size={Spinner.SIZE_LARGE} />
        </div>
      );
    }

    if (datasets === {}) {
      AppToaster.show({
        message: (
          <div>
            This schema has no source tables.
            <br />
            <br />
            Consider sync source tables for updated schema at the following link:
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/sync-tables/${selectedSchemaId}`}>
                  {`https://app.explo.co/sync-tables/${selectedSchemaId}`}
                </a>
              </li>
            </ul>
          </div>
        ),
        icon: 'info-sign',
        timeout: 10000,
        intent: Intent.SUCCESS,
      });
    }

    return (
      <>
        {this.renderSchemaHeader()}
        <Divider className={classes.divider} />
        {this.renderDatasetList()}
      </>
    );
  };

  renderSchemaHeader = () => {
    const { classes, parentSchemas, dataSources, history } = this.props;
    const { selectedSchemaId, dataSourceId } = this.state;

    const schemaName = _.indexBy(parentSchemas || [], 'id')[selectedSchemaId ?? -1]?.name ?? '';
    const schemaDataSources = _.where(dataSources, { parent_schema_id: selectedSchemaId });
    const schemaDataSourcesById = _.indexBy(schemaDataSources, 'id');
    return (
      <Menu>
        <MenuDivider className={classes.schemaTitle} title={schemaName.toUpperCase()}></MenuDivider>
        <DropdownSelect
          minimal
          fillWidth
          showIcon
          selectedItem={{
            id: String(dataSourceId),
            name: schemaDataSourcesById[dataSourceId].name,
          }}
          onChange={(item) => {
            history.replace(`/datasources/${item.id}`);
            this.fetchDatasetRows(item.id);
          }}
          noSelectionText="Select DataSource"
          options={schemaDataSources.map((dataSource) => ({
            id: String(dataSource.id),
            name: dataSource.name,
          }))}
          containerClassName={classes.schemaDropdown}
        />
      </Menu>
    );
  };

  renderDatasetList = () => {
    const { datasets } = this.props;

    const sourceDatasetsOrdered = _.sortBy(
      Object.values(datasets),
      (dataset) => dataset.table_name,
    );
    return (
      <div>
        <Menu>
          <MenuDivider title="Source Datasets"></MenuDivider>
          {sourceDatasetsOrdered.map((dataset) => this.renderDatasetItem(dataset))}
        </Menu>
      </div>
    );
  };

  renderNewDatasetHeader = () => {
    const { classes } = this.props;

    return (
      <div className={classes.menuDivider}>
        <div>Dashboard Datasets</div>
      </div>
    );
  };

  renderDatasetItem = (dataset: Dataset) => {
    const { selectedDatasetId } = this.state;
    const tableName =
      !dataset.table_name || dataset.table_name.length === 0 ? 'Untitled' : dataset.table_name;
    return (
      <DatasetMenuItem
        key={`dataset-navbar-item-${dataset.id}`}
        onClick={() => {
          this.switchSelectedDataset(dataset);
          trackEvent('Selected dataset', {
            dataset_id: dataset.id,
            dataset_name: tableName,
          });
        }}
        name={tableName}
        active={selectedDatasetId === dataset.id}
        editable={false}
      />
    );
  };

  switchSelectedDataset = (newDataset: Dataset) => {
    const { fetchDatasetPreview, fetchDatasetRowCount } = this.props;
    const { dataSourceId } = this.state;

    fetchDatasetPreview(
      {
        id: dataSourceId,
        postData: { dataset: newDataset },
      },
      () => {
        fetchDatasetRowCount({
          id: dataSourceId,
          postData: { dataset: newDataset, rowCount: true },
        });
      },
    );

    const stateUpdate = {
      selectedDatasetId: newDataset.id,
      currentPreviewPage: 1,
      pageNavVal: 1,
      pageNavValid: false,
      datasetQuery: newDataset.query || '',
    };
    this.setState(stateUpdate);
  };

  renderDatasetViewer = () => {
    const { datasets } = this.props;
    const { selectedDatasetId } = this.state;

    if (!selectedDatasetId || !datasets[selectedDatasetId]) {
      return this.renderDatasetLoadingTable();
    }
    const selectedDataset = datasets[selectedDatasetId];

    if (selectedDataset._error) {
      return this.renderDatasetTableError(datasets[selectedDatasetId]);
    }

    if (!selectedDataset._rows) {
      return this.renderDatasetLoadingTable();
    }

    return this.renderDatasetPreview(selectedDataset);
  };

  renderDatasetLoadingTable = () => {
    const { classes } = this.props;
    return <LoadingDataTable className={classes.sourceDataTable} maxRows={50} />;
  };

  renderSyncTablesMessage = () => {
    const { classes } = this.props;
    return (
      <div className={classes.unsyncedTables}>
        <Link to={ROUTES.SYNC_DATA_TABLES_NO_SCHEMA + `/${this.state.selectedSchemaId}`}>
          <h2>No source datasets were found, you may need to first sync your tables.</h2>{' '}
        </Link>
      </div>
    );
  };

  renderDatasetTableError = (dataset: Dataset) => {
    const { classes } = this.props;
    return (
      <div className={cx(classes.sourceDataTable, classes.datasetError)}>
        <NonIdealState
          className={classes.errorCallout}
          icon={<Icon icon="error" intent={Intent.DANGER} iconSize={50} />}
          title="There was an error fetching the results"
          description={parseErrorMessage(dataset._error)}
          action={
            <Button
              minimal
              onClick={() => alert(dataset._error)}
              icon="document-open"
              text="View full error"
            />
          }></NonIdealState>
      </div>
    );
  };

  renderDatasetPreview = (dataset: Dataset) => {
    const { classes } = this.props;
    if (!dataset._rows) return;

    return (
      <BaseDataTable
        className={classes.sourceDataTable}
        schema={dataset.schema || []}
        rows={dataset._rows}
        maxRows={50}
        isSortable={false}
        enableColumnResizing
        shouldTruncateText
      />
    );
  };

  renderDatasetPreviewControls = () => {
    const { classes, datasets, fetchDatasetPreview } = this.props;
    const {
      selectedDatasetId,
      currentPreviewPage,
      pageNavVal,
      pageNavValid,
      dataSourceId,
    } = this.state;

    let totalRowCount;
    const selectedDataset = datasets[selectedDatasetId || ''];
    if (selectedDataset) {
      totalRowCount = selectedDataset._total_row_count;
    }

    return (
      <div className={classes.previewDatasetControls}>
        <TablePageNavigation
          loading={totalRowCount === undefined}
          currentPageNumber={currentPreviewPage}
          changePage={(pageNumber: number) => {
            fetchDatasetPreview({
              id: dataSourceId,
              postData: {
                dataset: selectedDataset,
                offset: (pageNumber - 1) * 50,
              },
            });
            this.setState({ currentPreviewPage: pageNumber, pageNavVal: pageNumber });
          }}
          pageNumberInputVal={pageNavVal}
          maxPageNumber={totalRowCount ? Math.ceil(totalRowCount / 50) : 1}
          pageNumberInputIsInvalid={pageNavValid}
          changePageNumberInputVal={(pageNumber: number) =>
            this.setState({ pageNavVal: pageNumber })
          }
          changePageNumberisInvalid={(val: boolean) => this.setState({ pageNavValid: val })}
        />
        <PageNumberIndicator
          className={classes.pageNumberIndicator}
          currentPage={currentPreviewPage}
          rowsPerPage={50}
          totalRows={totalRowCount || 0}
        />
      </div>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  // We need to default loading to true so that we don't encounter false -> true -> false
  // loading sequences that make UI elements that depend on loading state jumpy.
  loading: createLoadingSelector([ACTION.FETCH_DATASETS, ACTION.FETCH_PARENT_SCHEMAS], true)(state),
  datasets: state.datasets.datasets,
  fetchingPreview: state.datasets.fetchingPreview,
  parentSchemas: state.parentSchemas.allParentSchema,
  dataSources: state.dataSourceList.dataSources,
  datasetLoadingError: state.datasets.error,
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  fetchDatasets,
  fetchDatasetPreview,
  fetchDatasetRowCount,
  fetchParentSchemas,
  listTeamDataSources,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManageDataTablesPage)),
);
