/** @format */
import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import FlexBox, {
  FlexDirection,
  HorizontalAlignment,
  VerticalAlignment,
} from 'components/core/FlexBox';
import Button from 'shared/Button';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(6),
  },
  titleText: {
    fontWeight: 600,
  },
  subtitleText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
  },
}));

type Props = {
  onNext: () => void;
};

export default function StartStep({ onNext }: Props) {
  const classes = useStyles();

  return (
    <FlexBox
      className={cx(classes.root, GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor)}
      direction={FlexDirection.COLUMN}
      horizontalAlignment={HorizontalAlignment.CENTER}
      verticalAlignment={VerticalAlignment.CENTER}>
      <div className={cx(classes.titleText, GLOBAL_STYLE_CLASSNAMES.text.h2.base)}>
        Configure your report
      </div>
      <div className={cx(classes.subtitleText, GLOBAL_STYLE_CLASSNAMES.text.body.secondary)}>
        Name your report, select your data, and export to PDF or CSV.
      </div>
      <Button type="primary" onClick={onNext} rightIcon="arrow-right" text="Get Started" />
    </FlexBox>
  );
}
