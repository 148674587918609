/** @format */

import React, { useState } from 'react';
import produce from 'immer';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import { GlobalStyleConfig } from 'globalStyles/types';
import { TEXT_SIZE_OFFSET_MAP } from 'globalStyles';
import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ColorPicker from './CustomStylesColorPicker';
import FlexBox, { VerticalAlignment } from 'components/core/FlexBox';
import FlexItem from 'components/core/FlexItem';
import DropdownSelect from 'shared/DropdownSelect';
import CollapsibleMenu from 'components/CollapsibleMenu';
import LineSelect from 'shared/LineSelect';
import CustomizedTag from './CustomizedTag';
import { capitalize } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: 55,
  },
  spacingInput: {
    marginRight: theme.spacing(3),
  },
  spacingHeading: {
    marginTop: theme.spacing(6),
  },
  backgroundColorSelection: {
    marginRight: theme.spacing(3),
  },
  actionsColorSelection: {
    marginLeft: theme.spacing(3),
  },
  inputLabel: {
    fontSize: 12,
    marginBottom: theme.spacing(1),
  },
  secondaryFontRow: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
  },
  collapsibleMenu: {
    marginTop: theme.spacing(4),
  },
  collapsibleMenuColorPicker: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  dropdownInput: {
    minWidth: 0,
  },
}));

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
  googleFonts?: string[];
};

export default function TextConfigSection(props: Props) {
  return (
    <>
      <ConfigSectionHeader title="Text" />
      <FontRow type="primary" {...props} />
      <FontRow type="secondary" {...props} />
      <ConfigSectionHeader isSubTitle title="Text Size" />
      <TextSizeInputs {...props} />
      <TextOverrideCollapsibleMenu {...props} />
    </>
  );
}

function FontRow({
  styleConfig,
  updateConfig,
  googleFonts,
  type,
}: Props & {
  type: 'primary' | 'secondary';
}) {
  const classes = useStyles();
  const fontType = type === 'primary' ? 'primaryFont' : 'secondaryFont';
  const fontColor = type === 'primary' ? 'primaryColor' : 'secondaryColor';
  const font = styleConfig.text[fontType];

  return (
    <FlexBox className={cx({ [classes.secondaryFontRow]: type === 'secondary' })}>
      <FlexItem className={classes.dropdownInput}>
        <div className={classes.backgroundColorSelection}>
          <div className={classes.inputLabel}>{`${capitalize(type)} Font`}</div>
          <DropdownSelect
            fillWidth
            showCancelBtn
            selectedItem={
              font
                ? {
                    id: font,
                    name: font,
                  }
                : undefined
            }
            onChange={(font) => {
              const newConfig = produce(styleConfig, (draft) => {
                draft.text[fontType] = font.id;
              });
              updateConfig(newConfig);
            }}
            onCancelClick={() => {
              const newConfig = produce(styleConfig, (draft) => {
                draft.text[fontType] = undefined;
              });
              updateConfig(newConfig);
            }}
            options={googleFonts?.map((googleFont) => ({ id: googleFont, name: googleFont })) ?? []}
            noSelectionText="Inherit"
          />
        </div>
      </FlexItem>
      <FlexItem>
        <div className={classes.actionsColorSelection}>
          <div className={classes.inputLabel}>Fill</div>
          <ColorPicker
            fill
            color={styleConfig.text[fontColor]}
            onClose={(newColor) => {
              const newConfig = produce(styleConfig, (draft) => {
                draft.text[fontColor] = newColor;
              });
              updateConfig(newConfig);
            }}
          />
        </div>
      </FlexItem>
    </FlexBox>
  );
}

function TextSizeInputs({ styleConfig, updateConfig }: Props) {
  const classes = useStyles();

  return (
    <FlexBox verticalAlignment={VerticalAlignment.BOTTOM}>
      <InputWithBlurSave
        hideRightIconInteractions
        onNewValueSubmitted={(newTextSizeString) => {
          const newTextSize = Number(newTextSizeString.replace('px', ''));

          if (isNaN(newTextSize) || newTextSize < 1) return;

          const newConfig = produce(styleConfig, (draft) => {
            draft.text.textSize = newTextSize;
          });
          updateConfig(newConfig);
        }}
        initialValue={`${styleConfig.text.textSize}px`}
        containerClassName={cx(classes.input, classes.spacingInput)}
      />
      <FlexItem>
        <LineSelect
          fill
          labels={['Small', 'Moderate', 'Large']}
          breakpoints={[10, 12, 14, 16, 18]}
          value={styleConfig.text.textSize}
          setValue={(newTextSize) => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.text.textSize = newTextSize;
            });
            updateConfig(newConfig);
          }}
        />
      </FlexItem>
    </FlexBox>
  );
}

function TextOverrideCollapsibleMenu(props: Props) {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <CollapsibleMenu
      className={classes.collapsibleMenu}
      title="Custom text"
      isExpanded={menuOpen}
      setIsExpanded={setMenuOpen}>
      <CollapsibleMenuRow isFirst text="Header 1" override="h1" {...props} />
      <CollapsibleMenuRow text="Header 2" override="h2" {...props} />
      <CollapsibleMenuRow text="Header 3" override="h3" {...props} />
      <CollapsibleMenuRow text="Body" override="body" {...props} />
      <CollapsibleMenuRow text="Small Heading" override="smallHeading" {...props} />
      <CollapsibleMenuRow text="Small Body" override="smallBody" {...props} />
    </CollapsibleMenu>
  );
}

type CollapsibleMenuRowProps = {
  googleFonts?: string[];
  override: keyof GlobalStyleConfig['text']['overrides'];
  text: string;
  isFirst?: boolean;
};

function CollapsibleMenuRow({
  googleFonts,
  override,
  text,
  isFirst,
  styleConfig,
  updateConfig,
}: CollapsibleMenuRowProps & Props) {
  const classes = useStyles();
  const font = styleConfig.text.overrides[override]?.font;
  const size =
    styleConfig.text.overrides[override]?.size ||
    styleConfig.text.textSize + TEXT_SIZE_OFFSET_MAP[override];

  return (
    <>
      <FlexBox
        className={cx(classes.inputLabel, { [classes.collapsibleMenu]: !isFirst })}
        verticalAlignment={VerticalAlignment.CENTER}>
        <div>{text}</div>
        <CustomizedTag
          hidden={!styleConfig.text.overrides[override]}
          onCancel={() => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.text.overrides[override] = undefined;
            });
            updateConfig(newConfig);
          }}
        />
      </FlexBox>
      <FlexBox verticalAlignment={VerticalAlignment.BOTTOM}>
        <FlexItem className={classes.dropdownInput}>
          <DropdownSelect
            fillWidth
            showCancelBtn
            selectedItem={
              font
                ? {
                    id: font,
                    name: font,
                  }
                : undefined
            }
            onChange={(font) => {
              const newConfig = produce(styleConfig, (draft) => {
                draft.text.overrides[override] = {
                  ...styleConfig.text.overrides[override],
                  font: font.id,
                };
              });
              updateConfig(newConfig);
            }}
            onCancelClick={() => {
              const newConfig = produce(styleConfig, (draft) => {
                const overrideProperties = draft.text.overrides[override];
                if (overrideProperties) {
                  overrideProperties.font = undefined;
                }
              });
              updateConfig(newConfig);
            }}
            options={googleFonts?.map((font) => ({ id: font, name: font })) ?? []}
            noSelectionText="Inherit"
          />
        </FlexItem>
        <FlexItem>
          <div className={classes.collapsibleMenuColorPicker}>
            <ColorPicker
              fill
              color={styleConfig.text.overrides[override]?.color || styleConfig.text.primaryColor}
              onClose={(newColor) => {
                const newConfig = produce(styleConfig, (draft) => {
                  draft.text.overrides[override] = {
                    ...styleConfig.text.overrides[override],
                    color: newColor,
                  };
                });
                updateConfig(newConfig);
              }}
            />
          </div>
        </FlexItem>
        <InputWithBlurSave
          hideRightIconInteractions
          onNewValueSubmitted={(newTextSizeString) => {
            const newTextSize = Number(newTextSizeString.replace('px', ''));

            if (isNaN(newTextSize) || newTextSize < 1) return;

            const newConfig = produce(styleConfig, (draft) => {
              draft.text.overrides[override] = {
                ...styleConfig.text.overrides[override],
                size: newTextSize,
              };
            });
            updateConfig(newConfig);
          }}
          initialValue={`${size}px`}
          containerClassName={cx(classes.input, classes.spacingInput)}
        />
      </FlexBox>
    </>
  );
}
