/** @format */

import React from 'react';
import _ from 'underscore';
import embed from 'vega-embed';
import ResizeObserver from 'react-resize-observer';
import { withStyles, createStyles } from '@material-ui/styles';
import { WithStyles } from '@material-ui/core/index';

import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';

const styles = () =>
  createStyles({
    needsConfigContainer: {
      height: '100%',
    },
  });

type PassedProps = {
  chartId: string;
  // eslint-disable-next-line
  chartConfig: any;
  loading?: boolean;
  configReadyToDisplay?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {};

class VegaChart extends React.PureComponent<Props, State> {
  componentDidMount() {
    const { chartId, chartConfig, loading, configReadyToDisplay } = this.props;

    if (loading || !configReadyToDisplay) return;
    embed(`#${chartId}`, chartConfig, {
      actions: false,
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { chartId, loading, chartConfig, configReadyToDisplay } = this.props;
    if (loading || !configReadyToDisplay) return;

    if (!_.isEqual(prevProps.chartConfig, chartConfig)) {
      embed(`#${chartId}`, chartConfig, {
        actions: false,
      });
    }
  }

  render() {
    const { classes, chartId, loading, configReadyToDisplay } = this.props;
    return (
      <>
        <div
          id={chartId}
          style={{
            width: '100%',
            height: '100%',
            display: loading || !configReadyToDisplay ? 'none' : undefined,
          }}
        />
        {!configReadyToDisplay && (
          <NeedsConfigurationPanel className={classes.needsConfigContainer} loading={loading} />
        )}
        <ResizeObserver onResize={() => window.dispatchEvent(new Event('resize'))}></ResizeObserver>
      </>
    );
  }
}

export default withStyles(styles)(VegaChart);
