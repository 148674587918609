/** @format */

import { Action } from 'reducers/rootReducer';
import { ACTION, ChangelogEntry } from 'actions/types';

export interface DataSourcesReducerState {
  entries: ChangelogEntry[];
}

const dataSourceReducerInitialState: DataSourcesReducerState = {
  entries: [],
};

export default (
  state: DataSourcesReducerState = dataSourceReducerInitialState,
  action: Action,
): DataSourcesReducerState => {
  const newState = Object.assign({}, state);
  const { payload } = action;

  switch (action.type) {
    case `${ACTION.FETCH_CHANGELOG_ENTRIES}_REQUEST`:
      newState.entries = [];
      return newState;
    case `${ACTION.FETCH_CHANGELOG_ENTRIES}_SUCCESS`:
      newState.entries = payload.entries;
      return newState;
    case `${ACTION.FETCH_CHANGELOG_ENTRY}_REQUEST`:
      newState.entries = [];
      return newState;
    case `${ACTION.FETCH_CHANGELOG_ENTRY}_SUCCESS`:
      newState.entries = [payload.entry];
      return newState;
    default:
      return state;
  }
};
