/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import SwitchInput from 'pages/dataPanelEditorPage/switchInput';
import DropdownSelect from 'shared/DropdownSelect';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  OPERATION_TYPES,
  V2BoxPlotInstructions,
  V2TwoDimensionChartInstructions,
  YAxisFormat,
} from 'constants/types';
import { SelectedDropdownInputItem } from 'constants/types';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  getYAxisFormat,
  updateYAxisFormat,
} from 'pages/dashboardPage/charts/utils/multiYAxisUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2TwoDimensionChartInstructions | V2BoxPlotInstructions;
  yAxisFormatId?: string;
};

export default function YAxisConfig({ visualizationType, instructions, yAxisFormatId }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const yAxisFormat = getYAxisFormat(yAxisFormatId, instructions, visualizationType);
  const selectedFormat = yAxisFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;

  return (
    <div className={classes.root}>
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={yAxisFormat?.title}
        onNewValueSubmitted={(newValue: string) => {
          const newYAxisFormat = {
            ...yAxisFormat,
            title: newValue,
            showTitle: true,
          };
          dispatch(
            updateVisualizeOperation(
              updateYAxisFormat(newYAxisFormat, instructions, visualizationType),
              visualizationType as OPERATION_TYPES,
            ),
          );
        }}
        label="Title"
      />
      <SwitchInput
        className={classes.configInput}
        switchOn={yAxisFormat?.showTitle}
        onChange={() => {
          const newYAxisFormat = {
            ...yAxisFormat,
            showTitle: !yAxisFormat?.showTitle,
          };
          dispatch(
            updateVisualizeOperation(
              updateYAxisFormat(newYAxisFormat, instructions, visualizationType),
              visualizationType as OPERATION_TYPES,
            ),
          );
        }}
        label="Show Title"
      />
      <DropdownSelect
        containerClassName={classes.configInput}
        selectedItem={selectedFormat}
        onChange={(item: SelectedDropdownInputItem) => {
          const newYAxisFormat = {
            ...yAxisFormat,
            numberFormat: item,
          };
          dispatch(
            updateVisualizeOperation(
              updateYAxisFormat(newYAxisFormat, instructions, visualizationType),
              visualizationType as OPERATION_TYPES,
            ),
          );
        }}
        filterable={false}
        options={[V2_NUMBER_FORMATS.NUMBER, V2_NUMBER_FORMATS.CURRENCY, V2_NUMBER_FORMATS.PERCENT]}
        noSelectionText="Plain Text"
        minimal
        btnMinimal
        fillWidth
        label="Value Format"
      />
      {selectedFormat.id !== V2_NUMBER_FORMATS.PLAIN_TEXT.id && (
        <InputWithBlurSave
          containerClassName={classes.configInput}
          initialValue={String(yAxisFormat?.decimalPlaces ?? 2)}
          onNewValueSubmitted={(newValue: string) => {
            const intValue = parseInt(newValue);
            if (intValue === 0 || intValue) {
              const newYAxisFormat = {
                ...yAxisFormat,
                decimalPlaces: intValue,
              };
              dispatch(
                updateVisualizeOperation(
                  updateYAxisFormat(newYAxisFormat, instructions, visualizationType),
                  visualizationType as OPERATION_TYPES,
                ),
              );
            }
          }}
          label="Decimal Places"
          helpText="This will only apply to the data tooltip unless 'Show Axis Decimals' is enabled."
        />
      )}
      <SwitchInput
        className={classes.configInput}
        switchOn={yAxisFormat?.showDecimals}
        onChange={() => {
          const newYAxisFormat = {
            ...yAxisFormat,
            showDecimals: !yAxisFormat?.showDecimals,
          };
          dispatch(
            updateVisualizeOperation(
              updateYAxisFormat(newYAxisFormat, instructions, visualizationType),
              visualizationType as OPERATION_TYPES,
            ),
          );
        }}
        label="Show Axis Decimals"
      />
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={String(yAxisFormat?.min ?? '')}
        onNewValueSubmitted={(newValue: string) => {
          const intValue = newValue === '' ? undefined : parseFloat(newValue);
          if (intValue === 0 || intValue || intValue === undefined) {
            const newYAxisFormat: YAxisFormat = { ...yAxisFormat };
            newYAxisFormat.min = intValue;
            dispatch(
              updateVisualizeOperation(
                updateYAxisFormat(newYAxisFormat, instructions, visualizationType),
                visualizationType as OPERATION_TYPES,
              ),
            );
          }
        }}
        label="Minimum value"
      />
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={String(yAxisFormat?.max ?? '')}
        onNewValueSubmitted={(newValue: string) => {
          const intValue = newValue === '' ? undefined : parseFloat(newValue);
          if (intValue === 0 || intValue || intValue === undefined) {
            const newYAxisFormat: YAxisFormat = { ...yAxisFormat };
            newYAxisFormat.max = intValue;
            dispatch(
              updateVisualizeOperation(
                updateYAxisFormat(newYAxisFormat, instructions, visualizationType),
                visualizationType as OPERATION_TYPES,
              ),
            );
          }
        }}
        label="Maximum value"
      />{' '}
    </div>
  );
}
