/** @format */

import { TableColumn } from 'actions/types';

export enum ItemTypes {
  CONFIGURATION_COLUMN = 'CONFIGURATION_COLUMN',
  DATASOURCE = 'DATASOURCE',
  DATA_TABLE_ROW = 'DATA_TABLE_ROW',
  REPORT_BUILDER_ROW = 'REPORT_BUILDER_ROW',
}

export type DraggableDataTableRow = {
  type: ItemTypes.REPORT_BUILDER_ROW;
  data: { index: number };
};

export type DraggableReportBuilderRow = {
  type: ItemTypes.REPORT_BUILDER_ROW;
  data: { index: number };
};

export type ConfigColumnItem = {
  type: string;
  data: TableColumn;
};

export type SchemaItem = {
  type: string;
  data: { id: number; name: string };
};
