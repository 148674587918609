/** @format */

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React from 'react';
import cx from 'classnames';
import { Icon } from '@blueprintjs/core';

import InformationIcon from 'pages/dashboardPage/DashboardDatasetView/Header/InformationIcon';

import { formatValue } from '../../pages/dashboardPage/charts/utils';
import { NoDataConfig, NumberFormatOption } from 'constants/types';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles/constants';
import FlexBox, {
  FlexDirection,
  HorizontalAlignment,
  VerticalAlignment,
} from 'components/core/FlexBox';
import { TextElemHorizAlignments } from 'types/dashboardTypes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  title: {
    fontSize: `16px !important`,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.ds.black,
    marginBottom: theme.spacing(1),
  },
  titleInfoIcon: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  numberTrendContainer: {
    fontSize: '36px !important',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),

    '&.bold': {
      fontWeight: 600,
    },
    '&.italic': {
      fontStyle: 'italic',
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  numberUnit: {
    marginLeft: theme.spacing(2),
    fontSize: '36px !important',
  },
  number: {
    fontSize: '36px !important',
  },
  trend: {
    fontWeight: 600,
    textAlign: 'center',

    '&.tag': {
      padding: '2px 4px',
      borderRadius: 2,
      backgroundColor: '#e3e8ee',

      '&.positive': {
        backgroundColor: '#cbf4c9',
      },
      '&.negative': {
        backgroundColor: theme.palette.ds.lightRed,
      },
    },
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',

    '&.LEFT_ALIGN': {
      alignItems: 'flex-start',
    },
    '&.CENTER_ALIGN': {
      alignItems: 'center',
    },
    '&.RIGHT_ALIGN': {
      alignItems: 'flex-end',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',

    '&.LEFT_ALIGN': {
      alignItems: 'flex-start',
    },
    '&.CENTER_ALIGN': {
      alignItems: 'center',
    },
    '&.RIGHT_ALIGN': {
      alignItems: 'flex-end',
    },
  },
}));

export interface Props {
  decimalPlaces?: number;
  infoTooltipText?: string;
  number: number;
  numberFormat?: NumberFormatOption;
  showTooltipText?: boolean;
  showTrendChangePeriodLabel?: boolean;
  subtitle?: string;
  title?: string;
  /**
   * This should be how much the trend changed as either an absolute (e.g. 400,000) or percentage (e.g. 0.4) value
   */
  trendChangeVal?: number;
  /**
   * This is shown after the percent change text e.g. +40% <Comparision Period>
   */
  trendChangeValLabel?: string;
  trendChangeValFormat?: NumberFormatOption;
  trendColorsReversed?: boolean;
  units?: string;
  timeFormatId?: string;
  customTimeFormat?: string;
  noData?: boolean;
  noDataInstructions?: NoDataConfig;
  bold?: boolean;
  italic?: boolean;
  valueAlignment?: TextElemHorizAlignments;
  titleAlignment?: TextElemHorizAlignments;
  useTrendTag?: boolean;
}

export default function NumberTrendTextPanel(props: Props) {
  const {
    decimalPlaces = 2,
    infoTooltipText,
    number,
    numberFormat,
    showTooltipText,
    subtitle,
    title,
    units,
    timeFormatId,
    customTimeFormat,
    noData,
    noDataInstructions,
    bold,
    italic,
    valueAlignment,
    titleAlignment,
  } = props;
  const classes = useStyles();

  const formattedNumber = formatValue({
    value: number,
    decimalPlaces,
    formatId: numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
    hasCommas: true,
    customTimeFormat,
    timeFormatId,
  });

  return (
    <FlexBox
      horizontalAlignment={HorizontalAlignment.CENTER}
      verticalAlignment={VerticalAlignment.CENTER}
      direction={FlexDirection.COLUMN}
      className={cx(classes.root, GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont)}>
      <div className={cx(classes.titleContainer, titleAlignment)}>
        <FlexBox direction={FlexDirection.ROW} verticalAlignment={VerticalAlignment.CENTER}>
          <div className={cx(classes.title, GLOBAL_STYLE_CLASSNAMES.text.h2.base)}>{title}</div>
          {showTooltipText && infoTooltipText && (
            <InformationIcon className={classes.titleInfoIcon} infoTooltipText={infoTooltipText} />
          )}
        </FlexBox>
        <div className={cx(classes.subtitle, GLOBAL_STYLE_CLASSNAMES.text.body.secondary)}>
          {subtitle}
        </div>
      </div>
      <div className={cx(classes.valueContainer, valueAlignment)}>
        {noData ? (
          <div className={classes.numberTrendContainer}>
            <span
              className={GLOBAL_STYLE_CLASSNAMES.text.body.primary}
              style={{ fontSize: props.noDataInstructions?.noDataFontSize || 36 }}>
              {noDataInstructions?.noDataText || 'No Data'}
            </span>
          </div>
        ) : (
          <div
            className={cx(classes.numberTrendContainer, GLOBAL_STYLE_CLASSNAMES.text.body.primary, {
              bold,
              italic,
            })}>
            <span className={classes.number}>{formattedNumber}</span>
            <span className={classes.numberUnit}>{units}</span>
          </div>
        )}
        {!noData && <TrendChange {...props} />}
      </div>
    </FlexBox>
  );
}

function TrendChange(props: Props) {
  const {
    decimalPlaces,
    showTrendChangePeriodLabel,
    trendChangeVal,
    trendChangeValFormat,
    trendChangeValLabel,
    trendColorsReversed,
    useTrendTag,
  } = props;
  const classes = useStyles(props);
  const theme = useTheme();

  if (trendChangeVal === undefined) return <></>;

  let trendChangeClass = 'neutral';
  let trendColor = '#4f566b';
  if (trendChangeVal < 0) {
    trendChangeClass = trendColorsReversed ? 'positive' : 'negative';
    trendColor = trendColorsReversed ? '#0F9960' : theme.palette.ds.red;
  } else if (trendChangeVal > 0) {
    trendChangeClass = trendColorsReversed ? 'negative' : 'positive';
    trendColor = trendColorsReversed ? theme.palette.ds.red : '#0F9960';
  }
  const defaultDecimalPlaces = trendChangeValFormat === V2_NUMBER_FORMATS.PERCENT ? 1 : 0;
  const formattedTrendChangeVal = formatValue({
    value: Math.abs(trendChangeVal),
    decimalPlaces: decimalPlaces !== undefined ? decimalPlaces : defaultDecimalPlaces,
    formatId: trendChangeValFormat?.id,
    hasCommas: true,
  });

  return (
    <div
      className={cx(
        classes.trend,
        { tag: useTrendTag },
        GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont,
        trendChangeClass,
      )}
      style={{ color: trendColor }}>
      <Icon color={trendColor} icon={trendChangeVal >= 0 ? 'caret-up' : 'caret-down'} />
      {`${formattedTrendChangeVal} ${
        trendChangeValLabel && showTrendChangePeriodLabel ? trendChangeValLabel : ''
      }`}
    </div>
  );
}
