/** @format */

import React, { useState } from 'react';
import _ from 'underscore';
import cx from 'classnames';

import { Classes, Intent, FormGroup } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import DropdownSelect from 'shared/DropdownSelect';
import Modal from 'components/core/Modal';
import { ParentSchema } from 'actions/types';
import Button from 'shared/Button';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';

const useStyles = makeStyles((theme: Theme) => ({
  formGroupValidation: (error?: boolean) => ({
    marginBottom: error ? 0 : theme.spacing(5),
  }),
  textInputContainer: {
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: 0,
  },
}));

type Props = {
  modalOpen: boolean;
  onClose: () => void;
  onSubmit: (newDashboardTitle: string, parent_schema_id: number) => void;
  parentSchemas: ParentSchema[];
  defaultParentSchema?: ParentSchema;
  errorState?: (textFieldVal?: string) => { isErrorState: boolean; errorMsg?: string };
  datasetName?: string;
  title: string;
  buttonTitle: string;
};

export default function DashboardDatasetModal({
  errorState,
  parentSchemas,
  defaultParentSchema,
  datasetName,
  onSubmit,
  onClose,
  modalOpen,
  title,
  buttonTitle,
}: Props) {
  const [textFieldVal, setTextFieldVal] = useState(datasetName || '');
  const [parentSchemaVal, setParentSchemaVal] = useState(
    // autopopulate if there already is a schema or if there's only one schema to select
    defaultParentSchema
      ? defaultParentSchema
      : parentSchemas.length === 1
      ? parentSchemas[0]
      : undefined,
  );
  const classes = useStyles(errorState);

  const { isErrorState, errorMsg } = errorState?.(textFieldVal) ?? {};

  const schemaById = _.indexBy(parentSchemas || [], 'id');

  return (
    <Modal modalOpen={modalOpen} onClose={onClose} title={title}>
      <div className={cx(Classes.DIALOG_BODY, classes.modalBody)}>
        <FormGroup
          className={classes.formGroupValidation}
          helperText={errorMsg}
          intent={isErrorState ? Intent.DANGER : Intent.NONE}
          labelFor="text-input">
          <InputGroup
            className={classes.textInputContainer}
            value={textFieldVal}
            placeholder="Dataset Name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTextFieldVal(e.target.value)}
          />
          <DropdownSelect
            minimal
            fillWidth
            showIcon
            selectedItem={
              parentSchemaVal && {
                id: String(parentSchemaVal.id),
                name: parentSchemaVal.name,
              }
            }
            onChange={(item) => {
              setParentSchemaVal(schemaById[item.id]);
            }}
            noSelectionText="Select Schema"
            options={parentSchemas.map((parentSchema) => ({
              id: String(parentSchema.id),
              name: parentSchema.name,
            }))}
            // only enable schema selection if we're creating a new dataset
            disabled={defaultParentSchema !== undefined}
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            type="primary"
            onClick={() => {
              if (
                isErrorState ||
                !textFieldVal ||
                textFieldVal.trim() === '' ||
                parentSchemaVal === undefined
              ) {
                return;
              }
              onSubmit(textFieldVal || '', parentSchemaVal?.id || 0);
              setTextFieldVal('');
              setParentSchemaVal(defaultParentSchema);
              onClose();
            }}
            disabled={
              isErrorState ||
              !textFieldVal ||
              textFieldVal.trim() === '' ||
              parentSchemaVal === undefined
            }
            text={buttonTitle}
          />
        </div>
      </div>
    </Modal>
  );
}
