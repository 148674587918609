/** @format */

export enum ConnectDataSourceStep {
  GETTING_STARTED = 'Getting started',
  SELECT_DB = 'Select a database',
  ENTER_CREDS = 'Enter credentials',
  SECURITY = 'Configure security',
  REVIEW = 'Review',
}
export const CONNECT_STEPS_ORDER = [
  ConnectDataSourceStep.GETTING_STARTED,
  ConnectDataSourceStep.SELECT_DB,
  ConnectDataSourceStep.ENTER_CREDS,
  ConnectDataSourceStep.SECURITY,
  ConnectDataSourceStep.REVIEW,
];

export const EXPLO_EGRESS_IPS = ['54.211.43.19', '52.55.98.121', '3.214.169.94', '54.156.141.148'];
