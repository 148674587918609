/** @format */

import React from 'react';
import { uniqueId } from 'underscore';

type DashboardLayoutContextType = {
  dashboardLayoutTagId: string;
};

const DashboardLayoutContext = React.createContext<DashboardLayoutContextType>({
  dashboardLayoutTagId: uniqueId('explo-dashboard'),
});

export default DashboardLayoutContext;
