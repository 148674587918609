/** @format */
import React from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';

import DropdownSelect from 'shared/DropdownSelect';

import { SelectedDropdownInputItem } from 'constants/types';
import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';
import { longestStringLength } from 'utils/general';
import { Props as FilterRowProps } from './FilterRow';

const useStyles = makeStyles({
  columnDropdownContainer: {
    width: '100%',
  },
});

type ColumnDropdownProps = { className: string } & Pick<
  FilterRowProps,
  'baseSchema' | 'selectedColumn' | 'onColumnSelect'
>;
export default function ColumnDropdown({
  className,
  baseSchema,
  selectedColumn,
  onColumnSelect,
}: ColumnDropdownProps) {
  const classes = useStyles();
  const columnBySchemaName = _.indexBy(baseSchema, 'name');
  const longestColumnLength = longestStringLength([
    'Column',
    ...baseSchema.map((col) => col.friendly_name || col.name),
  ]);

  return (
    <span className={className}>
      <DropdownSelect
        containerClassName={classes.columnDropdownContainer}
        // Adding 5 because of icon
        buttonStyle={{
          width: `${Math.min(longestColumnLength + 5, 25)}ch`,
        }}
        selectedItem={
          selectedColumn
            ? {
                name: selectedColumn.friendly_name || selectedColumn.name,
                id: selectedColumn.name,
                icon: SCHEMA_DATA_TYPES_BY_ID[selectedColumn.type].icon,
              }
            : undefined
        }
        onChange={(item: SelectedDropdownInputItem) => onColumnSelect(columnBySchemaName[item.id])}
        filterable={false}
        options={baseSchema.map((col) => ({
          name: col.friendly_name || col.name,
          id: col.name,
          icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
        }))}
        noSelectionText="Column"
        minimal
        fillWidth
      />
    </span>
  );
}
