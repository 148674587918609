/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';

import DropdownSelect from 'shared/DropdownSelect';

import { SelectedDropdownInputItem, VisualizeNumberInstructions } from 'constants/types';
import { TableColumn } from 'actions/types';
import { NUMBER_TYPES, SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(4),
    },
  });

type PassedProps = {
  schema: TableColumn[];
  visualizeNumberInstructions: VisualizeNumberInstructions;
  updateVisualizeOperation: (newVisualizeNumberInstructions: VisualizeNumberInstructions) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class NumberColumnConfiguration extends React.Component<Props> {
  render() {
    const { classes, schema, visualizeNumberInstructions } = this.props;
    const { layout, layoutConfig } = visualizeNumberInstructions;

    let column;
    if (layout === 'NUMBER') {
      column = layoutConfig.NUMBER.column;
    }

    return (
      <div className={classes.root}>
        <DropdownSelect
          label="Number Column"
          selectedItem={
            column?.name && column?.type
              ? {
                  name: column.name,
                  id: column.name,
                  icon: SCHEMA_DATA_TYPES_BY_ID[column.type].icon,
                }
              : undefined
          }
          onChange={this.onChange}
          noSelectionText="Select column"
          options={schema
            .filter((col) => NUMBER_TYPES.has(col.type))
            .map((col) => ({
              ...col,
              icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
              id: col.name,
            }))}
          minimal
          fillWidth
          showIcon
        />{' '}
      </div>
    );
  }

  onChange = (item: SelectedDropdownInputItem) => {
    const { visualizeNumberInstructions, updateVisualizeOperation, schema } = this.props;

    const selectedColumn = _.findWhere(schema, {
      name: item.name,
    });

    if (visualizeNumberInstructions.layout === 'NUMBER') {
      visualizeNumberInstructions.layoutConfig.NUMBER.column = selectedColumn;
    }
    updateVisualizeOperation(visualizeNumberInstructions);
  };
}

export default withStyles(styles)(NumberColumnConfiguration);
