/** @format */

import React, { useContext } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Spinner } from '@blueprintjs/core';

import TablePager from 'components/dataTable/tablePager';
import RowCount from './RowCount';
import DownloadChartButton from './DownloadChartButton';

import { AdHocOperationInstructions } from 'actions/types';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { DownloadChartInfo } from 'components/DashboardLayout/DashboardLayout';
import { GlobalStyleConfig } from 'globalStyles/types';
import { GlobalStylesContext } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  tableFooter: ({ globalStyleConfig }: { globalStyleConfig: GlobalStyleConfig }) => ({
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    paddingLeft: globalStyleConfig.container.padding.default,
    height: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  }),
  spinner: {
    marginRight: theme.spacing(1),
  },
  downloadSection: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type Props = {
  adHocOperationInstructions: AdHocOperationInstructions;
  canDownloadDataPanel: boolean;
  downloadChartInfo?: DownloadChartInfo;
  disableDownload?: boolean;
  onDownloadPanelCsv: () => void;
  onDownloadPanelPdf: () => void;
  onNewPage: (newPage: string) => void;
  loading?: boolean;
  sourceDataRowCount?: number;
  rowError?: boolean;
  hideDownloadButton?: boolean;
};

export default function DataTableFooter({
  adHocOperationInstructions,
  canDownloadDataPanel,
  onNewPage,
  rowError,
  sourceDataRowCount,
  loading,
  downloadChartInfo,
  onDownloadPanelCsv,
  onDownloadPanelPdf,
  disableDownload,
  hideDownloadButton,
}: Props) {
  const { globalStyleConfig } = useContext(GlobalStylesContext);
  const classes = useStyles({ globalStyleConfig });

  if (loading || sourceDataRowCount === undefined) {
    return (
      <div
        className={cx(
          classes.tableFooter,
          GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor,
        )}>
        <Spinner className={classes.spinner} size={17} />
      </div>
    );
  }

  return (
    <div
      className={cx(
        classes.tableFooter,
        GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor,
        GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadiusBottom,
      )}>
      <RowCount error={rowError} rowCount={sourceDataRowCount} />
      <TablePager
        currentPage={adHocOperationInstructions.currentPage || 1}
        maxPageNumber={Math.ceil(sourceDataRowCount / 50)}
        onNewPage={onNewPage}
      />
      <div className={classes.downloadSection}>
        {canDownloadDataPanel && (
          <DownloadChartButton
            downloadChartInfo={downloadChartInfo}
            disabled={disableDownload}
            hidden={hideDownloadButton}
            onDownloadPanelCsv={onDownloadPanelCsv}
            onDownloadPanelPdf={onDownloadPanelPdf}
          />
        )}
      </div>
    </div>
  );
}
