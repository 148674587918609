/** @format */

import dayjs from 'dayjs';
import { formatDefaultLocale, format, FormatLocaleDefinition } from 'd3-format';
import { LocaleDefinition, SupportedLocale } from 'constants/types';
import _ from 'underscore';

// plugins
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';

// internationalization
import pt from 'localization/pt-br';
import en from 'localization/en-us';
import es from 'localization/es';
import fr from 'localization/fr';

// load plugins
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);

const localTime = dayjs().format('LT');
const localeUses12HourClock = localTime.includes('AM') || localTime.includes('PM');

const DATE_FORMAT_TO_LOCALIZED_FORMAT: Record<string, string> = {
  'h:mm:ss A': 'LTS',
  'MM/DD/YYYY': 'L',
  'MMMM D, YYYY': 'LL',
  'MMM D, YYYY': 'll',
  'MMM D, YYYY h:mm A': 'lll',

  // modified from orignal formats
  'MMM D': 'l',
  'HH:00 M/D': 'llll',
  'MM-DD-YY': 'LLLL',
  'MM/DD/YYYY h:mm aa': 'L LTS',

  // don't need to be localized
  'MMM YYYY': 'MMM YYYY',
  YYYY: 'YYYY',
  ddd: 'ddd',
  D: 'D',
  MMM: 'MMM',
  'YYYY-MM-DD': 'YYYY-MM-DD',
  'DD-MM-YYYY': 'DD-MM-YYYY',
  'DD/MM/YYYY (HH:mm:ss)': 'DD/MM/YYYY (HH:mm:ss)',

  ha: localeUses12HourClock ? 'ha' : 'h',
  'h:mm A': 'LT',

  // unused
  'MMMM D, YYYY h:mm A': 'LLL',
};

const SUPPORTED_LOCALES: SupportedLocale[] = [
  { name: 'English', localeCode: 'en-us' },
  { name: 'Portuguese - Brazil', localeCode: 'pt-br' },
  { name: 'Spanish', localeCode: 'es' },
  { name: 'French', localeCode: 'fr' },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LOCALE_MAPPING: Record<string, LocaleDefinition> = {
  'pt-br': pt,
  'en-us': en,
  en: en,
  'fr-fr': fr,
  fr: fr,
  'es-es': es,
  es: es,
};

const getNavigatorLanguage = () => {
  let localeCode: string;
  if (navigator.languages && navigator.languages.length) {
    localeCode = navigator.languages[0];
  } else {
    localeCode = navigator.language;
  }

  return getSupportedLanguageOrUndefined(localeCode);
};

const getSupportedLanguageOrUndefined = (localeCode: string) =>
  _.find(Object.keys(LOCALE_MAPPING), (locale) => localeCode.toLowerCase() === locale);

const loadLocale = (options: {
  passedLocaleCode?: string;
  teamLocaleCode?: string;
  useBrowserLocale?: boolean;
}) => {
  const { passedLocaleCode, teamLocaleCode, useBrowserLocale } = options;

  // order of preference is
  // 1) passed locale code from embedded component or via url
  // 2) browser locale, if setting is set by team
  // 4) team's default language
  // 5) en (fall-through for safety)
  let localeCode: string | undefined;

  // if one setting gives us a null value, try the next one or default to English if none work
  if (passedLocaleCode) localeCode = getSupportedLanguageOrUndefined(passedLocaleCode);
  if (!localeCode && useBrowserLocale) localeCode = getNavigatorLanguage();
  if (!localeCode && teamLocaleCode) localeCode = teamLocaleCode;
  if (!localeCode) localeCode = 'en-us';

  const { locale, numbers } = LOCALE_MAPPING[localeCode];
  dayjs.locale(locale);
  formatDefaultLocale(numbers as FormatLocaleDefinition);

  return localeCode;
};

const getFormatForDatePicker = (format: string, localeCode: string) => {
  const localizedFormat = DATE_FORMAT_TO_LOCALIZED_FORMAT[format] as string;
  const locale = LOCALE_MAPPING[localeCode].locale;
  const formats = locale.formats as Record<string, string>;
  return formats[localizedFormat].replace(/D/g, 'd').replace(/Y/g, 'y').replace(/A/g, 'aa');
};

export {
  dayjs,
  format,
  SUPPORTED_LOCALES,
  localeUses12HourClock,
  loadLocale,
  getFormatForDatePicker,
  DATE_FORMAT_TO_LOCALIZED_FORMAT,
};
