/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { Theme } from '@material-ui/core/index';

import SwitchInput from 'pages/dataPanelEditorPage/switchInput';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export default function DrilldownConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <SwitchInput
        className={classes.configInput}
        switchOn={instructions?.drilldown?.categorySelectEnabled}
        onChange={() => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.drilldown = {
            ...newInstructions.drilldown,
            categorySelectEnabled: !newInstructions?.drilldown?.categorySelectEnabled,
          };

          dispatch(updateVisualizeOperation(newInstructions, visualizationType));
        }}
        label="Enable Category Selection"
      />
    </div>
  );
}
