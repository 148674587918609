/** @format */

import Color from 'color';
import _ from 'underscore';
import { Theme } from '@material-ui/core/styles';
import WebFont from 'webfontloader';

import { getTextColorForBackground, mixColors } from 'utils/general';
import { GlobalStyleConfig, GlobalStyleTextConfig } from './types';
import {
  ACTION_COLOR_OUTLINE_OPACITY,
  BACKGROUND_COLOR_OFFSET,
  DEFAULT_GLOBAL_STYLE_CONFIG,
  DROP_SHADOW_OPACITY,
  INTERACTION_STATE_OPACITY,
  TEXT_SIZE_OFFSET_MAP,
} from './constants';

export const getBaseGlobalStyles = (config?: GlobalStyleConfig): GlobalStyleConfig => {
  if (!config) {
    return DEFAULT_GLOBAL_STYLE_CONFIG;
  }

  return {
    base: {
      ...DEFAULT_GLOBAL_STYLE_CONFIG.base,
      ...config.base,
      actionColor: {
        ...DEFAULT_GLOBAL_STYLE_CONFIG.base.actionColor,
        ...config.base.actionColor,
      },
      spacing: {
        ...DEFAULT_GLOBAL_STYLE_CONFIG.base.spacing,
        ...config.base.spacing,
      },
    },
    container: {
      ...DEFAULT_GLOBAL_STYLE_CONFIG.container,
      ...config.container,
      cornerRadius: {
        ...DEFAULT_GLOBAL_STYLE_CONFIG.container.cornerRadius,
        ...config.container.cornerRadius,
      },
      outline: {
        ...DEFAULT_GLOBAL_STYLE_CONFIG.container.outline,
        ...config.container.outline,
      },
      padding: {
        ...DEFAULT_GLOBAL_STYLE_CONFIG.container.padding,
        ...config.container.padding,
      },
      shadow: {
        ...DEFAULT_GLOBAL_STYLE_CONFIG.container.shadow,
        ...config.container.shadow,
      },
    },
    text: {
      ...DEFAULT_GLOBAL_STYLE_CONFIG.text,
      ...config.text,
      overrides: {
        ...DEFAULT_GLOBAL_STYLE_CONFIG.text.overrides,
        ...config.text.overrides,
      },
    },
    visualizations: {
      ...DEFAULT_GLOBAL_STYLE_CONFIG.visualizations,
      ...config.visualizations,
      categoricalPalette: {
        ...DEFAULT_GLOBAL_STYLE_CONFIG.visualizations.categoricalPalette,
        ...config.visualizations.categoricalPalette,
      },
      divergingPalette: {
        ...DEFAULT_GLOBAL_STYLE_CONFIG.visualizations.divergingPalette,
        ...config.visualizations.divergingPalette,
      },
      gradientPalette: {
        ...DEFAULT_GLOBAL_STYLE_CONFIG.visualizations.gradientPalette,
        ...config.visualizations.gradientPalette,
      },
    },
  };
};

export function getCategoricalColors(globalStyleConfig: GlobalStyleConfig) {
  const categoricalColors = globalStyleConfig.visualizations.categoricalPalette;
  return [
    categoricalColors.hue1,
    categoricalColors.hue2,
    categoricalColors.hue3,
    categoricalColors.hue4,
    categoricalColors.hue5,
    categoricalColors.hue6,
  ];
}

export function getDivergingColors(globalStyleConfig: GlobalStyleConfig) {
  const divergingColors = globalStyleConfig.visualizations.divergingPalette;
  const { hue1, hue2, hue3 } = divergingColors;

  return [
    hue1,
    mixColors(hue1, hue2, 0.66).hex().toString(),
    mixColors(hue1, hue2, 0.33).hex().toString(),
    hue2,
    mixColors(hue2, hue3, 0.66).hex().toString(),
    mixColors(hue2, hue3, 0.33).hex().toString(),
    hue3,
  ];
}

export function getGradientColors(globalStyleConfig: GlobalStyleConfig) {
  const gradientColors = globalStyleConfig.visualizations.gradientPalette;
  const { hue1, hue2 } = gradientColors;

  return [
    hue1,
    mixColors(hue1, hue2, 0.75).hex().toString(),
    mixColors(hue1, hue2, 0.5).hex().toString(),
    mixColors(hue1, hue2, 0.25).hex().toString(),
    hue2,
  ];
}

export function loadFonts(globalStyleConfig: GlobalStyleConfig) {
  const { text } = globalStyleConfig;
  if (!text) return;

  const fonts = _.compact([
    text.primaryFont,
    text.secondaryFont,
    text.overrides.h1?.font,
    text.overrides.h2?.font,
    text.overrides.h3?.font,
    text.overrides.body?.font,
    text.overrides.smallHeading?.font,
    text.overrides.smallBody?.font,
  ]);

  const families: string[] = [];
  const urls: string[] = [];

  fonts.forEach((font) => {
    families.push(font);
    urls.push(
      `https://fonts.googleapis.com/css?family=${font.replace(' ', '+') || ''}&display=block`,
    );
  });

  WebFont.load({
    custom: {
      families,
      urls,
    },
  });
}

export function getCalculatedStyles(globalStyleConfig: GlobalStyleConfig, theme: Theme) {
  const {
    base: {
      actionColor: {
        default: defaultActionColor,
        buttonColor: maybeButtonColor,
        interactionStateColor: maybeInteractionStateColor,
      },
      backgroundColor: baseBackgroundColor,
    },
    container: {
      fill: containerFillColor,
      outline: {
        enabled: containerOutlineEnabled,
        color: containerOutlineColor,
        weight: containerOutlineWeight,
      },
      shadow: {
        enabled: containerShadowEnabled,
        color: containerShadowColor,
        size: containerShadowSize,
      },
      padding: { default: containerPadding },
      cornerRadius: { default: defaultContainerCornerRadius, buttons: maybeButtonCornerRadius },
    },
    text: { primaryColor: primaryTextColor, secondaryColor: secondaryTextColor },
  } = globalStyleConfig;

  const { white, black } = theme.palette.ds;
  const buttonColor = maybeButtonColor ?? defaultActionColor;
  const interactionStateColor = new Color(maybeInteractionStateColor ?? defaultActionColor)
    // We want interaction states to have 10% opacity
    .fade(1 - INTERACTION_STATE_OPACITY)
    .rgb()
    .string();
  const actionColorOutline = new Color(defaultActionColor)
    .fade(1 - ACTION_COLOR_OUTLINE_OPACITY)
    .rgb();
  const containerFillColorObject = new Color(containerFillColor);
  const dropShadowColor = new Color(containerShadowColor)
    .fade(1 - DROP_SHADOW_OPACITY)
    .rgb()
    .string();
  const buttonCornerRadius = maybeButtonCornerRadius ?? defaultContainerCornerRadius;
  const outlineBoxShadowBase = `0 0 0 ${containerOutlineWeight}px ${containerOutlineColor}`;

  const getButtonStyles = (alpha: number) => ({
    backgroundColor: mixColors(buttonColor, white, alpha).rgb().string(),
    color: getTextColorForBackground(mixColors(buttonColor, white, alpha).rgb().string(), theme),
  });

  const getTextSize = (type: keyof GlobalStyleTextConfig['overrides']) => {
    return (
      globalStyleConfig.text.overrides[type]?.size ||
      globalStyleConfig.text.textSize + TEXT_SIZE_OFFSET_MAP[type]
    );
  };
  const getTextFont = (type: keyof GlobalStyleTextConfig['overrides'], secondary?: boolean) => {
    return (
      globalStyleConfig.text.overrides[type]?.font ||
      globalStyleConfig.text[secondary ? 'secondaryFont' : 'primaryFont']
    );
  };
  const getTextColor = (type: keyof GlobalStyleTextConfig['overrides'], secondary?: boolean) => {
    return (
      globalStyleConfig.text.overrides[type]?.color ||
      globalStyleConfig.text[secondary ? 'secondaryColor' : 'primaryColor']
    );
  };
  const getTextStyles = (
    type: keyof GlobalStyleTextConfig['overrides'],
    options: { size?: boolean; font?: boolean; color?: boolean; secondary?: boolean } = {},
  ) => {
    const { size = true, font = true, color = true, secondary } = options;
    const fontFamily = getTextFont(type, secondary);

    return {
      ...(size && { fontSize: getTextSize(type) }),
      ...(font && fontFamily && { fontFamily }),
      ...(color && { color: getTextColor(type, secondary) }),
    };
  };

  return {
    base: {
      backgroundColorStyle: {
        backgroundColorStyle: {
          backgroundColor: baseBackgroundColor,
        },
      },
      actionColor: {
        default: {
          backgroundColorStyle: {
            backgroundColor: defaultActionColor,
          },
          blackOrWhiteColorStyle: {
            // TODO PD-1260 we should get rid of the important
            color: `${getTextColorForBackground(defaultActionColor, theme)} !important`,
          },
          boxShadowStyle: {
            // rgb(16 22 26 / 20%) part comes directly from Blueprint
            boxShadow: `0 0 0 1px ${defaultActionColor}, 0 0 0 3px ${actionColorOutline}, inset 0 1px 1px rgb(16 22 26 / 20%)`,
          },
          boxShadowImportantStyle: {
            boxShadow: `0 0 0 1px ${defaultActionColor}, 0 0 0 3px ${actionColorOutline}, inset 0 1px 1px rgb(16 22 26 / 20%) !important`,
          },
          borderColorStyle: {
            borderColor: defaultActionColor,
          },
        },
        buttonColor: {
          colorStyle: {
            color: buttonColor,
          },
          backgroundColorStyle: {
            backgroundColor: buttonColor,
          },
          backgroundColorImportantStyle: {
            backgroundColor: `${buttonColor} !important`,
          },
          blackOrWhiteColorStyle: {
            color: getTextColorForBackground(buttonColor, theme),
          },
          buttonHoverStyle: {
            ...getButtonStyles(0.92),
          },
          buttonActiveStyle: {
            ...getButtonStyles(0.84),
          },
          buttonActiveHoverStyle: {
            ...getButtonStyles(0.76),
          },
        },
        interactionStateColor: {
          borderColorStyle: {
            borderColor: interactionStateColor,
          },
          boxShadowStyle: {
            boxShadow: `inset 0 0 0 1px ${interactionStateColor}`,
          },
          boxShadowImportantStyle: {
            boxShadow: `inset 0 0 0 1px ${interactionStateColor} !important`,
          },
        },
      },
    },
    container: {
      fill: {
        backgroundColorStyle: {
          backgroundColor: containerFillColor,
        },
        offsetBackgroundColorStyle: {
          backgroundColor: containerFillColorObject.isDark()
            ? mixColors(containerFillColor, white, 1 - BACKGROUND_COLOR_OFFSET)
                .rgb()
                .toString()
            : mixColors(containerFillColor, black, 1 - BACKGROUND_COLOR_OFFSET)
                .rgb()
                .toString(),
        },
        blackOrWhiteColorStyle: {
          color: getTextColorForBackground(containerFillColor, theme),
        },
      },
      outline: {
        borderStyle: {
          border: containerOutlineEnabled
            ? `${containerOutlineWeight}px solid ${containerOutlineColor}`
            : 'none',
        },
        boxShadowStyle: {
          boxShadow: containerOutlineEnabled ? outlineBoxShadowBase : 'none',
        },
        boxShadowInsetStyle: {
          boxShadow: containerOutlineEnabled ? `inset ${outlineBoxShadowBase}` : 'none',
        },
        boxShadowInsetImportantStyle: {
          boxShadow: containerOutlineEnabled
            ? `inset ${outlineBoxShadowBase} !important`
            : 'none !important',
        },
      },
      shadow: {
        shadowStyle: {
          filter: containerShadowEnabled
            ? `drop-shadow(${containerShadowSize}px ${containerShadowSize}px ${containerShadowSize}px ${dropShadowColor})`
            : 'none',
        },
      },
      padding: {
        paddingStyle: {
          padding: containerPadding,
        },
      },
      cornerRadius: {
        default: {
          borderRadiusStyle: {
            borderRadius: defaultContainerCornerRadius,
          },
          borderTopRightRadiusStyle: {
            borderTopRightRadius: defaultContainerCornerRadius,
          },
          borderTopLeftRadiusStyle: {
            borderTopLeftRadius: defaultContainerCornerRadius,
          },
          borderBottomLeftRadiusStyle: {
            borderBottomLeftRadius: defaultContainerCornerRadius,
          },
          borderBottomRightRadiusStyle: {
            borderBottomRightRadius: defaultContainerCornerRadius,
          },
        },
        buttonCornerRadius: {
          borderRadiusStyle: {
            borderRadius: buttonCornerRadius,
          },
        },
      },
    },
    text: {
      primaryColor: {
        colorStyle: {
          color: primaryTextColor,
        },
      },
      secondaryColor: {
        colorStyle: {
          color: secondaryTextColor,
        },
        borderLeftColorStyle: {
          borderLeftColor: secondaryTextColor,
        },
        borderRightColorStyle: {
          borderRightColor: secondaryTextColor,
        },
        borderTopColorStyle: {
          borderTopColor: secondaryTextColor,
        },
      },
      overrides: {
        h1: {
          primaryStyle: {
            ...getTextStyles('h1'),
          },
        },
        h2: {
          primaryStyle: {
            ...getTextStyles('h2'),
          },
        },
        h3: {
          primaryStyle: {
            ...getTextStyles('h3'),
          },
          secondaryStyle: {
            ...getTextStyles('h3', { secondary: true }),
          },
        },
        body: {
          primaryStyle: {
            ...getTextStyles('body'),
          },
          secondaryStyle: {
            ...getTextStyles('body', { secondary: true }),
          },
          primaryWithoutColorStyle: {
            ...getTextStyles('body', { color: false }),
          },
          secondaryColorStyle: {
            color: getTextColor('body', true),
          },
        },
        smallBody: {
          primaryStyle: {
            ...getTextStyles('smallBody'),
          },
          secondaryStyle: {
            ...getTextStyles('smallBody', { secondary: true }),
          },
        },
        smallHeading: {
          secondaryStyle: {
            ...getTextStyles('smallHeading', { secondary: true }),
          },
          secondaryWithoutColorStyle: {
            ...getTextStyles('smallHeading', { secondary: true, color: false }),
          },
          secondaryFillImportantStyle: {
            fill: `${getTextColor('smallHeading', true)} !important`,
          },
        },
      },
    },
  };
}
