/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import FlexBox, { VerticalAlignment, HorizontalAlignment } from 'components/core/FlexBox';

const useStyles = makeStyles(() => ({
  root: {},
  noLabelDash: {
    marginTop: 0,
  },
  inputLeft: {
    '& input.': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  inputRight: {
    '& input': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginLeft: -1,
    },
  },
}));

export type Props = {
  /**
   * optional class to wrap entire range input
   */
  className?: string;
  /**
   * optional label shown above the range inputs in the top right
   */
  label?: string;
  /**
   * Value for start of the range
   */
  startVal?: string;
  /**
   * Value for end of range
   */
  endVal?: string;
  /**
   * Called when either start or end is updated
   */
  onNewRange: (start?: string, end?: string) => void;
};

const RangeInput: React.FC<Props> = ({ className, onNewRange, label, startVal, endVal }) => {
  const classes = useStyles();

  return (
    <FlexBox
      horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}
      verticalAlignment={VerticalAlignment.CENTER}
      className={cx(classes.root, className)}>
      <InputWithBlurSave
        containerClassName={classes.inputLeft}
        placeholder="Min"
        label={label}
        initialValue={String(startVal ?? '')}
        onNewValueSubmitted={(newStartString: string) => {
          if (newStartString === '') {
            return onNewRange(undefined, endVal);
          }
          onNewRange(newStartString, endVal);
        }}
      />
      <InputWithBlurSave
        containerClassName={classes.inputRight}
        useFakeLabel={!!label} // if no label, then don't fake a label on second input
        placeholder="Max"
        initialValue={String(endVal ?? '')}
        onNewValueSubmitted={(newEndString: string) => {
          if (newEndString === '') {
            return onNewRange(startVal, undefined);
          }
          onNewRange(startVal, newEndString);
        }}
      />
    </FlexBox>
  );
};

export default RangeInput;
