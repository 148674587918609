/** @format */

import React from 'react';
import cx from 'classnames';
import { dayjs } from 'utils/localizationUtils';
import DatePicker from 'react-datepicker';
import { makeStyles } from '@material-ui/core/styles';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import DropdownSelect from 'shared/DropdownSelect';

import {
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_RELATIVE_PICKER,
  DATE_RELATIVE_OPTIONS,
  FILTER_OPS_MULTISELECT,
} from 'constants/dataPanelEditorConstants';
import { TableColumn } from 'actions/types';
import {
  FilterOperatorType,
  FilterValueDateType,
  FilterValueRelativeDateType,
  FilterValueType,
} from 'constants/types';

const filterStyles = makeStyles((theme) => ({
  datePicker: {
    padding: '0 10PX',
    borderWidth: 0,
    borderRadius: 3,
    fontSize: 14,
    height: 30,
    boxShadow: `inset 0 0 0 1px ${theme.palette.ds.grey500} !important`,
    width: '100%',
  },
  dateRange: {
    display: 'flex',
    flexDirection: 'row',
  },
  startDateRaneWrapper: {
    marginRight: -1,
  },
  startDateRange: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  endDateRange: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  datePopover: {
    zIndex: 100,
  },
  dateWedge: {
    width: 16,
  },
  formGroupValidationNoError: {
    marginBottom: 20,
  },
  relativeContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  relativeNumInput: {
    width: '30%',
    marginRight: theme.spacing(2),
  },
  relativeTimeDropdown: {
    width: '70%',
  },
  fillWidth: {
    width: '100%',
  },
}));

const convertDashDateToSlashDate = (date: string) => {
  return date.replace(/-/g, '/');
};

interface Props {
  filterOperation: FilterOperatorType;
  filterColumn: TableColumn;
  filterValue: FilterValueType;
  fillWidth?: boolean;
  updateFilterValue: (value: FilterValueType) => void;
  updateStartDate: (startDate: string | null) => void;
  updateEndDate: (endDate: string | null) => void;
  adHocFilterSettings?: boolean;
  preventFutureDates?: boolean;
}

const FilterValueInput = (props: Props) => {
  const {
    filterValue,
    filterOperation,
    filterColumn,
    fillWidth,
    adHocFilterSettings,
    preventFutureDates,
  } = props;
  const styles = filterStyles();

  if (!filterOperation || !filterColumn || FILTER_OPS_NO_VALUE.has(filterOperation.id)) {
    return <></>;
  }

  let error = '';
  const VIEW_DATE_FORMAT = 'MM/DD/YYYY'; // we might want to support granular hours/minutes in the future
  const DATE_FORMAT = 'YYYY-MM-DD';
  if (FILTER_OPS_DATE_PICKER.has(filterOperation.id)) {
    let date: Date | null = null;
    const filterValueDate = filterValue as FilterValueDateType;
    if (filterValue) {
      date = filterValueDate.startDate
        ? new Date(convertDashDateToSlashDate(filterValueDate.startDate.toString()))
        : null;
    }

    return (
      <DatePicker
        className={styles.datePicker}
        popperClassName={styles.datePopover}
        withPortal={false}
        popperPlacement={adHocFilterSettings ? 'top-end' : undefined}
        selected={date || undefined}
        maxDate={preventFutureDates ? new Date() : undefined}
        onChange={(date) => {
          props.updateStartDate(date ? dayjs(date as Date).format(DATE_FORMAT) : null);
        }}
        placeholderText={VIEW_DATE_FORMAT}
        showMonthDropdown
        showYearDropdown
        popperModifiers={{
          preventOverflow: { enabled: false },
          flip: { boundariesElement: 'scrollParent' },
        }}
      />
    );
  } else if (FILTER_OPS_DATE_RANGE_PICKER.has(filterOperation.id)) {
    let startDate: Date | null = null;
    let endDate: Date | null = null;
    const filterValueDate = filterValue as FilterValueDateType;
    if (filterValue) {
      startDate = filterValueDate.startDate
        ? new Date(convertDashDateToSlashDate(filterValueDate.startDate.toString()))
        : null;
      endDate = filterValueDate.endDate
        ? new Date(convertDashDateToSlashDate(filterValueDate.endDate.toString()))
        : null;
    }
    return (
      <div className={styles.dateRange}>
        <DatePicker
          wrapperClassName={styles.startDateRaneWrapper}
          className={cx(styles.datePicker, styles.startDateRange)}
          popperClassName={styles.datePopover}
          selected={startDate || null}
          onChange={(newDate) => {
            props.updateStartDate(newDate ? dayjs(newDate as Date).format(DATE_FORMAT) : null);
          }}
          placeholderText={VIEW_DATE_FORMAT}
          selectsStart
          maxDate={preventFutureDates ? new Date() : undefined}
          startDate={startDate || null}
          endDate={endDate || null}
          withPortal={false}
          popperPlacement={adHocFilterSettings ? 'top-end' : undefined}
          showMonthDropdown
          showYearDropdown
          popperModifiers={{
            preventOverflow: { enabled: false },
            flip: { boundariesElement: 'scrollParent' },
          }}
        />
        <DatePicker
          className={cx(styles.datePicker, styles.endDateRange)}
          popperClassName={styles.datePopover}
          selected={endDate || null}
          onChange={(newDate) => {
            props.updateEndDate(newDate ? dayjs(newDate as Date).format(DATE_FORMAT) : null);
          }}
          placeholderText={VIEW_DATE_FORMAT}
          selectsEnd
          maxDate={preventFutureDates ? new Date() : undefined}
          startDate={startDate || null}
          endDate={endDate || null}
          withPortal={false}
          showMonthDropdown
          showYearDropdown
          popperPlacement={adHocFilterSettings ? 'top-end' : undefined}
          popperModifiers={{
            preventOverflow: { enabled: false },
            flip: { boundariesElement: 'scrollParent' },
          }}
        />
      </div>
    );
  } else if (FILTER_OPS_RELATIVE_PICKER.has(filterOperation.id)) {
    const filterValueRelativeDate = filterValue as FilterValueRelativeDateType;
    return (
      <div className={styles.relativeContainer}>
        <InputWithBlurSave
          containerClassName={styles.relativeNumInput}
          initialValue={filterValue ? String(filterValueRelativeDate.number || '') : ''}
          onNewValueSubmitted={(newValue) => {
            const newValNum = Number.parseInt(newValue);
            props.updateFilterValue({
              ...filterValueRelativeDate,
              number: newValNum,
            });
          }}
        />
        <div className={styles.relativeTimeDropdown}>
          <DropdownSelect
            selectedItem={filterValueRelativeDate && filterValueRelativeDate.relativeTimeType}
            onChange={(item) =>
              props.updateFilterValue({
                ...filterValueRelativeDate,
                relativeTimeType: item,
              })
            }
            noSelectionText="Select time"
            options={DATE_RELATIVE_OPTIONS}
            minimal
            fillWidth
          />
        </div>
      </div>
    );
  } else if (FILTER_OPS_MULTISELECT.has(filterOperation.id)) {
    const valuesArrStr = filterValue as string;
    try {
      if (valuesArrStr) {
        const valuesArr = JSON.parse(valuesArrStr);
        if (!Array.isArray(valuesArr) || valuesArr.length === 0) {
          error = 'Must be valid non empty javascript array.';
        }
      }
    } catch {
      error = 'Must be valid non empty javascript array.';
    }
  }

  return (
    <InputWithBlurSave
      containerClassName={fillWidth ? styles.fillWidth : ''}
      initialValue={filterValue as string}
      onNewValueSubmitted={(newValue) => {
        props.updateFilterValue(newValue);
      }}
      errorText={error}
    />
  );
};

export default FilterValueInput;
