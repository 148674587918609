/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Theme, WithStyles } from '@material-ui/core/index';
import { Intent } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { AppToaster } from 'toaster';
import cx from 'classnames';

import Button from 'shared/Button';
import DataConfigTab from './DataConfigTab';
import ConfirmationModal from 'components/modals/confirmationModal';
import DataPanelNameModal from './DataPanelNameModal';
import NavTabs from 'components/core/navTabs';
import FormatConfigTab from './FormatConfigTab';

import { ReduxState } from 'reducers/rootReducer';
import {
  ACTION,
  DataPanelTemplate,
  TableColumn,
  DataPanelConfigReducerState,
  Dataset,
} from 'actions/types';
import { deleteDataPanelV2, renameDataPanelV2 } from 'actions/dashboardV2Actions';
import {
  shouldRecomputeDataForDataPanel,
  shouldRecomputeSecondaryDataForDataPanel,
} from 'utils/dataPanelConfigUtils';
import { trackEvent } from 'analytics/exploAnalytics';
import { createLoadingSelector } from 'reducers/api/selectors';
import { DashboardElement } from 'types/dashboardTypes';

type ConfigTabInfo = { id: string; name: string };

const CONFIG_TABS: { [id: string]: ConfigTabInfo } = {
  DATA: {
    id: 'DATA',
    name: 'Data',
  },
  FORMAT: {
    id: 'FORMAT',
    name: 'Format',
  },
};

const TABS_HEIGHT = 40;
const HEADER_HEIGHT = 56;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.ds.white,
      borderRight: `1px solid ${theme.palette.ds.grey400}`,
    },
    bodyContainer: {
      backgroundColor: theme.palette.ds.white,
      height: `calc(100% - ${HEADER_HEIGHT}px)`,
    },
    configTabsMenu: {
      flexBasis: 0,
      height: TABS_HEIGHT,
      borderBottom: `1px solid ${theme.palette.ds.grey400}`,
    },
    configTabBtn: {
      width: '100%',
    },
    configMenu: {
      height: `calc(100% - ${TABS_HEIGHT}px)`,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    configurationHeader: {
      height: HEADER_HEIGHT,
      padding: `0 ${theme.spacing(3)}px`,
      borderBottom: `1px solid ${theme.palette.ds.grey400}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    dataTableNameContainer: {
      width: '60%',
    },
    dataTableName: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme.palette.ds.grey600,
    },
    headerActions: {
      display: 'flex',
      alignItems: 'center',
    },
    elementIdTag: {
      fontWeight: 'bold' as 'bold',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    pressedButton: {
      backgroundColor: `${theme.palette.ds.pressed.grey100} !important`,
      color: `${theme.palette.ds.grey900} !important`,
    },
  });

type PassedProps = {
  unselectElement: () => void;
  baseSchema: TableColumn[];
  computedSchema: TableColumn[];
  dataPanelTemplate: DataPanelTemplate;
  dataPanelConfigState: DataPanelConfigReducerState;
  sourceTableName?: string;
  visualizationType: string;
  dashboardElements?: DashboardElement[];
  sourceType?: string;
  duplicateDataPanel: (dataPanelTemplate: DataPanelTemplate) => void;
  dashboardDatasets: Record<string, Dataset>;
  existingDataPanelProvidedIds: string[];
};

type Props = PassedProps &
  WithStyles<typeof styles> &
  RouteComponentProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type State = {
  selectedConfigTabId: string;
  deleteConfirmationModalOpen?: boolean;
  renameDataPanelModalOpen?: boolean;
  isDuplicatingItem: boolean;
  shouldShowDuplicateCompleteToast: boolean;
};

class DataPanelConfigV2 extends React.Component<Props, State> {
  state: State = {
    selectedConfigTabId: CONFIG_TABS.DATA.id,
    isDuplicatingItem: false,
    shouldShowDuplicateCompleteToast: false,
  };

  componentDidUpdate(prevProps: Props) {
    // Don't recompute if the config changes because we're switching between
    // different data panels
    if (prevProps.dataPanelTemplate.id !== this.props.dataPanelTemplate.id) return;

    const { shouldRecompute } = shouldRecomputeDataForDataPanel(
      prevProps.dataPanelConfigState,
      this.props.dataPanelConfigState,
    );
    const shouldRecomputeSecondaryData = shouldRecomputeSecondaryDataForDataPanel(
      prevProps.dataPanelConfigState,
      this.props.dataPanelConfigState,
    );

    window.dispatchEvent(
      new CustomEvent('dataPanelConfigUpdated', {
        detail: {
          shouldRecompute,
          shouldRecomputeSecondaryData,
          dataPanelTemplateId: this.props.dataPanelTemplate.id,
        },
      }),
    );
  }

  render = () => {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderDashboardTitle()}
        {this.renderConfigBody()}
        {this.renderDeleteConfirmationModal()}
        {this.renderRenameDataPanelModal()}
      </div>
    );
  };

  renderDashboardTitle = () => {
    const {
      classes,
      dataPanelTemplate,
      dataPanelRenameSaving,
      sourceTableName,
      duplicateDataPanel,
    } = this.props;
    const { isDuplicatingItem, shouldShowDuplicateCompleteToast } = this.state;
    return (
      <div className={classes.configurationHeader}>
        <div className={classes.dataTableNameContainer}>
          <div className={classes.elementIdTag}>{dataPanelTemplate.name}</div>
          <div className={classes.dataTableName}>{sourceTableName}</div>
        </div>
        <div className={classes.headerActions}>
          <Button
            minimal
            disabled={dataPanelRenameSaving}
            icon="edit"
            loading={dataPanelRenameSaving}
            onClick={() => this.setState({ renameDataPanelModalOpen: true })}
          />
          <Button
            minimal
            className={cx({
              [classes.pressedButton]: shouldShowDuplicateCompleteToast,
            })}
            disabled={isDuplicatingItem || shouldShowDuplicateCompleteToast}
            icon={shouldShowDuplicateCompleteToast ? 'tick' : 'duplicate'}
            onClick={() => {
              this.setState({ isDuplicatingItem: true });
              duplicateDataPanel(dataPanelTemplate);
              this.setState({ isDuplicatingItem: false, shouldShowDuplicateCompleteToast: true });

              AppToaster.show({
                icon: 'endorsed',
                intent: Intent.SUCCESS,
                message: `${dataPanelTemplate.name} has been duplicated below`,
                onDismiss: () => this.setState({ shouldShowDuplicateCompleteToast: false }),
                timeout: 10000,
              });
            }}
          />
          <Button
            minimal
            icon="trash"
            onClick={() => this.setState({ deleteConfirmationModalOpen: true })}
            type="destructive"
          />
        </div>
      </div>
    );
  };

  renderConfigBody() {
    const { classes } = this.props;
    return (
      <div className={classes.bodyContainer}>
        {this.renderTabsMenu()}
        <div className={classes.configMenu}>{this.renderTabConfigOptions()}</div>
      </div>
    );
  }

  renderTabsMenu = () => {
    const { classes } = this.props;
    const { selectedConfigTabId } = this.state;

    return (
      <NavTabs
        className={classes.configTabsMenu}
        tabClassName={classes.configTabBtn}
        tabs={[CONFIG_TABS.DATA, CONFIG_TABS.FORMAT]}
        selectedTabId={selectedConfigTabId}
        onTabSelect={(tabId) => this.switchConfigTab(tabId)}
      />
    );
  };

  switchConfigTab = (tabId: string) => {
    const { selectedConfigTabId } = this.state;
    if (selectedConfigTabId === tabId) return;
    this.setState({ selectedConfigTabId: tabId });
  };

  renderTabConfigOptions = () => {
    const {
      visualizationType,
      baseSchema,
      computedSchema,
      dataPanelTemplate,
      dataPanelUpdating,
      dashboardElements,
      sourceType,
      dashboardDatasets,
    } = this.props;
    const { selectedConfigTabId } = this.state;

    switch (selectedConfigTabId) {
      case CONFIG_TABS.DATA.id:
        return (
          <DataConfigTab
            schema={baseSchema}
            visualizationType={visualizationType}
            vizInstructions={dataPanelTemplate.visualize_op.instructions}
            filterInstructions={dataPanelTemplate.filter_op.instructions}
            dashboardElements={dashboardElements}
            loading={dataPanelUpdating}
            sourceType={sourceType}
          />
        );
      case CONFIG_TABS.FORMAT.id:
        return (
          <FormatConfigTab
            schema={computedSchema}
            visualizationType={visualizationType}
            instructions={dataPanelTemplate.visualize_op.instructions}
            generalFormatOptions={dataPanelTemplate.visualize_op.generalFormatOptions}
            dashboardDatasets={dashboardDatasets}
            dataPanelData={dataPanelTemplate._rows || []}
          />
        );
      default:
        return <div></div>;
    }
  };

  renderDeleteConfirmationModal = () => {
    const { deleteDataPanelV2, dataPanelTemplate, unselectElement } = this.props;
    const { deleteConfirmationModalOpen } = this.state;

    if (!deleteConfirmationModalOpen) return;

    return (
      <ConfirmationModal
        isDestructive
        modalOpen={deleteConfirmationModalOpen}
        closeModal={() => this.setState({ deleteConfirmationModalOpen: false })}
        modalTitle="Are you sure you want to delete this data panel?"
        cancelBtnText="Cancel"
        confirmBtnText="Delete"
        onConfirm={() => {
          this.setState({
            deleteConfirmationModalOpen: false,
          });
          unselectElement();
          deleteDataPanelV2({ id: dataPanelTemplate.id });

          trackEvent('Deleted data panel', {
            data_panel_template_id: dataPanelTemplate.id,
          });
        }}
      />
    );
  };

  renderRenameDataPanelModal = () => {
    const { renameDataPanelV2, dataPanelTemplate, existingDataPanelProvidedIds } = this.props;
    const { renameDataPanelModalOpen } = this.state;

    if (!renameDataPanelModalOpen) return;

    return (
      <DataPanelNameModal
        initialDataPanelName={dataPanelTemplate.name}
        initialDataPanelId={dataPanelTemplate.provided_id || dataPanelTemplate.id}
        modalOpen={renameDataPanelModalOpen}
        closeModal={() => this.setState({ renameDataPanelModalOpen: false })}
        onSubmit={(newName: string, newId: string) => {
          this.setState({ renameDataPanelModalOpen: false });
          renameDataPanelV2({ id: dataPanelTemplate.id, name: newName, providedId: newId });
          trackEvent('Renamed data panel', {
            data_panel_template_id: dataPanelTemplate.id,
            name: newName,
          });
        }}
        existingDataPanelProvidedIds={existingDataPanelProvidedIds}
      />
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  dataPanelRenameSaving: createLoadingSelector([ACTION.RENAME_DATA_PANEL_TEMPLATE], false)(state),
  dataPanelUpdating: createLoadingSelector([ACTION.FETCH_DATA_PANEL_TEMPLATE], false)(state),
});

const mapDispatchToProps = {
  deleteDataPanelV2,
  renameDataPanelV2,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(DataPanelConfigV2)));
