/** @format */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Icon, Intent, EditableText } from '@blueprintjs/core';
import { passwordResetRequest, changeUserProfileName } from 'actions/authAction';
import { AppToaster } from 'toaster';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

const useStyles = makeStyles((theme: Theme) => ({
  subsection: {
    fontSize: 20,
    fontWeight: 600,
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(8),
  },
  profileIcon: {
    position: 'static',
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(8),
    display: 'flex',
  },
  changeProfileIcon: {
    marginLeft: theme.spacing(-28),
    display: 'flex',
    opacity: '0',
    alignItems: 'center',
    justifyContent: 'center',
    width: 112,
    height: 112,
    borderRadius: '50%',
    backgroundColor: theme.palette.ds.grey700,
    '&:hover': {
      opacity: '0',
      cursor: 'auto',
    },
  },
  initials: {
    backgroundColor: theme.palette.ds.grey700,
    color: theme.palette.ds.white,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    width: 112,
    height: 112,
    alignItems: 'center',
    fontSize: 52,
  },
  label: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.ds.grey900,
    marginTop: theme.spacing(4),
  },
  profileInfo: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(8),
    width: '80%',
  },
  nameBox: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    paddingLeft: theme.spacing(3),
    borderColor: theme.palette.ds.grey500,
    height: '32px',
    borderRadius: '4px',
    minWidth: '160px',
    maxWidth: '450px',
    width: '100%',
    '&:hover': {
      cursor: 'text',
    },
  },
  emailBox: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    paddingLeft: theme.spacing(3),
    borderColor: theme.palette.ds.grey300,
    height: '32px',
    minWidth: '180',
    maxWidth: '900px',
    width: 'calc(100% - 20px)',
    borderRadius: '4px',
    background: theme.palette.ds.grey100,
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  name: {
    fontSize: 14,
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1.5),
  },
  resetPasswordButton: {
    marginTop: theme.spacing(7),
    background: theme.palette.ds.grey200,
    fontWeight: 500,
    borderRadius: '4px',
    height: '32px',
    width: '141px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  profile: {
    display: 'flex',
    marginBottom: theme.spacing(20),
  },
  nameBoxes: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  nameBoxWrapper: {
    paddingRight: theme.spacing(5),
    width: '100%',
  },
  languageContainer: {
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(8),
  },
}));

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

function SettingsProfileSection(props: Props) {
  const classes = useStyles();
  const { passwordResetRequest, currentUser, changeUserProfileName } = props;
  const [firstName, setFirstName] = useState(currentUser.first_name);
  const [lastName, setLastName] = useState(currentUser.last_name);
  const onPasswordResetRequestSubmitted = () => {
    passwordResetRequest(
      {
        postData: {
          email: currentUser.email,
        },
      },
      () => {
        AppToaster.show({
          message: 'Reset password email sent successfully.',
          icon: 'endorsed',
          timeout: 3000,
          intent: Intent.SUCCESS,
        });
      },
      () => {
        AppToaster.show({
          message:
            'Something went wrong. Please try again or contact support@explo.co if the error continues.',
          icon: 'error',
          timeout: 3000,
          intent: Intent.DANGER,
        });
      },
    );
  };

  return (
    <div>
      <div className={classes.subsection}>Profile</div>
      <div className={classes.profile}>
        <div className={classes.profileIcon}>
          <div className={classes.initials}>
            {currentUser.first_name ? currentUser.first_name.charAt(0).toUpperCase() : 'A'}
            {currentUser.last_name ? currentUser.last_name.charAt(0).toUpperCase() : ''}
          </div>
          <div className={classes.changeProfileIcon}>
            <Icon icon="upload" color="white" />
          </div>
        </div>
        <div className={classes.profileInfo}>
          <div className={classes.nameBoxes}>
            <div className={classes.nameBoxWrapper}>
              <div className={classes.label}>First Name</div>
              <EditableText
                className={classes.nameBox}
                selectAllOnFocus={true}
                value={firstName}
                placeholder=""
                onConfirm={(value: string) => {
                  changeUserProfileName({
                    postData: {
                      first_name: value,
                      last_name: currentUser.last_name,
                      id: currentUser.id,
                    },
                  });
                }}
                onChange={(value: string) => setFirstName(value)}
              />
            </div>
            <div className={classes.nameBoxWrapper}>
              <div className={classes.label}>Last Name</div>
              <EditableText
                className={classes.nameBox}
                selectAllOnFocus={true}
                value={lastName}
                placeholder=""
                onConfirm={(value: string) => {
                  changeUserProfileName({
                    postData: {
                      first_name: currentUser.first_name,
                      last_name: value,
                      id: currentUser.id,
                    },
                  });
                }}
                onChange={(value: string) => setLastName(value)}
              />
            </div>
          </div>
          <div className={classes.label}>Email</div>
          <div className={classes.emailBox}>{currentUser.email}</div>
          <div
            className={classes.resetPasswordButton}
            onClick={() => onPasswordResetRequestSubmitted()}>
            Reset Password
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  passwordResetRequest,
  changeUserProfileName,
};

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsProfileSection);
