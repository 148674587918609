/** @format */

import React from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/index';

import FullPageContentContainer from 'shared/FullPageContentContainer';
import InputWithTag, { Props as InputWithTagProps } from 'shared/InputWithTag/InputWithTag';
import DropdownSelect from 'shared/DropdownSelect';
import ConnectStepByStep from '../ConnectStepByStep';
import ConnectFAQs from '../ConnectFAQs';

import { ConnectDataSourceStep } from '../constants';
import { DBConnectionConfig } from '../types';
import { Intent } from '@blueprintjs/core';
import { DataSource, ParentSchema } from 'actions/types';

const useStyles = makeStyles((theme: Theme) => ({
  nameInputs: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  nickNameInput: {
    width: '100%',
    marginRight: theme.spacing(3),
  },
  idInput: {
    width: '100%',
  },
}));

export type Props = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  parentSchemas?: ParentSchema[];
  selectedSchema?: ParentSchema;
  selectedSchemaIsNew?: boolean;
  setSelectedSchema: (schema: ParentSchema, isNew?: boolean) => void;
  existingDataSources: DataSource[];
};

const GettingStarted: React.FC<Props> = ({
  config,
  updateConfig,
  onNextClicked,
  parentSchemas,
  selectedSchema,
  selectedSchemaIsNew,
  setSelectedSchema,
  existingDataSources,
}) => {
  return (
    <FullPageContentContainer
      headerTitle="Getting started"
      headerSubtitle="Choose how you'd like to reference your database in Explo. You can change this later on"
      primaryActionConfig={{
        text: 'Next',
        disabled: !config.name || !config.providedId || !selectedSchema || !!config.providedIdError,
        onClick: onNextClicked,
      }}
      leftSideBarTopContent={
        <ConnectStepByStep currentStep={ConnectDataSourceStep.GETTING_STARTED} />
      }
      leftSideBarBottomContent={<ConnectFAQs />}
      bodyContent={
        <GettingStartedBody
          config={config}
          updateConfig={updateConfig}
          parentSchemas={parentSchemas}
          selectedSchema={selectedSchema}
          selectedSchemaIsNew={selectedSchemaIsNew}
          setSelectedSchema={setSelectedSchema}
          existingDataSources={existingDataSources}
        />
      }
    />
  );
};

export type BodyProps = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
  parentSchemas?: ParentSchema[];
  selectedSchema?: ParentSchema;
  selectedSchemaIsNew?: boolean;
  setSelectedSchema: (schema: ParentSchema, isNew?: boolean) => void;
  existingDataSources: DataSource[];
};

export const GettingStartedBody: React.FC<BodyProps> = ({
  config,
  updateConfig,
  parentSchemas,
  selectedSchema,
  selectedSchemaIsNew,
  setSelectedSchema,
  existingDataSources,
}) => {
  const classes = useStyles();

  const existingDSProvidedIds = new Set(_.pluck(existingDataSources, 'provided_id'));
  const parentSchemaById = _.indexBy(parentSchemas || [], 'id');

  let providedIdStatus: InputWithTagProps['statusInfo'] = {
    statusIcon: config.providedId.length > 0 ? 'tick' : undefined,
    statusIntent: Intent.SUCCESS,
  };
  if (config.providedIdError) {
    providedIdStatus = {
      statusIcon: 'cross',
      statusIntent: Intent.DANGER,
      statusText: 'ID not unique',
    };
  }

  return (
    <div>
      <div className={classes.nameInputs}>
        <InputWithTag
          className={classes.nickNameInput}
          label="Nickname"
          helpText="This is how we’ll refer to your database in Explo."
          placeholder="Explo DB"
          statusInfo={{
            statusIcon: config.name.length > 0 ? 'tick' : undefined,
            statusIntent: Intent.SUCCESS,
          }}
          value={config.name}
          onChange={(e) => {
            const newName = e.target.value;
            const oldGeneratedId = generateProvidedId(config.name);
            const newProvidedId =
              oldGeneratedId === config.providedId
                ? generateProvidedId(newName)
                : config.providedId;
            updateConfig({
              ...config,
              name: newName,
              providedId: newProvidedId,
              providedIdError: existingDSProvidedIds.has(newProvidedId)
                ? 'An existing data source has this ID'
                : undefined,
            });
          }}
        />
        <InputWithTag
          className={classes.idInput}
          label="ID"
          helpText="This is how you’ll reference the database when setting up user groups."
          placeholder="explo-db"
          statusInfo={providedIdStatus}
          value={config.providedId}
          onChange={(e) =>
            updateConfig({
              ...config,
              providedId: e.target.value,
              providedIdError: existingDSProvidedIds.has(e.target.value)
                ? 'An existing data source has this provided ID'
                : undefined,
            })
          }
        />
      </div>
      <DropdownSelect
        filterable={false}
        fillWidth
        minimal
        selectedItem={
          selectedSchema && {
            id: selectedSchema.id.toString(),
            name: selectedSchema.name,
          }
        }
        options={
          parentSchemas
            ? parentSchemas.map((schema) => ({ id: schema.id.toString(), name: schema.name }))
            : []
        }
        onChange={(item) => {
          setSelectedSchema(parentSchemaById[parseInt(item.id)]);
        }}
        noSelectionText="Select a schema"
        label="Schema"
        disabled={selectedSchemaIsNew}
        onCreateItem={(newSchemaName) =>
          newSchemaName &&
          setSelectedSchema(
            {
              name: newSchemaName,
              id: -1,
              team_id: -1,
            },
            true,
          )
        }
        createItemText="Create a new schema"
        createItemPlaceholderText="Enter new schema name"
      />
    </div>
  );
};

const generateProvidedId = (name: string) => name.toLowerCase().replace(/(\s+)/g, '-');

export default GettingStarted;
