/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';

import SwitchInput from 'pages/dataPanelEditorPage/switchInput';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import YAxisConfig from './YAxisConfig';
import {
  updateYAxisFormat,
  getAggColDisplayName,
  getYAxisFormat,
} from 'pages/dashboardPage/charts/utils/multiYAxisUtils';
import equal from 'fast-deep-equal/es6/react';
import _ from 'lodash';
import DropdownSelect from 'shared/DropdownSelect';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
}));

export type Props = {
  visualizationType: string;
  instructions: V2TwoDimensionChartInstructions;
  yAxisFormatId?: string;
};

/**
 * This component is used to display and set the YAxisOptions for charts that can format multiple Y-Axes
 * It has some extra options that don't make sense on a single Y Axis config such as matching the visualization color
 */
export default function MultiYAxisConfig({
  visualizationType,
  instructions,
  yAxisFormatId,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const yAxisFormat = getYAxisFormat(yAxisFormatId, instructions);

  const getColorSelectedItem = () => {
    const mappedAggColumn = _.find(instructions.aggColumns, (aggColumn) =>
      equal(aggColumn, yAxisFormat?.colorFromAggColumn),
    );
    if (!mappedAggColumn) return undefined;
    return {
      id: `${mappedAggColumn.agg.name}-${mappedAggColumn.column.name}`,
      name: getAggColDisplayName(mappedAggColumn),
    };
  };

  return (
    <div className={classes.root}>
      <YAxisConfig
        visualizationType={visualizationType}
        instructions={instructions}
        yAxisFormatId={yAxisFormatId}
      />
      <DropdownSelect
        filterable={false}
        containerClassName={classes.configInput}
        noSelectionText="Select Axis Color"
        showCancelBtn
        onCancelClick={() => {
          const newYAxisFormat = { ...yAxisFormat, colorFromAggColumn: undefined };
          dispatch(
            updateVisualizeOperation(
              updateYAxisFormat(newYAxisFormat, instructions),
              visualizationType as OPERATION_TYPES,
            ),
          );
        }}
        options={
          instructions.aggColumns?.map((aggColumn) => {
            return {
              id: `${aggColumn.agg.name}-${aggColumn.column.name}`,
              name: getAggColDisplayName(aggColumn),
            };
          }) || []
        }
        selectedItem={getColorSelectedItem()}
        onChange={(newItem) => {
          const aggColumn = _.find(instructions.aggColumns, (aggColumn) => {
            // newItem.id will always be "aggType-aggColumn"
            const idParts = newItem.id.split('-');
            return idParts[0] === aggColumn.agg.name && idParts[1] === aggColumn.column.name;
          });
          if (!aggColumn) return;
          const newYAxisFormat = { ...yAxisFormat, colorFromAggColumn: aggColumn };
          dispatch(
            updateVisualizeOperation(
              updateYAxisFormat(newYAxisFormat, instructions),
              visualizationType as OPERATION_TYPES,
            ),
          );
        }}
        label="Match Axis Color"
        minimal
        btnMinimal
        fillWidth
      />
      <SwitchInput
        className={classes.configInput}
        switchOn={yAxisFormat?.oppositeAligned}
        onChange={() => {
          const newYAxisFormat = {
            ...yAxisFormat,
            oppositeAligned: !yAxisFormat?.oppositeAligned,
          };
          dispatch(
            updateVisualizeOperation(
              updateYAxisFormat(newYAxisFormat, instructions),
              visualizationType as OPERATION_TYPES,
            ),
          );
        }}
        label="Show Axis on Opposite Side"
      />{' '}
    </div>
  );
}
