/** @format */
import { Layout } from '@explo-tech/react-grid-layout';
import {
  FilterOperationInstructions,
  OPERATION_TYPES,
  PivotOperationInstructions,
  SortOperationInstructions,
  SortOrder,
  VisualizeOperationGeneralFormatOptions,
  VisualizeOperationInstructions,
} from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { DashboardElement } from 'types/dashboardTypes';
import { dayjs } from 'utils/localizationUtils';

export enum ACTION {
  // Dashboard
  FETCH_ALL_DATA_SOURCE_TABLES = 'FETCH_ALL_DATA_SOURCE_TABLES',
  SYNC_SOURCE_TABLES = 'SYNC_SOURCE_TABLES',
  FETCH_DATASET_PREVIEW = 'FETCH_DATASET_PREVIEW',
  FETCH_DASHBOARD_TEMPLATE = 'FETCH_DASHBOARD_TEMPLATE',
  FETCH_DASHBOARD_TEMPLATE_LIST = 'FETCH_DASHBOARD_TEMPLATE_LIST',
  CREATE_DASHBOARD_TEMPLATE = 'CREATE_DASHBOARD_TEMPLATE',
  CLONE_DASHBOARD_TEMPLATE = 'CLONE_DASHBOARD_TEMPLATE',
  UPDATE_DASHBOARD_TEMPLATE_PARAMS = 'UPDATE_DASHBOARD_TEMPLATE_PARAMS',
  FETCH_DATA_PANEL_TEMPLATE = 'FETCH_DATA_PANEL_TEMPLATE',
  FETCH_SECONDARY_DATA = 'FETCH_SECONDARY_DATA',
  CREATE_TEMPLATE_DATA_PANEL = 'CREATE_TEMPLATE_DATA_PANEL',
  UPDATE_DASHBOARD_TEMPLATE_LAYOUT = 'UPDATE_DASHBOARD_TEMPLATE_LAYOUT',
  UPDATE_DASHBOARD_PDF_LAYOUT = 'UPDATE_DASHBOARD_PDF_LAYOUT',
  FETCH_DATASET_ROW_COUNT = 'FETCH_DATASET_ROW_COUNT',
  CREATE_FILTER_CLAUSE = 'CREATE_FILTER_CLAUSE',
  CREATE_SORT_CLAUSE = 'CREATE_SORT_CLAUSE',
  DELETE_SORT_CLAUSE = 'DELETE_SORT_CLAUSE',
  SELECT_SORT_COLUMN = 'SELECT_SORT_COLUMN',
  SELECT_SORT_ORDER = 'SELECT_SORT_ORDER',
  DELETE_FILTER_CLAUSE = 'DELETE_FILTER_CLAUSE',
  CREATE_PIVOTED_ON_COL = 'CREATE_PIVOTED_ON_COL',
  CREATE_AGGREGATION = 'CREATE_AGGREGATION',
  DELETE_PIVOTED_ON_COL = 'DELETE_PIVOTED_ON_COL',
  UPDATE_PIVOTED_ON_COL = 'UPDATE_PIVOTED_ON_COL',
  DELETE_AGGREGATION = 'DELETE_AGGREGATION',
  UPDATE_AGGREGATION = 'UPDATE_AGGREGATION',
  SELECT_FILTER_COLUMN = 'SELECT_FILTER_COLUMN',
  FETCH_DATA_PANEL_TEMPLATE_ROW_COUNT = 'FETCH_DATA_PANEL_TEMPLATE_ROW_COUNT',
  SELECT_FILTER_OPERATOR = 'SELECT_FILTER_OPERATOR',
  UPDATE_FILTER_VALUE = 'UPDATE_FILTER_VALUE',
  UPDATE_FILTER_VALUE_SOURCE = 'UPDATE_FILTER_VALUE_SOURCE',
  UPDATE_FILTER_VALUE_VARIABLE = 'UPDATE_FILTER_VALUE_VARIABLE',
  FETCH_END_USER_GROUPS = 'FETCH_END_USER_GROUPS',
  CREATE_END_USER_GROUP = 'CREATE_END_USER_GROUP',
  DELETE_END_USER_GROUP = 'DELETE_END_USER_GROUP',
  EDIT_END_USER_GROUP = 'EDIT_END_USER_GROUP',
  UPDATE_SELECTED_CHART = 'UPDATE_SELECTED_CHART',
  UPDATE_VISUALIZE_OP = 'UPDATE_VISUALIZE_OP',
  FETCH_END_USER_GROUP_INFO = 'FETCH_END_USER_GROUP_INFO',
  DELETE_DASHBOARD_TEMPLATE = 'DELETE_DASHBOARD_TEMPLATE',
  RENAME_DATA_PANEL_TEMPLATE = 'RENAME_DATA_PANEL_TEMPLATE',
  CREATE_DASHBOARD_TEMPLATE_ELEMENT = 'CREATE_DASHBOARD_TEMPLATE_ELEMENT',
  DOWNLOAD_DATA_PANEL_TEMPLATE_CSV = 'DOWNLOAD_DATA_PANEL_TEMPLATE_CSV',
  RENAME_DASHBOARD_TEMPLATE = 'RENAME_DASHBOARD_TEMPLATE',
  UPDATE_GLOBAL_STYLES = 'UPDATE_GLOBAL_STYLES',
  SAVE_GLOBAL_STYLES = 'SAVE_GLOBAL_STYLES',
  RESET_GLOBAL_STYLES = 'RESET_GLOBAL_STYLES',
  FETCH_GOOGLE_FONTS = 'FETCH_GOOGLE_FONTS',
  SELECT_DASHBOARD_ELEMENT_TO_EDIT = 'SELECT_DASHBOARD_ELEMENT_TO_EDIT',
  CREATE_NEW_DASHBOARD_DATASET = 'CREATE_NEW_DASHBOARD_DATASET',
  EDIT_DASHBOARD_DATASET_NAME = 'EDIT_DASHBOARD_DATASET_NAME',
  DELETE_DASHBOARD_DATASET = 'DELETE_DASHBOARD_DATASET',
  FETCH_DASHBOARD_DATASET_PREVIEW = 'FETCH_DASHBOARD_DATASET_PREVIEW',
  FETCH_DASHBOARD_DATASET_ROW_COUNT = 'FETCH_DASHBOARD_DATASET_ROW_COUNT',
  SAVE_DASHBOARD_DATASET_QUERY = 'SAVE_DASHBOARD_DATASET_QUERY',
  UPDATE_ELEMENT_CONFIG = 'UPDATE_ELEMENT_CONFIG',
  DELETE_DASHBOARD_ELEMENT = 'DELETE_DASHBOARD_ELEMENT',
  SAVE_DASHBOARD_ELEMENT_CONFIG = 'SAVE_DASHBOARD_ELEMENT_CONFIG',
  SAVE_DASHBOARD_ELEMENT_UPDATES = 'SAVE_DASHBOARD_ELEMENT_UPDATES',
  EMBED_FETCH_DASHBOARD = 'EMBED_FETCH_DASHBOARD',
  EMBED_FETCH_DATA_PANEL_TEMPLATE = 'EMBED_FETCH_DATA_PANEL_TEMPLATE',
  EMBED_FETCH_DATA_PANEL_ROW_COUNT = 'EMBED_FETCH_DATA_PANEL_ROW_COUNT',
  EMBED_DOWNLOAD_DATA_PANEL_TEMPLATE_CSV = 'EMBED_DOWNLOAD_DATA_PANEL_TEMPLATE_CSV',
  EMBED_DOWNLOAD_DATA_PANEL_TEMPLATE_PDF = 'EMBED_DOWNLOAD_DATA_PANEL_TEMPLATE_PDF',
  EMBED_FETCH_SECONDARY_DATA = 'EMBED_FETCH_SECONDARY_DATA',
  EMBED_FETCH_SHARE_DATA = 'EMBED_FETCH_SHARE_DATA',
  EMBED_FETCH_DASHBOARD_DATASET_PREVIEW = 'EMBED_FETCH_DASHBOARD_DATASET_PREVIEW',
  EMBED_SET_DPT_LOADING = 'EMBED_SET_DPT_LOADING',
  FETCH_SHARE_ID = 'FETCH_SHARE_ID', // Also functions as EMBED_FETCH_SHARE_ID, despite not prefixed with EMBED_
  FETCH_PDF_EXPORT_URL = 'FETCH_PDF_EXPORT_URL', // Also functions as EMBED_FETCH_PDF_EXPORT_URL, despite not prefixed with EMBED_
  FETCH_IMAGE_EXPORT_URL = 'FETCH_IMAGE_EXPORT_URL', // Also functions as EMBED_FETCH_IMAGE_EXPORT_URL, despite not prefixed with EMBED_
  SEND_TRACK_EVENT = 'SEND_TRACK_EVENT',
  SELECT_DASHBOARD_DPT_TO_EDIT = 'SELECT_DASHBOARD_DPT_TO_EDIT',
  RESET_EDITED_DATA_PANEL = 'RESET_EDITED_DATA_PANEL',
  UPDATE_FILTER_MATCH = 'UPDATE_FILTER_MATCH',
  FETCH_CHANGELOG_ENTRIES = 'FETCH_CHANGELOG_ENTRIES',
  FETCH_CHANGELOG_ENTRY = 'FETCH_CHANGELOG_ENTRY',
  PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_VALIDATE_TOKEN = 'PASSWORD_RESET_VALIDATE_TOKEN',
  PASSWORD_RESET_CONFIRM = 'PASSWORD_RESET_CONFIRM',
  FETCH_EDITOR_DATASET_PREVIEW = 'FETCH_EDITOR_DATASET_PREVIEW',
  FETCH_EDITOR_DATASET_ROW_COUNT = 'FETCH_EDITOR_DATASET_ROW_COUNT',
  SWAP_DATA_SOURCE = 'SWAP_DATA_SOURCE',
  LIST_TEAM_DATA_SOURCES = 'LIST_TEAM_DATA_SOURCES',
  CHANGE_USER_PROFILE_NAME = 'CHANGE_USER_PROFILE_NAME',
  INVITE_TEAMMATE = 'INVITE_TEAMMATE',
  FETCH_SIGNUP_EMAIL = 'FETCH_SIGNUP_EMAIL',
  ACCEPT_INVITE = 'ACCEPT_INVITE',
  SAVE_FAVICON = 'SAVE_FAVICON',
  SAVE_SHARE_LINK_TITLE = 'SAVE_SHARE_LINK_TITLE',
  CREATE_TEMPLATE_DATA_PANEL_V2 = 'CREATE_TEMPLATE_DATA_PANEL_V2',
  DUPLICATE_DASHBOARD_ITEM = 'DUPLICATE_DASHBOARD_ITEM',
  CREATE_DATASET_V2 = 'CREATE_DATASET_V2',
  FETCH_DATASETS = 'FETCH_DATASETS',
  DELETE_DATA_PANEL_V2 = 'DELETE_DATA_PANEL_V2',
  CREATE_NEW_DASHBOARD_VERSION = 'CREATE_NEW_DASHBOARD_VERSION',
  SWITCH_CURRENTLY_EDITING_DASHBOARD_VERSION = 'SWITCH_CURRENTLY_EDITING_DASHBOARD_VERSION',
  PUBLISH_NEW_DASHBOARD_VERSION = 'PUBLISH_NEW_DASHBOARD_VERSION',
  FETCH_DASHBOARD_VERSIONS = 'FETCH_DASHBOARD_VERSIONS',
  SAVE_DASHBOARD_DRAFT = 'SAVE_DASHBOARD_DRAFT',
  UPDATE_ENVIRONMENT_TAG = 'UPDATE_ENVIRONMENT_TAG',
  CREATE_ENVIRONMENT_TAG = 'CREATE_ENVIRONMENT_TAG',
  CLEAR_DASHBOARD_CONFIG_REDUCER = 'CLEAR_DASHBOARD_CONFIG_REDUCER',
  RENAME_DATA_SOURCE_ID = 'RENAME_DATA_SOURCE_ID',
  DELETE_CURRENT_DASHBOARD_DRAFT = 'DELETE_CURRENT_DASHBOARD_DRAFT',
  UPDATE_AD_HOC_OPERATION_INSTRUCTIONS = 'UPDATE_AD_HOC_OPERATION_INSTRUCTIONS',
  EMBED_UPDATE_AD_HOC_OPERATION_INSTRUCTIONS = 'EMBED_UPDATE_AD_HOC_OPERATION_INSTRUCTIONS',

  CREATE_PARENT_SCHEMA = 'CREATE_PARENT_SCHEMA',
  DELETE_PARENT_SCHEMA = 'DELETE_PARENT_SCHEMA',
  EDIT_PARENT_SCHEMA = 'EDIT_PARENT_SCHEMA',
  SYNC_PARENT_SCHEMA = 'SYNC_PARENT_SCHEMA',
  FETCH_PARENT_SCHEMAS = 'FETCH_PARENT_SCHEMAS',
  FETCH_ALL_SCHEMA_TABLES_FOR_TEAM = 'FETCH_ALL_SCHEMA_TABLES',
  SET_DPT_LOADING = 'SET_DPT_LOADING',

  DELETE_DATA_SOURCE = 'DELETE_DATA_SOURCE',

  CREATE_DASHBOARD_EMAIL = 'CREATE_DASHBOARD_EMAIL',
  FETCH_EMAIL_CADENCE_LIST = 'FETCH_EMAIL_CADENCE_LIST',
  EDIT_DASHBOARD_EMAIL = 'EDIT_DASHBOARD_EMAIL',
  DELETE_DASHBOARD_EMAIL = 'DELETE_DASHBOARD_EMAIL',
  SEND_TEST_EMAIL = 'SEND_TEST_EMAIL',

  SAVE_ANALYTICS_CONFIG = 'SAVE_ANALYTICS_CONFIG',
  FETCH_USER_TEAM = 'FETCH_USER_TEAM',

  CREATE_WHITELIST_DOMAIN = 'CREATE_WHITELIST_DOMAIN',
  DELETE_WHITELIST_DOMAIN = 'DELETE_WHITELIST_DOMAIN',
  FETCH_WHITELIST_DOMAIN = 'FETCH_WHITELIST_DOMAIN',

  GOOGLE_OAUTH_VERIFICATION = 'GOOGLE_OAUTH_VERIFICATION',

  SAVE_DEFAULT_LOCALE_CODE = 'SAVE_DEFAULT_LOCALE_CODE',
  SAVE_USE_BROWSER_LOCALE = 'SAVE_USE_BROWSER_LOCALE',
  LOGIN_USER = 'LOGIN_USER',

  UPDATE_VISUALIZE_OPERATION_GENERAL_FORMAT_OPTIONS = 'UPDATE_VISUALIZE_OPERATION_GENERAL_FORMAT_OPTIONS',
  DOWNLOAD_DATA_PANEL_TEMPLATE_PDF = 'DOWNLOAD_DATA_PANEL_TEMPLATE_PDF',

  GET_OR_CREATE_END_USER_GROUP = 'GET_OR_CREATE_END_USER_GROUP',
}

export interface UserTeam {
  id: number;
  team_name: string;
  trial_expiration_date: dayjs.ConfigType;
  api_token: string;
  invite_code: string;
  style_config_v2: GlobalStyleConfig;
  default_locale_code: string;
  use_browser_locale: boolean;
}

export interface User {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  email_verified?: boolean;
  profile_data?: object;
  is_superuser?: boolean;
  root_folder_id?: number;
  has_team?: boolean;
  team?: UserTeam;
}

export interface UserInfo {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  is_superuser?: boolean;
}

export interface Team {
  id: number;
  team_name: string;
  logo_url: string;
  required_email_domain: string | null;
  invite_code: string;
  team_members: User[];
  trial_expiration_date: null | string | number | Date; // Copying the dayjs() constructor
  api_token: string;
  analytics_reporting_url?: string;
  analytics_reporting_actions: ReportedAnalyticsAction[];
  analytics_reporting_token?: string;
  default_locale_code: string;
  use_browser_locale: boolean;
  favicon_url?: string;
}

export interface SupportedDataSource {
  type: string;
  name: string;
  configuration_schema: {
    order: string[];
    properties: Record<
      string,
      {
        type: string;
        label: string;
        placeholder: string;
        default?: string;
        help_tooltip: string;
        checked_label: string;
        depends_on?: string;
        optional?: boolean;
        security_page?: boolean;
      }
    >;
  };
}

export interface DataSource {
  id: number;
  provided_id?: string;
  source_type: string;
  team_id: number;
  name: string;
  default: boolean;
  parent_schema_id: number;
}

export interface TableColumn {
  name: string;
  type: string;
  friendly_name?: string;
}

export type TableRow = Record<string, string | number>;

export interface DataTable {
  name: string;
  columns: TableColumn[];
}

export interface DashboardParam {
  id: string;
  dashboard_template_id?: number;
  name: string;
  type: string;
}

export interface DashboardTemplate {
  id: number;
  team_id: string;
  name: string;
  embed_id: string;
  params: DashboardParam[];
  data_panels: DataPanelTemplate[];
  dashboard_elements: DashboardElement[];
  layout?: Layout[];
  cachedLayout?: Layout[];
  share_link_title?: string;
}

export interface Dataset {
  id: string;
  table_name: string;
  schema?: TableColumn[];
  query?: string;
  dashboard_template_id?: number;
  parent_schema_id: number;

  // attached when dataset data is fetched
  _schema?: TableColumn[]; // schema cache when preview is clicked but no save is clicked
  _rows?: TableRow[];
  _total_row_count?: number;
  _error?: string;

  _loading?: boolean;
  _is_new?: boolean;
}

export interface BaseOperation {
  operation_type: OPERATION_TYPES;
}

export type FilterOperation = BaseOperation & {
  instructions: FilterOperationInstructions;
};

export type PivotOperation = BaseOperation & {
  instructions: PivotOperationInstructions;
};

export type VisualizeOperation = BaseOperation & {
  instructions: VisualizeOperationInstructions;
  generalFormatOptions?: VisualizeOperationGeneralFormatOptions;
};

export type SortOperation = BaseOperation & {
  instructions: SortOperationInstructions;
};

export interface SortInfo {
  column_name: string;
  order: SortOrder;
}

export interface AdHocOperationInstructions {
  currentPage?: number;
  sortInfo?: SortInfo;
  filterInfo?: FilterOperationInstructions;
}

export interface DataPanelTemplate {
  // BE Model
  id: string;
  provided_id?: string; // provided id to reference data panel as variable
  container_id?: string;
  dashboard_template_id?: number;
  dashboard_instance_id?: number;
  table_id: string | number;
  filter_op: FilterOperation;
  sort_op: SortOperation;
  group_by_op: PivotOperation;
  visualize_op: VisualizeOperation;
  name: string;

  // Attached in FE
  _loading?: boolean;
  _outstandingSecondaryDataRequests?: number;
  _downloadCsvLoading?: boolean;
  _adHocOperationInstructions?: AdHocOperationInstructions;
  _secondaryData?: TableRow[];

  // Attached in BE
  _schema?: TableColumn[];
  _rows?: TableRow[];
  _error?: string;
  _total_row_count?: number;
  _base_schema?: TableColumn[];
  _base_schema_error?: string;
  _source_type?: string;
  _query?: string;
}

export interface Dashboard {
  // From BE
  data_panels: DataPanelTemplate[];
  params: DashboardParam[];
  dashboard_elements: DashboardElement[];
  id: number;
  layout?: Layout[];
  cachedLayout?: Layout[];
  template_id: number;
}

export interface ShareData {
  id: string;
  share_link_url: string | null;
  imageExportUrl?: string;
  pdfExportUrl?: string;
}

export interface EndUserGroup {
  id: number;
  name: string;
  provided_id: string;
  data_sources: number[];
  parent_schema_datasource_mapping: Record<string, string>;
}

export interface ChangelogEntry {
  id: number;
  post_date: string;
  title: string;
  header_img_url?: string;
  raw_markdown_body: string;
  url_path: string;
}

export interface DashboardVersionConfig {
  dashboard_layout: Layout[];
  pdf_layout?: Layout[];
  data_panels: Record<string, DataPanelTemplate>;
  elements: Record<string, DashboardElement>;
  params: Record<string, DashboardParam>;
  datasets: Record<string, Dataset>;
  schema_version: number;
}

export interface DashboardVersion {
  id: number;
  is_draft: boolean;
  version_number: number;
  change_comments: string;
  configuration: DashboardVersionConfig;
  created: string;
  version_saved_at?: string;
  published_by_id?: number;
}

export interface DataPanelConfigReducerState {
  filterOperation?: FilterOperation;
  sortOperation?: SortOperation;
  pivotOperation?: PivotOperation;
  visualizeOperation?: VisualizeOperation;
}

export interface DashboardVersionInfo {
  is_draft: boolean;
  change_comments?: string;
  version_number: number;
}

export interface DashboardEnvironmentTag {
  id: number;
  team_id: number;
  dashboard_versions_by_dashboard?: Record<number, number>;
  name: string;
  is_prod: boolean;
  color_hex: string;
}

export interface ParentSchema {
  id: number;
  name: string;
  team_id: number;
}

export interface EmailCadence {
  id: number;
  team_id: number;
  dashboard_template_id: number;
  minute: number;
  hour: number;
  week_of_month?: number;
  day_of_week?: number;
  subject?: string;
  from_email?: string;
  timezone?: string;
}

export interface ReportedAnalyticsAction {
  id: number;
  name: string;
}

export interface WhitelistDomain {
  id: number;
  name: string;
  team: Team;
}
