/** @format */

import React, { useContext } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/index';

import PanelName from './PanelName';

import { HEADER_HEIGHT } from 'pages/dashboardPage/DashboardDatasetView/DashboardDatasetView';
import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';

const useStyles = makeStyles(() => ({
  header: (props: { globalStyleConfig: GlobalStyleConfig }) => ({
    padding: 0,
    paddingBottom: props.globalStyleConfig.container.padding.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 400,
    fontSize: 14,
    height: HEADER_HEIGHT,
    overflow: 'hidden',
  }),
  headerActions: {
    display: 'flex',
    alignItems: 'center',
  },
  hoverIcon: {
    opacity: 0.5,
    display: 'none',
  },
}));

type Props = {
  actions?: React.ReactNode;
  className?: string;
  infoTooltipText?: string;
  inheritBaseBgColor?: boolean;
  loading?: boolean;
  panelName: string;
  style?: React.CSSProperties;
};

export default function DashboardDatasetViewHeader({
  actions,
  className,
  loading,
  panelName,
  style,
  infoTooltipText,
  inheritBaseBgColor,
}: Props) {
  const { globalStyleConfig } = useContext(GlobalStylesContext);
  const classes = useStyles({ globalStyleConfig });

  return (
    <div
      className={cx(
        classes.header,
        GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadiusTop,
        {
          [GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor]: inheritBaseBgColor,
        },
        className,
      )}
      style={style}>
      <PanelName panelName={panelName} loading={loading} infoTooltipText={infoTooltipText} />
      <div className={classes.headerActions}>
        <div className={classes.headerActions}>{actions}</div>
      </div>
    </div>
  );
}
