/** @format */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ReduxState } from 'reducers/rootReducer';
import { ControlGroup, Button, FormGroup, Intent, Divider } from '@blueprintjs/core';
import { AppToaster } from 'toaster';

import SetupFlowPage from 'components/pages/setupFlowPage';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';

import { logoutUser } from 'actions/authAction';
import { createTeam, addUserToTeam } from 'actions/authAction';
import { isUUID } from 'utils/general';

import { pageView } from 'analytics/exploAnalytics';
import { ROUTES } from 'constants/routes';

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & RouteComponentProps;

const JoinTeamPage = (props: Props) => {
  return (
    <SetupFlowPage
      showLogoutButton
      title="Join or create a team for your organization"
      pageBody={<JoinTeamPageBody {...props} />}
    />
  );
};

const JoinTeamPageBody = (props: Props) => {
  const { currentUser, createTeam, addUserToTeam } = props;
  const [teamName, setTeamName] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  useEffect(() => {
    pageView('Join Team');
  }, []);

  const onCreateTeam = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    createTeam(
      teamName,
      () => {
        // @ts-ignore
        window.location = ROUTES.HOME_APP_PAGE;
      },
      (errorMsg) => {
        AppToaster.show({
          icon: 'error',
          message: errorMsg,
          timeout: 10000,
          intent: Intent.DANGER,
        });
      },
    );

    event.preventDefault();
  };

  const onJoinTeam = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (currentUser.id && isUUID(inviteCode)) {
      addUserToTeam(
        currentUser.id,
        inviteCode,
        () => {
          // @ts-ignore
          window.location = ROUTES.HOME_APP_PAGE;
        },
        (errorMsg) => {
          AppToaster.show({
            icon: 'error',
            message:
              errorMsg && errorMsg.status === 403
                ? `You are already logged in with ${currentUser.email}. 
                  If this is the wrong account, please logout with the link in the top right.`
                : 'Incorrect invite code. Please ensure there are no spaces or tabs.',
            timeout: 10000,
            intent: Intent.DANGER,
          });
        },
      );
    } else {
      AppToaster.show({
        icon: 'error',
        message: 'Invalid Invite Code',
        timeout: 10000,
        intent: Intent.DANGER,
      });
    }

    event.preventDefault();
  };

  return (
    <div>
      <div className="flex-box center-horizontal">
        <FormGroup label="Join an existing team" labelFor="invite_code" inline={true}>
          <ControlGroup fill={true} vertical={false}>
            <InputGroup
              id="invite_code"
              onChange={(e: any) => setInviteCode(e.target.value)}
              name="invite_code"
              value={inviteCode}
              placeholder="Invite code"
              leftIcon="people"
            />
            <Button
              onClick={onJoinTeam}
              icon="arrow-right"
              disabled={inviteCode === '' || currentUser.createTeamLoading}
              loading={currentUser.joinTeamLoading}
            />
          </ControlGroup>
        </FormGroup>
      </div>

      <Divider />

      <div className="flex-box center-horizontal" style={{ marginTop: 20 }}>
        <FormGroup label="Create a new team" labelFor="team_name" inline={true}>
          <ControlGroup fill={true} vertical={false}>
            <InputGroup
              disabled={true}
              id="team_name"
              onChange={(e: any) => setTeamName(e.target.value)}
              name="team_name"
              value={teamName}
              placeholder="Team name"
              leftIcon="star"
            />
            <Button
              onClick={onCreateTeam}
              icon="arrow-right"
              disabled={teamName === '' || currentUser.joinTeamLoading}
              loading={currentUser.createTeamLoading}
            />
          </ControlGroup>
        </FormGroup>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  logoutUser,
  createTeam,
  addUserToTeam,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JoinTeamPage));
