/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { InputGroup as BP3InputGroup, IInputGroupProps, HTMLInputProps } from '@blueprintjs/core';

const useStyles = makeStyles((theme: Theme) => ({
  inputGroupContainer: {
    '& .bp3-input': {
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.ds.grey300}`,
    },

    '& .bp3-input:focus, .bp3-input.bp3-active': {
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.ds.blue}`,
    },
  },
}));

type Props = IInputGroupProps & HTMLInputProps;

export default function InputGroup({ className, ...otherProps }: Props) {
  const classes = useStyles();

  return <BP3InputGroup {...otherProps} className={cx(classes.inputGroupContainer, className)} />;
}
