/** @format */

import { Action } from 'reducers/rootReducer';
import { cloneDeep } from 'lodash';
import { DataPanelTemplate } from 'actions/types';
import { OPERATION_TYPES } from 'constants/types';

export function createReducer<T>(
  initialState: T,
  handlers: Record<string, (newState: T, action: Action) => T>,
  onComplete?: (oldState: T, newState: T, actionType: string) => void,
) {
  return function reducer(state: T = initialState, action: Action) {
    if (handlers[action.type]) {
      const result = handlers[action.type](cloneDeep(state), action);
      onComplete?.(state, result, action.type);
      return result;
    } else {
      return state;
    }
  };
}

export function resolveSecondaryData(
  dataPanelTemplate: DataPanelTemplate,
  secondaryDataResponse: DataPanelTemplate,
) {
  /**
   * For BoxPlots backed by a Redshift DB, we need to fetch batches of metrics for different calc columns
   * one at a time. So we don't want to overwrite secondaryData, but append to it instead.
   */
  if (
    secondaryDataResponse._source_type === 'redshift' &&
    dataPanelTemplate.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2
  ) {
    return [...(dataPanelTemplate._secondaryData ?? []), ...(secondaryDataResponse._rows ?? [])];
  }

  return secondaryDataResponse._rows;
}
