/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core/index';

import DropdownSelect from 'shared/DropdownSelect';

import { SelectedDropdownInputItem, VisualizeNumberInstructions } from 'constants/types';
import { NUMBER_FORMATS } from 'constants/dataConstants';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(4),
    },
  });

type PassedProps = {
  visualizeNumberInstructions: VisualizeNumberInstructions;
  updateVisualizeOperation: (newVisualizeNumberInstructions: VisualizeNumberInstructions) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class NumberFormatConfiguration extends React.Component<Props> {
  render() {
    const { classes, visualizeNumberInstructions } = this.props;
    const { layoutConfig } = visualizeNumberInstructions;

    return (
      <div className={classes.root}>
        <DropdownSelect
          label="Formatting"
          selectedItem={layoutConfig.NUMBER.numberFormat || NUMBER_FORMATS.NORMAL}
          onChange={this.onChange}
          noSelectionText="Select format"
          options={Object.values(NUMBER_FORMATS)}
          minimal
          fillWidth
          showIcon
        />
      </div>
    );
  }

  onChange = (item: SelectedDropdownInputItem) => {
    const { visualizeNumberInstructions, updateVisualizeOperation } = this.props;

    if (visualizeNumberInstructions.layout === 'NUMBER') {
      visualizeNumberInstructions.layoutConfig.NUMBER.numberFormat = item;
    }
    updateVisualizeOperation(visualizeNumberInstructions);
  };
}

export default withStyles(styles)(NumberFormatConfiguration);
