/** @format */

import React from 'react';

import { TableColumn } from 'actions/types';
import {
  NumberDisplayFormat,
  NumberDisplayOptions,
  NumberDisplayDisplayType,
  VisualizeTableInstructions,
} from 'constants/types';
import ToggleSwitchInput from 'shared/ToggleSwitchInput';
import GoalInputRow from './GoalInputRow';

type Props = {
  addFieldsToDisplayOptions: (
    newFields: Partial<NumberDisplayOptions> & {
      format: NumberDisplayFormat;
    },
  ) => void;
  column: TableColumn;
  instructions: VisualizeTableInstructions;
};

export default function ProgressBarConfiguration({
  addFieldsToDisplayOptions,
  column,
  instructions,
}: Props) {
  const { format: currentFormat, displayType, displayTypeOptions } = (instructions
    .schemaDisplayOptions?.[column.name] as NumberDisplayOptions) ?? {
    format: NumberDisplayFormat.NORMAL,
  };

  return (
    <>
      <ToggleSwitchInput
        largeText
        onChange={() => {
          addFieldsToDisplayOptions({
            format: currentFormat,
            displayType:
              displayType === NumberDisplayDisplayType.PROGRESS_BAR
                ? NumberDisplayDisplayType.VALUE
                : NumberDisplayDisplayType.PROGRESS_BAR,
          });
        }}
        label="Progress bar"
        switchOn={displayType === NumberDisplayDisplayType.PROGRESS_BAR}
      />
      {displayType === NumberDisplayDisplayType.PROGRESS_BAR && (
        <GoalInputRow
          type="max"
          updateExactValue={(newValue) => {
            addFieldsToDisplayOptions({
              format: currentFormat,
              displayTypeOptions: {
                ...displayTypeOptions,
                progressBarGoal: newValue,
              },
            });
          }}
          updateUsesColumnCalculation={(newValue) => {
            addFieldsToDisplayOptions({
              format: currentFormat,
              displayTypeOptions: {
                ...displayTypeOptions,
                useColumnMaxForProgressBarGoal: newValue,
              },
            });
          }}
          exactValue={displayTypeOptions?.progressBarGoal}
          usesColumnCalculation={displayTypeOptions?.useColumnMaxForProgressBarGoal}
          label="Goal value"
        />
      )}
    </>
  );
}
