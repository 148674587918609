/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { WithStyles } from '@material-ui/core/index';
import { Popover, Button, Icon } from '@blueprintjs/core';
import { BlockPicker } from 'react-color';

const styles = () =>
  createStyles({
    blockPicker: {
      textTransform: 'uppercase',
    },
  });

type PassedProps = {
  className?: string;
  selectedColor: string;
  colors: string[];
  onUpdate: (colorHex: string) => void;
  onClose?: () => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class ColorPicker extends React.Component<Props> {
  render() {
    const { selectedColor, classes, className, colors, onUpdate, onClose, children } = this.props;
    return (
      <Popover className={className} minimal onClose={onClose}>
        {children ?? <Button icon={<Icon icon="symbol-square" color={selectedColor} />} />}
        <BlockPicker
          // @ts-ignore
          width={180}
          className={classes.blockPicker}
          triangle="hide"
          color={selectedColor}
          colors={colors}
          onChange={(color) => onUpdate(color.hex)}
        />
      </Popover>
    );
  }
}

export default withStyles(styles)(ColorPicker);
