/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { WithStyles } from '@material-ui/core/index';
import { connect } from 'react-redux';

import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import SelectCategoryColumn from 'pages/dataPanelEditorPage/visualizeSections/funnel/selectCategoryColumn';
import SelectAmountColumn from 'pages/dataPanelEditorPage/visualizeSections/funnel/selectAmountColumn';
import SelectFunnelColors from 'pages/dataPanelEditorPage/visualizeSections/funnel/selectFunnelColors';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { VisualizeFunnelInstruction } from 'constants/types';
import { OPERATION_TYPES } from 'constants/types';
import { DataPanelTemplate } from 'actions/types';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
  });

type PassedProps = {
  instructions: VisualizeFunnelInstruction;
  dataPanelTemplate: DataPanelTemplate;
};

type Props = PassedProps &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  RouteComponentProps &
  ReturnType<typeof mapStateToProps>;

class VisualizeFunnelSection extends React.Component<Props> {
  render() {
    const { classes, instructions, dataPanelTemplate, updateVisualizeOperation } = this.props;
    if (!dataPanelTemplate || !dataPanelTemplate._schema) return <div></div>;

    return (
      <div className={classes.root}>
        <ConfigSectionHeader title="Configure funnel" />
        <SelectCategoryColumn
          schema={dataPanelTemplate._schema}
          instructions={instructions}
          updateVisualizeOperation={(newInstructions: VisualizeFunnelInstruction) => {
            updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_FUNNEL);
          }}
        />
        <SelectAmountColumn
          schema={dataPanelTemplate._schema}
          instructions={instructions}
          updateVisualizeOperation={(newInstructions: VisualizeFunnelInstruction) => {
            updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_FUNNEL);
          }}
        />
        <SelectFunnelColors
          instructions={instructions}
          updateVisualizeOperation={(newInstructions: VisualizeFunnelInstruction) => {
            updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_FUNNEL);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateVisualizeOperation,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VisualizeFunnelSection)),
);
