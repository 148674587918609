/** @format */

import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { AppToaster } from 'toaster';
import { Intent } from '@blueprintjs/core';

import DataSourcePanel from 'pages/DataSourcesPage/DataSourcePanel';
import PageHeader from 'components/pageHeader';
import ManageSchemasModal from 'pages/DataSourcesPage/ManageSchemasModal';
import TextFieldModal from 'components/modals/textFieldModal';
import ConfirmationModal from 'components/modals/confirmationModal';
import LoadingBody from 'components/loadingBody';

import { ReduxState } from 'reducers/rootReducer';
import {
  listTeamDataSources,
  renameDataSourceId,
  deleteDataSource,
} from 'actions/dataSourceActions';
import { fetchParentSchemas, fetchAllSchemaTables } from 'actions/parentSchemaActions';
import { NAVBAR_HEIGHT } from 'components/pages/navbar';
import { ROUTES } from 'constants/routes';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import Button from 'shared/Button';
import SyncTablesModal from './SyncTablesModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
    width: '100%',
    padding: `${theme.spacing(5)}px ${theme.spacing(6)}px`,
    overflowY: 'auto' as 'auto',
  },
  panelsContainer: {
    marginTop: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: '16px',
  },
  dataSourcePanel: {
    width: '100%',
  },
  schemaPanel: {
    color: theme.palette.ds.grey700,
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 500,
    paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  providedIdTag: {
    color: theme.palette.ds.grey500,
    marginLeft: theme.spacing(2),
    fontSize: 12,
    width: '50%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  syncTablesButton: {
    padding: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 0,
    fontWeight: 500,
    height: '28px',
    width: '106px',
  },
}));

type Props = {} & typeof mapDispatchToProps & RouteComponentProps;

function DataSourcesPage({
  listTeamDataSources,
  renameDataSourceId,
  fetchAllSchemaTables,
  fetchParentSchemas,
  deleteDataSource,
}: Props) {
  const [selectedDataSourceId, setSelectedDataSourceId] = useState<number>();
  const [schemaModelOpen, setSchemaModelOpen] = useState<boolean>(false);
  const [deleteDataSourceModalOpen, setDeleteDataSourceModalOpen] = useState<boolean>(false);
  const [renameDataSourceModalOpen, setRenameDataSourceModalOpen] = useState<boolean>(false);
  const [selectedParentSchemaId, setSelectedParentSchemaId] = useState<number>();
  const [syncTablesModalOpen, setSyncTablesModalOpen] = useState<boolean>(false);

  useEffect(() => {
    listTeamDataSources();
  }, [listTeamDataSources]);
  useEffect(() => {
    fetchParentSchemas();
  }, [fetchParentSchemas]);
  useEffect(() => {
    fetchAllSchemaTables();
  }, [fetchAllSchemaTables]);

  const { dataSources, parentSchema, parentSchemaTablesMap, pageDataLoading } = useSelector(
    (state: ReduxState) => ({
      dataSources: state.dataSourceList.dataSources,
      parentSchema: state.parentSchemas.allParentSchema || [],
      parentSchemaTablesMap: state.parentSchemas.schemaTablesMap || {},
      pageDataLoading: createLoadingSelector(
        [ACTION.LIST_TEAM_DATA_SOURCES, ACTION.FETCH_PARENT_SCHEMAS],
        true,
      )(state),
    }),
  );

  const dataSourcesBySchemaId = _.groupBy(dataSources, (datasource) => datasource.parent_schema_id);
  const dataSourceById = _.indexBy(dataSources, 'id');

  const classes = useStyles();
  const history = useHistory();

  if (pageDataLoading) return <LoadingBody />;

  return (
    <div className={classes.root}>
      <PageHeader
        title="Data Sources"
        primaryActionButtonText="Add Data Source"
        onPrimaryActionButtonClick={() => history.push(ROUTES.CONNECT_DATA_SOURCE)}
        secondaryActionButtonText="Manage Schemas"
        onSecondaryActionButtonClick={() => setSchemaModelOpen(true)}
      />

      <div className={classes.panelsContainer}>
        {parentSchema
          .filter((schema) => dataSourcesBySchemaId[schema.id]?.length > 0)
          .map((schema) => {
            const dataSourcesForSchema =
              _.sortBy(dataSourcesBySchemaId[schema.id], (datasource) => datasource.id) ?? [];
            const hasNoSyncedTables = _.isEmpty(parentSchemaTablesMap[schema.id]);
            return (
              <div key={`schema-panel-${schema.id}`}>
                <div className={classes.schemaPanel}>
                  {schema.name}
                  <div className={classes.providedIdTag}>({`ID: ${schema.id}`}) </div>
                  {hasNoSyncedTables && (
                    <Button
                      className={classes.syncTablesButton}
                      type="secondary"
                      onClick={() =>
                        history.push(ROUTES.SYNC_DATA_TABLES_NO_SCHEMA + `/${schema.id}`)
                      }
                      text="Sync Tables"
                    />
                  )}
                </div>
                {dataSourcesForSchema.map((datasource) => (
                  <DataSourcePanel
                    key={`data-source-panel-${datasource.id}`}
                    className={classes.dataSourcePanel}
                    name={datasource.name}
                    providedId={datasource.provided_id}
                    type={datasource.source_type}
                    id={datasource.id}
                    isDefault={datasource.default}
                    hasNoSyncedTables={hasNoSyncedTables}
                    openRenameDataSourceModal={() => {
                      setSelectedDataSourceId(datasource.id);
                      setRenameDataSourceModalOpen(true);
                    }}
                    openSyncTablesModal={() => {
                      setSelectedParentSchemaId(schema.id);
                      setSyncTablesModalOpen(true);
                    }}
                    openDeleteDataSourceModal={() => {
                      if (datasource.default) {
                        AppToaster.show({
                          message: (
                            <div>
                              You cannot delete a default data source. To delete this data source,
                              click Manage Schemas and either delete the entire schema or select a
                              new default data source.
                            </div>
                          ),
                          icon: 'error',
                          timeout: 5000,
                          intent: Intent.DANGER,
                        });
                      } else {
                        setSelectedDataSourceId(datasource.id);
                        setDeleteDataSourceModalOpen(true);
                      }
                    }}
                  />
                ))}
              </div>
            );
          })}
      </div>
      <TextFieldModal
        modalOpen={renameDataSourceModalOpen}
        closeModal={() => setRenameDataSourceModalOpen(false)}
        resourceName={
          selectedDataSourceId ? dataSourceById[selectedDataSourceId].provided_id : undefined
        }
        modalTitle={
          selectedDataSourceId
            ? `Rename the ID for: ${dataSourceById[selectedDataSourceId].name}`
            : ''
        }
        buttonName="Save"
        textFieldPlaceholder="e.g., big-query-prod"
        onSubmit={(name: string) =>
          renameDataSourceId(
            { id: selectedDataSourceId, postData: { provided_id: name } },
            undefined,
            (error) => {
              AppToaster.show({
                message: error.detail,
                icon: 'error',
                timeout: 7000,
                intent: Intent.DANGER,
              });
            },
          )
        }
      />
      <ConfirmationModal
        isDestructive
        modalOpen={deleteDataSourceModalOpen}
        closeModal={() => setDeleteDataSourceModalOpen(false)}
        modalTitle="Are you sure you want to delete this data source?"
        cancelBtnText="Cancel"
        confirmBtnText={`Delete ${
          selectedDataSourceId ? dataSourceById[selectedDataSourceId].name : undefined
        }`}
        onConfirm={() => {
          deleteDataSource({ id: selectedDataSourceId });

          setSelectedDataSourceId(undefined);
          setDeleteDataSourceModalOpen(false);
        }}
      />
      <ManageSchemasModal
        modalOpen={schemaModelOpen}
        closeModal={() => setSchemaModelOpen(false)}
        parentSchemas={parentSchema}
        dataSources={dataSources}
      />
      <SyncTablesModal
        modalOpen={syncTablesModalOpen}
        closeModal={() => setSyncTablesModalOpen(false)}
        onSync={() =>
          history.push(ROUTES.SYNC_DATA_TABLES_NO_SCHEMA + `/${selectedParentSchemaId}`)
        }
      />
    </div>
  );
}

const mapDispatchToProps = {
  listTeamDataSources,
  renameDataSourceId,
  fetchParentSchemas,
  fetchAllSchemaTables,
  deleteDataSource,
};

export default connect(null, mapDispatchToProps)(DataSourcesPage);
