/** @format */
import React from 'react';
import { connect } from 'react-redux';
import { Intent } from '@blueprintjs/core';
import cx from 'classnames';

import { deleteWhitelistDomain } from 'actions/whitelistDomainActions';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { AppToaster } from 'toaster';
import { WhitelistDomain } from 'actions/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `0 ${theme.spacing(8)}px 125px ${theme.spacing(8)}px`,
  },
  ruleContainer: {
    '&:hover': {
      background: theme.palette.ds.grey200,
    },
    '&:hover .rule-action': {
      color: `${theme.palette.ds.red}`,
      cursor: 'pointer',
    },
    borderRadius: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    margin: `${theme.spacing(2)}px -${theme.spacing(2.5)}px 0px -${theme.spacing(2)}px`,
  },
  ruleText: {},
  ruleAction: {
    color: theme.palette.ds.grey700,
  },
}));

type PassedProps = {
  rule: WhitelistDomain;
};

type Props = PassedProps & typeof mapDispatchToProps;

function SettingsDomainWhitelistRule(props: Props) {
  const { deleteWhitelistDomain, rule } = props;
  const classes = useStyles();

  const [deleteClicked, setDeleteClicked] = React.useState(false);

  return (
    <div className={classes.ruleContainer}>
      <div className={classes.ruleText}>{rule.name}</div>
      <div
        className={cx(classes.ruleAction, 'rule-action')}
        onClick={() => {
          deleteClicked &&
            deleteWhitelistDomain(
              {
                id: rule.id,
              },
              undefined,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (response: any) => {
                AppToaster.show({
                  message: response.error_msg
                    ? response.error_msg
                    : 'Something went wrong. Please try again or contact support@explo.co if the error continues.',
                  icon: 'error',
                  timeout: 3000,
                  intent: Intent.DANGER,
                });
                setDeleteClicked(false);
              },
            );
          setDeleteClicked(true);
        }}>
        Remove
      </div>
    </div>
  );
}

const mapDispatchToProps = { deleteWhitelistDomain };

export default connect(null, mapDispatchToProps)(SettingsDomainWhitelistRule);
