/** @format */
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';
import Color from 'color';
import FlexBox, { VerticalAlignment } from 'components/core/FlexBox';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ hidden }: Props) => ({
    color: theme.palette.ds.blue,
    backgroundColor: new Color(theme.palette.ds.blue).fade(0.92).rgb().string(),
    padding: theme.spacing(1),
    fontWeight: 500,
    fontSize: 10,
    borderRadius: theme.shape.borderRadius / 2,
    marginLeft: theme.spacing(2),
    ...(hidden && { visibility: 'hidden' }),
  }),
  cancelIcon: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
}));

type Props = {
  onCancel: () => void;
  hidden?: boolean;
};

export default function CustomizedTag(props: Props) {
  const { onCancel } = props;
  const theme = useTheme();
  const classes = useStyles(props);

  return (
    <FlexBox className={classes.root} verticalAlignment={VerticalAlignment.CENTER}>
      Customized
      <Icon
        className={classes.cancelIcon}
        icon="cross"
        iconSize={10}
        color={theme.palette.ds.blue}
        onClick={onCancel}
      />
    </FlexBox>
  );
}
