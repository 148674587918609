/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import Navbar from 'components/pages/navbar';
import InAppErrorBoundary from 'components/pages/inAppErrorBoundary';

const styles = (theme: Theme) => ({
  root: {
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column' as 'column',
    backgroundColor: theme.palette.ds.grey200,
  },
  body: {
    width: '100%',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
  },
});

type PassedProps = {
  removeLeftRoutes?: boolean;
  activeTabId?: string;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {};

class PageWithNavbar extends React.Component<Props, State> {
  render() {
    const { classes, removeLeftRoutes, activeTabId } = this.props;
    return (
      <div className={classes.root}>
        <Navbar activeTabId={activeTabId} removeLeftRoutes={removeLeftRoutes} />
        <div className={classes.body}>
          <InAppErrorBoundary>{this.props.children}</InAppErrorBoundary>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(PageWithNavbar);
