/** @format */

import React, { CSSProperties } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';

import FlexBox from 'components/core/FlexBox';
import InputLabel from 'shared/InputLabel';

const useStyles = makeStyles(() => ({
  fillWidth: {
    width: '100%',
  },
}));

export type Props = {
  /**
   * Toggle buttons to render as part of a group.
   */
  children: React.ReactNode;
  /**
   * A classname to pass to the container element.
   */
  className?: string;
  /**
   * If true, the group will expand to fill the width of its container.
   */
  fillWidth?: boolean;
  /**
   * Option label above the button group.
   */
  label?: string;
};

export default function ToggleButtonGroup({ children, className, fillWidth, label }: Props) {
  const classes = useStyles();

  return (
    <div className={className}>
      {label && <InputLabel text={label} />}
      <FlexBox className={cx({ [classes.fillWidth]: fillWidth })}>
        {React.Children.map(children, (child) => {
          const style: CSSProperties = {
            ...(fillWidth && { flex: 1 }),
          };
          return React.cloneElement(child as React.ReactElement, { style });
        })}
      </FlexBox>
    </div>
  );
}
